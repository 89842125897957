import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { jobAlertVendorGellAllRespondModel, jobAlertVendorSaveRespondModel, jobAlertVendorTitleDropdownResult, jobAlertVendorViewModel } from "../../models/vendor/JobAlert";
import { IJobAlertVendorAction, jobAlertVendorReducer } from "../../action/vendor/JobAlertAction";


export const JobAlertVendorDispatcherContext = React.createContext<React.Dispatch<IJobAlertVendorAction> | null>(
  null
);
export const JobAlertVendorStateContext = React.createContext<IJobAlertVendorManagementState | null>(
  null
);

export interface IJobAlertVendorManagementProps extends RouteComponentProps<any> {}

export interface IJobAlertVendorManagementState {
  visible: boolean;
  value: number;
  jobAlertVendor: jobAlertVendorViewModel;
  jobAlertVendorSaveRespond: jobAlertVendorSaveRespondModel;
  jobAlertVendorCategoryMapSaveRespond: jobAlertVendorSaveRespondModel;
  jobAlertVendorExperienceMapSaveRespond: jobAlertVendorSaveRespondModel;
  jobAlertVendorIndustryMapSaveRespond: jobAlertVendorSaveRespondModel;
  JobAlertVendorRoleMapSaveRespond: jobAlertVendorSaveRespondModel;
  JobAlertVendorTypeMapSaveRespond: jobAlertVendorSaveRespondModel;
  jobAlertVendorGetAll: jobAlertVendorGellAllRespondModel;
  jobAlertVendorTitleDropdownResult: jobAlertVendorTitleDropdownResult[];
}

export const initialJobAlertVendorManagementState = {
  visible: false,
  value: 0,
  jobAlertVendor: {} as jobAlertVendorViewModel,
  jobAlertVendorGetAll: {} as jobAlertVendorGellAllRespondModel,
  jobAlertVendorTitleDropdownResult: [] as jobAlertVendorTitleDropdownResult[],
} as IJobAlertVendorManagementState;

export const JobAlertVendorContextProvider: React.FC = ({ children }) => {
  const [jobAlertVendorState, dispatcher] = useImmerReducer(
    jobAlertVendorReducer,
    initialJobAlertVendorManagementState
  );

  return (
    <JobAlertVendorDispatcherContext.Provider value={dispatcher}>
      <JobAlertVendorStateContext.Provider value={jobAlertVendorState}>
        {children}
      </JobAlertVendorStateContext.Provider>
    </JobAlertVendorDispatcherContext.Provider>
  );
};
