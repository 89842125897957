import { Reducer } from "react";

import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";

import { message } from "antd";
import {
  ClientCandidateMoveRequestModel,
  ClientCandidateMoveResultModel,
  ClientJobResultModel,
  ClientJobStatusRequestModel,
  ClientJobStatusResultModel,
  ClientViewApplicationDetailsRequestModel,
  ClientViewApplicationDetailsResultModel,
  ClientViewApplicationRequestModel,
  ClientViewApplicationResultModel,
  DashboardCountRequestModel,
  DashboardCountResultModel,
  FakeCandidateSearchRequest,
  FakeCandidatesearchResult,
  GetAllJobRequestModel,
  GetAllJobRespondModel,
} from "../../models/client/ClientJob";
import {
  ClientJobDispatcherContext,
  ClientJobStateContext,
  IClientJobManagementState,
} from "../../context/client/ClientJobContext";
import { ClientPersonalInfoResultModel } from "../../models/client/ClientProfile";
let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IClientJobAction =
  | {
      type: "SAVE_OR_DELETE_JOB";
      ClientJobSaveRespondModel: ClientJobResultModel;
    }
  | {
      type: "GET_ALL_CLIENTJOB";
      AllClientJobs: GetAllJobRespondModel;
    }
  | {
      type: "GET_CLIENTJOB";
      ClientJob: GetAllJobRespondModel;
    }
  | {
      type: "SET_PUBLISH_JOB";
      publishJobResult: ClientPersonalInfoResultModel;
    }
  | {
      type: "SET_JOB_STATUS";
      clientJobStatusResul: ClientJobStatusResultModel;
    }
  | {
      type: "GET_DASHBOARD_COUNT";
      ClientDashBoardCount: DashboardCountResultModel;
    }
  | {
      type: "GET_ALL_NOTIFICATION";
      ClientNotification: string[];
    }
  | {
      type: "SEARCHING_FAKE_CANDIDATE";
      FakeCandidateSearchResult: FakeCandidatesearchResult[];
    }
  | {
      type: "VIEW_APPLICATION";
      ClientViewApplication: ClientViewApplicationResultModel;
    }
  | {
      type: "VIEW_APPLICATION_DETAILS";
      ClientViewApplicationDetailsData: ClientViewApplicationDetailsResultModel;
    }
  | {
      type: "CLIENT_CANDIDATE_MOVE";
      ClientCandidateMoveData: ClientCandidateMoveResultModel;
    };

export const clientJobReducer: Reducer<
  IClientJobManagementState,
  IClientJobAction
> = (draft, action): IClientJobManagementState => {
  switch (action.type) {
    case "SAVE_OR_DELETE_JOB":
      draft.ClientJobSaveRespondModel = action.ClientJobSaveRespondModel;
      return draft;
    case "GET_ALL_CLIENTJOB":
      draft.AllClientJobs = action.AllClientJobs;
      return draft;
    case "GET_CLIENTJOB":
      draft.ClientJob = action.ClientJob;
      return draft;
    case "SET_PUBLISH_JOB":
      draft.publishJobResult = action.publishJobResult;
      return draft;
    case "SET_JOB_STATUS":
      draft.clientJobStatusResul = action.clientJobStatusResul;
      return draft;
    case "GET_DASHBOARD_COUNT":
      draft.ClientDashBoardCount = action.ClientDashBoardCount;
      return draft;
    case "GET_ALL_NOTIFICATION":
      draft.ClientNotification = action.ClientNotification;
      return draft;
    case "SEARCHING_FAKE_CANDIDATE":
      draft.FakeCandidateSearchResult = action.FakeCandidateSearchResult;
      return draft;
    case "VIEW_APPLICATION":
      draft.ClientViewApplication = action.ClientViewApplication;
      return draft;
    case "VIEW_APPLICATION_DETAILS":
      draft.ClientViewApplicationDetailsData =
        action.ClientViewApplicationDetailsData;
      return draft;
    case "CLIENT_CANDIDATE_MOVE":
      draft.ClientCandidateMoveData = action.ClientCandidateMoveData;
      return draft;
  }
};

export const useClientJobDispatcher = (): React.Dispatch<IClientJobAction> => {
  const clientJobDispatcher = React.useContext(ClientJobDispatcherContext);
  if (!clientJobDispatcher) {
    throw new Error(
      "You have to provide the ClientJob dispatcher using theClientJobDispatcherContext.Provider in a parent component."
    );
  }
  return clientJobDispatcher;
};

export const useClientJobContext = (): IClientJobManagementState => {
  const clientJobContext = React.useContext(ClientJobStateContext);
  if (!clientJobContext)
    throw new Error(
      "You have to provide the clientJob context using the ClientJobStateContext.Provider in a parent component."
    );
  return clientJobContext;
};

export const SaveClientJob = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveOrDeleteClientJob;
    message.loading("please wait..");
    axios.post(url, query, { headers: header }).then((res) => {
      dispatcher({
        type: "SAVE_OR_DELETE_JOB",
        ClientJobSaveRespondModel: res.data,
      });
      res.data.isSuccess
        ? message.success("successful")
        : message.error(res.data.message);
    });
  } catch (e) {}
};

export const getAllClientjobs = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: GetAllJobRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllClientJob;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_CLIENTJOB",
        AllClientJobs: res.data,
      });
      // res.data.isSuccess;
      // ? message.success("ClientJob added successfully")
      // : message.error(res.data.message);
    });
  } catch (e) {}
};

export const getClientjob = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetClientJobById;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_CLIENTJOB",
        ClientJob: res.data,
      });
      // res.data.isSuccess;
      // ? message.success("ClientJob added successfully")
      // : message.error(res.data.message);
    });
  } catch (e) {}
};

export const setClientPublishJobs = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.PublishClientJob;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_PUBLISH_JOB",
        publishJobResult: res.data,
      });
      // res.data.isSuccess;
      // ? message.success("ClientJob added successfully")
      // : message.error(res.data.message);
    });
  } catch (e) {}
};

export const GetClientJobStatus = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: ClientJobStatusRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.CheckJobStatus;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_JOB_STATUS",
        clientJobStatusResul: res.data,
      });
      // res.data.isSuccess;
      // ? message.success("ClientJob added successfully")
      // : message.error(res.data.message);
    });
  } catch (e) {}
};

export const GetClientDashboardCount = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: DashboardCountRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ClientDashboardCount;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_DASHBOARD_COUNT",
        ClientDashBoardCount: res.data,
      });
      // res.data.isSuccess;
      // ? message.success("ClientJob added successfully")
      // : message.error(res.data.message);
    });
  } catch (e) {}
};

export const GetClientNotification = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: DashboardCountRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ClientNotification;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_NOTIFICATION",
        ClientNotification: res.data,
      });
      // res.data.isSuccess;
      // ? message.success("ClientJob added successfully")
      // : message.error(res.data.message);
    });
  } catch (e) {}
};

export const SearchingFakeCandidate = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: FakeCandidateSearchRequest,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ClientFakeCandidateSearch;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SEARCHING_FAKE_CANDIDATE",
        FakeCandidateSearchResult: res.data,
      });
      // res.data.isSuccess;
      // ? message.success("ClientJob added successfully")
      // : message.error(res.data.message);
    });
  } catch (e) {}
};

export const ClientViewApplicationInfo = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: ClientViewApplicationRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ClientViewApplication;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "VIEW_APPLICATION",
        ClientViewApplication: res.data,
      });
      // res.data.isSuccess;
      // ? message.success("ClientJob added successfully")
      // : message.error(res.data.message);
    });
  } catch (e) {}
};

export const ClientViewApplicationDetailsInfo = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: ClientViewApplicationDetailsRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ClientViewApplicationDetails;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "VIEW_APPLICATION_DETAILS",
        ClientViewApplicationDetailsData: res.data,
      });
      // res.data.isSuccess;
      // ? message.success("ClientJob added successfully")
      // : message.error(res.data.message);
    });
  } catch (e) {}
};

export const MoveClientCandidate = async (
  dispatcher: React.Dispatch<IClientJobAction>,
  query: ClientCandidateMoveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ClientCandidateMove;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "CLIENT_CANDIDATE_MOVE",
        ClientCandidateMoveData: res.data,
      });
      // res.data.isSuccess;
      // ? message.success("ClientJob added successfully")
      // : message.error(res.data.message);
    });
  } catch (e) {}
};
