import { ErrorMessage } from "@hookform/error-message";
import { Popover, Tooltip } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { Controller, useForm } from "react-hook-form";
import {
  Link,
  RouteComponentProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  getTitleWithCategoryId,
  setJobAlertCategoryMapSave,
  setJobAlertSave,
  setJobAlertTypeMapSave,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../action/candidate/JobAlertAction";
import {
  deleteJobBookMarkList,
  getJobBookMarkList,
  saveBookMarkList,
  useJobBookMarkContext,
  useJobBookMarkDispatcher,
} from "../../action/candidate/JobBookMarkAction";
import {
  getDatePostedList,
  useDatePostedContext,
  useDatePostedDispatcher,
} from "../../action/general/DatePostedAction";
import {
  getExperienceList,
  useExperienceContext,
  useExperienceDispatcher,
} from "../../action/general/ExperienceAction";
import {
  getJobTypeList,
  useJobTypeContext,
  useJobTypeDispatcher,
} from "../../action/general/JobTypeAction";
import {
  getNewlyPostedJobList,
  useNewlyPostedJobContext,
  useNewlyPostedJobDispatcher,
} from "../../action/general/NewlyPostedJobAction";
import {
  getSearchListWithOutToken,
  getSearchListWithToken,
  useSearchContext,
  useSearchDispatcher,
} from "../../action/general/SearchAction";
import {
  getJobAppliedCandidateList,
  useJobAppliedContext,
  useJobAppliedDispatcher,
} from "../../action/JobAppliedAction";
import {
  getMatchedJobList,
  useMatchedJobContext,
  useMatchedJobDispatcher,
} from "../../action/MatchedJobAction";
import { useMyProfileContext } from "../../action/MyProfileAction";
import {
  getAllCoverLetterCandidateMap,
  getAllResumeCandidateMap,
} from "../../apis/resumebuilder";
import {
  useCandidateContext,
  useCandidateProfileImageContext,
} from "../../context/candidate/CandidateMyProfile";
import { GlobalSpinnerContext } from "../../context/GlobalSpinner";
import { environment } from "../../environments/environment";
import {
  jobBookMarkDataWithUserId,
  jobBookMarkRequestModel,
  jobBookMarkSaveRequestModel,
} from "../../models/candidate/BookMarkedJob";
import { CoverletterDataResult } from "../../models/candidate/CoverLetter";
import {
  jobAlertCategoryMapRequestModel,
  jobAlertSaveRequestModel,
  jobAlertTitleDropdownResultModel,
  JobAlertTypeMapSaveRequestModel,
} from "../../models/candidate/JobAlert";
import {
  jobAppliedCandidateResultModel,
  jobAppliedCandidateResulViewtModel,
  jobAppliedSaveRequestModel,
} from "../../models/candidate/JobApplied";
import { categoryWithId } from "../../models/candidate/JobCategory";
import {
  jobViewModel,
  matchedJobRequestModel,
  matchedJobViewModel,
} from "../../models/candidate/MatchedJob";
import { ResumeAllList } from "../../models/candidate/Resume";
import { datePostedRequestModel } from "../../models/general/DatePosted";
import { experienceRequestModel } from "../../models/general/Experience";
import { jobTypeRequestmodel } from "../../models/general/JobType";
import { newlyPostedJobRequestModel } from "../../models/general/NewlyPostedJob";
import { searchDataWithOutTokenRequestModel } from "../../models/general/Search";
import AuthService from "../../services/AuthService";
import JobAppliedForm from "../candidate/jobs_Applied/JobAppliedForm";
import SelectOption from "../candidate/my_profile/components/SelectOption";
interface ReactSelectOption {
  value: string;
  label: string;
}

interface IJobSearchComponentProps extends RouteComponentProps {
  jobCategorys: {};
}

interface IJobSearchComponentState {
  jobCategory: string;
  jobList: matchedJobViewModel;
  jobAppliedViewList: jobAppliedCandidateResulViewtModel;
  jobAppliedViewListData: jobViewModel;
  jobListData: jobViewModel[];
  jobListDetails: jobViewModel;
  jobTitle: string;
  location: string;
  type: string[];
  types: string[];
  experiences: string;
  datePost: string;
  jobTypeData: string;
  currentJobId: number;
  filterFlag: boolean;
  tempData: jobAlertTitleDropdownResultModel[];
  jobCategorys: string;
}

const defaultValues = {
  jobCategory: "1",
  jobList: {} as matchedJobViewModel,
  jobListDetails: {} as jobViewModel,
  jobListData: {} as jobViewModel[],
  jobAppliedViewList: {} as jobAppliedCandidateResulViewtModel,
  jobAppliedViewListData: {} as jobViewModel,
  jobTitle: "",
  location: "",
  type: [],
  types: [],
  experiences: "1",
  datePost: "",
  jobTypeData: "",
  currentJobId: 0,
  filterFlag: true,
  tempData: [],
  jobCategorys: "",
};

const JobSearchComponent: React.FC<IJobSearchComponentProps> = (props: any) => {
  const [
    JobSearchComponentState,
    setJobSearchComponentState,
  ] = React.useState<IJobSearchComponentState>(defaultValues);
  // const { jobCategorys } = props;
  const {
    jobListData,
    jobList,
    jobListDetails,
    types,
    datePost,
    jobCategory,
    jobTitle,
    jobTypeData,
    type,
    location,
    experiences,
    jobAppliedViewList,
    jobAppliedViewListData,
    currentJobId,
    filterFlag,
    tempData,
    jobCategorys,
  } = JobSearchComponentState;
  const authorizationToken = AuthService.accessToken;
  const globalSpinner = useContext(GlobalSpinnerContext);

  let jobCategoryData: ReactSelectOption[] = [];
  let jobTypeDatas: ReactSelectOption[] = [];

  const myProfileContext = useMyProfileContext();
  const {
    myProfile,
    loggedUserId,
    profileImage,
    basicInfo,
    myProfileProgressBar,
  } = myProfileContext;

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IJobSearchComponentState>({
    defaultValues,
  });

  const {
    register: register2,
    errors: errors2,
    watch: watch2,
    handleSubmit: handleSubmit2,
    control: control2,
  } = useForm({
    defaultValues,
  });

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
  } = useForm({
    defaultValues,
  });

  const watchAllFields = watch2();
  //console.log(3434, watchAllFields);

  const candidateProfileImageContext = useCandidateProfileImageContext();

  const {
    candidateProfileImage,
    getCandidateProfileImage,
  } = candidateProfileImageContext;

  const searchDispatcher = useSearchDispatcher();
  const searchContext = useSearchContext();
  const { searchWithToken, searchWithOutToken, visible } = searchContext;

  const matchedJobDispatcher = useMatchedJobDispatcher();
  const matchedJobContext = useMatchedJobContext();
  const { matchedJob } = matchedJobContext;

  const newlyPostedJobDispatcher = useNewlyPostedJobDispatcher();
  const newlyPostedJobContext = useNewlyPostedJobContext();
  const { newlyPostedJob } = newlyPostedJobContext;

  const jobTypeDispatcher = useJobTypeDispatcher();
  const jobTypeContext = useJobTypeContext();
  const { jobType } = jobTypeContext;

  const experienceDispatcher = useExperienceDispatcher();
  const experienceContext = useExperienceContext();
  const { experience } = experienceContext;

  const datePostedDispatcher = useDatePostedDispatcher();
  const datePostedContext = useDatePostedContext();
  const { datePosted } = datePostedContext;

  const jobAlertDispatcher = useJobAlertDispatcher();
  const jobAlertContext = useJobAlertContext();
  const {
    jobAlert,
    jobAlertSaveRespond,
    jobAlertTitleDropdownResult,
  } = jobAlertContext;

  const jobAppliedDispatcher = useJobAppliedDispatcher();
  const jobAppliedContext = useJobAppliedContext();
  const {
    jobApplied,
    jobAppliedCandidateResult,
    jobAppliedSaveResultData,
  } = jobAppliedContext;

  const jobBookMarkDispatcher = useJobBookMarkDispatcher();
  const jobBookMarkContext = useJobBookMarkContext();
  const { jobBookMark, jobBookMarkSaveRespond } = jobBookMarkContext;

  const candidateId = loggedUserId;
  const candidateContext = useCandidateContext();
  const { getCandidates, candidateState } = candidateContext;

  const [candidateResumes, setCandidateResumes] = useState({} as ResumeAllList);
  const [currentAlertId, setcurrentAlertId] = useState<number>(0);
  const [isJobAppliedOpen, setIsJobAppliedOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);
  const [isJobTypeOpen, setIsJobTypeOpen] = useState<boolean>(true);
  const [isDatePostedOpen, setIsDatePostedOpen] = useState<boolean>(true);
  const [isExperienceOpen, setExperienceOpen] = useState<boolean>(true);
  const [isIndexChange, setIsIndexChange] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isScrolling, setIsScrolling] = useState<boolean>(true);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [currentJobTitle, setCurrentJobTitle] = useState<string>("");
  const [isHide, setIsHide] = useState<boolean>(false);
  const [name, setName] = useState("");
  const [candidateCoverLetters, setCandidateCoverLetters] = useState(
    {} as CoverletterDataResult
  );
  const handleJobOpen = () => {
    setIsJobAppliedOpen(!isJobAppliedOpen);
  };

  let history = useHistory();
  let { api, id, jobId } = useParams();
  //console.log(7777777777777, jobId);

  // menu close onClick outside
  const menuRef = useRef<any>();

  React.useEffect(() => {
    if (candidateId != null && candidateId != 0)
      (async () => {
        await getCandidates({
          CandidateId: Number(candidateId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "",
          SortOrder: "",
          ShowInactive: false,
        });
      })();
  }, [candidateId]);

  React.useEffect(() => {
    if (candidateState.data != undefined && candidateState.data.length > 0) {
      setName(candidateState.data[0]["firstName"]);
    }
  }, [candidateState]);

  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setIsProfileOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  React.useEffect(() => {
    if (jobAlertSaveRespond != undefined && jobAlertSaveRespond.entityId != 0) {
      (async () => {
        await setJobAlertCategoryMapSave(jobAlertDispatcher, {
          rowId: 0,
          jobAlertCategoryMapId: 0,
          jobCategoryId: parseInt(jobCategory),
          jobAlertId: jobAlertSaveRespond.entityId,
          isActive: true,
        } as jobAlertCategoryMapRequestModel);
      })();
    }
  }, [jobAlertSaveRespond]);

  React.useEffect(() => {
    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  React.useEffect(() => {
    if (jobAlertSaveRespond != undefined && jobAlertSaveRespond.entityId != 0) {
      (async () => {
        await setJobAlertTypeMapSave(jobAlertDispatcher, {
          rowId: 0,
          jobAlertTypeMapId: 0,
          jobTypeId: parseInt(jobTypeData),
          jobAlertId: jobAlertSaveRespond.entityId,
          isActive: true,
        } as JobAlertTypeMapSaveRequestModel);
      })();
    }
  }, [jobAlertSaveRespond]);

  React.useEffect(() => {
    setJobSearchComponentState((JobSearchComponentState) => {
      return {
        ...JobSearchComponentState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);

  // React.useEffect(() => {
  //   if(1){
  //   (async () => {
  //     await getMyProfileSelectBoxList(myProfileDispatcher)

  //   })();
  // }
  // }, [])

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getJobTypeList(jobTypeDispatcher, {
          page: 1,
          pageSize: 10,
          searchTerm: "",
          sortOrder: "",
        } as jobTypeRequestmodel);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getExperienceList(experienceDispatcher, {
          page: 1,
          pageSize: 10,
          searchTerm: "",
          sortOrder: "",
        } as experienceRequestModel);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getDatePostedList(datePostedDispatcher, {
          page: 1,
          pageSize: 10,
          searchTerm: "",
          sortOrder: "",
        } as datePostedRequestModel);
      })();
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      await getNewlyPostedJobList(newlyPostedJobDispatcher, {
        Page: 1,
        PageSize: 60,
        SearchTerm: "",
        SortOrder: "",
      } as newlyPostedJobRequestModel);
    })();
  }, []);

  React.useEffect(() => {
    //console.log(11111, loggedUserId);
    if (api === "a") {
      if (authorizationToken != null && loggedUserId != 0) {
        //console.log(111111111111111111111111111);
        (async () => {
          await getJobAppliedCandidateList(
            jobAppliedDispatcher,
            {
              candidateId: loggedUserId,
              jobAppliedId: 0,
              isActive: true,
              jobId: 0,
              rowId: 0,
            } as jobAppliedSaveRequestModel,
            authorizationToken
          );
        })();
      }
    }
    if (api === "b") {
      if (authorizationToken != null && loggedUserId != 0) {
        //console.log(22222222222222222222222);
        (async () => {
          await getJobBookMarkList(
            jobBookMarkDispatcher,
            {
              candidateId: loggedUserId,
              page: 1,
              pageSize: 60,
              searchTerm: "",
              showInactive: false,
              sortOrder: "",
            } as jobBookMarkRequestModel,
            authorizationToken
          );
        })();
      }
    }
    if (api === "m") {
      if (authorizationToken != null && loggedUserId != 0) {
        //console.log(22222222222222222222222);
        (async () => {
          await getMatchedJobList(
            matchedJobDispatcher,
            {
              CandidateId: loggedUserId,
              DigiDocumentTypeId: 0,
              PageIndex: 1,
              PageSize: 50,
              ShowInactive: false,
            } as matchedJobRequestModel,
            authorizationToken
          );
        })();
      }
    }
  }, [id, jobBookMarkSaveRespond, loggedUserId]);

  React.useEffect(() => {
    if (api === undefined) {
      setJobSearchComponentState({
        ...JobSearchComponentState,
        jobList:
          authorizationToken != null
            ? searchWithToken.data != undefined &&
              searchWithToken.data != undefined
              ? searchWithToken
              : // : newlyPostedJob
              jobId == "0"
              ? newlyPostedJob
              : // : matchedJob
                newlyPostedJob
            : // : matchedJob
            searchWithOutToken.data != undefined &&
              searchWithOutToken.data != undefined
            ? searchWithOutToken
            : newlyPostedJob,
      });
    } else {
      if (api === "a") {
        setJobSearchComponentState({
          ...JobSearchComponentState,
          jobAppliedViewList:
            authorizationToken != null
              ? jobAppliedCandidateResult != undefined &&
                jobAppliedCandidateResult.data != undefined
                ? jobAppliedCandidateResult
                : ({} as jobAppliedCandidateResulViewtModel)
              : ({} as jobAppliedCandidateResulViewtModel),
        });
      }

      if (api === "b") {
        setJobSearchComponentState({
          ...JobSearchComponentState,
          jobAppliedViewList:
            authorizationToken != null
              ? jobBookMark != undefined && jobBookMark.data != undefined
                ? jobBookMark
                : ({} as jobAppliedCandidateResulViewtModel)
              : ({} as jobAppliedCandidateResulViewtModel),
        });
      }
      if (api === "m") {
        setJobSearchComponentState({
          ...JobSearchComponentState,
          jobAppliedViewList:
            authorizationToken != null
              ? matchedJob != undefined && matchedJob.data != undefined
                ? matchedJob
                : ({} as jobAppliedCandidateResulViewtModel)
              : ({} as jobAppliedCandidateResulViewtModel),
        });
      }
    }
  }, [
    jobBookMarkSaveRespond,
    searchWithToken,
    searchWithOutToken,
    matchedJob,
    jobAppliedCandidateResult,
    newlyPostedJob,
  ]);

  // same api for render problem

  React.useEffect(() => {
    if (api === "a") {
      if (authorizationToken != null && loggedUserId != 0) {
        //console.log(111111111111111111111111111);
        (async () => {
          await getJobAppliedCandidateList(
            jobAppliedDispatcher,
            {
              candidateId: loggedUserId,
              jobAppliedId: 0,
              isActive: true,
              jobId: 0,
              rowId: 0,
            } as jobAppliedSaveRequestModel,
            authorizationToken
          );
        })();
      }
    }
    if (api === "b") {
      if (authorizationToken != null && loggedUserId != 0) {
        //console.log(22222222222222222222222);
        (async () => {
          await getJobBookMarkList(
            jobBookMarkDispatcher,
            {
              candidateId: loggedUserId,
              page: 1,
              pageSize: 60,
              searchTerm: "",
              showInactive: false,
              sortOrder: "",
            } as jobBookMarkRequestModel,
            authorizationToken
          );
        })();
      }
    }
  }, [id, jobBookMarkSaveRespond]);

  //console.log(565, jobAppliedSaveResultData);

  React.useEffect(() => {
    if (api === undefined) {
      if (jobList.total < 1) {
        setJobSearchComponentState({
          ...JobSearchComponentState,
          jobListDetails: {} as jobViewModel,
        });
      }
      if (jobList.data != undefined && jobList.total > 0) {
        if (jobId !== "0") {
          // for clickking some Shared link
          history.push(`${parseInt(jobId)}`);
          setJobSearchComponentState({
            ...JobSearchComponentState,
            jobListDetails: jobList.data.filter(
              (data) => data.rowId == parseInt(jobId)
            )[0],
          });
        } else {
          //  on Searching on index page
          //console.log("jobList.data", jobList);
          history.push(`${jobList.data[isIndexChange].rowId}`);
          setJobSearchComponentState({
            ...JobSearchComponentState,
            jobListDetails: jobList.data[isIndexChange],
          });
        }
      }
    } else {
      if (jobAppliedViewList.data != undefined)
        setJobSearchComponentState({
          ...JobSearchComponentState,
          jobAppliedViewListData: jobAppliedViewList.data.filter(
            (data) => data.jobId == id
          )[0],
        });
    }
  }, [jobList.data, jobAppliedViewList, id]);

  const handleJobListChange = (i: any) => {
    if (api === undefined) {
      history.push(`${jobList.data[i].rowId}`);
      setJobSearchComponentState({
        ...JobSearchComponentState,
        jobListDetails: jobList.data[i],
      });
    } else {
      history.push(`${i}`);
      setJobSearchComponentState({
        ...JobSearchComponentState,
        jobAppliedViewListData: jobAppliedViewList.data.filter(
          (data) => data.jobId == i
        )[0],
      });
    }
    setIsIndexChange(i);
  };

  const handleJobTypeChange = (event: any) => {
    if (event.target.checked)
      setJobSearchComponentState({
        ...JobSearchComponentState,
        types: types.concat(event.target.value),
      });
    else {
      setJobSearchComponentState({
        ...JobSearchComponentState,
        types: types.filter((data) => data != event.target.value),
      });
    }
  };

  const handleExperienceChange = (value: any) => {
    setJobSearchComponentState({
      ...JobSearchComponentState,
      experiences: value,
    });
  };

  const handleDatePosteChange = (value: any) => {
    setJobSearchComponentState({
      ...JobSearchComponentState,
      datePost: value,
    });
  };
  const onSubmit = (data: any) => {
    props.history.push("/job_search/0");
    setJobSearchComponentState({
      ...JobSearchComponentState,
      location: data.location,
      experiences: data.experience,
      jobTitle: data.jobTitle,
      type: [data.types],
    });

    authorizationToken != null
      ? getSearchListWithToken(
          searchDispatcher,
          {
            expereince: [],
            location: [data.location],
            title: [data.jobTitle],
            type: data.types != null ? [data.types] : [],
            lastDays: [],
            candidateId: loggedUserId,
            pageIndex: 1,
            pageSize: 60,
            showInactive: false,
          } as searchDataWithOutTokenRequestModel,
          authorizationToken
        )
      : getSearchListWithOutToken(searchDispatcher, {
          expereince: [],
          location: [data.location],
          title: [data.jobTitle],
          type: data.types != null ? [data.types] : [],
          lastDays: [],
          candidateId: 0,
          pageIndex: 1,
          pageSize: 60,
          showInactive: false,
        } as searchDataWithOutTokenRequestModel);
  };

  useEffect(() => {
    if (candidateId) {
      getAllResumeCandidateMap({
        CandidateId: candidateId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          ////console.log(res.data);
          setCandidateResumes(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, [candidateId]);

  useEffect(() => {
    if (candidateId) {
      getAllCoverLetterCandidateMap({
        CandidateId: candidateId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          ////console.log(res.data);
          setCandidateCoverLetters(res.data);
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [candidateId]);

  const handleJobApplied = () => {
    // saveJobApplied(jobAppliedDispatcher, {
    //   isActive: true,
    //   jobId: jobId,
    //   candidateId: loggedUserId,
    //   jobAppliedId: 0,
    //   rowId: 0,
    // } as jobAppliedSaveRequestModel);
  };

  const handleJobBookMarked = (jobId: number, jobName: string) => {
    if (authorizationToken != null) {
      saveBookMarkList(
        jobBookMarkDispatcher,
        {
          candidateId: loggedUserId,
          jobBookmarkedId: 0,
          jobId: jobId,
          isActive: true,
          rowId: 0,
        } as jobBookMarkSaveRequestModel,
        authorizationToken
      );
      setCurrentJobTitle(jobName);
    }
  };

  React.useEffect(() => {
    if (
      (authorizationToken != null && jobBookMarkSaveRespond.isSuccess) ||
      (authorizationToken != null && jobAppliedSaveResultData.isSuccess)
    ) {
      (async () => {
        await getSearchListWithToken(
          searchDispatcher,
          {
            expereince: [],
            location: [],
            title: [currentJobTitle.split(" ")[0]],
            type: [],
            lastDays: [],
            candidateId: loggedUserId,
            pageIndex: 1,
            pageSize: 60,
            showInactive: false,
          } as searchDataWithOutTokenRequestModel,
          authorizationToken
        );
      })();
    }
  }, [jobBookMarkSaveRespond, jobAppliedSaveResultData]);

  // const handleAutoSearch = (title: string) => {
  //   if (authorizationToken != null) {
  //     getSearchListWithToken(
  //       searchDispatcher,
  //       {
  //         expereince: [],
  //         location: [],
  //         title: [title.split(" ")[0]],
  //         type: [],
  //         lastDays: [],
  //         pageIndex: 1,
  //         pageSize: 60,
  //         showInactive: false,
  //       } as searchDataWithOutTokenRequestModel,
  //       authorizationToken
  //     );
  //   }
  // };

  const [disabled, setDisabled] = useState<boolean>(true);

  // const handleJobAlert = (data: any) => {
  //   setJobSearchComponentState({
  //     ...JobSearchComponentState,
  //     jobCategorys: data.jobCategorys,
  //     jobTypeData: data.jobTypeData,
  //   });

  //   // if (authorizationToken != null) {
  //   //   (async () => {
  //   //     await setJobAlertSave(
  //   //       jobAlertDispatcher,
  //   //       {
  //   //         rowId: 0,
  //   //         jobAlertId: 0,
  //   //         keywords: "",
  //   //         totalExperience: "",
  //   //         locationId: "",
  //   //         alertTitle: data.jobTitle,
  //   //         salaryFrom: 0,
  //   //         userId: loggedUserId,
  //   //         isActive: (data.radio = "1" ? true : false),
  //   //       } as jobAlertSaveRequestModel,
  //   //       authorizationToken
  //   //     );
  //   //   })();
  //   //   setIsOpen(!isOpen);
  //   // }
  // };

  // Scrolling fuction

  const handleScrollStop = () => {
    setIsScrolling(jobList.hasNext);

    if (jobList.hasNext && isScrolling) {
      setJobSearchComponentState({
        ...JobSearchComponentState,
        jobListData: [],
      });

      if (searchWithToken.data != undefined) {
      }

      setCurrentPage(currentPage + 1);

      authorizationToken != null
        ? searchWithToken.data != undefined && searchWithToken.data != undefined
          ? getSearchListWithToken(
              searchDispatcher,
              {
                expereince: [experiences],
                location: [location],
                title: [jobTitle],
                type: types != null ? types : [types],
                lastDays: [0],
                candidateId: loggedUserId,
                pageIndex: currentPage + 1,
                pageSize: 10,
                showInactive: false,
              } as searchDataWithOutTokenRequestModel,
              authorizationToken
            )
          : getMatchedJobList(
              matchedJobDispatcher,
              {
                CandidateId: loggedUserId,
                DigiDocumentTypeId: 0,
                PageIndex: 1,
                PageSize: 10,
                ShowInactive: false,
              } as matchedJobRequestModel,
              authorizationToken
            )
        : searchWithOutToken.data != undefined &&
          searchWithOutToken.data != undefined
        ? getSearchListWithOutToken(searchDispatcher, {
            expereince: [experiences],
            location: [location],
            title: [jobTitle],
            type: types != null ? types : [types],
            lastDays: [0],
            pageIndex: currentPage + 1,
            pageSize: 10,
            showInactive: false,
          } as searchDataWithOutTokenRequestModel)
        : getNewlyPostedJobList(newlyPostedJobDispatcher, {
            Page: currentPage + 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
          } as newlyPostedJobRequestModel);
      setIsScrolling(false);
    }
  };

  const handleDeleteBookMarked = (jobId: number, jobName: string) => {
    if (authorizationToken != null)
      deleteJobBookMarkList(
        jobBookMarkDispatcher,
        {
          jobId: jobId,
          candidateId: loggedUserId,
        } as jobBookMarkDataWithUserId,
        authorizationToken
      );
    setCurrentJobTitle(jobName);
  };

  const onLogout = () => {
    AuthService.logout();
    props.history.push("/");
  };

  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  const handleShareButtonClick = (id: any, value: any) => {
    //console.log("jobId", id);
    setJobSearchComponentState({
      ...JobSearchComponentState,
      currentJobId: id,
      filterFlag: value,
    });
    setIsShareOpen(!isShareOpen);
  };

  const handleJobAlertCreateClick = () => {
    setIsOpen(!isOpen);
    setcurrentAlertId(0);
  };

  React.useEffect(() => {
    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  const handleJobAlert = (data: any) => {
    setJobSearchComponentState({
      ...JobSearchComponentState,
      jobCategorys: data.jobCategorys,
      jobTypeData: data.jobTypeData,
    });

    if (authorizationToken != null) {
      (async () => {
        await setJobAlertSave(
          jobAlertDispatcher,
          {
            RowId: currentAlertId,
            JobAlertId: 1,
            JobCategoryId: parseInt(data.jobCategorys),
            Location: data.location,
            jobTitle: data.jobTitle,
            JobTypeId: parseInt(data.jobTypeData),
            JobTitleId: 0,
            Active: data.radio === "1" ? true : false,
            CandidateId: loggedUserId,
            IsActive: true,
          } as jobAlertSaveRequestModel,
          authorizationToken
        );
      })();
      setIsOpen(!isOpen);
    }
  };

  const handleJobApplayClick = (id: any) => {
    setJobSearchComponentState({
      ...JobSearchComponentState,
      currentJobId: id,
    });
    handleJobOpen();
  };

  const facebookData =
    "http://careerappui.clubactive.in/#/job_search/${currentJobId}" + api ===
    undefined
      ? jobListDetails != undefined
        ? jobListDetails.description
        : ""
      : jobAppliedViewListData != undefined
      ? jobAppliedViewListData.description
      : "";

  //console.log(888888888, candidateResumes);
  //console.log(99999999, tempData);
  return (
    <>
      {/* Header Start */}

      <div className="job_search_header">
        <div className="container-fluid">
          <div className="col-sm-6">
            <a
              onClick={() => {
                history.push("/");
              }}
            >
              <img
                src={require("../../images/logo_login.png")}
                // src="images/logo_login.png"
              />
            </a>
          </div>
          <div className="col-sm-6">
            {authorizationToken ? (
              <>
                <div
                  className="action_btn1"
                  onClick={() => {
                    setIsProfileOpen(!isProfileOpen);
                  }}
                >
                  <button type="button" className="jobs_action">
                    {/* {basicInfo.fullName != undefined ? basicInfo.fullName : ""}{" "} */}
                    {name}
                    <i className="fa fa-angle-down" aria-hidden="true" />
                  </button>
                  <div className="login_jobs">
                    {/* <img
                      src={
                        profileImage != null && profileImage.total > 0
                          ? `https://careerapp.azurewebsites.net/Upload/ProfileImage/${profileImage.data[0].imageUrl}`
                          : require("../../images/profileDefault1.jpg")
                      }
                    /> */}
                    {candidateProfileImage.data.length > 0 ? (
                      <img
                        className="img-responsive"
                        src={
                          environment.baseUrl +
                          `/Upload/ProfileImage/${candidateProfileImage.data[0]["imageUrl"]}`
                        }
                        alt=""
                      />
                    ) : (
                      <img
                        className="img-responsive"
                        src={require("../../images/profileDefault1.jpg")}
                      ></img>
                    )}
                  </div>

                  <div
                    className="jobs_action_dp"
                    tabIndex={-1}
                    style={{ display: `${isProfileOpen ? "block" : "none"}` }}
                  >
                    <div className="action_sec">
                      <ul ref={menuRef}>
                        <li>
                          <Link to="/candidate" className="active">
                            {" "}
                            <i className="fa fa-th-large" aria-hidden="true" />
                            Dashboard{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/candidate/jobs-applied" className="active">
                            {" "}
                            <i className="fa fa-briefcase" aria-hidden="true" />
                            Job Applied{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/candidate/digilocker/3" className="active">
                            {" "}
                            <i className="fa fa-lock" aria-hidden="true" />
                            {name.split(" ")[0]}
                            's DigiLocker{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/candidate/my-resume" className="active">
                            {" "}
                            <i className="fa fa-files-o" aria-hidden="true" />
                            My Resumes{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/candidate/messages" className="active">
                            {" "}
                            <i
                              className="fa fa-commenting-o"
                              aria-hidden="true"
                            />
                            Messages{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/candidate/saved-jobs" className="active">
                            {" "}
                            <i className="fa fa-suitcase" aria-hidden="true" />
                            Saved Jobs{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/candidate/my-profile" className="active">
                            {" "}
                            <i className="fa fa-user-o" aria-hidden="true" />
                            My Profile{" "}
                          </Link>
                        </li>
                        {/* <li>
                                <Link to="/candidate">DashBoard</Link>
                              </li> */}
                        <li>
                          <a onClick={onLogout} className="_cursor-pointer">
                            <i
                              className="fa fa-power-off"
                              aria-hidden="true"
                            ></i>
                            Logout
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="top_links">
                  <>
                    <Link to="/login">Login</Link>
                    <Link to="/registration">Register</Link>
                  </>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Header Ends */}

      {/* --------------------------------------------------- */}

      {/* Content start  */}

      <div className="job_search_page">
        <div className=" container-fluid">
          <div>
            <div className="col-sm-12">
              <div className="job_search_area">
                <div className="row">
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        <input
                          name="jobTitle"
                          ref={register({ required: "Required" })}
                          type="text"
                          className="form-control"
                          placeholder="Job Title"
                        />

                        <div className="search_icons">
                          <img
                            src={require("../../images/search_icon.png")}
                            // src="images/search_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        {/* <span className="select-search">
                      <Controller
                  control={control}
                  name="location"
                  
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption  values={location} disabled={false} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                />     
                        </span> */}

                        <input
                          name="location"
                          ref={register({ required: false })}
                          type="text"
                          className="form-control"
                          placeholder="location"
                        />
                        <div className="search_icons">
                          <img
                            src={require("../../images/locattion_icon.png")}
                            // src="images/locattion_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        <span className="select-search">
                          <Controller
                            control={control}
                            name="jobTypeData"
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  myProfile.jobTypes != undefined
                                    ? myProfile.jobTypes.map((e) => {
                                        return {
                                          value: e["value"],
                                          label: e["caption"],
                                        };
                                      })
                                    : jobTypeDatas
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                              />
                            )}
                          />
                        </span>
                        <div className="search_icons">
                          <img
                            src={require("../../images/type_icon.png")}
                            //  src="images/type_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        {/* <span className="select-search">
                      <Controller
                  control={control}
                  name="experiences"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption values={experiences} disabled={false} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                /> 
                  </span>*/}

                        <input
                          name="experience"
                          ref={register({ required: false })}
                          type="text"
                          className="form-control"
                          placeholder="experience"
                        />
                        <div className="search_icons">
                          <img src={require("../../images/exp_icon.png")} />
                        </div>
                      </div>
                    </div>
                    <div className="search_button">
                      <div className="search">
                        <button className="CustomButtonCss" type="submit">
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="job_search_left">
                <div
                  className="create_job_alert"
                  style={{ display: authorizationToken != null ? "" : "none" }}
                >
                  <div className="create_job_alert_head">Create Job Alert</div>
                  <div className="create_job_alert_con{">
                    Create job alerts and get notifications for jobs.
                  </div>
                  <div className="create_job_alert_btn">
                    <button
                      className="create_job_alert_button"
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                    >
                      Job Alert
                    </button>
                  </div>
                </div>

                <div className="accordian_main">
                  <div className="accordian_tab">
                    <h1
                      className={`${isJobTypeOpen ? "active" : ""}`}
                      onClick={() => {
                        setIsJobTypeOpen(!isJobTypeOpen);
                      }}
                    >
                      Job Type <div className=" arrow" />
                    </h1>

                    <div
                      className="acc_con"
                      style={{ display: `${isJobTypeOpen ? "block" : "none"}` }}
                    >
                      {!jobType || !jobType.data || jobType.data.length <= 0 ? (
                        <h1>loading..</h1>
                      ) : (
                        jobType.data.map((jobTypeData, index) => {
                          return (
                            <div key={index}>
                              <input
                                onChange={(e: any) => handleJobTypeChange(e)}
                                value={`${jobTypeData.rowId.toString()}`}
                                id="checkbox"
                                type="checkbox"
                                name="checkbox"
                              />
                              <label htmlFor="checkbox2">
                                <span />
                                {jobTypeData.title}{" "}
                              </label>
                              <div className="jobs_notification">20</div>
                            </div>
                          );
                        })
                      )}
                    </div>

                    <h1
                      className={`${isExperienceOpen ? "active" : ""}`}
                      onClick={() => {
                        setExperienceOpen(!isExperienceOpen);
                      }}
                    >
                      Experience <div className=" arrow" />
                    </h1>
                    <div
                      className="acc_con"
                      style={{
                        display: `${isExperienceOpen ? "block" : "none"}`,
                      }}
                    >
                      {!experience ||
                      !experience.data ||
                      experience.data.length <= 0 ? (
                        <h1>loading..</h1>
                      ) : (
                        experience.data.map((exp, index) => {
                          return (
                            <div key={index}>
                              <input
                                onClick={() =>
                                  handleExperienceChange(exp.title)
                                }
                                id="radio1"
                                type="radio"
                                name="radio"
                                defaultValue={1}
                                defaultChecked
                              />
                              <label htmlFor="radio1">
                                <span>
                                  <span />
                                </span>{" "}
                                {exp.title}{" "}
                              </label>
                              <div className="jobs_notification">20</div>
                            </div>
                          );
                        })
                      )}

                      {/* <div><input id="radio1" type="radio" name="radio" defaultValue={1} defaultChecked /><label htmlFor="radio1"><span><span /></span> 0 - 2 years</label>
                        <div className="jobs_notification">20</div>
                      </div> 
                      <div><input id="radio1" type="radio" name="radio" defaultValue={1} defaultChecked /><label htmlFor="radio1"><span><span /></span> 2 - 5 years </label>
                        <div className="jobs_notification">20</div>
                      </div> 
                      <div><input id="radio1" type="radio" name="radio" defaultValue={1} defaultChecked /><label htmlFor="radio1"><span><span /></span> 5 - 10 years</label>
                        <div className="jobs_notification">20</div>
                      </div> 
                      <div><input id="radio1" type="radio" name="radio" defaultValue={1} defaultChecked /><label htmlFor="radio1"><span><span /></span> &gt; 10 years </label>
                        <div className="jobs_notification">20</div>
                      </div>   */}
                    </div>
                    <h1
                      className={`${isDatePostedOpen ? "active" : ""}`}
                      onClick={() => {
                        setIsDatePostedOpen(!isDatePostedOpen);
                      }}
                    >
                      Date Posted <div className=" arrow" />
                    </h1>
                    <div
                      className="acc_con"
                      style={{
                        display: `${isDatePostedOpen ? "block" : "none"}`,
                      }}
                    >
                      {!datePosted ||
                      !datePosted.data ||
                      datePosted.data.length <= 0 ? (
                        <h1>loading..</h1>
                      ) : (
                        datePosted.data.map((date, index) => {
                          return (
                            <div key={index}>
                              <input
                                onClick={() => handleDatePosteChange(date.day)}
                                id="radio1"
                                type="radio"
                                name="radio"
                                defaultValue={1}
                                defaultChecked
                              />
                              <label htmlFor="radio1">
                                <span>
                                  <span />
                                </span>{" "}
                                Last {date.day} days{" "}
                              </label>
                              <div className="jobs_notification">20</div>
                            </div>
                          );
                        })
                      )}

                      {/* <div><input id="radio1" type="radio" name="radio" defaultValue={1} defaultChecked /><label htmlFor="radio1"><span><span /></span> Last 15 days</label>
                        <div className="jobs_notification">20</div>
                      </div> 
                      <div><input id="radio1" type="radio" name="radio" defaultValue={1} defaultChecked /><label htmlFor="radio1"><span><span /></span> Last 30 days</label>
                        <div className="jobs_notification">20</div>
                      </div> 
                      <div><input id="radio1" type="radio" name="radio" defaultValue={1} defaultChecked /><label htmlFor="radio1"><span><span /></span> Last 6 months </label>
                        <div className="jobs_notification">20</div>
                      </div> 
                      <div><input id="radio1" type="radio" name="radio" defaultValue={1} defaultChecked /><label htmlFor="radio1"><span><span /></span> &gt; Last 6 months </label>
                        <div className="jobs_notification">20</div>
                      </div>  */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="job_search_right">
                <div
                  id="verticalTab"
                  className="jobsearch-tabs  resp-vtabs"
                  style={{ display: "block", width: "100%", margin: "0px" }}
                >
                  <ul className="resp-tabs-list ">
                    <div
                      id="jobs_searh1"
                      style={{ overflow: "hidden", outline: "none" }}
                      tabIndex={0}
                    >
                      {/* maping */}

                      <Scrollbars
                        style={{ height: 700 }}
                        //  onScrollStop={()=>handleScrollStop()}
                        autoHide
                        renderThumbVertical={({ style, ...props }) => (
                          <div
                            {...props}
                            style={{
                              ...style,
                              position: "relative",
                              display: "block",
                              width: "5px",
                              cursor: "pointer",
                              borderRadius: "inherit",
                              backgroundColor: "rgb(73, 69, 69)",
                              height: "115px",
                            }}
                          />
                        )}
                      >
                        {/* <InfiniteScroll
dataLength={jobList.data!=undefined?jobList.data.length:0}
next={handleScrollStop}
hasMore={true}
loader={<h4>Loading...</h4>}
> */}

                        {
                          api === undefined ? (
                            //////////////////////     ///////////  //////////////////////////////////////////////
                            jobList.data != undefined ? (
                              jobList.data.length > 0 ? (
                                jobList.data.map((job, i) => {
                                  //console.log("Id", i);
                                  if (jobList.data.length > 0) {
                                    return (
                                      <Popover
                                        key={i}
                                        placement="right"
                                        content={<>{job.description}</>}
                                        title={job.title}
                                      >
                                        <li
                                          onClick={() => {
                                            handleJobListChange(i);
                                          }}
                                          className={`resp-tab-item`}
                                          style={
                                            job.rowId === parseInt(jobId)
                                              ? {
                                                  backgroundColor:
                                                    "cornflowerblue",
                                                }
                                              : { backgroundColor: "" }
                                          }
                                          aria-controls="tab_item-0"
                                          role="tab"
                                        >
                                          <div className="job_search_icon">
                                            <img
                                              src={require("../../images/designer_icon.png")}
                                              width={52}
                                              height={52}
                                            />
                                          </div>
                                          <div className="job_search_con">
                                            <div className="job_search_con_head">
                                              {job.title}
                                            </div>
                                            <div className="job_search_con_con">
                                              <i
                                                className="fa fa-map-marker"
                                                aria-hidden="true"
                                              />{" "}
                                              {job.location}
                                            </div>
                                          </div>
                                          <div className="job_search_social">
                                            <div>
                                              <a
                                                onClick={() =>
                                                  handleShareButtonClick(
                                                    job.rowId,
                                                    true
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa fa-share-alt"
                                                  aria-hidden="true"
                                                />
                                              </a>
                                            </div>
                                            <div>
                                              {authorizationToken != null ? (
                                                job.isBookmarked ? (
                                                  <i
                                                    onClick={() =>
                                                      handleDeleteBookMarked(
                                                        api != undefined
                                                          ? job.jobId
                                                          : job.rowId,
                                                        job.title
                                                      )
                                                    }
                                                    className="fa fa-star"
                                                    aria-hidden="true"
                                                    style={{
                                                      color: "greenyellow",
                                                    }}
                                                  />
                                                ) : (
                                                  <i
                                                    onClick={() =>
                                                      handleJobBookMarked(
                                                        api != undefined
                                                          ? job.jobId
                                                          : job.rowId,
                                                        job.title
                                                      )
                                                    }
                                                    className="fa fa-star-o"
                                                    aria-hidden="true"
                                                  />
                                                )
                                              ) : (
                                                <Link to="/login" />
                                              )}
                                            </div>
                                          </div>
                                          <div className="clearfix" />
                                          <div className="job_fulltime">
                                            {job.jobType}
                                          </div>
                                          <div className="job_search_exp">
                                            {job.experience}
                                          </div>
                                          <div className="job_search_post">
                                            {" "}
                                            {job.postedDate}
                                          </div>
                                        </li>
                                      </Popover>
                                    );
                                  } else {
                                    return (
                                      <h1
                                        style={{
                                          fontSize: "3em",
                                          fontWeight: 300,
                                          color: "#726e71",
                                        }}
                                      >
                                        No result for your search criteria
                                      </h1>
                                    );
                                  }
                                })
                              ) : (
                                <h1
                                  style={{
                                    fontSize: "3em",
                                    fontWeight: 300,
                                    color: "#726e71",
                                  }}
                                >
                                  No result for your search criteria
                                </h1>
                              )
                            ) : (
                              <h1>loading..</h1>
                            )
                          ) : (
                            // ///////////////////////////////////////////////////////////////////
                            <>
                              {/* {jobAppliedViewList.data != undefined ? (
                                jobAppliedViewList.data
                                  .filter((data) => data.rowId == id)
                                  .map((job, i) => {
                                    return (
                                      <li
                                        key={i}
                                        onClick={() => {
                                          handleJobListChange(job.rowId);
                                        }}
                                        className={`resp-tab-item ${
                                          job.rowId == id
                                            ? "resp-tab-active-job-data"
                                            : ""
                                        } `}
                                        aria-controls="tab_item-0"
                                        role="tab"
                                      >
                                        <div className="job_search_icon">
                                          <img
                                            src={require("../../images/designer_icon.png")}
                                            width={52}
                                            height={52}
                                          />
                                        </div>
                                        <div className="job_search_con">
                                          <div className="job_search_con_head">
                                            {job.title}
                                          </div>
                                          <div className="job_search_con_con">
                                            <i
                                              className="fa fa-map-marker"
                                              aria-hidden="true"
                                            />{" "}
                                            Abudhabi
                                          </div>
                                        </div>
                                        <div className="job_search_social">
                                          <div>
                                            <i
                                              className="fa fa-share-alt"
                                              aria-hidden="true"
                                            />
                                          </div>
                                          <div>
                                            {job.isBookmarked ? (
                                              <i
                                                // onClick={() =>
                                                //   handleDeleteBookMarked(
                                                //     Job.jobId
                                                //   )
                                                // }
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <i
                                                // onClick={() =>
                                                //   handleSaveJobBookMarked(
                                                //     Job.jobId
                                                //   )
                                                // }
                                                className="fa fa-star-o"
                                                aria-hidden="true"
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="clearfix" />
                                        <div className="job_fulltime">
                                          {job.jobType}
                                        </div>
                                        <div className="job_search_exp">
                                          {job.experience}
                                        </div>
                                        <div className="job_search_post">
                                          {" "}
                                          {job.postedDate}
                                        </div>
                                      </li>
                                    );
                                  })
                              ) : (
                                <h1>loding..</h1>
                              )} */}
                              {jobAppliedViewList.data != undefined ? (
                                jobAppliedViewList.data
                                  // .filter((data) => data.rowId != id)
                                  .map((job, i) => {
                                    //console.log("Idsecond", i);

                                    return (
                                      <li
                                        key={i}
                                        onClick={() => {
                                          handleJobListChange(
                                            api != undefined
                                              ? job.jobId
                                              : job.rowId
                                          );
                                        }}
                                        className={`resp-tab-item ${
                                          api !== undefined
                                            ? job.rowId == id
                                              ? "resp-tab-active"
                                              : ""
                                            : job.rowId == id
                                            ? "resp-tab-active"
                                            : "1"
                                        } `}
                                        aria-controls="tab_item-0"
                                        role="tab"
                                      >
                                        <div className="job_search_icon">
                                          <img
                                            src={require("../../images/designer_icon.png")}
                                            width={52}
                                            height={52}
                                          />
                                        </div>
                                        <div className="job_search_con">
                                          <div className="job_search_con_head">
                                            {job.title}
                                          </div>
                                          <div className="job_search_con_con">
                                            <i
                                              className="fa fa-map-marker"
                                              aria-hidden="true"
                                            />{" "}
                                            {job.location}
                                          </div>
                                        </div>
                                        <div className="job_search_social">
                                          <div>
                                            <i
                                              onClick={() =>
                                                handleShareButtonClick(
                                                  job.rowId,
                                                  true
                                                )
                                              }
                                              className="fa fa-share-alt"
                                              aria-hidden="true"
                                            />
                                          </div>
                                          <div>
                                            {job.isBookmarked ? (
                                              <i
                                                onClick={() =>
                                                  handleDeleteBookMarked(
                                                    api != undefined
                                                      ? job.jobId
                                                      : job.rowId,
                                                    job.title
                                                  )
                                                }
                                                className="fa fa-star"
                                                aria-hidden="true"
                                                style={{ color: "greenyellow" }}
                                              />
                                            ) : (
                                              <i
                                                onClick={() =>
                                                  handleJobBookMarked(
                                                    api != undefined
                                                      ? job.jobId
                                                      : job.rowId,
                                                    job.title
                                                  )
                                                }
                                                className="fa fa-star-o"
                                                aria-hidden="true"
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="clearfix" />
                                        <div className="job_fulltime">
                                          {job.jobType}
                                        </div>
                                        <div className="job_search_exp">
                                          {job.experience}
                                        </div>
                                        <div className="job_search_post">
                                          {" "}
                                          {job.postedDate}
                                        </div>
                                      </li>
                                    );
                                  })
                              ) : (
                                <h1>loding..</h1>
                              )}{" "}
                            </>
                          )
                          //////////////////////////////////////////////////////////////////////////////////////

                          // globalSpinner.showSpinner()
                        }
                        {/* </InfiniteScroll> */}
                      </Scrollbars>
                    </div>
                  </ul>
                  <div className="resp-tabs-container jobsearch-tabs-content">
                    {/*----------------Start-------------*/}
                    <Scrollbars
                      style={{ height: 700 }}
                      autoHide
                      renderThumbVertical={({ style, ...props }) => (
                        <div
                          {...props}
                          style={{
                            ...style,
                            position: "relative",
                            display: "block",
                            width: "5px",
                            cursor: "pointer",
                            borderRadius: "inherit",
                            backgroundColor: "rgb(73, 69, 69)",
                            height: "115px",
                          }}
                        />
                      )}
                    >
                      {/* JobDetaiis Section */}
                      {api === undefined ? (
                        <>
                          {jobListDetails != undefined ? (
                            jobListDetails.title != undefined ? (
                              <>
                                <h2
                                  className="resp-accordion resp-tab-active-job-data"
                                  role="tab"
                                  aria-controls="tab_item-0"
                                >
                                  <span className="resp-arrow" />
                                  <div className="job_search_icon">
                                    <img
                                      src={require("../../images/designer_icon.png")}
                                      width={52}
                                      height={52}
                                    />
                                  </div>
                                  <div className="job_search_con">
                                    <div className="job_search_con_head">
                                      Designer
                                    </div>
                                    <div className="job_search_con_con">
                                      <i
                                        className="fa fa-map-marker"
                                        aria-hidden="true"
                                      />{" "}
                                    </div>
                                  </div>
                                  <div className="job_search_social">
                                    <div>
                                      <i
                                        onClick={() =>
                                          handleShareButtonClick(
                                            jobListDetails.rowId,
                                            true
                                          )
                                        }
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div>
                                      {jobListDetails != undefined ? (
                                        jobListDetails.isBookmarked ? (
                                          <i
                                            onClick={() =>
                                              handleDeleteBookMarked(
                                                api != undefined
                                                  ? jobListDetails.jobId
                                                  : jobListDetails.rowId,
                                                jobListDetails.title
                                              )
                                            }
                                            className="fa fa-star"
                                            aria-hidden="true"
                                            style={{ color: "greenyellow" }}
                                          />
                                        ) : (
                                          <i
                                            onClick={() =>
                                              handleJobBookMarked(
                                                api != undefined
                                                  ? jobListDetails.jobId
                                                  : jobListDetails.rowId,
                                                jobListDetails.title
                                              )
                                            }
                                            className="fa fa-star-o"
                                            aria-hidden="true"
                                          />
                                        )
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="clearfix" />
                                  <div className="job_fulltime"></div>
                                  <div className="job_search_exp">
                                    0-2 Years
                                  </div>
                                  <div className="job_search_post">
                                    New 1 d{" "}
                                  </div>
                                </h2>
                                <div
                                  className="resp-tab-content resp-tab-content-active"
                                  aria-labelledby="tab_item-0"
                                  style={{ display: "block" }}
                                >
                                  <div
                                    className="jobs_searh"
                                    style={{
                                      overflow: "hidden",
                                      outline: "none",
                                    }}
                                    tabIndex={0}
                                  >
                                    <div className="jobs_right_details">
                                      <img
                                        src={require("../../images/job_search_details_img.png")}
                                        className="img-responsive"
                                      />
                                    </div>
                                    <div className="jobs_right_details_con">
                                      <div className="jobs_right_sec">
                                        <h1>
                                          {jobListDetails != undefined
                                            ? jobListDetails.title
                                            : ""}
                                        </h1>
                                        <h2>
                                          <i
                                            className="fa fa-map-marker"
                                            aria-hidden="true"
                                          />{" "}
                                          {jobListDetails != undefined
                                            ? jobListDetails.location
                                            : ""}
                                        </h2>
                                      </div>
                                      <div className="jobs_right_sec1">
                                        <div className="jobs_apply1">
                                          {authorizationToken != null ? (
                                            myProfileProgressBar.profileProgress <
                                            40 ? (
                                              <Popover
                                                content={
                                                  //    "Please complete your profile to minimum 60% to access this feature."
                                                  <>
                                                    <p>
                                                      Please complete your
                                                      profile to minimum 40% to
                                                      access this feature.
                                                    </p>
                                                    <Link to="/candidate/my-profile">
                                                      Complete Now
                                                    </Link>
                                                  </>
                                                }
                                              >
                                                <a>Apply Now</a>
                                              </Popover>
                                            ) : jobListDetails != undefined ? (
                                              jobListDetails.isApplied ? (
                                                <a>
                                                  {
                                                    jobListDetails.jobAppliedStatus
                                                  }
                                                </a>
                                              ) : (
                                                <a
                                                  onClick={(id: any) =>
                                                    handleJobApplayClick(
                                                      jobListDetails.rowId
                                                    )
                                                  }
                                                >
                                                  Apply Now
                                                </a>
                                              )
                                            ) : null
                                          ) : (
                                            <Tooltip title="Please login with your registered email and password to apply for this job post">
                                              <a
                                                className="jobs_apply1Btn1"
                                                // onClick={(jobId: any) =>
                                                //   handleJobApplied(
                                                //     jobListDetails.rowId
                                                //   )
                                                // }
                                                onClick={() => {
                                                  setIsJobAppliedOpen(
                                                    !isJobAppliedOpen
                                                  );
                                                }}
                                              >
                                                Apply Now
                                              </a>
                                            </Tooltip>
                                          )}
                                        </div>

                                        <div className="job_search_social1">
                                          <a>
                                            <i
                                              onClick={() =>
                                                handleShareButtonClick(
                                                  jobListDetails.rowId,
                                                  true
                                                )
                                              }
                                              className="fa fa-share-alt"
                                              aria-hidden="true"
                                            />{" "}
                                          </a>
                                          <a
                                            style={{
                                              display:
                                                authorizationToken != null
                                                  ? ""
                                                  : "none",
                                            }}
                                            // onClick={(jobId: any) =>
                                            //   handleJobBookMarked(
                                            //     jobListDetails.rowId,
                                            //     jobListDetails.title
                                            //   )
                                            // }
                                          >
                                            {jobListDetails != undefined ? (
                                              jobListDetails.isBookmarked ? (
                                                <i
                                                  onClick={() =>
                                                    handleDeleteBookMarked(
                                                      api != undefined
                                                        ? jobListDetails.jobId
                                                        : jobListDetails.rowId,
                                                      jobListDetails.title
                                                    )
                                                  }
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "greenyellow",
                                                  }}
                                                />
                                              ) : (
                                                <i
                                                  onClick={() =>
                                                    handleJobBookMarked(
                                                      api != undefined
                                                        ? jobListDetails.jobId
                                                        : jobListDetails.rowId,
                                                      jobListDetails.title
                                                    )
                                                  }
                                                  className="fa fa-star-o"
                                                  aria-hidden="true"
                                                />
                                              )
                                            ) : null}{" "}
                                          </a>
                                        </div>
                                        <div className="clearfix" />
                                        <div className="jobs_right_posted">
                                          {jobListDetails != undefined!
                                            ? jobListDetails.postedDate
                                            : ""}
                                        </div>
                                      </div>
                                      <div className="clearfix" />
                                      <div className="jobs_right_details_sec">
                                        <div className="col-sm-3 jobs_right_details_br">
                                          <div className="jobs_right_details_sec_con">
                                            Employer
                                          </div>
                                          <div className="jobs_right_details_sec_con1">
                                            JIT Marine
                                          </div>
                                        </div>
                                        <div className="col-sm-3 jobs_right_details_br">
                                          <div className="jobs_right_details_sec_con">
                                            Designation
                                          </div>
                                          <div className="jobs_right_details_sec_con1">
                                            {jobListDetails != undefined!
                                              ? jobListDetails.categoryName
                                              : ""}
                                          </div>
                                        </div>
                                        <div className="col-sm-3 jobs_right_details_br">
                                          <div className="jobs_right_details_sec_con">
                                            Type
                                          </div>
                                          <div className="jobs_right_details_sec_con1">
                                            {jobListDetails != undefined!
                                              ? jobListDetails.jobType
                                              : ""}
                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div className="jobs_right_details_sec_con">
                                            Experience
                                          </div>
                                          <div className="jobs_right_details_sec_con1">
                                            {jobListDetails != undefined!
                                              ? jobListDetails.experience
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearfix" />
                                      <h3>Overview</h3>
                                      <p>
                                        we belive that design (and you) will be
                                        critical to the company success. You
                                        will work with our founders and our
                                        early customers to help define and build
                                        our core product funtionality, while
                                        maintaining the quality bar that
                                        customers have come tto expect from
                                        modern SaaS applications.{" "}
                                      </p>
                                      <h3>Job description</h3>
                                      <p></p>
                                      <div>
                                        <img
                                          src={require("../../images/job_search_arrow.png")}
                                          className="jobs_right_aroow"
                                        />{" "}
                                        {jobListDetails != undefined!
                                          ? jobListDetails.description
                                          : ""}
                                      </div>

                                      <h3>Vacancies</h3>

                                      <p />
                                      <div>
                                        <img
                                          src={require("../../images/job_search_arrow.png")}
                                          className="jobs_right_aroow"
                                        />{" "}
                                        {jobListDetails != undefined!
                                          ? jobListDetails.numberOfVacancies
                                          : ""}
                                      </div>

                                      <p />
                                      <h3>Minimum Qualification</h3>
                                      <p>
                                        we belive that design (and you) will be
                                        critical to the company success. You
                                        will work with our founders and our
                                        early customers to help define and build
                                        our core product funtionality, while
                                        maintaining the quality bar that
                                        customers have come tto expect from
                                        modern SaaS applications.{" "}
                                      </p>
                                      <h3>Skills Required</h3>
                                      <p>
                                        we belive that design (and you) will be
                                        critical to the company success. You
                                        will work with our founders and our
                                        early customers to help define and build
                                        our core product funtionality, while
                                        maintaining the quality bar that
                                        customers have come tto expect from
                                        modern SaaS applications.{" "}
                                      </p>
                                      <h3>Salary Range</h3>
                                      <p>
                                        we belive that design (and you) will be
                                        critical to the company success. You
                                        will work with our founders and our
                                        early customers to help define and build
                                        our core product funtionality, while
                                        maintaining the quality bar that
                                        customers have come tto expect from
                                        modern SaaS applications.{" "}
                                      </p>
                                      <h3>Documents Required</h3>
                                      <p>
                                        we belive that design (and you) will be
                                        critical to the company success. You
                                        will work with our founders and our
                                        early customers to help define and build
                                        our core product funtionality, while
                                        maintaining the quality bar that
                                        customers have come tto expect from
                                        modern SaaS applications.{" "}
                                      </p>
                                      <h3>Other</h3>
                                      <p>
                                        we belive that design (and you) will be
                                        critical to the company success. You
                                        will work with our founders and our
                                        early customers to help define and build
                                        our core product funtionality, while
                                        maintaining the quality bar that
                                        customers have come tto expect from
                                        modern SaaS applications.{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null
                          ) : (
                            <>
                              <h2 style={{ padding: 103, color: "burlywood" }}>
                                Please Select a job to Know More details
                              </h2>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <h2
                            className="resp-accordion resp-tab-active-job-data"
                            role="tab"
                            aria-controls="tab_item-0"
                          >
                            <span className="resp-arrow" />
                            <div className="job_search_icon">
                              <img
                                src={require("../../images/designer_icon.png")}
                                width={52}
                                height={52}
                              />
                            </div>
                            <div className="job_search_con">
                              <div className="job_search_con_head">
                                Designer
                              </div>
                              <div className="job_search_con_con">
                                <i
                                  className="fa fa-map-marker"
                                  aria-hidden="true"
                                />{" "}
                              </div>
                            </div>
                            <div className="job_search_social">
                              <div>
                                <i
                                  onClick={() =>
                                    handleShareButtonClick(
                                      jobAppliedViewListData.rowId,
                                      true
                                    )
                                  }
                                  className="fa fa-share-alt"
                                  aria-hidden="true"
                                />
                              </div>
                              <div>
                                {jobAppliedViewListData != undefined ? (
                                  jobAppliedViewListData.isBookmarked ? (
                                    <i
                                      onClick={() =>
                                        handleDeleteBookMarked(
                                          api != undefined
                                            ? jobAppliedViewListData.jobId
                                            : jobAppliedViewListData.rowId,
                                          jobListDetails.title
                                        )
                                      }
                                      className="fa fa-star"
                                      aria-hidden="true"
                                      style={{ color: "greenyellow" }}
                                    />
                                  ) : (
                                    <i
                                      onClick={() =>
                                        handleJobBookMarked(
                                          api != undefined
                                            ? jobAppliedViewListData.jobId
                                            : jobAppliedViewListData.rowId,
                                          jobAppliedViewListData.title
                                        )
                                      }
                                      className="fa fa-star-o"
                                      aria-hidden="true"
                                    />
                                  )
                                ) : null}
                              </div>
                            </div>
                            <div className="clearfix" />
                            <div className="job_fulltime">Full Time</div>
                            <div className="job_search_exp">0-2 Years</div>
                            <div className="job_search_post">New 1 d </div>
                          </h2>
                          <div
                            className="resp-tab-content resp-tab-content-active"
                            aria-labelledby="tab_item-0"
                            style={{ display: "block" }}
                          >
                            <div
                              className="jobs_searh"
                              style={{ overflow: "hidden", outline: "none" }}
                              tabIndex={0}
                            >
                              <div className="jobs_right_details">
                                <img
                                  src={require("../../images/job_search_details_img.png")}
                                  className="img-responsive"
                                />
                              </div>
                              <div className="jobs_right_details_con">
                                <div className="jobs_right_sec">
                                  <h1>
                                    {jobAppliedViewListData != undefined!
                                      ? jobAppliedViewListData.title
                                      : ""}
                                  </h1>
                                  <h2>
                                    <i
                                      className="fa fa-map-marker"
                                      aria-hidden="true"
                                    />{" "}
                                    {jobAppliedViewListData != undefined!
                                      ? jobAppliedViewListData.location
                                      : ""}
                                  </h2>
                                </div>
                                <div className="jobs_right_sec1">
                                  <div
                                    style={{
                                      display:
                                        authorizationToken != null
                                          ? ""
                                          : "none",
                                    }}
                                    className="jobs_apply1"
                                  >
                                    {authorizationToken != null ? (
                                      jobAppliedViewListData != undefined! ? (
                                        jobAppliedViewListData.isApplied ? (
                                          <a>Applied</a>
                                        ) : (
                                          <a
                                            // onClick={(jobId: any) =>
                                            //   handleJobApplied(jobListDetails.rowId)
                                            // }
                                            onClick={() => {
                                              setIsJobAppliedOpen(
                                                !isJobAppliedOpen
                                              );
                                            }}
                                          >
                                            Apply Now
                                          </a>
                                        )
                                      ) : null
                                    ) : (
                                      <Tooltip title="please login to applay this job post ">
                                        <a
                                          className="jobs_apply1Btn1"
                                          onClick={() => {
                                            setIsJobAppliedOpen(
                                              !isJobAppliedOpen
                                            );
                                          }}
                                          // onClick={(jobId: any) =>
                                          //   handleJobApplied(
                                          //     jobListDetails.rowId
                                          //   )
                                          // }
                                        >
                                          Apply Now
                                        </a>
                                      </Tooltip>
                                    )}
                                  </div>
                                  <div className="job_search_social1">
                                    <a>
                                      <i
                                        onClick={() =>
                                          handleShareButtonClick(
                                            jobAppliedViewListData.rowId,
                                            true
                                          )
                                        }
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />{" "}
                                    </a>
                                    <a
                                      style={{
                                        display:
                                          authorizationToken != null
                                            ? ""
                                            : "none",
                                      }}
                                      onClick={(jobId: any) =>
                                        handleJobBookMarked(
                                          api != undefined
                                            ? jobListDetails.jobId
                                            : jobListDetails.rowId,
                                          jobListDetails.title
                                        )
                                      }
                                    >
                                      {jobAppliedViewListData != undefined ? (
                                        jobAppliedViewListData.isBookmarked ? (
                                          <i
                                            onClick={() =>
                                              handleDeleteBookMarked(
                                                api != undefined
                                                  ? jobAppliedViewListData.jobId
                                                  : jobAppliedViewListData.rowId,
                                                jobAppliedViewListData.title
                                              )
                                            }
                                            className="fa fa-star"
                                            aria-hidden="true"
                                            style={{ color: "greenyellow" }}
                                          />
                                        ) : (
                                          <i
                                            onClick={() =>
                                              handleJobBookMarked(
                                                api != undefined
                                                  ? jobAppliedViewListData.jobId
                                                  : jobAppliedViewListData.rowId,

                                                jobAppliedViewListData.title
                                              )
                                            }
                                            className="fa fa-star-o"
                                            aria-hidden="true"
                                          />
                                        )
                                      ) : null}{" "}
                                    </a>
                                  </div>
                                  <div className="clearfix" />
                                  <div className="jobs_right_posted">
                                    {jobListDetails != undefined!
                                      ? jobListDetails.postedDate
                                      : ""}
                                  </div>
                                </div>
                                <div className="clearfix" />
                                <div className="jobs_right_details_sec">
                                  <div className="col-sm-3 jobs_right_details_br">
                                    <div className="jobs_right_details_sec_con">
                                      Employer
                                    </div>
                                    <div className="jobs_right_details_sec_con1">
                                      JIT Marine
                                    </div>
                                  </div>
                                  <div className="col-sm-3 jobs_right_details_br">
                                    <div className="jobs_right_details_sec_con">
                                      Designation
                                    </div>
                                    <div className="jobs_right_details_sec_con1">
                                      {jobListDetails != undefined!
                                        ? jobListDetails.categoryName
                                        : ""}
                                    </div>
                                  </div>
                                  <div className="col-sm-3 jobs_right_details_br">
                                    <div className="jobs_right_details_sec_con">
                                      Type
                                    </div>
                                    <div className="jobs_right_details_sec_con1">
                                      {jobListDetails != undefined!
                                        ? jobListDetails.jobType
                                        : ""}
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <div className="jobs_right_details_sec_con">
                                      Experience
                                    </div>
                                    <div className="jobs_right_details_sec_con1">
                                      {jobListDetails != undefined!
                                        ? jobListDetails.experience
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                                <div className="clearfix" />
                                <h3>Overview</h3>
                                <p>
                                  we belive that design (and you) will be
                                  critical to the company success. You will work
                                  with our founders and our early customers to
                                  help define and build our core product
                                  funtionality, while maintaining the quality
                                  bar that customers have come tto expect from
                                  modern SaaS applications.{" "}
                                </p>
                                <h3>Job description</h3>
                                <p></p>
                                <div>
                                  <img
                                    src={require("../../images/job_search_arrow.png")}
                                    className="jobs_right_aroow"
                                  />{" "}
                                  {jobListDetails != undefined!
                                    ? jobListDetails.description
                                    : ""}
                                </div>
                                {/* <div><img src={require("../../images/job_search_arrow.png")} className="jobs_right_aroow" /> A portfolio that highlights your approach to problem solving, as well as your skills UI.</div>
                          <div><img src={require("../../images/job_search_arrow.png")} className="jobs_right_aroow" /> Experience conducting research and building out smooth flows for different types of users.</div>
                          <div><img src={require("../../images/job_search_arrow.png")} className="jobs_right_aroow" /> Excellent communication skills with a well-defined design process.</div>
                          <div><img src={require("../../images/job_search_arrow.png")} className="jobs_right_aroow" /> Familiarity with design tools like Sketch and Figma.</div>
                          <div><img src={require("../../images/job_search_arrow.png")} className="jobs_right_aroow" /> Up-level our overall design and bring consistency to end-user facing properties.</div> <p />  */}
                                <h3>Vacancies</h3>
                                {jobListDetails != undefined!
                                  ? jobListDetails.numberOfVacancies
                                  : ""}
                                <p />
                                <div>
                                  <img
                                    src={require("../../images/job_search_arrow.png")}
                                    className="jobs_right_aroow"
                                  />{" "}
                                  {jobListDetails != undefined!
                                    ? jobListDetails.numberOfVacancies
                                    : 0}
                                </div>
                                {/* <div><img src={require("../../images/job_search_arrow.png")} className="jobs_right_aroow" /> Dummy 2</div>
                          <div><img src={require("../../images/job_search_arrow.png")} className="jobs_right_aroow" /> Dummy 3</div>
                          <div><img src={require("../../images/job_search_arrow.png")} className="jobs_right_aroow" /> Dummy 4</div>
                          <div><img src={require("../../images/job_search_arrow.png")} className="jobs_right_aroow" /> Dummy 5</div>
                          <div><img src={require("../../images/job_search_arrow.png")} className="jobs_right_aroow" /> Dummy 6</div> */}
                                <p />
                                <h3>Minimum Qualification</h3>
                                <p>
                                  {/* {jobListDetails != undefined!
                                    ? jobListDetails.
                                    : 0} */}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Scrollbars>
                    {/*----------------End-------------*/}
                    {/*----------------Start-------------*/}

                    {/*----------------End-------------*/}
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content ends  */}

      {/* --------------------------------------------------- */}

      {/* Model start  */}

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
          setcurrentAlertId(0);
        }}
      >
        <form onSubmit={handleSubmit2(handleJobAlert)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>
              {currentAlertId == 0 ? "New Job Alert" : "Update Job Alert"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="email" className="required">
                Job Category
              </label>
              <span className="select-wrapper-sec">
                <Controller
                  control={control2}
                  name="jobCategorys"
                  rules={{
                    required: "Job Category is required",
                  }}
                  // value=''
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption
                      values={
                        myProfile.jobCategories != undefined
                          ? myProfile.jobCategories.map((e: any) => {
                              return {
                                value: e["value"],
                                label: e["caption"],
                              };
                            })
                          : jobCategoryData
                      }
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  )}
                />
              </span>
              <ErrorMessage
                errors={errors2}
                name="jobCategorys"
                render={({ message }) => (
                  <div className="register_validation">{message}</div>
                )}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email" className="required">
                Job Type
              </label>
              <span className="select-wrapper-sec">
                <Controller
                  className="custom-select-sec"
                  control={control2}
                  name="jobTypeData"
                  rules={{
                    required: "Job Type is required",
                  }}
                  // rules={{
                  //   validate: (value) => {
                  //     return value === "" ? "Job Type is required" : "";
                  //   },
                  // }}
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption
                      values={
                        myProfile.jobTypes != undefined
                          ? myProfile.jobTypes.map((e: any) => {
                              return {
                                value: e["value"],
                                label: e["caption"],
                              };
                            })
                          : jobTypeDatas
                      }
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  )}
                />
              </span>
              <ErrorMessage
                errors={errors2}
                name="jobTypeData"
                render={({ message }) => (
                  <div className="register_validation">{message}</div>
                )}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email" className="required">
                Job Title
              </label>
              <span className="select-wrapper-sec">
                <Controller
                  control={control2}
                  name="jobTitle"
                  rules={{
                    required: "Job Title is required",
                  }}
                  // value=''
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption
                      values={
                        tempData != undefined
                          ? tempData.map((e) => {
                              return {
                                value: e["value"],
                                label: e["caption"],
                              };
                            })
                          : tempData
                      }
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  )}
                />
              </span>
            </div>
            <ErrorMessage
              errors={errors2}
              name="jobTitle"
              render={({ message }) => (
                <div className="register_validation">{message}</div>
              )}
            />
            <div className="form-group">
              <label htmlFor="email">Location</label>
              <input
                defaultValue={location}
                type="text"
                className="form-control "
                name="location"
                placeholder=" "
                ref={register2({
                  required: false,
                })}
              />
            </div>
            <div className="form-group">
              <input
                id="radio1"
                type="radio"
                ref={register2({
                  required: false,
                })}
                name="radio"
                defaultValue={1}
                defaultChecked
              />
              <label htmlFor="radio1" style={{ marginRight: 20 }}>
                <span>
                  <span />
                </span>{" "}
                Active
              </label>
              <input
                id="radio2"
                type="radio"
                ref={register2({
                  required: false,
                })}
                name="radio"
                defaultValue={2}
                // defaultChecked
              />
              <label
                htmlFor="radio2"
                // style={{ marginLeft: 1 }}
              >
                <span>
                  <span />
                </span>{" "}
                Inactive
              </label>
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
              Create Alert
            </button>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
                setcurrentAlertId(0);
              }}
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="clearfix" />
        </form>
      </Modal>

      {/* Model end  */}

      {/* Model of Job Applied Start*/}

      {/* <Modal
        show={isJobAppliedOpen}
        onHide={() => {
          setIsJobAppliedOpen(!isJobAppliedOpen);
        }}
      >
        <form onSubmit={handleSubmit3(handleJobApplied)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="modal-title">Apply Now </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-6">
              <label htmlFor="email">Select Resume</label>
              <span className="select-wrapper-sec">
                <select name="timepass" className="custom-select-sec">
                  <option value="">Text</option>
                  <option value="">Text</option>
                  <option value="">Text</option>
                </select>
                <span className="holder">Text</span>
              </span>
            </div>
            <div className="col-sm-6">
              <div className="box1 m_t_25">
                <input
                  type="file"
                  name="file-1[]"
                  id="file-1"
                  className="inputfile inputfile-1"
                  data-multiple-caption="{count} files selected"
                  multiple
                />
                <label htmlFor="file-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={17}
                    viewBox="0 0 20 17"
                  >
                    <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                  </svg>{" "}
                  <span>Upload Resume</span>
                </label>
              </div>
            </div>
            <div className="col-sm-6">
              <label htmlFor="email">Select Cover Letter</label>
              <span className="select-wrapper-sec">
                <select name="timepass" className="custom-select-sec">
                  <option value="">Text</option>
                  <option value="">Text</option>
                  <option value="">Text</option>
                </select>
                <span className="holder">Text</span>
              </span>
            </div>
            <div className="col-sm-6">
              <div className="box1 m_t_25">
                <input
                  type="file"
                  name="file-1[]"
                  id="file-1"
                  className="inputfile inputfile-1"
                  data-multiple-caption="{count} files selected"
                  multiple
                />
                <label htmlFor="file-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={17}
                    viewBox="0 0 20 17"
                  >
                    <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                  </svg>{" "}
                  <span>Upload Cover letter</span>
                </label>
              </div>
            </div>
            <div className="col-sm-6">
              <h1 className="modal_headings">General Document </h1>
              <div>
                <input
                  id="radio1"
                  type="radio"
                  name="radio"
                  defaultValue={1}
                  defaultChecked
                />
                <label htmlFor="radio1">
                  <span>
                    <span />
                  </span>
                  Document Name1
                </label>
              </div>
              <div>
                <input
                  id="radio1"
                  type="radio"
                  name="radio"
                  defaultValue={1}
                  defaultChecked
                />
                <label htmlFor="radio1">
                  <span>
                    <span />
                  </span>
                  Document Name2
                </label>
              </div>
              <div>
                <input
                  id="radio1"
                  type="radio"
                  name="radio"
                  defaultValue={1}
                  defaultChecked
                />
                <label htmlFor="radio1">
                  <span>
                    <span />
                  </span>
                  Document Name3
                </label>
              </div>
            </div>
            <div className="col-sm-6">
              <h1 className="modal_headings">Pesonal Document </h1>
              <div>
                <input
                  id="radio1"
                  type="radio"
                  name="radio"
                  defaultValue={1}
                  defaultChecked
                />
                <label htmlFor="radio1">
                  <span>
                    <span />
                  </span>
                  Document Name1
                </label>
              </div>
              <div>
                <input
                  id="radio1"
                  type="radio"
                  name="radio"
                  defaultValue={1}
                  defaultChecked
                />
                <label htmlFor="radio1">
                  <span>
                    <span />
                  </span>
                  Document Name2
                </label>
              </div>
              <div>
                <input
                  id="radio1"
                  type="radio"
                  name="radio"
                  defaultValue={1}
                  defaultChecked
                />
                <label htmlFor="radio1">
                  <span>
                    <span />
                  </span>
                  Document Name3
                </label>
              </div>
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="button"
            >
              Apply Now
            </button>
            <button
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal> */}

      <JobAppliedForm
        isJobAppliedOpen={isJobAppliedOpen}
        handleJobOpen={handleJobOpen}
        currentJobId={currentJobId}
        loggedUserId={loggedUserId}
        authorizationToken={authorizationToken}
        candidateResumes={candidateResumes}
        candidateCoverLetters={candidateCoverLetters}
      />

      {/*{/* Model of Job Applied End*/}

      {/* ---------------------- */}

      <Modal
        show={isShareOpen}
        onHide={() => {
          setIsShareOpen(!isShareOpen);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share this job post on</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 m_t_30 text-center">
            <div className="social1">
              <FacebookShareButton
                url={`http://careerappui.clubactive.in/#/job_search/${jobId}`}
                quote={`http://careerappui.clubactive.in/#/job_search/${jobId}`}
                hashtag={`${
                  filterFlag
                    ? jobAppliedCandidateResult != undefined &&
                      jobAppliedCandidateResult.data != undefined
                      ? jobAppliedCandidateResult.data.filter(
                          (data) => data.rowId === jobId
                        )[0] != undefined
                        ? jobAppliedCandidateResult.data.filter(
                            (data) => data.rowId === jobId
                          )[0].description
                        : ""
                      : ""
                    : jobAppliedCandidateResult.data != undefined &&
                      jobAppliedCandidateResult.data != undefined
                    ? jobAppliedCandidateResult.data.filter(
                        (data) => data.rowId === jobId
                      )[0] != undefined
                      ? jobAppliedCandidateResult.data.filter(
                          (data) => data.rowId === jobId
                        )[0].description
                      : ""
                    : ""
                }`}
                className={""}
              >
                {/* <FacebookIcon size={36} /> */}
                <a href="#" className="social_face">
                  <i className="fa fa-facebook" aria-hidden="true" />
                </a>
              </FacebookShareButton>

              <TwitterShareButton
                url={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                via={`${
                  filterFlag
                    ? newlyPostedJob != undefined &&
                      newlyPostedJob.data != undefined
                      ? newlyPostedJob.data.filter(
                          (data) => data.rowId === currentJobId
                        )[0] != undefined
                        ? newlyPostedJob.data.filter(
                            (data) => data.rowId === currentJobId
                          )[0].description
                        : ""
                      : ""
                    : newlyPostedJob.data != undefined &&
                      newlyPostedJob.data != undefined
                    ? newlyPostedJob.data.filter(
                        (data) => data.rowId === currentJobId
                      )[0] != undefined
                      ? newlyPostedJob.data.filter(
                          (data) => data.rowId === currentJobId
                        )[0].description
                      : ""
                    : ""
                }`}
                hashtags={[
                  `${
                    filterFlag
                      ? newlyPostedJob != undefined &&
                        newlyPostedJob.data != undefined
                        ? newlyPostedJob.data.filter(
                            (data) => data.rowId === currentJobId
                          )[0] != undefined
                          ? newlyPostedJob.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0].description
                          : ""
                        : ""
                      : newlyPostedJob.data != undefined &&
                        newlyPostedJob.data != undefined
                      ? newlyPostedJob.data.filter(
                          (data) => data.rowId === currentJobId
                        )[0] != undefined
                        ? newlyPostedJob.data.filter(
                            (data) => data.rowId === currentJobId
                          )[0].description
                        : ""
                      : ""
                  }`,
                ]}
                className={""}
              >
                {/* <FacebookIcon size={36} /> */}
                <a href="#" className="social_twit">
                  <i className="fa fa-twitter" aria-hidden="true" />
                </a>
              </TwitterShareButton>

              <LinkedinShareButton
                url={`http://careerappui.clubactive.in/#/job_search/${jobId}`}
                title={`${
                  filterFlag
                    ? newlyPostedJob != undefined &&
                      newlyPostedJob.data != undefined
                      ? newlyPostedJob.data.filter(
                          (data) => data.rowId === jobId
                        )[0] != undefined
                        ? newlyPostedJob.data.filter(
                            (data) => data.rowId === jobId
                          )[0].title
                        : ""
                      : ""
                    : newlyPostedJob.data != undefined &&
                      newlyPostedJob.data != undefined
                    ? newlyPostedJob.data.filter(
                        (data) => data.rowId === jobId
                      )[0] != undefined
                      ? newlyPostedJob.data.filter(
                          (data) => data.rowId === jobId
                        )[0].title
                      : ""
                    : ""
                }`}
                summary={`${
                  filterFlag
                    ? newlyPostedJob != undefined &&
                      newlyPostedJob.data != undefined
                      ? newlyPostedJob.data.filter(
                          (data) => data.rowId === jobId
                        )[0] != undefined
                        ? newlyPostedJob.data.filter(
                            (data) => data.rowId === jobId
                          )[0].description
                        : ""
                      : ""
                    : newlyPostedJob.data != undefined &&
                      newlyPostedJob.data != undefined
                    ? newlyPostedJob.data.filter(
                        (data) => data.rowId === jobId
                      )[0] != undefined
                      ? newlyPostedJob.data.filter(
                          (data) => data.rowId === jobId
                        )[0].description
                      : ""
                    : ""
                }`}
                source={`http://careerappui.clubactive.in/#/job_search/${jobId}`}
              >
                <a href="#" className="social_twit">
                  <i className="fa fa-linkedin" aria-hidden="true" />
                </a>
              </LinkedinShareButton>
            </div>
          </div>
          <div className="modal-footer  m-t-30"></div>
        </Modal.Body>
      </Modal>

      {/* --------------------------------------------------- */}

      {/* Footer start  */}

      <div className="bottom_jobs">
        <div className="container-fluid">
          <div className="col-sm-6">© 2020 internationaljit.com</div>
          <div className="col-sm-6 text-right">
            Created by Born Code Technology
          </div>
        </div>
      </div>

      {/* Footer End  */}
    </>
  );
};
export default withRouter(JobSearchComponent);
