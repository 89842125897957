import React, { useState } from "react";
import Pikaday from "pikaday";
import moment from "moment";

interface IPikadayDatepickerProps {
  disabled: boolean;
  onChange: (e: number) => void;
  onBlur: (e: object) => void;
  value: number;
  name: string;
  overrideCursor?: boolean;
}

class PikadayDatepicker extends React.Component<
  IPikadayDatepickerProps,
  { isDom: boolean }
> {
  private myRef: React.RefObject<HTMLInputElement>;

  private _picker: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isDom: false,
    };
    this.myRef = React.createRef();
  }

  setDateIfChanged = (newDate: number, prevDate?: number) => {
    let newTime = newDate ? newDate : Date.now();
    let prevTime = prevDate ? prevDate : 0;

    if (this.state.isDom) {
      setTimeout(() => {
        this._picker.setDate(
          moment(new Date(newTime)).format("YYYY-MM-DD"),
          true
        );
      }, 10);
    }
  };

  componentDidMount() {
    const _this = this;

    this.setState(
      {
        isDom: true,
      },
      () => {
        this._picker = new Pikaday({
          field: this.myRef.current,
          format: "DD/MM/YYYY",
          yearRange: [1950, 2050],
          // defaultDate: new Date(_this.props.value),
          // setDefaultDate: true,
          onSelect: function (e: Date) {
            _this.props.onChange(e.getTime());
          },
        });

        this.setDateIfChanged(this.props.value);
      }
    );
  }

  componentWillReceiveProps(nextProps: any) {
    let newDate = nextProps.value;
    let lastDate = this.props.value;
    this.setDateIfChanged(newDate, lastDate);
  }

  render() {
    const _this = this;

    const { disabled, overrideCursor, value } = this.props;

    return (
      <span style={{ position: "relative" }}>
        {value == 0 ? (
          <div
            onClick={() => {
              _this._picker.show();
            }}
            className={disabled ? `_cursor-no-drop` : "_cursor-pointer"}
            style={{
              position: "absolute",
              margin: "1%",
              width: "85%",
              height: "34px",
              backgroundColor: "#fcfdff",
            }}
          />
        ) : (
          <span />
        )}

        <input
          type="text"
          ref={this.myRef}
          disabled={disabled}
          readOnly={true}
          id="pickaday_datepicker_custom"
          className={
            disabled
              ? `text-wrapper select-calander _cursor-no-drop`
              : "text-wrapper select-calander"
          }
        />
      </span>
    );
  }
}

export default PikadayDatepicker;

// import React, { useState } from "react";
// import Pikaday from "pikaday";
// import moment from "moment";

// interface IPikadayDatepickerProps {
//   disabled: boolean,
//   onChange: (e: number) => void,
//   onBlur: (e: object) => void,
//   value: number,
//   name: string,
//   overrideCursor?: boolean
// }

// class PikadayDatepicker extends React.Component<IPikadayDatepickerProps, { isDom: boolean }> {

//   private myRef: React.RefObject<HTMLInputElement>;

//   private myRef2: React.RefObject<HTMLInputElement>;

//   private _picker: any;

//   private _picker2: any;

//   constructor(props: any) {
//     super(props);
//     this.state = {
//       isDom: false
//     }
//     this.myRef = React.createRef();
//     this.myRef2 = React.createRef();
//   }

//   setDateIfChanged = (newDate: number, prevDate?: number) => {
//     let newTime = newDate ? newDate : Date.now();
//     let prevTime = prevDate ? prevDate : 0;

//     if (this.state.isDom) {
//       setTimeout(() => {
//         this._picker.setDate(moment(new Date(newTime)).format('YYYY-MM-DD'), true);
//       }, 10);
//     }
//   }

//   componentDidMount() {

//     const _this = this;

//     this.setState({
//       isDom: true
//     }, () => {
//       this._picker = new Pikaday({
//         field: this.myRef.current,
//         format: "DD/MM/YYYY",
//         // defaultDate: new Date(_this.props.value),
//         // setDefaultDate: true,
//         onSelect: function (e: Date) {
//           _this.props.onChange(e.getTime());
//         }
//       });

//       this._picker2 = new Pikaday({
//         field: this.myRef2.current,
//         format: "DD/MM/YYYY",
//         onSelect: function (e: Date) {
//           _this.props.onChange(e.getTime());
//         }
//       });

//       // this.setDateIfChanged(this.props.value);
//     });

//   }

//   componentWillReceiveProps(nextProps: any) {
//     let newDate = nextProps.value;
//     let lastDate = this.props.value;
//     this.setDateIfChanged(newDate, lastDate);
//   }

//   render() {

//     const { disabled, overrideCursor, value } = this.props;

//     //console.log(this.props);

//     return (
//       <React.Fragment>
//         <input
//           style={{ display: value != 0 ? "inline-block" : "none" }}
//           type="text"
//           ref={this.myRef}
//           disabled={disabled}
//           id="pickaday_datepicker_custom"
//           className={disabled ? `text-wrapper select-calander _cursor-no-drop` : "text-wrapper select-calander"} />
//         <input style={{ display: value == 0 ? "inline-block" : "none" }}
//           type="text"
//           ref={this.myRef2}
//           disabled={disabled}
//           id="pickaday_datepicker_custom2"
//           className={disabled ? `text-wrapper select-calander _cursor-no-drop` : "text-wrapper select-calander"} />
//       </React.Fragment>
//     );
//   }
// }

// // class PikadayDatepicker extends React.Component<IPikadayDatepickerProps, { isDom: boolean }> {

// //   private myRef: React.RefObject<HTMLInputElement>;

// //   private _picker: any;

// //   constructor(props: any) {
// //     super(props);
// //     this.state = {
// //       isDom: false
// //     }
// //     this.myRef = React.createRef();
// //   }

// //   setDateIfChanged = (newDate: number, prevDate?: number) => {
// //     let newTime = newDate ? newDate : Date.now();
// //     let prevTime = prevDate ? prevDate : 0;

// //     if (this.state.isDom) {
// //       this._picker.setDate(moment(new Date(newTime)).format('YYYY-MM-DD'), true);
// //     }

// //     // if (newTime !== prevTime) {
// //     //   if (newDate === 0) {
// //     //     this.myRef.current!.value = Date.now() + "";
// //     //   }
// //     //   //console.log(moment(new Date(newDate)).format('YYYY-MM-DD'));
// //     //   this._picker.setDate(moment(new Date(newDate)).format('YYYY-MM-DD'), true);
// //     // }
// //   }

// //   componentDidMount() {

// //     const _this = this;

// //     this.setState({
// //       isDom: true
// //     }, () => {
// //       this._picker = new Pikaday({
// //         field: this.myRef.current,
// //         format: "DD/MM/YYYY",
// //         // defaultDate: new Date(_this.props.value),
// //         // setDefaultDate: true,
// //         onSelect: function (e: Date) {
// //           _this.props.onChange(e.getTime());
// //         }
// //       });

// //       this.setDateIfChanged(this.props.value);
// //     });

// //   }

// //   componentWillReceiveProps(nextProps: any) {
// //     let newDate = nextProps.value;
// //     let lastDate = this.props.value;
// //     this.setDateIfChanged(newDate, lastDate);
// //   }

// //   render() {

// //     const { disabled, overrideCursor } = this.props;

// //     return <input type="text" ref={this.myRef} disabled={disabled} id="pickaday_datepicker_custom"
// //       className={disabled ? `text-wrapper select-calander _cursor-no-drop` : "text-wrapper select-calander"} />;

// //     // return <input type="text" ref={this.myRef} disabled={disabled} id="pickaday_datepicker_custom"
// //     //   className={disabled ? `text-wrapper select-calander ${overrideCursor ? "_cursor-no-drop" : "_cursor-url"}` : "text-wrapper select-calander"} />;
// //   }
// // }

// export default PikadayDatepicker;
