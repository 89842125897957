import React from "react";
import CareerTipsComponent from "./CarrerTipsComponent";


interface ICareerTipsContainerProps { }

interface ICareerTipsContainerState { }

const initialState = {};

const CareerTipsContainer: React.FC<ICareerTipsContainerProps> = (props) => {
  const [startUpState, setCareerTipsContainerState] = React.useState<ICareerTipsContainerState>(
    initialState
  );

  return (
    <>
    <CareerTipsComponent/>
    </>
  );
};
export default CareerTipsContainer;
