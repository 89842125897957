import React, { useState, useEffect, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import AuthService from "../../services/AuthService";
import {
  GoogleLoginButton,
  FacebookLoginButton,
  TwitterLoginButton,
  LinkedinLoginButton,
} from "./components/SocialLoginButton";
import { toast, ToastContainer } from "react-toastify";
import { socialLoginHandler } from "../../apis/misc";
import { Modal } from "react-bootstrap";
import OtpVerificationEmail from "./OtpVerificationEmail";
import OtpVerificationMobile from "./OtpVerificationMobile";
import { useHistory } from "react-router-dom";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { reactHookFormServerErrors } from "./../../utils/utility";
import OtpExpiryCouter from "./../GlobalOtpExpiryCouter";
import {
  sendEmailOpt,
  sendPhoneOtp,
  verifyCode,
  emailVerifyCode,
  verifyCodeNewMobile,
  createSuperAdmin,
  updateAccessToken,
} from "./../../apis/misc";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "./../../context/GlobalSpinner";
import Timer from "react-compound-timer";
import PhoneNumberWithCode from "../components/PhoneNumberWithCode";

function Verification(props: any) {
  console.log("props");
  console.log(props.email, props.contactNumber, props.countryCode);

  const [email, setEmail] = useState(props.email);

  const [contactNumber, setContactNumber] = useState(props.contactNumber);

  const [countryCode, setCountryCode] = useState(props.countryCode);

  const [showTimer, setShowTimer] = useState(false);

  const [timerMsg, setTimerMsg] = useState("");

  const [screen, setScreen] = useState(1);

  const globalSpinner = useGlobalSpinnerContext();

  const [disabled, setDisabled] = useState(false);

  const [isEmail, setIsEmail] = useState(0);

  const { register, handleSubmit, watch, errors, trigger, control } = useForm<{
    contactNumber: string;
    countryCode: string;
  }>({
    defaultValues: { contactNumber: "", countryCode: "" },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    errors: errors2,
    trigger: trigger2,
    setError: setError2,
    clearErrors: clearErrors2,
  } = useForm<{ code: string }>({
    defaultValues: { code: "" },
  });

  //for sending OTP
  useEffect(() => {
    // const sendOtp = () => {
    // globalSpinner.showSpinner();
    console.log("send ", isEmail);
    if (isEmail == 1) {
      console.log("Email", isEmail);
      sendEmailOpt({}, `?email=${email}`)
        .then((res: any) => {
          console.log(res);
          globalSpinner.hideSpinner();
          if (res.data.includes("error")) {
            toast.error(res.data);
          } else {
            toast.success("OTP has been successfully sent on your e-mail.");
            setScreen(2);
            setShowTimer(true);
          }
        })
        .catch((err: any) => {
          console.log(err);
          globalSpinner.hideSpinner();
          toast.error("Something went wrong");
        });
    } else if (isEmail == 2) {
      console.log("Mobile", isEmail);
      setCountryCode(countryCode == "" ? "+91" : countryCode);
      setContactNumber(8848738678);

      sendPhoneOtp(
        {},
        `?countryCode=${encodeURIComponent(
          countryCode == "" ? "+91" : countryCode
        )}&phoneNumber=${encodeURIComponent(8848738678)}`
      )
        .then((res: any) => {
          globalSpinner.hideSpinner();
          if (res.data.includes("error") || res.data.includes("Invalid")) {
            toast.error(res.data);
          } else {
            // toast.success("OTP has been send.");
            toast.success(
              "OTP has been successfully sent on your mobile number."
            );
            setScreen(2);
            setShowTimer(true);
          }
        })
        .catch((err: any) => {
          globalSpinner.hideSpinner();
          toast.error("Something went wrong");
        });
    }
    //}
  }, [isEmail]);

  const sendVerification = async () => {
    console.log("resend ", isEmail);
    globalSpinner.showSpinner();
    if (isEmail == 1) {
      sendEmailOpt({}, `?email=${email}`)
        .then((res: any) => {
          console.log(res);
          globalSpinner.hideSpinner();
          if (res.data.includes("error")) {
            toast.error(res.data);
          } else {
            //toast.success("OTP has been resend.");
            toast.success("OTP has been successfully sent on your e-mail.");
            setShowTimer(true);
            setTimerMsg("");
            setDisabled(false);
          }
        })
        .catch((err: any) => {
          console.log(err);
          globalSpinner.hideSpinner();
          toast.error("Something went wrong");
        });
    } else if (isEmail == 2) {
      sendPhoneOtp(
        {},
        `?countryCode=${encodeURIComponent(
          countryCode
        )}&phoneNumber=${encodeURIComponent(8848738678)}`
      )
        .then((res: any) => {
          globalSpinner.hideSpinner();
          if (res.data.includes("error") || res.data.includes("Invalid")) {
            toast.error(res.data);
          } else {
            // toast.success("OTP has been send.");
            toast.success(
              "OTP has been successfully sent on your mobile number."
            );
            setScreen(2);
            setShowTimer(true);
            setTimerMsg("");
            setDisabled(false);
          }
        })
        .catch((err: any) => {
          globalSpinner.hideSpinner();
          toast.error("Something went wrong");
        });
    }
  };

  //For verifying code
  const onSubmit2 = async (data: any) => {
    console.log("-------------verify--------------", data);
    console.log("verify ", isEmail);
    globalSpinner.showSpinner();
    try {
      const user = await AuthService.login(email, props.password, true);

      if (isEmail == 1) {
        emailVerifyCode({}, `?email=${email}&code=${data["code"]}`)
          .then((res: any) => {
            globalSpinner.hideSpinner();
            if (res.data.includes("error")) {
              toast.error(res.data);
              AuthService.logout();
            } else {
              toast.success("OTP verified.");
              props.history.push("/super_admin");
              setTimerMsg("");
              // props.setSuperAdmin(true);

              window.location.reload(false);
            }
          })
          .catch((err: any) => {
            globalSpinner.hideSpinner();
            AuthService.logout();
            reactHookFormServerErrors(
              {
                code: ["Error: Invalid OTP.Please enter correct OTP"],
              },
              setError2
            );
          });
      } else if (isEmail == 2) {
        verifyCode(
          {},
          `?code=${data["code"]}&countryCode=${encodeURIComponent(
            countryCode
          )}&phoneNumber=${encodeURIComponent(8848738678)}`
        )
          .then(async (res: any) => {
            console.log("Inside verify phone code ", res);
            globalSpinner.hideSpinner();
            if (res.data.includes("error")) {
              toast.error(res.data);
              AuthService.logout();
            } else {
              toast.success("OTP verified.");

              // props.history.push("/super_admin");
              setTimerMsg("");

              props.history.push("/super_admin");

              window.location.reload(false);

              // props.setSuperAdmin(true);
            }
          })
          .catch((err: any) => {
            globalSpinner.hideSpinner();
            AuthService.logout();
            reactHookFormServerErrors(
              {
                code: ["Error: Invalid OTP.Please enter correct OTP"],
              },
              setError2
            );
          });
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 400) {
        toast.error("Username/Password incorrect.");
        props.setIsOpen(false);
      } else {
        toast.error("Error occurred while login.");
        props.setIsOpen(false);
      }

      globalSpinner.hideSpinner();
    }
  };

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Account Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-12">
          {screen == 1 ? (
            <div className="row">
              <div className="col-sm-12">
                <form
                  className="needs-validation"
                  autoComplete="off"
                  //onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <div className="registration_forms registration_sec">
                    <div className="sub_heading">
                      <h2>Verify Account</h2>
                    </div>
                    <div className="already_login2">Send OTP on</div>
                    <div className="forgot_otp_btn veri_mar">
                      <div className="m_t_20">
                        <button
                          onClick={() => {
                            setIsEmail(1);
                            //sendOtp();
                          }}
                          type="button"
                          className={
                            isEmail == 1
                              ? "btn btn-success sig_but"
                              : "btn btn-success create_but"
                          }
                        >
                          Email
                        </button>
                      </div>
                      <div className="m_t_20">
                        <button
                          onClick={() => {
                            setIsEmail(2);
                            //sendOtp();
                          }}
                          type="button"
                          className={
                            isEmail == 2
                              ? "btn btn-success sig_but"
                              : "btn btn-success create_but"
                          }
                        >
                          Mobile
                        </button>
                      </div>
                      <div className="m_t_20">
                        <button
                          type="button"
                          onClick={() => {
                            props.setIsOpen(!props.isOpen);
                            AuthService.logout();
                          }}
                          data-dismiss="modal"
                          className="btn create_but  veri_but"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                </form>
              </div>
            </div>
          ) : (
            ""
          )}

          {screen == 2 ? (
            <form autoComplete="off" noValidate>
              <div className="form-group">
                <label htmlFor="email">Enter Code to confirm </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="code"
                  ref={register2({
                    required: "Code is required",
                  })}
                />
                <ErrorMessage
                  errors={errors2}
                  name="code"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
                {showTimer ? (
                  <Timer
                    initialTime={60000 * 5}
                    direction="backward"
                    onStop={() => {
                      console.log("stop");
                    }}
                    onResume={() => console.log("onResume hook")}
                    checkpoints={[
                      {
                        time: 0,
                        callback: () => {
                          console.log("Checkpoint A");
                          setShowTimer(false);
                          setTimerMsg("OTP Expired.");
                          setDisabled(true);
                        },
                      },
                    ]}
                  >
                    {({ reset }: { reset: any }) => (
                      <React.Fragment>
                        <div className="login_validation">
                          {" "}
                          OTP Expires in <Timer.Minutes /> Minutes,{" "}
                          <Timer.Seconds /> Seconds
                        </div>
                      </React.Fragment>
                    )}
                  </Timer>
                ) : (
                  ""
                )}
                {timerMsg ? (
                  <div className="login_validation">{timerMsg}</div>
                ) : (
                  ""
                )}
              </div>
            </form>
          ) : (
            ""
          )}
        </div>

        {screen == 2 ? (
          <div className="already_login1">
            Didn't get the code?
            <a
              className="_cursor-pointer"
              onClick={() => {
                if (!showTimer) {
                  sendVerification();
                }
              }}
            >
              &nbsp;Send again.
            </a>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
      <div className="modal-footer  m-t-30">
        {screen == 2 ? (
          <button
            onClick={handleSubmit2(onSubmit2)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
            disabled={disabled}
          >
            Verify
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="clearfix" />
    </React.Fragment>
  );
}

interface ILoginState {
  email: string;
  password: string;
  rememberMe: boolean;
}

function Login(props: any) {
  const defaultValues = {
    email: "",
    password: "",
    rememberMe: true,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
  } = useForm<ILoginState>({
    defaultValues,
  });

  const [showPassword, setShowPassword] = useState<Boolean>(false);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [superAdmin, setSuperAdmin] = useState<boolean>(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [contactNumber, setContactNumber] = useState("");

  const [countryCode, setCountryCode] = useState("");

  const [locationKeys, setLocationKeys] = useState<(string | undefined)[]>([]);
  const history = useHistory();

  let paramState =
    props.history.location.state != undefined &&
    props.history.location.state.stateValue != undefined
      ? props.history.location.state.stateValue
      : "";

  let paramStateValue =
    props.history.location.state != undefined &&
    props.history.location.state.stateValue != undefined
      ? props.history.location.state.stateValue.split("/")[2]
      : "";

  // console.log(77778, history.goForward());

  // useEffect(() => {
  //   return history.listen((location) => {
  //     if (history.action === 'PUSH') {
  //       if (location.key) setLocationKeys([location.key]);
  //     }

  //     if (history.action === 'POP') {
  //       if (locationKeys[1] === location.key) {
  //         setLocationKeys(([_, ...keys]) => keys);
  //         // Handle forward event
  //         console.log('forward button');
  //         setIsOpen(false);
  //         AuthService.logout();
  //       } else {
  //         setLocationKeys((keys) => [location.key, ...keys]);
  //         // Handle back event
  //         console.log('back button');
  //       }
  //     }
  //   });
  // }, [locationKeys]);

  // useEffect(() => {
  //   console.log("----superadminState 01----");
  //   if (superAdmin) {
  //     props.history.push("/super_admin");
  //   }
  // }, [superAdmin]);

  const onSubmit = async (data: any) => {
    console.log(22, data.email.toLowerCase());
    if (data.email.toLowerCase() == "kr.shahulhameed@gmail.com") {
      if (
        data.email.toLowerCase() == "kr.shahulhameed@gmail.com" &&
        data.password === "Test@123456"
      ) {
        console.log("it is super admin");
        setEmail(data.email.toLowerCase());
        setPassword(data.password);
        setIsOpen(true);
      } else {
        toast.error("Username/Password incorrect.");
      }
    } else {
      globalSpinner.showSpinner();
      try {
        // const user = await AuthService.login(data.email, data.password, data.rememberMe);
        const user = await AuthService.login(data.email, data.password, true);
        if (user) {
          console.log(user);
          if (user.email) {
            setEmail(user.email);
          }
          if (user.phone) {
            const phonenumber = user.phone.split("-");
            setCountryCode(phonenumber[0]);
            setContactNumber(phonenumber[1]);
          }

          console.log(
            "-----------------logged user----------: " + user.roles[0]
          );
          const authorizationToken = AuthService.accessToken;
          console.log(
            "-----------------authorizationToken in login----------: " +
              authorizationToken
          );
          // console.log(77777, history);

          updateAccessToken(
            {},
            `?id=${
              AuthService.currentUser?.id
            }&accessToken=${authorizationToken}&login=${true}`
          )
            .then((res: any) => {
              console.log("updated access token ", res.data);
            })
            .catch((err: any) => {
              console.log("error in updating access token", err);
            });

          if (user.roles[0] == "Candidate") {
            if (paramStateValue === "notify-jobs") {
              props.history.push(paramState);
            } else {
              props.history.push("/candidate");
            }

            window.location.reload(false);
          } else if (user.roles[0] == "Vendor") {
            props.history.push("/vendor");
            localStorage.setItem("logged", "1");
            window.location.reload(false);
          } else if (user.roles[0] == "SuperAdmin") {
            setIsOpen(true);
          } else if (user.roles[0] == "Admin") {
            props.history.push("/admin");
          } else if (user.roles[0] == "Client") {
            props.history.push("/client");
          }
          globalSpinner.hideSpinner();
        } else {
          globalSpinner.hideSpinner();
        }
      } catch (error) {
        console.log(error);
        if (error.response.status == 400) {
          toast.error("Username/Password incorrect.");
        } else {
          toast.error("Error occurred while login.");
        }

        globalSpinner.hideSpinner();
      }
    }
  };

  const handleFbLogin = (data: any) => {
    console.log(data);

    const code = JSON.parse(atob(data.signedRequest.split(".")[1])).code;

    console.log(code);

    // socialLoginHandler({
    //   code: JSON.parse(atob(data.signedRequest.split(".")[1])).code + "",
    //   accessToken: data.accessToken,
    //   social: "fb"
    // }).then((res) => {
    //   console.log(res.data);
    // }).catch((err) => {
    //   console.log(err);
    // });
  };

  return (
    <div className=" container">
      <ToastContainer />
      <div className="row">
        <div className="col-sm-6 desk">
          <div className="login_img">
            <img
              src={require("./../../images/login_img.jpg")}
              className="img-responsive"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-5 col-lg-offset-1">
          <div className="login_forms registration_sec">
            <div className="sub_heading">
              <h1>Welcome</h1>
              <p>
                To keep connected with us please login with your email address
                and password.
              </p>
            </div>
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              noValidate
            >
              <div className="login_form_sec">
                <div className="form-group">
                  <div className="login_icon">
                    <img src={require("./../../images/mailicon.jpg")} />
                  </div>
                  <div className="login_f">
                    <label>Email Address</label>
                    <input
                      type="text"
                      className="form-control input-name"
                      placeholder="Username"
                      name="email"
                      ref={register({
                        required: "Email is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Should be a valid email",
                        },
                      })}
                    />
                    {/* <div className="login_validation">Error</div> */}
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="login_br" />
                <div className="form-group">
                  <div className="login_icon">
                    <img src={require("./../../images/lock_icon.jpg")} />
                  </div>
                  <div className="login_f">
                    <div className="fons_lg">
                      <label>Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control validate input-pwd"
                        placeholder="Password"
                        name="password"
                        ref={register({
                          required: "Password is required",
                        })}
                      />
                      <span
                        onClick={(event: any) => {
                          setShowPassword(!showPassword);
                        }}
                        className={
                          showPassword
                            ? "fa fa-fw fa-eye field-icon toggle-password"
                            : "fa fa-fw fa-eye-slash field-icon toggle-password"
                        }
                      />
                      {/* <div className="login_validation">Error</div> */}
                      <ErrorMessage
                        errors={errors}
                        name="password"
                        render={({ message }) => (
                          <div className="login_validation">{message}</div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row m_t_25">
                  <div className="col-xs-6">
                    <input
                      id="checkbox1"
                      type="checkbox"
                      name="rememberMe"
                      ref={register}
                    />
                    <label htmlFor="checkbox1">
                      <span /> Remember Me{" "}
                    </label>
                  </div>
                  <div className="col-xs-6">
                    <div className="forgot">
                      <Link to="/forgot-password-otp">Forgot Password</Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Link  to="/candidate"> */}
              <button
                type="submit"
                style={{ marginLeft: 75, width: 137 }}
                className="btn sig_but"
              >
                Login Now
              </button>
              {/* </Link> */}
              <a href="index.html">
                <button
                  type="button"
                  className="btn create_but"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push("/registration");
                  }}
                >
                  Create An Account
                </button>
              </a>
            </form>
            <div className="clearfix" />
            <div className="already_login">{/* Or connect with */}</div>
            <div className="sign_with">
              {/*<GoogleLoginButton />
              <FacebookLoginButton handleLogin={handleFbLogin} />
              <TwitterLoginButton />
              <LinkedinLoginButton />*/}
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
      {/* div added for design fix while hiding social login */}
      <div className="row m_t_60"></div>
      {/*  ****end****  */}
      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Verification
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          setSuperAdmin={setSuperAdmin}
          superAdmin={superAdmin}
          email={email}
          countryCode={countryCode}
          contactNumber={contactNumber}
          password={password}
          history={props.history}
        />
      </Modal>
    </div>
  );
}

export default withRouter(Login);
