import React from "react";
import {
  getAdminPermissionList,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import {
  getExperienceList,
  useExperienceContext,
  useExperienceDispatcher,
} from "../../../action/general/ExperienceAction";

import {
  getAdminLoggedUserId,
  getAdminMyProfileBasicInfo,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import {
  getVendorProfileImage,
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../../action/MyProfileVendorAction";
import { permisionRequestModel } from "../../../models/admin/AdminMyJob";
import { experienceRequestModel } from "../../../models/general/Experience";
import { VendorProfileImageRequestModel } from "../../../models/vendor/MyProfileSelectBoxData";
import AuthService from "../../../services/AuthService";
import AdminMyJobComponent from "./AdminMyJobComponent";

const AdminMyJobContainer = () => {
  const myVendorProfileDispatcher = useVendorMyProfileDispatcher();
  const myVendorProfileContext = useVendorMyProfileContext();
  // const {
  //   loggedUserId,
  //   myProfile,
  //   myProfileProgressBar,
  //   profileImage,
  // } = myVendorProfileContext;

  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const {
    //myProfile,
    basicInfo,
    loggedAdminId,
  } = myProfileAdminContext;

  const experienceDispatcher = useExperienceDispatcher();

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null) {
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          parseInt(user.id),
          authorizationToken
        );
      })();

      (async () => {
        await getAdminMyProfileBasicInfo(
          myProfileAdminDispatcher,
          authorizationToken
        );
      })();
    }
  }, [authorizationToken]);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getExperienceList(experienceDispatcher, {
          page: 1,
          pageSize: 30,
          searchTerm: "",
          sortOrder: "",
        } as experienceRequestModel);
      })();
    }
  }, []);

  // React.useEffect(() => {
  //   if (authorizationToken != null && user?.id != null)
  //     (async () => {
  //       await getAdminLoggedUserId(
  //         myProfileAdminDispatcher,
  //         parseInt(user.id),
  //         authorizationToken
  //       );
  //     })();
  // }, [authorizationToken]);

  // React.useEffect(() => {
  //   if (authorizationToken != null) {
  //     (async () => {
  //       await getVendorProfileImage(
  //         myVendorProfileDispatcher,
  //         {
  //           page: 10,
  //           pageSize: 10,
  //           searchTerm: "",
  //           showInactive: true,
  //           sortOrder: "",
  //           vendorId: loggedUserId,
  //         } as VendorProfileImageRequestModel,
  //         authorizationToken
  //       );
  //     })();
  //   }
  // }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getAdminPermissionList(
          adminMyJobDispatcher,
          {
            PermissionId: 12,
          } as permisionRequestModel,
          authorizationToken
        );
      })();
    }
  }, [authorizationToken]);

  return (
    <>
      <AdminMyJobComponent />
    </>
  );
};
export default AdminMyJobContainer;
