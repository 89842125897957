import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../../../context/GlobalSpinner";
import { saveCandidate } from "../../../../apis/candidate";
import PhoneNumberWithCode from "../../../components/PhoneNumberWithCode";
import IsComponentDisabled from "../../../components/IsComponentDisabled";

type objectIndexing = {
  [key: string]: any;
};

interface ICommunicationState {
  rowId: string;
  contactNumber: string;
  countryCode: string;
  email: string;
  telephone: string;
  permanantAddress: string;
  currentAddress: string;
  sameAsPermanantAddress: boolean;
}

function Communication(props: any) {
  const { candidateId, candidateState, getCandidates } = props;
  const [emailData, setEmailData] = useState("");
  const [phoneData, setPhoneData] = useState("");

  const defaultValues = {
    rowId: "0",
    contactNumber: "",
    countryCode: "",
    email: "",
    telephone: "",
    permanantAddress: "",
    currentAddress: "",
    sameAsPermanantAddress: false,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm<ICommunicationState>({
    defaultValues,
  });
  //console.log(candidateState.data[0])
  useEffect(() => {
    reset({
      rowId:
        candidateState.data.length > 0 ? candidateState.data[0]["rowId"] : "0",
      contactNumber:
        candidateState.data.length > 0
          ? candidateState.data[0]["phoneNumber"]
          : "",
      countryCode:
        candidateState.data.length > 0
          ? candidateState.data[0]["countryCode"]
          : "",
      email:
        candidateState.data.length > 0 ? candidateState.data[0]["email"] : "",
      telephone:
        candidateState.data.length > 0
          ? candidateState.data[0]["telephoneNumber"]
          : "",
      permanantAddress:
        candidateState.data.length > 0
          ? candidateState.data[0]["permanantAddress1"]
          : "",
      currentAddress:
        candidateState.data.length > 0
          ? candidateState.data[0]["currentAddress1"]
          : "",
      sameAsPermanantAddress: false,
    });
    if (candidateState.data.length > 0) {
      setEmailData(candidateState.data[0]["email"]);
      setPhoneData(candidateState.data[0]["phoneNumber"]);
    }
  }, [reset, candidateState.data.length]);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const onSubmit = (data: any) => {
    //console.log(data);
    if (!disabled) {
      let _data1: objectIndexing = {};

      if (candidateState.data.length > 0) {
        _data1 = candidateState.data[0];
        _data1["rowId"] = data["rowId"];
        _data1["candidateId"] = Number(candidateId);
        _data1["countryCode"] = data["countryCode"];
        // _data1["phoneNumber"] = data["contactNumber"];
        // _data1["email"] = data["email"];
        _data1["phoneNumber"] = phoneData; //data["contactNumber"];
        _data1["email"] = emailData; // data["email"];
        _data1["telephoneNumber"] = data["telephone"];
        _data1["permanantAddress1"] = data["permanantAddress"];
        _data1["currentAddress1"] = data["sameAsPermanantAddress"]
          ? data["permanantAddress"]
          : data["currentAddress"];

        //console.log(_data1);

        globalSpinner.showSpinner();

        saveCandidate(_data1)
          .then((res: any) => {
            //console.log(res.data)
            globalSpinner.hideSpinner();
            if (res.data.isSuccess) {
              getCandidates({
                CandidateId: Number(candidateId),
                Page: 1,
                PageSize: 10,
                SearchTerm: "string",
                SortOrder: "string",
                ShowInactive: false,
              });
              toast.success("Communication details are saved.");
            } else {
              toast.error(res.data.message);
            }
            //console.log(res.data);
          })
          .catch((err: any) => {
            //console.log(err);
            globalSpinner.hideSpinner();
            toast.error("Something went wrong.");
          });
      }
    }
  };

  const [disabled, setDisabled] = useState<boolean>(true);

  if (watch("sameAsPermanantAddress")) {
    if (watch("currentAddress") != watch("permanantAddress")) {
      setValue("currentAddress", watch("permanantAddress"));
      if (watch("currentAddress").length > 0) {
        clearErrors("currentAddress");
      }
    }
  }

  return (
    <React.Fragment>
      {/*---------Start-------------------*/}
      <ToastContainer />

      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-1">
        <span className="resp-arrow" />
        Communication
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-1"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Communication</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>
        <form
          className="personal_details"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <input
            type="number"
            name="rowId"
            style={{ display: "none" }}
            disabled={disabled}
            ref={register}
          />
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <label className={"required"}> Contact Number</label>
                {IsComponentDisabled(
                  <Controller
                    control={control}
                    name="countryCode"
                    render={({ onChange, onBlur, value, name }) => (
                      <PhoneNumberWithCode
                        //codeHeight={"50px"}
                        noPenIcon={true}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        //disabled={disabled}
                        disabled={true}
                        phoneNo={
                          <input
                            type="text"
                            name="contactNumber"
                            disabled={true}
                            //disabled={disabled}
                            id="contactNumber"
                            className="form-control phoneNumber"
                            placeholder="Mobile number"
                            ref={register({
                              required: "Mobile number is required",
                              maxLength: {
                                value: 10,
                                message: "Please enter a valid mobile number",
                              },
                              pattern: {
                                value: /^[0-9]*$/,
                                message: "Mobile number should be numbers only",
                              },
                              minLength: {
                                value: 10,
                                message: "Mobile number must have 10 digits",
                              },
                            })}
                          />
                        }
                      />
                    )}
                  />,
                  disabled
                )}

                {/* <input type="text" name="contactNumber" className={disabled ? "form-control _cursor-url" : "form-control"} placeholder="Type here" disabled={disabled} ref={register({
                  required: "Contact Number is required",
                  maxLength: {
                    value: 15,
                    message: "Should be a Contact Number"
                  },
                })} /> */}
                <ErrorMessage
                  errors={errors}
                  name="contactNumber"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label className={"required"}> Email</label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    className={"form-control input-name"}
                    placeholder="Type here"
                    disabled={true}
                    name="email"
                    ref={register({
                      required: "Email is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Please enter a valid e-mail address.",
                      },
                    })}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label> Telephone</label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="telephone"
                    disabled={disabled}
                    className={"form-control form-control-n"}
                    placeholder="Type here"
                    ref={register({
                      required: false,
                      // required: "Telephone is required",
                      // maxLength: {
                      //   value: 15,
                      //   message: "Please enter a valid Telephone"
                      // },
                      // pattern: {
                      //   value: /^[0-9]*$/,
                      //   message: "Telephone should be numbers only"
                      // }
                    })}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="telephone"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label className={"required"}>Permanent Address</label>
                {IsComponentDisabled(
                  <textarea
                    disabled={disabled}
                    name="permanantAddress"
                    className={"form-control form-contro11"}
                    placeholder="Type here"
                    ref={register({
                      required: "Permanant Address cannot be blank.",
                      // validate: (value) => {equired
                      //   //exclude  start and end white-space
                      //   value = value.replace(/(^\s*)|(\s*$)/gi, "");
                      //   //convert 2 or more spaces to 1
                      //   value = value.replace(/[ ]{2,}/gi, " ");
                      //   // exclude newline with a start spacing
                      //   value = value.replace(/\n /, "\n");

                      //   if (value.split(" ").length < 8) {
                      //     return "Minimum 8 words are required in Permanant Address.";
                      //   } else if (value.split(" ").length > 30) {
                      //     return "Maximum words: 30";
                      //   } else {
                      //     return undefined;
                      //   }
                      // }
                    })}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="permanantAddress"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label className={"required"}> Current Address</label>
                {IsComponentDisabled(
                  <textarea
                    disabled={disabled}
                    name="currentAddress"
                    className={"form-control form-contro11"}
                    placeholder="Type here"
                    ref={register({
                      required: "Current Address cannot be blank.",
                      // validate: (value) => {
                      //   //exclude  start and end white-space
                      //   value = value.replace(/(^\s*)|(\s*$)/gi, "");
                      //   //convert 2 or more spaces to 1
                      //   value = value.replace(/[ ]{2,}/gi, " ");
                      //   // exclude newline with a start spacing
                      //   value = value.replace(/\n /, "\n");

                      //   if (value.split(" ").length < 8) {
                      //     return "Minimum 8 words are required in Current Address.";
                      //   } else if (value.split(" ").length > 30) {
                      //     return "Maximum words: 30";
                      //   } else {
                      //     return undefined;
                      //   }
                      // }
                    })}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="currentAddress"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email">&nbsp;</label>
                {/*Same as permanant address */}
                {IsComponentDisabled(
                  <div>
                    <input
                      id="_sameAsPermanantAddress"
                      type="checkbox"
                      disabled={disabled}
                      name="sameAsPermanantAddress"
                      ref={register}
                      className={"_cursor-pointer"}
                    />
                    <label htmlFor="_sameAsPermanantAddress">
                      <span
                        className={
                          disabled
                            ? "_add-experience-checkbox"
                            : "_add-experience-checkbox _cursor-pointer"
                        }
                      ></span>{" "}
                      &nbsp;
                    </label>
                    <label htmlFor="email">Same as permanant address</label>
                  </div>,
                  disabled
                )}
              </div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="clearfix" />
          <button type="submit" className="btn submit_btn" disabled={disabled}>
            Save
          </button>
        </form>
      </div>
      {/*---------End-------------------*/}
    </React.Fragment>
  );
}

export default Communication;
