import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import {
  getAchievementsList,
  deleteAchievements,
  useAchievementsContext,
  useAchievementsDispatcher,
} from "../../../../action/admin/AchievementsAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../../action/MyProfileAdminAction";
import {
  AchievementsRequestModel,
  AchievementsEditRequestModel,
  Achievements,
  AchievementsViewModel,
  AchievementsSaveRequestModel,
  AchievementsSaveRespondModel,
  AchievementsDeleteRequestModel,
} from "../../../../models/admin/Achievements";

import AuthService from "../../../../services/AuthService";
import AchievementsForm from "./Achievements";
import { Popover } from "antd";
import Popconfirm from "antd/lib/popconfirm";
import { environment } from "../../../../environments/environment";
import {
  useAdminMyJobDispatcher,
  useAdminMyJobContext,
} from "../../../../action/admin/AdminMyJobAction";

interface IAchievementsComponentProps {}

interface IAchievementsComponentState {}

const defaultValues = {};

const AchievementsComponent: React.FC<IAchievementsComponentProps> = (
  props
) => {
  const [
    AchievementsComponentState,
    setAchievementsComponentState,
  ] = React.useState<IAchievementsComponentState>(defaultValues);

  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;

  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const { myProfile, basicInfo, loggedAdminId } = myProfileAdminContext;

  const adminId = loggedAdminId;

  //console.log("userId", userId, "adminId", adminId);

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  const AchievementsDispatcher = useAchievementsDispatcher();
  const AchievementsContext = useAchievementsContext();
  const { Achievements, AchievementsSaveRespond } = AchievementsContext;
  //console.log("----------Achievements-----------",Achievements);

  let history = useHistory();

  const [isAchievementsOpen, setisAchievementsOpen] = useState<boolean>(false);
  const [AchievementsId, setAchievementsId] = useState<number>(0);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IAchievementsComponentState>({
    defaultValues,
  });

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      //console.log('Achievements call getAchievementsList',adminId);
      (async () => {
        await getAchievementsList(
          AchievementsDispatcher,
          {
            userId: 0,
            page: 1,
            pageSize: 60,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as AchievementsRequestModel,
          authorizationToken
        );
      })();
    }
  });

  const handleAchievementsDelete = (AchievementsId: number) => {
    if (authorizationToken != null)
      deleteAchievements(
        AchievementsDispatcher,
        {
          rowId: AchievementsId,
        } as AchievementsDeleteRequestModel,
        authorizationToken
      );
  };

  const handleJobApplayClick = (id: any) => {
    setAchievementsComponentState({
      ...AchievementsComponentState,
      myTeamMemberId: id,
    });
    handleAchievementsOpen();
  };

  const handleAchievementsOpen = () => {
    setisAchievementsOpen(!isAchievementsOpen);
  };

  const handleAchievementOpen = () => {
    setisAchievementsOpen(!isAchievementsOpen);
  };

  const menuRef = useRef<any>();

  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setIsListOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Achievements</h1>
          <a
            onClick={() => {
              setAchievementsId(0);
              handleAchievementsOpen();
            }}
            data-target="#create_jobdescription"
            data-toggle="modal"
            className="btn cutomise_but"
          >
            <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp; Add New
          </a>
          <div className="action_btn1">
            <button
              type="button"
              className="actions_menu"
              onClick={() => {
                setIsListOpen(!isListOpen);
              }}
            >
              <i className="fa fa-align-left" aria-hidden="true" />
            </button>
            <div
              className="user_menu"
              tabIndex={-1}
              style={{ display: isListOpen ? "block" : "none" }}
            >
              <div className="action_sec">
                <ul ref={menuRef}>
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isMyTeamPermission ? (
                    <li>
                      <Link to="/admin/myteammember">My Team</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isStoriesofJIT ? (
                    <li>
                      <Link to="/admin/story">Stories of JIT</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isCompanyContactsandSocialPermission ? (
                    <li>
                      <Link to="/admin/socialaccounturl">
                        Company Contacts and Social Media
                      </Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isAchievementPermission ? (
                    <li>
                      <Link to="/admin/achievements">Achievements</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  (AdminPermissionResult.isAddJobCategoryPermission ||
                    AdminPermissionResult.isAddJobTitlePermission) ? (
                    <li>
                      <Link to="/admin/sitesettings/jobCategory">
                        Category / Job Title
                      </Link>{" "}
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="career_tips_ach">
            <Scrollbars
              style={{ height: 750 }}
              autoHide
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    position: "relative",
                    display: "block",
                    width: "5px",
                    cursor: "pointer",
                    borderRadius: "inherit",
                    backgroundColor: "rgb(73, 69, 69)",
                    height: "115px",
                  }}
                />
              )}
            >
              {Achievements.data != undefined
                ? Achievements.data.map((achievement, i) => {
                    return (
                      <div className="col-sm-4" key={`${i}`}>
                        <div className="achievements_sec">
                          <div className="achieve_img">
                            <img
                              width="325px!important"
                              height="184px!important"
                              src={
                                environment.baseUrl +
                                `/Upload/AchievementDocument/${achievement.achievementDocument}`
                              }
                              // className="img-responsive"
                            />
                          </div>
                          <h2>{achievement.achievementTitle}</h2>
                          <p>{achievement.description}</p>
                          <div className="admin_edit">
                            <a
                              onClick={() => {
                                setAchievementsId(achievement.rowId);
                                handleAchievementOpen();
                              }}
                              data-target="#post_career"
                              data-toggle="modal"
                            >
                              Edit
                            </a>
                          </div>
                          <div className="admin_edit">
                            <Popconfirm
                              title={
                                <div>
                                  <p>
                                    Are you sure to remove this achievement?
                                  </p>
                                </div>
                              }
                              onConfirm={() =>
                                handleAchievementsDelete(achievement.rowId)
                              }
                              //onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <a
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                Remove
                              </a>
                            </Popconfirm>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </Scrollbars>
          </div>
          <div className="clearfix" />
          <AchievementsForm
            isAchievementsOpen={isAchievementsOpen}
            handleAchievementsOpen={handleAchievementsOpen}
            currentAchievementsId={AchievementsId}
            loggedAdminId={loggedAdminId}
            authorizationToken={authorizationToken}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default AchievementsComponent;
