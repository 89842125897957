import React from "react";
import CareerTipsApprovalComponent from "./CareerTipsApprovalComponent";
import AuthService from "./../../../services/AuthService";

interface ICareerTipsApprovalContainerProps { }

interface ICareerTipsApprovalContainerState { }

const initialState = {};

const CareerTipsApprovalContainer: React.FC<ICareerTipsApprovalContainerProps> = (props) => {
  const [CareerTipsApprovalContainerState, setCareerTipsApprovalContainerState] = React.useState<ICareerTipsApprovalContainerState>(
    initialState
  );
  return (
    <>
      <CareerTipsApprovalComponent/>
    </>
  );
};
export default CareerTipsApprovalContainer;
