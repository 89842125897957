import React from "react";

import { BrowserRouter } from "react-router-dom";
import IndexFooterContainer from "../index/indexFooter/IndexFooterContainer";
import HelpComponent from "./HelpComponent";

interface IHelpContainerProps { }

interface IHelpContainerState { }

const initialState = {};

const HelpContainer: React.FC<IHelpContainerProps> = (props) => {
  const [startUpState, setHelpContainerState] = React.useState<IHelpContainerState>(
    initialState
  );

  return (
    <>
    <HelpComponent/>
    <IndexFooterContainer />
    </>
  );
};
export default HelpContainer;
