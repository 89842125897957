import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../candidate/my_profile/components/SelectOption";
import {
  getCareerTipsByCategoryWithOutToken,
  getCareerTipsSelectBoxList,
  useCareerTipsApprovalContext,
  useCareerTipsApprovalDispatcher,
} from "../../action/admin/CareerTipsApprovalAction";
import { CareerTipsByCategoryRequestModel } from "../../models/admin/CareerTipsApproval";
import { useParams } from "react-router-dom";
import _ from "lodash";
import CareerPost from "./CareerPost";
import AuthService from "../../services/AuthService";

interface ICareerTipsCategoryComponentProps {
  currentCategoryId: number;
}

interface ICareerTipsCategoryComponentState {}

const initialState = {};

const defaultValues = {
  careerTipsCategoryId: 0,
};

interface ReactSelectOption {
  value: string;
  label: string;
}

const CareerTipsCategoryComponent: React.FC<ICareerTipsCategoryComponentProps> = (
  props: any
) => {
  const [
    startUpState,
    setCareerTipsCategoryComponentState,
  ] = React.useState<ICareerTipsCategoryComponentState>(initialState);
  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;
  const cl = props.location != undefined ? props.location.pathname : null;
  const history = useHistory();
  let { id } = useParams();
  let { title } = useParams();
  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });
  const { currentCategoryId } = props;

  const careerTipsApprovalDispatcher = useCareerTipsApprovalDispatcher();
  const careerTipsApprovalContext = useCareerTipsApprovalContext();
  const {
    careerTipsByCategory,
    CareerTipsSelectBoxDataViewModel,
  } = careerTipsApprovalContext;
  const [isCareerPostOpen, setIsCareerPostOpen] = useState<boolean>(false);
  const [loggedUserId, setLoggedUserId] = useState<number>(0);
  const [categoryID, setCategoryId] = useState<number>(id);
  const [categoryTitle, setCategoryTitle] = useState<string>(title);
  const [listFlag, setListFlag] = useState(0);

  const handleCareerPostOpen = () => {
    setIsCareerPostOpen(!isCareerPostOpen);
  };

  const handleCategorySelected = (catId: number) => {
    if (catId != undefined) {
      console.log("setting category id:", catId);
      setCategoryId(catId);
      console.log("setting category title:", careerTipsCategories[catId].label);
      if (careerTipsCategories[catId] != undefined) {
        setCategoryTitle(careerTipsCategories[catId].label);
      }
    }
  };

  React.useEffect(() => {
    if (userId != null && userId != undefined) {
      setLoggedUserId(Number(userId));
    }
  }, [userId]);

  React.useEffect(() => {
    if (userId != null && userId != undefined) {
      setLoggedUserId(Number(userId));
    }
  }, [userId]);

  React.useEffect(() => {
    console.log(
      categoryID,
      "----------careerTipsByCategory-----------",
      careerTipsByCategory
    );
    (async () => {
      CareerTipsSelectBoxDataViewModel.careerTipsCategories.map((e: any) => {
        if (e["value"] == categoryID) {
          setCategoryTitle(e["caption"]);
        }
      });

      await getCareerTipsByCategoryWithOutToken(careerTipsApprovalDispatcher, {
        categoryId: categoryID,
        page: 1,
        pageSize: 60,
        searchTerm: "",
        showInactive: false,
        sortOrder: "",
      } as CareerTipsByCategoryRequestModel);
    })();
  }, [categoryID]);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getCareerTipsSelectBoxList(careerTipsApprovalDispatcher);
      })();
    }
  }, []);

  let careerTipsCategories: ReactSelectOption[] = [];
  if (!_.isEmpty(CareerTipsSelectBoxDataViewModel)) {
    careerTipsCategories = CareerTipsSelectBoxDataViewModel.careerTipsCategories.map(
      (e: any) => {
        return { value: e["value"], label: e["caption"] };
      }
    );
  }

  const [selItems, setSelItems] = useState(0);
  const handleRead = (rowId: number, desc: string) => {
    console.log("handleRead 0:");
    if (selItems === rowId) {
      setSelItems(0);
    } else {
      setSelItems(rowId);
    }
  };

  return (
    <React.Fragment>
      {/* Header Start */}

      <div className="header header1">
        <header>
          <div className=" container">
            <div className="row">
              <div className="col-sm-2">
                <div className=" logo">
                  <a
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    <img
                      src={require("../../images/logo.png")}
                      // src={require("../../images/logo.png"
                      className="img-responsive"
                      alt=""
                    />{" "}
                  </a>
                </div>
              </div>
              <div className="col-sm-10">
                <div className="bs-example">
                  <nav
                    role="navigation"
                    className="navbar navbar-default navbar-static"
                    id="navbar-example"
                  >
                    <div className="navbar-header">
                      <button
                        data-target=".bs-example-js-navbar-collapse"
                        data-toggle="collapse"
                        type="button"
                        className="navbar-toggle"
                      >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                      </button>
                      <div className="collapse navbar-collapse bs-example-js-navbar-collapse">
                        <ul className="nav navbar-nav">
                          <li>
                            <Link to="/" className={cl === "/" ? "active" : ""}>
                              {" "}
                              Home
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/admin/careerdeveloper"
                              className={
                                cl === "/admin/careerdeveloper" ? "active" : ""
                              }
                            >
                              Career Developer
                            </Link>
                          </li>
                          {/* <li>
                            {" "}
                            <Link
                              to="/coming_soon"
                              className={cl === "/coming_soon" ? "active" : ""}
                            >
                              Client{" "}
                            </Link>
                          </li> */}
                          {/* <li>
                            {" "}
                            <Link
                              to="/vendor"
                              className={cl === "/vendor" ? "active" : ""}
                            >
                              Vendor
                            </Link>{" "}
                          </li> */}
                          <li>
                            <Link
                              to={`/help`}
                              className={cl === "/help" ? "active" : ""}
                            >
                              Help
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/about_us"
                              className={cl === "/about_us" ? "active" : ""}
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact_us"
                              className={cl === "/contact_us" ? "active" : ""}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      {/* Header ends */}

      {/*  */}
      <div className="category_sec_sub">
        <div className="post_career_tip1">
          <a
            onClick={() => {
              handleCareerPostOpen();
            }}
            data-target="#post_career"
            data-toggle="modal"
          >
            Post a Career Tip
          </a>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3>Career Tips </h3>
              <h1>{String(categoryTitle)}</h1>
              <div className="action_btn1">
                <div className="categoriesbtn">
                  {/* <a href="career_tips.html" className="careertips_btn">
                    {" "}
                    Categories{" "}
                  </a> */}
                  {/* <label htmlFor="email">Category</label> */}

                  {/* <select
                    style={{marginRight:'10px'}}
                    value={categoryID}
                    onChange={(e: any) => {
                      if (e.target.value) {
                        setCategoryId(e.target.value);
                      }
                    }}
                  >
                    {careerTipsCategories.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select> */}

                  {/* <Controller
                    rules={{
                      required: "Category is required",
                      validate: function (value) {
                        console.log(value);
                        return Number(value)
                          ? undefined
                          : "Category is required";
                      },
                    }}
                    control={control3}
                    name="careerTipsCategoryId"
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        disabled={false}
                        values={careerTipsCategories}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  /> */}
                  <Link to="/career_tips"> Back </Link>
                </div>
                <div
                  className="caretips_action"
                  tabIndex={-1}
                  style={{ display: "none" }}
                >
                  <div className="action_sec">
                    <ul>
                      <li>
                        <Link to="/career_tips_category">General </Link>
                      </li>
                      <li>
                        <a href="#">Training </a>
                      </li>
                      <li>
                        <a href="#">Job hunt </a>
                      </li>
                      <li>
                        <a href="#">Profile </a>
                      </li>
                      <li>
                        <a href="#">Resume and Cover letter </a>
                      </li>
                      <li>
                        <a href="#">Communication </a>
                      </li>
                      <li>
                        <a href="#">Interview </a>
                      </li>
                      <li>
                        <a href="#">Telephonic Skills </a>
                      </li>
                      <li>
                        <a href="#">Personality Skills </a>
                      </li>
                      <li>
                        <a href="#">Work life management </a>
                      </li>
                      <li>
                        <a href="#">Job shifting</a>
                      </li>
                      <li>
                        <a href="#">Role switching </a>
                      </li>
                      <li>
                        <a href="#">Taking a brake </a>
                      </li>
                      <li>
                        <a href="#">Excelling in career </a>
                      </li>
                      <li>
                        <a href="#">Positive attitude </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix" />
            {careerTipsByCategory.data != undefined
              ? //&& careerTipsByCategory.data.length > 0
                careerTipsByCategory.data.map((career, i) => {
                  //setListFlag(1);
                  return (
                    <div className="col-sm-6 col-lg-4">
                      <div className="categories_sec">
                        <div className="achieve_img">
                          <img
                            width="325px!important"
                            height="184!important"
                            src={
                              career.careerTipsDocument !== "null" &&
                              career.careerTipsDocument !== null &&
                              career.careerTipsDocument !== undefined
                                ? `https://careerapp.azurewebsites.net/Upload/CareerTipsDocument/${career.careerTipsDocument}`
                                : require("../../images/noimage.png")
                            }
                            //className="img-responsive"
                          />
                        </div>
                        <h2>{career.name} </h2>
                        <p>
                          {career.rowId !== selItems
                            ? career.description.length > 45
                              ? career.description.substring(0, 45) + "..."
                              : career.description
                            : career.description}
                        </p>
                        <div className="category_read">
                          <a
                            onClick={() =>
                              handleRead(career.rowId, career.description)
                            }
                          >
                            <img
                              src={require("../../images/achive_more.png")}
                            />{" "}
                            {career.rowId === selItems && (
                              <>
                                {" "}
                                {career.rowId === selItems
                                  ? "Read Less"
                                  : "Read More"}{" "}
                              </>
                            )}
                            {career.rowId !== selItems && <> Read More </>}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })
              : () => {
                  return <div>No career tips</div>;
                }}
            {/* {listFlag===0?(<div><p>No career tips!</p></div>):null} */}
          </div>
        </div>
      </div>
      {/*  */}
      <CareerPost
        isCareerTipsOpen={isCareerPostOpen}
        handleCareerTipsOpen={handleCareerPostOpen}
        currentCareerTipsId={0}
        loggedUserId={loggedUserId}
        authorizationToken={null}
      />
    </React.Fragment>
  );
};
export default CareerTipsCategoryComponent;
