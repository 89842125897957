import React from "react";
import ReactTooltip from 'react-tooltip';

type objectIndexing = {
  [key: string]: any
}

function IsComponentDisabled(com: any, disabled: boolean, message?: string) {

  const _props: objectIndexing = {}

  if (disabled) {
    _props["data-tip"] = `You are in read-only mode now. <br/>Please click pencil(icon) at the <br/>top-right to switch to edit mode.`;
  }

  return (
    <React.Fragment>
      <ReactTooltip place="top" type="dark" effect="solid" multiline={true} />
      <div {..._props}>{com}</div>
    </React.Fragment>
  );

}

export default IsComponentDisabled;