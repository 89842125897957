import React, { useState } from "react";
import {
  getJobBookMarkList,
  useJobBookMarkContext,
  useJobBookMarkDispatcher,
} from "../../../action/candidate/JobBookMarkAction";
import { jobBookMarkRequestModel } from "../../../models/candidate/BookMarkedJob";
import AuthService from "../../../services/AuthService";
import CandidateLayoutComponent from "./CandidateLayoutComponent";
import { CandidateContextProvider } from "./../../../context/candidate/CandidateMyProfile";
import {
  getJobAppliedList,
  useJobAppliedContext,
  useJobAppliedDispatcher,
} from "../../../action/JobAppliedAction";
import { jobAppliedRequestModel } from "../../../models/candidate/JobApplied";
import {
  getLoggedUserId,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import IdleTimeChecker from "../../IdleTimeChecker";
import Countdown from "react-countdown";
import CountDownTime from "../digilocker/CountDownForDigilocker";
import { useGlobelCounterContext } from "../../../action/general/GlobelCounterAction";

interface ICandidateLayoutContainerProps {}

interface ICandidateLayoutContainerState {}

const initialState = {};

const CandidateLayoutContainer: React.FC<ICandidateLayoutContainerProps> = (
  props
) => {
  const [
    CandidateLayoutContainerState,
    setCandidateLayoutContainerState,
  ] = React.useState<ICandidateLayoutContainerState>(initialState);

  const authorizationToken = AuthService.accessToken;
  const myProfileDispatcher = useMyProfileDispatcher();
  const jobBookMarkDispatcher = useJobBookMarkDispatcher();
  const jobAppliedDispatcher = useJobAppliedDispatcher();
  const jobAppliedContext = useJobAppliedContext();
  const { jobApplied } = jobAppliedContext;
  const globelCounterContext = useGlobelCounterContext();
  const { isAccessible } = globelCounterContext;
  const [isActive, setIsActive] = useState(false);
  let user = AuthService.currentUser;

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null)
      (async () => {
        await getLoggedUserId(
          myProfileDispatcher,
          parseInt(user.id),
          authorizationToken
        );
      })();
  }, [authorizationToken]);

  React.useEffect(() => {
    if (
      authorizationToken != null &&
      user?.id != null &&
      isAccessible == true
    ) {
      setIsActive(true);
    }
  }, [isAccessible]);

  console.log(5577, isAccessible);

  const handleCompleted = () => {
    //console.log("hhhhhhhhhhhhhhaaaaaaaaaaaaiiiiiiiiiiiiiiiiiiiii");
    // (() => {
    //   SetAccessible(
    //     globelCounterDispatcher,
    //     false
    //   );
    // })();
  };
  const handleStarteded = () => {
    //console.log("helooooooooooooooooooooooooooooooooo");
  };

  return (
    <CandidateContextProvider>
      {isAccessible ? (
        <CountDownTime autostart={true} />
      ) : (
        <CountDownTime autostart={false} />
      )}

      <CandidateLayoutComponent />
    </CandidateContextProvider>
  );
};
export default CandidateLayoutContainer;
