import { apiClient } from "../utils/httpClient";

export function getSocialAccounts(data?: any) {
  return apiClient("/api/ClientSocialAccounts/gaaa", data);
}

export function updateSocialAccounts(data?: any) {
  return apiClient("/api/ClientSocialAccounts/coea", data);
}

export function getUserList(data?: any) {
  return apiClient("/api/client/gul", data);
}

export function getMessageList(data?: any) {
  return apiClient("/api/ChatDetails/gaaa", data);
}

export function getMessageDashboardList(data?: any) {
  return apiClient("/api/client/gml", data);
}

export function updateReadStatus(data?: any) {
  return apiClient("/api/ChatDetails/chat-read", data);
}

export function sendChatFiles(data?: any) {
  return apiClient("/api/FileUpload/files", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
