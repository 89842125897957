import React, { useState, useEffect, useContext } from "react";
import { sendChatFiles } from "../../../apis/vendor";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { Modal } from "react-bootstrap";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import InfiniteScroll from "react-infinite-scroll-component";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
// import AuthService from "../../../services/AuthService";
import FileUploads from "../../../component/candidate/my_profile/components/FileUploads";
import { environment } from "../../../environments/environment";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../../context/GlobalSpinner";

interface ICandidateChatProps {
  handleDisplayModeChange: (data: any) => void;
  checkedListArray: any[];
  userId: number;
  handleRestCheckedArrays: () => void;
  loggedAdminId: number;
  jobTitle: string | null;
  JobApplicationViewJobResultModel: any;
  JobId: number;
  basicInfo: any;
  //receiver: any;
  adminState: any;
  searchOperation: boolean;
}

function ShowFileInfo(props: any) {
  const { obj } = props;
  //console.log(props);
  return (
    <React.Fragment>
      <div className="col-sm-6">
        {obj.file && (
          <div
            className="update_con"
            style={{
              width: 268,
              height: 105,
              paddingTop: 20,
            }}
          >
            {obj.file.name}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

function ChatMessageContent(props: any) {
  const {
    // totalCount,
    // fetchMoreData,
    // hasMore,
    messageShowList,
    receiveUserName,
    sendUserName,
    sendUserId,
    basicInfo,
    receiveUserId,
    downloadFile,
  } = props;
  //console.log(props);
  return (
    <React.Fragment>
      <div
        id="scrollableDiv"
        style={{
          height: 300,
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {/* <InfiniteScroll
        className="_custom-infinite-scroll-class"
        height={300}
        //dataLength={totalCount}
        dataLength={messageShowList.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        //To put endMessage and loader to the top.
        inverse={true} //
      //scrollableTarget="scrollableDiv"
      > */}
        {messageShowList.map((item: any, index: any) => {
          //start----------------------
          let chatPosition = "chat_right";
          let chatIcon = "chat_icon2";
          let subChatIcon = "icon_clr_rs2";
          let chatName = "chat_name1";
          let displayleft = item["messageTime"] + item["messageDate"];
          let displayRight = receiveUserName;

          let boxSide = "chat_box_r";
          if (item["fromUserId"] == sendUserId) {
            chatPosition = "chat_left";
            chatIcon = "chat_ico1";
            subChatIcon = "icon_clr_rs1";
            chatName = "chat_name";
            displayleft = sendUserName != undefined ? sendUserName : "";
            displayRight = item["messageTime"] + item["messageDate"];
            boxSide = "chat_box_l";
          }
          return (
            <div className={chatPosition}>
              <div className={chatIcon}>
                <div className={subChatIcon}>
                  {sendUserName != undefined ? sendUserName.charAt(0) : ""}
                </div>
              </div>
              <div className={chatName}>
                {item["fromUserId"] == sendUserId && (
                  <>
                    {displayleft}
                    <span>{displayRight}</span>
                  </>
                )}
                {item["fromUserId"] == receiveUserId && (
                  <>
                    <span>{displayleft}</span>
                    {displayRight}
                  </>
                )}
              </div>
              <div className="clearfix" />
              {item["fileType"] == "Text" && (
                <div className={boxSide} key={index}>
                  {item["message"]}
                </div>
              )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["contentHeader"].includes("image") ||
                  item["contentHeader"] == "image/jpeg") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={
                        "data:" +
                        item["contentHeader"] +
                        ";base64," +
                        item["attachment"]
                      }
                      // src={require("../../images/logo.png"
                      className="img-responsive"
                      alt=""
                      width={200}
                      height={200}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl +
                          `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["chatFile"].includes(".doc") ||
                  item["contentHeader"] ==
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={require("../../../images/word_document.png")}
                      //className="img-responsive"
                      alt={item["chatFile"]}
                      width={100}
                      height={100}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl +
                          `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["chatFile"].includes(".pdf") ||
                  item["contentHeader"] == "application/pdf") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={require("../../../images/pdf-icon.png")}
                      //className="img-responsive"
                      alt={item["chatFile"]}
                      width={100}
                      height={100}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl +
                          `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}

              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                item["contentHeader"].includes("video") && (
                  <div className={boxSide} key={index}>
                    <ReactPlayer
                      url={
                        environment.baseUrl +
                        `/Upload/ChatAttachment/${item["chatFile"]}`
                      }
                      width="50%"
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["contentHeader"].includes("audio") ||
                  item["contentHeader"] == "audio/wav") && (
                  <div className={boxSide} key={index}>
                    <ReactAudioPlayer
                      src={
                        environment.baseUrl +
                        `/Upload/ChatAttachment/${item["chatFile"]}`
                      }
                      //autoPlay
                      controls
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["chatFile"].includes(".xls") ||
                  item["contentHeader"] ==
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={require("../../../images/excel_document.png")}
                      //className="img-responsive"
                      alt={item["chatFile"]}
                      width={100}
                      height={100}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl +
                          `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}
            </div>
          );

          //end----------------------------
        })}
        {/* </InfiniteScroll> */}
      </div>
    </React.Fragment>
  );
}

const CandidateChat: React.FC<ICandidateChatProps> = (props) => {
  //console.log(props);
  const globalSpinner = useContext(GlobalSpinnerContext);
  const [sendUserId, setSendUserId] = useState<string>();
  const [sendUserName, setSendUserName] = useState<string>();
  const [receiveUserId, setReceiveUserId] = useState<string>();
  const [receiveUserName, setReceiveUserName] = useState<string>();
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);
  const [hubConnection, setHubConnection] = useState<HubConnection>();

  const [showing, setShowing] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [isOpenFile, setIsOpenFile] = useState<boolean>(false);
  const [isOpenRecorder, setIsOpenRecorder] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [recordState, setRecordState] = useState();
  const [obj, setObj] = useState<any>({ file: null });
  const [newMessage, setNewMessage] = React.useState(false);
  const [messageShowList, setMessageShowList] = useState(
    Array<{
      rowId: number;
      fromUserId: string;
      fromUserName: string;
      toUserId: string;
      toUserName: string;
      messageTime: string;
      messageDate: string;
      message: string;
      isActive: boolean;
      fileType: string;
      contentHeader: string;
      chatFile: string;
      attachment: string;
      chatDetailsId: number;
    }>()
  );

  const [DBMessageList, setDBMessageList] = useState(
    Array<{
      rowId: number;
      fromUserId: string;
      fromUserName: string;
      toUserId: string;
      toUserName: string;
      messageTime: string;
      messageDate: string;
      message: string;
      isActive: boolean;
      fileType: string;
      contentHeader: string;
      chatFile: string;
      attachment: string;
      chatDetailsId: number;
    }>()
  );

  let enterPress = true;

  let receiver = props.JobApplicationViewJobResultModel.data.filter(
    (e: any) => {
      if (
        props.checkedListArray.includes(
          props.searchOperation === true ? e.candidateId : e.userId
        )
      ) {
        ////console.log(e.userId);
        return e;
      }
    }
  );

  useEffect(() => {
    if (
      props.loggedAdminId != undefined &&
      Number(props.loggedAdminId) > 0 &&
      receiver.length > 0 &&
      (props.searchOperation === true
        ? Number(receiver[0].candidateUserId) > 0
        : Number(receiver[0].userId) > 0)
    ) {
      // //console.log(adminId);
      // Set the initial SignalR Hub Connection.
      const createHubConnection = async () => {
        const signalR = require("@aspnet/signalr");

        // Build new Hub Connection, url is currently hard coded.
        const hubConnect = new HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Debug)
          .withUrl(environment.baseUrl + "/chathub", {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
          })
          .build();

        try {
          await hubConnect.start();
          setSendUserId(props.basicInfo.userId);
          setSendUserName(
            props.adminState.data[0]["adminName"] != undefined
              ? props.adminState.data[0]["adminName"]
              : props.basicInfo.userName
          );
          // setReceiveUserId(receiver[0].userId);
          setReceiveUserId(
            props.searchOperation === true
              ? receiver[0].candidateUserId
              : receiver[0].userIdofUser
          );
          setReceiveUserName(
            props.searchOperation === true
              ? receiver[0].candidateName
              : receiver[0].userName
          );
          // Bind event handlers to the hubConnection.
          hubConnect.on(
            "ReceiveMessage",
            (
              sendUserId1: string,
              sendUserName1: string,
              receiveUserId1: string,
              receiveUserName1: string,
              message1: any
            ) => {
              // //console.log("sendUserId :", `${sendUserId1}`);
              // //console.log("sendUserName :", `${sendUserName1}`);
              // //console.log("receiveUserId :", `${receiveUserId1}`);
              // //console.log("receiveUserName :", `${receiveUserName1}`);
              // //console.log("sendUserId :", `-------------`);
              // //console.log("sendUserId :", `${sendUserId}`);
              // //console.log("sendUserName :", `${sendUserName}`);
              // //console.log("receiveUserId :", `${receiveUserId}`);
              // //console.log("receiveUserName :", `${receiveUserName}`);
              let postTime = new Date().toLocaleString("en", {
                hour: "numeric",
                hour12: true,
                minute: "numeric",
              });
              let messageText = "";
              let fileType = "";
              let contentHeader = "";
              let chatFile = "";
              let attachment = "";
              if (typeof message1 == "object") {
                messageText = message1["fileName"];
                fileType = "File";
                contentHeader = message1["fileHeaders"];
                chatFile = message1["fileName"];
                attachment = message1["fileBinary"];
              }
              if (typeof message1 == "string") {
                messageText = message1;
                fileType = "Text";
              }
              const item = {
                rowId: 0,
                fromUserId: `${sendUserId1}`,
                fromUserName: `${sendUserName1}`,
                toUserId: `${receiveUserId1}`,
                toUserName: `${receiveUserName1}`,
                messageTime: postTime,
                messageDate: "",
                message: messageText,
                isActive: true,
                fileType: fileType,
                contentHeader: contentHeader,
                chatFile: chatFile,
                attachment: attachment,
                chatDetailsId: 0,
              };
              setDBMessageList([item]);
              setNewMessage(true);
            }
          );
        } catch (err) {
          //alert(err);
          //console.log("Error while establishing connection: " + { err });
        }
        setHubConnection(hubConnect);
      };
      createHubConnection();
    }
  }, [props.loggedAdminId]);

  React.useEffect(() => {
    //console.log(DBMessageList);
    //console.log(sendUserId);
    //console.log(receiveUserId);
    if (newMessage && Number(sendUserId) > 0 && Number(receiveUserId) > 0) {
      setNewMessage(false);
      const shownDbList = DBMessageList.filter(
        (data: any) =>
          (data.fromUserId === String(sendUserId) &&
            data.toUserId === String(receiveUserId)) ||
          (data.fromUserId === String(receiveUserId) &&
            data.toUserId === String(sendUserId))
      );
      if (shownDbList != undefined && shownDbList.length > 0) {
        setMessageShowList([...shownDbList, ...messageShowList]);
      }
    }
  }, [newMessage]);

  const handleMessage = async () => {
    await sendChatMessage();
    setMessage("");
    enterPress = true;
  };

  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && enterPress) {
      enterPress = false;
      handleMessage();
    }
  };

  const emojiIconClick = () => {
    setShowEmoji(!showEmoji);
  };
  const addEmoji = (e: any) => {
    let emoji = e.native;
    setMessage(message + emoji);
  };

  /** Send message to server with client's nickname and message. */
  async function sendChatMessage(): Promise<void> {
    try {
      if (hubConnection && message !== "") {
        await hubConnection.invoke(
          "SendMessage",
          sendUserId,
          sendUserName,
          receiveUserId,
          receiveUserName,
          message
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  const sendFiles = () => {
    ////console.log(selectedFiles);
    globalSpinner.showSpinner();
    if (obj.file != undefined) {
      const formData = new FormData();
      formData.append("FormFile", obj.file);
      formData.append("FileName", obj.file.name);
      formData.append("SendUserId", String(sendUserId));
      formData.append("SendUserName", String(sendUserName));
      formData.append("ReceiveUserId", String(receiveUserId));
      formData.append("ReceiveUserName", String(receiveUserName));
      sendChatFiles(formData)
        .then((res) => {
          setIsOpenFile(false);
          setObj({ file: null });
        })
        .catch((err) => {
          //console.log(err);
          setObj({ file: null });
          toast.error("Please try again later.");
          setIsOpenFile(false);
        });
    }
    globalSpinner.hideSpinner();
  };
  const sendAudioFiles = (data: any) => {
    globalSpinner.showSpinner();
    const formData = new FormData();
    formData.append("FormFile", data.blob, "blob.wav");
    formData.append("FileName", "blob.wav");
    formData.append("SendUserId", String(sendUserId));
    formData.append("SendUserName", String(sendUserName));
    formData.append("ReceiveUserId", String(receiveUserId));
    formData.append("ReceiveUserName", String(receiveUserName));
    sendChatFiles(formData)
      .then((res) => {
        setIsOpenRecorder(false);
      })
      .catch((err) => {
        //console.log(err);
        toast.error("Please try again later.");
        setIsOpenRecorder(false);
      });
    globalSpinner.hideSpinner();
  };

  const start = () => {
    setRecordState(RecordState.START);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };

  //audioData contains blob and blobUrl
  const onStop = (audioData: any) => {
    // //console.log('audioData', audioData)
    sendAudioFiles(audioData);
  };

  const downloadFile = (filePath: any, fileName: any) => {
    var FileSaver = require("file-saver");
    FileSaver.saveAs(filePath, fileName);
  };

  const handleDrop = (files: any) => {
    if (
      ["image/jpeg", "application/pdf"].includes(files[0].type) &&
      files[0].size < 1048576 * 20
    ) {
      ////console.log(files[0]);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
    } else {
      toast.error("File size limit is 20MB");
    }
  };
  return (
    <>
      <ToastContainer />
      {/*=================Content Section Start================*/}
      {/* <div className="content-wrapper">
        <div className="container-fluid"> */}
      {/* <h1 className="heading">CHAT</h1> */}
      <div className="clearfix" />
      <div className="row " style={{ paddingTop: 10 }}>
        <div className="col-sm-12 col-lg-12">
          <div className="panel panel-default panel_n">
            <div className="panel-body panel-body1">
              <div className="connect_right">
                <div className="connect_right_top">
                  {receiver != undefined && receiver.length > 0 ? (
                    <div>
                      <div className="chat_icon1">
                        <div className="icon_clr_rs1">
                          {props.searchOperation === true
                            ? String(receiver[0].candidateName).charAt(0)
                            : String(receiver[0].userName).charAt(0)}
                        </div>
                      </div>
                      <div
                        className="connect_con_name_r"
                        style={{ paddingTop: 10 }}
                      >
                        {props.searchOperation === true
                          ? receiver[0].candidateName
                          : receiver[0].userName}
                      </div>
                      {/* <div className="connect_con_ac">
                            {String(onlineStatus).includes("false")
                              ? "Offline"
                              : "Online"}
                          </div> */}
                    </div>
                  ) : (
                    <div className="connect_con_name_r"></div>
                  )}
                </div>
                <div className=" m-t-10">
                  <div
                    //className="message_chat_des_scroll"
                    style={{ overflow: "hidden", height: "350px" }}
                    tabIndex={0}
                  >
                    <div className="connect_scroll_r">
                      <ChatMessageContent
                        messageShowList={messageShowList}
                        receiveUserName={receiveUserName}
                        sendUserName={sendUserName}
                        sendUserId={sendUserId}
                        basicInfo={props.basicInfo}
                        receiveUserId={receiveUserId}
                        downloadFile={downloadFile}
                      />
                    </div>
                  </div>
                </div>
                {showEmoji && (
                  <div className="connect_right_bottom">
                    <div className="connect_right_forms">
                      <span>
                        <Picker onSelect={addEmoji} />
                      </span>
                    </div>
                  </div>
                )}
                <div className="connect_right_bottom">
                  <div className="connect_right_forms">
                    <input
                      type="text"
                      value={message}
                      className="form-control"
                      placeholder="Type message here"
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={onEnter}
                    />
                  </div>
                  <div className="smile_icon">
                    <img
                      onClick={() => emojiIconClick()}
                      src={require("../../../images/smile.png")}
                    />
                  </div>
                  <div className="connect_right_icons">
                    <img
                      src={require("../../../images/attach_icon.png")}
                      onClick={() => setIsOpenFile(true)}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    />
                    <img
                      src={require("../../../images/speaker_icon.png")}
                      onClick={() => setIsOpenRecorder(true)}
                    />
                  </div>
                </div>

                <Modal
                  show={isOpenFile}
                  onHide={() => {
                    setIsOpenFile(!isOpenFile);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Send File</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-6">
                          <FileUploads
                            accept=".pdf,.jpg,.jpeg,.xlsx,.csv,.xls"
                            onChange={(file: any) => {
                              handleDrop([file]);
                            }}
                            name="Upload File"
                            disabled={false}
                          />
                        </div>
                        <ShowFileInfo obj={obj} />
                        {/* <div className="col-sm-6">
                          {obj.file && (
                            <div
                              className="update_con"
                              style={{
                                width: 268,
                                height: 105,
                                paddingTop: 20,
                              }}
                            >
                              {obj.file.name}
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </Modal.Body>
                  <div className="modal-footer  m-t-30">
                    <button
                      onClick={() => sendFiles()}
                      className="btn btn-danger"
                      type="submit"
                      disabled={obj.file ? false : true}
                    >
                      Send
                    </button>
                    <button
                      onClick={() => setIsOpenFile(false)}
                      className="btn btn-default"
                      type="button"
                    >
                      cancel
                    </button>
                  </div>
                  <div className="clearfix" />
                </Modal>
                <Modal
                  show={isOpenRecorder}
                  onHide={() => {
                    setIsOpenRecorder(!isOpenRecorder);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Send Audio File</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <AudioReactRecorder state={recordState} onStop={onStop} />
                      <button onClick={start} className="btn btn-primary">
                        Start
                      </button>
                      <button onClick={stop} className="btn btn-danger">
                        Send
                      </button>
                    </div>
                  </Modal.Body>
                  <div className="modal-footer  m-t-30">
                    <button
                      onClick={() => setIsOpenRecorder(false)}
                      className="btn btn-default"
                      type="button"
                    >
                      cancel
                    </button>
                  </div>
                  <div className="clearfix" />
                </Modal>
              </div>
            </div>
          </div>
        </div>
        {/* */}
      </div>
      <div className="clearfix" />
      {/* </div>
      </div > */}
      {/*=================Content Section End================*/}
    </>
  );
};

export default CandidateChat;
