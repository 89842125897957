import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  IJobAlertAction,
  jobAlertReducer,
} from "../../action/candidate/JobAlertAction";
import {
  jobAlertGellAllRespondModel,
  jobAlertSaveRespondModel,
  jobAlertTitleDropdownResultModel,
  jobAlertViewModel,
} from "../../models/candidate/JobAlert";

export const JobAlertDispatcherContext = React.createContext<React.Dispatch<IJobAlertAction> | null>(
  null
);
export const JobAlertStateContext = React.createContext<IJobAlertManagementState | null>(
  null
);

export interface IJobAlertManagementProps extends RouteComponentProps<any> {}

export interface IJobAlertManagementState {
  visible: boolean;
  value: number;
  jobAlert: jobAlertViewModel;
  jobAlertSaveRespond: jobAlertSaveRespondModel;
  jobAlertCategoryMapSaveRespond: jobAlertSaveRespondModel;
  jobAlertExperienceMapSaveRespond: jobAlertSaveRespondModel;
  jobAlertIndustryMapSaveRespond: jobAlertSaveRespondModel;
  JobAlertRoleMapSaveRespond: jobAlertSaveRespondModel;
  JobAlertTypeMapSaveRespond: jobAlertSaveRespondModel;
  jobAlertGetAll: jobAlertGellAllRespondModel;
  jobAlertTitleDropdownResult: jobAlertTitleDropdownResultModel[];
}

export const initialJobAlertManagementState = {
  visible: false,
  value: 0,
  jobAlert: {} as jobAlertViewModel,
  jobAlertGetAll: {} as jobAlertGellAllRespondModel,
  jobAlertTitleDropdownResult: [] as jobAlertTitleDropdownResultModel[],
} as IJobAlertManagementState;

export const JobAlertContextProvider: React.FC = ({ children }) => {
  const [jobAlertState, dispatcher] = useImmerReducer(
    jobAlertReducer,
    initialJobAlertManagementState
  );

  return (
    <JobAlertDispatcherContext.Provider value={dispatcher}>
      <JobAlertStateContext.Provider value={jobAlertState}>
        {children}
      </JobAlertStateContext.Provider>
    </JobAlertDispatcherContext.Provider>
  );
};
