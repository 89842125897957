import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  getMyTeamMember,
  getMyTeamMemberSelectBoxList,
  useMyTeamMemberContext,
  useMyTeamMemberDispatcher,
} from "../../../../action/admin/MyTeamMemberAction";
import { MyTeamMemberEditRequestModel } from "../../../../models/admin/MyTeamMember";
import FileUploads from "../../../candidate/my_profile/components/FileUploads";
import SelectOption from "../../../candidate/my_profile/components/SelectOption";
import { MyTeamMemberGetDataWithId } from "../../../../models/admin/MyTeamMember";
import { saveMyTeamMember } from "./../../../../apis/admin";
import _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "./../../../../context/GlobalSpinner";
import DragAndDrop from "../../../candidate/my_profile/components/DragAndDrop";

interface IMyTeamMemberFormProps {
  isMyTeamMemberOpen: boolean;
  handleMyTeamMemberOpen: () => void;
  currentMyTeamMemberId: number;
  loggedAdminId: number;
  authorizationToken: string | null;
}
interface ReactSelectOption {
  value: string;
  label: string;
}
const defaultValues = {
  selectResume: "",
  coverLetter: "",
  designationId: 0,
};

const MyTeamMemberForm: React.FC<IMyTeamMemberFormProps> = (props) => {
  const {
    handleMyTeamMemberOpen,
    isMyTeamMemberOpen,
    currentMyTeamMemberId,
    loggedAdminId,
    authorizationToken,
  } = props;

  const [obj, setObj] = useState<any>({ file: null });
  const [obj2, setObj2] = useState<any>({ file: null });
  const [disable, setDisable] = useState<number>(0);
  const [disable2, setDisable2] = useState<number>(0);
  const [disable3, setDisable3] = useState<string>("");
  const [disable4, setDisable4] = useState<string>("");

  const MyTeamMemberDispatcher = useMyTeamMemberDispatcher();
  const MyTeamMemberContext = useMyTeamMemberContext();
  const {
    MyTeamMemberResultModel,
    MyTeamMemberSelectBoxDataViewModel,
  } = MyTeamMemberContext;
  const globalSpinner = useContext(GlobalSpinnerContext);
  const [sizeValidate, setSizeValidate] = useState<string>("");

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  let MyTeamMemberDesignations: ReactSelectOption[] = [];
  if (
    !_.isEmpty(MyTeamMemberSelectBoxDataViewModel) &&
    MyTeamMemberSelectBoxDataViewModel != undefined
  ) {
    MyTeamMemberDesignations = MyTeamMemberSelectBoxDataViewModel.designations.map(
      (e: any) => {
        return { value: e["value"], label: e["caption"] };
      }
    );
  }

  const handleDrop = (files: any) => {
    if (
      ["image/jpeg", "image/jpg", "image/png"].includes(files[0].type) === false
    ) {
      setSizeValidate("Acceptable file formats: JPEG,JPG,PNG");
      return false;
    }
    if (
      ["image/jpeg", "image/jpg", "image/png"].includes(files[0].type) &&
      files[0].size < 1000000 * 2
    ) {
      setDisable(1);
      //console.log(files[0]);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
    }
  };

  React.useEffect(() => {
    if (loggedAdminId != 0 && authorizationToken != null) {
      (async () => {
        await getMyTeamMember(
          MyTeamMemberDispatcher,
          {
            rowId: currentMyTeamMemberId,
          } as MyTeamMemberGetDataWithId,
          authorizationToken
        );
      })();
    }
  }, [currentMyTeamMemberId]);

  React.useEffect(() => {
    if (1 && authorizationToken != null) {
      (async () => {
        await getMyTeamMemberSelectBoxList(
          MyTeamMemberDispatcher,
          authorizationToken
        );
      })();
    }
  }, []);

  React.useEffect(() => {
    //console.log("----MyTeamMemberResultModel----", MyTeamMemberResultModel);
    if (MyTeamMemberResultModel.data != null) {
      setValue3("rowId", MyTeamMemberResultModel.data["rowId"]);
      setValue3(
        "myTeamMemberId",
        MyTeamMemberResultModel.data["myTeamMemberId"]
      );
      setValue3("name", MyTeamMemberResultModel.data["name"]);
      setValue3(
        "designationId",
        Number(MyTeamMemberResultModel.data["designationId"])
      );
      setValue3("imageUrl", MyTeamMemberResultModel.data["imageUrl"]);
      setValue3("adminId", loggedAdminId);
      setValue3("isActive", MyTeamMemberResultModel.data["isActive"]);
    }
  }, [MyTeamMemberResultModel.data, currentMyTeamMemberId]);

  //console.log("MyTeamMemberResultModel", MyTeamMemberResultModel);
  //console.log("loggedAdminId", loggedAdminId);

  const handleMyTeamMember = (data: any) => {
    //console.log(33, data);
    if (authorizationToken != null) {
      globalSpinner.showSpinner();
      const formData = new FormData();
      if (currentMyTeamMemberId > 0) {
        formData.append("RowId", String(currentMyTeamMemberId));
      } else {
        formData.append("RowId", String(0));
      }
      formData.append("MyTeamMemberId", String(0));
      formData.append("Name", String(data["name"]));
      formData.append("DesignationId", String(data["designationId"]));
      formData.append("Document", obj.file);
      if (MyTeamMemberResultModel.data != null) {
        formData.append(
          "ImageUrl",
          String(MyTeamMemberResultModel.data["imageUrl"])
        );
      }
      formData.append("AdminId", String(loggedAdminId));
      formData.append("IsActive", JSON.stringify(true));
      saveMyTeamMember(formData).then((res: any) => {
        //console.log(res);
        if (res.data.isSuccess) {
          globalSpinner.hideSpinner();
          toast.success("My team member saved successfully.");
        } else {
          globalSpinner.hideSpinner();
          toast.error("Error:My team member not saved or updated.");
        }
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setDisable(0);
    setDisable2(0);
    setDisable3("");
    handleMyTeamMemberOpen();
  };

  return (
    <>
      <ToastContainer />
      <div className=" ">
        <div id="create_jobdescription" className="modal fade none-border">
          <Modal show={isMyTeamMemberOpen} onHide={handleClose}>
            <form onSubmit={handleSubmit3(handleMyTeamMember)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>Add New</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="email">Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Type here"
                    ref={register3({
                      required: "Name is required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors3}
                    name="name"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Position</label>
                  <span className="select-wrapper-sec">
                    <Controller
                      rules={{
                        required: "Position is required",
                        validate: function (value) {
                          //console.log(value);
                          return Number(value)
                            ? undefined
                            : "Position is required";
                        },
                      }}
                      control={control3}
                      name="designationId"
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          disabled={false}
                          values={MyTeamMemberDesignations}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>
                </div>

                <div className="form-group">
                  <label htmlFor="email">Upload</label>
                  <FileUploads
                    accept=".jpg,.jpeg"
                    onChange={(file: any) => {
                      handleDrop([file]);
                    }}
                    name="Upload"
                    disabled={disable3 !== "" ? true : false}
                  />
                  <DragAndDrop handleDrop={handleDrop}>
                    {obj.file ? (
                      <div
                        className="update_con"
                        style={{ width: 268, height: 105 }}
                      >
                        {obj.file.name}
                      </div>
                    ) : (
                      <React.Fragment>
                        {/* <div className="update_con">
                  Drag and drop your file here{" "}
                </div> */}
                        <div className="update_con">
                          Acceptable file formats: JPEG,JPG,PNG
                        </div>
                        <div className="update_con">
                          Maximum file size: 2 MB.{" "}
                        </div>
                      </React.Fragment>
                    )}
                  </DragAndDrop>
                  <h6 style={{ color: "red", marginLeft: 225 }}>
                    {sizeValidate}
                  </h6>
                </div>
                {/* <div className="update_con">Acceptable file formats: JPEG,JPG,PNG</div>
                <div className="update_con">Maximum file size: 2 MB.</div>
                 <div className="clearfix"></div> */}
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default MyTeamMemberForm;
