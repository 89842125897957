import { Reducer } from "react";

import React from "react";

import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";

import {
  ISearchManagementState,
  SearchDispatcherContext,
  SearchStateContext,
} from "../../context/general/SearchContext";
import {
  searchDataWithOutTokenRequestModel,
  searchDataWithOutTokenViewModel,
  searchDataWithTokenRequestModel,
  searchDataWithTokenViewModel,
  SearchRequest,
  searchResult,
  VendorSearchDataWithTokenRequestModel,
} from "../../models/general/Search";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type ISearchAction =
  | {
      type: "SET_Job Search_LIST_With_Token";
      searchWithToken: searchDataWithTokenViewModel;
    }
  | {
      type: "SET_Job Search_LIST_With_Out_Token";
      searchWithOutToken: searchDataWithOutTokenViewModel;
      visible: boolean;
    }
  | {
      type: "SET_GENERAL_SEARCH";
      searchResultData: searchResult;
    };

export const searchReducer: Reducer<ISearchManagementState, ISearchAction> = (
  draft,
  action
): ISearchManagementState => {
  switch (action.type) {
    case "SET_Job Search_LIST_With_Token":
      draft.searchWithToken = action.searchWithToken;
      return draft;

    case "SET_Job Search_LIST_With_Out_Token":
      draft.searchWithOutToken = action.searchWithOutToken;
      draft.visible = action.visible;
      return draft;
    case "SET_GENERAL_SEARCH":
      draft.searchResultData = action.searchResultData;
      return draft;
  }
};

export const useSearchDispatcher = (): React.Dispatch<ISearchAction> => {
  const searchDispatcher = React.useContext(SearchDispatcherContext);
  if (!searchDispatcher) {
    throw new Error(
      "You have to provide the Search dispatcher using theSearchDispatcherContext.Provider in a parent component."
    );
  }
  return searchDispatcher;
};

export const useSearchContext = (): ISearchManagementState => {
  const searchContext = React.useContext(SearchStateContext);
  if (!searchContext)
    throw new Error(
      "You have to provide the search context using the SearchStateContext.Provider in a parent component."
    );
  return searchContext;
};

export const getSearchListWithToken = async (
  dispatcher: React.Dispatch<ISearchAction>,
  query: searchDataWithOutTokenRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllSearchResultWithToken;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_Job Search_LIST_With_Token",
        searchWithToken: res.data,
      });
    });
  } catch (e) {}
};

export const getSearchListWithOutToken = async (
  dispatcher: React.Dispatch<ISearchAction>,
  query: searchDataWithOutTokenRequestModel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      //  "Authorization":`Bearer ${authorizationToken}`,
    };

    const url = AppUrls.GetAllSearchResultWithOutToken;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      //console.log(112, res);
      dispatcher({
        type: "SET_Job Search_LIST_With_Out_Token",
        searchWithOutToken: res.data,
        visible: true,
      });
    });
  } catch (e) {}
};

export const getSearchListWithTokenAsVendor = async (
  dispatcher: React.Dispatch<ISearchAction>,
  query: VendorSearchDataWithTokenRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetListOfVendorSearchResult;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_Job Search_LIST_With_Token",
        searchWithToken: res.data,
      });
    });
  } catch (e) {}
};

export const generalSearch = async (
  dispatcher: React.Dispatch<ISearchAction>,
  query: SearchRequest,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GenericSearch;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_GENERAL_SEARCH",
        searchResultData: res.data,
      });
    });
  } catch (e) {}
};
