import { ErrorMessage } from "@hookform/error-message";
import { Popconfirm, Popover, Timeline, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, withRouter } from "react-router";
import {
  getTitleWithCategoryId,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import {
  getAllClientjobs,
  getClientjob,
  GetClientJobStatus,
  SaveClientJob,
  setClientPublishJobs,
  useClientJobContext,
  useClientJobDispatcher,
} from "../../../action/client/ClientJobAction";
import { useExperienceContext } from "../../../action/general/ExperienceAction";
import { useVendorMyProfileContext } from "../../../action/MyProfileVendorAction";
import { jobAlertTitleDropdownResultModel } from "../../../models/candidate/JobAlert";
import { categoryWithId } from "../../../models/candidate/JobCategory";
import {
  ClientJobStatusRequestModel,
  GetAllJobRequestModel,
  GetAllJobRespond,
} from "../../../models/client/ClientJob";
import AuthService from "../../../services/AuthService";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import SelectOption from "../../candidate/my_profile/components/SelectOption";

interface ReactSelectOption {
  value: string;
  label: string;
}

type objectIndexing = {
  [key: string]: any;
};

interface IMyJobComponentProps {}

interface IMyJobComponentState {
  tempData: jobAlertTitleDropdownResultModel[];
}

const defaultValues = {
  jobTypeData: "",
  countryCode: "",
  mobileNumber: "",
  firstName: "",
  jobCategorys: "",
  tempData: [],
  jobTitle: "",
  jobStatus: "",
  assignTo: "",
};

const MyJobComponent = () => {
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const inputEl = useRef<HTMLButtonElement>(null);

  let history = useHistory();
  const authorizationToken = AuthService.accessToken;
  let jobCategoryData: ReactSelectOption[] = [];
  let jobTypeDatas: ReactSelectOption[] = [];

  const myVendorProfileContext = useVendorMyProfileContext();
  const {
    // loggedUserId,
    myProfile,
  } = myVendorProfileContext;

  const [
    clientMyJobComponentState,
    setClientMyJobComponentState,
  ] = React.useState<IMyJobComponentState>(defaultValues);
  let user = AuthService.currentUser;
  const jobAlertContext = useJobAlertContext();
  const { jobAlertTitleDropdownResult } = jobAlertContext;

  const jobAlertDispatcher = useJobAlertDispatcher();
  const clientJobDispatcher = useClientJobDispatcher();
  const clientJobContext = useClientJobContext();
  const {
    AllClientJobs,
    ClientJob,
    publishJobResult,
    ClientJobSaveRespondModel,
    clientJobStatusResul,
  } = clientJobContext;
  const experienceContext = useExperienceContext();
  const { experience } = experienceContext;

  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filename, setFileName] = useState("");
  const [currentJobId, setCurrentJobId] = useState<number>(0);
  const [isDeleteFlag, setIsDeleteFlag] = useState<boolean>(false);
  const [processingStatusId, setProcessingStatusId] = useState<number>(0);
  const [obj, setObj] = useState<any>({ file: null });
  const [filename1, setFileName1] = useState("");
  const [obj1, setObj1] = useState<any>({ file: null });
  const [hasMore, setHasMore] = React.useState(true);
  const [totalCount, setTotalCount] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [allClientjobState, setAllClientjobState] = useState<
    GetAllJobRespond[]
  >([]);

  const { tempData } = clientMyJobComponentState;
  const watchAllFields = watch2();

  let experienceData: ReactSelectOption[] = [];

  if (experience.data != undefined) {
    experienceData = experience.data.map((e: any) => {
      return {
        value: e["rowId"],
        label:
          e["title"] !== "0"
            ? e["title"] + " " + "years"
            : e["title"] + " " + "year",
      };
    });
  }

  React.useEffect(() => {
    setClientMyJobComponentState((clientMyJobComponentState) => {
      return {
        ...clientMyJobComponentState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != undefined) {
      (async () => {
        await getAllClientjobs(
          clientJobDispatcher,
          {
            ClientId: 0,
            UserId: Number(user.id),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
            Publishedjobsflag: isPublished,
            Draftedjobsflag: !isPublished,
            JobStatus: 1,
          } as GetAllJobRequestModel,
          authorizationToken
        );
      })();
      setIsOpen(false);
    }
  }, [user?.id, isPublished, publishJobResult, ClientJobSaveRespondModel]);

  const handleEdit = (id: any) => {
    // setMirrorData(0);
    setCurrentJobId(id);
    if (authorizationToken != null) {
      (async () => {
        await getClientjob(
          clientJobDispatcher,
          {
            RowId: id,
          } as any,
          authorizationToken
        );
      })();
    }
    setIsOpen(true);
  };

  const handlePublished = (id: any) => {
    // setMirrorData(0);
    setCurrentJobId(id);
    if (authorizationToken != null) {
      (async () => {
        await setClientPublishJobs(
          clientJobDispatcher,
          {
            RowId: id,
          } as any,
          authorizationToken
        );
      })();
    }
  };

  const handleViewApplicant = (id: any, jobTypeId: any) => {
    // history.push(`/client/viewApplicant/${jobTypeId}/${id}`);
    history.push(`/client/viewApplicant/${jobTypeId}/${id}`);
  };

  const handleCheckStatus = (id: any) => {
    // setMirrorData(0);
    setCurrentJobId(id);
    if (authorizationToken != null) {
      (async () => {
        await GetClientJobStatus(
          clientJobDispatcher,
          {
            JobId: id,
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
          } as ClientJobStatusRequestModel,
          authorizationToken
        );
      })();
    }
  };

  // const DeleteRefClick = () => {
  //   if (inputEl.current) {
  //     inputEl.current.click();
  //   }
  // };

  useEffect(() => {
    if (ClientJob.data != undefined) {
      // setValue2("AdminId", );
      // setValue2("Rowid", );
      setValue2("jobCategorys", ClientJob.data[0].categoryId.toString());
      setValue2("jobTitle", ClientJob.data[0].title);
      setValue2("jobDescription", ClientJob.data[0].description);
      setValue2("experience", ClientJob.data[0].experienceId.toString());
      setValue2("vacancies", ClientJob.data[0].numberOfVacancies);
      setValue2("jobTypeData", ClientJob.data[0].jobTypeId.toString());
      setValue2("location", ClientJob.data[0].location);
      setValue2("minAnnualSalary", ClientJob.data[0].minAnnualSalary);
      setValue2("maxAnnualSalary", ClientJob.data[0].maxAnnualSalary);
      // setValue2("MinAnnualSalary", ClientJob.data[0].);
      //  setValue2("WillingnessToTravelFlag",ClientJob.data[0]. );
      // setValue2("AutoScreeningFilterFlag", ClientJob.data[0].);
      // setValue2("AutoSkillAssessmentFlag", ClientJob.data[0].);
      setValue2("jobOverview", ClientJob.data[0].jobOverview);
      setValue2("minimumQualification", ClientJob.data[0].minQualification);
      setValue2("skillsRequired", ClientJob.data[0].skillRequired);
      setValue2("documentsRequired", ClientJob.data[0].documentsRequired);
      // setValue2("AutoSkillAssessmentFlag",ClientJob.data[0]. );
      setValue2("others", ClientJob.data[0].others);
      setValue2("assignTo", ClientJob.data[0].assignedToAdminId.toString());
      setValue2("jobStatus", ClientJob.data[0].jobStatus ? "1" : "2");

      setIsPublished(ClientJob.data[0].ispublishedJobs);
      setCurrentJobId(ClientJob.data[0].jobId);
      setFileName(ClientJob.data[0].jobIcon);
      setFileName1(ClientJob.data[0].jobBackground);
      setProcessingStatusId(ClientJob.data[0].processingStatusId);
    }
  }, [ClientJob.data]);

  React.useEffect(() => {
    //console.log(1213, watchAllFields.jobCategorys);
    //console.log(1212, authorizationToken);

    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  const handleAdminClick = (data: any) => {
    console.log(3329, data);
    const formData = new FormData();
    //   formData.append("AdminId", JSON.stringify(loggedAdminId));
    formData.append("Rowid", JSON.stringify(currentJobId));
    formData.append("CategoryId", JSON.stringify(parseInt(data.jobCategorys)));
    formData.append("Title", String(data.jobTitle));
    formData.append("Description", String(data.jobDescription));
    formData.append(
      "ExperienceId",
      JSON.stringify(parseInt(data.experience == null ? 0 : data.experience))
    );
    formData.append(
      "NumberOfVacancies",
      JSON.stringify(parseInt(data.vacancies == "" ? 0 : data.vacancies))
    );
    formData.append("JobTypeId", JSON.stringify(parseInt(data.jobTypeData)));
    formData.append("LocationId", String(data.location));
    formData.append(
      "MinAnnualSalary",
      JSON.stringify(
        parseInt(data.minAnnualSalary == "" ? 0 : data.minAnnualSalary)
      )
    );
    formData.append(
      "MaxAnnualSalary",
      JSON.stringify(
        parseInt(data.maxAnnualSalary == "" ? 0 : data.maxAnnualSalary)
      )
    );
    formData.append("WillingnessToTravelFlag", JSON.stringify(false));
    formData.append("AutoScreeningFilterFlag", JSON.stringify(false));
    formData.append("AutoSkillAssessmentFlag", JSON.stringify(false));
    formData.append("JobOverview", String(data.jobOverview));
    formData.append("MinQualification", String(data.minimumQualification));
    formData.append("SkillRequired", String(data.skillsRequired));
    formData.append("DocumentsRequired", String(data.documentsRequired));
    formData.append("Others", String(data.others));
    formData.append("AssignedToAdminId", JSON.stringify(0));
    formData.append(
      "JobStatus",
      JSON.stringify(data.jobStatus === "1" ? true : true)
    );
    formData.append("JobIcon", String(filename));
    formData.append("JobBackground", String(filename1));
    formData.append("IsAprrovedJobs", JSON.stringify(false));
    formData.append("IsProcessedJobs", JSON.stringify(false));
    formData.append("IspublishedJobs", JSON.stringify(isPublished));
    formData.append("JobIconDocument", obj.file);
    formData.append("JobBackgroundDocument", obj1.file);
    let newDate = new Date();
    formData.append("JobAssignDate", "12/11/2020");
    formData.append("ProcessingStatusId", JSON.stringify(processingStatusId));
    formData.append("ProcessingComment", JSON.stringify(""));
    formData.append("ClientId", JSON.stringify(0));
    formData.append("ChangeStatusAdminId", JSON.stringify(0));
    formData.append("DeleteFlag", JSON.stringify(isDeleteFlag));
    formData.append(
      "UserId",
      JSON.stringify(user?.id != undefined ? parseInt(user?.id) : 0)
    );

    if (authorizationToken != null) {
      (async () => {
        await SaveClientJob(clientJobDispatcher, formData, authorizationToken);
      })();
    }
    setIsPublished(false);
    setIsDeleteFlag(false);
    setProcessingStatusId(0);
    // );
  };

  const handleDrop = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(
          files[0].type
        ) &&
        files[0].size < 1000000 * 5
      ) {
        //console.log(files[0]);
        if (files.length > 0) {
          setObj({
            ...obj,
            ["file"]: files[0],
          });
          setFileName(files[0].name);
        }
      }
    }
  };

  const handleDropOne = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(
          files[0].type
        ) &&
        files[0].size < 1000000 * 5
      ) {
        //console.log(files[0]);
        if (files.length > 0) {
          setObj1({
            ...obj1,
            ["file"]: files[0],
          });
          setFileName1(files[0].name);
        }
      }
    }
  };

  const handleJobIconDelete = () => {
    setFileName("");
    setObj({ file: null });
  };

  const handleJobBackGroundDelete = () => {
    setFileName1("");
    setObj1({ file: null });
  };

  function cancel(e: any) {
    console.log(e);
  }

  const handleCreateJobClick = () => {
    setIsOpen(true);
    setCurrentJobId(0);
    setFileName("");
    setObj({ file: null });
    setFileName1("");
    setObj1({ file: null });
    setIsDeleteFlag(false);
    setProcessingStatusId(0);
    setIsPublished(false);
  };

  React.useEffect(() => {
    //console.log(currentPage);
    if (AllClientJobs.data != undefined && currentPage == 1) {
      //console.log("userList");
      //console.log(jobListInAdmin);
      setAllClientjobState(AllClientJobs.data);
      //setCurrentPage(1);
      setTotalPage(Math.ceil(AllClientJobs.total / 12));
      setTotalCount(AllClientJobs.total);
      //console.log(drafredjobState);
    }
    if (AllClientJobs.data != undefined && currentPage > 1) {
      //console.log("drafted");
      setHasMore(AllClientJobs.hasNext);
      setAllClientjobState([...AllClientJobs.data, ...allClientjobState]);
    }
  }, [AllClientJobs]);

  const fetchMoreData = async () => {
    //console.log("hhh");
    //console.log(currentPage, totalPage);
    //console.log(totalCount);
    if (currentPage >= totalPage) {
      setHasMore(false);
      return;
    } else {
      setCurrentPage(currentPage + 1);
      if (authorizationToken != null && user?.id != undefined) {
        (async () => {
          await getAllClientjobs(
            clientJobDispatcher,
            {
              ClientId: 0,
              UserId: Number(user.id),
              Page: currentPage + 1,
              PageSize: 12,
              SearchTerm: "string",
              SortOrder: "string",
              ShowInactive: false,
              Publishedjobsflag: isPublished,
              Draftedjobsflag: !isPublished,
              JobStatus: 1,
            } as GetAllJobRequestModel,
            authorizationToken
          );
        })();
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <h1 className="heading">My Job Posts</h1>
        <a
          onClick={() => {
            handleCreateJobClick();
          }}
          data-target="#create_jobdescription"
          data-toggle="modal"
          className="btn cutomise_but"
        >
          <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; Create New
          Job Description
        </a>

        {/* ------------ */}

        {/* --------------------- */}

        <div className="clearfix" />
        <div className="row" style={{ paddingBottom: 12 }}>
          <ul className="resp-tabs-list resp-tabs">
            <li
              // key={i}
              onClick={() => {
                setIsPublished(false);
                // setDocumentTypeActiveId(typeData.rowId);
              }}
              className={
                isPublished == false
                  ? "resp-tab-item resp-tab-active"
                  : "resp-tab-item"
              }
              aria-controls={`tab_item-1`}
              role="tab"
            >
              Drafted{" "}
            </li>
            <li
              // key={i}
              onClick={() => {
                setIsPublished(true);
                // setDocumentTypeActiveId(typeData.rowId);
              }}
              className={
                isPublished == true
                  ? "resp-tab-item resp-tab-active"
                  : "resp-tab-item"
              }
              aria-controls={`tab_item-1`}
              role="tab"
            >
              published{" "}
            </li>
          </ul>
        </div>

        <div className="row">
          <div
            className={
              isPublished ? `col-sm-12 col-lg-8` : `col-sm-12 col-lg-12`
            }
          >
            <div className="section_box3">
              <div className="row">
                <div
                  className="my_job_descrip"
                  style={{ overflow: "hidden" }}
                  tabIndex={0}
                >
                  <InfiniteScroll
                    className="_custom-infinite-scroll-class"
                    height={650}
                    dataLength={
                      AllClientJobs.data != undefined
                        ? AllClientJobs.data.length
                        : 0
                    }
                    // dataLength={2000}
                    // dataLength={totalCount}
                    next={() => fetchMoreData()}
                    hasMore={
                      AllClientJobs.data != undefined
                        ? AllClientJobs.hasNext
                        : false
                    }
                    loader={
                      null
                      // jobAlertResultDataSate.length > 0 ? (
                      //   <h4>Loading...</h4>
                      // ) : null
                    }
                    // initialScrollY={indexState * 80}
                    endMessage={null}
                  >
                    {allClientjobState != undefined
                      ? allClientjobState.map((data, index) => {
                          return (
                            <div className="col-sm-4">
                              <div className="matched_jobs">
                                <div className="matched_jobs_cat_t">
                                  {" "}
                                  {data.categoryName}
                                </div>
                                <div className="matched_star1">
                                  {/* <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                /> */}
                                </div>
                                <div className="clearfix" />
                                <div className="matched_heading">
                                  {data.title}
                                </div>
                                <div className="published">
                                  {/* <p className="textOverFlow">
                                  {data.description}
                                </p> */}
                                </div>
                                <div className="clearfix" />
                                <div className="matched_jobs_cat">
                                  <i
                                    className="fa fa-map-marker"
                                    aria-hidden="true"
                                  />{" "}
                                  {data.location}
                                </div>
                                <div className="matched_jobs_cat text-right">
                                  {/* <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                />{" "} */}
                                  {data.jobType}
                                </div>
                                <div className="clearfix" />
                                <div className="matched_jobs_cat">
                                  <i
                                    className="fa fa-list-ul"
                                    aria-hidden="true"
                                  />{" "}
                                  {data.numberOfVacancies} Vecancies
                                </div>
                                <div className="clearfix" />
                                <div className="matched_jobs_share">
                                  <a
                                    href="#"
                                    data-target="#share"
                                    data-toggle="modal"
                                  >
                                    <i
                                      className="fa fa-share-alt"
                                      aria-hidden="true"
                                    />
                                  </a>
                                  {data.processingStatusId == 2 ||
                                  data.processingStatusId == 4 ? (
                                    <Tooltip title="No permission to edit approved or published jobs">
                                      <a
                                        style={{ marginLeft: 12 }}
                                        // onClick={() => handleEdit(data.jobId)}
                                        data-target="#apply_now_pop"
                                        data-toggle="modal"
                                      >
                                        Edit
                                      </a>
                                    </Tooltip>
                                  ) : (
                                    <a
                                      style={{ marginLeft: 12 }}
                                      onClick={() => handleEdit(data.jobId)}
                                      data-target="#apply_now_pop"
                                      data-toggle="modal"
                                    >
                                      Edit
                                    </a>
                                  )}
                                </div>
                                <div className="check_status">
                                  {data.ispublishedJobs ? (
                                    data.numberOfApplicants < 1 ? (
                                      <a
                                        onClick={() =>
                                          handleCheckStatus(data.jobId)
                                        }
                                        data-target="#apply_now_pop"
                                        data-toggle="modal"
                                      >
                                        Check Status
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() =>
                                          handleViewApplicant(
                                            data.jobId,
                                            data.jobTypeId
                                          )
                                        }
                                        data-target="#apply_now_pop"
                                        data-toggle="modal"
                                      >
                                        View Applicant
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      onClick={() =>
                                        handlePublished(data.jobId)
                                      }
                                      data-target="#apply_now_pop"
                                      data-toggle="modal"
                                    >
                                      Publish
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>

          {/* job status */}
          <div
            style={{ display: isPublished ? "" : "none" }}
            className="col-sm-12 col-lg-4"
          >
            <div className="heading_sec1">
              <h1>
                {" "}
                Status
                {clientJobStatusResul.data != undefined
                  ? clientJobStatusResul.data[0]
                    ? "-Job Id-" + clientJobStatusResul.data[0].jobId
                    : null
                  : null}{" "}
              </h1>
            </div>
            <div className="section_box4">
              <div
                className="dash_message_client"
                style={{ overflow: "hidden" }}
                tabIndex={0}
              >
                <Timeline style={{ marginTop: 18 }} mode={"left"}>
                  {clientJobStatusResul.data != undefined
                    ? clientJobStatusResul.data.map((data, index) => {
                        return (
                          <Timeline.Item
                            color={
                              index == 1
                                ? "green"
                                : index == 2
                                ? "yellow"
                                : index == 3
                                ? "red"
                                : "blue"
                            }
                            label={data.processingStatusDate}
                          >
                            <div className="status_con">
                              {data.processingComment}{" "}
                            </div>
                          </Timeline.Item>
                        );
                      })
                    : null}
                  {clientJobStatusResul.data != undefined &&
                  clientJobStatusResul.data.length == 1 ? (
                    <Timeline.Item color={"white"}></Timeline.Item>
                  ) : null}
                  {/* <Timeline.Item color="green" label="2015-09-01">
                    <div className="status_con">
                      Job post is published and is avilable to everyone.{" "}
                    </div>
                  </Timeline.Item>
                  <Timeline.Item label="2015-09-01 09:12:11">
                    <div className="status_con">
                      Received job application from 7 candidates{" "}
                    </div>
                  </Timeline.Item>
                  <Timeline.Item color="yellow">
                    Technical testing
                  </Timeline.Item>
                  <Timeline.Item color="red" label="2015-09-01 09:12:11">
                    Network problems being solved
                  </Timeline.Item> */}
                </Timeline>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>

      {/* BEGIN MODAL */}
      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
        backdrop="static"
      >
        <form onSubmit={handleSubmit2(handleAdminClick)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {currentAdminId == 0
                        ? " New Admin Profile"
                        : "Update Admin Profile"} */}
            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email" className="required">
                  Job Category
                </label>
                <span className="select-wrapper-sec">
                  <Controller
                    control={control2}
                    name="jobCategorys"
                    rules={{
                      required: "Job Category is required",
                    }}
                    // value=''
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={
                          myProfile.jobCategories != undefined
                            ? myProfile.jobCategories.map((e: any) => {
                                return {
                                  value: e["value"],
                                  label: e["caption"],
                                };
                              })
                            : jobCategoryData
                        }
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>
                <ErrorMessage
                  errors={errors2}
                  name="jobCategorys"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
              </div>
            </div>

            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email" className="required">
                  Job Titile
                </label>
                <span className="select-wrapper-sec">
                  <Controller
                    control={control2}
                    name="jobTitle"
                    rules={{
                      required: "Job Title is required",
                    }}
                    // value=''
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={
                          tempData != undefined
                            ? tempData.map((e) => {
                                return {
                                  value: e["value"],
                                  label: e["caption"],
                                };
                              })
                            : tempData
                        }
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>

                <ErrorMessage
                  errors={errors2}
                  name="jobTitle"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email">Job Location</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="location"
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email" className="required">
                  Experience
                </label>
                <span className="select-wrapper-sec">
                  <Controller
                    control={control2}
                    name="experience"
                    rules={{
                      required: "experience is required",
                    }}
                    // value=''
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={experienceData}
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>
                <ErrorMessage
                  errors={errors2}
                  name="experience"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email" className="required">
                  Job Type
                </label>
                <span className="select-wrapper-sec">
                  <Controller
                    className="custom-select-sec"
                    control={control2}
                    name="jobTypeData"
                    rules={{
                      required: "Job Type is required",
                    }}
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={
                          myProfile.jobTypes != undefined
                            ? myProfile.jobTypes.map((e: any) => {
                                return {
                                  value: e["value"],
                                  label: e["caption"],
                                };
                              })
                            : jobTypeDatas
                        }
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>
                <ErrorMessage
                  errors={errors2}
                  name="jobTypeData"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
              </div>
            </div>

            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email">Vacancies</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="vacancies"
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email">Job Overview</label>
                <textarea
                  name="jobOverview"
                  rows={4}
                  className="form-control"
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email">Job Description</label>
                <textarea
                  name="jobDescription"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email">Minimum Qualification</label>
                <textarea
                  name="minimumQualification"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email">Skills Required</label>
                <textarea
                  name="skillsRequired"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Min Annual Salary</label>
                <input
                  type="number"
                  className="form-control"
                  name="minAnnualSalary"
                  placeholder="Type here"
                  ref={register2({
                    // required:
                    //   watch2("minAnnualSalary") != undefined ||
                    //   watch2("minAnnualSalary") !== ""
                    //     ? true
                    //     : false,
                    required: false,

                    // validate: (value) => {
                    //   //console.log(700, value);
                    //   if (value < 1 && value != 0) {
                    //     return "Min Annual Salary can not be a negative number";
                    //   } else if (
                    //     value >= Number(watch2("maxAnnualSalary")) &&
                    //     Number(watch2("minAnnualSalary")) !== 0
                    //   ) {
                    //     //console.log(
                    //       value,
                    //       Number(watch2("maxAnnualSalary"))
                    //     );
                    //     return "Must be less than Max Salary.";
                    //   } else {
                    //     return undefined;
                    //   }

                    // },
                  })}
                />
                <ErrorMessage
                  errors={errors2}
                  name="minAnnualSalary"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Max Annual Salary</label>
                <input
                  type="number"
                  className="form-control"
                  name="maxAnnualSalary"
                  placeholder="Type here"
                  ref={register2({
                    required: false,
                    // validate: (value) => {
                    //   if (value < 1 && value != 0) {
                    //     return "Max Annual Salary can not be a negative number";
                    //   } else if (
                    //     value < Number(watch2("minAnnualSalary") && 0)
                    //   ) {
                    //     return "Must be greater than Min Salary.";
                    //   } else {
                    //     return undefined;
                    //   }
                    // },
                  })}
                />
              </div>
              <ErrorMessage
                errors={errors2}
                name="maxAnnualSalary"
                render={({ message }) => (
                  <div className="register_validation">{message}</div>
                )}
              />
            </div>

            <div className="clearfix" />
            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email">Documents Required</label>
                <textarea
                  name="documentsRequired"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group">
                <label htmlFor="email">Others</label>
                <textarea
                  name="others"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Job Icon</label>

                <FileUploads
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(file: any) => {
                    handleDrop([file]);
                  }}
                  name="Upload icon"
                  disabled={false}
                />
              </div>
              {filename}{" "}
              {filename === "" || filename === null ? null : (
                <a>
                  <i
                    onClick={() => handleJobIconDelete()}
                    className="fa fa-trash-o"
                    aria-hidden="true"
                    style={{ paddingLeft: 2 }}
                  />
                </a>
              )}
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Job Background</label>
                <FileUploads
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(file: any) => {
                    handleDropOne([file]);
                  }}
                  name="Upload Background"
                  disabled={false}
                />
              </div>
              {filename1}{" "}
              {filename1 === "" || filename1 == null ? null : (
                <a>
                  <i
                    onClick={() => handleJobBackGroundDelete()}
                    className="fa fa-trash-o"
                    aria-hidden="true"
                    style={{ paddingLeft: 2 }}
                  />
                </a>
              )}
            </div>
            <Modal.Footer>
              {processingStatusId == 1 || processingStatusId == 3 ? (
                // <Popconfirm
                //   title="Are you sure to delete this job?"
                //   onConfirm={DeleteRefClick}
                //   onCancel={cancel}
                //   okText="Yes"
                //   cancelText="No"
                // >
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                  onClick={() => setIsDeleteFlag(true)}
                  style={{ backgroundColor: "red" }}
                  ref={inputEl}
                >
                  Delete
                </button>
              ) : (
                // </Popconfirm>
                <Tooltip title="You can't delete approved and published job">
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                    style={{ backgroundColor: "gray" }}
                  >
                    Delete
                  </button>{" "}
                </Tooltip>
              )}

              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
                onClick={() => {
                  setIsPublished(true);
                }}
              >
                Published Now
              </button>
              <button
                data-dismiss="modal"
                className="btn btn-default waves-effect"
                type="button"
                onClick={() => {
                  setIsOpen(false);
                  setFileName("");
                  setFileName1("");
                }}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
};

export default withRouter(MyJobComponent);
