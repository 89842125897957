import React from "react";
import {
  MessageListContextProvider,
  UserListContextProvider,
} from "../../../context/superadmin/SuperAdminMyProfile";
import SuperAdminMessageComponent from "./SuperAdminMessageComponent";

interface ISuperAdminMessageContainerProps {}

interface ISuperAdminMessageContainerState {}

const initialState = {};

const SuperAdminMessageContainer: React.FC<ISuperAdminMessageContainerProps> = (
  props
) => {
  const [
    SuperAdminMessageContainerState,
    setSuperAdminMessageContainerState,
  ] = React.useState<ISuperAdminMessageContainerState>(initialState);

  return (
    <UserListContextProvider>
      <MessageListContextProvider>
        <SuperAdminMessageComponent />
      </MessageListContextProvider>
    </UserListContextProvider>
  );
};
export default SuperAdminMessageContainer;
