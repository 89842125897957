import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { GlobalSpinnerContext } from "./../../context/GlobalSpinner";
import { ErrorMessage } from "@hookform/error-message";
import {
  getTokenByUserEmailOrMobile,
  resetPasswordByOtp,
} from "../../apis/misc";
import { Redirect, withRouter } from "react-router-dom";

interface IForgotPasswordState {
  password: string;
  passwordConfirm: string;
}

function ForgotPasswordUserConfirm(props: any) {
  //console.log(props);

  const defaultValues = {
    password: "",
    passwordConfirm: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
  } = useForm<IForgotPasswordState>({
    defaultValues,
  });

  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [showPassword2, setShowPassword2] = useState<Boolean>(false);
  const globalSpinner = useContext(GlobalSpinnerContext);

  const onSubmit = (data: any) => {
    ////console.log(data);
    globalSpinner.showSpinner();
    let query = "";
    //if (props.isEmail) {
    //   query = `?email=${encodeURIComponent(
    //     props.showEmail
    //   )}&countryCode=${encodeURIComponent(
    //     0 || ""
    //   )}&phoneNumber=${encodeURIComponent(0 || "")}&type=${encodeURIComponent(
    //     "email"
    //   )}`;
    // } else {
    //   query = `?email=${encodeURIComponent(
    //     0 || ""
    //   )}&countryCode=${encodeURIComponent(
    //     props.showCountry || ""
    //   )}&phoneNumber=${encodeURIComponent(
    //     props.showMobile || ""
    //   )}&type=${encodeURIComponent("mobile")}`;
    // }

    // getTokenByUserEmailOrMobile({}, query)
    //   .then((res: any) => {
    ////console.log(res.data);
    ////console.log(encodeURIComponent((res.data.token || "")));
    // if (res.data.message.includes("Valid user")) {
    query = `?email=${encodeURIComponent(
      props.changeEmail || ""
    )}&token=${encodeURIComponent(
      props.changeToken || ""
    )}&password=${encodeURIComponent(
      data["password"] || ""
    )}&confirmPassord=${encodeURIComponent(data["passwordConfirm"])}`;

    resetPasswordByOtp({}, query)
      .then((res: any) => {
        ////console.log(res.data);
        globalSpinner.hideSpinner();
        toast.success("Password reset done.");
        props.history.push("/login");
        //return <Redirect to="/" />;
      })
      .catch((err: any) => {
        //console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Error occurred while reset password.");
      });
    // }

    globalSpinner.hideSpinner();
    // })
    // .catch((err: any) => {
    //   // //console.log(err);
    //   globalSpinner.hideSpinner();
    //   toast.error(err.response.data);
    // });
  };

  return (
    <div className="col-sm-6 col-lg-5 col-lg-offset-1">
      <form
        className="needs-validation"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="registration_forms registration_sec">
          <div className="sub_heading">
            <h2>Create New Password</h2>
          </div>
          <div className="form-group">
            <div className="fons_lg">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                ref={register({
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message:
                      "Password should be 8 digits long with one uppercase and one lowercase letter, a number and a special character",
                  },
                  maxLength: {
                    value: 20,
                    message: "Should not be greater than 20 characters",
                  },
                  validate: function (value) {
                    let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                    return re.test(value)
                      ? undefined
                      : "Password should be 8 digit long which inludes capital letter, small letter and special characters";
                  },
                })}
                className="password-field-title-without-tooltip form-control input-pwd"
                placeholder="Password"
                required
              />
              <span
                onClick={(event: any) => {
                  setShowPassword(!showPassword);
                }}
                className={
                  showPassword
                    ? "fa fa-fw fa-eye field-icon toggle-password"
                    : "fa fa-fw fa-eye-slash field-icon toggle-password"
                }
              />
            </div>
            <div className="register_icon">
              <img src={require("./../../images/password_r.png")} />
            </div>
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <div className="register_validation">{message}</div>
              )}
            />
          </div>
          <div className="form-group">
            <div className="fons_lg">
              <input
                type={showPassword2 ? "text" : "password"}
                name="passwordConfirm"
                ref={register({
                  required: "Confirm password is required",
                  validate: (value) =>
                    value === watch("password")
                      ? undefined
                      : "Confirm password must be same as new password",
                })}
                className="form-control validate input-pwd"
                placeholder="Re-enter Password"
                required
              />
              <span
                onClick={(event: any) => {
                  setShowPassword2(!showPassword2);
                }}
                className={
                  showPassword2
                    ? "fa fa-fw fa-eye field-icon toggle-password"
                    : "fa fa-fw fa-eye-slash field-icon toggle-password"
                }
              />
            </div>
            <div className="register_icon">
              <img src={require("./../../images/password_r.png")} />
            </div>
            <ErrorMessage
              errors={errors}
              name="passwordConfirm"
              render={({ message }) => (
                <div className="register_validation">{message}</div>
              )}
            />
          </div>
          <div className="clearfix" />
          <div className="already_login1">
            Note: Your password must be 9 characters long with minimum one
            uppercase letter, one lowercase letter, a number and a special
            character.
          </div>
          <div className="m_t_20">
            <button type="submit" className="btn btn-success reg_but">
              Reset Password
            </button>
          </div>
        </div>
        <div className="clearfix" />
      </form>
    </div>
  );
}

export default withRouter(ForgotPasswordUserConfirm);
