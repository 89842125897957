import React from "react";
import { NotificationListContextProvider } from "../../../context/admin/AdminMyProfile";
import { MyUserContextProvider } from "../../../context/admin/MyUserContext";
import AdminDashboardComponent from "./AdminDashboardComponent";

interface IAdminDashboardContainerProps {}
interface IAdminDashboardContainerState {}
const AdminDashboardContainer: React.FC<IAdminDashboardContainerProps> = (
  props
) => {
  return (
    <>
      {/* <MyUserContextProvider> */}
        <NotificationListContextProvider>
          <AdminDashboardComponent />
        </NotificationListContextProvider>
      {/* </MyUserContextProvider> */}
    </>
  );
};

export default AdminDashboardContainer;
