import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";

import { useImmerReducer } from "use-immer";
import { jobCategoryViewModel } from "../models/candidate/JobCategory";
import { IJobCategoryAction, jobCategoryReducer } from "../action/JobCategoryAction";

export const JobCategoryDispatcherContext = React.createContext<React.Dispatch<IJobCategoryAction> | null>(null);
export const JobCategoryStateContext = React.createContext<IJobCategoryManagementState | null>(null);

export interface IJobCategoryManagementProps extends RouteComponentProps<any> { }

export interface IJobCategoryManagementState {
    visible:boolean;
    value:number;
    jobCategory:jobCategoryViewModel
}

export const initialJobCategoryManagementState = {
    visible:false,
    value:0,
    jobCategory:{} as jobCategoryViewModel,
} as IJobCategoryManagementState;

export const JobCategoryContextProvider: React.FC = ({ children }) => {
    const [jobCategoryState, dispatcher] = useImmerReducer(jobCategoryReducer, initialJobCategoryManagementState);

    return (
        <JobCategoryDispatcherContext.Provider value={dispatcher}>
            <JobCategoryStateContext.Provider value={jobCategoryState}>
                {children}
            </JobCategoryStateContext.Provider>
        </JobCategoryDispatcherContext.Provider>
    )
}