import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { useForm } from "react-hook-form";
import CareerPost from "./CareerPost";
import CareerTipsCategoryComponent from "./CareerTipsCategoryComponents";
import AuthService from "../../services/AuthService";
import {
  getCareerTipsApprovalList,
  getCareerTipsApprovalListWithOutToken,
  getCareerTipsCategoryListWithOutToken,
  setCareerTipsStatus,
  editCareerTips,
  useCareerTipsApprovalContext,
  useCareerTipsApprovalDispatcher,
} from "../../action/admin/CareerTipsApprovalAction";
import {
  CareerTipsApprovalRequestModel,
  CareerTipsEditRequestModel,
  CareerTipsApproval,
  CareerTipsApprovalViewModel,
  CareerTipsCategoryViewModel,
  CareerTipsApprovalSaveRequestModel,
  CareerTipsApprovalSaveRespondModel,
} from "../../models/admin/CareerTipsApproval";
import { divide } from "lodash";
import moment from "moment";
import { Modal } from "react-bootstrap";
import ReactGoogleSlides from "react-google-slides";

interface ICareerTipsComponentProps { }

interface ICareerTipsComponentState { }

const initialState = {};

const defaultValues = {};

const CareerTipsComponent: React.FC<ICareerTipsComponentProps> = (
  props: any
) => {
  const history = useHistory();
  const [
    startUpState,
    setCareerTipsComponentState,
  ] = React.useState<ICareerTipsComponentState>(initialState);
  const cl = props.location.pathname;
  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const careerTipsApprovalDispatcher = useCareerTipsApprovalDispatcher();
  const careerTipsApprovalContext = useCareerTipsApprovalContext();
  const {
    careerTipsApproval,
    careerTipsCategory,
    careerTipsApprovalWithOutToken,
    careerTipsApprovalSaveRespond,
  } = careerTipsApprovalContext;
  //console.log("----------careerTipsApproval-----------", careerTipsApproval);

  const [isCareerPostOpen, setIsCareerPostOpen] = useState<boolean>(false);
  const [careerTipsId, setCareerTipsId] = useState<number>(0);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [loggedUserId, setLoggedUserId] = useState<number>(0);
  const [isGoogleSlideShow, setIsGoogleSlideShow] = useState<boolean>(false);
  const [goolgeSliderURL, setGoolgeSliderURL] = useState<string>("");
  React.useEffect(() => {
    if (userId != null && userId != undefined) {
      setLoggedUserId(Number(userId));
    }
  }, [userId]);

  const handleCareerPostOpen = () => {
    setIsCareerPostOpen(!isCareerPostOpen);
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ICareerTipsComponentState>({
    defaultValues,
  });

  React.useEffect(() => {
    (async () => {
      await getCareerTipsApprovalListWithOutToken(
        careerTipsApprovalDispatcher,
        {
          userId: 0,
          page: 1,
          pageSize: 60,
          searchTerm: "",
          showInactive: false,
          sortOrder: "",
        } as CareerTipsApprovalRequestModel
      );
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      await getCareerTipsCategoryListWithOutToken(
        careerTipsApprovalDispatcher,
        {
          userId: 0,
          page: 1,
          pageSize: 60,
          searchTerm: "",
          showInactive: false,
          sortOrder: "",
        } as CareerTipsApprovalRequestModel
      );
    })();
  }, []);
  // const showURL = (value: string) => {
  //   setgoolgeSliderURL(value);
  // };
  const [selItems, setSelItems] = useState(0);
  const handleRead = (rowId: number, desc: string) => {
    //console.log('handleRead 0:');
    if (selItems === rowId) {
      setSelItems(0);
    } else {
      setSelItems(rowId);
    }
  };
  const handleReadMoreBlog = (slideUrl: string, isShowSlide: boolean) => {
    setGoolgeSliderURL(slideUrl);
    setIsGoogleSlideShow(
      !isShowSlide
    );
  }
  const handleShowGooleDrive = () => {

    return (
      <Modal
        show={isGoogleSlideShow}
        onHide={() => {
          setIsGoogleSlideShow(!isGoogleSlideShow);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Google Slides</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactGoogleSlides
            width={640}
            height={480}

            slidesLink={goolgeSliderURL}
            // slidesLink="https://docs.google.com/presentation/d/1YamFqUV0-k_Z8ewh8mTpGUCdQCKW3EHL/edit#slide=id.p1"
            slideDuration={5}
            position={1}
            showControls
            loop
          />
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {/* Header Start */}
      <div className="header header1">
        <header>
          <div className=" container">
            <div className="row">
              <div className="col-sm-2">
                <div className=" logo">
                  {" "}
                  <a
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    <img
                      src={require("../../images/logo.png")}
                      // src={require("../../images/logo.png"
                      className="img-responsive"
                      alt=""
                    />{" "}
                  </a>
                </div>
              </div>
              <div className="col-sm-10">
                <div className="bs-example">
                  <nav
                    role="navigation"
                    className="navbar navbar-default navbar-static"
                    id="navbar-example"
                  >
                    <div className="navbar-header">
                      <button
                        data-target=".bs-example-js-navbar-collapse"
                        data-toggle="collapse"
                        type="button"
                        className="navbar-toggle"
                      >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                      </button>
                      <div className="collapse navbar-collapse bs-example-js-navbar-collapse">
                        <ul className="nav navbar-nav">
                          <li>
                            <Link to="/" className={cl === "/" ? "active" : ""}>
                              {" "}
                              Home
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/admin/careerdeveloper"
                              className={
                                cl === "/admin/careerdeveloper" ? "active" : ""
                              }
                            >
                              Career Developer
                            </Link>
                          </li>
                          {/* <li>
                            {" "}
                            <Link
                              to="/coming_soon"
                              className={cl === "/coming_soon" ? "active" : ""}
                            >
                              Client{" "}
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/vendor"
                              className={cl === "/vendor" ? "active" : ""}
                            >
                              Vendor
                            </Link>{" "}
                          </li> */}
                          <li>
                            <Link
                              to={`/help`}
                              className={cl === "/help" ? "active" : ""}
                            >
                              Help
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/about_us"
                              className={cl === "/about_us" ? "active" : ""}
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact_us"
                              className={cl === "/contact_us" ? "active" : ""}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      {/* Header ends */}

      {/* Sub baneer start */}
      <div className="sub_banner">
        <div className="post_career_tip">
          <a
            onClick={() => {
              handleCareerPostOpen();
            }}
            data-target="#post_career"
            data-toggle="modal"
          >
            Post a Career Tip
          </a>
        </div>
        <div className="desk">
          <img
            src={require("../../images/careertips_banner.jpg")}
            // src="images/careertips_banner.jpg"
            className=" img-responsive"
          />
        </div>
        <div className=" mob">
          <img
            src={require("../../images/careertips_banner_mob.jpg")}
            // src="images/careertips_banner_mob.jpg"
            className=" img-responsive"
          />
        </div>
        <div className="career_banner">
          <div className=" container">
            <div className="row">
              <div className="col-sm-6 col-lg-4">
                <div className="career_banner_heading">
                  Get best career advices here{" "}
                </div>
                <div className="career_banner_con">
                  Career tips are secret success stories of Experts
                  outperforming in their career. You may step up onto their
                  footprints to make your professional journey successful and
                  memorable one.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
      {/* Sub banner ends */}

      <div className="career_tips_sub">
        <Link
          to={`/career_tips_category/5/${encodeURIComponent(
            "Users Featured Career Tips"
          )}`}
        >
          <div className=" container">
            <div className="row">
              <div className="col-sm-12 col-lg-12">
                <h1>Users Featured Career Tips</h1>
              </div>
            </div>
          </div>
        </Link>
      </div>
      {/* Carrer Tip Categories start */}
      <div className="career_categories">
        <div className="container">
          <div className="row">
            <h1>Categories</h1>
            <p>Select any one category to learn more about it. </p>

            {careerTipsCategory.data != undefined
              ? careerTipsCategory.data.map((category, i) => {
                return (
                  <div className="col-sm-3">
                    <Link
                      to={`/career_tips_category/${category.rowId}/${category.title}`}
                    >
                      <div className="career_categories_sec">
                        <div className="career_tips_icon">
                          <img
                            src={`https://careerapp.azurewebsites.net/Upload/CategoryImage/${category.imageUrl}`}
                          />
                        </div>
                        <div className="career_tips_subcon">
                          {category.title}{" "}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })
              : null}
          </div>
        </div>
      </div>
      {/* Carrer Tip Categories end */}

      {/* Achievement start  */}
      <div className="achievements">
        <div className=" container">
          <div className="row">
            <div className="col-sm-12 ">
              <h1>Blog</h1>
            </div>
            <div className="col-sm-12 ">
              <div
                id="owl-example"
                className="owl-carousel owl-theme"
                style={{ opacity: 1, display: "block" }}
              >
                <div className="owl-wrapper-outer">
                  <div
                    className="owl-wrapper"
                    style={{ width: "3660px", left: "0px", display: "block" }}
                  >
                    {careerTipsApprovalWithOutToken.data != undefined
                      ? careerTipsApprovalWithOutToken.data.map((career, i) => {
                        return (
                          <div
                            className="owl-item"
                            style={{ width: "305px" }}
                          >
                            <div className="item">
                              <div className="blogs_sec">
                                <div className="achievements_sec">
                                  <div className="achieve_img">
                                    <img
                                      width="255px!important"
                                      height="184!important"
                                      //src={`https://careerapp.azurewebsites.net/Upload/CareerTipsDocument/${career.careerTipsDocument}`}
                                      src={
                                        career.careerTipsDocument !==
                                          "null" &&
                                          career.careerTipsDocument !== null &&
                                          career.careerTipsDocument !==
                                          undefined
                                          ? `https://careerapp.azurewebsites.net/Upload/CareerTipsDocument/${career.careerTipsDocument}`
                                          : require("../../images/noimage.png")
                                      }
                                    // className="img-responsive"
                                    />
                                  </div>
                                  <h2>{career.name}{" "}</h2>
                                  <p>
                                    {career.rowId !== selItems
                                      ? career.description.length > 45
                                        ? career.description.substring(
                                          0,
                                          45
                                        ) + "..."
                                        : career.description
                                      : career.description}{" "}
                                  </p>
                                  <div className="achieve_date">
                                    {" "}
                                    <img
                                      src={require("../../images/achive_date.png")}
                                    />{" "}
                                    {career.publishedDate}
                                  </div>
                                  <div className="achieve_more">
                                    <a
                                      onClick={
                                        () => {
                                          //alert(career.GoogleSlideURL)
                                          handleReadMoreBlog(career.googleSlideURL, isGoogleSlideShow);
                                          // setGoolgeSliderURL(career.GoogleSlideURL);
                                          // setIsGoogleSlideShow(
                                          //   !isGoogleSlideShow

                                          // );
                                        }
                                        // handleRead(
                                        //   career.rowId,
                                        //   career.description
                                        // )
                                      }
                                    >
                                      <img
                                        src={require("../../images/achive_more.png")}
                                      />{" "}
                                      {career.rowId === selItems && (
                                        <>
                                          {" "}
                                          {career.rowId === selItems
                                            ? "Read Less"
                                            : "Read More"}{" "}
                                        </>
                                      )}
                                      {career.rowId !== selItems && (
                                        <> Read More</>

                                      )}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : null}
                  </div>
                  {/* <div className="owl-controls clickable">
                    <div className="owl-pagination">
                      <div className="owl-page active">
                        <span className="" />
                      </div>
                      <div className="owl-page">
                        <span className="" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Achievement ends  */}

      {handleShowGooleDrive()}

      <CareerPost
        isCareerTipsOpen={isCareerPostOpen}
        handleCareerTipsOpen={handleCareerPostOpen}
        currentCareerTipsId={careerTipsId}
        loggedUserId={loggedUserId}
        authorizationToken={authorizationToken}
      />
    </React.Fragment>
  );
};
export default withRouter(CareerTipsComponent);
