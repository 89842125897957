import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  AdminCandidateDetailsRespondModel,
  AdminCandidateSearchRespondModel,
  AdminSaveSearchResultModel,
  GetAdminSaveSearchResultModel,
  GetAdminSelectBoxJobDataResultModel,
  RemoveAdminSavedSearchCandidateModel,
  SendEmailOrSmsResultModel,
  SetAdminCandidateResultModel,
} from "../../models/admin/AdminCandidateSearch";
import {
  adminCandidateSearchrReducer,
  IAdminCandidateSearchrAction,
} from "../../action/admin/AdminCandidateSearchAction";

export const AdminCandidateSearchrDispatcherContext = React.createContext<React.Dispatch<IAdminCandidateSearchrAction> | null>(
  null
);
export const AdminCandidateSearchrStateContext = React.createContext<IAdminCandidateSearchrManagementState | null>(
  null
);

export interface IAdminCandidateSearchrManagementProps
  extends RouteComponentProps<any> {}

export interface IAdminCandidateSearchrManagementState {
  adminCandidateSearchResult: AdminCandidateSearchRespondModel;
  adminCandidateSearchResultDetails: AdminCandidateDetailsRespondModel;
  adminSaveSearchResultmodel: AdminSaveSearchResultModel;
  getAdminSaveSearchResultmodel: GetAdminSaveSearchResultModel;
  getAdminJobSelectBoxData: GetAdminSelectBoxJobDataResultModel;
  setAdminCandidateResultModel: SetAdminCandidateResultModel;
  removeAdminSavedCandidateSearchResultModel: RemoveAdminSavedSearchCandidateModel;
  sendEmailOrSmsResultData: SendEmailOrSmsResultModel;
}

export const initialAdminCandidateSearchrManagementState = {
  adminCandidateSearchResult: {} as AdminCandidateSearchRespondModel,
  adminCandidateSearchResultDetails: {} as AdminCandidateDetailsRespondModel,
  adminSaveSearchResultmodel: {} as AdminSaveSearchResultModel,
  getAdminSaveSearchResultmodel: {} as GetAdminSaveSearchResultModel,
  getAdminJobSelectBoxData: {} as GetAdminSelectBoxJobDataResultModel,
  setAdminCandidateResultModel: {} as SetAdminCandidateResultModel,
  removeAdminSavedCandidateSearchResultModel: {} as RemoveAdminSavedSearchCandidateModel,
  sendEmailOrSmsResultData: {} as SendEmailOrSmsResultModel,
} as IAdminCandidateSearchrManagementState;

export const AdminCandidateSearchrContextProvider: React.FC = ({
  children,
}) => {
  const [adminCandidateSearchrState, dispatcher] = useImmerReducer(
    adminCandidateSearchrReducer,
    initialAdminCandidateSearchrManagementState
  );

  return (
    <AdminCandidateSearchrDispatcherContext.Provider value={dispatcher}>
      <AdminCandidateSearchrStateContext.Provider
        value={adminCandidateSearchrState}
      >
        {children}
      </AdminCandidateSearchrStateContext.Provider>
    </AdminCandidateSearchrDispatcherContext.Provider>
  );
};
