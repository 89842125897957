import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";

import { digiLockerGetDataWithId } from "../../models/candidate/DigiLocker";
import { categoryWithId } from "../../models/candidate/JobCategory";
import {
  IVendorJobAppliedManagementState,
  VendorJobAppliedDispatcherContext,
  VendorJobAppliedStateContext,
} from "../../context/vendor/VendorJobAppliedContext";
import {
  GetAllSuggestedJobRespondModel,
  VendorGetAllJobRequestModel,
  VendorGetAllJobRespondModel,
  VendorJobAppliedRequestModel,
  VendorJobAppliedRespondModal,
} from "../../models/vendor/VendorJobApplied";
import message from "antd/lib/message";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IVendorJobAppliedAction =
  | {
      type: "GET_Job APPLIED_LIST";
      vendorGetAllJobList: VendorGetAllJobRespondModel;
    }
  | {
      type: "GET_Job SUGGESTED_JOB";
      vendorGetAllSuggestedJob: GetAllSuggestedJobRespondModel;
    }
  | {
      type: "SET_JOB_APPLIED_SAVE";
      vendorJobAppliedSaveRespond: VendorJobAppliedRespondModal;
    };
// | {
//     type: "SET_JOB_AlERT_CATEGORY_MAP_SAVE";
//     vendorJobAppliedCategoryMapSaveRespond: vendorJobAppliedSaveRespondModel;
//   }
// | {
//     type: "SET_JOB_AlERT_EXPERIENCE_MAP_SAVE";
//     vendorJobAppliedExperienceMapSaveRespond: vendorJobAppliedSaveRespondModel;
//   }
// | {
//     type: "SET_JOB_AlERT_INDUSTRY_MAP_SAVE";
//     vendorJobAppliedIndustryMapSaveRespond: vendorJobAppliedSaveRespondModel;
//   }
// | {
//     type: "SET_JOB_AlERT_ROLE_MAP_SAVE";
//     VendorJobAppliedRoleMapSaveRespond: vendorJobAppliedSaveRespondModel;
//   }
// | {
//     type: "SET_JOB_AlERT_TYPE_MAP_SAVE";
//     VendorJobAppliedTypeMapSaveRespond: vendorJobAppliedSaveRespondModel;
//   }
// | {
//     type: "GET_ALL_JOB_ALERT";
//     vendorJobAppliedGetAll: vendorJobAppliedGellAllRespondModel;
//   }
// | {
//     type: "DELETE_JOB_ALERT";
//     VendorJobAppliedRoleMapSaveRespond: vendorJobAppliedSaveRespondModel;
//   }
// | {
//     type: "GET_TITLE_WITH_CATEGORY_ID";
//     vendorJobAppliedTitleDropdownResult: vendorJobAppliedTitleDropdownResult[];
//   };

export const vendorJobAppliedReducer: Reducer<
  IVendorJobAppliedManagementState,
  IVendorJobAppliedAction
> = (draft, action): IVendorJobAppliedManagementState => {
  switch (action.type) {
    case "GET_Job APPLIED_LIST":
      draft.vendorGetAllJobList = action.vendorGetAllJobList;
      return draft;
    case "SET_JOB_APPLIED_SAVE":
      draft.vendorJobAppliedSaveRespond = action.vendorJobAppliedSaveRespond;
      return draft;
    case "GET_Job SUGGESTED_JOB":
      draft.vendorGetAllSuggestedJob = action.vendorGetAllSuggestedJob;
      return draft;
    // case "SET_JOB_AlERT_CATEGORY_MAP_SAVE":
    //   draft.vendorJobAppliedCategoryMapSaveRespond =
    //     action.vendorJobAppliedCategoryMapSaveRespond;
    //   return draft;
    // case "SET_JOB_AlERT_EXPERIENCE_MAP_SAVE":
    //   draft.vendorJobAppliedExperienceMapSaveRespond =
    //     action.vendorJobAppliedExperienceMapSaveRespond;
    //   return draft;
    // case "SET_JOB_AlERT_INDUSTRY_MAP_SAVE":
    //   draft.vendorJobAppliedIndustryMapSaveRespond =
    //     action.vendorJobAppliedIndustryMapSaveRespond;
    //   return draft;
    // case "SET_JOB_AlERT_ROLE_MAP_SAVE":
    //   draft.VendorJobAppliedRoleMapSaveRespond = action.VendorJobAppliedRoleMapSaveRespond;
    //   return draft;
    // case "SET_JOB_AlERT_TYPE_MAP_SAVE":
    //   draft.VendorJobAppliedRoleMapSaveRespond = action.VendorJobAppliedTypeMapSaveRespond;
    //   return draft;
    // case "GET_ALL_JOB_ALERT":
    //   draft.vendorJobAppliedGetAll = action.vendorJobAppliedGetAll;
    //   return draft;
    // case "DELETE_JOB_ALERT":
    //   draft.VendorJobAppliedRoleMapSaveRespond = action.VendorJobAppliedRoleMapSaveRespond;
    //   return draft;
    // case "GET_TITLE_WITH_CATEGORY_ID":
    //   draft.vendorJobAppliedTitleDropdownResult = action.vendorJobAppliedTitleDropdownResult;
    //   return draft;
  }
};

export const useVendorJobAppliedDispatcher = (): React.Dispatch<IVendorJobAppliedAction> => {
  const vendorJobAppliedDispatcher = React.useContext(
    VendorJobAppliedDispatcherContext
  );
  if (!vendorJobAppliedDispatcher) {
    throw new Error(
      "You have to provide the VendorJobApplied dispatcher using theVendorJobAppliedDispatcherContext.Provider in a parent component."
    );
  }
  return vendorJobAppliedDispatcher;
};

export const useVendorJobAppliedContext = (): IVendorJobAppliedManagementState => {
  const vendorJobAppliedContext = React.useContext(
    VendorJobAppliedStateContext
  );
  if (!vendorJobAppliedContext)
    throw new Error(
      "You have to provide the vendorJobApplied context using the VendorJobAppliedStateContext.Provider in a parent component."
    );
  return vendorJobAppliedContext;
};

// Get All applied job List

export const getVendorJobAppliedList = async (
  dispatcher: React.Dispatch<IVendorJobAppliedAction>,
  query: VendorGetAllJobRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllVendorAppliedJob;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_Job APPLIED_LIST",
        vendorGetAllJobList: res.data,
      });
    });
  } catch (e) {}
};

export const getVendorSuggestedList = async (
  dispatcher: React.Dispatch<IVendorJobAppliedAction>,
  query: VendorGetAllJobRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllVendorAppliedJob;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_Job SUGGESTED_JOB",
        vendorGetAllSuggestedJob: res.data,
      });
    });
  } catch (e) {}
};

// Save call

export const setVendorJobAppliedSave = async (
  dispatcher: React.Dispatch<IVendorJobAppliedAction>,
  query: VendorJobAppliedRequestModel,
  token: string
) => {
  if (token?.startsWith(`"`)) {
    token = token.substring(1);
    token = token.slice(0, -1);
  }
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveVendorJobApplied;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_JOB_APPLIED_SAVE",
        vendorJobAppliedSaveRespond: res.data,
      });
      res.data.isSuccess
        ? message.success("Applied job Saved sucessfully")
        : message.error(res.data.message, 8);
    });
  } catch (e) {}
};

// Save job alert Categorymap

// export const setVendorJobAppliedCategoryMapSave = async (
//   dispatcher: React.Dispatch<IVendorJobAppliedAction>,
//   query: vendorJobAppliedCategoryMapRequestModel
// ) => {
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${authorizationToken}`,
//     };

//     const url = AppUrls.SaveVendorJobAppliedCategoryMap;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "SET_JOB_AlERT_CATEGORY_MAP_SAVE",
//         vendorJobAppliedCategoryMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

//save VendorJobAppliedExperienceMap

// export const setVendorJobAppliedExperienceMapSave = async (
//   dispatcher: React.Dispatch<IVendorJobAppliedAction>,
//   query: vendorJobAppliedExperienceMapRequestModel
// ) => {
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${authorizationToken}`,
//     };

//     const url = AppUrls.SaveVendorJobAppliedExperienceMap;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "SET_JOB_AlERT_EXPERIENCE_MAP_SAVE",
//         vendorJobAppliedExperienceMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

// save VendorJobAppliedIndustryMap

// export const setVendorJobAppliedIndustryMapMapSave = async (
//   dispatcher: React.Dispatch<IVendorJobAppliedAction>,
//   query: vendorJobAppliedIndustryMapSaveRequestModel
// ) => {
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${authorizationToken}`,
//     };

//     const url = AppUrls.SaveVendorJobAppliedIndustryMap;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "SET_JOB_AlERT_INDUSTRY_MAP_SAVE",
//         vendorJobAppliedIndustryMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

//save VendorJobAppliedRoleMap

// export const setVendorJobAppliedRoleMapMapSave = async (
//   dispatcher: React.Dispatch<IVendorJobAppliedAction>,
//   query: VendorJobAppliedRoleMapSaveRequestModel
// ) => {
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${authorizationToken}`,
//     };

//     const url = AppUrls.SaveVendorJobAppliedRoleMapMap;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "SET_JOB_AlERT_ROLE_MAP_SAVE",
//         VendorJobAppliedRoleMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

// save VendorJobAppliedTypeMap

// export const setVendorJobAppliedTypeMapSave = async (
//   dispatcher: React.Dispatch<IVendorJobAppliedAction>,
//   query: VendorJobAppliedTypeMapSaveRequestModel
// ) => {
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${authorizationToken}`,
//     };

//     const url = AppUrls.SaveVendorJobAppliedTypeMap;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "SET_JOB_AlERT_TYPE_MAP_SAVE",
//         VendorJobAppliedTypeMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

// export const getAllVendorJobAppliedList = async (
//   dispatcher: React.Dispatch<IVendorJobAppliedAction>,
//   query: vendorJobAppliedGellAllRequestModel,
//   token: string
// ) => {
//   try {
//     if (token?.startsWith(`"`)) {
//       token = token.substring(1);
//       token = token.slice(0, -1);
//     }

//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     };

//     const url = AppUrls.GetAllVendorJobAlert;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({ type: "GET_ALL_JOB_ALERT", vendorJobAppliedGetAll: res.data });
//     });
//   } catch (e) {}
// };

// export const deleteVendorJobAppliedList = async (
//   dispatcher: React.Dispatch<IVendorJobAppliedAction>,
//   query: digiLockerGetDataWithId,
//   token: string
// ) => {
//   try {
//     if (token?.startsWith(`"`)) {
//       token = token.substring(1);
//       token = token.slice(0, -1);
//     }

//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     };

//     const url = AppUrls.DeleteVendorJobAlert;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "DELETE_JOB_ALERT",
//         VendorJobAppliedRoleMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

// export const getTitleWithCategoryId = async (
//   dispatcher: React.Dispatch<IVendorJobAppliedAction>,
//   query: categoryWithId,
//   token: string
// ) => {
//   try {
//     if (token?.startsWith(`"`)) {
//       token = token.substring(1);
//       token = token.slice(0, -1);
//     }

//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     };

//     const url = AppUrls.GetTitleWithCategory;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "GET_TITLE_WITH_CATEGORY_ID",

//         vendorJobAppliedTitleDropdownResult: res.data,
//       });
//       //console.log(676767676, res.data);
//     });
//   } catch (e) {}
// };
