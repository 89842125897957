import React from "react";

interface IMiscellaneousHeaderProps { }

interface IMiscellaneousHeaderState { }

const initialState = {};

const MiscellaneousHeader: React.FC<IMiscellaneousHeaderProps> = (props) => {

  const [MiscellaneousHeaderState, setMiscellaneousHeaderState] = React.useState<IMiscellaneousHeaderState>(initialState);

  return (
    <div className="login_header">
      <div className="container _cursor-pointer" onClick={() => { document.location.href = "/"; }}> <img src={require("./../../images/logo_login.png")} /></div>
    </div>
  );
};

export default MiscellaneousHeader;
