import React, { useState, useEffect } from "react";
import CoverLetterPreviewComponent from "./CoverLetterPreviewComponent";
import { getCoverLetterCandidateMap } from "../../../../apis/resumebuilder";
import { useMyProfileContext } from "../../../../action/MyProfileAction";

interface ICoverLetterPreviewContainerProps {
  selectTemplateInfo: any;
}

interface ICoverLetterPreviewContainerState {}

//const initialState = {};

const CoverLetterPreviewContainer: React.FC<ICoverLetterPreviewContainerProps> = (
  props
) => {
  // const [coverLetterPreviewContainerState, setCoverLetterPreviewContainerState] = React.useState<ICoverLetterPreviewContainerState>(
  //   initialState
  // );
  const myProfileContext = useMyProfileContext();
  const { loggedUserId } = myProfileContext;
  const candidateId = loggedUserId;
  const [coverLetterTemplateID, setCoverLetterTemplateID] = useState(0);
  const [coverLetterTemplateHtml, setCoverLetterTemplateHtml] = useState(
    "coverletter1.html"
  );
  const [coverLetterDescription, setcoverLetterDescription] = useState("");
  const [coverLetterInfo, setcoverLetterInfo] = useState("");
  const [coverLetterTitle, setCoverLetterTitle] = useState("");

  useEffect(() => {
    ////console.log(props);
    if (props.selectTemplateInfo.location.state != undefined) {
      setCoverLetterTemplateID(
        props.selectTemplateInfo.location.state.coverLetterTemplateID
      );
      setCoverLetterTemplateHtml(
        props.selectTemplateInfo.location.state.coverLetterTemplateHtml
      );
      setcoverLetterDescription(
        props.selectTemplateInfo.location.state.coverLetterDescription
      );
      setCoverLetterTitle(
        props.selectTemplateInfo.location.state.coverLetterTitle
      );
      getCoverLetterCandidateMap({
        CandidateId: candidateId,
        PageIndex: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: true,
      })
        .then((res) => {
          setcoverLetterInfo(res.data);
        })
        .catch((err) => {
          // //console.log(err);
        });
    }
  }, []);
  return (
    <>
      <CoverLetterPreviewComponent
        coverLetterDescription={coverLetterDescription}
        coverLetterTemplateID={coverLetterTemplateID}
        coverLetterTemplateHtml={coverLetterTemplateHtml}
        coverLetterInfo={coverLetterInfo}
        coverLetterTitle={coverLetterTitle}
        candidateId={candidateId}
      />
    </>
  );
};
export default CoverLetterPreviewContainer;
