import React from "react";
import MiscellaneousLayoutComponent from "./MiscellaneousLayoutComponent";

interface IMiscellaneousLayoutContainerProps { }

interface IMiscellaneousLayoutContainerState { }

const initialState = {};

const MiscellaneousLayoutContainer: React.FC<IMiscellaneousLayoutContainerProps> = (props: any) => {

  const [MiscellaneousLayoutContainerState, setMiscellaneousLayoutContainerState] = React.useState<IMiscellaneousLayoutContainerState>(initialState);

  return (
    <MiscellaneousLayoutComponent />
  );
};

export default MiscellaneousLayoutContainer;
