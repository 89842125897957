import {
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Popover,
  Radio,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import Scrollbars from "react-custom-scrollbars";
import { Controller, useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  getSearchListWithTokenAsVendor,
  useSearchContext,
  useSearchDispatcher,
} from "../../../action/general/SearchAction";
import { useMyProfileContext } from "../../../action/MyProfileAction";
import { useVendorMyProfileContext } from "../../../action/MyProfileVendorAction";
import {
  getVendorJobAppliedList,
  setVendorJobAppliedSave,
  useVendorJobAppliedContext,
  useVendorJobAppliedDispatcher,
} from "../../../action/vendor/VendorJobAppliedAction";
import {
  setVendorJobBookmark,
  useVendorJobBookMarkContext,
  useVendorJobBookMarkDispatcher,
} from "../../../action/vendor/VendorJobBookMarkAction";
import {
  DeleteVendorJobStatusList,
  getVendorJobStatusList,
  useVendorJobStatusContext,
  useVendorJobStatusDispatcher,
} from "../../../action/vendor/VendorJobStatusAction";
import {
  getVendorSuggestedJobList,
  getVendorSuggestedJobTitleList,
  useVendorSuggestedJobContext,
  useVendorSuggestedJobDispatcher,
} from "../../../action/vendor/VendorSuggestedJobAction";
import { getDocumentStatus } from "../../../apis/vendor";
import { useGlobalSpinnerContext } from "../../../context/GlobalSpinner";
import { VendorSearchDataWithTokenRequestModel } from "../../../models/general/Search";
import {
  VendorCandidateList,
  VendorGetAllJobRequestModel,
  VendorJobAppliedRequestModel,
} from "../../../models/vendor/VendorJobApplied";
import { VendorJobBookMarkRequestModel } from "../../../models/vendor/VendorJobBookMark";
import { JobStatusGetAllRequestModel } from "../../../models/vendor/VendorJobStatus";
import {
  VendorSuggestedJobTitleRequestModel,
  VendorSuggestedRequestModel,
} from "../../../models/vendor/VendorSuggestedJob";
import AuthService from "../../../services/AuthService";
import SelectOption from "../../candidate/my_profile/components/SelectOptionForSearch";

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IVendorJobAppliedComponentProps {}
interface IVendorJobAppliedComponentState {
  location: string;
  type: string[];
  jobTypeData: string;
  currentJobId: number;
  filterFlag: boolean;
}

const defaultValues = {
  location: "",
  type: [],
  jobTypeData: "1",
  currentJobId: 0,
  filterFlag: true,
};

// const authorizationToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8';
// let loggedUserId=1;

const VendorJobAppliedComponent: React.FC<IVendorJobAppliedComponentProps> = (
  props
) => {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IVendorJobAppliedComponentState>({
    defaultValues,
  });

  let jobTypeDatas: ReactSelectOption[] = [];
  const secondButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [
    vendorJobAppliedComponentState,
    setVendorJobAppliedComponentState,
  ] = React.useState<IVendorJobAppliedComponentState>(defaultValues);
  const authorizationToken = AuthService.accessToken;

  const vendorSuggestedJobDispatcher = useVendorSuggestedJobDispatcher();
  const vendorSuggestedJobContext = useVendorSuggestedJobContext();
  const {
    vendorSuggestedJobs,
    vendorSuggestedJobTitle,
  } = vendorSuggestedJobContext;
  const { currentJobId } = vendorJobAppliedComponentState;
  const myVendorProfileContext = useVendorMyProfileContext();
  const {
    basicInfo,
    // loggedUserId,
    myProfile,
    myProfileProgressBar,
    profileImage,
  } = myVendorProfileContext;

  const myProfileVContext = useMyProfileContext();
  const { loggedUserId } = myProfileVContext;

  const searchDispatcher = useSearchDispatcher();
  const searchContext = useSearchContext();
  const { searchWithToken, searchWithOutToken } = searchContext;

  const jobAppliedVendorDispatcher = useVendorJobAppliedDispatcher();
  const jobAppliedVendorContext = useVendorJobAppliedContext();
  const {
    vendorJobAppliedSaveRespond,
    vendorGetAllJobList,
  } = jobAppliedVendorContext;

  const vendorJobStatusDispatcher = useVendorJobStatusDispatcher();
  const vendorJobStatusContext = useVendorJobStatusContext();
  const {
    vendorGetAllJobStatus,
    vendorJobStatusDeleteResult,
  } = vendorJobStatusContext;

  const vendorJobBookMarkDispatcher = useVendorJobBookMarkDispatcher();
  const vendorJobBookMarkContext = useVendorJobBookMarkContext();
  const { bookmarkSaveResult } = vendorJobBookMarkContext;

  const globalSpinner = useGlobalSpinnerContext();

  const [jobStatusOPen, setJobStatusOPen] = useState<boolean>(false);
  //console.log(4434, loggedUserId, "@", authorizationToken);
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const [jobTitle, setJobTitle] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<number>(0);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [isRemovedAll, setIsRemovedCheckedAll] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [radioIndexList, setRadioIndexList] = useState<any[]>([]);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [indexCount, setIndexCount] = useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [documentStatus, setDocumentStatus] = useState("");
  let history = useHistory();

  const [vendorJobStatus, setVendorJobStatus] = useState(
    vendorGetAllJobStatus != undefined &&
      vendorGetAllJobStatus.data != undefined
      ? vendorGetAllJobStatus.data
      : []
  );

  let IdArray = [] as any[];

  function disabledDate(current: any) {
    // Can not select days before today and today

    return current && current < moment().endOf("day");
  }

  const onSubmit = (data: any) => {
    // if (authorizationToken != null && loggedUserId != 0) {
    //   history.push(
    //     `/jobSearch/searchtitle/0/${data.jobTitle}/${
    //       data.location === "" ? "location" : data.location
    //     }/${data.jobTypeData === "0" ? "0" : data.jobTypeData}`
    //   );
    // }

    if (authorizationToken != null && loggedUserId != 0) {
      console.log(39393, data);

      history.push(
        `/jobSearch/searchtitle/0/${
          data.jobTitle === "" ? "searchtitle" : data.jobTitle
        }/${data.location === "" ? "location" : data.location}/${
          data.jobTypeData === "" ? "0" : data.jobTypeData
        }`
      );
    } else {
      history.push(
        `/jobSearch/newlyPosted/0/${
          data.jobTitle === "" ? "searchtitle" : data.jobTitle
        }/${data.location === "" ? "location" : data.location}/${
          data.jobTypeData === "" ? "0" : data.jobTypeData
        }`
      );
    }
  };

  // React.useEffect(() => {
  //   if (jobTitle != "" && authorizationToken != null) {
  //     (async () => {
  //       await getSearchListWithTokenAsVendor(
  //         searchDispatcher,
  //         {
  //           expereince: [],
  //           location: [],
  //           title: [jobTitle],
  //           type: [],
  //           lastDays: [],
  //           vendorId: loggedUserId,
  //           pageIndex: 1,
  //           pageSize: 60,
  //           showInactive: false,
  //         } as VendorSearchDataWithTokenRequestModel,
  //         authorizationToken
  //         // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
  //       );
  //       setJobTitle("");
  //     })();
  //     history.push(`/job_search_vendor/${jobTitle}/0`);
  //   }
  // }, [jobTitle]);

  React.useEffect(() => {
    if (loggedUserId) {
      getDocumentStatus({
        VendorId: Number(loggedUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log("uploaded-document-status success", res.data);
          setDocumentStatus(res.data);
        })
        .catch((err) => {
          console.log("uploaded-document-status error", err);
        });
    }
  }, [loggedUserId]);

  const handleSuggestedClick = (id: any) => {
    history.push(`/jobSearch/suggested/${id}/searchtitle/location/0`);
    // history.push(`/job_search_vendor/suggested/${id}`);
  };

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId > 0) {
      (async () => {
        await getVendorJobAppliedList(
          jobAppliedVendorDispatcher,
          {
            vendorId: loggedUserId,
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as VendorGetAllJobRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId, bookmarkSaveResult]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId > 0) {
      globalSpinner.showSpinner();
      setVendorJobStatus([]);
      (async () => {
        await getVendorJobStatusList(
          vendorJobStatusDispatcher,
          {
            JobId: currentJobId,
            jobStatusId: statusFilter,
            Page: 1,
            PageSize: 100,
            SearchTerm: "",
            VendorId: loggedUserId,
            ShowInactive: false,
            SortOrder: "",
          } as JobStatusGetAllRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
        setFilterShow(false);
      })();
    }
  }, [loggedUserId, statusFilter, jobStatusOPen, vendorJobStatusDeleteResult]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId > 0) {
      (async () => {
        await getVendorSuggestedJobList(
          vendorSuggestedJobDispatcher,
          {
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
            vendorId: loggedUserId,
          } as VendorSuggestedRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId > 0) {
      (async () => {
        await getVendorSuggestedJobTitleList(
          vendorSuggestedJobDispatcher,
          {
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
            vendorId: loggedUserId,
          } as VendorSuggestedJobTitleRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId]);

  const onFinishStatus = (values: any) => {
    //console.log("Received values of form:", values);
    //console.log("Received vendorJobStatus", vendorJobStatus);
    let vendorCandidateListArray = [] as VendorCandidateList[];

    //     if(vendorJobStatus.length>0){
    // vendorJobStatus.forEach((data:any) => {
    //         vendorCandidateListArray.push({rowId:data.rowId,dob:data.dob,visaIssueCountry:data.visaIssueCountry,
    //           passPortNumber:data.passportNo,middleName:data.middleName,lastName:data.firstName,
    //           isActiveVisa:data.firstName,isActive:data.isActive,firstName:data.firstName,expDate:data.expDate
    //         })
    //     }
    // )}

    if (values.users != undefined && values.users.length > 0) {
      //console.log("hi");
      values.users.forEach((user: any) => {
        vendorCandidateListArray.push({
          rowId: 0,
          dob: user.dob != undefined ? user.dob._d : null,
          visaIssueCountry: user.visaIssueCountry,
          passPortNumber: user.passportNo,
          middleName: user.middleName,
          lastName: user.lastName,
          isActiveVisa:
            user.visaIssueCountry === "" ||
            user.expiryDate == null ||
            user.visaIssueCountry == undefined ||
            user.expiryDate == undefined
              ? false
              : true,
          isActive: true,
          firstName: user.firstName,
          expDate: user.expiryDate != undefined ? user.expiryDate._d : null,
        });
      });
    }
    if (authorizationToken != null) {
      (async () => {
        await setVendorJobAppliedSave(
          jobAppliedVendorDispatcher,
          {
            jobId: currentJobId,
            rowId: 0,
            vendorCandidateList: vendorCandidateListArray,
            vendorId: loggedUserId,
            IsActive: true,
          } as VendorJobAppliedRequestModel,
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
          authorizationToken
        );
      })();
    }
  };

  function cancel(e: any) {}

  React.useEffect(() => {
    // if (authorizationToken != null) {
    setJobStatusOPen(false);
    // }
  }, [vendorJobAppliedSaveRespond]);

  const menuRef = useRef<any>();
  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setFilterShow(false);
          // setIsProfileOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const handleAllChecked = (e: any) => {
    //console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArray = [];
      // digiLockerList
      vendorGetAllJobStatus.data
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArray.push(d.candidateId);
        });
      setCheckedListArray(IdArray);
      //console.log(23, checkedListArray);
      setIsCheckedAll(e.target.checked);
      //console.log("checkedListArray", checkedListArray);
      setIsRemovedCheckedAll(false);
    } else {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(e.target.checked);
      //console.log("checkedListArray", checkedListArray);
      setIsRemovedCheckedAll(true);
    }
  };

  const handleAllRemoved = (e: any) => {
    if (e.target.checked) {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(true);
      //console.log("checkedListArray", checkedListArray);
    }
  };

  const handleChecked = (e: any, id: any) => {
    // setIsChecked(true);
    if (e.target.checked) {
      IdArray.push(...checkedListArray, id);
      setCheckedListArray(IdArray);
      //console.log("checkedListArray", checkedListArray);
    } else {
      IdArray = checkedListArray.filter((data) => {
        return data !== id;
      });
      setCheckedListArray(IdArray);
      //console.log("checkedListArray", checkedListArray);
    }
    setIsRemovedCheckedAll(false);
    setIsChecked(true);
  };

  function onChangeDate(date: any, dateString: any) {
    //console.log(date, dateString);
  }

  const handleRemoveIndex = (indexValue: any) => {
    const item = radioIndexList.filter((value, index) => {
      return value != indexValue;
    });
    setRadioIndexList(item);
  };

  const handleOnRadioButtonChange = (e: any) => {
    if (e.target.value.charAt(0) == "f") {
      const item = radioIndexList.filter((value, index) => {
        return value != parseInt(e.target.value.substring(1));
      });
      setRadioIndexList(item);
    } else {
      setRadioIndexList((radioIndexList: any) => [
        ...radioIndexList,
        parseInt(e.target.value.substring(1)),
      ]);
    }
  };

  const handleJobStatusClick = (jobId: any) => {
    setIsCheckedAll(false);
    setIsRemovedCheckedAll(false);
    setCheckedListArray([]);
    setIsChecked(false);
    setVendorJobStatus([]);
    setJobStatusOPen(!jobStatusOPen);
    setVendorJobAppliedComponentState((vendorJobAppliedComponentState) => {
      return {
        ...vendorJobAppliedComponentState,
        currentJobId: jobId,
      };
    });
  };

  React.useEffect(() => {
    if (
      vendorGetAllJobStatus != undefined &&
      vendorGetAllJobStatus.data != undefined
    ) {
      setIndexCount(vendorGetAllJobStatus.data.length);
      setVendorJobStatus(
        vendorGetAllJobStatus != undefined &&
          vendorGetAllJobStatus.data != undefined
          ? vendorGetAllJobStatus.data
          : []
      );
      globalSpinner.hideSpinner();
    }
  }, [vendorGetAllJobStatus]);

  const handleShareButtonClick = (id: any, value: any) => {
    setVendorJobAppliedComponentState({
      ...vendorJobAppliedComponentState,
      currentJobId: id,
      filterFlag: value,
    });
    setIsShareOpen(!isShareOpen);
  };

  const handleSavedJobClick = (id: any) => {
    // setVendorDashboardComponentState({
    //   ...vendorDashboardComponentState,
    //   currentJobId: id,
    // });
    if (authorizationToken != null) {
      (async () => {
        await setVendorJobBookmark(
          vendorJobBookMarkDispatcher,
          {
            IsActive: true,
            jobBookmarkedId: 0,
            rowId: 0,
            vendorID: loggedUserId,
            jobId: id,
          } as VendorJobBookMarkRequestModel,
          authorizationToken
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImQ5ZjU0MjlmLTY3NTgtNGM0Ny04ODVhLTIwZjVkN2M4ODEyZiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTcyODEsImV4cCI6MTY0NDEzMzI4MSwiaWF0IjoxNjEyNTk3MjgxLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.sKevXWje7UM90S8rAZicVHpesgOz4IepSaY2JQkvcNM"
        );
      })();
    }
  };

  const handleJobStatusDelete = () => {
    if (authorizationToken != null) {
      (async () => {
        await DeleteVendorJobStatusList(
          vendorJobStatusDispatcher,
          {
            RowId: 0,
            JobId: currentJobId,
            VendorId: loggedUserId,
            VendorCandidateList: checkedListArray,
          },
          authorizationToken
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImQ5ZjU0MjlmLTY3NTgtNGM0Ny04ODVhLTIwZjVkN2M4ODEyZiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTcyODEsImV4cCI6MTY0NDEzMzI4MSwiaWF0IjoxNjEyNTk3MjgxLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.sKevXWje7UM90S8rAZicVHpesgOz4IepSaY2JQkvcNM"
        );
      })();
    }
  };

  React.useEffect(() => {
    // if (authorizationToken != null) {
    setVendorJobStatus([]);
    // }
  }, [statusFilter]);

  const content = (
    <>
      <p>You can only delete candidates in Applied and rejected tabs</p>
    </>
  );

  const fetchMoreData = async () => {};

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Jobs Applied</h1>
          <div className="clearfix" />
          <div className="row ">
            <div className="col-sm-12">
              <div className="carees_search1">
                <div className="row">
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="search_forms">
                      <div className="cr_serach_br">
                        <input
                          name="jobTitle"
                          ref={register({ required: false })}
                          type="text"
                          className="form-control"
                          placeholder="Job Title"
                        />
                        <div className="search_icons">
                          <img
                            src={require("../../../images/search_icon.png")}
                            //  src="images/search_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <ErrorMessage errors={errors} name="jobType" render={({ message }) => <div className="register_validation">{message}</div>} /> */}
                    <div className="search_forms">
                      <div className="cr_serach_br">
                        <span className="select-search">
                          <input
                            name="location"
                            ref={register({ required: false })}
                            type="text"
                            className="form-control"
                            placeholder="Location"
                          />
                        </span>

                        <div className="search_icons">
                          <img
                            src={require("../../../images/locattion_icon.png")}
                            // src="images/locattion_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="search_forms">
                      <div className="cr_serach_br">
                        <span className="select-search">
                          <Controller
                            control={control}
                            name="jobTypeData"
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  myProfile.jobTypes != undefined
                                    ? myProfile.jobTypes.map((e) => {
                                        return {
                                          value: e["value"],
                                          label: e["caption"],
                                        };
                                      })
                                    : jobTypeDatas
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                nameForLablel="Job Type"
                              />
                            )}
                          />
                          {/* <span className="holder">Type</span> */}
                        </span>
                        <div className="search_icons">
                          <img
                            src={require("../../../images/type_icon.png")}
                            // src="images/type_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="search_button">
                      <div className="search">
                        <button className="CustomButtonCss" type="submit">
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="section_box3">
                <div className="row">
                  <div
                    className="jobapplied_scroll"
                    style={{ overflow: "hidden", outline: "none" }}
                    tabIndex={0}
                  >
                    <InfiniteScroll
                      className="_custom-infinite-scroll-class"
                      height={700}
                      dataLength={
                        vendorGetAllJobList.data != undefined
                          ? vendorGetAllJobList.data.length
                          : 0
                      }
                      // dataLength={2000}
                      // dataLength={totalCount}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<h4></h4>}
                      // initialScrollY={indexState * 80}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>No more data to load</b>
                        </p>
                      }
                    >
                      {vendorGetAllJobList.data != undefined
                        ? vendorGetAllJobList.data.map((data, index) => {
                            return (
                              <div className="col-sm-4">
                                <div
                                  className="matched_jobs"
                                  style={{
                                    backgroundColor: data.jobActive
                                      ? "#fff"
                                      : "gainsboro",
                                  }}
                                >
                                  <div className="matched_jobs_cat_t">
                                    {data.categoryName}
                                  </div>
                                  <div className="jobs_start1">
                                    {data.daysAgo} days ago
                                  </div>
                                  <div className="matched_star">
                                    {data.isBookmarked ? (
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                        onClick={() =>
                                          handleSavedJobClick(data.jobId)
                                        }
                                      />
                                    ) : (
                                      <i
                                        className="fa fa-star-o"
                                        aria-hidden="true"
                                        onClick={() =>
                                          handleSavedJobClick(data.jobId)
                                        }
                                      />
                                    )}
                                  </div>
                                  <div className="clearfix" />
                                  <Popover
                                    content={
                                      data.jobActive
                                        ? "Please click for more details"
                                        : "This job is not activated"
                                    }
                                  >
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        data.jobActive
                                          ? history.push(
                                              `/jobSearch/applied/${data.jobId}/searchtitle/location/0`
                                            )
                                          : history.push(
                                              "/vendor/jobs-applied"
                                            );
                                      }}
                                    >
                                      <div className="matched_heading">
                                        {data.title}
                                      </div>
                                      <div className="matched_jobs_cat">
                                        <i
                                          className="fa fa-map-marker"
                                          aria-hidden="true"
                                        />{" "}
                                        {data.location}
                                      </div>
                                      <div className="matched_jobs_cat text-right">
                                        <i
                                          className="fa fa-clock-o"
                                          aria-hidden="true"
                                        />{" "}
                                        {data.jobType}
                                      </div>
                                      <div className="clearfix" />
                                      <div className="matched_jobs_cat">
                                        <i
                                          className="fa fa-list-ul"
                                          aria-hidden="true"
                                        />{" "}
                                        {data.numberOfVacancies} Vacancies
                                      </div>
                                    </div>
                                  </Popover>
                                  <div className="clearfix" />
                                  <div className="matched_jobs_share">
                                    <a
                                      onClick={() =>
                                        data.jobActive
                                          ? handleShareButtonClick(
                                              data.jobId,
                                              true
                                            )
                                          : null
                                      }
                                    >
                                      <i
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                  <div className="matched_jobs_status">
                                    {documentStatus !== undefined ? (
                                      documentStatus.includes("Rejected") ? (
                                        <Popover
                                          content={"Your document is rejected"}
                                        >
                                          <a
                                          // onClick={() =>
                                          //   handleJobStatusClick(data.jobId)
                                          // }
                                          >
                                            View Status
                                          </a>
                                        </Popover>
                                      ) : (
                                        <a
                                          onClick={() =>
                                            handleJobStatusClick(data.jobId)
                                          }
                                        >
                                          View Status
                                        </a>
                                      )
                                    ) : (
                                      <a
                                        onClick={() =>
                                          handleJobStatusClick(data.jobId)
                                        }
                                      >
                                        View Status
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </InfiniteScroll>
                    {/*job Applied Start  */}

                    {/* job Applied end */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="section_box3">
                <h1>Suggested Jobs</h1>
                <div
                  className="suggested_job_scroll"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 300 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {vendorSuggestedJobs.data != undefined
                      ? vendorSuggestedJobs.data.map((data, index) => {
                          return (
                            <div className="suggested_sec">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSuggestedClick(data.jobId)}
                              >
                                <div className="suggested_sec_cat">
                                  <i
                                    className="fa fa-briefcase"
                                    aria-hidden="true"
                                  />{" "}
                                  {data.title}
                                </div>
                                <div className="suggested_sec_cat">
                                  <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                  />{" "}
                                  {data.postedDate}
                                </div>
                                <div className="suggested_sec_cat">
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                  />{" "}
                                  {data.jobType}
                                </div>
                                <div className="suggested_sec_cat">
                                  <i
                                    className="fa fa-user"
                                    aria-hidden="true"
                                  />
                                  {data.numberOfVacancies}
                                </div>
                                <div className="suggested_sec_cat">
                                  <i
                                    className="fa fa-map-marker"
                                    aria-hidden="true"
                                  />{" "}
                                  {data.location}
                                </div>
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setIsShareOpen(!isShareOpen);
                                }}
                                className="suggested_sec_cat"
                              >
                                <i
                                  className="fa fa-share-alt"
                                  aria-hidden="true"
                                />{" "}
                                Share
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </Scrollbars>
                </div>
              </div>
              <div className="section_box3">
                <h1>Suggested Job Titles</h1>
                <div
                  className="suggested_title_scroll"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={0}
                >
                  <div className="suggested_titles">
                    <ul>
                      <Scrollbars
                        style={{ height: 270 }}
                        autoHide
                        renderThumbVertical={({ style, ...props }) => (
                          <div
                            {...props}
                            style={{
                              ...style,
                              position: "relative",
                              display: "block",
                              width: "5px",
                              cursor: "pointer",
                              borderRadius: "inherit",
                              backgroundColor: "rgb(73, 69, 69)",
                              height: "115px",
                            }}
                          />
                        )}
                      >
                        {vendorSuggestedJobTitle.data != undefined
                          ? vendorSuggestedJobTitle.data.map((data, index) => {
                              return (
                                <li>
                                  <a
                                    onClick={() => {
                                      history.push(
                                        `/jobSearch/suggested/0/${data.title}/location/0`
                                      );
                                    }}
                                  >
                                    {data.title}
                                  </a>
                                </li>
                              );
                            })
                          : null}
                      </Scrollbars>
                      {/* <li><a href="#">Software Engineer</a></li>
                      <li><a href="#">Developer</a></li>
                      <li><a href="#">Test Lead</a></li>
                      <li><a href="#">Dev Lead</a></li>
                      <li><a href="#">UI Developer</a></li> 
                      <li><a href="#">UI Developer</a></li>  */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>

        <Modal
          show={jobStatusOPen}
          onHide={() => {
            setJobStatusOPen(!jobStatusOPen);
          }}
          // style={{width:680}}
        >
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinishStatus}
            autoComplete="off"
          >
            {/* <form onSubmit={handleSubmit3(handleJobSubmit)} noValidate > */}
            <Modal.Header closeButton>
              <Modal.Title>My Candidate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* filteration */}

              <div className="action_btn1">
                <button
                  type="button"
                  className="actions_bt"
                  onClick={() => {
                    setFilterShow(!filterShow);
                  }}
                >
                  <i
                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                    aria-hidden="true"
                  />
                </button>
                <div className="col-sm-12">
                  <div
                    className="dash_action"
                    tabIndex={-1}
                    style={{ display: filterShow ? "block" : "none" }}
                  >
                    <div className="action_sec">
                      <ul ref={menuRef}>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(0);
                            }}
                            style={{ color: statusFilter !== 0 ? "" : "blue" }}
                          >
                            All
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(1);
                            }}
                            style={{ color: statusFilter !== 1 ? "" : "blue" }}
                          >
                            Applied
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(2);
                            }}
                            style={{ color: statusFilter !== 2 ? "" : "blue" }}
                          >
                            {" "}
                            Selected{" "}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(3);
                            }}
                            style={{ color: statusFilter !== 3 ? "" : "blue" }}
                          >
                            Rejected{" "}
                          </a>
                        </li>

                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(4);
                            }}
                            style={{ color: statusFilter !== 4 ? "" : "blue" }}
                          >
                            On Hold
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(5);
                            }}
                            style={{ color: statusFilter !== 5 ? "" : "blue" }}
                          >
                            Management
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(6);
                            }}
                            style={{ color: statusFilter !== 6 ? "" : "blue" }}
                          >
                            Technical
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(7);
                            }}
                            style={{ color: statusFilter !== 7 ? "" : "blue" }}
                          >
                            Interview: HR
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(8);
                            }}
                            style={{ color: statusFilter !== 8 ? "" : "blue" }}
                          >
                            Shortlist
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* filteration end*/}

              <div>
                <div className="candidate_top">
                  <div className="col-sm-4">
                    <input
                      style={{ cursor: "pointer" }}
                      id="checkbox1"
                      type="checkbox"
                      name="checkbox"
                      defaultValue={1}
                      checked={isCheckedAll}
                      // defaultChecked={isCheckedAll ? true : false}
                      onChange={(e: any) => handleAllChecked(e)}
                    />
                    <label htmlFor="checkbox1">
                      <span /> Select All
                    </label>
                  </div>
                  <div className="col-sm-4">
                    <input
                      style={{ cursor: "pointer" }}
                      id="checkbox1"
                      type="checkbox"
                      name="checkbox"
                      defaultValue={1}
                      checked={isRemovedAll}
                      onChange={(e: any) => handleAllRemoved(e)}
                    />
                    <label htmlFor="checkbox1">
                      <span /> De-select All
                    </label>
                  </div>

                  <div className="col-sm-4" style={{ paddingTop: 4 }}>
                    {statusFilter === 1 || statusFilter === 3 ? (
                      <Popconfirm
                        title={
                          <div>
                            <p>Are you sure delete this Candidate </p>
                          </div>
                        }
                        onConfirm={handleJobStatusDelete}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          //  onClick={handleJobStatusDelete}
                        >
                          <i className="fa fa-trash-o" aria-hidden="true" />{" "}
                          Delete
                        </div>
                      </Popconfirm>
                    ) : (
                      <Tooltip title={content}>
                        <div style={{ cursor: "pointer", color: "gray" }}>
                          <i className="fa fa-trash-o" aria-hidden="true" />{" "}
                          Delete
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="clearfix" />

                {vendorJobStatus != undefined
                  ? vendorJobStatus.map((data, index) => {
                      return (
                        <>
                          <div style={{ paddingTop: 22 }}>
                            <div className="candidate_no">
                              <div>
                                {" "}
                                <input
                                  id="checkbox1"
                                  type="checkbox"
                                  name="checkbox"
                                  onChange={(e: any) =>
                                    handleChecked(e, data.candidateId)
                                  }
                                  checked={checkedListArray.includes(
                                    data.candidateId
                                  )}
                                />
                                <label htmlFor="checkbox1">
                                  <span />
                                  C-00{index + 1}
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">
                                  Candidate first Name
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.firstName}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Middle Name</label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.middleName}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Last Name</label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.lastName}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Passport No</label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.passPort}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">DOB</label>
                                <DatePicker
                                  placeholder="YYYY-MM-DD"
                                  style={{ height: 39, width: 196 }}
                                  disabled
                                  onChange={onChangeDate}
                                  defaultValue={moment(data.dob, "DD/MM/YYYY")}
                                  format={"DD/MM/YYYY"}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Active Visa?</label>
                                <div className="row">
                                  <div className="col-xs-6">
                                    {/* <input disabled id="radio1" type="radio" name="radio" checked={data.isActivevisa} /><label htmlFor="radio1"><span><span /></span> Yes</label> */}
                                    <Radio
                                      checked={data.isActivevisa}
                                      value={1}
                                    >
                                      Yes
                                    </Radio>
                                  </div>
                                  <div className="col-xs-6">
                                    <Radio
                                      checked={!data.isActivevisa}
                                      value={2}
                                    >
                                      No
                                    </Radio>
                                    {/* <input disabled id="radio1" type="radio" name="radio" defaultValue={1} checked={data.isActivevisa?false:true}/><label htmlFor="radio1"><span><span /></span> No</label> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                            <div
                              className="col-sm-4"
                              style={{
                                display: data.isActivevisa ? "" : "none",
                              }}
                            >
                              <div className="form-group">
                                <label htmlFor="email">
                                  Visa Issuing Country{" "}
                                </label>
                                <input
                                  disabled
                                  defaultValue={data.visaIssueCountry}
                                  type="text"
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div
                              className="col-sm-4"
                              style={{
                                display: data.isActivevisa ? "" : "none",
                              }}
                            >
                              <div className="form-group">
                                <label htmlFor="email">Expiry date </label>
                                <DatePicker
                                  placeholder="YYYY-MM-DD"
                                  style={{ height: 39, width: 196 }}
                                  disabled
                                  onChange={onChangeDate}
                                  defaultValue={moment(
                                    data.expirary_Date,
                                    "DD/MM/YYYY"
                                  )}
                                  format={"DD/MM/YYYY"}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            style={{ paddingTop: data.isActivevisa ? 72 : 1 }}
                          ></div>
                        </>
                      );
                    })
                  : null}

                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div style={{ paddingTop: 110 }}>
                          <div className="candidate_no">
                            C-00{index + indexCount + 1}
                          </div>
                          <div className="close_icon _cursor-pointer">
                            <img
                              onClick={() => {
                                remove(field.name);
                                handleRemoveIndex(index);
                              }}
                              src={require("./../../../images/close_icon.png")}
                              width={16}
                              height={16}
                              style={{ marginTop: 26 }}
                            />
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="email">Candidate First Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "firstName"]}
                              fieldKey={[field.fieldKey, "firstName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing first name",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Middle Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "middleName"]}
                              fieldKey={[field.fieldKey, "middleName"]}
                              rules={[
                                {
                                  required: false,
                                  message: "Middle Name Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Last Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "lastName"]}
                              fieldKey={[field.fieldKey, "lastName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Last Name Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Passport No</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "passportNo"]}
                              fieldKey={[field.fieldKey, "passportNo"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Passport No Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">DOB</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "dob"]}
                              fieldKey={[field.fieldKey, "dob"]}
                              rules={[
                                { required: true, message: "DOB Missing " },
                              ]}
                            >
                              {/* <Input placeholder=""  />               */}
                              <DatePicker
                                placeholder="YYYY-MM-DD"
                                style={{ height: 39, width: 196 }}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="email">Active Visa?</label>
                              <div className="row">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "activeVisa"]}
                                  fieldKey={[field.fieldKey, "activeVisa"]}
                                  rules={[
                                    {
                                      required: false,
                                      message: "activeVisa Missing ",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    // onChange={radioValue2!=index?()=>{setRadioValue2(index)}:()=>{setRadioValue2(1000)}}
                                    onChange={handleOnRadioButtonChange}
                                    defaultValue={`f${index}`}
                                  >
                                    <div className="col-xs-6">
                                      <Radio defaultChecked value={`f${index}`}>
                                        Yes
                                      </Radio>
                                    </div>
                                    <div className="col-xs-6">
                                      <Radio value={`s${index}`}>No</Radio>
                                    </div>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="clearfix" />

                          <div
                            className="col-sm-4"
                            style={{
                              display: radioIndexList.includes(index)
                                ? "none"
                                : "",
                            }}
                          >
                            <label htmlFor="email">Visa Issuing Country</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "visaIssueCountry"]}
                              fieldKey={[field.fieldKey, "visaIssueCountry"]}
                              rules={[
                                {
                                  required: radioIndexList.includes(index)
                                    ? false
                                    : true,
                                  message: "Visa Issuing Country Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div
                            className="col-sm-4"
                            style={{
                              display: radioIndexList.includes(index)
                                ? "none"
                                : "",
                            }}
                          >
                            <label htmlFor="email">Expiry Date</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "expiryDate"]}
                              fieldKey={[field.fieldKey, "expiryDate"]}
                              rules={[
                                {
                                  required: radioIndexList.includes(index)
                                    ? false
                                    : true,
                                  message: "expiryDate Missing ",
                                },
                              ]}
                            >
                              <DatePicker
                                placeholder="YYYY-MM-DD"
                                disabledDate={disabledDate}
                                style={{ height: 39, width: 196 }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                      <div className="col-sm-4">
                        <Form.Item>
                          <div className="form-group m_t_30">
                            <label htmlFor="email"></label>
                            <button
                              ref={secondButtonRef}
                              className="btn btn-success save-event waves-effect waves-light"
                              type="button"
                              onClick={() => add()}
                            >
                              Add Candidates
                            </button>
                          </div>
                        </Form.Item>
                      </div>
                    </>
                  )}
                </Form.List>

                <div className="clearfix" />

                {/* <div className="col-sm-12">
          <div className="form-group ">
            <label htmlFor="email"></label>
            <button className="btn btn-success save-event waves-effect waves-light" type="button">Add Candidates</button>
          </div>        
        </div> */}
              </div>
            </Modal.Body>
            <div className="modal-footer  m-t-30">
              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setJobStatusOPen(false);
                }}
                data-dismiss="modal"
                className="btn btn-default waves-effect"
                type="button"
              >
                Cancel
              </button>
            </div>
            <div className="clearfix" />
            {/* </form> */}
          </Form>
        </Modal>

        {/* end modal job Status  */}

        {/* scoial media share modal statrt */}
        <Modal
          show={isShareOpen}
          onHide={() => {
            setIsShareOpen(!isShareOpen);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-12 m_t_30 text-center">
              <div className="social1">
                <WhatsappShareButton
                  url={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                  )}
                  className={""}
                  title={"CareerApp Job Post"}
                >
                  {/* <a href="#" className="social_face"> */}
                  <Tooltip title="whatsApp">
                    <i
                      className="fa fa-whatsapp"
                      style={{
                        fontSize: 51,
                        color: "green",
                        paddingRight: 2,
                      }}
                    ></i>
                  </Tooltip>
                  {/* </a> */}
                </WhatsappShareButton>{" "}
                <TelegramShareButton
                  url={`career app job Share`}
                  title={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                  )}
                >
                  <Tooltip title="Telegram">
                    <i
                      className="fa fa-telegram"
                      style={{
                        fontSize: 48,
                        color: "rgb(55, 174, 226)",
                      }}
                    ></i>
                  </Tooltip>
                </TelegramShareButton>
                <EmailShareButton
                  url={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                  )}
                  subject={"Jobs In Carrer app"}
                  body={"This is a Jobs from   Carrer app"}
                  className={""}
                >
                  {/* <EmailIcon></EmailIcon> */}
                  {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                  <Tooltip title="Gmail">
                    <img
                      src={require("../../../images/gmail-icon-svg-28.jpg")}
                      style={{ height: 66, marginTop: -24 }}
                    />
                  </Tooltip>
                </EmailShareButton>{" "}
                <CopyToClipboard
                  //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                  text={`https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`}
                >
                  <Tooltip title="copy to clipboard">
                    {" "}
                    {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                    <img
                      src={require("../../../images/copy-clipboard-icon-28.jpg")}
                      style={{
                        height: 66,
                        marginTop: -24,
                        marginLeft: -15,
                      }}
                      onClick={() => {
                        setIsShareOpen(!isShareOpen);
                      }}
                    />
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>
            <div className="modal-footer  m-t-30"></div>
          </Modal.Body>
        </Modal>
        {/* social media modal end */}
      </div>
    </>
  );
};

export default VendorJobAppliedComponent;
