import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import {
  getMyTeamMemberList,
  deleteMyTeamMember,
  useMyTeamMemberContext,
  useMyTeamMemberDispatcher,
} from "../../../../action/admin/MyTeamMemberAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../../action/MyProfileAdminAction";
import {
  MyTeamMemberRequestModel,
  MyTeamMemberEditRequestModel,
  MyTeamMember,
  MyTeamMemberViewModel,
  MyTeamMemberSaveRequestModel,
  MyTeamMemberSaveRespondModel,
  MyTeamMemberDeleteRequestModel,
} from "../../../../models/admin/MyTeamMember";

import AuthService from "../../../../services/AuthService";
import MyTeamMemberForm from "./TeamMember";
import { Popover } from "antd";
import Popconfirm from "antd/lib/popconfirm";
import { environment } from "../../../../environments/environment";
import {
  useAdminMyJobDispatcher,
  useAdminMyJobContext,
} from "../../../../action/admin/AdminMyJobAction";

interface IMyTeamMemberComponentProps {}

interface IMyTeamMemberComponentState {}

const defaultValues = {};

const MyTeamMemberComponent: React.FC<IMyTeamMemberComponentProps> = (
  props
) => {
  const [
    MyTeamMemberComponentState,
    setMyTeamMemberComponentState,
  ] = React.useState<IMyTeamMemberComponentState>(defaultValues);

  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;

  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const { myProfile, basicInfo, loggedAdminId } = myProfileAdminContext;

  const adminId = loggedAdminId;

  //console.log("userId", userId, "adminId", adminId);

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  const MyTeamMemberDispatcher = useMyTeamMemberDispatcher();
  const MyTeamMemberContext = useMyTeamMemberContext();
  const { MyTeamMember, MyTeamMemberSaveRespond } = MyTeamMemberContext;
  //console.log("----------MyTeamMember-----------", MyTeamMember);

  let history = useHistory();

  const [isMyTeamMemberOpen, setisMyTeamMemberOpen] = useState<boolean>(false);
  const [MyTeamMemberId, setMyTeamMemberId] = useState<number>(0);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IMyTeamMemberComponentState>({
    defaultValues,
  });

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      //console.log("career tips call getMyTeamMemberList", adminId);
      (async () => {
        await getMyTeamMemberList(
          MyTeamMemberDispatcher,
          {
            adminId: 0,
            page: 1,
            pageSize: 60,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as MyTeamMemberRequestModel
          //authorizationToken
        );
      })();
    }
  });

  const handleMyTeamDelete = (MyTeamMemberId: number) => {
    if (authorizationToken != null)
      deleteMyTeamMember(
        MyTeamMemberDispatcher,
        {
          rowId: MyTeamMemberId,
        } as MyTeamMemberDeleteRequestModel,
        authorizationToken
      );
  };

  const handleJobApplayClick = (id: any) => {
    setMyTeamMemberComponentState({
      ...MyTeamMemberComponentState,
      myTeamMemberId: id,
    });
    handleCareerAppliedOpen();
  };

  const handleCareerAppliedOpen = () => {
    setisMyTeamMemberOpen(!isMyTeamMemberOpen);
  };

  const menuRef = useRef<any>();

  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setIsListOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">My Team</h1>
          <a
            onClick={() => {
              setMyTeamMemberId(0);
              handleCareerAppliedOpen();
            }}
            data-target="#create_jobdescription"
            data-toggle="modal"
            className="btn cutomise_but"
          >
            <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp; Add New
          </a>
          <div className="action_btn1">
            <button
              type="button"
              className="actions_menu"
              onClick={() => {
                setIsListOpen(!isListOpen);
              }}
            >
              <i className="fa fa-align-left" aria-hidden="true" />
            </button>
            <div
              className="user_menu"
              tabIndex={-1}
              style={{ display: isListOpen ? "block" : "none" }}
            >
              <div className="action_sec">
                <ul ref={menuRef}>
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isMyTeamPermission ? (
                    <li>
                      <Link to="/admin/myteammember">My Team</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isStoriesofJIT ? (
                    <li>
                      <Link to="/admin/story">Stories of JIT</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isCompanyContactsandSocialPermission ? (
                    <li>
                      <Link to="/admin/socialaccounturl">
                        Company Contacts and Social Media
                      </Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isAchievementPermission ? (
                    <li>
                      <Link to="/admin/achievements">Achievements</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  (AdminPermissionResult.isAddJobCategoryPermission ||
                    AdminPermissionResult.isAddJobTitlePermission) ? (
                    <li>
                      <Link to="/admin/sitesettings/jobCategory">
                        Category / Job Title
                      </Link>{" "}
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="our_team">
            <div className="row">
              <div
                className="my_job_descrip"
                style={{ overflow: "hidden", outline: "none" }}
                tabIndex={0}
              >
                <Scrollbars
                  style={{ height: 750 }}
                  autoHide
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        position: "relative",
                        display: "block",
                        width: "5px",
                        cursor: "pointer",
                        borderRadius: "inherit",
                        backgroundColor: "rgb(73, 69, 69)",
                        height: "115px",
                      }}
                    />
                  )}
                >
                  {MyTeamMember.data != undefined
                    ? MyTeamMember.data.map((team, i) => {
                        return (
                          <div className="col-sm-4 col-lg-3" key={`${i}`}>
                            <div className="our_team_sec">
                              <div>
                                <img
                                  width="248px!important"
                                  height="248px!important"
                                  src={
                                    environment.baseUrl +
                                    `/Upload/MyTeamMember/${team.imageUrl}`
                                  }
                                  //className="img-responsive center-block"
                                />
                              </div>
                              <h2>{team.name}</h2>
                              <p>{team.designationName}</p>
                              <div className="admin_edit">
                                <a
                                  onClick={() => {
                                    setMyTeamMemberId(team.rowId);
                                    handleCareerAppliedOpen();
                                  }}
                                  data-target="#create_jobdescription"
                                  data-toggle="modal"
                                >
                                  Edit
                                </a>
                              </div>
                              <div className="admin_edit">
                                <Popconfirm
                                  title={
                                    <div>
                                      <p>
                                        Are you sure to delete this team member?
                                      </p>
                                    </div>
                                  }
                                  onConfirm={() =>
                                    handleMyTeamDelete(team.rowId)
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <a
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    Delete
                                  </a>
                                </Popconfirm>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </Scrollbars>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <MyTeamMemberForm
            isMyTeamMemberOpen={isMyTeamMemberOpen}
            handleMyTeamMemberOpen={handleCareerAppliedOpen}
            currentMyTeamMemberId={MyTeamMemberId}
            loggedAdminId={loggedAdminId}
            authorizationToken={authorizationToken}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default MyTeamMemberComponent;
