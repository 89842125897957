export const staticEmailTemplate = {
  data: [
    {
      rowId: 4,
      title: "Application Review response ",
      description: "Template 01 description",
      emailBodyContent:
        "Dear <<Name>>,\n\n Thanks for showing interest in our new opening. We appreciate your interest in <Opening and company name>. We are reviewing applications and expect to schedule interviews in the next couple of weeks. If you are selected for an interview, you can expect a phone call from our Human Resources staff shortly. Please share me your best time to reach you.\n\n Regards,\n <<Name>>\n<<Address>>",
      emailContent: "<<Job Title>> at JIT LLC",
      emailTemplateImage: "emailStaticTemplateOne.PNG",
      emailTemplateFile: null,
    },
    {
      rowId: 5,
      title: "Document Request ",
      description: "Template 02 description",
      emailBodyContent:
        "Dear <<Name>>\n\nThank you for your interest to work at JIT LLC. We have successfully received your resume in reply to the <<job titles>> job opening. We are going to assess your qualifications to see if they match the requirements of the advertised position. \nOur screening team requires some more documents from your . Please send the following list of documents.\n\n<<Document 1>>\n\n<<Document 2>>.\n.\n.\n.\n\n Yours sincerely,\n<<Name>>\n<<Formal Name and titles>>",
      emailContent: "<<Job Title>> at JIT LLC",
      emailTemplateImage: "emailStaticTemplateTwo.PNG",
      emailTemplateFile: null,
    },
    {
      rowId: 6,
      title: "Rejection Template",
      description: "Template 03 description",
      emailBodyContent:
        "Dear <<Name>>\n\n We appreciate your interest in JIT LLC for the position of <<Job Titles>>for which you applied on <<Date>>. After reviewing our application, yours was not selected for further consideration, as we found that you could not meet specific eligibility criteria.\n\n We would like to advise you that when you apply for any post, please go through the eligibility criterion well and submit the correct set of documents that will not mark you as a careless candidate. The selection committee appreciates the time you invested in your application.\n\n We wish you every personal and professional success with your job search. Thank you again for your interest in our company\n\n Regards,\n<<Name>>\n<<Job Title>>\n<<Company>>",
      emailContent: "<<Job Title>> at JIT LLC",
      emailTemplateImage: "emailStaticTemplateThree.PNG",
      emailTemplateFile: null,
    },
    {
      rowId: 7,
      title: " Rejection Template 2 ",
      description: "Template 03 description",
      emailBodyContent:
        "Dear<<Name>>\n\n Thank you for the recent submittal of your resume and cover letter in response to the advertised opening at <<Company name >>. We regret that the position has already been filled. We will retain your information consider it for future openings. As this and/or other positions become available in the future, we encourage you to follow up with us.\n\nSincerely,\n\n<<Name>> \n<<Job Title>>\n <<Company>>",
      emailContent: "<<Job Title>> at JIT LLC",
      emailTemplateImage: "emailStaticTemplateFour.PNG",
      emailTemplateFile: null,
    },
    {
      rowId: 8,
      title: "Interview Schedule Template 1",
      description: "Template 03 description",
      emailBodyContent:
        "Dear <<Name>>\n\n In response to your application for the post of <<Job Title>> you are requested to call for interview on <<Date and time >>. Please bring all original copies of your certificates.\n\n It should be noted that you will not be entitled to pay any TA or DA for attending the interview.\n\nThanking you,\n<<Name>> \n<<Job Title>>\n<<Company>>",
      emailContent: " Interview Schedule for <<Job Title>> at JIT LLC",
      emailTemplateImage: "emailStaticTemplateFive.PNG",
      emailTemplateFile: null,
    },
    {
      rowId: 9,
      title: "Interview Schedule Template 2",
      description: "Template 03 description",
      emailBodyContent:
        " Dear <<Name>>\n\nThank you for applying for the position of <<Job Title>>.\n\n We would like to invite you to come to our office to interview for the position. Your interview has been scheduled for <<Date time >>at <<address>>. \n\nPlease call me at <<phone number >>or email me at <<email>> if you have any questions or need to reschedule.\n \nSincerely \n<<Name>>\n<<Job Title>>\n<<Company>>",
      emailContent: " Interview Schedule for <<Job Title>> at JIT LLC",
      emailTemplateImage: "emailStaticTemplateSix.PNG",
      emailTemplateFile: null,
    },
    {
      rowId: 10,
      title: "Interview Schedule Template 3",
      description: "Template 03 description",
      emailBodyContent:
        " Dear <<Name>>\n\nThis is to the reference your application for the job <<job position>> indicating interest in seeking employment in our company. Thank you for the same.\n \nWe would like to inform you that your profile is being shortlisted for the job role and is best suited for it. Therefore, we would like to take a face to face interview with you on << Date and time>> at <<Venue details>>.\n\nWe hope that the venue is suitable for you. If not please get in touch with us, so that we can arrange the Date and venue according to your availability.\n\nThe company will reimburse you all the expenses incurred by you for this interview. This email has an attachment in which you need to fill in the details and carry it along on the date of the interview.\nKindly confirm your availability for the date and venue. If there are any changes to be made. Please contact us at phone number<<phone number>> and email id <<email id>>\n\nWe look forward to see you\n\nRegards,\n<<Name>> \n<<Job Title>>\n <<Company>>",
      emailContent: "Interview Schedule for <<Job Title>> at JIT LLC",
      emailTemplateImage: "emailStaticTemplateSeven.PNG",
      emailTemplateFile: null,
    },
  ],
};
