import React, { useState, useEffect, useContext, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  saveCandidate,
  saveCandidateLanguageMaps,
  saveCandidateRelatives,
  saveCandidateReferences,
} from "./../../../apis/candidate";
import {
  sendEmailOpt,
  sendPhoneOtp,
  verifyCode,
  emailVerifyCode,
  verifyCodeNewMobile,
} from "./../../../apis/misc";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "./../../../context/GlobalSpinner";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import { reactHookFormServerErrors } from "./../../../utils/utility";
import { Modal } from "react-bootstrap";
import { Link, withRouter, useLocation } from "react-router-dom";
import Timer from "react-compound-timer";
import IsComponentDisabled from "./../../components/IsComponentDisabled";
import PhoneNumberWithCode from "./../../components/PhoneNumberWithCode";
import _ from "lodash";
import SelectOption from "./components/SelectOption";

type objectIndexing = {
  [key: string]: any;
};
interface ReactSelectOption {
  value: string;
  label: string;
}

function EditContact2(props: any) {
  const [contactNumber, setContactNumber] = useState("");

  const [countryCode, setCountryCode] = useState("+91");

  const [showTimer, setShowTimer] = useState(false);

  const [timerMsg, setTimerMsg] = useState("");

  const [screen, setScreen] = useState(1);

  const globalSpinner = useGlobalSpinnerContext();

  const [disabled, setDisabled] = useState(false);

  const { register, handleSubmit, watch, errors, trigger, control } = useForm<{
    contactNumber: string;
    countryCode: string;
  }>({
    defaultValues: { contactNumber: "", countryCode: "" },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    errors: errors2,
    setError: setError2,
    trigger: trigger2,
    clearErrors: clearErrors2,
  } = useForm<{ code: string }>({
    defaultValues: { code: "" },
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    watch: watch3,
    control: control3,
    errors: errors3,
    trigger: trigger3,
    clearErrors: clearErrors3,
  } = useForm<{ newContactNumber: string; newCountryCode: string }>({
    defaultValues: { newContactNumber: "", newCountryCode: "+91" },
  });

  const onSubmit = (data: any) => {
    console.log(data);

    setCountryCode(data["countryCode"] == "" ? "+91" : data["countryCode"]);
    setContactNumber(data["contactNumber"]);
    globalSpinner.showSpinner();
    // sendPhoneOtp({}, `?countryCode=${encodeURIComponent(data["countryCode"])}&phoneNumber=${encodeURIComponent(data["contactNumber"])}`).then((res: any) => {
    sendPhoneOtp(
      {},
      `?countryCode=${encodeURIComponent(
        data["countryCode"] == "" ? "+91" : data["countryCode"]
      )}&phoneNumber=${encodeURIComponent(data["contactNumber"])}`
    )
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (res.data.includes("error") || res.data.includes("Invalid")) {
          toast.error(res.data);
        } else {
          // toast.success("OTP has been send.");
          toast.success(
            "OTP has been successfully sent on your mobile number."
          );
          setScreen(2);
          setShowTimer(true);
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const sendVerification = async () => {
    console.log(11, countryCode);
    console.log(12, contactNumber);

    globalSpinner.showSpinner();
    sendPhoneOtp(
      {},
      `?countryCode=${encodeURIComponent(
        countryCode
      )}&phoneNumber=${encodeURIComponent(contactNumber)}`
    )
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (res.data.includes("error") || res.data.includes("Invalid")) {
          toast.error(res.data);
        } else {
          //toast.success("OTP has been resend.");
          toast.success(
            "OTP has been successfully sent on your mobile number."
          );
          setShowTimer(true);
          setTimerMsg("");
          setDisabled(false);
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const onSubmit2 = (data: any) => {
    // console.log(33, data);
    // console.log(41, countryCode);
    // console.log(42, contactNumber);
    // console.log(43, props.oldCountryCode);
    // console.log(44, props.oldCountryCode);
    globalSpinner.showSpinner();
    verifyCodeNewMobile(
      {},
      `?newCountryCode=${encodeURIComponent(
        countryCode
      )}&newPhoneNumber=${encodeURIComponent(
        contactNumber
      )}&oldCountryCode=${encodeURIComponent(
        props.oldCountryCode
      )}&oldPhoneNumber=${encodeURIComponent(props.oldPhoneNumber)}&code=${
        data["code"]
      }`
    )
      .then((res: any) => {
        globalSpinner.hideSpinner();
        console.log(res);
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          toast.success("OTP verified.");
          setTimerMsg("");
          //setScreen(3);
          let data = {
            newCountryCode: countryCode,
            newContactNumber: contactNumber,
          };
          props.updateNumber(data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        //toast.error("Something went wrong");
        //toast.error("Error: Invalid OTP.Please enter correct OTP");
        reactHookFormServerErrors(
          {
            code: ["Error: Invalid OTP.Please enter correct OTP"],
          },
          setError2
        );
      });
  };

  const onSubmit3 = (data: any) => {
    console.log(data);
    props.updateNumber(data);
  };

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit Contact number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-12">
          {screen == 1 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Contact Number</label>
                {/* <input type="text" className="form-control" placeholder="Type here" name="contactNumber" ref={register({
                  required: "Number is required"
                })} /> */}
                <Controller
                  control={control}
                  name="countryCode"
                  render={({ onChange, onBlur, value, name }) => (
                    <PhoneNumberWithCode
                      // codeHeight={"50px"}
                      downwards={true}
                      noPenIcon={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      disabled={false}
                      phoneNo={
                        <input
                          type="text"
                          name="contactNumber"
                          disabled={false}
                          id="phoneNumber"
                          className="form-control phoneNumber"
                          placeholder="Type here"
                          ref={register({
                            required: "Mobile number is required",
                            maxLength: {
                              value: watch("countryCode").length > 3 ? 9 : 10,
                              message: `It must be ${
                                watch("countryCode").length > 3 ? 9 : 10
                              } digits only.`,
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Mobile number should be numbers only",
                            },
                            minLength: {
                              value: watch("countryCode").length > 3 ? 9 : 10,
                              message: `It must be ${
                                watch("countryCode").length > 3 ? 9 : 10
                              } digits only.`,
                            },
                          })}
                        />
                      }
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="contactNumber"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 2 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Enter Code to confirm </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="code"
                  ref={register2({
                    required: "Code is required",
                  })}
                />

                <ErrorMessage
                  errors={errors2}
                  name="code"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />

                {showTimer ? (
                  <Timer
                    initialTime={60000 * 5}
                    direction="backward"
                    onStop={() => {
                      console.log("stop");
                    }}
                    onResume={() => console.log("onResume hook")}
                    checkpoints={[
                      {
                        time: 0,
                        callback: () => {
                          console.log("Checkpoint A");
                          setShowTimer(false);
                          setTimerMsg("OTP Expired.");
                          setDisabled(true);
                        },
                      },
                    ]}
                  >
                    {({ reset }: { reset: any }) => (
                      <React.Fragment>
                        <div className="login_validation">
                          {" "}
                          OTP Expires in <Timer.Minutes /> Minutes,{" "}
                          <Timer.Seconds /> Seconds
                        </div>
                      </React.Fragment>
                    )}
                  </Timer>
                ) : (
                  ""
                )}
                {timerMsg ? (
                  <div className="login_validation">{timerMsg}</div>
                ) : (
                  ""
                )}
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 3 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Contact Number</label>
                {/* <input type="text" className="form-control" placeholder="Type here" name="newContactNumber" ref={register3({
                  required: "New Number is required"
                })} /> */}
                <Controller
                  control={control3}
                  name="newCountryCode"
                  render={({ onChange, onBlur, value, name }) => (
                    <PhoneNumberWithCode
                      // codeHeight={"50px"}
                      noPenIcon={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      disabled={false}
                      phoneNo={
                        <input
                          type="text"
                          name="newContactNumber"
                          disabled={false}
                          id="phoneNumber"
                          className="form-control phoneNumber"
                          placeholder="Type here"
                          ref={register({
                            required: "Mobile number is required",
                            maxLength: {
                              //value: 15,
                              value: 10,
                              message: "Should be a mobile number",
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Mobile number should be numbers only",
                            },
                          })}
                        />
                      }
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors3}
                  name="newContactNumber"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}
        </div>

        {screen == 2 ? (
          <div className="already_login1">
            Didn't get the code?
            <a
              className="_cursor-pointer"
              onClick={() => {
                sendVerification();
              }}
            >
              &nbsp;Send again.
            </a>{" "}
            {/*  { if (!showTimer)}*/}
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
      <div className="modal-footer  m-t-30">
        {screen == 1 ? (
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Send Verification Code
          </button>
        ) : (
          ""
        )}
        {screen == 2 ? (
          <button
            onClick={handleSubmit2(onSubmit2)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
            disabled={disabled}
          >
            Verify
          </button>
        ) : (
          ""
        )}
        {screen == 3 ? (
          <button
            onClick={handleSubmit3(onSubmit3)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Save
          </button>
        ) : (
          ""
        )}
        <button
          onClick={() => {
            props.setIsOpen(!props.isOpen);
          }}
          data-dismiss="modal"
          className="btn btn-default waves-effect"
          type="button"
        >
          Cancel
        </button>
      </div>
      <div className="clearfix" />
    </React.Fragment>
  );
}

// function EditContact2(props: any) {

//   // const [contactNumber, setContactNumber] = useState("");

//   const [state, setState] = useState({ countryCode: "91", contactNumber: "" });

//   const [state3, setState3] = useState({ countryCode3: "91", contactNumber3: "" });

//   const [showTimer, setShowTimer] = useState(false);

//   const [timerMsg, setTimerMsg] = useState("");

//   const [screen, setScreen] = useState(1);

//   const globalSpinner = useGlobalSpinnerContext();

//   const { register, handleSubmit, watch, errors, trigger, clearErrors, control } = useForm<{ countryCode: string, contactNumber: string }>({
//     defaultValues: { countryCode: "", contactNumber: "" }
//   });

//   const { register: register2, handleSubmit: handleSubmit2, watch: watch2,
//     errors: errors2, trigger: trigger2, clearErrors: clearErrors2 } = useForm<{ code: string }>({
//       defaultValues: { code: "" }
//     });

//   const { register: register3, handleSubmit: handleSubmit3, watch: watch3, control: control3,
//     errors: errors3, trigger: trigger3, clearErrors: clearErrors3 } = useForm<{ countryCode: string, contactNumber: string }>({
//       defaultValues: { countryCode: "", contactNumber: "" }
//     });

//   const onSubmit = (data: any) => {
//     console.log(data);

//     // setContactNumber(data["contactNumber"]);

//     setState((prevState: any) => ({
//       ...prevState,
//       countryCode: data["countryCode"], contactNumber: data["contactNumber"]
//     }));

//     globalSpinner.showSpinner();

//     sendPhoneOtp({}, `?phoneNumber=+${encodeURIComponent(data["countryCode"])}${encodeURIComponent(data["contactNumber"])}`).then((res: any) => {
//       globalSpinner.hideSpinner();
//       if (res.data.includes("error")) {
//         toast.error(res.data);
//       } else {
//         toast.success("OTP has been send.");
//         setScreen(2);
//         setShowTimer(true);
//       }
//     }).catch((err: any) => {
//       globalSpinner.hideSpinner();
//       toast.error("Something went wrong");
//     });

//   };

//   const sendVerification = async () => {
//     console.log(state.contactNumber);

//     globalSpinner.showSpinner();
//     sendPhoneOtp({}, `?phoneNumber=+${encodeURIComponent(state.countryCode)}${encodeURIComponent(state.contactNumber)}`).then((res: any) => {
//       globalSpinner.hideSpinner();
//       if (res.data.includes("error")) {
//         toast.error(res.data);
//       } else {
//         toast.success("OTP has been resend.");
//         setShowTimer(true);
//         setTimerMsg("");
//       }
//     }).catch((err: any) => {
//       globalSpinner.hideSpinner();
//       toast.error("Something went wrong");
//     });
//   }

//   const onSubmit2 = (data: any) => {
//     console.log(data);

//     globalSpinner.showSpinner();

//     verifyCode({}, `?phoneNumber=${state.contactNumber}&code=${data["code"]}`).then((res: any) => {
//       globalSpinner.hideSpinner();
//       console.log(res);
//       if (res.data.includes("error")) {
//         toast.error(res.data);
//       } else {
//         toast.success("OTP verified.");
//         setTimerMsg("");
//         setScreen(3);
//       }
//     }).catch((err: any) => {
//       console.log(err)
//       globalSpinner.hideSpinner();
//       toast.error("Something went wrong");
//     });
//   };

//   const onSubmit3 = (data: any) => {
//     console.log(data);
//     props.updateNumber(data);
//   };

//   return (
//     <React.Fragment>
//       <Modal.Header closeButton>
//         <Modal.Title>Edit Contact number</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="col-sm-12">

//           {screen == 1 ?
//             <form noValidate>
//               <div className="form-group">
//                 <label htmlFor="email">Contact Number</label>
//                 {/* <input type="text" className="form-control" placeholder="Type here" name="contactNumber" ref={register({
//                   required: "Number is required"
//                 })} /> */}

//                 <Controller
//                   control={control}
//                   name="countryCode"
//                   render={({ onChange, onBlur, value, name }) => (
//                     <PhoneNumberWithCode
//                       // codeHeight={"50px"}
//                       noPenIcon={true}
//                       onChange={onChange}
//                       onBlur={onBlur}
//                       value={value}
//                       name={name}
//                       disabled={false}
//                       phoneNo={
//                         <input type="text" name="contactNumber"
//                           disabled={false}
//                           id="phoneNumber" className="form-control phoneNumber"
//                           placeholder="Type here"
//                           ref={register({
//                             required: "Mobile number is required",
//                             maxLength: {
//                               value: 10,
//                               message: "Please enter a valid mobile number"
//                             },
//                             pattern: {
//                               value: /^[0-9]*$/,
//                               message: "Mobile number should be numbers only"
//                             },
//                             minLength: {
//                               value: 10,
//                               message: "Mobile number must have 10 digits"
//                             }
//                           })} />
//                       } />
//                   )}
//                 />

//                 <ErrorMessage errors={errors} name="contactNumber" render={({ message }) => <div className="login_validation">{message}</div>} />
//               </div>
//             </form> : ""}

//           {screen == 2 ?
//             <form noValidate>
//               <div className="form-group">
//                 <label htmlFor="email">Enter Code to confirm 	</label>
//                 <input type="text" className="form-control" placeholder="Type here" name="code" ref={register2({
//                   required: "Code is required"
//                 })} />
//                 <ErrorMessage errors={errors2} name="code" render={({ message }) => <div className="login_validation">{message}</div>} />
//                 {
//                   showTimer ?
//                     <Timer
//                       initialTime={60000 * 5}
//                       direction="backward"
//                       onStop={() => { console.log("stop") }}
//                       onResume={() => console.log('onResume hook')}
//                       checkpoints={[
//                         {
//                           time: 0,
//                           callback: () => { console.log('Checkpoint A'); setShowTimer(false); setTimerMsg("OTP Expired.") },
//                         }
//                       ]}
//                     >
//                       {({ reset }: { reset: any }) => (
//                         <React.Fragment>
//                           <div className="login_validation"> OTP Expires in <Timer.Minutes /> Minutes, <Timer.Seconds /> Seconds</div>
//                         </React.Fragment>
//                       )}
//                     </Timer> : ""
//                 }
//                 {timerMsg ? <div className="login_validation">{timerMsg}</div> : ""}
//               </div></form> : ""}

//           {screen == 3 ?
//             <form noValidate>
//               <div className="form-group">
//                 <label htmlFor="email">Contact Number</label>
//                 {/* <input type="text" className="form-control" placeholder="Type here" name="newContactNumber" ref={register3({
//                   required: "New Number is required"
//                 })} /> */}

//                 <Controller
//                   control={control3}
//                   name="countryCode"
//                   render={({ onChange, onBlur, value, name }) => (
//                     <PhoneNumberWithCode
//                       // codeHeight={"50px"}
//                       noPenIcon={true}
//                       onChange={onChange}
//                       onBlur={onBlur}
//                       value={value}
//                       name={name}
//                       disabled={false}
//                       phoneNo={
//                         <input type="text" name="contactNumber"
//                           disabled={false}
//                           id="phoneNumber" className="form-control phoneNumber"
//                           placeholder="Type here"
//                           ref={register({
//                             required: "Mobile number is required",
//                             maxLength: {
//                               value: 10,
//                               message: "Please enter a valid mobile number"
//                             },
//                             pattern: {
//                               value: /^[0-9]*$/,
//                               message: "Mobile number should be numbers only"
//                             },
//                             minLength: {
//                               value: 10,
//                               message: "Mobile number must have 10 digits"
//                             }
//                           })} />
//                       } />
//                   )}
//                 />

//                 <ErrorMessage errors={errors3} name="newContactNumber" render={({ message }) => <div className="login_validation">{message}</div>} />
//               </div>
//             </form> : ""}

//         </div>

//         {screen == 2 ?
//           <div className="already_login1">Didn't get the code?
//         <a className="_cursor-pointer" onClick={() => { if (!showTimer) { sendVerification(); } }}>Send again.</a>
//           </div> : ""}

//       </Modal.Body>
//       <div className="modal-footer  m-t-30">
//         {screen == 1 ? <button onClick={handleSubmit(onSubmit)} className="btn btn-success save-event waves-effect waves-light" type="button">Send Verification Code</button> : ""}
//         {screen == 2 ? <button onClick={handleSubmit2(onSubmit2)} className="btn btn-success save-event waves-effect waves-light" type="button">Verify</button> : ""}
//         {screen == 3 ? <button onClick={handleSubmit3(onSubmit3)} className="btn btn-success save-event waves-effect waves-light" type="button">Save</button> : ""}
//         <button onClick={() => { props.setIsOpen(!props.isOpen) }} data-dismiss="modal" className="btn btn-default waves-effect" type="button">Cancel</button>
//       </div>
//       <div className="clearfix" />
//     </React.Fragment>
//   );
// }

function EditEmail2(props: any) {
  const [email, setEmail] = useState("");

  const [showTimer, setShowTimer] = useState(false);

  const [timerMsg, setTimerMsg] = useState("");

  const [screen, setScreen] = useState(1);

  const globalSpinner = useGlobalSpinnerContext();
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    trigger,
    clearErrors,
  } = useForm<{ email: string }>({
    defaultValues: { email: "" },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    errors: errors2,
    trigger: trigger2,
    setError: setError2,
    clearErrors: clearErrors2,
  } = useForm<{ code: string }>({
    defaultValues: { code: "" },
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    watch: watch3,
    errors: errors3,
    trigger: trigger3,
    clearErrors: clearErrors3,
  } = useForm<{ newEmail: string }>({
    defaultValues: { newEmail: "" },
  });

  const onSubmit = (data: any) => {
    console.log(data);

    setEmail(data["email"]);

    globalSpinner.showSpinner();
    sendEmailOpt({}, `?email=${data["email"]}`)
      .then((res: any) => {
        console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          //toast.success("OTP has been send.");
          toast.success("OTP has been successfully sent on your e-mail.");
          setScreen(2);
          setShowTimer(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const sendVerification = async () => {
    console.log(email);

    globalSpinner.showSpinner();
    sendEmailOpt({}, `?email=${email}`)
      .then((res: any) => {
        console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          //toast.success("OTP has been resend.");
          toast.success("OTP has been successfully sent on your e-mail.");
          setShowTimer(true);
          setTimerMsg("");
          setDisabled(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const onSubmit2 = (data: any) => {
    console.log(data);

    globalSpinner.showSpinner();

    emailVerifyCode({}, `?email=${email}&code=${data["code"]}`)
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          toast.success("OTP verified.");
          setTimerMsg("");
          setScreen(3);
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        //toast.error("Something went wrong");
        reactHookFormServerErrors(
          {
            code: ["Error: Invalid OTP.Please enter correct OTP"],
          },
          setError2
        );
      });
  };

  const onSubmit3 = (data: any) => {
    console.log(data);
    props.updateEmail(data);
  };

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-12">
          {screen == 1 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="email"
                  ref={register({
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please enter a valid e-mail address.",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 2 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Enter Code to confirm </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="code"
                  ref={register2({
                    required: "Code is required",
                  })}
                />
                <ErrorMessage
                  errors={errors2}
                  name="code"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
                {showTimer ? (
                  <Timer
                    initialTime={60000 * 5}
                    direction="backward"
                    onStop={() => {
                      console.log("stop");
                    }}
                    onResume={() => console.log("onResume hook")}
                    checkpoints={[
                      {
                        time: 0,
                        callback: () => {
                          console.log("Checkpoint A");
                          setShowTimer(false);
                          setTimerMsg("OTP Expired.");
                          setDisabled(true);
                        },
                      },
                    ]}
                  >
                    {({ reset }: { reset: any }) => (
                      <React.Fragment>
                        <div className="login_validation">
                          {" "}
                          OTP Expires in <Timer.Minutes /> Minutes,{" "}
                          <Timer.Seconds /> Seconds
                        </div>
                      </React.Fragment>
                    )}
                  </Timer>
                ) : (
                  ""
                )}
                {timerMsg ? (
                  <div className="login_validation">{timerMsg}</div>
                ) : (
                  ""
                )}
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 3 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="newEmail"
                  ref={register3({
                    required: "New email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors3}
                  name="newEmail"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}
        </div>

        {screen == 2 ? (
          <div className="already_login1">
            Didn't get the code?
            <a
              className="_cursor-pointer"
              onClick={() => {
                if (!showTimer) {
                  sendVerification();
                }
              }}
            >
              &nbsp;Send again.
            </a>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
      <div className="modal-footer  m-t-30">
        {screen == 1 ? (
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Send Verification Code
          </button>
        ) : (
          ""
        )}
        {screen == 2 ? (
          <button
            onClick={handleSubmit2(onSubmit2)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
            disabled={disabled}
          >
            Verify
          </button>
        ) : (
          ""
        )}
        {screen == 3 ? (
          <button
            onClick={handleSubmit3(onSubmit3)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Save
          </button>
        ) : (
          ""
        )}
        <button
          onClick={() => {
            props.setIsOpen2(!props.isOpen2);
          }}
          data-dismiss="modal"
          className="btn btn-default waves-effect"
          type="button"
        >
          Cancel
        </button>
      </div>
      <div className="clearfix" />
    </React.Fragment>
  );
}

interface IEmergencyContactState {
  rowId: string;
  emergencyContactRelative: string;
  emergencyContactName: string;
  emergencyCountryCode: string;
  emergencyContactMobile: string;
}

function EmergencyContact(props: any) {
  const {
    disabled,
    saveDisabled,
    candidateId,
    relatives,
    candidateRelativesState,
    getCandidateRelatives,
  } = props;
  const defaultValues = {
    rowId: "0",
    emergencyContactRelative: "",
    emergencyContactName: "",
    emergencyCountryCode: "",
    emergencyContactMobile: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm<IEmergencyContactState>({
    defaultValues,
  });
  const globalSpinner = useContext(GlobalSpinnerContext);
  useEffect(() => {
    reset({
      rowId:
        candidateRelativesState.data.length > 0
          ? candidateRelativesState.data[0]["rowId"]
          : "0",
      emergencyContactRelative:
        candidateRelativesState.data.length > 0
          ? candidateRelativesState.data[0]["candidateRelativeId"]
          : 0,
      emergencyContactName:
        candidateRelativesState.data.length > 0
          ? candidateRelativesState.data[0]["relativeFirstName"]
          : "",
      emergencyCountryCode:
        candidateRelativesState.data.length > 0
          ? candidateRelativesState.data[0]["countryCode"]
          : "+91",
      emergencyContactMobile:
        candidateRelativesState.data.length > 0
          ? candidateRelativesState.data[0]["phoneNumber"]
          : "",
    });
  }, [reset, candidateRelativesState.data.length]);

  const onSubmit = (data: any) => {
    //console.log(data);
    //console.log(candidateRelativesState);
    let _data2: objectIndexing = {};
    //if (candidateRelativesState.data.length > 0) {
    _data2["rowId"] =
      candidateRelativesState.data.length > 0
        ? candidateRelativesState.data[0]["rowId"]
        : 0;
    _data2["candidateRelativeId"] = data["emergencyContactRelative"];
    _data2["namePrefixId"] = 1;
    _data2["candidateId"] = Number(candidateId);
    _data2["RelationshipId"] = 1;
    _data2["FirstName"] = data["emergencyContactName"];
    _data2["LastName"] = "";
    _data2["TelephoneNumber"] = "";
    _data2["genderId"] = 1;
    _data2["countryCode"] = data["emergencyCountryCode"];
    _data2["phoneNumber"] = data["emergencyContactMobile"];
    _data2["IsActive"] = true;

    globalSpinner.showSpinner();
    saveCandidateRelatives(_data2)
      .then((res) => {
        if (res.data.isSuccess) {
          getCandidateRelatives({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success("Emergency contact details are saved.");
          globalSpinner.hideSpinner();
        } else {
          toast.error(res.data.message);
          globalSpinner.hideSpinner();
        }
      })
      .catch((err) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong.");
      });
  };

  return (
    <React.Fragment>
      <form
        className="personal_details"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <input
          type="number"
          name="rowId"
          style={{ display: "none" }}
          disabled={disabled}
          ref={register}
        />
        <div className="profile_top">
          <h1>Emergency Contact Details</h1>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label> Relation</label>
              {IsComponentDisabled(
                <span className="select-wrapper-sec">
                  <Controller
                    rules={{
                      // required: "Relation is required",
                      required: false,
                      validate: function (value) {
                        console.log(value);
                        return Number(value)
                          ? undefined
                          : "Relation is required";
                      },
                    }}
                    control={control}
                    name="emergencyContactRelative"
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={relatives}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>,
                disabled
              )}

              <ErrorMessage
                errors={errors}
                name="emergencyContactRelative"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label
              // className={"required"}
              >
                {" "}
                Name
              </label>
              {IsComponentDisabled(
                <input
                  // disabled={disabled}
                  type="text"
                  name="emergencyContactName"
                  ref={register({
                    // required: "Name is required",
                    required: false,
                  })}
                  className={"form-control form-control-n"}
                  placeholder="Type here"
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="emergencyContactName"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label
              // className={"required"}
              >
                {" "}
                Mobile
              </label>

              {IsComponentDisabled(
                <Controller
                  control={control}
                  name="emergencyCountryCode"
                  render={({ onChange, onBlur, value, name }) => (
                    <PhoneNumberWithCode
                      // codeHeight={"50px"}
                      noPenIcon={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      disabled={disabled}
                      phoneNo={
                        <input
                          type="text"
                          name="emergencyContactMobile"
                          disabled={disabled}
                          id="phoneNumber"
                          className="form-control phoneNumber"
                          placeholder="Type here"
                          ref={register({
                            required: false,
                            // required: "Mobile number is required",
                            maxLength: {
                              // value: 10,
                              value:
                                watch("emergencyCountryCode").length > 3
                                  ? 9
                                  : 10,
                              message: `It must be ${
                                watch("emergencyCountryCode").length > 3
                                  ? 9
                                  : 10
                              } digits only.`,
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Mobile number should be numbers only",
                            },
                            minLength: {
                              value:
                                watch("emergencyCountryCode").length > 3
                                  ? 9
                                  : 10,
                              message: `It must be minimum ${
                                watch("emergencyCountryCode").length > 3
                                  ? 9
                                  : 10
                              }  digits.`,
                            },
                          })}
                        />
                      }
                    />
                  )}
                />,

                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="emergencyContactMobile"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="clearfix" />
        </div>

        <button
          type="submit"
          className="btn submit_btn"
          disabled={saveDisabled}
        >
          Save
        </button>
      </form>
    </React.Fragment>
  );
}

interface IReferenceState {
  rowId: string;
  referenceName: string;
  referenceCountryCode: string;
  referenceMobile: string;
  referenceEmail: string;
}

function Reference(props: any) {
  const {
    disabled,
    saveDisabled,
    candidateId,
    relatives,
    candidateReferences,
    getCandidateReferences,
  } = props;
  const defaultValues = {
    rowId: "0",
    referenceName: "",
    referenceCountryCode: "",
    referenceMobile: "",
    referenceEmail: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm<IReferenceState>({
    defaultValues,
  });
  const globalSpinner = useContext(GlobalSpinnerContext);
  useEffect(() => {
    reset({
      rowId:
        candidateReferences.data.length > 0
          ? candidateReferences.data[0]["rowId"]
          : "0",
      referenceName:
        candidateReferences.data.length > 0
          ? candidateReferences.data[0]["referenceFirstName"]
          : "",
      referenceCountryCode:
        candidateReferences.data.length > 0
          ? candidateReferences.data[0]["countryCode"]
          : "+91",
      referenceMobile:
        candidateReferences.data.length > 0
          ? candidateReferences.data[0]["phoneNumber"]
          : "",
      referenceEmail:
        candidateReferences.data.length > 0
          ? candidateReferences.data[0]["email"]
          : "",
    });
  }, [reset, candidateReferences.data.length]);

  const onSubmit = (data: any) => {
    //console.log(data);
    //console.log(candidateRelativesState);
    let _data3: objectIndexing = {};

    _data3["rowId"] =
      candidateReferences.data.length > 0
        ? candidateReferences.data[0]["rowId"]
        : 0;
    _data3["candidateId"] = Number(candidateId);
    _data3["FirstName"] = data["referenceName"];
    _data3["LastName"] = data["referenceName"];
    _data3["countryCode"] = data["referenceCountryCode"];
    _data3["phoneNumber"] = data["referenceMobile"];
    _data3["email"] = data["referenceEmail"];
    _data3["IsActive"] = true;

    globalSpinner.showSpinner();
    saveCandidateReferences(_data3)
      .then((res) => {
        if (res.data.isSuccess) {
          getCandidateReferences({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success("Reference details are saved.");
          globalSpinner.hideSpinner();
        } else {
          toast.error(res.data.message);
          globalSpinner.hideSpinner();
        }
      })
      .catch((err) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong.");
      });
  };

  return (
    <React.Fragment>
      <form
        className="personal_details"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <input
          type="number"
          name="rowId"
          style={{ display: "none" }}
          disabled={disabled}
          ref={register}
        />
        <div className="profile_top">
          <h1>Reference Details</h1>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label
              // className={"required"}
              >
                {" "}
                Name
              </label>
              {IsComponentDisabled(
                <input
                  name="referenceName"
                  disabled={disabled}
                  type="text"
                  ref={register({
                    // required: "Name is required",
                    required: false,
                  })}
                  className={"form-control form-control-n"}
                  placeholder="Type here"
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="referenceName"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label
              // className={"required"}
              >
                {" "}
                Mobile
              </label>
              {IsComponentDisabled(
                <Controller
                  control={control}
                  name="referenceCountryCode"
                  render={({ onChange, onBlur, value, name }) => (
                    <PhoneNumberWithCode
                      // codeHeight={"50px"}
                      noPenIcon={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      disabled={disabled}
                      phoneNo={
                        <input
                          type="text"
                          name="referenceMobile"
                          disabled={disabled}
                          id="phoneNumber"
                          className="form-control phoneNumber"
                          placeholder="Type here"
                          ref={register({
                            // required: "Mobile number is required",
                            required: false,
                            maxLength: {
                              value:
                                watch("referenceCountryCode").length > 3
                                  ? 9
                                  : 10,
                              message: `It must be minimum ${
                                watch("referenceCountryCode").length > 3
                                  ? 9
                                  : 10
                              }  digits.`,
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Mobile number should be numbers only",
                            },
                            minLength: {
                              value:
                                watch("referenceCountryCode").length > 3
                                  ? 9
                                  : 10,
                              message: `It must be minimum ${
                                watch("referenceCountryCode").length > 3
                                  ? 9
                                  : 10
                              }  digits.`,
                            },
                          })}
                        />
                      }
                    />
                  )}
                />,
                disabled
              )}

              <ErrorMessage
                errors={errors}
                name="referenceMobile"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label className={"required"}> Email</label>
              {IsComponentDisabled(
                <input
                  type="text"
                  className={"form-control input-name"}
                  placeholder="Type here"
                  name="referenceEmail"
                  ref={register({
                    // required: "Email is required",
                    required: false,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please enter a valid email",
                    },
                  })}
                  disabled={disabled}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="referenceEmail"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <div className="clearfix" />
        <button
          type="submit"
          className="btn submit_btn"
          disabled={saveDisabled}
        >
          Save
        </button>
      </form>
    </React.Fragment>
  );
}

interface ICommunicationState {
  rowId: string;
  contactNumber: string;
  countryCode: string;
  email: string;
  telephone: string;
  permanantAddress: string;
  currentAddress: string;
  sameAsPermanantAddress: boolean;
}

function Communication(props: any) {
  const {
    myProfile,
    userId,
    candidateId,
    candidateState,
    candidateRelativesState,
    getCandidates,
    candidateReferences,
    getCandidateReferences,
    getCandidateRelatives,
    candidateRelativeSelectBoxData,
  } = props;

  // console.log(candidateRelativesState, candidateReferences, candidateRelativeSelectBoxData);
  const [emailData, setEmailData] = useState("");
  const [phoneData, setPhoneData] = useState("");
  const [phoneCountryData, setPhoneCountryData] = useState("");
  let relatives: ReactSelectOption[] = [];
  if (!_.isEmpty(candidateRelativeSelectBoxData)) {
    relatives = candidateRelativeSelectBoxData.relationships.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  const defaultValues = {
    rowId: "0",
    contactNumber: "",
    countryCode: "",
    email: "",
    telephone: "",
    permanantAddress: "",
    currentAddress: "",
    sameAsPermanantAddress: false,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm<ICommunicationState>({
    defaultValues,
  });

  useEffect(() => {
    reset({
      rowId:
        candidateState.data.length > 0 ? candidateState.data[0]["rowId"] : "0",
      contactNumber:
        candidateState.data.length > 0
          ? candidateState.data[0]["phoneNumber"]
          : "",
      countryCode:
        candidateState.data.length > 0
          ? candidateState.data[0]["countryCode"]
          : "",
      email:
        candidateState.data.length > 0 ? candidateState.data[0]["email"] : "",
      telephone:
        candidateState.data.length > 0
          ? candidateState.data[0]["telephoneNumber"]
          : "",
      permanantAddress:
        candidateState.data.length > 0
          ? candidateState.data[0]["permanantAddress1"]
          : "",
      currentAddress:
        candidateState.data.length > 0
          ? candidateState.data[0]["currentAddress1"]
          : "",
      sameAsPermanantAddress:
        candidateState.data.length > 0
          ? candidateState.data[0]["permanantAddress1"] ==
            candidateState.data[0]["currentAddress1"]
          : false,
    });
    if (candidateState.data.length > 0) {
      setEmailData(candidateState.data[0]["email"]);
      setPhoneData(candidateState.data[0]["phoneNumber"]);
      setPhoneCountryData(candidateState.data[0]["countryCode"]);
    }
  }, [
    reset,
    candidateState.data.length,
    candidateRelativesState.data.length,
    candidateReferences.data.length,
  ]);

  const [disabled, setDisabled] = useState<boolean>(true);

  const [disabledEmail, setDisabledEmail] = useState<boolean>(true);

  const [disabledContact, setDisabledContact] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const contactNumberRef = useRef<HTMLInputElement | null>(null);

  const emailRef = useRef<HTMLInputElement | null>(null);

  const updateNumber = (data: any) => {
    console.log(data);
    const _data1 = Object.assign({}, candidateState.data[0], {
      countryCode: data["newCountryCode"],
      phoneNumber: data["newContactNumber"],
    });
    globalSpinner.showSpinner();
    saveCandidate(_data1)
      .then((res: any) => {
        //console.log(res.data);
        if (res.data.isSuccess) {
          getCandidates({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success("Contact number updated.");
          setIsOpen(false);
          globalSpinner.hideSpinner();
        } else {
          globalSpinner.hideSpinner();
          toast.error(res.data.message);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong.");
      });
  };

  const updateEmail = (data: any) => {
    //console.log(data);

    const _data1 = Object.assign({}, candidateState.data[0], {
      phoneNumber: data["newEmail"],
    });
    globalSpinner.showSpinner();
    saveCandidate(_data1)
      .then((res: any) => {
        console.log(res.data);
        if (res.data.isSuccess) {
          getCandidates({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success("Email updated.");
          setIsOpen2(false);
        } else {
          globalSpinner.hideSpinner();
          toast.error(res.data.message);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong.");
      });
  };

  const onSubmit = (data: any) => {
    let _data1: objectIndexing = {};

    if (candidateState.data.length > 0) {
      _data1 = candidateState.data[0];
      _data1["rowId"] = Number(data["rowId"]);
      _data1["candidateId"] = Number(candidateId);
      _data1["phoneNumber"] = phoneData; //data["contactNumber"];
      _data1["email"] = emailData; // data["email"];
      _data1["telephoneNumber"] = data["telephone"];
      _data1["permanantAddress1"] = data["permanantAddress"];
      _data1["currentAddress1"] = data["sameAsPermanantAddress"]
        ? data["permanantAddress"]
        : data["currentAddress"];

      globalSpinner.showSpinner();

      saveCandidate(_data1)
        .then((res: any) => {
          console.log(res.data);
          globalSpinner.hideSpinner();
          if (res.data.isSuccess) {
            getCandidates({
              CandidateId: Number(candidateId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "string",
              SortOrder: "string",
              ShowInactive: false,
            });
            toast.success("Communication details are saved.");
          } else {
            globalSpinner.hideSpinner();
            toast.error(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          globalSpinner.hideSpinner();
          toast.error("Something went wrong.");
        });
    }
  };

  let saveDisabled = true;

  if (!disabled) {
    saveDisabled = false;
  } else if (!disabledEmail) {
    saveDisabled = false;
  } else if (!disabledContact) {
    saveDisabled = false;
  }

  if (watch("sameAsPermanantAddress")) {
    if (watch("currentAddress") != watch("permanantAddress")) {
      setValue("currentAddress", watch("permanantAddress"));
      clearErrors("currentAddress");
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-1">
        <span className="resp-arrow" />
        Communication Details
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-1"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Communication Details</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>
        <form
          className="personal_details"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <input
            type="number"
            name="rowId"
            style={{ display: "none" }}
            disabled={disabled}
            ref={register}
          />

          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <label className={"required"}> Contact Number</label>
                {IsComponentDisabled(
                  <Controller
                    control={control}
                    name="countryCode"
                    render={({ onChange, onBlur, value, name }) => (
                      <PhoneNumberWithCode
                        // codeHeight={"50px"}
                        noPenIcon={true}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        disabled={disabledContact}
                        phoneNo={
                          <input
                            type="text"
                            name="contactNumber"
                            disabled={disabledContact}
                            id="phoneNumber"
                            className="form-control phoneNumber"
                            placeholder="Type here"
                            ref={register({
                              required: "Mobile number is required",
                              maxLength: {
                                value: watch("countryCode").length > 3 ? 9 : 10,
                                message: `It must be ${
                                  watch("countryCode").length > 3 ? 9 : 10
                                } digits only.`,
                              },
                              pattern: {
                                value: /^[0-9]*$/,
                                message: "Mobile number should be numbers only",
                              },
                              minLength: {
                                value: watch("countryCode").length > 3 ? 9 : 10,
                                message: `It must be ${
                                  watch("countryCode").length > 3 ? 9 : 10
                                } digits only.`,
                              },
                            })}
                          />
                        }
                      />
                    )}
                  />,
                  disabled
                )}

                <div className="profile_edit1">
                  <a
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <i
                      className="fa fa-pencil _cursor-pointer"
                      aria-hidden="true"
                    />
                  </a>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="countryCode"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="contactNumber"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label className={"required"}> Email</label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    className={"form-control input-name"}
                    placeholder="Type here"
                    name="email"
                    ref={(e: HTMLInputElement) => {
                      register(e, {
                        required: "Email is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Should be a valid email",
                        },
                      });
                      emailRef.current = e;
                    }}
                    disabled={disabledEmail}
                  />,
                  disabled
                )}
                {/* removed editing email
                <div className="profile_edit1"><a onClick={() => { setIsOpen2(!isOpen2) }}><i className="fa fa-pencil _cursor-pointer" aria-hidden="true" /></a></div> */}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label> Telephone</label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="telephone"
                    ref={register({
                      required: false,
                      // required: "Telephone is required",
                      // maxLength: {
                      //   value: 15,
                      //   message: "Please enter a valid a Telephone",
                      // },
                      // pattern: {
                      //   value: /^[0-9]*$/,
                      //   message: "Telephone should be numbers only"
                      // }
                    })}
                    disabled={disabled}
                    className={"form-control form-control-n"}
                    placeholder="Type here"
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="telephone"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label className={"required"}>Permanent Address</label>
                {IsComponentDisabled(
                  <textarea
                    disabled={disabled}
                    name="permanantAddress"
                    className={"form-control form-contro11"}
                    placeholder="Type here"
                    ref={register({
                      required: "Permanant Address cannot be blank.",
                      // validate: (value) => {
                      //   //exclude  start and end white-space
                      //   value = value.replace(/(^\s*)|(\s*$)/gi, "");
                      //   //convert 2 or more spaces to 1
                      //   value = value.replace(/[ ]{2,}/gi, " ");
                      //   // exclude newline with a start spacing
                      //   value = value.replace(/\n /, "\n");

                      //   if (value.split(" ").length < 8) {
                      //     return "Minimum 8 words are required in permanant address.";
                      //   } else if (value.split(" ").length > 15) {
                      //     return "Maximum words: 15";
                      //   } else {
                      //     return undefined;
                      //   }
                      // },
                    })}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="permanantAddress"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label className={"required"}> Current Address</label>
                {IsComponentDisabled(
                  <textarea
                    disabled={disabled || watch("sameAsPermanantAddress")}
                    name="currentAddress"
                    className={"form-control form-contro11"}
                    placeholder="Type here"
                    ref={register({
                      required: "Current Address cannot be blank.",
                      // validate: (value) => {
                      //   //exclude  start and end white-space
                      //   value = value.replace(/(^\s*)|(\s*$)/gi, "");
                      //   //convert 2 or more spaces to 1
                      //   value = value.replace(/[ ]{2,}/gi, " ");
                      //   // exclude newline with a start spacing
                      //   value = value.replace(/\n /, "\n");

                      //   if (value.split(" ").length < 8) {
                      //     return "Minimum 8 words are required in current address.";
                      //   } else if (value.split(" ").length > 15) {
                      //     return "Maximum words: 15";
                      //   } else {
                      //     return undefined;
                      //   }
                      // },
                    })}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="currentAddress"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email"></label>
                {IsComponentDisabled(
                  <div style={{ paddingTop: "6px" }}>
                    <input
                      id="_sameAsPermanantAddress"
                      type="checkbox"
                      disabled={disabled}
                      name="sameAsPermanantAddress"
                      ref={register}
                      className={
                        disabled ? "_cursor-no-drop" : "_cursor-pointer"
                      }
                    />
                    <label htmlFor="_sameAsPermanantAddress">
                      <span
                        className={"_add-experience-checkbox _cursor-pointer"}
                      ></span>{" "}
                      &nbsp;
                    </label>
                    <span>Same as permanant address</span>
                  </div>,
                  disabled
                )}
              </div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="clearfix" />
          <button
            type="submit"
            className="btn submit_btn"
            disabled={saveDisabled}
          >
            Save
          </button>
        </form>

        <EmergencyContact
          disabled={disabled}
          saveDisabled={saveDisabled}
          candidateId={candidateId}
          relatives={relatives}
          candidateRelativesState={candidateRelativesState}
          getCandidateRelatives={getCandidateRelatives}
        />

        <Reference
          disabled={disabled}
          saveDisabled={saveDisabled}
          candidateId={candidateId}
          candidateReferences={candidateReferences}
          getCandidateReferences={getCandidateReferences}
        />
      </div>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <EditContact2
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          updateNumber={updateNumber}
          oldPhoneNumber={phoneData}
          oldCountryCode={phoneCountryData}
        />
      </Modal>

      <Modal
        show={isOpen2}
        onHide={() => {
          setIsOpen2(!isOpen2);
        }}
      >
        <EditEmail2
          setIsOpen2={setIsOpen2}
          isOpen2={isOpen2}
          updateEmail={updateEmail}
        />
      </Modal>
    </React.Fragment>
  );
}

export default Communication;
