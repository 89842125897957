import React, { useState } from "react";
import Experience from "./Experience";
import Expertise from "./Expertise";
import Level from "./Level";
import Template from "./Template";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  getResumeTemplates
} from "../../../../apis/resumebuilder";


interface IResumeSelectTemplateComponentProps {
  candidateId: any;
  ResumeTemplateSelectBoxData: any;
  //ResumeTemplates: any;
  // setResumeTemplates: any;
  fieldOfExpertise: any;
  setFieldOfExpertise: any;
  experiences: any;
  setExperiences: any;
  level: any;
  setLevel: any;
}



interface IResumeSelectTemplateComponentState {
  rowId: number;
  resumeTemplateId: number;
  title: string;
  resumeContent: string;
  resumeImage: string;
  description: string;
}


const defaultValues = {
  rowId: 0,
  resumeTemplateId: 0,
  title: '',
  resumeContent: '',
  resumeImage: require("./../../../../images/reume_img.png"),
  description: ''
};

const ResumeSelectTemplateComponent: React.FC<IResumeSelectTemplateComponentProps> = (props) => {
  const [resumeSelectTemplateComponentState, setResumeSelectTemplateComponentState] = React.useState<IResumeSelectTemplateComponentState>(
    defaultValues
  );

  //const [active, setActive] = useState("Box Info");
  const [active, setActive] = useState("Template Info");

  let headings = new Map([
    ["Box Info", 'Lets create your template'],
    ["Template Info", 'Select your resume template']
  ]);

  let history = useHistory()

  const handleSelectTemplate = () => {
    // getResumeTemplates({
    //   "CandidateId": 0,//props.candidateId,
    //   "FieldOfExpertiseId": [Number(props.fieldOfExpertise)],// [2],
    //   "ExperienceTypeId": [Number(props.experiences)],//[4],
    //   "DesignationId": [Number(props.level)],// [1],
    //   "PageIndex": 1,
    //   "PageSize": 10,
    //   "ShowInactive": false
    // }).then((res) => {
    //   props.setResumeTemplates(res.data);
    // }).catch((err) => {
    //   console.log(err);
    // });
    setActive("Template Info");
  };
  const handlePreviewResume = () => {
    history.push({
      pathname: '/candidate/my-resume/resume/preview_my',
      state: { templateInfo: resumeSelectTemplateComponentState, fieldOfExpertise: props.fieldOfExpertise, experiences: props.experiences, level: props.level }
    });
  };

  let content = null;
  if (active == "Box Info") {
    content = (
      <div className="row">
        <div className="col-sm-12">
          <Expertise
            ResumeTemplateSelectBoxData={props.ResumeTemplateSelectBoxData}
            setFieldOfExpertise={props.setFieldOfExpertise}>
          </Expertise>
          <Experience
            ResumeTemplateSelectBoxData={props.ResumeTemplateSelectBoxData}
            setExperiences={props.setExperiences}
          >
          </Experience>
          <Level
            ResumeTemplateSelectBoxData={props.ResumeTemplateSelectBoxData}
            setLevel={props.setLevel}
          ></Level>
          <button className="btn continue_but"
            disabled={props.level > 0 && props.experiences > 0 && props.fieldOfExpertise > 0 ? false : true}
            onClick={() => { handleSelectTemplate(); }}>Continue</button>
        </div>
      </div>

    );
  } else if (active == "Template Info") {
    content = (
      <div className="row">
        <Template
          // ResumeTemplates={props.ResumeTemplates}
          setResumeSelectTemplateComponentState={setResumeSelectTemplateComponentState}
          resumeSelectTemplateComponentState={resumeSelectTemplateComponentState}
          // expertise={props.fieldOfExpertise}
          // experience={props.experiences}
          // level={props.level}
          expertise={1}
          experience={1}
          level={1}
        // setResumeTemplates={props.setResumeTemplates}
        ></Template>
        <div className="col-sm-12">
          <button className="btn continue_but"
            disabled={resumeSelectTemplateComponentState.rowId == 0 ? true : false}
            onClick={() => { handlePreviewResume(); }}>Preview</button>
        </div>
      </div>

    );
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">{headings.get(active)}</h1>
          <div className="clearfix" />
          {content}
        </div>
        <div className="clearfix" />
      </div >
    </>
  );
};
export default ResumeSelectTemplateComponent;
