import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";

import { IVendorJobStatusManagementState, VendorJobStatusDispatcherContext, VendorJobStatusStateContext } from "../../context/vendor/VendorJobStatusContext";
import { VendorGetAllJobRequestModel } from "../../models/vendor/VendorJobApplied";
import { JobStatusDeleteResult, JobStatusGetAllRequestModel, JobStatusGetAllRespondModel } from "../../models/vendor/VendorJobStatus";
import message from "antd/lib/message";





let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IVendorJobStatusAction =
  | {
      type: "GET_ALL_JOB_STATUS_LIST";
      vendorGetAllJobStatus: JobStatusGetAllRespondModel;
    }|  {
      type: "DELETE_JOB_STATUS_LIST";
      vendorJobStatusDeleteResult: JobStatusDeleteResult;
    }
 
    

export const vendorJobStatusReducer: Reducer<
  IVendorJobStatusManagementState,
  IVendorJobStatusAction
> = (draft, action): IVendorJobStatusManagementState => {
  switch (action.type) {
    case "GET_ALL_JOB_STATUS_LIST":
      draft.vendorGetAllJobStatus = action.vendorGetAllJobStatus;
      return draft;
      case "DELETE_JOB_STATUS_LIST":
        draft.vendorJobStatusDeleteResult = action.vendorJobStatusDeleteResult;
        return draft;
      
    
   
  }
};

export const useVendorJobStatusDispatcher = (): React.Dispatch<IVendorJobStatusAction> => {
  const vendorJobStatusDispatcher = React.useContext(VendorJobStatusDispatcherContext);
  if (!vendorJobStatusDispatcher) {
    throw new Error(
      "You have to provide the VendorJobStatus dispatcher using theVendorJobStatusDispatcherContext.Provider in a parent component."
    );
  }
  return vendorJobStatusDispatcher;
};

export const useVendorJobStatusContext = (): IVendorJobStatusManagementState => {
  const vendorJobStatusContext = React.useContext(VendorJobStatusStateContext);
  if (!vendorJobStatusContext)
    throw new Error(
      "You have to provide the vendorJobStatus context using the VendorJobStatusStateContext.Provider in a parent component."
    );
  return vendorJobStatusContext;
};

// Get All applied job List



export const getVendorJobStatusList = async (
  dispatcher: React.Dispatch<IVendorJobStatusAction>,
  query: JobStatusGetAllRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllJobStatus;
    
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type:  "GET_ALL_JOB_STATUS_LIST",vendorGetAllJobStatus: res.data});
    });
  } catch (e) {}
};

export const DeleteVendorJobStatusList = async (
  dispatcher: React.Dispatch<IVendorJobStatusAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteVendorJobStatus;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type:  "DELETE_JOB_STATUS_LIST",vendorJobStatusDeleteResult: res.data});
      res.data.isSuccess
      ? message.success("Deleted sucessfully")
      : message.error(res.data.message);

    });
  } catch (e) {}
};

// bookMark And UnBookMark

// export const setVendorJobBookmark = async (
//   dispatcher: React.Dispatch<IVendorJobStatusAction>,
//   query: VendorJobStatusRequestModel,
//   token: string
// ) => {
//   try {
//     if (token?.startsWith(`"`)) {
//       token = token.substring(1);
//       token = token.slice(0, -1);
//     }

//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     };

//     const url = AppUrls.SaveAndUnSaveVendorBookMark;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({ type:"SAVE_BOOKMARK", bookmarkSaveResult: res.data  });
//     });
//   } catch (e) {}
// };

// // Save call

// export const setVendorJobStatusSave = async (
//   dispatcher: React.Dispatch<IVendorJobStatusAction>,
//   query: VendorJobStatusRequestModel,
//   token: string
// ) => {
//   if (token?.startsWith(`"`)) {
//     token = token.substring(1);
//     token = token.slice(0, -1);
//   }
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     };

//     const url = AppUrls.SaveVendorJobAlert;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({ type: "SET_JOB_APPLIED_SAVE", vendorJobStatusSaveRespond: res.data });
//     });
//   } catch (e) {}
// };

