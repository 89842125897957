import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { SocialAccountUrlViewModel, 
         SocialAccountUrlSaveRespondModel, 
         SocialAccountUrlResultModel,
         SocialAccountUrlSelectBoxDataViewModel,
         SocialAccountUrlDeleteResultModel} from "../../models/admin/SocialAccountUrl";
import { ISocialAccountUrlAction, SocialAccountUrlReducer } from "../../action/admin/SocialAccountUrlAction";



export const SocialAccountUrlDispatcherContext = React.createContext<React.Dispatch<ISocialAccountUrlAction> | null>(null);
export const SocialAccountUrlStateContext = React.createContext<ISocialAccountUrlManagementState | null>(null);

export interface ISocialAccountUrlManagementProps extends RouteComponentProps<any> { }

export interface ISocialAccountUrlManagementState {
    visible:boolean;
    value:number;
    SocialAccountUrl:SocialAccountUrlViewModel,
    SocialAccountUrlResultModel:SocialAccountUrlResultModel,
    SocialAccountUrlSaveRespond:SocialAccountUrlSaveRespondModel,
    SocialAccountUrlSelectBoxDataViewModel:SocialAccountUrlSelectBoxDataViewModel,
    SocialAccountUrlDeleteResultModel:SocialAccountUrlDeleteResultModel;
}

export const initialSocialAccountUrlManagementState = {
    visible:false,
    value:0,
    SocialAccountUrl:{} as SocialAccountUrlViewModel,
    SocialAccountUrlResultModel:{} as SocialAccountUrlResultModel,
    SocialAccountUrlSaveRespond:{} as SocialAccountUrlSaveRespondModel,
    SocialAccountUrlSelectBoxDataViewModel:{} as SocialAccountUrlSelectBoxDataViewModel,
    SocialAccountUrlDeleteResultModel:{} as SocialAccountUrlDeleteResultModel
} as ISocialAccountUrlManagementState;

export const SocialAccountUrlContextProvider: React.FC = ({ children }) => {
    const [SocialAccountUrlState, dispatcher] = useImmerReducer(SocialAccountUrlReducer, initialSocialAccountUrlManagementState);

    return (
        <SocialAccountUrlDispatcherContext.Provider value={dispatcher}>
            <SocialAccountUrlStateContext.Provider value={SocialAccountUrlState}>
                {children}
            </SocialAccountUrlStateContext.Provider>
        </SocialAccountUrlDispatcherContext.Provider>
    )
}