import { ErrorMessage } from "@hookform/error-message";
import { Popover } from "antd";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  useAdminMyJobContext,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import {
  getTitleWithCategoryId,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import {
  SaveCategory,
  SaveTitle,
  useCategoryContext,
  useCategoryDispatcher,
} from "../../../action/general/CategoryAction";
import {
  getVendorMyProfileSelectBoxList,
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../../action/MyProfileVendorAction";
import { categoryWithId } from "../../../models/candidate/JobCategory";
import {
  CategorySaveRequestModel,
  TitleSaveRequestModel,
} from "../../../models/general/Category";
import AuthService from "../../../services/AuthService";
import SelectOption from "../../candidate/my_profile/components/SelectOption";

interface ReactSelectOption {
  value: string;
  label: string;
}
const defaultValues = {
  jobTypeData: "",
  jobCategorys: "",
};

const JobCategoryOrTitle = () => {
  let jobCategoryData: ReactSelectOption[] = [];

  const {
    register: register,
    errors: errors,
    handleSubmit: handleSubmit,
    control: control,
    watch: watch,
    setValue: setValue,
  } = useForm({
    defaultValues,
  });

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  const watchAllFields = watch3();

  const authorizationToken = AuthService.accessToken;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const categoryDispatcher = useCategoryDispatcher();
  const categoryContext = useCategoryContext();
  const { CategorySaveRespondModel, TitleSaveRespondModel } = categoryContext;

  const jobAlertDispatcher = useJobAlertDispatcher();
  const jobAlertContext = useJobAlertContext();
  const { jobAlertTitleDropdownResult } = jobAlertContext;

  const myVendorProfileContext = useVendorMyProfileContext();

  const {
    basicInfo,
    loggedUserId,
    myProfile,
    myProfileProgressBar,
    profileImage,
  } = myVendorProfileContext;

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const {
    adminSaveJobRespond,
    jobListInAdmin,
    jobListInAdminPub,
    permissionResult,
    adminEditRespond,
    adminDeleteRespond,
    AdminPermissionResult,
    publishedJobRespond,
  } = adminMyJobContext;

  const myProfileVendorDispatcher = useVendorMyProfileDispatcher();
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  React.useEffect(() => {
    if (1) {
      (async () => {
        await getVendorMyProfileSelectBoxList(myProfileVendorDispatcher);
      })();
    }
  }, [CategorySaveRespondModel]);

  React.useEffect(() => {
    //console.log(1213, watchAllFields.jobCategorys);
    //console.log(1212, jobAlertTitleDropdownResult);

    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys, TitleSaveRespondModel]);

  const handleCategoryClick = (data: any) => {
    //console.log(45, data);
    if (authorizationToken != null) {
      (async () => {
        await SaveCategory(
          categoryDispatcher,
          {
            Description: "",
            IsActive: true,
            JobCategoryId: 0,
            RowId: 0,
            Title: data.category,
          } as CategorySaveRequestModel,
          authorizationToken
        );
      })();
      setIsOpen(false);
    }
  };
  const handleTitleCategoryClick = (data: any) => {
    //console.log(5959, data);
    if (authorizationToken != null) {
      (async () => {
        await SaveTitle(
          categoryDispatcher,
          {
            CategoryId: parseInt(data.jobCategorys),
            Description: "",
            IsActive: true,
            RowId: 0,
            Title: data.jobTitle,
          } as TitleSaveRequestModel,
          authorizationToken
        );
      })();
      setIsOpen2(false);
    }
  };

  const content = (
    <>
      <p>You Don't Have Permission</p>
    </>
  );

  const menuRef = useRef<any>();

  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setIsListOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Category / Job Title</h1>
          {AdminPermissionResult != undefined &&
          AdminPermissionResult.isAddNewCategoriesPermission ? (
            <a
              onClick={() => {
                setIsOpen(true);
              }}
              data-target="#add_jobcategory"
              data-toggle="modal"
              className="btn cutomise_but"
            >
              <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; Add
              Category
            </a>
          ) : (
            <Popover content={content} trigger="hover">
              {" "}
              <a
                data-target="#add_jobcategory"
                data-toggle="modal"
                className="btn cutomise_but"
              >
                <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; Add
                Category
              </a>
            </Popover>
          )}
          {AdminPermissionResult != undefined &&
          AdminPermissionResult.isAddNewCategoriesPermission ? (
            <a
              onClick={() => {
                setIsOpen2(true);
              }}
              data-target="#add_jobtitle"
              data-toggle="modal"
              className="btn cutomise_but"
            >
              <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; Add Job
              Title{" "}
            </a>
          ) : (
            <Popover content={content} trigger="hover">
              {" "}
              <a
                data-target="#add_jobtitle"
                data-toggle="modal"
                className="btn cutomise_but"
              >
                <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; Add Job
                Title{" "}
              </a>
            </Popover>
          )}
          <div className="action_btn1">
            <button
              type="button"
              className="actions_menu"
              onClick={() => {
                setIsListOpen(!isListOpen);
              }}
            >
              <i className="fa fa-align-left" aria-hidden="true" />
            </button>
            <div
              className="user_menu"
              tabIndex={-1}
              style={{ display: isListOpen ? "block" : "none" }}
            >
              <div className="action_sec">
                <ul ref={menuRef}>
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isMyTeamPermission ? (
                    <li>
                      <Link to="/admin/myteammember">My Team</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isStoriesofJIT ? (
                    <li>
                      <Link to="/admin/story">Stories of JIT</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isCompanyContactsandSocialPermission ? (
                    <li>
                      <Link to="/admin/socialaccounturl">
                        Company Contacts and Social Media
                      </Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isAchievementPermission ? (
                    <li>
                      <Link to="/admin/achievements">Achievements</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  (AdminPermissionResult.isAddJobCategoryPermission ||
                    AdminPermissionResult.isAddJobTitlePermission) ? (
                    <li>
                      <Link to="/admin/sitesettings/jobCategory">
                        Category / Job Title
                      </Link>{" "}
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>

          {/* BEGIN MODAL */}

          <Modal
            show={isOpen}
            onHide={() => {
              setIsOpen(!isOpen);
            }}
          >
            <form onSubmit={handleSubmit2(handleCategoryClick)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>Add Category</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="email">Category Name</label>
                  <input
                    name="category"
                    type="text"
                    className="form-control "
                    placeholder="Type here"
                    ref={register2({
                      required: "category is required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors2}
                    name="category"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                >
                  Cancel
                </button>
              </div>
              <div className="clearfix" />
            </form>
          </Modal>

          {/* END MODAL */}
          {/* BEGIN MODAL */}
          <Modal
            show={isOpen2}
            onHide={() => {
              setIsOpen2(!isOpen2);
            }}
          >
            <form onSubmit={handleSubmit(handleTitleCategoryClick)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>Add Category</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="email" className="required">
                    Job Category
                  </label>
                  <span className="select-wrapper-sec">
                    <Controller
                      control={control}
                      name="jobCategorys"
                      rules={{
                        required: "Job Category is required",
                      }}
                      // value=''
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          values={
                            myProfile.jobCategories != undefined
                              ? myProfile.jobCategories.map((e: any) => {
                                  return {
                                    value: e["value"],
                                    label: e["caption"],
                                  };
                                })
                              : jobCategoryData
                          }
                          disabled={false}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>
                  <ErrorMessage
                    errors={errors}
                    name="jobCategorys"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Job Title</label>
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Type here"
                    name="jobTitle"
                    ref={register({
                      required: "Job title is required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="jobTitle"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                  onClick={() => {
                    setIsOpen2(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal>

          {/* END MODAL */}
          <div className="clearfix" />
          <div className="section_box3">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="email" className="required">
                    Job Category
                  </label>
                  <span className="select-wrapper-sec">
                    <Controller
                      control={control3}
                      name="jobCategorys"
                      rules={{
                        required: "Job Category is required",
                      }}
                      // value=''
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          values={
                            myProfile.jobCategories != undefined
                              ? myProfile.jobCategories.map((e: any) => {
                                  return {
                                    value: e["value"],
                                    label: e["caption"],
                                  };
                                })
                              : jobCategoryData
                          }
                          disabled={false}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>
                  {/* <ErrorMessage
                      errors={errors2}
                      name="jobCategorys"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    /> */}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="email">Job Title</label>
                  <div className="job_title_sec">
                    {jobAlertTitleDropdownResult != undefined
                      ? jobAlertTitleDropdownResult.map((data, index) => {
                          return (
                            <div className="job_title_con">{data.caption}</div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCategoryOrTitle;
