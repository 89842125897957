import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  getCareerTipsApprovalList,
  setCareerTipsStatus,
  editCareerTips,
  useCareerTipsApprovalContext,
  useCareerTipsApprovalDispatcher,
  getCareerTipsApprovalOrRejectList,
} from "../../../action/admin/CareerTipsApprovalAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import {
  CareerTipsApprovalRequestModel,
  CareerTipsEditRequestModel,
  CareerTipsApproval,
  CareerTipsApprovalViewModel,
  CareerTipsApprovalSaveRequestModel,
  CareerTipsApprovalSaveRespondModel,
} from "../../../models/admin/CareerTipsApproval";

import AuthService from "../../../services/AuthService";
import CareerTipsForm from "./CareerTipsForm";
import { Divider, Popover } from "antd";
import Popconfirm from "antd/lib/popconfirm";
import { environment } from "../../../environments/environment";
import {
  useAdminMyJobDispatcher,
  useAdminMyJobContext,
} from "../../../action/admin/AdminMyJobAction";
import { Select } from "antd";
import { getApproveOrRejectRequestModel } from "../../../models/admin/VendorApproval";
import { ErrorMessage } from "@hookform/error-message";
import SelectOption from "../../candidate/my_profile/components/SelectOption";

const { Option } = Select;

interface ICareerTipsApprovalComponentProps { }

interface ICareerTipsApprovalComponentState { }

const defaultValues = {};

const CareerTipsApprovalComponent: React.FC<ICareerTipsApprovalComponentProps> = (
  props
) => {
  const [
    CareerTipsApprovalComponentState,
    setCareerTipsApprovalComponentState,
  ] = React.useState<ICareerTipsApprovalComponentState>(defaultValues);

  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;

  //console.log('career tips approval permissions - ', AdminPermissionResult);
  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const { myProfile, basicInfo, loggedAdminId } = myProfileAdminContext;

  const adminId = loggedAdminId;

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  //console.log("userId", userId, "adminId", adminId);

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  const careerTipsApprovalDispatcher = useCareerTipsApprovalDispatcher();
  const careerTipsApprovalContext = useCareerTipsApprovalContext();
  const {
    careerTipsApproval,
    careerTipsApprovalSaveRespond,
    approveOrRejectResult,
  } = careerTipsApprovalContext;
  console.log("----------careerTipsApproval-----------", approveOrRejectResult);

  let history = useHistory();

  const [
    isCareerAppliedOpenOpen,
    setIsCareerAppliedOpenOpen,
  ] = useState<boolean>(false);
  const [careerTipsId, setCareerTipsId] = useState<number>(0);
  const [statusId, setStatusId] = useState<number>(1);
  const [categoryId, setCategoryId] = useState<number>(1);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [rejectedComments, setRejectedComment] = useState<string>("");
  const [isRejectedComments, setIsRejectedComment] = useState<boolean>(false);
  const [active, setActive] = useState(4);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ICareerTipsApprovalComponentState>({
    defaultValues,
  });

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      //console.log("career tips call getCareerTipsApprovalList", adminId);
      (async () => {
        await getCareerTipsApprovalList(
          careerTipsApprovalDispatcher,
          {
            userId: 0,
            page: 1,
            pageSize: 60,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as CareerTipsApprovalRequestModel,
          authorizationToken
        );
      })();
    }
  }, [userId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      //console.log("career tips call getCareerTipsApprovalList", adminId);
      (async () => {
        await getCareerTipsApprovalOrRejectList(
          careerTipsApprovalDispatcher,
          {
            AdminId: loggedAdminId,
            CategoryId: categoryId,
            StatusId: active,
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            ShowInactive: false,
            SortOrder: "",
          } as getApproveOrRejectRequestModel,
          authorizationToken
        );
      })();
      setIsRejectedComment(false);
    }
  }, [loggedAdminId, categoryId, active, careerTipsApprovalSaveRespond]);

  const handleApprovalStatus = (
    careerTipsStatusId: number,
    rowId: number,
    careerTipsId: number
  ) => {
    if (authorizationToken != null)
      setCareerTipsStatus(
        careerTipsApprovalDispatcher,
        {
          rowId: rowId,
          approvalCareerTipsId: 0,
          careerTipsId: careerTipsId,
          adminId: adminId,
          careerTipsStatusId: careerTipsStatusId,
          isActive: true,
          rejectedComment: "",
        } as CareerTipsApprovalSaveRequestModel,
        authorizationToken
      );
  };

  const handleDelete = (rowId: any, careerTipsId: number) => {
    if (authorizationToken != null)
      setCareerTipsStatus(
        careerTipsApprovalDispatcher,
        {
          rowId: rowId,
          approvalCareerTipsId: 0,
          careerTipsId: careerTipsId,
          adminId: adminId,
          careerTipsStatusId: statusId,
          isActive: false,
          rejectedComment: "",
        } as CareerTipsApprovalSaveRequestModel,
        authorizationToken
      );
  };

  const handleJobApplayClick = (id: any) => {
    setCareerTipsApprovalComponentState({
      ...CareerTipsApprovalComponentState,
      careerTipsId: id,
    });
    handleCareerAppliedOpen();
  };

  const handleCareerAppliedOpen = () => {
    setIsCareerAppliedOpenOpen(!isCareerAppliedOpenOpen);
  };

  function onChange(value: any) {

    console.log(`selected ${value}`);

    setCategoryId(parseInt(value));
  }
  // console.log(`selected ${value}`);
  // setCategoryId(parseInt(value));


  const handleRejectCall = (rowId: any, statusId: any) => {
    setStatusId(statusId);
    setCareerTipsId(rowId);
    setIsRejectedComment(true);
  };

  const handleSubmitReject = (data: any) => {
    if (authorizationToken != null)
      setCareerTipsStatus(
        careerTipsApprovalDispatcher,
        {
          rowId: careerTipsId,
          approvalCareerTipsId: 0,
          careerTipsId: 0,
          adminId: adminId,
          careerTipsStatusId: statusId,
          isActive: true,
          rejectedComment: data.comments,
        } as CareerTipsApprovalSaveRequestModel,
        authorizationToken
      );
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Career Tips</h1>
          <div className="action_btn1">
            <button
              type="button"
              className="actions_menu"
              onClick={() => {
                setIsListOpen(!isListOpen);
              }}
            >
              <i className="fa fa-align-left" aria-hidden="true" />
            </button>
            <div
              className="user_menu"
              tabIndex={-1}
              style={{ display: isListOpen ? "block" : "none" }}
            >
              <div className="action_sec">
                <ul>
                  {AdminPermissionResult != undefined &&
                    AdminPermissionResult.isVendorsRegistrationPermission ? (
                    <li>
                      <Link to="/admin/vendorapproval">
                        Venders Registration
                      </Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                    AdminPermissionResult.isClientsjobpostPermission ? (
                    <li>
                      <Link to="/admin/clientjobapproval">
                        Client Job Posts
                      </Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                    AdminPermissionResult.isCareerTipsPermission ? (
                    <li>
                      <Link to="/admin/careertipsapproval">Career Tips</Link>{" "}
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="section_box3">

            {/* */}

            {/*  */}

            <Select
              showSearch
              style={{ width: 200, padding: 4, float: "right" }}
              placeholder="Select a Category"
              optionFilterProp="children"
              onChange={onChange}

              defaultValue={"General"}
            >

              {approveOrRejectResult != undefined &&
                approveOrRejectResult.careerTipsCategoryCount != undefined

                ? approveOrRejectResult.careerTipsCategoryCount.map(
                  (data: any, index: any) => {
                    return <Option value={data.careerTipsCategoryId}>


                      {data.title} - {" "}
                      {data.careerTipsCount != undefined
                        ? data.careerTipsCount : 0}


                    </Option>;
                  }
                )
                : null
              }


              {/* <Option value="1">
                General -{" "}
                {approveOrRejectResult != undefined &&
                approveOrRejectResult.careerTipsCategoryCount != undefined
                  ? approveOrRejectResult.careerTipsCategoryCount.generalCount
                  : 0}
              </Option> */}
              {/* <Option value="1">
                {approveOrRejectResult.careerTipsCategoryCount[1].title} -{" "}
                {approveOrRejectResult != undefined &&
                  approveOrRejectResult.careerTipsCategoryCount != undefined
                  ? approveOrRejectResult.careerTipsCategoryCount[1].careerTipsCount
                  : 0}
              </Option>
              <Option value="2">
                {approveOrRejectResult.careerTipsCategoryCount[2].title} -{" "}
                {approveOrRejectResult != undefined &&
                  approveOrRejectResult.careerTipsCategoryCount != undefined
                  ? approveOrRejectResult.careerTipsCategoryCount[2].careerTipsCount
                  : 0}
              </Option> */}
              {/*<Option value="3">
                Telephonic -{" "}
                {approveOrRejectResult != undefined &&
                approveOrRejectResult.careerTipsCategoryCount != undefined
                  ? approveOrRejectResult.careerTipsCategoryCount
                      .telephonicCount
                  : 0}
              </Option>
              <Option value="4">
                Communication -{" "}
                {approveOrRejectResult != undefined &&
                approveOrRejectResult.careerTipsCategoryCount != undefined
                  ? approveOrRejectResult.careerTipsCategoryCount
                      .communicationCount
                  : 0}
              </Option>
              <Option value="5">
                User Featured Career Tips -{" "}
                {approveOrRejectResult != undefined &&
                approveOrRejectResult.careerTipsCategoryCount != undefined
                  ? approveOrRejectResult.careerTipsCategoryCount
                      .userFeaturedCount
                  : 0}
              </Option> */}
            </Select>
            {/*  */}
            <div className="row">
              <div className="col-sm-12 col-lg-12 ">
                <div className="ProgCheck">
                  <ol className="breadcrumb">
                    <li
                      style={{ cursor: "pointer" }}
                      className={active == 4 ? "active10" : ""}
                      onClick={() => {
                        setActive(4);
                      }}
                    >
                      {/* <span className="sr-only">Current step:</span> */}
                      New List{" "}
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className={active == 1 ? "active10" : ""}
                      onClick={() => {
                        setActive(1);
                      }}
                    >
                      {" "}
                      Approved{" "}
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className={active == 3 ? "active10" : ""}
                      onClick={() => {
                        setActive(3);
                      }}
                    >
                      {" "}
                      Rejected{" "}
                    </li>
                  </ol>
                </div>

                {active == 4 && (
                  <>
                    {" "}
                    <div className="row">
                      <div
                        className="my_job_descrip"
                        style={{ overflow: "hidden", outline: "none" }}
                        tabIndex={0}
                      >
                        <div className="">
                          <div className="career_tips_ach">
                            <Scrollbars
                              style={{ height: 800 }}
                              autoHide
                              renderThumbVertical={({ style, ...props }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...style,
                                    position: "relative",
                                    display: "block",
                                    width: "5px",
                                    cursor: "pointer",
                                    borderRadius: "inherit",
                                    backgroundColor: "rgb(73, 69, 69)",
                                    height: "115px",
                                  }}
                                />
                              )}
                            >
                              {approveOrRejectResult.data != undefined
                                ? approveOrRejectResult.data
                                  .filter(
                                    (data) => data.careerTipsStatusId == 4
                                  )
                                  .map((data, i) => {
                                    return (
                                      <div className="col-sm-4" key={`${i}`}>
                                        <div className="achievements_sec">
                                          <div className="achieve_img">
                                            <img
                                              width="325px!important"
                                              height="184px!important"
                                              src={
                                                environment.baseUrl +
                                                `/Upload/CareerTipsDocument/${data.careerTipsDocument}`
                                              }
                                            // className="img-responsive"
                                            />
                                          </div>
                                          <h2>{data.name}</h2>

                                          <p>{data.description}</p>
                                          <div className="caretips_apro">
                                            <a
                                              onClick={() => {
                                                setCareerTipsId(1);
                                                handleCareerAppliedOpen();
                                              }}
                                              data-target="#post_career"
                                              data-toggle="modal"
                                            >
                                              Edit
                                            </a>
                                          </div>
                                          <div className="caretips_apro">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to approve
                                                    this career tips?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleApprovalStatus(
                                                  1,
                                                  data.rowId,
                                                  data.careerTipsId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Approve
                                              </a>
                                            </Popconfirm>

                                            {/* <a  onClick={() => {
                                          handleApprovalStatus(1,career.rowId);
                                        }}
                                  > Approve 
                                  </a> */}
                                          </div>
                                          <div className="caretips_apro">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to reject
                                                    this career tips?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleRejectCall(
                                                  data.rowId,
                                                  3
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Reject
                                              </a>
                                            </Popconfirm>
                                          </div>
                                          <div className="caretips_apro">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to delete
                                                    this career tips?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleDelete(
                                                  data.rowId,
                                                  data.careerTipsId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Delete
                                              </a>
                                            </Popconfirm>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {active == 1 && (
                  <>
                    <div className="row">
                      <div
                        className="my_job_descrip"
                        style={{ overflow: "hidden", outline: "none" }}
                        tabIndex={0}
                      >
                        <div className="">
                          <div className="career_tips_ach">
                            <Scrollbars
                              style={{ height: 800 }}
                              autoHide
                              renderThumbVertical={({ style, ...props }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...style,
                                    position: "relative",
                                    display: "block",
                                    width: "5px",
                                    cursor: "pointer",
                                    borderRadius: "inherit",
                                    backgroundColor: "rgb(73, 69, 69)",
                                    height: "115px",
                                  }}
                                />
                              )}
                            >
                              {approveOrRejectResult.data != undefined
                                ? approveOrRejectResult.data
                                  .filter(
                                    (data) => data.careerTipsStatusId == 1
                                  )
                                  .map((data, i) => {
                                    return (
                                      <div className="col-sm-4" key={`${i}`}>
                                        <div className="achievements_sec">
                                          <div className="achieve_img">
                                            <img
                                              width="325px!important"
                                              height="184px!important"
                                              src={
                                                environment.baseUrl +
                                                `/Upload/CareerTipsDocument/${data.careerTipsDocument}`
                                              }
                                            // className="img-responsive"
                                            />
                                          </div>
                                          <h2>{data.name}</h2>

                                          <p>{data.description}</p>
                                          <div className="caretips_apro">
                                            <a
                                              onClick={() => {
                                                setCareerTipsId(1);
                                                handleCareerAppliedOpen();
                                              }}
                                              data-target="#post_career"
                                              data-toggle="modal"
                                            >
                                              Edit
                                            </a>
                                          </div>

                                          <div className="caretips_apro">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to process
                                                    this career tips?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleApprovalStatus(
                                                  4,
                                                  data.rowId,
                                                  data.careerTipsId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Move to new list
                                              </a>
                                            </Popconfirm>
                                          </div>

                                          <div className="caretips_apro ">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to reject
                                                    this career tips?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleRejectCall(
                                                  data.rowId,
                                                  3
                                                )
                                              }
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Reject
                                              </a>
                                            </Popconfirm>
                                          </div>
                                          <div className="caretips_apro ">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to delete
                                                    this career tips?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleDelete(
                                                  data.rowId,
                                                  data.careerTipsId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Delete
                                              </a>
                                            </Popconfirm>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {active == 3 && (
                  <>
                    <div className="row">
                      <div
                        className="my_job_descrip"
                        style={{ overflow: "hidden", outline: "none" }}
                        tabIndex={0}
                      >
                        <div className="">
                          <div className="career_tips_ach">
                            <Scrollbars
                              style={{ height: 800 }}
                              autoHide
                              renderThumbVertical={({ style, ...props }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...style,
                                    position: "relative",
                                    display: "block",
                                    width: "5px",
                                    cursor: "pointer",
                                    borderRadius: "inherit",
                                    backgroundColor: "rgb(73, 69, 69)",
                                    height: "115px",
                                  }}
                                />
                              )}
                            >
                              {approveOrRejectResult.data != undefined
                                ? approveOrRejectResult.data
                                  .filter(
                                    (data) => data.careerTipsStatusId == 3
                                  )
                                  .map((data, i) => {
                                    return (
                                      <div className="col-sm-4" key={`${i}`}>
                                        <div className="achievements_sec">
                                          <div className="achieve_img">
                                            <img
                                              width="325px!important"
                                              height="184px!important"
                                              src={
                                                environment.baseUrl +
                                                `/Upload/CareerTipsDocument/${data.careerTipsDocument}`
                                              }
                                            />
                                          </div>
                                          <h2>{data.name}</h2>

                                          <p>{data.description}</p>
                                          <Divider>Rejected Comment</Divider>
                                          <p>
                                            {data.careerTipsRejectedComment}
                                          </p>
                                          <div className="caretips_apro ">
                                            <a
                                              onClick={() => {
                                                setCareerTipsId(data.rowId);
                                                handleCareerAppliedOpen();
                                              }}
                                              data-target="#post_career"
                                              data-toggle="modal"
                                            >
                                              Edit
                                            </a>
                                          </div>
                                          <div className="caretips_apro ">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to approve
                                                    this career tips?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleApprovalStatus(
                                                  1,
                                                  data.rowId,
                                                  data.careerTipsId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Approve
                                              </a>
                                            </Popconfirm>
                                          </div>
                                          <div className="caretips_apro">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to process
                                                    this career tips?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleApprovalStatus(
                                                  4,
                                                  data.rowId,
                                                  data.careerTipsId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Move to new list
                                              </a>
                                            </Popconfirm>
                                          </div>
                                          <div className="caretips_apro ">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to delete
                                                    this career tips?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleDelete(
                                                  data.rowId,
                                                  data.careerTipsId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Delete
                                              </a>
                                            </Popconfirm>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/*  */}
          </div>
          <div className="clearfix" />
          <Modal
            show={isRejectedComments}
            onHide={() => {
              setIsRejectedComment(false);
            }}
          >
            <form onSubmit={handleSubmit3(handleSubmitReject)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title> Edit a career tip</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label className={"required"} htmlFor="email">
                    Comments for Reject
                  </label>
                  <input
                    type="text"
                    name="comments"
                    className="form-control"
                    placeholder="Enter Your Name"
                    ref={register3({
                      required: "Comments is required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors3}
                    name="comments"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                  onClick={() => {
                    setIsRejectedComment(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal>

          <CareerTipsForm
            isCareerTipsOpen={isCareerAppliedOpenOpen}
            handleCareerTipsOpen={handleCareerAppliedOpen}
            currentCareerTipsId={careerTipsId}
            loggedAdminId={loggedAdminId}
            authorizationToken={authorizationToken}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default CareerTipsApprovalComponent;
