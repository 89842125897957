export class User {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(
    id?: string,
    userName?: string,
    fullName?: string,
    email?: string,
    jobTitle?: string,
    phone?: string,
    roles?: string[]
  ) {
    this.id = id || "";
    this.userName = userName || "";
    this.fullName = fullName || "";
    this.email = email || "";
    this.jobTitle = jobTitle || "";
    this.phone = phone || "";
    this.roles = roles || [];
  }

  get friendlyName(): string {
    let name = this.fullName || this.userName;

    if (this.jobTitle) {
      name = this.jobTitle + " " + name;
    }

    return name;
  }

  public id: string;
  public userName: string;
  public fullName: string;
  public email: string;
  public jobTitle: string;
  public phone: string;
  public isEnabled: boolean = false;
  public isLockedOut: boolean = false;
  public roles: string[];
}
