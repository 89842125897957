import React, { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import { jobAlertTitleDropdownResultModel } from "../../../../models/candidate/JobAlert";
import AuthService from "../../../../services/AuthService";
import {
  AdminJobSave,
  getAdminPermissionList,
  useAdminMyJobContext,
  useAdminMyJobDispatcher,
} from "../../../../action/admin/AdminMyJobAction";
import {
  getVendorMyProfileSelectBoxList,
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../../../action/MyProfileVendorAction";
import {
  getTitleWithCategoryId,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../../action/candidate/JobAlertAction";
import { useAdminMyProfileContext } from "../../../../action/MyProfileAdminAction";
import { useExperienceContext } from "../../../../action/general/ExperienceAction";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import { categoryWithId } from "../../../../models/candidate/JobCategory";
import { Modal } from "react-bootstrap";
import SelectOption from "../../../candidate/my_profile/components/SelectOption";
import { ErrorMessage } from "@hookform/error-message";
import FileUploads from "../../../candidate/my_profile/components/FileUploads";
import { permisionRequestModel } from "../../../../models/admin/AdminMyJob";

const { Option } = Select;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "green" }} spin />
);

interface ReactSelectOption {
  value: string;
  label: string;
}

type objectIndexing = {
  [key: string]: any;
};

interface IAdminEditJobStateProps {}
interface IAdminEditJobStateState {
  tempData: jobAlertTitleDropdownResultModel[];
}

const defaultValues = {
  jobTypeData: "",
  countryCode: "",
  mobileNumber: "",
  firstName: "",
  jobCategorys: "",
  tempData: [],
  jobTitle: "",
  jobStatus: "",
  assignTo: "",
};

const AdminEditJob = (props: any) => {
  const authorizationToken = AuthService.accessToken;
  let jobCategoryData: ReactSelectOption[] = [];
  let jobTypeDatas: ReactSelectOption[] = [];

  const [
    adminEditJobState,
    setAdminEditJobState,
  ] = React.useState<IAdminEditJobStateState>(defaultValues);

  const { tempData } = adminEditJobState;

  const jobStatusSelect = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" },
  ];

  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filename, setFileName] = useState("");

  const [obj, setObj] = useState<any>({ file: null });
  const [filename1, setFileName1] = useState("");
  const [obj1, setObj1] = useState<any>({ file: null });
  const [mirrorData, setMirrorData] = useState<number>(0);
  const [jobStatusData, setjobStatusData] = useState<number>(1);
  const [currentJobId, setCurrentJobId] = useState<number>(0);
  const [isPublish, setPublish] = useState<boolean>(false);
  const [isLoder, setIsLoder] = useState<boolean>(false);
  const [isLoderDelete, setIsLoderDelete] = useState<boolean>(false);
  const [isLoderPublish, setIsLoderPublish] = useState<boolean>(false);
  const [asignToAdminId, setAsignToAdminId] = useState<number>(0);
  const [minSalaryState, setMinSalaryState] = useState(false);
  const [checkedList, setCheckedList] = React.useState<number[]>([]);

  const [checkAll, setCheckAll] = React.useState(false);

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const {
    adminSaveJobRespond,
    jobListInAdmin,
    jobListInAdminPub,
    permissionResult,
    adminEditRespond,
    adminDeleteRespond,
    AdminPermissionResult,
    publishedJobRespond,
    AssignToRespondModelData,
  } = adminMyJobContext;

  const myProfileVendorDispatcher = useVendorMyProfileDispatcher();

  const jobAlertDispatcher = useJobAlertDispatcher();
  const myVendorProfileDispatcher = useVendorMyProfileDispatcher();
  const myVendorProfileContext = useVendorMyProfileContext();
  const {
    basicInfo,
    // loggedUserId,
    myProfile,
    myProfileProgressBar,
    profileImage,
  } = myVendorProfileContext;

  const myProfileAdminContext = useAdminMyProfileContext();
  const {
    //myProfile,

    loggedAdminId,
  } = myProfileAdminContext;

  const jobAlertContext = useJobAlertContext();
  const {
    jobAlert,
    jobAlertGetAll,
    jobAlertSaveRespond,
    jobAlertTitleDropdownResult,
  } = jobAlertContext;

  const experienceContext = useExperienceContext();
  const { experience } = experienceContext;

  //console.log(898111, loggedAdminId);

  let experienceData: ReactSelectOption[] = [];
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });
  const { isOpenData, adminEditRespondState } = props;

  const watchAllFields = watch2();
  //console.log(34, AdminPermissionResult);

  if (experience.data != undefined) {
    experienceData = experience.data.map((e: any) => {
      return {
        value: e["rowId"],
        label:
          e["title"] !== "0"
            ? e["title"] + " " + "years"
            : e["title"] + " " + "year",
      };
    });
  }

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getVendorMyProfileSelectBoxList(myProfileVendorDispatcher);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getAdminPermissionList(
          adminMyJobDispatcher,
          {
            PermissionId: 12,
          } as permisionRequestModel,
          authorizationToken
        );
      })();
    }
  }, [authorizationToken]);

  React.useEffect(() => {
    setAdminEditJobState((adminEditJobState) => {
      return {
        ...adminEditJobState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);

  React.useEffect(() => {
    if (adminSaveJobRespond.isSuccess || adminDeleteRespond.isSuccess) {
      setIsLoder(false);
      setIsOpen(false);
      setFileName("");
      setFileName1("");
      setIsLoderDelete(false);
      setIsLoderPublish(false);
    }
  }, [adminSaveJobRespond, adminDeleteRespond]);

  useEffect(() => {
    if (
      props.adminEditRespondState.data != undefined &&
      props.adminEditRespondState.data.length > 0
    ) {
      console.log(125, props.adminEditRespondState);
      setValue2(
        "jobCategorys",
        props.adminEditRespondState.data[0].categoryId.toString()
      );
      setValue2("jobTitle", props.adminEditRespondState.data[0].title);
      setValue2(
        "jobDescription",
        props.adminEditRespondState.data[0].description
      );
      setValue2(
        "experience",
        props.adminEditRespondState.data[0].experienceId.toString()
      );
      setValue2(
        "vacancies",
        props.adminEditRespondState.data[0].numberOfVacancies
      );
      setValue2(
        "jobTypeData",
        props.adminEditRespondState.data[0].jobTypeId.toString()
      );
      setValue2("location", props.adminEditRespondState.data[0].location);
      setValue2(
        "minAnnualSalary",
        props.adminEditRespondState.data[0].minAnnualSalary
      );
      setValue2(
        "maxAnnualSalary",
        props.adminEditRespondState.data[0].maxAnnualSalary
      );
      // setValue2("MinAnnualSalary", props.adminEditRespondState.data[0].);
      //  setValue2("WillingnessToTravelFlag",props.adminEditRespondState.data[0]. );
      // setValue2("AutoScreeningFilterFlag", props.adminEditRespondState.data[0].);
      // setValue2("AutoSkillAssessmentFlag", props.adminEditRespondState.data[0].);
      setValue2("jobOverview", props.adminEditRespondState.data[0].jobOverview);
      setValue2(
        "minimumQualification",
        props.adminEditRespondState.data[0].minQualification
      );
      setValue2(
        "skillsRequired",
        props.adminEditRespondState.data[0].skillRequired
      );
      setValue2(
        "documentsRequired",
        props.adminEditRespondState.data[0].documentsRequired
      );
      // setValue2("AutoSkillAssessmentFlag",props.adminEditRespondState.data[0]. );
      setValue2("others", props.adminEditRespondState.data[0].others);
      setValue2(
        "assignTo",
        props.adminEditRespondState.data[0].assignedToAdminId.toString()
      );
      setValue2(
        "jobStatus",
        props.adminEditRespondState.data[0].jobStatus ? "1" : "2"
      );

      setPublish(props.adminEditRespondState.data[0].ispublishedJobs);
      setCurrentJobId(
        mirrorData == 1 ? 0 : props.adminEditRespondState.data[0].jobId
      );
      setFileName(props.adminEditRespondState.data[0].jobIcon);
      setFileName1(props.adminEditRespondState.data[0].jobBackground);
    }
  }, [props.adminEditRespondState.data]);

  React.useEffect(() => {
    //console.log(1213, watchAllFields.jobCategorys);
    //console.log(1212, authorizationToken);

    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  const handleAdminClick = (data: any) => {
    //console.log(3329, data);
    const formData = new FormData();

    formData.append("AdminId", JSON.stringify(loggedAdminId));
    formData.append("Rowid", JSON.stringify(currentJobId));
    formData.append("CategoryId", JSON.stringify(parseInt(data.jobCategorys)));
    formData.append("Title", String(data.jobTitle));
    formData.append("Description", String(data.jobDescription));
    formData.append(
      "ExperienceId",
      JSON.stringify(parseInt(data.experience == null ? 0 : data.experience))
    );
    formData.append(
      "NumberOfVacancies",
      JSON.stringify(parseInt(data.vacancies == "" ? 0 : data.vacancies))
    );
    formData.append("JobTypeId", JSON.stringify(parseInt(data.jobTypeData)));
    formData.append("LocationId", String(data.location));
    formData.append(
      "MinAnnualSalary",
      JSON.stringify(
        parseInt(data.minAnnualSalary == "" ? 0 : data.minAnnualSalary)
      )
    );
    formData.append(
      "MaxAnnualSalary",
      JSON.stringify(
        parseInt(data.maxAnnualSalary == "" ? 0 : data.maxAnnualSalary)
      )
    );
    formData.append("WillingnessToTravelFlag", JSON.stringify(false));
    formData.append("AutoScreeningFilterFlag", JSON.stringify(false));
    formData.append("AutoSkillAssessmentFlag", JSON.stringify(false));
    formData.append("JobOverview", String(data.jobOverview));
    formData.append("MinQualification", String(data.minimumQualification));
    formData.append("SkillRequired", String(data.skillsRequired));
    formData.append("DocumentsRequired", String(data.documentsRequired));
    formData.append("Others", String(data.others));
    formData.append(
      "AssignedToAdminId",
      data.assignTo === ""
        ? JSON.stringify(loggedAdminId)
        : JSON.stringify(parseInt(data.assignTo))
    );
    formData.append(
      "JobStatus",
      JSON.stringify(data.jobStatus === "1" ? true : false)
    );
    formData.append("JobIcon", String(filename));
    formData.append("JobBackground", String(filename1));
    formData.append("IsAprrovedJobs", JSON.stringify(false));
    formData.append("IsProcessedJobs", JSON.stringify(false));
    formData.append("IspublishedJobs", JSON.stringify(isPublish));
    formData.append("JobIconDocument", obj.file);
    formData.append("JobBackgroundDocument", obj1.file);
    let newDate = new Date();
    formData.append("JobAssignDate", "12/11/2020");
    setIsLoder(true);
    if (authorizationToken != null) {
      (async () => {
        await AdminJobSave(
          adminMyJobDispatcher,

          formData,
          authorizationToken
        );
      })();
    }
    setPublish(false);
    // );
  };

  const handleJobIconDelete = () => {
    setFileName("");
    setObj({ file: null });
  };

  const handleJobBackGroundDelete = () => {
    setFileName1("");
    setObj1({ file: null });
  };

  const handleDrop = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "application/pdf"].includes(files[0].type) &&
        files[0].size < 1000000 * 5
      ) {
        //console.log(files[0]);
        if (files.length > 0) {
          setObj({
            ...obj,
            ["file"]: files[0],
          });
          setFileName(files[0].name);
        }
      }
    }
  };

  const handleDropOne = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "application/pdf"].includes(files[0].type) &&
        files[0].size < 1000000 * 5
      ) {
        //console.log(files[0]);
        if (files.length > 0) {
          setObj1({
            ...obj1,
            ["file"]: files[0],
          });
          setFileName1(files[0].name);
        }
      }
    }
  };

  return (
    <>
      {/* BEGIN MODAL */}
      <Modal
        show={props.isOpenData}
        onHide={() => {
          props.handleCloseModel();
        }}
        backdrop="static"
      >
        <form onSubmit={handleSubmit2(handleAdminClick)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>
              {/* {currentAdminId == 0
                ? " New Admin Profile"
                : "Update Admin Profile"} */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="email" className="required">
                    Job Category
                  </label>
                  <span className="select-wrapper-sec">
                    <Controller
                      control={control2}
                      name="jobCategorys"
                      rules={{
                        required: "Job Category is required",
                      }}
                      // value=''
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          values={
                            myProfile.jobCategories != undefined
                              ? myProfile.jobCategories.map((e: any) => {
                                  return {
                                    value: e["value"],
                                    label: e["caption"],
                                  };
                                })
                              : jobCategoryData
                          }
                          disabled={false}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>
                  <ErrorMessage
                    errors={errors2}
                    name="jobCategorys"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="email" className="required">
                    Job Type
                  </label>
                  <span className="select-wrapper-sec">
                    <Controller
                      className="custom-select-sec"
                      control={control2}
                      name="jobTypeData"
                      rules={{
                        required: "Job Type is required",
                      }}
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          values={
                            myProfile.jobTypes != undefined
                              ? myProfile.jobTypes.map((e: any) => {
                                  return {
                                    value: e["value"],
                                    label: e["caption"],
                                  };
                                })
                              : jobTypeDatas
                          }
                          disabled={false}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>
                  <ErrorMessage
                    errors={errors2}
                    name="jobTypeData"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="email" className="required">
                    Job Title
                  </label>
                  <span className="select-wrapper-sec">
                    <Controller
                      control={control2}
                      name="jobTitle"
                      rules={{
                        required: "Job Title is required",
                      }}
                      // value=''
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          values={
                            tempData != undefined
                              ? tempData.map((e) => {
                                  return {
                                    value: e["value"],
                                    label: e["caption"],
                                  };
                                })
                              : tempData
                          }
                          disabled={false}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>

                  <ErrorMessage
                    errors={errors2}
                    name="jobTitle"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="email" className="required">
                    Experience
                  </label>
                  <span className="select-wrapper-sec">
                    <Controller
                      control={control2}
                      name="experience"
                      rules={{
                        required: "experience is required",
                      }}
                      // value=''
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          values={experienceData}
                          disabled={false}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>
                  <ErrorMessage
                    errors={errors2}
                    name="experience"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Job Location</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="location"
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>

            {/* <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="email">Experience</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type here"
              name="experience"
              ref={register2({
                required: false,
              })}
            />
          </div>
        </div> */}
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Vacancies</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="vacancies"
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            {/* <div className="clearfix" /> */}
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Job Overview</label>
                <textarea
                  name="jobOverview"
                  rows={4}
                  className="form-control"
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Job Description</label>
                <textarea
                  name="jobDescription"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Minimum Qualification</label>
                <textarea
                  name="minimumQualification"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Skills Required</label>
                <textarea
                  name="skillsRequired"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Min Annual Salary</label>
                <input
                  type="number"
                  className="form-control"
                  name="minAnnualSalary"
                  placeholder="Type here"
                  ref={register2({
                    // required:
                    //   watch2("minAnnualSalary") != undefined ||
                    //   watch2("minAnnualSalary") !== ""
                    //     ? true
                    //     : false,
                    required: false,

                    // validate: (value) => {
                    //   //console.log(700, value);
                    //   if (value < 1 && value != 0) {
                    //     return "Min Annual Salary can not be a negative number";
                    //   } else if (
                    //     value >= Number(watch2("maxAnnualSalary")) &&
                    //     Number(watch2("minAnnualSalary")) !== 0
                    //   ) {
                    //     //console.log(
                    //       value,
                    //       Number(watch2("maxAnnualSalary"))
                    //     );
                    //     return "Must be less than Max Salary.";
                    //   } else {
                    //     return undefined;
                    //   }

                    // },
                  })}
                />
                <ErrorMessage
                  errors={errors2}
                  name="minAnnualSalary"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Max Annual Salary</label>
                <input
                  type="number"
                  className="form-control"
                  name="maxAnnualSalary"
                  placeholder="Type here"
                  ref={register2({
                    required: false,
                    // validate: (value) => {
                    //   if (value < 1 && value != 0) {
                    //     return "Max Annual Salary can not be a negative number";
                    //   } else if (
                    //     value < Number(watch2("minAnnualSalary") && 0)
                    //   ) {
                    //     return "Must be greater than Min Salary.";
                    //   } else {
                    //     return undefined;
                    //   }
                    // },
                  })}
                />
              </div>
              <ErrorMessage
                errors={errors2}
                name="maxAnnualSalary"
                render={({ message }) => (
                  <div className="register_validation">{message}</div>
                )}
              />
            </div>

            <div className="clearfix" />
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Documents Required</label>
                <textarea
                  name="documentsRequired"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Others</label>
                <textarea
                  name="others"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                  ref={register2({
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Assign TO</label>
                <span className="select-wrapper-sec">
                  <Controller
                    control={control2}
                    name="assignTo"
                    rules={
                      {
                        // required: "Assign To is required",
                      }
                    }
                    // value=''
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={
                          permissionResult != undefined
                            ? permissionResult.data.map((e: any) => {
                                return {
                                  value: e["value"],
                                  label: e["caption"],
                                };
                              })
                            : jobCategoryData
                        }
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>
                <ErrorMessage
                  errors={errors2}
                  name="assignTo"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email" className="required">
                  Job Status
                </label>
                <span className="select-wrapper-sec">
                  <Controller
                    control={control2}
                    name="jobStatus"
                    rules={{
                      required: "Job Status is required",
                    }}
                    // value=''
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={jobStatusSelect}
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>
                <ErrorMessage
                  errors={errors2}
                  name="jobStatus"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Job Icon</label>

                <FileUploads
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(file: any) => {
                    handleDrop([file]);
                  }}
                  name="Upload icon"
                  disabled={false}
                />
              </div>
              {filename}{" "}
              {filename === "" || filename === null ? null : (
                <a>
                  <i
                    onClick={() => handleJobIconDelete()}
                    className="fa fa-trash-o"
                    aria-hidden="true"
                    style={{ paddingLeft: 2 }}
                  />
                </a>
              )}
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Job Background</label>
                <FileUploads
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(file: any) => {
                    handleDropOne([file]);
                  }}
                  name="Upload Background"
                  disabled={false}
                />
              </div>
              {filename1}{" "}
              {filename1 === "" || filename1 == null ? null : (
                <a>
                  <i
                    onClick={() => handleJobBackGroundDelete()}
                    className="fa fa-trash-o"
                    aria-hidden="true"
                    style={{ paddingLeft: 2 }}
                  />
                </a>
              )}
            </div>

            <div className="clearfix" />
          </Modal.Body>
          <div className="modal-footer m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
              Save
            </button>

            <button
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
              onClick={() => {
                props.handleCloseModel();
                setFileName("");
                setFileName1("");
              }}
            >
              Cancel
            </button>
          </div>
          <div className="clearfix" />
        </form>
      </Modal>

      {/* END MODAL */}
    </>
  );
};

export default AdminEditJob;
