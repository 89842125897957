import { Utilities } from "./Utilities";

class LocalStoreManager {
    public static readonly DBKEY_USER_DATA = 'user_data';
    private reservedKeys: string[] =
        [
            'sync_keys',
            'addToSyncKeys',
            'removeFromSyncKeys',
            'getSessionStorage',
            'setSessionStorage',
            'addToSessionStorage',
            'removeFromSessionStorage',
            'clearAllSessionsStorage'
        ];
    private static readonly DBKEY_SYNC_KEYS = 'sync_keys';
    private syncKeys: string[] = [];

    public deleteData(key = LocalStoreManager.DBKEY_USER_DATA) {
        this.testForInvalidKeys(key);

        this.removeFromSessionStorage(key);
        localStorage.removeItem(key);
    }

    private testForInvalidKeys(key: string) {
        if (!key) {
            throw new Error('key cannot be empty');
        }

        if (this.reservedKeys.some(x => x === key)) {
            throw new Error(`The storage key "${key}" is reserved and cannot be used. Please use a different key`);
        }
    }

    private removeFromSessionStorage(keyToRemove: string) {
        this.removeFromSessionStorageHelper(keyToRemove);
        this.removeFromSyncKeysBackup(keyToRemove);
        localStorage.setItem('removeFromSessionStorage', keyToRemove);
        localStorage.removeItem('removeFromSessionStorage');
    }

    private removeFromSyncKeysBackup(key: string) {
        const storedSyncKeys = this.getSyncKeysFromStorage();
        const index = storedSyncKeys.indexOf(key);
        if (index > -1) {
            storedSyncKeys.splice(index, 1);
            this.localStorageSetItem(LocalStoreManager.DBKEY_SYNC_KEYS, storedSyncKeys);
        }
    }

    private localStorageSetItem(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    private getSyncKeysFromStorage(defaultValue: string[] = []): string[] {
        const data = this.localStorageGetItem(LocalStoreManager.DBKEY_SYNC_KEYS);

        if (data == null) {
            return defaultValue;
        } else {
            return data as string[];
        }
    }

    private removeFromSessionStorageHelper(keyToRemove: string) {
        sessionStorage.removeItem(keyToRemove);
        this.removeFromSyncKeysHelper(keyToRemove);
    }

    private removeFromSyncKeysHelper(key: string) {
        const index = this.syncKeys.indexOf(key);

        if (index > -1) {
            this.syncKeys.splice(index, 1);
        }
    }

    private syncKeysContains(key: string) {

        return this.syncKeys.some(x => x === key);
    }

    private addToSyncKeysHelper(key: string) {
        if (!this.syncKeysContains(key)) {
            this.syncKeys.push(key);
        }
    }

    private sessionStorageSetItem(key: string, data: any) {
        sessionStorage.setItem(key, JSON.stringify(data));
    }

    private addToSessionStorageHelper(data: any, key: string) {
        this.addToSyncKeysHelper(key);
        this.sessionStorageSetItem(key, data);
    }

    private addToSyncKeysBackup(key: string) {
        const storedSyncKeys = this.getSyncKeysFromStorage();

        if (!storedSyncKeys.some(x => x === key)) {
            storedSyncKeys.push(key);
            this.localStorageSetItem(LocalStoreManager.DBKEY_SYNC_KEYS, storedSyncKeys);
        }
    }

    private addToSessionStorage(data: any, key: string) {
        this.addToSessionStorageHelper(data, key);
        this.addToSyncKeysBackup(key);

        this.localStorageSetItem('addToSessionStorage', { key: key, data: data });
        localStorage.removeItem('addToSessionStorage');
    }

    public saveSyncedSessionData(data: any, key = LocalStoreManager.DBKEY_USER_DATA) {
        this.testForInvalidKeys(key);

        localStorage.removeItem(key);
        this.addToSessionStorage(data, key);
    }

    public savePermanentData(data: any, key = LocalStoreManager.DBKEY_USER_DATA) {
        this.testForInvalidKeys(key);
        this.removeFromSessionStorage(key);
        this.localStorageSetItem(key, data);
    }

    private localStorageGetItem(key: string) {
        const item = localStorage.getItem(key);
        if (item !== null)
            return Utilities.JsonTryParse(item);
        else
            return null
    }

    public getDataObject<T>(key = LocalStoreManager.DBKEY_USER_DATA, isDateType = false): T | null {
        let data = this.getData(key);
        if (data != null) {
            if (isDateType) {
                data = new Date(data);
            }
            return data as T;
        } else {
            return null;
        }
    }

    public getData(key = LocalStoreManager.DBKEY_USER_DATA) {
        this.testForInvalidKeys(key);

        let data = this.sessionStorageGetItem(key);

        if (data === null) {
            data = this.localStorageGetItem(key);
        }

        return data;
    }

    private sessionStorageGetItem(key: string) {
        const data = sessionStorage.getItem(key)
        if (data === null)
            return null;
        return Utilities.JsonTryParse(data);
    }
}

export default new LocalStoreManager();