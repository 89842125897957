import { Popover, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import { Link, useHistory, useParams } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  saveBookMarkList,
  useJobBookMarkContext,
  useJobBookMarkDispatcher,
} from "../../../action/candidate/JobBookMarkAction";
import {
  GetAllJobNotifyList,
  NotInterested,
  useJobNotifyContext,
  useJobNotifyDispatcher,
} from "../../../action/candidate/JobNotifyAction";
import { useMyProfileContext } from "../../../action/MyProfileAction";
import { jobBookMarkSaveRequestModel } from "../../../models/candidate/BookMarkedJob";
import { CoverletterDataResult } from "../../../models/candidate/CoverLetter";
import {
  NotifyJobRequestModel,
  NotifyJobRespond,
  NotInterestedRequestModel,
} from "../../../models/candidate/JobNotify";
import { ResumeAllList } from "../../../models/candidate/Resume";
import AuthService from "../../../services/AuthService";
import JobAppliedForm from "../jobs_Applied/JobAppliedForm";
import { LoadingOutlined } from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { useJobAppliedContext } from "../../../action/JobAppliedAction";
import {
  getAllCoverLetterCandidateMap,
  getAllResumeCandidateMap,
} from "../../../apis/resumebuilder";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "green" }} spin />
);

const NotifyJobComponent = () => {
  let history = useHistory();

  const myProfileContext = useMyProfileContext();
  const {
    basicInfo,
    loggedUserId,
    myProfile,
    myProfileProgressBar,
  } = myProfileContext;

  const [isJobAppliedOpen, setIsJobAppliedOpen] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentJobId, setCurrentJobId] = useState(0);
  const [filterFlag, setFilterFlag] = React.useState(true);
  const [hasMore, setHasMore] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  const [resultMessage, setResultMessage] = React.useState("");
  const [notifiedJobs, setNotifiedJobs] = useState([] as NotifyJobRespond[]);
  const [candidateCoverLetters, setCandidateCoverLetters] = useState(
    {} as CoverletterDataResult
  );
  const candidateId = loggedUserId;

  const [candidateResumes, setCandidateResumes] = useState({} as ResumeAllList);
  const authorizationToken = AuthService.accessToken;

  let { id } = useParams<any>();

  const jobNotifyDispatcher = useJobNotifyDispatcher();
  const jobNotifyContext = useJobNotifyContext();
  const { notInterestedRespond, notifyJobRespond } = jobNotifyContext;
  const jobBookMarkDispatcher = useJobBookMarkDispatcher();
  const jobBookMarkContext = useJobBookMarkContext();
  const { jobBookMarkSaveRespond } = jobBookMarkContext;
  const jobAppliedContext = useJobAppliedContext();
  const { jobAppliedSaveResultData } = jobAppliedContext;

  React.useEffect(() => {
    if (
      notifyJobRespond.data !== undefined &&
      notifyJobRespond.data.length > 0
    ) {
      setNotifiedJobs(notifyJobRespond.data);
      setResultMessage("");
    } else if (
      notifyJobRespond.data !== undefined &&
      notifyJobRespond.data.length == 0
    ) {
      setResultMessage("No Job Found");
    }
  }, [notifyJobRespond.data]);

  React.useEffect(() => {
    if (authorizationToken !== null && loggedUserId !== 0) {
      (async () => {
        await GetAllJobNotifyList(
          jobNotifyDispatcher,
          {
            CandidateId: loggedUserId,
            DigiDocumentTypeId: 1,
            PageIndex: 1,
            PageSize: 100,
            ShowInactive: false,
          } as NotifyJobRequestModel,
          authorizationToken
        );
      })();
    }
  }, [
    loggedUserId,
    notInterestedRespond,
    jobBookMarkSaveRespond,
    jobAppliedSaveResultData,
  ]);

  const handleNotInterested = (id: any) => {
    if (authorizationToken !== null) {
      (async () => {
        await NotInterested(
          jobNotifyDispatcher,
          {
            CandidateId: loggedUserId,
            RowId: id,
          } as NotInterestedRequestModel,
          authorizationToken
        );
      })();
    }
  };

  const handleShareButtonClick = (id: any, value: any) => {
    setFilterFlag(value);
    setCurrentJobId(id);
    // setCandidateSavedJobComponentState({
    //   ...CandidateSavedJobComponentState,
    //   currentJobId: id,
    //   filterFlag: value,
    // });
    // setIsShareOpen(!isShareOpen);
  };

  const handleJobApplayClick = (id: any) => {
    setCurrentJobId(id);
    // setCandidateSavedJobComponentState({
    //   ...CandidateSavedJobComponentState,
    //   currentJobId: id,
    // });
    handleJobOpen();
  };

  const handleJobBookMarked = (jobId: number) => {
    console.log(jobId);
    if (authorizationToken != null) {
      saveBookMarkList(
        jobBookMarkDispatcher,
        {
          candidateId: loggedUserId,
          jobBookmarkedId: 0,
          jobId: jobId,
          isActive: true,
          rowId: 0,
        } as jobBookMarkSaveRequestModel,
        authorizationToken
      );
    }
  };

  useEffect(() => {
    if (candidateId) {
      getAllResumeCandidateMap({
        CandidateId: candidateId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          ////console.log(res.data);
          setCandidateResumes(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, [candidateId]);

  useEffect(() => {
    if (candidateId) {
      getAllCoverLetterCandidateMap({
        CandidateId: candidateId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          ////console.log(res.data);
          setCandidateCoverLetters(res.data);
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [candidateId]);

  const handleJobOpen = () => {
    setIsJobAppliedOpen(!isJobAppliedOpen);
  };

  const fetchMoreData = async () => {
    //console.log(2424);
    setCurrentPage(currentPage + 1);
    // if (authorizationToken != null && candidateId !== 0) {

    // }
  };

  const handleJobSelect = (id: any) => {
    history.push(`/jobSearch/notify/${id}/searchtitle/location/0`);
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Notify Jobs</h1>
          <div className="clearfix" />
          <div className="row ">
            <div className="col-sm-12">
              <div className="section_box3">
                <div className="row">
                  {notifiedJobs != undefined ? (
                    notifiedJobs.length > 0 ? (
                      <InfiniteScroll
                        className="_custom-infinite-scroll-class"
                        height={900}
                        dataLength={notifiedJobs.length + 1}
                        // dataLength={2000}
                        // dataLength={totalCount}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                        // initialScrollY={indexState * 80}
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>No more data to load</b>
                          </p>
                        }
                      >
                        {notifiedJobs !== undefined
                          ? notifiedJobs.map((Job, i) => {
                              return (
                                <div
                                  className="col-sm-3"
                                  key={`${i}`}
                                  style={{ width: 300 }}
                                >
                                  <div
                                    style={{
                                      backgroundColor:
                                        Job.jobId === parseInt(id)
                                          ? "rgb(238 251 221)"
                                          : Job.viewedFlag
                                          ? ""
                                          : "aliceblue",
                                    }}
                                    className="matched_jobs"
                                  >
                                    <div
                                      style={{ width: 140 }}
                                      className="matched_jobs_cat_t textOverFlow"
                                    >
                                      {Job.categoryName}
                                    </div>
                                    <div
                                      style={{ width: 80 }}
                                      className="jobs_start1 textOverFlow"
                                    >
                                      {Job.postedDate}
                                    </div>
                                    <div className="matched_star">
                                      {Job.isBookmarked ? (
                                        <i
                                          onClick={() =>
                                            handleJobBookMarked(Job.jobId)
                                          }
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <i
                                          onClick={() =>
                                            handleJobBookMarked(Job.jobId)
                                          }
                                          className="fa fa-star-o"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </div>
                                    <div className="clearfix" />
                                    <Popover
                                      content={"Please click for more details"}
                                    >
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleJobSelect(Job.jobId)
                                        }
                                      >
                                        <div className="matched_heading">
                                          {Job.title}
                                        </div>
                                        <div className="matched_jobs_cat textOverFlow">
                                          <i
                                            className="fa fa-map-marker"
                                            aria-hidden="true"
                                          />
                                          {""}
                                          {Job.location}
                                        </div>
                                        <div className="matched_jobs_cat text-right">
                                          <i
                                            style={{ marginRight: 5 }}
                                            className="fa fa-clock-o"
                                            aria-hidden="true"
                                          />
                                          {"  "}
                                          {Job.jobType}
                                        </div>
                                        <div className="clearfix" />
                                        <p className="textOverFlow">
                                          {Job.description}
                                        </p>
                                      </div>
                                    </Popover>
                                    <div className="jobs_apply_left">
                                      {Job.isApplied ? (
                                        <a
                                          style={{
                                            backgroundColor: "darkgray",
                                          }}
                                        >
                                          Not Interested
                                        </a>
                                      ) : (
                                        <a
                                          onClick={() =>
                                            handleNotInterested(Job.rowId)
                                          }
                                        >
                                          Not Interested
                                        </a>
                                      )}
                                    </div>

                                    <div className="jobs_apply">
                                      {Job.isApplied ? (
                                        <a>{Job.jobAppliedStatus}</a>
                                      ) : myProfileProgressBar.profileProgress <
                                        40 ? (
                                        <Popover
                                          content={
                                            <>
                                              <p>
                                                Please complete your profile to
                                                minimum 40% to access this
                                                feature.
                                              </p>
                                              <Link to="/candidate/my-profile">
                                                Complete Now
                                              </Link>
                                            </>
                                          }
                                        ></Popover>
                                      ) : Job.jobActive ? (
                                        <a
                                          onClick={(id: any) =>
                                            handleJobApplayClick(Job.jobId)
                                          }
                                        >
                                          Apply Now
                                        </a>
                                      ) : (
                                        <Popover content={"Inactive Job"}>
                                          <a
                                            style={{
                                              backgroundColor: "darkgray",
                                            }}
                                          >
                                            Apply Now
                                          </a>
                                        </Popover>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </InfiniteScroll>
                    ) : loader ? (
                      <Spin size="large" style={{ padding: 20 }} />
                    ) : (
                      <h4>{resultMessage}</h4>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />

          <JobAppliedForm
            isJobAppliedOpen={isJobAppliedOpen}
            handleJobOpen={handleJobOpen}
            currentJobId={currentJobId}
            loggedUserId={loggedUserId}
            authorizationToken={authorizationToken}
            candidateResumes={candidateResumes}
            candidateCoverLetters={candidateCoverLetters}
          />
          {/* modal for share */}

          <Modal
            show={isShareOpen}
            onHide={() => {
              setIsShareOpen(!isShareOpen);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Share this job post on</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-sm-12 m_t_30 text-center">
                <div className="social1">
                  <WhatsappShareButton
                    url={encodeURI(
                      `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                    )}
                    className={""}
                    title={"CareerApp Job Post"}
                  >
                    {/* <a href="#" className="social_face"> */}
                    <Tooltip title="whatsApp">
                      <i
                        className="fa fa-whatsapp"
                        style={{
                          fontSize: 51,
                          color: "green",
                          paddingRight: 2,
                        }}
                      ></i>
                    </Tooltip>
                    {/* </a> */}
                  </WhatsappShareButton>{" "}
                  <TelegramShareButton
                    url={`career app job Share`}
                    title={encodeURI(
                      `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                    )}
                  >
                    <Tooltip title="Telegram">
                      <i
                        className="fa fa-telegram"
                        style={{
                          fontSize: 48,
                          color: "rgb(55, 174, 226)",
                        }}
                      ></i>
                    </Tooltip>
                  </TelegramShareButton>
                  <EmailShareButton
                    url={encodeURI(
                      `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                    )}
                    subject={"Jobs In Carrer app"}
                    body={"This is a Jobs from   Carrer app"}
                    className={""}
                  >
                    {/* <EmailIcon></EmailIcon> */}
                    {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                    <Tooltip title="Gmail">
                      <img
                        src={require("../../../images/gmail-icon-svg-28.jpg")}
                        style={{ height: 66, marginTop: -24 }}
                      />
                    </Tooltip>
                  </EmailShareButton>{" "}
                  <CopyToClipboard
                    //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                    text={`https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`}
                  >
                    <Tooltip title="copy to clipboard">
                      {" "}
                      {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                      <img
                        src={require("../../../images/copy-clipboard-icon-28.jpg")}
                        style={{
                          height: 66,
                          marginTop: -24,
                          marginLeft: -15,
                        }}
                        onClick={() => {
                          setIsShareOpen(!isShareOpen);
                        }}
                      />
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="modal-footer  m-t-30"></div>
            </Modal.Body>
          </Modal>
          {/* end Model for share */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotifyJobComponent;
