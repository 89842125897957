import React, { useState, useEffect, useContext } from "react";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import {
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import AuthService from "../../../services/AuthService";
import {
  useUserListContext,
  useMessageListContext,
} from "../../../context/vendor/VendorMyProfile";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { Modal } from "react-bootstrap";
import {
  sendChatFiles,
  getMessageList,
  updateReadStatus,
} from "../../../apis/vendor";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import InfiniteScroll from "react-infinite-scroll-component";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../../context/GlobalSpinner";
import { environment } from "../../../environments/environment";

interface ICandidateMessageComponentProps {
  location: any;
}

interface ICandidateMessageComponentState {
  // sendUserName: string;
  // message: string;
  // messages: string[];
  // hubConnection: null;
}

const defaultValues = {
  // sendUserName: "",
  // message: "",
  // messages: [],
  // hubConnection: null,
};

function ShowFileInfo(props: any) {
  const { obj } = props;
  // console.log(props);
  return (
    <React.Fragment>
      <div className="col-sm-6">
        {obj.file && (
          <div
            className="update_con"
            style={{
              width: 268,
              height: 105,
              paddingTop: 20,
            }}
          >
            {obj.file.name}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

function UserChatList(props: any) {
  const { filter, userList, handleReceiveUser } = props;
  //console.log(props);
  if (filter != "") {
    let sel = userList.data.filter(
      (data: any) => String(data.userName).toLowerCase() == filter.toLowerCase()
    )[0];
    if (sel != undefined && sel != null) {
      handleReceiveUser(
        sel["userId"],
        sel["userName"],
        sel["isOnline"]
      )
    }
  }
  return (
    <React.Fragment>
      {userList.data.map((item: any, index: any) => {
        const lowercasedFilter = filter.toLowerCase();
        if (
          String(item["userName"]).toLowerCase().includes(lowercasedFilter) ||
          filter == ""
        ) {
          return (
            <li>
              <a
                onClick={() =>
                  handleReceiveUser(
                    item["userId"],
                    item["userName"],
                    item["isOnline"]
                  )
                }
                className="active"
              >
                <div className="connect_icon">
                  <div className="icon_clr1">
                    {String(item["userName"]).charAt(0)}
                  </div>
                </div>
                <div className="connect_con">
                  <div className="connect_con_name">
                    {" "}
                    {item["userName"]}
                    {/* <span className="dot dot-busy" /> */}
                  </div>
                  <div className="connect_con_des">Admin - Jitmarine</div>
                </div>
                {item["unReadCount"] > 0 ? (
                  <div className="chat_time_sec">
                    <div className="connect_con_noti">
                      {item["unReadCount"]}
                    </div>
                  </div>
                ) : null}
                {/* <div className="chat_time_sec">
                  <div className="chat_time">2 Min</div>
                  <div className="connect_con_noti"></div>
                 </div> */}
              </a>
            </li>
          );
        }
      })}
    </React.Fragment>
  );
}
function ChatMessageContent(props: any) {
  const {
    // totalCount,
    fetchMoreData,
    hasMore,
    messageShowList,
    receiveUserName,
    sendUserId,
    basicInfo,
    receiveUserId,
    isOnline,
    downloadFile,
  } = props;
  ////console.log(props)
  return (
    <React.Fragment>
      <InfiniteScroll
        className="_custom-infinite-scroll-class"
        height={600}
        //dataLength={totalCount}
        dataLength={messageShowList.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        //To put endMessage and loader to the top.
        inverse={true} //
      //scrollableTarget="scrollableDiv"
      >
        {messageShowList.map((item: any, index: any) => {
          //start----------------------
          let chatPosition = "chat_right";
          let chatIcon = "chat_icon2";
          let subChatIcon = "icon_clr_rs2";
          let chatName = "chat_name1";
          let displayleft = item["messageTime"] + item["messageDate"];
          let displayRight = receiveUserName;

          let boxSide = "chat_box_r";
          if (item["fromUserId"] == sendUserId) {
            chatPosition = "chat_left";
            chatIcon = "chat_ico1";
            subChatIcon = "icon_clr_rs1";
            chatName = "chat_name";
            displayleft =
              basicInfo.fullName != undefined ? basicInfo.fullName : "";
            displayRight = item["messageTime"] + item["messageDate"];
            boxSide = "chat_box_l";
          }
          return (
            <div className={chatPosition}>
              <div className={chatIcon}>
                <div className={subChatIcon}>
                  {/* {basicInfo.fullName != undefined
                    ? basicInfo.fullName.charAt(0)
                    : ""} */}
                  {item["fromUserId"] == sendUserId && (
                    <>
                      {displayleft.charAt(0)}
                    </>
                  )}
                  {item["fromUserId"] == receiveUserId && (
                    <>
                      {displayRight.charAt(0)}
                    </>
                  )}
                </div>
              </div>
              <div className={chatName}>
                {item["fromUserId"] == sendUserId && (
                  <>
                    {displayleft}
                    <span>{displayRight}</span>
                  </>
                )}
                {item["fromUserId"] == receiveUserId && (
                  <>
                    <span>{displayleft}</span>
                    {displayRight}
                  </>
                )}
              </div>
              <div className="clearfix" />
              {item["fileType"] == "Text" && (
                <div className={boxSide} key={index}>
                  {item["message"]}
                </div>
              )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["contentHeader"].includes("image") ||
                  item["contentHeader"] == "image/jpeg") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={environment.baseUrl + `/Upload/ChatAttachment/${item["chatFile"]}`}
                      // src={
                      //   "data:" +
                      //   item["contentHeader"] +
                      //   ";base64," +
                      //   item["attachment"]
                      // }
                      // src={require("../../images/logo.png"
                      className="img-responsive"
                      alt=""
                      width={200}
                      height={200}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl + `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["chatFile"].includes(".doc") ||
                  item["contentHeader"] ==
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={require("../../../images/word_document.png")}
                      //className="img-responsive"
                      alt={item["chatFile"]}
                      width={100}
                      height={100}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl + `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["chatFile"].includes(".pdf") ||
                  item["contentHeader"] == "application/pdf") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={require("../../../images/pdf-icon.png")}
                      //className="img-responsive"
                      alt={item["chatFile"]}
                      width={100}
                      height={100}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl + `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}

              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                item["contentHeader"].includes("video") && (
                  <div className={boxSide} key={index}>
                    <ReactPlayer
                      url={environment.baseUrl + `/Upload/ChatAttachment/${item["chatFile"]}`}
                      width="50%"
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["contentHeader"].includes("audio") ||
                  item["contentHeader"] == "audio/wav") && (
                  <div className={boxSide} key={index}>
                    <ReactAudioPlayer
                      src={environment.baseUrl + `/Upload/ChatAttachment/${item["chatFile"]}`}
                      //autoPlay
                      controls
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["chatFile"].includes(".xls") ||
                  item["contentHeader"] ==
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={require("../../../images/excel_document.png")}
                      //className="img-responsive"
                      alt={item["chatFile"]}
                      width={100}
                      height={100}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl + `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}
            </div>
          );

          //end----------------------------
        })}
      </InfiniteScroll>
    </React.Fragment>
  );
}

const CandidateMessageComponent: React.FC<ICandidateMessageComponentProps> = (
  props
) => {
  const globalSpinner = useContext(GlobalSpinnerContext);
  //globalSpinner.showSpinner();
  // const [CandidateMessageComponentState,
  //   setCandidateMessageComponentState
  // ] = React.useState<ICandidateMessageComponentState>(defaultValues);

  //const authorizationToken = AuthService.accessToken;
  const [message, setMessage] = useState<string>("");
  //const [messages, setMessages] = useState<string[]>([]);
  const [sendUserId, setSendUserId] = useState<string>();
  const [sendUserName, setSendUserName] = useState<string>();
  const [receiveUserId, setReceiveUserId] = useState<string>();
  const [isOnline, setIsOnline] = useState(false);
  const [receiveUserName, setReceiveUserName] = useState<string>();
  const [filter, setFilter] = useState<string>(props.location.state?.userName != undefined ? props.location.state?.userName : "");
  //const [filter, setFilter] = useState<string>("");
  const [hubConnection, setHubConnection] = useState<HubConnection>();
  //const [list, setList] = useState();
  // const [DBMessageList, setDBMessageList] = useState();
  //const [messagesList, setMessagesList] = useState();
  const [showing, setShowing] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [isOpenFile, setIsOpenFile] = useState<boolean>(false);
  const [isOpenRecorder, setIsOpenRecorder] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState();
  const [recordState, setRecordState] = useState();
  const [hasMore, setHasMore] = React.useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCount, setTotalCount] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSize = 10;
  const [messageShowList, setMessageShowList] = useState(
    Array<{
      rowId: number;
      fromUserId: string;
      fromUserName: string;
      toUserId: string;
      toUserName: string;
      messageTime: string;
      messageDate: string;
      message: string;
      isActive: boolean;
      fileType: string;
      contentHeader: string;
      chatFile: string;
      attachment: string;
      chatDetailsId: number;
    }>()
  );
  const [DBMessageList, setDBMessageList] = useState(
    Array<{
      rowId: number;
      fromUserId: string;
      fromUserName: string;
      toUserId: string;
      toUserName: string;
      messageTime: string;
      messageDate: string;
      message: string;
      isActive: boolean;
      fileType: string;
      contentHeader: string;
      chatFile: string;
      attachment: string;
      chatDetailsId: number;
    }>()
  );
  const [newMessage, setNewMessage] = React.useState(false);
  const [messageListInfo, setMessageListInfo] = useState(
    Array<{
      rowId: number;
      fromUserId: string;
      fromUserName: string;
      toUserId: string;
      toUserName: string;
      messageTime: string;
      messageDate: string;
      message: string;
      isActive: boolean;
      fileType: string;
      contentHeader: string;
      chatFile: string;
      attachment: string;
      chatDetailsId: number;
    }>()
  );
  const myCandidateProfileContext = useMyProfileContext();
  const {
    basicInfo,
    loggedUserId,
    // myProfile,
    // myProfileProgressBar,
    // profileImage,
  } = myCandidateProfileContext;

  const userListContext = useUserListContext();
  //const messageListContext = useMessageListContext();

  const { userList, getUserList } = userListContext;

  // const {
  //   messageList,
  //   getMessageList
  // } = messageListContext;

  const user = AuthService.currentUser;

  // //console.log('getLoggedUserId-------------------', loggedUserId);
  // //console.log('basicInfo-------------------', basicInfo.fullName != undefined ? basicInfo.fullName : "");

  const candidateId = loggedUserId;
  let enterPress = true;
  const getRecentUserList = () => {
    getUserList({
      UserId: Number(AuthService.currentUser?.id),
      Page: 1,
      PageSize: 100,
      SearchTerm: "",
      SortOrder: "",
    });
  };

  useEffect(() => {
    getRecentUserList();
  }, [AuthService.currentUser?.id]);

  useEffect(() => {
    const interval = setInterval(() => {
      getRecentUserList();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // React.useEffect(() => {
  //   getUserList({
  //     Page: 1,
  //     PageSize: 10,
  //     SearchTerm: "",
  //     SortOrder: "",
  //   });
  // }, [candidateId]);

  // React.useEffect(() => {
  //   getUserList({
  //     Page: 1,
  //     PageSize: 10,
  //     SearchTerm: "",
  //     SortOrder: "",
  //   });
  // });

  React.useEffect(() => {
    getMessageList({
      FromUserId: Number(sendUserId),
      ToUserId: Number(receiveUserId),
      Page: 1,
      PageSize: currentPageSize,
      SearchTerm: "",
      SortOrder: "",
    })
      .then((res) => {
        //console.log(res.data.data.length);
        //setMessageListInfo(res.data);
        //console.log(res.data);

        // if (res.data.total > 10) {
        setHasMore(res.data.hasNext);
        setTotalPage(Math.ceil(res.data.total / currentPageSize));
        setTotalCount(res.data.total);
        setMessageShowList(res.data.data);
        // }
      })
      .catch((err) => {
        // //console.log(err);
      });
    setCurrentPage(1);
  }, [sendUserId, receiveUserId]);

  // React.useEffect(() => {
  //   setList(
  //     userList.data.map((item, index) => {
  //       const lowercasedFilter = filter.toLowerCase();
  //       if (String(item["userName"]).toLowerCase().includes(lowercasedFilter) || filter == "") {
  //         return (
  //           <li><a onClick={() => handleReceiveUser(item["userId"], item["userName"])} className="active">
  //             <div className="connect_icon"><div className="icon_clr1">{String(item["userName"]).charAt(0)}</div></div>
  //             <div className="connect_con">
  //               <div className="connect_con_name"> {item["userName"]}<span className="dot dot-busy" /></div>
  //               <div className="connect_con_des">Admin - Jitmarine</div>
  //             </div>
  //             <div className="chat_time_sec">
  //               <div className="chat_time">2 Min</div>
  //               <div className="connect_con_noti">2</div>
  //             </div>
  //           </a></li>
  //         );
  //       }
  //     })
  //   );
  // }, [filter, userList]);

  // React.useEffect(() => {
  //   //console.log(messageList);
  //   if (messageList.totalCount > 0) {
  //     setTotalPage(Math.ceil(messageList.totalCount / currentPageSize))
  //     //console.log(Math.ceil(messageList.totalCount / currentPageSize));
  //     // //console.log(...messageList.data);
  //     // //console.log("ooooooooooooo");
  //     //setMessageShowList(prevList => [...prevList, ...messageList.data]);
  //     setMessageShowList([...messageShowList, ...messageList.data]);
  //   }
  //   else {

  //     setMessageShowList(messageList.data);
  //   }
  //   /// //console.log(messageShowList);
  // }, [messageList.data]);

  React.useEffect(() => {
    setMessageShowList([...messageShowList, ...messageListInfo]);
    /// //console.log(messageShowList);
  }, [messageListInfo]);

  React.useEffect(() => {
    if (newMessage) {
      setNewMessage(false);
      setMessageShowList([...DBMessageList, ...messageShowList]);
    }
  }, [newMessage]);

  // //console.log(loggedUserId, 'candidatelist------------>', userList);
  // //console.log(loggedUserId, 'sendUserId------------>', sendUserId);
  // //console.log(loggedUserId, 'receiveUserId------------>', receiveUserId);
  // //console.log(loggedUserId, 'messagelist------------>', messageList);

  const handleReceiveUser = async (
    receiveUserIdSel: any,
    receiveUserNameSel: any,
    isOnline: any
  ) => {
    setReceiveUserId(receiveUserIdSel);
    setReceiveUserName(receiveUserNameSel);
    setIsOnline(isOnline);
    setShowing(true);

    // Update message read status
    type objectIndexing = {
      [key: string]: any;
    };
    let _data: objectIndexing = {};

    _data["SendUserId"] = Number(receiveUserIdSel);
    _data["ReceiveUserId"] = Number(user?.id);

    updateReadStatus(_data).then((res: any) => {
      //console.log(res);
      if (res.data.isSuccess) {
        //console.log("read-updated-success");
      } else {
        //console.log("read-updated-error");
      }
    });
  };

  // Set the Hub Connection on mount.
  useEffect(() => {
    // Set the initial SignalR Hub Connection.
    const createHubConnection = async () => {
      const signalR = require("@aspnet/signalr");

      // Build new Hub Connection, url is currently hard coded.
      const hubConnect = new HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Debug)
        .withUrl("https://careerapp.azurewebsites.net/chathub", {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .build();

      try {
        await hubConnect.start();
        // //console.log('Connection successful!!!');
        // //console.log('getLoggedUserId', loggedUserId);
        // //console.log('user', user);
        // //console.log('user id', user?.id);
        setSendUserId(user?.id);
        setSendUserName(basicInfo.fullName);
        // //console.log('Basic Info', basicInfo);
        // //console.log(basicInfo.fullName)
        // //console.log("authorizationToken", authorizationToken)

        // Bind event handlers to the hubConnection.
        hubConnect.on(
          "ReceiveMessage",
          (
            sendUserId: string,
            sendUserName: string,
            receiveUserId: string,
            receiveUserName: string,
            message: any
          ) => {
            // setMessages(m => [...m, message]);
            // //console.log("sendUserId :", `${sendUserId}`);
            // //console.log("sendUserName :", `${sendUserName}`);
            // //console.log("receiveUserId :", `${receiveUserId}`);
            // //console.log("receiveUserName :", `${receiveUserName}`);
            let postTime = new Date().toLocaleString("en", {
              hour: "numeric",
              hour12: true,
              minute: "numeric",
            });
            let messageText = "";
            let fileType = "";
            let contentHeader = "";
            let chatFile = "";
            let attachment = "";
            if (typeof message == "object") {
              messageText = message["fileName"];
              fileType = "File";
              contentHeader = message["fileHeaders"];
              chatFile = message["fileName"];
              attachment = message["fileBinary"];
            }
            if (typeof message == "string") {
              messageText = message;
              fileType = "Text";
            }
            const item = {
              rowId: 0,
              fromUserId: `${sendUserId}`,
              fromUserName: `${sendUserName}`,
              toUserId: `${receiveUserId}`,
              toUserName: `${receiveUserName}`,
              messageTime: postTime,
              messageDate: "",
              message: messageText,
              isActive: true,
              fileType: fileType,
              contentHeader: contentHeader,
              chatFile: chatFile,
              attachment: attachment,
              chatDetailsId: 0,
            };
            ////console.log("kkkkkkkkkkkk");
            setDBMessageList([item]);
            setNewMessage(true);
          }
        );

        // hubConnect.on('newuserconnected', (nick: string) => {
        //   setMessages(m => [...m, `${nick} has connected.`]);
        // })
        // **TODO**
        // hubConnection.off('userdisconnected', (nick: string) => {
        //
        //     setMessages(m => [...m, `${nick} has disconnected.`]);
        // })
      } catch (err) {
        //alert(err);
        //console.log("Error while establishing connection: " + { err });
      }
      setHubConnection(hubConnect);
    };
    createHubConnection();
  }, [loggedUserId]);

  const handleMessage = async () => {
    await sendMessage();
    setMessage("");
    enterPress = true;
  };

  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && enterPress) {
      enterPress = false;
      handleMessage();
    }
  };

  const emojiIconClick = () => {
    setShowEmoji(!showEmoji);
  };
  const addEmoji = (e: any) => {
    let emoji = e.native;
    setMessage(message + emoji);
  };

  /** Send message to server with client's nickname and message. */
  async function sendMessage(): Promise<void> {
    try {
      if (hubConnection && message !== "") {
        await hubConnection.invoke(
          "SendMessage",
          sendUserId,
          sendUserName,
          receiveUserId,
          receiveUserName,
          message
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  const selectFile = (event: any) => {
    setSelectedFiles(event.target.files[0]);
    // //console.log(event.target.files[0]);
    // //console.log(selectedFiles);

    // const formData = new FormData();

    // formData.append('FormFile', event.target.files[0]);
    // formData.append('FileName', event.target.files[0].name);
    // formData.append('SendUserId', String(sendUserId));
    // formData.append('SendUserName', String(sendUserName));
    // formData.append('ReceiveUserId', String(receiveUserId));
    // formData.append('ReceiveUserName', String(receiveUserName));
    //setSelectedFiles(formData);

    // sendChatFiles(formData).then((res) => {
    //   //console.log(res.data);
    // }).catch((err) => {
    //   //console.log(err);
    // });
  };
  const sendFiles = () => {
    ////console.log(selectedFiles);
    globalSpinner.showSpinner();
    if (obj.file != undefined) {
      const formData = new FormData();
      // formData.append('FormFile', selectedFiles);
      // formData.append('FileName', selectedFiles.name);
      formData.append("FormFile", obj.file);
      formData.append("FileName", obj.file.name);
      formData.append("SendUserId", String(sendUserId));
      formData.append("SendUserName", String(sendUserName));
      formData.append("ReceiveUserId", String(receiveUserId));
      formData.append("ReceiveUserName", String(receiveUserName));
      sendChatFiles(formData)
        .then((res) => {
          //toast.error(res.status);
          ////console.log(res.data);
          setObj({ file: null });
          setSelectedFiles(undefined);
          setIsOpenFile(false);
        })
        .catch((err) => {
          //console.log(err);
          setObj({ file: null });
          toast.error("Please try again later.");
          setIsOpenFile(false);
        });
    }
    globalSpinner.hideSpinner();
  };
  const sendAudioFiles = (data: any) => {
    globalSpinner.showSpinner();
    const formData = new FormData();
    formData.append("FormFile", data.blob, "blob.wav");
    formData.append("FileName", "blob.wav");
    formData.append("SendUserId", String(sendUserId));
    formData.append("SendUserName", String(sendUserName));
    formData.append("ReceiveUserId", String(receiveUserId));
    formData.append("ReceiveUserName", String(receiveUserName));
    sendChatFiles(formData)
      .then((res) => {
        ////console.log(res.data);
        setIsOpenRecorder(false);
      })
      .catch((err) => {
        //console.log(err);
        toast.error("Please try again later.");
        setIsOpenRecorder(false)
      });
    globalSpinner.hideSpinner();
  };

  const start = () => {
    setRecordState(RecordState.START);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };

  //audioData contains blob and blobUrl
  const onStop = (audioData: any) => {
    // //console.log('audioData', audioData)
    sendAudioFiles(audioData);
  };

  const downloadFile = (filePath: any, fileName: any) => {
    var FileSaver = require("file-saver");
    FileSaver.saveAs(filePath, fileName);
  };

  const fetchMoreData = () => {
    //console.log("hhh");
    //console.log(currentPage, totalPage);
    if (currentPage >= totalPage) {
      setHasMore(false);
      return;
    } else {
      // getMessageList({
      //   FromUserId: Number(sendUserId),
      //   ToUserId: Number(receiveUserId),
      //   Page: currentPage,
      //   PageSize: currentPageSize,
      //   SearchTerm: "",
      //   SortOrder: ""
      // });
      getMessageList({
        FromUserId: Number(sendUserId),
        ToUserId: Number(receiveUserId),
        Page: currentPage + 1,
        PageSize: currentPageSize,
        SearchTerm: "",
        SortOrder: "",
      })
        .then((res) => {
          // //console.log(res.data.data.length);
          // setMessageListInfo(res.data);
          // setMessageShowList(res.data.data);
          setMessageListInfo(res.data.data);
          //console.log(messageListInfo);
          //setMessageShowList([...messageShowList, ...messageListInfo]);
          setCurrentPage(currentPage + 1);
          // if (res.data.total > 10) {
          //   setHasMore(true);
          // }
        })
        .catch((err) => {
          // //console.log(err);
        });
    }
  };

  const [obj, setObj] = useState<any>({ file: null });

  const handleDrop = (files: any) => {
    if (
      // ["image/jpeg", "application/pdf"].includes(files[0].type) &&
      files[0].size <
      1048576 * 20
    ) {
      //console.log(files[0]);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
    } else {
      toast.error("File size limit is 20MB");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Message</h1>
          <div className="clearfix" />
          <div className="row ">
            <div className="col-sm-5 col-lg-4 p-r-0">
              <div className="panel panel-default panel_n">
                <div className="panel-body panel-body1">
                  <div className="connect_left">
                    <div>
                      <input
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="form-control"
                        placeholder="Search"
                      />
                      <div className="search_icon">
                        <i className="fa fa-search" aria-hidden="true" />
                      </div>
                    </div>
                    <div className=" m-t-25">
                      <div
                        className="message_chat_scroll"
                        style={{ overflow: "hidden", outline: "none" }}
                        tabIndex={0}
                      >
                        <div className="connect_scroll">
                          <ul>
                            <UserChatList
                              filter={filter}
                              userList={userList}
                              handleReceiveUser={handleReceiveUser}
                            />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-7 col-lg-8">
              <div
                className="panel panel-default panel_n"
                style={{ display: { showing } ? "block" : "none" }}
              >
                <div className="panel-body panel-body1">
                  <div className="connect_right">
                    <div className="connect_right_top">
                      {receiveUserName != undefined ? (
                        <div>
                          <div className="chat_icon1">
                            <div className="icon_clr_rs1">A</div>
                          </div>
                          <div className="connect_con_name_r">
                            {receiveUserName}
                          </div>
                          <div className="connect_con_ac">
                            {isOnline == true ? "Online" : "Offline"}
                          </div>
                        </div>
                      ) : (
                        <div className="connect_con_name_r">
                          Please select an admin to chat
                        </div>
                      )}
                    </div>
                    <div className=" m-t-10">
                      <div
                        className="message_chat_des_scroll"
                        style={{ overflow: "revert", outline: "none" }}
                        tabIndex={0}
                      >
                        <div className="connect_scroll_r">
                          <ChatMessageContent
                            totalCount={totalCount}
                            fetchMoreData={fetchMoreData}
                            messageShowList={messageShowList}
                            receiveUserName={receiveUserName}
                            sendUserId={sendUserId}
                            basicInfo={basicInfo}
                            receiveUserId={receiveUserId}
                            isOnline={isOnline}
                            downloadFile={downloadFile}
                            hasMore={hasMore}
                          />

                          {/* {DBMessageList}
                          {messagesList} */}
                        </div>
                      </div>
                    </div>
                    {showEmoji && (
                      <div className="connect_right_bottom">
                        <div className="connect_right_forms">
                          <span>
                            <Picker onSelect={addEmoji} />
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="connect_right_bottom">
                      <div className="connect_right_forms">
                        <input
                          type="text"
                          value={message}
                          className="form-control"
                          placeholder="Type message here"
                          onChange={(e) => setMessage(e.target.value)}
                          onKeyDown={onEnter}
                        />
                      </div>
                      <div className="smile_icon">
                        <img
                          onClick={() => emojiIconClick()}
                          src={require("../../../images/smile.png")}
                        />
                      </div>
                      <div className="connect_right_icons">
                        <img
                          src={require("../../../images/attach_icon.png")}
                          onClick={() => setIsOpenFile(true)}
                          style={{ paddingLeft: 10, paddingRight: 10 }}
                        />
                        <img
                          src={require("../../../images/speaker_icon.png")}
                          onClick={() => setIsOpenRecorder(true)}
                        />
                      </div>
                    </div>

                    <Modal
                      show={isOpenFile}
                      onHide={() => {
                        setIsOpenFile(!isOpenFile);
                      }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Send File</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-6">
                              <FileUploads
                                accept=".pdf,.jpg,.jpeg,.xlsx,.csv,.xls"
                                onChange={(file: any) => {
                                  handleDrop([file]);
                                }}
                                name="Upload File"
                                disabled={false}
                              />
                            </div>
                            <ShowFileInfo obj={obj} />
                            {/* <div className="col-sm-6">
                              {obj.file && (
                                <div
                                  className="update_con"
                                  style={{
                                    width: 268,
                                    height: 105,
                                    paddingTop: 20,
                                  }}
                                >
                                  {obj.file.name}
                                </div>
                              )}
                            </div> */}
                            {/* <div className="fileUpload btn btn-primary">
                              <span>Upload</span>
                              <input id="uploadBtn" type="file" onChange={selectFile} className="upload" />
                            </div> */}
                          </div>
                        </div>
                      </Modal.Body>
                      <div className="modal-footer  m-t-30">
                        <button
                          onClick={() => sendFiles()}
                          className="btn btn-danger"
                          type="submit"
                          disabled={obj.file ? false : true}
                        >
                          Send
                        </button>
                        <button
                          onClick={() => setIsOpenFile(false)}
                          className="btn btn-default"
                          type="button"
                        >
                          cancel
                        </button>
                      </div>
                      <div className="clearfix" />
                    </Modal>
                    <Modal
                      show={isOpenRecorder}
                      onHide={() => {
                        setIsOpenRecorder(!isOpenRecorder);
                      }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Send Audio File</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <AudioReactRecorder
                            state={recordState}
                            onStop={onStop}
                          />
                          <button onClick={start} className="btn btn-primary">
                            Start
                          </button>
                          <button onClick={stop} className="btn btn-danger">
                            Send
                          </button>
                        </div>
                      </Modal.Body>
                      <div className="modal-footer  m-t-30">
                        <button
                          onClick={() => setIsOpenRecorder(false)}
                          className="btn btn-default"
                          type="button"
                        >
                          cancel
                        </button>
                      </div>
                      <div className="clearfix" />
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(CandidateMessageComponent);
