import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  getStory,
  useStoryContext,
  useStoryDispatcher,
} from "../../../../action/admin/StoryAction";
import { StoryEditRequestModel } from "../../../../models/admin/Story";
import FileUploads from "../../../candidate/my_profile/components/FileUploads";
import SelectOption from "../../../candidate/my_profile/components/SelectOption";
import { StoryGetDataWithId } from "../../../../models/admin/Story";
import { saveStory } from "./../../../../apis/admin";
import _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "./../../../../context/GlobalSpinner";
import DragAndDrop from "../../../candidate/my_profile/components/DragAndDrop";

interface IStoryFormProps {
  isStoryOpen: boolean;
  handleStoryOpen: () => void;
  currentStoryId: number;
  loggedAdminId: number;
  authorizationToken: string | null;
}
interface ReactSelectOption {
  value: string;
  label: string;
}
const defaultValues = {
  selectResume: "",
  coverLetter: "",
  designationId: 0,
};

const StoryForm: React.FC<IStoryFormProps> = (props) => {
  const {
    handleStoryOpen,
    isStoryOpen,
    currentStoryId,
    loggedAdminId,
    authorizationToken,
  } = props;

  const [obj, setObj] = useState<any>({ file: null });
  const [obj2, setObj2] = useState<any>({ file: null });
  const [disable, setDisable] = useState<number>(0);
  const [disable2, setDisable2] = useState<number>(0);
  const [disable3, setDisable3] = useState<string>("");
  const [disable4, setDisable4] = useState<string>("");

  const StoryDispatcher = useStoryDispatcher();
  const StoryContext = useStoryContext();
  const { StoryResultModel } = StoryContext;
  const globalSpinner = useContext(GlobalSpinnerContext);

  const [sizeValidate, setSizeValidate] = useState<string>("");

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  const handleDrop = (files: any) => {
    if (
      ["image/jpeg", "image/jpg", "image/png"].includes(files[0].type) === false
    ) {
      setSizeValidate("Acceptable file formats: JPEG,JPG,PNG");
      return false;
    }

    if (
      ["image/jpeg", "image/jpg", "image/png"].includes(files[0].type) &&
      files[0].size < 1000000 * 2
    ) {
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
      setSizeValidate("");
    } else {
      setSizeValidate("Image size can't be grater than 2MB");
    }
  };

  React.useEffect(() => {
    if (loggedAdminId != 0 && authorizationToken != null) {
      (async () => {
        await getStory(
          StoryDispatcher,
          {
            rowId: currentStoryId,
          } as StoryGetDataWithId,
          authorizationToken
        );
      })();
    }
  }, [currentStoryId]);

  React.useEffect(() => {
    //console.log("----StoryResultModel----", StoryResultModel);
    if (StoryResultModel.data != null) {
      setValue3("rowId", StoryResultModel.data["rowId"]);
      setValue3("storyId", StoryResultModel.data["storyId"]);
      setValue3("description", StoryResultModel.data["description"]);
      setValue3("imageUrl", StoryResultModel.data["imageUrl"]);
      setValue3("adminId", loggedAdminId);
      setValue3("isActive", StoryResultModel.data["isActive"]);
    }
  }, [StoryResultModel.data, currentStoryId]);

  //console.log("StoryResultModel", StoryResultModel);
  //console.log("loggedAdminId", loggedAdminId);

  const handleStory = (data: any) => {
    //console.log(33, data);

    if (authorizationToken != null) {
      globalSpinner.showSpinner();
      const formData = new FormData();
      if (currentStoryId > 0) {
        formData.append("RowId", String(currentStoryId));
      } else {
        formData.append("RowId", String(0));
      }
      formData.append("StoryId", String(0));
      formData.append("Description", String(data["description"]));
      formData.append("Document", obj.file);
      // if(StoryResultModel.data != null){
      //   formData.append("ImageUrl", String(StoryResultModel.data["imageUrl"]));
      // }
      formData.append("AdminId", String(loggedAdminId));
      formData.append("IsActive", JSON.stringify(true));
      saveStory(formData).then((res: any) => {
        //console.log(res);
        if (res.data.isSuccess) {
          globalSpinner.hideSpinner();
          toast.success("Story saved successfully.");
        } else {
          globalSpinner.hideSpinner();
          toast.error("Error:Story not saved.");
        }
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setDisable(0);
    setDisable2(0);
    setDisable3("");
    handleStoryOpen();
  };

  return (
    <>
      <ToastContainer />
      <div className=" ">
        <div id="create_jobdescription" className="modal fade none-border">
          <Modal show={isStoryOpen} onHide={handleClose}>
            <form onSubmit={handleSubmit3(handleStory)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>Add New</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="email">Description</label>
                  <textarea
                    name="description"
                    className="form-control"
                    defaultValue={""}
                    ref={register3({
                      required: "Description is required.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors3}
                    name="description"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
                <div className="form-group">
                  <FileUploads
                    accept=".jpg,.jpeg,.png"
                    onChange={(file: any) => {
                      handleDrop([file]);
                    }}
                    name="Upload Career Tips"
                    disabled={disable3 !== "" ? true : false}
                  />
                  <DragAndDrop handleDrop={handleDrop}>
                    {obj.file ? (
                      <div
                        className="update_con"
                        style={{ width: 268, height: 105 }}
                      >
                        {obj.file.name}
                      </div>
                    ) : (
                      <React.Fragment>
                        {/* <div className="update_con">
                  Drag and drop your file here{" "}
                </div> */}
                        <div className="update_con">
                          Acceptable file formats: JPEG,JPG,PNG
                        </div>
                        <div className="update_con">
                          Maximum file size: 2 MB.{" "}
                        </div>
                      </React.Fragment>
                    )}
                  </DragAndDrop>
                  <h6 style={{ color: "red", marginLeft: 225 }}>
                    {sizeValidate}
                  </h6>
                </div>
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default StoryForm;
