import { Reducer } from "react";
import {
  IMyProfileManagementState,
  MyProfileDispatcherContext,
  MyProfileStateContext,
} from "../context/MyProfileContext";
import React from "react";

import axios from "axios";
import AuthService from "../services/AuthService";
import { AppUrls } from "../environments/environment";
import {
  BasicInfoViewModel,
  MyProfileSelectBoxDataViewModel,
  profileBarProgress,
  profileImage,
  profileImageRequestModel,
  profileImageViewModel,
} from "../models/candidate/MyProfileSelectBoxData";
import { apiClient } from "../utils/httpClient";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IMyProfileAction =
  | {
      type: "MY_PROFILE_SELECTBOX_LIST";
      myProfile: MyProfileSelectBoxDataViewModel;
    }
  | {
      type: "MY_PROFILE_Basic_Details";
      basicInfo: BasicInfoViewModel;
    }
  | {
      type: "GET_LOGGED_USERID";
      loggedUserId: number;
    }
  | {
      type: "GET_PROFILE_IMAGE";
      profileImage: profileImageViewModel;
    }
  | {
      type: "GETTING PROGRESS";
      myProfileProgressBar: profileBarProgress;
    };

export const myProfileReducer: Reducer<
  IMyProfileManagementState,
  IMyProfileAction
> = (draft, action): IMyProfileManagementState => {
  switch (action.type) {
    case "MY_PROFILE_SELECTBOX_LIST":
      draft.myProfile = action.myProfile;
      return draft;
    case "MY_PROFILE_Basic_Details":
      draft.basicInfo = action.basicInfo;
      return draft;
    case "GET_LOGGED_USERID":
      draft.loggedUserId = action.loggedUserId;
      return draft;
    case "GET_PROFILE_IMAGE":
      draft.profileImage = action.profileImage;
      return draft;
    case "GETTING PROGRESS":
      draft.myProfileProgressBar = action.myProfileProgressBar;
      return draft;
  }
};

export const useMyProfileDispatcher = (): React.Dispatch<IMyProfileAction> => {
  const myProfileDispatcher = React.useContext(MyProfileDispatcherContext);
  if (!myProfileDispatcher) {
    throw new Error(
      "You have to provide the MyProfile dispatcher using theMyProfileDispatcherContext.Provider in a parent component."
    );
  }
  return myProfileDispatcher;
};

export const useMyProfileContext = (): IMyProfileManagementState => {
  const myProfileContext = React.useContext(MyProfileStateContext);
  if (!myProfileContext)
    throw new Error(
      "You have to provide the myProfile context using the MyProfileStateContext.Provider in a parent component."
    );
  return myProfileContext;
};

export const getMyProfileSelectBoxList = async (
  dispatcher: React.Dispatch<IMyProfileAction>
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const url = AppUrls.GetMyProfileSelectBoxData;

    axios.post(url, JSON.stringify({}), { headers: header }).then((res) => {
      dispatcher({ type: "MY_PROFILE_SELECTBOX_LIST", myProfile: res.data });
    });
  } catch (e) {}
};

export const getMyProfileBasicInfo = async (
  dispatcher: React.Dispatch<IMyProfileAction>,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetMyProfileBasicDetails;

    axios.post(url, JSON.stringify({}), { headers: header }).then((res) => {
      dispatcher({ type: "MY_PROFILE_Basic_Details", basicInfo: res.data });
    });
  } catch (e) {}
};

export const getLoggedUserId = async (
  dispatcher: React.Dispatch<IMyProfileAction>,
  query: number,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetLoggedCandidateId + `${query}`;

    axios.get(url, { headers: header }).then((res) => {
      dispatcher({ type: "GET_LOGGED_USERID", loggedUserId: res.data });
    });
  } catch (e) {}
};

export const getLoggedVendorId = async (
  dispatcher: React.Dispatch<IMyProfileAction>,
  query: number,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetLoggedVendorId + `${query}`;

    axios.get(url, { headers: header }).then((res) => {
      dispatcher({ type: "GET_LOGGED_USERID", loggedUserId: res.data });
      //console.log(500000, res.data);
    });
  } catch (e) {}
};

export const getProfileImage = async (
  dispatcher: React.Dispatch<IMyProfileAction>,
  query: profileImageRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetProfileImage;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "GET_PROFILE_IMAGE", profileImage: res.data });
    });
  } catch (e) {}
};

export const getProfileProgressBarResult = async (
  dispatcher: React.Dispatch<IMyProfileAction>,
  query: profileImageRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetProfileProgressBar;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "GETTING PROGRESS", myProfileProgressBar: res.data });
    });
  } catch (e) {}
};
