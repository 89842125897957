import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import PreviewHtmlTemplate from "./PreviewHtmlTemplate";
import { useHistory } from "react-router-dom";
import { GlobalSpinnerContext } from "../../../../context/GlobalSpinner";
import { toast, ToastContainer } from "react-toastify";
import {
  saveResumeCandidateMap,
  //, generateResumePDF
  getResumeReport,
} from "../../../../apis/resumebuilder";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import { Modal } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
//import { asBlob } from "html-docx-js-typescript";
import { getOnePassportInformation } from "../../../../apis/candidate";

type objectIndexing = {
  [key: string]: any;
};

interface IResumePreviewComponentProps {
  resumeInfo: any;
  candidateId: number;
  resumeTemplateID: any;
  resumeTemplateHtml: any;
}

interface IResumePreviewComponentState {}

//const initialState = {};

interface ICandidateResumeState {
  resumeName: string;
}

function SaveCandidateResume(props: any) {
  const defaultValues = {
    resumeName: "",
  };

  const {
    register,
    handleSubmit,
    errors,
    //watch,, setValue, getValues, control, reset
  } = useForm<ICandidateResumeState>({
    defaultValues,
  });
  const onSubmit = (data: any) => {
    var file_name_string = data["resumeName"];
    var resume_name_array = file_name_string.split(".");
    //var resume_name_extension = resume_name_array[resume_name_array.length - 1];
    ////console.log(resume_name_array[0]);
    ////console.log(resume_name_extension);
    props.saveTemplateWithName(resume_name_array[0]);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Modal.Header closeButton>
        <Modal.Title>Add Resume</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="email">Resume Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type Here"
              name="resumeName"
              ref={register({
                required: "Resume name is required",
              })}
            />

            <ErrorMessage
              errors={errors}
              name="resumeName"
              render={({ message }) => (
                <div className="login_validation">{message}</div>
              )}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer  m-t-30">
        <button
          className="btn btn-success save-event waves-effect waves-light"
          type="submit"
        >
          {" "}
          Add{" "}
        </button>
        <button
          onClick={() => {
            props.setIsOpen(false);
          }}
          data-dismiss="modal"
          className="btn btn-default waves-effect _cursor-pointer"
          type="button"
        >
          {" "}
          Cancel
        </button>
      </div>
      <div className="clearfix" />
    </form>
  );
}

const ResumePreviewComponent: React.FC<IResumePreviewComponentProps> = (
  props
) => {
  // const [resumePreviewComponentState, setResumePreviewComponentState] = React.useState<IResumePreviewComponentState>(
  //   initialState
  // );
  ////console.log(props);
  let resNumber = props.resumeTemplateHtml.replace("template", "");
  resNumber = resNumber.replace(".html", "");
  ////console.log(resNumber);
  const globalSpinner = useContext(GlobalSpinnerContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [
    onePassportInformation,
    setOnePassportInformation,
  ] = useState<objectIndexing>({});
  let history = useHistory();

  const handleReselectTemplate = () => {
    history.push({
      pathname: "/candidate/my-resume/resume/select_template",
    });
  };

  //rdlc report generation start
  const saveTemplateWithName = (resumeName: any) => {
    let input = document.getElementById("pdfdiv");
    globalSpinner.showSpinner();

    if (input) {
      const html2pdf = require("html2pdf.js");

      let htmlContent = input.innerHTML;

      var b64toBlob = require("b64-to-blob");

      // asBlob(htmlContent, opt1).then((data: any) => {
      getResumeReport(
        {},
        `?id=${encodeURIComponent(resNumber)}&candidateId=${encodeURIComponent(
          props.candidateId
        )}&reportType=${encodeURIComponent(5)}`
      )
        .then((res1: any) => {
          //console.log(res1);
          // const blobWord = new Blob([res1.data], { type: " application/msword" });
          const blobWord = new Blob([res1.data], {
            type:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          html2pdf()
            .set()
            .from(input)
            .outputImg("datauristring")
            .then(function (imgAsString: any) {
              var info = imgAsString.replace("data:image/jpeg;base64,", "");
              var resumeimage = b64toBlob(info, "image/jpg");
              const formData = new FormData();
              formData.append("RowId", JSON.stringify(0));
              formData.append("ResumeCandidateMapId", JSON.stringify(0));
              formData.append(
                "ResumeTemplateId",
                JSON.stringify(props.resumeTemplateID)
              );
              formData.append("CandidateId", JSON.stringify(props.candidateId));
              formData.append("Resume", htmlContent);
              formData.append("ResumeName", resumeName);
              //formData.append("ResumeFile", resumeName + ".pdf");
              formData.append("ResumeFile", resumeName + ".docx");
              //formData.append("ResumeFile", resumeName + ".doc");
              // formData.append("ResumeFileDocument", data, resumeName + ".docx");
              // formData.append("ResumeFileDocument", new Blob([res1.data], { type: 'application/pdf' }), resumeName + ".pdf");
              // formData.append(
              //   "ResumeFileDocument",
              //   blobWord,
              //   resumeName + ".doc"
              // );
              formData.append(
                "ResumeFileDocument",
                blobWord,
                resumeName + ".docx"
              );
              formData.append("ResumeImage", resumeName + ".jpg");
              formData.append(
                "ResumeImageDocument",
                resumeimage,
                resumeName + ".jpg"
              );
              formData.append("IsActive", JSON.stringify(true));
              //var FileSaver = require('file-saver');
              //FileSaver.saveAs(blobWord, resumeName + ".docx");
              ////console.log(formData)
              saveResumeCandidateMap(formData)
                .then((res) => {
                  ////console.log(res.data);
                  globalSpinner.hideSpinner();
                  if (res.data.isSuccess) {
                    setIsOpen(false);
                    toast.success(res.data.message);

                    setTimeout(
                      () =>
                        history.push({
                          pathname: "/candidate/my-resume",
                        }),
                      3000
                    );
                  } else {
                    toast.error(res.data.message);
                  }
                })
                .catch((err) => {
                  ////console.log(err);
                  globalSpinner.hideSpinner();
                  toast.error(err.toString());
                });
            });
        })
        .catch((err) => {
          ////console.log(err);
          globalSpinner.hideSpinner();
          toast.error("Unable to save,Please try again.");
        });
      //});
    } else {
      globalSpinner.hideSpinner();
      toast.error("Unable to save,Please try again.");
    }
  };

  //rdlc report generation end

  //azure function pdf generation start
  // const saveTemplateWithName2 = (resumeName: any) => {

  //   let input = document.getElementById('pdfdiv');
  //   globalSpinner.showSpinner();
  //   if (input) {
  //     const html2pdf = require('html2pdf.js');

  //     let htmlContent = input.innerHTML

  //     var b64toBlob = require('b64-to-blob');

  //     // asBlob(htmlContent, opt1).then((data: any) => {
  //     generateResumePDF({
  //       "resumehtml": input.innerHTML
  //     }).then((res1: any) => {
  //       html2pdf().set().from(input).outputImg('datauristring').then(function (imgAsString: any) {
  //         var info = imgAsString.replace("data:image/jpeg;base64,", "");
  //         var resumeimage = b64toBlob(info, 'image/jpg');
  //         const formData = new FormData();
  //         formData.append("RowId", JSON.stringify(0));
  //         formData.append("ResumeCandidateMapId", JSON.stringify(0));
  //         formData.append("ResumeTemplateId", JSON.stringify(props.resumeTemplateID));
  //         formData.append("CandidateId", JSON.stringify(props.candidateId));
  //         formData.append("Resume", htmlContent);
  //         formData.append("ResumeName", resumeName);
  //         formData.append("ResumeFile", resumeName + ".pdf");
  //         // formData.append("ResumeFileDocument", data, resumeName + ".docx");
  //         formData.append("ResumeFileDocument", new Blob([res1.data], { type: 'application/pdf' }), resumeName + ".pdf");
  //         formData.append("ResumeImage", resumeName + ".jpg");
  //         formData.append("ResumeImageDocument", resumeimage, resumeName + ".jpg");
  //         formData.append("IsActive", JSON.stringify(true));
  //         ////console.log(formData)
  //         saveResumeCandidateMap(formData).then((res) => {
  //           ////console.log(res.data);
  //           globalSpinner.hideSpinner();
  //           if (res.data.isSuccess) {
  //             setIsOpen(false);
  //             toast.success(res.data.message);

  //             setTimeout(() =>
  //               history.push({
  //                 pathname: '/candidate/my-resume',
  //               }), 3000);
  //           }
  //           else {
  //             toast.error(res.data.message);
  //           }
  //         }).catch((err) => {
  //           ////console.log(err);
  //           globalSpinner.hideSpinner();
  //           toast.error(err.toString());
  //         });
  //       });
  //     }).catch((err) => {
  //       ////console.log(err);
  //       globalSpinner.hideSpinner();
  //       toast.error(err.toString());
  //     });
  //     //});

  //   }
  //   else {
  //     globalSpinner.hideSpinner();
  //     toast.error("Unable to save,Please try again.");
  //   }

  // };
  //azure function pdf generation end
  // const saveTemplateWithName = (resumeName: any) => {

  //   let input = document.getElementById('pdfdiv');
  //   globalSpinner.showSpinner();
  //   if (input) {
  //     const html2pdf = require('html2pdf.js');
  //     var opt = {
  //       margin: [0.5, 0, 0.5, 0],//[top, left, bottom, right],
  //       //filename: resumeName+'.pdf',
  //       image: { type: 'jpg', quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape', compress: true },
  //     };
  //     let htmlContent = input.innerHTML
  //     //html2pdf().from(input).toPdf().output('datauristring').then(function (pdfAsString: any) {
  //     // html2pdf().set(opt).from(input).outputPdf('datauristring').then(function (pdfAsString: any) {
  //     var b64toBlob = require('b64-to-blob');

  //     const opt1 = {
  //       margin: {
  //         top: 10
  //       },
  //       orientation: 'landscape' as const
  //     }
  //     asBlob(htmlContent, opt1).then((data: any) => {
  //       // saveAs(data, 'file.docx') // save as docx file

  //       //var info = pdfAsString.replace("data:application/pdf;filename=generated.pdf;base64,", "");
  //       //var resumepdf = b64toBlob(info, 'application/pdf');
  //       html2pdf().set().from(input).outputImg('datauristring').then(function (imgAsString: any) {
  //         var info = imgAsString.replace("data:image/jpeg;base64,", "");
  //         var resumeimage = b64toBlob(info, 'image/jpg');
  //         const formData = new FormData();
  //         formData.append("RowId", JSON.stringify(0));
  //         formData.append("ResumeCandidateMapId", JSON.stringify(0));
  //         formData.append("ResumeTemplateId", JSON.stringify(props.resumeTemplateID));
  //         formData.append("CandidateId", JSON.stringify(props.candidateId));
  //         formData.append("Resume", htmlContent);
  //         formData.append("ResumeName", resumeName);
  //         // formData.append("ResumeFile", resumeName + ".pdf");
  //         // formData.append("ResumeFileDocument", resumepdf, resumeName + ".pdf");
  //         formData.append("ResumeFile", resumeName + ".docx");
  //         formData.append("ResumeFileDocument", data, resumeName + ".docx");
  //         formData.append("ResumeImage", resumeName + ".jpg");
  //         formData.append("ResumeImageDocument", resumeimage, resumeName + ".jpg");
  //         formData.append("IsActive", JSON.stringify(true));
  //         ////console.log(formData)
  //         saveResumeCandidateMap(formData).then((res) => {
  //           ////console.log(res.data);
  //           globalSpinner.hideSpinner();
  //           if (res.data.isSuccess) {
  //             setIsOpen(false);
  //             toast.success(res.data.message);

  //             setTimeout(() =>
  //               history.push({
  //                 pathname: '/candidate/my-resume',
  //               }), 3000);
  //           }
  //           else {
  //             toast.error(res.data.message);
  //           }
  //         }).catch((err) => {
  //           ////console.log(err);
  //           globalSpinner.hideSpinner();
  //           toast.error(err.toString());
  //         });
  //       });
  //     });
  //     //});

  //   }
  //   else {
  //     globalSpinner.hideSpinner();
  //     toast.error("Unable to save,Please try again.");
  //   }

  // };

  // const saveTemplateWithName = (resumeName: any) => {
  //   let input = document.getElementById('pdfdiv');
  //   globalSpinner.showSpinner();
  //   if (input) {
  //     const html2pdf = require('html2pdf.js');
  //     var opt = {
  //       margin: [0.5, 0, 0.5, 0],//[top, left, bottom, right],
  //       //filename: resumeName+'.pdf',
  //       image: { type: 'jpg', quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape', compress: true },
  //     };
  //     let htmlContent = input.innerHTML
  //     //html2pdf().from(input).toPdf().output('datauristring').then(function (pdfAsString: any) {
  //     html2pdf().set(opt).from(input).outputPdf('datauristring').then(function (pdfAsString: any) {
  //       var b64toBlob = require('b64-to-blob');
  //       var info = pdfAsString.replace("data:application/pdf;filename=generated.pdf;base64,", "");
  //       var resumepdf = b64toBlob(info, 'application/pdf');
  //       html2pdf().from(input).outputImg('datauristring').then(function (imgAsString: any) {
  //         var info = imgAsString.replace("data:image/jpeg;base64,", "");
  //         var resumeimage = b64toBlob(info, 'image/jpg');
  //         ////console.log(resumeimage)
  //         const formData = new FormData();
  //         formData.append("RowId", JSON.stringify(0));
  //         formData.append("ResumeCandidateMapId", JSON.stringify(0));
  //         formData.append("ResumeTemplateId", JSON.stringify(props.resumeTemplateID));
  //         formData.append("CandidateId", JSON.stringify(props.candidateId));
  //         formData.append("Resume", htmlContent);
  //         formData.append("ResumeName", resumeName);
  //         formData.append("ResumeFile", resumeName + ".pdf");
  //         formData.append("ResumeFileDocument", resumepdf, resumeName + ".pdf");
  //         formData.append("ResumeImage", resumeName + ".jpg");
  //         formData.append("ResumeImageDocument", resumeimage, resumeName + ".jpg");
  //         formData.append("IsActive", JSON.stringify(true));
  //         ////console.log(formData)
  //         // saveResumeCandidateMap(formData).then((res) => {
  //         //   //console.log(res.data);
  //         //   globalSpinner.hideSpinner();
  //         //   if (res.data.isSuccess) {
  //         //     history.push({
  //         //       pathname: '/candidate/my-resume',
  //         //     });
  //         //     toast.success(res.data.message);

  //         //   }
  //         //   else {
  //         //     toast.error(res.data.message);
  //         //   }
  //         // }).catch((err) => {
  //         //   //console.log(err);
  //         //   globalSpinner.hideSpinner();
  //         //   toast.error(err.toString());
  //         // });
  //       });
  //     });

  //   }
  //   else {
  //     globalSpinner.hideSpinner();
  //     toast.error("Unable to save,Please try again.");
  //   }

  // };
  // const saveTemplateWithName = (resumeName: any) => {
  //   let input = document.getElementById('pdfdiv');
  //   globalSpinner.showSpinner();
  //   if (input) {

  //     const divHeight = input.clientHeight;
  //     const divWidth = input.clientWidth;
  //     const ratio = divHeight / divWidth;
  //     let _pdfData: objectIndexing = {};
  //     _pdfData["Resume"] = input.innerHTML;
  //     html2canvas(input,
  //       { height: divHeight, width: divWidth, logging: true, useCORS: true, allowTaint: true, scale: 2 }
  //     ).then((canvas) => {
  //       // const componentWidth = divWidth;
  //       // const componentHeight = divHeight;
  //       // const orientation = componentWidth >= componentHeight ? 'l' : 'p';
  //       //let imgData = canvas.toDataURL('image/png');
  //       // const pdf = new jsPDF({
  //       //   orientation,
  //       //   unit: 'mm', //'px',
  //       //   format: 'a4'
  //       // })
  //       // pdf.internal.pageSize.width = componentWidth
  //       // pdf.internal.pageSize.height = componentHeight
  //       //pdf.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
  //       let imgData = canvas.toDataURL("image/jpeg");
  //       const pdf = new jsPDF(); // using defaults: orientation=portrait, unit=mm, size=A4
  //       var width = pdf.internal.pageSize.getWidth();
  //       var height = pdf.internal.pageSize.getHeight();
  //       // //console.log(divHeight, divWidth);
  //       // //console.log(height, width);
  //       height = ratio * width;
  //       // //console.log(height);
  //       // //console.log(ratio);
  //       //pdf.addImage(imgData, 'JPEG', 0, 0, width - 3, height - 30);
  //       let sub = 10;
  //       if (ratio > 1.5 && ratio < 2) {
  //         sub = 50  //tested 1.6
  //       }
  //       else if (ratio > 1 && ratio < 1.5) {
  //         sub = 20 //tested 1.3
  //       }
  //       pdf.addImage(imgData, 'JPEG', 0, 0, width - 3, height - sub);
  //       var b64toBlob = require('b64-to-blob');
  //       // var info = imgData.replace("data:image/png;base64,", "");
  //       // var blob = b64toBlob(info, 'image/png');
  //       var info = imgData.replace("data:image/jpeg;base64,", "");
  //       var blob = b64toBlob(info, 'image/jpg');
  //       _pdfData["ResumeImageDocument"] = blob;
  //       var binary = pdf.output();
  //       // pdf.save('myPage.pdf');
  //       // binary = binary ? btoa(binary) : "";
  //       var blob2 = b64toBlob(btoa(binary), 'application/pdf');
  //       _pdfData["ResumeFileDocument"] = blob2;
  //       // var FileSaver = require('file-saver');
  //       // FileSaver.saveAs(blob, "1.png");
  //       // FileSaver.saveAs(blob2, "blob2.pdf");
  //       const formData = new FormData();
  //       formData.append("RowId", JSON.stringify(0));
  //       formData.append("ResumeCandidateMapId", JSON.stringify(0));
  //       formData.append("ResumeTemplateId", JSON.stringify(props.resumeTemplateID));
  //       formData.append("CandidateId", JSON.stringify(props.candidateId));
  //       formData.append("Resume", _pdfData["Resume"]);
  //       formData.append("ResumeName", resumeName);
  //       formData.append("ResumeFile", resumeName + ".pdf");
  //       formData.append("ResumeFileDocument", _pdfData["ResumeFileDocument"], resumeName + ".pdf");
  //       formData.append("ResumeImage", resumeName + ".jpg");
  //       formData.append("ResumeImageDocument", _pdfData["ResumeImageDocument"], resumeName + ".jpg");
  //       formData.append("IsActive", JSON.stringify(true));
  //       // //console.log(formData);
  //       // for (var key of (formData as any).entries()) {
  //       //   //console.log(key[0] + ", " + key[1]);
  //       // }
  //       saveResumeCandidateMap(formData).then((res) => {
  //         //console.log(res.data);
  //         globalSpinner.hideSpinner();
  //         if (res.data.isSuccess) {
  //           toast.success(res.data.message);
  //         }
  //         else {
  //           toast.error(res.data.message);
  //         }
  //       }).catch((err) => {
  //         //console.log(err);
  //         globalSpinner.hideSpinner();
  //         toast.error(err.toString());
  //       });

  //     })
  //   }
  //   else {
  //     globalSpinner.hideSpinner();
  //     toast.error("Unable to save,Please try again.");
  //   }
  // };
  useEffect(() => {
    if (props.resumeInfo.candidateInfo) {
      ////console.log(8000, props.resumeInfo.candidateInfo[0].passportInformationId)
      if (
        props.resumeInfo.candidateInfo[0].passportInformationId != undefined &&
        props.resumeInfo.candidateInfo[0].passportInformationId > 0
      ) {
        getOnePassportInformation({
          rowId: Number(
            props.resumeInfo.candidateInfo[0].passportInformationId
          ),
        })
          .then((res: any) => {
            setOnePassportInformation(res.data);
          })
          .catch((err: any) => {
            // //console.log(err);
          });
      }
    }
  }, [props.resumeInfo.candidateInfo]);
  return (
    <>
      <ToastContainer />
      <div className="content-wrapper">
        <div className="container-fluid">
          <button
            className="btn cutomise_but"
            disabled={props.resumeTemplateID == 0 ? true : false}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {" "}
            Save Resume
          </button>
          <button
            className="btn cutomise_but"
            onClick={() => {
              handleReselectTemplate();
            }}
          >
            {" "}
            Reselect Template
          </button>
          <div className="clearfix" />
          <PreviewHtmlTemplate
            resumeInfo={props.resumeInfo}
            resumeTemplateID={props.resumeTemplateID}
            resumeTemplateHtml={props.resumeTemplateHtml}
            onePassportInformation={onePassportInformation}
          />
        </div>
        <div className="clearfix" />
      </div>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <SaveCandidateResume
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          saveTemplateWithName={saveTemplateWithName}
        />
      </Modal>
    </>
  );
};
export default ResumePreviewComponent;
