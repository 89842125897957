import imageCompression from "browser-image-compression";
import React from "react";

class FileUploads extends React.Component<
  {
    onChange: (a: any) => void;
    accept: string;
    name: string;
    disabled: boolean;
  },
  { file: {} }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      file: {},
    };
  }

  // handleCompressedImage = async (file: File) => {
  //   //console.log(888, file);
  //   if (["image/jpeg"].includes(file.type)) {
  //     const imageFile = file;
  //     //console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
  //     //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  //     const options = {
  //       maxSizeMB: 1,
  //       maxWidthOrHeight: 1920,
  //       useWebWorker: true,
  //     };
  //     try {
  //       const compressedFile = await imageCompression(imageFile, options);
  //       //console.log("compressedFile instanceof Blob", compressedFile); // true
  //       //console.log(
  //         `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
  //       ); // smaller than maxSizeMB

  //       // await uploadToServer(compressedFile); // write your own logic

  //       return compressedFile;
  //     } catch (error) {
  //       //console.log(error);
  //     }
  //   } else {
  //     return file;
  //   }
  // };

  render() {
    return (
      <div className="box1">
        <input
          disabled={this.props.disabled}
          className={`inputfile ${
            this.props.disabled ? "inputfile-101" : "inputfile-1"
          }  _cursor-pointer`}
          style={{
            opacity: "0",
            position: "absolute",
            width: "100%",
            height: "50px",
          }}
          type="file"
          accept={this.props.accept}
          onChange={(event: any) => {
            this.props.onChange(event.target.files[0]);
          }}
          // onChange={(event: any) => {
          //   this.props.onChange(
          //     this.handleCompressedImage(event.target.files[0])
          //   );
          // }}
          onClick={(event: any) => {
            event.target.value = null;
          }}
        />
        <label
          htmlFor={`${this.props.disabled ? "file-101" : "file-1"}`}
          style={{}}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 20 17"
          >
            <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
          </svg>
          <span>{this.props.name}</span>
        </label>
      </div>
    );
  }
}

export default FileUploads;
