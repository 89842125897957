import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import SelectOption from "../../my_profile/components/SelectOption";
import PikadayDatepicker from "../../my_profile/components/PikadayDatepicker";
import SelectMulti from "../../my_profile/components/SelectMulti";
import _ from "lodash";
import { ErrorMessage } from "@hookform/error-message";
import { toast, ToastContainer } from "react-toastify";
import { GlobalSpinnerContext } from "../../../../context/GlobalSpinner";
import {
  saveCandidate,
  saveCandidateLanguageMaps,
} from "../../../../apis/candidate";
import IsComponentDisabled from "../../../components/IsComponentDisabled";

type objectIndexing = {
  [key: string]: any;
};

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IPersonelInfoState {
  rowId: string;
  name: string;
  dob: number;
  nationality: string;
  position: string;
  gender: string;
  maritalStatus: string;
  languages: string[];
  summary: string;
}

function PersonelInfo(props: any) {
  const {
    myProfile,
    candidateId,
    candidateState,
    candidateLanguageMaps,
    getCandidateLanguageMaps,
    getCandidates,
  } = props;

  let genders: ReactSelectOption[] = [];

  let maritalStatuss: ReactSelectOption[] = [];

  let nationalities: ReactSelectOption[] = [];

  let languageOpts: ReactSelectOption[] = [];

  let positions: ReactSelectOption[] = [];

  if (!_.isEmpty(myProfile)) {
    languageOpts = myProfile.languages.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });

    genders = myProfile.genders.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });

    maritalStatuss = myProfile.martialStatus.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });

    nationalities = myProfile.countries.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });

    // positions = myProfile.jobRoles.map((e: any) => {
    //   return { value: e["value"], label: e["caption"] };
    // });
  }

  let defaultValues = {
    rowId: "0",
    name: "",
    dob: 0,
    nationality: "",
    position: "",
    gender: "",
    maritalStatus: "",
    languages: [],
    summary: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<IPersonelInfoState>({
    defaultValues,
  });

  useEffect(() => {
    if (candidateState.data.length > 0) {
      setValue("rowId", candidateState.data[0]["rowId"]);
      setValue("name", candidateState.data[0]["firstName"]);
      setValue(
        "dob",
        moment(candidateState.data[0]["dob"], "DD/MM/YYYY").toDate().getTime()
      );
      setValue("nationality", candidateState.data[0]["countryId"] + "");
      setValue(
        "position",
        candidateState.data[0]["positionApplyingFor"]
          ? candidateState.data[0]["positionApplyingFor"]
          : ""
      );
      setValue("gender", candidateState.data[0]["genderId"] + "");
      setValue("maritalStatus", candidateState.data[0]["martialStatusId"]);
      setValue(
        "languages",
        candidateLanguageMaps.map((e: any) => `${e["languageId"]}`)
      );
      //setValue("summary", candidateState.data[0]["profileSummary"] + "");
      setValue(
        "summary",
        candidateState.data[0]["profileSummary"] == null
          ? ""
          : candidateState.data[0]["profileSummary"] + ""
      );
    }
  }, [reset, candidateState.data.length, candidateLanguageMaps.length]);

  const globalSpinner = useContext(GlobalSpinnerContext);
  const onSubmit = (data: any) => {
    //console.log(data);
    if (!disabled) {
      let _data1: objectIndexing = {};

      if (candidateState.data.length > 0) {
        _data1 = candidateState.data[0];
        _data1["rowId"] = data["rowId"];
        _data1["candidateId"] = Number(candidateId);
        _data1["dob"] = moment(new Date(data["dob"])).format("DD/MM/YYYY");
        _data1["genderId"] = data["gender"];
        _data1["positionApplyingFor"] = data["position"];
        //_data1["martialStatusId"] = data["maritalStatus"];
        _data1["martialStatusId"] =
          data["maritalStatus"] != null
            ? data["maritalStatus"].includes("sel")
              ? null
              : data["maritalStatus"]
            : data["maritalStatus"];
        _data1["firstName"] = _.startCase(_.toLower(data["name"]));
        _data1["CountryId"] = Number(data["nationality"]);
        _data1["ProfileSummary"] = data["summary"];
        const _lans = data.languages.map((e: any) => Number(e)) || [];

        globalSpinner.showSpinner();

        saveCandidateLanguageMaps({
          rowId: 0,
          candidateLanguageMapId: 0,
          candidateId: Number(candidateId),
          languageId: _lans,
          IsActive: true,
        })
          .then((res: any) => {
            if (res.data.isSuccess) {
              saveCandidate(_data1)
                .then((res: any) => {
                  //console.log(res.data);
                  globalSpinner.hideSpinner();
                  if (res.data.isSuccess) {
                    getCandidates({
                      CandidateId: Number(candidateId),
                      Page: 1,
                      PageSize: 10,
                      SearchTerm: "string",
                      SortOrder: "string",
                      ShowInactive: false,
                    });
                    getCandidateLanguageMaps({
                      CandidateId: Number(candidateId),
                      Page: 1,
                      PageSize: 10,
                      SearchTerm: "string",
                      SortOrder: "string",
                      ShowInactive: false,
                    });
                    toast.success("Personal Info details are saved.");
                  } else {
                    //console.log(res.data);
                    globalSpinner.hideSpinner();
                    toast.error(res.data.message);
                  }
                })
                .catch((err: any) => {
                  //console.log(err);
                  globalSpinner.hideSpinner();
                  toast.error(err.toString());
                });
            } else {
              //console.log(res.data);
              globalSpinner.hideSpinner();
              toast.error(res.data.message);
            }
          })
          .catch((err: any) => {
            //console.log(err);
            globalSpinner.hideSpinner();
            toast.error(err.toString());
          });
      }
    }
  };

  const [disabled, setDisabled] = useState<boolean>(true);
  return (
    <React.Fragment>
      {/*---------Start-------------------*/}
      <ToastContainer />
      <h2
        className="resp-accordion resp-tab-active"
        role="tab"
        aria-controls="tab_item-0"
      >
        <span className="resp-arrow" />
        Personal Info
      </h2>
      <div
        className="resp-tab-content resp-tab-content-active"
        aria-labelledby="tab_item-0"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Personal Info</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div>
          <form
            className="personal_details"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <input
              type="number"
              name="rowId"
              style={{ display: "none" }}
              disabled={disabled}
              ref={register}
            />
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Name</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <input
                        type="text"
                        name="name"
                        className={"form-control"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          required: "Name is required.",
                          pattern: {
                            value: /^[a-zA-Z ]*$/,
                            message:
                              "Name must have words only with spaces between them.",
                          },
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Gender</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <span className="select-wrapper-sec">
                        <Controller
                          rules={{
                            // validate: (value) => {
                            //   return value < 1
                            //     ? "Gender is required"
                            //     : undefined;
                            // },
                            validate: function (value) {
                              return Number(value)
                                ? undefined
                                : "Gender is required";
                            },
                          }}
                          control={control}
                          name="gender"
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={genders}
                              disabled={disabled}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="gender"
                      render={({ message }) => (
                        <div className="profile_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> DOB (dd/mm/yyyy)</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <Controller
                        rules={{
                          validate: (value) => {
                            ////console.log("Hello");
                            return value < Date.now()
                              ? undefined
                              : "DOB cannot be a future date.";
                          },
                        }}
                        control={control}
                        name="dob"
                        render={({ onChange, onBlur, value, name }) => {
                          // //console.log(value);
                          return (
                            <PikadayDatepicker
                              disabled={disabled}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          );
                        }}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="dob"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label> Marital Status</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <span className="select-wrapper-sec">
                        <Controller
                          // rules={{
                          //   validate: (value) => {
                          //     return value < 1
                          //       ? "Marital Status is required"
                          //       : undefined;
                          //   },
                          // }}
                          // rules={{
                          //   validate: function (value) {
                          //     return Number(value)
                          //       ? undefined
                          //       : "Marital Status is required";
                          //   },
                          // }}
                          control={control}
                          name="maritalStatus"
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={maritalStatuss}
                              disabled={disabled}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="maritalStatus"
                      render={({ message }) => (
                        <div className="profile_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Country</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <span className="select-wrapper-sec">
                        <Controller
                          // rules={{
                          //   validate: (value) => {
                          //     return value < 1
                          //       ? "Country is required"
                          //       : undefined;
                          //   },
                          // }}
                          rules={{
                            validate: function (value) {
                              return Number(value)
                                ? undefined
                                : "Country is required";
                            },
                          }}
                          control={control}
                          name="nationality"
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={nationalities}
                              disabled={disabled}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="nationality"
                      render={({ message }) => (
                        <div className="profile_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Languages Known</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <Controller
                        rules={{
                          validate: (value) => {
                            return value.length === 0
                              ? "Languages Known is required"
                              : undefined;
                          },
                        }}
                        control={control}
                        name="languages"
                        render={({ onChange, onBlur, value, name }) => {
                          // //console.log(value);
                          return (
                            <SelectMulti
                              name={name}
                              values={languageOpts}
                              disabled={disabled}
                              value={value ? value : []}
                              onChange={onChange}
                            />
                          );
                        }}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="languages"
                      render={({ message }) => (
                        <div className="profile_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Job Title</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <input
                        type="text"
                        name="position"
                        className={"form-control"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          required: "Job Title is required",
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="position"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}>
                      {" "}
                      Profile Summary/Objective
                    </label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <textarea
                        name="summary"
                        className={"form-control form-contro11"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          required:
                            "Profile Summary/Objective cannot be blank.",
                          validate: (value) => {
                            //exclude  start and end white-space
                            value = value.replace(/(^\s*)|(\s*$)/gi, "");
                            //convert 2 or more spaces to 1
                            value = value.replace(/[ ]{2,}/gi, " ");
                            // exclude newline with a start spacing
                            value = value.replace(/\n /, "\n");

                            if (value.split(" ").length < 15) {
                              return "Minimum 15 words are required in responsibilities.";
                            } else if (value.split(" ").length > 40) {
                              return "Maximum words: 40";
                            } else {
                              return undefined;
                            }
                          },
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="summary"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix" />
            <button
              type="submit"
              className="btn submit_btn"
              disabled={disabled}
            >
              Save
            </button>
          </form>
        </div>
      </div>
      {/*---------End-------------------*/}
    </React.Fragment>
  );
}

export default PersonelInfo;
