import { ErrorMessage } from "@hookform/error-message";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  getDigiLockerList,
  useDigiLockerContext,
  useDigiLockerDispatcher,
} from "../../../action/candidate/DigiLockerAction";
import {
  saveJobApplied,
  useJobAppliedDispatcher,
} from "../../../action/JobAppliedAction";
import { digiLockerDocumentRequestModel } from "../../../models/candidate/DigiLocker";
import { ResumeAllList } from "../../../models/candidate/Resume";
import FileUploads from "../my_profile/components/FileUploads";
import SelectOption from "../my_profile/components/SelectOption";
import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox, Spin } from "antd";
import { CoverletterDataResult } from "../../../models/candidate/CoverLetter";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "green" }} spin />
);
interface IJobAppliedFormProps {
  isJobAppliedOpen: boolean;
  handleJobOpen: () => void;
  currentJobId: number;
  loggedUserId: number;
  authorizationToken: string | null;
  candidateResumes: ResumeAllList;
  candidateCoverLetters: CoverletterDataResult;
}

const defaultValues = {
  selectResume: "",
  coverLetter: "",
};

const JobAppliedForm: React.FC<IJobAppliedFormProps> = (props) => {
  const {
    handleJobOpen,
    isJobAppliedOpen,
    currentJobId,
    loggedUserId,
    authorizationToken,
    candidateResumes,
    candidateCoverLetters,
  } = props;
  const [obj, setObj] = useState<any>({ file: null });
  const [obj2, setObj2] = useState<any>({ file: null });
  const [disable, setDisable] = useState<number>(0);
  const [disable2, setDisable2] = useState<number>(0);
  const [selectResumeValue, setSelectResumeValue] = useState<number>(0);
  const [selectCoverLetterValue, setSelectCoverLetterValue] = useState<number>(
    0
  );
  const [disable3, setDisable3] = useState<string>("");
  const [disable4, setDisable4] = useState<string>("");
  const [filename1, setFileName1] = useState("");
  const [filename2, setFileName2] = useState("");
  const digiLockerMarkDispatcher = useDigiLockerDispatcher();
  const digiLockerMarkContext = useDigiLockerContext();
  const { digiLockerType, digiLockers } = digiLockerMarkContext;
  const [isLoder, setIsLoader] = useState(false);

  const [selfIntroChecked, setSelfIntroChecked] = useState(false);

  const jobAppliedDispatcher = useJobAppliedDispatcher();

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  const SelectCoverLetter = [{}];
  //console.log(960, candidateResumes);
  const watchAllFields = watch3();

  React.useEffect(() => {
    //console.log(221, watchAllFields.selectResume);
    if (watchAllFields.selectResume !== "") {
      //console.log(220, "rrrrrrrrrrrrrrrrrrrr");
      setDisable3(watchAllFields.selectResume);
      setSelectResumeValue(0);
      setFileName1("");
    }
  }, [watchAllFields.selectResume]);

  React.useEffect(() => {
    //console.log(321, "sssssssssssssssssss");
    if (watchAllFields.coverLetter !== "") {
      //console.log(320, "sssssssssssssssssss");

      setSelectCoverLetterValue(0);
      setFileName2("");
      setDisable4(watchAllFields.coverLetter);
    }
  }, [watchAllFields.coverLetter]);

  const handleDrop = (files: any) => {
    setValue3("selectResume", "");
    if (
      ["image/jpeg", "application/pdf"].includes(files[0].type) &&
      files[0].size < 1000000 * 5
    ) {
      setDisable(1);
      setSelectResumeValue(1);

      //console.log(files[0]);
      setFileName1(files[0].name);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
    }
  };

  const handleDrop2 = (files: any) => {
    if (
      ["image/jpeg", "application/pdf"].includes(files[0].type) &&
      files[0].size < 1000000 * 5
    ) {
      setSelectCoverLetterValue(1);
      setValue3("coverLetter", "");
      //console.log(files[0]);
      setDisable2(1);
      if (files.length > 0) {
        setObj2({
          ...obj2,
          ["file"]: files[0],
        });
        setFileName2(files[0].name);
      }
    }
  };

  React.useEffect(() => {
    if (loggedUserId != 0 && authorizationToken != null) {
      (async () => {
        await getDigiLockerList(
          digiLockerMarkDispatcher,
          {
            candidateId: loggedUserId,
            digiDocumentTypeId: 0,
            pageIndex: 1,
            pageSize: 10,
            showInactive: false,
          } as digiLockerDocumentRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  const handleJobApplied = (dataResult: any) => {
    setIsLoader(true);
    console.log(33, dataResult);
    if (authorizationToken != null) {
      const formData = new FormData();
      const data = {
        RowId: 0,
        JobAppliedDetailsId: 0,
        CandidateId: loggedUserId,
        JobId: currentJobId,
        ResumeCandidateMapId:
          dataResult.selectResume === ""
            ? null
            : selectResumeValue === 0
            ? parseInt(dataResult.selectResume)
            : null,
        CoverLetterCandidateMapId:
          dataResult.coverLetter === ""
            ? 0
            : selectCoverLetterValue === 0
            ? parseInt(dataResult.coverLetter)
            : 0,
        // ResumeCandidateMapId: 3,
        // CoverLetterCandidateMapId: 1,
        ResumeDocument: null,
        ResumeDocumentFile: selectResumeValue === 1 ? obj.file : null,
        CoverLetterDocument: null,
        CoverLetterDocumentFile:
          selectCoverLetterValue === 1 ? obj2.file : null,
        IsActive: true,
        Videoflag: dataResult.radio5,
        JobAppliedDigiDocMapPostmodel: dataResult.radio,
      };

      formData.append(
        "JobAppliedDetailsPostmodel.RowId",
        JSON.stringify(data.RowId)
      );
      formData.append(
        "JobAppliedDetailsPostmodel.JobAppliedDetailsId",
        JSON.stringify(data.JobAppliedDetailsId)
      );
      formData.append(
        "JobAppliedDetailsPostmodel.CandidateId",
        JSON.stringify(data.CandidateId)
      );
      formData.append(
        "JobAppliedDetailsPostmodel.ResumeCandidateMapId",
        JSON.stringify(
          data.ResumeCandidateMapId == null ? 0 : data.ResumeCandidateMapId
        )
      );
      formData.append(
        "JobAppliedDetailsPostmodel.CoverLetterCandidateMapId",
        JSON.stringify(data.CoverLetterCandidateMapId)
      );
      formData.append(
        "JobAppliedDetailsPostmodel.JobId",
        JSON.stringify(data.JobId)
      );
      formData.append(
        "JobAppliedDetailsPostmodel.ResumeDocument",
        JSON.stringify(data.ResumeDocument)
      );
      formData.append(
        "JobAppliedDetailsPostmodel.ResumeDocumentFile",
        data.ResumeDocumentFile
      );

      formData.append(
        "JobAppliedDetailsPostmodel.CoverLetterDocument",
        JSON.stringify(data.CoverLetterDocument)
      );
      formData.append(
        "JobAppliedDetailsPostmodel.CoverLetterDocumentFile",
        data.CoverLetterDocumentFile
      );
      formData.append(
        "JobAppliedDetailsPostmodel.IsActive",
        JSON.stringify(data.IsActive)
      );

      formData.append(
        "JobAppliedDetailsPostmodel.Videoflag",
        JSON.stringify(data.Videoflag)
      );

      formData.append(
        "JobAppliedDetailsPostmodel.JobstatusId",
        JSON.stringify(1)
      );

      // formData.append(
      //   "JobAppliedDigiDocMapPostmodel",
      //   JSON.stringify(data.JobAppliedDigiDocMapPostmodel)
      // );

      //console.log(55555, dataResult.radio);
      if (dataResult.radio != undefined) {
        dataResult.radio.forEach((element: any, i: any) => {
          formData.append(
            `JobAppliedDigiDocMapPostmodel[${i}].RowId`,
            JSON.stringify(0)
          );

          formData.append(
            `JobAppliedDigiDocMapPostmodel[${i}].JobAppliedDigiDocMapId`,
            JSON.stringify(0)
          );

          formData.append(
            `JobAppliedDigiDocMapPostmodel[${i}].JobAppliedDetailsId`,
            JSON.stringify(data.JobId)
          );
          formData.append(
            `JobAppliedDigiDocMapPostmodel[${i}].DigiDocumentDetailId`,
            JSON.stringify(parseInt(element))
          );
          formData.append(
            `JobAppliedDigiDocMapPostmodel[${i}].IsActive`,
            JSON.stringify(true)
          );
        });
      }
      //console.log(112, formData);

      for (var key of (formData as any).entries()) {
        //console.log(key[0] + ", " + key[1]);
      }

      saveJobApplied(jobAppliedDispatcher, formData, authorizationToken);
      setIsLoader(false);
      setFileName1("");
      setFileName2("");
      setSelectResumeValue(0);
      setSelectCoverLetterValue(0);
      handleClose();
    }
  };

  const handleClose = () => {
    setDisable(0);
    setDisable2(0);
    setSelectResumeValue(0);
    setSelectCoverLetterValue(0);
    setDisable3("");
    handleJobOpen();
    setFileName1("");
    setFileName2("");
    setSelfIntroChecked(false);
  };

  function onChangeCheck(e: any) {
    console.log(`checked = ${e.target.checked}`);
    setSelfIntroChecked(e.target.checked);
  }

  return (
    <>
      <Modal show={isJobAppliedOpen} onHide={handleClose}>
        <form onSubmit={handleSubmit3(handleJobApplied)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title> Apply Now</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-6">
                <label htmlFor="email" className="required">
                  Select Resume
                </label>
                <span className="select-wrapper-sec">
                  <Controller
                    control={control3}
                    rules={{
                      required:
                        disable == 1
                          ? false
                          : "Select Resume is a required field",
                    }}
                    name="selectResume"
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={
                          candidateResumes.data != undefined
                            ? candidateResumes.data.map((e: any) => {
                                return {
                                  value: e["rowId"],
                                  label: e["resumeName"],
                                };
                              })
                            : [{}]
                        }
                        // disabled={disable == 1 ? true : false}
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        // value={value}
                        value={selectResumeValue == 1 ? "0" : value}
                        name={name}
                      />
                    )}
                  />
                </span>
                <ErrorMessage
                  errors={errors3}
                  name="selectResume"
                  render={({ message }) => (
                    <div className="register_validation1">{message}</div>
                  )}
                />
              </div>

              <div className="col-sm-6">
                <div className="box1 m_t_35">
                  <FileUploads
                    accept=".pdf,.jpg,.jpeg"
                    onChange={(file: any) => {
                      handleDrop([file]);
                    }}
                    // name={filename1 === "" ? "Upload Resume" : filename1}
                    name="Upload Resume"
                    // disabled={disable3 !== "" ? true : false}
                    disabled={false}
                  />
                </div>
                {filename1}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label htmlFor="email">Select Cover Letter</label>
                <span className="select-wrapper-sec">
                  <Controller
                    control={control3}
                    // rules={{
                    //   required: "Select Cover Letter is a required field",
                    // }}
                    name="coverLetter"
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={
                          candidateCoverLetters.data != undefined
                            ? candidateCoverLetters.data.map((e: any) => {
                                return {
                                  value: e["rowId"],
                                  label: e["coverLetterName"],
                                };
                              })
                            : [{}]
                        }
                        disabled={false}
                        // disabled={disable2 == 1 ? true : false}
                        onChange={onChange}
                        onBlur={onBlur}
                        // value={value}
                        value={selectCoverLetterValue == 1 ? "0" : value}
                        name={name}
                      />
                    )}
                  />
                </span>
                <ErrorMessage
                  errors={errors3}
                  name="coverLetter"
                  render={({ message }) => (
                    <div className="register_validation1">{message}</div>
                  )}
                />
              </div>
              <div className="col-sm-6">
                <div className="box1 m_t_35">
                  <FileUploads
                    accept=".pdf,.jpg,.jpeg"
                    onChange={(file: any) => {
                      handleDrop2([file]);
                    }}
                    name="Upload Cover Letter"
                    // disabled={disable4 !== "" ? true : false}
                    disabled={false}
                  />
                </div>
                {filename2}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <h1 className="modal_headings">General Document </h1>
                {digiLockerType.data != undefined &&
                digiLockers.data != undefined
                  ? digiLockers.data
                      .filter(
                        (data) =>
                          data.digiDocumentTypeId ===
                          digiLockerType.data[0].rowId
                      )
                      .map((data, i) => {
                        return (
                          <div>
                            <input
                              id=""
                              type="checkbox"
                              name="radio"
                              defaultValue={`${data.rowId}`}
                              ref={register3}
                            />
                            <label htmlFor="checkbox">
                              <span>
                                <span />
                              </span>
                              {data.name}
                            </label>
                          </div>
                        );
                      })
                  : null}
              </div>
              <div className="col-sm-6">
                <h1 className="modal_headings">Pesonal Document </h1>
                {digiLockerType.data != undefined &&
                digiLockers.data != undefined
                  ? digiLockers.data
                      .filter(
                        (data) =>
                          data.digiDocumentTypeId ===
                          digiLockerType.data[1].rowId
                      )
                      .map((data, i) => {
                        return (
                          <div>
                            <input
                              id=""
                              type="checkbox"
                              name="radio"
                              defaultValue={`${data.rowId}`}
                              ref={register3({
                                required: false,
                              })}
                            />
                            <label htmlFor="checkbox">
                              <span>
                                <span />
                              </span>
                              {data.name}
                            </label>
                          </div>
                        );
                      })
                  : null}
              </div>
              <ErrorMessage
                errors={errors3}
                name="radio"
                render={({ message }) => (
                  <div className="register_validation1">{message}</div>
                )}
              />
            </div>
            <div className="row" style={{ padding: 12 }}>
              <h1 className="modal_headings"> Video</h1>
              <div>
                <input
                  id=""
                  type="checkbox"
                  name="radio5"
                  // defaultValue={`${data.rowId}`}
                  ref={register3({
                    required: false,
                  })}
                />
                <label htmlFor="checkbox" style={{ color: "brown" }}>
                  <span>
                    <span />
                  </span>
                  Add Self Intro video
                </label>
              </div>
              {/* <Checkbox onChange={onChangeCheck}>Add Self Intro video</Checkbox> */}
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            {isLoder ? (
              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
              >
                <Spin indicator={antIcon} />
              </button>
            ) : (
              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
              >
                Apply Now
              </button>
            )}

            <button
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default JobAppliedForm;
