import { Dispatch, Reducer } from "react";

import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";

import { AppUrls, environment } from "../../environments/environment";

import { message } from "antd";
import {
  permisionRequestModel,
  PermissionResultModel,
} from "../../models/admin/AdminMyJob";
import {
  AdminCandidateDetailsRespondModel,
  AdminCandidateSearchDetailsRequestModel,
  AdminCandidateSearchRequestModel,
  AdminCandidateSearchRespondModel,
  AdminSaveSearchRequestModel,
  AdminSaveSearchResultModel,
  GetAdminSaveSearchResultModel,
  GetAdminSelectBoxJobDataRequest,
  GetAdminSelectBoxJobDataResultModel,
  GetSaveSearchAdminListRequestModel,
  RemoveAdminSavedSearchCandidateModel,
  SendEmailOrSmsRequestModel,
  SendEmailOrSmsResultModel,
  SetAdminCandidateResultModel,
  SetAdminCandidateSaveRequestModel,
} from "../../models/admin/AdminCandidateSearch";
import {
  AdminCandidateSearchrDispatcherContext,
  AdminCandidateSearchrStateContext,
  IAdminCandidateSearchrManagementState,
} from "../../context/admin/AdminCandidateSearchContext";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IAdminCandidateSearchrAction =
  | {
      type: "SEARCH_ADMIN_CANDIDATE";
      adminCandidateSearchResult: AdminCandidateSearchRespondModel;
    }
  | {
      type: "SEARCH_ADMIN_CANDIDATE_DETAILS";
      adminCandidateSearchResultDetails: AdminCandidateDetailsRespondModel;
    }
  | {
      type: "SAVE_ADMIN_CANDIDATE_SEARCH";
      adminSaveSearchResultmodel: AdminSaveSearchResultModel;
    }
  | {
      type: "GET_ADMIN_CANDIDATE_SAVE_SEARCH";
      getAdminSaveSearchResultmodel: GetAdminSaveSearchResultModel;
    }
  | {
      type: "GET_ADMIN_CANDIDATE_SELECT_BOX_JOBS";
      getAdminJobSelectBoxData: GetAdminSelectBoxJobDataResultModel;
    }
  | {
      type: "SET_ADMIN_CANDIDTE_SAVE_SEARCH";
      setAdminCandidateResultModel: SetAdminCandidateResultModel;
    }
  | {
      type: "REMOVE_ADMIN_CANDIDATE_SEARCH";
      removeAdminSavedCandidateSearchResultModel: RemoveAdminSavedSearchCandidateModel;
    }
  | {
      type: "ADMIN_CANDIDATE_SEARCH_SEND_EMAIL_OR_SMS";
      sendEmailOrSmsResultData: SendEmailOrSmsResultModel;
    };

export const adminCandidateSearchrReducer: Reducer<
  IAdminCandidateSearchrManagementState,
  IAdminCandidateSearchrAction
> = (draft, action): IAdminCandidateSearchrManagementState => {
  switch (action.type) {
    case "SEARCH_ADMIN_CANDIDATE":
      draft.adminCandidateSearchResult = action.adminCandidateSearchResult;
      return draft;
    case "SEARCH_ADMIN_CANDIDATE_DETAILS":
      draft.adminCandidateSearchResultDetails =
        action.adminCandidateSearchResultDetails;
      return draft;
    case "SAVE_ADMIN_CANDIDATE_SEARCH":
      draft.adminSaveSearchResultmodel = action.adminSaveSearchResultmodel;
      return draft;
    case "GET_ADMIN_CANDIDATE_SAVE_SEARCH":
      draft.getAdminSaveSearchResultmodel =
        action.getAdminSaveSearchResultmodel;
      return draft;
    case "GET_ADMIN_CANDIDATE_SELECT_BOX_JOBS":
      draft.getAdminJobSelectBoxData = action.getAdminJobSelectBoxData;
      return draft;
    case "SET_ADMIN_CANDIDTE_SAVE_SEARCH":
      draft.setAdminCandidateResultModel = action.setAdminCandidateResultModel;
      return draft;
    case "REMOVE_ADMIN_CANDIDATE_SEARCH":
      draft.removeAdminSavedCandidateSearchResultModel =
        action.removeAdminSavedCandidateSearchResultModel;
      return draft;
    case "ADMIN_CANDIDATE_SEARCH_SEND_EMAIL_OR_SMS":
      draft.sendEmailOrSmsResultData = action.sendEmailOrSmsResultData;
      return draft;
  }
};

export const useAdminCandidateSearchrDispatcher = (): React.Dispatch<IAdminCandidateSearchrAction> => {
  const adminCandidateSearchrDispatcher = React.useContext(
    AdminCandidateSearchrDispatcherContext
  );
  if (!adminCandidateSearchrDispatcher) {
    throw new Error(
      "You have to provide the AdminCandidateSearchr dispatcher using theAdminCandidateSearchrDispatcherContext.Provider in a parent component."
    );
  }
  return adminCandidateSearchrDispatcher;
};

export const useAdminCandidateSearchrContext = (): IAdminCandidateSearchrManagementState => {
  const adminCandidateSearchrContext = React.useContext(
    AdminCandidateSearchrStateContext
  );
  if (!adminCandidateSearchrContext)
    throw new Error(
      "You have to provide the adminCandidateSearchr context using the AdminCandidateSearchrStateContext.Provider in a parent component."
    );
  return adminCandidateSearchrContext;
};

// Admin Candidate Search
export const SearchAdminCandidate = async (
  dispatcher: React.Dispatch<IAdminCandidateSearchrAction>,
  query: AdminCandidateSearchRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SearchAdminCandidate;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SEARCH_ADMIN_CANDIDATE",
        adminCandidateSearchResult: res.data,
      });
      // res.data.isSuccess
      //   ? message.success("Client created sucessfully")
      //   : message.error(res.data.message);
    });
  } catch (e) {}
};

// Admin candidate details search
export const SearchAdminCandidateDetails = async (
  dispatcher: React.Dispatch<IAdminCandidateSearchrAction>,
  query: AdminCandidateSearchDetailsRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    // const url = AppUrls.SearchAdminCandidateDetails;
    const url =
      environment.baseUrl +
      "/api/AdminSearchCandidates/GetCandidatesDetailsByIdAsync";
    message.loading("please wait..");
    //console.log(6672, url, query);
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SEARCH_ADMIN_CANDIDATE_DETAILS",
        adminCandidateSearchResultDetails: res.data,
      });
    });
  } catch (e) {}
};

// Admin candidate SaveSearch search
export const SaveAdminCandidateSearch = async (
  dispatcher: React.Dispatch<IAdminCandidateSearchrAction>,
  query: AdminSaveSearchRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    const url = AppUrls.SaveAdminCandidateSearch;

    message.loading("please wait..");
    //console.log(6672, url, query);
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SAVE_ADMIN_CANDIDATE_SEARCH",
        adminSaveSearchResultmodel: res.data,
      });
    });
  } catch (e) {}
};

// Admin candidate SaveSearch search
export const GetAdminSavedSearch = async (
  dispatcher: React.Dispatch<IAdminCandidateSearchrAction>,
  query: GetSaveSearchAdminListRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    const url = AppUrls.GetAdminCandidateSearch;

    message.loading("please wait..");
    //console.log(6672, url, query);
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ADMIN_CANDIDATE_SAVE_SEARCH",
        getAdminSaveSearchResultmodel: res.data,
      });
    });
  } catch (e) {}
};

// Get SelectBox Jobs
export const GetAdminSelectBoxJobs = async (
  dispatcher: React.Dispatch<IAdminCandidateSearchrAction>,
  query: GetAdminSelectBoxJobDataRequest,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    const url = AppUrls.GetAdminCandidateSelectBoxJobs;

    message.loading("please wait..");
    //console.log(6672, url, query);
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ADMIN_CANDIDATE_SELECT_BOX_JOBS",
        getAdminJobSelectBoxData: res.data,
      });
    });
  } catch (e) {}
};

//Set AdminCandidate Search

export const SetAdminCandidateSaveSeach = async (
  dispatcher: React.Dispatch<IAdminCandidateSearchrAction>,
  query: SetAdminCandidateSaveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    const url = AppUrls.SetAdminCandidateSearch;

    message.loading("please wait..");
    //console.log(6672, url, query);
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_ADMIN_CANDIDTE_SAVE_SEARCH",
        setAdminCandidateResultModel: res.data,
      });
    });
  } catch (e) {}
};

// remove AdminCandidateSaveSearch

export const RemoveAdminCandidateSaveSearch = async (
  dispatcher: React.Dispatch<IAdminCandidateSearchrAction>,
  query: SetAdminCandidateSaveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    const url = AppUrls.RemoveAdminCandidateSavedSearch;

    message.loading("please wait..");
    //console.log(6672, url, query);
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "REMOVE_ADMIN_CANDIDATE_SEARCH",
        removeAdminSavedCandidateSearchResultModel: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Removed Successfully")
          : message.error(res.data.message)
        : message.error("error occured");
    });
  } catch (e) {}
};

// SendEmail or sms
export const sendEmailOrSmsAdminCandidateSearch = async (
  dispatcher: React.Dispatch<IAdminCandidateSearchrAction>,
  query: SendEmailOrSmsRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    const url = AppUrls.SendEmailOrSmSInAdminSearch;

    message.loading("please wait..");
    //console.log(6672, url, query);
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "ADMIN_CANDIDATE_SEARCH_SEND_EMAIL_OR_SMS",
        sendEmailOrSmsResultData: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Email Sent Successfully")
          : message.error(res.data.message)
        : message.error("error occured");
    });
  } catch (e) {}
};
