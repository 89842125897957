import React from "react";
import SuperAdminReportComponent from "./SuperAdminReportComponent";

const SuperAdminReportContainer = () => {
  return (
    <>
      <SuperAdminReportComponent />
    </>
  );
};

export default SuperAdminReportContainer;
