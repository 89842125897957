import React, { useState } from "react";
import {
  getDigiLockerDocumentTypeList,
  useDigiLockerContext,
  useDigiLockerDispatcher,
} from "../../action/candidate/DigiLockerAction";
import {
  getAllJobAlertList,
  getJobAlertList,
  useJobAlertDispatcher,
} from "../../action/candidate/JobAlertAction";
import {
  getJobBookMarkList,
  useJobBookMarkContext,
  useJobBookMarkDispatcher,
} from "../../action/candidate/JobBookMarkAction";
import {
  getSuggestedJobList,
  useSuggestedJobDispatcher,
} from "../../action/candidate/SuggestedAction";
import {
  getAllCount,
  useJobTypeContext,
  useJobTypeDispatcher,
} from "../../action/general/JobTypeAction";
import {
  getNewlyPostedJobList,
  useNewlyPostedJobDispatcher,
} from "../../action/general/NewlyPostedJobAction";
import {
  getJobAppliedCandidateList,
  useJobAppliedDispatcher,
} from "../../action/JobAppliedAction";
import {
  getMatchedJobList,
  useMatchedJobDispatcher,
} from "../../action/MatchedJobAction";
import {
  getVendorLoggedUserId,
  getVendorMyProfileBasicInfo,
  getVendorMyProfileSelectBoxList,
  getVendorProfileImage,
  getVendorProfileProgressBarResult,
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../action/MyProfileVendorAction";
import {
  getSuperAdminLoggedUserId,
  getSuperAdminMyProfileBasicInfo,
  getSuperAdminMyProfileSelectBoxList,
  getSuperAdminProfileImage,
  getSuperAdminProfileProgressBarResult,
  useSuperAdminMyProfileContext,
  useSuperAdminMyProfileDispatcher,
} from "../../action/MyProfileSuperAdminAction";
import { jobBookMarkRequestModel } from "../../models/candidate/BookMarkedJob";
import { digiLockerTypeRequestModel } from "../../models/candidate/DigiLocker";
import {
  jobAlertGellAllRequestModel,
  jobAlertRequestmodel,
} from "../../models/candidate/JobAlert";
import { jobAppliedSaveRequestModel } from "../../models/candidate/JobApplied";
import { matchedJobRequestModel } from "../../models/candidate/MatchedJob";

import { suggestedJobRequestModel } from "../../models/candidate/SuggestedJob";
import { countAllRequestModel } from "../../models/general/JobType";
import { newlyPostedJobRequestModel } from "../../models/general/NewlyPostedJob";
import AuthService from "../../services/AuthService";
import IdleTimeChecker from "../IdleTimeChecker";
import LayoutComponent from "./LayoutComponent";
import {
  getLoggedUserId,
  getLoggedVendorId,
  getMyProfileBasicInfo,
  getMyProfileSelectBoxList,
  getProfileImage,
  getProfileProgressBarResult,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../action/MyProfileAction";
import { profileImageRequestModel } from "../../models/candidate/MyProfileSelectBoxData";
import { VendorProfileImageRequestModel } from "../../models/vendor/MyProfileSelectBoxData";
import { AdminProfileImageRequestModel } from "../../models/admin/MyProfileSelectBoxData";
import { SuperAdminProfileImageRequestModel } from "../../models/superadmin/MyProfileSelectBoxData";
import {
  getAdminLoggedUserId,
  getAdminMyProfileBasicInfo,
  getAdminMyProfileSelectBoxList,
  getAdminProfileImage,
  getAdminProfileProgressBarResult,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../action/MyProfileAdminAction";
import ScrollToTop from "../ScrollToTop";
import { RefreshToken } from "../RefreshToken";

interface ILayoutContainerProps {}

interface ILayoutContainerState {}

const initialState = {};

const LayoutContainer: React.FC<ILayoutContainerProps> = (props) => {
  const [
    LayoutContainerState,
    setLayoutContainerState,
  ] = React.useState<ILayoutContainerState>(initialState);
  const authorizationToken = AuthService.accessToken;

  const digiLockerMarkDispatcher = useDigiLockerDispatcher();
  const matchedJobDispatcher = useMatchedJobDispatcher();
  const myProfileDispatcher = useMyProfileDispatcher();
  const newlyPostedJobDispatcher = useNewlyPostedJobDispatcher();

  const myProfileVendorDispatcher = useVendorMyProfileDispatcher();
  // const myProfileVendorContext = useVendorMyProfileContext();
  // const { loggedUserId } = myProfileVendorContext;

  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const { loggedAdminId } = myProfileAdminContext;

  const myProfileSuperAdminDispatcher = useSuperAdminMyProfileDispatcher();
  const myProfileSuperAdminContext = useSuperAdminMyProfileContext();
  const { loggedSuperAdminId } = myProfileSuperAdminContext;

  const myProfileVContext = useMyProfileContext();
  const { myProfile, basicInfo, loggedUserId } = myProfileVContext;
  const jobBookMarkContext = useJobBookMarkContext();
  const { jobBookMark } = jobBookMarkContext;
  const jobAlertDispatcher = useJobAlertDispatcher();
  const suggestedJobDispatcher = useSuggestedJobDispatcher();
  const jobAppliedDispatcher = useJobAppliedDispatcher();
  // To Get Current UserId
  const myProfileContext = useMyProfileContext();
  const { profileImage, myProfileProgressBar } = myProfileContext;

  const jobTypeDispatcher = useJobTypeDispatcher();

  const [isRender, setIsRender] = useState(true);

  React.useEffect(() => {
    if (isRender && authorizationToken != null)
      (async () => {
        await getMyProfileBasicInfo(myProfileDispatcher, authorizationToken);
        setIsRender(false);
      })();
  }, [loggedUserId]);

  React.useEffect(() => {
    if (isRender && authorizationToken != null)
      (async () => {
        await getVendorMyProfileBasicInfo(
          myProfileVendorDispatcher,
          authorizationToken
        );
        setIsRender(false);
      })();
  }, [loggedUserId]);

  React.useEffect(() => {
    if (isRender && authorizationToken != null)
      (async () => {
        await getAdminMyProfileBasicInfo(
          myProfileAdminDispatcher,
          authorizationToken
        );
        setIsRender(false);
      })();
  }, [loggedAdminId]);

  React.useEffect(() => {
    if (isRender && authorizationToken != null)
      (async () => {
        await getSuperAdminMyProfileBasicInfo(
          myProfileSuperAdminDispatcher,
          authorizationToken
        );
        setIsRender(false);
      })();
  }, [loggedSuperAdminId]);

  let user = AuthService.currentUser;
  let role = AuthService.currentUser?.roles[0];
  React.useEffect(() => {
    if (role === "Candidate") {
      if (authorizationToken != null && user?.id != null)
        (async () => {
          await getLoggedUserId(
            myProfileDispatcher,
            parseInt(user.id),
            authorizationToken
          );
        })();
    } else if (role === "Vendor") {
      if (authorizationToken != null && user?.id != null)
        (async () => {
          await getLoggedVendorId(
            myProfileDispatcher,
            parseInt(user.id),
            authorizationToken
          );
        })();
    } else if (role === "SuperAdmin") {
      if (authorizationToken != null && user?.id != null)
        (async () => {
          await getSuperAdminLoggedUserId(
            myProfileSuperAdminDispatcher,
            parseInt(user.id),
            authorizationToken
          );
        })();
    } else {
      if (authorizationToken != null && user?.id != null)
        (async () => {
          await getAdminLoggedUserId(
            myProfileAdminDispatcher,
            parseInt(user.id),
            authorizationToken
          );
        })();
    }
  }, [authorizationToken]);
  // React.useEffect(() => {
  //   if (authorizationToken != null && user?.id != null)
  //     (async () => {
  //       await getVendorLoggedUserId(
  //         myProfileVendorDispatcher,
  //         parseInt(user.id),
  //         authorizationToken
  //       );
  //     })();
  // }, [authorizationToken]);

  // React.useEffect(() => {
  //   if (authorizationToken != null && user?.id != null)
  //     (async () => {
  //       await getAdminLoggedUserId(
  //         myProfileAdminDispatcher,
  //         parseInt(user.id),
  //         authorizationToken
  //       );
  //     })();
  // }, [authorizationToken]);

  // React.useEffect(() => {
  //   if (authorizationToken != null && user?.id != null)
  //     (async () => {
  //       await getSuperAdminLoggedUserId(
  //         myProfileSuperAdminDispatcher,
  //         parseInt(user.id),
  //         authorizationToken
  //       );
  //     })();
  // }, [authorizationToken]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getDigiLockerDocumentTypeList(
          digiLockerMarkDispatcher,
          {
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
          } as digiLockerTypeRequestModel,
          authorizationToken
        );
      })();
    }
  }, [user?.id]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getAllCount(
          jobTypeDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as countAllRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getProfileProgressBarResult(
          myProfileDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as profileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getVendorProfileProgressBarResult(
          myProfileVendorDispatcher,
          {
            vendorId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as VendorProfileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  // React.useEffect(() => {
  //   if (authorizationToken != null) {
  //     (async () => {
  //       await getMatchedJobList(
  //         matchedJobDispatcher,
  //         {
  //           candidateId: loggedUserId,
  //           type: "",
  //           expereince: "",
  //           location: "",
  //           title: "",
  //           pageIndex: 1,
  //           pageSize: 10,
  //           showInactive: false,
  //         } as matchedJobRequestModel,
  //         authorizationToken
  //       );
  //     })();
  //   }
  // }, [loggedUserId]);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getMyProfileSelectBoxList(myProfileDispatcher);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getVendorMyProfileSelectBoxList(myProfileVendorDispatcher);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getSuperAdminMyProfileSelectBoxList(
          myProfileSuperAdminDispatcher
        );
      })();
    }
  }, []);

  // React.useEffect(() => {
  //   if (authorizationToken != null && loggedUserId!= 0 ) {
  //     (async () => {
  //       await getVendorProfileImage(
  //         myProfileVendorDispatcher,
  //         {
  //           vendorId: loggedUserId,
  //           page: 1,
  //           pageSize: 10,
  //           searchTerm: "",
  //           showInactive: false,
  //           sortOrder: "",
  //         } as VendorProfileImageRequestModel,
  //         authorizationToken
  //       );
  //     })();
  //   }
  // }, [loggedUserId]);

  //  React.useEffect(() => {
  //   if (authorizationToken != null && loggedSuperAdminId!= 0 ) {
  //     (async () => {
  //       await getSuperAdminProfileImage(
  //         myProfileSuperAdminDispatcher,
  //         {
  //           superadminId: loggedSuperAdminId,
  //           page: 1,
  //           pageSize: 10,
  //           searchTerm: "",
  //           showInactive: false,
  //           sortOrder: "",
  //         } as SuperAdminProfileImageRequestModel,
  //         authorizationToken
  //       );
  //     })();
  //   }
  // }, [loggedSuperAdminId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getProfileImage(
          myProfileDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as profileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getVendorProfileImage(
          myProfileVendorDispatcher,
          {
            vendorId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as VendorProfileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      (async () => {
        await getAdminProfileImage(
          myProfileAdminDispatcher,
          {
            adminId: loggedAdminId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as AdminProfileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedAdminId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedSuperAdminId != 0) {
      (async () => {
        await getSuperAdminProfileImage(
          myProfileSuperAdminDispatcher,
          {
            superadminId: loggedSuperAdminId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as SuperAdminProfileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedSuperAdminId]);

  const jobBookMarkDispatcher = useJobBookMarkDispatcher();

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getJobBookMarkList(
          jobBookMarkDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 60,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as jobBookMarkRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  // React.useEffect(() => {
  //   if(authorizationToken!=null){
  //   (async () => {
  //     await getAllJobAlertList(jobAlertDispatcher,{candidateId:loggedUserId,
  //       page:1,pageSize:60,searchTerm:'',sortOrder:'',showInactive:true} as jobAlertGellAllRequestModel,authorizationToken);

  //   })();
  // }
  // }, [user?.id])

  // React.useEffect(() => {
  //   if (authorizationToken != null && loggedUserId != 0)
  //     (async () => {
  //       await getJobAlertList(
  //         jobAlertDispatcher,
  //         {
  //           candidateId: loggedUserId,
  //           expereince: "",
  //           lastDays: 0,
  //           title: "",
  //           type: "",
  //           location: "",
  //           pageIndex: 1,
  //           pageSize: 10,
  //           showInactive: false,
  //         } as jobAlertRequestmodel,
  //         authorizationToken
  //       );
  //     })();
  // }, [authorizationToken, loggedUserId]);

  // React.useEffect(() => {
  //   if (authorizationToken != null && loggedUserId != 0) {
  //     (async () => {
  //       await getJobAppliedCandidateList(
  //         jobAppliedDispatcher,
  //         {
  //           candidateId: loggedUserId,
  //           jobAppliedId: 0,
  //           isActive: true,
  //           jobId: 0,
  //           rowId: 0,
  //         } as jobAppliedSaveRequestModel,
  //         authorizationToken
  //       );
  //     })();
  //   }
  // }, [loggedUserId]);

  return (
    <>
      <ScrollToTop />
      <LayoutComponent />
      {/* <RefreshToken /> */}
      {authorizationToken != null ? <IdleTimeChecker></IdleTimeChecker> : null}
    </>
  );
};

export default LayoutContainer;
