import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";

import {
  ClientCandidateMoveResultModel,
  ClientJobResultModel,
  ClientJobStatusResultModel,
  ClientViewApplicationDetailsResultModel,
  ClientViewApplicationResultModel,
  DashboardCountResultModel,
  FakeCandidatesearchResult,
  GetAllJobRespondModel,
} from "../../models/client/ClientJob";
import {
  clientJobReducer,
  IClientJobAction,
} from "../../action/client/ClientJobAction";
import { ClientPersonalInfoResultModel } from "../../models/client/ClientProfile";

export const ClientJobDispatcherContext = React.createContext<React.Dispatch<IClientJobAction> | null>(
  null
);
export const ClientJobStateContext = React.createContext<IClientJobManagementState | null>(
  null
);

export interface IClientJobManagementProps extends RouteComponentProps<any> {}

export interface IClientJobManagementState {
  ClientJobSaveRespondModel: ClientJobResultModel;
  AllClientJobs: GetAllJobRespondModel;
  ClientJob: GetAllJobRespondModel;
  publishJobResult: ClientPersonalInfoResultModel;
  clientJobStatusResul: ClientJobStatusResultModel;
  ClientDashBoardCount: DashboardCountResultModel;
  ClientNotification: string[];
  FakeCandidateSearchResult: FakeCandidatesearchResult[];
  ClientViewApplication: ClientViewApplicationResultModel;
  ClientViewApplicationDetailsData: ClientViewApplicationDetailsResultModel;
  ClientCandidateMoveData: ClientCandidateMoveResultModel;
}

export const initialClientJobManagementState = {
  ClientJobSaveRespondModel: {} as ClientJobResultModel,
  AllClientJobs: {} as GetAllJobRespondModel,
  ClientJob: {} as GetAllJobRespondModel,
  publishJobResult: {} as ClientPersonalInfoResultModel,
  clientJobStatusResul: {} as ClientJobStatusResultModel,
  ClientDashBoardCount: {} as DashboardCountResultModel,
  ClientNotification: [] as string[],
  FakeCandidateSearchResult: {} as FakeCandidatesearchResult[],
  ClientViewApplication: {} as ClientViewApplicationResultModel,
  ClientViewApplicationDetailsData: {} as ClientViewApplicationDetailsResultModel,
  ClientCandidateMoveData: {} as ClientCandidateMoveResultModel,
} as IClientJobManagementState;

export const ClientJobContextProvider: React.FC = ({ children }) => {
  const [clientJobState, dispatcher] = useImmerReducer(
    clientJobReducer,
    initialClientJobManagementState
  );

  return (
    <ClientJobDispatcherContext.Provider value={dispatcher}>
      <ClientJobStateContext.Provider value={clientJobState}>
        {children}
      </ClientJobStateContext.Provider>
    </ClientJobDispatcherContext.Provider>
  );
};
