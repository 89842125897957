import React from "react";
import IndexFooterContainer from "../index/indexFooter/IndexFooterContainer";
import IndexHeaderContainer from "../index/indexHeader/IndexHeaderContainer";
import AboutUsComponent from "./AboutUsComponent";
import { StoryContextProvider } from "../../context/admin/StoryContext";
import { MyTeamMemberContextProvider } from "../../context/admin/MyTeamMemberContext";

interface IAboutUsContainerProps { }

interface IAboutUsContainerState { }

const initialState = {};

const AboutUsContainer: React.FC<IAboutUsContainerProps> = (props) => {
  const [AboutUsContainerState, setAboutUsContainerState] = React.useState<IAboutUsContainerState>(
    initialState
  );

  return (
    <>
    {/* <IndexHeaderContainer /> */}
     <MyTeamMemberContextProvider>
       <StoryContextProvider>
          <AboutUsComponent/>
       </StoryContextProvider>
     </MyTeamMemberContextProvider>
    <IndexFooterContainer />
    </>
  );
};
export default AboutUsContainer;
