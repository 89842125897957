import React from "react";
import CareerTipsCategoryComponent from "./CareerTipsCategoryComponents";

interface ICareerTipsCategoryContainerProps { }

interface ICareerTipsCategoryContainerState { }

const initialState = {};

const CareerTipsCategoryContainer: React.FC<ICareerTipsCategoryContainerProps> = (props) => {
  const [startUpState, setCareerTipsCategoryContainerState] = React.useState<ICareerTipsCategoryContainerState>(
    initialState
  );

  return (
    <>
    <CareerTipsCategoryComponent currentCategoryId={4}/>
    </>
  );
};
export default CareerTipsCategoryContainer;
