import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  CandidateDashboardRequestModel,
  DashboardSaveRequest,
  DashboardSaveRespond,
  GetAllDashboardRespond,
  GetAllDashboardRespondModel,
} from "../../models/candidate/Dashboard";
import {
  DashboardDispatcherContext,
  DashboardStateContext,
  IDashboardManagementState,
} from "../../context/candidate/DashboardContext";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IDashboardAction =
  | {
      type: "GET_Dashboard_LIST";
      dashboardRespond: GetAllDashboardRespond;
    }
  | {
      type: "SAVE_Dashboard_LIST";
      dashboardSaveRespond: DashboardSaveRespond;
    };

export const dashboardReducer: Reducer<
  IDashboardManagementState,
  IDashboardAction
> = (draft, action): IDashboardManagementState => {
  switch (action.type) {
    case "GET_Dashboard_LIST":
      draft.dashboardRespond = action.dashboardRespond;
      return draft;

    case "SAVE_Dashboard_LIST":
      draft.dashboardSaveRespond = action.dashboardSaveRespond;
      return draft;
  }
};

export const useDashboardDispatcher = (): React.Dispatch<IDashboardAction> => {
  const dashboardDispatcher = React.useContext(DashboardDispatcherContext);
  if (!dashboardDispatcher) {
    throw new Error(
      "You have to provide the Dashboard dispatcher using theDashboardDispatcherContext.Provider in a parent component."
    );
  }
  return dashboardDispatcher;
};

export const useDashboardContext = (): IDashboardManagementState => {
  const dashboardContext = React.useContext(DashboardStateContext);
  if (!dashboardContext)
    throw new Error(
      "You have to provide the dashboard context using the DashboardStateContext.Provider in a parent component."
    );
  return dashboardContext;
};

export const getDashboardList = async (
  dispatcher: React.Dispatch<IDashboardAction>,
  query: CandidateDashboardRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetDashboard;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_Dashboard_LIST",
        dashboardRespond: res.data,
      });
    });
  } catch (e) {}
};

export const SaveDashBoard = async (
  dispatcher: React.Dispatch<IDashboardAction>,
  query: DashboardSaveRequest,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveDashBoardLayout;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SAVE_Dashboard_LIST",
        dashboardSaveRespond: res.data,
      });
      res.data.isSuccess
        ? message.success("Layout")
        : message.error(res.data.message);
    });
  } catch (e) {}
};
