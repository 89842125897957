import React from "react";
import VendorMessageComponent from "./VendorMessageComponent";
import { UserListContextProvider, MessageListContextProvider } from "../../../context/vendor/VendorMyProfile";

interface IVendorMessageContainerProps { }

interface IVendorMessageContainerState { }

const initialState = {};

const VendorMessageContainer: React.FC<IVendorMessageContainerProps> = (props) => {
  const [VendorMessageContainerState, setVendorMessageContainerState] = React.useState<IVendorMessageContainerState>(
    initialState
  );

  return (
    <UserListContextProvider>
      <MessageListContextProvider>
         <VendorMessageComponent/> 
     </MessageListContextProvider>
   </UserListContextProvider>  
  );
};
export default VendorMessageContainer;
