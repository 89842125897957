import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { GlobalSpinnerContext } from "./../../context/GlobalSpinner";
import { sendPhoneOtp, verifyCode } from "./../../apis/misc";
import { reactHookFormServerErrors } from "./../../utils/utility";
import { toast, ToastContainer } from "react-toastify";
import OtpExpiryCouter from "./../GlobalOtpExpiryCouter";
import { createCandidate, createVendor } from "./../../apis/misc";
import { saveVendor } from "./../../apis/vendor";
import AuthService from "../../services/AuthService";

interface IOtpVerificationState {
  otp: string;
}

function OtpVerification(props: any) {
  console.log(props);

  const query = new URLSearchParams(props.location?.search);

  const defaultValues = {
    otp: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    setError,
  } = useForm<IOtpVerificationState>({
    defaultValues,
  });

  const globalSpinner = useContext(GlobalSpinnerContext);

  const onSubmit = (data: any) => {
    console.log(data);

    globalSpinner.showSpinner();

    verifyCode(
      {},
      //`?code=${data["otp"]}&phoneNumber=${encodeURIComponent((query.get("countryCode") || "") + (query.get("mobileNumber") || ""))}`
      `?code=${data["otp"]}&countryCode=${encodeURIComponent(
        query.get("countryCode") || ""
      )}&phoneNumber=${encodeURIComponent(query.get("mobileNumber") || "")}`
    )
      .then((res: any) => {
        console.log(res.data);
        if (props.location.state.userRoleId == 4) {
          createCandidate({
            RowId: 0, // set value as 0
            CandidateId: 0, // set value as 0
            //"NamePrefixId": 0,
            NamePrefixId: null,
            FirstName: props.location.state.name, //null,
            LastName: null,
            //"GenderId": 0,
            GenderId: null,
            //"MartialStatusId": 0,
            MartialStatusId: null,
            Email: query.get("email"),
            CountryCode: query.get("countryCode"), //data["countryCode"],
            PhoneNumber: query.get("mobileNumber"), // data["mobileNumber"],
            TelephoneNumber: null,
            //"PassportInformationId": 0,
            PassportInformationId: null,
            // "LicenceInformationId": 0,
            LicenceInformationId: null,
            PanNo: null,
            AadharNo: null,
            //"CategoryId": 0,
            CategoryId: null,
            //"DesignationId": 0,
            DesignationId: null,
            //"JobTypeId": 0,
            JobTypeId: null,
            CurrentCTC: 0,
            ExpectedPackage: 0,
            // "IndustryId": 0,
            IndustryId: null,
            // "FunctionalAreaId": 0,
            FunctionalAreaId: null,
            //"JobRoleId": 0,
            JobRoleId: null,
            Skills: null,
            ProfileSummary: null,
            Dob: "",
            CurrentAddress1: null,
            CurrentAddress2: null,
            PermanantAddress1: null,
            PermanantAddress2: null,
            City: null,
            //"StateId": 0,
            StateId: null,
            //"CountryId": 0,
            CountryId: null,
            ZipCode: null,
            UserId: Number(props.location.state.user), // set value as above created userid
            IsActive: true,
          })
            .then(async (res: any) => {
              console.log(res.data);
              globalSpinner.hideSpinner();
              toast.success(
                "Your account has been successfully.Please login ith your registered e-mail and password."
              );

              // props.history.push("/login");
              // -----------------------------Login code------------------
              try {
                // const user = await AuthService.login(data.email, data.password, data.rememberMe);
                globalSpinner.showSpinner();
                if (
                  props.location.state.email !== null &&
                  props.location.state.password !== null
                ) {
                  const user = await AuthService.login(
                    props.location.state.email,
                    props.location.state.password,
                    true
                  );

                  if (user) {
                    console.log(
                      "-----------------logged user----------: " + user.roles[0]
                    );
                    const authorizationToken = AuthService.accessToken;
                    console.log(
                      "-----------------authorizationToken in login----------: " +
                        authorizationToken
                    );
                    globalSpinner.hideSpinner();
                    if (user.roles[0] == "Candidate") {
                      props.history.push("/candidate");
                      window.location.reload(false);
                    } else if (user.roles[0] == "Vendor") {
                      props.history.push("/vendor");
                      localStorage.setItem("logged", "1");
                      window.location.reload(false);
                    }
                    globalSpinner.hideSpinner();
                  } else {
                    globalSpinner.hideSpinner();
                  }
                }
              } catch (error) {
                console.log(error);
                if (error.response.status == 400) {
                  toast.error("Username/Password incorrect.");
                } else {
                  toast.error("Error occurred while login.");
                }

                globalSpinner.hideSpinner();
              }
              // -----------------------------Login code start------------
            })
            .catch((err: any) => {
              console.log(err);
              globalSpinner.hideSpinner();
              toast.error("Error occurred while registration.");
            });
        } else if (props.location.state.userRoleId == 2) {
          const formData = new FormData();
          //formData.append("RowId", JSON.stringify(Number(0)));
          formData.append("VendorId", JSON.stringify(Number(0)));
          formData.append("VendorName", String(props.location.state.name));
          //formData.append("JobRole", String(null));
          //formData.append("Location", String(null));
          //formData.append("Designation", String(null));
          formData.append("CountryCode", String(query.get("countryCode")));
          formData.append("PhoneNumber", String(query.get("mobileNumber")));
          //formData.append("Organisation", String(null));
          formData.append("Email", String(query.get("email")));
          //formData.append("AboutMe", String(null));
          //formData.append("VendorDocument", String(null));
          //formData.append("Document", obj.file);
          formData.append(
            "UserId",
            JSON.stringify(Number(props.location.state.user))
          );
          formData.append("VendorStatusId", JSON.stringify(1));
          formData.append("IsActive", JSON.stringify(true));

          saveVendor(
            formData
            // RowId: 0, // set value as 0
            // VendorId: 0, // set value as 0
            // //"NamePrefixId": 0,
            // //NamePrefixId: null,
            // VendorName: props.location.state.name, //null,
            // //LastName: null,
            // //"GenderId": 0,
            // //GenderId: null,
            // //"MartialStatusId": 0,
            // //MartialStatusId: null,
            // Email: query.get("email"),
            // CountryCode: query.get("countryCode"), //data["countryCode"],
            // PhoneNumber: query.get("mobileNumber"), // data["mobileNumber"],
            // //TelephoneNumber: null,
            // //"PassportInformationId": 0,
            // //PassportInformationId: null,
            // // "LicenceInformationId": 0,
            // //LicenceInformationId: null,
            // //PanNo: null,
            // //AadharNo: null,
            // //"CategoryId": 0,
            // //CategoryId: null,
            // //"DesignationId": 0,
            // //DesignationId: null,
            // //"JobTypeId": 0,
            // //JobTypeId: null,
            // //CurrentCTC: 0,
            // //ExpectedPackage: 0,
            // // "IndustryId": 0,
            // //IndustryId: null,
            // // "FunctionalAreaId": 0,
            // //FunctionalAreaId: null,
            // //"JobRoleId": 0,
            // //JobRoleId: null,
            // //Skills: null,
            // Location: null,
            // VendorStatusId: Number(1),
            // AboutMe: null,
            // Designation: null,
            // VendorDocument: null,
            // JobRole: null,
            // Organisation: null,
            // //"StateId": 0,
            // StateId: null,
            // //"CountryId": 0,
            // CountryId: null,
            // //ZipCode: null,
            // UserId: Number(props.location.state.user), // set value as above created userid
            // IsActive: true,
          )
            .then(async (res: any) => {
              console.log(res.data);
              globalSpinner.hideSpinner();
              toast.success(
                "Your account has been successfully.Please login ith your registered e-mail and password."
              );
              // props.history.push("/login");
              // -----------------------------Login code------------------
              try {
                // const user = await AuthService.login(data.email, data.password, data.rememberMe);
                if (
                  props.location.state.email !== null &&
                  props.location.state.password !== null
                ) {
                  const user = await AuthService.login(
                    props.location.state.email,
                    props.location.state.password,
                    true
                  );
                  if (user) {
                    console.log(
                      "-----------------logged user----------: " + user.roles[0]
                    );
                    const authorizationToken = AuthService.accessToken;
                    console.log(
                      "-----------------authorizationToken in login----------: " +
                        authorizationToken
                    );

                    if (user.roles[0] == "Candidate") {
                      props.history.push("/candidate");
                      window.location.reload(false);
                    } else if (user.roles[0] == "Vendor") {
                      props.history.push("/vendor");
                      localStorage.setItem("logged", "1");
                      window.location.reload(false);
                    }
                    globalSpinner.hideSpinner();
                  } else {
                    globalSpinner.hideSpinner();
                  }
                }
              } catch (error) {
                console.log(error);
                if (error.response.status == 400) {
                  toast.error("Username/Password incorrect.");
                } else {
                  toast.error("Error occurred while login.");
                }

                globalSpinner.hideSpinner();
              }
              // -----------------------------Login code start------------
            })
            .catch((err: any) => {
              console.log(err);
              globalSpinner.hideSpinner();
              toast.error("Error occurred while registration.");
            });
        }

        // if (res.data.includes("There was an error confirming the code")) {
        //   reactHookFormServerErrors(
        //     {
        //       otp: ["Invalid verification code."],
        //     },
        //     setError
        //   );
        // } else {
        //props.history.push("/login");
        // }
      })
      .catch((err: any) => {
        console.log(err);

        globalSpinner.hideSpinner();
        toast.error("Invalid OTP");
        reactHookFormServerErrors(
          {
            //otp: [err.toString()],
            otp: [err.response.data.toString()],
          },
          setError
        );
      });
  };

  const [showTimer, setShowTimer] = React.useState("1");

  const resendOtp = (data: any) => {
    console.log(data);

    globalSpinner.showSpinner();

    sendPhoneOtp(
      {},
      //`?phoneNumber=${encodeURIComponent((query.get("countryCode") || "") + (query.get("mobileNumber") || ""))}`
      `?countryCode=${encodeURIComponent(
        query.get("countryCode") || ""
      )}&phoneNumber=${encodeURIComponent(query.get("mobileNumber") || "")}`
    )
      .then((res: any) => {
        if (
          res.data.includes("There was an error") ||
          res.data.includes("Invalid")
        ) {
          console.log(res.data);
          toast.error(res.data);
          // reactHookFormServerErrors(
          //   {
          //     otp: ["Invalid verification code."],
          //   },
          //   setError
          // );
        } else {
          if (Number(showTimer)) {
            const c = Number(showTimer) + 1;
            console.log(c, "================================");
            toast.success("Successfully Sent");
            setShowTimer(c + "");
          } else {
            setShowTimer("1");
          }
        }
      })
      .catch((err: any) => {
        console.log(err);

        globalSpinner.hideSpinner();
        console.log(err);
        toast.error("Something went wrong");

        // reactHookFormServerErrors(
        //   {
        //     otp: [err.toString()],
        //   },
        //   setError
        // );
      });
  };

  // const globalSpinner = useContext(GlobalSpinnerContext);

  // const [showTimer, setShowTimer] = React.useState("1");

  // const onSubmit = (data: any) => {
  //   console.log(data, props.location);
  //   globalSpinner.showSpinner();

  //   const phoneNumber = query.get("mobileNumber") || "";

  //   verifyCode(
  //     {},
  //     `?code=${data["otp"]}&phoneNumber=${encodeURIComponent(phoneNumber)}`
  //   )
  //     .then((res: any) => {
  //       console.log(res.data);

  //       globalSpinner.hideSpinner();

  //       if (res.data.includes("There was an error confirming the code")) {
  //         reactHookFormServerErrors(
  //           {
  //             otp: ["Invalid verification code."],
  //           },
  //           setError
  //         );
  //       } else {
  //         props.history.push("/login");
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.log(err);

  //       globalSpinner.hideSpinner();

  //       reactHookFormServerErrors(
  //         {
  //           otp: [err.toString()],
  //         },
  //         setError
  //       );
  //     });
  // };

  // const resendOtp = () => {
  //   if (
  //     query.get("mobileNumber") ||
  //     (props.location.state && props.location.state.mobileNumber)
  //   ) {
  //     globalSpinner.showSpinner();
  //     sendPhoneOtp(
  //       {},
  //       `?phoneNumber=${encodeURIComponent(
  //         query.get("mobileNumber") + "" || props.location.state.mobileNumber
  //       )}`
  //     )
  //       .then((res: any) => {
  //         console.log(res.data);

  //         globalSpinner.hideSpinner();

  //         toast.info("Resend otp successful.");
  //       })
  //       .catch((err: any) => {
  //         console.log(err);

  //         globalSpinner.hideSpinner();

  //         toast.error(err.toString());
  //       });
  //   } else {
  //     toast.info("Registration step has been broken or invalid mobileNumber.");
  //   }
  // };

  return (
    <div className=" container">
      <ToastContainer />
      <div className="row">
        <div className="col-sm-6 desk">
          <div className="login_img">
            <img
              src={require("./../../images/otp_img.jpg")}
              className="img-responsive"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-5 col-lg-offset-1">
          <form
            className="needs-validation"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className="otp_sec registration_sec">
              <div className="sub_heading">
                <h2>Mobile Verification</h2>
                <div className="otp_text">
                  We have sent a message with verification code to your phone
                  number.
                </div>
                <div className="otp_text1">
                  Please enter the verification code to confirm.{" "}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-n"
                    placeholder="Enter Code"
                    name="otp"
                    ref={register({
                      required: "Field is required",
                      maxLength: {
                        value: 15,
                        message: "Should be a mobile number",
                      },
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Mobile number should be numbers only",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="otp"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                  <OtpExpiryCouter setTimer={setShowTimer} timer={showTimer} />
                </div>
                <div className="already_login1">
                  Didn't get the code?{"       "}{" "}
                  <a onClick={resendOtp}> Send again.</a>
                </div>
                <div className="m_t_20">
                  <button type="submit" className="btn btn-success reg_but">
                    Verify
                  </button>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(OtpVerification);
