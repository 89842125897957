import React from "react";
import ResumeLayoutComponent from "./ResumeLayoutComponent";


interface IResumeLayoutContainerProps { }

interface IResumeLayoutContainerState { }

const initialState = {};

const ResumeLayoutContainer: React.FC<IResumeLayoutContainerProps> = (props) => {
  const [resumeLayoutContainerState, setResumeLayoutContainerState] = React.useState<IResumeLayoutContainerState>(
    initialState
  );

  return (
    <>
      <ResumeLayoutComponent/>
    </>
  );
};
export default ResumeLayoutContainer;
