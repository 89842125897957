import { Reducer } from "react"
import { IExperienceManagementState, ExperienceDispatcherContext, ExperienceStateContext } from "../../context/general/ExperienceContext"
import React from "react";

import axios from 'axios';
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {  experienceRequestModel, experienceViewModel } from "../../models/general/Experience";

let token=AuthService.accessToken;
let   authorizationToken =token!=null?token.replace(/['"]+/g,''):""; 

export type IExperienceAction = {
    type:"SET_Experience_LIST",
    experience:experienceViewModel 
}




export const experienceReducer: Reducer<IExperienceManagementState, IExperienceAction> = (draft, action): IExperienceManagementState => {
    switch (action.type) {
     
            case "SET_Experience_LIST":
                draft.experience=action.experience;
                return draft;
    }
}



export const useExperienceDispatcher = (): React.Dispatch<IExperienceAction> => {
    const experienceDispatcher = React.useContext(ExperienceDispatcherContext);
    if (!experienceDispatcher) {
        throw new Error("You have to provide the Experience dispatcher using theExperienceDispatcherContext.Provider in a parent component.");
    }
    return experienceDispatcher;
}


export const useExperienceContext = (): IExperienceManagementState => {
    const experienceContext = React.useContext(ExperienceStateContext);
    if (!experienceContext) throw new Error("You have to provide the experience context using the ExperienceStateContext.Provider in a parent component.");
    return experienceContext;
}



export const getExperienceList = async (dispatcher: React.Dispatch<IExperienceAction>,query:experienceRequestModel  ) => {
   
    try{

      let header = {
                      "Content-Type": "application/json",
                      "Accept": "application/json",
                     "Authorization":`Bearer ${authorizationToken}`,
                  };
  
                   const url=AppUrls.GetAllExperience
  
                  axios.post(url, JSON.stringify( 
                     query
                   ),{ headers: header })
                  .then(res => {
                    dispatcher({ type:"SET_Experience_LIST", experience: res.data });
                  })

  }
  catch(e){

  }
}



