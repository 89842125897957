import React from "react";
import { AdminContextProvider } from "../../../context/admin/AdminMyProfile";
import { SocialAccountUrlContextProvider } from "../../../context/admin/SocialAccountUrlContext";
import { CandidateContextProvider } from "../../../context/candidate/CandidateMyProfile";
import { MyProfileAdminContextProvider } from "../../../context/MyProfileAdminContext";
import { MyProfileSuperAdminContextProvider } from "../../../context/MyProfileSuperAdminContext";
import { SuperAdminContextProvider } from "../../../context/superadmin/SuperAdminMyProfile";
import UnAuthorizedComponent from "./UnAuthorizedComponent";

const UnAuthorizedContainer = () => {
  return (
    <>
      <SocialAccountUrlContextProvider>
        <AdminContextProvider>
          <MyProfileAdminContextProvider>
            <SuperAdminContextProvider>
              <MyProfileSuperAdminContextProvider>
                {/* <VendorContextProvider> */}
                {/* <MyProfileVendorContextProvider>         */}
                <CandidateContextProvider>
                  <UnAuthorizedComponent />
                </CandidateContextProvider>
              </MyProfileSuperAdminContextProvider>
            </SuperAdminContextProvider>
          </MyProfileAdminContextProvider>
        </AdminContextProvider>
      </SocialAccountUrlContextProvider>
    </>
  );
};

export default UnAuthorizedContainer;
