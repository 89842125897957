import React, { useState } from "react";
import FileViewer from "react-file-viewer";
import { useLocation, useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import PDFViewer from "pdf-viewer-reactjs";

interface IFileViewerProps {}

const FileView: React.FC<IFileViewerProps> = (props) => {
  // pdf viewer settings
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  //

  let { fileType, filePath } = useParams();
  const location = useLocation();

  const filePathUrl = location.pathname.slice(15);
  const tilePath = location.search;
  //console.log(fileType, filePathUrl);

  const file = `${filePath}`;
  //  const file = `${filePathUrl}${tilePath}`;
  //  const file = "https://careerapp.azurewebsites.net/api/DigiDocumentDownload/file?AccessKey=6574c710816a4979b7ff0d7529e31b8d&Expires637437165857990311"
  const type = `${fileType}`;
  // const type ='jpg'

  const onError = (e: any) => {
    //console.log(e, "error in file-viewer");
  };

  return (
    <div>
      {/* <h1>React File Viewer Demo</h1>
      <h2>Displaying file with extension {type}</h2> */}
      <FileViewer
        fileType={type}
        filePath={filePathUrl}
        onError={onError}
        key={filePathUrl}
      />

      {/* <Document
        file={filePathUrl}
        // onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document> */}

      {/* 
<PDFViewer
            document={{
                url: {filePathUrl},
            }}
        /> */}
    </div>
  );
};

export default FileView;
