import React from "react";
import ClientViewApplicationComponent from "./ClientViewApplicationComponent";

const ClientViewApplicationContainer = () => {
  return (
    <>
      <ClientViewApplicationComponent />
    </>
  );
};

export default ClientViewApplicationContainer;
