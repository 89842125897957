import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  suggestedJobTitleResultModel,
  SuggestedJobViewModel,
} from "../../models/candidate/SuggestedJob";
import {
  ISuggestedJobAction,
  suggestedJobReducer,
} from "../../action/candidate/SuggestedAction";

export const SuggestedJobDispatcherContext = React.createContext<React.Dispatch<ISuggestedJobAction> | null>(
  null
);
export const SuggestedJobStateContext = React.createContext<ISuggestedJobManagementState | null>(
  null
);

export interface ISuggestedJobManagementProps
  extends RouteComponentProps<any> {}

export interface ISuggestedJobManagementState {
  visible: boolean;
  value: number;
  suggestedJobs: SuggestedJobViewModel;
  suggestedJobTitleResultModel: suggestedJobTitleResultModel;
}

export const initialSuggestedJobManagementState = {
  visible: false,
  value: 0,
  suggestedJobs: {} as SuggestedJobViewModel,
  suggestedJobTitleResultModel: {} as suggestedJobTitleResultModel,
} as ISuggestedJobManagementState;

export const SuggestedJobContextProvider: React.FC = ({ children }) => {
  const [suggestedJobState, dispatcher] = useImmerReducer(
    suggestedJobReducer,
    initialSuggestedJobManagementState
  );

  return (
    <SuggestedJobDispatcherContext.Provider value={dispatcher}>
      <SuggestedJobStateContext.Provider value={suggestedJobState}>
        {children}
      </SuggestedJobStateContext.Provider>
    </SuggestedJobDispatcherContext.Provider>
  );
};
