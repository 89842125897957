import React from "react";
import { Route, useRouteMatch, withRouter } from "react-router-dom";
import { MyProfileSuperAdminContextProvider } from "../../../context/MyProfileSuperAdminContext";
import {
  SocialAccountContextProvider,
  SuperAdminContextProvider,
  SuperAdminProfileImageContextProvider,
} from "../../../context/superadmin/SuperAdminMyProfile";
import SuperAdminDashboardContainer from "../dashboard/SuperAdminDashboardContainer";
import SuperAdminMessageContainer from "../message/SuperAdminMessageContainer";
import SuperAdminReportContainer from "../report/SuperAdminReportContainer";
import UsersAdminContainer from "../users/UsersAdminContainer";
import SuperAdminProfileContainer from "./../my_profile/SuperAdminProfileContainer";
import SuperAdminLayoutSidebar from "./SuperAdminLayoutSidebar";

interface ISuperAdminLayoutComponentProps {}

interface ISuperAdminLayoutComponentState {}

const initialState = {};

// const authorizationToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8';
// let loggedUserId=1;

const SuperAdminLayoutComponent: React.FC<ISuperAdminLayoutComponentProps> = (
  props
) => {
  // const [
  //   superadminLayoutComponentState,
  //   setSuperAdminLayoutComponentState,
  // ] = React.useState<ISuperAdminLayoutComponentState>(initialState);

  //  const authorizationToken = AuthService.accessToken;

  const { path } = useRouteMatch();

  return (
    <div id="wrapper">
      {/* <SuperAdminContextProvider>
          <MyProfileSuperAdminContextProvider>
            <SuperAdminProfileImageContextProvider> */}
      <SuperAdminLayoutSidebar />
      {/* </SuperAdminProfileImageContextProvider>
            </MyProfileSuperAdminContextProvider>
           </SuperAdminContextProvider> */}
      <Route exact path={`${path}`}>
        <SuperAdminDashboardContainer />
      </Route>
      <Route exact path={`${path}/my_user`}>
        <UsersAdminContainer />
      </Route>
      <Route exact path={`${path}/report`}>
        <SuperAdminReportContainer />
      </Route>
      <Route exact path={`${path}/messages`}>
        <SuperAdminContextProvider>
          <MyProfileSuperAdminContextProvider>
            <SocialAccountContextProvider>
              <SuperAdminMessageContainer />
            </SocialAccountContextProvider>
          </MyProfileSuperAdminContextProvider>
        </SuperAdminContextProvider>
      </Route>
      <Route exact path={`${path}/my-profile`}>
        <SuperAdminContextProvider>
          <MyProfileSuperAdminContextProvider>
            <SocialAccountContextProvider>
              <SuperAdminProfileImageContextProvider>
                <SuperAdminProfileContainer />
              </SuperAdminProfileImageContextProvider>
            </SocialAccountContextProvider>
          </MyProfileSuperAdminContextProvider>
        </SuperAdminContextProvider>
      </Route>
    </div>
  );
};
export default withRouter(SuperAdminLayoutComponent);
