import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  SuperAdminReportResultModel,
  SuperAdminTotalReportResultModel,
} from "../../models/superadmin/SuperAdminReport";
import {
  ISuperAdminReportAction,
  superAdminReportReducer,
} from "../../action/superAdmin/SuperAdminReportAction";

export const SuperAdminReportDispatcherContext = React.createContext<React.Dispatch<ISuperAdminReportAction> | null>(
  null
);
export const SuperAdminReportStateContext = React.createContext<ISuperAdminReportManagementState | null>(
  null
);

export interface ISuperAdminReportManagementProps
  extends RouteComponentProps<any> {}

export interface ISuperAdminReportManagementState {
  adminReportsList: SuperAdminReportResultModel;
  totalReportList: SuperAdminTotalReportResultModel;
  
}

export const initialSuperAdminReportManagementState = {
  adminReportsList: {} as SuperAdminReportResultModel,
  totalReportList: {} as SuperAdminTotalReportResultModel,
} as ISuperAdminReportManagementState;

export const SuperAdminReportContextProvider: React.FC = ({ children }) => {
  const [superAdminReportState, dispatcher] = useImmerReducer(
    superAdminReportReducer,
    initialSuperAdminReportManagementState
  );

  return (
    <SuperAdminReportDispatcherContext.Provider value={dispatcher}>
      <SuperAdminReportStateContext.Provider value={superAdminReportState}>
        {children}
      </SuperAdminReportStateContext.Provider>
    </SuperAdminReportDispatcherContext.Provider>
  );
};
