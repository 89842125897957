import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  IJobTypeAction,
  jobTypeReducer,
} from "../../action/general/JobTypeAction";
import {
  countAllResultModel,
  jobTypeViewModel,
  VendorCountAllResultModel,
} from "../../models/general/JobType";

export const JobTypeDispatcherContext = React.createContext<React.Dispatch<
  IJobTypeAction
> | null>(null);
export const JobTypeStateContext = React.createContext<IJobTypeManagementState | null>(
  null
);

export interface IJobTypeManagementProps extends RouteComponentProps<any> {}

export interface IJobTypeManagementState {
  visible: boolean;
  value: number;
  jobType: jobTypeViewModel;
  allCounts: countAllResultModel;
  vendorGetAllCount:VendorCountAllResultModel
}

export const initialJobTypeManagementState = {
  visible: false,
  value: 0,
  jobType: {} as jobTypeViewModel,
  allCounts: {} as countAllResultModel,
  vendorGetAllCount:{} as VendorCountAllResultModel
} as IJobTypeManagementState;

export const JobTypeContextProvider: React.FC = ({ children }) => {
  const [jobTypeState, dispatcher] = useImmerReducer(
    jobTypeReducer,
    initialJobTypeManagementState
  );

  return (
    <JobTypeDispatcherContext.Provider value={dispatcher}>
      <JobTypeStateContext.Provider value={jobTypeState}>
        {children}
      </JobTypeStateContext.Provider>
    </JobTypeDispatcherContext.Provider>
  );
};
