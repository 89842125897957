import React, { useRef, useState } from "react";
import { Link, useRouteMatch, withRouter } from "react-router-dom";
import { useMyProfileContext } from "../../../action/MyProfileAction";
import AuthService from "./../../../services/AuthService";
import { useHistory } from "react-router-dom";
import {
  useCandidateContext,
  useCandidateProfileImageContext,
} from "../../../context/candidate/CandidateMyProfile";
import { getCandidates } from "../../../apis/candidate";
import {
  useVendorContext,
  useVendorProfileImageContext,
} from "../../../context/vendor/VendorMyProfile";
import { useVendorMyProfileContext } from "../../../action/MyProfileVendorAction";
import {
  useSuperAdminContext,
  useSuperAdminProfileImageContext,
} from "../../../context/superadmin/SuperAdminMyProfile";
import { useSuperAdminMyProfileContext } from "../../../action/MyProfileSuperAdminAction";
import { getSuperAdmins } from "../../../apis/superadmin";
import {
  useAdminContext,
  useAdminProfileImageContext,
} from "../../../context/admin/AdminMyProfile";
import { useAdminMyProfileContext } from "../../../action/MyProfileAdminAction";
import { updateAccessToken } from "../../../apis/misc";
import {
  getSocialAccountUrlList,
  useSocialAccountUrlContext,
  useSocialAccountUrlDispatcher,
} from "../../../action/admin/SocialAccountUrlAction";

import { SocialAccountUrlRequestModel } from "../../../models/admin/SocialAccountUrl";
import { getDocumentStatus } from "./../../../apis/vendor";
import { AdminPermissionRequestModel } from "../../../models/admin/AdminMyJob";
import {
  GetAdminPermission,
  useAdminMyJobContext,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";

interface IIndexHeaderComponentProps {}

interface IIndexHeaderComponentState {}

const initialState = {};

const IndexHeaderComponent: React.FC<IIndexHeaderComponentProps> = (
  props: any
) => {
  const [
    IndexHeaderComponentState,
    setIndexHeaderComponentState,
  ] = React.useState<IIndexHeaderComponentState>(initialState);
  const [socialURL, setSocialURL] = useState({
    facebook: "#",
    google: "#",
    linkedIn: "#",
    twitter: "#",
    instagram: "#",
    youtube: "#",
    contact: "086 834 2525",
    email: "info@jit.com",
  });
  const myProfileContext = useMyProfileContext();
  const { myProfile, loggedUserId, profileImage, basicInfo } = myProfileContext;
  const cl = props.location.pathname;
  // console.log(76767, cl);
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const candidateProfileImageContext = useCandidateProfileImageContext();

  const {
    candidateProfileImage,
    getCandidateProfileImage,
  } = candidateProfileImageContext;
  const candidateContext = useCandidateContext();
  const { getCandidates, candidateState } = candidateContext;

  const myVendorProfileContext = useVendorMyProfileContext();
  // const { loggedUserId } = myVendorProfileContext;

  const VendorProfileImageContext = useVendorProfileImageContext();
  const {
    vendorProfileImage,
    getVendorProfileImage,
  } = VendorProfileImageContext;

  const vendorContext = useVendorContext();
  const { getVendors, vendorState } = vendorContext;

  const mySuperAdminProfileContext = useSuperAdminMyProfileContext();
  const { loggedSuperAdminId } = mySuperAdminProfileContext;

  const SuperAdminProfileImageContext = useSuperAdminProfileImageContext();
  const {
    superAdminProfileImage,
    getSuperAdminProfileImage,
  } = SuperAdminProfileImageContext;

  const superadminContext = useSuperAdminContext();
  const { getSuperAdmins, superAdminState } = superadminContext;

  const myAdminProfileContext = useAdminMyProfileContext();
  const { loggedAdminId } = myAdminProfileContext;

  const AdminProfileImageContext = useAdminProfileImageContext();
  const { adminProfileImage, getAdminProfileImage } = AdminProfileImageContext;

  const adminContext = useAdminContext();
  const { getAdmins, adminState } = adminContext;

  const authorizationToken = AuthService.accessToken;
  const user = AuthService.currentUser;
  const [name, setName] = useState("");

  const [documentStatus, setDocumentStatus] = useState();

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;

  console.log(500000, user?.roles[0]);

  let role = AuthService.currentUser?.roles[0];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onLogout = () => {
    updateAccessToken(
      {},
      `?id=${AuthService.currentUser?.id}&accessToken=${
        AuthService.accessToken
      }&login=${false}`
    )
      .then((res: any) => {
        console.log("updated access token ", res.data);
      })
      .catch((err: any) => {
        console.log("error in updating access token", err);
      });
    AuthService.logout();
    props.history.push("/");
  };

  // menu close onClick outside
  const menuRef = useRef<any>();

  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // console.log(44444444444444444444, profileImage);
  // console.log(3333333333333333, superAdminProfileImage);

  const candidateId = loggedUserId;
  const SocialAccountUrlDispatcher = useSocialAccountUrlDispatcher();
  const SocialAccountUrlContext = useSocialAccountUrlContext();
  const { SocialAccountUrl } = SocialAccountUrlContext;
  // console.log("----------SocialAccountUrl-----------", SocialAccountUrl);

  React.useEffect(() => {
    if (candidateId != null && candidateId != 0)
      (async () => {
        await getCandidates({
          CandidateId: Number(candidateId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "",
          SortOrder: "",
          ShowInactive: false,
        });
      })();
  }, [candidateId]);

  React.useEffect(() => {
    if (candidateId) {
      getCandidateProfileImage({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [candidateId]);

  React.useEffect(() => {
    if (loggedUserId) {
      getVendors({
        vendorId: Number(loggedUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (loggedUserId) {
      getVendorProfileImage({
        vendorId: Number(loggedUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (loggedSuperAdminId) {
      getSuperAdmins({
        superadminId: Number(loggedSuperAdminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [loggedSuperAdminId]);

  React.useEffect(() => {
    if (loggedSuperAdminId) {
      getSuperAdminProfileImage({
        superadminId: Number(loggedSuperAdminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [loggedSuperAdminId]);

  React.useEffect(() => {
    if (loggedAdminId) {
      getAdmins({
        adminId: Number(loggedAdminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [loggedAdminId]);

  React.useEffect(() => {
    if (loggedAdminId) {
      getAdminProfileImage({
        adminId: Number(loggedAdminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [loggedAdminId]);

  React.useEffect(() => {
    if (loggedUserId) {
      getDocumentStatus({
        VendorId: Number(loggedUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log("uploaded-document-status success", res.data);
          setDocumentStatus(res.data);
        })
        .catch((err) => {
          console.log("uploaded-document-status error", err);
        });
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await GetAdminPermission(
          adminMyJobDispatcher,
          {
            AdminId: loggedAdminId,
          } as AdminPermissionRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedAdminId]);

  console.log(
    loggedAdminId,
    " - GetAdminPermission Index Page - ",
    AdminPermissionResult
  );

  React.useEffect(() => {
    if (candidateState.data != undefined && candidateState.data.length > 0) {
      setName(candidateState.data[0]["firstName"]);
    }
  }, [candidateState]);

  React.useEffect(() => {
    if (vendorState.data != undefined && vendorState.data.length > 0) {
      setName(vendorState.data[0]["vendorName"]);
    }
  }, [vendorState]);

  React.useEffect(() => {
    if (superAdminState.data != undefined && superAdminState.data.length > 0) {
      setName(superAdminState.data[0]["superAdminName"]);
    }
  }, [superAdminState]);

  React.useEffect(() => {
    if (adminState.data != undefined && adminState.data.length > 0) {
      setName(adminState.data[0]["adminName"]);
    }
  }, [adminState]);

  const headerNavigation = () => {
    if (user?.roles[0] === "Candidate") {
      return (
        <div
          className="action_btn1"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <button type="button" className="jobs_action">
            {/* {basicInfo.fullName != undefined
            ? basicInfo.fullName
            : ""}{" "} */}
            {name}
            <i className="fa fa-angle-down" aria-hidden="true" />
          </button>
          <div className="login_jobs">
            {/* <img
            src={
              profileImage != null && profileImage.total > 0
                ? `https://careerapp.azurewebsites.net/Upload/ProfileImage/${profileImage.data[0].imageUrl}`
                : require("../../../images/profileDefault1.jpg")
            }
          /> */}
            {candidateProfileImage.data.length > 0 ? (
              <img
                className="img-responsive"
                src={`https://careerapp.azurewebsites.net/Upload/ProfileImage/${candidateProfileImage.data[0]["imageUrl"]}`}
                alt=""
              />
            ) : (
              <img
                className="img-responsive"
                src={require("./../../../images/profileDefault1.jpg")}
              ></img>
            )}
          </div>

          <div
            className="jobs_action_dp"
            tabIndex={-1}
            style={{ display: `${isOpen ? "block" : "none"}` }}
          >
            <div className="action_sec">
              <ul ref={menuRef}>
                <li>
                  <Link to="/candidate">
                    {" "}
                    <i className="fa fa-th-large" aria-hidden="true" />
                    Dashboard{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/jobs-applied">
                    {" "}
                    <i className="fa fa-briefcase" aria-hidden="true" />
                    Job Applied{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/digilocker/3">
                    {" "}
                    <i className="fa fa-lock" aria-hidden="true" />
                    {name.split(" ")[0]}
                    's DigiLocker{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/my-resume">
                    {" "}
                    <i className="fa fa-files-o" aria-hidden="true" />
                    My Resumes{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/messages">
                    {" "}
                    <i className="fa fa-commenting-o" aria-hidden="true" />
                    Messages{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/saved-jobs">
                    {" "}
                    <i className="fa fa-suitcase" aria-hidden="true" />
                    Saved Jobs{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/notify-jobs/0">
                    {" "}
                    <i className="fa fa-suitcase" aria-hidden="true" />
                    Notify Jobs{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/my-profile">
                    {" "}
                    <i className="fa fa-user-o" aria-hidden="true" />
                    My Profile{" "}
                  </Link>
                </li>

                {/* <li>
                <Link to="/candidate">DashBoard</Link>
              </li> */}
                <li>
                  <a onClick={onLogout} className="_cursor-pointer">
                    <i className="fa fa-power-off" aria-hidden="true"></i>
                    Logout
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else if (user?.roles[0] === "Vendor") {
      return (
        <div
          className="action_btn1"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <button type="button" className="jobs_action">
            {basicInfo.fullName != undefined ? basicInfo.fullName : ""}{" "}
            <i className="fa fa-angle-down" aria-hidden="true" />
          </button>
          <div className="login_jobs">
            {/* <img
            src={
              profileImage != null && profileImage.total > 0
                ? `https://careerapp.azurewebsites.net/Upload/ProfileImage/${profileImage.data[0].imageUrl}`
                : require("../../../images/profileDefault1.jpg")
            }
          /> */}
            {vendorProfileImage.data.length > 0 ? (
              <img
                className="img-responsive"
                src={`https://careerapp.azurewebsites.net/Upload/VendorProfileImage/${vendorProfileImage.data[0]["imageUrl"]}`}
              />
            ) : (
              <img
                className="img-responsive"
                src={require("./../../../images/profileDefault1.jpg")}
              ></img>
            )}
          </div>

          <div
            className="jobs_action_dp"
            tabIndex={-1}
            style={{ display: `${isOpen ? "block" : "none"}` }}
          >
            <div className="action_sec">
              <ul ref={menuRef}>
                <li>
                  <Link to="/vendor">
                    {" "}
                    <i className="fa fa-th-large" aria-hidden="true" />
                    Dashboard{" "}
                  </Link>
                </li>
                {documentStatus !== undefined &&
                documentStatus === "Document verification status - Approved" ? (
                  <li>
                    <Link to="/vendor/jobs-applied">
                      {" "}
                      <i className="fa fa-briefcase" aria-hidden="true" />
                      Job Applied{" "}
                    </Link>
                  </li>
                ) : (
                  <div></div>
                )}
                <li>
                  <Link to="/vendor/messages">
                    {" "}
                    <i className="fa fa-commenting-o" aria-hidden="true" />
                    Messages{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/saved-jobs">
                    {" "}
                    <i className="fa fa-suitcase" aria-hidden="true" />
                    Saved Jobs{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/my-profile">
                    {" "}
                    <i className="fa fa-user-o" aria-hidden="true" />
                    My Profile{" "}
                  </Link>
                </li>
                {/* <li>
                <Link to="/candidate">DashBoard</Link>
              </li> */}
                <li>
                  <a onClick={onLogout} className="_cursor-pointer">
                    <i className="fa fa-power-off" aria-hidden="true"></i>
                    Logout
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else if (user?.roles[0] === "SuperAdmin") {
      return (
        <div
          className="action_btn1"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <button type="button" className="jobs_action">
            {basicInfo.fullName != undefined ? basicInfo.fullName : ""}{" "}
            <i className="fa fa-angle-down" aria-hidden="true" />
          </button>
          <div className="login_jobs">
            {/* <img
            src={
              profileImage != null && profileImage.total > 0
                ? `https://careerapp.azurewebsites.net/Upload/ProfileImage/${profileImage.data[0].imageUrl}`
                : require("../../../images/profileDefault1.jpg")
            }
          /> */}
            {superAdminProfileImage.data.length > 0 ? (
              <img
                className="img-responsive"
                src={`https://careerapp.azurewebsites.net/Upload/SuperAdminProfileImage/${superAdminProfileImage.data[0]["imageUrl"]}`}
              />
            ) : (
              <img
                className="img-responsive"
                src={require("./../../../images/profileDefault1.jpg")}
              ></img>
            )}
          </div>

          <div
            className="jobs_action_dp"
            tabIndex={-1}
            style={{ display: `${isOpen ? "block" : "none"}` }}
          >
            <div className="action_sec">
              <ul ref={menuRef}>
                <li>
                  <Link to="/super_admin" className="active">
                    {" "}
                    <i className="fa fa-th-large" aria-hidden="true" />
                    Dashboard{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/super_admin/my_user" className="active">
                    {" "}
                    <i className="fa fa-commenting-o" aria-hidden="true" />
                    My User{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/super_admin/messages" className="active">
                    {" "}
                    <i className="fa fa-commenting-o" aria-hidden="true" />
                    Messages{" "}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/admin/approval_careertips" className="active">
                    {" "}
                    <i className="fa fa-commenting-o" aria-hidden="true" />
                    Career Tips{" "}
                  </Link>
                </li> */}
                <li>
                  <Link to="/super_admin/my-profile" className="active">
                    {" "}
                    <i className="fa fa-user-o" aria-hidden="true" />
                    My Profile{" "}
                  </Link>
                </li>
                {/* <li>
                <Link to="/candidate">DashBoard</Link>
              </li> */}
                <li>
                  <a onClick={onLogout} className="_cursor-pointer">
                    <i className="fa fa-power-off" aria-hidden="true"></i>
                    Logout
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else if (user?.roles[0] === "Admin") {
      return (
        <div
          className="action_btn1"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <button type="button" className="jobs_action">
            {basicInfo.fullName != undefined ? basicInfo.fullName : ""}{" "}
            <i className="fa fa-angle-down" aria-hidden="true" />
          </button>
          <div className="login_jobs">
            {/* <img
            src={
              profileImage != null && profileImage.total > 0
                ? `https://careerapp.azurewebsites.net/Upload/ProfileImage/${profileImage.data[0].imageUrl}`
                : require("../../../images/profileDefault1.jpg")
            }
          /> */}
            {adminProfileImage.data.length > 0 ? (
              <img
                className="img-responsive"
                src={`https://careerapp.azurewebsites.net/Upload/AdminProfileImage/${adminProfileImage.data[0]["imageUrl"]}`}
              />
            ) : (
              <img
                className="img-responsive"
                src={require("./../../../images/profileDefault1.jpg")}
              ></img>
            )}
          </div>

          <div
            className="jobs_action_dp"
            tabIndex={-1}
            style={{ display: `${isOpen ? "block" : "none"}` }}
          >
            <div className="action_sec">
              <ul ref={menuRef}>
                <li>
                  <Link to="/admin" className="active">
                    {" "}
                    <i className="fa fa-th-large" aria-hidden="true" />
                    Dashboard{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/my_user" className="active">
                    {" "}
                    <i className="fa fa-users" aria-hidden="true" />
                    My User{" "}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/super_admin/messages" className="active">
                    {" "}
                    <i className="fa fa-commenting-o" aria-hidden="true" />
                    Messages{" "}
                  </Link>
                </li> */}
                <li>
                  <Link to="/admin/careerdeveloper" className="active">
                    {" "}
                    <i className="fa fa-files-o" aria-hidden="true" />
                    Career Developers{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/my-profile" className="active">
                    {" "}
                    <i className="fa fa-user-o" aria-hidden="true" />
                    My Profile{" "}
                  </Link>
                </li>
                {/* <li>
                <Link to="/candidate">DashBoard</Link>
              </li> */}
                <li>
                  <a onClick={onLogout} className="_cursor-pointer">
                    <i className="fa fa-power-off" aria-hidden="true"></i>
                    Logout
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
  };
  React.useEffect(() => {
    if (SocialAccountUrl.data === undefined) {
      (async () => {
        await getSocialAccountUrlList(
          SocialAccountUrlDispatcher,
          {
            adminId: 0,
            page: 1,
            pageSize: 60,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as SocialAccountUrlRequestModel,
          ""
        );
      })();
    }
  }, [SocialAccountUrl.data]);
  React.useEffect(() => {
    //   setSocialURL( prevValues => {
    //     return { ...prevValues,[e.target.name]: e.target.value}
    //  }
    // { facebook: "#", linkedIn: "#", twitter: "#", instagram: "#", youtube: '#', contact: "086 834 2525", email: "info@jit.com" });
    if (SocialAccountUrl.data != undefined) {
      SocialAccountUrl.data.forEach((item: any) => {
        // console.log(item);
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("facebook")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["facebook"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("google")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["google"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("twitter")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["twitter"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("instagram")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["instagram"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("youtube")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["youtube"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("contact")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["contact"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"]).toLowerCase().includes("email")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["email"]: item["linkUrl"] };
          });
        }
      });
    }
  }, [SocialAccountUrl.data]);
  return (
    <>
      <div className="top_sec">
        <div className=" container">
          <div className="row">
            <div className="col-sm-6">
              <div className="top_media">
                <a href={socialURL.facebook} target="_blank">
                  <i className="fa fa-facebook" aria-hidden="true" />
                </a>
                <a href={socialURL.twitter} target="_blank">
                  <i className="fa fa-twitter" aria-hidden="true" />
                </a>
                <a href={socialURL.instagram} target="_blank">
                  <i className="fa fa-instagram" aria-hidden="true" />
                </a>
                <a href={socialURL.youtube} target="_blank">
                  <i className="fa fa-youtube-play" aria-hidden="true" />
                </a>

                <a href="#">
                  <i className="fa fa-phone" aria-hidden="true" />
                  {/* 086 8342525 */}
                  {socialURL.contact}
                </a>
                <a href="#">
                  <i className="fa fa-envelope-o" aria-hidden="true" />{" "}
                  {/* info@jit.com */}
                  {socialURL.email}
                </a>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="language_select">
                <span className="select-wrapper-lan">
                  <select name="timepass" className="laguage">
                    <option value="">Engilsh</option>
                  </select>
                  <span className="holder">English</span>
                </span>
              </div>
              {/* <div className="top_links">
                    {authorizationToken ? <a onClick={onLogout} className="_cursor-pointer">Logout</a> :
                      <>
                        <Link to='/login'>Login</Link>
                        <Link to='/registration'>Register</Link>
                      </>}
                  </div> */}

              {authorizationToken ? (
                <>{headerNavigation()}</>
              ) : (
                <>
                  <div className="top_links">
                    <>
                      <Link to="/login">Login</Link>
                      <Link to="/registration">Register</Link>
                    </>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="header headerfix">
        <header>
          <div className=" container">
            <div className="row">
              <div className="col-sm-2">
                <div className=" logo">
                  <a
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    <img
                      src={require("../../../images/logo.png")}
                      // src="images/logo.png"
                      className="img-responsive"
                      alt=""
                    />{" "}
                  </a>
                </div>
              </div>
              <div className="col-sm-10">
                <div className="bs-example">
                  <nav
                    role="navigation"
                    className="navbar navbar-default navbar-static"
                    id="navbar-example"
                  >
                    <div className="navbar-header">
                      <button
                        data-target=".bs-example-js-navbar-collapse"
                        data-toggle="collapse"
                        type="button"
                        className="navbar-toggle"
                      >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                      </button>
                      <div className="collapse navbar-collapse bs-example-js-navbar-collapse">
                        <ul className="nav navbar-nav">
                          <li className="activefix">
                            <Link to="/" className={cl === "/" ? "active" : ""}>
                              {" "}
                              Home
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/career_developer"
                              className={
                                cl === "/career_developer" ? "active" : ""
                              }
                            >
                              Career Developer
                            </Link>
                          </li>
                          {/* {authorizationToken != null ? (
                            role === "client" ? (
                              <li>
                                {" "}
                                <Link
                                  to="/coming_soon"
                                  className={
                                    cl === "/coming_soon" ? "active" : ""
                                  }
                                >
                                  Client{" "}
                                </Link>
                              </li>
                            ) : null
                          ) : (
                            <li>
                              {" "}
                              <Link
                                to="/coming_soon"
                                className={
                                  cl === "/coming_soon" ? "active" : ""
                                }
                              >
                                Client{" "}
                              </Link>
                            </li>
                          )} */}

                          {/* {authorizationToken != null ? (
                            role === "vendor" ? (
                              <li>
                                {" "}
                                <Link
                                  to="/vendor"
                                  className={cl === "/vendor" ? "active" : ""}
                                >
                                  Vendor
                                </Link>{" "}
                              </li>
                            ) : null
                          ) : (
                            <li>
                              {" "}
                              <Link
                                to="/vendor"
                                className={cl === "/vendor" ? "active" : ""}
                              >
                                Vendor
                              </Link>{" "}
                            </li>
                          )} */}

                          <li>
                            <Link
                              to={`/help`}
                              className={cl === "/help" ? "active" : ""}
                            >
                              Help
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/about_us"
                              className={cl === "/about_us" ? "active" : ""}
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact_us"
                              className={cl === "/contact_us" ? "active" : ""}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};
export default withRouter(IndexHeaderComponent);
