import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { IJobAppliedAction, jobAppliedReducer } from "../action/JobAppliedAction";
import { jobAppliedCandidateResulViewtModel, jobAppliedSaveResultModel, jobAppliedViewModel } from "../models/candidate/JobApplied";

export const JobAppliedDispatcherContext = React.createContext<React.Dispatch<IJobAppliedAction> | null>(null);
export const JobAppliedStateContext = React.createContext<IJobAppliedManagementState | null>(null);

export interface IJobAppliedManagementProps extends RouteComponentProps<any> { }

export interface IJobAppliedManagementState {
    visible:boolean;
    value:number;
    jobApplied:jobAppliedViewModel
    jobAppliedSaveResultData:jobAppliedSaveResultModel
    jobAppliedCandidateResult:jobAppliedCandidateResulViewtModel
}

export const initialJobAppliedManagementState = {
    visible:false,
    value:0,
    jobApplied:{} as jobAppliedViewModel,
    jobAppliedSaveResultData:{} as jobAppliedSaveResultModel,
    jobAppliedCandidateResult:{} as jobAppliedCandidateResulViewtModel
} as IJobAppliedManagementState;

export const JobAppliedContextProvider: React.FC = ({ children }) => {
    const [jobAppliedState, dispatcher] = useImmerReducer(jobAppliedReducer, initialJobAppliedManagementState);

    return (
        <JobAppliedDispatcherContext.Provider value={dispatcher}>
            <JobAppliedStateContext.Provider value={jobAppliedState}>
                {children}
            </JobAppliedStateContext.Provider>
        </JobAppliedDispatcherContext.Provider>
    )
}