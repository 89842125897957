import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import SelectOption from "./components/SelectOption";
import PikadayDatepicker from "./components/PikadayDatepicker";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import {
  saveCandidateExperiences,
  deleteCandidateExperiences,
} from "./../../../apis/candidate";
import moment from "moment";
import { confirmWrapper, confirm } from "./../../GlobalConfirm";
import { ToastContainer, toast } from "react-toastify";
import { GlobalSpinnerContext } from "./../../../context/GlobalSpinner";
import { ErrorMessage } from "@hookform/error-message";
import IsComponentDisabled from "../../components/IsComponentDisabled";
import { Empty } from "antd";

type objectIndexing = {
  [key: string]: any;
};

interface IExperianceState {
  employer: string;
  location: string;
  role: string;
  fromDate: number;
  toDate: number;
  currentlyWorkingHere: boolean;
  responsibilities: string;
  achievements: string;
}

interface ReactSelectOption {
  value: string;
  label: string;
}

function AddExperiance({
  isOpen2,
  setIsOpen2,
  candidateExperienceSelectBoxData,
  candidateExperiences,
  getCandidateExperiences,
  userId,
  candidateId,
  nationalities,
}: {
  isOpen2: any;
  setIsOpen2: any;
  candidateExperienceSelectBoxData: any;
  candidateExperiences: [];
  getCandidateExperiences: any;
  userId: any;
  candidateId: any;
  nationalities: ReactSelectOption[];
}) {
  const defaultValues = {
    employer: "",
    location: "",
    role: "",
    fromDate: 0,
    toDate: 0,
    currentlyWorkingHere: false,
    responsibilities: "",
    achievements: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IExperianceState>({
    defaultValues,
  });

  const globalSpinner = useContext(GlobalSpinnerContext);
  // console.log(8888, watch("currentlyWorkingHere"));

  const onSubmit = (data: any) => {
    // console.log(data);

    globalSpinner.showSpinner();

    saveCandidateExperiences({
      RowId: 0,
      CandidateExperienceId: 0,
      CandidateId: Number(candidateId),
      EmployerName: data["employer"],
      //"LocationId": data["nationality"],
      LocationId: data["location"],
      JobRole: data["role"],
      FromDate: new Date(data["fromDate"]),
      ToDate: data["currentlyWorkingHere"] ? null : new Date(data["toDate"]),
      CurrentlyWorkHereFlag: data["currentlyWorkingHere"],
      Responsibilities: data["responsibilities"],
      Achievements: data["achievements"],
      IsActive: true,
    })
      .then((res) => {
        console.log(res.data);
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          getCandidateExperiences({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
            ShowInactive: false,
          });
          toast.success(res.data.message);
          setIsOpen2(!isOpen2);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error(err.toString());
      });
  };

  const [isNewDocument, setIsNewDocument] = useState<boolean>(true);
  const [disabledOne, setDisabledOne] = useState<boolean>(true);
  const [isFirstTab, setIsFirstTab] = useState<boolean>(true);

  let roles: ReactSelectOption[] = [];

  React.useEffect(() => {
    if (
      watch("fromDate") == watch("toDate") &&
      !!watch("fromDate") &&
      !!watch("toDate")
    ) {
      setValue("currentlyWorkingHere", true);
    } else if (
      watch("fromDate") > watch("toDate") &&
      !!watch("fromDate") &&
      !!watch("toDate")
    ) {
      toast.error("From date must be less than To date");
    } else if (watch("currentlyWorkingHere")) {
      setValue("toDate", 0);
      setValue("fromDate", watch("fromDate"));
      setDisabledOne(false);
    } else {
      setDisabledOne(true);
      setValue("fromDate", watch("fromDate"));
      console.log(7777777);
    }
  }, [watch("currentlyWorkingHere"), watch("fromDate"), watch("toDate")]);

  if (!_.isEmpty(candidateExperienceSelectBoxData)) {
    roles = candidateExperienceSelectBoxData.jobRoles.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Modal.Header closeButton>
        <Modal.Title>Add Experience</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="email" className={"required"}>
              Employer
            </label>
            <input
              type="text"
              name="employer"
              className="form-control"
              placeholder="Type here"
              ref={register({
                required: "Employer is required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="employer"
              render={({ message }) => (
                <div className="profile_validation">{message}</div>
              )}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="email" className={"required"}>
              Location{" "}
            </label>
            <input
              type="text"
              name="location"
              disabled={false}
              className="form-control"
              placeholder="Type here"
              ref={register({
                required: "Location is required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="location"
              render={({ message }) => (
                <div className="profile_validation">{message}</div>
              )}
            />
            {/* <span className="select-wrapper-sec">
              <Controller
                rules={{
                  required: "Location is required"
                }}
                control={control}
                name="location"
                render={({ onChange, onBlur, value, name }) => (
                  <SelectOption values={nationalities} disabled={false} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                )}
              />
            </span>
            <ErrorMessage errors={errors} name="location" render={({ message }) => <div className="profile_validation">{message}</div>} /> */}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="email" className={"required"}>
              Role{" "}
            </label>
            <input
              type="text"
              name="role"
              className="form-control"
              placeholder="Type here"
              ref={register({
                required: "Role is required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="role"
              render={({ message }) => (
                <div className="profile_validation">{message}</div>
              )}
            />
            {/* <span className="select-wrapper-sec">
              <Controller
                control={control}
                name="role"
                render={({ onChange, onBlur, value, name }) => (
                  <SelectOption values={roles} disabled={false} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                )}
              />
            </span> */}
          </div>
        </div>
        <div className="col-sm-4">
          <label className={"control-label required"}>From Date</label>
          <Controller
            rules={{
              validate: (value) => {
                if (value <= 0) {
                  return "Required from date.";
                } else if (value > Date.now()) {
                  return "From Date cannot be a future date.";
                } else if (value >= Number(watch("toDate")) && disabledOne) {
                  return "Must be less than To date.";
                } else {
                  return undefined;
                }
                // return value < Date.now() ? undefined : "DOB cannot be a future date.";
              },
            }}
            control={control}
            name="fromDate"
            render={({ onChange, onBlur, value, name }) => (
              <PikadayDatepicker
                disabled={false}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="fromDate"
            render={({ message }) => (
              <div className="login_validation">{message}</div>
            )}
          />
        </div>
        <div className="col-sm-4">
          <label className={"control-label required"}>To Date</label>
          <Controller
            rules={{
              validate: (value) => {
                if (value <= 0) {
                  if (watch("currentlyWorkingHere")) return undefined;
                  return "Required to date.";
                } else if (value > Date.now()) {
                  return "To Date cannot be a future date.";
                } else if (value <= Number(watch("fromDate"))) {
                  return "Must be grater than From date.";
                } else {
                  return undefined;
                }
                // return value < Date.now() ? undefined : "DOB cannot be a future date.";
              },
            }}
            control={control}
            name="toDate"
            render={({ onChange, onBlur, value, name }) => (
              <PikadayDatepicker
                disabled={watch("currentlyWorkingHere")}
                overrideCursor={true}
                onChange={onChange}
                onBlur={onBlur}
                value={value != null ? value : 0}
                name={name}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="toDate"
            render={({ message }) => (
              <div className="login_validation">
                {watch("currentlyWorkingHere") ? "" : message}
              </div>
            )}
          />
        </div>

        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="email"></label>
            <div style={{ paddingTop: "6px" }}>
              <input
                id="checkbox1"
                type="checkbox"
                name="currentlyWorkingHere"
                ref={register}
                className="_cursor-pointer"
              />
              <label htmlFor="checkbox1">
                <span className="_add-experience-checkbox _cursor-pointer"></span>{" "}
                &nbsp;
              </label>
              <span>Currently working here.</span>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="email" className={"required"}>
              Project Description / Responsibilities
            </label>
            <textarea
              name="responsibilities"
              className={"form-control form-contro11 required"}
              placeholder="Type here"
              disabled={false}
              ref={register({
                required: "Project Description / Responsibilities is required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="responsibilities"
              render={({ message }) => (
                <div className="profile_validation">{message}</div>
              )}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label
              htmlFor="email"
              // className={"required"}
            >
              Achievements{" "}
            </label>
            <textarea
              name="achievements"
              className={"form-control form-contro11 required"}
              placeholder="Type here"
              disabled={false}
              ref={register({
                // required: "Achievements cannot be blank.",
                required: false,
                // validate: (value) => {
                //   //exclude  start and end white-space
                //   value = value.replace(/(^\s*)|(\s*$)/gi, "");
                //   //convert 2 or more spaces to 1
                //   value = value.replace(/[ ]{2,}/gi, " ");
                //   // exclude newline with a start spacing
                //   value = value.replace(/\n /, "\n");

                //   if (value.split(" ").length < 40) {
                //     return "Minimum 40 words are required in responsibilities.";
                //   } else if (value.split(" ").length > 120) {
                //     return "Maximum words: 120";
                //   } else {
                //     return undefined;
                //   }
                // },
              })}
            />
            {/* <ErrorMessage
              errors={errors}
              name="achievements"
              render={({ message }) => (
                <div className="profile_validation">{message}</div>
              )}
            /> */}
          </div>
        </div>
        <div className="clearfix" />
      </Modal.Body>

      <div className="modal-footer  m-t-30">
        <button
          className="btn btn-success save-event waves-effect waves-light"
          type="submit"
        >
          Save
        </button>
        <button
          onClick={() => {
            setIsOpen2(!isOpen2);
          }}
          className="btn btn-default waves-effect"
          type="button"
        >
          Cancel
        </button>
      </div>
      <div className="clearfix" />
    </form>
  );
}

function RenderExperience({
  index,
  item,
  disabled,
  candidateExperienceSelectBoxData,
  removeExperience,
  updateExperience,
  candidateAchievementsState,
  nationalities,
}: {
  index: any;
  item: any;
  disabled: boolean;
  candidateExperienceSelectBoxData: any;
  removeExperience: (e: any, i: any) => void;
  updateExperience: (e: any, i: any) => void;
  candidateAchievementsState: any;
  nationalities: ReactSelectOption[];
}) {
  console.log(item);
  const defaultValues = {
    employer: item["employerName"],
    location: item["locationId"],
    role: item["jobRole"],
    fromDate: moment(item["fromDate"], "DD/MM/YYYY").toDate().getTime(),
    toDate:
      item["toDate"] != null
        ? moment(item["toDate"], "DD/MM/YYYY").toDate().getTime()
        : item["currentlyWorkHereFlag"]
        ? new Date().getTime()
        : 0,
    currentlyWorkingHere: item["currentlyWorkHereFlag"],
    responsibilities: item["responsibilities"],
    achievements: item["achievements"],
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IExperianceState>({
    defaultValues,
  });
  const [disabledOne, setDisabledOne] = useState<boolean>(true);

  const onSubmit = (data: any) => {
    if (!disabled) {
      console.log(data);
      updateExperience(item, data);
    }
  };

  let roles: ReactSelectOption[] = [];

  if (!_.isEmpty(candidateExperienceSelectBoxData)) {
    roles = candidateExperienceSelectBoxData.jobRoles.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  React.useEffect(() => {
    if (
      watch("fromDate") == watch("toDate") &&
      !!watch("fromDate") &&
      !!watch("toDate")
    ) {
      setValue("currentlyWorkingHere", true);
    } else if (
      watch("fromDate") > watch("toDate") &&
      !!watch("fromDate") &&
      !!watch("toDate")
    ) {
      toast.error("From date must be less than To date");
    } else if (watch("currentlyWorkingHere")) {
      setValue("toDate", 0);
      setValue("fromDate", watch("fromDate"));
    } else {
      setValue("fromDate", watch("fromDate"));
      console.log(7777777);
    }
  }, [watch("currentlyWorkingHere"), watch("fromDate"), watch("toDate")]);

  return (
    <div className=" profile_sec_bg">
      <div
        className="close_icon1 _cursor-pointer"
        onClick={() => {
          removeExperience(item, index);
        }}
      >
        <img
          src={require("./../../../images/close_icon.png")}
          width={16}
          height={16}
        />
      </div>
      <div className="row">
        <form
          className="personal_details"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Employer
              </label>
              {IsComponentDisabled(
                <input
                  type="text"
                  name="employer"
                  disabled={disabled}
                  className={
                    disabled ? "form-control _cursor-no-drop" : "form-control"
                  }
                  placeholder="Type here"
                  ref={register({
                    required: "Employer is required",
                  })}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="employer"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Location{" "}
              </label>
              {/* <span className="select-wrapper-sec">
                <Controller
                  control={control}
                  name="location"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption values={nationalities} disabled={disabled} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                />
              </span> */}
              {IsComponentDisabled(
                <input
                  type="text"
                  name="location"
                  disabled={disabled}
                  className="form-control"
                  placeholder="Type here"
                  ref={register({
                    required: "Location is required",
                  })}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="location"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Role{" "}
              </label>
              {IsComponentDisabled(
                <input
                  type="text"
                  name="role"
                  disabled={disabled}
                  className={
                    disabled ? "form-control _cursor-no-drop" : "form-control"
                  }
                  placeholder="Type here"
                  ref={register({
                    required: "Role is required",
                  })}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="role"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
              {/* <span className="select-wrapper-sec">
                <Controller
                  control={control}
                  name="role"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption values={roles} disabled={disabled} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                />
              </span> */}
            </div>
          </div>
          <div className="col-sm-4">
            <label className={"control-label required"}>From Date</label>
            {IsComponentDisabled(
              <Controller
                rules={{
                  validate: (value) => {
                    if (value <= 0) {
                      return "Required from date.";
                    } else if (value > Date.now()) {
                      return "From Date cannot be a future date.";
                    } else if (
                      value >= Number(watch("toDate")) &&
                      disabledOne
                    ) {
                      //console.log(watch("toDate"));
                      return "Must be less than To date.";
                    } else {
                      return undefined;
                    }
                    // return value < Date.now() ? undefined : "DOB cannot be a future date.";
                  },
                }}
                control={control}
                name="fromDate"
                render={({ onChange, onBlur, value, name }) => (
                  <PikadayDatepicker
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                  />
                )}
              />,
              disabled
            )}
            <ErrorMessage
              errors={errors}
              name="fromDate"
              render={({ message }) => (
                <div className="login_validation">{message}</div>
              )}
            />
          </div>
          {!watch("currentlyWorkingHere") && (
            <div className="col-sm-4">
              <label className={"control-label required"}>To Date</label>
              {IsComponentDisabled(
                <Controller
                  rules={{
                    // validate: (value) => {
                    //   return value < Date.now()
                    //     ? undefined
                    //     : "To date cannot be a future date.";
                    // },
                    validate: (value) => {
                      if (value <= 0) {
                        if (watch("currentlyWorkingHere")) return undefined;
                        return "Required to date.";
                      } else if (value > Date.now()) {
                        return "To Date cannot be a future date.";
                      } else if (value <= Number(watch("fromDate"))) {
                        return "Must be greater than From date.";
                      } else {
                        return undefined;
                      }
                      // return value < Date.now() ? undefined : "DOB cannot be a future date.";
                    },
                  }}
                  control={control}
                  name="toDate"
                  render={({ onChange, onBlur, value, name }) => (
                    <PikadayDatepicker
                      disabled={watch("currentlyWorkingHere") || disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value != null ? value : 0}
                      name={name}
                    />
                  )}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="toDate"
                render={({ message }) => (
                  <div className="login_validation">
                    {watch("currentlyWorkingHere") ? "" : message}
                  </div>
                )}
              />
            </div>
          )}
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="email"></label>
              {IsComponentDisabled(
                <div style={{ paddingTop: "6px" }}>
                  <input
                    id="checkbox1"
                    type="checkbox"
                    disabled={disabled}
                    name="currentlyWorkingHere"
                    ref={register}
                    className={disabled ? "_cursor-no-drop" : "_cursor-pointer"}
                  />
                  <label htmlFor="checkbox1">
                    <span
                      className={
                        disabled
                          ? "_add-experience-checkbox _cursor-no-drop"
                          : "_add-experience-checkbox _cursor-pointer"
                      }
                    ></span>{" "}
                    &nbsp;
                  </label>
                  <span>Currently working here.</span>
                </div>,
                disabled
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Project Description / Responsibilities
              </label>
              {IsComponentDisabled(
                <textarea
                  name="responsibilities"
                  disabled={disabled}
                  className={
                    disabled
                      ? "form-control form-contro11 _cursor-no-drop"
                      : "form-control form-contro11"
                  }
                  placeholder="Type here"
                  ref={register({
                    required:
                      "Project Description / Responsibilities is required",
                  })}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="responsibilities"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label
                htmlFor="email"
                // className={"required"}
              >
                Achievements{" "}
              </label>
              {IsComponentDisabled(
                <textarea
                  name="achievements"
                  disabled={disabled}
                  className={
                    disabled
                      ? "form-control form-contro11 _cursor-no-drop"
                      : "form-control form-contro11"
                  }
                  placeholder="Type here"
                  ref={register({
                    // required: "Achievements cannot be blank.",
                    required: false,
                    // validate: (value) => {
                    //   if (value.split(" ").length < 40) {
                    //     return "Minimum 40 words are required in achievements.";
                    //   } else if (value.split(" ").length > 120) {
                    //     return "Maximum words: 120";
                    //   } else {
                    //     return undefined;
                    //   }
                    // },
                  })}
                />,
                disabled
              )}
              {/* <ErrorMessage
                errors={errors}
                name="achievements"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              /> */}
            </div>
          </div>
          <div className="col-sm-12">
            {/* <a className="btn submit_btn">Save</a> */}
            <button
              className="btn submit_btn"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

interface IExperienceState {}

function Experience(props: any) {
  const {
    candidateExperienceSelectBoxData,
    candidateExperiences,
    myProfile,
    getCandidateExperiences,
    userId,
    candidateId,
    candidateAchievementsState,
    getCandidateAchievements,
  } = props;

  console.log(343434, candidateExperiences);

  let nationalities: ReactSelectOption[] = [];

  if (!_.isEmpty(myProfile)) {
    nationalities = myProfile.countries.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  const defaultValues = {};

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IExperienceState>({
    defaultValues,
  });

  const onSubmit = (data: any) => console.log(data);

  const [disabled, setDisabled] = useState<boolean>(true);

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const removeExperience = async (e: any, i: any) => {
    if (
      await confirm({
        confirmation: "Are you sure you want to delete this?",
        options: {
          yes: "Yes",
          no: "No",
          header: "Delete",
        },
      })
    ) {
      console.log("yes");
      console.log(e, i);
      globalSpinner.showSpinner();
      deleteCandidateExperiences({ RowId: e.rowId })
        .then((res) => {
          console.log(res);
          globalSpinner.hideSpinner();
          if (res.data.isSuccess) {
            getCandidateExperiences({
              CandidateId: Number(candidateId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "",
              SortOrder: "",
              ShowInactive: false,
            });
            toast.success("Experience removed.");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          globalSpinner.hideSpinner();
          toast.error(err.toString());
        });
    } else {
      console.log("no");
      console.log(e, i);
    }
  };

  const updateExperience = async (item: any, data: any) => {
    console.log(item, data);

    const _data1 = Object.assign({}, item, data);

    console.log(22222, _data1);

    // _data1["fromDate"] = new Date(_data1["fromDate"]);
    // _data1["toDate"] = _data1["currentlyWorkingHere"]
    //   ? null
    //   : new Date(_data1["toDate"]);
    _data1["fromDate"] = moment(new Date(_data1["fromDate"])).format(
      "DD/MM/YYYY"
    );
    _data1["toDate"] = _data1["currentlyWorkingHere"]
      ? null
      : moment(new Date(_data1["toDate"])).format("DD/MM/YYYY");
    _data1["currentlyWorkingHere"] = _data1["currentlyWorkingHere"];
    _data1["locationId"] = _data1["location"];
    _data1["currentlyWorkHereFlag"] = _data1["currentlyWorkingHere"];
    _data1["employerName"] = _data1["employer"];
    _data1["jobRole"] = _data1["role"];
    globalSpinner.showSpinner();

    saveCandidateExperiences(_data1)
      .then((res) => {
        console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          getCandidateExperiences({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
            ShowInactive: false,
          });
          toast.success("Experience updated.");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong.");
      });

    // if (await confirm({
    //   confirmation: "Are you sure you want to delete this?",
    //   options: {
    //     yes: "Yes",
    //     no: "No",
    //     header: "Delete"
    //   }
    // })) {
    //   console.log('yes');
    //   console.log(e, i);
    //   globalSpinner.showSpinner();
    //   deleteCandidateExperiences({ RowId: e.rowId }).then((res) => {
    //     console.log(res);
    //     globalSpinner.hideSpinner();
    //     if (res.data.isSuccess) {
    //       getCandidateExperiences({
    //         "CandidateId": Number(candidateId),
    //         "Page": 1,
    //         "PageSize": 10,
    //         "SearchTerm": "",
    //         "SortOrder": "",
    //         "ShowInactive": false
    //       });
    //       toast.success("Experience removed.");
    //     } else {
    //       toast.error(res.data.message);
    //     }
    //   }).catch((err) => {
    //     console.log(err);
    //     globalSpinner.hideSpinner();
    //     toast.error(err.toString());
    //   });
    // } else {
    //   console.log('no');
    //   console.log(e, i);
    // }
  };

  return (
    <React.Fragment>
      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-4">
        <span className="resp-arrow" />
        Experience
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-4"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Experience</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>

        <div>
          <a
            onClick={() => {
              setIsOpen2(!isOpen2);
            }}
            className="btn add_btn"
            style={{ marginBottom: "10px" }}
          >
            Add Experience
          </a>
        </div>

        {candidateExperiences.map((e: any, i: any) => {
          return (
            <RenderExperience
              index={i}
              item={e}
              key={i}
              disabled={disabled}
              candidateExperienceSelectBoxData={
                candidateExperienceSelectBoxData
              }
              removeExperience={removeExperience}
              updateExperience={updateExperience}
              candidateAchievementsState={candidateAchievementsState}
              nationalities={nationalities}
            />
          );
        })}

        {/* <div className="col-sm-12">
          <a onClick={() => { setIsOpen2(!isOpen2); }} className="btn submit_btn">Add Experience</a>
        </div> */}
      </div>

      <Modal
        show={isOpen2}
        onHide={() => {
          setIsOpen2(!isOpen2);
        }}
        dialogClassName="_attach-training"
      >
        <AddExperiance
          setIsOpen2={setIsOpen2}
          isOpen2={isOpen2}
          candidateExperienceSelectBoxData={candidateExperienceSelectBoxData}
          candidateExperiences={candidateExperiences}
          getCandidateExperiences={getCandidateExperiences}
          userId={userId}
          candidateId={candidateId}
          nationalities={nationalities}
        />
      </Modal>

      <ToastContainer />
    </React.Fragment>
  );
}

export default Experience;
