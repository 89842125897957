import React, { useState, useContext } from "react";

import { Link, withRouter } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";

import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// import { saveContactUs } from "./../../apis/misc";

import { toast, ToastContainer } from "react-toastify";

import { GlobalSpinnerContext } from "./../../context/GlobalSpinner";

import { ErrorMessage } from "@hookform/error-message";

import { Form } from "react-bootstrap";
import { saveContactUs } from "../../apis/misc";
import AuthService from "../../services/AuthService";

interface IContactUsComponentProps {}

interface IContactUsComponentState {
  name: string;

  email: string;

  subject: string;

  message: string;
}

const initialState = {};

const defaultValues = {
  name: "",

  email: "",

  subject: "",

  message: "",
};

const ContactUsComponent: React.FC<IContactUsComponentProps> = (props: any) => {
  const cl = props.location.pathname;
  const [
    ContactUsComponentState,
    setContactUsComponentState,
  ] = React.useState<IContactUsComponentState>(defaultValues);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const {
    register,

    handleSubmit,

    watch,

    errors,

    setValue,

    getValues,

    setError,

    control,
  } = useForm<IContactUsComponentState>({
    defaultValues,
  });
  const authorizationToken = AuthService.accessToken;
  const [name, setName] = useState("");

  const [email, setEmail] = useState(
    AuthService.currentUser?.email != undefined
      ? AuthService.currentUser?.email
      : ""
  );

  const [subject, setSubject] = useState("");

  const [message, setMessage] = useState("");

  const onSubmit = (data: any) => {
    //console.log(data);

    globalSpinner.showSpinner();

    saveContactUs({
      RowId: 0,

      ContactUsId: 0,

      Name: data["name"],

      Email: data["email"],

      Subject: data["subject"],

      Message: data["message"],

      IsActive: true,
    })
      .then((res: any) => {
        toast.success("Message has been sent to Jitmarine");

        //console.log("Message has been sent to Jitmarine");

        globalSpinner.hideSpinner();
      })

      .catch((err: any) => {
        toast.error("Error occurred while sending.");

        //console.log(err);

        globalSpinner.hideSpinner();
      });

    setName("");

    setEmail("");

    setSubject("");

    setMessage("");
  };

  const history = useHistory();

  React.useEffect(() => {
    if (authorizationToken != null) {
      setValue(
        "email",
        AuthService.currentUser?.email != undefined
          ? AuthService.currentUser?.email
          : ""
      );
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="header header1">
        <header>
          <div className=" container">
            <div className="row">
              <div className="col-sm-2">
                <div className=" logo">
                  <a
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    <img
                      src={require("../../images/logo.png")}
                      // src="images/logo.png"

                      className="img-responsive"
                      alt=""
                    />{" "}
                  </a>
                </div>
              </div>

              <div className="col-sm-10">
                <div className="bs-example">
                  <nav
                    role="navigation"
                    className="navbar navbar-default navbar-static"
                    id="navbar-example"
                  >
                    <div className="navbar-header">
                      <button
                        data-target=".bs-example-js-navbar-collapse"
                        data-toggle="collapse"
                        type="button"
                        className="navbar-toggle"
                      >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                      </button>

                      <div className="collapse navbar-collapse bs-example-js-navbar-collapse">
                        <ul className="nav navbar-nav">
                          <li>
                            <Link to="/" className={cl === "/" ? "active" : ""}>
                              {" "}
                              Home
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/career_developer"
                              className={
                                cl === "/career_developer" ? "active" : ""
                              }
                            >
                              Career Developer
                            </Link>
                          </li>
                          {/* <li>
                            {" "}
                            <Link
                              to="/coming_soon"
                              className={cl === "/coming_soon" ? "active" : ""}
                            >
                              Client{" "}
                            </Link>
                          </li> */}
                          {/* <li>
                            {" "}
                            <Link
                              to="/vendor"
                              className={cl === "/vendor" ? "active" : ""}
                            >
                              Vendor
                            </Link>{" "}
                          </li> */}
                          <li>
                            <Link
                              to={`/help`}
                              className={cl === "/help" ? "active" : ""}
                            >
                              Help
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/about_us"
                              className={cl === "/about_us" ? "active" : ""}
                            >
                              About Us
                            </Link>
                          </li>
                          <li className="activefix">
                            <Link
                              to="/contact_us"
                              className={cl === "/contact_us" ? "active" : ""}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      {/* Header Ends */}

      {/* ----------------------------- */}

      {/* baner start */}

      <div className="sub_banner">
        <div className="banner_border">
          <img
            src={require("../../images/contactus_banner.jpg")}
            // src="images/contactus_banner.jpg"

            className=" img-responsive"
          />
        </div>

        <div className="clearfix" />
      </div>

      {/* baner end */}

      {/* ------------------- */}

      {/* Contact details start */}

      <div className="contact_details_sec">
        <div className=" container">
          <div className="row">
            <div className="col-sm-3 col-xs-6">
              <div>
                <i className="fa fa-map-marker" aria-hidden="true" />
              </div>

              <div className="contact_details_sec_con">
                {/* <div>JIT International</div>
                            <div>Mazyad Mall, 10th Floor, Tower 2 Mohammed Bin Zayed City, </div> 
                            <div>Mussaffah, Abu Dhabi, United Arab Emirates</div> */}
                <p style={{ textAlign: "justify" }}>
                  JIT International Mazyad Mall, 10th Floor, Tower 2 Mohammed
                  Bin Zayed City, Mussaffah, Abu Dhabi, United Arab Emirates
                </p>
              </div>
            </div>

            <div className="col-sm-3 col-xs-6">
              <div>
                <i className="fa fa-envelope-o" aria-hidden="true" />
              </div>

              <div className="contact_details_sec_con">
                <a>info@jit.com</a>
              </div>
            </div>

            <div className="col-sm-3 col-xs-6">
              <div>
                <i className="fa fa-phone" aria-hidden="true" />
              </div>

              <div className="contact_details_sec_con">
                <a>086 834 2525</a>
              </div>
            </div>

            <div className="col-sm-3 col-xs-6">
              <div>
                <i className="fa fa-whatsapp" aria-hidden="true" />
              </div>

              <div className="contact_details_sec_con">
                <a>086 834 2525</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact details end  */}

      {/* ------------------------------------ */}

      {/* contact form section start */}

      <div className="contact_form_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="contact_form_head">
                <img
                  src={require("../../images/contactus_banner.jpg")}
                  // src="images/contact_br.jpg"

                  width={59}
                  height={4}
                />{" "}
                &nbsp; Contact us
              </div>

              <div className="contact_form_head1">Lets Get in touch</div>
            </div>

            <div className="col-sm-5">
              <form
                onSubmit={handleSubmit(onSubmit)}
                id="contact-form"
                noValidate
              >
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    ref={register({
                      required: "Name is required",
                    })}
                    className="form-control form-control-n"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="email"
                    ref={register({
                      required: "Email is required",

                      maxLength: {
                        value: 150,

                        message: "Should be a valid email",
                      },

                      pattern: {
                        value: /\S+@\S+\.\S+/,

                        message: "Should be a valid email",
                      },
                    })}
                    className="form-control form-control-n"
                    placeholder="Email ID"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="subject"
                    ref={register({
                      required: "Subject is required",
                    })}
                    className="form-control form-control-n"
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="subject"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="form-group">
                  <textarea
                    name="message"
                    className={"form-control form-contro11"}
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    ref={register({
                      required: "Message cannot be blank.",

                      validate: (value) => {
                        //exclude  start and end white-space

                        value = value.replace(/(^\s*)|(\s*$)/gi, "");

                        //convert 2 or more spaces to 1

                        value = value.replace(/[ ]{2,}/gi, " ");

                        // exclude newline with a start spacing

                        value = value.replace(/\n /, "\n");

                        if (value.split(" ").length > 250) {
                          return "Maximum words: 250";
                        }
                      },
                    })}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="message"
                    render={({ message }) => (
                      <div className="login_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="form-group">
                  <button type="submit" className="btn submit_btn">
                    Submit
                  </button>
                </div>
              </form>
            </div>

            <div className="col-sm-7">
              <img
                src={require("../../images/contact_img.jpg")}
                // src="images/contact_img.jpg"

                className="img-responsive center-block"
              />
            </div>
          </div>
        </div>
      </div>

      {/* contact form section end */}
    </React.Fragment>
  );
};

export default withRouter(ContactUsComponent);
