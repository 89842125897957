import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  CreateAdminRespondModel,
  GetAllPermissionResultModel,
  SuperAdminRespondModel,
  SuperAdminRespondModelById,
  SuperAdminUserDeleteResultModel,
  SuperAdminUserRespondModel,
} from "../../models/superadmin/SuperAdminUsers";
import {
  ISuperAdminUserAction,
  superAdminUserReducer,
} from "../../action/superAdmin/SuperAdminUserAction";

export const SuperAdminUserDispatcherContext = React.createContext<React.Dispatch<ISuperAdminUserAction> | null>(
  null
);
export const SuperAdminUserStateContext = React.createContext<ISuperAdminUserManagementState | null>(
  null
);

export interface ISuperAdminUserManagementProps
  extends RouteComponentProps<any> {}

export interface ISuperAdminUserManagementState {
  superAdminUsersList: SuperAdminUserRespondModel;
  saveAdminRespondModel: CreateAdminRespondModel;
  superAdminUser: SuperAdminRespondModel;
  getAllPermission: GetAllPermissionResultModel;
  deleteSuperAdminUserResponse: SuperAdminUserDeleteResultModel;
}

export const initialSuperAdminUserManagementState = {
  superAdminUsersList: {} as SuperAdminUserRespondModel,
  saveAdminRespondModel: {} as CreateAdminRespondModel,
  superAdminUser: {} as SuperAdminRespondModel,
  getAllPermission: {} as GetAllPermissionResultModel,
  deleteSuperAdminUserResponse: {} as SuperAdminUserDeleteResultModel,
} as ISuperAdminUserManagementState;

export const SuperAdminUserContextProvider: React.FC = ({ children }) => {
  const [superAdminUserState, dispatcher] = useImmerReducer(
    superAdminUserReducer,
    initialSuperAdminUserManagementState
  );

  return (
    <SuperAdminUserDispatcherContext.Provider value={dispatcher}>
      <SuperAdminUserStateContext.Provider value={superAdminUserState}>
        {children}
      </SuperAdminUserStateContext.Provider>
    </SuperAdminUserDispatcherContext.Provider>
  );
};
