import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { AdminCommunicationContextProvider } from "../../../context/admin/AdminCommunicationContext";
import { AdminMyJobContextProvider } from "../../../context/admin/AdminMyJobContext";
import {
  AdminContextProvider,
  AdminProfileImageContextProvider,
} from "../../../context/admin/AdminMyProfile";
import { CareerTipsApprovalContextProvider } from "../../../context/admin/CareerTipsApprovalContext";
import { ClientJobApprovalContextProvider } from "../../../context/admin/ClientJobApprovalContext";
import { MyTeamMemberContextProvider } from "../../../context/admin/MyTeamMemberContext";
import { SocialAccountUrlContextProvider } from "../../../context/admin/SocialAccountUrlContext";
import { StoryContextProvider } from "../../../context/admin/StoryContext";
import { VendorApprovalContextProvider } from "../../../context/admin/VendorApprovalContext";
import { JobAlertContextProvider } from "../../../context/candidate/JobAlertContext";
import { ExperienceContextProvider } from "../../../context/general/ExperienceContext";
import { MyProfileAdminContextProvider } from "../../../context/MyProfileAdminContext";
import { MyProfileVendorContextProvider } from "../../../context/MyProfileVendorContext";
import CareerDeveloperContainer from "../career_developer/CareerDeveloperContainer";
import BulKEmailContainer from "../communication/BulkEmail/BulKEmailContainer";
import BulKSMSContainer from "../communication/BulkSMS/BulKSMSContainer";
import ContactUsContainer from "../communication/ContactUs/ContactUsContainer";
import MessagesContainer from "../communication/Messages/MessagesContainer";
import JobApplicationContainer from "../jobApplication/JobApplicationContainer";
import AdminMyJobContainer from "../myJobs/AdminMyJobContainer";
import JobCategoryOrTitle from "../siteSetting/JobCategoryOrTitle";
import MyTeamMemberContainer from "../siteSetting/myteammember/MyTeamMemberContainer";
import SocialAccountUrlContainer from "../siteSetting/socialaccounturl/SocialAccountUrlContainer";
import StoryContainer from "../siteSetting/story/StoryContainer";
import CareerTipsApprovalContainer from "./../careertipsapproval/CareerTipsApprovalContainer";
import ClientJobApprovalContainer from "./../clientjobapproval/ClientJobApprovalContainer";
import MyClient from "./../myUser/MyClient";
import AdminProfileContainer from "./../my_profile/AdminProfileContainer";
import VendorReportContainer from "./../report/VendorReportContainer";
import VendorApprovalContainer from "./../vendorapproval/VendorApprovalContainer";
import AdminLayoutSidebar from "./AdminLayoutSidebar";
import AdminDashboardContainer from "../../admin/dashboard/AdminDashboardContainer";
import { AchievementsContextProvider } from "../../../context/admin/AchievementsContext";
import AchievementsContainer from "../siteSetting/achievements/AchievementsContainer";
import AdminCandidateSearchContainer from "../candidateSearch/AdminCandidateSearchContainer";
import { BasicInfoViewModel } from "../../../models/candidate/MyProfileSelectBoxData";
import ResourceAvailability from "../myUser/ResourceAvailability";
import AdminClientSideJobs from "../myUser/AdminClientSideJobs";
import ClientReportContainer from "../report/ClientReportContainer";
import Banner from "../banner/Banner";
import GoogleSideUrl from "../careertipsapproval/GoogleSideUrl";

interface IAdminLayoutComponentProps {
  basicInfo: BasicInfoViewModel;
}

interface IAdminLayoutComponentState {}

const initialState = {};

const AdminLayoutComponent: React.FC<IAdminLayoutComponentProps> = (props) => {
  const [
    AdminLayoutComponentState,
    setAdminLayoutComponentState,
  ] = React.useState<IAdminLayoutComponentState>(initialState);

  const { path } = useRouteMatch();
  const { basicInfo } = props;
  return (
    <>
      <div id="wrapper">
        <AchievementsContextProvider>
          <SocialAccountUrlContextProvider>
            <StoryContextProvider>
              <MyTeamMemberContextProvider>
                <VendorApprovalContextProvider>
                  <ClientJobApprovalContextProvider>
                    <CareerTipsApprovalContextProvider>
                      <AdminLayoutSidebar />
                    </CareerTipsApprovalContextProvider>
                  </ClientJobApprovalContextProvider>
                </VendorApprovalContextProvider>
              </MyTeamMemberContextProvider>
            </StoryContextProvider>
          </SocialAccountUrlContextProvider>
        </AchievementsContextProvider>
        <Route exact path={`${path}`}>
          <AdminDashboardContainer />
        </Route>
        <Route exact path={`${path}/myJob/drafted`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <AdminMyJobContainer />
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/myJob/published`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <AdminMyJobContainer />
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route
          exact
          path={`${path}/job_application/:candidateDefaultId/:vendorDefaultId/:id`}
        >
          <AdminCommunicationContextProvider>
            <MyProfileAdminContextProvider>
              <JobApplicationContainer />
            </MyProfileAdminContextProvider>
          </AdminCommunicationContextProvider>
        </Route>

        <Route exact path={`${path}/candidate_search/:paramId`}>
          {/* <MyProfileAdminContextProvider> */}
          <AdminCandidateSearchContainer basicInfo={basicInfo} />
          {/* </MyProfileAdminContextProvider> */}
        </Route>
        {/* <Route exact path={`${path}/interview_shedule`}>
          <InterviewShedule />
        </Route> */}
        <Route exact path={`${path}/my_user`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <MyClient />
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/ResourceAvailability`}>
          <AdminContextProvider>
            <ClientJobApprovalContextProvider>
              <MyProfileAdminContextProvider>
                <ResourceAvailability />
              </MyProfileAdminContextProvider>
            </ClientJobApprovalContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/clientJob`}>
          <AdminContextProvider>
            <ClientJobApprovalContextProvider>
              <MyProfileAdminContextProvider>
                <AdminClientSideJobs />
              </MyProfileAdminContextProvider>
            </ClientJobApprovalContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/careertipsapproval`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <AdminProfileImageContextProvider>
                <CareerTipsApprovalContextProvider>
                  <CareerTipsApprovalContainer />
                </CareerTipsApprovalContextProvider>
              </AdminProfileImageContextProvider>
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/vendorapproval`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <AdminProfileImageContextProvider>
                <VendorApprovalContextProvider>
                  <VendorApprovalContainer />
                </VendorApprovalContextProvider>
              </AdminProfileImageContextProvider>
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/clientjobapproval`}>
          <MyProfileVendorContextProvider>
            <JobAlertContextProvider>
              <ExperienceContextProvider>
                <AdminMyJobContextProvider>
                  <AdminContextProvider>
                    <MyProfileAdminContextProvider>
                      <AdminProfileImageContextProvider>
                        <ClientJobApprovalContextProvider>
                          <ClientJobApprovalContainer />
                        </ClientJobApprovalContextProvider>
                      </AdminProfileImageContextProvider>
                    </MyProfileAdminContextProvider>
                  </AdminContextProvider>
                </AdminMyJobContextProvider>
              </ExperienceContextProvider>
            </JobAlertContextProvider>
          </MyProfileVendorContextProvider>
        </Route>
        <Route exact path={`${path}/my-profile`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <AdminProfileImageContextProvider>
                <AdminProfileContainer />
              </AdminProfileImageContextProvider>
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        {/* <Route exact path={`${path}/communication`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <BulKSMSContainer />
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route> */}
        <Route exact path={`${path}/communication/bulkSMS`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <BulKSMSContainer />
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/communication/bulkEmail`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <BulKEmailContainer />
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/communication/messages`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <MessagesContainer />
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/communication/contactUs`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <ContactUsContainer />
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/report/vendor`}>
          <VendorReportContainer />
        </Route>
        <Route exact path={`${path}/report/client`}>
          <ClientReportContainer />
        </Route>
        <Route exact path={`${path}/sitesettings/jobCategory`}>
          <JobCategoryOrTitle />
        </Route>
        <Route exact path={`${path}/careerDeveloper`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <CareerDeveloperContainer />
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/myteammember`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <AdminProfileImageContextProvider>
                <MyTeamMemberContextProvider>
                  <MyTeamMemberContainer />
                </MyTeamMemberContextProvider>
              </AdminProfileImageContextProvider>
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/story`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <AdminProfileImageContextProvider>
                <StoryContextProvider>
                  <StoryContainer />
                </StoryContextProvider>
              </AdminProfileImageContextProvider>
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/socialaccounturl`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <AdminProfileImageContextProvider>
                <SocialAccountUrlContextProvider>
                  <SocialAccountUrlContainer />
                </SocialAccountUrlContextProvider>
              </AdminProfileImageContextProvider>
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/achievements`}>
          <AdminContextProvider>
            <MyProfileAdminContextProvider>
              <AdminProfileImageContextProvider>
                <AchievementsContextProvider>
                  <AchievementsContainer />
                </AchievementsContextProvider>
              </AdminProfileImageContextProvider>
            </MyProfileAdminContextProvider>
          </AdminContextProvider>
        </Route>
        <Route exact path={`${path}/banner`}>
          <Banner />
        </Route>
        <Route exact path={`${path}/google_slide_url`}>
          <CareerTipsApprovalContextProvider>
            <GoogleSideUrl />
          </CareerTipsApprovalContextProvider>
        </Route>
      </div>
    </>
  );
};
export default AdminLayoutComponent;
