import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import { GlobalSpinnerContext } from "./../../context/GlobalSpinner";
import PhoneNumberWithCode from "../components/PhoneNumberWithCode";
import {
  verifyUserEmailOrMobile,
  verifyCode,
  emailVerifyCode,
  sendEmailOpt,
  sendPhoneOtp,
  verifyForgetOtpAndGenerateToken,
} from "../../apis/misc";
import { reactHookFormServerErrors } from "./../../utils/utility";
import OtpExpiryCouter from "./../GlobalOtpExpiryCouter";
import ForgotPasswordUserConfirm from "./ForgotPasswordUserConfirm";
import { Redirect } from "react-router-dom";
import { AnyARecord } from "dns";

// interface IForgotPasswordUerState {
//   password: string,
//   passwordConfirm: string
// }

interface IForgotPasswordOtpState {
  email: string;
  mobile: string;
  countryCode: string;
}

interface IOtpVerificationState {
  otp: string;
}

function VerifyForgotPasswordOTP(props: any) {
  //console.log(props);
  const defaultValues = {
    otp: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    setError,
    trigger,
    clearErrors,
  } = useForm<IOtpVerificationState>({
    defaultValues,
  });

  const [showTimer, setShowTimer] = React.useState("1");
  const [disabled, setDisabled] = useState(false);

  ////console.log(showTimer);

  useEffect(() => {
    if (showTimer.includes("OTP Expired.")) {
      setShowTimer("Your OTP has been expired.Please resend again");
      setDisabled(true);
      // reactHookFormServerErrors(
      //   {
      //     otp: ["Please resend again"]
      //   },
      //   setError
      // );
    }
  }, [showTimer]);

  useEffect(() => {
    resendOtp();
    ////console.log("useEffect ran...");
  }, [props.isEmail]);

  const onSubmit = (data: any) => {
    props.globalSpinner.showSpinner();
    let msg = "Error: Invalid OTP.Please enter correct OTP";

    //call api to verify otp and get token after verification
    let query = "";
    if (props.isEmail) {
      query = `?email=${encodeURIComponent(
        props.showEmail
      )}&countryCode=${encodeURIComponent(
        0 || ""
      )}&phoneNumber=${encodeURIComponent(0 || "")}&type=${encodeURIComponent(
        "email"
      )}&code=${encodeURIComponent(data["otp"])}`;
      // emailVerifyCode({}, `?code=${data["otp"]}&email=${encodeURIComponent(props.showEmail)}`)
    } else {
      query = `?email=${encodeURIComponent(
        0 || ""
      )}&countryCode=${encodeURIComponent(
        data["countryCode"] || ""
      )}&phoneNumber=${encodeURIComponent(
        data["mobile"] || ""
      )}&type=${encodeURIComponent("mobile")}&code=${encodeURIComponent(
        data["otp"]
      )}`;
    }

    verifyForgetOtpAndGenerateToken({}, query)
      .then((res: any) => {
        //console.log(res.data);
        props.globalSpinner.hideSpinner();
        props.setActive("Confirm ForgotPassword OTP");
        props.setChangeToken(res.data.token);
        props.setChangeEmail(res.data.email);
      })
      .catch((err: any) => {
        ////console.log(err);
        props.globalSpinner.hideSpinner();
        reactHookFormServerErrors(
          {
            otp: [msg],
          },
          setError
        );
      });
  };
  const resendOtp = () => {
    props.globalSpinner.showSpinner();

    if (props.isEmail) {
      sendEmailOpt({}, `?email=${encodeURIComponent(props.showEmail || "")}`)
        .then((res: any) => {
          props.globalSpinner.hideSpinner();
          if (res.data.includes("There was an error")) {
            ////console.log(res.data);
            //toast.error(res.data);
            toast.error("Something went wrong");
          } else {
            setDisabled(false);
            toast.success("OTP has been successfully sent on your e-mail.");
            if (Number(showTimer)) {
              const c = Number(showTimer) + 1;
              /////console.log(c, "================================")
              setShowTimer(c + "");
            } else {
              setShowTimer("1");
            }
          }
        })
        .catch((err: any) => {
          ////console.log(err);
          props.globalSpinner.hideSpinner();
          ////console.log(err);
          toast.error("Something went wrong");
        });
    } else {
      //=======================
      // props.globalSpinner.showSpinner();
      sendPhoneOtp(
        {},
        //`?phoneNumber=${encodeURIComponent((props.showCountry || "") + (props.showMobile || ""))}`
        `?countryCode=${encodeURIComponent(
          props.showCountry
        )}&phoneNumber=${encodeURIComponent(props.showMobile)}`
      )
        .then((res: any) => {
          props.globalSpinner.hideSpinner();
          ////console.log(res.data);
          if (
            res.data.includes("There was an error") ||
            res.data.includes("Invalid")
          ) {
            // //console.log(res.data);
            //toast.error(res.data);
            toast.error("Something went wrong");
          } else {
            setDisabled(false);
            toast.success(
              "OTP has been successfully sent on your mobile number."
            );
            if (Number(showTimer)) {
              const c = Number(showTimer) + 1;
              // //console.log(c, "================================")
              setShowTimer(c + "");
            } else {
              setShowTimer("1");
            }
          }
        })
        .catch((err: any) => {
          ////console.log(err);
          props.globalSpinner.hideSpinner();
          ////console.log(err);
          toast.error("Something went wrong");
        });

      //========================
    }
  };

  return (
    <div className="col-sm-6 col-lg-5 col-lg-offset-1">
      <form
        className="needs-validation"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="registration_forms registration_sec">
          <div className="sub_heading">
            <h2>Forgot Password</h2>
          </div>
          <div className="already_login2">Verify OTP</div>
          <div className="form-group" style={{ paddingTop: "20px" }}>
            <input
              type="text"
              className="form-control form-control-n"
              placeholder="Enter Code"
              name="otp"
              ref={register({
                required: "OTP is required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="otp"
              render={({ message }) => (
                <div className="register_validation">{message}</div>
              )}
            />
            <OtpExpiryCouter setTimer={setShowTimer} timer={showTimer} />
          </div>
          <div className="already_login1">
            Didn't get the code?{" "}
            <a
              className="_cursor-pointer"
              onClick={() => {
                resendOtp();
              }}
            >
              Send again.
            </a>
          </div>{" "}
          {/* */}
          <div className="m_t_20">
            <button
              type="submit"
              className="btn btn-success reg_but"
              disabled={disabled}
            >
              Verify
            </button>
          </div>
        </div>
        <div className="clearfix" />
      </form>
    </div>
  );
}

function ForgotPasswordOTP(props: any) {
  //props.setshowEmail("hellooo");
  const defaultValues = {
    email: "",
    mobile: "",
    countryCode: "+91",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    setError,
    trigger,
    clearErrors,
    control,
  } = useForm<IForgotPasswordOtpState>({
    defaultValues,
  });
  //props.setShowEmail("hellooo");

  //const [messageUser, setMessageUser] = useState("");

  if (watch("countryCode")) {
    props.setShowCountry(watch("countryCode"));
    // //console.log(props);
  }

  const onSubmit = (data: any) => {
    // //console.log("--------------------");
    // //console.log(data);
    // //console.log(props.isEmail);
    // //console.log(data["email"]);
    // //console.log(data["mobile"]);
    // //console.log(data["countryCode"]);
    props.globalSpinner.showSpinner();
    let query = "";
    if (props.isEmail) {
      query = `?email=${encodeURIComponent(
        data["email"]
      )}&countryCode=${encodeURIComponent(
        0 || ""
      )}&phoneNumber=${encodeURIComponent(0 || "")}&type=${encodeURIComponent(
        "email"
      )}`;
    } else {
      query = `?email=${encodeURIComponent(
        0 || ""
      )}&countryCode=${encodeURIComponent(
        data["countryCode"] || ""
      )}&phoneNumber=${encodeURIComponent(
        data["mobile"] || ""
      )}&type=${encodeURIComponent("mobile")}`;
    }

    ////console.log(props);
    verifyUserEmailOrMobile({}, query)
      .then((res: any) => {
        // //console.log(res.data);
        props.globalSpinner.hideSpinner();
        props.setActive("Verify ForgotPassword OTP");
      })
      .catch((err: any) => {
        // //console.log(err.response);
        props.globalSpinner.hideSpinner();
        if (err.response.data != undefined) {
          let msg = props.isEmail
            ? "Email was not found."
            : "Phone number was not found";
          //toast.error(err.response.data);
          if (
            err.response.data.message.includes(
              "Admin does not have permission to access this link"
            )
          ) {
            msg = err.response.data.message;
          }
          toast.error(msg);
        } else {
          toast.error("Something went wrong!");
        }
      });
    //props.globalSpinner.hideSpinner();
  };

  let valEmail = {};
  let valMobile = {};
  let showEmailOrMobile;
  if (props.isEmail) {
    valEmail = {
      required: "Email is required",
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Please enter a valid e-mail address.", //"Should be a valid email"
      },
    };
    showEmailOrMobile = (
      <>
        {" "}
        <input
          type="text"
          className="form-control form-control-n"
          placeholder="Type here"
          name="email"
          ref={register(valEmail)}
          onChange={(e) => props.setShowEmail(e.target.value)}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <div className="register_validation">{message}</div>
          )}
        />
      </>
    );
  } else {
    valMobile = {
      required: "Mobile Number is required",

      maxLength: {
        value: watch("countryCode").length > 3 ? 9 : 10,
        message: `It must be ${
          watch("countryCode").length > 3 ? 9 : 10
        } digits only.`,
      },
      minLength: {
        value: watch("countryCode").length > 3 ? 9 : 10,
        message: `It must be minimum ${
          watch("countryCode").length > 3 ? 9 : 10
        }  digits.`,
      },
      pattern: {
        value: /^[0-9]*$/,
        message: "Mobile number must be numbers only",
      },
    };
    showEmailOrMobile = (
      <>
        <Controller
          control={control}
          name="countryCode"
          render={({ onChange, onBlur, value, name }) => (
            <PhoneNumberWithCode
              codeHeight={"50px"}
              noPenIcon={true}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              phoneNo={
                <input
                  type="text"
                  name="mobile"
                  id="contactNumber"
                  className="form-control phoneNumber"
                  placeholder="Mobile number"
                  ref={register(valMobile)}
                  onChange={(e) => {
                    props.setShowMobile(e.target.value);
                    props.setShowCountry(value);
                  }}
                />
              }
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="mobile"
          render={({ message }) => (
            <div className="register_validation">{message}</div>
          )}
        />
      </>
    );
  }

  return (
    <div className="col-sm-6 col-lg-5 col-lg-offset-1">
      <form
        className="needs-validation"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="registration_forms registration_sec">
          <div className="sub_heading">
            <h2>Forgot Password</h2>
          </div>
          <div className="already_login2">Send OTP on</div>
          <div className="forgot_otp_btn">
            <div className="m_t_20">
              <button
                onClick={() => {
                  props.setIsEmail(true);
                }}
                type="button"
                className={
                  props.isEmail
                    ? "btn btn-success sig_but"
                    : "btn btn-success create_but"
                }
              >
                Email
              </button>
            </div>
            <div className="m_t_20">
              <button
                onClick={() => {
                  props.setIsEmail(false);
                }}
                type="button"
                className={
                  props.isEmail
                    ? "btn btn-success create_but"
                    : "btn btn-success sig_but"
                }
              >
                Mobile
              </button>
            </div>
          </div>
          <div className="form-group">{showEmailOrMobile}</div>
          {/* <div className="already_login1">Didn't get the code? <a className="_cursor-pointer" >Send again.</a></div>   onClick={() => { resend() }} */}
          <div className="m_t_20">
            <button type="submit" className="btn btn-success reg_but">
              Send
            </button>
          </div>
        </div>
        <div className="clearfix" />
      </form>
    </div>
  );
}

function ForgotPasswordUser(props: any) {
  const [showMobile, setShowMobile] = useState("");
  const [showCountry, setShowCountry] = useState("");
  const [active, setActive] = useState("Forgot Password OTP");
  const [isEmail, setIsEmail] = useState<Boolean>(true);
  const [showEmail, setShowEmail] = useState<string>("Forgot Password OTP");
  const [changeToken, setChangeToken] = useState("");
  const [changeEmail, setChangeEmail] = useState("");

  const globalSpinner = useContext(GlobalSpinnerContext);

  let tab = null;

  if (active == "Forgot Password OTP") {
    tab = (
      <ForgotPasswordOTP
        setActive={setActive}
        globalSpinner={globalSpinner}
        isEmail={isEmail}
        setIsEmail={setIsEmail}
        setShowEmail={setShowEmail}
        showEmail={showEmail}
        showMobile={showMobile}
        setShowMobile={setShowMobile}
        setShowCountry={setShowCountry}
        showCountry={showCountry}
      />
    );
  } else if (active == "Verify ForgotPassword OTP") {
    tab = (
      <VerifyForgotPasswordOTP
        setActive={setActive}
        globalSpinner={globalSpinner}
        isEmail={isEmail}
        showEmail={showEmail}
        showMobile={showMobile}
        showCountry={showCountry}
        setChangeToken={setChangeToken}
        setChangeEmail={setChangeEmail}
      />
    );
  } else if (active == "Confirm ForgotPassword OTP") {
    tab = (
      <ForgotPasswordUserConfirm
        isEmail={isEmail}
        showEmail={showEmail}
        showMobile={showMobile}
        showCountry={showCountry}
        changeToken={changeToken}
        changeEmail={changeEmail}
      />
    );
  }

  return (
    <>
      <div className=" container">
        <ToastContainer />
        <div className="row">
          <div className="col-sm-6 desk">
            <div className="login_img">
              <img
                src={require("./../../images/forgot_img.jpg")}
                className="img-responsive"
              />
            </div>
          </div>
          {tab}
        </div>
      </div>
    </>
  );
}

export default withRouter(ForgotPasswordUser);
