import { apiClient } from "./../utils/httpClient";
import Axios from "axios";
import AuthService from "./../services/AuthService";

type objectIndexing = {
  [key: string]: any;
};

export function getResumeTemplateSelectBoxData(data?: any) {
  return apiClient("/api/ResumeTemplate/get-selectbox-data", data);
}

export function getResumeTemplates(data?: any) {
  return apiClient("/api/ResumeTemplate/gaaa", data);
}

// export function getResumeTemplate(data?: any) {
//   return apiClient("/api/ResumeTemplate/ga", data);
// }

export function saveResumeCandidateMap(data?: any) {
  return apiClient("/api/ResumeCandidateMap/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function getResumeCandidateMap(data?: any) {
  return apiClient("/api/ResumeCandidateMap/gcra", data);
}

export function getAllResumeCandidateMap(data?: any) {
  //return apiClient("/api/ResumeCandidateMap/gaaa", data);
  return apiClient("/api/ResumeCandidateMap/gcaaa", data);
}

export function deleteResumeCandidateMap(data?: any) {
  return apiClient("/api/ResumeCandidateMap/da", data);
}

export function getCoverLetterTemplates(data?: any) {
  return apiClient("/api/CoverLetterTemplate/gaaa", data);
}

export function getAllCoverLetterCandidateMap(data?: any) {
  return apiClient("/api/CoverLetterCandidateMap/gcaaa", data);
}

export function deleteCoverLetterCandidateMap(data?: any) {
  return apiClient("/api/CoverLetterCandidateMap/da", data);
}

export function saveCoverLetterCandidateMap(data?: any) {
  return apiClient("/api/CoverLetterCandidateMap/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function getCoverLetterCandidateMap(data?: any) {
  return apiClient("/api/CoverLetterCandidateMap/gcca", data);
}

export function generateResumePDF(data?: any) {
  return Axios({
    method: "post",
    responseType: "blob",
    url:
      "https://jithtml2pdf.azurewebsites.net/api/Html2Pdf?code=Ga/sjqQ/tsakfw6k3Ovq8xx/HN8FWoNEgGGRnuqy5dRzgJ0Koq4ing==",
    data: data || {},
  });
}

/*export function getResumeReport(data?: any) {
  let accessToken = AuthService.accessToken;
  if (accessToken?.startsWith(`"`)) {
    accessToken = accessToken.substring(1);
    accessToken = accessToken.slice(0, -1);
  }

  let headers: objectIndexing = {
    Authorization: "bearer " + accessToken,
    "Content-Type": "application/json",
  };

  return Axios({
    method: "post",
    responseType: "blob",
    url: "https://careerapp.azurewebsites.net/api/Report/gaaa",
    data: data || {},
    headers,
  });
}*/

export function getResumeReport(data: any, query: string) {
  let accessToken = AuthService.accessToken;
  if (accessToken?.startsWith(`"`)) {
    accessToken = accessToken.substring(1);
    accessToken = accessToken.slice(0, -1);
  }

  query = query + `&token=${accessToken}`;
  return Axios({
    method: "get",
    responseType: "blob",
    // url: `http://localhost:44329/Report/GenerateCandidateResume${query}`,
    url: `https://jitreport.clubactive.in/Report/GenerateCandidateResume${query}`,
    data: data || {},
  });
}
