import React, { useReducer, createContext, useContext } from "react";
import * as API from "./../../apis/superadmin";

const initialState2 = {
  data: [],
  loading: false,
  error: null,
};

export const SuperAdminContext = createContext({
  superAdminState: initialState2,
  getSuperAdmins: (data?: any) => {},
});

const reducer2 = (superAdminState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState2, loading: true };
    case "FETCHED":
      return { ...initialState2, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState2, loading: false, error: action.payload };
    default:
      return superAdminState;
  }
};

export const SuperAdminContextProvider = (props: any) => {
  const [superAdminState, dispatch] = useReducer(reducer2, initialState2);
  const getSuperAdmins = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getSuperAdmins(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <SuperAdminContext.Provider
      value={{
        superAdminState,
        getSuperAdmins,
      }}
    >
      {props.children}
    </SuperAdminContext.Provider>
  );
};

export const useSuperAdminContext = () => {
  const superAdminContext = useContext(SuperAdminContext);
  return superAdminContext;
};

const initialState11 = {
  data: [],
  loading: false,
  error: null,
};

export const SocialAccountContext = createContext({
  socialAccounts: initialState11,
  getSocialAccounts: (data?: any) => {},
});

const reducer11 = (socialAccounts: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState11, loading: true };
    case "FETCHED":
      return { ...initialState11, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState11, loading: false, error: action.payload };
    default:
      return socialAccounts;
  }
};

export const SocialAccountContextProvider = (props: any) => {
  const [socialAccounts, dispatch] = useReducer(reducer11, initialState11);

  const getSocialAccounts = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getSocialAccounts(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <SocialAccountContext.Provider
      value={{
        socialAccounts,
        getSocialAccounts,
      }}
    >
      {props.children}
    </SocialAccountContext.Provider>
  );
};

export const useSocialAccountContext = () => {
  const socialAccountContext = useContext(SocialAccountContext);
  return socialAccountContext;
};

const initialState13 = {
  data: [],
  loading: false,
  error: null,
};

export const SuperAdminProfileImageContext = createContext({
  superAdminProfileImage: initialState13,
  getSuperAdminProfileImage: (data?: any) => {},
});

const reducer13 = (superAdminProfileImage: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState13, loading: true };
    case "FETCHED":
      return { ...initialState13, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState13, loading: false, error: action.payload };
    default:
      return superAdminProfileImage;
  }
};

export const SuperAdminProfileImageContextProvider = (props: any) => {
  const [superAdminProfileImage, dispatch] = useReducer(
    reducer13,
    initialState13
  );

  const getSuperAdminProfileImage = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getSuperAdminProfileImage(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <SuperAdminProfileImageContext.Provider
      value={{
        superAdminProfileImage,
        getSuperAdminProfileImage,
      }}
    >
      {props.children}
    </SuperAdminProfileImageContext.Provider>
  );
};

export const useSuperAdminProfileImageContext = () => {
  const superAdminProfileImageContext = useContext(
    SuperAdminProfileImageContext
  );
  return superAdminProfileImageContext;
};

const initialState14 = {
  data: [],
  loading: false,
  error: null,
};

export const SuperAdminListContext = createContext({
  superAdminList: initialState14,
  getSuperAdminList: (data?: any) => {},
});

const reducer14 = (superAdminList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState14, loading: true };
    case "FETCHED":
      return { ...initialState14, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState14, loading: false, error: action.payload };
    default:
      return superAdminList;
  }
};

export const SuperAdminListContextProvider = (props: any) => {
  const [superAdminList, dispatch] = useReducer(reducer14, initialState14);

  const getSuperAdminList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getSuperAdminList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <SuperAdminListContext.Provider
      value={{
        superAdminList,
        getSuperAdminList,
      }}
    >
      {props.children}
    </SuperAdminListContext.Provider>
  );
};

export const useSuperAdminListContext = () => {
  const superAdminListContext = useContext(SuperAdminListContext);
  return superAdminListContext;
};

const initialState15 = {
  data: [],
  loading: false,
  error: null,
};

export const UserListContext = createContext({
  userList: initialState15,
  getUserList: (data?: any) => {},
});

const reducer15 = (userList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState15, loading: true };
    case "FETCHED":
      return { ...initialState15, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState15, loading: false, error: action.payload };
    default:
      return userList;
  }
};

export const UserListContextProvider = (props: any) => {
  const [userList, dispatch] = useReducer(reducer15, initialState15);

  const getUserList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getUserList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <UserListContext.Provider
      value={{
        userList,
        getUserList,
      }}
    >
      {props.children}
    </UserListContext.Provider>
  );
};

export const useUserListContext = () => {
  const userListContext = useContext(UserListContext);
  return userListContext;
};

const initialState16 = {
  data: [],
  loading: false,
  error: null,
};

export const MessageListContext = createContext({
  messageList: initialState16,
  getMessageList: (data?: any) => {},
});

const reducer16 = (messageList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState16, loading: true };
    case "FETCHED":
      return { ...initialState16, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState16, loading: false, error: action.payload };
    default:
      return messageList;
  }
};

export const MessageListContextProvider = (props: any) => {
  const [messageList, dispatch] = useReducer(reducer16, initialState16);

  const getMessageList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getMessageList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <MessageListContext.Provider
      value={{
        messageList,
        getMessageList,
      }}
    >
      {props.children}
    </MessageListContext.Provider>
  );
};

export const useMessageListContext = () => {
  const messageListContext = useContext(MessageListContext);
  return messageListContext;
};

const initialState17 = {
  data: [],
  loading: false,
  error: null,
};

export const NotificationListContext = createContext({
  notificationList: initialState17,
  getNotificationList: (data?: any) => {},
});

const reducer17 = (notificationList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState17, loading: true };
    case "FETCHED":
      return { ...initialState17, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState17, loading: false, error: action.payload };
    default:
      return notificationList;
  }
};

export const NotificationListContextProvider = (props: any) => {
  const [notificationList, dispatch] = useReducer(reducer17, initialState17);

  const getNotificationList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getNotificationList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <NotificationListContext.Provider
      value={{
        notificationList,
        getNotificationList,
      }}
    >
      {props.children}
    </NotificationListContext.Provider>
  );
};

export const useNotificationListContext = () => {
  const notificationListContext = useContext(NotificationListContext);
  return notificationListContext;
};
