import React from 'react'
import VendorSavedJobComponent from './VendorSavedJobComponent'

const VendorSavedJobContainer = () => {
  return (
    <>
      <VendorSavedJobComponent/>
    </>
  )
}

export default VendorSavedJobContainer
