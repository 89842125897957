import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  ClientJobApprovalRequestModel,
  ClientJobApproval,
  ClientJobApprovalViewModel,
  ClientJobApprovalSaveRequestModel,
  ClientJobApprovalSaveRespondModel,
  ClientJobEditRequestModel,
  ClientJobResultModel,
  ClientJobGetDataWithId,
  ClientJobSelectBoxDataViewModel,
  ClientFakeCandidateResultModel,
  ClientFakeCandidateAllResultModel,
  ClientFakeCandidateAllRequestModel,
  ClientFakeCandidateRequestModel,
  ClientFakeCandidateDeleteResultModel,
  ClientFakeCandidateEditResult,
  CliendPendingJobResultModel,
  CliendPendingJobRequestModel,
  AdminSideClientJobListRequestModel,
  JobApproveOrRejectRequestModel,
  CandidateInfoByIdResultModel,
  CandidateInfoByIdRequestModel,
  AllAppliedCandidateListInAdminsideResultModel,
  AllAppliedCandidateListInAdminsideRequestModel,
} from "../../models/admin/ClientJobApproval";
import {
  IClientJobApprovalManagementState,
  ClientJobApprovalDispatcherContext,
  ClientJobApprovalStateContext,
} from "../../context/admin/ClientJobApprovalContext";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IClientJobApprovalAction =
  | {
      type: "CLIENTJOBAPPROVAL_LIST";
      clientJobApproval: ClientJobApprovalViewModel;
    }
  | {
      type: "CLIENTJOB_SELECTBOX_LIST";
      ClientJobSelectBoxDataViewModel: ClientJobSelectBoxDataViewModel;
    }
  | {
      type: "GET_CLIENTJOB";
      ClientJobResultModel: ClientJobResultModel;
    }
  | {
      type: "EDIT_CLIENTJOB";
      clientJobApprovalSaveRespond: ClientJobApprovalSaveRespondModel;
    }
  | {
      type: "SET_CLIENTJOB_STATUS";
      clientJobApprovalSaveRespond: ClientJobApprovalSaveRespondModel;
    }
  | {
      type: "ADD_CANDIDATE_FAKE_DETAILS";
      ClientFakeCandidateAddResult: ClientFakeCandidateResultModel;
    }
  | {
      type: "DELETE_CANDIDATE_FAKE_DETAILS";
      ClientFakeCandidateDeleteResult: ClientFakeCandidateResultModel;
    }
  | {
      type: "ALL_FAKE_CANDIDATE_DETAILS";
      ClientAllFakeCandidateResul: ClientFakeCandidateAllResultModel;
    }
  | {
      type: "EDIT_FAKE_CANDIDATE_DETAILS";
      ClientFakeCandidateEditResult: ClientFakeCandidateEditResult;
    }
  | {
      type: "GET_ALL_PENDING_APPROVAL_JOBS";
      ClientPendingJobApprovalResult: CliendPendingJobResultModel;
    }
  | {
      type: "GET_ALL_ADMINSIDE_CLIENT_JOBS";
      AdminClientSideJobs: CliendPendingJobResultModel;
    }
  | {
      type: "SET_CLIENT_APPROVE_OR_REJECT_JOB_FROM_ADMINSIDE";
      JobApproveOrRejectFromAdminResult: ClientFakeCandidateResultModel;
    }
  | {
      type: "GET_CANDIDATE_INFO_BY_ID_FOR_FORM";
      CandidateInfoById: CandidateInfoByIdResultModel;
    }
  | {
      type: "ADD_CANDIDATE_FOR_FORM";
      AddCandidateListForFormResult: ClientFakeCandidateResultModel;
    }
  | {
      type: "GET_ALL_APPLIED_CLIENT_LIST";
      AdminGetAllApliedCandidateListForClientJob: AllAppliedCandidateListInAdminsideResultModel;
    };

export const clientJobApprovalReducer: Reducer<
  IClientJobApprovalManagementState,
  IClientJobApprovalAction
> = (draft, action): IClientJobApprovalManagementState => {
  switch (action.type) {
    case "CLIENTJOBAPPROVAL_LIST":
      draft.clientJobApproval = action.clientJobApproval;
      return draft;
    case "GET_CLIENTJOB":
      draft.ClientJobResultModel = action.ClientJobResultModel;
      return draft;
    case "EDIT_CLIENTJOB":
      draft.clientJobApprovalSaveRespond = action.clientJobApprovalSaveRespond;
      return draft;
    case "SET_CLIENTJOB_STATUS":
      draft.clientJobApprovalSaveRespond = action.clientJobApprovalSaveRespond;
      return draft;
    case "CLIENTJOB_SELECTBOX_LIST":
      draft.ClientJobSelectBoxDataViewModel =
        action.ClientJobSelectBoxDataViewModel;
      return draft;
    case "ADD_CANDIDATE_FAKE_DETAILS":
      draft.ClientFakeCandidateAddResult = action.ClientFakeCandidateAddResult;
      return draft;
    case "DELETE_CANDIDATE_FAKE_DETAILS":
      draft.ClientFakeCandidateDeleteResult =
        action.ClientFakeCandidateDeleteResult;
      return draft;
    case "ALL_FAKE_CANDIDATE_DETAILS":
      draft.ClientAllFakeCandidateResul = action.ClientAllFakeCandidateResul;
      return draft;
    case "EDIT_FAKE_CANDIDATE_DETAILS":
      draft.ClientFakeCandidateEditResult =
        action.ClientFakeCandidateEditResult;
      return draft;
    case "GET_ALL_PENDING_APPROVAL_JOBS":
      draft.ClientPendingJobApprovalResult =
        action.ClientPendingJobApprovalResult;
      return draft;
    case "GET_ALL_ADMINSIDE_CLIENT_JOBS":
      draft.AdminClientSideJobs = action.AdminClientSideJobs;
      return draft;
    case "SET_CLIENT_APPROVE_OR_REJECT_JOB_FROM_ADMINSIDE":
      draft.JobApproveOrRejectFromAdminResult =
        action.JobApproveOrRejectFromAdminResult;
      return draft;
    case "GET_CANDIDATE_INFO_BY_ID_FOR_FORM":
      draft.CandidateInfoById = action.CandidateInfoById;
      return draft;
    case "ADD_CANDIDATE_FOR_FORM":
      draft.AddCandidateListForFormResult =
        action.AddCandidateListForFormResult;
      return draft;
    case "GET_ALL_APPLIED_CLIENT_LIST":
      draft.AdminGetAllApliedCandidateListForClientJob =
        action.AdminGetAllApliedCandidateListForClientJob;
      return draft;
  }
};

export const useClientJobApprovalDispatcher = (): React.Dispatch<IClientJobApprovalAction> => {
  const clientJobApprovalDispatcher = React.useContext(
    ClientJobApprovalDispatcherContext
  );
  if (!clientJobApprovalDispatcher) {
    throw new Error(
      "You have to provide the ClientJobApproval dispatcher using theClientJobApprovalDispatcherContext.Provider in a parent component."
    );
  }
  return clientJobApprovalDispatcher;
};

export const useClientJobApprovalContext = (): IClientJobApprovalManagementState => {
  const clientJobApprovalContext = React.useContext(
    ClientJobApprovalStateContext
  );
  if (!clientJobApprovalContext)
    throw new Error(
      "You have to provide the clientJobApproval context using the ClientJobApprovalStateContext.Provider in a parent component."
    );
  return clientJobApprovalContext;
};

export const getClientJobApprovalList = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: ClientJobApprovalRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetClientJobList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "CLIENTJOBAPPROVAL_LIST",
        clientJobApproval: res.data,
      });
    });
  } catch (e) {}
};

export const setClientJobStatus = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: ClientJobApprovalSaveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditClientJobStatus;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_CLIENTJOB_STATUS",
        clientJobApprovalSaveRespond: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Job approved successfully.")
          : message.error(res.data.message)
        : message.error(" Some error happend on approving job.");
    });
  } catch (e) {}
};

export const editClientJob = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: ClientJobEditRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditClientJob;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_CLIENTJOB",
        clientJobApprovalSaveRespond: res.data,
      });

      res.status == 200
        ? message.success("Career tip is updated.")
        : message.error(
            "There are some error happend on updating career tips."
          );
    });
  } catch (e) {}
};

export const getClientJob = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: ClientJobGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetClientJob;
    if (query.rowId != 0) {
      axios
        .post(url, JSON.stringify(query), { headers: header })
        .then((res) => {
          dispatcher({
            type: "GET_CLIENTJOB",
            ClientJobResultModel: res.data,
          });
        });
    } else {
      dispatcher({
        type: "GET_CLIENTJOB",
        ClientJobResultModel: {} as ClientJobResultModel,
      });
    }
  } catch (e) {}
};

export const getClientJobSelectBoxList = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetClientJobSelectBoxData;

    axios.post(url, JSON.stringify({}), { headers: header }).then((res) => {
      dispatcher({
        type: "CLIENTJOB_SELECTBOX_LIST",
        ClientJobSelectBoxDataViewModel: res.data,
      });
    });
  } catch (e) {}
};

export const GetAllFakeCandidateList = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: ClientFakeCandidateAllRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllFakeCandidate;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "ALL_FAKE_CANDIDATE_DETAILS",
        ClientAllFakeCandidateResul: res.data,
      });
    });
  } catch (e) {}
};

export const AddFakeCandidate = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: ClientFakeCandidateRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.AddFakeCandidate;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "ADD_CANDIDATE_FAKE_DETAILS",
        ClientFakeCandidateAddResult: res.data,
      });
    });
  } catch (e) {}
};

export const DeleteFakeCandidate = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: ClientFakeCandidateDeleteResultModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteAllFakeCandidate;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "DELETE_CANDIDATE_FAKE_DETAILS",
        ClientFakeCandidateDeleteResult: res.data,
      });
    });
  } catch (e) {}
};

export const EditFakeCandidate = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ClientFakeCandidateEdit;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_FAKE_CANDIDATE_DETAILS",
        ClientFakeCandidateEditResult: res.data,
      });
    });
  } catch (e) {}
};

export const GetAllPendingApprovalJobList = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: CliendPendingJobRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ClientJobPendingApprove;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_PENDING_APPROVAL_JOBS",
        ClientPendingJobApprovalResult: res.data,
      });
    });
  } catch (e) {}
};

export const GetAllAdminSideClientJobs = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: AdminSideClientJobListRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ListAllAdminClientJobs;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_ADMINSIDE_CLIENT_JOBS",
        AdminClientSideJobs: res.data,
      });
    });
  } catch (e) {}
};

export const ClientJobApproveOrRejectFromAdmin = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: JobApproveOrRejectRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ApproveOrRejectClientJobsFromAdmin;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_CLIENT_APPROVE_OR_REJECT_JOB_FROM_ADMINSIDE",
        JobApproveOrRejectFromAdminResult: res.data,
      });
    });
  } catch (e) {}
};

export const CandidateInfoByIdForForm = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: CandidateInfoByIdRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.CandidateDetailsByInfo;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_CANDIDATE_INFO_BY_ID_FOR_FORM",
        CandidateInfoById: res.data,
      });
    });
  } catch (e) {}
};

export const AddcandidateForListForm = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.AddCandidateInToForm;

    axios.post(url, query, { headers: header }).then((res) => {
      dispatcher({
        type: "ADD_CANDIDATE_FOR_FORM",
        AddCandidateListForFormResult: res.data,
      });
    });
  } catch (e) {}
};

export const GetAllAppliedCandidateInAdminSide = async (
  dispatcher: React.Dispatch<IClientJobApprovalAction>,
  query: AllAppliedCandidateListInAdminsideRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllAdminSideClientCandidatelist;

    axios.post(url, query, { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_APPLIED_CLIENT_LIST",
        AdminGetAllApliedCandidateListForClientJob: res.data,
      });
    });
  } catch (e) {}
};
