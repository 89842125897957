import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  ISearchAction,
  searchReducer,
} from "../../action/general/SearchAction";
import {
  searchDataWithOutTokenViewModel,
  searchDataWithTokenViewModel,
  searchResult,
} from "../../models/general/Search";

export const SearchDispatcherContext = React.createContext<React.Dispatch<ISearchAction> | null>(
  null
);
export const SearchStateContext = React.createContext<ISearchManagementState | null>(
  null
);

export interface ISearchManagementProps extends RouteComponentProps<any> {}

export interface ISearchManagementState {
  visible: boolean;
  value: number;
  searchWithToken: searchDataWithTokenViewModel;
  searchWithOutToken: searchDataWithOutTokenViewModel;
  searchResultData: searchResult;
}

export const initialSearchManagementState = {
  visible: false,
  value: 0,
  searchWithToken: {} as searchDataWithTokenViewModel,
  searchWithOutToken: {} as searchDataWithOutTokenViewModel,
  searchResultData: {} as searchResult,
} as ISearchManagementState;

export const SearchContextProvider: React.FC = ({ children }) => {
  const [searchState, dispatcher] = useImmerReducer(
    searchReducer,
    initialSearchManagementState
  );

  return (
    <SearchDispatcherContext.Provider value={dispatcher}>
      <SearchStateContext.Provider value={searchState}>
        {children}
      </SearchStateContext.Provider>
    </SearchDispatcherContext.Provider>
  );
};
