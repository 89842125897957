import React from "react";
import { useMyProfileContext, useMyProfileDispatcher } from "../../../action/MyProfileAction";
import { getMatchedJobVendorList, useMatchedJobVendorDispatcher } from "../../../action/vendor/VendorMatchedJobAction";
import { MatchedJobContextProvider } from "../../../context/MatchedJobContext";
import { JobAlertVendorContextProvider } from "../../../context/vendor/JobAlertContext";
import { VendorJobAppliedContextProvider } from "../../../context/vendor/VendorJobAppliedContext";
import { VendorJobBookMarkContextProvider } from "../../../context/vendor/VendorJobBookMarkContext";
import { VendorJobStatusContextProvider } from "../../../context/vendor/VendorJobStatusContext";
import { MatchedJobVendorContextProvider } from "../../../context/vendor/VendorMatchedJobContext";
import { VendorProfileImageContextProvider } from "../../../context/vendor/VendorMyProfile";
import { VendorSuggestedJobContextProvider } from "../../../context/vendor/VendorSuggestedJobContext";
import { matchedJobVendorRequestModel } from "../../../models/vendor/VendorMatchedJob";
import AuthService from "../../../services/AuthService";
import VendorLayoutComponent from "./VendorLayoutComponent";
import VendorLayoutSidebar from "./VendorLayoutSidebar";

interface IVendorLayoutContainerProps {}

interface IVendorLayoutContainerState {}

const initialState = {};

const VendorLayoutContainer: React.FC<IVendorLayoutContainerProps> = (
  props
) => {
  const [
    vendorLayoutContainerState,
    setVendorLayoutContainerState,
  ] = React.useState<IVendorLayoutContainerState>(initialState);



  return (
    <div id="wrapper">
     
      <VendorSuggestedJobContextProvider>
      <VendorJobStatusContextProvider>
      <VendorJobBookMarkContextProvider>
      <VendorJobAppliedContextProvider>
      <JobAlertVendorContextProvider>
        <MatchedJobVendorContextProvider>
      <VendorLayoutComponent />
      </MatchedJobVendorContextProvider>
      </JobAlertVendorContextProvider>
      </VendorJobAppliedContextProvider>
      </VendorJobBookMarkContextProvider>
      </VendorJobStatusContextProvider>
      </VendorSuggestedJobContextProvider>
  
    </div>
  );
};
export default VendorLayoutContainer;
