import React from "react";
import { environment } from "../../../environments/environment";
import { ClientViewApplicationDetailsResultModel } from "../../../models/client/ClientJob";
import {
  FileDoneOutlined,
  IdcardOutlined,
  WalletOutlined,
} from "@ant-design/icons";
interface IClientViewApplicationDetailsResultModel {
  clientViewDetails: ClientViewApplicationDetailsResultModel;
}

const ClientViewApplicationDetails = (
  props: IClientViewApplicationDetailsResultModel
) => {
  const { clientViewDetails } = props;
  return (
    <>
      {" "}
      {clientViewDetails.candidateId != undefined ? (
        <>
          <h2
            className="resp-accordion resp-tab-active"
            role="tab"
            aria-controls="tab_item-0"
          >
            <span className="resp-arrow" />
            <div className="candidates_check">
              <input
                id="checkbox2"
                type="checkbox"
                name="checkbox"
                defaultValue={1}
              />
              <label htmlFor="checkbox2">
                <span />
              </label>
            </div>
            <div className="candidates_icon">
              <img
                src={
                  environment.baseUrl +
                  `/Upload/ProfileImage/${clientViewDetails.candidateImage}`
                }
              />
            </div>
            <div className="candidates_con">
              <div className="candidates_con_head">
                {clientViewDetails.candidateName}
              </div>
              <div className="candidates_con_con">
                Candidate Id-{clientViewDetails.candidateId}
              </div>
              <div className="candidates_con_con">
                {clientViewDetails.location}
              </div>
            </div>
          </h2>
          <div
            className="resp-tab-content resp-tab-content-active"
            aria-labelledby="tab_item-0"
            style={{ display: "block" }}
          >
            <div className="candidates_icon1">
              {clientViewDetails.candidateImage != null ? (
                <img
                  src={
                    environment.baseUrl +
                    `/Upload/ProfileImage/${clientViewDetails.candidateImage}`
                  }
                />
              ) : (
                <img
                  className="img-responsive"
                  src={require("./../../../images/profileDefault1.jpg")}
                ></img>
              )}
            </div>
            <div className="candidates_con1">
              <div className="candidates_con_head1">
                <a href="admin_myjobs_candidates_details.html">
                  {" "}
                  {clientViewDetails.candidateName}
                </a>
              </div>
              <div className="candidates_con_con1">
                Candidate Id-{clientViewDetails.candidateId}
              </div>
              <div className="candidates_con_con1">
                {clientViewDetails.location}
              </div>
            </div>
            <div className="clearfix" />
            <div className="row m-t-25">
              {clientViewDetails.resumeFileFlag ? (
                <div className="col-sm-3">
                  <label> Resume</label>
                  <div className="form-group">
                    <a
                      onClick={() =>
                        window.open(
                          environment.baseUrl +
                            "/Upload/CandidateDocuments/Resume/" +
                            clientViewDetails.resumeFileName,
                          "_blank"
                        )
                      }
                    >
                      <FileDoneOutlined
                        style={{
                          fontSize: 60,
                          color: "cornflowerblue",
                          cursor: "pointer",
                        }}
                      />
                    </a>
                    {/* <input
    type="email"
    className="form-control"
    placeholder="Type here"
  /> */}
                  </div>
                </div>
              ) : null}
              {clientViewDetails.certificateFileFlag ? (
                <div className="col-sm-3">
                  <label> Certificates</label>
                  <div className="form-group">
                    <a
                      onClick={() =>
                        window.open(
                          environment.baseUrl +
                            "/Upload/CandidateDocuments/Certificate/" +
                            clientViewDetails.certificateFileName,
                          "_blank"
                        )
                      }
                    >
                      <WalletOutlined
                        style={{ fontSize: 60, color: "cadetblue" }}
                      />
                    </a>
                  </div>
                </div>
              ) : null}
              {clientViewDetails.passportFileFlag ? (
                <div className="col-sm-3">
                  <label>Passport</label>
                  <div className="form-group">
                    <a
                      onClick={() =>
                        window.open(
                          environment.baseUrl +
                            "/Upload/CandidateDocuments/Passport/" +
                            clientViewDetails.passportFileName,
                          "_blank"
                        )
                      }
                    >
                      <IdcardOutlined
                        style={{ fontSize: 60, color: "yellowgreen" }}
                      />
                    </a>
                  </div>
                </div>
              ) : null}
              {clientViewDetails.residentStatusFileFlag ? (
                <div className="col-sm-3">
                  <label>residentStatus</label>
                  <div className="form-group">
                    <a
                      onClick={() =>
                        window.open(
                          environment.baseUrl +
                            "/Upload/CandidateDocuments/ResidentStatus/" +
                            clientViewDetails.residentStatusFileName,
                          "_blank"
                        )
                      }
                    >
                      <IdcardOutlined
                        style={{ fontSize: 60, color: "yellowgreen" }}
                      />
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
            {/*<a href="#" class="btn submit_btn">Save</a> */}
          </div>
        </>
      ) : null}
    </>
  );
};

export default ClientViewApplicationDetails;
