import React from "react";
import { useDigiLockerContext } from "../../../action/candidate/DigiLockerAction";
import { DigiLockerContextProvider } from "../../../context/candidate/DigiLockerContext";
import CandidateDigilockerComponent from "./CondidateDigilockerComponent";
import DigilockerOtpVarification from "./DigilockerOtpVarification";

interface ICandidateDigilockerContainerProps {}

interface ICandidateDigilockerContainerState {}

const initialState = {};

const CandidateDigilockerContainer: React.FC<ICandidateDigilockerContainerProps> = (
  props
) => {
  const [
    CandidateDigilockerContainerState,
    setCandidateDigilockerContainerState,
  ] = React.useState<ICandidateDigilockerContainerState>(initialState);
  const digiLockerMarkContext = useDigiLockerContext();
  const { digiLockerDetails } = digiLockerMarkContext;

  return (
    <>
      {/* <CandidateDigilockerComponent/> */}
      <DigilockerOtpVarification />
    </>
  );
};
export default CandidateDigilockerContainer;
