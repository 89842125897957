import React from "react";
import { useParams } from "react-router-dom";
import {
  GetAdminMyJob,
  useAdminMyJobContext,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
// import {
//   getAdminLoggedUserId,
//   useAdminMyProfileDispatcher,
// } from "../../../action/MyProfileAdminAction";
import {
  getAdminLoggedUserId,
  getAdminMyProfileBasicInfo,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import { RowIdRequestModel } from "../../../models/admin/AdminMyJob";
import AuthService from "../../../services/AuthService";
import JobApplicationComponent from "./JobApplicationComponent";

const JobApplicationContainer = () => {
  const authorizationToken = AuthService.accessToken;
  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { adminEditRespond } = adminMyJobContext;
  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();

  let user = AuthService.currentUser;

  let { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await GetAdminMyJob(
          adminMyJobDispatcher,
          {
            RowId: id,
          } as RowIdRequestModel,
          authorizationToken
        );
      })();
    }
  }, [id]);

  //console.log(288, adminEditRespond);

  // React.useEffect(() => {
  //   if (authorizationToken != null && user?.id != null)
  //     (async () => {
  //       await getAdminLoggedUserId(
  //         myProfileAdminDispatcher,
  //         parseInt(user.id),
  //         authorizationToken
  //       );
  //     })();
  // }, [authorizationToken]);
  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null) {
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          parseInt(user.id),
          authorizationToken
        );
      })();

      (async () => {
        await getAdminMyProfileBasicInfo(
          myProfileAdminDispatcher,
          authorizationToken
        );
      })();
    }
  }, [authorizationToken]);

  return (
    <>
      <JobApplicationComponent />
    </>
  );
};

export default JobApplicationContainer;
