import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";

import {
  globelCounterReducer,
  IGlobelCounterAction,
} from "../../action/general/GlobelCounterAction";

export const GlobelCounterDispatcherContext = React.createContext<React.Dispatch<IGlobelCounterAction> | null>(
  null
);
export const GlobelCounterStateContext = React.createContext<IGlobelCounterManagementState | null>(
  null
);

export interface IGlobelCounterManagementProps
  extends RouteComponentProps<any> {}

export interface IGlobelCounterManagementState {
  isAccessible: boolean;
  startTime: number;
}

export const initialGlobelCounterManagementState = {
  isAccessible: false,
  startTime: 0,
} as IGlobelCounterManagementState;

export const GlobelCounterContextProvider: React.FC = ({ children }) => {
  const [globelCounterState, dispatcher] = useImmerReducer(
    globelCounterReducer,
    initialGlobelCounterManagementState
  );

  return (
    <GlobelCounterDispatcherContext.Provider value={dispatcher}>
      <GlobelCounterStateContext.Provider value={globelCounterState}>
        {children}
      </GlobelCounterStateContext.Provider>
    </GlobelCounterDispatcherContext.Provider>
  );
};
