import React from "react";
import {
  getLoggedUserId,
  getLoggedVendorId,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import { getSuperAdminLoggedUserId } from "../../../action/MyProfileSuperAdminAction";
import { NotificationListContextProvider } from "../../../context/vendor/VendorMyProfile";
import AuthService from "../../../services/AuthService";
import VendorDashboardComponent from "./VendorDashboardComponent";

interface IVendorDashboardContainerProps {}
interface IVendorDashboardContainerState {}
const VendorDashboardContainer: React.FC<IVendorDashboardContainerProps> = (
  props
) => {
  let user = AuthService.currentUser;
  let role = AuthService.currentUser?.roles[0];
  const authorizationToken = AuthService.accessToken;

  const myProfileDispatcher = useMyProfileDispatcher();

  React.useEffect(() => {
    if (role === "Candidate") {
      if (authorizationToken != null && user?.id != null) {
        (async () => {
          await getLoggedVendorId(
            myProfileDispatcher,
            parseInt(user.id),
            authorizationToken
          );
        })();
      }
    }
  }, [authorizationToken]);
  return (
    <>
      <NotificationListContextProvider>
        <VendorDashboardComponent />
      </NotificationListContextProvider>
    </>
  );
};

export default VendorDashboardContainer;
