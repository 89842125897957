import React, { useState } from "react";
import Skills from "./Skills";
import Trainings from "./Trainings";
import Experience from "./Experience";
import Qualification from "./Qualification";
import Communication from "./Communication";
import PersonelInfo from "./PersonelInfo";
import { MyProfileSelectBoxDataViewModel } from "../../../../models/candidate/MyProfileSelectBoxData";


interface IResumeMyInformationComponentProps {

  myProfile: MyProfileSelectBoxDataViewModel;
  candidateExperiences: any;
  getCandidateExperiences: any;
  candidateId: any;
  candidateState: any;
  getCandidates: any;
  candidateLanguageMaps: any[];
  getCandidateLanguageMaps: any;
  getCandidateSkills: any;
  candidateSkillsState: any;
  getTrainings: any;
  trainingState: any;
  educationQualificationState: any;
  getEducationQualifications: any;
}

interface IResumeMyInformationComponentState { }

const initialState = {};

const ResumeMyInformationComponent: React.FC<IResumeMyInformationComponentProps> = (props) => {
  const [resumeMyInformationComponentState, setResumeMyInformationComponentState] = React.useState<IResumeMyInformationComponentState>(
    initialState
  );

  const [active, setActive] = useState("Personal Info");

  const titles = [
    "Personal Info",
    "Communication",
    "Qualification",
    "Experience",
    "Trainings",
    "Skills",
  ];

  const _titles = titles.map((e, i) => {
    return (
      <li
        key={i}
        onClick={() => {
          setActive(e);
        }}
        className={
          e == active ? "resp-tab-item resp-tab-active" : "resp-tab-item"
        }
        aria-controls={`tab_item-${i}`}
        role="tab"
        style={{ width: "16%" }}
      >
        {e}
      </li>
    );
  });

  let tab = null;

  if (active == "Personal Info") {
    tab = (
      <PersonelInfo myProfile={props.myProfile}
        getCandidates={props.getCandidates}
        candidateState={props.candidateState}
        candidateId={props.candidateId}
        candidateLanguageMaps={props.candidateLanguageMaps}
        getCandidateLanguageMaps={props.getCandidateLanguageMaps} />
    );
  } else if (active == "Communication") {
    tab = (
      <Communication
        getCandidates={props.getCandidates}
        candidateState={props.candidateState}
        candidateId={props.candidateId}
      />
    );
  } else if (active == "Qualification") {
    tab = (
      <Qualification
        educationQualificationState={props.educationQualificationState}
        getEducationQualifications={props.getEducationQualifications}
        candidateId={props.candidateId}
      />
    );
  } else if (active == "Experience") {
    tab = (
      <Experience
        candidateExperiences={props.candidateExperiences}
        getCandidateExperiences={props.getCandidateExperiences}
        candidateId={props.candidateId}
      />
    );
  } else if (active == "Trainings") {
    tab = (
      <Trainings
        getTrainings={props.getTrainings}
        trainingState={props.trainingState}
        candidateId={props.candidateId}
      />
    );
  } else if (active == "Skills") {
    tab = (
      <Skills
        getCandidateSkills={props.getCandidateSkills}
        candidateSkillsState={props.candidateSkillsState}
        candidateId={props.candidateId}
      />
    );
  }

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Resume</h1>
          <div className="clearfix" />
          <div className="row">
            <div className="col-sm-12">
              <div className="pfofile_tab_sec">
                <div id="horizontalTab" style={{ display: 'block', width: '100%', margin: '0px' }}>
                  <ul className="resp-tabs-list profile-tabs">{_titles}</ul>
                  <div className="resp-tabs-container resp-tab-contents">
                    {tab}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modals */}
    </React.Fragment>
  );
};
export default ResumeMyInformationComponent;
