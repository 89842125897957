import React from "react";
import IndexComponent from "./IndexComponent";

interface IIndexContainerProps {}

interface IIndexContainerState {}

const initialState = {};

const IndexContainer: React.FC<IIndexContainerProps> = (props) => {
  const [IndexContainerState, setIndexContainerState] = React.useState<
    IIndexContainerState
  >(initialState);

  return (
    <>
      <IndexComponent />
    </>
  );
};
export default IndexContainer;
