import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import FileUploads from "./../candidate/my_profile/components/FileUploads";
import _ from "lodash";
import countryTelData from "country-telephone-data";
import PhoneNumberWithCode from "./../components/PhoneNumberWithCode";
import { useForm, Controller } from "react-hook-form";
import Pikaday from "pikaday";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";

interface IPikadayDatepickerProps {
  disabled: boolean;
  onChange: (e: number) => void;
  onBlur: (e: object) => void;
  value: number;
  name: string;
  overrideCursor?: boolean;
}

class PikadayDatepicker extends React.Component<
  IPikadayDatepickerProps,
  { isDom: boolean }
> {
  private myRef: React.RefObject<HTMLInputElement>;

  private myRef2: React.RefObject<HTMLInputElement>;

  private _picker: any;

  private _picker2: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isDom: false,
    };
    this.myRef = React.createRef();
    this.myRef2 = React.createRef();
  }

  setDateIfChanged = (newDate: number, prevDate?: number) => {
    let newTime = newDate ? newDate : Date.now();
    let prevTime = prevDate ? prevDate : 0;

    if (this.state.isDom) {
      setTimeout(() => {
        this._picker.setDate(
          moment(new Date(newTime)).format("YYYY-MM-DD"),
          true
        );
      }, 10);
    }
  };

  componentDidMount() {
    const _this = this;

    this.setState(
      {
        isDom: true,
      },
      () => {
        this._picker = new Pikaday({
          field: this.myRef.current,
          format: "DD/MM/YYYY",
          // defaultDate: new Date(_this.props.value),
          // setDefaultDate: true,
          onSelect: function (e: Date) {
            _this.props.onChange(e.getTime());
          },
        });

        this._picker2 = new Pikaday({
          field: this.myRef2.current,
          format: "DD/MM/YYYY",
          onSelect: function (e: Date) {
            _this.props.onChange(e.getTime());
          },
        });

        // this.setDateIfChanged(this.props.value);
      }
    );
  }

  componentWillReceiveProps(nextProps: any) {
    let newDate = nextProps.value;
    let lastDate = this.props.value;
    this.setDateIfChanged(newDate, lastDate);
  }

  render() {
    const { disabled, overrideCursor, value } = this.props;

    return (
      <React.Fragment>
        <input
          style={{ display: value != 0 ? "inline-block" : "none" }}
          type="text"
          ref={this.myRef}
          disabled={disabled}
          id="pickaday_datepicker_custom"
          className={
            disabled
              ? `text-wrapper select-calander _cursor-no-drop`
              : "text-wrapper select-calander"
          }
        />
        <input
          style={{ display: value == 0 ? "inline-block" : "none" }}
          type="text"
          ref={this.myRef2}
          disabled={disabled}
          id="pickaday_datepicker_custom2"
          className={
            disabled
              ? `text-wrapper select-calander _cursor-no-drop`
              : "text-wrapper select-calander"
          }
        />
      </React.Fragment>
    );
  }
}

function JitTest() {
  const [hasMore, setHasMore] = React.useState(true);
  const [items, setItems] = React.useState(Array.from({ length: 20 }));

  const fetchMoreData = () => {
    if (items.length >= 500) {
      setHasMore(false);
      return;
    }
    // a fake async api call like which sends
    // 20 more records in .5 secs
    setTimeout(() => {
      setItems(items.concat(Array.from({ length: 2 })));
    }, 500);
  };

  let defaultValues = {
    dob: 0,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<{ dob: number }>({
    defaultValues,
  });

  React.useEffect(() => {
    reset({
      dob: 1607352745920,
    });
  }, []);

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <React.Fragment>
      <h1>jit test component</h1>

      <div className="row">
        <div className="col-sm-12">
          <div className="pfofile_tab_sec">
            <div id="horizontalTab">
              <ul className="resp-tabs-list profile-tabs">
                <li>Pesonal Info</li>
              </ul>
              <div className="resp-tabs-container resp-tab-contents">
                {/*---------Start-------------------*/}
                <div>
                  <div className="profile_top">
                    <h1>Pesonal Info</h1>
                    <div className="profile_edit">
                      <a href="#">
                        <i className="fa fa-pencil" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                  <div>
                    <form
                      className="personal_details"
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate
                    >
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <div className="col-sm-5">
                              <label> Role </label>
                            </div>
                            <div className="col-sm-7">
                              {/* <input type="email" className="form-control" placeholder="Lead Developement" /> */}
                              <Controller
                                rules={{
                                  required: "required",
                                  validate: (value) => {
                                    // if (value < Date.now()) {
                                    //   return "DOB cannot be a future date.";
                                    // } else if (value < 1) {
                                    //   return "required";
                                    // } else {
                                    //   return undefined;
                                    // }
                                    return value < Date.now()
                                      ? undefined
                                      : "DOB cannot be a future date.";
                                  },
                                }}
                                control={control}
                                name="dob"
                                render={({ onChange, onBlur, value, name }) => {
                                  console.log(value);
                                  return (
                                    <PikadayDatepicker
                                      disabled={false}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      name={name}
                                    />
                                  );
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="dob"
                                render={({ message }: { message: any }) => (
                                  <div className="login_validation">
                                    {message}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix" />
                      <button className="btn submit_btn" type="submit">
                        Save
                      </button>
                    </form>
                  </div>
                </div>
                {/*---------End-------------------*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <FileUploads /> */}

      <InfiniteScroll
        className="_custom-infinite-scroll-class"
        height={400}
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        {items.map((i: any, index: any) => (
          <div
            style={{
              height: 30,
              border: "1px solid green",
              margin: 6,
              padding: 8,
            }}
            key={index}
          >
            div - #{index}
          </div>
        ))}
      </InfiniteScroll>
    </React.Fragment>
  );
}

// function PhoneNumberWithCode(props: any) {

//   const wrapperRef = React.useRef<HTMLUListElement>(null);

//   const wrapperRef2 = React.useRef<HTMLButtonElement>(null);

//   const [cCode, setCCode] = React.useState("in");

//   const [isOpen, setIsOpen] = React.useState(false);

//   React.useEffect(() => {

//     function handleClickOutside(event: any) {
//       if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
//         console.log("You clicked outside of me!");
//         if (wrapperRef2.current && !wrapperRef2.current?.contains(event.target)) {
//           console.log("You clicked outside button!");
//           setIsOpen(false);
//         }
//       }
//     }

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [wrapperRef]);

//   const val = _.find(countryTelData.allCountries, { iso2: cCode });

//   const lis = countryTelData.allCountries.map((e: any, i: any) => {

//     const c = e.name.indexOf("(");

//     let s = e.name;

//     if (c > -1) {
//       s = s.substr(0, c);
//     }

//     return (
//       <React.Fragment>
//         <li key={i} style={{ width: "300px" }}>
//           <a role="button" className="country" onClick={() => { setCCode(e.iso2); setIsOpen(false); }}>
//             <i style={{ marginRight: '10px' }} id={e.iso2} className={`flag ${e.iso2}`} />
//             {s}
//             <i id={e.dialCode} className="callingCode" style={{ marginLeft: '10px' }}>
//               +{e.dialCode}
//             </i>
//           </a>
//         </li>
//         <li />
//       </React.Fragment>
//     );
//   });

//   return (
//     <React.Fragment>
//       <div className="input-phone">
//         <div className="input-group">
//           <div className={isOpen ? "input-group-btn open" : "input-group-btn"}>
//             <button ref={wrapperRef2} onClick={() => { setIsOpen(!isOpen); }} type="button" id="btn-country" className="btn btn-default  f16 dropdown-toggle" style={{ height: "40px" }}>
//               <span className={`flag ${val ? val.iso2 : ""} btn-flag`} style={{ marginTop: "3px" }} />
//               <span className="btn-cc">&nbsp;&nbsp; +{val ? val.dialCode : ""} &nbsp;&nbsp;</span><span className="caret" />
//             </button>
//             <ul ref={wrapperRef} className="dropdown-menu f16" style={{ overflowX: "hidden" }}>
//               {lis}
//             </ul>
//           </div>
//           <input type="text" name="phoneNumber" id="phoneNumber" className="form-control phoneNumber" placeholder="Phone Number" /><input type="hidden" name="defaultCountry" id="defaultCountry" size={2} defaultValue="fr" /><input type="hidden" name="carrierCode" id="carrierCode" size={2} defaultValue={33} />
//         </div>
//       </div>
//       <div className="profile_edit2">
//         <a><i className="fa fa-pencil" aria-hidden="true" /></a>
//       </div>
//     </React.Fragment>
//   );
// }

// class PhoneNumberWithCode extends React.Component<{}, { isOpen: boolean, cCode: string }> {

//   private wrapperRef: React.RefObject<HTMLInputElement>;

//   constructor(props: any) {
//     super(props);
//     this.state = {
//       isOpen: false,
//       cCode: "in"
//     }
//     this.wrapperRef = React.createRef();
//   }

//   componentWillMount() {
//     let _this = this;
//     // hide on route change
//     // if (browserHistory && browserHistory.listen) {
//     //   _this.unlisten = browserHistory.listen( location =>  {
//     //     _this.state.isOpen ? _this.setState({ isOpen: false }) : "";
//     //   });
//     // }
//   }

//   componentDidMount() {
//     document.addEventListener('mousedown', this.handleClickOutside);
//   }

//   componentWillUnmount() {
//     document.removeEventListener('mousedown', this.handleClickOutside);
//   }

//   selectCode = (c: any) => {
//     console.log(c);
//     let _this = this;
//     this.setState({ cCode: c, isOpen: false });
//   }

//   handleClickOutside = (event: any) => {
//     if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
//       if (this.state.isOpen) {
//         this.setState({ isOpen: false });
//       }
//     }
//   }

//   render() {

//     const { cCode, isOpen } = this.state;

//     const val = _.find(countryTelData.allCountries, { iso2: cCode });

//     console.log(val);

//     const _this = this;

//     const lis = countryTelData.allCountries.map((e: any, i: any) => {

//       const c = e.name.indexOf("(");

//       let s = e.name;

//       if (c > -1) {
//         s = s.substr(0, c);
//       }

//       return (
//         <React.Fragment key={i}>
//           <li style={{ width: "300px" }} key={i}>
//             <a key={i} role="button" className="country" onClick={() => { console.log(e.iso2); _this.selectCode(e.iso2); }}>
//               <i style={{ marginRight: '10px' }} id={e.iso2} className={`flag ${e.iso2}`} />
//               {s}
//               <i id={e.dialCode} className="callingCode" style={{ marginLeft: '10px' }}>
//                 +{e.dialCode}
//               </i>
//             </a>
//           </li>
//           <li />
//         </React.Fragment>
//       );
//     });

//     return (
//       <React.Fragment>
//         <div className="input-phone">
//           <div className="input-group">
//             <div className={isOpen ? "input-group-btn open" : "input-group-btn"}>
//               <button onClick={() => { this.setState({ isOpen: !isOpen }); }} type="button" id="btn-country" className="btn btn-default  f16 dropdown-toggle" style={{ height: "40px" }}>
//                 <span className={`flag ${val ? val.iso2 : ""} btn-flag`} style={{ marginTop: "3px" }} />
//                 <span className="btn-cc">&nbsp;&nbsp; +{val ? val.dialCode : ""} &nbsp;&nbsp;</span><span className="caret" />
//               </button>
//               <ul className="dropdown-menu f16" style={{ overflowX: "hidden" }}>
//                 {lis}
//               </ul>
//             </div>
//             <input type="text" name="phoneNumber" id="phoneNumber" className="form-control phoneNumber" placeholder="Phone Number" />
//           </div>
//         </div>
//         <div className="profile_edit2">
//           <a><i className="fa fa-pencil" /></a>
//         </div>
//       </React.Fragment>
//     );
//   }
// }

export default JitTest;
