import { ErrorMessage } from "@hookform/error-message";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { Controller, useForm } from "react-hook-form";
import {
  Link,
  RouteComponentProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  getTitleWithCategoryId,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../action/candidate/JobAlertAction";
import {
  getDatePostedList,
  useDatePostedContext,
  useDatePostedDispatcher,
} from "../../action/general/DatePostedAction";
import {
  getExperienceList,
  useExperienceContext,
  useExperienceDispatcher,
} from "../../action/general/ExperienceAction";
import {
  getJobTypeList,
  useJobTypeContext,
  useJobTypeDispatcher,
} from "../../action/general/JobTypeAction";
import {
  getNewlyPostedJobList,
  useNewlyPostedJobContext,
  useNewlyPostedJobDispatcher,
} from "../../action/general/NewlyPostedJobAction";
import {
  getSearchListWithTokenAsVendor,
  useSearchContext,
  useSearchDispatcher,
} from "../../action/general/SearchAction";
import { useVendorMyProfileContext } from "../../action/MyProfileVendorAction";
import {
  setJobAlertVendorSave,
  useJobAlertVendorContext,
  useJobAlertVendorDispatcher,
} from "../../action/vendor/JobAlertAction";
import {
  getVendorJobAppliedList,
  useVendorJobAppliedContext,
  useVendorJobAppliedDispatcher,
} from "../../action/vendor/VendorJobAppliedAction";
import {
  getVendorJobBookMarkList,
  setVendorJobBookmark,
  useVendorJobBookMarkContext,
  useVendorJobBookMarkDispatcher,
} from "../../action/vendor/VendorJobBookMarkAction";
import {
  getMatchedJobVendorList,
  useMatchedJobVendorContext,
  useMatchedJobVendorDispatcher,
} from "../../action/vendor/VendorMatchedJobAction";
import {
  getVendorSuggestedJobList,
  useVendorSuggestedJobContext,
  useVendorSuggestedJobDispatcher,
} from "../../action/vendor/VendorSuggestedJobAction";
import { updateAccessToken } from "../../apis/misc";
import { useCandidateProfileImageContext } from "../../context/candidate/CandidateMyProfile";
import { useVendorProfileImageContext } from "../../context/vendor/VendorMyProfile";
import { jobAlertTitleDropdownResultModel } from "../../models/candidate/JobAlert";
import { categoryWithId } from "../../models/candidate/JobCategory";
import { jobViewModel } from "../../models/candidate/MatchedJob";
import { datePostedRequestModel } from "../../models/general/DatePosted";
import { experienceRequestModel } from "../../models/general/Experience";
import { jobTypeRequestmodel } from "../../models/general/JobType";
import { newlyPostedJobRequestModel } from "../../models/general/NewlyPostedJob";
import { VendorSearchDataWithTokenRequestModel } from "../../models/general/Search";
import { jobAlertVendorSaveRequestModel } from "../../models/vendor/JobAlert";
import { VendorGetAllJobRequestModel } from "../../models/vendor/VendorJobApplied";
import { VendorJobBookMarkRequestModel } from "../../models/vendor/VendorJobBookMark";
import { matchedJobVendorRequestModel } from "../../models/vendor/VendorMatchedJob";
import { VendorSuggestedRequestModel } from "../../models/vendor/VendorSuggestedJob";
import AuthService from "../../services/AuthService";
import SelectOption from "../candidate/my_profile/components/SelectOption";
import JobSearchDetailedComponent from "./JobSearchDetailedComponent";

interface IJobSearchVendorComponentProps extends RouteComponentProps {}

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IJobSearchVendorComponentState {
  jobCategory: string;
  // jobList: matchedJobViewModel;
  // jobAppliedViewList: jobAppliedCandidateResulViewtModel;
  // jobAppliedViewListData: jobAppliedCandidateResultModel;
  // jobListData: matchedJob[];
  // jobListDetails: matchedJob;
  jobTitle: string;
  location: string;
  type: string[];
  types: string[];
  experiences: string;
  datePost: string;
  jobTypeData: string;
  currentJobId: number;
  filterFlag: boolean;
  tempData: jobAlertTitleDropdownResultModel[];
  jobCategorys: string;
}

const defaultValues = {
  jobCategory: "1",
  // jobList: {} as matchedJobViewModel,
  // jobListDetails: {} as matchedJob,
  // jobListData: {} as matchedJob[],
  // jobAppliedViewList: {} as jobAppliedCandidateResulViewtModel,
  // jobAppliedViewListData: {} as jobAppliedCandidateResultModel,
  jobTitle: "",
  location: "",
  type: [],
  types: [],
  experiences: "1",
  datePost: "",
  jobTypeData: "",
  currentJobId: 0,
  filterFlag: true,
  tempData: [],
  jobCategorys: "",
};

const JobSearchVendorComponent: React.FC<IJobSearchVendorComponentProps> = (
  props: any
) => {
  let history = useHistory();
  const authorizationToken = AuthService.accessToken;
  // const authorizationToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8';
  // let loggedUserId=1;

  const [
    JobSearchVendorComponentState,
    setJobSearchVendorComponentState,
  ] = React.useState<IJobSearchVendorComponentState>(defaultValues);

  const {
    types,
    datePost,
    experiences,
    tempData,
    location,
  } = JobSearchVendorComponentState;

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IJobSearchVendorComponentState>({
    defaultValues,
  });

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const myProfileContext = useVendorMyProfileContext();
  const {
    myProfile,
    loggedUserId,
    profileImage,
    basicInfo,
    myProfileProgressBar,
  } = myProfileContext;

  const candidateProfileImageContext = useCandidateProfileImageContext();

  let jobCategoryData: ReactSelectOption[] = [];
  let jobTypeDatas: ReactSelectOption[] = [];
  const watchAllFields = watch2();
  const {
    candidateProfileImage,
    getCandidateProfileImage,
  } = candidateProfileImageContext;

  const jobAlertVendorDispatcher = useJobAlertVendorDispatcher();
  const jobAlertVendorContext = useJobAlertVendorContext();
  const {
    jobAlertVendorSaveRespond,
    jobAlertVendorGetAll,
  } = jobAlertVendorContext;

  const jobAlertDispatcher = useJobAlertDispatcher();
  const jobAlertContext = useJobAlertContext();
  const {
    jobAlert,
    jobAlertGetAll,
    jobAlertSaveRespond,
    jobAlertTitleDropdownResult,
  } = jobAlertContext;

  const jobTypeDispatcher = useJobTypeDispatcher();
  const jobTypeContext = useJobTypeContext();
  const { jobType } = jobTypeContext;

  const experienceDispatcher = useExperienceDispatcher();
  const experienceContext = useExperienceContext();
  const { experience } = experienceContext;

  const datePostedDispatcher = useDatePostedDispatcher();
  const datePostedContext = useDatePostedContext();
  const { datePosted } = datePostedContext;

  const searchDispatcher = useSearchDispatcher();
  const searchContext = useSearchContext();
  const { searchWithToken, searchWithOutToken } = searchContext;

  const newlyPostedJobDispatcher = useNewlyPostedJobDispatcher();
  const newlyPostedJobContext = useNewlyPostedJobContext();
  const { newlyPostedJob } = newlyPostedJobContext;

  const jobAppliedVendorDispatcher = useVendorJobAppliedDispatcher();
  const jobAppliedVendorContext = useVendorJobAppliedContext();
  const {
    vendorJobAppliedSaveRespond,
    vendorGetAllJobList,
  } = jobAppliedVendorContext;

  const VendorProfileImageContext = useVendorProfileImageContext();
  const {
    vendorProfileImage,
    getVendorProfileImage,
  } = VendorProfileImageContext;

  const matchedJobVendorDispatcher = useMatchedJobVendorDispatcher();
  const matchedJobVendorContext = useMatchedJobVendorContext();
  const { matchedJobVendor } = matchedJobVendorContext;

  const vendorJobBookMarkDispatcher = useVendorJobBookMarkDispatcher();
  const vendorJobBookMarkContext = useVendorJobBookMarkContext();
  const {
    bookmarkSaveResult,
    vendorGetAllJobBookMark,
  } = vendorJobBookMarkContext;

  const vendorSuggestedJobDispatcher = useVendorSuggestedJobDispatcher();
  const vendorSuggestedJobContext = useVendorSuggestedJobContext();
  const {
    vendorSuggestedJobs,
    vendorSuggestedJobTitle,
  } = vendorSuggestedJobContext;

  //console.log(4434, vendorGetAllJobList);

  const [currentAlertId, setcurrentAlertId] = useState<number>(0);
  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isJobTypeOpen, setIsJobTypeOpen] = useState<boolean>(true);
  const [isExperienceOpen, setExperienceOpen] = useState<boolean>(true);
  const [isDatePostedOpen, setIsDatePostedOpen] = useState<boolean>(true);
  const [currentJobId, setCurrentJobId] = useState<number>(0);
  const [currentJobList, setCurrentJobList] = useState<jobViewModel[]>([]);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [name, setName] = useState("");
  const user = AuthService.currentUser;
  // menu close onClick outside
  const menuRef = useRef<any>();
  let role = AuthService.currentUser?.roles[0];
  let { api, id, jobId } = useParams<{ api: string; id: any; jobId: any }>();

  let apis = ["appliedJob", "matched", "saved", "newly", "search", "suggested"];

  React.useEffect(() => {
    if (1) {
      if (id == 0 && searchWithToken.data != undefined && api === "search") {
        //console.log(8898, parseInt(id));
        if (searchWithToken.data.length > 0) {
          setCurrentJobId(searchWithToken.data[0].jobId);
        }
        setCurrentJobList(searchWithToken.data);
      } else if (
        id != 0 &&
        api === "appliedJob" &&
        vendorGetAllJobList.data != undefined
      ) {
        setCurrentJobId(parseInt(id));
        setCurrentJobList(vendorGetAllJobList.data);
      } else if (
        id != 0 &&
        api === "matched" &&
        matchedJobVendor.data != undefined
      ) {
        setCurrentJobId(parseInt(id));
        setCurrentJobList(matchedJobVendor.data);
      } else if (
        id != 0 &&
        api === "saved" &&
        vendorGetAllJobBookMark.data != undefined
      ) {
        setCurrentJobId(parseInt(id));
        setCurrentJobList(vendorGetAllJobBookMark.data);
      } else if (
        id != 0 &&
        api === "suggested" &&
        vendorSuggestedJobs.data != undefined
      ) {
        setCurrentJobId(parseInt(id));
        setCurrentJobList(vendorSuggestedJobs.data);
      } else if (
        id != 0 &&
        api === "newly" &&
        newlyPostedJob.data != undefined
      ) {
        setCurrentJobId(parseInt(id));
        //  setCurrentJobList(newlyPostedJob.data);
      } else if (
        !apis.includes(api) &&
        searchWithToken.data != undefined &&
        searchWithToken.data.length > 0
      ) {
        setCurrentJobId(searchWithToken.data[0].jobId);
        setCurrentJobList(searchWithToken.data);
      }
    }
  }, [
    id,
    searchWithToken.data,
    vendorGetAllJobList.data,
    matchedJobVendor.data,
    vendorSuggestedJobs.data,
  ]);

  //console.log(6776, currentJobList);

  React.useEffect(() => {
    (async () => {
      await getNewlyPostedJobList(newlyPostedJobDispatcher, {
        Page: 1,
        PageSize: 60,
        SearchTerm: "",
        SortOrder: "",
      } as newlyPostedJobRequestModel);
    })();
  }, []);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getVendorSuggestedJobList(
          vendorSuggestedJobDispatcher,
          {
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
            vendorId: loggedUserId,
          } as VendorSuggestedRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId, bookmarkSaveResult]);

  const handleJobAlert = (data: any) => {
    setJobSearchVendorComponentState({
      ...JobSearchVendorComponentState,
      jobCategorys: data.jobCategorys,
      jobTypeData: data.jobTypeData,
    });

    if (authorizationToken != null) {
      (async () => {
        await setJobAlertVendorSave(
          jobAlertVendorDispatcher,
          {
            rowId: currentAlertId,
            jobAlertId: 1,
            VendorId: loggedUserId,
            JobCategoryId: parseInt(data.jobCategorys),
            location: data.location,
            jobTitle: data.jobTitle,
            JobTypeId: 1,
            // Active: true,
            isActive: true,
            Active: (data.radio = "1" ? true : false),
          } as jobAlertVendorSaveRequestModel,
          authorizationToken
        );
      })();
      setIsOpen(!isOpen);
    }
  };

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getJobTypeList(jobTypeDispatcher, {
          page: 1,
          pageSize: 100,
          searchTerm: "",
          sortOrder: "",
        } as jobTypeRequestmodel);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getExperienceList(experienceDispatcher, {
          page: 1,
          pageSize: 100,
          searchTerm: "",
          sortOrder: "",
        } as experienceRequestModel);
      })();
    }
  }, []);
  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getVendorJobBookMarkList(
          vendorJobBookMarkDispatcher,
          {
            vendorId: loggedUserId,
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as VendorGetAllJobRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [bookmarkSaveResult, loggedUserId]);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getDatePostedList(datePostedDispatcher, {
          page: 1,
          pageSize: 100,
          searchTerm: "",
          sortOrder: "",
        } as datePostedRequestModel);
      })();
    }
  }, []);

  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setIsProfileOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getVendorJobAppliedList(
          jobAppliedVendorDispatcher,
          {
            vendorId: loggedUserId,
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as VendorGetAllJobRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId, bookmarkSaveResult]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getMatchedJobVendorList(
          matchedJobVendorDispatcher,
          {
            VendorId: loggedUserId,
            PageIndex: 1,
            PageSize: 100,
            SearchTerm: "",
            SortOrder: "",
            ShowInactive: false,
          } as matchedJobVendorRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId, bookmarkSaveResult]);

  React.useEffect(() => {
    if (authorizationToken != null && !apis.includes(api)) {
      (async () => {
        getSearchListWithTokenAsVendor(
          searchDispatcher,
          {
            expereince: [],
            // data.experience!=null?[data.experience]: [],
            location: [""],
            title: [api],
            type: [],
            lastDays: [],
            vendorId: loggedUserId,
            pageIndex: 1,
            pageSize: 60,
            showInactive: false,
          } as VendorSearchDataWithTokenRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [api]);

  React.useEffect(() => {
    if (
      authorizationToken != null &&
      bookmarkSaveResult.IsSuccess != undefined
    ) {
      (async () => {
        getSearchListWithTokenAsVendor(
          searchDispatcher,
          {
            expereince: [],
            // data.experience!=null?[data.experience]: [],
            location: [""],
            title: [""],
            type: [],
            lastDays: [],
            vendorId: loggedUserId,
            pageIndex: 1,
            pageSize: 60,
            showInactive: false,
          } as VendorSearchDataWithTokenRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [bookmarkSaveResult]);

  const onSubmit = (data: any) => {
    //console.log(8899, data);
    if (authorizationToken != null && loggedUserId != 0) {
      getSearchListWithTokenAsVendor(
        searchDispatcher,
        {
          expereince: [],
          // data.experience!=null?[data.experience]: [],
          location: data.location !== "" ? [data.location] : [""],
          title: [data.jobTitle],
          type: data.jobTypeData != null ? [] : [],
          lastDays: [],
          vendorId: loggedUserId,
          pageIndex: 1,
          pageSize: 60,
          showInactive: false,
        } as VendorSearchDataWithTokenRequestModel,
        authorizationToken
        // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
      );
      history.push(`/job_search_vendor/search/0`);
    }
  };

  const onLogout = () => {
    updateAccessToken(
      {},
      `?id=${AuthService.currentUser?.id}&accessToken=${
        AuthService.accessToken
      }&login=${false}`
    )
      .then((res: any) => {
        //console.log("updated access token ", res.data);
      })
      .catch((err: any) => {
        //console.log("error in updating access token", err);
      });
    AuthService.logout();
    props.history.push("/");
  };

  const handleJobTypeChange = (event: any) => {
    if (event.target.checked) {
      setJobSearchVendorComponentState({
        ...JobSearchVendorComponentState,
        types: types.concat(event.target.value),
      });
    } else {
      setJobSearchVendorComponentState({
        ...JobSearchVendorComponentState,
        types: types.filter((data) => data != event.target.value),
      });
    }
  };

  const handleExperienceChange = (value: any) => {
    setJobSearchVendorComponentState({
      ...JobSearchVendorComponentState,
      experiences: value,
    });
  };

  const handleDatePosteChange = (value: any) => {
    setJobSearchVendorComponentState({
      ...JobSearchVendorComponentState,
      datePost: value,
    });
  };

  const handleShareButtonClick = (id: any, value: any) => {
    setJobSearchVendorComponentState({
      ...JobSearchVendorComponentState,
      currentJobId: id,
      filterFlag: value,
    });
    setIsShareOpen(!isShareOpen);
  };

  const handleSavedJobClick = (id: any) => {
    // setVendorDashboardComponentState({
    //   ...vendorDashboardComponentState,
    //   currentJobId: id,
    // });
    if (authorizationToken != null) {
      (async () => {
        await setVendorJobBookmark(
          vendorJobBookMarkDispatcher,
          {
            IsActive: true,
            jobBookmarkedId: 0,
            rowId: 0,
            vendorID: loggedUserId,
            jobId: id,
          } as VendorJobBookMarkRequestModel,
          authorizationToken
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImQ5ZjU0MjlmLTY3NTgtNGM0Ny04ODVhLTIwZjVkN2M4ODEyZiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTcyODEsImV4cCI6MTY0NDEzMzI4MSwiaWF0IjoxNjEyNTk3MjgxLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.sKevXWje7UM90S8rAZicVHpesgOz4IepSaY2JQkvcNM"
        );
      })();
    }
  };

  const handleJobAlertCreateClick = () => {
    setIsOpen(!isOpen);
    setcurrentAlertId(0);
  };

  React.useEffect(() => {
    setJobSearchVendorComponentState((JobSearchVendorComponentState) => {
      return {
        ...JobSearchVendorComponentState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);

  React.useEffect(() => {
    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  const headerNavigation = () => {
    if (user?.roles[0] === "Candidate") {
      return (
        <div
          className="action_btn1"
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <button type="button" className="jobs_action">
            {/* {basicInfo.fullName != undefined
            ? basicInfo.fullName
            : ""}{" "} */}
            {name}
            <i className="fa fa-angle-down" aria-hidden="true" />
          </button>
          <div className="login_jobs">
            {/* <img
            src={
              profileImage != null && profileImage.total > 0
                ? `https://careerapp.azurewebsites.net/Upload/ProfileImage/${profileImage.data[0].imageUrl}`
                : require("../../../images/profileDefault1.jpg")
            }
          /> */}
            {candidateProfileImage.data.length > 0 ? (
              <img
                className="img-responsive"
                src={`https://careerapp.azurewebsites.net/Upload/ProfileImage/${candidateProfileImage.data[0]["imageUrl"]}`}
                alt=""
              />
            ) : (
              <img
                className="img-responsive"
                src={require("./../../images/profileDefault1.jpg")}
              ></img>
            )}
          </div>

          <div
            className="jobs_action_dp"
            tabIndex={-1}
            style={{ display: `${isMenuOpen ? "block" : "none"}` }}
          >
            <div className="action_sec">
              <ul ref={menuRef}>
                <li>
                  <Link to="/candidate" className="active">
                    {" "}
                    <i className="fa fa-th-large" aria-hidden="true" />
                    Dashboard{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/jobs-applied" className="active">
                    {" "}
                    <i className="fa fa-briefcase" aria-hidden="true" />
                    Job Applied{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/digilocker/3" className="active">
                    {" "}
                    <i className="fa fa-lock" aria-hidden="true" />
                    {name.split(" ")[0]}
                    's DigiLocker{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/my-resume" className="active">
                    {" "}
                    <i className="fa fa-files-o" aria-hidden="true" />
                    My Resumes{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/messages" className="active">
                    {" "}
                    <i className="fa fa-commenting-o" aria-hidden="true" />
                    Messages{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/saved-jobs" className="active">
                    {" "}
                    <i className="fa fa-suitcase" aria-hidden="true" />
                    Saved Jobs{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/candidate/my-profile" className="active">
                    {" "}
                    <i className="fa fa-user-o" aria-hidden="true" />
                    My Profile{" "}
                  </Link>
                </li>
                {/* <li>
                <Link to="/candidate">DashBoard</Link>
              </li> */}
                <li>
                  <a onClick={onLogout} className="_cursor-pointer">
                    <i className="fa fa-power-off" aria-hidden="true"></i>
                    Logout
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else if (user?.roles[0] === "Vendor") {
      return (
        <div
          className="action_btn1"
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <button type="button" className="jobs_action">
            {basicInfo.fullName != undefined ? basicInfo.fullName : ""}{" "}
            <i className="fa fa-angle-down" aria-hidden="true" />
          </button>
          <div className="login_jobs">
            {/* <img
            src={
              profileImage != null && profileImage.total > 0
                ? `https://careerapp.azurewebsites.net/Upload/ProfileImage/${profileImage.data[0].imageUrl}`
                : require("../../../images/profileDefault1.jpg")
            }
          /> */}
            {vendorProfileImage.data.length > 0 ? (
              <img
                className="img-responsive"
                src={`https://careerapp.azurewebsites.net/Upload/VendorProfileImage/${vendorProfileImage.data[0]["imageUrl"]}`}
              />
            ) : (
              <img
                className="img-responsive"
                src={require("./../../images/profileDefault1.jpg")}
              ></img>
            )}
          </div>

          <div
            className="jobs_action_dp"
            tabIndex={-1}
            style={{ display: `${isMenuOpen ? "block" : "none"}` }}
          >
            <div className="action_sec">
              <ul ref={menuRef}>
                <li>
                  <Link to="/vendor" className="active">
                    {" "}
                    <i className="fa fa-th-large" aria-hidden="true" />
                    Dashboard{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/jobs-applied" className="active">
                    {" "}
                    <i className="fa fa-briefcase" aria-hidden="true" />
                    Job Applied{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/messages" className="active">
                    {" "}
                    <i className="fa fa-commenting-o" aria-hidden="true" />
                    Messages{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/saved-jobs" className="active">
                    {" "}
                    <i className="fa fa-suitcase" aria-hidden="true" />
                    Saved Jobs{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/my-profile" className="active">
                    {" "}
                    <i className="fa fa-user-o" aria-hidden="true" />
                    My Profile{" "}
                  </Link>
                </li>
                {/* <li>
                <Link to="/candidate">DashBoard</Link>
              </li> */}
                <li>
                  <a onClick={onLogout} className="_cursor-pointer">
                    <i className="fa fa-power-off" aria-hidden="true"></i>
                    Logout
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {/* Header Start */}

      <div className="job_search_header">
        <div className="container-fluid">
          <div className="col-sm-6">
            {authorizationToken ? (
              role === "Candidate" ? (
                <a
                  onClick={() => {
                    history.push("/candidate");
                  }}
                >
                  <i className="fa fa-2x fa-arrow-circle-left"></i>
                </a>
              ) : (
                <a
                  onClick={() => {
                    history.push("/vendor");
                  }}
                >
                  <i className="fa fa-2x fa-arrow-circle-left"></i>
                </a>
              )
            ) : (
              <a
                onClick={() => {
                  history.push("/");
                }}
              >
                <i className="fa fa-2x fa-arrow-circle-left"></i>
              </a>
            )}
            {/* <a
              onClick={() => {
                history.push("/");
              }}
            >
              <img
                src={require("../../images/logo_login.png")}
                // src={require("../../images/logo_login.png"
              />
            </a> */}
          </div>
          <div className="col-sm-6">
            {authorizationToken ? (
              <>
                {headerNavigation()}
                {/* <div
                  className="action_btn1"
                  onClick={() => {
                    setIsProfileOpen(!isProfileOpen);
                  }}
                >
                  <button type="button" className="jobs_action">
                    {basicInfo.fullName != undefined ? basicInfo.fullName : ""}{" "}
                    <i className="fa fa-angle-down" aria-hidden="true" />
                  </button>
                  <div className="login_jobs">
              
                    {candidateProfileImage.data.length > 0 ? (
                      <img
                        className="img-responsive"
                        src={`https://careerapp.azurewebsites.net/Upload/ProfileImage/${candidateProfileImage.data[0]["imageUrl"]}`}
                        alt=""
                      />
                    ) : (
                      <img
                        className="img-responsive"
                        src={require("../../images/profileDefault1.jpg")}
                      ></img>
                    )}
                  </div>

                  <div
                    className="jobs_action_dp"
                    tabIndex={-1}
                    style={{ display: `${isProfileOpen ? "block" : "none"}` }}
                  >
                    <div className="action_sec">
                      <ul ref={menuRef}>
                        <li>
                          <Link to="/vendor" className="active">
                            {" "}
                            <i className="fa fa-th-large" aria-hidden="true" />
                            Dashboard{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/vendor/jobs-applied" className="active">
                            {" "}
                            <i className="fa fa-briefcase" aria-hidden="true" />
                            Job Applied{" "}
                          </Link>
                        </li>
                       
                        
                        <li>
                          <Link to="/vendor/messages" className="active">
                            {" "}
                            <i
                              className="fa fa-commenting-o"
                              aria-hidden="true"
                            />
                            Messages{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/vendor/saved-jobs" className="active">
                            {" "}
                            <i className="fa fa-suitcase" aria-hidden="true" />
                            Saved Jobs{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/vendor/my-profile" className="active">
                            {" "}
                            <i className="fa fa-user-o" aria-hidden="true" />
                            My Profile{" "}
                          </Link>
                        </li>
          
                        <li>
                          <a onClick={onLogout} className="_cursor-pointer">
                            <i
                              className="fa fa-power-off"
                              aria-hidden="true"
                            ></i>
                            Logout
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </>
            ) : (
              <>
                <div className="top_links">
                  <>
                    <Link to="/login">Login</Link>
                    <Link to="/registration">Register</Link>
                  </>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Header Ends */}

      {/* Content start  */}

      <div className="job_search_page">
        <div className=" container-fluid">
          <div>
            <div className="col-sm-12">
              <div className="job_search_area">
                <div className="row">
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        <input
                          name="jobTitle"
                          ref={register({ required: "Required" })}
                          type="text"
                          className="form-control"
                          placeholder="Job Title"
                        />

                        <div className="search_icons">
                          <img
                            src={require("../../images/search_icon.png")}
                            // src={require("../../images/search_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        <input
                          name="location"
                          ref={register({ required: false })}
                          type="text"
                          className="form-control"
                          placeholder="location"
                        />
                        <div className="search_icons">
                          <img
                            src={require("../../images/locattion_icon.png")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        <span className="select-search">
                          <Controller
                            control={control}
                            name="jobTypeData"
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  myProfile.jobTypes != undefined
                                    ? myProfile.jobTypes.map((e) => {
                                        return {
                                          value: e["value"],
                                          label: e["caption"],
                                        };
                                      })
                                    : []
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                              />
                            )}
                          />
                        </span>
                        <div className="search_icons">
                          <img
                            src={require("../../images/type_icon.png")}
                            //  src={require("../../images/type_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        <input
                          name="experience"
                          ref={register({ required: false })}
                          type="text"
                          className="form-control"
                          placeholder="experience"
                        />
                        <div className="search_icons">
                          <img src={require("../../images/exp_icon.png")} />
                        </div>
                      </div>
                    </div>
                    <div className="search_button">
                      <div className="search">
                        <button className="CustomButtonCss" type="submit">
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="job_search_left">
                <div
                  className="create_job_alert"
                  style={{ display: authorizationToken != null ? "" : "none" }}
                >
                  <div className="create_job_alert_head">Create Job Alert</div>
                  <div className="create_job_alert_con{">
                    Create job alerts and get notifications for jobs.
                  </div>
                  <div className="create_job_alert_btn">
                    <button
                      className="create_job_alert_button"
                      onClick={
                        //   ()=>{
                        //    setIsOpen(!isOpen);
                        // }
                        handleJobAlertCreateClick
                      }
                    >
                      Job Alert
                    </button>
                  </div>
                </div>

                <div className="accordian_main">
                  <div className="accordian_tab">
                    <h1
                      className={`${isJobTypeOpen ? "active" : ""}`}
                      onClick={() => {
                        setIsJobTypeOpen(!isJobTypeOpen);
                      }}
                    >
                      Job Type <div className=" arrow" />
                    </h1>

                    <div
                      className="acc_con"
                      style={{ display: `${isJobTypeOpen ? "block" : "none"}` }}
                    >
                      {!jobType || !jobType.data || jobType.data.length <= 0 ? (
                        <h1>loading..</h1>
                      ) : (
                        jobType.data.map((jobTypeData, index) => {
                          return (
                            <div key={index}>
                              <input
                                onChange={(e: any) => handleJobTypeChange(e)}
                                value={`${jobTypeData.rowId.toString()}`}
                                id="checkbox"
                                type="checkbox"
                                name="checkbox"
                              />
                              <label htmlFor="checkbox2">
                                <span />
                                {jobTypeData.title}{" "}
                              </label>
                              <div className="jobs_notification">20</div>
                            </div>
                          );
                        })
                      )}
                    </div>

                    <h1
                      className={`${isExperienceOpen ? "active" : ""}`}
                      onClick={() => {
                        setExperienceOpen(!isExperienceOpen);
                      }}
                    >
                      Experience <div className=" arrow" />
                    </h1>
                    <div
                      className="acc_con"
                      style={{
                        display: `${isExperienceOpen ? "block" : "none"}`,
                      }}
                    >
                      {!experience ||
                      !experience.data ||
                      experience.data.length <= 0 ? (
                        <h1>loading..</h1>
                      ) : (
                        experience.data.map((exp, index) => {
                          return (
                            <div key={index}>
                              <input
                                onClick={() =>
                                  handleExperienceChange(exp.title)
                                }
                                id="radio1"
                                type="radio"
                                name="radio"
                                defaultValue={1}
                                // defaultChecked
                              />
                              <label htmlFor="radio1">
                                <span>
                                  <span />
                                </span>{" "}
                                {exp.title}{" "}
                              </label>
                              <div className="jobs_notification">20</div>
                            </div>
                          );
                        })
                      )}
                    </div>
                    <h1
                      className={`${isDatePostedOpen ? "active" : ""}`}
                      onClick={() => {
                        setIsDatePostedOpen(!isDatePostedOpen);
                      }}
                    >
                      Date Posted <div className=" arrow" />
                    </h1>
                    <div
                      className="acc_con"
                      style={{
                        display: `${isDatePostedOpen ? "block" : "none"}`,
                      }}
                    >
                      {!datePosted ||
                      !datePosted.data ||
                      datePosted.data.length <= 0 ? (
                        <h1>loading..</h1>
                      ) : (
                        datePosted.data.map((date, index) => {
                          return (
                            <div key={index}>
                              <input
                                onClick={() => handleDatePosteChange(date.day)}
                                id="radio2"
                                type="radio"
                                name="radio"
                                defaultValue={1}
                                defaultChecked
                              />
                              <label htmlFor="radio2">
                                <span>
                                  <span />
                                </span>{" "}
                                Last {date.day} days{" "}
                              </label>
                              <div className="jobs_notification">20</div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="job_search_right">
                <div
                  id="verticalTab"
                  className="jobsearch-tabs  resp-vtabs"
                  style={{ display: "block", width: "100%", margin: "0px" }}
                >
                  <ul className="resp-tabs-list ">
                    {/* <div id="jobs_searh1" style={{overflow: 'hidden', outline: 'none'}} tabIndex={0}> */}
                    <Scrollbars
                      style={{ height: 700 }}
                      autoHide
                      renderThumbVertical={({ style, ...props }) => (
                        <div
                          {...props}
                          style={{
                            ...style,
                            position: "relative",
                            display: "block",
                            width: "5px",
                            cursor: "pointer",
                            borderRadius: "inherit",
                            backgroundColor: "rgb(73, 69, 69)",
                            height: "115px",
                          }}
                        />
                      )}
                    >
                      {currentJobList.length > 0 ? (
                        currentJobList.map((data, index) => {
                          return (
                            <li
                              onClick={() => {
                                setCurrentJobId(data.jobId);
                                history.push(`${data.jobId}`);
                              }}
                              className={`resp-tab-item ${
                                currentJobId == data.jobId
                                  ? "resp-tab-active"
                                  : ""
                              } `}
                              aria-controls="tab_item-0"
                              role="tab"
                            >
                              <div className="job_search_icon">
                                <img
                                  src={require("../../images/designer_icon.png")}
                                />
                              </div>
                              <div className="job_search_con">
                                <div className="job_search_con_head">
                                  {data.title}
                                </div>
                                <div className="job_search_con_con">
                                  <i
                                    className="fa fa-map-marker"
                                    aria-hidden="true"
                                  />{" "}
                                  {data.location}
                                </div>
                              </div>
                              <div className="job_search_social">
                                <div>
                                  <i
                                    className="fa fa-share-alt"
                                    onClick={() =>
                                      handleShareButtonClick(data.jobId, true)
                                    }
                                    aria-hidden="true"
                                  />
                                </div>
                                <div>
                                  {data.isBookmarked ? (
                                    <i
                                      className="fa fa-star"
                                      onClick={() =>
                                        handleSavedJobClick(data.jobId)
                                      }
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <i
                                      className="fa fa-star-o"
                                      onClick={() =>
                                        handleSavedJobClick(data.jobId)
                                      }
                                      aria-hidden="true"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="clearfix" />
                              <div className="job_fulltime">
                                {data.postedDate}
                              </div>
                              <div className="job_search_exp">
                                {data.experience}
                              </div>
                              <div className="job_search_post">New 1 d </div>
                            </li>
                          );
                        })
                      ) : (
                        <h6>No Search Result Found</h6>
                      )}
                    </Scrollbars>

                    {/* </div> */}

                    {/* <div id="jobs_searh1" style={{overflow: 'hidden', outline: 'none'}} tabIndex={0}> */}

                    {/* <li className="resp-tab-item resp-tab-active" aria-controls="tab_item-0" role="tab">
  <div className="job_search_icon"><img src={require("../../images/designer_icon.png"  /></div>
  <div className="job_search_con">
    <div className="job_search_con_head">Designer</div>
    <div className="job_search_con_con"><i className="fa fa-map-marker" aria-hidden="true" /> Abudhabi</div>
  </div>
  <div className="job_search_social">
    <div><i className="fa fa-share-alt" aria-hidden="true" /></div>
    <div><i className="fa fa-star-o" aria-hidden="true" /></div>
  </div>
  <div className="clearfix" />
  <div className="job_fulltime">Full Time</div>
  <div className="job_search_exp">0-2 Years</div>
  <div className="job_search_post">New  1 d </div> 
</li> */}

                    {/* </div> */}
                  </ul>
                  <div className="resp-tabs-container jobsearch-tabs-content">
                    {/*----------------Start-------------*/}
                    <JobSearchDetailedComponent
                      jobList={currentJobList.filter((data, index) => {
                        return data.jobId == currentJobId;
                      })}
                      onHandleSavedJobClick={handleSavedJobClick}
                      onHandleShareButtonClick={handleShareButtonClick}
                    />
                    {/*----------------End-------------*/}
                  </div>
                </div>
                <div className="clearfix" />
              </div>

              {/*---------------------------------------------- */}
            </div>
          </div>
        </div>
        {/* scoial media share modal statrt */}
        <Modal
          show={isShareOpen}
          onHide={() => {
            setIsShareOpen(!isShareOpen);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-12 m_t_30 text-center">
              <div className="social1">
                <FacebookShareButton
                  url={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                  quote={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                  hashtag={"efg"}
                  title={"hij"}
                  // quote={`${
                  //   filterFlag
                  //     ? matchedJobVendor != undefined &&
                  //       matchedJobVendor.data != undefined
                  //       ? matchedJobVendor.data.filter(
                  //           (data) => data.rowId === currentJobId
                  //         )[0] != undefined
                  //         ? matchedJobVendor.data.filter(
                  //             (data) => data.rowId === currentJobId
                  //           )[0].description
                  //         : ""
                  //       : ""
                  //     : matchedJobVendor.data != undefined &&
                  //       matchedJobVendor.data != undefined
                  //     ? matchedJobVendor.data.filter(
                  //         (data) => data.rowId === currentJobId
                  //       )[0] != undefined
                  //       ? matchedJobVendor.data.filter(
                  //           (data) => data.rowId === currentJobId
                  //         )[0].description
                  //       : ""
                  //     : ""
                  // }`}
                  // hashtag={`${
                  //   filterFlag
                  //     ? matchedJobVendor != undefined &&
                  //       matchedJobVendor.data != undefined
                  //       ? matchedJobVendor.data.filter(
                  //           (data) => data.rowId === currentJobId
                  //         )[0] != undefined
                  //         ? matchedJobVendor.data.filter(
                  //             (data) => data.rowId === currentJobId
                  //           )[0].description
                  //         : ""
                  //       : ""
                  //     : matchedJobVendor.data != undefined &&
                  //       matchedJobVendor.data != undefined
                  //     ? matchedJobVendor.data.filter(
                  //         (data) => data.rowId === currentJobId
                  //       )[0] != undefined
                  //       ? matchedJobVendor.data.filter(
                  //           (data) => data.rowId === currentJobId
                  //         )[0].description
                  //       : ""
                  //     : ""
                  // }`}
                  className={""}
                >
                  {/* <FacebookIcon size={36} /> */}
                  <a href="#" className="social_face">
                    <i className="fa fa-facebook" aria-hidden="true" />
                  </a>
                </FacebookShareButton>

                <TwitterShareButton
                  url={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                  className={""}
                >
                  {/* <FacebookIcon size={36} /> */}
                  <a href="#" className="social_twit">
                    <i className="fa fa-twitter" aria-hidden="true" />
                  </a>
                </TwitterShareButton>

                <LinkedinShareButton
                  url={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                  source={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                >
                  <a href="#" className="social_twit">
                    <i className="fa fa-linkedin" aria-hidden="true" />
                  </a>
                </LinkedinShareButton>
              </div>
            </div>
            <div className="modal-footer  m-t-30"></div>
          </Modal.Body>
        </Modal>
        {/* social media modal end */}
      </div>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <form onSubmit={handleSubmit2(handleJobAlert)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>New Job Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="email" className="required">
                Job Category
              </label>
              <span className="select-wrapper-sec">
                <Controller
                  control={control2}
                  name="jobCategorys"
                  rules={{
                    required: "Job Category is required",
                  }}
                  // value=''
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption
                      values={
                        myProfile.jobCategories != undefined
                          ? myProfile.jobCategories.map((e) => {
                              return {
                                value: e["value"],
                                label: e["caption"],
                              };
                            })
                          : jobCategoryData
                      }
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  )}
                />
              </span>
              <ErrorMessage
                errors={errors2}
                name="jobCategorys"
                render={({ message }) => (
                  <div className="register_validation">{message}</div>
                )}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email" className="required">
                Job Type
              </label>
              <span className="select-wrapper-sec">
                <Controller
                  className="custom-select-sec"
                  control={control2}
                  name="jobTypeData"
                  rules={{
                    required: "Job Type is required",
                  }}
                  // rules={{
                  //   validate: (value) => {
                  //     return value === "" ? "Job Type is required" : "";
                  //   },
                  // }}
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption
                      values={
                        myProfile.jobTypes != undefined
                          ? myProfile.jobTypes.map((e) => {
                              return {
                                value: e["value"],
                                label: e["caption"],
                              };
                            })
                          : jobTypeDatas
                      }
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  )}
                />
              </span>
              <ErrorMessage
                errors={errors2}
                name="jobTitle"
                render={({ message }) => (
                  <div className="register_validation">{message}</div>
                )}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email" className="required">
                Job Title
              </label>
              <span className="select-wrapper-sec">
                <Controller
                  control={control2}
                  name="jobTitle"
                  rules={{
                    required: "Job Title is required",
                  }}
                  // value=''
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption
                      values={
                        tempData != undefined
                          ? tempData.map((e) => {
                              return {
                                value: e["value"],
                                label: e["caption"],
                              };
                            })
                          : tempData
                      }
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  )}
                />
              </span>
            </div>
            <ErrorMessage
              errors={errors2}
              name="jobTitle"
              render={({ message }) => (
                <div className="register_validation">{message}</div>
              )}
            />
            <div className="form-group">
              <label htmlFor="email">Location</label>
              <input
                defaultValue={location}
                type="text"
                className="form-control "
                name="location"
                placeholder=" "
                ref={register2({
                  required: false,
                })}
              />
            </div>
            <div className="form-group">
              <input
                id="radio1"
                type="radio"
                ref={register2({
                  required: false,
                })}
                name="radio"
                defaultValue={1}
                defaultChecked
              />
              <label htmlFor="radio1" style={{ marginRight: 20 }}>
                <span>
                  <span />
                </span>{" "}
                Active
              </label>
              <input
                id="radio2"
                type="radio"
                ref={register2({
                  required: false,
                })}
                name="radio"
                defaultValue={2}
                // defaultChecked
              />
              <label
                htmlFor="radio2"
                // style={{ marginLeft: 1 }}
              >
                <span>
                  <span />
                </span>{" "}
                Inactive
              </label>
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
              Create Alert
            </button>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="clearfix" />
        </form>
      </Modal>

      {/* Content ends  */}
    </>
  );
};

export default withRouter(JobSearchVendorComponent);
