import { apiClient } from "./../utils/httpClient";

export function getCandidateId(data?: any) {
  return apiClient(`/api/Candidate/gc/${data}`, {}, "get");
}

export function getCandidates(data?: any) {
  return apiClient("/api/Candidate/gaaa", data);
}

export function getCandidate(data?: any) {
  return apiClient("/api/Candidate/ga", data);
}

export function saveCandidate(data?: any) {
  return apiClient("/api/Candidate/coea", data);
}

export function getSelectBoxData(data?: any) {
  return apiClient("/api/Candidate/get-selectbox-data", data);
}

export function getCandidateExperienceSelectBoxData(data?: any) {
  return apiClient("/api/CandidateExperience/get-selectbox-data", data);
}

export function getCandidateExperiences(data?: any) {
  return apiClient("/api/CandidateExperience/gaaa", data);
}

export function saveCandidateExperiences(data?: any) {
  return apiClient("/api/CandidateExperience/coea", data);
}

export function deleteCandidateExperiences(data?: any) {
  return apiClient("/api/CandidateExperience/da", data);
}

export function getCandidateSkills(data?: any) {
  return apiClient("/api/CandidateSkills/gaaa", data);
}

export function saveCandidateSkill(data?: any) {
  return apiClient("/api/CandidateSkills/coea", data);
}

export function deleteCandidateSkill(data?: any) {
  return apiClient("/api/CandidateSkills/da", data);
}

export function getCandidateSkillSelectBoxData(data?: any) {
  return apiClient("/api/CandidateSkills/get-selectbox-data", data);
}

export function getCandidateRelatives(data?: any) {
  return apiClient("/api/CandidateRelatives/gaaa", data);
}

export function saveCandidateRelatives(data?: any) {
  return apiClient("/api/CandidateRelatives/coea", data);
}

export function getCandidateLanguageMaps(data?: any) {
  return apiClient("/api/CandidateLanguageMap/gaaa", data);
}

export function saveCandidateLanguageMaps(data?: any) {
  return apiClient("/api/CandidateLanguageMap/coea", data);
}

export function getTrainingSelectBoxData(data?: any) {
  return apiClient("/api/Training/get-selectbox-data", data);
}

export function getTrainings(data?: any) {
  return apiClient("/api/Training/gaaa", data);
}

export function saveTraining(data?: any) {
  return apiClient("/api/Training/coea", data);
}

export function deleteTrainings(data?: any) {
  return apiClient("/api/Training/da", data);
}

export function getBankDetails(data?: any) {
  return apiClient("/api/BankDetails/gaaa", data);
}

export function deleteBankDetails(data?: any) {
  return apiClient("/api/BankDetails/da", data);
}

export function getBankDetailsSelectBoxData(data?: any) {
  return apiClient("/api/BankDetails/get-selectbox-data", data);
}

export function saveBankDetails(data?: any) {
  return apiClient("/api/BankDetails/coea", data);
}

export function getEducationQualifications(data?: any) {
  return apiClient("/api/EducationQualification/gaaa", data);
}

export function saveEducationQualification(data?: any) {
  return apiClient("/api/EducationQualification/coea", data);
}

export function deleteEducationQualification(data?: any) {
  return apiClient("/api/EducationQualification/da", data);
}

export function getEducationQualificationSelectBoxData(data?: any) {
  return apiClient("/api/EducationQualification/get-selectbox-data", data);
}

export function getCandidateAchievements(data?: any) {
  return apiClient("/api/CandidateAchievements/gaaa", data);
}

export function getCandidateReferences(data?: any) {
  return apiClient("/api/CandidateReferences/gaaa", data);
}

export function saveCandidateReferences(data?: any) {
  return apiClient("/api/CandidateReferences/coea", data);
}

export function getProfileProgress(data?: any) {
  return apiClient("/api/JobApplied/gpa", data);
}

export function getSocialAccounts(data?: any) {
  return apiClient("/api/SocialAccounts/gaaa", data);
}

export function deleteSocialAccounts(data?: any) {
  return apiClient("/api/SocialAccounts/da", data);
}

export function updateSocialAccounts(data?: any) {
  return apiClient("/api/SocialAccounts/coea", data);
}

export function saveSocialAccounts(data?: any) {
  return apiClient("/api/SocialAccounts/coea", data);
}

export function getPassportInformation(data?: any) {
  return apiClient("/api/PassportInformation/gaaa", data);
}

export function getOnePassportInformation(data?: any) {
  return apiClient("/api/PassportInformation/ga", data);
}

export function savePassportInformation(data?: any) {
  return apiClient("/api/PassportInformation/coea", data);
}

export function deletePassportInformation(data?: any) {
  return apiClient("/api/PassportInformation/da", data);
}

export function getCandidateProfileImage(data?: any) {
  return apiClient("/api/CandidateProfileImage/gaaa", data);
}

export function saveCandidateProfileImage(data?: any) {
  return apiClient("/api/CandidateProfileImage/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getCandidateOtherCertificates(data?: any) {
  return apiClient("/api/CandidateOtherCertificate/gaaa", data);
}

export function saveCandidateOtherCertificate(data?: any) {
  return apiClient("/api/CandidateOtherCertificate/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getDigiDocumentType(data?: any) {
  return apiClient("/api/DigiDocumentType/gaaa", data);
}

export function getSeamanBookCdc(data?: any) {
  return apiClient("/api/SeamanBookCdc/gaaa", data);
}

export function getOneSeamanBookCdc(data?: any) {
  return apiClient("/api/SeamanBookCdc/ga", data);
}

export function saveSeamanBookCdc(data?: any) {
  return apiClient("/api/SeamanBookCdc/coea", data);
}

export function deleteSeamanBookCdc(data?: any) {
  return apiClient("/api/SeamanBookCdc/da", data);
}

export function getDigiDocumentDetails(data?: any) {
  return apiClient("/api/DigiDocumentDetails/gaa", data);
}

export function getOneDigiDocumentDetails(data?: any) {
  return apiClient("/api/DigiDocumentDetails/ga", data);
}

export function saveDigiDocumentDetails(data?: any) {
  return apiClient("/api/DigiDocumentDetails/coea", data);
}

export function saveDigiDocumentUpload(data?: any) {
  return apiClient("/api/DigiDocumentUpload/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getCandidateRelativesSelectBoxData(data?: any) {
  return apiClient("/api/CandidateRelatives/get-selectbox-data", data);
}

export function getNotificationList(data?: any) {
  return apiClient("/api/candidate/getNotificatinsAsync", data);
}

export function deletCandidateProfileImage(data?: any) {
  return apiClient("/api/CandidateProfileImage/da", data);
}

export function uploadOrDeleteCandidateProfileVideo(data?: any) {
  return apiClient("/api/Candidate/uploadorDeleteVideo", data);
}
