import React from "react";
import { CandidateContextProvider } from "../../../context/candidate/CandidateMyProfile";
import { SuperAdminContextProvider } from "../../../context/superadmin/SuperAdminMyProfile";
import { MyProfileSuperAdminContextProvider } from "../../../context/MyProfileSuperAdminContext";
import { VendorContextProvider } from "../../../context/vendor/VendorMyProfile";
import { MyProfileVendorContextProvider } from "../../../context/MyProfileVendorContext";
import IndexHeaderComponent from "./IndexHeaderComponent";
import { AdminContextProvider } from "../../../context/admin/AdminMyProfile";
import { MyProfileAdminContextProvider } from "../../../context/MyProfileAdminContext";
import { SocialAccountUrlContextProvider } from "../../../context/admin/SocialAccountUrlContext";

interface IIndexHeaderContainerProps { }

interface IIndexHeaderContainerState { }

const initialState = {};

const IndexHeaderContainer: React.FC<IIndexHeaderContainerProps> = (props) => {
  const [
    IndexHeaderContainerState,
    setIndexHeaderContainerState,
  ] = React.useState<IIndexHeaderContainerState>(initialState);

  return (
    <>
      <SocialAccountUrlContextProvider>
        <AdminContextProvider>
          <MyProfileAdminContextProvider>
            <SuperAdminContextProvider>
              <MyProfileSuperAdminContextProvider>
                {/* <VendorContextProvider> */}
                {/* <MyProfileVendorContextProvider>         */}
                <CandidateContextProvider>
                  <IndexHeaderComponent />
                </CandidateContextProvider>
                {/* </MyProfileVendorContextProvider>         */}
                {/* </VendorContextProvider> */}
              </MyProfileSuperAdminContextProvider>
            </SuperAdminContextProvider>
          </MyProfileAdminContextProvider>
        </AdminContextProvider>
      </SocialAccountUrlContextProvider>
    </>
  );
};
export default IndexHeaderContainer;
