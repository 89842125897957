import { ErrorMessage } from "@hookform/error-message";
import { Checkbox, Popover, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import {
  AdminJobSave,
  DeleteAdminMyJob,
  GetAdminMyJob,
  GetAdminPermission,
  GetAllAdminMyJobList,
  GetAllAdminMyJobListPub,
  SavePublish,
  SetAllAssignTo,
  useAdminMyJobContext,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import {
  getTitleWithCategoryId,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import { useExperienceContext } from "../../../action/general/ExperienceAction";
import { useAdminMyProfileContext } from "../../../action/MyProfileAdminAction";
import {
  getVendorMyProfileSelectBoxList,
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../../action/MyProfileVendorAction";
import { saveAdminActions } from "../../../apis/admin";
import {
  AdminJobList,
  AdminJobListRequestModel,
  AdminPermissionRequestModel,
  AssignToRequestModel,
  JobIdRequestModel,
  PublishedJobRequestModel,
  PublishedJobRespond,
  RowIdRequestModel,
} from "../../../models/admin/AdminMyJob";
import { jobAlertTitleDropdownResultModel } from "../../../models/candidate/JobAlert";
import { categoryWithId } from "../../../models/candidate/JobCategory";
import AuthService from "../../../services/AuthService";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import SelectOption from "../../candidate/my_profile/components/SelectOption";
import AdminDraftedJobComponent from "./AdminDraftedJobComponent";
import AdminPublishedJobComponent from "./AdminPublishedJobComponent";
import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";

const { Option } = Select;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "green" }} spin />
);

interface ReactSelectOption {
  value: string;
  label: string;
}

type objectIndexing = {
  [key: string]: any;
};

interface IAdminMyJobComponentProps {}
interface IAdminMyJobComponentState {
  tempData: jobAlertTitleDropdownResultModel[];
}

const defaultValues = {
  jobTypeData: "",
  countryCode: "",
  mobileNumber: "",
  firstName: "",
  jobCategorys: "",
  tempData: [],
  jobTitle: "",
  jobStatus: "",
  assignTo: "",
};

const AdminMyJobComponent = (props: any) => {
  const authorizationToken = AuthService.accessToken;
  let jobCategoryData: ReactSelectOption[] = [];
  let jobTypeDatas: ReactSelectOption[] = [];

  const [
    adminMyJobComponentState,
    setAdminMyJobComponentState,
  ] = React.useState<IAdminMyJobComponentState>(defaultValues);

  const { tempData } = adminMyJobComponentState;

  const jobStatusSelect = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" },
  ];

  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filename, setFileName] = useState("");

  const [obj, setObj] = useState<any>({ file: null });
  const [filename1, setFileName1] = useState("");
  const [obj1, setObj1] = useState<any>({ file: null });
  const [mirrorData, setMirrorData] = useState<number>(0);
  const [jobStatusData, setjobStatusData] = useState<number>(1);
  const [currentJobId, setCurrentJobId] = useState<number>(0);
  const [isPublish, setPublish] = useState<boolean>(false);
  const [isLoder, setIsLoder] = useState<boolean>(false);
  const [isLoderDelete, setIsLoderDelete] = useState<boolean>(false);
  const [isLoderPublish, setIsLoderPublish] = useState<boolean>(false);
  const [asignToAdminId, setAsignToAdminId] = useState<number>(0);
  const [minSalaryState, setMinSalaryState] = useState(false);
  const [checkedList, setCheckedList] = React.useState<number[]>([]);

  const [checkAll, setCheckAll] = React.useState(false);

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const {
    adminSaveJobRespond,
    jobListInAdmin,
    jobListInAdminPub,
    permissionResult,
    adminEditRespond,
    adminDeleteRespond,
    AdminPermissionResult,
    publishedJobRespond,
    AssignToRespondModelData,
  } = adminMyJobContext;

  const cl = props.location.pathname;
  const myProfileVendorDispatcher = useVendorMyProfileDispatcher();

  const jobAlertDispatcher = useJobAlertDispatcher();
  const myVendorProfileDispatcher = useVendorMyProfileDispatcher();
  const myVendorProfileContext = useVendorMyProfileContext();
  const {
    basicInfo,
    // loggedUserId,
    myProfile,
    myProfileProgressBar,
    profileImage,
  } = myVendorProfileContext;

  const myProfileAdminContext = useAdminMyProfileContext();
  const {
    //myProfile,

    loggedAdminId,
  } = myProfileAdminContext;

  const jobAlertContext = useJobAlertContext();
  const {
    jobAlert,
    jobAlertGetAll,
    jobAlertSaveRespond,
    jobAlertTitleDropdownResult,
  } = jobAlertContext;

  const experienceContext = useExperienceContext();
  const { experience } = experienceContext;

  //console.log(898111, loggedAdminId);

  let experienceData: ReactSelectOption[] = [];
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const watchAllFields = watch2();
  //console.log(34, AdminPermissionResult);

  if (experience.data != undefined) {
    experienceData = experience.data.map((e: any) => {
      return {
        value: e["rowId"],
        label:
          e["title"] !== "0"
            ? e["title"] + " " + "years"
            : e["title"] + " " + "year",
      };
    });
  }

  // React.useEffect(() => {
  //   if (authorizationToken != null) {
  //     (async () => {
  //       await GetAdminPermission(
  //         adminMyJobDispatcher,
  //         {
  //           AdminId: loggedAdminId,
  //         } as AdminPermissionRequestModel,
  //         authorizationToken
  //       );
  //     })();
  //   }
  // }, [loggedAdminId]);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getVendorMyProfileSelectBoxList(myProfileVendorDispatcher);
      })();
    }
  }, []);

  React.useEffect(() => {
    setAdminMyJobComponentState((adminMyJobComponentState) => {
      return {
        ...adminMyJobComponentState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);

  React.useEffect(() => {
    if (adminSaveJobRespond.isSuccess || adminDeleteRespond.isSuccess) {
      setIsLoder(false);
      setIsOpen(false);
      setFileName("");
      setFileName1("");
      setIsLoderDelete(false);
      setIsLoderPublish(false);
    }
  }, [adminSaveJobRespond, adminDeleteRespond]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId !== 0) {
      if (cl === "/admin/myJob/drafted") {
        (async () => {
          await GetAllAdminMyJobList(
            adminMyJobDispatcher,
            {
              AdminId: loggedAdminId,
              Draftedjobsflag: true,
              JobStatus: jobStatusData,
              Page: 1,
              PageSize: 16,
              Publishedjobsflag: false,
              SearchTerm: "",
              SortOrder: "",
            } as AdminJobListRequestModel,
            authorizationToken
          );
        })();
      } else if (cl === "/admin/myJob/published") {
        (async () => {
          await GetAllAdminMyJobListPub(
            adminMyJobDispatcher,
            {
              AdminId: loggedAdminId,
              Draftedjobsflag: false,
              JobStatus: jobStatusData,
              Page: 1,
              PageSize: 16,
              Publishedjobsflag: true,
              SearchTerm: "",
              SortOrder: "",
            } as AdminJobListRequestModel,
            authorizationToken
          );
        })();
      }
    }
  }, [
    cl,
    adminSaveJobRespond,
    adminDeleteRespond,
    jobStatusData,
    publishedJobRespond,
    loggedAdminId,
    AssignToRespondModelData,
  ]);

  useEffect(() => {
    if (adminEditRespond.data != undefined) {
      // setValue2("AdminId", );
      // setValue2("Rowid", );
      setValue2("jobCategorys", adminEditRespond.data[0].categoryId.toString());
      setValue2("jobTitle", adminEditRespond.data[0].title);
      setValue2("jobDescription", adminEditRespond.data[0].description);
      setValue2("experience", adminEditRespond.data[0].experienceId.toString());
      setValue2("vacancies", adminEditRespond.data[0].numberOfVacancies);
      setValue2("jobTypeData", adminEditRespond.data[0].jobTypeId.toString());
      setValue2("location", adminEditRespond.data[0].location);
      setValue2("minAnnualSalary", adminEditRespond.data[0].minAnnualSalary);
      setValue2("maxAnnualSalary", adminEditRespond.data[0].maxAnnualSalary);
      // setValue2("MinAnnualSalary", adminEditRespond.data[0].);
      //  setValue2("WillingnessToTravelFlag",adminEditRespond.data[0]. );
      // setValue2("AutoScreeningFilterFlag", adminEditRespond.data[0].);
      // setValue2("AutoSkillAssessmentFlag", adminEditRespond.data[0].);
      setValue2("jobOverview", adminEditRespond.data[0].jobOverview);
      setValue2(
        "minimumQualification",
        adminEditRespond.data[0].minQualification
      );
      setValue2("skillsRequired", adminEditRespond.data[0].skillRequired);
      setValue2(
        "documentsRequired",
        adminEditRespond.data[0].documentsRequired
      );
      // setValue2("AutoSkillAssessmentFlag",adminEditRespond.data[0]. );
      setValue2("others", adminEditRespond.data[0].others);
      setValue2(
        "assignTo",
        adminEditRespond.data[0].assignedToAdminId.toString()
      );
      setValue2("jobStatus", adminEditRespond.data[0].jobStatus ? "1" : "2");

      setPublish(adminEditRespond.data[0].ispublishedJobs);
      setCurrentJobId(mirrorData == 1 ? 0 : adminEditRespond.data[0].jobId);
      setFileName(adminEditRespond.data[0].jobIcon);
      setFileName1(adminEditRespond.data[0].jobBackground);
    }
  }, [adminEditRespond.data]);

  React.useEffect(() => {
    //console.log(1213, watchAllFields.jobCategorys);
    //console.log(1212, authorizationToken);

    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  const handleAdminClick = (data: any) => {
    //console.log(3329, data);
    const formData = new FormData();

    formData.append("AdminId", JSON.stringify(loggedAdminId));
    formData.append("Rowid", JSON.stringify(currentJobId));
    formData.append("CategoryId", JSON.stringify(parseInt(data.jobCategorys)));
    formData.append("Title", String(data.jobTitle));
    formData.append("Description", String(data.jobDescription));
    formData.append(
      "ExperienceId",
      JSON.stringify(parseInt(data.experience == null ? 0 : data.experience))
    );
    formData.append(
      "NumberOfVacancies",
      JSON.stringify(parseInt(data.vacancies == "" ? 0 : data.vacancies))
    );
    formData.append("JobTypeId", JSON.stringify(parseInt(data.jobTypeData)));
    formData.append("LocationId", String(data.location));
    formData.append(
      "MinAnnualSalary",
      JSON.stringify(
        parseInt(data.minAnnualSalary == "" ? 0 : data.minAnnualSalary)
      )
    );
    formData.append(
      "MaxAnnualSalary",
      JSON.stringify(
        parseInt(data.maxAnnualSalary == "" ? 0 : data.maxAnnualSalary)
      )
    );
    formData.append("WillingnessToTravelFlag", JSON.stringify(false));
    formData.append("AutoScreeningFilterFlag", JSON.stringify(false));
    formData.append("AutoSkillAssessmentFlag", JSON.stringify(false));
    formData.append("JobOverview", String(data.jobOverview));
    formData.append("MinQualification", String(data.minimumQualification));
    formData.append("SkillRequired", String(data.skillsRequired));
    formData.append("DocumentsRequired", String(data.documentsRequired));
    formData.append("Others", String(data.others));
    formData.append(
      "AssignedToAdminId",
      data.assignTo === ""
        ? JSON.stringify(loggedAdminId)
        : JSON.stringify(parseInt(data.assignTo))
    );
    formData.append(
      "JobStatus",
      JSON.stringify(data.jobStatus === "1" ? true : false)
    );
    formData.append("JobIcon", String(filename));
    formData.append("JobBackground", String(filename1));
    formData.append("IsAprrovedJobs", JSON.stringify(false));
    formData.append("IsProcessedJobs", JSON.stringify(false));
    formData.append("IspublishedJobs", JSON.stringify(isPublish));
    formData.append("JobIconDocument", obj.file);
    formData.append("JobBackgroundDocument", obj1.file);
    let newDate = new Date();
    formData.append("JobAssignDate", "12/11/2020");
    setIsLoder(true);
    if (authorizationToken != null) {
      (async () => {
        await AdminJobSave(
          adminMyJobDispatcher,

          formData,
          authorizationToken
        );
      })();
    }
    setPublish(false);
    // );
  };

  const handleJobIconDelete = () => {
    setFileName("");
    setObj({ file: null });
  };

  const handleJobBackGroundDelete = () => {
    setFileName1("");
    setObj1({ file: null });
  };

  const handleDrop = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "application/pdf"].includes(files[0].type) &&
        files[0].size < 1000000 * 5
      ) {
        //console.log(files[0]);
        if (files.length > 0) {
          setObj({
            ...obj,
            ["file"]: files[0],
          });
          setFileName(files[0].name);
        }
      }
    }
  };

  const handleDropOne = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "application/pdf"].includes(files[0].type) &&
        files[0].size < 1000000 * 5
      ) {
        //console.log(files[0]);
        if (files.length > 0) {
          setObj1({
            ...obj1,
            ["file"]: files[0],
          });
          setFileName1(files[0].name);
        }
      }
    }
  };

  const handleEdit = (id: any) => {
    setMirrorData(0);
    setCurrentJobId(id);
    if (authorizationToken != null) {
      (async () => {
        await GetAdminMyJob(
          adminMyJobDispatcher,
          {
            RowId: id,
          } as RowIdRequestModel,
          authorizationToken
        );
      })();
    }
    setIsOpen(true);
  };

  const handleMirrorJob = (id: any) => {
    setMirrorData(1);

    if (authorizationToken != null) {
      (async () => {
        await GetAdminMyJob(
          adminMyJobDispatcher,
          {
            RowId: id,
          } as RowIdRequestModel,
          authorizationToken
        );
      })();
    }
    setIsOpen(true);
  };

  const handleDelete = () => {
    setIsLoderDelete(true);
    if (authorizationToken != null) {
      (async () => {
        await DeleteAdminMyJob(
          adminMyJobDispatcher,
          {
            JobId: currentJobId,
          } as JobIdRequestModel,
          authorizationToken
        );
      })();
    }
  };

  const handlePublish = (id: any) => {
    if (authorizationToken != null) {
      (async () => {
        await SavePublish(
          adminMyJobDispatcher,
          {
            AdminId: loggedAdminId,
            Isactive: true,
            Jobid: id,
            PublishedJobsFLag: true,
          } as PublishedJobRequestModel,
          authorizationToken
        );
      })();
      let _data: objectIndexing = {};

      _data["AdminId"] = Number(loggedAdminId);
      _data["JobId"] = id;
      _data["PublishedJobsFlag"] = true;
      _data["ApprovalJobsFlag"] = false;
      _data["ViewedJobsFlag"] = false;
      _data["ProcessedJobsFlag"] = false;
      _data["FlagId"] = Number(1);
      _data["IsActive"] = true;
      saveAdminActions(_data).then((res: any) => {
        //console.log(res);
        if (res.data.isSuccess) {
          //  globalSpinner.hideSpinner();
          //  toast.success("Email sent successfully.");
          //  setActive(1);
        } else {
          // globalSpinner.hideSpinner();
          // toast.error("Error:Email not sent.");
        }
      });
    }
  };

  const content = (
    <>
      <p>You Don't Have Permission</p>
    </>
  );

  const onChangeInPub = (list: any, publishedjobState: AdminJobList[]) => {
    setCheckedList(list);
    let JobIdArray =
      publishedjobState != undefined
        ? publishedjobState.map((data, index) => {
            return data.jobId;
          })
        : [];

    setCheckAll(list.length === JobIdArray.length);
    //console.log("JobIdArray", JobIdArray);
  };

  const onCheckAllChangeInPub = (e: any, publishedjobState: AdminJobList[]) => {
    let JobIdArray =
      publishedjobState != undefined
        ? publishedjobState.map((data, index) => {
            return data.jobId;
          })
        : [];

    setCheckedList(e.target.checked ? JobIdArray : []);

    setCheckAll(e.target.checked);
  };

  function onChange(value: any) {
    //console.log(`selected ${value}`);
    if (
      authorizationToken != null &&
      loggedAdminId > 0 &&
      checkedList.length > 0
    ) {
      (async () => {
        await SetAllAssignTo(
          adminMyJobDispatcher,
          {
            AdminId: value,
            JobId: checkedList,
          } as AssignToRequestModel,
          authorizationToken
        );
      })();
    }
    setAsignToAdminId(value);
  }

  // if (watch2("minAnnualSalary")) {
  //   //console.log(8000, watch2("minAnnualSalary"));
  //   watch2("minAnnualSalary") != undefined || watch2("minAnnualSalary") !== ""
  //     ? setMinSalaryState(true)
  //     : setMinSalaryState(false);
  // }

  React.useEffect(() => {
    if (1) {
      //console.log(8000, watch2("minAnnualSalary"));
      watch2("minAnnualSalary") != undefined || watch2("minAnnualSalary") !== ""
        ? setMinSalaryState(true)
        : setMinSalaryState(false);
    }
  }, [watch2("minAnnualSalary")]);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">
            {cl === "/admin/myJob/drafted" ? "Drafted" : "Published"}
          </h1>
          {AdminPermissionResult != undefined &&
          AdminPermissionResult.isCreateorEditJobPermission ? (
            <a
              onClick={() => {
                setIsOpen(true);
                setCurrentJobId(0);
              }}
              data-target="#create_jobdescription"
              data-toggle="modal"
              className="btn cutomise_but"
            >
              <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; Create
              New
            </a>
          ) : (
            <Popover content={content} trigger="hover">
              <a
                data-target="#create_jobdescription"
                data-toggle="modal"
                className="btn cutomise_but"
              >
                <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; Create
                New
              </a>
            </Popover>
          )}

          <div className="row ">
            <div className="action_btn1 "></div>
            <div className="action_btn1 ">
              <button
                type="button"
                className="actions_menu"
                onClick={() => {
                  setIsListOpen(!isListOpen);
                }}
              >
                <i className="fa fa-align-left" aria-hidden="true" />
              </button>
              <div
                className="user_menu"
                tabIndex={-1}
                style={{ display: isListOpen ? "block" : "none" }}
              >
                <div className="action_sec">
                  <ul>
                    <li>
                      <Link to="/admin/myJob/drafted">Drafted</Link>{" "}
                    </li>
                    <li>
                      <Link to="/admin/myJob/published">Published </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row " style={{ marginBottom: 15 }}>
            {cl === "/admin/myJob/drafted" ? null : (
              <ul className="resp-tabs-list resp-tabs">
                <li
                  // key={i}
                  onClick={() => {
                    setjobStatusData(1);
                    // setDocumentTypeActiveId(typeData.rowId);
                  }}
                  className={
                    jobStatusData == 1
                      ? "resp-tab-item resp-tab-active"
                      : "resp-tab-item"
                  }
                  aria-controls={`tab_item-1`}
                  role="tab"
                >
                  Active{" "}
                </li>
                <li
                  // key={i}
                  onClick={() => {
                    setjobStatusData(0);
                    // setDocumentTypeActiveId(typeData.rowId);
                  }}
                  className={
                    jobStatusData == 0
                      ? "resp-tab-item resp-tab-active"
                      : "resp-tab-item"
                  }
                  aria-controls={`tab_item-1`}
                  role="tab"
                >
                  Inactive{" "}
                </li>
              </ul>
            )}
            <label style={{ marginLeft: 20 }}>Assign To:{` `} </label>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={onChange}
            >
              {permissionResult != undefined &&
              permissionResult.data != undefined
                ? permissionResult.data.map((e: any) => {
                    return <Option value={e["value"]}>{e["caption"]}</Option>;
                  })
                : jobCategoryData}
              {/* <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
                <Option value="tom">Tom</Option> */}
            </Select>
          </div>

          {/* BEGIN MODAL */}
          <Modal
            show={isOpen}
            onHide={() => {
              setIsOpen(!isOpen);
            }}
            backdrop="static"
          >
            <form onSubmit={handleSubmit2(handleAdminClick)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>
                  {/* {currentAdminId == 0
                        ? " New Admin Profile"
                        : "Update Admin Profile"} */}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email" className="required">
                        Job Category
                      </label>
                      <span className="select-wrapper-sec">
                        <Controller
                          control={control2}
                          name="jobCategorys"
                          rules={{
                            required: "Job Category is required",
                          }}
                          // value=''
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={
                                myProfile.jobCategories != undefined
                                  ? myProfile.jobCategories.map((e: any) => {
                                      return {
                                        value: e["value"],
                                        label: e["caption"],
                                      };
                                    })
                                  : jobCategoryData
                              }
                              disabled={false}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>
                      <ErrorMessage
                        errors={errors2}
                        name="jobCategorys"
                        render={({ message }) => (
                          <div className="register_validation">{message}</div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email" className="required">
                        Job Type
                      </label>
                      <span className="select-wrapper-sec">
                        <Controller
                          className="custom-select-sec"
                          control={control2}
                          name="jobTypeData"
                          rules={{
                            required: "Job Type is required",
                          }}
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={
                                myProfile.jobTypes != undefined
                                  ? myProfile.jobTypes.map((e: any) => {
                                      return {
                                        value: e["value"],
                                        label: e["caption"],
                                      };
                                    })
                                  : jobTypeDatas
                              }
                              disabled={false}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>
                      <ErrorMessage
                        errors={errors2}
                        name="jobTypeData"
                        render={({ message }) => (
                          <div className="register_validation">{message}</div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email" className="required">
                        Job Title
                      </label>
                      <span className="select-wrapper-sec">
                        <Controller
                          control={control2}
                          name="jobTitle"
                          rules={{
                            required: "Job Title is required",
                          }}
                          // value=''
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={
                                tempData != undefined
                                  ? tempData.map((e) => {
                                      return {
                                        value: e["value"],
                                        label: e["caption"],
                                      };
                                    })
                                  : tempData
                              }
                              disabled={false}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>

                      <ErrorMessage
                        errors={errors2}
                        name="jobTitle"
                        render={({ message }) => (
                          <div className="register_validation">{message}</div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email" className="required">
                        Experience
                      </label>
                      <span className="select-wrapper-sec">
                        <Controller
                          control={control2}
                          name="experience"
                          rules={{
                            required: "experience is required",
                          }}
                          // value=''
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={experienceData}
                              disabled={false}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>
                      <ErrorMessage
                        errors={errors2}
                        name="experience"
                        render={({ message }) => (
                          <div className="register_validation">{message}</div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      name="location"
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>

                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Experience</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      name="experience"
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div> */}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Vacancies</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      name="vacancies"
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                {/* <div className="clearfix" /> */}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Overview</label>
                    <textarea
                      name="jobOverview"
                      rows={4}
                      className="form-control"
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Description</label>
                    <textarea
                      name="jobDescription"
                      className="form-control"
                      rows={4}
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Minimum Qualification</label>
                    <textarea
                      name="minimumQualification"
                      className="form-control"
                      rows={4}
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Skills Required</label>
                    <textarea
                      name="skillsRequired"
                      className="form-control"
                      rows={4}
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Min Annual Salary</label>
                    <input
                      type="number"
                      className="form-control"
                      name="minAnnualSalary"
                      placeholder="Type here"
                      ref={register2({
                        // required:
                        //   watch2("minAnnualSalary") != undefined ||
                        //   watch2("minAnnualSalary") !== ""
                        //     ? true
                        //     : false,
                        required: false,

                        // validate: (value) => {
                        //   //console.log(700, value);
                        //   if (value < 1 && value != 0) {
                        //     return "Min Annual Salary can not be a negative number";
                        //   } else if (
                        //     value >= Number(watch2("maxAnnualSalary")) &&
                        //     Number(watch2("minAnnualSalary")) !== 0
                        //   ) {
                        //     //console.log(
                        //       value,
                        //       Number(watch2("maxAnnualSalary"))
                        //     );
                        //     return "Must be less than Max Salary.";
                        //   } else {
                        //     return undefined;
                        //   }

                        // },
                      })}
                    />
                    <ErrorMessage
                      errors={errors2}
                      name="minAnnualSalary"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Max Annual Salary</label>
                    <input
                      type="number"
                      className="form-control"
                      name="maxAnnualSalary"
                      placeholder="Type here"
                      ref={register2({
                        required: false,
                        // validate: (value) => {
                        //   if (value < 1 && value != 0) {
                        //     return "Max Annual Salary can not be a negative number";
                        //   } else if (
                        //     value < Number(watch2("minAnnualSalary") && 0)
                        //   ) {
                        //     return "Must be greater than Min Salary.";
                        //   } else {
                        //     return undefined;
                        //   }
                        // },
                      })}
                    />
                  </div>
                  <ErrorMessage
                    errors={errors2}
                    name="maxAnnualSalary"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="clearfix" />
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Documents Required</label>
                    <textarea
                      name="documentsRequired"
                      className="form-control"
                      rows={4}
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Others</label>
                    <textarea
                      name="others"
                      className="form-control"
                      rows={4}
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Assign TO</label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="assignTo"
                        rules={
                          {
                            // required: "Assign To is required",
                          }
                        }
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              permissionResult != undefined
                                ? permissionResult.data.map((e: any) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : jobCategoryData
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="assignTo"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Status
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="jobStatus"
                        rules={{
                          required: "Job Status is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={jobStatusSelect}
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="jobStatus"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Icon</label>

                    <FileUploads
                      accept=".pdf,.jpg,.jpeg,.png"
                      onChange={(file: any) => {
                        handleDrop([file]);
                      }}
                      name="Upload icon"
                      disabled={false}
                    />
                  </div>
                  {filename}{" "}
                  {filename === "" || filename === null ? null : (
                    <a>
                      <i
                        onClick={() => handleJobIconDelete()}
                        className="fa fa-trash-o"
                        aria-hidden="true"
                        style={{ paddingLeft: 2 }}
                      />
                    </a>
                  )}
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Background</label>
                    <FileUploads
                      accept=".pdf,.jpg,.jpeg,.png"
                      onChange={(file: any) => {
                        handleDropOne([file]);
                      }}
                      name="Upload Background"
                      disabled={false}
                    />
                  </div>
                  {filename1}{" "}
                  {filename1 === "" || filename1 == null ? null : (
                    <a>
                      <i
                        onClick={() => handleJobBackGroundDelete()}
                        className="fa fa-trash-o"
                        aria-hidden="true"
                        style={{ paddingLeft: 2 }}
                      />
                    </a>
                  )}
                </div>

                <div className="clearfix" />
              </Modal.Body>
              <div className="modal-footer m-t-30">
                {currentJobId != 0 ? (
                  isLoderDelete ? (
                    <button
                      className="btn btn-danger save-event waves-effect waves-light"
                      type="button"
                    >
                      <Spin indicator={antIcon} />
                    </button>
                  ) : (
                    <button
                      className="btn btn-danger save-event waves-effect waves-light"
                      type="button"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  )
                ) : null}

                {isLoder ? (
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                  >
                    <Spin indicator={antIcon} />
                  </button>
                ) : cl === "/admin/myJob/drafted" ? (
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                    onClick={() => {
                      setPublish(false);
                    }}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                    onClick={() => {
                      setPublish(true);
                    }}
                  >
                    Save
                  </button>
                )}

                {cl === "/admin/myJob/drafted" ? (
                  isLoderPublish ? (
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="submit"
                    >
                      <Spin indicator={antIcon} />
                    </button>
                  ) : (
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="submit"
                      onClick={() => {
                        setPublish(true);
                        setIsLoderPublish(true);
                      }}
                    >
                      Publish Now
                    </button>
                  )
                ) : null}

                <button
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                    setFileName("");
                    setFileName1("");
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="clearfix" />
            </form>
          </Modal>

          {/* END MODAL */}

          <div className="clearfix" />
          {cl === "/admin/myJob/drafted" ? (
            <AdminDraftedJobComponent
              handleEdit={handleEdit}
              handleMirrorJob={handleMirrorJob}
              jobListInAdmin={jobListInAdmin}
              handlePublish={handlePublish}
              AdminPermissionResult={AdminPermissionResult}
              jobStatusData={jobStatusData}
              loggedAdminId={loggedAdminId}
            />
          ) : (
            <AdminPublishedJobComponent
              handleMirrorJob={handleMirrorJob}
              jobListInAdmin={jobListInAdminPub}
              AdminPermissionResult={AdminPermissionResult}
              handleEdit={handleEdit}
              jobStatusData={jobStatusData}
              loggedAdminId={loggedAdminId}
              onChangeInPub={onChangeInPub}
              checkedList={checkedList}
              onCheckAllChangeInPub={onCheckAllChangeInPub}
              checkAll={checkAll}
            />
          )}

          <div className="clearfix" />
        </div>
      </div>
    </>
  );
};

export default withRouter(AdminMyJobComponent);
