import { Reducer } from "react"
import { IDatePostedManagementState, DatePostedDispatcherContext, DatePostedStateContext } from "../../context/general/DatePostedContext"
import React from "react";

import axios from 'axios';
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {  datePostedRequestModel, datePostedViewModel } from "../../models/general/DatePosted";

let token=AuthService.accessToken;
let   authorizationToken =token!=null?token.replace(/['"]+/g,''):""; 

export type IDatePostedAction = {
    type:"SET_DatePosted_LIST",
    datePosted:datePostedViewModel 
}




export const datePostedReducer: Reducer<IDatePostedManagementState, IDatePostedAction> = (draft, action): IDatePostedManagementState => {
    switch (action.type) {
     
            case "SET_DatePosted_LIST":
                draft.datePosted=action.datePosted;
                return draft;
    }
}



export const useDatePostedDispatcher = (): React.Dispatch<IDatePostedAction> => {
    const datePostedDispatcher = React.useContext(DatePostedDispatcherContext);
    if (!datePostedDispatcher) {
        throw new Error("You have to provide the DatePosted dispatcher using theDatePostedDispatcherContext.Provider in a parent component.");
    }
    return datePostedDispatcher;
}


export const useDatePostedContext = (): IDatePostedManagementState => {
    const datePostedContext = React.useContext(DatePostedStateContext);
    if (!datePostedContext) throw new Error("You have to provide the datePosted context using the DatePostedStateContext.Provider in a parent component.");
    return datePostedContext;
}



export const getDatePostedList = async (dispatcher: React.Dispatch<IDatePostedAction>,query:datePostedRequestModel  ) => {
   
    try{

      let header = {
                      "Content-Type": "application/json",
                      "Accept": "application/json",
                     "Authorization":`Bearer ${authorizationToken}`,
                  };
  
                   const url=AppUrls.GetAllDatePosted
  
                  axios.post(url, JSON.stringify( 
                     query
                   ),{ headers: header })
                  .then(res => {
                    dispatcher({ type:"SET_DatePosted_LIST", datePosted: res.data });
                  })

  }
  catch(e){

  }
}



