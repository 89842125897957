import { Reducer } from "react"

import React from "react";

import axios from 'axios';
import AuthService from "../../services/AuthService";

import { AppUrls } from "../../environments/environment";
import { matchedJobVendorRequestModel, matchedJobVendorViewModel } from "../../models/vendor/VendorMatchedJob";
import { IMatchedJobVendorManagementState, MatchedJobVendorDispatcherContext, MatchedJobVendorStateContext } from "../../context/vendor/VendorMatchedJobContext";



let token=AuthService.accessToken;
let   authorizationToken =token!=null?token.replace(/['"]+/g,''):""; 

export type IMatchedJobVendorAction = {
    type:"SET_Job Applied_LIST",
    matchedJobVendor:matchedJobVendorViewModel 
}




export const matchedJobVendorReducer: Reducer<IMatchedJobVendorManagementState, IMatchedJobVendorAction> = (draft, action): IMatchedJobVendorManagementState => {
    switch (action.type) {
     
            case "SET_Job Applied_LIST":
                draft.matchedJobVendor=action.matchedJobVendor;
                return draft;
    }
}



export const useMatchedJobVendorDispatcher = (): React.Dispatch<IMatchedJobVendorAction> => {
    const matchedJobVendorDispatcher = React.useContext(MatchedJobVendorDispatcherContext);
    if (!matchedJobVendorDispatcher) {
        throw new Error("You have to provide the MatchedJobVendor dispatcher using theMatchedJobVendorDispatcherContext.Provider in a parent component.");
    }
    return matchedJobVendorDispatcher;
}


export const useMatchedJobVendorContext = (): IMatchedJobVendorManagementState => {
    const matchedJobVendorContext = React.useContext(MatchedJobVendorStateContext);
    if (!matchedJobVendorContext) throw new Error("You have to provide the matchedJobVendor context using the MatchedJobVendorStateContext.Provider in a parent component.");
    return matchedJobVendorContext;
}



export const getMatchedJobVendorList = async (dispatcher: React.Dispatch<IMatchedJobVendorAction>,query:matchedJobVendorRequestModel,token:string   ) => {
   
    try{

      if (token?.startsWith(`"`)) {
        token = token.substring(1);
        token = token.slice(0, -1);
      }

      let header = {
                      "Content-Type": "application/json",
                      "Accept": "application/json",
                     "Authorization":`Bearer ${token}`,
                  };
  
                   const url=AppUrls.GetAllVendorMatchedJob
  
                  axios.post(url, JSON.stringify( 
                     query
                   ),{ headers: header })
                  .then(res => {
                    dispatcher({ type:"SET_Job Applied_LIST", matchedJobVendor: res.data });
                  })

  }
  catch(e){

  }
}



