import React, { useState, useEffect } from "react";
import ResumeComponent from "./ResumeComponent";
import {
  useMyProfileContext,
  useMyProfileDispatcher,
  getProfileProgressBarResult,
} from "../../../../action/MyProfileAction";

import { profileImageRequestModel } from "../../../../models/candidate/MyProfileSelectBoxData";
import AuthService from "../../../../services/AuthService";
interface IResumeContainerProps {
  digiLockerVew: any;
}

interface IResumeContainerState {}

//const initialState = {};

const ResumeContainer: React.FC<IResumeContainerProps> = (props) => {
  // const [resumeContainerState, setResumeContainerState] = React.useState<IResumeContainerState>(
  //   initialState
  // );

  const myProfileContext = useMyProfileContext();
  const { loggedUserId } = myProfileContext;
  const candidateId = loggedUserId;

  // const [candidateResumes, setCandidateResumes] = useState({});

  // const [candidateCoverLetters, setCandidateCoverLetters] = useState({});
  const authorizationToken = AuthService.accessToken;
  const myProfileDispatcher = useMyProfileDispatcher();
  // useEffect(() => {
  //   if (candidateId) {
  //     getAllResumeCandidateMap(
  //       {
  //         "CandidateId": candidateId,
  //         "Page": 1,
  //         "PageSize": 10,
  //         "SearchTerm": "",
  //         "SortOrder": "",
  //         "ShowInactive": false
  //       }
  //     ).then((res) => {
  //       ////console.log(res.data);
  //       setCandidateResumes(res.data);

  //     }).catch((err) => {
  //       //console.log(err);
  //     });

  //     getAllCoverLetterCandidateMap(
  //       {
  //         "CandidateId": candidateId,
  //         "Page": 1,
  //         "PageSize": 10,
  //         "SearchTerm": "",
  //         "SortOrder": "",
  //         "ShowInactive": false
  //       }
  //     ).then((res) => {
  //       ////console.log(res.data);
  //       setCandidateCoverLetters(res.data);

  //     }).catch((err) => {
  //       //console.log(err);
  //     });

  //   }
  // }, [candidateId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getProfileProgressBarResult(
          myProfileDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as profileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  return (
    <>
      <ResumeComponent
        // candidateResumes={candidateResumes}
        candidateId={candidateId}
        // setCandidateResumes={setCandidateResumes}
        digiLockerVew={props.digiLockerVew}
        // candidateCoverLetters={candidateCoverLetters}
        // setCandidateCoverLetters={setCandidateCoverLetters}
      />
    </>
  );
};
export default ResumeContainer;
