import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AchievementsContextProvider } from "../../context/admin/AchievementsContext";
import { AdminCommunicationContextProvider } from "../../context/admin/AdminCommunicationContext";
import { CareerDeveloperContextProvider } from "../../context/admin/CareerDeveloperContext";
import { CareerTipsApprovalContextProvider } from "../../context/admin/CareerTipsApprovalContext";
import { JobAlertVendorContextProvider } from "../../context/vendor/JobAlertContext";
import { VendorJobAppliedContextProvider } from "../../context/vendor/VendorJobAppliedContext";
import { VendorJobBookMarkContextProvider } from "../../context/vendor/VendorJobBookMarkContext";
import { MatchedJobVendorContextProvider } from "../../context/vendor/VendorMatchedJobContext";
import { VendorSuggestedJobContextProvider } from "../../context/vendor/VendorSuggestedJobContext";
import AuthService from "../../services/AuthService";
import AboutUsContainer from "../about_us/AboutUsContainer";
import AdminLayoutContainer from "../admin/layout/AdminLayoutContainer";
import CandidateLayoutContainer from "../candidate/layout/CandidateLayoutContainer";
import CareerTipsCategoryContainer from "../career_tips/CareerTipsCategoryContainer";
import CareerTipsContainer from "../career_tips/CareerTipsContainer";
import ComingSoonContainer from "../comingsoon/ComingSoonContainer";
import UnAuthorizedContainer from "../comingsoon/unAuthorized/UnAuthorizedContainer";
import ContactUsContainer from "../contact_us/ContactUsContainer";
import FileView from "../FileView";
import HelpContainer from "../help/HelpContainer";
import IndexContainer from "../index/IndexContainer";
import JobSearchContainer from "../job_search/JobSearchContainer";
import JobSearchVendorContainer from "../job_search/JobSearchVendorContainer";
import SearchGenericContainer from "../job_search/search_generic/SearchGenericContainer";
import MiscellaneousLayoutContainer from "../miscellaneous/MiscellaneousLayoutContainer";
import SuperAdminLayoutContainer from "../super_admin/layout/SuperAdminLayoutContainer";
import VendorLayoutContainer from "../vendor/layout/VendorLayoutContainer";
import JitTest from "./JitTest_";
import CareerDeveloperContainer from "../career_developer/CareerDeveloperContainer";
import ClientLayoutContainer from "../client/layout/ClientLayoutContainer";

interface ILayoutComponentProps {}

interface ILayoutComponentState {}

const initialState = {};

const LayoutComponent: React.FC<ILayoutComponentProps> = (props) => {
  const [
    LayoutComponentState,
    setLayoutComponentState,
  ] = React.useState<ILayoutComponentState>(initialState);

  let role = AuthService.currentUser?.roles[0];

  const routeFuction = () => {
    if (role === "Candidate") {
      return (
        <Switch>
          <Route exact path="/">
            {/* <SearchContextProvider> */}
            <AchievementsContextProvider>
              <IndexContainer />
            </AchievementsContextProvider>

            {/* </SearchContextProvider> */}
          </Route>
          <Route exact path="/jit_test_">
            <JitTest />
          </Route>

          <Route path="/candidate">
            <CandidateLayoutContainer />
          </Route>
          <Route path="/un_authorized">
            <UnAuthorizedContainer />
          </Route>
          <Route path="/admin">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/vendor">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/super_admin">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/about_us">
            <AboutUsContainer />
          </Route>
          <Route path="/contact_us">
            <ContactUsContainer />
          </Route>
          <Route path="/help">
            <HelpContainer />
          </Route>
          <Route path="/career_developer">
            <CareerDeveloperContainer />
          </Route>
          <Route path="/coming_soon">
            <ComingSoonContainer />
          </Route>
          <Route path="/career_tips">
            <CareerTipsApprovalContextProvider>
              <CareerTipsContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route path="/career_tips_category/:id/:title">
            <CareerTipsApprovalContextProvider>
              <CareerTipsCategoryContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route
            exact
            path="/jobsearch/:paramTitle/:paramId/:paramJobTitle/:paramLocation/:paramJobType"
          >
            {/* <SearchContextProvider> */}
            <SearchGenericContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route exact path="/job_search/:jobId">
            {/* <SearchContextProvider> */}
            <JobSearchContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/job_search/:api/:id">
            {/* <SearchContextProvider> */}
            <JobSearchContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/job_search_vendor/:api/:id">
            <VendorSuggestedJobContextProvider>
              +{" "}
              <JobAlertVendorContextProvider>
                +{" "}
                <VendorJobBookMarkContextProvider>
                  +{" "}
                  <MatchedJobVendorContextProvider>
                    <VendorJobAppliedContextProvider>
                      <JobSearchVendorContainer />
                    </VendorJobAppliedContextProvider>
                    +{" "}
                  </MatchedJobVendorContextProvider>
                  +{" "}
                </VendorJobBookMarkContextProvider>
                +{" "}
              </JobAlertVendorContextProvider>
            </VendorSuggestedJobContextProvider>
          </Route>
          <Route path="/file_view/:fileType/:filePath">
            <FileView />
          </Route>
          <Route path="/">
            <MiscellaneousLayoutContainer />
          </Route>
        </Switch>
      );
    } else if (role === "Vendor") {
      return (
        <Switch>
          <Route exact path="/">
            {/* <SearchContextProvider> */}

            <AchievementsContextProvider>
              <IndexContainer />
            </AchievementsContextProvider>

            {/* </SearchContextProvider> */}
          </Route>
          <Route exact path="/jit_test_">
            <JitTest />
          </Route>
          <Route path="/un_authorized">
            <UnAuthorizedContainer />
          </Route>
          <Route
            exact
            path="/jobsearch/:paramTitle/:paramId/:paramJobTitle/:paramLocation/:paramJobType"
          >
            {/* <SearchContextProvider> */}
            <SearchGenericContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/candidate">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/admin">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/vendor">
            <VendorLayoutContainer />
          </Route>
          <Route path="/super_admin">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/about_us">
            <AboutUsContainer />
          </Route>
          <Route path="/contact_us">
            <ContactUsContainer />
          </Route>
          <Route path="/help">
            <HelpContainer />
          </Route>
          <Route path="/career_developer">
            <CareerDeveloperContainer />
          </Route>
          <Route path="/coming_soon">
            <ComingSoonContainer />
          </Route>
          <Route path="/career_tips">
            <CareerTipsApprovalContextProvider>
              <CareerTipsContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route path="/career_tips_category/:id/:title">
            <CareerTipsApprovalContextProvider>
              <CareerTipsCategoryContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route exact path="/job_search/:jobId">
            {/* <SearchContextProvider> */}
            <JobSearchContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/job_search/:api/:id">
            {/* <SearchContextProvider> */}
            <JobSearchContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/job_search_vendor/:api/:id">
            <VendorSuggestedJobContextProvider>
              +{" "}
              <JobAlertVendorContextProvider>
                +{" "}
                <VendorJobBookMarkContextProvider>
                  +{" "}
                  <MatchedJobVendorContextProvider>
                    <VendorJobAppliedContextProvider>
                      <JobSearchVendorContainer />
                    </VendorJobAppliedContextProvider>
                    +{" "}
                  </MatchedJobVendorContextProvider>
                  +{" "}
                </VendorJobBookMarkContextProvider>
                +{" "}
              </JobAlertVendorContextProvider>
            </VendorSuggestedJobContextProvider>
          </Route>
          <Route path="/file_view/:fileType/:filePath">
            <FileView />
          </Route>
          <Route path="/">
            <MiscellaneousLayoutContainer />
          </Route>
        </Switch>
      );
    } else if (role === "SuperAdmin") {
      return (
        <Switch>
          <Route exact path="/">
            {/* <SearchContextProvider> */}

            <AchievementsContextProvider>
              <IndexContainer />
            </AchievementsContextProvider>

            {/* </SearchContextProvider> */}
          </Route>
          <Route exact path="/jit_test_">
            <JitTest />
          </Route>
          <Route path="/un_authorized">
            <UnAuthorizedContainer />
          </Route>
          <Route path="/candidate">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/admin">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/vendor">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/super_admin">
            <SuperAdminLayoutContainer />
          </Route>
          <Route path="/about_us">
            <AboutUsContainer />
          </Route>
          <Route path="/contact_us">
            <ContactUsContainer />
          </Route>
          <Route path="/help">
            <HelpContainer />
          </Route>
          <Route path="/career_developer">
            <CareerDeveloperContainer />
          </Route>
          <Route path="/coming_soon">
            <ComingSoonContainer />
          </Route>
          <Route path="/career_tips">
            <CareerTipsApprovalContextProvider>
              <CareerTipsContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route path="/career_tips_category/:id/:title">
            <CareerTipsApprovalContextProvider>
              <CareerTipsCategoryContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route exact path="/job_search/:jobId">
            {/* <SearchContextProvider> */}
            <JobSearchContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/job_search/:api/:id">
            {/* <SearchContextProvider> */}
            <JobSearchContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/job_search_vendor/:api/:id">
            <VendorSuggestedJobContextProvider>
              +{" "}
              <JobAlertVendorContextProvider>
                +{" "}
                <VendorJobBookMarkContextProvider>
                  +{" "}
                  <MatchedJobVendorContextProvider>
                    <VendorJobAppliedContextProvider>
                      <JobSearchVendorContainer />
                    </VendorJobAppliedContextProvider>
                    +{" "}
                  </MatchedJobVendorContextProvider>
                  +{" "}
                </VendorJobBookMarkContextProvider>
                +{" "}
              </JobAlertVendorContextProvider>
            </VendorSuggestedJobContextProvider>
          </Route>
          <Route path="/file_view/:fileType/:filePath">
            <FileView />
          </Route>
          <Route path="/">
            <MiscellaneousLayoutContainer />
          </Route>
        </Switch>
      );
    } else if (role === "Admin") {
      return (
        <Switch>
          <Route exact path="/">
            {/* <SearchContextProvider> */}

            <AchievementsContextProvider>
              <IndexContainer />
            </AchievementsContextProvider>

            {/* </SearchContextProvider> */}
          </Route>
          <Route exact path="/jit_test_">
            <JitTest />
          </Route>
          <Route path="/un_authorized">
            <UnAuthorizedContainer />
          </Route>
          <Route path="/candidate">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/admin">
            <AdminCommunicationContextProvider>
              <CareerDeveloperContextProvider>
                <AdminLayoutContainer />
              </CareerDeveloperContextProvider>
            </AdminCommunicationContextProvider>
          </Route>
          <Route path="/vendor">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/super_admin">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/about_us">
            <AboutUsContainer />
          </Route>
          <Route path="/contact_us">
            <ContactUsContainer />
          </Route>
          <Route path="/help">
            <HelpContainer />
          </Route>
          <Route path="/career_developer">
            <CareerDeveloperContainer />
          </Route>
          <Route path="/coming_soon">
            <ComingSoonContainer />
          </Route>
          <Route path="/career_tips">
            <CareerTipsApprovalContextProvider>
              <CareerTipsContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route path="/career_tips_category/:id/:title">
            <CareerTipsApprovalContextProvider>
              <CareerTipsCategoryContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route exact path="/job_search/:jobId">
            {/* <SearchContextProvider> */}
            <JobSearchContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/job_search/:api/:id">
            {/* <SearchContextProvider> */}
            <JobSearchContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/job_search_vendor/:api/:id">
            <VendorSuggestedJobContextProvider>
              +{" "}
              <JobAlertVendorContextProvider>
                +{" "}
                <VendorJobBookMarkContextProvider>
                  +{" "}
                  <MatchedJobVendorContextProvider>
                    <VendorJobAppliedContextProvider>
                      <JobSearchVendorContainer />
                    </VendorJobAppliedContextProvider>
                    +{" "}
                  </MatchedJobVendorContextProvider>
                  +{" "}
                </VendorJobBookMarkContextProvider>
                +{" "}
              </JobAlertVendorContextProvider>
            </VendorSuggestedJobContextProvider>
          </Route>
          <Route path="/file_view/:fileType/:filePath">
            <FileView />
          </Route>
          <Route path="/">
            <MiscellaneousLayoutContainer />
          </Route>
        </Switch>
      );
    } else if (role === "Client") {
      return (
        <Switch>
          <Route exact path="/">
            <AchievementsContextProvider>
              <IndexContainer />
            </AchievementsContextProvider>
          </Route>
          <Route exact path="/jit_test_">
            <JitTest />
          </Route>
          <Route path="/un_authorized">
            <UnAuthorizedContainer />
          </Route>
          <Route
            exact
            path="/jobsearch/:paramTitle/:paramId/:paramJobTitle/:paramLocation/:paramJobType"
          >
            <SearchGenericContainer />
          </Route>
          <Route path="/candidate">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/admin">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/vendor">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/client">
            <ClientLayoutContainer />
          </Route>
          <Route path="/super_admin">
            <Redirect to="/un_authorized"></Redirect>
          </Route>
          <Route path="/about_us">
            <AboutUsContainer />
          </Route>
          <Route path="/contact_us">
            <ContactUsContainer />
          </Route>
          <Route path="/help">
            <HelpContainer />
          </Route>
          <Route path="/career_developer">
            <CareerDeveloperContainer />
          </Route>
          <Route path="/coming_soon">
            <ComingSoonContainer />
          </Route>
          <Route path="/career_tips">
            <CareerTipsApprovalContextProvider>
              <CareerTipsContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route path="/career_tips_category/:id/:title">
            <CareerTipsApprovalContextProvider>
              <CareerTipsCategoryContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route exact path="/job_search/:jobId">
            <JobSearchContainer />
          </Route>
          <Route path="/job_search/:api/:id">
            <JobSearchContainer />
          </Route>

          <Route path="/file_view/:fileType/:filePath">
            <FileView />
          </Route>
          <Route path="/">
            <MiscellaneousLayoutContainer />
          </Route>
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route exact path="/">
            {/* <SearchContextProvider> */}

            <AchievementsContextProvider>
              <IndexContainer />
            </AchievementsContextProvider>

            {/* </SearchContextProvider> */}
          </Route>
          <Route exact path="/jit_test_">
            <JitTest />
          </Route>

          <Route path="/candidate">
            <CandidateLayoutContainer />
          </Route>
          <Route path="/admin">
            <AdminCommunicationContextProvider>
              <CareerDeveloperContextProvider>
                <AdminLayoutContainer />
              </CareerDeveloperContextProvider>
            </AdminCommunicationContextProvider>
          </Route>
          <Route path="/vendor">
            <VendorLayoutContainer />
          </Route>
          <Route path="/super_admin">
            <SuperAdminLayoutContainer />
          </Route>
          <Route path="/about_us">
            <AboutUsContainer />
          </Route>
          <Route path="/contact_us">
            <ContactUsContainer />
          </Route>
          <Route path="/help">
            <HelpContainer />
          </Route>
          <Route path="/career_developer">
            <CareerDeveloperContainer />
          </Route>
          <Route path="/coming_soon">
            <ComingSoonContainer />
          </Route>
          <Route path="/career_tips">
            <CareerTipsApprovalContextProvider>
              <CareerTipsContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route
            exact
            path="/jobsearch/:paramTitle/:paramId/:paramJobTitle/:paramLocation/:paramJobType"
          >
            {/* <SearchContextProvider> */}
            <SearchGenericContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/career_tips_category/:id/:title">
            <CareerTipsApprovalContextProvider>
              <CareerTipsCategoryContainer />
            </CareerTipsApprovalContextProvider>
          </Route>
          <Route exact path="/job_search/:jobId">
            {/* <SearchContextProvider> */}
            <JobSearchContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/job_search/:api/:id">
            {/* <SearchContextProvider> */}
            <JobSearchContainer />
            {/* </SearchContextProvider> */}
          </Route>
          <Route path="/job_search_vendor/:api/:id">
            <VendorSuggestedJobContextProvider>
              +{" "}
              <JobAlertVendorContextProvider>
                +{" "}
                <VendorJobBookMarkContextProvider>
                  +{" "}
                  <MatchedJobVendorContextProvider>
                    <VendorJobAppliedContextProvider>
                      <JobSearchVendorContainer />
                    </VendorJobAppliedContextProvider>
                    +{" "}
                  </MatchedJobVendorContextProvider>
                  +{" "}
                </VendorJobBookMarkContextProvider>
                +{" "}
              </JobAlertVendorContextProvider>
            </VendorSuggestedJobContextProvider>
          </Route>
          <Route path="/file_view/:fileType/:filePath">
            <FileView />
          </Route>
          <Route path="/">
            <MiscellaneousLayoutContainer />
          </Route>
        </Switch>
      );
    }
  };

  return (
    <>
      {" "}
      {routeFuction()}
      {/* <Switch>
        <Route exact path="/">
     

          <AchievementsContextProvider>
            <IndexContainer />
          </AchievementsContextProvider>

        
        </Route>
        <Route exact path="/jit_test_">
          <JitTest />
        </Route>
        <Route path="/un_authorized">
          <UnAuthorizedContainer />
        </Route>
        <Route
          exact
          path="/jobsearch/:paramTitle/:paramId/:paramJobTitle/:paramLocation/:paramJobType"
        >
        
          <SearchGenericContainer />
         
        </Route>
        <Route path="/candidate">
          <Redirect to="/un_authorized"></Redirect>
        </Route>
        <Route path="/admin">
          <Redirect to="/un_authorized"></Redirect>
        </Route>
        <Route path="/vendor">
          <Redirect to="/un_authorized"></Redirect>
        </Route>
        <Route path="/client">
          <ClientLayoutContainer />
        </Route>
        <Route path="/super_admin">
          <Redirect to="/un_authorized"></Redirect>
        </Route>
        <Route path="/about_us">
          <AboutUsContainer />
        </Route>
        <Route path="/contact_us">
          <ContactUsContainer />
        </Route>
        <Route path="/help">
          <HelpContainer />
        </Route>
        <Route path="/career_developer">
          <CareerDeveloperContainer />
        </Route>
        <Route path="/coming_soon">
          <ComingSoonContainer />
        </Route>
        <Route path="/career_tips">
          <CareerTipsApprovalContextProvider>
            <CareerTipsContainer />
          </CareerTipsApprovalContextProvider>
        </Route>
        <Route path="/career_tips_category/:id/:title">
          <CareerTipsApprovalContextProvider>
            <CareerTipsCategoryContainer />
          </CareerTipsApprovalContextProvider>
        </Route>
        <Route exact path="/job_search/:jobId">
        
          <JobSearchContainer />
        
        </Route>
        <Route path="/job_search/:api/:id">
         
          <JobSearchContainer />
         
        </Route>

        <Route path="/file_view/:fileType/:filePath">
          <FileView />
        </Route>
        <Route path="/">
          <MiscellaneousLayoutContainer />
        </Route>
      </Switch>
     */}
    </>
  );
};
export default LayoutComponent;
