import React from "react";
import ClientLayoutComponent from "./ClientLayoutComponent";

const ClientLayoutContainer = () => {
  return (
    <>
      <ClientLayoutComponent />
    </>
  );
};

export default ClientLayoutContainer;
