import React from "react";
import MiscellaneousHeader from "./MiscellaneousHeader";
import Registration from "./Registration";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordOtp from "./ForgotPasswordOtp";
import OtpVerificationEmail from "./OtpVerificationEmail";
import OtpVerificationMobile from "./OtpVerificationMobile";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ForgotPasswordUser from "./ForgotPasswordUser";

interface IMiscellaneousLayoutComponentProps { }

interface IMiscellaneousLayoutComponentState { }

const initialState = {};

const MiscellaneousLayoutComponent: React.FC<IMiscellaneousLayoutComponentProps> = (props) => {

  const [MiscellaneousLayoutComponentState, setMiscellaneousLayoutComponentState] = React.useState<IMiscellaneousLayoutComponentState>(initialState);

  return (
    <div className="register_bg">

      <MiscellaneousHeader />

      <Switch>
        <Route path="/registration">
          <Registration />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        {/* <Route path="/forgot-password">
          <ForgotPassword />
        </Route> */}
        <Route path="/forgot-password-otp">
          {/* <ForgotPasswordOtp /> */}
          <ForgotPasswordUser />
        </Route>
        <Route path="/otp-verification-email">
          <OtpVerificationEmail />
        </Route>
        <Route path="/otp-verification-mobile">
          <OtpVerificationMobile />
        </Route>
      </Switch>
    </div>
  );
};

export default MiscellaneousLayoutComponent;
