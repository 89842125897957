import React from "react";
import PropTypes from "prop-types";
import { confirmable, createConfirmation } from "react-confirm";
import { Modal, Button } from "react-bootstrap";

const GlobalConfirm = ({
  show,
  proceed,
  confirmation,
  options,
}: {
  show: boolean;
  proceed: (e: boolean) => {};
  confirmation: string;
  options: any;
}) => {
  //console.log(confirmation, options)

  return (
    <Modal show={show} onHide={() => proceed(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{options.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmation}</Modal.Body>
      <div className="modal-footer  m-t-30">
        <button
          onClick={() => proceed(true)}
          className="btn btn-danger"
          type="submit"
        >
          {options.yes}
        </button>
        <button
          onClick={() => proceed(false)}
          className="btn btn-default"
          type="button"
        >
          {options.no}
        </button>
      </div>
      <div className="clearfix" />
      {/* <Modal.Footer>
        <Button onClick={() => proceed(true)}>
          {options.yes}
        </Button>
        <Button onClick={() => proceed(false)}>
          {options.no}
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

const _GlobalConfirm = confirmable(GlobalConfirm);

export const confirm = createConfirmation(_GlobalConfirm);

export function confirmWrapper(confirmation: any, options: any) {
  return confirm({ confirmation, options });
}
