import React from "react";

import IndexFooterContainer from "../index/indexFooter/IndexFooterContainer";

import IndexHeaderContainer from "../index/indexHeader/IndexHeaderContainer";

import ContactUsComponent from "./ContactUsComponent";


interface IContactUsContainerProps { }


interface IContactUsContainerState { }


const initialState = {};


const ContactUsContainer: React.FC<IContactUsContainerProps> = (props) => {

  const [ContactUsContainerState, setContactUsContainerState] = React.useState<IContactUsContainerState>(

    initialState

  );


  return (

    <>

     

    <ContactUsComponent/>

    <IndexFooterContainer />

    </>

  );

};

export default ContactUsContainer;