import React, { useContext } from 'react';

export const GlobalSpinnerContext = React.createContext({
  visible: false,
  showSpinner: () => { },
  hideSpinner: () => { }
});

export function GlobalSpinnerProvider({ children }: { children: any }) {

  const [visible, setVisible] = React.useState(false);

  return (
    <GlobalSpinnerContext.Provider value={{
      visible,
      showSpinner: () => { setVisible(true); },
      hideSpinner: () => { setVisible(false); }
    }}>
      {children}
    </GlobalSpinnerContext.Provider>
  );
}

export function useGlobalSpinnerContext() {
  const globalSpinner = useContext(GlobalSpinnerContext);
  return globalSpinner;
}