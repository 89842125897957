import React from "react";
import { Route, Switch, useRouteMatch, withRouter } from "react-router";
import {
  GettClientPersonalDetails,
  SocialAccountContextProvider,
  useClientProfileDispatcher,
} from "../../../action/client/ClientProfileAction";
import { ClientPersonalInfoRequestByIdModel } from "../../../models/client/ClientProfile";
import AuthService from "../../../services/AuthService";
import ClientDashboardComponent from "../dashboard/ClientDashboardComponent";
import ClientMessageContainer from "../message/ClientMessageContainer";
import ClientViewApplicationContainer from "../myJob/ClientViewApplicationContainer";
import MyJobContainer from "../myJob/MyJobContainer";

import ClientProfileContainer from "../profile/ClientProfileContainer";
import ClientSideSearch from "../search/ClientSideSearch";
import ClientSidebarComponent from "./ClientSidebarComponent";

const ClientLayoutComponent = () => {
  const { path, url } = useRouteMatch();
  console.log(888, path);
  const clientProfileDispatcher = useClientProfileDispatcher();

  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != undefined) {
      (async () => {
        await GettClientPersonalDetails(
          clientProfileDispatcher,
          {
            rowId: 0,
            userId: parseInt(user?.id),
          } as ClientPersonalInfoRequestByIdModel,
          authorizationToken
        );
      })();
    }
  }, [user?.id]);

  return (
    <>
      <div id="wrapper">
        <ClientSidebarComponent />
        <div className="content-wrapper">
          <Switch>
            <Route exact path={`${path}`}>
              <ClientDashboardComponent />
            </Route>
            <Route exact path={`${path}/my_profile`}>
              <SocialAccountContextProvider>
                <ClientProfileContainer />
              </SocialAccountContextProvider>
            </Route>
            <Route exact path={`${path}/message`}>
              <ClientMessageContainer />
            </Route>
            <Route exact path={`${path}/my_jobpost`}>
              <MyJobContainer />
            </Route>
            <Route
              exact
              path={`${path}/clientSearch/:paramCategory/:paramExp/:paramLocation/:paramTitle/:id`}
            >
              <ClientSideSearch />
            </Route>
            <Route exact path={`${path}/viewApplicant/:jobTypeId/:jobId`}>
              <ClientViewApplicationContainer />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default withRouter(ClientLayoutComponent);
