import React, { useState, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../../candidate/my_profile/components/SelectOption";
import { saveAdminActions } from "../../../apis/admin";
import {
  useAdminCommunicationDispatcher,
  useAdminCommunicationContext,
  getSMSTemplateList,
  saveBulkSMSConversation,
} from "../../../action/admin/AdminCommunicationAction";
import {
  BulkSMSListRequestModel,
  BulkSMSDetailRequestModel,
  BulkSMSDetailPostModel,
  MessageTransactionsData,
  BulkSMSDetailListModel,
  BulkSMSAllListModel,
} from "../../../models/admin/AdminCommunication";

import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../../context/GlobalSpinner";
import _ from "lodash";

interface ReactSelectOption {
  value: string;
  label: string;
}

type objectIndexing = {
  [key: string]: any;
};

function SelectTemplate(props: any) {
  const { setActive, smsContent, setSmsContent, smsTemplateList } = props;

  const handleChange = (e: any) => {
    //console.log(`selected ${e.target.value}`);
    //smsTemplateList.data.filter()
    let seletedTempate = { templateId: 0, subject: "", body: "" };
    smsTemplateList.data.filter((data: any) => {
      if (data.rowId == e.target.value) {
        seletedTempate = {
          templateId: data.rowId,
          subject: data.subject,
          body: data.message,
        };
      }
    });
    setSmsContent(seletedTempate);
    //sethandleMode(parseInt(value));
  };

  return (
    <React.Fragment>
      <div className="admins_se">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="col-sm-3">
                <label> Subject</label>
              </div>
              {/* {smsTemplateList.data !=undefined && smsTemplateList.data.map((item: any, index: any) => {
                //console.log(item);
              })
                } */}
              <div className="col-sm-5">
                {/* <span className="select-wrapper-sec"> */}
                {smsTemplateList.data != undefined && (
                  <select
                    name="timepass"
                    className="custom-select-sec"
                    onChange={handleChange}
                    defaultValue="0"
                    style={{ width: 300 }}
                  >
                    <option value="0">Select</option>
                    {smsTemplateList.data.map((item: any, index: any) => {
                      return (
                        <option key={item["rowId"]} value={item["rowId"]}>
                          {item["subject"]}
                        </option>
                      );
                    })}
                  </select>
                )}
                {/* </span>   */}
              </div>
              {/* <div className="button dropdown">
                              <select id="colorselector" >
                                <option value="1">Candidate</option>
                                <option value="2">Vendor</option>
                              </select>
                            </div> */}
            </div>
          </div>
          <div className="clearfix" />
          <div className="col-sm-12">{smsContent.body}</div>
        </div>
        <div className="pull-right">
          <button className="btn next_btn" onClick={() => setActive(2)}>
            Next{" "}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

function EditTemplate(props: any) {
  const {
    setActive,
    smsEditSubject,
    setSmsEditSubject,
    smsEditBody,
    setSmsEditBody,
    toNames,
  } = props;

  return (
    <React.Fragment>
      <div className="admins_se">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="To"
                value={toNames}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                onChange={(e) => setSmsEditSubject(e.target.value)}
                placeholder="Message Body"
                value={smsEditSubject}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <textarea
                className="form-control form-contro12"
                onChange={(e) => setSmsEditBody(e.target.value)}
                placeholder="Type here"
                value={smsEditBody}
              />
            </div>
          </div>
        </div>
        <div className=" pull-left">
          <button className="btn next_btn" onClick={() => setActive(1)}>
            Back{" "}
          </button>
        </div>
        <div className="pull-right">
          <button className="btn next_btn" onClick={() => setActive(3)}>
            Next{" "}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

function PreviewTemplate(props: any) {
  const {
    setActive,
    smsEditSubject,
    smsEditBody,
    toNames,
    handleSendSMS,
  } = props;
  return (
    <React.Fragment>
      <div className="admins_se">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="To"
                value={toNames}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Type here"
                value={smsEditSubject}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <textarea
                className="form-control form-contro12"
                placeholder="Type here"
                value={smsEditBody}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className=" pull-left">
          <button className="btn next_btn" onClick={() => setActive(2)}>
            Back{" "}
          </button>
        </div>
        <div className="pull-right">
          <button className="btn next_btn" onClick={() => handleSendSMS()}>
            Send{" "}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

const BulkSMS = (props: any) => {
  const globalSpinner = useContext(GlobalSpinnerContext);
  const adminCommunicationDispatcher = useAdminCommunicationDispatcher();
  const adminCommunicationContext = useAdminCommunicationContext();
  const { smsTemplateList, bulkSMSRespond } = adminCommunicationContext;

  const {
    handleDisplayModeChange,
    checkedListArray,
    userId,
    JobId,
    JobApplicationViewJobResultModel,
    adminId,
    basicInfo,
  } = props;
  const [active, setActive] = useState(1);
  const [smsContent, setSmsContent] = useState({
    templateId: 0,
    subject: "",
    body: "",
  });
  const [smsEditSubject, setSmsEditSubject] = useState("");
  const [smsEditBody, setSmsEditBody] = useState("");
  let smsSendData: ReactSelectOption[] = [];
  const [smsContacts, setSmsContacts] = useState([] as any[]);
  // //console.log(basicInfo.phoneNumber);
  // //console.log(basicInfo.countryCode);
  // //console.log(userId)
  // //console.log(JobId)
  // //console.log(JobApplicationViewJobResultModel.data)
  const [toNames, setToNames] = useState("");

  React.useEffect(() => {
    if (
      JobApplicationViewJobResultModel.data != undefined &&
      JobApplicationViewJobResultModel.data.length > 0
    ) {
      //setInitialUserId(JobApplicationViewJobResultModel.data[0].userId);
      let allFounded = JobApplicationViewJobResultModel.data.filter(
        (e: any) => {
          if (checkedListArray.includes(e.userId)) {
            //console.log(e.userId);
            return e;
          }
        }
      );
      setSmsContacts(allFounded);
    }
  }, [JobApplicationViewJobResultModel.data]);

  React.useEffect(() => {
    let ToNames = "";
    if (smsContacts != undefined && smsContacts.length > 0) {
      ToNames = "";
      smsContacts.filter((data: any) => {
        //console.log(data);
        ToNames += data.userName + ",";
      });
    }
    setToNames(ToNames);
  }, [smsContacts.length]);

  React.useEffect(() => {
    (async () => {
      await getSMSTemplateList(adminCommunicationDispatcher, {
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      });
    })();
  }, []);

  React.useEffect(() => {
    if (smsContent.templateId > 0) {
      setSmsEditSubject(smsContent.subject);
      setSmsEditBody(smsContent.body);
    } else {
      setSmsEditSubject("");
      setSmsEditBody("");
    }
  }, [smsContent.templateId, smsContent.subject, smsContent.body]);

  const handleSendSMS = () => {
    //console.log("h");
    let trans = [] as MessageTransactionsData[];
    //console.log(smsContacts.length);
    if (smsContacts.length > 0) {
      smsContacts.forEach((item: any, index: any) => {
        const listItem: MessageTransactionsData = {
          MessageFrom: basicInfo.countryCode + basicInfo.phoneNumber,
          MessageTo: item["userCountryCode"] + item["userPhone"],
          MessageStatus: "NA",
        };
        trans.push(listItem);
      });
    }
    //console.log(trans.length);
    if (trans.length > 0) {
      //globalSpinner.showSpinner();
      (async () => {
        globalSpinner.showSpinner();
        await saveBulkSMSConversation(adminCommunicationDispatcher, {
          MessageTypeId: 0,
          MessageType: "Bulk",
          MessageSubject: smsEditSubject,
          MessageBody: smsEditBody,
          MessageTransactions: trans,
          UserId: adminId, //loggedUserId,;
          IsActive: true,
        } as BulkSMSDetailPostModel);
        globalSpinner.hideSpinner();
      })();
    }
  };

  React.useEffect(() => {
    if (bulkSMSRespond != undefined && !_.isEmpty(bulkSMSRespond)) {
      ////console.log(bulkSMSRespond);
      globalSpinner.hideSpinner();
      if (bulkSMSRespond.isSuccess) {
        // call processed api for reports
        let _data: objectIndexing = {};

        _data["AdminId"] = Number(adminId);
        _data["JobId"] = JobId;
        _data["PublishedJobsFlag"] = false;
        _data["ApprovalJobsFlag"] = false;
        _data["ViewedJobsFlag"] = false;
        _data["ProcessedJobsFlag"] = true;
        _data["FlagId"] = Number(4);
        _data["IsActive"] = true;
        saveAdminActions(_data).then((res: any) => {
          //console.log(res);
          if (res.data.isSuccess) {
            globalSpinner.hideSpinner();
            toast.success("SMS sent successfully.");
            setActive(1);
          } else {
            globalSpinner.hideSpinner();
            toast.error("Error:SMS not sent.");
          }
        });
      } else {
        globalSpinner.hideSpinner();
        toast.error("Error:SMS not sent.");
      }
    }
  }, [bulkSMSRespond]);

  const goToPreviousPath = () => {
    handleDisplayModeChange(0);
  };

  return (
    <>
      <ToastContainer />
      {/*=================Content Section Start================*/}
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Published</h1>
          <a onClick={goToPreviousPath} className="btn cutomise_but">
            <i className="fa fa-angle-left" aria-hidden="true" /> &nbsp; Back{" "}
          </a>
          <div className="clearfix" />
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="section_box4">
                <h1>SMS</h1>
                <div className="clearfix" />
                <div className="row">
                  <div className="col-sm-12 col-lg-12 ">
                    <div className="ProgCheck">
                      <ol className="breadcrumb">
                        <li className={active == 1 ? "active" : ""}>
                          {/* <span className="sr-only">Current step:</span> */}
                          Select Template{" "}
                        </li>
                        <li className={active == 2 ? "active" : ""}>
                          {" "}
                          Edit Template{" "}
                        </li>
                        <li className={active == 3 ? "active" : ""}>
                          {" "}
                          Preview Template{" "}
                        </li>
                      </ol>
                    </div>

                    {active == 1 && smsTemplateList != undefined && (
                      <SelectTemplate
                        setActive={setActive}
                        smsContent={smsContent}
                        setSmsContent={setSmsContent}
                        smsContacts={smsContacts}
                        smsTemplateList={smsTemplateList}
                      />
                    )}
                    {active == 2 && (
                      <EditTemplate
                        setActive={setActive}
                        smsEditSubject={smsEditSubject}
                        setSmsEditSubject={setSmsEditSubject}
                        smsEditBody={smsEditBody}
                        setSmsEditBody={setSmsEditBody}
                        toNames={toNames}
                      />
                    )}
                    {active == 3 && (
                      <PreviewTemplate
                        setActive={setActive}
                        smsEditSubject={smsEditSubject}
                        smsEditBody={smsEditBody}
                        // smsContacts={smsContacts}
                        toNames={toNames}
                        handleSendSMS={handleSendSMS}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      {/*=================Content Section End================*/}
    </>
  );
};

export default BulkSMS;
