import React, { useState, useContext } from "react";
import { environment } from "../../../../environments/environment";
import { GlobalSpinnerContext } from "../../../../context/GlobalSpinner";
import { getCoverLetterTemplates } from "../../../../apis/resumebuilder";

function Template(props: any) {
  const {
    setCoverLetterSelectTemplateComponentState,
    coverLetterSelectTemplateComponentState,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(false);
  const currentPageSize = 4;
  const [totalPage, setTotalPage] = useState(1);
  const [coverLetterTemplates, setCoverLetterTemplates] = useState<any>([]);
  const globalSpinner = useContext(GlobalSpinnerContext);

  const handleCoverLetterTemplateClick = (item: any) => {
    setCoverLetterSelectTemplateComponentState({
      rowId: item.rowId,
      coverLetterTemplateId: item.coverLetterTemplateId,
      title: item.title,
      coverLetterTemplateContent: item.coverLetterTemplateContent,
      coverLetterTemplateImage:
        environment.baseUrl + "/Upload/CoverLetterImage/" +
        item.coverLetterTemplateImage,
      description: item.description,
    });
  };

  React.useEffect(() => {
    if (currentPage == 1 && previousPage == false) {
      getCoverLetterTemplatesData(currentPage, currentPageSize);
    }

  }, [currentPage]);

  const fetchNextData = async () => {
    if (currentPage >= totalPage) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
      getCoverLetterTemplatesData(currentPage + 1, currentPageSize);
    }
  };

  const fetchPreviousData = async () => {
    if (currentPage <= 1) {
      return;
    } else {
      if ((currentPage - 1) == 1) {
        setPreviousPage(true)
      }
      setCurrentPage(currentPage - 1);
      getCoverLetterTemplatesData(currentPage - 1, currentPageSize);
    }
  };


  const getCoverLetterTemplatesData = (getPage: any, getPageSize: any) => {
    globalSpinner.showSpinner();
    getCoverLetterTemplates({
      "CandidateId": 0,
      "PageIndex": getPage,
      "PageSize": getPageSize,
      "ShowInactive": false
    }).then((res: any) => {
      globalSpinner.hideSpinner();
      if (getPage == 1) {
        setTotalPage(Math.ceil(res.data.total / currentPageSize));
      }
      setCoverLetterTemplates(res.data.data);
    }).catch((err: any) => {
      globalSpinner.hideSpinner();
    });
  }

  const handleCarouselNextClick = () => {
    //call api()
    fetchNextData()
  };

  const handleCarouselPreviousClick = () => {
    fetchPreviousData();
  };

  return (
    <React.Fragment>
      {/*=================Content Section Start================*/}
      <div className="col-sm-4">
        <div>
          <img
            src={
              coverLetterSelectTemplateComponentState.coverLetterTemplateImage
            }
            className="img-responsive"
          />
        </div>
        <div>
          <img
            src={require("./../../../../images/shadow.jpg")}
            className="img-responsive"
          />
        </div>
      </div>
      <div className="col-sm-8">
        <div className="section_box4">
          <div className="select_templates">
            <div className="row">
              <div className="prodict_list">
                <div
                  id="owl-demo1"
                  className="owl-carousel owl-theme"
                  style={{ opacity: 1, display: "block" }}
                >
                  <div className="owl-wrapper-outer">
                    <div
                      className="owl-wrapper"
                      style={{
                        width: "2568px",
                        left: "0px",
                        display: "block",
                        transition: "all 0ms ease 0s",
                        // transform: `translate3d(${carouselValue}px, 0px, 0px)`,
                        transform: `translate3d(0px, 0px, 0px)`,
                      }}
                    >
                      {coverLetterTemplates != undefined &&
                        coverLetterTemplates.map((e: any, i: any) => (
                          <div className="owl-item" style={{ width: "200px" }}>
                            <div className="item">
                              <div className="matched_jobs_sec">
                                <div
                                  className="matched_jobs"
                                  onClick={() =>
                                    handleCoverLetterTemplateClick(e)
                                  }
                                >
                                  <img
                                    src={`${environment.baseUrl + "/Upload/CoverLetterImage/" + e.coverLetterTemplateImage}`}
                                    alt=""
                                    className="image2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div
                    className="owl-controls clickable"
                    style={{ display: "none" }}
                  >
                    <div className="owl-pagination">
                      <div className="owl-page active">
                        <span className="" />
                      </div>
                    </div>
                    <div className="owl-buttons">
                      <div
                        className="owl-prev"
                        onClick={handleCarouselPreviousClick}
                      >
                        <img
                          src={require("../../../../images/products_ar1.png")}
                        />
                      </div>
                      <div
                        className="owl-next"
                        onClick={handleCarouselNextClick}
                      >
                        <img
                          src={require("../../../../images/products_ar2.png")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*=================Content Section End================*/}
    </React.Fragment>
  );
}

export default Template;
