import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import SelectOption from "./components/SelectOption";
import PikadayDatepicker from "./components/PikadayDatepicker";
import { Modal } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import {
  getEducationQualificationSelectBoxData,
  saveEducationQualification,
  deleteEducationQualification,
  saveDigiDocumentDetails,
  saveDigiDocumentUpload,
  getOneDigiDocumentDetails,
} from "./../../../apis/candidate";
import { defaultProps } from "react-select/src/Select";
import _ from "lodash";
import moment from "moment";
import { confirmWrapper, confirm } from "./../../GlobalConfirm";
import { ToastContainer, toast } from "react-toastify";
import { GlobalSpinnerContext } from "./../../../context/GlobalSpinner";
import AttachDocument from "./../AttachDocument3";
import IsComponentDisabled from "./../../components/IsComponentDisabled";
import { Popconfirm, Tooltip } from "antd";

type objectIndexing = {
  [key: string]: any;
};

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IQualificationState {
  degree: string;
  university: string;
  //year: number;
  year: string;
}

function RenderQalification(props: any) {
  const {
    disabled,
    item,
    index,
    educationQualificationSelectBoxData,
    removeQualification,
    updateQualification,
    digiLockerType,
    candidateId,
    getEducationQualifications,
    digiLockers,
  } = props;

  const defaultValues = {
    degree: item.course + "",
    university: item.university,
    //year: moment(item["dateTo"], "DD/MM/YYYY").toDate().getTime(),
    year: item.qualificationYear,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<IQualificationState>({
    defaultValues,
  });

  const [
    oneQualificationDocument,
    setOneQualificationDocument,
  ] = useState<objectIndexing>({});

  useEffect(() => {
    if (
      item["digiDocumentDetailId"] != null &&
      item["digiDocumentDetailId"] > 0
    ) {
      getOneDigiDocumentDetails({
        rowId: Number(item["digiDocumentDetailId"]),
      }).then((res) => {
        //console.log(res);
        if (res.data.isSuccess == true && res.data.data != null) {
          setOneQualificationDocument(res.data);
        }
      });
    }
  }, [item.digiDocumentDetailId]);

  const onSubmit = (data: any) => {
    //console.log(data);
    //console.log(item);
    const _data = Object.assign(item, {
      course: data["degree"],
      university: data["university"],
      //dateTo: new Date(data["year"]).toISOString(),
      QualificationYear: data["year"],
    });
    updateQualification(_data);
  };

  let courses: ReactSelectOption[] = [];

  if (!_.isEmpty(educationQualificationSelectBoxData)) {
    courses = educationQualificationSelectBoxData.courses.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });

    // reset({
    //   degree: courseId + "",
    //   university: candidateState.data[0]["firstName"],
    //   year: moment(candidateState.data[0]["dob"], "DD/MM/YYYY").toDate().getTime()
    // });
  }

  const globalSpinner = useContext(GlobalSpinnerContext);

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const onSave = (data: any) => {
    //console.log(data);

    globalSpinner.showSpinner();

    if ("fromLocker" in data) {
      const e = Object.assign({}, item, {
        digiDocumentDetailId: data.digiDocumentDetailId,
      });

      saveEducationQualification(e)
        .then((res) => {
          globalSpinner.hideSpinner();
          if (res.data.isSuccess) {
            getEducationQualifications({
              CandidateId: Number(candidateId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "",
              SortOrder: "",
              ShowInactive: false,
            });
            toast.success("Qualification certificates uploaded.");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          globalSpinner.hideSpinner();
          toast.error(err.message);
        });
    } else {
      saveDigiDocumentDetails({
        RowId: 0,
        DigiDocumentDetailId: 0,
        Name: data["docName"],
        DocumentNumber: data["docNumber"],
        Description: "string",
        CandidateId: Number(candidateId),
        DigiDocumentTypeId: Number(data["docType"]),
        ExpiryDate: new Date(data["docExpiry"]).toISOString(),
        ExpiryFlag: data["docRadio"] == "yes",
        IsActive: true,
      })
        .then((res: any) => {
          //console.log(res.data);
          if (res.data.isSuccess) {
            const formData = new FormData();
            // formData.append("RowId", 0);
            // formData.append("DigiDocumentUploadId", 0);
            formData.append("DigiDocumentDetailId", res.data.entityId);
            formData.append("DigiDocument", "string");
            formData.append("Document", data["docFile"]);
            formData.append("IsActive", "true");

            saveDigiDocumentUpload(formData)
              .then((res1: any) => {
                if (res1.data.isSuccess) {
                  const e = Object.assign({}, item, {
                    digiDocumentDetailId: res.data.entityId,
                  });

                  saveEducationQualification(e)
                    .then((res) => {
                      globalSpinner.hideSpinner();
                      if (res.data.isSuccess) {
                        getEducationQualifications({
                          CandidateId: Number(candidateId),
                          Page: 1,
                          PageSize: 10,
                          SearchTerm: "",
                          SortOrder: "",
                          ShowInactive: false,
                        });
                        toast.success("Qualification certificates uploaded.");
                      } else {
                        toast.error(res.data.message);
                      }
                    })
                    .catch((err) => {
                      globalSpinner.hideSpinner();
                      toast.error(err.message);
                    });
                } else {
                  globalSpinner.hideSpinner();
                  toast.error(res.data.message);
                }
              })
              .catch((err: any) => {
                globalSpinner.hideSpinner();
                toast.error("Something went wrong.");
              });
          } else {
            globalSpinner.hideSpinner();
            toast.error(res.data.message);
          }
        })
        .catch((err: any) => {
          globalSpinner.hideSpinner();
          toast.error("Something went wrong.");
        });
    }
  };

  return (
    <>
      <div className=" profile_sec_bg">
        <div
          className="close_icon1 _cursor-pointer"
          onClick={() => {
            removeQualification(item, index);
          }}
        >
          <img
            src={require("./../../../images/close_icon.png")}
            width={16}
            height={16}
          />
        </div>
        <div className="row">
          <form
            className="personal_details"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Qualification Name
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="degree"
                    className={"form-control"}
                    placeholder="Type here"
                    disabled={disabled}
                    ref={register({
                      required: "Qualification Name is required",
                    })}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="degree"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
                {/* <span className="select-wrapper-sec">
                <Controller
                  control={control}
                  name="degree"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption values={courses} disabled={disabled} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                />
              </span> */}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  University/Board/Institution
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="university"
                    className={"form-control"}
                    placeholder="Type here"
                    disabled={disabled}
                    ref={register({
                      required: "University/Board/Institution is required",
                    })}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="university"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Passing Year
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="year"
                    ref={register({
                      required: "Year is required",
                      maxLength: {
                        value: 4,
                        message: "Please provide a valid year",
                      },
                      minLength: {
                        value: 4,
                        message: "Please provide a valid year",
                      },
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Year should be numbers only",
                      },
                      validate: (value) =>
                        parseInt(value) > moment().year()
                          ? "Passing year cannot be a future year"
                          : undefined,
                    })}
                    disabled={disabled}
                    className={"form-control form-control-n"}
                    placeholder="Type here"
                  />,
                  disabled
                )}
                {/* {IsComponentDisabled(
                  <Controller
                    rules={{
                      validate: (value) => {
                        if (value <= 0) {
                          return "Required passing year.";
                        } else if (value > Date.now()) {
                          return "Passing year cannot be a future date.";
                        } else {
                          return undefined;
                        }
                        // return value < Date.now() ? undefined : "Passing year cannot be a future date.";
                      },
                    }}
                    control={control}
                    name="year"
                    render={({ onChange, onBlur, value, name }) => {
                      //console.log(value);
                      return (
                        <PikadayDatepicker
                          disabled={disabled}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      );
                    }}
                  />,
                  disabled
                )} */}
                <ErrorMessage
                  errors={errors}
                  name="year"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
                {/* <span className="select-wrapper-sec">
                <Controller
                  control={control}
                  name="year"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption values={[]} disabled={disabled} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                />
              </span> */}
              </div>
            </div>
            <div className="col-sm-4">
              {/* <button className="btn submit_btn" type="button" disabled={disabled} onClick={() => { setIsOpen2(!isOpen2) }}>Attach Document</button> */}
              {_.isEmpty(oneQualificationDocument) != true &&
                item["digiDocumentDetailId"] > 0 && (
                  <Popconfirm
                    title={
                      <div>
                        <p>
                          You already have a document attached to this record.
                        </p>
                        <p>
                          {" "}
                          Name:{""} {`${oneQualificationDocument.data.name} `}
                        </p>
                        {/* <br /> */}
                        <p>
                          {" "}
                          Last Modified:{" "}
                          {`${oneQualificationDocument.data.updatedDate}`}
                        </p>
                        <p> Do you want to update the above document?</p>
                      </div>
                    }
                    onConfirm={() => setIsOpen2(!isOpen2)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      type="button"
                      className="btn submit_btn"
                      disabled={disabled}
                    >
                      Attach Document
                    </button>
                    <a className="btn submit_btn" style={{ display: "none" }}>
                      Attach Document
                    </a>
                  </Popconfirm>
                )}
              {(_.isEmpty(oneQualificationDocument) == true ||
                item["digiDocumentDetailId"] == null ||
                item["digiDocumentDetailId"] < 1) && (
                <button
                  onClick={() => {
                    setIsOpen2(!isOpen2);
                  }}
                  type="button"
                  className="btn submit_btn"
                  disabled={disabled}
                >
                  Attach Document
                </button>
              )}
              <button
                type="submit"
                className="btn submit_btn"
                disabled={disabled}
              >
                Save
              </button>
            </div>
            <div className="col-sm-8">
              {_.isEmpty(oneQualificationDocument) != true &&
                item["digiDocumentDetailId"] > 0 && (
                  <label className="m_t_20">Document attached </label>
                )}
            </div>
            {/* <div className="col-sm-12">
                <a onClick={() => { setIsOpen(!isOpen) }} className="btn submit_btn">Add Qalification</a>
              </div> */}
          </form>
        </div>
      </div>
      <Modal
        show={isOpen2}
        onHide={() => {
          setIsOpen2(!isOpen2);
        }}
        dialogClassName="_attach-training"
      >
        <AttachDocument
          digiLockerType={digiLockerType}
          setIsOpen2={setIsOpen2}
          isOpen2={isOpen2}
          candidateId={candidateId}
          onSave={onSave}
          digiLockers={digiLockers}
          docNameProp={watch("degree")}
          docTypeProp={2}
          docNumberProp={""}
          docExpiryProp={0}
          docRadioProp={"no"}
        />
      </Modal>
    </>
  );
}

function Qualification(props: any) {
  const {
    userId,
    candidateId,
    educationQualificationState,
    getEducationQualifications,
    digiLockerType,
    digiLockers,
  } = props;

  //console.log(educationQualificationState);

  const [disabled, setDisabled] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const [
    educationQualificationSelectBoxData,
    setEducationQualificationSelectBoxData,
  ] = useState({});

  useEffect(() => {
    getEducationQualificationSelectBoxData()
      .then((res) => {
        //console.log(res.data);
        setEducationQualificationSelectBoxData(res.data);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const removeQualification = async (data: any) => {
    //console.log(data);

    if (
      await confirm({
        confirmation: "Are you sure you want to delete this?",
        options: {
          yes: "Yes",
          no: "No",
          header: "Delete",
        },
      })
    ) {
      //console.log("yes");
      //console.log(data);
      globalSpinner.showSpinner();
      deleteEducationQualification({ rowId: data.rowId })
        .then((res) => {
          globalSpinner.hideSpinner();
          if (res.data.isSuccess) {
            getEducationQualifications({
              CandidateId: Number(candidateId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "",
              SortOrder: "",
              ShowInactive: false,
            });
            toast.success("Qualification details are removed.");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          globalSpinner.hideSpinner();
          toast.error(err.message);
        });
    } else {
      //console.log("no");
      //console.log(data);
    }
  };

  const updateQualification = async (data: any) => {
    //console.log(data);
    globalSpinner.showSpinner();
    saveEducationQualification(data)
      .then((res) => {
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          getEducationQualifications({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
            ShowInactive: false,
          });
          toast.success("Qualification details are updated.");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        globalSpinner.hideSpinner();
        toast.error(err.message);
      });
  };

  let courses: ReactSelectOption[] = [];

  if (!_.isEmpty(educationQualificationSelectBoxData)) {
    courses = (educationQualificationSelectBoxData as any).courses.map(
      (e: any) => {
        return { value: e["value"], label: e["caption"] };
      }
    );
  }

  const defaultValues = {
    degree: "",
    university: "",
    year: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<IQualificationState>({
    defaultValues,
  });

  useEffect(() => {
    if (watch("pursuing") !== undefined && watch("pursuing") === true) {
      setValue("year", "");
    }
  }, [watch("pursuing")]);

  const onSubmit = (data: any) => {
    //console.log(data);

    globalSpinner.showSpinner();
    saveEducationQualification({
      RowId: 0,
      EducationQualificationId: 0,
      CandidateId: Number(candidateId),
      Course: data["degree"],
      University: data["university"],
      Grade: "string",
      // DateFrom: "2020-11-02T16:31:42.544Z",
      // DateTo: new Date(data["year"]).toISOString(),
      QualificationYear: data["year"],
      IsActive: true,
      IsPursuingStudy: data["pursuing"],
    })
      .then((res) => {
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          getEducationQualifications({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
            ShowInactive: false,
          });
          setIsOpen(!isOpen);
          toast.success("Qualification details are saved.");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        globalSpinner.hideSpinner();
        toast.error(err.message);
      });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-3">
        <span className="resp-arrow" />
        Qualification
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-3"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Qualification</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>

        <div>
          <a
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="btn add_btn"
            style={{ marginBottom: "10px" }}
          >
            Add Qualification
          </a>
        </div>

        {educationQualificationState.data.map((e: any, i: any) => (
          <RenderQalification
            key={i}
            item={e}
            index={i}
            disabled={disabled}
            digiLockerType={digiLockerType}
            candidateId={candidateId}
            educationQualificationSelectBoxData={
              educationQualificationSelectBoxData
            }
            removeQualification={removeQualification}
            updateQualification={updateQualification}
            getEducationQualifications={getEducationQualifications}
            digiLockers={digiLockers}
          />
        ))}

        {/* <div className="col-sm-12">
          <a onClick={() => { setIsOpen(!isOpen) }} className="btn submit_btn">Add Qalification</a>
        </div> */}
      </div>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Add Qualification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Qualification Name
              </label>
              <input
                type="text"
                name="degree"
                className="form-control"
                placeholder="Type here"
                ref={register({
                  required: "Qualification Name is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="degree"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
              {/* <span className="select-wrapper-sec">
                <Controller
                  control={control}
                  name="degree"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption values={courses} disabled={false} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                />
              </span> */}
            </div>
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                University/Board/Institution
              </label>
              <input
                type="text"
                name="university"
                disabled={false}
                className="form-control"
                placeholder="Type here"
                ref={register({
                  required: "University/Board/Institution is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="university"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Passing Year
              </label>

              <input
                type="text"
                name="year"
                ref={register({
                  required: watch("pursuing") ? false : "Year is required",
                  maxLength: {
                    value: 4,
                    message: "Please provide a valid year",
                  },
                  minLength: {
                    value: 4,
                    message: "Please provide a valid year",
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Year should be numbers only",
                  },
                  validate: (value) =>
                    parseInt(value) > moment().year()
                      ? "Passing year cannot be a future year"
                      : undefined,
                })}
                className={"form-control form-control-n"}
                placeholder="Type here"
                disabled={
                  watch("pursuing") !== undefined
                    ? watch("pursuing")
                    : watch("pursuing")
                }
              />

              {/* <Controller
                rules={{
                  validate: (value) => {
                    if (value <= 0) {
                      return "Required passing year.";
                    } else if (value > Date.now()) {
                      return "Passing year cannot be a future date.";
                    } else {
                      return undefined;
                    }
                    // return value < Date.now() ? undefined : "DOB cannot be a future date.";
                  },
                }}
                control={control}
                name="year"
                render={({ onChange, onBlur, value, name }) => {
                  //console.log(value);
                  return (
                    <PikadayDatepicker
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  );
                }}
              /> */}
              <ErrorMessage
                errors={errors}
                name="year"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
              {/* <span className="select-wrapper-sec">
                <Controller
                  control={control}
                  name="year"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption values={[]} disabled={false} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                />
              </span> */}
            </div>

            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Pursuing
              </label>

              <input
                id="checkbox1"
                type="checkbox"
                disabled={false}
                name="pursuing"
                ref={register}
                className="form-control"
                style={{
                  height: 18,
                  opacity: "inherit",
                  marginTop: -31,
                  marginLeft: -237,
                }}
                // className={disabled ? "_cursor-no-drop" : "_cursor-pointer"}
              />
              <ErrorMessage
                errors={errors}
                name="pursing"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="clearfix" />
        </form>
      </Modal>
    </React.Fragment>
  );
}

export default Qualification;
