import React, {
  useState
  //, useContext
  , useEffect
} from "react";
import {
  useForm
  //, Controller 
} from "react-hook-form";

// import { BrowserRouter } from "react-router-dom";
// import IsComponentDisabled from "../../../components/IsComponentDisabled";

import { ErrorMessage } from "@hookform/error-message";
import { //Link,
  useHistory
  //, withRouter 
} from "react-router-dom";


interface ICoverLetterEditTemplateComponentProps {

  candidateId: Number
  coverLetterTemplateID: Number
  coverLetterTemplateHtml: string
  coverLetterDescription: string
}

interface ICoverLetterEditTemplateComponentState {
  description: string;
  title: string
}

const defaultValues = {
  description: "",
  title: "Dear Manager"
};

const CoverLetterEditTemplateComponent: React.FC<ICoverLetterEditTemplateComponentProps> = (props) => {

  let history = useHistory()
  const [disabled, setDisabled] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    //watch,
    errors,
    setValue,
    //getValues,
    //control,
    //reset,
  } = useForm<ICoverLetterEditTemplateComponentState>({
    defaultValues,
  });

  useEffect(() => {
    if (props.candidateId) {
      setValue("description", props.coverLetterDescription);
    }
  }, [props.candidateId, props.coverLetterTemplateID]);

  const onSubmit = (data: any) => {
    if (!disabled) {
      history.push({
        pathname: '/candidate/my-resume/cover-letter/Preview_Cover',
        state: {
          coverLetterTemplateID: props.coverLetterTemplateID,
          coverLetterDescription: data["description"],
          coverLetterTemplateHtml: props.coverLetterTemplateHtml,
          coverLetterTitle: data["title"]
        }
      });
    }
  }




  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Cover Letter</h1>
          {/* <div className="profile_edit">
            <a onClick={() => {
              setDisabled(!disabled);
            }}>
              <i className="fa fa-pencil" aria-hidden="true" />
            </a>
          </div> */}
          <div className="clearfix" />
          <div className="section_box4">
            <form className="personal_details" onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="row">

                <div className="col-sm-4">
                  <div className="form-group">
                    <label> Title</label>

                    <input
                      type="text"
                      name="title"
                      className={"form-control"}
                      placeholder="Type here"
                      disabled={disabled}
                      ref={register({
                        required: "title is required.",
                      }
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="title"
                      render={({ message }) => (
                        <div className="profile_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label> Description</label>

                    <textarea
                      name="description"
                      style={{ height: '500px' }}
                      className={"form-control form-contro11"}
                      placeholder="Type here"
                      disabled={disabled}
                      ref={register({
                        required: "Description is required.",
                      }
                      )}

                    />
                    <ErrorMessage
                      errors={errors}
                      name="description"
                      render={({ message }) => (
                        <div className="profile_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <button
                type="submit"
                className="btn submit_btn"
                disabled={disabled}
              >
                Preview
            </button>
            </form>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </>
  );
};
export default CoverLetterEditTemplateComponent;
