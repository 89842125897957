import React, { useEffect } from "react";
import { useSocialAccountContext } from "../../../action/client/ClientProfileAction";

import AuthService from "../../../services/AuthService";
import AdminProfileContainer from "../../admin/my_profile/AdminProfileContainer";
import ClientProfileComponent from "./ClientProfileComponent";

const ClientProfileContainer = () => {
  const socialAccountContext = useSocialAccountContext();
  const { socialAccounts, getSocialAccounts } = socialAccountContext;
  let user = AuthService.currentUser;
  const authorizationToken = AuthService.accessToken;

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != undefined) {
      getSocialAccounts({
        ClientId: 0,
        UserId: Number(user.id),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [user?.id]);

  return (
    <>
      <ClientProfileComponent
        socialAccounts={socialAccounts}
        getSocialAccounts={getSocialAccounts}
      />
    </>
  );
};

export default ClientProfileContainer;
