import React, { useState, useEffect } from "react";
import CoverLetterEditTemplateComponent from "./CoverLetterEditTemplateComponent";
import { useMyProfileContext } from "../../../../action/MyProfileAction";

interface ICoverLetterEditTemplateContainerProps {
  selectTemplateInfo: any;
}

//interface ICoverLetterEditTemplateContainerState { }

//const initialState = {};

const CoverLetterEditTemplateContainer: React.FC<ICoverLetterEditTemplateContainerProps> = (
  props
) => {
  // const [coverLetterEditTemplateContainerState, setCoverLetterEditTemplateContainerState] = React.useState<ICoverLetterEditTemplateContainerState>(
  //   initialState
  // );

  const myProfileContext = useMyProfileContext();
  const { loggedUserId } = myProfileContext;
  const candidateId = loggedUserId;
  const [coverLetterTemplateID, setCoverLetterTemplateID] = useState(0);
  const [coverLetterTemplateHtml, setCoverLetterTemplateHtml] = useState(
    "coverletter1.html"
  );
  const [coverLetterDescription, setcoverLetterDescription] = useState("");

  useEffect(() => {
    ////console.log(props);
    if (candidateId && props.selectTemplateInfo.location.state != undefined) {
      setCoverLetterTemplateID(
        props.selectTemplateInfo.location.state.templateInfo.rowId
      );
      setCoverLetterTemplateHtml(
        props.selectTemplateInfo.location.state.templateInfo
          .coverLetterTemplateContent
      );
      setcoverLetterDescription(
        props.selectTemplateInfo.location.state.templateInfo.description
      );
    }
  }, [candidateId]);

  return (
    <>
      <CoverLetterEditTemplateComponent
        candidateId={candidateId}
        coverLetterTemplateID={coverLetterTemplateID}
        coverLetterTemplateHtml={coverLetterTemplateHtml}
        coverLetterDescription={coverLetterDescription}
      />
    </>
  );
};
export default CoverLetterEditTemplateContainer;
