// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../images/cover_land.png");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../images/cover_phone.png");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../images/cover_mail.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div style=\"padding:30px 30px 0 30px\"> <table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\"> <tr> <td class=\"coverletter_l\" valign=\"top\"> <div> <% if(profileImageInfo[0]) { %> <div><img src=\"<%=profileImage %> \" class=\"resume_img center-block\"></div> <%}%> <div class=\"coverletter_name\"> <%= candidateInfo[0].firstName %> <%= candidateInfo[0].lastName %> </div> <div class=\"coverletter_des\"> <%= candidateInfo[0].positionApplyingFor %> </div> <div> <% if(candidateInfo[0].currentAddress1) { %> <div class=\"coverletter_con\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"></div> <div class=\"coverletter_con1\"> <%= candidateInfo[0].currentAddress1 %> </div> <div class=\"clearfix\"></div> <%}%> <% if(candidateInfo[0].phoneNumber) { %> <div class=\"coverletter_con\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"></div> <div class=\"coverletter_con1\"> <%= candidateInfo[0].countryCode%> <%= candidateInfo[0].phoneNumber %> </div> <div class=\"clearfix\"></div> <%}%> <% if(candidateInfo[0].email) { %> <div class=\"coverletter_con\"><img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"></div> <div class=\"coverletter_con1\"> <%= candidateInfo[0].email %> </div> <%}%> </div> </div> </td> <td class=\"coverletter_r\" valign=\"top\"> <div class=\"resume_right_sec\"> <div class=\"coverletter_r_head\"> <%= moment().format(\"MMMM DD, gggg\") %> </div> <div class=\"coverletter_r_head1\"> <%= title %> </div> <div class=\"coverletter_r_con\" style=\"white-space:pre-wrap!important\"><%= content %></div> <div class=\"clearfix\"></div> <div class=\"coverletter_regards\">Thanks</div> <div class=\"coverletter_regards1\"> <%= candidateInfo[0].firstName %> <%= candidateInfo[0].lastName %> </div> </div> </td> </tr> </table> <div class=\"resume_build_2l\"> </div> </div>";
// Exports
module.exports = code;