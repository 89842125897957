import { Dispatch, Reducer } from "react";

import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";

import { AppUrls } from "../../environments/environment";
import {
  ClientReportResultModel,
  VendorReportRequestModel,
  VendorReportResultModel,
  VendorTotalReportResultModel,
} from "../../models/admin/VendorReport";
import {
  IVendorReportManagementState,
  VendorReportDispatcherContext,
  VendorReportStateContext,
} from "../../context/admin/VendorReportContext";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IVendorReportAction =
  | {
      type: "LIST_ALL_VENDOR_REPORT";
      vendorReportsList: VendorReportResultModel;
    }
  | {
      type: "GET_ALL_TOTAL_COUNT";
      totalReportList: VendorTotalReportResultModel;
    }
  | {
      type: "LIST_ALL_CLIENT_REPORT";
      clientReportsList: ClientReportResultModel;
    };

export const VendorReportReducer: Reducer<
  IVendorReportManagementState,
  IVendorReportAction
> = (draft, action): IVendorReportManagementState => {
  switch (action.type) {
    case "LIST_ALL_VENDOR_REPORT":
      draft.vendorReportsList = action.vendorReportsList;
      return draft;
    case "GET_ALL_TOTAL_COUNT":
      draft.totalReportList = action.totalReportList;
      return draft;
    case "LIST_ALL_CLIENT_REPORT":
      draft.clientReportsList = action.clientReportsList;
      return draft;
  }
};

export const useVendorReportDispatcher = (): React.Dispatch<IVendorReportAction> => {
  const vendorReportDispatcher = React.useContext(
    VendorReportDispatcherContext
  );
  if (!vendorReportDispatcher) {
    throw new Error(
      "You have to provide the VendorReport dispatcher using theVendorReportDispatcherContext.Provider in a parent component."
    );
  }
  return vendorReportDispatcher;
};

export const useVendorReportContext = (): IVendorReportManagementState => {
  const vendorReportContext = React.useContext(VendorReportStateContext);
  if (!vendorReportContext)
    throw new Error(
      "You have to provide the vendorReport context using the VendorReportStateContext.Provider in a parent component."
    );
  return vendorReportContext;
};

export const getAllVendorListInReport = async (
  dispatcher: React.Dispatch<IVendorReportAction>,
  query: VendorReportRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllVendorReport;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "LIST_ALL_VENDOR_REPORT",
        vendorReportsList: res.data,
      });
    });
  } catch (e) {}
};

export const getAllTotalReportCount = async (
  dispatcher: React.Dispatch<IVendorReportAction>,
  query: VendorReportRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetVendorTotalCountReport;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_TOTAL_COUNT",
        totalReportList: res.data,
      });
    });
  } catch (e) {}
};

// clientReport

export const getAllTotalReportCountForClient = async (
  dispatcher: React.Dispatch<IVendorReportAction>,
  query: VendorReportRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetClientTotalCountReport;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_TOTAL_COUNT",
        totalReportList: res.data,
      });
    });
  } catch (e) {}
};

export const getAllClientListInReport = async (
  dispatcher: React.Dispatch<IVendorReportAction>,
  query: VendorReportRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllClientReport;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "LIST_ALL_CLIENT_REPORT",
        clientReportsList: res.data,
      });
    });
  } catch (e) {}
};
