import React from "react";
import Template from "./Template";
import { useHistory } from "react-router-dom";
interface ICoverLetterSelectTemplateComponentProps { }

interface ICoverLetterSelectTemplateComponentState {

  rowId: number;
  coverLetterTemplateId: number;
  title: string;
  coverLetterTemplateContent: string;
  coverLetterTemplateImage: string;
  description: string;

}

const defaultValues = {
  rowId: 0,
  coverLetterTemplateId: 0,
  title: '',
  coverLetterTemplateContent: '',
  coverLetterTemplateImage: require("./../../../../images/cover_letter.jpg"),
  description: ''
};


const CoverLetterSelectTemplateComponent: React.FC<ICoverLetterSelectTemplateComponentProps> = (props) => {
  const [coverLetterSelectTemplateComponentState, setCoverLetterSelectTemplateComponentState] = React.useState<ICoverLetterSelectTemplateComponentState>(
    defaultValues
  );
  let history = useHistory()
  const handleEditCoverLetter = () => {
    history.push({
      pathname: '/candidate/my-resume/cover-letter/Edit_Template',
      state: { templateInfo: coverLetterSelectTemplateComponentState }
    });
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Select your Cover Letter</h1>
          <div className="clearfix" />

          <div className="row">
            <Template
              setCoverLetterSelectTemplateComponentState={setCoverLetterSelectTemplateComponentState}
              coverLetterSelectTemplateComponentState={coverLetterSelectTemplateComponentState}
            ></Template>

          </div>
          <div className="row">
            <div className="col-sm-12">
              <button className="btn continue_but"
                disabled={coverLetterSelectTemplateComponentState.rowId == 0 ? true : false}
                onClick={() => { handleEditCoverLetter(); }}>Next</button>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </>
  );
};
export default CoverLetterSelectTemplateComponent;
