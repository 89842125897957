import React from "react";
import IndexContentComponent from "./IndexContentComponent";

interface IIndexContentContainerProps {}

interface IIndexContentContainerState {}

const initialState = {};

const IndexContentContainer: React.FC<IIndexContentContainerProps> = (
  props
) => {
  const [
    IndexContentContainerState,
    setIndexContentContainerState,
  ] = React.useState<IIndexContentContainerState>(initialState);

  return (
    <>
      <IndexContentComponent />
    </>
  );
};
export default IndexContentContainer;
