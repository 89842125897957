import React, {
  useState,
  //, useContext, useEffect
} from "react";
//import moment from "moment";
import {
  deleteCoverLetterCandidateMap,
  getAllCoverLetterCandidateMap,
} from "../../../../apis/resumebuilder";
import { toast, ToastContainer } from "react-toastify";
import { useGlobalSpinnerContext } from "../../../../context/GlobalSpinner";
import { useHistory } from "react-router-dom";
import {
  Link,
  //, withRouter, useRouteMatch
} from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { useMyProfileContext } from "../../../../action/MyProfileAction";

import {
  // EmailIcon,
  EmailShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import { Modal } from "react-bootstrap";
import {
  Popconfirm,
  //, Popover
  Tooltip,
} from "antd";
import { environment } from "../../../../environments/environment";

function CandidateCoverLetters(props: any) {
  // const [countNext, setCountNext] = useState(0);
  // const [carouselValue, setCarouselValue] = useState(0);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [coverLetterShare, setCoverLetterShare] = useState("");
  const { pageSize } = props;
  const [candidateCoverLetters, setCandidateCoverLetters] = useState<any>([]);

  const myProfileContext = useMyProfileContext();
  // const {
  // //   basicInfo,
  //    loggedUserId
  // //   myProfile,
  // //   myProfileProgressBar,
  // //   profileImage,
  // } = myProfileContext;
  const { loggedUserId } = myProfileContext;
  const candidateId = loggedUserId;
  const globalSpinner = useGlobalSpinnerContext();
  let history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(false);
  const currentPageSize = pageSize;
  const [totalPage, setTotalPage] = useState(1);

  React.useEffect(() => {
    if (currentPage == 1 && previousPage == false) {
      getCandidateCoverLetterData(currentPage, currentPageSize);
    }
  }, [currentPage]);

  const fetchNextData = async () => {
    if (currentPage >= totalPage) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
      getCandidateCoverLetterData(currentPage + 1, currentPageSize);
    }
  };

  const fetchPreviousData = async () => {
    if (currentPage <= 1) {
      return;
    } else {
      if (currentPage - 1 == 1) {
        setPreviousPage(true);
      }
      setCurrentPage(currentPage - 1);
      getCandidateCoverLetterData(currentPage - 1, currentPageSize);
    }
  };

  const getCandidateCoverLetterData = (getPage: any, getPageSize: any) => {
    globalSpinner.showSpinner();
    getAllCoverLetterCandidateMap({
      CandidateId: candidateId,
      Page: getPage,
      PageSize: getPageSize,
      SearchTerm: "",
      SortOrder: "",
      ShowInactive: false,
    })
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (getPage == 1) {
          setTotalPage(Math.ceil(res.data.total / currentPageSize));
        }
        setCandidateCoverLetters(res.data.data);
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        // //console.log(err);
      });
  };

  const handleCarouselNextClick = () => {
    //call api()
    fetchNextData();
  };

  const handleCarouselPreviousClick = () => {
    fetchPreviousData();
  };

  const editCoverLetter = async (e: any, i: any) => {
    history.push({
      pathname: "/candidate/my-resume/resume/my_information",
    });
  };
  const shareCoverLetter = async (e: any, i: any) => {
    ////console.log(e, i);
    setIsShareOpen(!isShareOpen);
    setCoverLetterShare(environment.baseUrl + e.coverLetterFileFullPath);
  };

  const removeCoverLetter = async (e: any, i: any) => {
    globalSpinner.showSpinner();
    deleteCoverLetterCandidateMap({ RowId: e.rowId })
      .then((res) => {
        ////console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          getCandidateCoverLetterData(currentPage, currentPageSize);
          toast.success("Cover letter was successfully removed.");
        } else {
          //toast.error(res.data.message);
          toast.error("Cover letter not removed.");
        }
      })
      .catch((err) => {
        ////console.log(err);
        globalSpinner.hideSpinner();
      });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="heading_sec2">
        <h1>Cover Letter</h1>
        <Link
          to={`/candidate/my-resume/cover-letter/Select_template`}
          className="btn resume_build_but"
        >
          Build your cover letter with us
        </Link>
      </div>
      <div className="row">
        <div className="prodict_list">
          <div
            id="owl-demo2"
            className="owl-carousel owl-theme"
            style={{ opacity: 1, display: "block" }}
          >
            <div className="owl-wrapper-outer">
              <div
                className="owl-wrapper"
                style={{
                  width: "2568px",
                  left: "0px",
                  display: "block",
                  transition: "all 0ms ease 0s",
                  // transform: `translate3d(${carouselValue}px, 0px, 0px)`,
                  transform: `translate3d(0px, 0px, 0px)`,
                }}
              >
                {candidateCoverLetters != undefined &&
                  candidateCoverLetters
                    .filter((f: any) => f.isActive == true)
                    .map((e: any, i: any) => (
                      <div className="owl-item" style={{ width: "321px" }}>
                        <div className="item">
                          <div className="matched_jobs_sec">
                            <div className="digis_expairs">
                              Created on - {e.createdDate}
                            </div>
                            <div className="matched_jobs">
                              <img
                                src={
                                  environment.baseUrl +
                                  e.candidateCoverLetterImageFullPath
                                }
                                alt=""
                                className="image2"
                              />
                              <div className="overlay">
                                <div className="myresume_edits1 ">
                                  <a
                                    href={`${
                                      environment.baseUrl +
                                      e.coverLetterFileFullPath
                                    }`}
                                    target="_blank"
                                    className="myresume_clr1"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    />
                                  </a>
                                  <a
                                    onClick={() => {
                                      shareCoverLetter(e, i);
                                    }}
                                    data-target="#share"
                                    data-toggle="modal"
                                    className="myresume_clr2"
                                  >
                                    <i
                                      className="fa fa-share-alt"
                                      aria-hidden="true"
                                    />
                                  </a>
                                  <a
                                    onClick={() => {
                                      editCoverLetter(e, i);
                                    }}
                                    className="myresume_clr3"
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    />
                                  </a>

                                  <Popconfirm
                                    title={
                                      <div>
                                        <p>
                                          Are you sure delete this document{" "}
                                        </p>
                                        Name: {`${e.coverLetterName}`}
                                        <br />
                                        Last Modified: {`${e.updatedDate}`}
                                      </div>
                                    }
                                    onConfirm={() =>
                                      //handleDeleted(document.rowId)
                                      removeCoverLetter(e, i)
                                    }
                                    //onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <a className="myresume_clr4">
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </Popconfirm>
                                </div>
                              </div>
                            </div>
                            <div className="digis_documents">
                              <i
                                className="fa fa-file-text-o"
                                aria-hidden="true"
                              />{" "}
                              {e.coverLetterName}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            <div className="owl-controls clickable" style={{ display: "none" }}>
              <div className="owl-pagination">
                <div className="owl-page active">
                  <span className="" />
                </div>
              </div>
              <div className="owl-buttons">
                <div className="owl-prev" onClick={handleCarouselPreviousClick}>
                  <img src={require("../../../../images/products_ar1.png")} />
                </div>
                <div className="owl-next" onClick={handleCarouselNextClick}>
                  <img src={require("../../../../images/products_ar2.png")} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={isShareOpen}
        onHide={() => {
          setIsShareOpen(!isShareOpen);
          setCoverLetterShare("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share this Resume on</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 m_t_30 text-center">
            <div className="social1">
              <WhatsappShareButton url={coverLetterShare} className={""}>
                <i
                  className="fa fa-whatsapp"
                  style={{ fontSize: 48, color: "green" }}
                ></i>
              </WhatsappShareButton>{" "}
              <TelegramShareButton url={coverLetterShare}>
                <Tooltip title="Telegram">
                  <i
                    className="fa fa-telegram"
                    style={{ fontSize: 48, color: "rgb(55, 174, 226)" }}
                  ></i>
                </Tooltip>
              </TelegramShareButton>
              <EmailShareButton
                url={coverLetterShare}
                subject={"Cover Letter In Carrer app"}
                body={"This is a Cover Letter from Carrer app"}
                className={""}
              >
                <Tooltip title="Gmail">
                  <img
                    src={require("../../../../images/gmail-icon-svg-28.jpg")}
                    style={{ height: 66, marginTop: -24 }}
                  />
                </Tooltip>
              </EmailShareButton>{" "}
              <CopyToClipboard text={coverLetterShare}>
                <Tooltip title="copy to clipboard">
                  {" "}
                  <img
                    src={require("../../../../images/copy-clipboard-icon-28.jpg")}
                    style={{ height: 66, marginTop: -24, marginLeft: -15 }}
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  />
                </Tooltip>
              </CopyToClipboard>
            </div>
          </div>
          <div className="modal-footer  m-t-30"></div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
export default CandidateCoverLetters;
