import React, { useState } from "react";
import Countdown from "react-countdown";
import {
  SetAccessible,
  useGlobelCounterDispatcher,
} from "../../../action/general/GlobelCounterAction";

function CountDownTime({ autostart }: { autostart: boolean }) {
  const globelCounterDispatcher = useGlobelCounterDispatcher();
  const [isActive, setIsActive] = useState(false);
  const [countValue, setCountValue] = useState(20000);
  console.log(12121212, autostart);

  React.useEffect(() => {
    if (autostart) {
      setIsActive(true);
      // setCountValue(countValue + 1);
    }
  }, [autostart]);

  const handleCompleted = () => {
    console.log("asasssaaaaaaaaaaaaaaaa");
    setCountValue(countValue + 1);
    // (() => {
    //   SetAccessible(globelCounterDispatcher, false, new Date());
    // })();
  };
  const handleStarteded = () => {
    console.log("bbbbbbbbbbbb");
  };

  let autoStatus = autostart;

  if (autostart) {
    return (
      <React.Fragment>
        <Countdown
          date={Date.now() + 20000}
          intervalDelay={0}
          precision={3}
          onComplete={() => handleCompleted()}
          onStart={() => handleStarteded()}
          autoStart={true}
        ></Countdown>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Countdown
          date={Date.now() + 20000}
          intervalDelay={0}
          precision={3}
          onComplete={() => handleCompleted()}
          onStart={() => handleStarteded()}
          autoStart={false}
        ></Countdown>
      </React.Fragment>
    );
  }
}

export default CountDownTime;
