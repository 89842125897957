import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  AchievementsRequestModel,
  Achievements,
  AchievementsViewModel,
  AchievementsSaveRequestModel,
  AchievementsSaveRespondModel,
  AchievementsEditRequestModel,
  AchievementsResultModel,
  AchievementsGetDataWithId,
  AchievementsSelectBoxDataViewModel,
  AchievementsDeleteRequestModel,
  AchievementsDeleteResultModel,
  AchievementsWithOutTokenViewModel,
  ClientsWithOutTokenViewModel,
  ClientsRequestModel,
} from "../../models/admin/Achievements";
import {
  IAchievementsManagementState,
  AchievementsDispatcherContext,
  AchievementsStateContext,
} from "../../context/admin/AchievementsContext";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IAchievementsAction =
  | {
      type: "Achievements_LIST";
      Achievements: AchievementsViewModel;
    }
  | {
      type: "Achievements_WithOutToken_LIST";
      AchievementsWithOutToken : AchievementsWithOutTokenViewModel;
    }
  | {
      type: "Clients_WithOutToken_LIST";
      ClientsWithOutToken : ClientsWithOutTokenViewModel;
    }
  | {
      type: "Achievements_SELECTBOX_LIST";
      AchievementsSelectBoxDataViewModel: AchievementsSelectBoxDataViewModel;
    }
  | {
      type: "GET_Achievements";
      AchievementsResultModel: AchievementsResultModel;
    }
  | {
      type: "EDIT_Achievements";
      AchievementsSaveRespond: AchievementsSaveRespondModel;
    }
  | {
      type: "DELETE_Achievements";
      AchievementsDeleteResultModel: AchievementsDeleteResultModel;
    };

export const AchievementsReducer: Reducer<
  IAchievementsManagementState,
  IAchievementsAction
> = (draft, action): IAchievementsManagementState => {
  switch (action.type) {
    case "Achievements_LIST":
      draft.Achievements = action.Achievements;
      return draft;
    case "Achievements_WithOutToken_LIST":
      draft.AchievementsWithOutToken = action.AchievementsWithOutToken;
      return draft;
    case "Clients_WithOutToken_LIST":
      draft.ClientsWithOutToken = action.ClientsWithOutToken;
      return draft;
    case "GET_Achievements":
      draft.AchievementsResultModel = action.AchievementsResultModel;
      return draft;
    case "EDIT_Achievements":
      draft.AchievementsSaveRespond = action.AchievementsSaveRespond;
      return draft;
    case "DELETE_Achievements":
      draft.AchievementsDeleteResultModel = action.AchievementsDeleteResultModel;
      return draft;
    case "Achievements_SELECTBOX_LIST":
      draft.AchievementsSelectBoxDataViewModel = action.AchievementsSelectBoxDataViewModel;
      return draft;
  }
};

export const useAchievementsDispatcher = (): React.Dispatch<IAchievementsAction> => {
  const AchievementsDispatcher = React.useContext(AchievementsDispatcherContext);
  if (!AchievementsDispatcher) {
    throw new Error(
      "You have to provide the Achievements dispatcher using theAchievementsDispatcherContext.Provider in a parent component."
    );
  }
  return AchievementsDispatcher;
};

export const useAchievementsContext = (): IAchievementsManagementState => {
  const AchievementsContext = React.useContext(AchievementsStateContext);
  if (!AchievementsContext)
    throw new Error(
      "You have to provide the Achievements context using the AchievementsStateContext.Provider in a parent component."
    );
  return AchievementsContext;
};

export const getAchievementsList = async (
  dispatcher: React.Dispatch<IAchievementsAction>,
  query: AchievementsRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAchievementsList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "Achievements_LIST", Achievements: res.data });
    });
  } catch (e) {}
};

export const getAchievementsListWithOutToken = async (
  dispatcher: React.Dispatch<IAchievementsAction>,
  query: AchievementsRequestModel
) => {
  try {
    
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const url = AppUrls.GetAchievementsListWithOutToken;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "Achievements_WithOutToken_LIST", AchievementsWithOutToken: res.data });
    });
  } catch (e) {}
};

export const getClientsListWithOutToken = async (
  dispatcher: React.Dispatch<IAchievementsAction>,
  query: ClientsRequestModel
) => {
  try {
    
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const url = AppUrls.GetClientsListWithOutToken;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "Clients_WithOutToken_LIST", ClientsWithOutToken: res.data });
    });
  } catch (e) {}
};

export const deleteAchievements = async (
  dispatcher: React.Dispatch<IAchievementsAction>,
  query: AchievementsDeleteRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteAchievements;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "DELETE_Achievements", AchievementsDeleteResultModel: res.data });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Achievement removed successfully.")
          : message.error(res.data.message)
        : message.error(" Some error happend on removing achievement.");
    });


  } catch (e) {}
};

export const editAchievements = async (
  dispatcher: React.Dispatch<IAchievementsAction>,
  query: AchievementsEditRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditAchievements;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_Achievements",
        AchievementsSaveRespond: res.data,
      });

      res.status == 200
        ? message.success("Achievement is updated.")
        : message.error("There are some error happend on updating achievement.");
    });
  } catch (e) {}
};

export const getAchievements = async (
  dispatcher: React.Dispatch<IAchievementsAction>,
  query: AchievementsGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAchievements;
    if (query.rowId != 0) {
      axios
        .post(url, JSON.stringify(query), { headers: header })
        .then((res) => {
          dispatcher({
            type: "GET_Achievements",
            AchievementsResultModel: res.data,
          });
        });
    } else {
      dispatcher({
        type: "GET_Achievements",
        AchievementsResultModel: {} as AchievementsResultModel,
      });
    }
  } catch (e) {}
};

export const getAchievementsSelectBoxList = async (
  dispatcher: React.Dispatch<IAchievementsAction>,
  token: string
) => {
  try {
     if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAchievementsSelectBoxData;

    axios.post(url, JSON.stringify({}), { headers: header }).then((res) => {
      dispatcher({ type: "Achievements_SELECTBOX_LIST", AchievementsSelectBoxDataViewModel: res.data });
    });
  } catch (e) {}
};