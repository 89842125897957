import React from "react";
import {
  getSuperAdminLoggedUserId,
  useSuperAdminMyProfileContext,
  useSuperAdminMyProfileDispatcher,
} from "../../../action/MyProfileSuperAdminAction";
import {
  getAllAdminUserPermission,
  useSuperAdminUserContext,
  useSuperAdminUserDispatcher,
} from "../../../action/superAdmin/SuperAdminUserAction";
import { GetAllPermissionRequestModel } from "../../../models/superadmin/SuperAdminUsers";
import AuthService from "../../../services/AuthService";
import { SuperAdminContextProvider } from "./../../../context/superadmin/SuperAdminMyProfile";
import SuperAdminLayoutComponent from "./SuperAdminLayoutComponent";

interface ISuperAdminLayoutContainerProps {}

interface ISuperAdminLayoutContainerState {}

const initialState = {};

const SuperAdminLayoutContainer: React.FC<ISuperAdminLayoutContainerProps> = (
  props
) => {
  const [
    superAdminLayoutContainerState,
    setSuperAdminLayoutContainerState,
  ] = React.useState<ISuperAdminLayoutContainerState>(initialState);

  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;

  const myProfileSuperAdminDispatcher = useSuperAdminMyProfileDispatcher();
  const myProfileSuperAdminContext = useSuperAdminMyProfileContext();
  const {
    myProfile,
    basicInfo,
    loggedSuperAdminId,
  } = myProfileSuperAdminContext;

  const superAdminUserDispatcher = useSuperAdminUserDispatcher();
  const superAdminUserContext = useSuperAdminUserContext();
  const { getAllPermission } = superAdminUserContext;

  //console.log(900, user);
  //console.log(901, authorizationToken);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null)
      (async () => {
        await getSuperAdminLoggedUserId(
          myProfileSuperAdminDispatcher,
          parseInt(user.id),
          authorizationToken
        );
      })();
  }, [authorizationToken]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getAllAdminUserPermission(
          superAdminUserDispatcher,
          {
            Page: 1,
            PageSize: 100,
            SearchTerm: "",
            SortOrder: "",
          } as GetAllPermissionRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedSuperAdminId]);

  return (
    <div id="wrapper">
      <SuperAdminContextProvider>
        <SuperAdminLayoutComponent />
      </SuperAdminContextProvider>
    </div>
  );
};
export default SuperAdminLayoutContainer;
