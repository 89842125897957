import React from "react";

import { Link, withRouter } from "react-router-dom";
import { Collapse } from "antd";
import { useHistory } from "react-router-dom";

const { Panel } = Collapse;

interface IComingSoonComponentProps { }

interface IComingSoonComponentState { }

const initialState = {};

const ComingSoonComponent: React.FC<IComingSoonComponentProps> = (
  props: any
) => {
  const [
    startUpState,
    setComingSoonComponentState,
  ] = React.useState<IComingSoonComponentState>(initialState);

  const history = useHistory();
  const cl = props.location.pathname;
  return (
    <>
      {/* Header Start */}

      <div className="header header1">
        <header>
          <div className=" container">
            <div className="row">
              <div className="col-sm-2">
                <div className=" logo">
                  <a
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    <img
                      src={require("../../images/logo.png")}
                      // src={require("../../images/logo.png"
                      className="img-responsive"
                      alt=""
                    />{" "}
                  </a>
                </div>
              </div>
              <div className="col-sm-10">
                <div className="bs-example">
                  <nav
                    role="navigation"
                    className="navbar navbar-default navbar-static"
                    id="navbar-example"
                  >
                    <div className="navbar-header">
                      <button
                        data-target=".bs-example-js-navbar-collapse"
                        data-toggle="collapse"
                        type="button"
                        className="navbar-toggle"
                      >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                      </button>
                      <div className="collapse navbar-collapse bs-example-js-navbar-collapse">
                        <ul className="nav navbar-nav">
                          <li>
                            <Link to="/" className={cl === "/" ? "active" : ""}>
                              {" "}
                              Home
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/career_developer"
                              className={
                                cl === "/career_developer" ? "active" : ""
                              }
                            >
                              Career Developer
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/coming_soon"
                              className={cl === "/coming_soon" ? "active" : ""}
                            >
                              Client{" "}
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/vendor"
                              className={cl === "/vendor" ? "active" : ""}
                            >
                              Vendor
                            </Link>{" "}
                          </li>
                          <li>
                            <Link
                              to={`/help`}
                              className={cl === "/help" ? "active" : ""}
                            >
                              Help
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/about_us"
                              className={cl === "/about_us" ? "active" : ""}
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact_us"
                              className={cl === "/contact_us" ? "active" : ""}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "12%",
          marginBottom: "12%",
          width: "50%",
        }}
      >
        <img
          src={require("../../images/coming_soon.png")}
          alt="Coming soon!"
          className=" img-responsive"
        />
      </div>
    </>
  );
};
export default withRouter(ComingSoonComponent);
