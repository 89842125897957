import { ErrorMessage } from "@hookform/error-message";
import { Dropdown, Menu } from "antd";
import Popconfirm from "antd/lib/popconfirm";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSuperAdminMyProfileContext } from "../../../action/MyProfileSuperAdminAction";
import { useNotificationListContext } from "../../../context/superadmin/SuperAdminMyProfile";
import {
  deleteSuperAdminUser,
  getSuperAdminUserById,
  getSuperAdminUserList,
  saveSuperAdminUser,
  useSuperAdminUserContext,
  useSuperAdminUserDispatcher,
} from "../../../action/superAdmin/SuperAdminUserAction";
import { AppUrls } from "../../../environments/environment";
import {
  CreateAdminRequestModel,
  InvitationRequestModel,
  SuperAdminRequestModelById,
  SuperAdminUserDeleteRequestModel,
  SuperAdminUserRequestModel,
} from "../../../models/superadmin/SuperAdminUsers";
import AuthService from "../../../services/AuthService";
import PhoneNumberWithCode from "../../components/PhoneNumberWithCode";
import { GlobalSpinnerContext } from "../../../context/GlobalSpinner";
import Scrollbars from "react-custom-scrollbars";
import {
  getAllTotalReportCount,
  useSuperAdminReportContext,
  useSuperAdminReportDispatcher,
} from "../../../action/superAdmin/SuperAdminReportAction";
import { SuperAdminReportRequestModel } from "../../../models/superadmin/SuperAdminReport";
import { Link } from "react-router-dom";
import { getMessageDashboardList } from "../../../apis/vendor";
import { registerMe } from "../../../apis/misc";
import { message } from "antd";

const defaultValues = {
  countryCode: "",
  mobileNumber: "",
  firstName: "",
};

const SuperAdminDashboardComponent = () => {
  // const [isListShow, setIsListShow] = useState<string>('none');

  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;

  const [isListShow, setIsListShow] = useState<boolean>(false);
  const [isListShowWithIndex, setIsListShowWithIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentAdminId, setCurrentAdmin] = useState<number>(0);
  const [generatedPassword, setGeneratedPassword] = useState<string>("");
  const [permissionArrayList, setPermissionArrayList] = useState<number[]>([]);
  const [list, setList] = useState();
  const [messageList, setMessageList] = useState();
  const [messageListFlag, setMessageListFlag] = useState(0);
  const [listFlag, setListFlag] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [mobileData, setMobileData] = useState<string>("");
  const [emailData, setEmailData] = useState<string>("");
  const [countryCodeData, setCountryCodeData] = useState<string>("");
  const [passwordData, setPasswordData] = useState<string>("");
  const globalSpinner = useContext(GlobalSpinnerContext);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [isRemovedAll, setIsRemovedCheckedAll] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [currentAdminIdAfterReg, setCurrentAdminAfterReg] = useState<number>(0);
  const [prevPassword, setPrevPassword] = useState<string>("");

  const superAdminUserDispatcher = useSuperAdminUserDispatcher();
  const superAdminUserContext = useSuperAdminUserContext();
  const {
    getAllPermission,
    superAdminUsersList,
    deleteSuperAdminUserResponse,
    superAdminUser,
    saveAdminRespondModel,
  } = superAdminUserContext;

  const myProfileSuperAdminContext = useSuperAdminMyProfileContext();
  const {
    myProfile,
    basicInfo,
    loggedSuperAdminId,
  } = myProfileSuperAdminContext;

  const superAdminReportDispatcher = useSuperAdminReportDispatcher();
  const superAdminReportContext = useSuperAdminReportContext();
  const { adminReportsList, totalReportList } = superAdminReportContext;

  const menuRef = useRef<any>();

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const notificationListContext = useNotificationListContext();
  const { notificationList, getNotificationList } = notificationListContext;

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getAllTotalReportCount(
          superAdminReportDispatcher,
          {
            FilterId: 0,
            Page: 1,
            SuperAdminId: 1,
            PageSize: 10,
            SearchTerm: "",
            ShowInactive: false,
            SortOrder: "",
          } as SuperAdminReportRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, []);

  React.useEffect(() => {
    if (
      loggedSuperAdminId > 0 &&
      authorizationToken != null &&
      authorizationToken != undefined
    ) {
      console.log(
        loggedSuperAdminId,
        " notificationList0 ",
        authorizationToken
      );
      getNotificationList({
        SuperAdminId: loggedSuperAdminId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      });
      console.log(
        loggedSuperAdminId,
        " notificationList0 ",
        notificationList,
        " ",
        authorizationToken
      );
    }
  }, [loggedSuperAdminId]);

  React.useEffect(() => {
    if (
      loggedSuperAdminId > 0 &&
      authorizationToken != null &&
      authorizationToken != undefined
    ) {
      getMessageDashboardList({
        UserId: Number(AuthService.currentUser?.id),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      })
        .then((res) => {
          console.log("Dashboard message ", res);

          setMessageList(
            res.data.data.map((item: any, index: any) => {
              if (item["unReadCount"] > 0) {
                setMessageListFlag(1);
                return (
                  <div className="message_sec1">
                    {/* <Link to="super_admin/messages"> */}
                    <Link to={{
                      pathname: "super_admin/messages",
                      state: item
                    }}>
                      <span className="message_icon">
                        <img
                          src={
                            item["imageUrl"] !== "null" &&
                              item["imageUrl"] !== null &&
                              item["imageUrl"] !== undefined
                              ? `https://careerapp.azurewebsites.net/Upload/AdminProfileImage/${item["imageUrl"]}`
                              : require("./../../../images/profileDefault1.jpg")
                          }
                          className="img-responsive"
                        />
                      </span>
                      <div className="message_con_vend">
                        <div className="message_head">{item["userName"]}</div>
                        <div className="message_pra">{item["message"]}</div>
                      </div>
                      <div className="message_noti">
                        <div className="message_noti_con">
                          {item["daysAgo"]} day ago
                        </div>
                        <div className="message_noti_count">
                          {item["unReadCount"]}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })
          );
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [loggedSuperAdminId]);

  React.useEffect(() => {
    if (
      notificationList != null &&
      notificationList != undefined &&
      authorizationToken != null &&
      authorizationToken != undefined &&
      loggedSuperAdminId > 0
    ) {
      if (notificationList.data != undefined) {
        console.log(
          loggedSuperAdminId,
          " notificationList1 ",
          notificationList,
          " ",
          authorizationToken
        );

        setList(
          notificationList.data.map((item: String) => {
            setListFlag(1);
            if (item.includes("Profile") || item.includes("Password")) {
              return (
                <div className="notification_sec">
                  <Link to="super_admin/my-profile">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.includes("Matched")) {
              return (
                <div className="notification_sec">
                  <Link to="/super_admin">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.includes("applied")) {
              return (
                <div className="notification_sec">
                  <Link to="super_admin/jobs-applied">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else {
              return (
                <div className="notification_sec">
                  <Link to="/super_admin">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            }
          })
        );
      }
    }
  }, [loggedSuperAdminId, notificationList]);

  React.useEffect(() => {
    const handler = (event: any) => {
      if (1) {
        if (1) {
          // console.log("*");
          // setIsListShowWithIndex(
          //   isListShowWithIndex == 1000 ? 1000 : isListShowWithIndex
          // );
        } else {
          console.log(isListShowWithIndex);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    console.log(290, superAdminUser);

    if (superAdminUser.data != undefined) {
      globalSpinner.hideSpinner();
      let listArray = [];
      if (superAdminUser.data.permissionTypes.length > 0) {
        listArray = superAdminUser.data.permissionTypes.map(
          (dataValue: any) => {
            return dataValue.toString();
          }
        );
      }
      console.log(291, superAdminUser.data);
      setValue2("firstName", superAdminUser.data.adminName);
      setValue2("email", superAdminUser.data.email);
      setValue2("location", superAdminUser.data.location);
      setValue2("mobileNumber", superAdminUser.data.mobileNumber);
      // setValue2("password", superAdminUser.data.generatePassword);
      setGeneratedPassword(superAdminUser.data.generatePassword);
      setValue2("checkbox", listArray);
      // setPermissionArrayList(superAdminUser.data.permissionTypes);
    }
  }, [superAdminUser.data]);

  // useEffect(() => {
  //   if (saveAdminRespondModel.IsSuccess) {
  //     setIsOpen(false);
  //   }
  // }, [saveAdminRespondModel]);

  const handleInvitationClick = (
    mobileNumber: string,
    email: string,
    countryCode: string,
    password: string
  ) => {
    setGeneratedPassword("");

    setMobileData(mobileNumber);
    setEmailData(email);
    setCountryCodeData(countryCode);
    setPasswordData(password);

    setIsModalVisible(true);
  };

  const handleEmailClick = () => {
    setIsEmail(true);
    setIsMobile(false);
    if (authorizationToken != null) {
      let token = "";
      try {
        if (authorizationToken?.startsWith(`"`)) {
          let token = authorizationToken.substring(1);
          token = authorizationToken.slice(0, -1);
        }

        let header = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        };

        let data = {
          Email: emailData,
          PhoneNumber: countryCodeData + mobileData,
          Password: passwordData,
        } as InvitationRequestModel;

        // const url1 = AppUrls.SendInvitationPhone;
        const url2 = AppUrls.SendInvitationEmail;
        // axios
        //   .post(url1, JSON.stringify(data), { headers: header })
        //   .then((res) => {
        //     console.log(res);
        //   });

        axios
          .post(url2, JSON.stringify(data), { headers: header })
          .then((res) => {
            console.log(res);
          });
      } catch (e) { }
      setIsListShowWithIndex(1000);
      setIsModalVisible(false);
      setIsEmail(false);
      setIsMobile(false);
    }
  };

  const handlePhoneClick = () => {
    setIsEmail(false);
    setIsMobile(true);
    if (authorizationToken != null) {
      let token = "";
      try {
        if (authorizationToken?.startsWith(`"`)) {
          let token = authorizationToken.substring(1);
          token = authorizationToken.slice(0, -1);
        }

        let header = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        };

        let data = {
          Email: emailData,
          PhoneNumber: countryCodeData + mobileData,
          Password: passwordData,
        } as InvitationRequestModel;

        const url1 = AppUrls.SendInvitationPhone;
        // const url2 = AppUrls.SendInvitationEmail;
        axios
          .post(url1, JSON.stringify(data), { headers: header })
          .then((res) => {
            console.log(res);
          });

        // axios
        //   .post(url2, JSON.stringify(data), { headers: header })
        //   .then((res) => {
        //     console.log(res);
        //   });
      } catch (e) { }
      setIsListShowWithIndex(1000);
      setIsModalVisible(false);
      setIsEmail(false);
      setIsMobile(true);
    }
  };

  const handleAdminClick = (data: any) => {
    let permissionArray = [];
    // setGeneratedPassword("");
    permissionArray = data.checkbox;
    let permissionArrayInt = permissionArray.map((dataValue: any) => {
      return parseInt(dataValue);
    });
    console.log(295, data);
    message.loading("please wait..");
    if (authorizationToken != null) {
      setIsOpen(true);
      setIsListShowWithIndex(1000);
      // setIsListShow("none");
      // if (currentAdminId == 0) {
      registerMe({
        RowId: currentAdminId,
        UserId: currentAdminIdAfterReg,
        RoleId: 14,
        Email: data.email,
        UserName: data.firstName,
        CountryCode: data.countryCode === "" ? "+91" : data.countryCode,
        PhoneNumber: data.mobileNumber,
        Password: data.password,
        CurrentPassword: currentAdminId === 0 ? data.password : prevPassword,
        EmployerFlag: false,
        VendorFlag: false,
        SuperAdminFlag: false,
        EmployeeFlag: false,
        CandidateFlag: false,
        AdminFlag: true,
        IsActive: true,
      }).then((res: any) => {
        console.log(456111, res);
        res.data.isSuccess
          ? message.loading("Creating Admin..")
          : message.error(res.data.message);
        setCurrentAdminAfterReg(res.data.rowId);
        if (res.data.isSuccess) {
          saveAdminDetails(data, res.data.rowId);
        }
      });
      // } else {
      //   saveAdminDetails(data, currentAdminIdAfterReg);
      // }
    }
  };

  const saveAdminDetails = (data: any, adminUserId: number) => {
    if (authorizationToken != null) {
      let permissionArray = [];
      permissionArray = data.checkbox;
      let permissionArrayInt = permissionArray.map((dataValue: any) => {
        return parseInt(dataValue);
      });
      (async () => {
        await saveSuperAdminUser(
          superAdminUserDispatcher,
          {
            AdminName: data.firstName,
            AdminTitleIdList: permissionArrayInt,
            CountryCode: data.countryCode === "" ? "+91" : data.countryCode,
            Email: data.email,
            GeneratePassword: data.password,
            IsActive: true,
            Location: data.location,
            MobileNumber: data.mobileNumber,
            RowId: currentAdminId,
            SuperAdminId: 1,
            UserId: adminUserId,
          } as CreateAdminRequestModel,
          authorizationToken
        );
        setGeneratedPassword("");
        setCurrentAdminAfterReg(0);
        setIsCheckedAll(false);
        setIsRemovedCheckedAll(false);
        setCheckedListArray([]);
      })();
    }
  };

  let IdArray = [] as any[];
  const handleAllChecked = (e: any) => {
    console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArray = [];
      // digiLockerList
      // if (superAdminUser.data != undefined) {
      //   console.log(4444555, superAdminUser.data.permissionTypes);
      //   superAdminUser.data.permissionTypes.map((d) => {
      //     IdArray.push(d);
      //   });
      // }
      setCheckedListArray([
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
      ]);
      console.log(23, checkedListArray);
      setIsCheckedAll(e.target.checked);
      console.log("checkedListArray", checkedListArray);
      setIsRemovedCheckedAll(false);
    } else {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(e.target.checked);
      console.log("checkedListArray", checkedListArray);
      setIsRemovedCheckedAll(true);
    }
  };

  const handleAllRemoved = (e: any) => {
    if (e.target.checked) {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(true);
      console.log("checkedListArray", checkedListArray);
    }
  };

  const handleChecked = (e: any, id: any) => {
    // setIsChecked(true);
    if (e.target.checked) {
      IdArray.push(...checkedListArray, id);
      setCheckedListArray(IdArray);
      console.log("checkedListArray", checkedListArray);
    } else {
      IdArray = checkedListArray.filter((data) => {
        return data !== id;
      });
      setCheckedListArray(IdArray);
      console.log("checkedListArray", checkedListArray);
    }
    setIsRemovedCheckedAll(false);
    setIsChecked(true);
  };

  const handleEditAdminClick = (data: any) => {
    setGeneratedPassword("");
    globalSpinner.showSpinner();
    if (authorizationToken != null) {
      (async () => {
        await getSuperAdminUserById(
          superAdminUserDispatcher,
          {
            RowId: data,
          } as SuperAdminRequestModelById,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
      setCurrentAdmin(data);
      setIsListShow(false);
      setIsOpen(true);
      setIsListShowWithIndex(1000);
      // setIsListShow("none");
    }
  };

  // setValue2("firstName", "fdfh");

  const handleGeneratePasword = () => {
    let A1 = "Admin";
    let A2 = "@";
    const min = 1;
    const max = 100;
    const rand1 = Math.floor(Math.random() * 31) + 10;
    const rand2 = Math.floor(Math.random() * 31) + 50;
    // const pwd = (A1 +Math.round(rand1) + A2 + Math.round(rand2)).toString();
    setGeneratedPassword(A1 + Math.round(rand1) + A2 + Math.round(rand2));
    console.log(56888, rand1);
  };

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getSuperAdminUserList(
          superAdminUserDispatcher,
          {
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
            superAdminId: 1,
          } as SuperAdminUserRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
      setIsOpen(false);
    }
  }, [loggedSuperAdminId, deleteSuperAdminUserResponse, saveAdminRespondModel]);

  const handleAdminDelete = (id: any) => {
    setGeneratedPassword("");
    console.log(3545776556374, id);
    if (authorizationToken != null) {
      (async () => {
        await deleteSuperAdminUser(
          superAdminUserDispatcher,
          {
            RowId: id,
            SuperAdminId: 1,
          } as SuperAdminUserDeleteRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
      // setIsListShow("none");
    }
  };

  function cancel(e: any) {
    setGeneratedPassword("");
  }

  return (
    <>
      {/*=================Content Section Start================*/}
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row m-t-25">
            <div className="col-sm-8">
              <Modal
                show={isModalVisible}
                onHide={() => {
                  setIsModalVisible(!isModalVisible);
                }}
                bsSize="xsmall"
              >
                <Modal.Header closeButton>
                  <Modal.Title>please select</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="col-sm-2"></div>
                  <div className="col-sm-4">
                    <button
                      onClick={handleEmailClick}
                      type="button"
                      className={
                        isEmail
                          ? "btn btn-success sig_but"
                          : "btn btn-success create_but"
                      }
                    >
                      Email
                    </button>
                  </div>
                  <div className="col-sm-4">
                    <button
                      style={{ marginLeft: 52 }}
                      onClick={handlePhoneClick}
                      type="button"
                      className={
                        isMobile
                          ? "btn btn-success sig_but"
                          : "btn btn-success create_but"
                      }
                    >
                      Mobile
                    </button>
                  </div>
                  <div className="col-sm-2"></div>
                </Modal.Body>
              </Modal>

              <Modal
                show={isOpen}
                onHide={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <form onSubmit={handleSubmit2(handleAdminClick)} noValidate>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {currentAdminId == 0
                        ? " New Admin Profile"
                        : "Update Admin Profile"}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="email" className="required">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Name"
                          name="firstName"
                          ref={register2({
                            required: "Name is required",
                          })}
                        />
                        <ErrorMessage
                          errors={errors2}
                          name="firstName"
                          render={({ message }) => (
                            <div className="login_validation">{message}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="email" className="required">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder=""
                          name="email"
                          ref={register2({
                            required: "Email is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please enter a valid e-mail address.",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors2}
                          name="email"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="email" className="required">
                          Mobile Number
                        </label>
                        {/* <input
                      type="text"
                      name="countryCode"
                      style={{ display: "none" }}
                      disabled={false}
                      ref={register2}
                    /> */}
                        <Controller
                          control={control2}
                          name="countryCode"
                          render={({ onChange, onBlur, value, name }) => (
                            <PhoneNumberWithCode
                              codeHeight={"41px"}
                              noPenIcon={true}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                              phoneNo={
                                <input
                                  type="text"
                                  name="mobileNumber"
                                  id="phoneNumber"
                                  className="form-control phoneNumber"
                                  placeholder="Mobile number"
                                  ref={register2({
                                    required: "Mobile number is required",
                                    maxLength: {
                                      value:
                                        watch2("countryCode").length > 3
                                          ? 9
                                          : 10,
                                      message: `It must be ${watch2("countryCode").length > 3
                                          ? 9
                                          : 10
                                        } digits only.`,
                                    },
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Mobile number should be numbers only",
                                    },
                                    minLength: {
                                      value:
                                        watch2("countryCode").length > 3
                                          ? 9
                                          : 10,
                                      message: `It must be ${watch2("countryCode").length > 3
                                          ? 9
                                          : 10
                                        } digits only.`,
                                    },
                                  })}
                                />
                              }
                            />
                          )}
                        />

                        <ErrorMessage
                          errors={errors2}
                          name="mobileNumber"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>

                      {/* <div className="form-group"> */}
                      {/* <label htmlFor="email">Mobile </label>
                    <UserMobileInput />
                    <div className="input-phone"> </div>
                  </div> */}
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group ">
                        <label htmlFor="email" className="col-sm-12 required">
                          Password
                        </label>
                        <button
                          onClick={handleGeneratePasword}
                          className="btn btn-success save-event waves-effect waves-light"
                          type="button"
                        >
                          Generate Password
                        </button>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group ">
                        <label htmlFor="email" className="col-sm-12"></label>

                        <input
                          type="text"
                          // className="form-control "
                          style={{
                            paddingTop: 25,
                            color: "green",
                            border: "none",
                            fontSize: "large",
                          }}
                          // placeholder="location"
                          name="password"
                          value={generatedPassword}
                          ref={register2({
                            required: "password is required",
                          })}
                        />
                        {generatedPassword !== "" ? null : (
                          <ErrorMessage
                            errors={errors2}
                            name="password"
                            render={({ message }) => (
                              <div
                                style={{ fontSize: "large", marginTop: -28 }}
                                className="login_validation"
                              >
                                {message}
                              </div>
                            )}
                          />
                        )}
                        <div className="col-sm-4"></div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="email">Location</label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="location"
                          name="location"
                          ref={register2({
                            required: false,
                          })}
                        />
                        <ErrorMessage
                          errors={errors2}
                          name="location"
                          render={({ message }) => (
                            <div className="login_validation">{message}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <h1 className="modal_sub_head">Permissions:</h1>
                      <div className="col-sm-4">
                        <input
                          style={{ cursor: "pointer" }}
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={1}
                          checked={isCheckedAll}
                          // defaultChecked={isCheckedAll ? true : false}
                          onChange={(e: any) => handleAllChecked(e)}
                        />
                        <label htmlFor="checkbox1">
                          <span /> Select All
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          style={{ cursor: "pointer" }}
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={1}
                          checked={isRemovedAll}
                          onChange={(e: any) => handleAllRemoved(e)}
                        />
                        <label htmlFor="checkbox1">
                          <span /> De-select All
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="modal_sub_head1">Approval</div>
                    </div>
                    <div className="col-sm-4">
                      <input
                        id="checkbox861"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={1}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox18">
                        <span /> Vendors registration
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <input
                        id="checkbox862"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={2}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox869">
                        <span /> Clients job posts
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <input
                        id="checkbox164"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={3}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox12">
                        <span /> Career Tips
                      </label>
                    </div>
                    <div className="col-sm-12">
                      <div className="modal_sub_head1">Site Settings </div>
                    </div>
                    <div className="col-sm-4">
                      <input
                        id="checkbox921"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={4}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox112">
                        <span /> My team
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <input
                        id="checkbox155"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={5}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox121">
                        <span /> Stories of JIT
                      </label>
                    </div>

                    <div className="col-sm-4">
                      <input
                        id="checkbox145"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={8}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox154">
                        <span /> Add job title
                      </label>
                    </div>

                    <div className="col-sm-4">
                      <input
                        id="checkbox12244"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={7}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox451">
                        <span /> Add job category
                      </label>
                    </div>

                    <div className="col-sm-6">
                      <input
                        id="checkbox112"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={6}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox132">
                        <span />
                        Company contacts and social media details
                      </label>
                    </div>

                    <div className="clearfix" />
                    <div className="col-sm-12">
                      <div className="modal_sub_head1">Reports </div>
                    </div>
                    <div className="col-sm-4">
                      <input
                        id="checkbox164"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={9}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox14444444">
                        <span /> Client wise
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <input
                        id="checkbox761"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={10}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox16643">
                        <span /> Vendor wise{" "}
                      </label>
                    </div>
                    <div className="clearfix" />
                    <div className="col-sm-12">
                      <div className="modal_sub_head1">Job applications</div>
                    </div>
                    <div className="col-sm-4">
                      <input
                        id="checkbox1643"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={11}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox154">
                        <span /> Create and Edit Job posts
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        id="checkbox14367"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={12}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox16336">
                        <span /> View and Process job applications
                      </label>
                    </div>
                    <div className="clearfix" />
                    <div className="col-sm-12">
                      <div className="modal_sub_head1">Client Profiling </div>
                    </div>
                    <div className="col-sm-6">
                      <input
                        id="checkbox16566653"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={13}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox1">
                        <span /> Create and Edit client's Profile
                      </label>
                    </div>
                    <div className="clearfix" />
                    <div className="col-sm-12">
                      <div className="modal_sub_head1">Career Developer</div>
                    </div>
                    <div className="col-sm-4">
                      <input
                        id="checkbox133332"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={14}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox1808">
                        <span /> Add new categories
                      </label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        id="checkbox177"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={15}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox168767">
                        <span /> Add new video under category
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <input
                        id="checkbox6861"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={16}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox1767">
                        <span /> Delete category
                      </label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        id="checkbox65681"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={17}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox178">
                        <span /> Delete video under category
                      </label>
                    </div>
                    <div className="col-sm-7">
                      <input
                        id="checkbox145"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={18}
                        ref={register2({
                          required: false,
                        })}
                      />
                      <label htmlFor="checkbox1665">
                        <span /> Update video under category
                      </label>
                    </div>
                    <div className="clearfix" />
                  </Modal.Body>
                  <div className="modal-footer  m-t-30">
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="submit"
                    >
                      Save
                    </button>
                    <button
                      data-dismiss="modal"
                      className="btn btn-default waves-effect"
                      type="button"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="clearfix" />
                </form>
              </Modal>

              <div className="section_box2">
                <h1>Users</h1>
                <div className="row">
                  <div
                    className="admin_dash"
                    style={{ overflow: "hidden", outline: "none" }}
                    tabIndex={0}
                  >
                    <div className="users_admin_m">
                      {/* user Start */}
                      <Scrollbars
                        style={{ height: 450 }}
                        autoHide
                        renderThumbVertical={({ style, ...props }) => (
                          <div
                            {...props}
                            style={{
                              ...style,
                              position: "relative",
                              display: "block",
                              width: "5px",
                              cursor: "pointer",
                              borderRadius: "inherit",
                              backgroundColor: "rgb(73, 69, 69)",
                              height: "115px",
                            }}
                          />
                        )}
                      >
                        {superAdminUsersList.data != undefined
                          ? superAdminUsersList.data.map((data, i) => {
                            return (
                              <div className="col-sm-4">
                                <div className="users_admin1">
                                  <div className="action_btn1">
                                    {/* <button
                                      type="button"
                                      onClick={() => {
                                        setIsListShow(false);
                                        
                                        setIsListShowWithIndex(data.adminId);
                                      }}
                                      className="actions_user"
                                    >
                                      <i
                                        className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                        aria-hidden="true"
                                      />
                                    </button> */}

                                    <Dropdown
                                      overlay={
                                        <div className="action_sec">
                                          <Menu
                                            style={{
                                              backgroundColor: "ghostwhite",
                                              width: 100,
                                            }}
                                          >
                                            <Menu.Item key="0">
                                              <li
                                                onClick={() => {
                                                  handleInvitationClick(
                                                    data.mobileNumber,
                                                    data.email,
                                                    data.countryCode,
                                                    data.generatePassword
                                                  );
                                                  setIsListShowWithIndex(
                                                    data.adminId
                                                  );
                                                }}
                                              >
                                                <a>Invite </a>
                                              </li>
                                            </Menu.Item>
                                            <Menu.Divider />
                                            <Menu.Item key="1">
                                              <li
                                                onClick={(id: any) =>
                                                  handleEditAdminClick(
                                                    data.adminId
                                                  )
                                                }
                                              >
                                                <a>Edit </a>
                                              </li>
                                            </Menu.Item>
                                            <Menu.Divider />
                                            <Menu.Item key="3">
                                              <Popconfirm
                                                title={
                                                  <div>
                                                    <p>
                                                      Are you sure delete this
                                                      Admin{" "}
                                                    </p>
                                                  </div>
                                                }
                                                onConfirm={(id: any) =>
                                                  handleAdminDelete(
                                                    data.adminId
                                                  )
                                                }
                                                onCancel={cancel}
                                                okText="Yes"
                                                cancelText="No"
                                              >
                                                <li
                                                // onClick={(id: any) =>
                                                //   handleAdminDelete(data.adminId)
                                                // }
                                                >
                                                  <a
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    Delete
                                                  </a>
                                                </li>
                                              </Popconfirm>
                                            </Menu.Item>
                                          </Menu>
                                        </div>
                                      }
                                      trigger={["click"]}
                                    >
                                      <button
                                        type="button"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <i
                                          className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </Dropdown>
                                    <div
                                      className="user_action"
                                      // style={{
                                      //   display:
                                      //     data.adminId == isListShowWithIndex
                                      //       ? "block"
                                      //       : "none",
                                      // }}
                                      tabIndex={-1}
                                    >
                                      <div className="action_sec">
                                        {/* <ul ref={menuRef}>
                                          <li
                                            onClick={() => {
                                              handleInvitationClick(
                                                data.mobileNumber,
                                                data.email,
                                                data.countryCode,
                                                data.generatePassword
                                              );
                                              setIsListShowWithIndex(
                                                data.adminId
                                              );
                                            }}
                                          >
                                            <a>Invite </a>
                                          </li>
                                          <li
                                            onClick={(id: any) =>
                                              handleEditAdminClick(data.adminId)
                                            }
                                          >
                                            <a>Edit </a>
                                          </li>
                                          <Popconfirm
                                            title={
                                              <div>
                                                <p>
                                                  Are you sure delete this Admin{" "}
                                                </p>
                                              </div>
                                            }
                                            onConfirm={(id: any) =>
                                              handleAdminDelete(data.adminId)
                                            }
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <li
                                            // onClick={(id: any) =>
                                            //   handleAdminDelete(data.adminId)
                                            // }
                                            >
                                              <a style={{ cursor: "pointer" }}>
                                                Delete
                                              </a>
                                            </li>
                                          </Popconfirm>
                                        </ul>
                                       */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="clearfix" />
                                  <div className="admin_user">
                                    <img
                                      className="img-responsive"
                                      src={require("./../../../images/profileDefault1.jpg")}
                                    ></img>
                                  </div>
                                  <div className="admins_names">
                                    {data.adminName}
                                  </div>
                                  <div className="admins_contact1">
                                    {" "}
                                    <i
                                      className="fa fa-phone"
                                      aria-hidden="true"
                                    />{" "}
                                    {data.mobileNumber}
                                  </div>
                                  <div
                                    className="admins_contact1"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "flex",
                                    }}
                                  >
                                    <i
                                      style={{ padding: 3, marginLeft: 10 }}
                                      className="fa fa-envelope-o"
                                      aria-hidden="true"
                                    />{" "}
                                    {data.email}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="section_box2">
                <h1>Messages</h1>
                <div
                  className="admin_dash"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 468 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {messageListFlag === 1 ? (
                      messageList
                    ) : (
                      <div>You have no messages!</div>
                    )}
                  </Scrollbars>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="section_box2">
                <h1>Report</h1>
                <div className="clearfix" />
                <div className="dash_report_sec">
                  <div className="report_co">Approved</div>
                  <div className="report_co1">
                    {totalReportList.totalJObsCountInfos != undefined
                      ? totalReportList.totalJObsCountInfos.noofTotalAprovedJobs
                      : null}
                  </div>
                </div>
                <div className="dash_report_sec">
                  <div className="report_co">Published</div>
                  <div className="report_co1">
                    {" "}
                    {totalReportList.totalJObsCountInfos != undefined
                      ? totalReportList.totalJObsCountInfos
                        .nooTotalPublishedJobs
                      : null}
                  </div>
                </div>
                <div className="dash_report_sec">
                  <div className="report_co">Viewed</div>
                  <div className="report_co1">
                    {totalReportList.totalJObsCountInfos != undefined
                      ? totalReportList.totalJObsCountInfos.noofTotalViewedJobs
                      : null}
                  </div>
                </div>
                <div className="dash_report_sec">
                  <div className="report_co">Processed</div>
                  <div className="report_co1">
                    {" "}
                    {totalReportList.totalJObsCountInfos != undefined
                      ? totalReportList.totalJObsCountInfos
                        .noofTotalProcessedJobs
                      : null}
                  </div>
                </div>
                <div className="dash_report_sec">
                  <div className="report_co">Created</div>
                  <div className="report_co1">
                    {totalReportList.totalClientsCountInfos != undefined
                      ? totalReportList.totalClientsCountInfos
                        .noofTotalCreatedClients
                      : null}
                  </div>
                </div>
                <div className="dash_report_sec">
                  <div className="report_co">Deleted</div>
                  <div className="report_co1">
                    {totalReportList.totalClientsCountInfos != undefined
                      ? totalReportList.totalClientsCountInfos
                        .noofTotalDeletedClients
                      : null}
                  </div>
                </div>
                <div className="dash_report_sec">
                  <div className="report_co">Import</div>
                  <div className="report_co1">
                    {totalReportList.totalDatabaseCountInfos != undefined
                      ? totalReportList.totalDatabaseCountInfos
                        .noofTotalImpoertDB
                      : null}
                  </div>
                </div>
                <div className="dash_report_sec">
                  <div className="report_co">Export</div>
                  <div className="report_co1">
                    {" "}
                    {totalReportList.totalDatabaseCountInfos != undefined
                      ? totalReportList.totalDatabaseCountInfos
                        .noofTotalExportDB
                      : null}
                  </div>
                </div>
              </div>
            </div>
            {/* BEGIN MODAL */}
            <div className="attach_docs">
              <div className="modal fade none-border" id="database_setup">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        aria-hidden="true"
                        data-dismiss="modal"
                        className="close"
                        type="button"
                      >
                        ×
                      </button>
                      <h4 className="modal-title">Setup Automatic Backup </h4>
                    </div>
                    <div className="modal-body">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">Frequency</label>
                          <span className="select-wrapper-sec">
                            <select
                              name="timepass"
                              className="custom-select-sec"
                            >
                              <option value="">Select</option>
                              <option value="">Dummy</option>
                              <option value="">Dummy</option>
                            </select>
                            <span className="holder">Select</span>
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">Time</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="time"
                          />
                          <div className="time_icon">
                            <img src="images/time_icon.png" />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            id="checkbox1"
                            type="checkbox"
                            name="checkbox"
                            defaultValue={1}
                          />
                          <label htmlFor="checkbox1">
                            <span /> Notify me Via Email
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            id="checkbox1"
                            type="checkbox"
                            name="checkbox"
                            defaultValue={1}
                          />
                          <label htmlFor="checkbox1">
                            <span /> Notify me via Message{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer  m-t-30">
                      <button
                        className="btn btn-success save-event waves-effect waves-light"
                        type="button"
                      >
                        Save
                      </button>
                      <button
                        data-dismiss="modal"
                        className="btn btn-default waves-effect"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END MODAL */}
            <div className="col-sm-6">
              <div className="section_box2">
                <h1>Notifications</h1>
                <div
                  className="dash_noti_scroll"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 230 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {listFlag === 1 ? (
                      list
                    ) : (
                      <div>You have no notifications!</div>
                    )}
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      {/*=================Content Section End================*/}
    </>
  );
};

export default SuperAdminDashboardComponent;
