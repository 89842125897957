import React, { useState } from "react";
import {
  getJobCategoryList,
  useJobCategoryContext,
  useJobCategoryDispatcher,
} from "../../../action/JobCategoryAction";
import {
  getMatchedJobList,
  useMatchedJobContext,
  useMatchedJobDispatcher,
} from "../../../action/MatchedJobAction";
import {
  matchedJob,
  matchedJobRequestModel,
} from "../../../models/candidate/MatchedJob";
import CandidateLayoutComponent from "../layout/CandidateLayoutComponent";
import { Scrollbars } from "react-custom-scrollbars";
import {
  deleteJobAlertList,
  getAllJobAlertList,
  getJobAlertList,
  getTitleWithCategoryId,
  setJobAlertCategoryMapSave,
  setJobAlertSave,
  setJobAlertTypeMapSave,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import {
  jobAlertCategoryMapRequestModel,
  jobAlertGellAllRequestModel,
  jobAlertRequestmodel,
  jobAlertSaveRequestModel,
  JobAlertTypeMapSaveRequestModel,
  jobAlert,
  jobAlertTitleDropdownResultModel,
  jobAlertGetAllModel,
} from "../../../models/candidate/JobAlert";
import AuthService from "../../../services/AuthService";
import {
  getLoggedUserId,
  getMyProfileBasicInfo,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import {
  saveBookMarkList,
  useJobBookMarkContext,
  useJobBookMarkDispatcher,
} from "../../../action/candidate/JobBookMarkAction";
import {
  saveJobApplied,
  useJobAppliedContext,
  useJobAppliedDispatcher,
} from "../../../action/JobAppliedAction";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../my_profile/components/SelectOption";
import {
  getDigiLockerExpiringDocumentList,
  useDigiLockerContext,
} from "../../../action/candidate/DigiLockerAction";
import { jobAppliedSaveRequestModel } from "../../../models/candidate/JobApplied";
import { jobBookMarkSaveRequestModel } from "../../../models/candidate/BookMarkedJob";
import {
  FacebookShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { useJobTypeContext } from "../../../action/general/JobTypeAction";
import { useCandidateContext } from "../../../context/candidate/CandidateMyProfile";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Progress } from "antd";
import { digiLockerGetDataWithId } from "../../../models/candidate/DigiLocker";
import { ErrorMessage } from "@hookform/error-message";
import { categoryWithId } from "../../../models/candidate/JobCategory";

interface ReactSelectOption {
  value: string;
  label: string;
}
interface ICandidateDashboardComponentProps {}

interface ICandidateDashboardComponentState {
  jobAlertListData: jobAlertGetAllModel[];
  jobCategorys: string;
  jobTypeData: string;
  countNext: number;
  carouselValue: number;
  MatchedJobDetails: matchedJob;
  currentJobId: number;
  filterFlag: boolean;
  updated: boolean;
  tempData: jobAlertTitleDropdownResultModel[];
}

const defaultValues = {
  jobAlertListData: [] as jobAlertGetAllModel[],
  jobTypeData: "",
  jobCategorys: "",
  countNext: 0,
  carouselValue: 0,
  MatchedJobDetails: {} as matchedJob,
  currentJobId: 0,
  filterFlag: true,
  updated: false,
  tempData: [],
};
const authorizationToken = AuthService.accessToken;

const CandidateDashboardComponentThree: React.FC<ICandidateDashboardComponentProps> = (
  props
) => {
  const [
    CandidateDashboardComponentState,
    setCandidateDashboardComponentState,
  ] = React.useState<ICandidateDashboardComponentState>(defaultValues);

  let jobCategoryData: ReactSelectOption[] = [];
  let jobTypeDatas: ReactSelectOption[] = [];
  let jobTitleData: ReactSelectOption[] = [];
  const {
    jobAlertListData,
    jobCategorys,
    jobTypeData,
    currentJobId,
    filterFlag,
    updated,
    tempData,
  } = CandidateDashboardComponentState;

  const jobCategoryDispatcher = useJobCategoryDispatcher();
  const jobCategoryContext = useJobCategoryContext();
  const { jobCategory } = jobCategoryContext;

  const matchedJobDispatcher = useMatchedJobDispatcher();
  const matchedJobContext = useMatchedJobContext();
  const { matchedJob } = matchedJobContext;

  const jobAlertDispatcher = useJobAlertDispatcher();
  const jobAlertContext = useJobAlertContext();
  const {
    jobAlert,
    jobAlertGetAll,
    jobAlertSaveRespond,
    jobAlertTitleDropdownResult,
  } = jobAlertContext;

  const myProfileContext = useMyProfileContext();
  const {
    basicInfo,
    loggedUserId,
    myProfile,
    myProfileProgressBar,
    profileImage,
  } = myProfileContext;

  const jobBookMarkContext = useJobBookMarkContext();
  const { jobBookMark } = jobBookMarkContext;
  const myProfileDispatcher = useMyProfileDispatcher();
  const jobAppliedContext = useJobAppliedContext();
  const { jobApplied } = jobAppliedContext;

  const candidateContext = useCandidateContext();
  const { getCandidates, candidateState } = candidateContext;

  let history = useHistory();
  let user = AuthService.currentUser;

  //console.log(55555, basicInfo);

  const handleCompleteClick = () => {
    history.push("/my-profile");
  };

  const digiLockerMarkContext = useDigiLockerContext();
  const {
    digiLockerType,
    digiLockerExpiringDocument,
    digiLockers,
  } = digiLockerMarkContext;

  const jobTypeContext = useJobTypeContext();
  const { allCounts } = jobTypeContext;

  //console.log("jobAlertGetAll", jobAlertGetAll);
  //console.log("allCounts", allCounts);

  const jobAppliedDispatcher = useJobAppliedDispatcher();
  const jobBookMarkDispatcher = useJobBookMarkDispatcher();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoder, setISLoder] = useState<boolean>(false);
  const { carouselValue, countNext } = CandidateDashboardComponentState;
  const [
    currentPageOfDigiLockerList,
    setCurrentPageOfDigiLockerList,
  ] = useState(1);
  const [currentPageOfExpDocList, setCurrentPageOfExpDocList] = useState(1);
  const [currentId, setCurrentId] = useState(1);

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
  } = useForm({
    defaultValues,
  });

  const watchAllFields = watch2();
  //console.log(3434, watchAllFields);
  //console.log(3535, jobAlertTitleDropdownResult);
  //console.log(3636, parseInt(watchAllFields.jobCategorys));
  //console.log(3737, tempData);
  React.useEffect(() => {
    setCandidateDashboardComponentState((CandidateDashboardComponentState) => {
      return {
        ...CandidateDashboardComponentState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);

  React.useEffect(() => {
    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  const handleCarouselNextClick = () => {
    let num = matchedJob.data != undefined ? matchedJob.data.length : 4;
    let value = num - 4;
    if (countNext < value && value >= 1) {
      setCandidateDashboardComponentState(
        (CandidateDashboardComponentState) => {
          return {
            ...CandidateDashboardComponentState,
            countNext: countNext + 1,
            carouselValue: carouselValue + -225,
          };
        }
      );
      if (countNext % 9 === 0) {
        setCurrentPageOfExpDocList(currentPageOfExpDocList + 1);
      }
    } else {
      setCandidateDashboardComponentState(
        (CandidateDashboardComponentState) => {
          return {
            ...CandidateDashboardComponentState,
            countNext: 0,
            carouselValue: 0,
          };
        }
      );
    }
  };

  const handleCarouselPreviousClick = () => {
    let num = matchedJob.data != undefined ? matchedJob.data.length : 4;
    let value = num / 4;
    if (countNext > 0) {
      setCandidateDashboardComponentState(
        (CandidateDashboardComponentState) => {
          return {
            ...CandidateDashboardComponentState,
            countNext: countNext - 1,
            carouselValue: carouselValue + 225,
          };
        }
      );
      if (countNext % 9 === 0) {
        setCurrentPageOfExpDocList(
          currentPageOfExpDocList != 1 ? currentPageOfExpDocList - 1 : 1
        );
      }
    } else {
      setCandidateDashboardComponentState(
        (CandidateDashboardComponentState) => {
          return {
            ...CandidateDashboardComponentState,
            countNext: 0,
            carouselValue: 0,
          };
        }
      );
    }
  };

  // React.useEffect(() => {
  //   if (authorizationToken != null)
  //     (async () => {
  //       await getJobAlertList(
  //         jobAlertDispatcher,
  //         {
  //           candidateId: loggedUserId,
  //           expereince: "",
  //           lastDays: 0,
  //           title: "",
  //           type: "",
  //           location: "",
  //           pageIndex: 1,
  //           pageSize: 10,
  //           showInactive: true,
  //         } as jobAlertRequestmodel,
  //         authorizationToken
  //       );
  //     })();
  // }, [authorizationToken]);

  React.useEffect(() => {
    if (authorizationToken != null)
      (async () => {
        await getJobCategoryList(jobCategoryDispatcher, authorizationToken);
        setISLoder(true);
      })();
  }, [authorizationToken]);

  // React.useEffect(() => {
  //   if(jobAlertGetAll.data!=undefined)
  //   setCandidateDashboardComponentState({
  //     ...CandidateDashboardComponentState,
  //     jobAlertListData:jobAlertGetAll.data.length>0?jobAlertListData.concat(jobAlertGetAll.data):jobAlertGetAll.data
  // });
  // }, [])

  React.useEffect(() => {
    if (authorizationToken != null) {
      //   setCandidateDashboardComponentState({
      //     ...CandidateDashboardComponentState,
      //   jobAlertListData:[]
      //  });
      (async () => {
        getAllJobAlertList(
          jobAlertDispatcher,
          {
            candidateId: loggedUserId,
            page: currentPage,
            pageSize: 10,
            searchTerm: "",
            sortOrder: "",
            showInactive: true,
          } as jobAlertGellAllRequestModel,
          authorizationToken
        );
      })();
      setISLoder(false);
    }
  }, [authorizationToken]);

  const handleJobAlert = (data: any) => {
    setCandidateDashboardComponentState({
      ...CandidateDashboardComponentState,
      jobCategorys: data.jobCategorys,
      jobTypeData: data.jobTypeData,
    });

    // if (authorizationToken != null) {
    //   (async () => {
    //     await setJobAlertSave(
    //       jobAlertDispatcher,
    //       {
    //         rowId: 0,
    //         jobAlertId: 0,
    //         keywords: "",
    //         totalExperience: "",
    //         locationId: "",
    //         alertTitle: data.jobTitle,
    //         salaryFrom: 0,
    //         userId: loggedUserId,
    //         isActive: (data.radio = "1" ? true : false),
    //       } as jobAlertSaveRequestModel,
    //       authorizationToken
    //     );
    //   })();
    //   setIsOpen(!isOpen);
    // }
  };

  React.useEffect(() => {
    if (jobAlertSaveRespond != undefined && jobAlertSaveRespond.entityId != 0) {
      (async () => {
        await setJobAlertTypeMapSave(jobAlertDispatcher, {
          rowId: 0,
          jobAlertTypeMapId: 0,
          jobTypeId: parseInt(jobTypeData),
          jobAlertId: jobAlertSaveRespond.entityId,
          isActive: true,
        } as JobAlertTypeMapSaveRequestModel);
      })();
    }
  }, [jobAlertSaveRespond]);

  React.useEffect(() => {
    if (jobAlertSaveRespond != undefined && jobAlertSaveRespond.entityId != 0) {
      (async () => {
        await setJobAlertCategoryMapSave(jobAlertDispatcher, {
          rowId: 0,
          jobAlertCategoryMapId: 0,
          jobCategoryId: parseInt(jobCategorys),
          jobAlertId: jobAlertSaveRespond.entityId,
          isActive: true,
        } as jobAlertCategoryMapRequestModel);
        setISLoder(true);
      })();
    }
  }, [jobAlertSaveRespond]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getJobAlertList(
          jobAlertDispatcher,
          {
            candidateId: loggedUserId,
            expereince: "",
            lastDays: 0,
            title: "",
            type: "",
            location: "",
            pageIndex: 1,
            pageSize: 100,
            showInactive: false,
          } as jobAlertRequestmodel,
          authorizationToken
        );
      })();
    }
  }, [jobAlertSaveRespond, loggedUserId]);

  const handleScrollStop = () => {
    setIsScrolling(jobAlertGetAll.hasNext);

    if (jobAlertGetAll.hasNext && isScrolling && authorizationToken != null) {
      setCurrentPage(currentPage + 1);

      getAllJobAlertList(
        jobAlertDispatcher,
        {
          candidateId: loggedUserId,
          page: currentPage,
          pageSize: 10,
          searchTerm: "",
          sortOrder: "",
          showInactive: true,
        } as jobAlertGellAllRequestModel,
        authorizationToken
      );
      setIsScrolling(false);
    }
  };

  const handleJobApplied = (jobId: number) => {
    if (authorizationToken != null) {
      saveJobApplied(
        jobAppliedDispatcher,
        {
          isActive: true,
          jobId: jobId,
          candidateId: loggedUserId,
          jobAppliedId: 0,
          rowId: 0,
        } as jobAppliedSaveRequestModel,
        authorizationToken
      );
    }
  };

  const handleDeleteJobAlert = (jobId: number) => {
    if (authorizationToken != null) {
      //console.log(666);
      deleteJobAlertList(
        jobAlertDispatcher,
        {
          rowId: jobId,
        } as digiLockerGetDataWithId,
        authorizationToken
      );
    }
  };

  const handleJobBookMarked = (jobId: number) => {
    if (authorizationToken != null) {
      saveBookMarkList(
        jobBookMarkDispatcher,
        {
          candidateId: loggedUserId,
          jobBookmarkedId: 0,
          jobId: jobId,
          isActive: true,
          rowId: 0,
        } as jobBookMarkSaveRequestModel,
        authorizationToken
      );
    }
  };

  const handleShareButtonClick = (id: any, value: any) => {
    setCandidateDashboardComponentState({
      ...CandidateDashboardComponentState,
      currentJobId: id,
      filterFlag: value,
    });
    setIsShareOpen(!isShareOpen);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="clearfix" />
          <div className="row m_t_30">
            <div className="col-sm-10">
              <div className="dash_sec_new1">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="dash_notification_new">
                      <div>
                        <img
                          src={require("./../../../images/jobs_saved.png")}
                          className="center-block"
                        />
                      </div>{" "}
                      <div>
                        {" "}
                        {allCounts.jobSaved != undefined
                          ? allCounts.jobSaved
                          : 0}{" "}
                        Jobs Saved
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification_new">
                      <div>
                        <img
                          src={require("./../../../images/jobs_applied.png")}
                          className="center-block"
                        />
                      </div>{" "}
                      <div>
                        {" "}
                        {allCounts.jobApplied != undefined
                          ? allCounts.jobApplied
                          : 0}{" "}
                        Jobs Applied
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification_new">
                      <div>
                        <img
                          src={require("./../../../images/shortlisted.png")}
                          className="center-block"
                        />
                      </div>{" "}
                      <div>
                        {allCounts.shortListed != undefined
                          ? allCounts.shortListed
                          : 0}{" "}
                        Shortlisted
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification_new">
                      <div>
                        <img
                          src={require("./../../../images/new_messages.png")}
                          className="center-block"
                        />
                      </div>{" "}
                      <div>
                        {" "}
                        {allCounts.newMessages != undefined
                          ? allCounts.newMessages
                          : 0}{" "}
                        Unread Messages
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification_new">
                      <div>
                        <img
                          src={require("./../../../images/new_alerts.png")}
                          className="center-block"
                        />
                      </div>{" "}
                      <div>
                        {" "}
                        {allCounts.newAlerts != undefined
                          ? allCounts.newAlerts
                          : 0}{" "}
                        New Alerts
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification_new">
                      <div>
                        <img
                          src={require("./../../../images/expiring_documents.png")}
                          className="center-block"
                        />
                      </div>{" "}
                      <div>
                        {" "}
                        {allCounts.expiryDigiDocs != undefined
                          ? allCounts.expiryDigiDocs.length > 0 &&
                            allCounts.expiryDigiDocs[0].documentCount !=
                              undefined
                            ? allCounts.expiryDigiDocs[0].documentCount +
                              allCounts.expiryDigiDocs[1].documentCount
                            : 0
                          : 0}{" "}
                        Expiring Documents
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <a
                href="#"
                data-target="#customie_dash"
                data-toggle="modal"
                className=" cutomise_but1"
              >
                <i className="fa fa-pencil" aria-hidden="true" /> &nbsp;
                Customize your Dashboard
              </a>
            </div>
            {/* BEGIN MODAL */}

            <div className="attach_docs">
              <div id="customie_dash" className="modal fade none-border">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        aria-hidden="true"
                        data-dismiss="modal"
                        className="close"
                        type="button"
                      >
                        ×
                      </button>
                      <h4 className="modal-title">Customise Your Dashboard </h4>
                    </div>
                    <div className="modal-body">
                      <div className="col-sm-6">
                        <img
                          src="images/cadidate_dash1.jpg"
                          className="img-responsive"
                        />
                      </div>
                    </div>
                    <div className="modal-footer  m-t-30">
                      <button
                        className="btn btn-success save-event waves-effect waves-light"
                        type="button"
                      >
                        Apply Changes
                      </button>
                      <button
                        data-dismiss="modal"
                        className="btn btn-default waves-effect"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </div>
            {/* END MODAL */}

            <div className="col-sm-12">
              <div className="section_box1">
                <h1>Matched Jobs</h1>
                <div className="row">
                  <div className="prodict_list">
                    <div
                      id="owl-demo1"
                      className="owl-carousel owl-theme"
                      style={{ opacity: 1, display: "block" }}
                    >
                      <div className="owl-wrapper-outer">
                        <div
                          className="owl-wrapper"
                          style={{
                            width: "3140px",
                            left: "0px",
                            display: "block",
                            transition: "all 1000ms ease 0s",
                            transform: `translate3d(${carouselValue}px, 0px, 0px)`,
                          }}
                        >
                          {matchedJob.data != undefined
                            ? matchedJob.data.map((data, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="owl-item"
                                    style={{ width: "314px" }}
                                  >
                                    <div className="item">
                                      <div className="matched_jobs_sec">
                                        <div className="matched_jobs">
                                          <div className="matched_jobs_cat_t">
                                            {data.jobType}
                                          </div>
                                          <div className="jobs_start1">
                                            {data.postedDate}
                                          </div>
                                          <div className="matched_star1">
                                            <i
                                              className="fa fa-star-o"
                                              aria-hidden="true"
                                              onClick={(jobId: any) =>
                                                handleJobBookMarked(data.rowId)
                                              }
                                            />
                                          </div>
                                          <div className="clearfix" />
                                          <div className="matched_heading">
                                            {data.title}
                                          </div>
                                          <div className="matched_jobs_cat">
                                            <i
                                              className="fa fa-map-marker"
                                              aria-hidden="true"
                                            />{" "}
                                            San Fransisco
                                          </div>
                                          <div className="matched_jobs_cat text-right">
                                            <i
                                              className="fa fa-clock-o"
                                              aria-hidden="true"
                                            />{" "}
                                            {data.categoryName}
                                          </div>
                                          <div className="clearfix" />
                                          <p>{data.description}</p>
                                          <div className="matched_jobs_share">
                                            <a
                                              onClick={() =>
                                                handleShareButtonClick(
                                                  data.rowId,
                                                  true
                                                )
                                              }
                                              data-target="#share"
                                              data-toggle="modal"
                                            >
                                              <i
                                                className="fa fa-share-alt"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </div>
                                          <div className="jobs_apply">
                                            <a
                                              href="#"
                                              data-target="#apply_now_pop"
                                              data-toggle="modal"
                                              onClick={(jobId: any) =>
                                                handleJobApplied(data.rowId)
                                              }
                                            >
                                              Apply Now
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}

                          {/* <div className="owl-item" style={{width: '314px'}}>
                <div className="item"> 
                    <div className="matched_jobs_sec">
                      <div className="matched_jobs">
                        <div className="matched_jobs_cat_t">Design</div> 
                        <div className="jobs_start1">2 days ago</div> 
                        <div className="matched_star1"><i className="fa fa-star-o" aria-hidden="true" /></div> 
                        <div className="clearfix" />
                        <div className="matched_heading">UI/Designer</div>
                        <div className="matched_jobs_cat"><i className="fa fa-map-marker" aria-hidden="true" />  San Fransisco</div> 
                        <div className="matched_jobs_cat text-right"><i className="fa fa-clock-o" aria-hidden="true" /> Part Time</div> 
                        <div className="clearfix" />
                        <p>Develop design, implement, and test application software....</p>
                        <div className="matched_jobs_share"><a onClick={()=>handleShareButtonClick(1,true)} data-target="#share" data-toggle="modal"><i className="fa fa-share-alt" aria-hidden="true" /></a></div>
                        <div className="jobs_apply"><a data-target="#apply_now_pop" data-toggle="modal">Apply Now</a></div>
                      </div>  
                    </div>
                  </div>
                  </div> */}

                          {/*                  
                  <div className="owl-item" style={{width: '314px'}}>
                    <div className="item"> 
                    <div className="matched_jobs_sec">
                      <div className="matched_jobs">
                        <div className="matched_jobs_cat_t">Design</div> 
                        <div className="jobs_start1">2 days ago</div> 
                        <div className="matched_star1"><i className="fa fa-star-o" aria-hidden="true" /></div> 
                        <div className="clearfix" />
                        <div className="matched_heading">UI/Designer</div>
                        <div className="matched_jobs_cat"><i className="fa fa-map-marker" aria-hidden="true" />  San Fransisco</div> 
                        <div className="matched_jobs_cat text-right"><i className="fa fa-clock-o" aria-hidden="true" /> Part Time</div> 
                        <div className="clearfix" />
                        <p>Develop design, implement, and test application software....</p>
                        <div className="matched_jobs_share"><a href="#" data-target="#share" data-toggle="modal"><i className="fa fa-share-alt" aria-hidden="true" /></a></div>
                        <div className="jobs_apply"><a href="#" data-target="#apply_now_pop" data-toggle="modal">Apply Now</a></div>
                      </div>  
                    </div>
                  </div></div><div className="owl-item" style={{width: '314px'}}><div className="item"> 
                    <div className="matched_jobs_sec">
                      <div className="matched_jobs">
                        <div className="matched_jobs_cat_t">Design</div> 
                        <div className="jobs_start1">2 days ago</div> 
                        <div className="matched_star1"><i className="fa fa-star-o" aria-hidden="true" /></div> 
                        <div className="clearfix" />
                        <div className="matched_heading">UI/Designer</div>
                        <div className="matched_jobs_cat"><i className="fa fa-map-marker" aria-hidden="true" />  San Fransisco</div> 
                        <div className="matched_jobs_cat text-right"><i className="fa fa-clock-o" aria-hidden="true" /> Part Time</div> 
                        <div className="clearfix" />
                        <p>Develop design, implement, and test application software....</p>
                        <div className="matched_jobs_share"><a href="#" data-target="#share" data-toggle="modal"><i className="fa fa-share-alt" aria-hidden="true" /></a></div>
                        <div className="jobs_apply"><a href="#" data-target="#apply_now_pop" data-toggle="modal">Apply Now</a></div>
                      </div>   
                    </div>
                  </div></div><div className="owl-item" style={{width: '314px'}}><div className="item"> 
                    <div className="matched_jobs_sec">
                      <div className="matched_jobs">
                        <div className="matched_jobs_cat_t">Design</div> 
                        <div className="jobs_start1">2 days ago</div> 
                        <div className="matched_star1"><i className="fa fa-star-o" aria-hidden="true" /></div> 
                        <div className="clearfix" />
                        <div className="matched_heading">UI/Designer</div>
                        <div className="matched_jobs_cat"><i className="fa fa-map-marker" aria-hidden="true" />  San Fransisco</div> 
                        <div className="matched_jobs_cat text-right"><i className="fa fa-clock-o" aria-hidden="true" /> Part Time</div> 
                        <div className="clearfix" />
                        <p>Develop design, implement, and test application software....</p>
                        <div className="matched_jobs_share"><a href="#" data-target="#share" data-toggle="modal"><i className="fa fa-share-alt" aria-hidden="true" /></a></div>
                        <div className="jobs_apply"><a href="#" data-target="#apply_now_pop" data-toggle="modal">Apply Now</a></div>
                      </div>   
                    </div>
                  </div></div><div className="owl-item" style={{width: '314px'}}><div className="item"> 
                    <div className="matched_jobs_sec">
                      <div className="matched_jobs">
                        <div className="matched_jobs_cat_t">Design</div> 
                        <div className="jobs_start1">2 days ago</div> 
                        <div className="matched_star1"><i className="fa fa-star-o" aria-hidden="true" /></div> 
                        <div className="clearfix" />
                        <div className="matched_heading">UI/Designer</div>
                        <div className="matched_jobs_cat"><i className="fa fa-map-marker" aria-hidden="true" />  San Fransisco</div> 
                        <div className="matched_jobs_cat text-right"><i className="fa fa-clock-o" aria-hidden="true" /> Part Time</div> 
                        <div className="clearfix" />
                        <p>Develop design, implement, and test application software....</p>
                        <div className="matched_jobs_share"><a href="#" data-target="#share" data-toggle="modal"><i className="fa fa-share-alt" aria-hidden="true" /></a></div>
                        <div className="jobs_apply"><a href="#" data-target="#apply_now_pop" data-toggle="modal">Apply Now</a></div>
                      </div>  
                    </div>
                  </div></div> */}
                        </div>
                      </div>
                      <div className="owl-controls clickable">
                        <div className="owl-pagination">
                          <div className="owl-page active">
                            <span className="" />
                          </div>
                          <div className="owl-page">
                            <span className="" />
                          </div>
                        </div>
                        <div className="owl-buttons">
                          <div
                            className="owl-prev"
                            onClick={handleCarouselPreviousClick}
                          >
                            <img
                              src={require("../../../images/products_ar1.png")}
                            />{" "}
                          </div>
                          <div
                            className="owl-next"
                            onClick={handleCarouselNextClick}
                          >
                            <img
                              src={require("../../../images/products_ar2.png")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* BEGIN MODAL */}
            <div className="modal fade none-border" id="share">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      aria-hidden="true"
                      data-dismiss="modal"
                      className="close"
                      type="button"
                    >
                      ×
                    </button>
                    <h4 className="modal-title">Share </h4>
                  </div>
                  <div className="col-sm-12 m_t_30 text-center">
                    <div className="social1">
                      <a href="#" className="social_face">
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                      <a href="#" className="social_twit">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                      <a href="#" className="social_insta">
                        <i className="fa fa-instagram" aria-hidden="true" />
                      </a>
                      <a href="#" className="social_youtube">
                        <i className="fa fa-youtube-play" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                  <div className="modal-footer  m-t-30"></div>
                </div>
              </div>
            </div>
            {/* END MODAL */}
            <div className="col-sm-4">
              <div className="resume_build_dash">
                <h1>Resume Builder</h1>
                <div className="resume_build_dash_con">
                  {" "}
                  My Resume -{" "}
                  {allCounts.resumeCreated != undefined
                    ? allCounts.resumeCreated
                    : 0}
                </div>
                <div className="progress_sec1">
                  <Progress
                    strokeColor={"#1dbc03"}
                    percent={myProfileProgressBar.profileProgress}
                  ></Progress>
                  <Link
                    to="/candidate/my-resume/resume/my_information"
                    className="btn resumebuild_but"
                  >
                    Resume Builder
                  </Link>
                  <div className="progress_con1">
                    {" "}
                    {myProfileProgressBar.profileProgress < 60 ? (
                      <>
                        {" "}
                        <Link to="/candidate/my-resume/resume/my_information">
                          Complete Now
                        </Link>{" "}
                        <span>{myProfileProgressBar.profileProgress}%</span>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* resume build end */}
            {/* digilocker start */}

            <div className="col-sm-4">
              <div className="digilocker_dash">
                <h1>
                  {" "}
                  {basicInfo.fullName != undefined
                    ? basicInfo.fullName.split(" ")[0]
                    : ""}
                  's
                </h1>
                <h2>
                  DIGI<span>Locker</span>
                </h2>
                <div className="digilocker_dash_con">
                  {" "}
                  <a onClick={() => history.push("/candidate/digilocker/3")}>
                    General Documents -{" "}
                    {allCounts.digiDocs != undefined &&
                    allCounts.digiDocs.length > 0 &&
                    allCounts.digiDocs[0].documentCount != undefined
                      ? allCounts.digiDocs[0].documentCount
                      : 0}
                  </a>{" "}
                </div>
                <div className="digilocker_dash_con">
                  <a onClick={() => history.push("/candidate/digilocker/2")}>
                    Personal Documents -{" "}
                    {allCounts.digiDocs != undefined &&
                    allCounts.digiDocs.length > 0
                      ? allCounts.digiDocs[1].documentCount
                      : 0}
                  </a>
                </div>
              </div>
            </div>
            {/* digilocker end */}

            {/* job Alert star */}

            <div className="col-sm-4">
              <div className="section_box2">
                <h1>Job Alert</h1>
                <div className="jobs_alert_btn">
                  <a
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    data-target="#job_alert"
                    data-toggle="modal"
                  >
                    Create Alert
                  </a>
                </div>
                <div className="clearfix" />
                <div className="jobs_alert_scroll" tabIndex={0}>
                  <Scrollbars
                    style={{ height: 230 }}
                    autoHide
                    // onScrollStop={()=>handleScrollStop()}
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {jobAlertGetAll.data != undefined
                      ? jobAlertGetAll.data.map((data, i) => {
                          return (
                            <div key={i} className="jobalert_sec">
                              <span
                                onClick={() => handleDeleteJobAlert(data.rowId)}
                                className="jobalert_sec_icon"
                              >
                                <img
                                  src={require("./../../../images/job_close.png")}
                                  className="img-responsive"
                                />
                              </span>
                              <div
                                onClick={() => {
                                  history.push(`job_search/${data.rowId}`);
                                }}
                                className="jobalert_con _cursor-pointer"
                              >
                                <div
                                  className={`jobalert_headings jobalert_clr${
                                    (i + 1) % 3 === 0 ? 3 : (i + 1) % 3
                                  }`}
                                >
                                  {/* {data.jobType} */}
                                </div>
                                <div className="jobalert_head _cursor-pointer">
                                  {/* {data.alertTitle} */}
                                </div>
                                {data.isActive ? (
                                  <div className="jobalert_active">active</div>
                                ) : (
                                  <div className="jobalert_inactive">
                                    Inactive
                                  </div>
                                )}
                                {/* <div className="jobalert_active">active</div> */}
                              </div>
                              <div className="jobalert_con1">
                                <div className="jobalert_time">
                                  {/* {data.postedDate} */}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </Scrollbars>
                </div>
              </div>
            </div>
            {/* job Alert end */}

            {/* BEGIN MODAL job Alert*/}

            <Modal
              show={isOpen}
              onHide={() => {
                setIsOpen(!isOpen);
              }}
            >
              <form onSubmit={handleSubmit2(handleJobAlert)} noValidate>
                <Modal.Header closeButton>
                  <Modal.Title>New Job Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Category
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="jobCategorys"
                        rules={{
                          required: "Job Category is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              myProfile.jobCategories != undefined
                                ? myProfile.jobCategories.map((e) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : jobCategoryData
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="jobCategorys"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Type
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        className="custom-select-sec"
                        control={control2}
                        name="jobTypeData"
                        rules={{
                          required: "Job Type is required",
                        }}
                        // rules={{
                        //   validate: (value) => {
                        //     return value === "" ? "Job Type is required" : "";
                        //   },
                        // }}
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              myProfile.jobTypes != undefined
                                ? myProfile.jobTypes.map((e) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : jobTypeDatas
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="jobTypeData"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Title
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="jobTitle"
                        rules={{
                          required: "Job Title is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              tempData != undefined
                                ? tempData.map((e) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : tempData
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                  </div>
                  <ErrorMessage
                    errors={errors2}
                    name="jobTitle"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                  <div className="form-group">
                    <label htmlFor="email">Location</label>
                    <input
                      type="text"
                      className="form-control "
                      name="location"
                      placeholder=" "
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      id="radio1"
                      type="radio"
                      ref={register2({
                        required: false,
                      })}
                      name="radio"
                      defaultValue={1}
                      defaultChecked
                    />
                    <label htmlFor="radio1">
                      <span>
                        <span />
                      </span>{" "}
                      Active
                    </label>
                    <input
                      id="radio2"
                      type="radio"
                      ref={register2({
                        required: false,
                      })}
                      name="radio"
                      defaultValue={2}
                    />
                    <label htmlFor="radio2" style={{ marginLeft: 1 }}>
                      <span>
                        <span />
                      </span>{" "}
                      Inactive
                    </label>
                  </div>
                </Modal.Body>
                <div className="modal-footer  m-t-30">
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                  >
                    Create Alert
                  </button>
                  <button
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    data-dismiss="modal"
                    className="btn btn-default waves-effect"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
                <div className="clearfix" />
              </form>
            </Modal>
            {/* END MODAL */}

            <div className="clearfix" />

            <div className="col-sm-6">
              <div className="section_box2">
                <h1>Notifications</h1>
                <div
                  className="dash_noti_scroll"
                  style={{ overflow: "hidden" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 230 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    <div className="notification_sec">
                      <a href="#">
                        <span className="notification_icon">
                          <img
                            src={require("./../../../images/1.jpg")}
                            className="img-responsive"
                          />
                        </span>
                        <div className="notification_con">
                          <div className="notification_head">
                            <strong>Stephan parker</strong>
                            has messaged you
                          </div>
                          <div className="notification_pra">4 days ago</div>
                        </div>
                      </a>
                    </div>
                    <div className="notification_sec">
                      <a href="#">
                        <span className="notification_icon">
                          <img
                            src={require("./../../../images/2.jpg")}
                            className="img-responsive"
                          />
                        </span>
                        <div className="notification_con">
                          <div className="notification_head">
                            <strong>24 Matched jobs</strong>
                            for you. Review them.
                          </div>
                          <div className="notification_pra">4 days ago</div>
                        </div>
                      </a>
                    </div>
                    <div className="notification_sec">
                      <a href="#">
                        <span className="notification_icon">
                          <img
                            src={require("./../../../images/3.jpg")}
                            className="img-responsive"
                          />
                        </span>
                        <div className="notification_con">
                          <div className="notification_head">
                            <strong>2 Documents are getting expired</strong>{" "}
                            this month. Re-new them now.
                          </div>
                          <div className="notification_pra">4 days ago</div>
                        </div>
                      </a>
                    </div>
                    <div className="notification_sec">
                      <a href="#">
                        <span className="notification_icon">
                          <img
                            src={require("./../../../images/3.jpg")}
                            className="img-responsive"
                          />
                        </span>
                        <div className="notification_con">
                          <div className="notification_head">
                            <strong>2 Documents are getting expired</strong>{" "}
                            this month. Re-new them now.
                          </div>
                          <div className="notification_pra">4 days ago</div>
                        </div>
                      </a>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="section_box2">
                <h1>Messages</h1>
                <div
                  className="dash_message_scroll"
                  style={{ overflow: "hidden" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 230 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    <div className="message_sec">
                      <a href="message.html">
                        <span className="message_icon">
                          <img
                            src={require("./../../../images/4.jpg")}
                            className="img-responsive"
                          />
                        </span>
                        <div className="message_con">
                          <div className="message_head">Alan Mathew </div>
                          <div className="message_pra">
                            Hi, Share your Aadhar card please!
                          </div>
                        </div>
                        <div className="message_noti">
                          <div className="message_noti_con">1 day ago</div>
                          <div className="message_noti_count">2</div>
                        </div>
                      </a>
                    </div>
                    <div className="message_sec">
                      <a href="message.html">
                        <span className="message_icon">
                          <img
                            src={require("./../../../images/5.jpg")}
                            className="img-responsive"
                          />
                        </span>
                        <div className="message_con">
                          <div className="message_head">Helen</div>
                          <div className="message_pra">
                            Please find below form.
                          </div>
                        </div>
                        <div className="message_noti">
                          <div className="message_noti_con">3 day ago</div>
                          <div className="message_noti_count">2</div>
                        </div>
                      </a>
                    </div>
                    <div className="message_sec">
                      <a href="message.html">
                        <span className="message_icon">
                          <img
                            src={require("./../../../images/6.jpg")}
                            className="img-responsive"
                          />
                        </span>
                        <div className="message_con">
                          <div className="message_head">Udai</div>
                          <div className="message_pra">
                            Share your availability for an interview.
                          </div>
                        </div>
                        <div className="message_noti">
                          <div className="message_noti_con">5 day ago</div>
                          <div className="message_noti_count">5</div>
                        </div>
                      </a>
                    </div>
                    <div className="message_sec">
                      <a href="message.html">
                        <span className="message_icon">
                          <img
                            src={require("./../../../images/6.jpg")}
                            className="img-responsive"
                          />
                        </span>
                        <div className="message_con">
                          <div className="message_head">Udai</div>
                          <div className="message_pra">
                            Share your availability for an interview.
                          </div>
                        </div>
                        <div className="message_noti">
                          <div className="message_noti_con">5 day ago</div>
                          <div className="message_noti_count">5</div>
                        </div>
                      </a>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </>
  );
};

export default CandidateDashboardComponentThree;
