import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import { useImmerReducer } from "use-immer";

import {
  Banner,
  GetAllBannerRespond,
  GetAllBannerRespondModel,
  GetAllBannerRespondModelForEdit,
} from "../../models/admin/Banner";
import React from "react";
import { bannerReducer, IBannerAction } from "../../action/admin/BannerAction";
export const BannerDispatcherContext = React.createContext<React.Dispatch<IBannerAction> | null>(
  null
);
export const BannerStateContext = React.createContext<IBannerManagementState | null>(
  null
);

export interface IBannerManagementProps extends RouteComponentProps<any> { }

export interface IBannerManagementState {
  bannersList: GetAllBannerRespondModel;
  addBannerRespond: Banner;
  bannerStatusRespond: Banner;
  deleteBannerRespond: Banner;
  editBaneerRespond: GetAllBannerRespondModelForEdit;
}

export const initialBannerManagementState = {
  visible: false,
  value: 0,
  bannersList: {} as GetAllBannerRespondModel,
  addBannerRespond: {} as Banner,
  bannerStatusRespond: {} as Banner,
  deleteBannerRespond: {} as Banner,
  editBaneerRespond: {} as GetAllBannerRespondModelForEdit,
} as IBannerManagementState;

export const BannerContextProvider: React.FC = ({ children }) => {
  const [bannerState, dispatcher] = useImmerReducer(
    bannerReducer,
    initialBannerManagementState
  );

  return (
    <BannerDispatcherContext.Provider value={dispatcher}>
      <BannerStateContext.Provider value={bannerState}>
        {children}
      </BannerStateContext.Provider>
    </BannerDispatcherContext.Provider>
  );
};
