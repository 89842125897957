import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { useImmerReducer } from "use-immer";

import {
  CareerDeveloperVideoAllListModel,
  CareerDeveloperVideoRespondModel,
  CareerDeveloperVideoDeleteResultModel,
  CategorySelectBoxDataViewModel,
  CareerDeveloperCategoryDeleteResultModel,
  CareerDeveloperCategoryRespondModel
} from "../../models/admin/CareerDeveloper";

import {
  careerDeveloperReducer,
  ICareerDeveloperAction,
} from "../../action/admin/CareerDeveloperAction";


export const CareerDeveloperDispatcherContext = React.createContext<React.Dispatch<ICareerDeveloperAction> | null>(
  null
);
export const CareerDeveloperStateContext = React.createContext<ICareerDeveloperManagementState | null>(
  null
);

export interface ICareerDeveloperManagementProps extends RouteComponentProps<any> { }

export interface ICareerDeveloperManagementState {
  careerDeveloperVideoAllList: CareerDeveloperVideoAllListModel;
  careerDeveloperCategoryList: CategorySelectBoxDataViewModel;
  careerDeveloperVideoRespond: CareerDeveloperVideoRespondModel;
  careerDeveloperVideoDeleteResult: CareerDeveloperVideoDeleteResultModel;
  careerDeveloperCategoryDeleteResult: CareerDeveloperCategoryDeleteResultModel;
  careerDeveloperCategoryRespond: CareerDeveloperCategoryRespondModel;
}


export const initialCareerDeveloperManagementState = {
  careerDeveloperVideoAllList: {} as CareerDeveloperVideoAllListModel,
  careerDeveloperCategoryList: {} as CategorySelectBoxDataViewModel,
  careerDeveloperVideoRespond: {} as CareerDeveloperVideoRespondModel,
  careerDeveloperVideoDeleteResult: {} as CareerDeveloperVideoDeleteResultModel,
  careerDeveloperCategoryDeleteResult: {} as CareerDeveloperCategoryDeleteResultModel,
  careerDeveloperCategoryRespond: {} as CareerDeveloperCategoryRespondModel

} as ICareerDeveloperManagementState;


export const CareerDeveloperContextProvider: React.FC = ({ children }) => {
  const [careerDeveloperState, dispatcher] = useImmerReducer(
    careerDeveloperReducer,
    initialCareerDeveloperManagementState
  );

  return (
    <CareerDeveloperDispatcherContext.Provider value={dispatcher}>
      <CareerDeveloperStateContext.Provider value={careerDeveloperState}>
        {children}
      </CareerDeveloperStateContext.Provider>
    </CareerDeveloperDispatcherContext.Provider>
  );
};