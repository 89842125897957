import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { AchievementsViewModel, 
         AchievementsSaveRespondModel, 
         AchievementsResultModel,
         AchievementsSelectBoxDataViewModel,
         AchievementsDeleteResultModel,
         AchievementsWithOutTokenViewModel,
         ClientsWithOutTokenViewModel} from "../../models/admin/Achievements";
import { IAchievementsAction, AchievementsReducer } from "../../action/admin/AchievementsAction";



export const AchievementsDispatcherContext = React.createContext<React.Dispatch<IAchievementsAction> | null>(null);
export const AchievementsStateContext = React.createContext<IAchievementsManagementState | null>(null);

export interface IAchievementsManagementProps extends RouteComponentProps<any> { }

export interface IAchievementsManagementState {
    visible:boolean;
    value:number;
    Achievements:AchievementsViewModel,
    AchievementsResultModel:AchievementsResultModel,
    AchievementsSaveRespond:AchievementsSaveRespondModel,
    AchievementsSelectBoxDataViewModel:AchievementsSelectBoxDataViewModel,
    AchievementsDeleteResultModel:AchievementsDeleteResultModel;
    AchievementsWithOutToken:AchievementsWithOutTokenViewModel;
    ClientsWithOutToken:ClientsWithOutTokenViewModel;
}

export const initialAchievementsManagementState = {
    visible:false,
    value:0,
    Achievements:{} as AchievementsViewModel,
    AchievementsResultModel:{} as AchievementsResultModel,
    AchievementsSaveRespond:{} as AchievementsSaveRespondModel,
    AchievementsSelectBoxDataViewModel:{} as AchievementsSelectBoxDataViewModel,
    AchievementsDeleteResultModel:{} as AchievementsDeleteResultModel,
    AchievementsWithOutToken:{} as AchievementsWithOutTokenViewModel,
    ClientsWithOutToken:{} as ClientsWithOutTokenViewModel,
} as IAchievementsManagementState;

export const AchievementsContextProvider: React.FC = ({ children }) => {
    const [AchievementsState, dispatcher] = useImmerReducer(AchievementsReducer, initialAchievementsManagementState);

    return (
        <AchievementsDispatcherContext.Provider value={dispatcher}>
            <AchievementsStateContext.Provider value={AchievementsState}>
                {children}
            </AchievementsStateContext.Provider>
        </AchievementsDispatcherContext.Provider>
    )
}