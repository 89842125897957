import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { IDatePostedAction, datePostedReducer } from "../../action/general/DatePostedAction";
import { datePostedViewModel } from "../../models/general/DatePosted";



export const DatePostedDispatcherContext = React.createContext<React.Dispatch<IDatePostedAction> | null>(null);
export const DatePostedStateContext = React.createContext<IDatePostedManagementState | null>(null);

export interface IDatePostedManagementProps extends RouteComponentProps<any> { }

export interface IDatePostedManagementState {
    visible:boolean;
    value:number;
    datePosted:datePostedViewModel
}

export const initialDatePostedManagementState = {
    visible:false,
    value:0,
    datePosted:{} as datePostedViewModel,
} as IDatePostedManagementState;

export const DatePostedContextProvider: React.FC = ({ children }) => {
    const [datePostedState, dispatcher] = useImmerReducer(datePostedReducer, initialDatePostedManagementState);

    return (
        <DatePostedDispatcherContext.Provider value={dispatcher}>
            <DatePostedStateContext.Provider value={datePostedState}>
                {children}
            </DatePostedStateContext.Provider>
        </DatePostedDispatcherContext.Provider>
    )
}