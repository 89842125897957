import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import {
  getSocialAccountUrlList,
  deleteSocialAccountUrl,
  useSocialAccountUrlContext,
  useSocialAccountUrlDispatcher,
} from "../../../../action/admin/SocialAccountUrlAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../../action/MyProfileAdminAction";
import {
  SocialAccountUrlRequestModel,
  SocialAccountUrlEditRequestModel,
  SocialAccountUrl,
  SocialAccountUrlViewModel,
  SocialAccountUrlSaveRequestModel,
  SocialAccountUrlSaveRespondModel,
  SocialAccountUrlDeleteRequestModel,
} from "../../../../models/admin/SocialAccountUrl";

import AuthService from "../../../../services/AuthService";
import SocialAccountUrlForm from "./SocialAccountUrl";
import { Popover } from "antd";
import Popconfirm from "antd/lib/popconfirm";
import {
  useAdminMyJobDispatcher,
  useAdminMyJobContext,
} from "../../../../action/admin/AdminMyJobAction";

interface ISocialAccountUrlComponentProps {}

interface ISocialAccountUrlComponentState {}

const defaultValues = {};

const SocialAccountUrlComponent: React.FC<ISocialAccountUrlComponentProps> = (
  props
) => {
  const [
    SocialAccountUrlComponentState,
    setSocialAccountUrlComponentState,
  ] = React.useState<ISocialAccountUrlComponentState>(defaultValues);

  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;

  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const { myProfile, basicInfo, loggedAdminId } = myProfileAdminContext;

  const adminId = loggedAdminId;

  //console.log("userId", userId, "adminId", adminId);

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  const SocialAccountUrlDispatcher = useSocialAccountUrlDispatcher();
  const SocialAccountUrlContext = useSocialAccountUrlContext();
  const {
    SocialAccountUrl,
    SocialAccountUrlSaveRespond,
  } = SocialAccountUrlContext;
  //console.log("----------SocialAccountUrl-----------",SocialAccountUrl);

  let history = useHistory();

  const [isSocialAccountUrlOpen, setisSocialAccountUrlOpen] = useState<boolean>(
    false
  );
  const [SocialAccountUrlId, setSocialAccountUrlId] = useState<number>(0);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ISocialAccountUrlComponentState>({
    defaultValues,
  });

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      //console.log('career tips call getSocialAccountUrlList',adminId);
      (async () => {
        await getSocialAccountUrlList(
          SocialAccountUrlDispatcher,
          {
            adminId: 0,
            page: 1,
            pageSize: 60,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as SocialAccountUrlRequestModel,
          authorizationToken
        );
      })();
    }
  });

  const handleSocialAccountUrlDelete = (SocialAccountUrlId: number) => {
    if (authorizationToken != null)
      deleteSocialAccountUrl(
        SocialAccountUrlDispatcher,
        {
          rowId: SocialAccountUrlId,
        } as SocialAccountUrlDeleteRequestModel,
        authorizationToken
      );
  };

  const handleJobApplayClick = (id: any) => {
    setSocialAccountUrlComponentState({
      ...SocialAccountUrlComponentState,
      myTeamMemberId: id,
    });
    handleSocialAccountUrlOpen();
  };

  const handleSocialAccountUrlOpen = () => {
    setisSocialAccountUrlOpen(!isSocialAccountUrlOpen);
  };

  const menuRef = useRef<any>();

  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setIsListOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Social Contacts</h1>
          <a
            onClick={() => {
              setSocialAccountUrlId(0);
              handleSocialAccountUrlOpen();
            }}
            data-target="#create_jobdescription"
            data-toggle="modal"
            className="btn cutomise_but"
          >
            <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp; Add New
          </a>
          <div className="action_btn1">
            <button
              type="button"
              className="actions_menu"
              onClick={() => {
                setIsListOpen(!isListOpen);
              }}
            >
              <i className="fa fa-align-left" aria-hidden="true" />
            </button>
            <div
              className="user_menu"
              tabIndex={-1}
              style={{ display: isListOpen ? "block" : "none" }}
            >
              <div className="action_sec">
                <ul ref={menuRef}>
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isMyTeamPermission ? (
                    <li>
                      <Link to="/admin/myteammember">My Team</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isStoriesofJIT ? (
                    <li>
                      <Link to="/admin/story">Stories of JIT</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isCompanyContactsandSocialPermission ? (
                    <li>
                      <Link to="/admin/socialaccounturl">
                        Company Contacts and Social Media
                      </Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isAchievementPermission ? (
                    <li>
                      <Link to="/admin/achievements">Achievements</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  (AdminPermissionResult.isAddJobCategoryPermission ||
                    AdminPermissionResult.isAddJobTitlePermission) ? (
                    <li>
                      <Link to="/admin/sitesettings/jobCategory">
                        Category / Job Title
                      </Link>{" "}
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="career_tips_ach">
            <Scrollbars
              style={{ height: 750 }}
              autoHide
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    position: "relative",
                    display: "block",
                    width: "5px",
                    cursor: "pointer",
                    borderRadius: "inherit",
                    backgroundColor: "rgb(73, 69, 69)",
                    height: "115px",
                  }}
                />
              )}
            >
              {SocialAccountUrl.data != undefined
                ? SocialAccountUrl.data.map((social, i) => {
                    return (
                      <div className="col-sm-6" key={`${i}`}>
                        <div className="socail_cotact_sec">
                          <div className="admin_social">
                            <img
                              width="40"
                              height="40"
                              src={require(`../../../../images/${social.socialAccountTypeId}.png`)}
                            />
                          </div>
                          <div className="admin_social_link">
                            <a href={social.linkUrl}>{social.linkUrl}</a>
                          </div>

                          <div className="socail_cotact_edits">
                            <a
                              onClick={() => {
                                setSocialAccountUrlId(social.rowId);
                                handleSocialAccountUrlOpen();
                              }}
                              data-target="#create_jobdescription"
                              data-toggle="modal"
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <Popconfirm
                              title={
                                <div>
                                  <p>
                                    Are you sure to delete this social account
                                    url?
                                  </p>
                                </div>
                              }
                              onConfirm={() =>
                                handleSocialAccountUrlDelete(social.rowId)
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <a
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  className="fa fa-trash-o"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </Popconfirm>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </Scrollbars>
          </div>
          <div className="clearfix" />
          <SocialAccountUrlForm
            isSocialAccountUrlOpen={isSocialAccountUrlOpen}
            handleSocialAccountUrlOpen={handleSocialAccountUrlOpen}
            currentSocialAccountUrlId={SocialAccountUrlId}
            loggedAdminId={loggedAdminId}
            authorizationToken={authorizationToken}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default SocialAccountUrlComponent;
