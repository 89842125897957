import FilePdfTwoTone from "@ant-design/icons/lib/icons/FilePdfTwoTone";
import { Alert, Button, notification, Space } from "antd";
import React, { useState } from "react";
import Modal from "react-bootstrap/lib/Modal";
import { useHistory } from "react-router";
import {
  getDashboardList,
  useDashboardContext,
  useDashboardDispatcher,
} from "../../../action/candidate/DashboardsAction";
import {
  closeDigiLockerModal,
  getDigiLockerExpiringDocumentList,
  useDigiLockerContext,
  useDigiLockerDispatcher,
} from "../../../action/candidate/DigiLockerAction";
import {
  getAllJobAlertList,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import {
  getAllCount,
  useJobTypeDispatcher,
} from "../../../action/general/JobTypeAction";
import {
  getMatchedJobList,
  useMatchedJobContext,
  useMatchedJobDispatcher,
} from "../../../action/MatchedJobAction";
import {
  getLoggedUserId,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import { NotificationListContextProvider } from "../../../context/candidate/CandidateMyProfile";
import { CandidateDashboardRequestModel } from "../../../models/candidate/Dashboard";
import { digiLockerExpiringDocumentRequestModel } from "../../../models/candidate/DigiLocker";
import { jobAlertGellAllRequestModel } from "../../../models/candidate/JobAlert";
import { matchedJobRequestModel } from "../../../models/candidate/MatchedJob";
import { countAllRequestModel } from "../../../models/general/JobType";
import AuthService from "../../../services/AuthService";

import CandidateDashboardComponent from "./CandidateDashboardComponent";
import CandidateDashboardComponentFour from "./CandidateDashBoardComponentFour";
import CandidateDashboardComponentThree from "./CandidateDashBoardComponentThree";
import CandidateDashboardComponentTwo from "./CandidateDashboardComponentTwo";

interface ICandidateDashboardContainerProps {}

interface ICandidateDashboardContainerState {}

const initialState = {};
let user = AuthService.currentUser;
const CandidateDashboardContainer: React.FC<ICandidateDashboardContainerProps> = (
  props
) => {
  const [
    CandidateDashboardContainerState,
    setCandidateDashboardContainerState,
  ] = React.useState<ICandidateDashboardContainerState>(initialState);
  const authorizationToken = AuthService.accessToken;

  const dashboardDispatcher = useDashboardDispatcher();
  const dashboardContext = useDashboardContext();
  const { dashboardRespond, dashboardSaveRespond } = dashboardContext;
  const jobAlertDispatcher = useJobAlertDispatcher();
  const matchedJobDispatcher = useMatchedJobDispatcher();
  const matchedJobContext = useMatchedJobContext();
  const { matchedJob } = matchedJobContext;
  const myProfileContext = useMyProfileContext();
  const {
    basicInfo,
    loggedUserId,
    profileImage,
    myProfileProgressBar,
  } = myProfileContext;
  const jobTypeDispatcher = useJobTypeDispatcher();
  const myProfileDispatcher = useMyProfileDispatcher();

  let history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [keyArray, setkeyArray] = useState<string[]>([]);
  const digiLockerMarkDispatcher = useDigiLockerDispatcher();
  const digiLockerMarkContext = useDigiLockerContext();
  const {
    digiLockerExpiringDocument,
    digiDocumentExpModal,
  } = digiLockerMarkContext;

  React.useEffect(() => {
    setIsOpen(authorizationToken != null ? true : false);
  }, [authorizationToken]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getDashboardList(
          dashboardDispatcher,
          {
            CandidateId: loggedUserId,
          } as CandidateDashboardRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId, dashboardSaveRespond]);

  // React.useEffect(() => {
  //   if (authorizationToken != null && loggedUserId != 0) {
  //     (async () => {
  //       await getAllCount(
  //         jobTypeDispatcher,
  //         {
  //           candidateId: loggedUserId,
  //           page: 1,
  //           pageSize: 10,
  //           searchTerm: "",
  //           showInactive: false,
  //           sortOrder: "",
  //         } as countAllRequestModel,
  //         authorizationToken
  //       );
  //     })();
  //   }
  // }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getMatchedJobList(
          matchedJobDispatcher,
          {
            CandidateId: loggedUserId,
            DigiDocumentTypeId: 0,
            PageIndex: 1,
            PageSize: 10,
            ShowInactive: false,
          } as matchedJobRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        getAllJobAlertList(
          jobAlertDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            sortOrder: "",
            showInactive: false,
          } as jobAlertGellAllRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (loggedUserId != 0 && authorizationToken != null) {
      // setIsRenderCheckedList(true);
      // setIsChecked(false);
      // setIsCheckedAll(false);
      (async () => {
        await getDigiLockerExpiringDocumentList(
          digiLockerMarkDispatcher,
          {
            candidateId: loggedUserId,
            digiDocumentTypeId: 0,
            pageIndex: 1,
            pageSize: 10,
            showInactive: false,
          } as digiLockerExpiringDocumentRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  //console.log("dashbordLayout", dashboardRespond);

  const handleCloseFunctionCall = () => {
    closeDigiLockerModal(digiLockerMarkDispatcher, false);
  };

  const handleNotificationClick = () => {
    history.push("/candidate/digilocker/3");
  };

  const handleShowModel = () => {
    if (
      digiLockerExpiringDocument.data != undefined &&
      digiDocumentExpModal &&
      digiLockerExpiringDocument.data.length > 0
    ) {
      handleCloseFunctionCall();

      digiLockerExpiringDocument.data
        .filter((data) => data.isExpired == true)
        .map((data, index) => {
          return notification.open({
            message: "Expired",
            key: `${index}`,
            description:
              data.name + " " + " expiried on" + " " + data.expiryDate,
            className: "custom-class",
            style: {
              // width: 600,
              backgroundColor: "rgb(249 9 9 / 82%)",
              color: "antiquewhite",
              cursor: "pointer",
            },
            duration: 0,
            onClick: handleNotificationClick,
            placement: "bottomRight",
            icon: <FilePdfTwoTone style={{ fontSize: 40 }} />,
          });
        });

      digiLockerExpiringDocument.data
        .filter((data) => data.isExpired == false)
        .map((data, index) => {
          return notification.open({
            message: "Going to expire",
            key: `2${index}`,
            description:
              data.name + " " + "is going to expire on" + " " + data.expiryDate,
            className: "custom-class",
            style: {
              // width: 600,
              backgroundColor: "#f57c00eb",
              color: "antiquewhite",
              cursor: "pointer",
            },
            onClick: handleNotificationClick,
            duration: isOpen ? 0 : 2,
            placement: "bottomRight",

            icon: <FilePdfTwoTone style={{ fontSize: 40 }} />,
          });
        });
    } else return null;
  };

  const handleDashboardLayOut = () => {
    if (dashboardRespond.dashboardListId == 0) {
      return (
        <NotificationListContextProvider>
          {}
          <CandidateDashboardComponent />
        </NotificationListContextProvider>
      );
    } else if (dashboardRespond.dashboardListId == 1) {
      return (
        <NotificationListContextProvider>
          {}
          <CandidateDashboardComponent />
        </NotificationListContextProvider>
      );
    } else if (dashboardRespond.dashboardListId == 2) {
      return (
        <NotificationListContextProvider>
          {}
          <CandidateDashboardComponentTwo />
        </NotificationListContextProvider>
      );
      // return (
      //   <NotificationListContextProvider>
      //     {}
      //     <CandidateDashboardComponentThree />
      //   </NotificationListContextProvider>
      // );
    } else if (dashboardRespond.dashboardListId == 2) {
      return (
        <NotificationListContextProvider>
          {}
          <CandidateDashboardComponentThree />
        </NotificationListContextProvider>
      );
    } else if (dashboardRespond.dashboardListId == 3) {
      return (
        <NotificationListContextProvider>
          {}
          <CandidateDashboardComponentFour />
        </NotificationListContextProvider>
      );
    } else {
      return (
        <NotificationListContextProvider>
          {}
          <CandidateDashboardComponent />
        </NotificationListContextProvider>
      );
    }
  };

  return (
    <>
      {isOpen ? handleShowModel() : null}
      {/* {digiLockerExpiringDocument.data != undefined &&
      digiDocumentExpModal &&
      digiLockerExpiringDocument.data.length > 0
        ? digiLockerExpiringDocument.data
            .filter((data) => data.isExpired == true)
            .map((data, index) => {
              return notification.open({
                message: "Expired",
                key: `${index}`,
                description:
                  data.name + " " + " expiried on" + " " + data.expiryDate,
                className: "custom-class",
                style: {
                  // width: 600,
                  backgroundColor: "rgb(249 9 9 / 82%)",
                  color: "antiquewhite",
                  cursor: "pointer",
                  display: authorizationToken != null ? "" : "none",
                },
                duration: 0,
                onClick: handleNotificationClick,
                placement: "bottomRight",
                icon: <FilePdfTwoTone style={{ fontSize: 40 }} />,
              });
            })
        : null}

      {digiLockerExpiringDocument.data != undefined &&
      digiDocumentExpModal &&
      digiLockerExpiringDocument.data.length > 0
        ? digiLockerExpiringDocument.data
            .filter((data) => data.isExpired == false)
            .map((data, index) => {
              return notification.open({
                message: "Going to expire",
                key: `2${index}`,
                description:
                  data.name +
                  " " +
                  "is going to expire on" +
                  " " +
                  data.expiryDate,
                className: "custom-class",
                style: {
                  // width: 600,
                  backgroundColor: "#f57c00eb",
                  color: "antiquewhite",
                  cursor: "pointer",
                  display: isOpen ? "" : "none",
                },
                onClick: handleNotificationClick,
                duration: isOpen ? 0 : 2,
                placement: "bottomRight",

                icon: <FilePdfTwoTone style={{ fontSize: 40 }} />,
              });
            })
        : null} */}

      {handleDashboardLayOut()}
    </>
  );
};
export default CandidateDashboardContainer;
