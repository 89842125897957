import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { StoryViewModel, 
         StorySaveRespondModel, 
         StoryResultModel,
        StoryDeleteResultModel} from "../../models/admin/Story";
import { IStoryAction, StoryReducer } from "../../action/admin/StoryAction";



export const StoryDispatcherContext = React.createContext<React.Dispatch<IStoryAction> | null>(null);
export const StoryStateContext = React.createContext<IStoryManagementState | null>(null);

export interface IStoryManagementProps extends RouteComponentProps<any> { }

export interface IStoryManagementState {
    visible:boolean;
    value:number;
    Story:StoryViewModel,
    StoryResultModel:StoryResultModel,
    StorySaveRespond:StorySaveRespondModel,
    StoryDeleteResultModel:StoryDeleteResultModel;
}

export const initialStoryManagementState = {
    visible:false,
    value:0,
    Story:{} as StoryViewModel,
    StoryResultModel:{} as StoryResultModel,
    StorySaveRespond:{} as StorySaveRespondModel,
    StoryDeleteResultModel:{} as StoryDeleteResultModel
} as IStoryManagementState;

export const StoryContextProvider: React.FC = ({ children }) => {
    const [StoryState, dispatcher] = useImmerReducer(StoryReducer, initialStoryManagementState);

    return (
        <StoryDispatcherContext.Provider value={dispatcher}>
            <StoryStateContext.Provider value={StoryState}>
                {children}
            </StoryStateContext.Provider>
        </StoryDispatcherContext.Provider>
    )
}