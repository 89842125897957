import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  ClientJobApprovalViewModel,
  ClientJobApprovalSaveRespondModel,
  ClientJobResultModel,
  ClientJobSelectBoxDataViewModel,
  ClientFakeCandidateResultModel,
  ClientFakeCandidateAllResultModel,
  ClientFakeCandidateEditResult,
  CliendPendingJobResultModel,
  CandidateInfoByIdResultModel,
  AllAppliedCandidateListInAdminsideResultModel,
} from "../../models/admin/ClientJobApproval";
import {
  IClientJobApprovalAction,
  clientJobApprovalReducer,
} from "../../action/admin/ClientJobApprovalAction";

export const ClientJobApprovalDispatcherContext = React.createContext<React.Dispatch<IClientJobApprovalAction> | null>(
  null
);
export const ClientJobApprovalStateContext = React.createContext<IClientJobApprovalManagementState | null>(
  null
);

export interface IClientJobApprovalManagementProps
  extends RouteComponentProps<any> {}

export interface IClientJobApprovalManagementState {
  visible: boolean;
  value: number;
  clientJobApproval: ClientJobApprovalViewModel;
  ClientJobResultModel: ClientJobResultModel;
  clientJobApprovalSaveRespond: ClientJobApprovalSaveRespondModel;
  ClientJobSelectBoxDataViewModel: ClientJobSelectBoxDataViewModel;
  ClientFakeCandidateAddResult: ClientFakeCandidateResultModel;
  ClientAllFakeCandidateResul: ClientFakeCandidateAllResultModel;
  ClientFakeCandidateDeleteResult: ClientFakeCandidateResultModel;
  ClientFakeCandidateEditResult: ClientFakeCandidateEditResult;
  ClientPendingJobApprovalResult: CliendPendingJobResultModel;
  AdminClientSideJobs: CliendPendingJobResultModel;
  JobApproveOrRejectFromAdminResult: ClientFakeCandidateResultModel;
  CandidateInfoById: CandidateInfoByIdResultModel;
  AddCandidateListForFormResult: ClientFakeCandidateResultModel;
  AdminGetAllApliedCandidateListForClientJob: AllAppliedCandidateListInAdminsideResultModel;
}

export const initialClientJobApprovalManagementState = {
  visible: false,
  value: 0,
  clientJobApproval: {} as ClientJobApprovalViewModel,
  ClientJobResultModel: {} as ClientJobResultModel,
  clientJobApprovalSaveRespond: {} as ClientJobApprovalSaveRespondModel,
  ClientJobSelectBoxDataViewModel: {} as ClientJobSelectBoxDataViewModel,
  ClientFakeCandidateAddResult: {} as ClientFakeCandidateResultModel,
  ClientAllFakeCandidateResul: {} as ClientFakeCandidateAllResultModel,
  ClientFakeCandidateDeleteResult: {} as ClientFakeCandidateResultModel,
  ClientFakeCandidateEditResult: {} as ClientFakeCandidateEditResult,
  ClientPendingJobApprovalResult: {} as CliendPendingJobResultModel,
  AdminClientSideJobs: {} as CliendPendingJobResultModel,
  JobApproveOrRejectFromAdminResult: {} as ClientFakeCandidateResultModel,
  CandidateInfoById: {} as CandidateInfoByIdResultModel,
  AddCandidateListForFormResult: {} as ClientFakeCandidateResultModel,
  AdminGetAllApliedCandidateListForClientJob: {} as AllAppliedCandidateListInAdminsideResultModel,
} as IClientJobApprovalManagementState;

export const ClientJobApprovalContextProvider: React.FC = ({ children }) => {
  const [clientJobApprovalState, dispatcher] = useImmerReducer(
    clientJobApprovalReducer,
    initialClientJobApprovalManagementState
  );

  return (
    <ClientJobApprovalDispatcherContext.Provider value={dispatcher}>
      <ClientJobApprovalStateContext.Provider value={clientJobApprovalState}>
        {children}
      </ClientJobApprovalStateContext.Provider>
    </ClientJobApprovalDispatcherContext.Provider>
  );
};
