import { Reducer } from "react"
import { IMatchedJobManagementState, MatchedJobDispatcherContext, MatchedJobStateContext } from "../context/MatchedJobContext"
import React from "react";
import { matchedJobRequestModel, matchedJobViewModel } from "../models/candidate/MatchedJob";
import axios from 'axios';
import AuthService from "../services/AuthService";
import { AppUrls } from "../environments/environment";

let token=AuthService.accessToken;
let   authorizationToken =token!=null?token.replace(/['"]+/g,''):""; 

export type IMatchedJobAction = {
    type:"SET_Job Applied_LIST",
    matchedJob:matchedJobViewModel 
}




export const matchedJobReducer: Reducer<IMatchedJobManagementState, IMatchedJobAction> = (draft, action): IMatchedJobManagementState => {
    switch (action.type) {
     
            case "SET_Job Applied_LIST":
                draft.matchedJob=action.matchedJob;
                return draft;
    }
}



export const useMatchedJobDispatcher = (): React.Dispatch<IMatchedJobAction> => {
    const matchedJobDispatcher = React.useContext(MatchedJobDispatcherContext);
    if (!matchedJobDispatcher) {
        throw new Error("You have to provide the MatchedJob dispatcher using theMatchedJobDispatcherContext.Provider in a parent component.");
    }
    return matchedJobDispatcher;
}


export const useMatchedJobContext = (): IMatchedJobManagementState => {
    const matchedJobContext = React.useContext(MatchedJobStateContext);
    if (!matchedJobContext) throw new Error("You have to provide the matchedJob context using the MatchedJobStateContext.Provider in a parent component.");
    return matchedJobContext;
}



export const getMatchedJobList = async (dispatcher: React.Dispatch<IMatchedJobAction>,query:matchedJobRequestModel,token:string   ) => {
   
    try{

      if (token?.startsWith(`"`)) {
        token = token.substring(1);
        token = token.slice(0, -1);
      }

      let header = {
                      "Content-Type": "application/json",
                      "Accept": "application/json",
                     "Authorization":`Bearer ${token}`,
                  };
  
                   const url=AppUrls.GetAllMatchedJob
  
                  axios.post(url, JSON.stringify( 
                     query
                   ),{ headers: header })
                  .then(res => {
                    dispatcher({ type:"SET_Job Applied_LIST", matchedJob: res.data });
                  })

  }
  catch(e){

  }
}



