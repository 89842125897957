import React from "react";

import { BrowserRouter } from "react-router-dom";
import IndexFooterContainer from "../index/indexFooter/IndexFooterContainer";
import ComingSoonComponent from "./ComingSoonComponent";

interface IComingSoonContainerProps { }

interface IComingSoonContainerState { }

const initialState = {};

const ComingSoonContainer: React.FC<IComingSoonContainerProps> = (props) => {
  const [startUpState, setComingSoonContainerState] = React.useState<IComingSoonContainerState>(
    initialState
  );

  return (
    <>
    <ComingSoonComponent/>
    <IndexFooterContainer />
    </>
  );
};
export default ComingSoonContainer;
