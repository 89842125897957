import React from "react";
import ClientReportComponent from "./ClientReportComponent";

const ClientReportContainer = () => {
  return (
    <div>
      <ClientReportComponent />
    </div>
  );
};

export default ClientReportContainer;
