import { apiClient } from "../utils/httpClient";

export function getCareerDeveloperVideosBySearch(data?: any) {
  return apiClient("/api/CareerDeveloper/sa", data);
}

export function getCareerDeveloperVideosByCategory(data?: any) {
  return apiClient("/api/CareerDeveloper/gcaaa", data);
}

export function getCareerDeveloperCategories(data?: any) {
  return apiClient("/api/CareerDeveloperCategory/gaaa", data);
}