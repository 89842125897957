import { apiClient } from "./../utils/httpClient";

export function getSuperAdminId(data?: any) {
  return apiClient(`/api/SuperAdmin/gv/${data}`, {}, "get");
}

export function getSuperAdmins(data?: any) {
  return apiClient("/api/SuperAdmin/gaaa", data);
}

export function getSuperAdmin(data?: any) {
  return apiClient("/api/SuperAdmin/ga", data);
}

export function saveSuperAdmin(data?: any) {
  // return apiClient("/api/SuperAdmin/coea", data, "post", {
  //   headers: {
  //     "Content-Type": 'multipart/form-data'
  //   }
  // });
  return apiClient("/api/SuperAdmin/coea", data);
}

export function getSelectBoxData(data?: any) {
  return apiClient("/api/SuperAdmin/get-selectbox-data", data);
}

export function getProfileProgress(data?: any) {
  return apiClient("/api/SuperAdmin/gscp", data);
}

export function getDocumentStatus(data?: any) {
  return apiClient("/api/SuperAdmin/uploaded-document-status", data);
}

export function getSocialAccounts(data?: any) {
  return apiClient("/api/SuperAdminSocialAccounts/gaaa", data);
}

export function deleteSocialAccounts(data?: any) {
  return apiClient("/api/SuperAdminSocialAccounts/da", data);
}

export function updateSocialAccounts(data?: any) {
  return apiClient("/api/SuperAdminSocialAccounts/coea", data);
}

export function saveSocialAccounts(data?: any) {
  return apiClient("/api/SuperAdminSocialAccounts/coea", data);
}

export function getSuperAdminProfileImage(data?: any) {
  return apiClient("/api/SuperAdminProfileImage/gaaa", data);
}

export function saveSuperAdminProfileImage(data?: any) {
  return apiClient("/api/SuperAdminProfileImage/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getSuperAdminList(data?: any) {
  return apiClient("/api/SuperAdmin/gaoa", data);
}

export function deleteSuperAdminDocument(data?: any) {
  return apiClient("/api/SuperAdmin/delete-doc", data);
}

export function getUserList(data?: any) {
  return apiClient("/api/Vendor/gul", data);
}

export function getMessageList(data?: any) {
  return apiClient("/api/ChatDetails/gaaa", data);
}

export function getNotificationList(data?: any) {
  return apiClient("/api/superadmin/getNotificatinsAsync", data);
}
