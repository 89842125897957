import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Link, useHistory, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getBulkSMSAllList,
  getBulkSMSDetailList,
  saveBulkSMSConversation,
  useAdminCommunicationDispatcher,
  useAdminCommunicationContext,
  removeBulkSMSConversation,
} from "../../../../action/admin/AdminCommunicationAction";
import {
  BulkSMSListRequestModel,
  BulkSMSDetailRequestModel,
  BulkSMSDetailPostModel,
  MessageTransactionsData,
  BulkSMSDetailListModel,
  BulkSMSAllListModel,
} from "../../../../models/admin/AdminCommunication";
import { useAdminMyProfileContext } from "../../../../action/MyProfileAdminAction";
import moment from "moment";
import { Modal } from "react-bootstrap";

import { getBulkSMSDetailList as fetchTranacton } from "../../../../apis/admin";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../../../context/GlobalSpinner";
import _ from "lodash";
import { useAdminMyJobContext } from "../../../../action/admin/AdminMyJobAction";

type objectIndexing = {
  [key: string]: any;
};

function SMSUserList(props: any) {
  const {
    filter,
    userList,
    setCheckedListArray,
    checkedListArray,
    bulkSMSDetailList,
    adminCommunicationDispatcher,
    setSelectedMessage,
    handleDeleteSMS,
    setUserShowList,
    userShowList,
    adminId,
    hasMore,
    setHasMore,
    globalSpinner,
  } = props;
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isUnCheckedAll, setIsUnCheckedAll] = useState(false);
  const [isUserListOpen, setIsUserListOpen] = useState<boolean>(false);
  //const [hasMore, setHasMore] = React.useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSize = 10;
  const [totalPage, setTotalPage] = useState(1);
  //console.log(hasMore);
  React.useEffect(() => {
    if (userList.data != undefined && currentPage == 1) {
      // //console.log("userList");
      // //console.log(userList);
      setUserShowList(userList.data);
      //setCurrentPage(1);
      setTotalPage(Math.ceil(userList.total / currentPageSize));
      setTotalCount(userList.total);
      // //console.log(userShowList);
    }
    if (userList.data != undefined && currentPage > 1) {
      // //console.log("userList2");
      setUserShowList([...userList.data, ...userShowList]);
    }
  }, [userList]);

  const fetchMoreData = async () => {
    // //console.log("hhh");
    // //console.log(currentPage, totalPage);
    // //console.log(totalCount);
    if (currentPage >= totalPage) {
      setHasMore(false);
      return;
    } else {
      setCurrentPage(currentPage + 1);
      (async () => {
        globalSpinner.showSpinner();
        await getBulkSMSAllList(adminCommunicationDispatcher, {
          UserId: adminId, //loggedUserId,
          Page: currentPage + 1,
          PageSize: currentPageSize,
          SearchTerm: "",
          SortOrder: "",
        } as BulkSMSListRequestModel);
        globalSpinner.hideSpinner();
      })();
    }
  };
  const handleAllChecked = (e: any) => {
    let IdArray = [] as any[];
    if (e.target.checked && userShowList != undefined) {
      IdArray = [];
      userShowList.map((d: any) => {
        // //console.log(d)
        if (Number(d.transactionCount) > 0) {
          IdArray.push(d.messageTypeId);
        }
      });
      setIsCheckedAll(e.target.checked);
      setCheckedListArray(IdArray);
      setIsUnCheckedAll(false);
    } else {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsUnCheckedAll(false);
    }
  };

  const handleAllUnChecked = (e: any) => {
    let IdArray = [] as any[];
    if (e.target.checked) {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
    }
    setIsUnCheckedAll(e.target.checked);
  };

  const handleChecked = (e: any, item: any) => {
    ////console.log("hhhhoooo")
    let IdArray = [] as any[];
    if (e.target.checked) {
      IdArray.push(...checkedListArray, item.messageTypeId);
    } else {
      IdArray = checkedListArray.filter((data: any) => {
        // //console.log(IdArray)
        // //console.log(data)
        // //console.log(item)
        return data !== item.messageTypeId;
      });
    }
    setCheckedListArray(IdArray);
  };

  const showUsers = async (e: any, i: any) => {
    ////console.log(e);
    (async () => {
      globalSpinner.showSpinner();
      await getBulkSMSDetailList(adminCommunicationDispatcher, {
        UserId: adminId, //loggedUserId,
        MessageTypeId: e.messageTypeId,
        Page: 1,
        PageSize: 20,
        SearchTerm: "",
        SortOrder: "",
      } as BulkSMSDetailRequestModel);
      globalSpinner.hideSpinner();
    })();
    setIsUserListOpen(true);
  };

  React.useEffect(() => {
    if (checkedListArray.length > 0 && checkedListArray.length < 2) {
      let sel = userShowList.filter(
        (data: any) => data.messageTypeId == checkedListArray[0]
      )[0];
      setSelectedMessage(sel);
    } else {
      setSelectedMessage({
        messageTypeId: 0,
        messageBody: "",
        messageSubject: "",
      });
    }
  }, [checkedListArray.length]);

  React.useEffect(() => {
    if (currentPage >= totalPage) {
      setHasMore(false);
    }
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className=" m-t-25">
        <div className="row">
          <div className="col-sm-4 col-lg-4 pad_r_0">
            <input
              id="checkboxsel"
              type="checkbox"
              name="checkboxsel"
              checked={isCheckedAll}
              onChange={(e: any) => handleAllChecked(e)}
            />
            <label htmlFor="checkboxsel">
              <span /> Select All
            </label>
          </div>
          <div className="col-sm-5 col-lg-5 pad_r_0">
            <input
              id="checkboxusel"
              type="checkbox"
              name="checkboxusel"
              checked={isUnCheckedAll}
              onChange={(e: any) => handleAllUnChecked(e)}
            />
            <label htmlFor="checkboxusel">
              <span /> Deselect All
            </label>
          </div>
          <div className="col-sm-3 col-lg-3">
            <button
              className="btn delete_btn"
              onClick={handleDeleteSMS}
              disabled={checkedListArray.length > 0 ? false : true}
            >
              Delete{" "}
            </button>
          </div>
          {/* <button className="btn delete_btn" onClick={fetchMoreData} >fetch </button> */}
        </div>
        <div
          className="message_chat_scroll"
          style={{ overflow: "hidden" }}
          tabIndex={0}
        >
          <div className="connect_scroll">
            {userShowList != undefined && (
              <ul>
                <InfiniteScroll
                  className="_custom-infinite-scroll-class"
                  height={600}
                  // dataLength={100}
                  // dataLength={totalCount}
                  dataLength={userShowList.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>No more data to load</b>
                    </p>
                  }
                >
                  {userShowList != undefined &&
                    userShowList.map((item: any, index: any) => {
                      const lowercasedFilter = filter.toLowerCase();
                      if (
                        (String(item["messageTo"])
                          .toLowerCase()
                          .includes(lowercasedFilter) ||
                          filter == "") &&
                        Number(item["transactionCount"]) > 0
                      ) {
                        return (
                          <li>
                            {" "}
                            <a
                              onClick={() => {
                                // showSelectedConversation(item["messageTypeId"], item["messageSubject"], item["messageBody"], true)
                                //console.log(item);
                              }}
                              className="admin_m1"
                            >
                              <div
                                className="candidates_check1"
                                style={{ paddingTop: 10 }}
                              >
                                <input
                                  id={`checkbox${item["rowId"]}`}
                                  type="checkbox"
                                  name={`checkbox${item["rowId"]}`}
                                  onChange={(e: any) => handleChecked(e, item)}
                                  checked={checkedListArray.includes(
                                    item["messageTypeId"]
                                  )}
                                />
                                <label htmlFor={`checkbox${item["rowId"]}`}>
                                  <span />
                                </label>
                              </div>
                              <div
                                className="connect_con"
                                style={{ paddingLeft: 10 }}
                              >
                                <div className="connect_con_name">
                                  {" "}
                                  {item["messageTo"]}
                                  {Number(item["transactionCount"]) > 1 && (
                                    <div
                                      className="connect_con_des"
                                      onClick={() => {
                                        showUsers(item, index);
                                      }}
                                    >
                                      +{Number(item["transactionCount"]) - 1}
                                      more
                                    </div>
                                  )}
                                </div>
                                <div className="connect_con_des">
                                  {item["messageSubject"]}
                                </div>
                              </div>
                              <div className="chat_time_sec1">
                                <div className="chat_time">
                                  {moment(new Date(item["createdDate"])).format(
                                    "DD-MM-YYYY"
                                  )}
                                </div>
                                <div className="chat_time">
                                  {moment(new Date(item["createdDate"])).format(
                                    "HH:mm:ss"
                                  )}
                                </div>
                              </div>
                            </a>
                          </li>
                        );
                      }
                    })}
                </InfiniteScroll>
              </ul>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={isUserListOpen}
        onHide={() => {
          setIsUserListOpen(!isUserListOpen);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Message Recievers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 m_t_30 text-center">
            <div className="social1">
              {bulkSMSDetailList.data &&
                bulkSMSDetailList.data.map((item: any, index: any) => {
                  if (item["isActive"]) {
                    return <p> {item["messageTo"]}</p>;
                  }
                })}
            </div>
          </div>
          <div className="modal-footer  m-t-30"></div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

const BulkSMSComponent = () => {
  const globalSpinner = useContext(GlobalSpinnerContext);
  const adminCommunicationDispatcher = useAdminCommunicationDispatcher();
  const adminCommunicationContext = useAdminCommunicationContext();
  const {
    bulkSMSAllList,
    bulkSMSDetailList,
    bulkSMSRespond,
    bulkSMSDeleteResult,
  } = adminCommunicationContext;
  //  const myVendorProfileContext = useVendorMyProfileContext();
  // const { loggedUserId } = myVendorProfileContext;
  const myProfileAdminContext = useAdminMyProfileContext();
  const {
    //myProfile,
    // basicInfo,
    loggedAdminId,
  } = myProfileAdminContext;
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;
  const adminId = loggedAdminId;
  const [filter, setFilter] = useState<string>("");
  const [sendMessage, setSendMessage] = useState<string>("");
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [replyBlockDisplay, setReplyBlockDisplay] = useState<string>("none");
  const [selectedMessage, setSelectedMessage] = useState({
    messageTypeId: 0,
    messageBody: "",
    messageSubject: "",
  });
  const [selectedMessageTrans, setSelectedMessageTrans] = useState(
    [] as BulkSMSDetailListModel[]
  );
  //const [userShowList, setUserShowList] = useState({});
  const [userShowList, setUserShowList] = useState(
    Array<BulkSMSAllListModel>()
  );
  const [hasMore, setHasMore] = React.useState(true);
  const [toggleBlockDisplay, setToggleBlockDisplay] = useState<string>("none");
  React.useEffect(() => {
    (async () => {
      globalSpinner.showSpinner();
      await getBulkSMSAllList(adminCommunicationDispatcher, {
        UserId: adminId, //loggedUserId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      } as BulkSMSListRequestModel);
      globalSpinner.hideSpinner();
    })();
  }, [adminId, bulkSMSDeleteResult, bulkSMSRespond]);

  // React.useEffect(() => {
  //   if (bulkSMSAllList != undefined) {
  //     ////console.log(bulkSMSAllList);
  //     globalSpinner.hideSpinner();
  //   }
  // }, [bulkSMSAllList]);

  React.useEffect(() => {
    if (selectedMessage.messageTypeId > 0) {
      let _data1: objectIndexing = {};
      _data1["UserId"] = adminId; //loggedUserId,
      _data1["MessageTypeId"] = selectedMessage.messageTypeId;
      _data1["Page"] = 1;
      _data1["PageSize"] = 100;
      _data1["SearchTerm"] = "";
      _data1["SortOrder"] = "";
      globalSpinner.showSpinner();
      fetchTranacton(_data1)
        .then((res: any) => {
          globalSpinner.hideSpinner();
          if (res.status == 200) {
            setSelectedMessageTrans(res.data.data);
          } else {
            //console.log(res.data);
          }
        })
        .catch((err: any) => {
          //console.log(err);
          globalSpinner.hideSpinner();
        });
    }
  }, [selectedMessage.messageTypeId]);

  const handleSendSMSBlock = () => {
    if (replyBlockDisplay.includes("none")) {
      setReplyBlockDisplay("block");
    } else {
      setReplyBlockDisplay("none");
    }
  };

  const handleSendSMS = () => {
    let trans = [] as MessageTransactionsData[];
    if (selectedMessageTrans.length > 0) {
      selectedMessageTrans.forEach((item: any, index: any) => {
        if (item["isActive"] == true) {
          const listItem: MessageTransactionsData = {
            MessageFrom: item["messageFrom"],
            MessageTo: item["messageTo"],
            MessageStatus: "NA",
          };
          trans.push(listItem);
        }
      });
    }
    if (trans.length > 0) {
      (async () => {
        globalSpinner.showSpinner();
        await saveBulkSMSConversation(adminCommunicationDispatcher, {
          MessageTypeId: 0,
          MessageType: "Bulk",
          MessageSubject: "Reply:" + selectedMessage.messageSubject,
          MessageBody: sendMessage,
          MessageTransactions: trans,
          UserId: adminId, //loggedUserId,;
          IsActive: true,
        } as BulkSMSDetailPostModel);
        globalSpinner.hideSpinner();
      })();
    }
  };

  const handleDeleteSMS = () => {
    ////console.log(checkedListArray);
    if (checkedListArray.length > 0) {
      (async () => {
        globalSpinner.showSpinner();
        await removeBulkSMSConversation(adminCommunicationDispatcher, {
          UserId: adminId, //loggedUserId,;
          MessageTypeId: checkedListArray,
        });
        globalSpinner.hideSpinner();
      })();
    }
  };

  const handleToggle = () => {
    if (toggleBlockDisplay.includes("none")) {
      setToggleBlockDisplay("block");
    } else {
      setToggleBlockDisplay("none");
    }
  };

  return (
    <>
      {/*=================Content Section Start================*/}
      {AdminPermissionResult.isCreateorEditJobPermission != undefined &&
        AdminPermissionResult.isCreateorEditJobPermission && (
          <div className="content-wrapper">
            <div className="container-fluid">
              <h1 className="heading">Sent SMS</h1>
              <div className="action_btn1">
                <button
                  type="button"
                  className="actions_menu"
                  onClick={handleToggle}
                >
                  <i className="fa fa-align-left" aria-hidden="true" />
                </button>
                <div
                  className="user_menu"
                  style={{
                    display: `${toggleBlockDisplay}`,
                  }}
                >
                  <div className="action_sec">
                    <ul>
                      {AdminPermissionResult != undefined &&
                      AdminPermissionResult.isCreateorEditJobPermission ? (
                        <li>
                          <Link to="/admin/communication/bulkSMS">
                            Bulk Sent SMS
                          </Link>
                        </li>
                      ) : null}
                      {AdminPermissionResult != undefined &&
                      AdminPermissionResult.isCreateorEditJobPermission ? (
                        <li>
                          {" "}
                          <Link to="/admin/communication/bulkEmail">
                            Bulk Sent Email
                          </Link>{" "}
                        </li>
                      ) : null}
                      <li>
                        <Link to="/admin/communication/messages">Messages</Link>{" "}
                      </li>
                      <li>
                        <Link to="/admin/communication/contactUs">
                          Contact us Queries
                        </Link>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <div className="row " style={{ paddingTop: 10 }}>
                <div className="col-sm-5 col-lg-4 p-r-0">
                  <div className="panel panel-default panel_n">
                    <div className="panel-body panel-body1">
                      <div className="connect_left">
                        <div>
                          <input
                            type="email"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            className="form-control"
                            placeholder="Search"
                          />
                          {/* // <input type="email" className="form-control" placeholder="Search" /> */}
                          <div className="search_icon">
                            <i className="fa fa-search" aria-hidden="true" />
                          </div>
                        </div>
                        {bulkSMSAllList != undefined &&
                          !_.isEmpty(bulkSMSAllList) && (
                            <SMSUserList
                              filter={filter}
                              userList={bulkSMSAllList}
                              setCheckedListArray={setCheckedListArray}
                              checkedListArray={checkedListArray}
                              bulkSMSDetailList={bulkSMSDetailList}
                              adminCommunicationDispatcher={
                                adminCommunicationDispatcher
                              }
                              setSelectedMessage={setSelectedMessage}
                              setSelectedMessageTrans={setSelectedMessageTrans}
                              handleDeleteSMS={handleDeleteSMS}
                              setUserShowList={setUserShowList}
                              userShowList={userShowList}
                              adminId={adminId}
                              hasMore={hasMore}
                              setHasMore={setHasMore}
                              globalSpinner={globalSpinner}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {selectedMessage.messageTypeId > 0 && (
                  <div className="col-sm-7 col-lg-8">
                    <div className="panel panel-default panel_n">
                      <div className="panel-body panel-body1">
                        <div>
                          {/* <p> {selectedMessage.messageTypeId}</p>
                    <p>{selectedMessage.messageSubject}</p> 
                    <p></p> */}
                          {selectedMessage.messageBody}
                        </div>
                        <div className="col-sm-3 cs">
                          {/* <img src="images/reume_img.png" className="img-responsive" /> */}
                        </div>
                        <div className=" clearfix" />
                        <div>
                          <button
                            className="btn next_btn"
                            id="flip"
                            onClick={handleSendSMSBlock}
                          >
                            Send SMS
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      id="replay"
                      style={{
                        display: `${replyBlockDisplay}`,
                      }}
                    >
                      <div className="panel panel-default panel_n">
                        <div className="panel-body panel-body1">
                          <textarea
                            placeholder="Text here"
                            className="form-control"
                            onChange={(e) => setSendMessage(e.target.value)}
                          />
                          <div>
                            <button
                              className="btn next_btn"
                              onClick={handleSendSMS}
                              disabled={
                                selectedMessage.messageTypeId > 0 &&
                                selectedMessageTrans.length > 0
                                  ? false
                                  : true
                              }
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="clearfix" />
            </div>
          </div>
        )}
      {/*=================Content Section End================*/}
    </>
  );
};

export default BulkSMSComponent;
