import React, { useEffect } from "react";
import LayoutContainer from "./component/layout/LayoutContainer";
import {
  withRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import AuthService from "./services/AuthService";
import { SearchContextProvider } from "./context/general/SearchContext";
import { JobAlertContextProvider } from "./context/candidate/JobAlertContext";
import { JobTypeContextProvider } from "./context/general/JobTypeContext";
import { ExperienceContextProvider } from "./context/general/ExperienceContext";
import {
  GlobalSpinnerContext,
  GlobalSpinnerProvider,
} from "./context/GlobalSpinner";
import { DatePostedContextProvider } from "./context/general/DatePostedContext";
import { MyProfileContextProvider } from "./context/MyProfileContext";
import { JobAppliedContextProvider } from "./context/JobAppliedContext";
import { JobBookMarkContextProvider } from "./context/candidate/JobBookMarkContext";
import { DigiLockerContextProvider } from "./context/candidate/DigiLockerContext";
import GlobalSpinner from "./component/GlobalSpinner";
import { MatchedJobContextProvider } from "./context/MatchedJobContext";
import { NewlyPostedJobContextProvider } from "./context/general/NewlyPostedJobContext";
import { SuggestedJobContextProvider } from "./context/candidate/SuggestedJobContext";
import { CandidateProfileImageContextProvider } from "./context/candidate/CandidateMyProfile";
import { MyProfileVendorContextProvider } from "./context/MyProfileVendorContext";
import {
  VendorContextProvider,
  VendorProfileImageContextProvider,
} from "./context/vendor/VendorMyProfile";
import { MyProfileSuperAdminContextProvider } from "./context/MyProfileSuperAdminContext";
import {
  SuperAdminContextProvider,
  SuperAdminProfileImageContextProvider,
} from "./context/superadmin/SuperAdminMyProfile";
import { JobCategoryContextProvider } from "./context/JobCategoryContext";
import { SuperAdminUserContextProvider } from "./context/superadmin/SuperAdminUserContext";
import { SuperAdminReportContextProvider } from "./context/superadmin/SuperAdminReportContext";
import { AdminMyJobContextProvider } from "./context/admin/AdminMyJobContext";
import { AdminJobApplicationContextProvider } from "./context/admin/AdminJobApplicationContext";
import {
  AdminContextProvider,
  AdminProfileImageContextProvider,
} from "./context/admin/AdminMyProfile";
import { CategoryContextProvider } from "./context/general/CategoryContext";
import { VendorReportContextProvider } from "./context/admin/VendorReportContext";
import { MyProfileAdminContextProvider } from "./context/MyProfileAdminContext";
import { DashboardContextProvider } from "./context/candidate/DashboardContext";
import { VendorJobAppliedContextProvider } from "./context/vendor/VendorJobAppliedContext";
import { VendorJobBookMarkContextProvider } from "./context/vendor/VendorJobBookMarkContext";
import { AdminCandidateSearchrContextProvider } from "./context/admin/AdminCandidateSearchContext";
import { JobNotifyContextProvider } from "./context/candidate/JobNotifycontext";
import { GlobelCounterContextProvider } from "./context/general/GlobelCounterContext";
import { ClientProfileContextProvider } from "./context/client/ClientProfileCotext";
import { ClientJobContextProvider } from "./context/client/ClientJobContext";
import { BannerContextProvider } from "./context/admin/BannerContext";

interface IStartUpProps {}

interface IStartUpState {}

const initialState = {};

const StartUp: React.FC<IStartUpProps> = (props: any) => {
  const [startUpState, setStartUpState] = React.useState<IStartUpState>(
    initialState
  );

  const unprotectedRoutes = [
    "/registration",
    "/login",
    "/forgot-password",
    "/forgot-password-otp",
    "/otp-verification",
    "/job-search",
  ];

  const protectedRoutes = ["/candidate", "/admin", "/employee", "/vendor"];

  const authorizationToken = AuthService.accessToken;

  const location = useLocation();

  const _unprotectedRoutes = unprotectedRoutes.filter((e) => {
    return location.pathname.startsWith(e);
  });

  const _protectedRoutes = protectedRoutes.filter((e) => {
    return location.pathname.startsWith(e);
  });

  console.log(
    "StartUp component",
    authorizationToken,
    location,
    _unprotectedRoutes,
    _protectedRoutes,
    props
  );

  const _default = (
    <GlobalSpinnerProvider>
      <GlobalSpinnerContext.Consumer>
        {(context: any) => (
          <React.Fragment>
            <GlobalSpinner visible={context.visible} />
            <GlobelCounterContextProvider>
              <BannerContextProvider>
                <ClientJobContextProvider>
                  <ClientProfileContextProvider>
                    <JobNotifyContextProvider>
                      <AdminCandidateSearchrContextProvider>
                        <VendorJobBookMarkContextProvider>
                          <VendorJobAppliedContextProvider>
                            <DashboardContextProvider>
                              <VendorReportContextProvider>
                                <CategoryContextProvider>
                                  <AdminContextProvider>
                                    <MyProfileAdminContextProvider>
                                      <AdminProfileImageContextProvider>
                                        <AdminJobApplicationContextProvider>
                                          <AdminMyJobContextProvider>
                                            <SuperAdminReportContextProvider>
                                              <SuperAdminUserContextProvider>
                                                <JobCategoryContextProvider>
                                                  <CandidateProfileImageContextProvider>
                                                    <SuggestedJobContextProvider>
                                                      <NewlyPostedJobContextProvider>
                                                        <MatchedJobContextProvider>
                                                          <DigiLockerContextProvider>
                                                            <JobBookMarkContextProvider>
                                                              <JobAppliedContextProvider>
                                                                <VendorContextProvider>
                                                                  <MyProfileContextProvider>
                                                                    <DatePostedContextProvider>
                                                                      <ExperienceContextProvider>
                                                                        <JobTypeContextProvider>
                                                                          <JobAlertContextProvider>
                                                                            <MyProfileVendorContextProvider>
                                                                              <VendorProfileImageContextProvider>
                                                                                <SuperAdminContextProvider>
                                                                                  <MyProfileSuperAdminContextProvider>
                                                                                    <SuperAdminProfileImageContextProvider>
                                                                                      <SearchContextProvider>
                                                                                        <LayoutContainer />
                                                                                      </SearchContextProvider>
                                                                                    </SuperAdminProfileImageContextProvider>
                                                                                  </MyProfileSuperAdminContextProvider>
                                                                                </SuperAdminContextProvider>
                                                                              </VendorProfileImageContextProvider>
                                                                            </MyProfileVendorContextProvider>
                                                                          </JobAlertContextProvider>
                                                                        </JobTypeContextProvider>
                                                                      </ExperienceContextProvider>
                                                                    </DatePostedContextProvider>
                                                                  </MyProfileContextProvider>
                                                                </VendorContextProvider>
                                                              </JobAppliedContextProvider>
                                                            </JobBookMarkContextProvider>
                                                          </DigiLockerContextProvider>
                                                        </MatchedJobContextProvider>
                                                      </NewlyPostedJobContextProvider>
                                                    </SuggestedJobContextProvider>
                                                  </CandidateProfileImageContextProvider>
                                                </JobCategoryContextProvider>
                                              </SuperAdminUserContextProvider>
                                            </SuperAdminReportContextProvider>
                                          </AdminMyJobContextProvider>
                                        </AdminJobApplicationContextProvider>
                                      </AdminProfileImageContextProvider>
                                    </MyProfileAdminContextProvider>
                                  </AdminContextProvider>
                                </CategoryContextProvider>
                              </VendorReportContextProvider>
                            </DashboardContextProvider>
                          </VendorJobAppliedContextProvider>
                        </VendorJobBookMarkContextProvider>
                      </AdminCandidateSearchrContextProvider>
                    </JobNotifyContextProvider>
                  </ClientProfileContextProvider>
                </ClientJobContextProvider>
              </BannerContextProvider>
            </GlobelCounterContextProvider>
          </React.Fragment>
        )}
      </GlobalSpinnerContext.Consumer>
    </GlobalSpinnerProvider>
  );

  if (authorizationToken) {
    if (_unprotectedRoutes.length > 0) {
      return <Redirect to="/" />;
    } else {
      return _default;
    }
  } else {
    if (_protectedRoutes.length > 0) {
      // return <Redirect to="/login"  />;
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { stateValue: location.pathname },
          }}
        />
      );
    } else {
      return _default;
    }
  }

  /*if (authorizationToken) {
    if (_unprotectedRoutes.length > 0 && unprotectedRoutes[5]!="/job-search") {
      return <Redirect to="/" />
    } else if(unprotectedRoutes[5]!="/job-search"){

       return <Redirect to="/job-search"/>   
    }
    else {
      
      return<>
      <ExperienceContextProvider>
      <JobTypeContextProvider>
      <JobAlertContextProvider>
      <SearchContextProvider>
       <LayoutContainer />;
       </SearchContextProvider>
       </JobAlertContextProvider>
       </JobTypeContextProvider>
       </ExperienceContextProvider>
       </>
    }
  } else {
    if (_protectedRoutes.length > 0 && unprotectedRoutes[5]!="/job-search") {
      return <Redirect to="/login" />
    } else if(unprotectedRoutes[5]!="/job-search"){

       return <Redirect to="/job-search"/>   
    }

     else {
       
      return <>
      <ExperienceContextProvider>
      <JobTypeContextProvider>
      <JobAlertContextProvider>
      <SearchContextProvider>
      <LayoutContainer />;
      </SearchContextProvider>
      </JobAlertContextProvider>
      </JobTypeContextProvider>
      </ExperienceContextProvider>
      </>
    }
  }*/
};

export default StartUp;
