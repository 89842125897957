import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import { GetAllJobBookMarkRespondModel, VendorJobBookMarkRequestModel, VendorJobBookMarkRespondModel } from "../../models/vendor/VendorJobBookMark";
import { IVendorJobBookMarkManagementState, VendorJobBookMarkDispatcherContext, VendorJobBookMarkStateContext } from "../../context/vendor/VendorJobBookMarkContext";
import { VendorGetAllJobRequestModel } from "../../models/vendor/VendorJobApplied";
import message from "antd/lib/message";





let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IVendorJobBookMarkAction =
  | {
      type: "GET_ALL_BOOKMARK_LIST";
      vendorGetAllJobBookMark: GetAllJobBookMarkRespondModel;
    }| {
      type: "SAVE_BOOKMARK";
      bookmarkSaveResult:VendorJobBookMarkRespondModel
    }
 
    

export const vendorJobBookMarkReducer: Reducer<
  IVendorJobBookMarkManagementState,
  IVendorJobBookMarkAction
> = (draft, action): IVendorJobBookMarkManagementState => {
  switch (action.type) {
    case "GET_ALL_BOOKMARK_LIST":
      draft.vendorGetAllJobBookMark = action.vendorGetAllJobBookMark;
      return draft;
      case "SAVE_BOOKMARK":
        draft.bookmarkSaveResult = action.bookmarkSaveResult;
        return draft;
      
    
   
  }
};

export const useVendorJobBookMarkDispatcher = (): React.Dispatch<IVendorJobBookMarkAction> => {
  const vendorJobBookMarkDispatcher = React.useContext(VendorJobBookMarkDispatcherContext);
  if (!vendorJobBookMarkDispatcher) {
    throw new Error(
      "You have to provide the VendorJobBookMark dispatcher using theVendorJobBookMarkDispatcherContext.Provider in a parent component."
    );
  }
  return vendorJobBookMarkDispatcher;
};

export const useVendorJobBookMarkContext = (): IVendorJobBookMarkManagementState => {
  const vendorJobBookMarkContext = React.useContext(VendorJobBookMarkStateContext);
  if (!vendorJobBookMarkContext)
    throw new Error(
      "You have to provide the vendorJobBookMark context using the VendorJobBookMarkStateContext.Provider in a parent component."
    );
  return vendorJobBookMarkContext;
};

// Get All applied job List



export const getVendorJobBookMarkList = async (
  dispatcher: React.Dispatch<IVendorJobBookMarkAction>,
  query: VendorGetAllJobRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllJobBookMark;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type:  "GET_ALL_BOOKMARK_LIST",vendorGetAllJobBookMark: res.data});
    });
  } catch (e) {}
};

// bookMark And UnBookMark

export const setVendorJobBookmark = async (
  dispatcher: React.Dispatch<IVendorJobBookMarkAction>,
  query: VendorJobBookMarkRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveAndUnSaveVendorBookMark;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type:"SAVE_BOOKMARK", bookmarkSaveResult: res.data  });
      res.data.isSuccess
      ? message.success(res.data.message)
      : message.error(res.data.message);

    });
  } catch (e) {}
};

// // Save call

// export const setVendorJobBookMarkSave = async (
//   dispatcher: React.Dispatch<IVendorJobBookMarkAction>,
//   query: VendorJobBookMarkRequestModel,
//   token: string
// ) => {
//   if (token?.startsWith(`"`)) {
//     token = token.substring(1);
//     token = token.slice(0, -1);
//   }
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     };

//     const url = AppUrls.SaveVendorJobAlert;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({ type: "SET_JOB_APPLIED_SAVE", vendorJobBookMarkSaveRespond: res.data });
//     });
//   } catch (e) {}
// };

