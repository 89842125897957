import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  MutableRefObject,
} from "react";
import { useForm, Controller } from "react-hook-form";
import PikadayDatepicker from "../../candidate/my_profile/components/PikadayDatepicker";
import SelectOption from "../../candidate/my_profile/components/SelectOption";
import SelectMulti from "../../candidate/my_profile/components/SelectMulti";
import _, { isNull } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import { reactHookFormServerErrors } from "./../../../utils/utility";
import { pascal2Camel, camel2Pascal } from "./../../../utils/utility";
import { Modal } from "react-bootstrap";
import IsComponentDisabled from "./../../components/IsComponentDisabled";
import PhoneNumberWithCode from "./../../components/PhoneNumberWithCode";
import Timer from "react-compound-timer";
import {
  sendEmailOpt,
  sendPhoneOtp,
  verifyCode,
  emailVerifyCode,
  verifyCodeNewMobile,
  createVendor,
} from "./../../../apis/misc";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "./../../../context/GlobalSpinner";
import { saveVendor } from "./../../../apis/vendor";
import { getLoggedUserId } from "../../../action/MyProfileAction";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  Popconfirm,
  message,
  Upload,
  Checkbox,
  Divider,
  Tooltip,
  Popover,
} from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { confirmWrapper, confirm } from "./../../GlobalConfirm";
import { deleteVendorDocument } from "./../../../apis/vendor";
import { Left } from "react-bootstrap/lib/Media";
import ReactDOM from "react-dom";

type objectIndexing = {
  [key: string]: any;
};

function EditContact2(props: any) {
  const [contactNumber, setContactNumber] = useState("");

  const [countryCode, setCountryCode] = useState("+91");

  const [showTimer, setShowTimer] = useState(false);

  const [timerMsg, setTimerMsg] = useState("");

  const [screen, setScreen] = useState(1);

  const globalSpinner = useGlobalSpinnerContext();

  const [disabled, setDisabled] = useState(false);

  const [eDisabled, setEDisabled] = useState(true);

  const { register, handleSubmit, watch, errors, trigger, control } = useForm<{
    contactNumber: string;
    countryCode: string;
  }>({
    defaultValues: { contactNumber: "", countryCode: "" },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    errors: errors2,
    setError: setError2,
    trigger: trigger2,
    clearErrors: clearErrors2,
  } = useForm<{ code: string }>({
    defaultValues: { code: "" },
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    watch: watch3,
    control: control3,
    errors: errors3,
    trigger: trigger3,
    clearErrors: clearErrors3,
  } = useForm<{ newContactNumber: string; newCountryCode: string }>({
    defaultValues: { newContactNumber: "", newCountryCode: "+91" },
  });

  const onSubmit = (data: any) => {
    console.log(data);

    setCountryCode(data["countryCode"] == "" ? "+91" : data["countryCode"]);
    setContactNumber(data["contactNumber"]);
    globalSpinner.showSpinner();
    // sendPhoneOtp({}, `?countryCode=${encodeURIComponent(data["countryCode"])}&phoneNumber=${encodeURIComponent(data["contactNumber"])}`).then((res: any) => {
    sendPhoneOtp(
      {},
      `?countryCode=${encodeURIComponent(
        data["countryCode"] == "" ? "+91" : data["countryCode"]
      )}&phoneNumber=${encodeURIComponent(data["contactNumber"])}`
    )
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (res.data.includes("error") || res.data.includes("Invalid")) {
          toast.error(res.data);
        } else {
          // toast.success("OTP has been send.");
          toast.success(
            "OTP has been successfully sent on your mobile number."
          );
          setScreen(2);
          setShowTimer(true);
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const sendVerification = async () => {
    console.log(11, countryCode);
    console.log(12, contactNumber);

    globalSpinner.showSpinner();
    sendPhoneOtp(
      {},
      `?countryCode=${encodeURIComponent(
        countryCode
      )}&phoneNumber=${encodeURIComponent(contactNumber)}`
    )
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (res.data.includes("error") || res.data.includes("Invalid")) {
          toast.error(res.data);
        } else {
          //toast.success("OTP has been resend.");
          toast.success(
            "OTP has been successfully sent on your mobile number."
          );
          setShowTimer(true);
          setTimerMsg("");
          setDisabled(false);
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const onSubmit2 = (data: any) => {
    // console.log(33, data);
    // console.log(41, countryCode);
    // console.log(42, contactNumber);
    // console.log(43, props.oldCountryCode);
    // console.log(44, props.oldCountryCode);
    globalSpinner.showSpinner();
    verifyCodeNewMobile(
      {},
      `?newCountryCode=${encodeURIComponent(
        countryCode
      )}&newPhoneNumber=${encodeURIComponent(
        contactNumber
      )}&oldCountryCode=${encodeURIComponent(
        props.oldCountryCode
      )}&oldPhoneNumber=${encodeURIComponent(props.oldPhoneNumber)}&code=${
        data["code"]
      }`
    )
      .then((res: any) => {
        globalSpinner.hideSpinner();
        console.log(res);
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          toast.success("OTP verified.");
          setTimerMsg("");
          //setScreen(3);
          let data = {
            newCountryCode: countryCode,
            newContactNumber: contactNumber,
          };
          props.updateNumber(data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        //toast.error("Something went wrong");
        //toast.error("Error: Invalid OTP.Please enter correct OTP");
        reactHookFormServerErrors(
          {
            code: ["Error: Invalid OTP.Please enter correct OTP"],
          },
          setError2
        );
      });
  };

  const onSubmit3 = (data: any) => {
    console.log(data);
    props.updateNumber(data);
  };

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit Contact number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-12">
          {screen == 1 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Contact Number</label>
                {/* <input type="text" className="form-control" placeholder="Type here" name="contactNumber" ref={register({
                  required: "Number is required"
                })} /> */}
                <Controller
                  control={control}
                  name="countryCode"
                  render={({ onChange, onBlur, value, name }) => (
                    <PhoneNumberWithCode
                      // codeHeight={"50px"}
                      downwards={true}
                      noPenIcon={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      disabled={false}
                      phoneNo={
                        <input
                          type="text"
                          name="contactNumber"
                          disabled={false}
                          id="phoneNumber"
                          className="form-control phoneNumber"
                          placeholder="Type here"
                          ref={register({
                            required: "Mobile number is required",
                            maxLength: {
                              value: watch("countryCode").length > 3 ? 9 : 10,
                              message: `It must be ${
                                watch("countryCode").length > 3 ? 9 : 10
                              } digits only.`,
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Mobile number should be numbers only",
                            },
                            minLength: {
                              value: watch("countryCode").length > 3 ? 9 : 10,
                              message: `It must be ${
                                watch("countryCode").length > 3 ? 9 : 10
                              } digits only.`,
                            },
                          })}
                        />
                      }
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="contactNumber"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 2 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Enter Code to confirm </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="code"
                  ref={register2({
                    required: "Code is required",
                  })}
                />

                <ErrorMessage
                  errors={errors2}
                  name="code"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />

                {showTimer ? (
                  <Timer
                    initialTime={60000 * 5}
                    direction="backward"
                    onStop={() => {
                      console.log("stop");
                    }}
                    onResume={() => console.log("onResume hook")}
                    checkpoints={[
                      {
                        time: 0,
                        callback: () => {
                          console.log("Checkpoint A");
                          setShowTimer(false);
                          setTimerMsg("OTP Expired.");
                          setDisabled(true);
                        },
                      },
                    ]}
                  >
                    {({ reset }: { reset: any }) => (
                      <React.Fragment>
                        <div className="login_validation">
                          {" "}
                          OTP Expires in <Timer.Minutes /> Minutes,{" "}
                          <Timer.Seconds /> Seconds
                        </div>
                      </React.Fragment>
                    )}
                  </Timer>
                ) : (
                  ""
                )}
                {timerMsg ? (
                  <div className="login_validation">{timerMsg}</div>
                ) : (
                  ""
                )}
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 3 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Contact Number</label>
                {/* <input type="text" className="form-control" placeholder="Type here" name="newContactNumber" ref={register3({
                  required: "New Number is required"
                })} /> */}
                <Controller
                  control={control3}
                  name="newCountryCode"
                  render={({ onChange, onBlur, value, name }) => (
                    <PhoneNumberWithCode
                      // codeHeight={"50px"}
                      noPenIcon={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      disabled={false}
                      phoneNo={
                        <input
                          type="text"
                          name="newContactNumber"
                          disabled={false}
                          id="phoneNumber"
                          className="form-control phoneNumber"
                          placeholder="Type here"
                          ref={register({
                            required: "Mobile number is required",
                            maxLength: {
                              //value: 15,
                              value: 10,
                              message: "Should be a mobile number",
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Mobile number should be numbers only",
                            },
                          })}
                        />
                      }
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors3}
                  name="newContactNumber"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}
        </div>

        {screen == 2 ? (
          <div className="already_login1">
            Didn't get the code?
            <a
              className="_cursor-pointer"
              onClick={() => {
                sendVerification();
              }}
            >
              &nbsp;Send again.
            </a>{" "}
            {/*  { if (!showTimer)}*/}
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
      <div className="modal-footer  m-t-30">
        {screen == 1 ? (
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Send Verification Code
          </button>
        ) : (
          ""
        )}
        {screen == 2 ? (
          <button
            onClick={handleSubmit2(onSubmit2)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
            disabled={disabled}
          >
            Verify
          </button>
        ) : (
          ""
        )}
        {screen == 3 ? (
          <button
            onClick={handleSubmit3(onSubmit3)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Save
          </button>
        ) : (
          ""
        )}
        <button
          onClick={() => {
            props.setIsOpen(!props.isOpen);
          }}
          data-dismiss="modal"
          className="btn btn-default waves-effect"
          type="button"
        >
          Cancel
        </button>
      </div>
      <div className="clearfix" />
    </React.Fragment>
  );
}

function EditEmail2(props: any) {
  const [email, setEmail] = useState("");

  const [showTimer, setShowTimer] = useState(false);

  const [timerMsg, setTimerMsg] = useState("");

  const [screen, setScreen] = useState(1);

  const globalSpinner = useGlobalSpinnerContext();
  const [disabled, setDisabled] = useState(false);
  const [disabledEmail, setDisabledEmail] = useState<boolean>(true);

  const emailRef = useRef<HTMLInputElement | null>(null);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    trigger,
    clearErrors,
  } = useForm<{ email: string }>({
    defaultValues: { email: "" },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    errors: errors2,
    trigger: trigger2,
    setError: setError2,
    clearErrors: clearErrors2,
  } = useForm<{ code: string }>({
    defaultValues: { code: "" },
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    watch: watch3,
    errors: errors3,
    trigger: trigger3,
    clearErrors: clearErrors3,
  } = useForm<{ newEmail: string }>({
    defaultValues: { newEmail: "" },
  });

  const onSubmit = (data: any) => {
    console.log(data);

    setEmail(data["email"]);

    globalSpinner.showSpinner();
    sendEmailOpt({}, `?email=${data["email"]}`)
      .then((res: any) => {
        console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          //toast.success("OTP has been send.");
          toast.success("OTP has been successfully sent on your e-mail.");
          setScreen(2);
          setShowTimer(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const sendVerification = async () => {
    console.log(email);

    globalSpinner.showSpinner();
    sendEmailOpt({}, `?email=${email}`)
      .then((res: any) => {
        console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          //toast.success("OTP has been resend.");
          toast.success("OTP has been successfully sent on your e-mail.");
          setShowTimer(true);
          setTimerMsg("");
          setDisabled(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const onSubmit2 = (data: any) => {
    console.log(data);

    globalSpinner.showSpinner();

    emailVerifyCode({}, `?email=${email}&code=${data["code"]}`)
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          toast.success("OTP verified.");
          setTimerMsg("");
          setScreen(3);
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        //toast.error("Something went wrong");
        reactHookFormServerErrors(
          {
            code: ["Error: Invalid OTP.Please enter correct OTP"],
          },
          setError2
        );
      });
  };

  const onSubmit3 = (data: any) => {
    console.log(data);
    props.updateEmail(data);
  };

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-12">
          {screen == 1 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="email"
                  ref={register({
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please enter a valid e-mail address."
                    }
                  })}
                /> */}
                {IsComponentDisabled(
                  <input
                    type="text"
                    className={"form-control input-name"}
                    placeholder="Type here"
                    name="email"
                    ref={(e: HTMLInputElement) => {
                      register(e, {
                        required: "Email is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Should be a valid email",
                        },
                      });
                      emailRef.current = e;
                    }}
                    disabled={disabledEmail}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 2 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Enter Code to confirm </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="code"
                  ref={register2({
                    required: "Code is required",
                  })}
                />
                <ErrorMessage
                  errors={errors2}
                  name="code"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
                {showTimer ? (
                  <Timer
                    initialTime={60000 * 5}
                    direction="backward"
                    onStop={() => {
                      console.log("stop");
                    }}
                    onResume={() => console.log("onResume hook")}
                    checkpoints={[
                      {
                        time: 0,
                        callback: () => {
                          console.log("Checkpoint A");
                          setShowTimer(false);
                          setTimerMsg("OTP Expired.");
                          setDisabled(true);
                        },
                      },
                    ]}
                  >
                    {({ reset }: { reset: any }) => (
                      <React.Fragment>
                        <div className="login_validation">
                          {" "}
                          OTP Expires in <Timer.Minutes /> Minutes,{" "}
                          <Timer.Seconds /> Seconds
                        </div>
                      </React.Fragment>
                    )}
                  </Timer>
                ) : (
                  ""
                )}
                {timerMsg ? (
                  <div className="login_validation">{timerMsg}</div>
                ) : (
                  ""
                )}
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 3 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="newEmail"
                  ref={register3({
                    required: "New email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors3}
                  name="newEmail"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}
        </div>

        {screen == 2 ? (
          <div className="already_login1">
            Didn't get the code?
            <a
              className="_cursor-pointer"
              onClick={() => {
                if (!showTimer) {
                  sendVerification();
                }
              }}
            >
              &nbsp;Send again.
            </a>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
      <div className="modal-footer  m-t-30">
        {screen == 1 ? (
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Send Verification Code
          </button>
        ) : (
          ""
        )}
        {screen == 2 ? (
          <button
            onClick={handleSubmit2(onSubmit2)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
            disabled={disabled}
          >
            Verify
          </button>
        ) : (
          ""
        )}
        {screen == 3 ? (
          <button
            onClick={handleSubmit3(onSubmit3)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Save
          </button>
        ) : (
          ""
        )}
        <button
          onClick={() => {
            props.setIsOpen2(!props.isOpen2);
          }}
          data-dismiss="modal"
          className="btn btn-default waves-effect"
          type="button"
        >
          Cancel
        </button>
      </div>
      <div className="clearfix" />
    </React.Fragment>
  );
}

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IPersonelInfoState {
  rowId: number;
  vendorId: number;
  vendorName: string;
  jobRole: string;
  location: string;
  designation: string;
  countryCode: string;
  phoneNumber: string;
  organisation: string;
  email: string;
  aboutMe: string;
  vendorDocument: string;
  document: string;
  userId: number;
  vendorStatusId: number;
  isActive: boolean;
}

function PersonelInfo(props: any) {
  const { myProfile, vendorId, userId, vendorState, getVendors } = props;

  const [emailData, setEmailData] = useState("");
  const [phoneData, setPhoneData] = useState("");
  const [phoneCountryData, setPhoneCountryData] = useState("");

  let defaultValues = {
    rowId: 0,
    vendorId: 0,
    vendorName: "",
    jobRole: "",
    location: "",
    designation: "",
    countryCode: "",
    phoneNumber: "",
    organisation: "",
    email: "",
    aboutMe: "",
    vendorDocument: "",
    document: "",
    userId: 0,
    vendorStatusId: 0,
    isActive: true,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<IPersonelInfoState>({
    defaultValues,
  });

  //console.log(getValues());

  const [disabled, setDisabled] = useState<boolean>(true);

  const [disabledEmail, setDisabledEmail] = useState<boolean>(true);

  const [disabledContact, setDisabledContact] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const [isOpen3, setIsOpen3] = useState<boolean>(false);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const contactNumberRef = useRef<HTMLInputElement | null>(null);

  const emailRef = useRef<HTMLInputElement | null>(null);

  const [filename, setFileName] = useState("");

  const [obj, setObj] = useState<any>({ file: null });

  const [fullPath, setFullPath] = useState<string>("");

  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);

  const inputFileRef = useRef() as MutableRefObject<FileUploads>;

  const [vendorDoc, setVendorDoc] = useState();

  const handleDrop = (files: any) => {
    if (
      [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/docx",
        "application/pdf",
      ].includes(files[0].type) &&
      files[0].size < 1000000 * 5
    ) {
      console.log(files[0]);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
        setFileName(files[0].name);
      }
    }
  };

  const handleDownloadFile = () => {
    return null;
  };

  const handleShareButtonClick = (id: any, value: any, FullPath: any) => {
    console.log("share button clicked!");
    //if (authorizationToken != null) {
    setFullPath(FullPath);
    setIsShareOpen(!isShareOpen);
    //}
  };

  const handleDelete = async () => {
    if (
      await confirm({
        confirmation: "Are you sure you want to delete this?",
        options: {
          yes: "Yes",
          no: "No",
          header: "Delete",
        },
      })
    ) {
      console.log("yes");
      globalSpinner.showSpinner();
      deleteVendorDocument({ RowId: vendorId })
        .then((res) => {
          console.log("----delete doc----", res);
          globalSpinner.hideSpinner();
          if (res.data.isSuccess) {
            getVendors({
              VendorId: Number(vendorId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "string",
              SortOrder: "string",
              ShowInactive: false,
            });
            toast.success("Document removed.");
            props.docStatusRefresh();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          globalSpinner.hideSpinner();
          toast.error(err.toString());
        });
    } else {
      console.log("no");
    }
  };

  const inputNameRef = useRef<FileUploads>(null);

  const handleEdit = async () => {
    // if(inputNameRef!=null)
    //  inputNameRef.current.click()

    var list = document.getElementsByClassName("inputfile-1")[0] as HTMLElement;
    list.click();
    console.log("list--------", list);
    // var ev2 = new Event('click', { bubbles: true});
    // list.dispatchEvent(ev2);
    // list.current.click();
    //const label = React.createHtmlElement('label',{htmlFor: 'inputfile-1'},'Select File')
    //var ev2 = new Event('click', { bubbles: true});
    //label.click();
    // if(inputFileRef!=null)
    // {
    //   inputFileRef.current.focus();
    // }
  };

  const updateNumber = (data: any) => {
    console.log(data);
    const _data1 = Object.assign({}, vendorState.data[0], {
      countryCode: data["newCountryCode"],
      phoneNumber: data["newContactNumber"],
      email: emailData,
    });
    globalSpinner.showSpinner();
    saveVendor(_data1)
      .then((res: any) => {
        //console.log(res.data);
        if (res.data.isSuccess) {
          getVendors({
            vendorId: Number(vendorId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success("Contact number updated.");
          setIsOpen(false);
          globalSpinner.hideSpinner();
        } else {
          globalSpinner.hideSpinner();
          toast.error(res.data.message);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong.");
      });
  };

  const updateEmail = (data: any) => {
    //console.log(data);

    const _data1 = Object.assign({}, vendorState.data[0], {
      phoneNumber: data["newEmail"],
    });
    globalSpinner.showSpinner();
    saveVendor(_data1)
      .then((res: any) => {
        console.log(res.data);
        if (res.data.isSuccess) {
          getVendors({
            vendorId: Number(vendorId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success("Email updated.");
          setIsOpen2(false);
        } else {
          globalSpinner.hideSpinner();
          toast.error(res.data.message);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong.");
      });
  };

  useEffect(() => {
    console.log("----vendorState----", vendorState);
    if (vendorState.data.length > 0) {
      setValue("rowId", vendorState.data[0]["rowId"]);
      setValue("vendorId", 0);
      setValue("vendorName", vendorState.data[0]["vendorName"]);
      setValue("jobRole", vendorState.data[0]["jobRole"]);
      setValue("location", vendorState.data[0]["location"]);
      setValue("designation", vendorState.data[0]["designation"]);
      setValue("countryCode", vendorState.data[0]["countryCode"]);
      setValue("phoneNumber", vendorState.data[0]["phoneNumber"]);
      setValue("organisation", vendorState.data[0]["organisation"]);
      setValue("email", vendorState.data[0]["email"]);
      setValue("aboutMe", vendorState.data[0]["aboutMe"]);
      setValue("vendorDocument", vendorState.data[0]["vendorDocument"]);
      setValue("document", vendorState.data[0]["document"]);
      setValue("userId", userId);
      setValue("vendorStatusId", vendorState.data[0]["vendorStatusId"]);
      setValue("isActive", vendorState.data[0]["isActive"]);
      setPhoneData(vendorState.data[0]["phoneNumber"]);
      setPhoneCountryData(vendorState.data[0]["countryCode"]);
      setEmailData(vendorState.data[0]["email"]);

      setVendorDoc(<div></div>);
      if (
        vendorState.data[0]["vendorDocument"] !== "null" &&
        vendorState.data[0]["vendorDocument"] !== null &&
        vendorState.data[0]["vendorDocument"] !== undefined
      ) {
        setVendorDoc(
          <div className="matched_jobs">
            {vendorState.data[0]["vendorDocument"].split(".")[1] !== "pdf" &&
            vendorState.data[0]["vendorDocument"].split(".")[1] !== "docx" ? (
              <img
                src={`https://careerapp.azurewebsites.net/Upload/VendorDocument/${vendorState.data[0]["vendorDocument"]}`}
                alt=""
                className="image"
              />
            ) : (
              <img
                src={require("../../../images/digi_document.jpg")}
                alt=""
                className="img-responsive"
              />
            )}
            <div className="overlay">
              <div className="myresume_edits2 ">
                <a
                  onClick={handleDownloadFile}
                  href={
                    vendorState.data.length > 0
                      ? `https://careerapp.azurewebsites.net/api/Vendor/download-doc/${vendorState.data[0]["rowId"]}`
                      : "#"
                  }
                  className="myresume_clr1"
                >
                  <i className="fa fa-download" aria-hidden="true" />
                </a>

                <a
                  onClick={() =>
                    handleShareButtonClick(
                      vendorState.data.length > 0
                        ? vendorState.data[0]["rowId"]
                        : 0,
                      true,
                      "FullPath"
                    )
                  }
                  data-target="#share"
                  data-toggle="modal"
                  className="myresume_clr2"
                >
                  <i className="fa fa-share-alt" aria-hidden="true" />
                </a>
                {props.documentStatus !==
                "Document verification status - Approved" ? (
                  <div>
                    <a onClick={handleEdit} className="myresume_clr3">
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </a>
                    <a onClick={handleDelete} className="myresume_clr4">
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      }
    }
  }, [vendorState.data.length]);

  const onSubmit = (data: any) => {
    //console.log(data);

    if (!disabled) {
      let _data1: objectIndexing = {};
      let _data2: objectIndexing = {};

      if (vendorState.data.length > 0) {
        //console.log(vendorState.data[0]);
        _data1 = vendorState.data[0];

        // _data1["rowId"] = data["rowId"];
        // _data1["vendorId"] = Number(vendorId);
        // _data1["vendorName"] = data["vendorName"];
        // _data1["jobRole"] = data["jobRole"];
        // _data1["location"] = data["location"];
        // _data1["designation"] = data["designation"];
        // _data1["countryCode"] = data["countryCode"];
        // _data1["phoneNumber"] = data["phoneNumber"];
        // _data1["organisation"] = data["organisation"];
        // _data1["email"] = data["email"];
        // _data1["aboutMe"] = data["aboutMe"];
        // _data1["vendorDocument"] = data["vendorDocument"];
        // _data1["document"] = data["document"];
        // _data1["userId"] = userId;
        // _data1["vendorStatusId"] = data["vendorStatusId"];
        // _data1["isActive"] = data["isActive"];
        // console.log("----Save vendorState----",_data1);

        const formData = new FormData();
        formData.append("RowId", String(data["rowId"]));
        formData.append("VendorId", JSON.stringify(Number(vendorId)));
        formData.append("VendorName", String(data["vendorName"]));
        formData.append("JobRole", String(data["jobRole"]));
        formData.append("Location", String(data["location"]));
        formData.append("Designation", String(data["designation"]));
        formData.append("CountryCode", String(_data1["countryCode"]));
        formData.append("PhoneNumber", String(_data1["phoneNumber"]));
        formData.append("Organisation", String(data["organisation"]));
        formData.append("Email", String(emailData));
        formData.append("AboutMe", String(data["aboutMe"]));
        formData.append("VendorDocument", String(null));
        formData.append("Document", obj.file);
        formData.append("UserId", JSON.stringify(Number(userId)));
        formData.append("VendorStatusId", JSON.stringify(2));
        formData.append("IsActive", JSON.stringify(true));

        for (var key of (formData as any).entries()) {
          console.log(key[0] + ", " + key[1]);
        }

        globalSpinner.showSpinner();
        console.log("----Form Data----", formData);
        saveVendor(formData)
          .then((res: any) => {
            console.log(res.data);
            globalSpinner.hideSpinner();
            if (res.data.isSuccess) {
              getVendors({
                VendorId: Number(vendorId),
                Page: 1,
                PageSize: 10,
                SearchTerm: "string",
                SortOrder: "string",
                ShowInactive: false,
              });
              toast.success("Personal Info details are saved.");
            } else {
              console.log(res.data);
              globalSpinner.hideSpinner();
              toast.error(res.data.message);
            }
          })
          .catch((err: any) => {
            console.log(err);
            globalSpinner.hideSpinner();
            toast.error(err.toString());
          });
      }
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-0">
        <span className="resp-arrow" />
        Personal Info
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-0"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Personal Info</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div>
          <form
            className="personal_details"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <input
              type="number"
              name="rowId"
              style={{ display: "none" }}
              disabled={disabled}
              ref={register}
            />

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Name</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <input
                        type="text"
                        name="vendorName"
                        className={"form-control"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          required: "Name is required.",
                          pattern: {
                            value: /^[a-zA-Z ]*$/,
                            message:
                              "Name must have words only with spaces between them.",
                          },
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="vendorName"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label> Role</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <input
                        type="text"
                        name="jobRole"
                        className={"form-control"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          //required: "Role is required.",
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="jobRole"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label> Location</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <input
                        type="text"
                        name="location"
                        className={"form-control"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          //required: "Location is required.",
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="location"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label>Designation</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <input
                        type="text"
                        name="designation"
                        className={"form-control"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          //required: "Designation is required.",
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="designation"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Phone</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <Controller
                        control={control}
                        name="countryCode"
                        render={({ onChange, onBlur, value, name }) => (
                          <PhoneNumberWithCode
                            // codeHeight={"50px"}
                            noPenIcon={true}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                            disabled={disabledContact}
                            phoneNo={
                              <input
                                type="text"
                                name="phoneNumber"
                                disabled={disabledContact}
                                id="phoneNumber"
                                className="form-control phoneNumber"
                                placeholder="Type here"
                                ref={register({
                                  required: "Mobile number is required",
                                  maxLength: {
                                    value:
                                      watch("countryCode").length > 3 ? 9 : 10,
                                    message: `It must be ${
                                      watch("countryCode").length > 3 ? 9 : 10
                                    } digits only.`,
                                  },
                                  pattern: {
                                    value: /^[0-9]*$/,
                                    message:
                                      "Mobile number should be numbers only",
                                  },
                                  minLength: {
                                    value:
                                      watch("countryCode").length > 3 ? 9 : 10,
                                    message: `It must be ${
                                      watch("countryCode").length > 3 ? 9 : 10
                                    } digits only.`,
                                  },
                                })}
                              />
                            }
                          />
                        )}
                      />,
                      disabled
                    )}

                    <div className="profile_edit1">
                      <a
                        onClick={() => {
                          setIsOpen3(!isOpen3);
                        }}
                      >
                        <i
                          className="fa fa-pencil _cursor-pointer"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="countryCode"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="phoneNumber"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label>Organisation</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <input
                        type="text"
                        name="organisation"
                        className={"form-control"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          //required: "Organisation is required.",
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="organisation"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Email</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <input
                        type="text"
                        name="email"
                        className={"form-control"}
                        placeholder="Type here"
                        ref={(e: HTMLInputElement) => {
                          register(e, {
                            required: "Email is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please enter a valid e-mail address.",
                            },
                          });
                          emailRef.current = e;
                        }}
                        disabled={disabledEmail}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label> About Me</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <textarea
                        name="aboutMe"
                        className={"form-control form-contro11"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          //required:
                          // "About cannot be blank.",
                          validate: (value) => {
                            //exclude  start and end white-space
                            value = value.replace(/(^\s*)|(\s*$)/gi, "");
                            //convert 2 or more spaces to 1
                            value = value.replace(/[ ]{2,}/gi, " ");
                            // exclude newline with a start spacing
                            value = value.replace(/\n /, "\n");

                            if (value.split(" ").length > 250) {
                              return "Maximum words: 250";
                            }
                          },
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="aboutMe"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-6">
                    {props.documentStatus !==
                    "Document verification status - Approved" ? (
                      <FileUploads
                        accept=".pdf,.jpg,.jpeg,.png,.docx"
                        onChange={(file: any) => {
                          handleDrop([file]);
                        }}
                        name="Upload Licence/ Registration Doc"
                        disabled={false}
                        ref={inputFileRef}
                      />
                    ) : (
                      // <Popover content={"You Need To Delete Your Document"}>
                      <FileUploads
                        accept=".pdf,.jpg,.jpeg,.png,.docx"
                        onChange={(file: any) => {
                          handleDrop([file]);
                        }}
                        name="Upload Licence/ Registration Doc"
                        disabled={true}
                        ref={inputFileRef}
                      />
                      // </Popover>
                    )}
                    {filename}
                  </div>

                  <div className="col-sm-6">{vendorDoc}</div>
                </div>
              </div>
            </div>

            <div className="clearfix" />
            {disabled ? (
              <Tooltip title="Need to click pencil icon for save">
                <button
                  type="submit"
                  className="btn "
                  disabled={disabled}
                  style={{ backgroundColor: "gray" }}
                >
                  Save
                </button>
              </Tooltip>
            ) : (
              <button type="submit" className="btn submit_btn" disabled={false}>
                Save
              </button>
            )}
            {/* <button
              type="submit"
              className="btn submit_btn"
              disabled={disabled}
            >
              Save
            </button> */}
          </form>
        </div>
      </div>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Update your account password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="email">New Password</label>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Type here"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Re-enter new password</label>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Type here"
                />
              </div>
              <div className="profile_cons1">
                Note: Password should be 8 digits long with at least one
                Cappital letter, one small letter and a special character.
              </div>
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
              Verify
            </button>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="clearfix" />
        </form>
      </Modal>

      <Modal
        show={isOpen3}
        onHide={() => {
          setIsOpen3(!isOpen3);
        }}
      >
        <EditContact2
          setIsOpen={setIsOpen3}
          isOpen={isOpen3}
          updateNumber={updateNumber}
          oldPhoneNumber={phoneData}
          oldCountryCode={phoneCountryData}
        />
      </Modal>

      <Modal
        show={isOpen2}
        onHide={() => {
          setIsOpen2(!isOpen2);
        }}
      >
        <EditEmail2
          setIsOpen2={setIsOpen2}
          isOpen2={isOpen2}
          updateEmail={updateEmail}
        />
      </Modal>

      <Modal
        show={isShareOpen}
        onHide={() => {
          setIsShareOpen(!isShareOpen);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share this document on</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 m_t_30 text-center">
            <div className="social1">
              <WhatsappShareButton
                url={encodeURI(
                  vendorState.data.length > 0
                    ? `https://careerapp.azurewebsites.net/Upload/VendorDocument/${vendorState.data[0]["vendorDocument"]}`
                    : `no file`
                )}
                className={""}
                title={"CareerApp Job Post"}
              >
                {/* <a href="#" className="social_face"> */}
                <Tooltip title="whatsApp">
                  <i
                    className="fa fa-whatsapp"
                    style={{ fontSize: 51, color: "green", paddingRight: 2 }}
                  ></i>
                </Tooltip>
                {/* </a> */}
              </WhatsappShareButton>{" "}
              <TelegramShareButton
                url={`career app job Share`}
                title={encodeURI(
                  vendorState.data.length > 0
                    ? `https://careerapp.azurewebsites.net/Upload/VendorDocument/${vendorState.data[0]["vendorDocument"]}`
                    : `no file`
                )}
              >
                <Tooltip title="Telegram">
                  <i
                    className="fa fa-telegram"
                    style={{ fontSize: 48, color: "rgb(55, 174, 226)" }}
                  ></i>
                </Tooltip>
              </TelegramShareButton>
              <EmailShareButton
                url={encodeURI(
                  vendorState.data.length > 0
                    ? `https://careerapp.azurewebsites.net/Upload/VendorDocument/${vendorState.data[0]["vendorDocument"]}`
                    : `no file`
                )}
                subject={"Jobs In Carrer app"}
                body={"This is a Jobs from   Carrer app"}
                className={""}
              >
                {/* <EmailIcon></EmailIcon> */}
                {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                <Tooltip title="Gmail">
                  <img
                    src={require("../../../images/gmail-icon-svg-28.jpg")}
                    style={{ height: 66, marginTop: -24 }}
                  />
                </Tooltip>
              </EmailShareButton>{" "}
              {vendorState.data.length > 0 ? (
                <CopyToClipboard
                  text={`https://careerapp.azurewebsites.net/Upload/VendorDocument/${vendorState.data[0]["vendorDocument"]}`}
                >
                  <Tooltip title="copy to clipboard">
                    {" "}
                    <img
                      src={require("../../../images/copy-clipboard-icon-28.jpg")}
                      style={{ height: 66, marginTop: -24, marginLeft: -15 }}
                      onClick={() => {
                        setIsShareOpen(!isShareOpen);
                      }}
                    />
                  </Tooltip>
                </CopyToClipboard>
              ) : (
                <CopyToClipboard text={"no file"}>
                  <Tooltip title="copy to clipboard">
                    {" "}
                    <img
                      src={require("../../../images/copy-clipboard-icon-28.jpg")}
                      style={{ height: 66, marginTop: -24, marginLeft: -15 }}
                      onClick={() => {
                        setIsShareOpen(!isShareOpen);
                      }}
                    />
                  </Tooltip>
                </CopyToClipboard>
              )}
            </div>
          </div>
          <div className="modal-footer  m-t-30"></div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default PersonelInfo;
