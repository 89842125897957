import React, { useState, useEffect } from "react";
import ResumeSelectTemplateComponent from "./ResumeSelectTemplateComponent";
import { getResumeTemplateSelectBoxData } from "../../../../apis/resumebuilder";

import { useMyProfileContext } from "../../../../action/MyProfileAction";

interface IResumeSelectTemplateContainerProps {}

interface IResumeSelectTemplateContainerState {}

const initialState = {};

const ResumeSelectTemplateContainer: React.FC<IResumeSelectTemplateContainerProps> = (
  props
) => {
  const [
    resumeSelectTemplateContainerState,
    setResumeSelectTemplateContainerState,
  ] = React.useState<IResumeSelectTemplateContainerState>(initialState);

  const [
    ResumeTemplateSelectBoxData,
    setResumeTemplateSelectBoxData,
  ] = useState({});
  // const [ResumeTemplates, setResumeTemplates] = useState({});
  const [fieldOfExpertise, setFieldOfExpertise] = useState({});
  const [experiences, setExperiences] = useState({});
  const [level, setLevel] = useState({});
  const myProfileContext = useMyProfileContext();
  const { loggedUserId } = myProfileContext;

  const candidateId = loggedUserId;

  useEffect(() => {
    (async function () {
      getResumeTemplateSelectBoxData()
        .then((res) => {
          setResumeTemplateSelectBoxData(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });

      // getResumeTemplates({
      //   "CandidateId": 0,
      //   "FieldOfExpertiseId": [1],
      //   "ExperienceTypeId": [18],
      //   "DesignationId": [1],
      //   "PageIndex": 1,
      //   "PageSize": 10,
      //   "ShowInactive": true
      // }).then((res) => {
      //   //console.log(res.data);
      //   setResumeTemplates(res.data);
      // }).catch((err) => {
      //   //console.log(err);
      // });
      // getResumeTemplates({
      //   "CandidateId": candidateId,
      //   "FieldOfExpertiseId": [1],
      //   "ExperienceTypeId": [18],
      //   "DesignationId": [1],
      //   "PageIndex": 1,
      //   "PageSize": 10,
      //   "ShowInactive": false
      // }).then((res) => {
      //   setResumeTemplates(res.data);
      // }).catch((err) => {
      //   //console.log(err);
      // });
    })();
  }, [candidateId]);

  return (
    <>
      <ResumeSelectTemplateComponent
        candidateId={candidateId}
        ResumeTemplateSelectBoxData={ResumeTemplateSelectBoxData}
        // ResumeTemplates={ResumeTemplates}
        // setResumeTemplates={setResumeTemplates}
        fieldOfExpertise={fieldOfExpertise}
        setFieldOfExpertise={setFieldOfExpertise}
        experiences={experiences}
        setExperiences={setExperiences}
        level={level}
        setLevel={setLevel}
      />
    </>
  );
};
export default ResumeSelectTemplateContainer;
