import axios from "axios";
import React, { Reducer } from "react";
import {
  ISuggestedJobManagementState,
  SuggestedJobDispatcherContext,
  SuggestedJobStateContext,
} from "../../context/candidate/SuggestedJobContext";
import { AppUrls } from "../../environments/environment";
import {
  suggestedJobRequestModel,
  suggestedJobTitleResultModel,
  SuggestedJobViewModel,
} from "../../models/candidate/SuggestedJob";

// let token = AuthService.accessToken;
// let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type ISuggestedJobAction =
  | {
      type: "SET_Job_SUGGESTED_LIST";
      suggestedJobs: SuggestedJobViewModel;
    }
  | {
      type: "SET_Job_SUGGESTED_JOb_TITLE_LIST";
      suggestedJobTitleResultModel: suggestedJobTitleResultModel;
    };

export const suggestedJobReducer: Reducer<
  ISuggestedJobManagementState,
  ISuggestedJobAction
> = (draft, action): ISuggestedJobManagementState => {
  switch (action.type) {
    case "SET_Job_SUGGESTED_LIST":
      draft.suggestedJobs = action.suggestedJobs;
      return draft;
    case "SET_Job_SUGGESTED_JOb_TITLE_LIST":
      draft.suggestedJobTitleResultModel = action.suggestedJobTitleResultModel;
      return draft;
  }
};

export const useSuggestedJobDispatcher = (): React.Dispatch<ISuggestedJobAction> => {
  const suggestedJobDispatcher = React.useContext(
    SuggestedJobDispatcherContext
  );
  if (!suggestedJobDispatcher) {
    throw new Error(
      "You have to provide the SuggestedJob dispatcher using theSuggestedJobDispatcherContext.Provider in a parent component."
    );
  }
  return suggestedJobDispatcher;
};

export const useSuggestedJobContext = (): ISuggestedJobManagementState => {
  const suggestedJobContext = React.useContext(SuggestedJobStateContext);
  if (!suggestedJobContext)
    throw new Error(
      "You have to provide the suggestedJob context using the SuggestedJobStateContext.Provider in a parent component."
    );
  return suggestedJobContext;
};

export const getSuggestedJobList = async (
  dispatcher: React.Dispatch<ISuggestedJobAction>,
  query: suggestedJobRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetSuggestedJob;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "SET_Job_SUGGESTED_LIST", suggestedJobs: res.data });
    });
  } catch (e) {}
};

export const getSuggestedJobTitleList = async (
  dispatcher: React.Dispatch<ISuggestedJobAction>,
  query: suggestedJobRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetSuggestedJobTitle;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_Job_SUGGESTED_JOb_TITLE_LIST",
        suggestedJobTitleResultModel: res.data,
      });
    });
  } catch (e) {}
};
