import React from "react";
import CandidateMessageComponent from "./CandidateMessageComponent";
import { UserListContextProvider, MessageListContextProvider } from "../../../context/vendor/VendorMyProfile";

interface ICandidateMessageContainerProps { }

interface ICandidateMessageContainerState { }

const initialState = {};

const CandidateMessageContainer: React.FC<ICandidateMessageContainerProps> = (props) => {
  const [CandidateMessageContainerState, setCandidateMessageContainerState] = React.useState<ICandidateMessageContainerState>(
    initialState
  );

  return (
    <UserListContextProvider>
      <MessageListContextProvider>
        <CandidateMessageComponent />
      </MessageListContextProvider>
    </UserListContextProvider>
  );
};
export default CandidateMessageContainer;
