import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PikadayDatepicker from "./components/PikadayDatepicker";
import SelectOption from "./components/SelectOption";
//import SelectMulti from "./components/SelectMulti";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { GlobalSpinnerContext } from "./../../../context/GlobalSpinner";
import moment from "moment";
//import { pascal2Camel, camel2Pascal } from "./../../../utils/utility";
import {
  saveCandidate,
  saveBankDetails,
  getBankDetailsSelectBoxData,
  savePassportInformation,
  saveSeamanBookCdc,
  deleteSeamanBookCdc,
  deletePassportInformation,
  saveDigiDocumentDetails,
  saveDigiDocumentUpload,
  getOnePassportInformation,
  getOneSeamanBookCdc,
  deleteBankDetails,
  getOneDigiDocumentDetails,
} from "./../../../apis/candidate";
import { ErrorMessage } from "@hookform/error-message";
import _ from "lodash";
import {
  //confirmWrapper,
  confirm,
} from "./../../GlobalConfirm";
import AttachDocument from "./../AttachDocument3";
import IsComponentDisabled from "./../../components/IsComponentDisabled";
import {
  Popconfirm,
  //,Tooltip
} from "antd";
import { watch } from "fs";

type objectIndexing = {
  [key: string]: any;
};

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IPassportState {
  number: string;
  startDate: number;
  endDate: number;
  placeIssued: string;
  ecrStatus: string;
  nationality: string;
}

function Passport(props: any) {
  const {
    disabled,
    number,
    startDate,
    endDate,
    placeIssued,
    ecrStatus,
    nationality,
    myProfile,
    updatePassportDetails,
    removePassportDetails,
    item,
    candidateId,
    digiLockerType,
    digiLockers,
    onePassportDocumentInformation,
  } = props;

  let nationalities: ReactSelectOption[] = [];
  // //console.log(item);
  let ecrstatuslist: ReactSelectOption[] = [];
  if (!_.isEmpty(myProfile)) {
    nationalities = myProfile.countries.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
    ecrstatuslist.push({ value: "ECR", label: "ECR" });
    ecrstatuslist.push({ value: "ECNR", label: "ECNR" });
  }

  // const nationalities = [{
  //   label: "India",
  //   value: "india"
  // }];

  const defaultValues = {
    number,
    startDate,
    endDate,
    placeIssued,
    ecrStatus,
    nationality,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    // setValue,
    getValues,
    control,
    trigger,
  } = useForm<IPassportState>({
    defaultValues,
  });

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const [form, setState] = useState({
    docName: "",
    docType: "",
    docNumber: "",
    docExpiry: 0,
    docRadio: "",
    docFile: {},
    fromLocker: false,
    digiDocumentDetailId: 0,
  });

  const onSave = (d: any) => {
    //console.log(d);
    // console.log(getValues("number"));
    // console.log(getValues("startDate"));
    // console.log(getValues("endDate"));
    // console.log(getValues("ecrStatus"));
    // console.log(getValues("placeIssued"));
    // console.log(getValues("nationality"));
    //start.................................
    setIsOpen2(false);

    const {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    } = d;

    const _d = Object.assign({}, form, {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    });

    ////console.log(_d);
    ////console.log(item);
    ///setState(_d);
    if (item != undefined && item["rowId"] > 0) {
      const _data = Object.assign({}, item);
      if (_d.fromLocker) {
        _data["digiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data };

      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updatePassportDetails("_passport", d);
    } else {
      //setState(_d);
      //save new passport information along with attachment
      const _data = Object.assign({}, {
        passportNo: getValues("number"),
        startDate: new Date(getValues("startDate")).toISOString(),
        endDate: new Date(getValues("endDate")).toISOString(),
        placeIssued: getValues("placeIssued"),
        ECRStatus: getValues("ecrStatus"),
        countryId: Number(getValues("nationality")),
        isActive: true,
      });
      const d: objectIndexing = { _data };
      if (_d.fromLocker) {
        d["digiDocumentDetailId"] = _d.digiDocumentDetailId;
      }

      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updatePassportDetails("_passport", d);
      //console.log(d);
      //save new passport information along with attachment end
    }
    //end.................................

  };

  ////console.log(form);

  const onSubmit = (data: any) => {
    ////console.log(data);
    // //console.log(ecrstatuslist[data["ecrStatus"]].label);

    const _data = Object.assign({}, item, {
      passportNo: data["number"],
      startDate: new Date(data["startDate"]).toISOString(),
      endDate: new Date(data["endDate"]).toISOString(),
      placeIssued: data["placeIssued"],
      ECRStatus: data["ecrStatus"],
      countryId: Number(data["nationality"]),
      isActive: true,
    });

    if (form.fromLocker) {
      _data["digiDocumentDetailId"] = form.digiDocumentDetailId;
    }
    const d: objectIndexing = { _data };

    if (form.docName) {
      d["_attachDoc"] = form;
    }

    updatePassportDetails("_passport", d);
  };

  return (
    <div className="content_checks_bg2">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="content_sec1_head">Passport</div>
        <div className="close_icon _cursor-pointer">
          <img
            onClick={() => {
              removePassportDetails("_passport", {
                //passportInformationId: 0
                passportInformationId: null,
              });
            }}
            src={require("./../../../images/close_icon.png")}
            width={16}
            height={16}
          />
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Number
              </label>
              {IsComponentDisabled(
                <input
                  type="text"
                  name="number"
                  ref={register({
                    required: "Number is required",
                    // maxLength: {
                    //   value: 15,
                    //   message: "Shold be a Number"
                    // },
                    // pattern: {
                    //   value: /^[0-9]*$/,
                    //   message: "Number should be numbers only"
                    // }
                  })}
                  disabled={disabled}
                  className={"form-control form-control-n"}
                  placeholder="Type here"
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="number"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label className={"control-label required"}>Date Issued</label>
              {IsComponentDisabled(
                <Controller
                  control={control}
                  name="startDate"
                  rules={{
                    validate: (value) => {
                      if (value <= 0) {
                        return "Required to date.";
                      } else if (value > Date.now()) {
                        return "Start Date cannot be a future date.";
                      } else if (value >= Number(watch("endDate"))) {
                        return "Must be less than End date.";
                      } else {
                        return undefined;
                      }
                      // return value == 0 ? "Start Date is required" : undefined;
                    },
                  }}
                  render={({ onChange, onBlur, value, name }) => (
                    <PikadayDatepicker
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  )}
                />,
                disabled
              )}

              <ErrorMessage
                errors={errors}
                name="startDate"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label className={"control-label required"}>Valid Upto</label>
              {IsComponentDisabled(
                <Controller
                  control={control}
                  name="endDate"
                  rules={{
                    validate: (value) => {
                      if (value <= 0) {
                        return "Required to date.";
                      } else if (value <= Number(watch("startDate"))) {
                        return "Must be greater than Start date.";
                      } else {
                        return undefined;
                      }
                      // return value == 0 ? "End Date is required" : undefined;
                    },
                  }}
                  render={({ onChange, onBlur, value, name }) => (
                    <PikadayDatepicker
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  )}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="endDate"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Place Issued{" "}
              </label>
              {IsComponentDisabled(
                <input
                  type="text"
                  name="placeIssued"
                  className={"form-control"}
                  placeholder="Type here"
                  ref={register({
                    required: "Place issued is required",
                  })}
                  disabled={disabled}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="placeIssued"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                ECR Status{" "}
              </label>
              {IsComponentDisabled(
                <span className="select-wrapper-sec">
                  <Controller
                    rules={{
                      required: "ECR Status is required",
                      validate: function (value) {
                        // //console.log(value);
                        return value.includes("sel")
                          ? "ECR Status is required"
                          : undefined;
                      },
                    }}
                    control={control}
                    name="ecrStatus"
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={ecrstatuslist}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>,
                disabled
              )}
              {/* {IsComponentDisabled(
                <input
                  type="text"
                  name="ecrStatus"
                  className={"form-control"}
                  placeholder="Type here"
                  ref={register({
                    required: "ECR Status is required",
                  })}
                  disabled={disabled}
                />,
                disabled
              )} */}
              <ErrorMessage
                errors={errors}
                name="ecrStatus"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Country
              </label>
              {IsComponentDisabled(
                <span className="select-wrapper-sec">
                  <Controller
                    rules={{
                      required: "Countryis required",
                      validate: function (value) {
                        ////console.log(value);
                        return Number(value)
                          ? undefined
                          : "Country is required";
                      },
                    }}
                    control={control}
                    name="nationality"
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={nationalities}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="nationality"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            {/* <a onClick={() => { setIsOpen2(!isOpen2) }} className="btn submit_btn">Attach Document</a> */}
            {/* <button onClick={() => { setIsOpen2(!isOpen2) }} type="button" className="btn submit_btn" disabled={disabled}>Attach Document</button> */}

            {item != undefined &&
              item["digiDocumentDetailId"] > 0 &&
              onePassportDocumentInformation.data != undefined && (
                //       <h2>
                //         You have  {`${onePassportDocumentInformation.data.name} `}unread messages.
                // </h2>

                <Popconfirm
                  title={
                    <div>
                      <p>
                        You already have a document attached to this record.
                      </p>
                      <p>
                        {" "}
                        Name: {`${onePassportDocumentInformation.data.name} `}
                      </p>
                      {/* <br /> */}
                      <p>
                        {" "}
                        Last Modified:{" "}
                        {`${onePassportDocumentInformation.data.updatedDate}`}
                      </p>
                      <p> Do you want to update the above document?</p>
                    </div>
                  }
                  //onConfirm={() => setIsOpen2(!isOpen2)}
                  onConfirm={() => {
                    const result = trigger(["number", "startDate", "endDate", "ecrStatus", "placeIssued", "nationality"]);
                    result.then((checkStatus: any) => {
                      if (checkStatus == true) {
                        setIsOpen2(!isOpen2);
                      }
                    });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <button
                    type="button"
                    className="btn submit_btn"
                    disabled={disabled}
                  >
                    Attach Document
                  </button>
                  <a className="btn submit_btn" style={{ display: "none" }}>
                    Attach Document
                  </a>
                </Popconfirm>
              )}
            {(item == undefined ||
              item["digiDocumentDetailId"] == 0 ||
              item["digiDocumentDetailId"] == null) && (
                <button
                  onClick={() => {
                    const result = trigger(["number", "startDate", "endDate", "ecrStatus", "placeIssued", "nationality"]);
                    result.then((checkStatus: any) => {
                      if (checkStatus == true) {
                        setIsOpen2(!isOpen2);
                      }
                    });

                  }}
                  type="button"
                  className="btn submit_btn"
                  disabled={disabled}
                >
                  Attach Document
                </button>
              )}
            <button
              type="submit"
              className="btn submit_btn"
              disabled={disabled}
            >
              Save
            </button>
          </div>
          <div className="col-sm-4">
            {((item != undefined &&
              item["digiDocumentDetailId"] > 0 &&
              onePassportDocumentInformation.data != undefined) ||
              (form.docName != undefined && form.docName.length > 0)) && (
                <label className="m_t_20">Document attached </label>
              )}
          </div>
        </div>
      </form>

      <Modal
        show={isOpen2}
        onHide={() => {
          setIsOpen2(!isOpen2);
        }}
        dialogClassName="_attach-training"
      >
        {/* <AttachDocument setIsOpen2={setIsOpen2} isOpen2={isOpen2} /> */}
        <AttachDocument
          digiLockerType={digiLockerType}
          setIsOpen2={setIsOpen2}
          isOpen2={isOpen2}
          candidateId={candidateId}
          onSave={onSave}
          digiLockers={digiLockers}
          docNameProp={"Passport"}
          docTypeProp={2}
          docNumberProp={watch("number")}
          docExpiryProp={watch("endDate")}
          docRadioProp={"yes"}
        />
      </Modal>
    </div>
  );
}

interface IBankDetailsState {
  rowId: string;
  bankName: string;
  branch: string;
  accountNumber: string;
  ifcsIbanSwift: string;
  accountType: string;
  country: string;
}

function BankDetails(props: any) {
  const {
    disabled,
    bankDetailsState,
    //myProfile,
    candidateId,
    bankDetailsSelectBoxData,
    //updateBankDetails,
    removeBankDetails,
    isAdd,
    updateDetails,
    digiLockerType,
    digiLockers,
    oneBankDocument,
  } = props;
  //console.log(bankDetailsState);
  const defaultValues = {
    rowId: "0",
    bankName: "",
    branch: "",
    accountNumber: "",
    ifcsIbanSwift: "",
    accountType: "",
    country: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    // setValue,
    getValues,
    control,
    reset,
    trigger
  } = useForm<IBankDetailsState>({
    defaultValues,
  });

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const [form, setState] = useState({
    docName: "",
    docType: "",
    docNumber: "",
    docExpiry: 0,
    docRadio: "",
    docFile: {},
    fromLocker: false,
    digiDocumentDetailId: 0,
  });

  const onSave = (d: any) => {
    //console.log(d);

    setIsOpen2(false);

    const {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    } = d;

    const _d = Object.assign({}, form, {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    });

    ////console.log(_d);

    //setState(_d);
    if (bankDetailsState.data.length > 0) {
      const _data = Object.assign({}, bankDetailsState.data[0]);
      if (_d.fromLocker) {
        _data["digiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data };

      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updateDetails("_bank", _data, d);
    } else {
      // setState(_d);
      //save new bank details along with attachment
      const _data: objectIndexing = {
        rowId: getValues("rowId"),
        bankDetailsId: 0,
        candidateId: Number(candidateId),
        bankName: getValues("bankName"),
        branchName: getValues("branch"),
        accountNo: getValues("accountNumber"),
        ifscibanswiftNo: getValues("ifcsIbanSwift"),
        bankAccountTypeId: Number(getValues("accountType")),
        countryId: Number(getValues("country")),
        stateId: 1,
        isActive: true,
      };

      if (_d.fromLocker) {
        _data["digiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data };
      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updateDetails("_bank", _data, d);
      // console.log(_data);
      // console.log(d);
      //save new bank details along with attachment end
    }
  };

  const onSubmit = (data: any) => {
    ////console.log(data);
    ////console.log(bankDetailsState.data.length);

    //if (bankDetailsState.data.length > 0 && !disabled) {

    //const _data1 = Object.assign(bankDetailsState.data[0], {
    ////console.log(bankDetailsState.data[0])
    const _data1 = Object.assign({}, bankDetailsState.data[0], {
      rowId: data["rowId"],
      bankDetailsId: 0,
      candidateId: Number(candidateId),
      bankName: data["bankName"],
      branchName: data["branch"],
      accountNo: data["accountNumber"],
      ifscibanswiftNo: data["ifcsIbanSwift"],
      bankAccountTypeId: Number(data["accountType"]),
      countryId: Number(data["country"]),
      stateId: 1,
      isActive: true,
    });

    if (form.fromLocker) {
      _data1["digiDocumentDetailId"] = form.digiDocumentDetailId;
    }
    const d: objectIndexing = { _data1 };

    if (form.docName) {
      d["_attachDoc"] = form;
    }
    updateDetails("_bank", _data1, d);
    // console.log(_data1);
    // console.log(d);
    //updateBankDetails("_bank", _data1);
    //}
  };

  let accountTypes: ReactSelectOption[] = [];

  let countries: ReactSelectOption[] = [];

  if (!_.isEmpty(bankDetailsSelectBoxData)) {
    //console.log(bankDetailsSelectBoxData);

    countries = bankDetailsSelectBoxData.countries.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });

    accountTypes = bankDetailsSelectBoxData.bankAccountTypes.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  useEffect(() => {
    if (bankDetailsState.data.length > 0) {
      //console.log(bankDetailsState.data);
      reset({
        rowId:
          bankDetailsState.data.length > 0
            ? bankDetailsState.data[0]["rowId"]
            : 0,
        bankName:
          bankDetailsState.data.length > 0
            ? bankDetailsState.data[0]["bankName"]
            : "",
        branch:
          bankDetailsState.data.length > 0
            ? bankDetailsState.data[0]["branchName"]
            : "",
        accountNumber:
          bankDetailsState.data.length > 0
            ? bankDetailsState.data[0]["accountNo"]
            : "",
        ifcsIbanSwift:
          bankDetailsState.data.length > 0
            ? bankDetailsState.data[0]["ifscibanswiftNo"]
            : "",
        accountType: isAdd
          ? ""
          : bankDetailsState.data[0]["bankAccountTypeId"] + "",
        country: isAdd ? "" : bankDetailsState.data[0]["countryId"] + "",
      });
    }
  }, [reset, bankDetailsState.data.length]);

  return (
    <div id="bank_details" className="">
      <div className=" content_checks_bg2 m_t_10">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="content_sec1_head">Bank Details</div>
          <div className="close_icon _cursor-pointer">
            <img
              onClick={() => {
                if (bankDetailsState.data.length > 0) {
                  const _data1 = Object.assign(bankDetailsState.data[0], {
                    bankName: "",
                    branchName: "",
                    accountNo: "",
                    ifscibanswiftNo: "",
                    // bankAccountTypeId: 0,
                    // countryId: 0
                  });

                  ////console.log(_data1);

                  removeBankDetails("_bank", _data1);
                }
              }}
              src={require("./../../../images/close_icon.png")}
              width={16}
              height={16}
            />
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <input
                  type="number"
                  name="rowId"
                  style={{ display: "none" }}
                  disabled={disabled}
                  ref={register}
                />
                <label htmlFor="email" className={"required"}>
                  Bank Name
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="bankName"
                    className={"form-control"}
                    placeholder="Type here"
                    ref={register({
                      required: "Bank Name is required",
                    })}
                    disabled={disabled}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="bankName"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Branch
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="branch"
                    className={"form-control"}
                    placeholder="Type here"
                    ref={register({
                      required: "Branch is required",
                    })}
                    disabled={disabled}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="branch"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Account Number
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="accountNumber"
                    className={"form-control"}
                    placeholder="Type here"
                    ref={register({
                      required: "Account Number is required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Account Number should be numbers only",
                      },
                    })}
                    disabled={disabled}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="accountNumber"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  IFCS/IBAN/SWIFT Number
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="ifcsIbanSwift"
                    className={"form-control"}
                    placeholder="Type here"
                    ref={register({
                      required: "IFCS/IBAN/SWIFT Number is required",
                    })}
                    disabled={disabled}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="ifcsIbanSwift"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Account Type
                </label>
                {/* <input type="text" name="accountType" className="form-control" placeholder="Type here" ref={register({
                  required: "Account Type is required"
                })} disabled={disabled} /> */}
                {IsComponentDisabled(
                  <span className="select-wrapper-sec">
                    <Controller
                      rules={{
                        required: "Account Type is required",
                        validate: function (value) {
                          //console.log(value);
                          return Number(value)
                            ? undefined
                            : "Account Type is required";
                        },
                      }}
                      control={control}
                      name="accountType"
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          values={accountTypes}
                          disabled={disabled}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="accountType"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Country
                </label>
                {/* <input type="text" name="country" className="form-control" placeholder="Type here" ref={register({
                  required: "Country is required"
                })} disabled={disabled} /> */}
                {IsComponentDisabled(
                  <span className="select-wrapper-sec">
                    <Controller
                      rules={{
                        required: "Country is required",
                        validate: function (value) {
                          ////console.log(value);
                          return Number(value)
                            ? undefined
                            : "Country is required";
                        },
                      }}
                      control={control}
                      name="country"
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          values={countries}
                          disabled={disabled}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="country"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-sm-4">
              {/* <button onClick={() => { setIsOpen2(!isOpen2) }} type="button" className="btn submit_btn" disabled={disabled}>Attach Document</button> */}
              {bankDetailsState.data.length > 0 &&
                bankDetailsState.data[0].digiDocumentDetailId > 0 &&
                oneBankDocument.data != undefined && (
                  //       <h2>.
                  //         You have  {`${onePassportDocumentInformation.data.name} `}unread messages.
                  // </h2>

                  <Popconfirm
                    title={
                      <div>
                        <p>
                          You already have a document attached to this record.
                        </p>
                        <p> Name: {`${oneBankDocument.data.name} `}</p>
                        {/* <br /> */}
                        <p>
                          {" "}
                          Last Modified: {`${oneBankDocument.data.updatedDate}`}
                        </p>
                        <p> Do you want to update the above document?</p>
                      </div>
                    }
                    // onConfirm={() => setIsOpen2(!isOpen2)}
                    onConfirm={() => {
                      const result = trigger(["bankName", "branch", "accountNumber", "ifcsIbanSwift", "accountType", "country"]);
                      result.then((checkStatus: any) => {
                        if (checkStatus == true) {
                          setIsOpen2(!isOpen2);
                        }
                      });
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      type="button"
                      className="btn submit_btn"
                      disabled={disabled}
                    >
                      Attach Document
                    </button>
                    <a className="btn submit_btn" style={{ display: "none" }}>
                      Attach Document
                    </a>
                  </Popconfirm>
                )}
              {(bankDetailsState.data.length < 1 ||
                bankDetailsState.data[0].digiDocumentDetailId == 0 ||
                bankDetailsState.data[0].digiDocumentDetailId == null) && (
                  <button
                    onClick={() => {
                      const result = trigger(["bankName", "branch", "accountNumber", "ifcsIbanSwift", "accountType", "country"]);
                      result.then((checkStatus: any) => {
                        if (checkStatus == true) {
                          setIsOpen2(!isOpen2);
                        }
                      });
                    }}
                    type="button"
                    className="btn submit_btn"
                    disabled={disabled}
                  >
                    Attach Document
                  </button>
                )}
              <button
                type="submit"
                className="btn submit_btn"
                disabled={disabled}
              >
                Save
              </button>
            </div>
            <div className="col-sm-4">
              {((bankDetailsState.data.length > 0 &&
                bankDetailsState.data[0].digiDocumentDetailId > 0 &&
                oneBankDocument.data != undefined) ||
                (form.docName != undefined && form.docName.length > 0)) && (
                  <label className="m_t_20">Document attached </label>
                )}
            </div>
          </div>
        </form>
        <Modal
          show={isOpen2}
          onHide={() => {
            setIsOpen2(!isOpen2);
          }}
          dialogClassName="_attach-training"
        >
          {/* <AttachDocument setIsOpen2={setIsOpen2} isOpen2={isOpen2} /> */}
          <AttachDocument
            digiLockerType={digiLockerType}
            setIsOpen2={setIsOpen2}
            isOpen2={isOpen2}
            candidateId={candidateId}
            onSave={onSave}
            digiLockers={digiLockers}
            docNameProp={watch("bankName")}
            docTypeProp={"2"}
            docNumberProp={watch("accountNumber")}
            docExpiryProp={0}
            docRadioProp={"no"}
          />
        </Modal>
      </div>
    </div>
  );
}

interface IAdharState {
  aadharNumber: string;
}

function Adhar(props: any) {
  const {
    disabled,
    aadharNo,
    updateDetails,
    removeDetails,
    candidateId,
    digiLockerType,
    digiLockers,
    oneAadharDocument,
  } = props;

  const defaultValues = {
    aadharNumber: aadharNo,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    // setValue,
    getValues,
    //control,
    trigger
  } = useForm<IAdharState>({
    defaultValues,
  });

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const [form, setState] = useState({
    docName: "",
    docType: "",
    docNumber: "",
    docExpiry: 0,
    docRadio: "",
    docFile: {},
    fromLocker: false,
    digiDocumentDetailId: 0,
  });

  const onSave = (d: any) => {
    //console.log(d);

    setIsOpen2(false);

    const {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    } = d;

    const _d = Object.assign({}, form, {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    });

    //console.log(_d);

    //setState(_d);
    if (aadharNo != null && aadharNo != undefined && !_.isEmpty(aadharNo)) {
      const _data: objectIndexing = {};
      //const d1: objectIndexing = {};
      _data["aadharNo"] = aadharNo;
      if (_d.fromLocker) {
        _data["aadharDigiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data };

      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updateDetails("_aadhar", _data, d);
    } else {
      //setState(_d);
      //save new aadhar information along with attachment 
      const _data: objectIndexing = {};
      _data["aadharNo"] = getValues("aadharNumber");

      if (_d.fromLocker) {
        _data["aadharDigiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data };
      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updateDetails("_aadhar", _data, d);
      //save new aadhar information along with attachment end
    }
  };

  const onSubmit = (data: any) => {
    //console.log(data);
    const _data: objectIndexing = {};
    const d: objectIndexing = {};
    _data["aadharNo"] = data["aadharNumber"];
    if (form.fromLocker) {
      _data["aadharDigiDocumentDetailId"] = form.digiDocumentDetailId;
    } else if (form.docName) {
      d["_attachDoc"] = form;
    }
    //console.log(_data, d);
    updateDetails("_aadhar", _data, d);
  };

  return (
    <div id="adhar" className="">
      <div className=" content_checks_bg2 m_t_10">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="content_sec1_head">Aadhar</div>
          <div className="close_icon _cursor-pointer">
            <img
              onClick={() => {
                removeDetails("_aadhar", {
                  aadharNo: null,
                  aadharDigiDocumentDetailId: null,
                });
              }}
              src={require("./../../../images/close_icon.png")}
              width={16}
              height={16}
            />
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Aadhar Number
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="aadharNumber"
                    className={"form-control"}
                    placeholder="Type here"
                    ref={register({
                      required: "Aadhar Number is required",
                    })}
                    disabled={disabled}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="aadharNumber"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-sm-4">
              {/* <button onClick={() => { setIsOpen2(!isOpen2) }} type="button" className="btn submit_btn" disabled={disabled}>Attach Document</button> */}
              {_.isEmpty(oneAadharDocument) != true &&
                _.isEmpty(aadharNo) != true && (
                  <Popconfirm
                    title={
                      <div>
                        <p>
                          You already have a document attached to this record.
                        </p>
                        <p>
                          {" "}
                          Name:{" "}
                          {`${oneAadharDocument.data != undefined
                            ? oneAadharDocument.data.name
                            : ""
                            } `}
                        </p>
                        {/* <br /> */}
                        <p>
                          {" "}
                          Last Modified:{" "}
                          {`${oneAadharDocument.data != undefined
                            ? oneAadharDocument.data.updatedDate
                            : ""
                            }`}
                        </p>
                        <p> Do you want to update the above document?</p>
                      </div>
                    }
                    // onConfirm={() => setIsOpen2(!isOpen2)}
                    onConfirm={() => {
                      const result = trigger(["aadharNumber"]);
                      result.then((checkStatus: any) => {
                        if (checkStatus == true) {
                          setIsOpen2(!isOpen2);
                        }
                      });
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      type="button"
                      className="btn submit_btn"
                      disabled={disabled}
                    >
                      Attach Document
                    </button>
                    <a className="btn submit_btn" style={{ display: "none" }}>
                      Attach Document
                    </a>
                  </Popconfirm>
                )}
              {(_.isEmpty(oneAadharDocument) == true ||
                _.isEmpty(aadharNo) == true) && (
                  <button
                    onClick={() => {
                      const result = trigger(["aadharNumber"]);
                      result.then((checkStatus: any) => {
                        if (checkStatus == true) {
                          setIsOpen2(!isOpen2);
                        }
                      });
                    }}
                    type="button"
                    className="btn submit_btn"
                    disabled={disabled}
                  >
                    Attach Document
                  </button>
                )}
              <button
                type="submit"
                className="btn submit_btn"
                disabled={disabled}
              >
                Save
              </button>
            </div>
            <div className="col-sm-4">
              {((_.isEmpty(oneAadharDocument) != true &&
                _.isEmpty(aadharNo) != true) ||
                (form.docName != undefined && form.docName.length > 0)) && (
                  <label className="m_t_20">Document attached </label>
                )}
            </div>
          </div>
        </form>
        <Modal
          show={isOpen2}
          onHide={() => {
            setIsOpen2(!isOpen2);
          }}
          dialogClassName="_attach-training"
        >
          {/* <AttachDocument setIsOpen2={setIsOpen2} isOpen2={isOpen2} /> */}
          <AttachDocument
            digiLockerType={digiLockerType}
            setIsOpen2={setIsOpen2}
            isOpen2={isOpen2}
            candidateId={candidateId}
            onSave={onSave}
            digiLockers={digiLockers}
            docNameProp={"Aadhar"}
            docTypeProp={2}
            docNumberProp={watch("aadharNumber")}
            docExpiryProp={0}
            docRadioProp={"no"}
          />
        </Modal>
      </div>
    </div>
  );
}

interface IPanState {
  panNumber: string;
}

function Pan(props: any) {
  const {
    disabled,
    panNo,
    updateDetails,
    removeDetails,
    candidateId,
    digiLockerType,
    digiLockers,
    onePanDocument,
  } = props;
  ////console.log(_.isEmpty(panNo));
  ////console.log(_.isEmpty(onePanDocument));
  const defaultValues = {
    panNumber: panNo,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    // setValue,
    getValues,
    // control,
    trigger
  } = useForm<IPanState>({
    defaultValues,
  });

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const [form, setState] = useState({
    docName: "",
    docType: "",
    docNumber: "",
    docExpiry: 0,
    docRadio: "",
    docFile: {},
    fromLocker: false,
    digiDocumentDetailId: 0,
  });

  const onSave = (d: any) => {
    ////console.log(d);

    setIsOpen2(false);

    const {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    } = d;

    const _d = Object.assign({}, form, {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    });

    // //console.log(_d);
    // //console.log(!_.isEmpty(panNo));
    // //console.log(panNo != undefined);
    // //console.log(panNo == "");

    ////console.log(panNo);    //setState(_d);
    if (panNo != null && panNo != undefined && !_.isEmpty(panNo)) {
      const _data: objectIndexing = {};
      const d1: objectIndexing = {};
      _data["panNo"] = panNo;
      if (_d.fromLocker) {
        _data["panDigiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data };

      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updateDetails("_pan", _data, d);
    } else {
      //setState(_d);
      //save new pan information along with attachment 
      const _data: objectIndexing = {};
      _data["panNo"] = getValues("panNumber");

      if (_d.fromLocker) {
        _data["panDigiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data };
      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updateDetails("_pan", _data, d);
      //save new pan information along with attachment end
    }
  };

  const onSubmit = (data: any) => {
    // //console.log(data);
    const _data: objectIndexing = {};
    const d: objectIndexing = {};
    _data["panNo"] = data["panNumber"];
    if (form.fromLocker) {
      _data["panDigiDocumentDetailId"] = form.digiDocumentDetailId;
    } else if (form.docName) {
      d["_attachDoc"] = form;
    }

    // //console.log(_data, d);
    updateDetails("_pan", _data, d);
  };

  return (
    <div id="pan" className="">
      <div className=" content_checks_bg2 m_t_10">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="content_sec1_head">PAN</div>
          <div className="close_icon _cursor-pointer">
            <img
              onClick={() => {
                removeDetails("_pan", {
                  panNo: null,
                  panDigiDocumentDetailId: null,
                });
              }}
              src={require("./../../../images/close_icon.png")}
              width={16}
              height={16}
            />
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  PAN Number{" "}
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="panNumber"
                    className={"form-control"}
                    placeholder="Type here"
                    ref={register({
                      required: "PAN Number is required",
                    })}
                    disabled={disabled}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="panNumber"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-sm-4">
              {/* <button onClick={() => { setIsOpen2(!isOpen2) }} type="button" className="btn submit_btn" disabled={disabled}>Attach Document</button> */}
              {_.isEmpty(onePanDocument) != true && _.isEmpty(panNo) != true && (
                <Popconfirm
                  title={
                    <div>
                      <p>
                        You already have a document attached to this record.
                      </p>
                      <p> Name: {`${onePanDocument.data.name} `}</p>
                      {/* <br /> */}
                      <p>
                        {" "}
                        Last Modified: {`${onePanDocument.data.updatedDate}`}
                      </p>
                      <p> Do you want to update the above document?</p>
                    </div>
                  }
                  //onConfirm={() => setIsOpen2(!isOpen2)}
                  onConfirm={() => {
                    const result = trigger(["panNumber"]);
                    result.then((checkStatus: any) => {
                      if (checkStatus == true) {
                        setIsOpen2(!isOpen2);
                      }
                    });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <button
                    type="button"
                    className="btn submit_btn"
                    disabled={disabled}
                  >
                    Attach Document
                  </button>
                  <a className="btn submit_btn" style={{ display: "none" }}>
                    Attach Document
                  </a>
                </Popconfirm>
              )}
              {(_.isEmpty(onePanDocument) == true ||
                _.isEmpty(panNo) == true) && (
                  <button
                    onClick={() => {
                      const result = trigger(["panNumber"]);
                      result.then((checkStatus: any) => {
                        if (checkStatus == true) {
                          setIsOpen2(!isOpen2);
                        }
                      });
                    }}
                    type="button"
                    className="btn submit_btn"
                    disabled={disabled}
                  >
                    Attach Document
                  </button>
                )}

              <button
                type="submit"
                className="btn submit_btn"
                disabled={disabled}
              >
                Save
              </button>
            </div>
            <div className="col-sm-4">
              {((_.isEmpty(onePanDocument) != true &&
                _.isEmpty(panNo) != true) ||
                (form.docName != undefined && form.docName.length > 0)) && (
                  <label className="m_t_20">Document attached </label>
                )}
            </div>
          </div>
        </form>
        <Modal
          show={isOpen2}
          onHide={() => {
            setIsOpen2(!isOpen2);
          }}
          dialogClassName="_attach-training"
        >
          {/* <AttachDocument setIsOpen2={setIsOpen2} isOpen2={isOpen2} /> */}
          <AttachDocument
            digiLockerType={digiLockerType}
            setIsOpen2={setIsOpen2}
            isOpen2={isOpen2}
            candidateId={candidateId}
            onSave={onSave}
            digiLockers={digiLockers}
            docNameProp={"PAN"}
            docTypeProp={2}
            docNumberProp={watch("panNumber")}
            docExpiryProp={0}
            docRadioProp={"no"}
          />
        </Modal>
      </div>
    </div>
  );
}

interface ISeamanBookState {
  number: string;
  placeIssued: string;
  dateIssued: number;
  expiryDate: number;
}

function SeamanBook(props: any) {
  const {
    disabled,
    seamanBookCdcId,
    // updateSeaman,
    removeSeaman,
    placeIssued,
    dateIssued,
    expiryDate,
    item,
    updateDetails,
    candidateId,
    digiLockerType,
    digiLockers,
    oneSeamanBookCdcDocument,
  } = props;
  ////console.log(typeof (dateIssued));
  //console.log(seamanBookCdcId);
  const defaultValues = {
    number: seamanBookCdcId,
    placeIssued: placeIssued,
    // dateIssued: new Date(dateIssued).getTime(),
    // expiryDate: new Date(expiryDate).getTime()
    dateIssued:
      typeof dateIssued == "string"
        ? moment(dateIssued, "DD/MM/YYYY").toDate().getTime()
        : new Date(dateIssued).getTime(),
    expiryDate:
      typeof dateIssued == "string"
        ? moment(expiryDate, "DD/MM/YYYY").toDate().getTime()
        : new Date(dateIssued).getTime(),
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    // setValue,
    getValues,
    control,
    trigger
  } = useForm<ISeamanBookState>({
    defaultValues,
  });

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const [form, setState] = useState({
    docName: "",
    docType: "",
    docNumber: "",
    docExpiry: 0,
    docRadio: "",
    docFile: {},
    fromLocker: false,
    digiDocumentDetailId: 0,
  });

  const onSave = (d: any) => {
    //console.log(d);

    setIsOpen2(false);

    const {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    } = d;

    const _d = Object.assign({}, form, {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    });

    // //console.log(_d);

    // setState(_d);
    if (item != undefined && item["rowId"] > 0) {
      const _data = Object.assign({}, item);
      if (_d.fromLocker) {
        _data["digiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data };

      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updateDetails("_seamanBook", _data, d);
    } else {
      // setState(_d);
      //save new seaman book information along with attachment
      const _data: objectIndexing = {
        placeIssued: getValues("placeIssued"),
        dateIssued: new Date(getValues("dateIssued")).toISOString(),
        expiryDate: new Date(getValues("expiryDate")).toISOString(),
        cdcNumber: getValues("number"),
        isActive: true,
      };
      if (_d.fromLocker) {
        _data["digiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data }
      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updateDetails("_seamanBook", _data, d);
      //save new seaman book information along with attachment end
    }
  };

  const onSubmit = (data: any) => {
    // //console.log(data);
    // //console.log(item);
    const d: objectIndexing = {};
    const _data = Object.assign({}, item, {
      //rowId: 0,
      //seamanBookCdcId: data["number"],
      placeIssued: data["placeIssued"],
      dateIssued: new Date(data["dateIssued"]).toISOString(),
      expiryDate: new Date(data["expiryDate"]).toISOString(),
      // dateIssued: moment(data["dateIssued"], "DD/MM/YYYY").toDate().getTime(),
      // expiryDate: moment(data["expiryDate"], "DD/MM/YYYY").toDate().getTime(),

      cdcNumber: data["number"],
      isActive: true,
    });

    ////console.log(_data);
    if (form.fromLocker) {
      _data["digiDocumentDetailId"] = form.digiDocumentDetailId;
    }
    //const d: objectIndexing = { _data };

    if (form.docName) {
      d["_attachDoc"] = form;
    }
    updateDetails("_seamanBook", _data, d);
  };

  return (
    <div id="seaman_book" className="">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className=" content_checks_bg2 m_t_10">
          <div className="content_sec1_head">SEAMAN BOOK CDC</div>
          <div className="close_icon _cursor-pointer">
            <img
              onClick={() => {
                removeSeaman("_seamanBook", {
                  rowId: "",
                });
              }}
              src={require("./../../../images/close_icon.png")}
              width={16}
              height={16}
            />
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Number :
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="number"
                    ref={register({
                      required: "Number is required",
                      // maxLength: {
                      //   value: 15,
                      //   message: "Shold be a Number"
                      // },
                      // pattern: {
                      //   value: /^[0-9]*$/,
                      //   message: "Number should be numbers only"
                      // }
                    })}
                    className={"form-control form-control-n"}
                    placeholder="Type here"
                    disabled={disabled}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="number"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Place Issued
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="placeIssued"
                    className={"form-control"}
                    placeholder="Type here"
                    disabled={disabled}
                    ref={register({
                      required: "Place Issued is required",
                    })}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="placeIssued"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="control-label required">Date Issued</label>
                {IsComponentDisabled(
                  <Controller
                    control={control}
                    name="dateIssued"
                    rules={{
                      validate: (value) => {
                        return value == 0
                          ? "Date Issued is required"
                          : undefined;
                      },
                    }}
                    render={({ onChange, onBlur, value, name }) => (
                      <PikadayDatepicker
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="dateIssued"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="control-label required">Expiry Date</label>
                {IsComponentDisabled(
                  <Controller
                    control={control}
                    name="expiryDate"
                    rules={{
                      validate: (value) => {
                        return value == 0
                          ? "Expiry Date is required"
                          : undefined;
                      },
                    }}
                    render={({ onChange, onBlur, value, name }) => (
                      <PikadayDatepicker
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="expiryDate"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-sm-4">
              {/* <button onClick={() => { setIsOpen2(!isOpen2) }} type="button" className="btn submit_btn" disabled={disabled}>Attach Document</button> */}
              {item != undefined &&
                item["digiDocumentDetailId"] > 0 &&
                oneSeamanBookCdcDocument.data != undefined && (
                  //       <h2>
                  //         You have  {`${onePassportDocumentInformation.data.name} `}unread messages.
                  // </h2>

                  <Popconfirm
                    title={
                      <div>
                        <p>
                          You already have a document attached to this record.
                        </p>
                        <p> Name: {`${oneSeamanBookCdcDocument.data.name} `}</p>
                        {/* <br /> */}
                        <p>
                          {" "}
                          Last Modified:{" "}
                          {`${oneSeamanBookCdcDocument.data.updatedDate}`}
                        </p>
                        <p> Do you want to update the above document?</p>
                      </div>
                    }
                    //onConfirm={() => setIsOpen2(!isOpen2)}
                    onConfirm={() => {
                      const result = trigger(["number", "placeIssued", "dateIssued", "expiryDate"]);
                      result.then((checkStatus: any) => {
                        if (checkStatus == true) {
                          setIsOpen2(!isOpen2);
                        }
                      });
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      type="button"
                      className="btn submit_btn"
                      disabled={disabled}
                    >
                      Attach Document
                    </button>
                    <a className="btn submit_btn" style={{ display: "none" }}>
                      Attach Document
                    </a>
                  </Popconfirm>
                )}
              {(item == undefined ||
                item["digiDocumentDetailId"] == 0 ||
                item["digiDocumentDetailId"] == null) && (
                  <button
                    onClick={() => {
                      const result = trigger(["number", "placeIssued", "dateIssued", "expiryDate"]);
                      result.then((checkStatus: any) => {
                        if (checkStatus == true) {
                          setIsOpen2(!isOpen2);
                        }
                      });
                    }}
                    type="button"
                    className="btn submit_btn"
                    disabled={disabled}
                  >
                    Attach Document
                  </button>
                )}
              <button
                type="submit"
                className="btn submit_btn"
                disabled={disabled}
              >
                Save
              </button>
            </div>
            <div className="col-sm-4">
              {((item != undefined &&
                item["digiDocumentDetailId"] > 0 &&
                oneSeamanBookCdcDocument.data != undefined) ||
                (form.docName != undefined && form.docName.length > 0)) && (
                  <label className="m_t_20">Document attached </label>
                )}
            </div>
          </div>
        </div>
      </form>
      <Modal
        show={isOpen2}
        onHide={() => {
          setIsOpen2(!isOpen2);
        }}
        dialogClassName="_attach-training"
      >
        {/* <AttachDocument setIsOpen2={setIsOpen2} isOpen2={isOpen2} /> */}
        <AttachDocument
          digiLockerType={digiLockerType}
          setIsOpen2={setIsOpen2}
          isOpen2={isOpen2}
          candidateId={candidateId}
          onSave={onSave}
          digiLockers={digiLockers}
          docNameProp={"SeamanbookCdc"}
          docTypeProp={2}
          docNumberProp={watch("number")}
          docExpiryProp={watch("expiryDate")}
          docRadioProp={"yes"}
        />
      </Modal>
    </div>
  );
}

interface IResidenceIdState {
  residenceId: string;
}

function ResidenceId(props: any) {
  const {
    disabled,
    residenceId,
    updateDetails,
    removeDetails,
    candidateId,
    digiLockerType,
    digiLockers,
    oneResidenceDocument,
  } = props;

  const defaultValues = {
    residenceId: residenceId,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    // setValue,
    getValues,
    // control,
    trigger
  } = useForm<IResidenceIdState>({
    defaultValues,
  });

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const [form, setState] = useState({
    docName: "",
    docType: "",
    docNumber: "",
    docExpiry: 0,
    docRadio: "",
    docFile: {},
    fromLocker: false,
    digiDocumentDetailId: 0,
  });

  const onSave = (d: any) => {
    //console.log(d);

    setIsOpen2(false);

    const {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    } = d;

    const _d = Object.assign({}, form, {
      docName,
      docType,
      docNumber,
      docExpiry,
      docRadio,
      docFile,
      fromLocker,
      digiDocumentDetailId,
    });

    // //console.log(_d);

    //setState(_d);
    if (
      residenceId != null &&
      residenceId != undefined &&
      !_.isEmpty(residenceId)
    ) {
      const _data: objectIndexing = {};
      const d1: objectIndexing = {};
      _data["residenceId"] = residenceId;
      if (_d.fromLocker) {
        _data["residenceDigiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data };

      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updateDetails("_residenceId", _data, d);
    } else {
      // setState(_d);
      //save new residence id information along with attachment
      const _data: objectIndexing = {};
      _data["residenceId"] = getValues("residenceId");
      if (_d.fromLocker) {
        _data["residenceDigiDocumentDetailId"] = _d.digiDocumentDetailId;
      }
      const d: objectIndexing = { _data };

      if (_d.docName) {
        d["_attachDoc"] = _d;
      }
      updateDetails("_residenceId", _data, d);
      //save new residence id information along with attachment end
    }
  };

  const onSubmit = (data: any) => {
    // //console.log(data);
    const _data: objectIndexing = {};
    const d: objectIndexing = {};
    _data["residenceId"] = data["residenceId"];
    if (form.fromLocker) {
      _data["residenceDigiDocumentDetailId"] = form.digiDocumentDetailId;
    } else if (form.docName) {
      d["_attachDoc"] = form;
    }
    ////console.log(_data, d);
    updateDetails("_residenceId", _data, d);
  };

  return (
    <div id="residence" className="">
      <div className=" content_checks_bg2 m_t_10">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="content_sec1_head">Residence ID</div>
          <div className="close_icon _cursor-pointer">
            <img
              onClick={() => {
                removeDetails("_residenceId", {
                  residenceId: null,
                  residenceDigiDocumentDetailId: null,
                });
              }}
              src={require("./../../../images/close_icon.png")}
              width={16}
              height={16}
            />
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Residence ID :{" "}
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="residenceId"
                    className={"form-control"}
                    placeholder="Type here"
                    ref={register({
                      required: "Residence ID is required",
                    })}
                    disabled={disabled}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="residenceId"
                  render={({ message }) => (
                    <div className="profile_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-sm-4">
              {/* <button onClick={() => { setIsOpen2(!isOpen2) }} type="button" className="btn submit_btn" disabled={disabled}>Attach Document</button> */}
              {_.isEmpty(oneResidenceDocument) != true &&
                _.isEmpty(residenceId) != true && (
                  <Popconfirm
                    title={
                      <div>
                        <p>
                          You already have a document attached to this record.
                        </p>
                        <p> Name: {`${oneResidenceDocument.data.name} `}</p>
                        {/* <br /> */}
                        <p>
                          {" "}
                          Last Modified:{" "}
                          {`${oneResidenceDocument.data.updatedDate}`}
                        </p>
                        <p> Do you want to update the above document?</p>
                      </div>
                    }
                    //onConfirm={() => setIsOpen2(!isOpen2)}
                    onConfirm={() => {
                      const result = trigger(["residenceId"]);
                      result.then((checkStatus: any) => {
                        if (checkStatus == true) {
                          setIsOpen2(!isOpen2);
                        }
                      });
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      type="button"
                      className="btn submit_btn"
                      disabled={disabled}
                    >
                      Attach Document
                    </button>
                    <a className="btn submit_btn" style={{ display: "none" }}>
                      Attach Document
                    </a>
                  </Popconfirm>
                )}
              {(_.isEmpty(oneResidenceDocument) == true ||
                _.isEmpty(residenceId) == true) && (
                  <button
                    onClick={() => {
                      const result = trigger(["residenceId"]);
                      result.then((checkStatus: any) => {
                        if (checkStatus == true) {
                          setIsOpen2(!isOpen2);
                        }
                      });
                    }}
                    type="button"
                    className="btn submit_btn"
                    disabled={disabled}
                  >
                    Attach Document
                  </button>
                )}
              <button
                type="submit"
                className="btn submit_btn"
                disabled={disabled}
              >
                Save
              </button>
            </div>
            <div className="col-sm-4">
              {((_.isEmpty(oneResidenceDocument) != true &&
                _.isEmpty(residenceId) != true) ||
                (form.docName != undefined && form.docName.length > 0)) && (
                  <label className="m_t_20">Document attached </label>
                )}
            </div>
          </div>
        </form>
        <Modal
          show={isOpen2}
          onHide={() => {
            setIsOpen2(!isOpen2);
          }}
          dialogClassName="_attach-training"
        >
          {/* <AttachDocument setIsOpen2={setIsOpen2} isOpen2={isOpen2} /> */}
          <AttachDocument
            digiLockerType={digiLockerType}
            setIsOpen2={setIsOpen2}
            isOpen2={isOpen2}
            candidateId={candidateId}
            onSave={onSave}
            digiLockers={digiLockers}
            docNameProp={"ResidenceId"}
            docTypeProp={2}
            docNumberProp={watch("residenceId")}
            docExpiryProp={0}
            docRadioProp={"no"}
          />
        </Modal>
      </div>
    </div>
  );
}

function Document(props: any) {
  const {
    myProfile,
    // userId,
    candidateId,
    candidateState,
    // seamanBookCdc,
    // getSeamanBookCdc,
    digiLockerType,
    digiLockers,
    getCandidates,
    getBankDetails,
    bankDetailsState,
    passportInformation,
    getPassportInformation,
  } = props;

  ////console.log(passportInformation);

  const [disabled, setDisabled] = useState<boolean>(true);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const [bankDetailsSelectBoxData, setBankDetailsSelectBoxData] = useState({});

  useEffect(() => {
    getBankDetailsSelectBoxData()
      .then((res) => {
        ////console.log(res.data);
        setBankDetailsSelectBoxData(res.data);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  const [
    onePassportInformation,
    setOnePassportInformation,
  ] = useState<objectIndexing>({});
  const [oneSeamanBookCdc, setOneSeamanBookCdc] = useState<objectIndexing>({});
  const [
    onePassportDocumentInformation,
    setOnePassportDocumentInformation,
  ] = useState<objectIndexing>({});
  const [
    oneSeamanBookCdcDocument,
    setOneSeamanBookCdcDocument,
  ] = useState<objectIndexing>({});
  const [
    oneResidenceDocument,
    setOneResidenceDocument,
  ] = useState<objectIndexing>({});
  const [onePanDocument, setOnePanDocument] = useState<objectIndexing>({});
  const [oneAadharDocument, setOneAadharDocument] = useState<objectIndexing>(
    {}
  );
  const [oneBankDocument, setOneBankDocument] = useState<objectIndexing>({});

  useEffect(() => {
    if (candidateState.data.length > 0) {
      if (candidateState.data[0]["passportInformationId"]) {
        getOnePassportInformation({
          rowId: Number(candidateState.data[0]["passportInformationId"]),
        })
          .then((res) => {
            ////console.log(res.data);
            setOnePassportInformation(res.data);
            if (res.data.digiDocumentDetailId != 0) {
              getOneDigiDocumentDetails({
                rowId: Number(res.data.digiDocumentDetailId),
              }).then((res1) => {
                ////console.log(res);
                if (res1.data.isSuccess == true && res1.data.data != null) {
                  setOnePassportDocumentInformation(res1.data);
                }
              });
            }
            // //console.log(res.data.digiDocumentDetailId);
          })
          .catch((err) => {
            // //console.log(err);
          });
      }
      if (candidateState.data[0]["seamanBookCdcId"]) {
        getOneSeamanBookCdc({
          rowId: Number(candidateState.data[0]["seamanBookCdcId"]),
        })
          .then((res) => {
            ////console.log(res.data);
            setOneSeamanBookCdc(res.data);
            if (res.data.digiDocumentDetailId != 0) {
              getOneDigiDocumentDetails({
                rowId: Number(res.data.digiDocumentDetailId),
              }).then((res1) => {
                ////console.log(res);
                if (res1.data.isSuccess == true && res1.data.data != null) {
                  setOneSeamanBookCdcDocument(res1.data);
                }
              });
            }
          })
          .catch((err) => {
            // //console.log(err);
          });
      }
      if (
        candidateState.data[0]["residenceId"] &&
        candidateState.data[0]["residenceDigiDocumentDetailId"]
      ) {
        getOneDigiDocumentDetails({
          rowId: Number(
            candidateState.data[0]["residenceDigiDocumentDetailId"]
          ),
        }).then((res) => {
          ////console.log(res);
          if (res.data.isSuccess == true && res.data.data != null) {
            setOneResidenceDocument(res.data);
          }
        });
      }
      if (
        candidateState.data[0]["panNo"] &&
        candidateState.data[0]["panDigiDocumentDetailId"]
      ) {
        getOneDigiDocumentDetails({
          rowId: Number(candidateState.data[0]["panDigiDocumentDetailId"]),
        }).then((res) => {
          ////console.log(res);
          if (res.data.isSuccess == true && res.data.data != null) {
            setOnePanDocument(res.data);
          }
        });
      }
      if (
        candidateState.data[0]["aadharNo"] &&
        candidateState.data[0]["aadharDigiDocumentDetailId"]
      ) {
        getOneDigiDocumentDetails({
          rowId: Number(candidateState.data[0]["aadharDigiDocumentDetailId"]),
        }).then((res) => {
          // //console.log(res);
          if (res.data.isSuccess == true && res.data.data != null) {
            setOneAadharDocument(res.data);
          }
        });
      }

      if (bankDetailsState.data.length > 0) {
        ////console.log(bankDetailsState.data);
        getOneDigiDocumentDetails({
          rowId: Number(bankDetailsState.data[0]["digiDocumentDetailId"]),
        }).then((res) => {
          // //console.log(res);
          if (res.data.isSuccess == true && res.data.data != null) {
            setOneBankDocument(res.data);
          }
        });
      }
    }
  }, [candidateState.data.length]);

  ////console.log(onePassportInformation);

  const [docTempRender, setDocTempRender] = useState<string>("");

  const updatePassportDetails = async (id: any, d: any) => {
    if ("_attachDoc" in d) {
      globalSpinner.showSpinner();

      saveDigiDocumentDetails({
        RowId: 0,
        DigiDocumentDetailId: 0,
        Name: d._attachDoc["docName"],
        DocumentNumber: d._attachDoc["docNumber"],
        Description: "string",
        CandidateId: Number(candidateId),
        DigiDocumentTypeId: Number(d._attachDoc["docType"]),
        ExpiryDate: new Date(d._attachDoc["docExpiry"]).toISOString(),
        ExpiryFlag: d._attachDoc["docRadio"] == "yes",
        IsActive: true,
      })
        .then((res: any) => {
          // //console.log(res.data);
          if (res.data.isSuccess) {
            const formData = new FormData();
            // formData.append("RowId", 0);
            // formData.append("DigiDocumentUploadId", 0);
            formData.append("DigiDocumentDetailId", res.data.entityId);
            formData.append("DigiDocument", "string");
            formData.append("Document", d._attachDoc["docFile"]);
            formData.append("IsActive", "true");

            const _digiDocumentDetailId = res.data.entityId;

            saveDigiDocumentUpload(formData)
              .then((res: any) => {
                if (res.data.isSuccess) {
                  const data = d._data;

                  globalSpinner.showSpinner();
                  savePassportInformation(
                    Object.assign({}, data, {
                      digiDocumentDetailId: _digiDocumentDetailId,
                    })
                  )
                    .then((res) => {
                      // //console.log(res.data);

                      if (candidateState.data.length > 0) {
                        const _data1 = Object.assign(candidateState.data[0], {
                          passportInformationId: Number(res.data.entityId),
                        });

                        // //console.log(_data1);

                        saveCandidate(_data1)
                          .then((res: any) => {
                            // //console.log(res.data);

                            globalSpinner.hideSpinner();

                            if (res.data.isSuccess) {
                              getCandidates({
                                CandidateId: Number(candidateId),
                                Page: 1,
                                PageSize: 10,
                                SearchTerm: "string",
                                SortOrder: "string",
                                ShowInactive: false,
                              });
                              getOnePassportInformation({
                                rowId: Number(
                                  candidateState.data[0][
                                  "passportInformationId"
                                  ]
                                ),
                              })
                                .then((res) => {
                                  ////console.log(res.data);
                                  setOnePassportInformation(res.data);
                                })
                                .catch((err) => {
                                  // //console.log(err);
                                });
                              setDocTempRender("");
                              toast.success("Passport details updated.");
                            } else {
                              setDocTempRender("");
                              toast.error("Something went wrong");
                            }
                          })
                          .catch((err: any) => {
                            setDocTempRender("");
                            // //console.log(err);
                            globalSpinner.hideSpinner();
                            toast.error("Something went wrong");
                          });
                      }
                    })
                    .catch((err) => {
                      setDocTempRender("");
                      ////console.log(err);
                      globalSpinner.hideSpinner();
                      toast.error("Something went wrong");
                    });
                } else {
                  globalSpinner.hideSpinner();
                  toast.error(res.data.message);
                }
              })
              .catch((err: any) => {
                globalSpinner.hideSpinner();
                toast.error("Something went wrong.");
              });
          } else {
            globalSpinner.hideSpinner();
            toast.error(res.data.message);
          }
        })
        .catch((err: any) => {
          globalSpinner.hideSpinner();
          toast.error("Something went wrong.");
        });
    } else {
      const data = d._data;

      globalSpinner.showSpinner();
      savePassportInformation(data)
        .then((res) => {
          ////console.log(res.data);

          if (candidateState.data.length > 0) {
            const _data1 = Object.assign(candidateState.data[0], {
              passportInformationId: res.data["entityId"],
            });

            ////console.log(_data1);

            saveCandidate(_data1)
              .then((res: any) => {
                // //console.log(res.data);

                globalSpinner.hideSpinner();

                if (res.data.isSuccess) {
                  getCandidates({
                    CandidateId: Number(candidateId),
                    Page: 1,
                    PageSize: 10,
                    SearchTerm: "string",
                    SortOrder: "string",
                    ShowInactive: false,
                  });
                  getPassportInformation({
                    Page: 1,
                    PageSize: 10,
                    SearchTerm: "string",
                    SortOrder: "string",
                  });
                  setDocTempRender("");
                  toast.success("Passport details updated.");
                } else {
                  setDocTempRender("");
                  toast.error("Something went wrong");
                }
              })
              .catch((err: any) => {
                setDocTempRender("");
                ////console.log(err);
                globalSpinner.hideSpinner();
                toast.error("Something went wrong");
              });
          }
        })
        .catch((err) => {
          setDocTempRender("");
          ////console.log(err);
          globalSpinner.hideSpinner();
          toast.error("Something went wrong");
        });
    }
  };

  const removePassportDetails = async (id: any, data: any) => {
    ////console.log(id, data, docTempRender);

    if (id == docTempRender) {
      setDocTempRender("");
    } else {
      if (
        await confirm({
          confirmation: "Are you sure you want to delete this?",
          options: {
            yes: "Yes",
            no: "No",
            header: "Delete",
          },
        })
      ) {
        if (candidateState.data.length > 0) {
          globalSpinner.showSpinner();
          const _data1 = Object.assign(candidateState.data[0], {
            //passportInformationId: 0
            passportInformationId: null,
          });
          deletePassportInformation({ rowId: onePassportInformation["rowId"] })
            .then((res: any) => {
              // //console.log(res.data);
              if (res.data.isSuccess) {
                saveCandidate(_data1)
                  .then((res) => {
                    ////console.log(res.data);

                    globalSpinner.hideSpinner();

                    if (res.data.isSuccess) {
                      getCandidates({
                        CandidateId: Number(candidateId),
                        Page: 1,
                        PageSize: 10,
                        SearchTerm: "string",
                        SortOrder: "string",
                        ShowInactive: false,
                      });
                      setOnePassportInformation({});
                      toast.success("Passport details removed.");
                    } else {
                      globalSpinner.hideSpinner();
                      toast.error(res.data.message);
                    }
                  })
                  .catch((err) => {
                    ////console.log(err);
                    globalSpinner.hideSpinner();
                    toast.error(err.toString());
                  });
              } else {
                globalSpinner.hideSpinner();
                toast.error(res.data.message);
              }
            })
            .catch((err: any) => {
              ////console.log(err);
              globalSpinner.hideSpinner();
              toast.error(err.toString());
            });
        }
      } else {
        ////console.log("no");
      }
    }
  };

  const updateBankDetails = async (id: any, data: any) => {
    ////console.log(id, data);
    globalSpinner.showSpinner();
    saveBankDetails(data)
      .then((res) => {
        ////console.log(res.data);

        globalSpinner.hideSpinner();

        if (res.data.isSuccess) {
          // getCandidates({
          //   "CandidateId": Number(candidateId),
          //   "Page": 1,
          //   "PageSize": 10,
          //   "SearchTerm": "string",
          //   "SortOrder": "string",
          //   "ShowInactive": false
          // });
          getBankDetails({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          setDocTempRender("");
          toast.success("Bank details updated.");
        } else {
          setDocTempRender("");
          toast.error("Something went wrong, check all details are correct.");
        }
      })
      .catch((err) => {
        ////console.log(err);
        setDocTempRender("");
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const removeBankDetails = async (id: any, data: any) => {
    ////console.log(id, data, docTempRender);

    if (id == docTempRender) {
      setDocTempRender("");
    } else {
      if (
        await confirm({
          confirmation: "Are you sure you want to delete this?",
          options: {
            yes: "Yes",
            no: "No",
            header: "Delete",
          },
        })
      ) {
        globalSpinner.showSpinner();
        deleteBankDetails({ rowId: data["rowId"] })
          .then((res) => {
            ////console.log(res.data);

            globalSpinner.hideSpinner();

            if (res.data.isSuccess) {
              // getCandidates({
              //   "CandidateId": Number(candidateId),
              //   "Page": 1,
              //   "PageSize": 10,
              //   "SearchTerm": "string",
              //   "SortOrder": "string",
              //   "ShowInactive": false
              // });
              getBankDetails({
                CandidateId: Number(candidateId),
                Page: 1,
                PageSize: 10,
                SearchTerm: "string",
                SortOrder: "string",
                ShowInactive: false,
              });

              toast.success("Bank details removed.");
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            ////console.log(err);
            globalSpinner.hideSpinner();
            toast.error(err.toString());
          });
      } else {
        ////console.log("no");
      }
    }
  };

  const updateDocumentDetails = async (id: any, data: any, d: any) => {
    var attachId = 0;
    var attachStatus = false;
    //-------------------------------------
    if ("_attachDoc" in d) {
      globalSpinner.showSpinner();
      saveDigiDocumentDetails({
        RowId: 0,
        DigiDocumentDetailId: 0,
        Name: d._attachDoc["docName"],
        DocumentNumber: d._attachDoc["docNumber"],
        Description: "string",
        CandidateId: Number(candidateId),
        DigiDocumentTypeId: Number(d._attachDoc["docType"]),
        ExpiryDate: new Date(d._attachDoc["docExpiry"]).toISOString(),
        ExpiryFlag: d._attachDoc["docRadio"] == "yes",
        IsActive: true,
      })
        .then((res: any) => {
          if (res.data.isSuccess) {
            const formData = new FormData();
            formData.append("DigiDocumentDetailId", res.data.entityId);
            formData.append("DigiDocument", "string");
            formData.append("Document", d._attachDoc["docFile"]);
            formData.append("IsActive", "true");
            const _digiDocumentDetailId = res.data.entityId;
            saveDigiDocumentUpload(formData)
              .then((res: any) => {
                if (res.data.isSuccess) {
                  attachId = _digiDocumentDetailId;
                  attachStatus = true;
                  ////console.log(attachId, attachStatus);
                  if (attachId > 0) {
                    if (id == "_aadhar") {
                      data["aadharDigiDocumentDetailId"] = attachId;
                    } else if (id == "_pan") {
                      data["panDigiDocumentDetailId"] = attachId;
                    } else if (id == "_residenceId") {
                      data["residenceDigiDocumentDetailId"] = attachId;
                    } else if (id == "_seamanBook" || id == "_bank") {
                      data["digiDocumentDetailId"] = attachId;
                    }
                  }
                  // //console.log(id, data);
                  if (id == "_seamanBook") {
                    updateSeaman(id, data);
                  } else if (id == "_bank") {
                    updateBankDetails("_bank", data);
                  } else {
                    updateDetails(id, data);
                  }
                } else {
                  toast.error(res.data.message);
                }
              })
              .catch((err) => {
                ////console.log(err);
                //toast.error(res.data.message);
              });
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    } else {
      if (id == "_seamanBook") {
        //data["digiDocumentDetailId"] = null;
        updateSeaman(id, data);
      } else if (id == "_bank") {
        updateBankDetails("_bank", data);
      } else {
        updateDetails(id, data);
      }
    }
    globalSpinner.hideSpinner();
    //------------------------------------------
  };

  const updateDetails = async (id: any, data: any) => {
    // //console.log(id, data);
    if (candidateState.data.length > 0) {
      const _data1 = Object.assign(candidateState.data[0], data);

      // //console.log(_data1);

      globalSpinner.showSpinner();

      saveCandidate(_data1)
        .then((res: any) => {
          ////console.log(res.data);

          globalSpinner.hideSpinner();

          if (res.data.isSuccess) {
            getCandidates({
              CandidateId: Number(candidateId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "string",
              SortOrder: "string",
              ShowInactive: false,
            });
            setDocTempRender("");
            if (id == "_passport") {
              toast.success("Passport details updated.");
            } else if (id == "_aadhar") {
              toast.success("Aadhar updated.");
            } else if (id == "_pan") {
              toast.success("Pan Number updated.");
            } else if (id == "_seamanBook") {
              toast.success("SEAMAN BOOK CDC updated.");
            } else if (id == "_residenceId") {
              toast.success("Residence ID updated.");
            } else {
              toast.success("Details updated.");
            }
          } else {
            setDocTempRender("");
            toast.error(res.data.message);
          }
        })
        .catch((err: any) => {
          setDocTempRender("");
          // //console.log(err);
          globalSpinner.hideSpinner();
          toast.error("Something went wrong");
        });
    }
  };

  const removeDetails = async (id: any, data: any) => {
    // //console.log(id, data, docTempRender);

    if (id == docTempRender) {
      setDocTempRender("");
    } else {
      if (
        await confirm({
          confirmation: "Are you sure you want to delete this?",
          options: {
            yes: "Yes",
            no: "No",
            header: "Delete",
          },
        })
      ) {
        if (candidateState.data.length > 0) {
          const _data1 = Object.assign(candidateState.data[0], data);

          ////console.log(_data1);

          globalSpinner.showSpinner();

          saveCandidate(_data1)
            .then((res: any) => {
              ////console.log(res.data);

              globalSpinner.hideSpinner();

              if (res.data.isSuccess) {
                getCandidates({
                  CandidateId: Number(candidateId),
                  Page: 1,
                  PageSize: 10,
                  SearchTerm: "string",
                  SortOrder: "string",
                  ShowInactive: false,
                });
                if (id == "_passport") {
                  toast.success("Passport details removed.");
                } else if (id == "_aadhar") {
                  setOneAadharDocument({});
                  toast.success("Aadhar removed.");
                } else if (id == "_pan") {
                  setOnePanDocument({});
                  toast.success("Pan Number removed.");
                } else if (id == "_seamanBook") {
                  toast.success("SEAMAN BOOK CDC removed.");
                } else if (id == "_residenceId") {
                  setOneResidenceDocument({});
                  toast.success("Residence ID removed.");
                } else {
                  toast.success("Details removed.");
                }
              } else {
                toast.error(res.data.message);
              }
            })
            .catch((err: any) => {
              ////console.log(err);
              globalSpinner.hideSpinner();
              toast.error(err.toString());
            });
        }
      } else {
        ////console.log("no");
      }
    }
  };

  const updateSeaman = async (id: any, data: any) => {
    globalSpinner.showSpinner();
    ////console.log(data);
    saveSeamanBookCdc(data)
      .then((res: any) => {
        ////console.log(res.data);

        if (res.data.isSuccess && candidateState.data.length > 0) {
          const _data1 = Object.assign(candidateState.data[0], {
            seamanBookCdcId: res.data["entityId"],
          });

          ////console.log(_data1);

          saveCandidate(_data1)
            .then((res: any) => {
              ////console.log(res.data);

              globalSpinner.hideSpinner();

              if (res.data.isSuccess) {
                getCandidates({
                  CandidateId: Number(candidateId),
                  Page: 1,
                  PageSize: 10,
                  SearchTerm: "string",
                  SortOrder: "string",
                  ShowInactive: false,
                });
                getOneSeamanBookCdc({
                  rowId: Number(res.data["entityId"]),
                })
                  .then((res) => {
                    // //console.log(res.data);
                    setOneSeamanBookCdc(res.data);
                  })
                  .catch((err) => {
                    ////console.log(err);
                  });
                setDocTempRender("");
                toast.success("SEAMAN BOOK CDC updated.");
              } else {
                setDocTempRender("");
                toast.error("Something went wrong");
              }
            })
            .catch((err: any) => {
              setDocTempRender("");
              ////console.log(err);
              globalSpinner.hideSpinner();
              toast.error("Something went wrong");
            });
        } else {
          setDocTempRender("");
          globalSpinner.hideSpinner();
          toast.error(res.data.message);
          //toast.error("Something went wrong");
        }

        // //console.log(res.data);

        // globalSpinner.hideSpinner();

        // if (res.data.isSuccess) {
        //   getSeamanBookCdc({
        //     Page: 1,
        //     PageSize: 10,
        //     SearchTerm: "string",
        //     SortOrder: "string"
        //   });
        //   setDocTempRender("");
        //   toast.success("SEAMAN BOOK CDC updated.");
        // } else {
        //   setDocTempRender("");
        //   toast.error(res.data.message);
        // }
      })
      .catch((err: any) => {
        setDocTempRender("");
        ////console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const removeSeaman = async (id: any, data: any) => {
    ////console.log(id, data, docTempRender);

    if (id == docTempRender) {
      setDocTempRender("");
    } else {
      if (
        await confirm({
          confirmation: "Are you sure you want to delete this?",
          options: {
            yes: "Yes",
            no: "No",
            header: "Delete",
          },
        })
      ) {
        if (!_.isEmpty(oneSeamanBookCdc)) {
          globalSpinner.showSpinner();

          deleteSeamanBookCdc({ rowId: oneSeamanBookCdc["rowId"] })
            .then((res: any) => {
              ////console.log(res.data);

              globalSpinner.hideSpinner();

              if (res.data.isSuccess) {
                const _data1 = Object.assign(candidateState.data[0], {
                  seamanBookCdcId: null,
                });

                saveCandidate(_data1)
                  .then((res) => {
                    // //console.log(res.data);

                    globalSpinner.hideSpinner();

                    if (res.data.isSuccess) {
                      getCandidates({
                        CandidateId: Number(candidateId),
                        Page: 1,
                        PageSize: 10,
                        SearchTerm: "string",
                        SortOrder: "string",
                        ShowInactive: false,
                      });
                      // setOneSeamanBookCdc({});
                      // toast.success("Passport details removed.");
                      setOneSeamanBookCdc({});
                      toast.success("SEAMAN BOOK CDC removed.");
                    } else {
                      toast.error(res.data.message);
                    }
                  })
                  .catch((err) => {
                    ////console.log(err);
                    globalSpinner.hideSpinner();
                    toast.error(err.toString());
                  });
                // getOneSeamanBookCdc({
                //   rowId: Number(candidateState.data[0]["seamanBookCdcId"])
                // }).then((res) => {
                //   //console.log(res.data);
                //   setOneSeamanBookCdc(res.data);
                // }).catch((err) => {
                //   //console.log(err);
                // });

                // setOneSeamanBookCdc({});
                // toast.success("SEAMAN BOOK CDC removed.");
              } else {
                toast.error(res.data.message);
              }
            })
            .catch((err: any) => {
              ////console.log(err);
              globalSpinner.hideSpinner();
              toast.error(err.toString());
            });
        }
      } else {
        ////console.log("no");
      }
    }
  };

  const dummyForm: objectIndexing = {
    _pan: (
      <Pan
        disabled={disabled}
        panNo={""}
        ///updateDetails={updateDetails}
        updateDetails={updateDocumentDetails}
        removeDetails={removeDetails}
        digiLockerType={digiLockerType}
        digiLockers={digiLockers}
        candidateId={candidateId}
      />
    ),
    _passport: (
      <Passport
        myProfile={myProfile}
        digiLockerType={digiLockerType}
        digiLockers={digiLockers}
        candidateId={candidateId}
        disabled={disabled}
        number={""}
        startDate={0}
        endDate={0}
        placeIssued={""}
        ecrStatus={""}
        nationality={""}
        updatePassportDetails={updatePassportDetails}
        removePassportDetails={removePassportDetails}
      />
    ),
    _aadhar: (
      <Adhar
        disabled={disabled}
        aadharNo={""}
        //updateDetails={updateDetails}
        updateDetails={updateDocumentDetails}
        removeDetails={removeDetails}
        digiLockerType={digiLockerType}
        digiLockers={digiLockers}
        candidateId={candidateId}
      />
    ),
    _seamanBook: (
      <SeamanBook
        disabled={disabled} //item={{}}
        seamanBookCdcId={""}
        placeIssued={""}
        dateIssued={0}
        expiryDate={0}
        updateSeaman={updateSeaman}
        removeSeaman={removeSeaman}
        updateDetails={updateDocumentDetails}
        digiLockerType={digiLockerType}
        digiLockers={digiLockers}
        candidateId={candidateId}
      />
    ),
    _residenceId: (
      <ResidenceId
        disabled={disabled}
        residenceId={""}
        //updateDetails={updateDetails}
        updateDetails={updateDocumentDetails}
        removeDetails={removeDetails}
        digiLockerType={digiLockerType}
        digiLockers={digiLockers}
        candidateId={candidateId}
      />
    ),
    _bank: (
      <BankDetails
        isAdd={true}
        disabled={disabled}
        bankDetailsState={bankDetailsState}
        myProfile={myProfile}
        candidateId={candidateId}
        bankDetailsSelectBoxData={bankDetailsSelectBoxData}
        updateBankDetails={updateBankDetails}
        removeBankDetails={removeBankDetails}
        updateDetails={updateDocumentDetails}
        digiLockerType={digiLockerType}
        digiLockers={digiLockers}
        oneBankDocument={oneBankDocument}
      />
    ),
  };

  const docMapRef = {
    _passport: (
      <option value="_passport" key="1">
        {/* PASSPORT{" "} */}
        Passport{" "}
      </option>
    ),
    _bank: (
      <option value="_bank" key="2">
        {/* BANK DETAILS{" "} */}
        Bank Details{" "}
      </option>
    ),
    _pan: (
      <option value="_pan" key="3">
        {/* PAN */}
        Pan
      </option>
    ),
    _aadhar: (
      <option value="_aadhar" key="4">
        Adhar
      </option>
    ),
    _seamanBook: (
      <option value="_seamanBook" key="5">
        {/* SEAMAN BOOK CDC */}
        Seaman Book Cdc
      </option>
    ),
    _residenceId: (
      <option value="_residenceId" key="6">
        {/* Residence ID{" "} */}
        Residence Id{" "}
      </option>
    ),
  };

  const docMap = Object.assign({}, docMapRef);

  const [docMapTemp, setDocMapTemp] = useState<objectIndexing>({});

  if (bankDetailsState.data.length > 0) {
    if (bankDetailsState.data[0]["accountNo"]) {
      delete (docMap as any)._bank;
    }
  }

  if (candidateState.data.length > 0) {
    if (candidateState.data[0]["passportNo"]) {
      delete (docMap as any)._passport;
    }

    // if (passportInformation.data.length > 0) {
    //   if (passportInformation.data[0]["passportNo"]) {
    //     delete (docMap as any)._passport;
    //   }
    // }

    const _passportInformation = _.find(passportInformation.data, {
      passportInformationId: candidateState.data[0]["passportInformationId"],
    });

    // //console.log(_passportInformation);
    // //console.log(passportInformation.data);
    // //console.log(candidateState.data[0]);

    if (_passportInformation) {
      delete (docMap as any)._passport;
    }

    if (candidateState.data[0]["panNo"]) {
      delete (docMap as any)._pan;
    }
    if (candidateState.data[0]["aadharNo"]) {
      delete (docMap as any)._aadhar;
    }
    if (candidateState.data[0]["seamanBookCdcId"]) {
      delete (docMap as any)._seamanBook;
    }
    if (candidateState.data[0]["residenceId"]) {
      delete (docMap as any)._residenceId;
    }
  }

  const docChange = (e: any) => {
    // //console.log(e);
    setDocTempRender(e);
    setDisabled(false);
    // //console.log(docTempRender);
  };

  ////console.log(bankDetailsState);

  return (
    <React.Fragment>
      <ToastContainer />
      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-2">
        <span className="resp-arrow" />
        Document
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-2"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Documents</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="personal_details">
          {_.isEmpty(onePassportInformation) ? (
            ""
          ) : (
            <Passport
              myProfile={myProfile}
              digiLockerType={digiLockerType}
              digiLockers={digiLockers}
              candidateId={candidateId}
              disabled={disabled}
              number={onePassportInformation["passportNo"]}
              // startDate={new Date(onePassportInformation["issuedDate"]).getTime()}
              // endDate={new Date(onePassportInformation["expiryDate"]).getTime()}
              startDate={moment(
                onePassportInformation["startDate"],
                "DD/MM/YYYY"
              )
                .toDate()
                .getTime()}
              endDate={moment(onePassportInformation["endDate"], "DD/MM/YYYY")
                .toDate()
                .getTime()}
              // new Date(onePassportInformation["endDate"]).getTime()}
              placeIssued={onePassportInformation["placeIssued"]}
              //ecrStatus={onePassportInformation["ECRStatus"]}
              ecrStatus={onePassportInformation["ecrStatus"]}
              nationality={onePassportInformation["countryId"]}
              updatePassportDetails={updatePassportDetails}
              removePassportDetails={removePassportDetails}
              item={onePassportInformation}
              onePassportDocumentInformation={onePassportDocumentInformation}
            />
          )}

          {bankDetailsState.data.length > 0 &&
            bankDetailsState.data[0]["accountNo"] ? (
            <BankDetails
              isAdd={false}
              disabled={disabled}
              bankDetailsState={bankDetailsState}
              myProfile={myProfile}
              candidateId={candidateId}
              bankDetailsSelectBoxData={bankDetailsSelectBoxData}
              updateBankDetails={updateBankDetails}
              removeBankDetails={removeBankDetails}
              updateDetails={updateDocumentDetails}
              digiLockerType={digiLockerType}
              digiLockers={digiLockers}
              oneBankDocument={oneBankDocument}
            />
          ) : (
            ""
          )}

          {candidateState.data.length > 0 &&
            candidateState.data[0]["aadharNo"] ? (
            <Adhar
              disabled={disabled}
              aadharNo={candidateState.data[0]["aadharNo"]}
              //updateDetails={updateDetails}
              updateDetails={updateDocumentDetails}
              removeDetails={removeDetails}
              digiLockerType={digiLockerType}
              digiLockers={digiLockers}
              candidateId={candidateId}
              oneAadharDocument={oneAadharDocument}
            />
          ) : (
            ""
          )}

          {candidateState.data.length > 0 && candidateState.data[0]["panNo"] ? (
            <Pan
              disabled={disabled}
              panNo={candidateState.data[0]["panNo"]}
              //updateDetails={updateDetails}
              updateDetails={updateDocumentDetails}
              removeDetails={removeDetails}
              digiLockerType={digiLockerType}
              digiLockers={digiLockers}
              candidateId={candidateId}
              onePanDocument={onePanDocument}
            />
          ) : (
            ""
          )}

          {_.isEmpty(oneSeamanBookCdc) ? (
            ""
          ) : (
            <SeamanBook
              disabled={disabled}
              item={oneSeamanBookCdc}
              //seamanBookCdcId={oneSeamanBookCdc["seamanBookCdcId"]}
              seamanBookCdcId={oneSeamanBookCdc["cdcNumber"]}
              placeIssued={oneSeamanBookCdc["placeIssued"]}
              dateIssued={oneSeamanBookCdc["dateIssued"]}
              expiryDate={oneSeamanBookCdc["expiryDate"]}
              updateSeaman={updateSeaman}
              removeSeaman={removeSeaman}
              oneSeamanBookCdcDocument={oneSeamanBookCdcDocument}
              updateDetails={updateDocumentDetails}
              digiLockerType={digiLockerType}
              digiLockers={digiLockers}
              candidateId={candidateId}
            />
          )}

          {candidateState.data.length > 0 &&
            candidateState.data[0]["residenceId"] ? (
            <ResidenceId
              disabled={disabled}
              residenceId={candidateState.data[0]["residenceId"]}
              //updateDetails={updateDetails}
              updateDetails={updateDocumentDetails}
              removeDetails={removeDetails}
              digiLockerType={digiLockerType}
              digiLockers={digiLockers}
              candidateId={candidateId}
              oneResidenceDocument={oneResidenceDocument}
            />
          ) : (
            ""
          )}

          {/* {_.isEmpty(docTempRender) ? "" : docTempRender} */}
          {/* {Object.entries(docTempRender).map((e: any, i: any) => e[1])} */}
          {_.isEmpty(dummyForm[docTempRender]) ? "" : dummyForm[docTempRender]}

          <div className="col-sm-4">
            <div className="button dropdown">
              <select
                id="colorselector"
                className="_cursor-pointer"
                onChange={(e: any) => {
                  docChange(e.target.value);
                }}
              >
                <option value="" key="0">
                  {/* Add Other Documents */}
                  Select Document
                </option>
                {Object.entries(docMap).map((e: any, i: any) => e[1])}
                {Object.entries(docMapTemp).map((e: any, i: any) => e[1])}
              </select>
            </div>
          </div>

          <div className="clearfix" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Document;
