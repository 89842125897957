import React from "react";
import StoryComponent from "./StoryComponent";
import AuthService from "./../../../../services/AuthService";

interface IStoryContainerProps { }

interface IStoryContainerState { }

const initialState = {};

const StoryContainer: React.FC<IStoryContainerProps> = (props) => {
  const [StoryContainerState, setStoryContainerState] = React.useState<IStoryContainerState>(
    initialState
  );
  return (
    <>
      <StoryComponent/>
    </>
  );
};
export default StoryContainer;
