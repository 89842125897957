import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  useAdminCommunicationDispatcher,
  useAdminCommunicationContext,
  getBulkEmailAllList,
  getBulkEmailDetailList,
  saveBulkEmailConversation,
  removeBulkEmailConversation,
} from "../../../../action/admin/AdminCommunicationAction";
import {
  BulkEmailListRequestModel,
  BulkEmailDetailRequestModel,
  BulkEmailDetailPostModel,
  MailTransactionsData,
  BulkEmailDetailListModel,
  BulkEmailAllListModel,
} from "../../../../models/admin/AdminCommunication";
// import {
//   useVendorMyProfileContext,
//   useVendorMyProfileDispatcher,
// } from "../../../../action/MyProfileVendorAction";
import {
  //getAdminLoggedUserId,
  useAdminMyProfileContext,
  //useAdminMyProfileDispatcher
} from "../../../../action/MyProfileAdminAction";
import moment from "moment";
import { Modal } from "react-bootstrap";

import { getBulkEmailDetailList as fetchTransacton } from "../../../../apis/admin";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../../../context/GlobalSpinner";
import _ from "lodash";
import { environment } from "../../../../environments/environment";
import { useAdminMyJobContext } from "../../../../action/admin/AdminMyJobAction";

type objectIndexing = {
  [key: string]: any;
};

function EmailUserList(props: any) {
  const {
    filter,
    userList,
    setCheckedListArray,
    checkedListArray,
    bulkEmailDetailList,
    adminCommunicationDispatcher,
    setSelectedMail,
    handleDeleteEmail,
    setUserShowList,
    userShowList,
    adminId,
    globalSpinner,
  } = props;
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isUnCheckedAll, setIsUnCheckedAll] = useState(false);
  const [isUserListOpen, setIsUserListOpen] = useState<boolean>(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [totalCount, setTotalCount] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSize = 10;
  const [totalPage, setTotalPage] = useState(1);

  React.useEffect(() => {
    // //console.log(currentPage);
    if (userList.data != undefined && currentPage == 1) {
      // //console.log("userList");
      // //console.log(userList);
      setUserShowList(userList.data);
      //setCurrentPage(1);
      setTotalPage(Math.ceil(userList.total / currentPageSize));
      setTotalCount(userList.total);
      // //console.log(userShowList);
    }
    if (userList.data != undefined && currentPage > 1) {
      // //console.log("userList2");
      setUserShowList([...userList.data, ...userShowList]);
    }
  }, [userList]);

  const fetchMoreData = async () => {
    // //console.log(currentPage, totalPage);
    // //console.log(totalCount);
    if (currentPage >= totalPage) {
      setHasMore(false);
      return;
    } else {
      setCurrentPage(currentPage + 1);
      (async () => {
        globalSpinner.showSpinner();
        await getBulkEmailAllList(adminCommunicationDispatcher, {
          UserId: adminId, //loggedUserId,
          Page: currentPage + 1,
          PageSize: currentPageSize,
          SearchTerm: "",
          SortOrder: "",
        } as BulkEmailListRequestModel);
        globalSpinner.hideSpinner();
      })();
    }
  };
  const handleAllChecked = (e: any) => {
    let IdArray = [] as any[];
    if (e.target.checked && userShowList != undefined) {
      IdArray = [];
      userShowList.map((d: any) => {
        //console.log(d);
        if (Number(d.transactionCount) > 0) {
          IdArray.push(d.mailTypeId);
        }
      });
    } else {
      IdArray = [];
    }
    setIsCheckedAll(e.target.checked);
    setCheckedListArray(IdArray);
    setIsUnCheckedAll(false);
  };

  const handleAllUnChecked = (e: any) => {
    let IdArray = [] as any[];
    if (e.target.checked) {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
    }
    setIsUnCheckedAll(e.target.checked);
  };

  const handleChecked = (e: any, item: any) => {
    let IdArray = [] as any[];
    if (e.target.checked) {
      IdArray.push(...checkedListArray, item.mailTypeId);
    } else {
      IdArray = checkedListArray.filter((data: any) => {
        return data !== item.mailTypeId;
      });
    }
    setCheckedListArray(IdArray);
  };

  const showUsers = async (e: any, i: any) => {
    ////console.log(e);
    (async () => {
      globalSpinner.showSpinner();
      await getBulkEmailDetailList(adminCommunicationDispatcher, {
        UserId: adminId, //loggedUserId,
        MailTypeId: e.mailTypeId,
        Page: 1,
        PageSize: 100,
        SearchTerm: "",
        SortOrder: "",
      } as BulkEmailDetailRequestModel);
      globalSpinner.hideSpinner();
    })();
    setIsUserListOpen(true);
  };

  React.useEffect(() => {
    if (checkedListArray.length > 0 && checkedListArray.length < 2) {
      let sel = userShowList.filter(
        (data: any) => data.mailTypeId == checkedListArray[0]
      )[0];
      setSelectedMail(sel);
    } else {
      setSelectedMail({
        mailTypeId: 0,
        mailBody: "",
        mailSubject: "",
        mailFile1: "",
      });
    }
  }, [checkedListArray.length]);

  React.useEffect(() => {
    if (currentPage >= totalPage) {
      setHasMore(false);
    }
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className=" m-t-25">
        <div className="row">
          <div className="col-sm-4 col-lg-4 pad_r_0">
            <input
              id="checkboxsel"
              type="checkbox"
              name="checkboxsel"
              checked={isCheckedAll}
              onChange={(e: any) => handleAllChecked(e)}
            />
            <label htmlFor="checkboxsel">
              <span /> Select All
            </label>
          </div>
          <div className="col-sm-5 col-lg-5 pad_r_0">
            <input
              id="checkboxusel"
              type="checkbox"
              name="checkboxusel"
              checked={isUnCheckedAll}
              onChange={(e: any) => handleAllUnChecked(e)}
            />
            <label htmlFor="checkboxusel">
              <span /> Deselect All
            </label>
          </div>
          <div className="col-sm-3 col-lg-3">
            <button
              className="btn delete_btn"
              onClick={handleDeleteEmail}
              disabled={checkedListArray.length > 0 ? false : true}
            >
              Delete{" "}
            </button>
          </div>
          {/* <button className="btn delete_btn" onClick={fetchMoreData} >fetch </button> */}
        </div>
        <div
          className="message_chat_scroll"
          style={{ overflow: "hidden" }}
          tabIndex={0}
        >
          <div className="connect_scroll">
            {userShowList != undefined && (
              <ul>
                <InfiniteScroll
                  className="_custom-infinite-scroll-class"
                  height={600}
                  // dataLength={100}
                  dataLength={userShowList.length}
                  // dataLength={totalCount}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>No more data to load</b>
                    </p>
                  }
                >
                  {userShowList != undefined &&
                    userShowList.map((item: any, index: any) => {
                      const lowercasedFilter = filter.toLowerCase();
                      if (
                        (String(item["mailTo"])
                          .toLowerCase()
                          .includes(lowercasedFilter) ||
                          filter == "") &&
                        Number(item["transactionCount"]) > 0
                      ) {
                        return (
                          <li>
                            {" "}
                            <a className="admin_m1">
                              <div
                                className="candidates_check1"
                                style={{ paddingTop: 10 }}
                              >
                                <input
                                  id={`checkbox${item["rowId"]}`}
                                  type="checkbox"
                                  name={`checkbox${item["rowId"]}`}
                                  onChange={(e: any) => handleChecked(e, item)}
                                  checked={checkedListArray.includes(
                                    item["mailTypeId"]
                                  )}
                                />
                                <label htmlFor={`checkbox${item["rowId"]}`}>
                                  <span />
                                </label>
                              </div>
                              <div
                                className="connect_con"
                                style={{ paddingLeft: 10 }}
                              >
                                <div className="connect_con_name">
                                  {" "}
                                  {item["mailTo"]}
                                  {Number(item["transactionCount"]) > 1 && (
                                    <div
                                      className="connect_con_des"
                                      onClick={() => {
                                        showUsers(item, index);
                                      }}
                                    >
                                      +{Number(item["transactionCount"]) - 1}
                                      more
                                    </div>
                                  )}
                                </div>
                                <div className="connect_con_des">
                                  {item["mailSubject"]}
                                </div>
                              </div>
                              <div className="chat_time_sec1">
                                <div className="chat_time">
                                  {moment(new Date(item["createdDate"])).format(
                                    "DD-MM-YYYY"
                                  )}
                                </div>
                                <div className="chat_time">
                                  {moment(new Date(item["createdDate"])).format(
                                    "HH:mm:ss"
                                  )}
                                </div>
                              </div>
                            </a>
                          </li>
                        );
                      }
                    })}
                </InfiniteScroll>
              </ul>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={isUserListOpen}
        onHide={() => {
          setIsUserListOpen(!isUserListOpen);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mail Recievers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 m_t_30 text-center">
            <div className="social1">
              {bulkEmailDetailList.data &&
                bulkEmailDetailList.data.map((item: any, index: any) => {
                  if (item["isActive"]) {
                    return <p> {item["mailTo"]}</p>;
                  }
                })}
            </div>
          </div>
          <div className="modal-footer  m-t-30"></div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

const BulkEmailComponent = () => {
  const globalSpinner = useContext(GlobalSpinnerContext);
  const adminCommunicationDispatcher = useAdminCommunicationDispatcher();
  const adminCommunicationContext = useAdminCommunicationContext();
  const {
    bulkEmailAllList,
    bulkEmailDetailList,
    bulkEmailRespond,
    bulkEmailDeleteResult,
  } = adminCommunicationContext;
  // const myVendorProfileContext = useVendorMyProfileContext();
  // const { loggedUserId } = myVendorProfileContext;
  //const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const {
    //myProfile,
    // basicInfo,
    loggedAdminId,
  } = myProfileAdminContext;
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;
  const adminId = loggedAdminId;
  const [filter, setFilter] = useState<string>("");
  const [sendMessage, setSendMessage] = useState<string>("");
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [replyBlockDisplay, setReplyBlockDisplay] = useState<string>("none");
  const [selectedMail, setSelectedMail] = useState({
    mailTypeId: 0,
    mailBody: "",
    mailSubject: "",
    mailFile1: "",
  });
  const [selectedMailTrans, setSelectedMailTrans] = useState(
    [] as BulkEmailDetailListModel[]
  );
  //const [userShowList, setUserShowList] = useState({});
  const [userShowList, setUserShowList] = useState(
    Array<BulkEmailAllListModel>()
  );
  const [toggleBlockDisplay, setToggleBlockDisplay] = useState<string>("none");

  React.useEffect(() => {
    //
    (async () => {
      globalSpinner.showSpinner();
      await getBulkEmailAllList(adminCommunicationDispatcher, {
        UserId: adminId, //loggedUserId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      } as BulkEmailListRequestModel);
      globalSpinner.hideSpinner();
    })();
  }, [adminId, bulkEmailDeleteResult, bulkEmailRespond]);

  // React.useEffect(() => {
  //   // if (bulkEmailAllList != undefined) {
  //   //   // //console.log(bulkEmailAllList);

  //   // }
  //   globalSpinner.hideSpinner();
  // }, [bulkEmailAllList]);

  React.useEffect(() => {
    if (selectedMail.mailTypeId > 0) {
      let _data1: objectIndexing = {};
      _data1["UserId"] = adminId; //loggedUserId,
      _data1["MailTypeId"] = selectedMail.mailTypeId;
      _data1["Page"] = 1;
      _data1["PageSize"] = 100;
      _data1["SearchTerm"] = "";
      _data1["SortOrder"] = "";
      globalSpinner.showSpinner();
      fetchTransacton(_data1)
        .then((res: any) => {
          globalSpinner.hideSpinner();
          if (res.status == 200) {
            setSelectedMailTrans(res.data.data);
          } else {
            //console.log(res.data);
          }
        })
        .catch((err: any) => {
          //console.log(err);
          globalSpinner.hideSpinner();
        });
    }
  }, [selectedMail.mailTypeId]);

  const handleSendEmailBlock = () => {
    if (replyBlockDisplay.includes("none")) {
      setReplyBlockDisplay("block");
    } else {
      setReplyBlockDisplay("none");
    }
  };

  const handleSendEmail = () => {
    let trans = [] as MailTransactionsData[];
    const formData = new FormData();
    if (selectedMailTrans.length > 0) {
      selectedMailTrans.forEach((item: any, index: any) => {
        if (item["isActive"] == true) {
          const listItem: MailTransactionsData = {
            MailFrom: item["mailFrom"],
            MailTo: item["mailTo"],
            MailStatus: "NA",
          };
          formData.append(
            "MailTransactions[" + index + "][MailFrom]",
            String(item["mailFrom"])
          );
          formData.append(
            "MailTransactions[" + index + "][MailTo]",
            String(item["mailTo"])
          );
          formData.append(
            "MailTransactions[" + index + "][MailStatus]",
            String("NA")
          );
          trans.push(listItem);
        }
      });
    }
    if (trans.length > 0) {
      formData.append("MailTypeId", String(0));
      formData.append("MailType", "Bulk");
      formData.append("MailSubject", "Reply:" + selectedMail.mailSubject);
      formData.append("MailBody", sendMessage);
      formData.append("MailHtmlContent", "");
      formData.append("FileAttached1", String(null));
      formData.append("FileAttached2", String(null));
      formData.append("FileAttached3", String(null));
      formData.append("FileAttached4", String(null));
      formData.append("FileAttached5", String(null));
      //formData.append("MailTransactions", JSON.stringify(trans));
      formData.append("UserId", String(adminId));
      formData.append("IsActive", JSON.stringify(true));
      //
      (async () => {
        globalSpinner.showSpinner();
        await saveBulkEmailConversation(
          adminCommunicationDispatcher,
          formData
          // {
          //   MailTypeId: 0,
          //   MailType: "Bulk",
          //   MailSubject: "Reply:" + selectedMail.mailSubject,
          //   MailBody: sendMessage,
          //   MailHtmlContent: "",
          //   FileAttached1: "",
          //   FileAttached2: "",
          //   FileAttached3: "",
          //   FileAttached4: "",
          //   FileAttached5: "",
          //   MailTransactions: trans,
          //   UserId: adminId, //loggedUserId,;
          //   IsActive: true,
          // } as BulkEmailDetailPostModel
        );
        globalSpinner.hideSpinner();
      })();
    }
  };

  const handleDeleteEmail = () => {
    ////console.log(checkedListArray);
    if (checkedListArray.length > 0) {
      //
      (async () => {
        globalSpinner.showSpinner();
        await removeBulkEmailConversation(adminCommunicationDispatcher, {
          UserId: adminId, //loggedUserId,;
          MailTypeId: checkedListArray,
        });
        globalSpinner.hideSpinner();
      })();
    }
  };

  const handleToggle = () => {
    if (toggleBlockDisplay.includes("none")) {
      setToggleBlockDisplay("block");
    } else {
      setToggleBlockDisplay("none");
    }
  };

  return (
    <>
      {/*=================Content Section Start================*/}
      {AdminPermissionResult.isCreateorEditJobPermission != undefined &&
        AdminPermissionResult.isCreateorEditJobPermission && (
          <div className="content-wrapper">
            <div className="container-fluid">
              <h1 className="heading">Sent Email</h1>
              <div className="action_btn1">
                <button
                  type="button"
                  className="actions_menu"
                  onClick={handleToggle}
                >
                  <i className="fa fa-align-left" aria-hidden="true" />
                </button>
                <div
                  className="user_menu"
                  style={{
                    display: `${toggleBlockDisplay}`,
                  }}
                >
                  <div className="action_sec">
                    <ul>
                      {AdminPermissionResult != undefined &&
                      AdminPermissionResult.isCreateorEditJobPermission ? (
                        <li>
                          <Link to="/admin/communication/bulkSMS">
                            Bulk Sent SMS
                          </Link>
                        </li>
                      ) : null}
                      {AdminPermissionResult != undefined &&
                      AdminPermissionResult.isCreateorEditJobPermission ? (
                        <li>
                          {" "}
                          <Link to="/admin/communication/bulkEmail">
                            Bulk Sent Email
                          </Link>{" "}
                        </li>
                      ) : null}
                      <li>
                        <Link to="/admin/communication/messages">Messages</Link>{" "}
                      </li>
                      <li>
                        <Link to="/admin/communication/contactUs">
                          Contact us Queries
                        </Link>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <div className="row " style={{ paddingTop: 10 }}>
                <div className="col-sm-5 col-lg-4 p-r-0">
                  <div className="panel panel-default panel_n">
                    <div className="panel-body panel-body1">
                      <div className="connect_left">
                        <div>
                          <input
                            type="email"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            className="form-control"
                            placeholder="Search"
                          />
                          {/* // <input type="email" className="form-control" placeholder="Search" /> */}
                          <div className="search_icon">
                            <i className="fa fa-search" aria-hidden="true" />
                          </div>
                        </div>
                        {bulkEmailAllList != undefined && (
                          <EmailUserList
                            filter={filter}
                            userList={bulkEmailAllList}
                            setCheckedListArray={setCheckedListArray}
                            checkedListArray={checkedListArray}
                            bulkEmailDetailList={bulkEmailDetailList}
                            adminCommunicationDispatcher={
                              adminCommunicationDispatcher
                            }
                            setSelectedMail={setSelectedMail}
                            setSelectedMailTrans={setSelectedMailTrans}
                            handleDeleteEmail={handleDeleteEmail}
                            setUserShowList={setUserShowList}
                            userShowList={userShowList}
                            adminId={adminId}
                            globalSpinner={globalSpinner}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {selectedMail.mailTypeId > 0 && (
                  <div className="col-sm-7 col-lg-8">
                    <div className="panel panel-default panel_n">
                      <div className="panel-body panel-body1">
                        <div>
                          {/* <p> {selectedMessage.messageTypeId}</p>
                    <p>{selectedMessage.messageSubject}</p> 
                    <p></p> */}
                          {selectedMail.mailBody}
                        </div>
                        <div className="col-sm-3 cs">
                          {!_.isEmpty(selectedMail.mailFile1) && (
                            <a
                              href={
                                environment.baseUrl +
                                `/Upload/BulkEmailAttachment/${selectedMail.mailFile1}`
                              }
                            >
                              Attachment
                            </a>
                          )}
                        </div>
                        <div className=" clearfix" />
                        <div>
                          <button
                            className="btn next_btn"
                            id="flip"
                            onClick={handleSendEmailBlock}
                          >
                            Reply
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      id="replay"
                      style={{
                        display: `${replyBlockDisplay}`,
                      }}
                    >
                      <div className="panel panel-default panel_n">
                        <div className="panel-body panel-body1">
                          <textarea
                            placeholder="Text here"
                            className="form-control"
                            onChange={(e) => setSendMessage(e.target.value)}
                          />
                          <div>
                            <button
                              className="btn next_btn"
                              onClick={handleSendEmail}
                              disabled={
                                selectedMail.mailTypeId > 0 &&
                                selectedMailTrans.length > 0
                                  ? false
                                  : true
                              }
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="clearfix" />
            </div>
          </div>
        )}
      {/*=================Content Section End================*/} s
    </>
  );
};

export default BulkEmailComponent;
