import React, { useState } from "react";
import {
  Redirect,
  useLocation,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { useSuperAdminMyProfileContext } from "../../../action/MyProfileSuperAdminAction";
import {
  useSuperAdminContext,
  useSuperAdminProfileImageContext,
} from "../../../context/superadmin/SuperAdminMyProfile";
import AuthService from "../../../services/AuthService";
import { updateAccessToken } from "../../../apis/misc";

function SuperAdminLayoutSidebar(props: any) {
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname != null ? location.pathname : "/super_admin"
  );

  const [currentWidth, setCurrentCurrentWidth] = useState<number>(
    window.innerWidth
  );

  const [menuHide, setMenuHide] = useState(false);
  const [name, setName] = useState("");

  const back = () => {
    //console.log(location);
    props.history.replace(location.pathname);
    return <Redirect to="/" />;
  };

  const superadminProfileImageContext = useSuperAdminProfileImageContext();

  const {
    superAdminProfileImage,
    getSuperAdminProfileImage,
  } = superadminProfileImageContext;

  const myProfileContext = useSuperAdminMyProfileContext();
  const {
    myProfile,
    loggedSuperAdminId,
    profileImage,
    basicInfo,
  } = myProfileContext;
  const authorizationToken = AuthService.accessToken;
  const user = AuthService.currentUser;

  const superadminContext = useSuperAdminContext();
  const { getSuperAdmins, superAdminState } = superadminContext;

  const superadminId = loggedSuperAdminId;

  React.useEffect(() => {
    //console.log("superadminlayoutsidebar outside if", superadminId);
    if (superadminId) {
      //console.log("superadminlayoutsidebar inside if", superadminId);

      getSuperAdminProfileImage({
        SuperAdminId: Number(superadminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [superadminId]);

  React.useEffect(() => {
    if (superadminId)
      (async () => {
        await getSuperAdmins({
          SuperAdminId: Number(superadminId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "",
          SortOrder: "",
          ShowInactive: false,
        });
      })();
  }, [superadminId]);

  React.useEffect(() => {
    if (superAdminState.data != undefined && superAdminState.data.length > 0) {
      setName(superAdminState.data[0]["superAdminName"]);
    }
  }, [superAdminState]);

  const onLogout = () => {
    updateAccessToken(
      {},
      `?id=${AuthService.currentUser?.id}&accessToken=${
        AuthService.accessToken
      }&login=${false}`
    )
      .then((res: any) => {
        //console.log("updated access token ",res.data);
      })
      .catch((err: any) => {
        //console.log("error in updating access token",err);
      });
    AuthService.logout();
    props.history.push("/");
  };

  const navigation = (url: any) => {
    props.history.push(url);
    if (currentWidth < 650) {
      setMenuHide(!menuHide);
    }
  };

  const cl = props.location.pathname;

  //console.log(111111111111111, name.split(" ")[0]);
  //console.log(222222222222222, window.innerWidth);
  //console.log("superAdminProfileImage", superAdminProfileImage);

  React.useEffect(() => {
    setCurrentCurrentWidth(window.innerWidth);
  }, [window.innerWidth]);

  return (
    <>
      {/*======Left Menu Start=======*/}
      <div className="icon-mobile-nav">
        <a
          onClick={() => {
            setMenuHide(!menuHide);
          }}
        >
          <i className="fa fa-2x fa-reorder" />
        </a>
      </div>
      <div className="menu_main">
        <div
          className={`left-menu-super-admin admin_menu  ${
            menuHide ? "hide-menu" : ""
          }`}
        >
          <div className="dash_logo">
            <img
              src={require("../../../images/logo_dash.png")}
              // src="images/logo_dash.png"
              className="img-responsive center-block"
            />
          </div>
          <div className="dash_profile">
            {/* <img
            src={
              profileImage != null && profileImage.total > 0
                ? `https://careerapp.azurewebsites.net/Upload/ProfileImage/${profileImage.data[0].imageUrl}`
                : require("../../../images/profileDefault1.jpg")
            }
            className="img-responsive"
          /> */}

            {superAdminProfileImage.data.length > 0 ? (
              <img
                className="img-responsive"
                src={`https://careerapp.azurewebsites.net/Upload/SuperAdminProfileImage/${superAdminProfileImage.data[0]["imageUrl"]}`}
                alt=""
              />
            ) : (
              <img
                className="img-responsive"
                src={require("./../../../images/profileDefault1.jpg")}
              ></img>
            )}
          </div>
          <div className="dash_profile_name">
            {superAdminState.data != undefined &&
            superAdminState.data.length > 0
              ? superAdminState.data[0]["superAdminName"]
              : ""}
            {/* {basicInfo.fullName != undefined ? basicInfo.fullName : ""} */}
          </div>
          <div className="dash_profile_admin">Super Admin</div>
          <aside
            style={{ marginTop: 10 }}
            className="bg-black dk nav_side-xs aside hidden-print"
            id="nav"
          >
            <section className="vbox">
              <section className="w-f-md scrollable">
                <div>
                  <div
                    data-height="auto"
                    data-disable-fade-out="true"
                    data-distance={0}
                    data-size="10px"
                    data-railopacity="0.2"
                  >
                    {/* nav */}
                    <div
                      id="divexample1"
                      style={{ overflow: "hidden" }}
                      tabIndex={0}
                    >
                      <nav className="nav_side-primary nav_pad">
                        <ul className="nav_side" data-ride="collapse">
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}`);
                              }}
                              className={
                                cl == "/super_admin"
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-th-large"
                                aria-hidden="true"
                              />
                              <div className="menus">Dashboard </div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/my_user`);
                              }}
                              className={
                                cl.includes("/super_admin/my_user")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-users" aria-hidden="true" />
                              <div className="menus">My Users</div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/messages`);
                              }}
                              className={
                                cl.includes("/super_admin/messages")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-commenting-o"
                                aria-hidden="true"
                              />
                              <div className="menus">Messaging</div>
                            </a>
                          </li>
                          {/* <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/database`);
                              }}
                              className={
                                cl.includes("/super_admin/database")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-database"
                                aria-hidden="true"
                              />
                              <div className="menus">Database</div>
                            </a>
                          </li> */}
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/report`);
                              }}
                              className={
                                cl.includes("/super_admin/report")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-files-o" aria-hidden="true" />
                              <div className="menus">Reports</div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/my-profile`);
                              }}
                              className={
                                cl.includes("/super_admin/my-profile")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-user-o" aria-hidden="true" />
                              <div className="menus">Profile</div>
                            </a>
                          </li>
                        </ul>
                        <ul className="nav_side nav_side1" data-ride="collapse">
                          <li>
                            <a onClick={onLogout}>
                              {" "}
                              <i
                                className="fa fa-power-off"
                                aria-hidden="true"
                              />
                              <div className="menus">Log Out </div>{" "}
                            </a>
                          </li>
                        </ul>
                      </nav>
                      {/* / nav */}
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </aside>
        </div>
      </div>
      {/*======Left Menu End=======*/}
    </>
  );
}

export default withRouter(SuperAdminLayoutSidebar);
