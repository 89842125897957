import React from "react";
import CandidateResumes from "./CandidateResumes";
import CandidateCoverLetters from "./CandidateCoverLetters";


interface IResumeComponentProps {
  // candidateResumes: any
  candidateId: number
  // setCandidateResumes: any
  digiLockerVew: any
  // candidateCoverLetters: any
  // setCandidateCoverLetters: any
}

interface IResumeComponentState { }

//const initialState = {};

const ResumeComponent: React.FC<IResumeComponentProps> = (props) => {
  // const [resumeComponentState, setResumeComponentState] = React.useState<IResumeComponentState>(
  //   initialState
  // );

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Resume</h1>
          <div className="clearfix" />
          <div className="row">
            <div className={props.digiLockerVew ? "col-sm-12 col-lg-9" : "col-sm-12 col-lg-12"}>
              <CandidateResumes
                // candidateResumes={props.candidateResumes} 
                pageSize={props.digiLockerVew ? 3 : 4}
                candidateId={props.candidateId}
              //  setCandidateResumes={props.setCandidateResumes}
              />
              <div className="m-t-25" />
              <CandidateCoverLetters
                // candidateCoverLetters={props.candidateCoverLetters} 
                pageSize={props.digiLockerVew ? 3 : 4}
                candidateId={props.candidateId}
              // setCandidateCoverLetters={props.setCandidateCoverLetters} 
              />

            </div>
            {/* ==================== */}
            {props.digiLockerVew ?
              <div className="col-sm-12 col-lg-3">
                <div className="heading_sec1">
                  <h1>
                    <i className="fa fa-lock" aria-hidden="true" /> About
                  DigiLocker
                </h1>
                </div>
                <div className="section_box4">
                  <div className="sec_con">
                    JIT's Digi Locker is a cost free feature giving candidates
                    secured access. Anytime. Anywhere. On the go.
                  <br />
                    {/* <br /> */}
                  There are two folders as General and Personal for convenience
                  to candidates. They cannot make Extra folders.
                  <br />
                  Candidates can ADD, UPDATE, EDIT, REMOVE, SHARE and SET EXPIRY
                  reminders. Candidates can ACCESS these documents as well as
                  ADD documents while attaching them in PROFILE or RESUME.
                  <br />
                  Candidates can ADD, UPDATE, EDIT, REMOVE, SHARE and SET EXPIRY
                  reminders. Candidates can ACCESS these documents as well as
                  ADD documents while attaching them in PROFILE or RESUME.
                  <br />
                  Candidates can ADD, UPDATE, EDIT, REMOVE, SHARE and SET EXPIRY
                  reminders. Candidates can ACCESS these documents as well as
                  ADD documents while attaching them in PROFILE or RESUME.
                  <br />
                  Candidates can ADD, UPDATE, EDIT, REMOVE, SHARE and SET EXPIRY
                  reminders. Candidates can ACCESS these documents as well as
                  ADD documents while attaching them in PROFILE or RESUME.
                </div>
                </div>
                <div className="heading_sec1">
                  <h1>
                    <i className="fa fa-cog" aria-hidden="true" /> How it works?
                </h1>
                </div>
                <div className="section_box4">
                  <div>
                    {" "}
                    <img
                      src={require("../../../../images/video_img.jpg")}
                      // src="images/video_img.jpg"
                      className="img-responsive"
                    />
                  </div>
                </div>
              </div>
              : ""}
            {/* ==================== */}
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </>
  );
};
export default ResumeComponent;
