import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  IDigiLockerAction,
  digiLockerReducer,
} from "../../action/candidate/DigiLockerAction";
import {
  digidocDownloadResult,
  digiLockerDetails,
  digiLockerDetailsResultModel,
  digiLockerDetailsViewModel,
  digiLockerDocumentMoveResultmodel,
  digiLockerDocumentSaveRespondModel,
  digiLockerExpiringDocumentViewModel,
  digiLockerTypeViewModel,
} from "../../models/candidate/DigiLocker";

export const DigiLockerDispatcherContext = React.createContext<React.Dispatch<IDigiLockerAction> | null>(
  null
);
export const DigiLockerStateContext = React.createContext<IDigiLockerManagementState | null>(
  null
);

export interface IDigiLockerManagementProps extends RouteComponentProps<any> {}

export interface IDigiLockerManagementState {
  visible: boolean;
  value: number;
  digiLockers: digiLockerDetailsViewModel;
  digiLockersNewList: digiLockerDetailsViewModel;
  digiLockerSaveRespond: digiLockerDocumentSaveRespondModel;
  digiLockerSaveRespondOne: digiLockerDocumentSaveRespondModel;
  digiLockerType: digiLockerTypeViewModel;
  digiLockerExpiringDocument: digiLockerExpiringDocumentViewModel;
  digiLockerDetails: digiLockerDetailsResultModel;
  digiLockerDeletRespond: digiLockerDocumentSaveRespondModel;
  documentMoveResultModel: digiLockerDocumentMoveResultmodel;
  documentSharedResult: string;
  digiDocumentExpModal: boolean;
}

export const initialDigiLockerManagementState = {
  visible: false,
  value: 0,
  digiLockers: {} as digiLockerDetailsViewModel,
  digiLockersNewList: {} as digiLockerDetailsViewModel,
  digiLockerSaveRespond: {} as digiLockerDocumentSaveRespondModel,
  digiLockerSaveRespondOne: {} as digiLockerDocumentSaveRespondModel,
  digiLockerDeletRespond: {} as digiLockerDocumentSaveRespondModel,
  digiLockerType: {} as digiLockerTypeViewModel,
  digiLockerExpiringDocument: {} as digiLockerExpiringDocumentViewModel,
  digiLockerDetails: {} as digiLockerDetailsResultModel,
  documentMoveResultModel: {} as digiLockerDocumentMoveResultmodel,
  documentSharedResult: "",
  digiDocumentExpModal: true,
} as IDigiLockerManagementState;

export const DigiLockerContextProvider: React.FC = ({ children }) => {
  const [digiLockerState, dispatcher] = useImmerReducer(
    digiLockerReducer,
    initialDigiLockerManagementState
  );

  return (
    <DigiLockerDispatcherContext.Provider value={dispatcher}>
      <DigiLockerStateContext.Provider value={digiLockerState}>
        {children}
      </DigiLockerStateContext.Provider>
    </DigiLockerDispatcherContext.Provider>
  );
};
