import React, { useState, useEffect } from "react";
import {
  getLoggedUserId,
  getMyProfileSelectBoxList,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../../action/MyProfileAction";
import ResumeMyInformationComponent from "./ResumeMyInformationComponent";
import { getCandidateExperienceSelectBoxData } from "../../../../apis/candidate";
import {
  useCandidateContext,
  useCandidateExperienceContext,
  useCandidateSkillsContext,
  useTrainingContext,
  useEducationQualificationContext,
  // useCandidateAchievementsContext,
  useCandidateLanguageMapsContext,
  // useCandidateReferencesContext,
  // useCandidateProfileImageContext,
  // useCandidateOtherCertificateContext,
} from "../../../../context/resume/CandidateMyInformation";

interface IResumeMyInformationContainerProps {}

interface IResumeMyInformationContainerState {}

const initialState = {};

const ResumeMyInformationContainer: React.FC<IResumeMyInformationContainerProps> = (
  props
) => {
  const [
    resumeMyInformationContainerState,
    setResumeMyInformationContainerState,
  ] = React.useState<IResumeMyInformationContainerState>(initialState);

  const myProfileDispatcher = useMyProfileDispatcher();
  const myProfileContext = useMyProfileContext();
  const { myProfile, basicInfo, loggedUserId } = myProfileContext;

  const [
    candidateExperienceSelectBoxData,
    setCandidateExperienceSelectBoxData,
  ] = useState({});

  const candidateId = loggedUserId;
  const candidateExperienceContext = useCandidateExperienceContext();
  const candidateContext = useCandidateContext();
  const candidateSkillsContext = useCandidateSkillsContext();
  const trainingContext = useTrainingContext();
  const educationQualificationContext = useEducationQualificationContext();
  const candidateLanguageMapsContext = useCandidateLanguageMapsContext();
  const {
    getCandidateExperiences,
    candidateExperienceState,
  } = candidateExperienceContext;
  const { getCandidates, candidateState } = candidateContext;
  const { getCandidateSkills, candidateSkillsState } = candidateSkillsContext;
  const { getTrainings, trainingState } = trainingContext;
  const {
    getEducationQualifications,
    educationQualificationState,
  } = educationQualificationContext;
  const {
    getCandidateLanguageMaps,
    candidateLanguageMaps,
  } = candidateLanguageMapsContext;

  useEffect(() => {
    if (candidateId) {
      getCandidateExperiences({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      });

      getCandidates({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      });

      getCandidateSkills({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getTrainings({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getEducationQualifications({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getCandidateLanguageMaps({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [candidateId]);

  React.useEffect(() => {
    (async () => {
      await getMyProfileSelectBoxList(myProfileDispatcher);

      getCandidateExperienceSelectBoxData()
        .then((res) => {
          //console.log(res.data);
          setCandidateExperienceSelectBoxData(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    })();
  }, []);

  return (
    <>
      <ResumeMyInformationComponent
        myProfile={myProfile}
        candidateExperiences={candidateExperienceState.data}
        getCandidateExperiences={getCandidateExperiences}
        candidateId={candidateId}
        getCandidates={getCandidates}
        candidateState={candidateState}
        candidateLanguageMaps={candidateLanguageMaps.data}
        getCandidateLanguageMaps={getCandidateLanguageMaps}
        getCandidateSkills={getCandidateSkills}
        candidateSkillsState={candidateSkillsState}
        getTrainings={getTrainings}
        trainingState={trainingState}
        educationQualificationState={educationQualificationState}
        getEducationQualifications={getEducationQualifications}
      />
    </>
  );
};
export default ResumeMyInformationContainer;
