import React from "react";
import { CandidateContextProvider } from "../../context/candidate/CandidateMyProfile";
import { valueCaptionPair } from "../../models/General";
import JobSearchComponent from "./JobSearchComponent";

interface IJobSearchContainerProps {}

interface IJobSearchContainerState {
  jobCategorys: valueCaptionPair[];
}

const initialState = {
  jobCategorys: [
    {
      label: "Java",
      value: "java",
    },
    {
      label: "JS",
      value: "js",
    },
  ],
};

const JobSearchContainer: React.FC<IJobSearchContainerProps> = (props) => {
  const [
    JobSearchContainerState,
    setJobSearchContainerState,
  ] = React.useState<IJobSearchContainerState>(initialState);
  const { jobCategorys } = JobSearchContainerState;

  return (
    <>
      <CandidateContextProvider>
        <JobSearchComponent jobCategorys={jobCategorys} />
      </CandidateContextProvider>
    </>
  );
};
export default JobSearchContainer;
