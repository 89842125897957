import React, { useEffect, useState } from "react";
import {
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import {
  getSuperAdminLoggedUserId,
  getSuperAdminMyProfileSelectBoxList,
  useSuperAdminMyProfileContext,
  useSuperAdminMyProfileDispatcher,
} from "../../../action/MyProfileSuperAdminAction";
import {
  getDocumentStatus,
  getProfileProgress,
} from "./../../../apis/superadmin";
import {
  useSocialAccountContext,
  useSuperAdminContext,
  useSuperAdminProfileImageContext,
} from "./../../../context/superadmin/SuperAdminMyProfile";
import AuthService from "./../../../services/AuthService";
import SuperAdminProfileComponent from "./SuperAdminProfileComponent";

interface ISuperAdminProfileContainerProps {}

interface ISuperAdminProfileContainerState {}

const initialState = {};

const SuperAdminProfileContainer: React.FC<ISuperAdminProfileContainerProps> = (
  props
) => {
  // Api For SelectBox Data
  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const myProfileDispatcher = useMyProfileDispatcher();
  const myProfileContext = useMyProfileContext();

  const myProfileSuperAdminDispatcher = useSuperAdminMyProfileDispatcher();
  const myProfileSuperAdminContext = useSuperAdminMyProfileContext();
  const {
    myProfile,
    basicInfo,
    loggedSuperAdminId,
  } = myProfileSuperAdminContext;

  const superadminId = loggedSuperAdminId;

  console.log("userId", userId, "superadminId", superadminId);

  const [
    SuperAdminExperienceSelectBoxData,
    setSuperAdminExperienceSelectBoxData,
  ] = useState({});

  const [
    SuperAdminRelativeSelectBoxData,
    setSuperAdminRelativeSelectBoxData,
  ] = useState({});

  const SuperAdminContext = useSuperAdminContext();
  const socialAccountContext = useSocialAccountContext();
  const SuperAdminProfileImageContext = useSuperAdminProfileImageContext();

  const { socialAccounts, getSocialAccounts } = socialAccountContext;
  const {
    superAdminProfileImage,
    getSuperAdminProfileImage,
  } = SuperAdminProfileImageContext;

  const [progressBar, setProgressBar] = useState({});
  const [documentStatus, setDocumentStatus] = useState({});
  const { getSuperAdmins, superAdminState } = SuperAdminContext;

  // const [socialAccounts, setSocialAccounts] = useState([]);

  console.log(888888888888888, loggedSuperAdminId, userId);

  useEffect(() => {
    if (superadminId) {
      console.log(superadminId, "@@@@@/////////// ", superAdminState);
      getSuperAdmins({
        SuperAdminId: Number(superadminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      });

      getProfileProgress({
        SuperAdminId: Number(superadminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log(
            "@@@@@@@@@@@@@@@superadminstate selected candidate@@@@@@@@@ ",
            res.data
          );
          setProgressBar(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      getDocumentStatus({
        SuperAdminId: Number(superadminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log(
            "@@@@@@@@@@@@@@@uploaded-document-status@@@@@@@@@ ",
            res.data
          );
          setDocumentStatus(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      getSocialAccounts({
        SuperAdminId: Number(superadminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      if (superAdminProfileImage.data.length <= 0) {
        getSuperAdminProfileImage({
          SuperAdminId: Number(superadminId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "string",
          SortOrder: "string",
          ShowInactive: false,
        });
      }
    }
  }, [superadminId]);

  React.useEffect(() => {
    (async () => {
      await getSuperAdminMyProfileSelectBoxList(myProfileSuperAdminDispatcher);
    })();
  }, [loggedSuperAdminId]);

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getSuperAdminLoggedUserId(
          myProfileSuperAdminDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  return (
    <>
      <SuperAdminProfileComponent
        myProfile={myProfile}
        userId={userId}
        superadminId={superadminId}
        superadminState={superAdminState}
        getSuperAdmins={getSuperAdmins}
        progressBar={progressBar}
        documentStatus={documentStatus}
        socialAccounts={socialAccounts}
        getSocialAccounts={getSocialAccounts}
        getSuperAdminProfileImage={getSuperAdminProfileImage}
        superadminProfileImage={superAdminProfileImage}
      />
    </>
  );
};
export default SuperAdminProfileContainer;
