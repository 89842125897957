import React, { useState, useContext } from "react";
import { cssTransition, toast, ToastContainer } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import { BrowserRouter, Link, withRouter, useHistory } from "react-router-dom";
import {
  getCareerDeveloperVideosBySearch,
  getCareerDeveloperVideosByCategory,
  getCareerDeveloperCategories,
} from "../../apis/index";
import ReactPlayer from "react-player";
import { environment } from "../../environments/environment";
import { ErrorMessage } from "@hookform/error-message";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../context/GlobalSpinner";

interface ICareerDeveloperComponent {}

const initialState = {};

function CategoryWiseVideoTile(props: any) {
  const {
    categories,
    setCategories,
    tileDisplay,
    setTileDisplay,
    getCategoryWiseTilesData,
    currentVideoPageSize,
    careerVideos,
    fetchMoreCategoryData,
    hasMoreCategoryData,
  } = props;

  // //console.log(props);
  const handleCategorySelection = (value: number) => {
    setTileDisplay(value);
    getCategoryWiseTilesData(value, 1, currentVideoPageSize);
  };

  return (
    <React.Fragment>
      <div className="heading_sec1">
        <h1>
          <i className="fa fa-list-ul" aria-hidden="true" /> Categories
        </h1>
      </div>
      <div className="section_box4">
        <div
          className="video_category_scroll"
          style={{ overflow: "hidden" }}
          tabIndex={0}
        >
          <div className="accordian_main">
            <div className="category_tab">
              {categories != undefined && categories.length > 0 && (
                <InfiniteScroll
                  className="_custom-infinite-scroll-class"
                  height={500}
                  dataLength={categories.length}
                  // dataLength={2000}
                  // dataLength={totalCount}
                  next={fetchMoreCategoryData}
                  hasMore={hasMoreCategoryData}
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>No more data to load</b>
                    </p>
                  }
                >
                  {categories
                    .filter((f: any) => f.isActive === true)
                    .map((e: any, i: any) => (
                      <>
                        <h1
                          style={{ marginTop: 10 }}
                          onClick={() => handleCategorySelection(e.rowId)}
                        >
                          {e.title}{" "}
                          <div className=" arrow" style={{ marginRight: 10 }} />
                        </h1>
                        {tileDisplay == e.rowId && (
                          <div className="row">
                            <div className="category_con_cd">
                              {careerVideos.length > 0 &&
                                careerVideos
                                  .filter((f: any) => f.isActive === true)
                                  .map((e1: any, i1: any) => (
                                    <>
                                      <div className="videos_cat">
                                        <ReactPlayer
                                          url={
                                            e1.videoName === "null"
                                              ? e1.youTubeUrl
                                              : environment.baseUrl +
                                                `/Upload/CareerDeveloperVideos/${e1.videoName}`
                                          }
                                          width="100%"
                                          height="100px"
                                        />
                                      </div>
                                    </>
                                  ))}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </InfiniteScroll>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function CareerDeveloperVideo(props: any) {
  const {
    careerDeveloperVideoAllList,
    // countNext,
    // setCountNext,
    // carouselValue,
    // setCarouselValue,
    // removeVideo,
    // setCurrentVideo,
    fetchMoreCareerDeveloperData,
    currentVideoPage,
    setCurrentVideo,
  } = props;

  const handleCarouselNextClick = () => {
    fetchMoreCareerDeveloperData(currentVideoPage + 1, true);
  };

  const handleCarouselPreviousClick = () => {
    fetchMoreCareerDeveloperData(currentVideoPage - 1, false);
  };

  const playVideo = async (e: any, i: any) => {
    //console.log(e, i);
    setCurrentVideo(
      e.videoName === "null"
        ? e.youTubeUrl
        : environment.baseUrl + `/Upload/CareerDeveloperVideos/${e.videoName}`
    );
  };

  return (
    <React.Fragment>
      <div className="section_box2">
        <div className="col-sm-12">
          <h1>Watch more videos</h1>
          <div className="row">
            <div className="prodict_list">
              <div
                id="owl-demo1"
                className="owl-carousel owl-theme"
                style={{ opacity: 1, display: "block" }}
              >
                <div className="owl-wrapper-outer">
                  <div
                    className="owl-wrapper"
                    style={{
                      width: "2772px",
                      //"2568px",
                      left: "0px",
                      display: "block",
                      transition: "all 0ms ease 0s",
                      transform: "translate3d(0px, 0px, 0px)",
                    }}
                  >
                    {careerDeveloperVideoAllList != undefined &&
                      careerDeveloperVideoAllList
                        .filter((f: any) => f.isActive == true)
                        .map((e: any, i: any) => (
                          <div className="owl-item" style={{ width: "300px" }}>
                            <div className="item">
                              <div className="matched_jobs_sec">
                                {/* ============================================== */}

                                <div className="career_video">
                                  <ReactPlayer
                                    url={
                                      e.videoName === "null" ||
                                      e.videoName === ""
                                        ? e.youTubeUrl
                                        : environment.baseUrl +
                                          `/Upload/CareerDeveloperVideos/${e.videoName}`
                                    }
                                    width="100%"
                                    height="100px"
                                  />
                                  <div className="overlay">
                                    <div className="text">
                                      <div className="career_play">
                                        <a
                                          onClick={() => {
                                            playVideo(e, i);
                                          }}
                                          data-target="#add_document"
                                          data-toggle="modal"
                                        >
                                          Play
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="videos_head">
                                  {e.displayVideoName}
                                </div>
                                <div className="clearfix" />
                                <div className="videos_by">by JIT</div>
                                <div className="videos_by">{e.title}</div>

                                {/* ============================================== */}
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
                <div
                  className="owl-controls clickable"
                  style={{ display: "none" }}
                >
                  <div className="owl-pagination">
                    <div className="owl-page active">
                      <span className="" />
                    </div>
                  </div>
                  <div className="owl-buttons">
                    <div
                      className="owl-prev"
                      onClick={handleCarouselPreviousClick}
                    >
                      <img src={require("../../images/products_ar1.png")} />
                    </div>
                    <div className="owl-next" onClick={handleCarouselNextClick}>
                      <img src={require("../../images/products_ar2.png")} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const CareerDeveloperComponent: React.FC<ICareerDeveloperComponent> = (
  props: any
) => {
  const history = useHistory();
  const cl = props.location.pathname;

  const {
    register,
    handleSubmit,
    // watch,
    errors,
    // trigger,
    // control
  } = useForm<{
    searchString: string;
  }>({
    defaultValues: { searchString: "" },
  });
  const [hasEnabledCategory, setHasEnabledCategory] = React.useState(false);
  const [currentVideoPage, setCurrentVideoPage] = useState(1);
  const [previousVideoPage, setPreviousVideoPage] = useState(false);
  const currentVideoPageSize = 4;
  const [totalVideoPage, setTotalVideoPage] = useState(1);
  const [careerVideos, setCareerVideos] = useState<any>([]);
  const [hasMoreVideo, setHasMoreVideo] = React.useState(false);
  const [selSearch, setSelSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(false);
  const currentPageSize = 10;
  const [totalPage, setTotalPage] = useState(1);
  const [categories, setCategories] = useState<any>([]);
  const [hasMore, setHasMore] = React.useState(false);
  const [tileDisplay, setTileDisplay] = useState(0);

  const [currentVideo, setCurrentVideo] = useState("");
  const [initialVideo, setInitialVideo] = useState(true);
  const globalSpinner = useContext(GlobalSpinnerContext);

  React.useEffect(() => {
    getCareerDeveloperData(selSearch, currentVideoPage, currentVideoPageSize);
    getCategoryData(currentPage, currentPageSize);
  }, [currentPageSize]);

  const onSubmit = (data: any) => {
    if (currentVideoPage != 1) {
      setCurrentVideoPage(1);
    }
    setSelSearch(data.searchString);
    getCareerDeveloperData(data.searchString, 1, currentVideoPageSize);
    if (hasEnabledCategory != false) {
      setHasEnabledCategory(false);
    }
  };

  const getCareerDeveloperData = (
    searchString: any,
    getPage: any,
    getPageSize: any
  ) => {
    globalSpinner.showSpinner();
    getCareerDeveloperVideosBySearch({
      Page: getPage,
      PageSize: getPageSize,
      SearchTerm: searchString,
      SortOrder: "",
    })
      .then((res: any) => {
        globalSpinner.hideSpinner();
        setHasMoreVideo(res.data.hasNext);
        if (getPage == 1) {
          setTotalVideoPage(Math.ceil(res.data.total / currentPageSize));
        }
        setCareerVideos(res.data.data);
        if (res.data.total > 0 && initialVideo) {
          setCurrentVideo(
            res.data.data[0].videoName === "null"
              ? res.data.data[0].youTubeUrl
              : environment.baseUrl +
                  `/Upload/CareerDeveloperVideos/${res.data.data[0].videoName}`
          );
          setInitialVideo(false);
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
      });
  };
  const getCategoryWiseCareerDeveloperData = (
    categoryId: any,
    getPage: any,
    getPageSize: any
  ) => {
    globalSpinner.showSpinner();
    getCareerDeveloperVideosByCategory({
      CategoryId: [categoryId],
      Page: getPage,
      PageSize: getPageSize,
      SearchTerm: "",
      SortOrder: "",
    })
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (getPage == 1) {
          setTotalVideoPage(Math.ceil(res.data.total / currentPageSize));
        }
        setCareerVideos(res.data.data);
        setHasMoreVideo(res.data.hasNext);
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
      });
  };
  const getCategoryWiseTilesData = (
    categoryId: any,
    getPage: any,
    getPageSize: any
  ) => {
    if (getPage == 1) {
      setCurrentVideoPage(getPage);
    }
    getCategoryWiseCareerDeveloperData(categoryId, getPage, getPageSize);
    if (hasEnabledCategory != true) {
      setHasEnabledCategory(true);
    }
  };
  const getCategoryData = (getPage: any, getPageSize: any) => {
    globalSpinner.showSpinner();
    getCareerDeveloperCategories({
      Page: getPage,
      PageSize: getPageSize,
      SearchTerm: "",
      SortOrder: "",
    })
      .then((res: any) => {
        globalSpinner.hideSpinner();
        setHasMore(res.data.hasNext);
        if (getPage == 1) {
          setTotalPage(Math.ceil(res.data.total / currentPageSize));
          setCategories(res.data.data);
        } else {
          setCategories([...res.data.data, ...categories]);
        }

        // setPreviousPage(res.data.hasPreviousPage)
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
      });
  };

  const fetchMoreCategoryData = async () => {
    if (hasMore) {
      setCurrentPage(currentPage + 1);
      getCategoryData(currentPage + 1, currentPageSize);
    }
  };

  const fetchMoreCareerDeveloperData = async (
    getPage: any,
    fetchNext: boolean
  ) => {
    if (hasMoreVideo && fetchNext) {
      setCurrentVideoPage(getPage);
      if (hasEnabledCategory) {
        getCategoryWiseCareerDeveloperData(
          tileDisplay,
          getPage,
          currentVideoPageSize
        );
      } else {
        getCareerDeveloperData(selSearch, getPage, currentVideoPageSize);
      }
    }
    if (fetchNext != true && currentVideoPage > 1) {
      setCurrentVideoPage(getPage);
      if (hasEnabledCategory) {
        getCategoryWiseCareerDeveloperData(
          tileDisplay,
          getPage,
          currentVideoPageSize
        );
      } else {
        getCareerDeveloperData(selSearch, getPage, currentVideoPageSize);
      }
    }
  };

  return (
    <>
      {/* Header Start */}

      <div className="header header1">
        <header>
          <div className=" container">
            <div className="row">
              <div className="col-sm-2">
                <div className=" logo">
                  <a
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    <img
                      src={require("../../images/logo.png")}
                      // src={require("../../images/logo.png"
                      className="img-responsive"
                      alt=""
                    />{" "}
                  </a>
                </div>
              </div>
              <div className="col-sm-10">
                <div className="bs-example">
                  <nav
                    role="navigation"
                    className="navbar navbar-default navbar-static"
                    id="navbar-example"
                  >
                    <div className="navbar-header">
                      <button
                        data-target=".bs-example-js-navbar-collapse"
                        data-toggle="collapse"
                        type="button"
                        className="navbar-toggle"
                      >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                      </button>
                      <div className="collapse navbar-collapse bs-example-js-navbar-collapse">
                        <ul className="nav navbar-nav">
                          <li>
                            <Link to="/" className={cl === "/" ? "active" : ""}>
                              {" "}
                              Home
                            </Link>{" "}
                          </li>
                          <li className="activefix">
                            {" "}
                            <Link
                              to="/career_developer"
                              className={
                                cl === "/career_developer" ? "active" : ""
                              }
                            >
                              Career Developer
                            </Link>
                          </li>
                          {/* <li>
                            {" "}
                            <Link
                              to="/coming_soon"
                              className={cl === "/coming_soon" ? "active" : ""}
                            >
                              Client{" "}
                            </Link>
                          </li> */}
                          {/* <li>
                            {" "}
                            <Link
                              to="/vendor"
                              className={cl === "/vendor" ? "active" : ""}
                            >
                              Vendor
                            </Link>{" "}
                          </li> */}
                          <li className="activefix">
                            <Link
                              to={`/help`}
                              className={cl === "/help" ? "active" : ""}
                            >
                              Help
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/about_us"
                              className={cl === "/about_us" ? "active" : ""}
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact_us"
                              className={cl === "/contact_us" ? "active" : ""}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      {/* Header Ends */}

      {/* Content start */}
      <div className="job_search_page">
        <div className=" container-fluid">
          <div className="developer_section">
            <div className="row">
              {/* <div className="developer_top"> */}
              {/* <div className="col-sm-12">
                  <img src={require("../../images/logo_login.png")} className="developer_logo" /></div>
                */}
              <div className="col-sm-8">
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="video_search_area_cd">
                    <div className="row">
                      <div className="col-xs-1">
                        <div className="search_icons_cd">
                          <img src={require("../../images/search_icon.png")} />
                        </div>
                      </div>
                      <div className="col-xs-5">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type Here"
                          style={{ marginLeft: -80 }}
                          name="searchString"
                          ref={register({
                            // required: "Search is required",
                            required: false,
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="searchString"
                          render={({ message }) => (
                            <div
                              className="login_validation"
                              style={{ marginTop: "-15px" }}
                            >
                              {message}
                            </div>
                          )}
                        />
                        {/* </div>
                    <div> */}
                      </div>

                      <div className="col-xs-6" style={{ padding: 10 }}>
                        <button
                          className="btn btn-success"
                          // onCick={ }
                        >
                          {" "}
                          Search{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-sm-4">
                {/* <div className="developer_right">
                    <div className="action_btn1">
                      <button type="button" className="jobs_action">Jhon Doe <i className="fa fa-angle-down" aria-hidden="true" /></button>
                      <div className="login_jobs"><img src="images/4.jpg" /></div>
                      <div className="jobs_action_dp" tabIndex={-1}>
                        <div className="action_sec">
                          <ul>
                            <li><a href="#">Logout</a> </li>
                            <li><a href="#">Dummy</a></li>
                            <li><a href="#">Dummy</a></li>
                            <li><a href="#">Dummy</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="video_alert"><img src="../../images/alert_icon.png" /></div>
                  </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <div className="section_box2" style={{ minHeight: "515px" }}>
                  {!_.isEmpty(currentVideo) && (
                    <ReactPlayer
                      //url={
                      //  environment.baseUrl +
                      //  `/Upload/CareerDeveloperVideos/${currentVideo}`
                      //  }

                      url={currentVideo}
                      width="100%"
                      height="500px"
                      controls={true}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-4">
                <CategoryWiseVideoTile
                  categories={categories}
                  setCategories={setCategories}
                  tileDisplay={tileDisplay}
                  setTileDisplay={setTileDisplay}
                  getCategoryWiseTilesData={getCategoryWiseTilesData}
                  currentVideoPageSize={currentVideoPageSize}
                  careerVideos={careerVideos}
                  fetchMoreCategoryData={fetchMoreCategoryData}
                  hasMoreCategoryData={hasMore}
                />
              </div>
            </div>
            <div className="clearfix" />
            <CareerDeveloperVideo
              careerDeveloperVideoAllList={careerVideos}
              // hasEnabledCategory={hasEnabledCategory}
              fetchMoreCareerDeveloperData={fetchMoreCareerDeveloperData}
              currentVideoPage={currentVideoPage}
              setCurrentVideo={setCurrentVideo}
            />
          </div>
        </div>
      </div>

      {/* Content end */}

      {/* ------------------------------ */}
    </>
  );
};
export default withRouter(CareerDeveloperComponent);
