import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  IJobNotifyAction,
  jobNotifyReducer,
} from "../../action/candidate/JobNotifyAction";
import {
  NotifyJobRespondModel,
  NotInterestedRespondModel,
} from "../../models/candidate/JobNotify";

export const JobNotifyDispatcherContext = React.createContext<React.Dispatch<IJobNotifyAction> | null>(
  null
);
export const JobNotifyStateContext = React.createContext<IJobNotifyManagementState | null>(
  null
);

export interface IJobNotifyManagementProps extends RouteComponentProps<any> {}

export interface IJobNotifyManagementState {
  notifyJobRespond: NotifyJobRespondModel;
  notInterestedRespond: NotInterestedRespondModel;
}

export const initialJobNotifyManagementState = {
  notifyJobRespond: {} as NotifyJobRespondModel,
  notInterestedRespond: {} as NotInterestedRespondModel,
} as IJobNotifyManagementState;

export const JobNotifyContextProvider: React.FC = ({ children }) => {
  const [jobNotifyState, dispatcher] = useImmerReducer(
    jobNotifyReducer,
    initialJobNotifyManagementState
  );

  return (
    <JobNotifyDispatcherContext.Provider value={dispatcher}>
      <JobNotifyStateContext.Provider value={jobNotifyState}>
        {children}
      </JobNotifyStateContext.Provider>
    </JobNotifyDispatcherContext.Provider>
  );
};
