import { Dispatch, Reducer } from "react";

import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";

import { AppUrls } from "../../environments/environment";
import {
  SuperAdminReportRequestModel,
  SuperAdminReportResultModel,
  SuperAdminTotalReportResultModel,
} from "../../models/superadmin/SuperAdminReport";
import {
  ISuperAdminReportManagementState,
  SuperAdminReportDispatcherContext,
  SuperAdminReportStateContext,
} from "../../context/superadmin/SuperAdminReportContext";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type ISuperAdminReportAction =
  | {
      type: "LEST_ALL_ADMIN_REPORT";
      adminReportsList: SuperAdminReportResultModel;
    }
  | {
      type: "GET_ALL_TOTAL_COUNT";
      totalReportList: SuperAdminTotalReportResultModel;
    };

export const superAdminReportReducer: Reducer<
  ISuperAdminReportManagementState,
  ISuperAdminReportAction
> = (draft, action): ISuperAdminReportManagementState => {
  switch (action.type) {
    case "LEST_ALL_ADMIN_REPORT":
      draft.adminReportsList = action.adminReportsList;
      return draft;
    case "GET_ALL_TOTAL_COUNT":
      draft.totalReportList = action.totalReportList;
      return draft;
  }
};

export const useSuperAdminReportDispatcher = (): React.Dispatch<ISuperAdminReportAction> => {
  const superAdminReportDispatcher = React.useContext(
    SuperAdminReportDispatcherContext
  );
  if (!superAdminReportDispatcher) {
    throw new Error(
      "You have to provide the SuperAdminReport dispatcher using theSuperAdminReportDispatcherContext.Provider in a parent component."
    );
  }
  return superAdminReportDispatcher;
};

export const useSuperAdminReportContext = (): ISuperAdminReportManagementState => {
  const superAdminReportContext = React.useContext(
    SuperAdminReportStateContext
  );
  if (!superAdminReportContext)
    throw new Error(
      "You have to provide the superAdminReport context using the SuperAdminReportStateContext.Provider in a parent component."
    );
  return superAdminReportContext;
};

export const getAllAdminListInReport = async (
  dispatcher: React.Dispatch<ISuperAdminReportAction>,
  query: SuperAdminReportRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllAdminReport;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "LEST_ALL_ADMIN_REPORT",
        adminReportsList: res.data,
      });
    });
  } catch (e) {}
};

export const getAllTotalReportCount = async (
  dispatcher: React.Dispatch<ISuperAdminReportAction>,
  query: SuperAdminReportRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetTotalCountReport;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_TOTAL_COUNT",
        totalReportList: res.data,
      });
    });
  } catch (e) {}
};
