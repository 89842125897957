import React from "react";

import { BrowserRouter } from "react-router-dom";
import IndexFooterContainer from "../index/indexFooter/IndexFooterContainer";
import CareerDeveloperComponent from "./CareerDeveloperComponent";

interface ICareerDeveloperContainerProps { }


const CareerDeveloperContainer: React.FC<ICareerDeveloperContainerProps> = (props) => {
  return (
    <>
      <CareerDeveloperComponent />
      <IndexFooterContainer />
    </>
  );
};
export default CareerDeveloperContainer;
