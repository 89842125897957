import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import {
  saveEducationQualification,
  deleteEducationQualification,
} from "../../../../apis/candidate";
import _ from "lodash";
import PikadayDatepicker from "../../my_profile/components/PikadayDatepicker";
import { ErrorMessage } from "@hookform/error-message";
import { GlobalSpinnerContext } from "../../../../context/GlobalSpinner";
import { ToastContainer, toast } from "react-toastify";
import { confirmWrapper, confirm } from "../../../GlobalConfirm";
import { Modal } from "react-bootstrap";
import IsComponentDisabled from "../../../components/IsComponentDisabled";

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IQualificationState {
  degree: string;
  university: string;
  //year: number
  year: string;
}

function RenderQualification(props: any) {
  const {
    disabled,
    item,
    index,
    removeQualification,
    updateQualification,
    setIsOpen2,
    isOpen2,
  } = props;

  const defaultValues = {
    degree: item.course + "",
    university: item.university,
    //year: moment(item["dateTo"], "DD/MM/YYYY").toDate().getTime(),
    year: item.qualificationYear,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<IQualificationState>({
    defaultValues,
  });

  const onSubmit = (data: any) => {
    //console.log(data);
    //console.log(item);
    const _data = Object.assign(item, {
      course: data["degree"],
      university: data["university"],
      //dateTo: new Date(data["year"]).toISOString(),
      QualificationYear: data["year"],
    });
    updateQualification(_data);
  };

  return (
    <div className=" profile_sec_bg">
      <div
        className="close_icon1 _cursor-pointer"
        onClick={() => {
          removeQualification(item, index);
        }}
      >
        <img
          src={require("../../../../images/close_icon.png")}
          width={16}
          height={16}
        />
      </div>
      <div className="row">
        <form
          className="personal_details"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="degree" className={"required"}>
                Qualification Name
              </label>
              {IsComponentDisabled(
                <input
                  type="text"
                  name="degree"
                  className={"form-control"}
                  placeholder="Type here"
                  disabled={disabled}
                  ref={register({
                    required: "Qualification Name is required",
                  })}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="degree"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="university" className={"required"}>
                University/Board/Institution
              </label>
              {IsComponentDisabled(
                <input
                  type="text"
                  name="university"
                  className={"form-control"}
                  placeholder="Type here"
                  disabled={disabled}
                  ref={register({
                    required: "University/Board/Institution is required",
                  })}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="university"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="year" className={"required"}>
                Passing Year
              </label>
              {IsComponentDisabled(
                <input
                  type="text"
                  name="year"
                  ref={register({
                    required: "Year is required",
                    maxLength: {
                      value: 4,
                      message: "Please provide a valid year",
                    },
                    minLength: {
                      value: 4,
                      message: "Please provide a valid year",
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Year should be numbers only",
                    },
                    validate: (value) =>
                      parseInt(value) > moment().year()
                        ? "Passing year cannot be a future year"
                        : undefined,
                  })}
                  disabled={disabled}
                  className={"form-control form-control-n"}
                  placeholder="Type here"
                />,
                disabled
              )}
              {/* {IsComponentDisabled(<Controller
                rules={{
                  validate: (value) => {
                    return value < Date.now() ? undefined : "Passing year cannot be a future date.";
                  }
                }}
                control={control}
                name="year"
                render={({ onChange, onBlur, value, name }) => {
                  //console.log(value);
                  return <PikadayDatepicker disabled={disabled} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                }}
              />, disabled)} */}
              <ErrorMessage
                errors={errors}
                name="year"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-12">
            {/* <button className="btn submit_btn" type="button" disabled={disabled} onClick={() => { setIsOpen2(!isOpen2) }}>Attach Document</button> */}
            <button
              type="submit"
              className="btn submit_btn"
              disabled={disabled}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function Qualification(props: any) {
  const {
    userId,
    candidateId,
    educationQualificationState,
    getEducationQualifications,
  } = props;
  //console.log(educationQualificationState);

  const [disabled, setDisabled] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  // const [isOpen2, setIsOpen2] = useState<boolean>(false);

  // let courses: ReactSelectOption[] = [];

  const defaultValues = {
    degree: "",
    university: "",
    //year: Date.now()
    year: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<IQualificationState>({
    defaultValues,
  });

  const globalSpinner = useContext(GlobalSpinnerContext);

  const removeQualification = async (data: any) => {
    //console.log(data);

    if (
      await confirm({
        confirmation: "Are you sure you want to delete this?",
        options: {
          yes: "Yes",
          no: "No",
          header: "Delete",
        },
      })
    ) {
      //console.log('yes');
      //console.log(data);
      globalSpinner.showSpinner();
      deleteEducationQualification({ rowId: data.rowId })
        .then((res) => {
          globalSpinner.hideSpinner();
          if (res.data.isSuccess) {
            getEducationQualifications({
              CandidateId: Number(candidateId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "",
              SortOrder: "",
              ShowInactive: false,
            });
            toast.success("Qualification details are removed.");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          globalSpinner.hideSpinner();
          toast.error(err.message);
        });
    } else {
      //console.log('no');
      //console.log(data);
    }
  };

  const updateQualification = async (data: any) => {
    //console.log(data);
    globalSpinner.showSpinner();
    saveEducationQualification(data)
      .then((res) => {
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          getEducationQualifications({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
            ShowInactive: false,
          });
          toast.success("Qualification details are updated.");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        globalSpinner.hideSpinner();
        toast.error(err.message);
      });
  };

  const onSubmit = (data: any) => {
    //console.log(data);

    globalSpinner.showSpinner();
    saveEducationQualification({
      RowId: 0,
      EducationQualificationId: 0,
      CandidateId: Number(candidateId),
      Course: data["degree"],
      University: data["university"],
      Grade: "string",
      // "DateFrom": "2020-11-02T16:31:42.544Z",
      // "DateTo": new Date(data["year"]).toISOString(),
      QualificationYear: data["year"],
      IsActive: true,
    })
      .then((res) => {
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          getEducationQualifications({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
            ShowInactive: false,
          });
          setIsOpen(!isOpen);
          toast.success("Qualification details are saved.");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        globalSpinner.hideSpinner();
        toast.error(err.message);
      });
  };

  return (
    <React.Fragment>
      {/*---------Start-------------------*/}
      <ToastContainer />
      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-2">
        <span className="resp-arrow" />
        Qualification
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-2"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Qualification</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div style={{ paddingBottom: "50px" }}>
          <a
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="btn add_btn"
          >
            Add Qualification
          </a>
        </div>
        {educationQualificationState.data.map((e: any, i: any) => (
          <RenderQualification
            key={i}
            item={e}
            index={i}
            disabled={disabled}
            removeQualification={removeQualification}
            updateQualification={updateQualification}
            // setIsOpen2={setIsOpen2}
            // isOpen2={isOpen2}
          />
        ))}
      </div>
      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Add Qualification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="degree" className={"required"}>
                Qualification Name
              </label>
              <input
                type="text"
                name="degree"
                className="form-control"
                placeholder="Type here"
                ref={register({
                  required: "Qualification Name is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="degree"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
            <div className="form-group">
              <label htmlFor="university" className={"required"}>
                University/Board/Institution
              </label>
              <input
                type="text"
                name="university"
                disabled={false}
                className="form-control"
                placeholder="Type here"
                ref={register({
                  required: "University/Board/Institution is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="university"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
            <div className="form-group">
              <label htmlFor="year" className={"required"}>
                Passing Year
              </label>
              <input
                type="text"
                name="year"
                ref={register({
                  required: "Year is required",
                  maxLength: {
                    value: 4,
                    message: "Please provide a valid year",
                  },
                  minLength: {
                    value: 4,
                    message: "Please provide a valid year",
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Year should be numbers only",
                  },
                  validate: (value) =>
                    parseInt(value) > moment().year()
                      ? "Passing year cannot be a future year"
                      : undefined,
                })}
                className={"form-control form-control-n"}
                placeholder="Type here"
              />
              {/* <Controller
                rules={{
                  validate: (value) => {
                    return value < Date.now() ? undefined : "Passing year cannot be a future date.";
                  }
                }}
                control={control}
                name="year"
                render={({ onChange, onBlur, value, name }) => {
                  //console.log(value);
                  return <PikadayDatepicker disabled={false} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                }}
              /> */}
              <ErrorMessage
                errors={errors}
                name="year"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="clearfix" />
        </form>
      </Modal>

      {/*---------End-------------------*/}
    </React.Fragment>
  );
}

export default Qualification;
