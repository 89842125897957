import { Reducer } from "react";
import React from "react";
import {
  CareerDeveloperVideoAllListModel,
  CareerDeveloperVideoRespondModel,
  CareerDeveloperVideoDeleteResultModel,
  CategorySelectBoxDataViewModel,
  CareerDeveloperCategoryDeleteResultModel,
  CareerDeveloperCategoryRespondModel,
  CareerDeveloperVideoRequestModel,
  CareerDeveloperVideoPostModel,
  CareerDeveloperVideoDeleteRequestModel,
  CareerDeveloperCategoryDeleteRequestModel,
  CareerDeveloperCategoryPostModel
} from "../../models/admin/CareerDeveloper";

import {
  CareerDeveloperDispatcherContext,
  CareerDeveloperStateContext,
  ICareerDeveloperManagementState,
} from "../../context/admin/CareerDeveloperContext";
import * as API from "./../../apis/admin";

export type ICareerDeveloperAction =
  | {
    type: "GET_Career_Developer_Video_LIST";
    careerDeveloperVideoAllList: CareerDeveloperVideoAllListModel;
  }
  | {
    type: "SAVE_Career_Developer_Video";
    careerDeveloperVideoRespond: CareerDeveloperVideoRespondModel;
  }
  | {
    type: "REMOVE_Career_Developer_Video";
    careerDeveloperVideoDeleteResult: CareerDeveloperVideoDeleteResultModel;
  }
  | {
    type: "GET_Career_Developer_Category_LIST";
    careerDeveloperCategoryList: CategorySelectBoxDataViewModel;
  }
  | {
    type: "SAVE_Career_Developer_Category";
    careerDeveloperCategoryRespond: CareerDeveloperCategoryRespondModel;
  }
  | {
    type: "REMOVE_Career_Developer_Category";
    careerDeveloperCategoryDeleteResult: CareerDeveloperCategoryDeleteResultModel;
  }

export const careerDeveloperReducer: Reducer<
  ICareerDeveloperManagementState,
  ICareerDeveloperAction
> = (draft, action): ICareerDeveloperManagementState => {
  switch (action.type) {
    case "GET_Career_Developer_Video_LIST":
      draft.careerDeveloperVideoAllList = action.careerDeveloperVideoAllList;
      return draft;
    case "SAVE_Career_Developer_Video":
      draft.careerDeveloperVideoRespond = action.careerDeveloperVideoRespond;
      return draft;
    case "REMOVE_Career_Developer_Video":
      draft.careerDeveloperVideoDeleteResult = action.careerDeveloperVideoDeleteResult;
      return draft;
    case "GET_Career_Developer_Category_LIST":
      draft.careerDeveloperCategoryList = action.careerDeveloperCategoryList;
      return draft;
    case "SAVE_Career_Developer_Category":
      draft.careerDeveloperCategoryRespond = action.careerDeveloperCategoryRespond;
      return draft;
    case "REMOVE_Career_Developer_Category":
      draft.careerDeveloperCategoryDeleteResult = action.careerDeveloperCategoryDeleteResult;
      return draft;

  }
};


export const useCareerDeveloperDispatcher = (): React.Dispatch<ICareerDeveloperAction> => {
  const careerDeveloperDispatcher = React.useContext(CareerDeveloperDispatcherContext);
  if (!careerDeveloperDispatcher) {
    throw new Error(
      "You have to provide the cCareerDeveloper dispatcher using the CareerDeveloperDispatcherContext.Provider in a parent component."
    );
  }
  return careerDeveloperDispatcher;
};

export const useCareerDeveloperContext = (): ICareerDeveloperManagementState => {
  const careerDeveloperContext = React.useContext(CareerDeveloperStateContext);
  if (!careerDeveloperContext)
    throw new Error(
      "You have to provide the careerDeveloper context using the CareerDeveloperStateContext.Provider in a parent component."
    );
  return careerDeveloperContext;
};


export const getCareerDeveloperVideoAllList = async (
  dispatcher: React.Dispatch<ICareerDeveloperAction>,
  query: CareerDeveloperVideoRequestModel
) => {
  try {

    API.getCareerDevelopers(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "GET_Career_Developer_Video_LIST", careerDeveloperVideoAllList: res.data });

    });
  } catch (e) { }
};

export const saveCareerDeveloperVideo = async (
  dispatcher: React.Dispatch<ICareerDeveloperAction>,
  query: any
  //query: CareerDeveloperVideoPostModel
) => {
  try {

    API.saveCareerDeveloper(query).then((res) => {
      dispatcher({ type: "SAVE_Career_Developer_Video", careerDeveloperVideoRespond: res.data });

    });
  } catch (e) { }
};



export const removeCareerDeveloperVideo = async (
  dispatcher: React.Dispatch<ICareerDeveloperAction>,
  query: CareerDeveloperVideoDeleteRequestModel
) => {
  try {
    API.deleteCareerDeveloper(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "REMOVE_Career_Developer_Video", careerDeveloperVideoDeleteResult: res.data });
    });
  } catch (e) { }
};


//Career_Developer Category

export const getCareerDeveloperCategoryAllList = async (
  dispatcher: React.Dispatch<ICareerDeveloperAction>,
  query: {}
) => {
  try {

    API.getCareerDeveloperSelectBoxData(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "GET_Career_Developer_Category_LIST", careerDeveloperCategoryList: res.data });

    });
  } catch (e) { }
};

export const saveCareerDeveloperCategory = async (
  dispatcher: React.Dispatch<ICareerDeveloperAction>,
  query: CareerDeveloperCategoryPostModel
) => {
  try {

    API.saveCareerDeveloperCategory(query).then((res) => {
      dispatcher({ type: "SAVE_Career_Developer_Category", careerDeveloperCategoryRespond: res.data });

    });
  } catch (e) { }
};



export const removeCareerDeveloperCategory = async (
  dispatcher: React.Dispatch<ICareerDeveloperAction>,
  query: CareerDeveloperCategoryDeleteRequestModel
) => {
  try {
    API.deleteCareerDeveloperCategory(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "REMOVE_Career_Developer_Category", careerDeveloperCategoryDeleteResult: res.data });
    });
  } catch (e) { }
};