import React from "react";
import CandidateSavedJobComponent from "./CandidateSavedJobComponent";




interface ICandidateSavedJobContainerProps { }

interface ICandidateSavedJobContainerState { }

const initialState = {};

const CandidateSavedJobContainer: React.FC<ICandidateSavedJobContainerProps> = (props) => {
  const [CandidateSavedJobContainerState, setCandidateSavedJobContainerState] = React.useState<ICandidateSavedJobContainerState>(
    initialState
  );

  return (
    <>
      <CandidateSavedJobComponent/>
    </>
  );
};
export default CandidateSavedJobContainer;
