import { Dispatch, Reducer } from "react";

import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";

import { AppUrls } from "../../environments/environment";

import {
  MyUserDispatcherContext,
  MyUserStateContext,
  IMyUserManagementState,
} from "../../context/admin/MyUserContext";
import { message } from "antd";
import {
  permisionRequestModel,
  PermissionResultModel,
} from "../../models/admin/AdminMyJob";
import {
  AdminClientEditRequestModel,
  AdminClientEditRespondModel,
  GetAllAdminClientRequestModel,
  GetAllAdminClientRespondModel,
  GetAllAdminRemoveRequestModel,
  GetAllAdminRemoveRespondModel,
  SaveAdminClientRequestModel,
  SaveAdminClientRespondModel,
} from "../../models/admin/MyUser";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IMyUserAction =
  | {
      type: "SAVE_ADMIN_CLIENT";
      SaveAdminClientRespondModel: SaveAdminClientRespondModel;
    }
  | {
      type: "EDIT_ADMIN_CLIENT";
      AdminClientEditRespondModel: AdminClientEditRespondModel;
    }
  | {
      type: "GET_ALL_ADMIN_CLIENT";
      GetAllAdminClientRespondModel: GetAllAdminClientRespondModel;
    }
  | {
      type: "REMOVE_ADMIN_CLIENT";
      GetAllAdminRemoveRespondModel: GetAllAdminRemoveRespondModel;
    };

export const myUserReducer: Reducer<IMyUserManagementState, IMyUserAction> = (
  draft,
  action
): IMyUserManagementState => {
  switch (action.type) {
    case "SAVE_ADMIN_CLIENT":
      draft.SaveAdminClientRespondModel = action.SaveAdminClientRespondModel;
      return draft;
    case "EDIT_ADMIN_CLIENT":
      draft.AdminClientEditRespondModel = action.AdminClientEditRespondModel;
      return draft;
    case "GET_ALL_ADMIN_CLIENT":
      draft.GetAllAdminClientRespondModel =
        action.GetAllAdminClientRespondModel;
      return draft;
    case "REMOVE_ADMIN_CLIENT":
      draft.GetAllAdminRemoveRespondModel =
        action.GetAllAdminRemoveRespondModel;
      return draft;
  }
};

export const useMyUserDispatcher = (): React.Dispatch<IMyUserAction> => {
  const myUserDispatcher = React.useContext(MyUserDispatcherContext);
  if (!myUserDispatcher) {
    throw new Error(
      "You have to provide the MyUser dispatcher using theMyUserDispatcherContext.Provider in a parent component."
    );
  }
  return myUserDispatcher;
};

export const useMyUserContext = (): IMyUserManagementState => {
  const myUserContext = React.useContext(MyUserStateContext);
  if (!myUserContext)
    throw new Error(
      "You have to provide the myUser context using the MyUserStateContext.Provider in a parent component."
    );
  return myUserContext;
};

export const SaveAdminClient = async (
  dispatcher: React.Dispatch<IMyUserAction>,
  query: SaveAdminClientRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveClientDetails;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SAVE_ADMIN_CLIENT",
        SaveAdminClientRespondModel: res.data,
      });
      res.data.isSuccess
        ? message.success("Client created sucessfully")
        : message.error(res.data.message);
    });
  } catch (e) {}
};

export const EditAdminClient = async (
  dispatcher: React.Dispatch<IMyUserAction>,
  query: AdminClientEditRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditClientDetails;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_ADMIN_CLIENT",
        AdminClientEditRespondModel: res.data,
      });
    });
  } catch (e) {}
};

export const GetAllAdminClient = async (
  dispatcher: React.Dispatch<IMyUserAction>,
  query: GetAllAdminClientRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllAdminClientDetails;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_ADMIN_CLIENT",
        GetAllAdminClientRespondModel: res.data,
      });
    });
  } catch (e) {}
};

export const RemoveAdminClient = async (
  dispatcher: React.Dispatch<IMyUserAction>,
  query: GetAllAdminRemoveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.RemoveAdminClientDetails;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "REMOVE_ADMIN_CLIENT",
        GetAllAdminRemoveRespondModel: res.data,
      });
      res.data.isSuccess
        ? message.success("Client Removed sucessfully")
        : message.error(res.data.message);
    });
  } catch (e) {}
};
