import React, { useState } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import CoverLetterEditTemplateContainer from "../edit_template/CoverLetterEditTemplateContainer";
import MyCoverLetterContainer from "../my_cover_letter/MyCoverLetterContainer";
import CoverLetterPreviewContainer from "../preview/CoverLetterPreviewContainer";
import CoverLetterSelectTemplateContainer from "../select_template/CoverLetterSelectTemplateContainer";
import AuthService from "../../../../services/AuthService";
import { updateAccessToken } from "../../../../apis/misc";

interface ICoverLetterLayoutComponentProps {
  location: any;
}

// interface ICoverLetterLayoutComponentState { }

// const initialState = {};

const CoverLetterLayoutComponent: React.FC<ICoverLetterLayoutComponentProps> = (
  props
) => {
  // const [coverLetterLayoutComponentState, setCoverLetterLayoutComponentState] = React.useState<ICoverLetterLayoutComponentState>(
  //   initialState
  // );

  const [active, setActive] = useState("Select template");
  const { path, url } = useRouteMatch();
  let history = useHistory();

  const titles = [
    "Select Template",
    "Edit Template",
    "Preview Cover Letter",
    "My Cover Letter",
  ];

  React.useEffect(() => {
    const c1 = props.location.pathname;
    if (c1.includes("/cover-letter/Select_Template")) {
      setActive("Select Template");
    } else if (c1.includes("/cover-letter/Edit_Template")) {
      setActive("Edit Template");
    } else if (c1.includes("/cover-letter/Preview_Cover")) {
      setActive("Preview Cover Letter");
    } else if (c1.includes("/cover-letter/My_Cover")) {
      setActive("My Cover Letter");
    }
  });

  let icons = new Map([
    [0, "fa fa-tachometer"],
    [1, "fa fa-file-text-o"],
    [2, "fa fa-file-text"],
    [3, "fa fa-files-o"],
  ]);

  const _titles = titles.map((e, i) => {
    var p = e.split(" ");
    return (
      <>
        <li
          key={i}
          onClick={() => {
            setActive(e);
          }}
          style={{ paddingTop: "10px" }}
        >
          <Link
            to={`${url}/${p[1] == undefined ? p[0] : p[0] + "_" + p[1]}`}
            className={e == active ? "active" : ""}
          >
            {" "}
            <i className={icons.get(i)} aria-hidden="true" />
            <div className="menus">{e} </div>{" "}
          </Link>
        </li>
      </>
    );
  });

  const back = () => {
    history.replace("/candidate/my-resume");
  };

  const onLogout = () => {
    updateAccessToken(
      {},
      `?id=${AuthService.currentUser?.id}&accessToken=${
        AuthService.accessToken
      }&login=${false}`
    )
      .then((res: any) => {
        //console.log("updated access token ", res.data);
      })
      .catch((err: any) => {
        //console.log("error in updating access token", err);
      });
    AuthService.logout();
    history.push("/");
  };

  return (
    <>
      <div id="wrapper">
        {/*======Left Menu Start=======*/}
        <div className="icon-mobile-nav">
          <a href="#">
            <i className="fa fa-2x fa-reorder" />
          </a>
        </div>
        <div className="menu_main">
          <div className="left-menu">
            <div className="dash_Left_arrow">
              <Link to="/candidate/my-resume">
                <i className="fa fa-2x fa-arrow-circle-left"></i>
              </Link>
            </div>
            <div className="dash_logo">
              <img
                src={require("./../../../../images/logo_dash.png")}
                // src="images/logo_dash.png"
                className="img-responsive center-block"
              />
            </div>
            <a href="#" className="btn resume_build_btn">
              Cover Letter
            </a>
            <aside
              className="bg-black dk nav_side-xs aside hidden-print"
              id="nav"
            >
              <section className="vbox" style={{ paddingTop: "40px" }}>
                <section className="w-f-md scrollable">
                  <div>
                    <div
                      data-height="auto"
                      data-disable-fade-out="true"
                      data-distance={0}
                      data-size="10px"
                      data-railopacity="0.2"
                    >
                      {/* nav */}
                      <div
                        id="divexample1"
                        style={{ overflow: "hidden" }}
                        tabIndex={0}
                      >
                        <nav className="nav_side-primary nav_pad">
                          <ul className="nav_side" data-ride="collapse">
                            {_titles}
                            <li style={{ paddingTop: "10px" }}>
                              <Link
                                to="/candidate"
                                // onClick={back}
                              >
                                <i
                                  className="fa fa-th-large"
                                  aria-hidden="true"
                                />
                                <div className="menus">Dashboard</div>
                              </Link>
                            </li>
                          </ul>
                          <ul
                            className="nav_side nav_side1"
                            data-ride="collapse"
                          >
                            <li>
                              <Link to="/help">
                                <i
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                                />
                                <div className="menus">Help</div>
                              </Link>
                            </li>
                            <li>
                              <a onClick={onLogout}>
                                {" "}
                                <i
                                  className="fa fa-power-off"
                                  aria-hidden="true"
                                />
                                <div className="menus">Log Out </div>{" "}
                              </a>
                            </li>
                          </ul>
                        </nav>
                        {/* / nav */}
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </aside>
          </div>
        </div>
        {/*======Left Menu End=======*/}
        {/*=================Content Section Start================*/}
        <Switch>
          <Route exact path={`${path}`}>
            <Redirect to={`${path}/Select_Template`} />
          </Route>
          <Route exact path={`${path}/Select_Template`}>
            <CoverLetterSelectTemplateContainer />
          </Route>
          <Route exact path={`${path}/Edit_Template`}>
            <CoverLetterEditTemplateContainer selectTemplateInfo={props} />
          </Route>
          <Route exact path={`${path}/Preview_Cover`}>
            <CoverLetterPreviewContainer selectTemplateInfo={props} />
          </Route>
          <Route exact path={`${path}/My_Cover`}>
            <MyCoverLetterContainer />
          </Route>
        </Switch>
        {/*=================Content Section End================*/}
      </div>
    </>
  );
};
export default withRouter(CoverLetterLayoutComponent);
