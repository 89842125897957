import { Tooltip } from "antd";
import React, { useState } from "react";
import {
  Redirect,
  useLocation,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import {
  GetAllAdminMyJobList,
  GetAllAdminMyJobListPub,
  GetAdminPermission,
  useAdminMyJobContext,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import {
  getCareerTipsApprovalList,
  useCareerTipsApprovalContext,
  useCareerTipsApprovalDispatcher,
} from "../../../action/admin/CareerTipsApprovalAction";
import {
  AdminJobListRequestModel,
  AdminPermissionRequestModel,
} from "../../../models/admin/AdminMyJob";
import AuthService from "../../../services/AuthService";
import { CareerTipsApprovalRequestModel } from "../../../models/admin/CareerTipsApproval";
import {
  getClientJobApprovalList,
  useClientJobApprovalContext,
  useClientJobApprovalDispatcher,
} from "../../../action/admin/ClientJobApprovalAction";
import { ClientJobApprovalRequestModel } from "../../../models/admin/ClientJobApproval";
import {
  getVendorApprovalList,
  getVendorStatusList,
  useVendorApprovalContext,
  useVendorApprovalDispatcher,
} from "../../../action/admin/VendorApprovalAction";
import {
  VendorApprovalRequestModel,
  VendorStatusListRequestModel,
} from "../../../models/admin/VendorApproval";
import {
  useAdminContext,
  useAdminProfileImageContext,
} from "../../../context/admin/AdminMyProfile";
import { useAdminMyProfileContext } from "../../../action/MyProfileAdminAction";
import { getAdmins } from "../../../apis/admin";
import { environment } from "../../../environments/environment";

const AdminLayoutSidebar = (props: any) => {
  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const {
    jobListInAdmin,
    jobListInAdminPub,
    AdminPermissionResult,
  } = adminMyJobContext;

  const careerTipsApprovalDispatcher = useCareerTipsApprovalDispatcher();
  const careerTipsApprovalContext = useCareerTipsApprovalContext();
  const {
    careerTipsApproval,
    careerTipsApprovalSaveRespond,
  } = careerTipsApprovalContext;

  const clientJobApprovalDispatcher = useClientJobApprovalDispatcher();
  const clientJobApprovalContext = useClientJobApprovalContext();
  const {
    clientJobApproval,
    clientJobApprovalSaveRespond,
  } = clientJobApprovalContext;

  const vendorApprovalDispatcher = useVendorApprovalDispatcher();
  const vendorApprovalContext = useVendorApprovalContext();
  const { vendorApproval, vendorApprovalSaveRespond } = vendorApprovalContext;

  const { path, url } = useRouteMatch();
  const authorizationToken = AuthService.accessToken;
  const user = AuthService.currentUser;
  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname != null ? location.pathname : "/admin"
  );

  const [currentWidth, setCurrentCurrentWidth] = useState<number>(
    window.innerWidth
  );

  const [menuHide, setMenuHide] = useState(false);
  const [name, setName] = useState("");

  const back = () => {
    //console.log(location);
    props.history.replace(location.pathname);
    return <Redirect to="/" />;
  };

  const navigation = (url: any) => {
    props.history.push(url);
    if (currentWidth < 650) {
      setMenuHide(!menuHide);
    }
  };

  const cl = props.location.pathname;

  const onLogout = () => {
    AuthService.logout();
    props.history.push("/");
  };

  const adminProfileImageContext = useAdminProfileImageContext();

  const { adminProfileImage, getAdminProfileImage } = adminProfileImageContext;

  const myProfileContext = useAdminMyProfileContext();
  const {
    myProfile,
    loggedAdminId,
    profileImage,
    basicInfo,
  } = myProfileContext;

  const adminId = loggedAdminId;

  const adminContext = useAdminContext();
  const { getAdmins, adminState } = adminContext;

  //console.log(2888, adminId);

  //console.log("adminProfileImage ", adminProfileImage);

  React.useEffect(() => {
    //console.log("adminlayoutsidebar outside if", adminId);
    if (adminId) {
      //console.log("adminlayoutsidebar inside if", adminId);
      getAdminProfileImage({
        AdminId: Number(adminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [adminId]);

  React.useEffect(() => {
    if (adminId)
      (async () => {
        await getAdmins({
          AdminId: Number(adminId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "",
          SortOrder: "",
          ShowInactive: false,
        });
      })();
  }, [adminId]);

  React.useEffect(() => {
    if (adminState.data != undefined && adminState.data.length > 0) {
      setName(adminState.data[0]["adminName"]);
    }
  }, [adminState]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await GetAllAdminMyJobList(
          adminMyJobDispatcher,
          {
            AdminId: loggedAdminId,
            Draftedjobsflag: true,
            JobStatus: 1,
            Page: 1,
            PageSize: 16,
            Publishedjobsflag: false,
            SearchTerm: "",
            SortOrder: "",
          } as AdminJobListRequestModel,
          authorizationToken
        );
      })();

      (async () => {
        await GetAllAdminMyJobListPub(
          adminMyJobDispatcher,
          {
            Draftedjobsflag: false,
            JobStatus: 1,
            Page: 1,
            PageSize: 16,
            Publishedjobsflag: true,
            SearchTerm: "",
            SortOrder: "",
          } as AdminJobListRequestModel,
          authorizationToken
        );
      })();

      (async () => {
        await getCareerTipsApprovalList(
          careerTipsApprovalDispatcher,
          {
            userId: 0,
            page: 1,
            pageSize: 60,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as CareerTipsApprovalRequestModel,
          authorizationToken
        );
      })();

      (async () => {
        await getClientJobApprovalList(
          clientJobApprovalDispatcher,
          {
            userId: 0,
            page: 1,
            pageSize: 60,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as ClientJobApprovalRequestModel,
          authorizationToken
        );
      })();

      (async () => {
        await getVendorStatusList(
          vendorApprovalDispatcher,
          {
            Page: 1,
            PageSize: 10,
            VendorStatusId: 2,
            ShowInactive: false,
            SortOrder: "",
            SearchTitle: "",
            SearchTerm: "",
          } as VendorStatusListRequestModel,
          authorizationToken
        );
      })();
    }
  }, [authorizationToken]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await GetAdminPermission(
          adminMyJobDispatcher,
          {
            AdminId: loggedAdminId,
          } as AdminPermissionRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedAdminId]);

  //console.log("GetAdminPermission - ", AdminPermissionResult);

  const text = (
    <ul className="nav dk text-sm" style={{}}>
      <li className={cl === "/admin/myJob/drafted" ? "active" : ""}>
        <a
          onClick={() => {
            navigation(`${url}/myJob/drafted`);
          }}
        >
          <i className="fa fa-angle-right text-xs" />
          <span>Drafted- </span>
          <b className="badge_m bg-dark pull-right">
            {jobListInAdmin.data != undefined
              ? jobListInAdmin.data.length
              : null}
          </b>{" "}
        </a>
      </li>
      <li className={cl === "/admin/myJob/published" ? "active" : ""}>
        <a
          onClick={() => {
            navigation(`${url}/myJob/published`);
          }}
        >
          {" "}
          <i className="fa fa-angle-right text-xs" /> <span>Published- </span>{" "}
          <b className="badge_m bg-dark pull-right">
            {" "}
            {jobListInAdminPub.data != undefined
              ? jobListInAdminPub.data.length
              : null}
          </b>
        </a>
      </li>
    </ul>
  );

  const text1 = (
    <ul className="nav dk text-sm" style={{}}>
      {AdminPermissionResult != undefined &&
      AdminPermissionResult.isVendorsRegistrationPermission ? (
        <li className={cl === "/admin/vendorapproval" ? "active" : ""}>
          <a
            onClick={() => {
              navigation(`${url}/vendorapproval`);
            }}
          >
            <i className="fa fa-angle-right text-xs" />
            <span>Venders Registration-</span>
            <b className="badge_m bg-dark pull-right">
              {vendorApproval.data != undefined
                ? vendorApproval.data.length
                : 0}
            </b>{" "}
          </a>
        </li>
      ) : null}
      {AdminPermissionResult != undefined &&
      AdminPermissionResult.isClientsjobpostPermission ? (
        <li className={cl === "/admin/clientjobapproval" ? "active" : ""}>
          <a
            onClick={() => {
              navigation(`${url}/clientjobapproval`);
            }}
          >
            {" "}
            <i className="fa fa-angle-right text-xs" />{" "}
            <span>Client Job Posts- </span>{" "}
            <b className="badge_m bg-dark pull-right">
              {" "}
              {clientJobApproval.data != undefined
                ? clientJobApproval.data.length
                : 0}
            </b>
          </a>
        </li>
      ) : null}
      {AdminPermissionResult != undefined &&
      AdminPermissionResult.isCareerTipsPermission ? (
        <li className={cl === "/admin/careertipsapproval" ? "active" : ""}>
          <a
            onClick={() => {
              navigation(`${url}/careertipsapproval`);
            }}
          >
            {" "}
            <i className="fa fa-angle-right text-xs" />{" "}
            <span>Career Tips- </span>{" "}
            <b className="badge_m bg-dark pull-right">
              {" "}
              {careerTipsApproval.data != undefined
                ? careerTipsApproval.data.length
                : 0}
            </b>
          </a>
        </li>
      ) : null}
    </ul>
  );

  const text2 = (
    <ul className="nav dk text-sm">
      {AdminPermissionResult != undefined &&
      AdminPermissionResult.isCreateorEditJobPermission ? (
        <li>
          <a
            onClick={() => {
              navigation(`${url}/communication/bulkSMS`);
            }}
          >
            {" "}
            <i className="fa fa-angle-right text-xs" />{" "}
            <span>Bulk Sent SMS</span>{" "}
          </a>
        </li>
      ) : null}
      {AdminPermissionResult != undefined &&
      AdminPermissionResult.isCreateorEditJobPermission ? (
        <li>
          <a
            onClick={() => {
              navigation(`${url}/communication/bulkEmail`);
            }}
          >
            {" "}
            <i className="fa fa-angle-right text-xs" />{" "}
            <span>Bulk Sent Email</span>{" "}
          </a>
        </li>
      ) : null}
      <li>
        <a
          onClick={() => {
            navigation(`${url}/communication/messages`);
          }}
        >
          {" "}
          <i className="fa fa-angle-right text-xs" /> <span>Messages</span>
          {/* <b className="badge_m bg-dark pull-right">8</b> */}
        </a>
      </li>
      <li>
        <a
          onClick={() => {
            navigation(`${url}/communication/contactUs`);
          }}
        >
          {" "}
          <i className="fa fa-angle-right text-xs" />{" "}
          <span>Contact us Queries</span>
          {/* <b className="badge_m bg-dark pull-right">8</b> */}
        </a>
      </li>
    </ul>
  );

  const text4 = (
    <ul className="nav dk text-sm" style={{}}>
      <li className={cl === "/admin/report/client" ? "active" : ""}>
        <a
          onClick={() => {
            navigation(`${url}/report/client`);
          }}
        >
          {" "}
          <i className="fa fa-angle-right text-xs" /> <span>Client Report</span>{" "}
        </a>
      </li>

      <li className={cl === "/admin/report/vendor" ? "active" : ""}>
        <a
          onClick={() => {
            navigation(`${url}/report/vendor`);
          }}
        >
          <i className="fa fa-angle-right text-xs" />
          <span>Vendor Report</span>
        </a>
      </li>
    </ul>
  );

  const text3 = (
    <ul className="nav dk text-sm" style={{}}>
      {AdminPermissionResult != undefined &&
      AdminPermissionResult.isMyTeamPermission ? (
        <li
          className={cl === "/admin/sitesettings/myteammember" ? "active" : ""}
        >
          <a
            onClick={() => {
              navigation(`${url}/myteammember`);
            }}
          >
            <i className="fa fa-angle-right text-xs" />
            <span>My Team </span>{" "}
          </a>
        </li>
      ) : null}
      {AdminPermissionResult != undefined &&
      AdminPermissionResult.isStoriesofJIT ? (
        <li className={cl === "/admin/sitesettings/story" ? "active" : ""}>
          <a
            onClick={() => {
              navigation(`${url}/story`);
            }}
          >
            <i className="fa fa-angle-right text-xs" />
            <span>Stories of JIT</span>{" "}
          </a>
        </li>
      ) : null}
      {AdminPermissionResult != undefined &&
      AdminPermissionResult.isCompanyContactsandSocialPermission ? (
        <li
          className={
            cl === "/admin/sitesettings/socialaccounturl" ? "active" : ""
          }
        >
          <a
            onClick={() => {
              navigation(`${url}/socialaccounturl`);
            }}
          >
            <i className="fa fa-angle-right text-xs" />
            <span>Company Contacts and Social Media</span>{" "}
          </a>
        </li>
      ) : null}
      {AdminPermissionResult != undefined &&
      AdminPermissionResult.isAchievementPermission ? (
        <li
          className={cl === "/admin/sitesettings/achievements" ? "active" : ""}
        >
          <a
            onClick={() => {
              navigation(`${url}/achievements`);
            }}
          >
            <i className="fa fa-angle-right text-xs" />
            <span>Achievements</span>{" "}
          </a>
        </li>
      ) : null}
      {AdminPermissionResult != undefined &&
      AdminPermissionResult.isAddJobCategoryPermission ? (
        <li
          className={cl === "/admin/sitesettings/jobCategory" ? "active" : ""}
        >
          <a
            onClick={() => {
              navigation(`${url}/sitesettings/jobCategory`);
            }}
          >
            <i className="fa fa-angle-right text-xs" />
            <span>Category / Job Title</span>{" "}
          </a>
        </li>
      ) : null}
      <li>
        {" "}
        <a
          onClick={() => {
            navigation(`${url}/banner`);
          }}
        >
          <i className="fa fa-angle-right text-xs" />
          <span>Banner</span>{" "}
        </a>
      </li>
      <li>
        {" "}
        <a
          onClick={() => {
            navigation(`${url}/google_slide_url`);
          }}
        >
          <i className="fa fa-angle-right text-xs" />
          <span>Google Slide Url</span>{" "}
        </a>
      </li>
    </ul>
  );

  const text11 = (
    <ul className="nav dk text-sm" style={{}}>
      <li className={cl === "/admin/my_user" ? "active" : ""}>
        <a
          onClick={() => {
            navigation(`${url}/my_user`);
          }}
        >
          <i className="fa fa-angle-right text-xs" />
          <span>Users </span>
          {/* <b className="badge_m bg-dark pull-right">
            {jobListInAdmin.data != undefined
              ? jobListInAdmin.data.length
              : null}
          </b>{" "} */}
        </a>
      </li>
      <li className={cl === "/admin/clientJob" ? "active" : ""}>
        <a
          onClick={() => {
            navigation(`${url}/clientJob`);
          }}
        >
          {" "}
          <i className="fa fa-angle-right text-xs" /> <span>Client Jobs</span>{" "}
          {/* <b className="badge_m bg-dark pull-right">
            {" "}
            {jobListInAdminPub.data != undefined
              ? jobListInAdminPub.data.length
              : null}
          </b> */}
        </a>
      </li>
      <li className={cl === "/admin/ResourceAvailability" ? "active" : ""}>
        <a
          onClick={() => {
            navigation(`${url}/ResourceAvailability`);
          }}
        >
          {" "}
          <i className="fa fa-angle-right text-xs" />{" "}
          <span>Resource Availability </span>{" "}
          {/* <b className="badge_m bg-dark pull-right">
            {" "}
            {jobListInAdminPub.data != undefined
              ? jobListInAdminPub.data.length
              : null}
          </b> */}
        </a>
      </li>
    </ul>
  );

  return (
    <>
      {/*======Left Menu Start=======*/}
      <div className="icon-mobile-nav">
        <a
          onClick={() => {
            setMenuHide(!menuHide);
          }}
        >
          <i className="fa fa-2x fa-reorder" />
        </a>
      </div>
      <div className="menu_main">
        <div
          className={`left-menu-admin admin_menu  ${
            menuHide ? "hide-menu" : ""
          }`}
        >
          <div className="dash_logo">
            <img
              src={require("../../../images/logo_dash.png")}
              // src="images/logo_dash.png"
              className="img-responsive center-block"
            />
          </div>
          <div className="dash_profile">
            {adminProfileImage.data.length > 0 ? (
              <img
                className="img-responsive"
                src={
                  environment.baseUrl +
                  `/Upload/AdminProfileImage/${adminProfileImage.data[0]["imageUrl"]}`
                }
                alt=""
              />
            ) : (
              <img
                className="img-responsive"
                src={require("./../../../images/profileDefault1.jpg")}
              ></img>
            )}
          </div>
          <div className="dash_profile_name">
            {adminState.data != undefined && adminState.data.length > 0
              ? adminState.data[0]["adminName"]
              : ""}
            {/* {basicInfo.fullName != undefined ? basicInfo.fullName : ""} */}
          </div>
          <div className="dash_profile_admin">Admin</div>
          <aside
            className="bg-black dk adminclr nav_side-xs aside hidden-print"
            id="nav"
          >
            <section className="vbox">
              <section className="w-f-md scrollable">
                <div>
                  <div
                    data-height="auto"
                    data-disable-fade-out="true"
                    data-distance={0}
                    data-size="10px"
                    data-railopacity="0.2"
                  >
                    {/* nav */}
                    <div
                      id="divexample1"
                      style={{ overflow: "hidden" }}
                      tabIndex={0}
                    >
                      <nav className="nav_side-primary nav_pad">
                        <ul className="nav_side" data-ride="collapse">
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}`);
                              }}
                              className={
                                cl === "/admin"
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-th-large"
                                aria-hidden="true"
                              />
                              <div className="menus">Dashboard </div>
                            </a>
                          </li>
                          <Tooltip
                            placement="rightTop"
                            color={"#0d4e77"}
                            title={text}
                          >
                            <li className="">
                              <a
                                // onClick={() => {
                                //   navigation(`${url}/myJob`);
                                // }}
                                className={
                                  cl.includes("/admin/myJob/drafted") ||
                                  cl.includes("/admin/myJob/published")
                                    ? "active _cursor-pointer"
                                    : "_cursor-pointer"
                                }
                              >
                                {" "}
                                <i
                                  className="fa fa-briefcase"
                                  aria-hidden="true"
                                />
                                <div className="menus">My jobs</div>
                                {text}
                              </a>
                            </li>
                          </Tooltip>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/candidate_search/0`);
                              }}
                              className={
                                cl.includes("/admin/candidate_search")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-user-o" aria-hidden="true" />
                              <div className="menus">Candidate Search</div>
                            </a>
                          </li>
                          <Tooltip
                            placement="rightTop"
                            color={"#0d4e77"}
                            title={text11}
                          >
                            <li>
                              <a
                                // onClick={() => {
                                //   navigation(`${url}/my_user`);
                                // }}
                                className={
                                  cl.includes("/admin/my_user") ||
                                  cl.includes("/admin/ResourceAvailability")
                                    ? "active _cursor-pointer"
                                    : "_cursor-pointer"
                                }
                              >
                                <i className="fa fa-users" aria-hidden="true" />
                                <div className="menus">Clients</div>
                                {text11}
                              </a>
                            </li>
                          </Tooltip>
                          <Tooltip
                            placement="rightTop"
                            color={"#0d4e77"}
                            title={text2}
                          >
                            <li>
                              <a
                                // onClick={() => {
                                //   navigation(`${url}/communication`);
                                // }}
                                className={
                                  cl.includes("/admin/communication/bulkSMS") ||
                                  cl.includes(
                                    "/admin/communication/bulkEmail"
                                  ) ||
                                  cl.includes(
                                    "/admin/communication/contactUs"
                                  ) ||
                                  cl.includes("/admin/communication/messages")
                                    ? "active _cursor-pointer"
                                    : "_cursor-pointer"
                                }
                              >
                                <i
                                  className="fa fa-commenting-o"
                                  aria-hidden="true"
                                />
                                <div className="menus">Communication</div>
                                {text2}
                              </a>
                            </li>
                          </Tooltip>

                          <Tooltip
                            placement="rightTop"
                            color={"#0d4e77"}
                            title={text4}
                          >
                            <li>
                              <a
                                onClick={() => {
                                  navigation(`${url}/report/vendor`);
                                }}
                                className={
                                  cl === "/admin/report/vendor"
                                    ? "active _cursor-pointer"
                                    : "_cursor-pointer"
                                }
                              >
                                {" "}
                                <i
                                  className="fa fa-files-o"
                                  aria-hidden="true"
                                />
                                <div className="menus">Report </div>
                              </a>
                            </li>
                          </Tooltip>

                          {/* <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/my_user`);
                              }}
                              className={
                                cl.includes("/super_admin/my_user")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-users" aria-hidden="true" />
                              <div className="menus">My Users</div>
                            </a>
                          </li>
                          <li> */}
                          {/* <a
                              onClick={() => {
                                navigation(`${url}/messages`);
                              }}
                              className={
                                cl.includes("/super_admin/messages")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-commenting-o"
                                aria-hidden="true"
                              />
                              <div className="menus">Messaging</div>
                            </a>
                          </li> */}
                          {/* <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/database`);
                              }}
                              className={
                                cl.includes("/super_admin/database")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-database"
                                aria-hidden="true"
                              />
                              <div className="menus">Database</div>
                            </a>
                          </li> */}
                          {AdminPermissionResult != undefined &&
                          (AdminPermissionResult.isVendorsRegistrationPermission ||
                            AdminPermissionResult.isClientsjobpostPermission ||
                            AdminPermissionResult.isCareerTipsPermission) ? (
                            <Tooltip
                              placement="rightTop"
                              color={"#0d4e77"}
                              title={text1}
                            >
                              <li className="">
                                <a
                                  // onClick={() => {
                                  //   navigation(`${url}/myJob`);
                                  // }}
                                  className={
                                    cl.includes("/admin/vendorapproval") ||
                                    cl.includes("/admin/clientjobapproval") ||
                                    cl.includes("/admin/careertipsapproval")
                                      ? "active _cursor-pointer"
                                      : "_cursor-pointer"
                                  }
                                >
                                  {" "}
                                  <i
                                    className="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                  />
                                  <div className="menus">Approvals</div>
                                  {text}
                                </a>
                              </li>
                            </Tooltip>
                          ) : null}
                          {AdminPermissionResult != undefined &&
                          (AdminPermissionResult.isMyTeamPermission ||
                            AdminPermissionResult.isStoriesofJIT ||
                            AdminPermissionResult.isCompanyContactsandSocialPermission ||
                            AdminPermissionResult.isAchievementPermission ||
                            AdminPermissionResult.isAddJobCategoryPermission) ? (
                            <Tooltip
                              placement="rightTop"
                              color={"#0d4e77"}
                              title={text3}
                            >
                              <li className="">
                                <a
                                  // onClick={() => {
                                  //   navigation(`${url}/myJob`);
                                  // }}
                                  className={
                                    cl.includes(
                                      "/admin/sitesettings/myteammember"
                                    ) ||
                                    cl.includes("/admin/sitesettings/story") ||
                                    cl.includes(
                                      "/admin/sitesettings/socialaccounturl"
                                    ) ||
                                    cl.includes(
                                      "/admin/sitesettings/achievements"
                                    ) ||
                                    cl.includes(
                                      "/admin/sitesettings/jobCategory"
                                    )
                                      ? "active _cursor-pointer"
                                      : "_cursor-pointer"
                                  }
                                >
                                  {" "}
                                  <i className="fa fa-cog" aria-hidden="true" />
                                  <div className="menus">Site Settings</div>
                                  {text}
                                </a>
                              </li>
                            </Tooltip>
                          ) : null}
                          {/* <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/careertipsapproval`);
                              }}
                              className={
                                cl.includes("/admin/careertipsapproval")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-files-o" aria-hidden="true" />
                              <div className="menus">Career Tips</div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/vendorapproval`);
                              }}
                              className={
                                cl.includes("/admin/vendorapproval")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-files-o" aria-hidden="true" />
                              <div className="menus">Vendor Approval</div>
                            </a>
                          </li>
                           <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/clientjobapproval`);
                              }}
                              className={
                                cl.includes("/admin/clientjobapproval")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-files-o" aria-hidden="true" />
                              <div className="menus">Client Job Approval</div>
                            </a>
                          </li>
                           */}
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/careerdeveloper`);
                              }}
                              className={
                                cl.includes("/admin/careerdeveloper")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              <i
                                className="fa fa-files-o"
                                aria-hidden="true"
                              ></i>
                              <div className="menus">Career Developer</div>
                            </a>
                          </li>

                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/my-profile`);
                              }}
                              className={
                                cl.includes("/admin/my-profile")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-user-o" aria-hidden="true" />
                              <div className="menus">Profile</div>
                            </a>
                          </li>
                          <li>
                            <a onClick={onLogout}>
                              {" "}
                              <i
                                className="fa fa-power-off"
                                aria-hidden="true"
                              />
                              <div className="menus">Log Out </div>{" "}
                            </a>
                          </li>
                        </ul>

                        {/* <ul className="nav_side nav_side1" data-ride="collapse">
                          <li>
                            <a onClick={onLogout}>
                              {" "}
                              <i
                                className="fa fa-power-off"
                                aria-hidden="true"
                              />
                              <div className="menus">Log Out </div>{" "}
                            </a>
                          </li>
                        </ul>
                       */}
                      </nav>
                      {/* / nav */}
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </aside>
        </div>
      </div>

      {/*======Left Menu End=======*/}
    </>
  );
};

export default withRouter(AdminLayoutSidebar);
