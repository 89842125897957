import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  VendorApprovalRequestModel,
  VendorApproval,
  VendorApprovalViewModel,
  VendorApprovalSaveRequestModel,
  VendorApprovalSaveRespondModel,
  VendorEditRequestModel,
  VendorResultModel,
  VendorGetDataWithId,
  VendorSelectBoxDataViewModel,
  VendorStatusListResultModel,
  VendorStatusListRequestModel,
} from "../../models/admin/VendorApproval";
import {
  IVendorApprovalManagementState,
  VendorApprovalDispatcherContext,
  VendorApprovalStateContext,
} from "../../context/admin/VendorApprovalContext";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IVendorApprovalAction =
  | {
      type: "VENDORAPPROVAL_LIST";
      vendorApproval: VendorApprovalViewModel;
    }
  | {
      type: "VENDOR_SELECTBOX_LIST";
      VendorSelectBoxDataViewModel: VendorSelectBoxDataViewModel;
    }
  | {
      type: "GET_VENDOR";
      VendorResultModel: VendorResultModel;
    }
  | {
      type: "EDIT_VENDOR";
      vendorApprovalSaveRespond: VendorApprovalSaveRespondModel;
    }
  | {
      type: "SET_VENDOR_STATUS";
      vendorApprovalSaveRespond: VendorApprovalSaveRespondModel;
    }
  | {
      type: "GET_VENDOR_STATUS_LIST";
      VendorStatusListResult: VendorStatusListResultModel;
    };

export const vendorApprovalReducer: Reducer<
  IVendorApprovalManagementState,
  IVendorApprovalAction
> = (draft, action): IVendorApprovalManagementState => {
  switch (action.type) {
    case "VENDORAPPROVAL_LIST":
      draft.vendorApproval = action.vendorApproval;
      return draft;
    case "GET_VENDOR":
      draft.VendorResultModel = action.VendorResultModel;
      return draft;
    case "EDIT_VENDOR":
      draft.vendorApprovalSaveRespond = action.vendorApprovalSaveRespond;
      return draft;
    case "SET_VENDOR_STATUS":
      draft.vendorApprovalSaveRespond = action.vendorApprovalSaveRespond;
      return draft;
    case "VENDOR_SELECTBOX_LIST":
      draft.VendorSelectBoxDataViewModel = action.VendorSelectBoxDataViewModel;
      return draft;
    case "GET_VENDOR_STATUS_LIST":
      draft.VendorStatusListResult = action.VendorStatusListResult;
      return draft;
  }
};

export const useVendorApprovalDispatcher = (): React.Dispatch<IVendorApprovalAction> => {
  const vendorApprovalDispatcher = React.useContext(
    VendorApprovalDispatcherContext
  );
  if (!vendorApprovalDispatcher) {
    throw new Error(
      "You have to provide the VendorApproval dispatcher using theVendorApprovalDispatcherContext.Provider in a parent component."
    );
  }
  return vendorApprovalDispatcher;
};

export const useVendorApprovalContext = (): IVendorApprovalManagementState => {
  const vendorApprovalContext = React.useContext(VendorApprovalStateContext);
  if (!vendorApprovalContext)
    throw new Error(
      "You have to provide the vendorApproval context using the VendorApprovalStateContext.Provider in a parent component."
    );
  return vendorApprovalContext;
};

export const getVendorApprovalList = async (
  dispatcher: React.Dispatch<IVendorApprovalAction>,
  query: VendorApprovalRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetVendorList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "VENDORAPPROVAL_LIST", vendorApproval: res.data });
    });
  } catch (e) {}
};

export const setVendorStatus = async (
  dispatcher: React.Dispatch<IVendorApprovalAction>,
  query: VendorApprovalSaveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditVendorStatus;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_VENDOR_STATUS",
        vendorApprovalSaveRespond: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Vendor document status updated.")
          : message.error(res.data.message)
        : message.error(
            " some error happend on updating vendor document status."
          );
    });
  } catch (e) {}
};

export const editVendor = async (
  dispatcher: React.Dispatch<IVendorApprovalAction>,
  query: VendorEditRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditVendor;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_VENDOR",
        vendorApprovalSaveRespond: res.data,
      });

      res.status == 200
        ? message.success("Vendor document status is updated.")
        : message.error(
            "There are some error happend on updating vendor document status."
          );
    });
  } catch (e) {}
};

export const getVendor = async (
  dispatcher: React.Dispatch<IVendorApprovalAction>,
  query: VendorGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetVendor;
    if (query.rowId != 0) {
      axios
        .post(url, JSON.stringify(query), { headers: header })
        .then((res) => {
          dispatcher({
            type: "GET_VENDOR",
            VendorResultModel: res.data,
          });
        });
    } else {
      dispatcher({
        type: "GET_VENDOR",
        VendorResultModel: {} as VendorResultModel,
      });
    }
  } catch (e) {}
};

export const getVendorSelectBoxList = async (
  dispatcher: React.Dispatch<IVendorApprovalAction>,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetVendorSelectBoxData;

    axios.post(url, JSON.stringify({}), { headers: header }).then((res) => {
      dispatcher({
        type: "VENDOR_SELECTBOX_LIST",
        VendorSelectBoxDataViewModel: res.data,
      });
    });
  } catch (e) {}
};

export const getVendorStatusList = async (
  dispatcher: React.Dispatch<IVendorApprovalAction>,
  query: VendorStatusListRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetVendorJobStatusList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_VENDOR_STATUS_LIST",
        VendorStatusListResult: res.data,
      });
    });
  } catch (e) {}
};
