import { Reducer } from "react";

import React from "react";

import axios from "axios";
import AuthService from "../../services/AuthService";
import {
  newlyPostedJobRequestModel,
  newlyPostedJobViewModel,
} from "../../models/general/NewlyPostedJob";
import { AppUrls } from "../../environments/environment";
import {
  INewlyPostedJobManagementState,
  NewlyPostedJobDispatcherContext,
  NewlyPostedJobStateContext,
} from "../../context/general/NewlyPostedJobContext";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type INewlyPostedJobAction = {
  type: "SET_Job Applied_LIST";
  newlyPostedJob: newlyPostedJobViewModel;
  visible: boolean;
  //  }
  //   |{
  //   type:"VALUE_CHANGE",
  //   value:newlyPostedJobViewModel
};

export const newlyPostedJobReducer: Reducer<
  INewlyPostedJobManagementState,
  INewlyPostedJobAction
> = (draft, action): INewlyPostedJobManagementState => {
  switch (action.type) {
    case "SET_Job Applied_LIST":
      draft.newlyPostedJob = action.newlyPostedJob;
      draft.visible = action.visible;
      return draft;
  }
};

export const useNewlyPostedJobDispatcher = (): React.Dispatch<INewlyPostedJobAction> => {
  const newlyPostedJobDispatcher = React.useContext(
    NewlyPostedJobDispatcherContext
  );
  if (!newlyPostedJobDispatcher) {
    throw new Error(
      "You have to provide the NewlyPostedJob dispatcher using theNewlyPostedJobDispatcherContext.Provider in a parent component."
    );
  }
  return newlyPostedJobDispatcher;
};

export const useNewlyPostedJobContext = (): INewlyPostedJobManagementState => {
  const newlyPostedJobContext = React.useContext(NewlyPostedJobStateContext);
  if (!newlyPostedJobContext)
    throw new Error(
      "You have to provide the newlyPostedJob context using the NewlyPostedJobStateContext.Provider in a parent component."
    );
  return newlyPostedJobContext;
};

export const getNewlyPostedJobList = async (
  dispatcher: React.Dispatch<INewlyPostedJobAction>,
  query: newlyPostedJobRequestModel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorizationToken}`,
    };

    const url = AppUrls.GetAllNewlyPostedJob;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      //console.log(113,res)
      dispatcher({
        type: "SET_Job Applied_LIST",
        newlyPostedJob: res.data,
        visible: true,
      });
    });
  } catch (e) {}
};
