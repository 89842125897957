import { ErrorMessage } from "@hookform/error-message";
import { Col, Popconfirm, Popover, Progress, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  SaveDashBoard,
  useDashboardDispatcher,
} from "../../../action/candidate/DashboardsAction";
import {
  deleteJobAlertList,
  getAllJobAlertList,
  getTitleWithCategoryId,
  setJobAlertSave,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import {
  saveBookMarkList,
  useJobBookMarkContext,
  useJobBookMarkDispatcher,
} from "../../../action/candidate/JobBookMarkAction";
import {
  getAllCount,
  useJobTypeContext,
  useJobTypeDispatcher,
} from "../../../action/general/JobTypeAction";
import { useJobAppliedContext } from "../../../action/JobAppliedAction";
import {
  getJobCategoryList,
  useJobCategoryDispatcher,
} from "../../../action/JobCategoryAction";
import {
  getMatchedJobList,
  useMatchedJobContext,
  useMatchedJobDispatcher,
} from "../../../action/MatchedJobAction";
import {
  getLoggedUserId,
  getMyProfileSelectBoxList,
  getProfileProgressBarResult,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import {
  getAllCoverLetterCandidateMap,
  getAllResumeCandidateMap,
} from "../../../apis/resumebuilder";
import {
  useCandidateContext,
  useNotificationListContext,
} from "../../../context/candidate/CandidateMyProfile";
import { jobBookMarkSaveRequestModel } from "../../../models/candidate/BookMarkedJob";
import { DashboardSaveRequest } from "../../../models/candidate/Dashboard";
import { digiLockerGetDataWithId } from "../../../models/candidate/DigiLocker";
import {
  jobAlertGellAllRequestModel,
  jobAlertGetAllModel,
  jobAlertSaveRequestModel,
  jobAlertTitleDropdownResultModel,
} from "../../../models/candidate/JobAlert";
import { categoryWithId } from "../../../models/candidate/JobCategory";
import {
  matchedJob,
  matchedJobRequestModel,
} from "../../../models/candidate/MatchedJob";
import { ResumeAllList } from "../../../models/candidate/Resume";
import { countAllRequestModel } from "../../../models/general/JobType";
import AuthService from "../../../services/AuthService";
import JobAppliedForm from "../jobs_Applied/JobAppliedForm";
import SelectOption from "../my_profile/components/SelectOption";
import { getMessageDashboardList } from "../../../apis/vendor";
import { useDigiLockerContext } from "../../../action/candidate/DigiLockerAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { profileImageRequestModel } from "../../../models/candidate/MyProfileSelectBoxData";
import { CoverletterDataResult } from "../../../models/candidate/CoverLetter";
import CopyToClipboard from "react-copy-to-clipboard";

interface ReactSelectOption {
  value: string;
  label: string;
}
interface ICandidateDashboardComponentProps {}

interface ICandidateDashboardComponentState {
  jobCategorys: string;
  jobTypeData: string;
  location: string;
  tempData: jobAlertTitleDropdownResultModel[];
  carouselValue: number;
  MatchedJobDetails: matchedJob;
  currentJobId: number;
  filterFlag: boolean;
  updated: boolean;
  // tempData: jobAlertTitleDropdownResult[];
  countNext: number;
}

const defaultValues = {
  location: "",
  jobTypeData: "",
  jobCategorys: "",
  countNext: 0,
  carouselValue: 0,
  MatchedJobDetails: {} as matchedJob,
  currentJobId: 0,
  filterFlag: true,
  updated: false,
  tempData: [],
};
const authorizationToken = AuthService.accessToken;

const CandidateDashboardComponent: React.FC<ICandidateDashboardComponentProps> = (
  props
) => {
  const [
    CandidateDashboardComponentState,
    setCandidateDashboardComponentState,
  ] = React.useState<ICandidateDashboardComponentState>(defaultValues);

  let jobCategoryData: ReactSelectOption[] = [];
  // let jobTypeDatas: ReactSelectOption[] = [];
  const jobTypeDatas = [
    { value: "1", label: "Permanent" },
    { value: "2", label: "Temporary" },
    { value: "3", label: "Freelancer" },
    { value: "4", label: "Any" },
  ];
  let jobTitleData: ReactSelectOption[] = [];
  const [list, setList] = useState();
  const [messageList, setMessageList] = useState();
  const [messageListFlag, setMessageListFlag] = useState(0);
  const [listFlag, setListFlag] = useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const {
    location,
    currentJobId,
    filterFlag,
    tempData,
  } = CandidateDashboardComponentState;

  const jobCategoryDispatcher = useJobCategoryDispatcher();
  // const jobCategoryContext = useJobCategoryContext();
  // const { jobCategory } = jobCategoryContext;
  const jobTypeDispatcher = useJobTypeDispatcher();
  const matchedJobDispatcher = useMatchedJobDispatcher();
  const matchedJobContext = useMatchedJobContext();
  const { matchedJob } = matchedJobContext;

  const [jobAlertResultDataSate, setobAlertResultDataState] = useState<
    jobAlertGetAllModel[]
  >([]);

  const jobAlertDispatcher = useJobAlertDispatcher();
  const jobAlertContext = useJobAlertContext();
  const {
    jobAlert,
    jobAlertGetAll,
    jobAlertSaveRespond,
    jobAlertTitleDropdownResult,
    JobAlertRoleMapSaveRespond,
  } = jobAlertContext;

  const digiLockerMarkContext = useDigiLockerContext();
  const {
    digiLockerType,
    digiLockerExpiringDocument,
    digiLockersNewList,
    digiLockerSaveRespond,
    digiLockerDetails,
    digiLockerDeletRespond,
    documentMoveResultModel,
    documentSharedResult,
  } = digiLockerMarkContext;

  const myProfileContext = useMyProfileContext();
  const { loggedUserId, myProfile, myProfileProgressBar } = myProfileContext;

  const jobBookMarkContext = useJobBookMarkContext();
  const { jobBookMarkSaveRespond } = jobBookMarkContext;
  const myProfileDispatcher = useMyProfileDispatcher();
  const jobAppliedContext = useJobAppliedContext();
  const { jobAppliedSaveResultData } = jobAppliedContext;

  const candidateContext = useCandidateContext();
  const { getCandidates, candidateState } = candidateContext;

  const notificationListContext = useNotificationListContext();
  const { notificationList, getNotificationList } = notificationListContext;
  const [currentAlertId, setcurrentAlertId] = useState<number>(0);
  const [candidateResumes, setCandidateResumes] = useState({} as ResumeAllList);
  const [candidateCoverLetters, setCandidateCoverLetters] = useState(
    {} as CoverletterDataResult
  );
  const [loggedUserIdState, setLoggedUserIdState] = useState<number>(0);

  let history = useHistory();
  let user = AuthService.currentUser;

  // const handleCompleteClick = () => {
  //   history.push("/my-profile");
  // };

  const jobTypeContext = useJobTypeContext();
  const { allCounts } = jobTypeContext;

  console.log("loggedUserId", loggedUserId);
  console.log("allCounts", allCounts);

  // const jobAppliedDispatcher = useJobAppliedDispatcher();
  const jobBookMarkDispatcher = useJobBookMarkDispatcher();

  const dashboardDispatcher = useDashboardDispatcher();

  const [isCustomStyleOpen, setIsCustomStyleOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  // const [isScrolling, setIsScrolling] = useState<boolean>(true);
  const [currentPage, setPageIndexforMotchedJob] = useState<number>(1);
  const [pageIndexforMotchedJob, setCurrentPage] = useState<number>(1);
  const [currentPageJobAlert, setCurrentPageJobAlert] = useState<number>(1);
  const [isLoder, setISLoder] = useState<boolean>(false);
  const [dashboardLayoutValue, setDashboardLayoutValue] = useState<number>(10);
  const { carouselValue, countNext } = CandidateDashboardComponentState;
  const [
    currentPageOfDigiLockerList,
    setCurrentPageOfDigiLockerList,
  ] = useState(1);
  const [currentPageOfExpDocList, setCurrentPageOfExpDocList] = useState(1);
  // const [currentId, setCurrentId] = useState(1);
  const [name, setName] = useState("");
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const watchAllFields = watch2();
  console.log(3434, watchAllFields);
  console.log(3535, jobAlertTitleDropdownResult);
  console.log(3636, parseInt(watchAllFields.jobCategorys));
  console.log(3737, tempData);
  const [isJobAppliedOpen, setIsJobAppliedOpen] = useState<boolean>(false);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getMyProfileSelectBoxList(myProfileDispatcher);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null)
      (async () => {
        await getLoggedUserId(
          myProfileDispatcher,
          parseInt(user?.id),
          authorizationToken
        );
      })();
  }, [authorizationToken]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getProfileProgressBarResult(
          myProfileDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as profileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getProfileProgressBarResult(
          myProfileDispatcher,
          {
            candidateId: loggedUserId,
            page: pageIndexforMotchedJob,
            pageSize: 30,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as profileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (loggedUserId > 0) {
      setLoggedUserIdState(loggedUserId);
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (
      loggedUserId > 0
      // &&
      // authorizationToken != null &&
      // authorizationToken != undefined
    ) {
      getMessageDashboardList({
        UserId: Number(AuthService.currentUser?.id),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      })
        .then((res) => {
          console.log("Dashboard message ", res);

          setMessageList(
            res.data.data.map((item: any, index: any) => {
              if (item["unReadCount"] > 0) {
                setMessageListFlag(1);
                return (
                  <div className="message_sec1">
                    {/* <Link to="candidate/messages"> */}
                    <Link
                      to={{
                        pathname: "candidate/messages",
                        state: item,
                      }}
                    >
                      <span className="message_icon">
                        <img
                          src={
                            item["imageUrl"] !== "null" &&
                            item["imageUrl"] !== null &&
                            item["imageUrl"] !== undefined
                              ? `https://careerapp.azurewebsites.net/Upload/AdminProfileImage/${item["imageUrl"]}`
                              : require("./../../../images/profileDefault1.jpg")
                          }
                          className="img-responsive"
                        />
                      </span>
                      <div className="message_con_vend">
                        <div className="message_head">{item["userName"]}</div>
                        <div className="message_pra">{item["message"]}</div>
                      </div>
                      <div className="message_noti">
                        <div className="message_noti_con">
                          {item["daysAgo"]} day ago
                        </div>
                        <div className="message_noti_count">
                          {item["unReadCount"]}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })
          );
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    console.log(loggedUserId, " notificationList0 ", authorizationToken);
    if (
      loggedUserId != 0
      //  &&
      // authorizationToken != null
    ) {
      console.log(loggedUserId, " notificationList0 ", authorizationToken);
      getNotificationList({
        CandidateId: loggedUserId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      });
      console.log(
        loggedUserId,
        " notificationList0 ",
        notificationList,
        " ",
        authorizationToken
      );
    }
  }, [loggedUserId]);

  useEffect(() => {
    if (candidateId) {
      getAllResumeCandidateMap({
        CandidateId: candidateId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          //console.log(res.data);
          setCandidateResumes(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [loggedUserId]);

  useEffect(() => {
    if (candidateId) {
      getAllCoverLetterCandidateMap({
        CandidateId: candidateId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          //console.log(res.data);
          setCandidateCoverLetters(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, [loggedUserId]);

  console.log("loggedUserId ", loggedUserId);
  console.log("notificationList ", notificationList);

  React.useEffect(() => {
    if (
      notificationList != null &&
      notificationList != undefined &&
      // authorizationToken != null &&
      // authorizationToken != undefined &&
      loggedUserId > 0
    ) {
      if (notificationList.data != undefined) {
        console.log(
          loggedUserId,
          " notificationList1 ",
          notificationList,
          " ",
          authorizationToken
        );

        setList(
          notificationList.data.map((item: String) => {
            setListFlag(1);
            if (item.includes("Profile") || item.includes("Password")) {
              return (
                <div className="notification_sec">
                  <Link to="candidate/my-profile">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.includes("applied")) {
              return (
                <div className="notification_sec">
                  <Link to="candidate/jobs-applied">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.includes("Matched")) {
              return (
                <div className="notification_sec">
                  <a onClick={() => handleScrollClick()}>
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </a>
                </div>
              );
            } else if (item.includes("Digi")) {
              return (
                <div className="notification_sec">
                  <Link to="candidate/digilocker/3">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else {
              return (
                <div className="notification_sec">
                  <Link to="/candidate">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            }
          })
        );
      }
    }
  }, [loggedUserId, notificationList]);

  const candidateId = loggedUserId;
  React.useEffect(() => {
    if (candidateId != null && candidateId != 0)
      (async () => {
        await getCandidates({
          CandidateId: Number(candidateId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "",
          SortOrder: "",
          ShowInactive: false,
        });
      })();
  }, [candidateId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getAllCount(
          jobTypeDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as countAllRequestModel,
          authorizationToken
        );
      })();
    }
  }, [
    loggedUserId,
    jobBookMarkSaveRespond,
    jobAppliedSaveResultData,
    digiLockerSaveRespond,
    documentMoveResultModel,
    digiLockerDeletRespond,
  ]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getMatchedJobList(
          matchedJobDispatcher,
          {
            CandidateId: loggedUserId,
            DigiDocumentTypeId: 0,
            PageIndex: 1,
            PageSize: 10,
            ShowInactive: false,
          } as matchedJobRequestModel,
          authorizationToken
        );
      })();
    }
  }, [
    loggedUserIdState,
    jobAlertSaveRespond,
    jobBookMarkSaveRespond,
    jobAppliedSaveResultData,
  ]);

  React.useEffect(() => {
    if (candidateState.data != undefined && candidateState.data.length > 0) {
      setName(candidateState.data[0]["firstName"]);
    }
  }, [candidateState]);

  React.useEffect(() => {
    setCandidateDashboardComponentState((CandidateDashboardComponentState) => {
      return {
        ...CandidateDashboardComponentState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);

  React.useEffect(() => {
    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  const handleCarouselNextClick = () => {
    let num = matchedJob.data != undefined ? matchedJob.data.length : 4;
    let value = num - 3;
    if (countNext < value && value >= 1) {
      setCandidateDashboardComponentState(
        (CandidateDashboardComponentState) => {
          return {
            ...CandidateDashboardComponentState,
            countNext: countNext + 1,
            carouselValue: carouselValue + -350,
          };
        }
      );
      if (countNext % 9 === 0) {
        setCurrentPageOfExpDocList(currentPageOfExpDocList + 1);
      }
    } else {
      setCandidateDashboardComponentState(
        (CandidateDashboardComponentState) => {
          return {
            ...CandidateDashboardComponentState,
            countNext: 0,
            carouselValue: 0,
          };
        }
      );
    }
  };

  const handleCarouselPreviousClick = () => {
    let num = matchedJob.data != undefined ? matchedJob.data.length : 4;
    // let value = num / 4;
    if (countNext > 0) {
      setCandidateDashboardComponentState(
        (CandidateDashboardComponentState) => {
          return {
            ...CandidateDashboardComponentState,
            countNext: countNext - 1,
            carouselValue: carouselValue + 350,
          };
        }
      );
      if (countNext % 9 === 0) {
        setCurrentPageOfExpDocList(
          currentPageOfExpDocList != 1 ? currentPageOfExpDocList - 1 : 1
        );
      }
    } else {
      setCandidateDashboardComponentState(
        (CandidateDashboardComponentState) => {
          return {
            ...CandidateDashboardComponentState,
            countNext: 0,
            carouselValue: 0,
          };
        }
      );
    }
  };

  React.useEffect(() => {
    if (authorizationToken != null)
      (async () => {
        await getJobCategoryList(jobCategoryDispatcher, authorizationToken);
        setISLoder(true);
      })();
  }, [loggedUserIdState]);

  React.useEffect(() => {
    if (
      authorizationToken != null &&
      loggedUserId != 0 &&
      dashboardLayoutValue !== 10
    ) {
      (async () => {
        await SaveDashBoard(
          dashboardDispatcher,
          {
            DashboardListId: dashboardLayoutValue,
            IsActive: true,
            candidateId: loggedUserId,
          } as DashboardSaveRequest,
          authorizationToken
        );
      })();
      setIsCustomStyleOpen(false);
    }
  }, [loggedUserId, dashboardLayoutValue]);

  React.useEffect(() => {
    if (jobAlertGetAll && jobAlertGetAll.data != undefined) {
      if (currentPageJobAlert === 1) {
        setobAlertResultDataState(
          jobAlertGetAll && jobAlertGetAll.data != undefined
            ? jobAlertGetAll.data
            : []
        );
        setHasMore(jobAlertGetAll.hasNext);
      } else {
        setHasMore(jobAlertGetAll.hasNext);
        setobAlertResultDataState([
          ...jobAlertGetAll.data,
          ...jobAlertResultDataSate,
        ]);
      }
    }
  }, [jobAlertGetAll]);

  const fetchMoreData = async () => {
    console.log(2424);
    setCurrentPageJobAlert(currentPageJobAlert + 1);
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        getAllJobAlertList(
          jobAlertDispatcher,
          {
            candidateId: loggedUserId,
            page: currentPageJobAlert,
            pageSize: 10,
            searchTerm: "",
            sortOrder: "",
            showInactive: false,
          } as jobAlertGellAllRequestModel,
          authorizationToken
        );
      })();
      // setISLoder(false);
    }
  };

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        getAllJobAlertList(
          jobAlertDispatcher,
          {
            candidateId: loggedUserId,
            page: currentPage,
            pageSize: 10,
            searchTerm: "",
            sortOrder: "",
            showInactive: false,
          } as jobAlertGellAllRequestModel,
          authorizationToken
        );
      })();
      setISLoder(false);
    }
  }, [loggedUserIdState, jobAlertSaveRespond, JobAlertRoleMapSaveRespond]);

  React.useEffect(() => {
    if (currentAlertId != 0) {
      console.log(
        717172,
        jobAlertGetAll.data.filter((data) => data.rowId == currentAlertId)[0]
          .jobTypeId
      );
      console.log(
        717173,
        jobAlertGetAll.data.filter((data) => data.rowId == currentAlertId)
      );
      setValue2(
        "jobTypeData",
        `${
          jobAlertGetAll.data.filter((data) => data.rowId == currentAlertId)[0]
            .jobTypeId
        }`
      );
      setValue2(
        "jobCategorys",
        `${
          jobAlertGetAll.data.filter((data) => data.rowId == currentAlertId)[0]
            .jobCategoryId
        }`
      );
      setValue2(
        "location",
        jobAlertGetAll.data.filter((data) => data.rowId == currentAlertId)[0]
          .location
      );
      setValue2(
        "radio",
        jobAlertGetAll.data.filter((data) => data.rowId == currentAlertId)[0]
          .active
          ? "1"
          : "2"
      );
    }
  }, [currentAlertId]);

  React.useEffect(() => {
    if (currentAlertId && tempData != undefined) {
      // setValue2("jobTitle",jobAlertVendorGetAll.data.filter(data=>data.rowId==currentAlertId)[0].jobTitle)
      setValue2(
        "jobTitle",
        jobAlertGetAll.data.filter((data) => data.rowId == currentAlertId)[0]
          .jobTitle
      );
      console.log(
        717175,
        jobAlertGetAll.data.filter((data) => data.rowId == currentAlertId)[0]
          .jobTitle
      );
      console.log(717176, tempData);
    }
  }, [watchAllFields.jobCategorys, tempData]);

  const handleJobAlert = (data: any) => {
    setCandidateDashboardComponentState({
      ...CandidateDashboardComponentState,
      jobCategorys: data.jobCategorys,
      jobTypeData: data.jobTypeData,
    });

    if (authorizationToken != null) {
      (async () => {
        await setJobAlertSave(
          jobAlertDispatcher,
          {
            RowId: currentAlertId,
            JobAlertId: 1,
            JobCategoryId: parseInt(data.jobCategorys),
            Location: data.location,
            jobTitle: data.jobTitle,
            JobTypeId: parseInt(data.jobTypeData),
            JobTitleId: 0,
            Active: data.radio === "1" ? true : false,
            CandidateId: loggedUserId,
            IsActive: true,
          } as jobAlertSaveRequestModel,
          authorizationToken
        );
      })();
      setIsOpen(!isOpen);
    }
  };

  // const handleJobApplied = (jobId: number) => {
  //   if (authorizationToken != null) {
  //     saveJobApplied(
  //       jobAppliedDispatcher,
  //       {
  //         isActive: true,
  //         jobId: jobId,
  //         candidateId: loggedUserId,
  //         jobAppliedId: 0,
  //         rowId: 0,
  //       } as jobAppliedSaveRequestModel,
  //       authorizationToken
  //     );
  //   }
  // };

  const handleJobBookMarked = (jobId: number) => {
    if (authorizationToken != null) {
      saveBookMarkList(
        jobBookMarkDispatcher,
        {
          candidateId: loggedUserId,
          jobBookmarkedId: 0,
          jobId: jobId,
          isActive: true,
          rowId: 0,
        } as jobBookMarkSaveRequestModel,
        authorizationToken
      );
    }
  };

  const handleShareButtonClick = (id: any, value: any) => {
    setCandidateDashboardComponentState({
      ...CandidateDashboardComponentState,
      currentJobId: id,
      filterFlag: value,
    });
    setIsShareOpen(!isShareOpen);
  };

  const handleJobAlertCreateClick = () => {
    setIsOpen(!isOpen);
    setcurrentAlertId(0);
  };

  const handleJobAlertClick = (rowId: any) => {
    setIsOpen(true);
    setcurrentAlertId(rowId);
  };

  const handleJobAlertDeleteClick = (rowId: any) => {
    if (authorizationToken != null) {
      (async () => {
        await deleteJobAlertList(
          jobAlertDispatcher,
          {
            rowId: rowId,
          } as digiLockerGetDataWithId,
          authorizationToken
        );
      })();
    }
  };

  function cancel(e: any) {}

  const handleJobOpen = () => {
    setIsJobAppliedOpen(!isJobAppliedOpen);
  };

  const handleJobApplayClick = (id: any) => {
    setCandidateDashboardComponentState({
      ...CandidateDashboardComponentState,
      currentJobId: id,
    });
    handleJobOpen();
  };

  const handleMatchedJobSelect = (id: any) => {
    history.push(`/jobSearch/matched/${id}/searchtitle/location/0`);
  };

  const handleScrollClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCarouselPreviousClickStatus = () => {
    if (pageIndexforMotchedJob !== 1) {
      setPageIndexforMotchedJob(pageIndexforMotchedJob - 1);
    }
  };
  const handleCarouselNextClickStatus = () => {
    console.log(
      matchedJob != undefined && matchedJob.data != undefined
        ? matchedJob.data.length % 3
        : 0
    );
    if (
      matchedJob != undefined && matchedJob.data != undefined
        ? matchedJob.total / 3 >= currentPage
        : 0
    ) {
      setPageIndexforMotchedJob(pageIndexforMotchedJob + 1);
    }
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <a
            style={{ marginBottom: 14 }}
            onClick={() => {
              setIsCustomStyleOpen(true);
            }}
            data-target="#customie_dash"
            data-toggle="modal"
            className="btn cutomise_but"
          >
            <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; Customize
            your Dashboard
          </a>

          {/* BEGIN MODAL */}
          <Modal
            show={isCustomStyleOpen}
            onHide={() => {
              setIsCustomStyleOpen(!isCustomStyleOpen);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Customise Your Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col
                  span={12}
                  onClick={() => {
                    setDashboardLayoutValue(1);
                  }}
                >
                  <div
                  // style={{
                  //   display: ? "blue" : "",
                  // }}
                  >
                    {" "}
                    <img
                      // className="zoomDashboard"
                      style={{ height: 240, width: 300 }}
                      src={require("./../../../images/DashboardLayoutOne.PNG")}
                    ></img>
                    <span>Dashboard 1</span>
                  </div>
                </Col>
                <Col
                  span={12}
                  onClick={() => {
                    setDashboardLayoutValue(2);
                  }}
                >
                  <div
                  // style={{
                  //   backgroundColor: dashboardLayoutValue === 1 ? "blue" : "",
                  // }}
                  >
                    {" "}
                    <img
                      style={{ height: 240, width: 300 }}
                      src={require("./../../../images/DashboardLayoutTwo.PNG")}
                    ></img>
                    <span>Dashboard 2</span>
                  </div>
                </Col>
                {/* <Col
                  span={6}
                  onClick={() => {
                    setDashboardLayoutValue(2);
                  }}
                >
                  {" "}
                  <div
                    style={{
                      display: dashboardLayoutValue === 3 ? "none" : "",
                    }}
                  >
                    <img
                      style={{ height: 120, width: 150 }}
                      src={require("./../../../images/DashboardLayoutThree.PNG")}
                    ></img>
                    <span>Dashboard 3</span>
                  </div>
                </Col>
                <Col
                  span={6}
                  onClick={() => {
                    setDashboardLayoutValue(3);
                  }}
                >
                  <div
                    style={{
                      display: dashboardLayoutValue === 4 ? "none" : "",
                    }}
                  >
                    <img
                      style={{ height: 120, width: 150 }}
                      src={require("./../../../images/DashboardLayoutFour.PNG")}
                    ></img>
                    <span>Dashboard 4</span>
                  </div>
                </Col> */}
              </Row>
            </Modal.Body>

            <div className="clearfix" />
          </Modal>
          <div className="attach_docs">
            <div id="customie_dash" className="modal fade none-border">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      aria-hidden="true"
                      data-dismiss="modal"
                      className="close"
                      type="button"
                    >
                      ×
                    </button>
                    <h4 className="modal-title">Customise Your Dashboard </h4>
                  </div>
                  <div className="modal-body">
                    <div className="col-sm-6">
                      <div className="dash_style_heading"> Style 1 </div>
                      <div className="dash_styles">
                        <div className="dash_status">
                          <a href="#">Status</a>
                        </div>
                        <div className="dash_apply">
                          <a href="#">Apply Now</a>
                        </div>
                        <div className="clearfix" />
                        <div className="dash_style_heading1">Matched Jobs</div>
                        <div className="clearfix" />
                        <p>Alert digi resume locker builder</p>
                        <p>Notification Messages</p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="dash_style_heading"> Style 2 </div>
                      <div className="dash_styles">
                        <div className="dash_status">
                          <a href="#">Status</a>
                        </div>
                        <div className="dash_apply">
                          <a href="#">Apply Now</a>
                        </div>
                        <div className="clearfix" />
                        <div className="dash_style_heading1">Matched Jobs</div>
                        <div className="clearfix" />
                        <p>Alert digi resume locker builder</p>
                        <p>Notification Messages</p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="dash_style_heading"> Style 3 </div>
                      <div className="dash_styles">
                        <div className="dash_status">
                          <a href="#">Status</a>
                        </div>
                        <div className="dash_apply">
                          <a href="#">Apply Now</a>
                        </div>
                        <div className="clearfix" />
                        <div className="dash_style_heading1">Matched Jobs</div>
                        <div className="clearfix" />
                        <p>Alert digi resume locker builder</p>
                        <p>Notification Messages</p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="dash_style_heading"> Style 4</div>
                      <div className="dash_styles">
                        <div className="dash_status">
                          <a href="#">Status</a>
                        </div>
                        <div className="dash_apply">
                          <a href="#">Apply Now</a>
                        </div>
                        <div className="clearfix" />
                        <div className="dash_style_heading1">Matched Jobs</div>
                        <div className="clearfix" />
                        <p>Alert digi resume locker builder</p>
                        <p>Notification Messages</p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer  m-t-30">
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="button"
                    >
                      Apply Changes
                    </button>
                    <button
                      data-dismiss="modal"
                      className="btn btn-default waves-effect"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            </div>
          </div>
          {/* END MODAL */}

          <JobAppliedForm
            isJobAppliedOpen={isJobAppliedOpen}
            handleJobOpen={handleJobOpen}
            currentJobId={currentJobId}
            loggedUserId={loggedUserId}
            authorizationToken={authorizationToken}
            candidateResumes={candidateResumes}
            candidateCoverLetters={candidateCoverLetters}
          />

          <div className="clearfix" />
          <div className="row mar_t_25">
            <div className="col-sm-12">
              <div className="dash_sec">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img src={require("./../../../images/jobs_saved.png")} />{" "}
                      {allCounts.jobSaved != undefined ? allCounts.jobSaved : 0}{" "}
                      Jobs Saved
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img
                        src={require("./../../../images/jobs_applied.png")}
                      />{" "}
                      {allCounts.jobApplied != undefined
                        ? allCounts.jobApplied
                        : 0}{" "}
                      Jobs Applied
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img src={require("./../../../images/shortlisted.png")} />{" "}
                      {allCounts.shortListed != undefined
                        ? allCounts.shortListed
                        : 0}{" "}
                      Shortlisted
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img
                        src={require("./../../../images/new_messages.png")}
                      />{" "}
                      {allCounts.newMessages != undefined
                        ? allCounts.newMessages
                        : 0}{" "}
                      Unread Messages
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img src={require("./../../../images/new_alerts.png")} />{" "}
                      {allCounts.newAlerts != undefined
                        ? allCounts.newAlerts
                        : 0}{" "}
                      New Alerts
                    </div>
                  </div>
                  <div className="col-sm-2 pad_l_0">
                    <div className="dash_notification">
                      <img
                        src={require("./../../../images/expiring_documents.png")}
                      />{" "}
                      {allCounts.expiryDigiDocs != undefined
                        ? allCounts.expiryDigiDocs.length > 0 &&
                          allCounts.expiryDigiDocs[0].documentCount != undefined
                          ? allCounts.expiryDigiDocs[0].documentCount +
                            allCounts.expiryDigiDocs[1].documentCount
                          : 0
                        : 0}{" "}
                      Expiring Documents
                    </div>
                  </div>
                </div>
              </div>
              {/* matched jobs Starting */}
              <div className="section_box1">
                <h1>Matched Jobs</h1>
                <div className="row">
                  <div className="prodict_list">
                    <div
                      id="owl-demo1"
                      className="owl-carousel owl-theme"
                      style={{ opacity: 1, display: "block" }}
                    >
                      <div className="owl-wrapper-outer">
                        <div
                          className="owl-wrapper"
                          style={{
                            width: "3140px",
                            left: "0px",
                            display: "block",
                            transition: "all 1000ms ease 0s",
                            transform: `translate3d(${carouselValue}px, 0px, 0px)`,
                          }}
                        >
                          {matchedJob.data != undefined
                            ? matchedJob.data.map((data, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="owl-item"
                                    style={{ width: "314px" }}
                                  >
                                    <div
                                      className="item "
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="matched_jobs_sec">
                                        <div className="matched_jobs">
                                          <div className="matched_jobs_cat_t">
                                            {data.jobType}
                                          </div>
                                          <div className="jobs_start1">
                                            {data.postedDate}
                                          </div>
                                          <div className="matched_star1">
                                            {data.isBookmarked ? (
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  handleJobBookMarked(
                                                    data.rowId
                                                  )
                                                }
                                              />
                                            ) : (
                                              <i
                                                className="fa fa-star-o"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  handleJobBookMarked(
                                                    data.rowId
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                          <div className="clearfix" />
                                          <Popover
                                            content={
                                              "Please click for more details"
                                            }
                                          >
                                            <div
                                              onClick={() =>
                                                handleMatchedJobSelect(
                                                  data.jobId
                                                )
                                              }
                                            >
                                              <div className="matched_heading textOverFlow">
                                                {data.title}
                                              </div>
                                              <div className="matched_jobs_cat">
                                                <i
                                                  className="fa fa-map-marker"
                                                  aria-hidden="true"
                                                />{" "}
                                                {data.location}
                                              </div>
                                              <div className="matched_jobs_cat text-right textOverFlow">
                                                <i
                                                  className="fa fa-clock-o"
                                                  aria-hidden="true"
                                                />{" "}
                                                {data.categoryName}
                                              </div>
                                              <div className="clearfix" />
                                              <p className="textOverFlow">
                                                {data.description}
                                              </p>
                                            </div>
                                          </Popover>
                                          <div className="matched_jobs_share">
                                            <a
                                              onClick={() =>
                                                handleShareButtonClick(
                                                  data.rowId,
                                                  true
                                                )
                                              }
                                              data-target="#share"
                                              data-toggle="modal"
                                            >
                                              <i
                                                className="fa fa-share-alt"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </div>
                                          <div className="jobs_apply">
                                            {data.jobAppliedStatus != null ? (
                                              <span className="stausShow">
                                                {data.jobAppliedStatus}
                                              </span>
                                            ) : myProfileProgressBar.profileProgress <
                                              40 ? (
                                              <Popover
                                                content={
                                                  // "Please complete your profile to minimum 40% to access this feature."
                                                  <>
                                                    <p>
                                                      Please complete your
                                                      profile to minimum 40% to
                                                      access this feature.
                                                    </p>
                                                    <Link to="/candidate/my-profile">
                                                      Complete Now
                                                    </Link>
                                                  </>
                                                }
                                              >
                                                <a>Apply Now</a>
                                              </Popover>
                                            ) : (
                                              <a
                                                data-target="#apply_now_pop"
                                                data-toggle="modal"
                                                onClick={(jobId: any) =>
                                                  handleJobApplayClick(
                                                    data.rowId
                                                  )
                                                }
                                              >
                                                Apply Now
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                      <div className="owl-controls clickable">
                        <div className="owl-pagination">
                          <div className="owl-page active">
                            <span className="" />
                          </div>
                          <div className="owl-page">
                            <span className="" />
                          </div>
                        </div>
                        <div className="owl-buttons">
                          <div
                            className="owl-prev"
                            onClick={handleCarouselPreviousClick}
                          >
                            <img
                              src={require("../../../images/products_ar1.png")}
                            />{" "}
                          </div>
                          <div
                            className="owl-next"
                            onClick={handleCarouselNextClick}
                          >
                            <img
                              src={require("../../../images/products_ar2.png")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* matched jobs ending */}
            </div>
            {/* BEGIN MODAL */}
            {/* <div className="modal fade none-border" id="share">
              <div className="modal-dialog"> */}
            <Modal
              show={isShareOpen}
              onHide={() => {
                setIsShareOpen(!isShareOpen);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Share</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-sm-12 m_t_30 text-center">
                  <div className="social1">
                    <WhatsappShareButton
                      url={encodeURI(
                        `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                      )}
                      className={""}
                      title={"CareerApp Job Post"}
                    >
                      {/* <a href="#" className="social_face"> */}
                      <Tooltip title="whatsApp">
                        <i
                          className="fa fa-whatsapp"
                          style={{
                            fontSize: 51,
                            color: "green",
                            paddingRight: 2,
                          }}
                        ></i>
                      </Tooltip>
                      {/* </a> */}
                    </WhatsappShareButton>{" "}
                    <TelegramShareButton
                      url={`career app job Share`}
                      title={encodeURI(
                        `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                      )}
                    >
                      <Tooltip title="Telegram">
                        <i
                          className="fa fa-telegram"
                          style={{
                            fontSize: 48,
                            color: "rgb(55, 174, 226)",
                          }}
                        ></i>
                      </Tooltip>
                    </TelegramShareButton>
                    <EmailShareButton
                      url={encodeURI(
                        `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                      )}
                      subject={"Jobs In Carrer app"}
                      body={"This is a Jobs from   Carrer app"}
                      className={""}
                    >
                      {/* <EmailIcon></EmailIcon> */}
                      {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                      <Tooltip title="Gmail">
                        <img
                          src={require("../../../images/gmail-icon-svg-28.jpg")}
                          style={{ height: 66, marginTop: -24 }}
                        />
                      </Tooltip>
                    </EmailShareButton>{" "}
                    <CopyToClipboard
                      //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                      text={`https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`}
                    >
                      <Tooltip title="copy to clipboard">
                        {" "}
                        {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                        <img
                          src={require("../../../images/copy-clipboard-icon-28.jpg")}
                          style={{
                            height: 66,
                            marginTop: -24,
                            marginLeft: -15,
                          }}
                          onClick={() => {
                            setIsShareOpen(!isShareOpen);
                          }}
                        />
                      </Tooltip>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="modal-footer  m-t-30"></div>
              </Modal.Body>

              {/* <Modal.Body>
                <div className="col-sm-12 m_t_30 text-center">
                  <div className="social1">
                    <FacebookShareButton
                      url={"http://careerappui.clubactive.in/"}
                      quote={`${
                        filterFlag
                          ? matchedJob != undefined &&
                            matchedJob.data != undefined
                            ? matchedJob.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0] != undefined
                              ? matchedJob.data.filter(
                                  (data) => data.rowId === currentJobId
                                )[0].description
                              : ""
                            : ""
                          : jobAlert.data != undefined &&
                            jobAlert.data != undefined
                          ? jobAlert.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0] != undefined
                            ? jobAlert.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0].description
                            : ""
                          : ""
                      }`}
                      hashtag={`${
                        filterFlag
                          ? matchedJob != undefined &&
                            matchedJob.data != undefined
                            ? matchedJob.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0] != undefined
                              ? matchedJob.data.filter(
                                  (data) => data.rowId === currentJobId
                                )[0].description
                              : ""
                            : ""
                          : jobAlert.data != undefined &&
                            jobAlert.data != undefined
                          ? jobAlert.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0] != undefined
                            ? jobAlert.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0].description
                            : ""
                          : ""
                      }`}
                      className={""}
                    >
                    
                      <a href="#" className="social_face">
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={"http://careerappui.clubactive.in/"}
                      via={`${
                        filterFlag
                          ? matchedJob != undefined &&
                            matchedJob.data != undefined
                            ? matchedJob.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0] != undefined
                              ? matchedJob.data.filter(
                                  (data) => data.rowId === currentJobId
                                )[0].description
                              : ""
                            : ""
                          : jobAlert.data != undefined &&
                            jobAlert.data != undefined
                          ? jobAlert.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0] != undefined
                            ? jobAlert.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0].description
                            : ""
                          : ""
                      }`}
                      hashtags={[
                        `${
                          filterFlag
                            ? matchedJob != undefined &&
                              matchedJob.data != undefined
                              ? matchedJob.data.filter(
                                  (data) => data.rowId === currentJobId
                                )[0] != undefined
                                ? matchedJob.data.filter(
                                    (data) => data.rowId === currentJobId
                                  )[0].description
                                : ""
                              : ""
                            : jobAlert.data != undefined &&
                              jobAlert.data != undefined
                            ? jobAlert.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0] != undefined
                              ? jobAlert.data.filter(
                                  (data) => data.rowId === currentJobId
                                )[0].description
                              : ""
                            : ""
                        }`,
                      ]}
                      className={""}
                    >
                   
                      <a href="#" className="social_twit">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                    </TwitterShareButton>

                    <LinkedinShareButton
                      url={"http://careerappui.clubactive.in/"}
                      title={`${
                        filterFlag
                          ? matchedJob != undefined &&
                            matchedJob.data != undefined
                            ? matchedJob.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0] != undefined
                              ? matchedJob.data.filter(
                                  (data) => data.rowId === currentJobId
                                )[0].title
                              : ""
                            : ""
                          : jobAlert.data != undefined &&
                            jobAlert.data != undefined
                          ? jobAlert.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0] != undefined
                            ? jobAlert.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0].title
                            : ""
                          : ""
                      }`}
                      summary={`${
                        filterFlag
                          ? matchedJob != undefined &&
                            matchedJob.data != undefined
                            ? matchedJob.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0] != undefined
                              ? matchedJob.data.filter(
                                  (data) => data.rowId === currentJobId
                                )[0].description
                              : ""
                            : ""
                          : jobAlert.data != undefined &&
                            jobAlert.data != undefined
                          ? jobAlert.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0] != undefined
                            ? jobAlert.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0].description
                            : ""
                          : ""
                      }`}
                      source={"http://careerappui.clubactive.in/"}
                    >
                      <LinkedinIcon />
                    </LinkedinShareButton>
                  </div>
                </div>
                <div className="modal-footer  m-t-30">

                </div>
              </Modal.Body> */}
            </Modal>

            <div
              className="modal fade none-border"
              id="share"
              style={{ display: "none" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      aria-hidden="true"
                      data-dismiss="modal"
                      className="close"
                      type="button"
                    >
                      ×
                    </button>
                    <h4 className="modal-title">Share </h4>
                  </div>
                  <div className="col-sm-12 m_t_30 text-center">
                    <div className="social1">
                      <a href="#" className="social_face">
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                      <a href="#" className="social_twit">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                      <a href="#" className="social_insta">
                        <i className="fa fa-instagram" aria-hidden="true" />
                      </a>
                      <a href="#" className="social_youtube">
                        <i className="fa fa-youtube-play" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                  <div className="modal-footer  m-t-30"></div>
                </div>
              </div>
            </div>
            {/* END MODAL */}

            <div className="col-sm-4">
              <div className="section_box2">
                <h1>Job Alert</h1>
                <div className="jobs_alert_btn">
                  <a
                    onClick={
                      //   ()=>{
                      //    setIsOpen(!isOpen);
                      // }
                      handleJobAlertCreateClick
                    }
                    data-target="#job_alert"
                    data-toggle="modal"
                  >
                    Create Alert
                  </a>
                </div>
                <div className="clearfix" />
                <div
                  className="jobs_alert_scroll"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={0}
                >
                  <InfiniteScroll
                    className="_custom-infinite-scroll-class"
                    height={200}
                    dataLength={jobAlertResultDataSate.length}
                    // dataLength={2000}
                    // dataLength={totalCount}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={
                      jobAlertResultDataSate.length > 0 ? (
                        <h4>Loading...</h4>
                      ) : null
                    }
                    // initialScrollY={indexState * 80}
                    endMessage={
                      jobAlertResultDataSate.length > 0 ? (
                        <p style={{ textAlign: "center" }}>
                          <b>No more data to load</b>
                        </p>
                      ) : null
                    }
                  >
                    {jobAlertGetAll.data != undefined
                      ? jobAlertGetAll.data.map((jobAlert, i) => {
                          return (
                            <>
                              <div className="jobalert_sec">
                                <span className="jobalert_sec_icon">
                                  <Popconfirm
                                    title={
                                      <div>
                                        <p>
                                          Are you sure delete this Job Alert{" "}
                                        </p>
                                      </div>
                                    }
                                    onConfirm={(e: any) =>
                                      handleJobAlertDeleteClick(jobAlert.rowId)
                                    }
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <img
                                      // onClick={() =>
                                      //   handleJobAlertDeleteClick(jobAlert.rowId)
                                      // }
                                      src={require("../../../images/job_close.png")}
                                      className="img-responsive"
                                    />
                                  </Popconfirm>
                                </span>
                                <div
                                  className="jobalert_con _cursor-pointer"
                                  onClick={() =>
                                    handleJobAlertClick(jobAlert.rowId)
                                  }
                                >
                                  <div
                                    className={`jobalert_headings jobalert_clr${
                                      (i % 3) + 1
                                    }`}
                                  >
                                    {jobAlert.jobCategory
                                      .charAt(0)
                                      .toUpperCase() +
                                      jobAlert.jobCategory.slice(1)}
                                  </div>
                                  <div className="jobalert_head">
                                    {jobAlert.jobTitle} - {jobAlert.jobType}
                                  </div>
                                  <div
                                    className={
                                      jobAlert.active
                                        ? "jobalert_active"
                                        : "jobalert_inactive"
                                    }
                                  >
                                    {jobAlert.active ? "Active" : "Inactive"}
                                  </div>
                                </div>
                                <div className="jobalert_con1">
                                  <div className="jobalert_time">1 day ago</div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : null}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
            {/* BEGIN MODAL */}

            <Modal
              show={isOpen}
              onHide={() => {
                setIsOpen(!isOpen);
                setcurrentAlertId(0);
              }}
              backdrop="static"
            >
              <form onSubmit={handleSubmit2(handleJobAlert)} noValidate>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {currentAlertId == 0 ? "New Job Alert" : "Update Job Alert"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Category
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="jobCategorys"
                        rules={{
                          required: "Job Category is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              myProfile.jobCategories != undefined
                                ? myProfile.jobCategories.map((e: any) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : jobCategoryData
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="jobCategorys"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Type
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        className="custom-select-sec"
                        control={control2}
                        name="jobTypeData"
                        rules={{
                          required: "Job Type is required",
                        }}
                        // rules={{
                        //   validate: (value) => {
                        //     return value === "" ? "Job Type is required" : "";
                        //   },
                        // }}
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              // myProfile.jobTypes != undefined
                              //   ? myProfile.jobTypes.map((e: any) => {
                              //       return {
                              //         value: e["value"],
                              //         label: e["caption"],
                              //       };
                              //     })
                              //   :
                              jobTypeDatas
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="jobTypeData"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Title
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="jobTitle"
                        rules={{
                          required: "Job Title is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              tempData != undefined
                                ? tempData.map((e) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : tempData
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                  </div>
                  <ErrorMessage
                    errors={errors2}
                    name="jobTitle"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                  <div className="form-group">
                    <label htmlFor="email">Location</label>
                    <input
                      defaultValue={location}
                      type="text"
                      className="form-control "
                      name="location"
                      placeholder=" "
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      id="radio1"
                      type="radio"
                      ref={register2({
                        required: false,
                      })}
                      name="radio"
                      defaultValue={1}
                      defaultChecked
                    />
                    <label htmlFor="radio1" style={{ marginRight: 20 }}>
                      <span>
                        <span />
                      </span>{" "}
                      Active
                    </label>
                    <input
                      id="radio2"
                      type="radio"
                      ref={register2({
                        required: false,
                      })}
                      name="radio"
                      defaultValue={2}
                      // defaultChecked
                    />
                    <label
                      htmlFor="radio2"
                      // style={{ marginLeft: 1 }}
                    >
                      <span>
                        <span />
                      </span>{" "}
                      Inactive
                    </label>
                  </div>
                </Modal.Body>
                <div className="modal-footer  m-t-30">
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                  >
                    Create Alert
                  </button>
                  <button
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setcurrentAlertId(0);
                    }}
                    data-dismiss="modal"
                    className="btn btn-default waves-effect"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
                <div className="clearfix" />
              </form>
            </Modal>

            {/* END MODAL */}
            {/* </div>
          <div className="clearfix" />
        </div> */}

            {/* END MODAL */}
            <div className="col-sm-4">
              <div className="digilocker_dash">
                <h1>
                  {" "}
                  {/* {basicInfo.fullName != undefined
                    ? basicInfo.fullName.split(" ")[0]
                    : ""} */}
                  {name.split(" ")[0]}
                  's
                </h1>
                <h2>
                  DIGI<span>Locker</span>
                </h2>
                <div className="digilocker_dash_con">
                  <a onClick={() => history.push("/candidate/digilocker/3")}>
                    General Documents -{" "}
                    {allCounts.digiDocs != undefined &&
                    allCounts.digiDocs.length > 0 &&
                    allCounts.digiDocs[0].documentCount != undefined
                      ? allCounts.digiDocs[0].documentCount
                      : 0}
                  </a>{" "}
                </div>
                <div className="digilocker_dash_con">
                  <a onClick={() => history.push("/candidate/digilocker/2")}>
                    Personal Documents -{" "}
                    {allCounts.digiDocs != undefined &&
                    allCounts.digiDocs.length > 0
                      ? allCounts.digiDocs[1].documentCount
                      : 0}
                  </a>
                  <div className="digilocker_dash_con">
                    <a
                      style={{ color: "red" }}
                      onClick={() => history.push("/candidate/digilocker/3")}
                    >
                      Expiring Documents -{" "}
                      {allCounts.expiryDigiDocs != undefined
                        ? allCounts.expiryDigiDocs.length > 0 &&
                          allCounts.expiryDigiDocs[0].documentCount != undefined
                          ? allCounts.expiryDigiDocs[0].documentCount +
                            allCounts.expiryDigiDocs[1].documentCount
                          : 0
                        : 0}{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="resume_build_dash">
                <h1>Resume Builder</h1>
                <div className="resume_build_dash_con">
                  My Resume -{" "}
                  {allCounts.resumeCreated != undefined
                    ? allCounts.resumeCreated
                    : 0}
                </div>
                <div className="progress_sec1">
                  <Progress
                    strokeColor={"#1dbc03"}
                    percent={myProfileProgressBar.profileProgress}
                  ></Progress>
                  {/* <div className="progress_bar_bg">
                    <div className="progress_bar" />
                  </div> */}

                  {myProfileProgressBar.profileProgress < 40 ? (
                    <>
                      <Popover
                        content={
                          "Please complete your profile to minimum 40% to access this feature."
                        }
                      >
                        <Link to="/candidate" className="btn resumebuild_but">
                          Resume Builder
                        </Link>
                      </Popover>
                    </>
                  ) : (
                    <Link
                      to="/candidate/my-resume/resume/my_information"
                      className="btn resumebuild_but"
                    >
                      Resume Builder
                    </Link>
                  )}

                  <div className="progress_con1">
                    {" "}
                    {myProfileProgressBar.profileProgress < 40 ? (
                      <>
                        {" "}
                        <Link to="/candidate/my-resume/resume/my_information">
                          Complete Now
                        </Link>{" "}
                        <span>{myProfileProgressBar.profileProgress}%</span>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix" />

            <div className="col-sm-6">
              <div className="section_box2">
                <h1>Notifications</h1>
                <div
                  className="dash_noti_scroll"
                  style={{ overflow: "hidden" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 230 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {listFlag === 1 ? (
                      list
                    ) : (
                      <div>You have no notifications!</div>
                    )}
                  </Scrollbars>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="section_box2">
                <h1>Messages</h1>
                <div
                  className="dash_message_scroll"
                  style={{ overflow: "hidden" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 230 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {messageListFlag === 1 ? (
                      messageList
                    ) : (
                      <div>You have no messages!</div>
                    )}
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(CandidateDashboardComponent);
