import React from "react";

export default function GlobalSpinner(props: any) {
  return (
    <div className="_ajax-spinner-loading" style={{ display: props.visible ? "block" : "none" }}>
      <div className="_ajax-spinner-css">
        <div></div>
      </div>
    </div>
  );
}