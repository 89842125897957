import axios from "axios";
import { environment } from "./../environments/environment";
import AuthService from "./../services/AuthService";

type objectIndexing = {
  [key: string]: any
}

export function apiClient(path: any, data?: any, method?: any, opts?: any) {

  let accessToken = AuthService.accessToken;

  if (accessToken?.startsWith(`"`)) {
    accessToken = accessToken.substring(1);
    accessToken = accessToken.slice(0, -1);
  }

  let headers: objectIndexing = {
    "Authorization": "bearer " + accessToken,
    "Content-Type": "application/json"
  };

  if (opts && opts.headers) {
    headers = Object.assign({}, headers, opts.headers);
  }

  return axios({
    method: method || 'post',
    url: environment.baseUrl + path,
    data: data || {},
    headers
  });
}