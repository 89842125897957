import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  jobAlertCategoryMapRequestModel,
  jobAlertExperienceMapRequestModel,
  jobAlertGellAllRequestModel,
  jobAlertGellAllRespondModel,
  jobAlertIndustryMapSaveRequestModel,
  jobAlertRequestmodel,
  JobAlertRoleMapSaveRequestModel,
  jobAlertSaveRequestModel,
  jobAlertSaveRespondModel,
  jobAlertTitleDropdownResultModel,
  JobAlertTypeMapSaveRequestModel,
  jobAlertViewModel,
} from "../../models/candidate/JobAlert";
import {
  IJobAlertManagementState,
  JobAlertDispatcherContext,
  JobAlertStateContext,
} from "../../context/candidate/JobAlertContext";
import { digiLockerGetDataWithId } from "../../models/candidate/DigiLocker";
import { categoryWithId } from "../../models/candidate/JobCategory";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IJobAlertAction =
  | {
      type: "SET_Job Alert_LIST";
      jobAlert: jobAlertViewModel;
    }
  | {
      type: "SET_JOB_AlERT_SAVE";
      jobAlertSaveRespond: jobAlertSaveRespondModel;
    }
  | {
      type: "SET_JOB_AlERT_CATEGORY_MAP_SAVE";
      jobAlertCategoryMapSaveRespond: jobAlertSaveRespondModel;
    }
  | {
      type: "SET_JOB_AlERT_EXPERIENCE_MAP_SAVE";
      jobAlertExperienceMapSaveRespond: jobAlertSaveRespondModel;
    }
  | {
      type: "SET_JOB_AlERT_INDUSTRY_MAP_SAVE";
      jobAlertIndustryMapSaveRespond: jobAlertSaveRespondModel;
    }
  | {
      type: "SET_JOB_AlERT_ROLE_MAP_SAVE";
      JobAlertRoleMapSaveRespond: jobAlertSaveRespondModel;
    }
  | {
      type: "SET_JOB_AlERT_TYPE_MAP_SAVE";
      JobAlertTypeMapSaveRespond: jobAlertSaveRespondModel;
    }
  | {
      type: "GET_ALL_JOB_ALERT";
      jobAlertGetAll: jobAlertGellAllRespondModel;
    }
  | {
      type: "DELETE_JOB_ALERT";
      JobAlertRoleMapSaveRespond: jobAlertSaveRespondModel;
    }
  | {
      type: "GET_TITLE_WITH_CATEGORY_ID";
      jobAlertTitleDropdownResult: jobAlertTitleDropdownResultModel[];
    };

export const jobAlertReducer: Reducer<
  IJobAlertManagementState,
  IJobAlertAction
> = (draft, action): IJobAlertManagementState => {
  switch (action.type) {
    case "SET_Job Alert_LIST":
      draft.jobAlert = action.jobAlert;
      return draft;
    case "SET_JOB_AlERT_SAVE":
      draft.jobAlertSaveRespond = action.jobAlertSaveRespond;
      return draft;
    case "SET_JOB_AlERT_CATEGORY_MAP_SAVE":
      draft.jobAlertCategoryMapSaveRespond =
        action.jobAlertCategoryMapSaveRespond;
      return draft;
    case "SET_JOB_AlERT_EXPERIENCE_MAP_SAVE":
      draft.jobAlertExperienceMapSaveRespond =
        action.jobAlertExperienceMapSaveRespond;
      return draft;
    case "SET_JOB_AlERT_INDUSTRY_MAP_SAVE":
      draft.jobAlertIndustryMapSaveRespond =
        action.jobAlertIndustryMapSaveRespond;
      return draft;
    case "SET_JOB_AlERT_ROLE_MAP_SAVE":
      draft.JobAlertRoleMapSaveRespond = action.JobAlertRoleMapSaveRespond;
      return draft;
    case "SET_JOB_AlERT_TYPE_MAP_SAVE":
      draft.JobAlertRoleMapSaveRespond = action.JobAlertTypeMapSaveRespond;
      return draft;
    case "GET_ALL_JOB_ALERT":
      draft.jobAlertGetAll = action.jobAlertGetAll;
      return draft;
    case "DELETE_JOB_ALERT":
      draft.JobAlertRoleMapSaveRespond = action.JobAlertRoleMapSaveRespond;
      return draft;
    case "GET_TITLE_WITH_CATEGORY_ID":
      draft.jobAlertTitleDropdownResult = action.jobAlertTitleDropdownResult;
      return draft;
  }
};

export const useJobAlertDispatcher = (): React.Dispatch<IJobAlertAction> => {
  const jobAlertDispatcher = React.useContext(JobAlertDispatcherContext);
  if (!jobAlertDispatcher) {
    throw new Error(
      "You have to provide the JobAlert dispatcher using theJobAlertDispatcherContext.Provider in a parent component."
    );
  }
  return jobAlertDispatcher;
};

export const useJobAlertContext = (): IJobAlertManagementState => {
  const jobAlertContext = React.useContext(JobAlertStateContext);
  if (!jobAlertContext)
    throw new Error(
      "You have to provide the jobAlert context using the JobAlertStateContext.Provider in a parent component."
    );
  return jobAlertContext;
};

//Get All List

// get  list

export const getJobAlertList = async (
  dispatcher: React.Dispatch<IJobAlertAction>,
  query: jobAlertRequestmodel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllJobAlert;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "SET_Job Alert_LIST", jobAlert: res.data });
    });
  } catch (e) {}
};

// Save call

export const setJobAlertSave = async (
  dispatcher: React.Dispatch<IJobAlertAction>,
  query: jobAlertSaveRequestModel,
  token: string
) => {
  if (token?.startsWith(`"`)) {
    token = token.substring(1);
    token = token.slice(0, -1);
  }
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveJobAlert;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "SET_JOB_AlERT_SAVE", jobAlertSaveRespond: res.data });
    });
  } catch (e) {}
};

//Save job alert Categorymap

export const setJobAlertCategoryMapSave = async (
  dispatcher: React.Dispatch<IJobAlertAction>,
  query: jobAlertCategoryMapRequestModel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorizationToken}`,
    };

    const url = AppUrls.SaveJobAlertCategoryMap;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_JOB_AlERT_CATEGORY_MAP_SAVE",
        jobAlertCategoryMapSaveRespond: res.data,
      });
    });
  } catch (e) {}
};

//save JobAlertExperienceMap

export const setJobAlertExperienceMapSave = async (
  dispatcher: React.Dispatch<IJobAlertAction>,
  query: jobAlertExperienceMapRequestModel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorizationToken}`,
    };

    const url = AppUrls.SaveJobAlertExperienceMap;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_JOB_AlERT_EXPERIENCE_MAP_SAVE",
        jobAlertExperienceMapSaveRespond: res.data,
      });
    });
  } catch (e) {}
};

// save JobAlertIndustryMap

export const setJobAlertIndustryMapMapSave = async (
  dispatcher: React.Dispatch<IJobAlertAction>,
  query: jobAlertIndustryMapSaveRequestModel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorizationToken}`,
    };

    const url = AppUrls.SaveJobAlertIndustryMap;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_JOB_AlERT_INDUSTRY_MAP_SAVE",
        jobAlertIndustryMapSaveRespond: res.data,
      });
    });
  } catch (e) {}
};

//save JobAlertRoleMap

export const setJobAlertRoleMapMapSave = async (
  dispatcher: React.Dispatch<IJobAlertAction>,
  query: JobAlertRoleMapSaveRequestModel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorizationToken}`,
    };

    const url = AppUrls.SaveJobAlertRoleMapMap;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_JOB_AlERT_ROLE_MAP_SAVE",
        JobAlertRoleMapSaveRespond: res.data,
      });
    });
  } catch (e) {}
};

// save JobAlertTypeMap

export const setJobAlertTypeMapSave = async (
  dispatcher: React.Dispatch<IJobAlertAction>,
  query: JobAlertTypeMapSaveRequestModel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorizationToken}`,
    };

    const url = AppUrls.SaveJobAlertTypeMap;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_JOB_AlERT_TYPE_MAP_SAVE",
        JobAlertTypeMapSaveRespond: res.data,
      });
    });
  } catch (e) {}
};

export const getAllJobAlertList = async (
  dispatcher: React.Dispatch<IJobAlertAction>,
  query: jobAlertGellAllRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllJobAlertWithCandidateId;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "GET_ALL_JOB_ALERT", jobAlertGetAll: res.data });
    });
  } catch (e) {}
};

export const deleteJobAlertList = async (
  dispatcher: React.Dispatch<IJobAlertAction>,
  query: digiLockerGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteJobAlert;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "DELETE_JOB_ALERT",
        JobAlertRoleMapSaveRespond: res.data,
      });
    });
  } catch (e) {}
};

export const getTitleWithCategoryId = async (
  dispatcher: React.Dispatch<IJobAlertAction>,
  query: categoryWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetTitleWithCategory;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_TITLE_WITH_CATEGORY_ID",

        jobAlertTitleDropdownResult: res.data,
      });
      //console.log(676767676, res.data);
    });
  } catch (e) {}
};
