import { createContext, Reducer, useContext, useReducer } from "react";

import React from "react";

import axios from "axios";
import AuthService from "../../services/AuthService";
import {
  ClientImageProfileResultModel,
  ClientPersonalInfoRequestByIdModel,
  ClientPersonalInfoRequestModel,
  ClientPersonalInfoResultByIdModel,
  ClientPersonalInfoResultModel,
  ClientProfileImageRequestModel,
} from "../../models/client/ClientProfile";
import {
  ClientProfileDispatcherContext,
  ClientProfileStateContext,
  IClientProfileManagementState,
} from "../../context/client/ClientProfileCotext";
import { AppUrls } from "../../environments/environment";
import { apiClient } from "../../utils/httpClient";
import { message } from "antd";
import * as API from "./../../apis/client";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IClientProfileAction =
  | {
      type: "ADD_OR_EDIT_PERSONAL_DETAILS";
      addOrEditClientProfileResult: ClientPersonalInfoResultModel;
    }
  | {
      type: "GET_CLIENT_DETAILS_BY_ID";
      getClientPersonalDetails: ClientPersonalInfoResultByIdModel;
    }
  | {
      type: "GET_CLIENT_DPROFILE_IMAGE";
      clientProfileImage: ClientImageProfileResultModel;
    }
  | {
      type: "DELET_CLIENT_PROFILE_IMAGE";
      deleteProfileImageResult: ClientPersonalInfoResultModel;
    };

export const clientProfileReducer: Reducer<
  IClientProfileManagementState,
  IClientProfileAction
> = (draft, action): IClientProfileManagementState => {
  switch (action.type) {
    case "ADD_OR_EDIT_PERSONAL_DETAILS":
      draft.addOrEditClientProfileResult = action.addOrEditClientProfileResult;
      return draft;
    case "GET_CLIENT_DETAILS_BY_ID":
      draft.getClientPersonalDetails = action.getClientPersonalDetails;
      return draft;
    case "GET_CLIENT_DPROFILE_IMAGE":
      draft.clientProfileImage = action.clientProfileImage;
      return draft;

    case "DELET_CLIENT_PROFILE_IMAGE":
      draft.deleteProfileImageResult = action.deleteProfileImageResult;
      return draft;
  }
};

export const useClientProfileDispatcher = (): React.Dispatch<IClientProfileAction> => {
  const clientProfileDispatcher = React.useContext(
    ClientProfileDispatcherContext
  );
  if (!clientProfileDispatcher) {
    throw new Error(
      "You have to provide the ClientProfile dispatcher using theClientProfileDispatcherContext.Provider in a parent component."
    );
  }
  return clientProfileDispatcher;
};

export const useClientProfileContext = (): IClientProfileManagementState => {
  const clientProfileContext = React.useContext(ClientProfileStateContext);
  if (!clientProfileContext)
    throw new Error(
      "You have to provide the clientProfile context using the ClientProfileStateContext.Provider in a parent component."
    );
  return clientProfileContext;
};

export const AddOrEditClientPersonalDetails = async (
  dispatcher: React.Dispatch<IClientProfileAction>,
  query: ClientPersonalInfoRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.AddOrEditPersonalDetailsClient;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "ADD_OR_EDIT_PERSONAL_DETAILS",
        addOrEditClientProfileResult: res.data,
      });
      res.data.isSuccess
        ? message.success("Profile Info Updated Successfully")
        : message.error(res.data.message);
    });
  } catch (e) {}
};

export const GettClientPersonalDetails = async (
  dispatcher: React.Dispatch<IClientProfileAction>,
  query: ClientPersonalInfoRequestByIdModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetProfilePersonalDetailsClient;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_CLIENT_DETAILS_BY_ID",
        getClientPersonalDetails: res.data,
      });
    });
  } catch (e) {}
};

export const GetClientProfileImage = async (
  dispatcher: React.Dispatch<IClientProfileAction>,
  query: ClientProfileImageRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetProfileImageClient;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_CLIENT_DPROFILE_IMAGE",
        clientProfileImage: res.data,
      });
    });
  } catch (e) {}
};

export const DeleteClientProfileImage = async (
  dispatcher: React.Dispatch<IClientProfileAction>,
  query: ClientPersonalInfoRequestByIdModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteClientProfile;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "DELET_CLIENT_PROFILE_IMAGE",
        deleteProfileImageResult: res.data,
      });
    });
  } catch (e) {}
};

export function saveClientProfileImage(data?: any) {
  return apiClient("/api/ClientProfileImage/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// social media accounts
const initialState11 = {
  data: [],
  loading: false,
  error: null,
};

export const SocialAccountContext = createContext({
  socialAccounts: initialState11,
  getSocialAccounts: (data?: any) => {},
});

const reducer11 = (socialAccounts: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState11, loading: true };
    case "FETCHED":
      return { ...initialState11, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState11, loading: false, error: action.payload };
    default:
      return socialAccounts;
  }
};

export const SocialAccountContextProvider = (props: any) => {
  const [socialAccounts, dispatch] = useReducer(reducer11, initialState11);

  const getSocialAccounts = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getSocialAccounts(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <SocialAccountContext.Provider
      value={{
        socialAccounts,
        getSocialAccounts,
      }}
    >
      {props.children}
    </SocialAccountContext.Provider>
  );
};

export const useSocialAccountContext = () => {
  const socialAccountContext = useContext(SocialAccountContext);
  return socialAccountContext;
};

// for message

const initialState15 = {
  data: [],
  loading: false,
  error: null,
};

export const UserListContext = createContext({
  userList: initialState15,
  getUserList: (data?: any) => {},
});

const reducer15 = (userList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState15, loading: true };
    case "FETCHED":
      return { ...initialState15, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState15, loading: false, error: action.payload };
    default:
      return userList;
  }
};

export const UserListContextProvider = (props: any) => {
  const [userList, dispatch] = useReducer(reducer15, initialState15);

  const getUserList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getUserList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <UserListContext.Provider
      value={{
        userList,
        getUserList,
      }}
    >
      {props.children}
    </UserListContext.Provider>
  );
};

export const useUserListContext = () => {
  const userListContext = useContext(UserListContext);
  return userListContext;
};

const initialState16 = {
  data: [],
  loading: false,
  error: null,
};

export const MessageListContext = createContext({
  messageList: initialState16,
  getMessageList: (data?: any) => {},
});

const reducer16 = (messageList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState16, loading: true };
    case "FETCHED":
      return { ...initialState16, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState16, loading: false, error: action.payload };
    default:
      return messageList;
  }
};

export const MessageListContextProvider = (props: any) => {
  const [messageList, dispatch] = useReducer(reducer16, initialState16);

  const getMessageList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getMessageList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <MessageListContext.Provider
      value={{
        messageList,
        getMessageList,
      }}
    >
      {props.children}
    </MessageListContext.Provider>
  );
};

export const useMessageListContext = () => {
  const messageListContext = useContext(MessageListContext);
  return messageListContext;
};
