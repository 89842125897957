import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  getContactUsList,
  useAdminCommunicationDispatcher,
  useAdminCommunicationContext,
  removeContactUs,
} from "../../../../action/admin/AdminCommunicationAction";
import {
  ContactUsAllListModel,
  ContactUsRequestModel,
  ContactUsDeleteRequestModel,
} from "../../../../models/admin/AdminCommunication";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../../../context/GlobalSpinner";
import { useAdminMyProfileContext } from "../../../../action/MyProfileAdminAction";
import { useAdminMyJobContext } from "../../../../action/admin/AdminMyJobAction";

function ContactUsQueryList(props: any) {
  const {
    filter,
    userList,
    setCheckedListArray,
    checkedListArray,
    adminCommunicationDispatcher,
    setSelectedMessage,
    handleDeleteContact,
    setUserShowList,
    userShowList,
    // adminId
    globalSpinner,
  } = props;
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isUnCheckedAll, setIsUnCheckedAll] = useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [totalCount, setTotalCount] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSize = 10;
  const [totalPage, setTotalPage] = useState(1);

  React.useEffect(() => {
    ////console.log(currentPage);
    if (userList.data != undefined && currentPage == 1) {
      setUserShowList(userList.data);
      //setCurrentPage(1);
      setTotalPage(Math.ceil(userList.total / currentPageSize));
      setTotalCount(userList.total);
    }
    if (userList.data != undefined && currentPage > 1) {
      setUserShowList([...userList.data, ...userShowList]);
    }
  }, [userList]);

  const fetchMoreData = async () => {
    // //console.log("hhh");
    // //console.log(currentPage, totalPage);
    // //console.log(totalCount);
    if (currentPage >= totalPage) {
      setHasMore(false);
      return;
    } else {
      setCurrentPage(currentPage + 1);
      (async () => {
        globalSpinner.showSpinner();
        await getContactUsList(adminCommunicationDispatcher, {
          Page: currentPage + 1,
          PageSize: currentPageSize,
          SearchTerm: "",
          SortOrder: "",
        } as ContactUsRequestModel);
        globalSpinner.hideSpinner();
      })();
    }
  };

  const handleAllChecked = (e: any) => {
    let IdArray = [] as any[];
    if (e.target.checked && userShowList != undefined) {
      IdArray = [];
      userShowList.map((d: any) => {
        // //console.log(d)
        // if (Number(d.transactionCount) > 0) {
        IdArray.push(d.rowId);
        // }
      });
    } else {
      IdArray = [];
    }
    setIsCheckedAll(e.target.checked);
    setCheckedListArray(IdArray);
    setIsUnCheckedAll(false);
  };

  const handleSelected = (id: any) => {
    console.log(id);
    let sel = userShowList.filter((data: any) => data.rowId == id)[0];
    setSelectedMessage(sel);
  };

  const handleAllUnChecked = (e: any) => {
    let IdArray = [] as any[];
    if (e.target.checked) {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
    }
    setIsUnCheckedAll(e.target.checked);
  };

  const handleChecked = (e: any, item: any) => {
    let IdArray = [] as any[];
    if (e.target.checked) {
      IdArray.push(...checkedListArray, item.rowId);
    } else {
      IdArray = checkedListArray.filter((data: any) => {
        return data !== item.rowId;
      });
    }
    setCheckedListArray(IdArray);
  };

  React.useEffect(() => {
    if (checkedListArray.length > 0 && checkedListArray.length < 2) {
      let sel = userShowList.filter(
        (data: any) => data.rowId == checkedListArray[0]
      )[0];
      setSelectedMessage(sel);
    } else {
      setSelectedMessage({
        rowId: 0,
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    }
  }, [checkedListArray.length]);

  return (
    <React.Fragment>
      <div className=" m-t-25">
        <div className="row">
          <div className="col-sm-4 col-lg-4 pad_r_0">
            <input
              id="checkboxsel"
              type="checkbox"
              name="checkboxsel"
              checked={isCheckedAll}
              onChange={(e: any) => handleAllChecked(e)}
            />
            <label htmlFor="checkboxsel">
              <span /> Select All
            </label>
          </div>
          <div className="col-sm-5 col-lg-5 pad_r_0">
            <input
              id="checkboxusel"
              type="checkbox"
              name="checkboxusel"
              checked={isUnCheckedAll}
              onChange={(e: any) => handleAllUnChecked(e)}
            />
            <label htmlFor="checkboxusel">
              <span /> Deselect All
            </label>
          </div>
          <div className="col-sm-3 col-lg-3">
            <button
              className="btn delete_btn"
              onClick={handleDeleteContact}
              disabled={checkedListArray.length > 0 ? false : true}
            >
              Delete{" "}
            </button>
          </div>
          {/* <button className="btn delete_btn" onClick={fetchMoreData} >fetch </button> */}
        </div>
        <div
          className="message_chat_scroll"
          style={{ overflow: "hidden" }}
          tabIndex={0}
        >
          <div className="connect_scroll">
            {userShowList != undefined && (
              <ul>
                <InfiniteScroll
                  className="_custom-infinite-scroll-class"
                  height={600}
                  // dataLength={100}
                  // dataLength={totalCount}
                  dataLength={userShowList.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>No more data to load</b>
                    </p>
                  }
                >
                  {userShowList != undefined &&
                    userShowList.map((item: any, index: any) => {
                      const lowercasedFilter = filter.toLowerCase();
                      if (
                        String(item["email"])
                          .toLowerCase()
                          .includes(lowercasedFilter) ||
                        filter == ""
                      ) {
                        return (
                          <li>
                            {" "}
                            <a
                              // onClick={() => {
                              //   showSelectedConversation(item["messageTypeId"], item["messageSubject"], item["messageBody"], true)
                              // }
                              // }
                              className="admin_m1"
                            >
                              <div
                                className="candidates_check1"
                                style={{ paddingTop: 10 }}
                              >
                                <input
                                  id={`checkbox${item["rowId"]}`}
                                  type="checkbox"
                                  name={`checkbox${item["rowId"]}`}
                                  onChange={(e: any) => handleChecked(e, item)}
                                  checked={checkedListArray.includes(
                                    item["rowId"]
                                  )}
                                />
                                <label htmlFor={`checkbox${item["rowId"]}`}>
                                  <span />
                                </label>
                              </div>
                              <div
                                className="connect_con"
                                style={{ paddingLeft: 10 }}
                                onClick={(id: any) =>
                                  handleSelected(item["rowId"])
                                }
                              >
                                <div className="connect_con_name">
                                  {" "}
                                  {item["email"]}
                                </div>
                                <div className="connect_con_des">
                                  {item["name"]}
                                </div>
                              </div>
                              <div className="chat_time_sec1">
                                <div className="chat_time">
                                  {moment(new Date(item["createdDate"])).format(
                                    "DD-MM-YYYY"
                                  )}
                                </div>
                                <div className="chat_time">
                                  {moment(new Date(item["createdDate"])).format(
                                    "HH:mm:ss"
                                  )}
                                </div>
                              </div>
                            </a>
                          </li>
                        );
                      }
                    })}
                </InfiniteScroll>
              </ul>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const ContactUsComponent = () => {
  const globalSpinner = useContext(GlobalSpinnerContext);
  const adminCommunicationDispatcher = useAdminCommunicationDispatcher();
  const adminCommunicationContext = useAdminCommunicationContext();
  const { contactUsAllList, contactUsDeleteResult } = adminCommunicationContext;
  // const myVendorProfileContext = useVendorMyProfileContext();
  // const { loggedUserId } = myVendorProfileContext;
  const myProfileAdminContext = useAdminMyProfileContext();
  const {
    //myProfile,
    // basicInfo,
    loggedAdminId,
  } = myProfileAdminContext;
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;
  const adminId = loggedAdminId;
  const [filter, setFilter] = useState<string>("");
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [selectedMessage, setSelectedMessage] = useState({
    rowId: 0,
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [userShowList, setUserShowList] = useState(
    Array<ContactUsAllListModel>()
  );
  const [toggleBlockDisplay, setToggleBlockDisplay] = useState<string>("none");
  React.useEffect(() => {
    (async () => {
      globalSpinner.showSpinner();
      await getContactUsList(adminCommunicationDispatcher, {
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      } as ContactUsRequestModel);
      globalSpinner.hideSpinner();
    })();
  }, [adminId, contactUsDeleteResult]);

  // React.useEffect(() => {
  //   if (contactUsAllList != undefined) {
  //     // //console.log(contactUsAllList);
  //     globalSpinner.hideSpinner();
  //   }
  // }, [contactUsAllList]);

  const handleDeleteContact = () => {
    ////console.log(checkedListArray);
    if (checkedListArray.length > 0) {
      (async () => {
        globalSpinner.showSpinner();
        await removeContactUs(adminCommunicationDispatcher, {
          ContactId: checkedListArray,
        } as ContactUsDeleteRequestModel);
        globalSpinner.hideSpinner();
      })();
    }
  };

  const handleToggle = () => {
    if (toggleBlockDisplay.includes("none")) {
      setToggleBlockDisplay("block");
    } else {
      setToggleBlockDisplay("none");
    }
  };

  return (
    <>
      {/*=================Content Section Start================*/}
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Contact Queries</h1>
          <div className="action_btn1">
            <button
              type="button"
              className="actions_menu"
              onClick={handleToggle}
            >
              <i className="fa fa-align-left" aria-hidden="true" />
            </button>
            <div
              className="user_menu"
              style={{
                display: `${toggleBlockDisplay}`,
              }}
            >
              <div className="action_sec">
                <ul>
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isCreateorEditJobPermission ? (
                    <li>
                      <Link to="/admin/communication/bulkSMS">
                        Bulk Sent SMS
                      </Link>
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isCreateorEditJobPermission ? (
                    <li>
                      {" "}
                      <Link to="/admin/communication/bulkEmail">
                        Bulk Sent Email
                      </Link>{" "}
                    </li>
                  ) : null}
                  <li>
                    <Link to="/admin/communication/messages">Messages</Link>{" "}
                  </li>
                  <li>
                    <Link to="/admin/communication/contactUs">
                      Contact us Queries
                    </Link>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row " style={{ paddingTop: 10 }}>
            <div className="col-sm-5 col-lg-4 p-r-0">
              <div className="panel panel-default panel_n">
                <div className="panel-body panel-body1">
                  <div className="connect_left">
                    <div>
                      <input
                        type="email"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="form-control"
                        placeholder="Search"
                      />
                      {/* // <input type="email" className="form-control" placeholder="Search" /> */}
                      <div className="search_icon">
                        <i className="fa fa-search" aria-hidden="true" />
                      </div>
                    </div>
                    {contactUsAllList != undefined && (
                      <ContactUsQueryList
                        filter={filter}
                        userList={contactUsAllList}
                        setCheckedListArray={setCheckedListArray}
                        checkedListArray={checkedListArray}
                        adminCommunicationDispatcher={
                          adminCommunicationDispatcher
                        }
                        setSelectedMessage={setSelectedMessage}
                        handleDeleteContact={handleDeleteContact}
                        setUserShowList={setUserShowList}
                        userShowList={userShowList}
                        //adminId={adminId}
                        globalSpinner={globalSpinner}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedMessage.rowId > 0 && (
              <div className="col-sm-7 col-lg-8">
                <div className="panel panel-default panel_n">
                  <div className="panel-body panel-body1">
                    <div className=" form-group">
                      <div className="col-sm-2">Subject</div>
                      <div className="col-sm-10 ">
                        {selectedMessage.subject}
                      </div>
                    </div>
                    <div className=" clearfix" />
                    <div className=" form-group">
                      <div className="col-sm-2 m_t_15">Comment</div>
                      <div className="col-sm-10 m_t_15">
                        {selectedMessage.message}
                      </div>
                    </div>
                    <div className=" clearfix" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="clearfix" />
        </div>
      </div>
      {/*=================Content Section End================*/} s
    </>
  );
};

export default ContactUsComponent;
