import React from "react";
import { Switch, Route, useRouteMatch, withRouter } from "react-router-dom";
import {
  getAllVendorCount,
  useJobTypeContext,
  useJobTypeDispatcher,
} from "../../../action/general/JobTypeAction";
import {
  getLoggedUserId,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import {
  getVendorJobAppliedList,
  setVendorJobAppliedSave,
  useVendorJobAppliedContext,
  useVendorJobAppliedDispatcher,
} from "../../../action/vendor/VendorJobAppliedAction";
import {
  getVendorJobBookMarkList,
  useVendorJobBookMarkContext,
  useVendorJobBookMarkDispatcher,
} from "../../../action/vendor/VendorJobBookMarkAction";
import {
  getVendorSuggestedJobList,
  getVendorSuggestedJobTitleList,
  useVendorSuggestedJobContext,
  useVendorSuggestedJobDispatcher,
} from "../../../action/vendor/VendorSuggestedJobAction";
import { VendorCountAllRequestModel } from "../../../models/general/JobType";
import { VendorGetAllJobRequestModel } from "../../../models/vendor/VendorJobApplied";
import {
  VendorSuggestedJobTitleRequestModel,
  VendorSuggestedRequestModel,
} from "../../../models/vendor/VendorSuggestedJob";
import AuthService from "../../../services/AuthService";
import VendorDashboardContainer from "../dashboard/VendorDashBoardContainer";
import VendorJobAppliedComponent from "../jobs_applied/VendorJobAppliedComponent";
import VendorSavedJobContainer from "../saved_jobs/VendorSavedJobContainer";
import VendorLayoutSidebar from "./VendorLayoutSidebar";
import VendorMessageContainer from "../message/VendorMessageContainer";
import VendorMessageComponent from "../message/VendorMessageComponent";
import VendorProfileContainer from "./../my_profile/VendorProfileContainer";
import { MyProfileVendorContextProvider } from "../../../context/MyProfileVendorContext";
import {
  SocialAccountContextProvider,
  VendorContextProvider,
  VendorProfileImageContextProvider,
} from "../../../context/vendor/VendorMyProfile";
import {
  getVendorProfileImage,
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../../action/MyProfileVendorAction";
import { VendorProfileImageRequestModel } from "../../../models/vendor/MyProfileSelectBoxData";

interface IVendorLayoutComponentProps {}

interface IVendorLayoutComponentState {}

const initialState = {};

// const authorizationToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8';
// let loggedUserId=1;

const VendorLayoutComponent: React.FC<IVendorLayoutComponentProps> = (
  props
) => {
  const [
    vendorLayoutComponentState,
    setVendorLayoutComponentState,
  ] = React.useState<IVendorLayoutComponentState>(initialState);

  const authorizationToken = AuthService.accessToken;

  const { path, url } = useRouteMatch();

  const jobAppliedVendorDispatcher = useVendorJobAppliedDispatcher();
  const jobAppliedVendorContext = useVendorJobAppliedContext();
  const {
    vendorJobAppliedSaveRespond,
    vendorGetAllJobList,
  } = jobAppliedVendorContext;

  const jobTypeDispatcher = useJobTypeDispatcher();
  const jobTypeContext = useJobTypeContext();
  const { jobType } = jobTypeContext;

  const vendorSuggestedJobDispatcher = useVendorSuggestedJobDispatcher();
  const vendorSuggestedJobContext = useVendorSuggestedJobContext();
  const { vendorSuggestedJobs } = vendorSuggestedJobContext;

  const vendorJobBookMarkDispatcher = useVendorJobBookMarkDispatcher();
  const vendorJobBookMarkContext = useVendorJobBookMarkContext();
  const { bookmarkSaveResult } = vendorJobBookMarkContext;

  const myProfileDispatcher = useMyProfileDispatcher();
  const myVendorProfileDispatcher = useVendorMyProfileDispatcher();

  const myVendorProfileContext = useVendorMyProfileContext();
  const {
    basicInfo,
    // loggedUserId,
    myProfile,
    myProfileProgressBar,
    profileImage,
  } = myVendorProfileContext;

  const myProfileVContext = useMyProfileContext();
  const { loggedUserId } = myProfileVContext;

  let user = AuthService.currentUser;

  // React.useEffect(() => {
  //   if (authorizationToken != null && user?.id != null)
  //     (async () => {
  //       await getLoggedUserId(
  //         myProfileDispatcher,
  //         parseInt(user.id),
  //         authorizationToken
  //       );
  //     })();
  // }, [authorizationToken]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getVendorJobAppliedList(
          jobAppliedVendorDispatcher,
          {
            vendorId: loggedUserId,
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as VendorGetAllJobRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getAllVendorCount(
          jobTypeDispatcher,
          {
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
            vendorId: loggedUserId,
          } as VendorCountAllRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getVendorSuggestedJobList(
          vendorSuggestedJobDispatcher,
          {
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
            vendorId: loggedUserId,
          } as VendorSuggestedRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getVendorJobBookMarkList(
          vendorJobBookMarkDispatcher,
          {
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
            vendorId: loggedUserId,
          } as VendorGetAllJobRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getVendorSuggestedJobTitleList(
          vendorSuggestedJobDispatcher,
          {
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
            vendorId: loggedUserId,
          } as VendorSuggestedJobTitleRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getVendorProfileImage(
          myVendorProfileDispatcher,
          {
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: true,
            sortOrder: "",
            vendorId: loggedUserId,
          } as VendorProfileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  return (
    <div id="wrapper">
      {/* <VendorProfileImageContextProvider> */}
      <MyProfileVendorContextProvider> 
      <VendorLayoutSidebar />
      </MyProfileVendorContextProvider>
      {/* </VendorProfileImageContextProvider> */}
      <Route exact path={`${path}`}>
        <VendorDashboardContainer />
      </Route>
      <Route exact path={`${path}/jobs-applied`}>
        <VendorJobAppliedComponent />
      </Route>
      <Route exact path={`${path}/saved-jobs`}>
        <VendorSavedJobContainer />
      </Route>
      <Route exact path={`${path}/messages`}>
        <VendorMessageContainer />
      </Route>
      <Route exact path={`${path}/my-profile`}>
        {/* <VendorContextProvider>
          <MyProfileVendorContextProvider> */}
        <SocialAccountContextProvider>
          <VendorProfileContainer />
        </SocialAccountContextProvider>
        {/* </MyProfileVendorContextProvider>
          </VendorContextProvider> */}
      </Route>
    </div>
  );
};
export default withRouter(VendorLayoutComponent);
