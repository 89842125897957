import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  ClientReportResultModel,
  VendorReportResultModel,
  VendorTotalReportResultModel,
} from "../../models/admin/VendorReport";
import {
  IVendorReportAction,
  VendorReportReducer,
} from "../../action/admin/VendorReportAction";

export const VendorReportDispatcherContext = React.createContext<React.Dispatch<IVendorReportAction> | null>(
  null
);
export const VendorReportStateContext = React.createContext<IVendorReportManagementState | null>(
  null
);

export interface IVendorReportManagementProps
  extends RouteComponentProps<any> {}

export interface IVendorReportManagementState {
  vendorReportsList: VendorReportResultModel;
  totalReportList: VendorTotalReportResultModel;
  clientReportsList: ClientReportResultModel;
}

export const initialVendorReportManagementState = {
  vendorReportsList: {} as VendorReportResultModel,
  totalReportList: {} as VendorTotalReportResultModel,
} as IVendorReportManagementState;

export const VendorReportContextProvider: React.FC = ({ children }) => {
  const [vendorReportState, dispatcher] = useImmerReducer(
    VendorReportReducer,
    initialVendorReportManagementState
  );

  return (
    <VendorReportDispatcherContext.Provider value={dispatcher}>
      <VendorReportStateContext.Provider value={vendorReportState}>
        {children}
      </VendorReportStateContext.Provider>
    </VendorReportDispatcherContext.Provider>
  );
};
