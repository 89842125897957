import React, { useState } from "react";
import {
  Table,
  Tag,
  Space,
  Select,
  Button,
  Divider,
  Popconfirm,
  Row,
  Col,
  Input,
  Form,
} from "antd";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import {
  ChangeBannerStatus,
  DeleteBanner,
  EditBanner,
  GetAllBanners,
  SaveBanners,
  useBannerContext,
  useBannerDispatcher,
} from "../../../action/admin/BannerAction";
import AuthService from "../../../services/AuthService";
import { GetAllBannerRequestModel } from "../../../models/admin/Banner";
import { environment } from "../../../environments/environment";
import { values } from "lodash";

const { Column, ColumnGroup } = Table;
const { Option } = Select;

const Banner = () => {
  const [filename1, setFileName1] = useState("");
  const [obj1, setObj1] = useState<any>({ file: null });
  const [rowId, setRowId] = useState(0);
  const [bannerTypeId, setBannerTypeId] = useState(0);
  const authorizationToken = AuthService.accessToken;

  const bannerDispatcher = useBannerDispatcher();
  const bannerContext = useBannerContext();
  const {
    bannersList,
    deleteBannerRespond,
    addBannerRespond,
    bannerStatusRespond,
    editBaneerRespond,
  } = bannerContext;

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await GetAllBanners(
          bannerDispatcher,
          {
            BannerStatus: true,
            Page: 1,
            PageSize: 20,
            SearchTerm: "",
            ShowInactive: true,
            SortOrder: "",
          } as GetAllBannerRequestModel,
          authorizationToken
        );
      })();
      // form.setFieldsValue({
      //   bannerName: "",
      //   bannerType: 0,
      // });
    }
  }, [
    authorizationToken,
    deleteBannerRespond,
    addBannerRespond,
    bannerStatusRespond,
  ]);

  React.useEffect(() => {
    if (authorizationToken != null && editBaneerRespond.data != undefined) {
      console.log(66, editBaneerRespond.data.title);
      setRowId(editBaneerRespond.data.rowId);
      setFileName1(editBaneerRespond.data.bannerFileName);
      form.setFieldsValue({
        title: editBaneerRespond.data.title,
        bannerType: editBaneerRespond.data.bannerTypeId,
        description: editBaneerRespond.data.description,
        bannerCaption1: editBaneerRespond.data.bannerCaption1,
        bannerStatus: editBaneerRespond.data.bannerStatus == true ? "A" : "I",
      });
    }
  }, [editBaneerRespond]);

  const handleClickEdit = (id: any) => {
    if (authorizationToken != null) {
      (async () => {
        await EditBanner(
          bannerDispatcher,
          {
            RowId: id,
          } as any,
          authorizationToken
        );
      })();
    }
  };

  const confirm = (id: any) => {
    if (authorizationToken != null) {
      (async () => {
        await DeleteBanner(
          bannerDispatcher,
          {
            RowId: id,
          } as any,
          authorizationToken
        );
      })();
    }
  };

  function cancel(e: any) {
    console.log(e);
  }

  const handleDrop = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "application/pdf"].includes(files[0].type) &&
        files[0].size < 1000000 * 5
      ) {
        //console.log(files[0]);
        if (files.length > 0) {
          setObj1({
            ...obj1,
            ["file"]: files[0],
          });
          setFileName1(files[0].name);
        }
      }
    }
  };

  const handleStatusChange = (id: any) => {
    if (authorizationToken != null) {
      (async () => {
        await ChangeBannerStatus(
          bannerDispatcher,
          {
            RowId: id,
          } as any,
          authorizationToken
        );
      })();
    }
  };

  function handleChange(value: any) {
    console.log(`selected ${value}`);
    if (value.includes("A")) {
      console.log(11111);
    } else {
      console.log(22222);
    }
    // if (authorizationToken != null) {
    //   (async () => {
    //     await DeleteBanner(
    //       bannerDispatcher,
    //       {
    //         RowId: id,
    //       } as any,
    //       authorizationToken
    //     );
    //   })();
    // }
  }

  function handleChangeOne(value: any) {
    console.log(`selected ${value}`);
    setBannerTypeId(parseInt(value));
  }

  function handleChangeTwo(value: any) {
    console.log(`selected ${value}`);
  }

  const onFinish = (values: any) => {
    console.log("Success:", values);

    const formData = new FormData();
    formData.append("RowId", JSON.stringify(rowId));
    formData.append("Title", String(values.title));
    formData.append("BannerFileName", String(filename1));
    formData.append(
      "Description",
      String(values.description == undefined ? "" : values.description)
    );
    formData.append(
      "BannerCaption1",
      String(values.bannerCaption1 == undefined ? "" : values.bannerCaption1)
    );
    formData.append(
      "BannerStatus",
      JSON.stringify(values.bannerStatus == "A" ? true : false)
    );
    formData.append(
      "BannerTypeId",
      JSON.stringify(
        values.bannerType == undefined ? bannerTypeId : values.bannerType
      )
    );
    formData.append("IsActive", JSON.stringify(true));
    formData.append("BannerFile", obj1.file);

    if (authorizationToken != null) {
      (async () => {
        await SaveBanners(bannerDispatcher, formData, authorizationToken);
      })();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <Divider />
          <div
            className="container"
            style={{
              paddingTop: 22,
              backgroundColor: "white",
              paddingBottom: 22,
            }}
          >
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <div className="col-sm-3">
                  <div style={{ marginTop: -17 }}>
                    <FileUploads
                      accept=".pdf,.jpg,.jpeg,.png"
                      onChange={(file: any) => {
                        handleDrop([file]);
                      }}
                      name="Upload Banner"
                      disabled={false}
                    />
                  </div>
                </div>

                <div className="col-sm-2">
                  <Form.Item
                    label=""
                    style={{ width: 290 }}
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please input  BannerName!",
                      },
                    ]}
                  >
                    <Input placeholder="Banner Name" />
                  </Form.Item>
                </div>
                <div className="col-sm-2">
                  <Form.Item
                    label=""
                    name="bannerType"
                    rules={[
                      {
                        required: false,
                        message: "Please input your Banner Type!",
                      },
                    ]}
                  >
                    {" "}
                    <Select
                      defaultValue={"1"}
                      style={{ width: 160, marginLeft: 31, marginTop: 4 }}
                      onChange={handleChangeOne}
                    >
                      <Option value={"1"}>Home Page</Option>
                      <Option value={"2"}>Home Page Bottom</Option>
                      <Option value={"3"}>other Pages</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-2">
                  {" "}
                  <Form.Item
                    label=""
                    name="bannerStatus"
                    rules={[
                      {
                        required: false,
                        message: "Please select!",
                      },
                    ]}
                  >
                    <Select
                      defaultValue={"A"}
                      style={{ width: 140, marginTop: 4 }}
                      onChange={handleChangeTwo}
                    >
                      <Option value={"A"}>Active</Option>
                      <Option value={"I"}>InActive</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-2"></div>
                <div className="col-sm-1"></div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <Form.Item
                    // label=""
                    name="description"
                    style={{ width: 500 }}
                    rules={[
                      {
                        required: false,
                        message: "Please input your description!",
                      },
                    ]}
                  >
                    <Input placeholder="Banner Description" />
                  </Form.Item>
                </div>
                <div className="col-sm-3">
                  <Form.Item
                    label=""
                    style={{ width: 500 }}
                    name="bannerCaption1"
                    rules={[
                      {
                        required: false,
                        message: "Please input your caption1!",
                      },
                    ]}
                  >
                    <Input placeholder="Banner Caption 1" />
                  </Form.Item>
                </div>
                <div style={{ paddingLeft: 63 }} className="col-sm-1">
                  {" "}
                  <Form.Item
                    label=""
                    name="password"
                    rules={[
                      {
                        required: false,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    {" "}
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      // style={{ marginTop: 30 }}
                      type="submit"
                    >
                      Save
                    </button>
                  </Form.Item>
                </div>
                <div className="col-sm-4"> </div>
              </div>
            </Form>
          </div>

          {/* <Row>
            <Col span={2} order={1}></Col>
            <Col span={4} order={2}>
              <FileUploads
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={(file: any) => {
                  handleDrop([file]);
                }}
                name="Upload Banner"
                disabled={false}
              />
            </Col>
            <Col span={4} order={2}>
              <Input placeholder="Basic usage" />
            </Col>
            <Col span={4} order={2}>
              <Select
                defaultValue={"A"}
                style={{ width: 120 }}
                onChange={handleChange}
              >
                <Option value={"A"}>Active</Option>
                <Option value={"I"}>InActive</Option>
              </Select>
            </Col>
            <Col span={4} order={2}>
              <Select
                defaultValue={"A"}
                style={{ width: 120 }}
                onChange={handleChange}
              >
                <Option value={"A"}>Active</Option>
                <Option value={"I"}>InActive</Option>
              </Select>
            </Col>
            <Col span={4} order={2}>
              <Button>Save</Button>
            </Col>
            <Col span={2} order={2}></Col>
          </Row> */}

          <Divider />

          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title"> Page Banner List</h4>
                {/* <p className="card-description">
                {" "}
                Add class <code>.table-striped</code>
              </p> */}
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Banner Image</th>

                        <th>Title</th>
                        <th>Description</th>
                        <th>Banner Caption 1</th>
                        <th> </th>
                        <th> Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bannersList != undefined && bannersList.data != undefined
                        ? bannersList.data.map((data, index) => {
                            return (
                              <tr>
                                <td className="py-1">
                                  <img
                                    style={{
                                      height: 100,
                                      width: 100,
                                      borderRadius: "5%",
                                    }}
                                    src={
                                      environment.baseUrl +
                                      "/Upload/Banners/" +
                                      data.bannerFileName
                                    }
                                    alt="image"
                                  />
                                </td>
                                <td>{data.title}</td>
                                <td>{data.description}</td>
                                <td>{data.bannerCaption1}</td>
                                <td>{data.bannerStatus}</td>
                                <td>
                                  <select
                                    style={{ padding: 2, marginLeft: 7 }}
                                    name="cars"
                                    id="cars"
                                    onChange={(e: any) =>
                                      handleStatusChange(data.rowId)
                                    }
                                    defaultValue={
                                      data.bannerStatus == true
                                        ? "Active"
                                        : "InActive"
                                    }
                                  >
                                    <option value="Active">Active</option>
                                    <option value="InActive">InActive</option>
                                  </select>

                                  <button
                                    onClick={() => handleClickEdit(data.rowId)}
                                    className="btn btn-info save-event waves-effect waves-light"
                                    style={{ marginLeft: 12 }}
                                  >
                                    Edit
                                  </button>
                                  <Popconfirm
                                    title="Are you sure to delete this task?"
                                    onConfirm={() => confirm(data.rowId)}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <button
                                      //  onClick={() => handleClick(data.rowId)}
                                      className="btn btn-danger save-event waves-effect waves-light"
                                      style={{ marginLeft: 12 }}
                                    >
                                      Delete
                                    </button>
                                  </Popconfirm>
                                </td>
                              </tr>
                            );
                          })
                        : null}

                      {/* <tr>
                      <td className="py-1">
                        <img
                          src="../../assets/images/faces-clipart/pic-2.html"
                          alt="image"
                        />
                      </td>
                      <td>Messsy Adam</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            style={{ width: "75%" }}
                            aria-valuenow={75}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </td>
                      <td>$245.30</td>
                      <td>July 1, 2015</td>
                    </tr>
                   */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Divider />
        </div>
      </div>
    </>
  );
};

export default Banner;
