import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Countdown from "react-countdown";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  SetAccessible,
  useGlobelCounterContext,
  useGlobelCounterDispatcher,
} from "../../../action/general/GlobelCounterAction";
import { useMyProfileContext } from "../../../action/MyProfileAction";
import { sendPhoneOtp, verifyCode } from "../../../apis/misc";
import { useGlobalSpinnerContext } from "../../../context/GlobalSpinner";
import CandidateDigilockerComponent from "./CondidateDigilockerComponent";
import CountDownTime from "./CountDownForDigilocker";
import DigiLockerOtpModal from "./DigiLockerOtpModal";

const DigilockerOtpVarification = () => {
  let history = useHistory();
  const [currentOpt, setCurrentOpt] = useState("");
  const [isShow, setIsShow] = useState(true);
  const [showTimer, setShowTimer] = useState(false);
  const [isCall, setIsCall] = useState(false);

  const myProfileContext = useMyProfileContext();
  const { loggedUserId, basicInfo } = myProfileContext;
  const globalSpinner = useGlobalSpinnerContext();
  const globelCounterDispatcher = useGlobelCounterDispatcher();
  const globelCounterContext = useGlobelCounterContext();
  const { isAccessible, startTime } = globelCounterContext;
  var d1 = new Date();
  var d2 = new Date();
  let diffTime = (startTime - d2.getTime()) / 1000;

  console.log(323232, (startTime - d2.getTime()) / 1000);
  console.log(323233, diffTime / 1000);
  // const handleCancleClick = () => {};

  return (
    <>
      {isAccessible && diffTime > -600 ? (
        <>
          <CandidateDigilockerComponent />
          {/* <CountDownTime autostart={isAccessible} /> */}
        </>
      ) : (
        <>
          <DigiLockerOtpModal
            basicInfo={basicInfo !== undefined ? basicInfo : {}}
            d1={d1}
          />

          {/* <ToastContainer />

          <Modal
            show={isShow}
            onHide={() => {
              setIsShow(!isShow);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify OTP</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="job_search_forms9">
                <div className="cr_serach_br" style={{ width: 635 }}>
                  <input
                    name="otp"
                    // ref={register({ required: false })}
                    type="text"
                    className="form-control"
                    placeholder="please enter your OTP"
                    onChange={(e: any) => setCurrentOpt(e.target.value)}
                  />
                </div>
              </div>
            </Modal.Body>
            <div className="modal-footer  m-t-30">
              <button
                className="btn btn-success"
                style={{
                  marginLeft: -225,
                  backgroundColor: "green",
                }}
                type="button"
                onClick={handleSubmitButtonClick}
              >
                Submit
              </button>

              <button
                onClick={() => history.push("/candidate")}
                className="btn btn-default"
                type="button"
              >
                cancel
              </button>
            </div>

            <div className="clearfix" />
          </Modal> */}
        </>
      )}
    </>
  );
};

export default DigilockerOtpVarification;
