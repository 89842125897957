import React from "react";
import VendorReportComponent from "./VendorReportComponent";

const VendorReportContainer = () => {
  return (
    <>
      <VendorReportComponent />
    </>
  );
};

export default VendorReportContainer;
