import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import {
  GoogleLoginButton,
  FacebookLoginButton,
  TwitterLoginButton,
  LinkedinLoginButton,
} from "./components/SocialLoginButton";
import { GlobalSpinnerContext } from "../../context/GlobalSpinner";
import { registerMe, createCandidate, sendEmailOpt } from "../../apis/misc";
import { reactHookFormServerErrors } from "../../utils/utility";
import { toast, ToastContainer } from "react-toastify";
import PhoneNumberWithCode from "../components/PhoneNumberWithCode";

interface IRegistrationState {
  fullName: string;
  email: string;
  countryCode: string;
  mobileNumber: string;
  password: string;
  passwordConfirm: string;
  userRole: string;
}

function Registration(props: any) {
  const defaultValues = {
    fullName: "",
    email: "",
    countryCode: "+91",
    mobileNumber: "",
    password: "",
    passwordConfirm: "",
    userRole: "Candidate",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    setError,
    control,
  } = useForm<IRegistrationState>({
    defaultValues,
  });

  const globalSpinner = useContext(GlobalSpinnerContext);
  const onSubmit = (data: any) => {
    //console.log(data);

    // props.history.push({
    //   pathname: '/otp-verification-email',
    //   search: `?countryCode=${encodeURIComponent(data["countryCode"])}&mobileNumber=${encodeURIComponent(data["mobileNumber"])}&email=${encodeURIComponent(data["email"])}`,
    //   state: { countryCode: data["countryCode"], mobileNumber: data["mobileNumber"], email: data["email"] }
    // });

    globalSpinner.showSpinner();

    // Developer : Abhilash C K
    // Date : 28-01-2021
    // Role checking
    const userRole = data["userRole"];
    let roleId = 1;

    if (userRole == "Candidate") {
      roleId = 4;
    } else if (userRole == "Vendor") {
      roleId = 2;
    }

    //console.log("--------------roleId----------" + roleId);

    registerMe({
      RowId: 0,
      UserId: 0,
      RoleId: roleId,
      Email: data["email"],
      UserName: data["fullName"],
      CountryCode: data["countryCode"],
      PhoneNumber: data["mobileNumber"],
      Password: data["password"],
      EmployerFlag: false,
      VendorFlag: data["userRole"] == "Vendor",
      EmployeeFlag: false,
      CandidateFlag: data["userRole"] == "Candidate",
      IsActive: true,
    })
      .then((res: any) => {
        //console.log(res.data);

        if (res.data.isSuccess) {
          globalSpinner.hideSpinner();
          props.history.push({
            pathname: "/otp-verification-email",
            search: `?countryCode=${encodeURIComponent(
              data["countryCode"]
            )}&mobileNumber=${encodeURIComponent(
              data["mobileNumber"]
            )}&email=${encodeURIComponent(data["email"])}`,
            state: {
              countryCode: data["countryCode"],
              email: data["email"],
              mobileNumber: data["mobileNumber"],
              user: res.data["rowId"],
              name: data["fullName"],
              userRoleId: roleId,
              password: data["password"],
            },
          });

          // createCandidate({
          //   "RowId": 0, // set value as 0
          //   "CandidateId": 0, // set value as 0
          //   "NamePrefixId": 0,
          //   "FirstName": null,
          //   "LastName": null,
          //   "GenderId": 0,
          //   "MartialStatusId": 0,
          //   "Email": data["email"],
          //   "CountryCode": data["countryCode"],
          //   "PhoneNumber": data["mobileNumber"],
          //   "TelephoneNumber": null,
          //   "PassportInformationId": 0,
          //   "LicenceInformationId": 0,
          //   "PanNo": null,
          //   "AadharNo": null,
          //   "CategoryId": 0,
          //   "DesignationId": 0,
          //   "JobTypeId": 0,
          //   "CurrentCTC": 0,
          //   "ExpectedPackage": 0,
          //   "IndustryId": 0,
          //   "FunctionalAreaId": 0,
          //   "JobRoleId": 0,
          //   "Skills": null,
          //   "ProfileSummary": null,
          //   "Dob": "2020-11-02T06:34:32.004Z",
          //   "CurrentAddress1": null,
          //   "CurrentAddress2": null,
          //   "PermanantAddress1": null,
          //   "PermanantAddress2": null,
          //   "City": null,
          //   "StateId": 0,
          //   "CountryId": 0,
          //   "ZipCode": null,
          //   "UserId": Number(res.data["rowId"]), // set value as above created userid
          //   "IsActive": true
          // }).then((res: any) => {

          //   //console.log(res.data);

          //   props.history.push({
          //     pathname: '/otp-verification-email',
          //     search: `?countryCode=${encodeURIComponent(data["countryCode"])}&mobileNumber=${encodeURIComponent(data["mobileNumber"])}&email=${encodeURIComponent(data["email"])}`,
          //     state: { countryCode: data["countryCode"], email: data["email"], mobileNumber: data["mobileNumber"] }
          //   });

          //   globalSpinner.hideSpinner();

          // }).catch((err: any) => {

          //   //console.log(err);
          //   globalSpinner.hideSpinner();
          //   toast.error("Error occurred while registration.");

          // });
        } else {
          globalSpinner.hideSpinner();
          if (
            res.data.message.includes("Email and phonenumber not verified.") ||
            res.data.message.includes("Email not verified.") ||
            res.data.message.includes("Phone Number not verified.")
          ) {
            props.history.push({
              pathname: "/otp-verification-email",
              search: `?countryCode=${encodeURIComponent(
                data["countryCode"]
              )}&mobileNumber=${encodeURIComponent(
                data["mobileNumber"]
              )}&email=${encodeURIComponent(data["email"])}`,
              state: {
                countryCode: data["countryCode"],
                email: data["email"],
                mobileNumber: data["mobileNumber"],
                user: res.data["entityId"],
                name: data["fullName"],
                userRoleId: roleId,
              },
            });
          }
          // if (res.data.message.includes("Phone Number not verified.")) {
          //   props.history.push({
          //     pathname: '/otp-verification-mobile',
          //     search: `?countryCode=${encodeURIComponent(data["countryCode"])}&mobileNumber=${encodeURIComponent(data["mobileNumber"])}&email=${encodeURIComponent(data["email"])}`,
          //     state: { countryCode: data["countryCode"] || "", mobileNumber: data["mobileNumber"], email: data["email"], user: res.data["entityId"], name: data["fullName"] }
          //   });
          // }
          if (/Email/i.test(res.data.message)) {
            if (
              res.data.message.includes(
                "Email and phone number already exist."
              ) ||
              res.data.message.includes("Email already exist.")
            ) {
              reactHookFormServerErrors(
                {
                  email: [
                    "Email is already registered! Please try with the new email.",
                  ],
                },
                setError
              );
            } else {
              reactHookFormServerErrors(
                {
                  email: [res.data.message],
                },
                setError
              );
            }
          }
          if (/Phone/i.test(res.data.message)) {
            if (
              res.data.message.includes(
                "Email and phone number already exist."
              ) ||
              res.data.message.includes("Phone number already exist.")
            ) {
              reactHookFormServerErrors(
                {
                  mobileNumber: [
                    "Phone number is already registered! Please try with the new number.",
                  ],
                },
                setError
              );
            } else {
              reactHookFormServerErrors(
                {
                  mobileNumber: [res.data.message],
                },
                setError
              );
            }
          }
          if (
            /Post/i.test(res.data.message) ||
            /Error/i.test(res.data.message)
          ) {
            toast.error("Error occurred while registration.");
            // reactHookFormServerErrors({
            //   passwordConfirm: [res.data.message]
            // }, setError);
          }

          // reactHookFormServerErrors({
          //   email: [res.data.message]
          // }, setError);
        }
      })
      .catch((err: any) => {
        toast.error("Error occurred while registration.");
        //console.log(err);
        globalSpinner.hideSpinner();
      });
  };

  watch("userRole"); // rerender suring manual field update

  //console.log(getValues("userRole"));

  const userRoleClass = getValues("userRole") || defaultValues.userRole;

  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [showPassword2, setShowPassword2] = useState<Boolean>(false);

  return (
    <div className=" container">
      <ToastContainer />
      <div className="row">
        <div className="col-sm-6 desk">
          <div className="login_img">
            <img
              src={require("./../../images/register.jpg")}
              className="img-responsive"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-5 col-lg-offset-1">
          <div className="registration_forms registration_sec">
            <div className="sub_heading">
              <h2>Create An Account</h2>
              {/* <div className="connects_us">Connect With </div>
              <div className="sign_with1">
                <GoogleLoginButton />
                <FacebookLoginButton />
                <TwitterLoginButton />
                <LinkedinLoginButton />
              </div> */}
              <div className="already_login1">
                {/* Or  */}
                use your email for registration:{" "}
              </div>
            </div>
            <div id="horizontalTab">
              <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <input
                  type="hidden"
                  name="userRole"
                  ref={register({ required: true })}
                />{" "}
                {/* hidden field for userRole */}
                <ul className="resp-tabs-list">
                  <li
                    className={
                      userRoleClass == "Candidate"
                        ? "resp-tab-item resp-tab-active"
                        : "resp-tab-item"
                    }
                    onClick={() => {
                      setValue("userRole", "Candidate");
                    }}
                  >
                    Candidate
                  </li>
                  <li
                    className={
                      userRoleClass == "Vendor"
                        ? "resp-tab-item resp-tab-active"
                        : "resp-tab-item"
                    }
                    onClick={() => {
                      setValue("userRole", "Vendor");
                    }}
                  >
                    Vendor
                  </li>
                </ul>
                <div className="resp-tabs-container">
                  <div>
                    <div className="pad_t_15">
                      <div className="form-group">
                        <input
                          type="text"
                          name="fullName"
                          ref={register({
                            required: "Full name is required",
                            maxLength: {
                              value: 50,
                              message:
                                "Full name max length should not be greater than 50",
                            },
                            pattern: {
                              value:
                                userRoleClass === "Vendor"
                                  ? /^[ A-Za-z0-9_@./#&+-]*$/
                                  : /^[a-zA-Z\s]*$/,
                              message:
                                userRoleClass === "Vendor"
                                  ? ""
                                  : "Full name should be letters only",
                            },
                          })}
                          className="form-control form-control-n"
                          placeholder="Full Name"
                        />
                        <div className="register_icon">
                          <img src={require("./../../images/user_r.png")} />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="fullName"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          ref={register({
                            required: "Email is required",
                            maxLength: {
                              value: 150,
                              message: "Should be a valid email",
                            },
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Should be a valid email",
                            },
                          })}
                          className="form-control form-control-n"
                          placeholder="Email Address"
                        />
                        <div className="register_icon">
                          <img src={require("./../../images/mailicon_r.png")} />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="email"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="countryCode"
                          style={{ display: "none" }}
                          disabled={false}
                          ref={register}
                        />
                        <Controller
                          // rules={{
                          //   required: "Mobile number is required",
                          //   maxLength: {
                          //     value: 15,
                          //     message: "Shold be a mobile number"
                          //   },
                          //   pattern: {
                          //     value: /^[0-9]*$/,
                          //     message: "Mobile number should be numbers only"
                          //   }
                          //   // pattern: {
                          //   //   value: /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/,
                          //   //   message: "Mobile number should be numbers only"
                          //   // }
                          // }}
                          control={control}
                          name="countryCode"
                          render={({ onChange, onBlur, value, name }) => (
                            <PhoneNumberWithCode
                              codeHeight={"50px"}
                              noPenIcon={true}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                              phoneNo={
                                <input
                                  type="text"
                                  name="mobileNumber"
                                  id="phoneNumber"
                                  className="form-control phoneNumber"
                                  placeholder="Mobile number"
                                  ref={register({
                                    required: "Mobile number is required",
                                    maxLength: {
                                      value: 15,
                                      message: "Should be a mobile number",
                                    },
                                    // minLength: {
                                    //   value: 10,
                                    //   message: "Should be a mobile number",
                                    // },
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Mobile number should be numbers only",
                                    },
                                  })}
                                />
                              }
                            />
                          )}
                        />
                        {/* <input type="text" name="mobileNumber" ref={register({
                          required: "Mobile number is required",
                          maxLength: {
                            value: 15,
                            message: "Shold be a mobile number"
                          },
                          // pattern: {
                          //   value: /^[0-9]*$/,
                          //   message: "Mobile number should be numbers only"
                          // }
                          // pattern: {
                          //   value: /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/,
                          //   message: "Mobile number should be numbers only"
                          // }
                        })} className="form-control form-control-n" placeholder="Mobile Number" /> */}
                        {/* <div className="register_icon"><img src={require("./../../images/mobile_r.png")} /></div> */}
                        <ErrorMessage
                          errors={errors}
                          name="mobileNumber"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>

                      <div className="form-group">
                        <div className="fons_lg">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            ref={register({
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password should be 8 digits long with one uppercase and one lowercase letter, a number and a special character",
                              },
                              maxLength: {
                                value: 20,
                                message:
                                  "Should not be greater than 20 characters",
                              },
                              validate: function (value) {
                                let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                                return re.test(value)
                                  ? undefined
                                  : "Password should be 8 digit long which inludes capital letter, small letter and special characters";
                              },
                            })}
                            className="password-field-title-without-tooltip form-control input-pwd"
                            placeholder="Password"
                          />
                          <span
                            onClick={(event: any) => {
                              setShowPassword(!showPassword);
                            }}
                            className={
                              showPassword
                                ? "fa fa-fw fa-eye field-icon toggle-password"
                                : "fa fa-fw fa-eye-slash field-icon toggle-password"
                            }
                          />
                        </div>
                        <div className="register_icon">
                          <img src={require("./../../images/password_r.png")} />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="password"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <div className="fons_lg">
                          <input
                            type={showPassword2 ? "text" : "password"}
                            name="passwordConfirm"
                            ref={register({
                              required: "Confirm password is required",
                              validate: (value) =>
                                value === watch("password")
                                  ? undefined
                                  : "Should be same as password",
                            })}
                            className="form-control form-control-n"
                            placeholder="Confirm Password"
                          />
                          <span
                            onClick={(event: any) => {
                              setShowPassword2(!showPassword2);
                            }}
                            className={
                              showPassword2
                                ? "fa fa-fw fa-eye field-icon toggle-password"
                                : "fa fa-fw fa-eye-slash field-icon toggle-password"
                            }
                          />
                        </div>
                        <div className="register_icon">
                          <img src={require("./../../images/password_r.png")} />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="passwordConfirm"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>
                      <div className="clearfix" />
                      <div className="m_t_20">
                        <button
                          type="submit"
                          className="btn btn-success reg_but"
                        >
                          Register
                        </button>
                      </div>
                      <div className="already_login1">
                        Already have an account? Click here to{" "}
                        <Link to="/login">Login.</Link>
                      </div>
                      <div className="already_login1">
                        By continuing you will receive a one-time verification
                        code to your phone number or email by SMS.{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Registration);
