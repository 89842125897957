import { Reducer } from "react";
// import { IBannerManagementState, BannerDispatcherContext, BannerStateContext } from "../context/BannerContext"
import React from "react";
import axios from "axios";
import { AppUrls } from "../../environments/environment";
import {
  BannerDispatcherContext,
  BannerStateContext,
  IBannerManagementState,
} from "../../context/admin/BannerContext";
import {
  AddBannerRequestModest,
  Banner,
  GetAllBannerRequestModel,
  GetAllBannerRespond,
  GetAllBannerRespondModel,
  GetAllBannerRespondModelForEdit,
} from "../../models/admin/Banner";
// import { ProductAPIClient, GetBannerProductsPostModel, BannerDetailViewModel } from "../api";

export type IBannerAction =
  | {
    type: "GET_ALL_BANNERS_LIST";
    bannersList: GetAllBannerRespondModel;
  }
  | {
    type: "ADD_BANNER";
    addBannerRespond: Banner;
  }
  | {
    type: "BANNER_STATUS_CHANGE";
    bannerStatusRespond: Banner;
  }
  | {
    type: "DELETE_BANNER";
    deleteBannerRespond: Banner;
  }
  | {
    type: "EDIT_BANNER";
    editBaneerRespond: GetAllBannerRespondModelForEdit;
  };

export const bannerReducer: Reducer<IBannerManagementState, IBannerAction> = (
  draft,
  action
): IBannerManagementState => {
  switch (action.type) {
    case "GET_ALL_BANNERS_LIST":
      draft.bannersList = action.bannersList;
      return draft;
    case "ADD_BANNER":
      draft.addBannerRespond = action.addBannerRespond;
      return draft;
    case "BANNER_STATUS_CHANGE":
      draft.bannerStatusRespond = action.bannerStatusRespond;
      return draft;
    case "DELETE_BANNER":
      draft.deleteBannerRespond = action.deleteBannerRespond;
      return draft;
    case "EDIT_BANNER":
      draft.editBaneerRespond = action.editBaneerRespond;
      return draft;
  }
};

export const useBannerDispatcher = (): React.Dispatch<IBannerAction> => {
  const BannerDispatcher = React.useContext(BannerDispatcherContext);
  if (!BannerDispatcher) {
    throw new Error(
      "You have to provide the Banner dispatcher using theBannerDispatcherContext.Provider in a parent component."
    );
  }
  return BannerDispatcher;
};

export const useBannerContext = (): IBannerManagementState => {
  const BannerContext = React.useContext(BannerStateContext);
  if (!BannerContext)
    throw new Error(
      "You have to provide the Banner context using the BannerStateContext.Provider in a parent component."
    );
  return BannerContext;
};

export const GetAllBanners = async (
  dispatcher: React.Dispatch<IBannerAction>,
  query: GetAllBannerRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllBanner;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_BANNERS_LIST",
        bannersList: res.data,
      });
    });
  } catch (e) { }
};

export const GetAllBannerswithoutoken = async (
  dispatcher: React.Dispatch<IBannerAction>,
  query: GetAllBannerRequestModel,

) => {
  try {


    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",

    };

    const url = AppUrls.GetAllBanner;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_BANNERS_LIST",
        bannersList: res.data,
      });
    });
  } catch (e) { }
};


export const SaveBanners = async (
  dispatcher: React.Dispatch<IBannerAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.AddBanner;

    axios.post(url, query, { headers: header }).then((res) => {
      dispatcher({
        type: "ADD_BANNER",
        addBannerRespond: res.data,
      });
    });
  } catch (e) { }
};

export const ChangeBannerStatus = async (
  dispatcher: React.Dispatch<IBannerAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.BannerStatusChange;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "BANNER_STATUS_CHANGE",
        bannerStatusRespond: res.data,
      });
    });
  } catch (e) { }
};

export const DeleteBanner = async (
  dispatcher: React.Dispatch<IBannerAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteBanner;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "DELETE_BANNER",
        deleteBannerRespond: res.data,
      });
    });
  } catch (e) { }
};

export const EditBanner = async (
  dispatcher: React.Dispatch<IBannerAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditBanner;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_BANNER",
        editBaneerRespond: res.data,
      });
    });
  } catch (e) { }
};
