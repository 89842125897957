import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";

import {
  ClientImageProfileResultModel,
  ClientPersonalInfoResultByIdModel,
  ClientPersonalInfoResultModel,
} from "../../models/client/ClientProfile";
import {
  clientProfileReducer,
  IClientProfileAction,
} from "../../action/client/ClientProfileAction";

export const ClientProfileDispatcherContext = React.createContext<React.Dispatch<IClientProfileAction> | null>(
  null
);
export const ClientProfileStateContext = React.createContext<IClientProfileManagementState | null>(
  null
);

export interface IClientProfileManagementProps
  extends RouteComponentProps<any> {}

export interface IClientProfileManagementState {
  addOrEditClientProfileResult: ClientPersonalInfoResultModel;
  getClientPersonalDetails: ClientPersonalInfoResultByIdModel;
  clientProfileImage: ClientImageProfileResultModel;
  deleteProfileImageResult: ClientPersonalInfoResultModel;
}

export const initialClientProfileManagementState = {
  addOrEditClientProfileResult: {} as ClientPersonalInfoResultModel,
  getClientPersonalDetails: {} as ClientPersonalInfoResultByIdModel,
  clientProfileImage: {} as ClientImageProfileResultModel,
  deleteProfileImageResult: {} as ClientPersonalInfoResultModel,
} as IClientProfileManagementState;

export const ClientProfileContextProvider: React.FC = ({ children }) => {
  const [clientProfileState, dispatcher] = useImmerReducer(
    clientProfileReducer,
    initialClientProfileManagementState
  );

  return (
    <ClientProfileDispatcherContext.Provider value={dispatcher}>
      <ClientProfileStateContext.Provider value={clientProfileState}>
        {children}
      </ClientProfileStateContext.Provider>
    </ClientProfileDispatcherContext.Provider>
  );
};
