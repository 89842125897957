import { Badge, Popover, Tooltip } from "antd";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  GetAllAdminMyJobList,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import {
  AdminJobList,
  AdminJobListRequestModel,
  AdminJobListRespondModel,
  AdminPermissionRespondModel,
} from "../../../models/admin/AdminMyJob";
import AuthService from "../../../services/AuthService";

interface IAdminDraftedJobComponent extends RouteComponentProps {
  handleEdit: (id: number) => void;
  handleMirrorJob: (id: number) => void;
  jobListInAdmin: AdminJobListRespondModel;
  handlePublish: (id: number) => void;
  AdminPermissionResult: AdminPermissionRespondModel;
  jobStatusData: number;
  loggedAdminId: number;
}

const AdminDraftedJobComponent: React.FC<IAdminDraftedJobComponent> = (
  props
) => {
  const {
    handleEdit,
    handleMirrorJob,
    jobListInAdmin,
    handlePublish,
    AdminPermissionResult,
    jobStatusData,
    loggedAdminId,
  } = props;

  const adminMyJobDispatcher = useAdminMyJobDispatcher();

  const authorizationToken = AuthService.accessToken;

  const [hasMore, setHasMore] = React.useState(true);
  const [totalCount, setTotalCount] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSize = 3;
  const [totalPage, setTotalPage] = useState(1);
  const [drafredjobState, setDraftedjobState] = useState<AdminJobList[]>([]);

  React.useEffect(() => {
    //console.log(currentPage);
    if (jobListInAdmin.data != undefined && currentPage == 1) {
      //console.log("userList");
      //console.log(jobListInAdmin);
      setDraftedjobState(jobListInAdmin.data);
      //setCurrentPage(1);
      setTotalPage(Math.ceil(jobListInAdmin.total / currentPageSize));
      setTotalCount(jobListInAdmin.total);
      //console.log(drafredjobState);
    }
    if (jobListInAdmin.data != undefined && currentPage > 1) {
      //console.log("drafted");
      setHasMore(jobListInAdmin.hasNext);
      setDraftedjobState([...jobListInAdmin.data, ...drafredjobState]);
    }
  }, [jobListInAdmin]);

  const fetchMoreData = async () => {
    //console.log("hhh");
    //console.log(currentPage, totalPage);
    //console.log(totalCount);
    if (currentPage >= totalPage) {
      setHasMore(false);
      return;
    } else {
      setCurrentPage(currentPage + 1);
      if (authorizationToken != null) {
        // if (cl === "/admin/myJob/drafted") {
        (async () => {
          await GetAllAdminMyJobList(
            adminMyJobDispatcher,
            {
              Draftedjobsflag: true,
              JobStatus: jobStatusData,
              Page: currentPage + 1,
              PageSize: currentPageSize,
              Publishedjobsflag: false,
              SearchTerm: "",
              SortOrder: "",
            } as AdminJobListRequestModel,
            authorizationToken
          );
        })();
        // }
      }
    }
  };

  const onHandleEdit = (id: number) => {
    handleEdit(id);
  };

  const onHandleMirror = (id: number) => {
    handleMirrorJob(id);
  };

  const onHandlePublish = (id: number) => {
    handlePublish(id);
  };

  const content = (
    <>
      <p>You Don't Have Permission</p>
    </>
  );

  const content1 = (
    <>
      <p>You are not assign admin</p>
    </>
  );

  const handleViewClick = (
    id: any,
    candidateDefaultId: any,
    vendorDefaultId: any
  ) => {
    props.history.push(
      `/admin/job_Application/${candidateDefaultId}/${vendorDefaultId}/${id}`
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-lg-12">
          <div className="section_box3">
            <div className="row">
              <div
                className="my_job_descrip"
                style={{ overflow: "hidden", outline: "none" }}
                tabIndex={0}
              >
                <InfiniteScroll
                  className="_custom-infinite-scroll-class"
                  height={600}
                  // dataLength={100}
                  dataLength={totalCount}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    jobListInAdmin.data !== undefined &&
                    jobListInAdmin.data.length > 0 ? (
                      <h4>Loading...</h4>
                    ) : null
                  }
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      {jobListInAdmin.data !== undefined &&
                      jobListInAdmin.data.length > 16 ? (
                        <b>Yay! You have seen it all</b>
                      ) : null}
                    </p>
                  }
                >
                  {/* job Started */}
                  {jobListInAdmin.data != undefined
                    ? jobListInAdmin.data.map((data, index) => {
                        return (
                          <div className="col-sm-3">
                            <div
                              style={{
                                backgroundColor: data.jobStatus
                                  ? "white"
                                  : "gainsboro",
                              }}
                              className="matched_jobs"
                            >
                              <div className="matched_jobs_cat_t">
                                {data.categoryName}
                              </div>
                              <div style={{ float: "right", marginTop: -8 }}>
                                {" "}
                                <Tooltip
                                  title={
                                    data.applicationviewcount +
                                    " " +
                                    "new applicant"
                                  }
                                >
                                  <Badge
                                    style={{ float: "right" }}
                                    count={data.applicationviewcount}
                                  />
                                </Tooltip>
                              </div>
                              <div className="matched_star1">
                                {AdminPermissionResult != undefined &&
                                AdminPermissionResult.isCreateorEditJobPermission ? (
                                  <a
                                    onClick={() => {
                                      onHandleMirror(data.rowId);
                                    }}
                                    data-target="#create_jobdescription"
                                    data-toggle="modal"
                                  >
                                    Mirror a Job
                                  </a>
                                ) : (
                                  <Popover content={content} trigger="hover">
                                    <a
                                      data-target="#create_jobdescription"
                                      data-toggle="modal"
                                    >
                                      Mirror a Job
                                    </a>
                                  </Popover>
                                )}
                              </div>
                              <div className="clearfix" />
                              <div className="matched_heading">
                                {data.title}
                              </div>
                              <div className="clearfix" />
                              <div className="matched_jobs_cat">
                                <i
                                  className="fa fa-map-marker"
                                  aria-hidden="true"
                                />
                                {data.location}
                              </div>
                              <div className="matched_jobs_cat text-right">
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                />{" "}
                                {data.jobType}
                              </div>
                              <div className="clearfix" />
                              <div className="matched_jobs_cat">
                                <i
                                  className="fa fa-list-ul"
                                  aria-hidden="true"
                                />{" "}
                                {data.numberOfVacancies} Vacancies
                              </div>
                              {/* ------- */}
                              {data.assignedToAdminId === loggedAdminId ? (
                                <>
                                  <div className="matched_jobs_cat">
                                    Assigned By:
                                    {data.assignedBy}
                                  </div>

                                  <div className="matched_jobs_cat">
                                    <i
                                      className="fa fa-list-ul"
                                      aria-hidden="true"
                                    />{" "}
                                    Assigned Date:
                                    {data.jobAssignDate}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="matched_jobs_cat">
                                    Assign To:
                                    {data.assignTo}
                                  </div>

                                  <div className="matched_jobs_cat">
                                    <i
                                      className="fa fa-list-ul"
                                      aria-hidden="true"
                                    />{" "}
                                    Assigned Date:
                                    {data.jobAssignDate}
                                  </div>
                                </>
                              )}
                              Job Id: {data.jobId}
                              {/* ------- */}
                              <div className="clearfix" />
                              <div className="approve_d">
                                {AdminPermissionResult != undefined &&
                                AdminPermissionResult.isCreateorEditJobPermission ? (
                                  <a
                                    onClick={() => {
                                      onHandleEdit(data.rowId);
                                    }}
                                    data-target="#create_jobdescription"
                                    data-toggle="modal"
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    />{" "}
                                    Edit
                                  </a>
                                ) : (
                                  <Popover content={content} trigger="hover">
                                    <a
                                      data-target="#create_jobdescription"
                                      data-toggle="modal"
                                    >
                                      <i
                                        className="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                      />{" "}
                                      Edit
                                    </a>
                                  </Popover>
                                )}
                              </div>
                              {data.numberOfApplicants !== undefined &&
                              data.numberOfApplicants !== null &&
                              data.numberOfApplicants > 0 ? (
                                <div
                                  className="check_status"
                                  // style={{ paddingBottom: 6 }}
                                >
                                  {AdminPermissionResult != undefined &&
                                  AdminPermissionResult.isViewJobPermission ? (
                                    data.jobStatus == false ? (
                                      <a style={{ backgroundColor: "gray" }}>
                                        <Popover
                                          content={
                                            "please active job for view application"
                                          }
                                          trigger="hover"
                                        >
                                          View Application
                                        </Popover>
                                      </a>
                                    ) : data.assignedToAdminId ===
                                      loggedAdminId ? (
                                      <a
                                        onClick={() => {
                                          handleViewClick(
                                            data.jobId,
                                            data.candidateDefaultId,
                                            data.vendorDefaultId
                                          );
                                        }}
                                      >
                                        View Application
                                      </a>
                                    ) : (
                                      <Popover
                                        content={content1}
                                        trigger="hover"
                                      >
                                        <a style={{ backgroundColor: "gray" }}>
                                          View Application
                                        </a>
                                      </Popover>
                                    )
                                  ) : (
                                    <Popover content={content} trigger="hover">
                                      <a>View Application</a>
                                    </Popover>
                                  )}
                                </div>
                              ) : (
                                <div
                                  className="check_status"
                                  // style={{ paddingBottom: 6 }}
                                >
                                  <a style={{ backgroundColor: "darkgray" }}>
                                    No Applicant
                                  </a>
                                </div>
                              )}
                              <div className="check_status">
                                <a
                                  style={{ marginTop: 10 }}
                                  onClick={() => {
                                    onHandlePublish(data.rowId);
                                  }}
                                  data-target="#apply_now_pop"
                                  data-toggle="modal"
                                >
                                  Publish
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
        {/* BEGIN MODAL */}
        <div className="modal fade none-border" id="share">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  aria-hidden="true"
                  data-dismiss="modal"
                  className="close"
                  type="button"
                >
                  ×
                </button>
                <h4 className="modal-title">Share</h4>
              </div>
              <div className="col-sm-12 m_t_30 text-center">
                <div className="social1">
                  <a href="#" className="social_face">
                    <i className="fa fa-facebook" aria-hidden="true" />
                  </a>
                  <a href="#" className="social_twit">
                    <i className="fa fa-twitter" aria-hidden="true" />
                  </a>
                  <a href="#" className="social_insta">
                    <i className="fa fa-instagram" aria-hidden="true" />
                  </a>
                  <a href="#" className="social_youtube">
                    <i className="fa fa-youtube-play" aria-hidden="true" />
                  </a>
                </div>
              </div>
              <div className="modal-footer m-t-30" />
            </div>
          </div>
        </div>
        {/* END MODAL */}
      </div>
    </>
  );
};

export default withRouter(AdminDraftedJobComponent);
