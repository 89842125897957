import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  getSocialAccountUrl,
  getSocialAccountUrlSelectBoxList,
  useSocialAccountUrlContext,
  useSocialAccountUrlDispatcher,
} from "../../../../action/admin/SocialAccountUrlAction";
import { SocialAccountUrlEditRequestModel } from "../../../../models/admin/SocialAccountUrl";
import FileUploads from "../../../candidate/my_profile/components/FileUploads";
import SelectOption from "../../../candidate/my_profile/components/SelectOption";
import { SocialAccountUrlGetDataWithId } from "../../../../models/admin/SocialAccountUrl";
import { saveSocialAccountUrl } from "./../../../../apis/admin";
import _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "./../../../../context/GlobalSpinner";

interface ISocialAccountUrlFormProps {
  isSocialAccountUrlOpen: boolean;
  handleSocialAccountUrlOpen: () => void;
  currentSocialAccountUrlId: number;
  loggedAdminId: number;
  authorizationToken: string | null;
}
interface ReactSelectOption {
  value: string;
  label: string;
}
const defaultValues = {
  selectResume: "",
  coverLetter: "",
  socialAccountTypeId: 0,
};

const SocialAccountUrlForm: React.FC<ISocialAccountUrlFormProps> = (props) => {
  const {
    handleSocialAccountUrlOpen,
    isSocialAccountUrlOpen,
    currentSocialAccountUrlId,
    loggedAdminId,
    authorizationToken,
  } = props;

  const [obj, setObj] = useState<any>({ file: null });
  const [obj2, setObj2] = useState<any>({ file: null });
  const [disable, setDisable] = useState<number>(0);
  const [disable2, setDisable2] = useState<number>(0);
  const [disable3, setDisable3] = useState<string>("");
  const [disable4, setDisable4] = useState<string>("");

  const SocialAccountUrlDispatcher = useSocialAccountUrlDispatcher();
  const SocialAccountUrlContext = useSocialAccountUrlContext();
  const {
    SocialAccountUrlResultModel,
    SocialAccountUrlSelectBoxDataViewModel,
  } = SocialAccountUrlContext;
  const globalSpinner = useContext(GlobalSpinnerContext);

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  let SocialAccountTypes: ReactSelectOption[] = [];
  if (
    !_.isEmpty(SocialAccountUrlSelectBoxDataViewModel) &&
    SocialAccountUrlSelectBoxDataViewModel != undefined
  ) {
    SocialAccountTypes = SocialAccountUrlSelectBoxDataViewModel.socialAccountTypes.map(
      (e: any) => {
        return { value: e["value"], label: e["caption"] };
      }
    );
  }

  const handleDrop = (files: any) => {
    if (
      ["image/jpeg", "application/pdf"].includes(files[0].type) &&
      files[0].size < 1000000 * 5
    ) {
      setDisable(1);
      //console.log(files[0]);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
    }
  };

  React.useEffect(() => {
    if (loggedAdminId != 0 && authorizationToken != null) {
      (async () => {
        await getSocialAccountUrl(
          SocialAccountUrlDispatcher,
          {
            rowId: currentSocialAccountUrlId,
          } as SocialAccountUrlGetDataWithId,
          authorizationToken
        );
      })();
    }
  }, [currentSocialAccountUrlId]);

  React.useEffect(() => {
    if (1 && authorizationToken != null) {
      (async () => {
        await getSocialAccountUrlSelectBoxList(
          SocialAccountUrlDispatcher,
          authorizationToken
        );
      })();
    }
  }, []);

  React.useEffect(() => {
    //console.log("----SocialAccountUrlResultModel----", SocialAccountUrlResultModel);
    if (SocialAccountUrlResultModel.data != null) {
      setValue3("rowId", SocialAccountUrlResultModel.data["rowId"]);
      setValue3(
        "socialAccountUrlId",
        SocialAccountUrlResultModel.data["socialAccountUrlId"]
      );
      setValue3(
        "socialAccountTypeId",
        SocialAccountUrlResultModel.data["socialAccountTypeId"]
      );
      setValue3("linkUrl", SocialAccountUrlResultModel.data["linkUrl"]);
      setValue3("adminId", loggedAdminId);
      setValue3("isActive", SocialAccountUrlResultModel.data["isActive"]);
    }
  }, [SocialAccountUrlResultModel.data, currentSocialAccountUrlId]);

  //console.log("SocialAccountUrlResultModel", SocialAccountUrlResultModel);
  //console.log("loggedAdminId", loggedAdminId);

  const handleSocialAccountUrl = (data: any) => {
    //console.log(33, data);
    type objectIndexing = {
      [key: string]: any;
    };
    if (authorizationToken != null) {
      globalSpinner.showSpinner();
      let _data: objectIndexing = {};

      if (currentSocialAccountUrlId > 0) {
        _data["RowId"] = currentSocialAccountUrlId;
      } else {
        _data["RowId"] = 0;
      }
      _data["SocialAccountUrlId"] = 0;
      _data["SocialAccountTypeId"] = data["socialAccountTypeId"];
      _data["LinkUrl"] = data["linkUrl"];
      _data["AdminId"] = loggedAdminId;
      _data["IsActive"] = true;
      saveSocialAccountUrl(_data).then((res: any) => {
        //console.log(res);
        if (res.data.isSuccess) {
          globalSpinner.hideSpinner();
          toast.success("Social account url saved successfully.");
        } else {
          globalSpinner.hideSpinner();
          toast.error("Error:Social account url not saved or updated.");
        }
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setDisable(0);
    setDisable2(0);
    setDisable3("");
    handleSocialAccountUrlOpen();
  };

  return (
    <>
      <ToastContainer />
      <div className=" ">
        <div id="create_jobdescription" className="modal fade none-border">
          <Modal show={isSocialAccountUrlOpen} onHide={handleClose}>
            <form onSubmit={handleSubmit3(handleSocialAccountUrl)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>Add New</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="email">Select</label>
                  <span className="select-wrapper-sec">
                    <Controller
                      rules={{
                        required: "Position is required",
                        validate: function (value) {
                          //console.log(value);
                          return Number(value)
                            ? undefined
                            : "Position is required";
                        },
                      }}
                      control={control3}
                      name="socialAccountTypeId"
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          disabled={false}
                          values={SocialAccountTypes}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Url/ Number</label>
                  <input
                    type="text"
                    name="linkUrl"
                    className="form-control"
                    placeholder="Type here"
                    ref={register3({
                      required: "Url is required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors3}
                    name="linkUrl"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default SocialAccountUrlForm;
