import { apiClient } from "../utils/httpClient";

export function getAdminId(data?: any) {
  return apiClient(`/api/AdminProfile/gv/${data}`, {}, "get");
}

export function getAdmins(data?: any) {
  return apiClient("/api/AdminProfile/gaaa", data);
}

export function getAdmin(data?: any) {
  return apiClient("/api/Admin/ga", data);
}

export function saveAdmin(data?: any) {
  return apiClient("/api/AdminProfile/update-admin-profile", data);
}

export function getSelectBoxData(data?: any) {
  return apiClient("/api/Admin/get-selectbox-data", data);
}

export function getAdminProfileImage(data?: any) {
  return apiClient("/api/AdminProfileImage/gaaa", data);
}

export function saveAdminProfileImage(data?: any) {
  return apiClient("/api/AdminProfileImage/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getAdminList(data?: any) {
  return apiClient("/api/Admin/gaoa", data);
}

export function deleteAdminDocument(data?: any) {
  return apiClient("/api/Admin/delete-doc", data);
}

export function getUserList(data?: any) {
  return apiClient("/api/Vendor/gul", data);
}

export function getMessageList(data?: any) {
  return apiClient("/api/ChatDetails/gaaa", data);
}

export function getNotificationList(data?: any) {
  return apiClient("/api/superadmin/getAdminNotificatinsAsync", data);
}

export function saveCareerTips(data?: any) {
  //console.log('-----------------------formdata------------------', data);
  return apiClient("/api/CareerTips/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

//communication
export function getBulkSMSList(data?: any) {
  return apiClient("/api/Communication/BulkSMS/gaaa", data);
}

export function getBulkSMSDetailList(data?: any) {
  return apiClient("/api/Communication/BulkSMS/ga", data);
}

export function saveBulkSMSList(data?: any) {
  return apiClient("/api/Communication/BulkSMS/coea", data);
}

// export function deleteBulkSMSList(data?: any) {
//   return apiClient("/api/Communication/BulkSMS/da", data);
// }

export function deleteBulkSMSListByIds(data?: any) {
  return apiClient("/api/Communication/BulkSMS/daaa", data);
}

export function getBulkEmailList(data?: any) {
  return apiClient("/api/Communication/BulkEmail/gaaa", data);
}

export function getBulkEmailDetailList(data?: any) {
  return apiClient("/api/Communication/BulkEmail/ga", data);
}

export function saveBulkEmailList(data?: any) {
  return apiClient("/api/Communication/BulkEmail/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// export function deleteBulkEmailList(data?: any) {
//   return apiClient("/api/Communication/BulkEmail/da", data);
// }

export function deleteBulkEmailListByIds(data?: any) {
  return apiClient("/api/Communication/BulkEmail/daaa", data);
}

export function getContactUsList(data?: any) {
  return apiClient("/api/ContactUs/gaaa", data);
}

export function deleteContactUsByIds(data?: any) {
  return apiClient("/api/ContactUs/daaa", data);
}

//messages
export function getCommunicationUserList(data?: any) {
  return apiClient("/api/Communication/Messages/gul", data);
}

export function saveClientJob(data?: any) {
  return apiClient("/api/jobpost/coea", data);
}

export function updateJob(data?: any) {
  //console.log('-----------------------updateJob------------------', data);
  return apiClient(
    "/api/AdminDraftedandPublishedJobs/AddOrEditJobAsync",
    data,
    "post",
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

//SMS templates
export function getBulkSMSTemplates(data?: any) {
  return apiClient("/api/Communication/BulkSMSTemplate/gaaa", data);
}

//SMS templates
export function getBulkEmailTemplates(data?: any) {
  return apiClient("/api/BulkEmail/gaaa", data);
}

export function saveEmail(data?: any) {
  return apiClient("/api/Communication/BulkSMS/coea", data);
}

export function saveEmails(data?: any) {
  //console.log('-----------------------save emails------------------', data);
  return apiClient("/api/BulkEmail/send-email", data);
}

//report updations

export function saveAdminActions(data?: any) {
  //console.log('-----------------------save emails------------------', data);
  return apiClient("/api/SuperAdminReport/coea", data);
}

// career developer

export function saveCareerDeveloper(data?: any) {
  return apiClient("/api/CareerDeveloper/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getCareerDevelopers(data?: any) {
  return apiClient("/api/CareerDeveloper/gaaa", data);
}

export function deleteCareerDeveloper(data?: any) {
  return apiClient("/api/CareerDeveloper/da", data);
}

// career developer category

export function saveCareerDeveloperCategory(data?: any) {
  return apiClient("/api/CareerDeveloperCategory/coea", data);
}

export function deleteCareerDeveloperCategory(data?: any) {
  return apiClient("/api/CareerDeveloperCategory/da", data);
}

export function getCareerDeveloperSelectBoxData(data?: any) {
  return apiClient("/api/CareerDeveloper/get-selectbox-data", data);
}

//My Team Member
export function saveMyTeamMember(data?: any) {
  //console.log('-----------------------Save My Team Member------------------', data);
  return apiClient("/api/MyTeamMember/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
//Story
export function saveStory(data?: any) {
  //console.log('-----------------------Save Story------------------', data);
  return apiClient("/api/Story/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
//Social Account Url
export function saveSocialAccountUrl(data?: any) {
  //console.log('-----------------------Save Social Account Url------------------', data);
  return apiClient("/api/SocialAccountUrl/coea", data);
}

//Achievements
export function saveAchievements(data?: any) {
  //console.log('-----------------------formdata------------------', data);
  return apiClient("/api/Achievements/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

//Get dashboard message list
export function getMessageDashboardList(data?: any) {
  return apiClient("/api/AdminProfile/gml", data);
}

export function deleteAdminProfile(data?: any) {
  return apiClient("/api/AdminProfileImage/da", data);
}
