import React from "react";

import CandidateJobAppliedComponent from "./CandidateJobAppliedComponent";

interface ICandidateJobAppliedContainerProps {}

interface ICandidateJobAppliedContainerState {}

const initialState = {};

const CandidateJobAppliedContainer: React.FC<ICandidateJobAppliedContainerProps> = (
  props
) => {
  const [
    CandidateJobAppliedContainerState,
    setCandidateJobAppliedContainerState,
  ] = React.useState<ICandidateJobAppliedContainerState>(initialState);

  return (
    <>
      <CandidateJobAppliedComponent />
    </>
  );
};
export default CandidateJobAppliedContainer;
