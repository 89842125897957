import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { useImmerReducer } from "use-immer";

import {
  adminJobApplicationReducer,
  IAdminJobApplicationAction,
} from "../../action/admin/AdminJobApplicationAction";
import { PermissionResultModel } from "../../models/admin/AdminMyJob";
import {
  AdminMoveJobStatusRespondModel,
  GetAdminCandidateResultModel,
  GetAdminVendorCandidateResultModel,
  InterviwSheduleBulkSmsAndEmailRespondModel,
  JobApplicationViewJobResultModel,
} from "../../models/admin/AdminJobApplication";

export const AdminJobApplicationDispatcherContext = React.createContext<React.Dispatch<IAdminJobApplicationAction> | null>(
  null
);
export const AdminJobApplicationStateContext = React.createContext<IAdminJobApplicationManagementState | null>(
  null
);

export interface IAdminJobApplicationManagementProps
  extends RouteComponentProps<any> {}

export interface IAdminJobApplicationManagementState {
  JobApplicationViewJobResultModel: JobApplicationViewJobResultModel;
  AdminMoveJobStatusResult: AdminMoveJobStatusRespondModel;
  AdminCandidateResult: GetAdminCandidateResultModel;
  AdminVendorCandidateResult: GetAdminVendorCandidateResultModel;
  interviewSheduleBulkSmsOrEmailRespond: InterviwSheduleBulkSmsAndEmailRespondModel;
  GeneralReportResult: AdminMoveJobStatusRespondModel;
}

export const initialAdminJobApplicationManagementState = {
  JobApplicationViewJobResultModel: {} as JobApplicationViewJobResultModel,
  AdminMoveJobStatusResult: {} as AdminMoveJobStatusRespondModel,
  AdminCandidateResult: {} as GetAdminCandidateResultModel,
  AdminVendorCandidateResult: {} as GetAdminVendorCandidateResultModel,
  interviewSheduleBulkSmsOrEmailRespond: {} as InterviwSheduleBulkSmsAndEmailRespondModel,
  GeneralReportResult: {} as AdminMoveJobStatusRespondModel,
} as IAdminJobApplicationManagementState;

export const AdminJobApplicationContextProvider: React.FC = ({ children }) => {
  const [adminJobApplicationState, dispatcher] = useImmerReducer(
    adminJobApplicationReducer,
    initialAdminJobApplicationManagementState
  );

  return (
    <AdminJobApplicationDispatcherContext.Provider value={dispatcher}>
      <AdminJobApplicationStateContext.Provider
        value={adminJobApplicationState}
      >
        {children}
      </AdminJobApplicationStateContext.Provider>
    </AdminJobApplicationDispatcherContext.Provider>
  );
};
