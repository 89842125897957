import { Reducer } from "react";
import {
  ICategoryManagementState,
  CategoryDispatcherContext,
  CategoryStateContext,
} from "../../context/general/CategoryContext";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  CategorySaveRequestModel,
  CategorySaveRespondModel,
  TitleSaveRequestModel,
  TitleSaveRespondModel,
} from "../../models/general/Category";
import { message } from "antd";
let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type ICategoryAction =
  | {
      type: "SAVE_CATEGORY";
      CategorySaveRespondModel: CategorySaveRespondModel;
    }
  | { type: "SAVE_TITLE"; TitleSaveRespondModel: TitleSaveRespondModel };

export const categoryReducer: Reducer<
  ICategoryManagementState,
  ICategoryAction
> = (draft, action): ICategoryManagementState => {
  switch (action.type) {
    case "SAVE_CATEGORY":
      draft.CategorySaveRespondModel = action.CategorySaveRespondModel;
      return draft;
    case "SAVE_TITLE":
      draft.TitleSaveRespondModel = action.TitleSaveRespondModel;
      return draft;
  }
};

export const useCategoryDispatcher = (): React.Dispatch<ICategoryAction> => {
  const categoryDispatcher = React.useContext(CategoryDispatcherContext);
  if (!categoryDispatcher) {
    throw new Error(
      "You have to provide the Category dispatcher using theCategoryDispatcherContext.Provider in a parent component."
    );
  }
  return categoryDispatcher;
};

export const useCategoryContext = (): ICategoryManagementState => {
  const categoryContext = React.useContext(CategoryStateContext);
  if (!categoryContext)
    throw new Error(
      "You have to provide the category context using the CategoryStateContext.Provider in a parent component."
    );
  return categoryContext;
};

export const SaveCategory = async (
  dispatcher: React.Dispatch<ICategoryAction>,
  query: CategorySaveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveCategory;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "SAVE_CATEGORY", CategorySaveRespondModel: res.data });
      res.data.isSuccess
        ? message.success("Category added successfully")
        : message.error(res.data.message);
    });
  } catch (e) {}
};

export const SaveTitle = async (
  dispatcher: React.Dispatch<ICategoryAction>,
  query: TitleSaveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveTitle;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "SAVE_TITLE", TitleSaveRespondModel: res.data });
      res.data.isSuccess
        ? message.success("Title added successfully")
        : message.error(res.data.message);
    });
  } catch (e) {}
};
