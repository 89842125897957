import React from "react";
// import {
//   getLoggedUserId,
//   useMyProfileDispatcher,
// } from "../../../../action/MyProfileAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileDispatcher
} from "../../../action/MyProfileAdminAction";
import AuthService from "../../../services/AuthService";
import CareerDeveloperComponent from "./CareerDeveloperComponent";

const CareerDeveloperContainer = () => {
  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();

  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null)
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          parseInt(user.id),
          authorizationToken
        );
      })();
  }, [authorizationToken]);


  return (
    <>
      <CareerDeveloperComponent />
    </>
  );
};
export default CareerDeveloperContainer;
