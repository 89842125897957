import React from "react";
import NotifyJobComponent from "./NotifyJobComponent";

const NotifyJobContainer = () => {
  return (
    <>
      <NotifyJobComponent />
    </>
  );
};

export default NotifyJobContainer;
