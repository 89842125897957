import { Reducer } from "react";

import React from "react";

import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";

import { message } from "antd";
import {
  NotifyJobRequestModel,
  NotifyJobRespondModel,
  NotInterestedRequestModel,
  NotInterestedRespondModel,
} from "../../models/candidate/JobNotify";
import {
  IJobNotifyManagementState,
  JobNotifyDispatcherContext,
  JobNotifyStateContext,
} from "../../context/candidate/JobNotifycontext";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IJobNotifyAction =
  | {
      type: "SET_JOBNOTIFY_JOB_LIST";
      notifyJobRespond: NotifyJobRespondModel;
    }
  | {
      type: "NOT_INTERESTED";
      notInterestedRespond: NotInterestedRespondModel;
    };

export const jobNotifyReducer: Reducer<
  IJobNotifyManagementState,
  IJobNotifyAction
> = (draft, action): IJobNotifyManagementState => {
  switch (action.type) {
    case "SET_JOBNOTIFY_JOB_LIST":
      draft.notifyJobRespond = action.notifyJobRespond;
      return draft;
    case "NOT_INTERESTED":
      draft.notInterestedRespond = action.notInterestedRespond;
      return draft;
  }
};

export const useJobNotifyDispatcher = (): React.Dispatch<IJobNotifyAction> => {
  const jobNotifyDispatcher = React.useContext(JobNotifyDispatcherContext);
  if (!jobNotifyDispatcher) {
    throw new Error(
      "You have to provide the JobNotify dispatcher using theJobNotifyDispatcherContext.Provider in a parent component."
    );
  }
  return jobNotifyDispatcher;
};

export const useJobNotifyContext = (): IJobNotifyManagementState => {
  const jobNotifyContext = React.useContext(JobNotifyStateContext);
  if (!jobNotifyContext)
    throw new Error(
      "You have to provide the jobNotify context using the JobNotifyStateContext.Provider in a parent component."
    );
  return jobNotifyContext;
};

export const GetAllJobNotifyList = async (
  dispatcher: React.Dispatch<IJobNotifyAction>,
  query: NotifyJobRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.NotifyJobs;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_JOBNOTIFY_JOB_LIST",
        notifyJobRespond: res.data,
      });
    });
  } catch (e) {}
};

export const NotInterested = async (
  dispatcher: React.Dispatch<IJobNotifyAction>,
  query: NotInterestedRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.NotInterestedInNotifyJob;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "NOT_INTERESTED",
        notInterestedRespond: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("successfully removed ")
          : message.error(res.data.message)
        : message.error("error occured");
    });
  } catch (e) {}
};
