import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { useImmerReducer } from "use-immer";
import { IMyUserAction, myUserReducer } from "../../action/admin/MyUserAction";
import {
  AdminClientEditRespondModel,
  GetAllAdminClientRequestModel,
  GetAllAdminClientRespondModel,
  GetAllAdminRemoveRespondModel,
  SaveAdminClientRespondModel,
} from "../../models/admin/MyUser";

export const MyUserDispatcherContext = React.createContext<React.Dispatch<IMyUserAction> | null>(
  null
);
export const MyUserStateContext = React.createContext<IMyUserManagementState | null>(
  null
);

export interface IMyUserManagementProps extends RouteComponentProps<any> {}

export interface IMyUserManagementState {
  SaveAdminClientRespondModel: SaveAdminClientRespondModel;
  AdminClientEditRespondModel: AdminClientEditRespondModel;
  GetAllAdminClientRespondModel: GetAllAdminClientRespondModel;
  GetAllAdminRemoveRespondModel: GetAllAdminRemoveRespondModel;
}

export const initialMyUserManagementState = {
  SaveAdminClientRespondModel: {} as SaveAdminClientRespondModel,
  AdminClientEditRespondModel: {} as AdminClientEditRespondModel,
  GetAllAdminClientRespondModel: {} as GetAllAdminClientRespondModel,
  GetAllAdminRemoveRespondModel: {} as GetAllAdminRemoveRespondModel,
} as IMyUserManagementState;

export const MyUserContextProvider: React.FC = ({ children }) => {
  const [myUserState, dispatcher] = useImmerReducer(
    myUserReducer,
    initialMyUserManagementState
  );

  return (
    <MyUserDispatcherContext.Provider value={dispatcher}>
      <MyUserStateContext.Provider value={myUserState}>
        {children}
      </MyUserStateContext.Provider>
    </MyUserDispatcherContext.Provider>
  );
};
