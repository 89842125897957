import React, {
  useState,
  // , useContext, useEffect
} from "react";
//import moment from "moment";
import {
  deleteResumeCandidateMap,
  getAllResumeCandidateMap,
  generateResumePDF,
} from "../../../../apis/resumebuilder";
// import { confirmWrapper, confirm } from "../../../GlobalConfirm";
import {
  toast,
  //,ToastContainer
} from "react-toastify";
import { useGlobalSpinnerContext } from "../../../../context/GlobalSpinner";
import { Link, useHistory } from "react-router-dom";
// import {
//   Link,
//   // , withRouter, useRouteMatch
// } from "react-router-dom";
//import AuthService from "../../../../services/AuthService";
import {
  // EmailIcon,
  EmailShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import { Modal } from "react-bootstrap";
import { Popconfirm, Popover, Tooltip } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { useMyProfileContext } from "../../../../action/MyProfileAction";
import { environment } from "../../../../environments/environment";

function DownloadSavedResumes(props: any) {
  const {
    downloadResume,
    resumeDownloadInfo,
    resumeFormat,
    setIsDownloadOpen,
    setResumeFormat,
  } = props;
  return (
    <>
      <div className="col-sm-12 m_t_30 text-center">
        {/* hhhh
            <div className="heading_sec1">
              <h1>Level</h1>
            </div> */}
        {/* <div className="section_box4"> */}
        <div className="col-xs-4">
          <input
            id={"1"}
            type="radio"
            name="radioLevel"
            defaultValue={"1"}
            onClick={() => {
              setResumeFormat(1);
            }}
          />
          <label htmlFor={"WORD"}>
            <span>
              <span />
            </span>{" "}
            {"WORD"}{" "}
          </label>
        </div>
        <div className="col-xs-4">
          <input
            id={"2"}
            type="radio"
            name="radioLevel"
            defaultValue={"2"}
            onClick={() => {
              setResumeFormat(2);
            }}
          />
          <label htmlFor={"PDF"}>
            <span>
              <span />
            </span>{" "}
            {"PDF"}{" "}
          </label>
        </div>

        {/* )} */}
        {/* </div> */}
      </div>
      <div className="modal-footer  m-t-30">
        <button
          className="btn btn-success save-event waves-effect waves-light"
          type="button"
          onClick={() => {
            downloadResume(resumeDownloadInfo);
          }}
          disabled={resumeFormat == 0 ? true : false}
        >
          {" "}
          Download{" "}
        </button>
        <button
          onClick={() => {
            setIsDownloadOpen(false);
            setResumeFormat(0);
          }}
          data-dismiss="modal"
          className="btn btn-default waves-effect _cursor-pointer"
          type="button"
        >
          {" "}
          Cancel
        </button>
      </div>
    </>
  );
}

function CandidateResumes(props: any) {
  // const [countNext, setCountNext] = useState(0);

  // const [carouselValue, setCarouselValue] = useState(0);
  //const [dashDisplay, setDashDisplay] = useState("block");

  // const authorizationToken = AuthService.accessToken;
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [resumeShare, setResumeShare] = useState("");
  const [isDownloadOpen, setIsDownloadOpen] = useState<boolean>(false);
  const [resumeDownloadInfo, setResumeDownloadInfo] = useState("");
  const [resumeFormat, setResumeFormat] = useState(0);
  const { pageSize } = props;
  const [candidateResumes, setCandidateResumes] = useState<any>([]);

  const myProfileContext = useMyProfileContext();
  const {
    // basicInfo,
    loggedUserId,
    // myProfile,
    myProfileProgressBar,
    // profileImage,
  } = myProfileContext;

  const candidateId = loggedUserId;
  //console.log(790, candidateResumes);
  const globalSpinner = useGlobalSpinnerContext();
  let history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(false);
  const currentPageSize = pageSize;
  const [totalPage, setTotalPage] = useState(1);

  React.useEffect(() => {
    if (currentPage == 1 && previousPage == false) {
      getCandidateResumeData(currentPage, currentPageSize);
    }
  }, [currentPage]);

  const fetchNextData = async () => {
    if (currentPage >= totalPage) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
      getCandidateResumeData(currentPage + 1, currentPageSize);
    }
  };

  const fetchPreviousData = async () => {
    if (currentPage <= 1) {
      return;
    } else {
      if (currentPage - 1 == 1) {
        setPreviousPage(true);
      }
      setCurrentPage(currentPage - 1);
      getCandidateResumeData(currentPage - 1, currentPageSize);
    }
  };

  const getCandidateResumeData = (getPage: any, getPageSize: any) => {
    globalSpinner.showSpinner();
    getAllResumeCandidateMap({
      CandidateId: candidateId,
      Page: getPage,
      PageSize: getPageSize,
      SearchTerm: "",
      SortOrder: "",
      ShowInactive: false,
    })
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (getPage == 1) {
          setTotalPage(Math.ceil(res.data.total / currentPageSize));
        }
        setCandidateResumes(res.data.data);
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        // //console.log(err);
      });
  };

  const handleCarouselNextClick = () => {
    //call api()
    fetchNextData();
  };

  const handleCarouselPreviousClick = () => {
    fetchPreviousData();
  };

  // const handleDashDisplay = () => {
  //   //console.log(dashDisplay);
  //   if (dashDisplay.includes("none")) {
  //     setDashDisplay("block !important");
  //   } else {
  //     setDashDisplay("none");
  //   }
  // };
  const editResume = async (e: any, i: any) => {
    history.push({
      pathname: "/candidate/my-resume/resume/my_information",
    });
  };
  const shareResume = async (e: any, i: any) => {
    //console.log(e, i);
    setIsShareOpen(!isShareOpen);
    setResumeShare(environment.baseUrl + e.resumeFileFullPath);
  };

  const downloadResumeShow = async (e: any, i: any) => {
    //console.log(e, i);
    setIsDownloadOpen(!isDownloadOpen);
    setResumeDownloadInfo(e);
    // var FileSaver = require('file-saver');
    // let url = "https://careerapp.azurewebsites.net" + e.resumeFileFullPath
    // let filename = e.resumeName + ".pdf";
    // //console.log(url, filename)
    // FileSaver.saveAs(url, filename);
    //FileSaver.saveAs(blob2, "blob2.pdf");
  };

  const downloadResume = async (e: any) => {
    ////console.log(resumeFormat);
    // //console.log(resumeDownloadInfo);
    var FileSaver = require("file-saver");
    if (resumeFormat == 1) {
      let url = environment.baseUrl + e.resumeFileFullPath;
      let filename = e.resumeName + ".docx";
      FileSaver.saveAs(url, filename);
    }
    if (resumeFormat == 2) {
      globalSpinner.showSpinner();
      generateResumePDF({
        resumehtml: e.resume,
      })
        .then((res1: any) => {
          FileSaver.saveAs(
            new Blob([res1.data], { type: "application/pdf" }),
            e.resumeName + ".pdf"
          );
          globalSpinner.hideSpinner();
        })
        .catch((err: any) => {
          ////console.log(err);
          globalSpinner.hideSpinner();
          toast.error(err.toString());
        });
    }
  };
  const removeResume = async (e: any, i: any) => {
    // //console.log(e, i);
    // if (await confirm({
    //   confirmation: "Are you sure you want to delete this?",
    //   options: {
    //     yes: "Yes",
    //     no: "No",
    //     header: "Delete"
    //   }
    // })) {
    // //console.log('yes');
    // //console.log(e, i);
    // } else {
    //   //console.log('no');
    //   //console.log(e, i);
    // }

    globalSpinner.showSpinner();
    deleteResumeCandidateMap({ RowId: e.rowId })
      .then((res) => {
        // //console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          // getAllResumeCandidateMap({
          //   CandidateId: candidateId,
          //   Page: 1,
          //   PageSize: 10,
          //   SearchTerm: "",
          //   SortOrder: "",
          //   ShowInactive: false,
          // })
          //   .then((res1) => {
          //     ////console.log(res.data);
          //     setCandidateResumes(res1.data);
          //   })
          //   .catch((err) => {
          //     ////console.log(err);
          //   });
          getCandidateResumeData(currentPage, currentPageSize);
          toast.success("Resume was successfully removed.");
        } else {
          //toast.error(res.data.message);
          toast.error("Resume not removed.");
        }
      })
      .catch((err) => {
        ////console.log(err);
        globalSpinner.hideSpinner();
        //toast.error(err.toString());
      });
  };

  //const { path, url } = useRouteMatch();
  return (
    <React.Fragment>
      <div className="heading_sec2">
        <h1>Resume Builder </h1>
        <div>
          {/* <Link
            to={`/candidate/my-resume/resume/my_information`}
            className="btn resume_build_but"
          >
            Build your resume with us
          </Link> */}

          {myProfileProgressBar.profileProgress < 40 ? (
            <>
              <Popover
                content={
                  //      "Please complete your profile to minimum 40% to access this feature."
                  <>
                    <p>
                      Please complete your profile to minimum 40% to access this
                      feature.
                    </p>
                    <Link to="/candidate/my-profile">Complete Now</Link>
                  </>
                }
              >
                <Link
                  to={`/candidate/my-resume`}
                  className="btn resume_build_but"
                >
                  Build your resume with us
                </Link>
              </Popover>
            </>
          ) : (
            <Link
              to={`/candidate/my-resume/resume/my_information`}
              className="btn resume_build_but"
            >
              Build your resume with us
            </Link>
          )}
        </div>
      </div>

      <div className="row">
        <div className="prodict_list">
          <div
            id="owl-demo1"
            className="owl-carousel owl-theme"
            style={{ opacity: 1, display: "block" }}
          >
            <div className="owl-wrapper-outer">
              <div
                className="owl-wrapper"
                style={{
                  width: "2568px",
                  left: "0px",
                  display: "block",
                  transition: "all 0ms ease 0s",
                  transform: "translate3d(0px, 0px, 0px)",
                  // transform: `translate3d(${carouselValue}px, 0px, 0px)`,
                }}
              >
                {candidateResumes != undefined &&
                  candidateResumes
                    .filter((f: any) => f.isActive == true)
                    .map((e: any, i: any) => (
                      <div className="owl-item" style={{ width: "321px" }}>
                        <div className="item">
                          <div className="matched_jobs_sec">
                            <div className="digis_expairs">
                              Created on - {e.createdDate}
                            </div>
                            <div className="matched_jobs">
                              <img
                                //src={require("./../../../../images/reume_img.png")}
                                src={
                                  environment.baseUrl +
                                  e.candidateResumeImageFullPath
                                }
                                alt=""
                                className="image2"
                              />
                              <div className="overlay">
                                <div className="myresume_edits1 ">
                                  {/* <a
                                    href={`${
                                      environment.baseUrl + e.resumeFileFullPath
                                    }`}
                                    target="_blank"
                                    className="myresume_clr1"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    />
                                  </a> */}
                                  <a
                                    onClick={() => {
                                      downloadResumeShow(e, i);
                                    }}
                                    className="myresume_clr1"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    />
                                  </a>
                                  <a
                                    onClick={() => {
                                      shareResume(e, i);
                                    }}
                                    className="myresume_clr2"
                                    data-target="#share"
                                    data-toggle="modal"
                                  >
                                    <i
                                      className="fa fa-share-alt"
                                      aria-hidden="true"
                                    />
                                  </a>
                                  <a
                                    onClick={() => {
                                      editResume(e, i);
                                    }}
                                    className="myresume_clr3"
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    />
                                  </a>
                                  {/* <a onClick={() => { removeResume(e, i); }} className="myresume_clr4"><i className="fa fa-trash-o" aria-hidden="true" /></a> */}
                                  <Popconfirm
                                    title={
                                      <div>
                                        <p>
                                          Are you sure delete this document{" "}
                                        </p>
                                        Name: {`${e.resumeName}`}
                                        <br />
                                        Last Modified: {`${e.updatedDate}`}
                                      </div>
                                    }
                                    onConfirm={() =>
                                      //handleDeleted(document.rowId)
                                      removeResume(e, i)
                                    }
                                    //onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <a className="myresume_clr4">
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </Popconfirm>
                                </div>
                              </div>
                            </div>
                            <div className="digis_documents">
                              <i
                                className="fa fa-file-text-o"
                                aria-hidden="true"
                              />{" "}
                              {e.resumeName}
                            </div>
                            <div className="action_btn1">
                              {/* <button
                                onClick={handleDashDisplay}
                                type="button"
                                className="actions_bt3"
                              >
                                <i
                                  className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                  aria-hidden="true"
                                />
                              </button> */}
                              <div className="dash_action3" tabIndex={-1}>
                                <div //className="action_sec"
                                  style={{
                                    display: "block",
                                    //display: `${dashDisplay}`,
                                    //opacity: 0.598829
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <a href="#">Rename</a>{" "}
                                    </li>
                                    <li>
                                      <a href="#"> Duplicate</a>
                                    </li>
                                    {/* <li><a href="#">Dummy</a></li>
                                  <li><a href="#">Dummy</a></li> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            <div className="owl-controls clickable" style={{ display: "none" }}>
              <div className="owl-pagination">
                <div className="owl-page active">
                  <span className="" />
                </div>
              </div>
              <div className="owl-buttons">
                <div className="owl-prev" onClick={handleCarouselPreviousClick}>
                  <img src={require("../../../../images/products_ar1.png")} />
                </div>
                <div className="owl-next" onClick={handleCarouselNextClick}>
                  <img src={require("../../../../images/products_ar2.png")} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={isShareOpen}
        onHide={() => {
          setIsShareOpen(!isShareOpen);
          setResumeShare("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share this Resume on</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 m_t_30 text-center">
            <div className="social1">
              <WhatsappShareButton url={resumeShare} className={""}>
                {/* <a href="#" className="social_face"> */}
                <i
                  className="fa fa-whatsapp"
                  style={{ fontSize: 48, color: "green" }}
                ></i>
                {/* </a> */}
              </WhatsappShareButton>{" "}
              {/* <EmailShareButton
                url={resumeShare}
                subject={"Resume In Carrer app"}
                body={"This is a Resume from Carrer app"}
                className={""}
              >
                <EmailIcon></EmailIcon>
              </EmailShareButton>{" "}
              <CopyToClipboard
                text={resumeShare}
              >
                <Tooltip title="copy to clipboard">
                  {" "}
                  <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i>
                </Tooltip>
              </CopyToClipboard> */}
              <TelegramShareButton
                url={resumeShare}
                // title={encodeURI(
                //   `https://careerapp.azurewebsites.net/${documentSharedResult}`
                // )}
              >
                <Tooltip title="Telegram">
                  <i
                    className="fa fa-telegram"
                    style={{ fontSize: 48, color: "rgb(55, 174, 226)" }}
                  ></i>
                </Tooltip>
              </TelegramShareButton>
              <EmailShareButton
                url={resumeShare}
                subject={"Resume In Carrer app"}
                body={"This is a Resume from Carrer app"}
                className={""}
              >
                {/* <EmailIcon></EmailIcon> */}
                {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                <Tooltip title="Gmail">
                  <img
                    src={require("../../../../images/gmail-icon-svg-28.jpg")}
                    style={{ height: 66, marginTop: -24 }}
                  />
                </Tooltip>
              </EmailShareButton>{" "}
              <CopyToClipboard
                //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                text={resumeShare}
              >
                <Tooltip title="copy to clipboard">
                  {" "}
                  {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                  <img
                    src={require("../../../../images/copy-clipboard-icon-28.jpg")}
                    style={{ height: 66, marginTop: -24, marginLeft: -15 }}
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  />
                </Tooltip>
              </CopyToClipboard>
            </div>
          </div>
          <div className="modal-footer  m-t-30"></div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isDownloadOpen}
        onHide={() => {
          setIsDownloadOpen(!isDownloadOpen);
          setResumeFormat(0);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Resume </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DownloadSavedResumes
            downloadResume={downloadResume}
            resumeDownloadInfo={resumeDownloadInfo}
            resumeFormat={resumeFormat}
            setIsDownloadOpen={setIsDownloadOpen}
            setResumeFormat={setResumeFormat}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
export default CandidateResumes;
