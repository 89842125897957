import React, { useState } from "react";
import {
  getSocialAccountUrlList,
  useSocialAccountUrlContext,
  useSocialAccountUrlDispatcher,
} from "../../../action/admin/SocialAccountUrlAction";

import { SocialAccountUrlRequestModel } from "../../../models/admin/SocialAccountUrl";

interface IIndexFooterComponentProps {}

interface IIndexFooterComponentState {}

const initialState = {};

const IndexFooterComponent: React.FC<IIndexFooterComponentProps> = (props) => {
  const [
    IndexFooterComponentState,
    setIndexFooterComponentState,
  ] = React.useState<IIndexFooterComponentState>(initialState);
  const SocialAccountUrlDispatcher = useSocialAccountUrlDispatcher();
  const SocialAccountUrlContext = useSocialAccountUrlContext();
  const { SocialAccountUrl } = SocialAccountUrlContext;
  const [socialURL, setSocialURL] = useState({
    facebook: "#",
    google: "#",
    linkedIn: "#",
    twitter: "#",
    instagram: "#",
    youtube: "#",
    contact: "086 834 2525",
    email: "info@jit.com",
  });
  React.useEffect(() => {
    if (SocialAccountUrl.data == undefined) {
      (async () => {
        await getSocialAccountUrlList(
          SocialAccountUrlDispatcher,
          {
            adminId: 0,
            page: 1,
            pageSize: 60,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as SocialAccountUrlRequestModel,
          ""
        );
      })();
    }
  }, [SocialAccountUrl.data]);

  React.useEffect(() => {
    //   setSocialURL( prevValues => {
    //     return { ...prevValues,[e.target.name]: e.target.value}
    //  }
    // { facebook: "#", linkedIn: "#", twitter: "#", instagram: "#", youtube: '#', contact: "086 834 2525", email: "info@jit.com" });
    if (SocialAccountUrl.data != undefined) {
      SocialAccountUrl.data.forEach((item: any) => {
        // //console.log(item);
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("facebook")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["facebook"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("google")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["google"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("twitter")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["twitter"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("instagram")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["instagram"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("youtube")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["youtube"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"])
            .toLowerCase()
            .includes("contact")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["contact"]: item["linkUrl"] };
          });
        }
        if (
          String(item["socialAccountTypeTitle"]).toLowerCase().includes("email")
        ) {
          setSocialURL((prevValues) => {
            return { ...prevValues, ["email"]: item["linkUrl"] };
          });
        }
      });
    }
  }, [SocialAccountUrl.data]);

  return (
    <>
      <section>
        <div className="bottom-sec">
          <div className="sec_main">
            <div className="container">
              <div className="row">
                <div className="col-sm-3 wow fadeInLeft" data-wow-delay=".4s">
                  <div className="bottom_logo">
                    {" "}
                    <img
                      src={require("../../../images/logo_bottom.png")}
                      // src="images/logo_bottom.png"
                      className="img-responsive"
                    />
                  </div>
                  <div className="bottom_contact_main">
                    <div className="bottom_contact_icon">
                      <i className="fa fa-map-marker" aria-hidden="true" />
                    </div>
                    <div className="bottom_contact_con">
                      JIT International Mazyad Mall, 10th Floor, Tower 2
                      Mohammed Bin Zayed City, Mussaffah, Abu Dhabi, United Arab
                      Emirates
                    </div>
                    <div className="clearfix" />
                    <div className="bottom_contact_icon">
                      <i className="fa fa-phone" aria-hidden="true" />
                    </div>
                    <div className="bottom_contact_con">
                      {/* 086 834 2525 */}
                      <a> {socialURL.contact}</a>
                    </div>
                    <div className="clearfix" />
                    <div className="bottom_contact_icon">
                      <i className="fa fa-envelope-o" aria-hidden="true" />
                    </div>
                    <div className="bottom_contact_con">
                      {/* info@jit.com */}
                      <a>{socialURL.email}</a>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-sm-3 wow fadeInLeft" data-wow-delay=".8s">
                  {/* <h2>About JIT </h2> */}
                  <ul>
                    {/* <li>
                      <a href="#">Company</a>{" "}
                    </li>
                    <li>
                      <a href="#">Careers</a>{" "}
                    </li>
                    <li>
                      <a href="#">News</a>{" "}
                    </li>
                    <li>
                      <a href="#">Events</a>{" "}
                    </li>
                    <li>
                      <a href="#">Blogs</a>{" "}
                    </li>
                    <li>
                      <a href="#">Trust &amp; Security </a>{" "}
                    </li>
                    <li>
                      <a href="#">Contact us</a>{" "}
                    </li> */}
                  </ul>
                </div>
                <div className="col-sm-3 wow fadeInLeft" data-wow-delay=".6s">
                  {/* <h2>Products </h2> */}
                  <ul>
                    {/* <li>
                      <a href="#">JIT Software</a>{" "}
                    </li>
                    <li>
                      <a href="#">JIT Align</a>{" "}
                    </li>
                    <li>
                      <a href="#">JIT Service Desk</a>{" "}
                    </li>
                    <li>
                      <a href="#">Confluence</a>{" "}
                    </li>
                    <li>
                      <a href="#">Trello</a>{" "}
                    </li>
                    <li>
                      <a href="#">Bitbucket </a>{" "}
                    </li> */}
                  </ul>
                </div>
                <div className="col-sm-3 wow fadeInLeft" data-wow-delay=".8s">
                  <h2>Connect with Us </h2>
                  <div className="social">
                    <a
                      href={socialURL.facebook}
                      target="_blank"
                      className="social_face"
                    >
                      <i className="fa fa-facebook" aria-hidden="true" />
                    </a>
                    <a
                      href={socialURL.twitter}
                      target="_blank"
                      className="social_twit"
                    >
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </a>
                    <a
                      href={socialURL.instagram}
                      target="_blank"
                      className="social_insta"
                    >
                      <i className="fa fa-instagram" aria-hidden="true" />
                    </a>
                    <a
                      href={socialURL.youtube}
                      target="_blank"
                      className="social_youtube"
                    >
                      <i className="fa fa-youtube-play" aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="footer_link">
                  <a href="#">Privacy Policy</a>
                  <a href="#">Terms </a>
                  Copyright © 2020 internationaljit.com
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default IndexFooterComponent;
