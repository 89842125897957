import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";

import { digiLockerGetDataWithId } from "../../models/candidate/DigiLocker";
import { categoryWithId } from "../../models/candidate/JobCategory";
import {
  jobAlertVendorGellAllRequestModel,
  jobAlertVendorGellAllRespondModel,
  jobAlertVendorRequestmodel,
  jobAlertVendorSaveRequestModel,
  jobAlertVendorSaveRespondModel,
  jobAlertVendorTitleDropdownResult,
  jobAlertVendorViewModel,
} from "../../models/vendor/JobAlert";
import {
  IJobAlertVendorManagementState,
  JobAlertVendorDispatcherContext,
  JobAlertVendorStateContext,
} from "../../context/vendor/JobAlertContext";
import message from "antd/lib/message";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IJobAlertVendorAction =
  | {
      type: "SET_Job Alert_LIST";
      jobAlertVendor: jobAlertVendorViewModel;
    }
  | {
      type: "SET_JOB_AlERT_SAVE";
      jobAlertVendorSaveRespond: jobAlertVendorSaveRespondModel;
    }
  | {
      type: "SET_JOB_AlERT_CATEGORY_MAP_SAVE";
      jobAlertVendorCategoryMapSaveRespond: jobAlertVendorSaveRespondModel;
    }
  | {
      type: "SET_JOB_AlERT_EXPERIENCE_MAP_SAVE";
      jobAlertVendorExperienceMapSaveRespond: jobAlertVendorSaveRespondModel;
    }
  | {
      type: "SET_JOB_AlERT_INDUSTRY_MAP_SAVE";
      jobAlertVendorIndustryMapSaveRespond: jobAlertVendorSaveRespondModel;
    }
  | {
      type: "SET_JOB_AlERT_ROLE_MAP_SAVE";
      JobAlertVendorRoleMapSaveRespond: jobAlertVendorSaveRespondModel;
    }
  | {
      type: "SET_JOB_AlERT_TYPE_MAP_SAVE";
      JobAlertVendorTypeMapSaveRespond: jobAlertVendorSaveRespondModel;
    }
  | {
      type: "GET_ALL_JOB_ALERT";
      jobAlertVendorGetAll: jobAlertVendorGellAllRespondModel;
    }
  | {
      type: "DELETE_JOB_ALERT";
      JobAlertVendorRoleMapSaveRespond: jobAlertVendorSaveRespondModel;
    }
  | {
      type: "GET_TITLE_WITH_CATEGORY_ID";
      jobAlertVendorTitleDropdownResult: jobAlertVendorTitleDropdownResult[];
    };

export const jobAlertVendorReducer: Reducer<
  IJobAlertVendorManagementState,
  IJobAlertVendorAction
> = (draft, action): IJobAlertVendorManagementState => {
  switch (action.type) {
    case "SET_Job Alert_LIST":
      draft.jobAlertVendor = action.jobAlertVendor;
      return draft;
    case "SET_JOB_AlERT_SAVE":
      draft.jobAlertVendorSaveRespond = action.jobAlertVendorSaveRespond;
      return draft;
    case "SET_JOB_AlERT_CATEGORY_MAP_SAVE":
      draft.jobAlertVendorCategoryMapSaveRespond =
        action.jobAlertVendorCategoryMapSaveRespond;
      return draft;
    case "SET_JOB_AlERT_EXPERIENCE_MAP_SAVE":
      draft.jobAlertVendorExperienceMapSaveRespond =
        action.jobAlertVendorExperienceMapSaveRespond;
      return draft;
    case "SET_JOB_AlERT_INDUSTRY_MAP_SAVE":
      draft.jobAlertVendorIndustryMapSaveRespond =
        action.jobAlertVendorIndustryMapSaveRespond;
      return draft;
    case "SET_JOB_AlERT_ROLE_MAP_SAVE":
      draft.JobAlertVendorRoleMapSaveRespond =
        action.JobAlertVendorRoleMapSaveRespond;
      return draft;
    case "SET_JOB_AlERT_TYPE_MAP_SAVE":
      draft.JobAlertVendorRoleMapSaveRespond =
        action.JobAlertVendorTypeMapSaveRespond;
      return draft;
    case "GET_ALL_JOB_ALERT":
      draft.jobAlertVendorGetAll = action.jobAlertVendorGetAll;
      return draft;
    case "DELETE_JOB_ALERT":
      draft.JobAlertVendorRoleMapSaveRespond =
        action.JobAlertVendorRoleMapSaveRespond;
      return draft;
    case "GET_TITLE_WITH_CATEGORY_ID":
      draft.jobAlertVendorTitleDropdownResult =
        action.jobAlertVendorTitleDropdownResult;
      return draft;
  }
};

export const useJobAlertVendorDispatcher = (): React.Dispatch<IJobAlertVendorAction> => {
  const jobAlertVendorDispatcher = React.useContext(
    JobAlertVendorDispatcherContext
  );
  if (!jobAlertVendorDispatcher) {
    throw new Error(
      "You have to provide the JobAlertVendor dispatcher using theJobAlertVendorDispatcherContext.Provider in a parent component."
    );
  }
  return jobAlertVendorDispatcher;
};

export const useJobAlertVendorContext = (): IJobAlertVendorManagementState => {
  const jobAlertVendorContext = React.useContext(JobAlertVendorStateContext);
  if (!jobAlertVendorContext)
    throw new Error(
      "You have to provide the jobAlertVendor context using the JobAlertVendorStateContext.Provider in a parent component."
    );
  return jobAlertVendorContext;
};

//Get All List

// get  list

// export const getJobAlertVendorList = async (
//   dispatcher: React.Dispatch<IJobAlertVendorAction>,
//   query: jobAlertVendorRequestmodel,
//   token: string
// ) => {
//   try {
//     if (token?.startsWith(`"`)) {
//       token = token.substring(1);
//       token = token.slice(0, -1);
//     }

//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     };

//     const url = AppUrls.GetAllJobAlertVendor;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({ type: "SET_Job Alert_LIST", jobAlertVendor: res.data });
//     });
//   } catch (e) {}
// };

// Save call

export const setJobAlertVendorSave = async (
  dispatcher: React.Dispatch<IJobAlertVendorAction>,
  query: jobAlertVendorSaveRequestModel,
  token: string
) => {
  if (token?.startsWith(`"`)) {
    token = token.substring(1);
    token = token.slice(0, -1);
  }
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveVendorJobAlert;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_JOB_AlERT_SAVE",
        jobAlertVendorSaveRespond: res.data,
      });
      res.data.isSuccess
        ? message.success("Job Alert Saved sucessfully")
        : message.error(res.data.message);
    });
  } catch (e) {}
};

//Save job alert Categorymap

// export const setJobAlertVendorCategoryMapSave = async (
//   dispatcher: React.Dispatch<IJobAlertVendorAction>,
//   query: jobAlertVendorCategoryMapRequestModel
// ) => {
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${authorizationToken}`,
//     };

//     const url = AppUrls.SaveJobAlertVendorCategoryMap;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "SET_JOB_AlERT_CATEGORY_MAP_SAVE",
//         jobAlertVendorCategoryMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

//save JobAlertVendorExperienceMap

// export const setJobAlertVendorExperienceMapSave = async (
//   dispatcher: React.Dispatch<IJobAlertVendorAction>,
//   query: jobAlertVendorExperienceMapRequestModel
// ) => {
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${authorizationToken}`,
//     };

//     const url = AppUrls.SaveJobAlertVendorExperienceMap;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "SET_JOB_AlERT_EXPERIENCE_MAP_SAVE",
//         jobAlertVendorExperienceMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

// save JobAlertVendorIndustryMap

// export const setJobAlertVendorIndustryMapMapSave = async (
//   dispatcher: React.Dispatch<IJobAlertVendorAction>,
//   query: jobAlertVendorIndustryMapSaveRequestModel
// ) => {
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${authorizationToken}`,
//     };

//     const url = AppUrls.SaveJobAlertVendorIndustryMap;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "SET_JOB_AlERT_INDUSTRY_MAP_SAVE",
//         jobAlertVendorIndustryMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

//save JobAlertVendorRoleMap

// export const setJobAlertVendorRoleMapMapSave = async (
//   dispatcher: React.Dispatch<IJobAlertVendorAction>,
//   query: JobAlertVendorRoleMapSaveRequestModel
// ) => {
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${authorizationToken}`,
//     };

//     const url = AppUrls.SaveJobAlertVendorRoleMapMap;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "SET_JOB_AlERT_ROLE_MAP_SAVE",
//         JobAlertVendorRoleMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

// save JobAlertVendorTypeMap

// export const setJobAlertVendorTypeMapSave = async (
//   dispatcher: React.Dispatch<IJobAlertVendorAction>,
//   query: JobAlertVendorTypeMapSaveRequestModel
// ) => {
//   try {
//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${authorizationToken}`,
//     };

//     const url = AppUrls.SaveJobAlertVendorTypeMap;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "SET_JOB_AlERT_TYPE_MAP_SAVE",
//         JobAlertVendorTypeMapSaveRespond: res.data,
//       });
//     });
//   } catch (e) {}
// };

export const getAllJobAlertVendorList = async (
  dispatcher: React.Dispatch<IJobAlertVendorAction>,
  query: jobAlertVendorGellAllRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllVendorJobAlert;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "GET_ALL_JOB_ALERT", jobAlertVendorGetAll: res.data });
    });
  } catch (e) {}
};

export const deleteJobAlertVendorList = async (
  dispatcher: React.Dispatch<IJobAlertVendorAction>,
  query: digiLockerGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteVendorJobAlert;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "DELETE_JOB_ALERT",
        JobAlertVendorRoleMapSaveRespond: res.data,
      });
      res.data.isSuccess
        ? message.success("Job Alert Deleted sucessfully")
        : message.error(res.data.message);
    });
  } catch (e) {}
};

// export const getTitleWithCategoryId = async (
//   dispatcher: React.Dispatch<IJobAlertVendorAction>,
//   query: categoryWithId,
//   token: string
// ) => {
//   try {
//     if (token?.startsWith(`"`)) {
//       token = token.substring(1);
//       token = token.slice(0, -1);
//     }

//     let header = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     };

//     const url = AppUrls.GetTitleWithCategory;

//     axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
//       dispatcher({
//         type: "GET_TITLE_WITH_CATEGORY_ID",

//         jobAlertVendorTitleDropdownResult: res.data,
//       });
//       //console.log(676767676, res.data);
//     });
//   } catch (e) {}
// };
