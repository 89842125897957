import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import PreviewHtmlTemplate from "./PreviewHtmlTemplate";
import { useHistory } from "react-router-dom";
import { GlobalSpinnerContext } from "../../../../context/GlobalSpinner";
import { toast, ToastContainer } from "react-toastify";
import {
  saveCoverLetterCandidateMap,
  generateResumePDF,
} from "../../../../apis/resumebuilder";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Modal } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { asBlob } from "html-docx-js-typescript";

type objectIndexing = {
  [key: string]: any;
};

interface ICoverLetterPreviewComponentProps {
  coverLetterInfo: any;
  candidateId: Number;
  coverLetterTemplateID: Number;
  coverLetterTemplateHtml: string;
  coverLetterDescription: string;
  coverLetterTitle: string;
}

interface ICoverLetterPreviewComponentState {}

//const initialState = {};

interface ICandidateCoverLetterState {
  coverLetterName: string;
}

function SaveCandidateCoverLetter(props: any) {
  const defaultValues = {
    coverLetterName: "",
  };

  const {
    register,
    handleSubmit,
    //, watch
    errors,
    // , setValue, getValues, control, reset
  } = useForm<ICandidateCoverLetterState>({
    defaultValues,
  });
  const onSubmit = (data: any) => {
    var file_name_string = data["coverLetterName"];
    var cover_letter_name_array = file_name_string.split(".");
    props.saveTemplateWithName(cover_letter_name_array[0]);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Modal.Header closeButton>
        <Modal.Title>Add Cover Letter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="email">Cover Letter</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type Here"
              name="coverLetterName"
              ref={register({
                required: "Cover letter name is required",
              })}
            />

            <ErrorMessage
              errors={errors}
              name="coverLetterName"
              render={({ message }) => (
                <div className="login_validation">{message}</div>
              )}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer  m-t-30">
        <button
          className="btn btn-success save-event waves-effect waves-light"
          type="submit"
        >
          {" "}
          Add{" "}
        </button>
        <button
          onClick={() => {
            props.setIsOpen(false);
          }}
          data-dismiss="modal"
          className="btn btn-default waves-effect _cursor-pointer"
          type="button"
        >
          {" "}
          Cancel
        </button>
      </div>
      <div className="clearfix" />
    </form>
  );
}

const CoverLetterPreviewComponent: React.FC<ICoverLetterPreviewComponentProps> = (
  props
) => {
  // const [coverLetterPreviewComponentState, setCoverLetterPreviewComponentState] = React.useState<ICoverLetterPreviewComponentState>(
  //   initialState
  // );
  const [coverLetterTemplateID, setCoverLetterTemplateID] = useState(0);
  const [coverLetterTemplateHtml, setCoverLetterTemplateHtml] = useState(
    "coverletter1.html"
  );
  const [coverLetterDescription, setcoverLetterDescription] = useState("");
  const [coverLetterInfo, setCoverLetterInfo] = useState({});
  const [coverLetterTitle, setCoverLetterTitle] = useState({});
  const globalSpinner = useContext(GlobalSpinnerContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  let history = useHistory();

  const handleReselectTemplate = () => {
    history.push({
      pathname: "/candidate/my-resume/cover-letter/Select_Template",
    });
  };

  const saveTemplateWithName = (coverletterName: any) => {
    let input = document.getElementById("pdfdiv");
    globalSpinner.showSpinner();
    if (input) {
      const divHeight = input.clientHeight;
      const divWidth = input.clientWidth;
      const ratio = divHeight / divWidth;
      let _pdfData: objectIndexing = {};
      _pdfData["CoverLetter"] = input.innerHTML;
      html2canvas(input, {
        height: divHeight,
        width: divWidth,
        logging: true,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      }).then((canvas) => {
        let imgData = canvas.toDataURL("image/jpeg");
        // const pdf = new jsPDF(); // using defaults: orientation=portrait, unit=mm, size=A4
        // var width = pdf.internal.pageSize.getWidth();
        // var height = pdf.internal.pageSize.getHeight();
        // height = ratio * width;
        // let sub = 10;
        // if (ratio > 1.5 && ratio < 2) {
        //   sub = 50  //tested 1.6
        // }
        // else if (ratio > 1 && ratio < 1.5) {
        //   sub = 20 //tested 1.3
        // }
        // pdf.addImage(imgData, 'JPEG', 0, 0, width - 3, height - sub);
        var b64toBlob = require("b64-to-blob");
        var info = imgData.replace("data:image/jpeg;base64,", "");
        var blob = b64toBlob(info, "image/jpg");
        _pdfData["CoverLetterImageDocument"] = blob;
        //var binary = pdf.output();
        // var blob2 = b64toBlob(btoa(binary), 'application/pdf');
        // _pdfData["CoverLetterFileDocument"] = blob2;
        // const opt1 = {
        //   margin: {
        //     top: 10
        //   },
        //   orientation: 'landscape' as const
        // }
        // asBlob(_pdfData["CoverLetter"], opt1).then((data: any) => {
        generateResumePDF({
          resumehtml: _pdfData["CoverLetter"],
        })
          .then((data: any) => {
            _pdfData["CoverLetterFileDocument"] = new Blob([data.data], {
              type: "application/pdf",
            });

            const formData = new FormData();
            formData.append("RowId", JSON.stringify(0));
            formData.append("CoverLetterCandidateMapId", JSON.stringify(0));
            formData.append(
              "CoverLetterTemplateId",
              JSON.stringify(coverLetterTemplateID)
            );
            formData.append("CandidateId", JSON.stringify(props.candidateId));
            formData.append("CoverLetter", _pdfData["CoverLetter"]);
            formData.append("CoverLetterName", coverletterName);
            formData.append("CoverLetterFile", coverletterName + ".pdf");
            formData.append(
              "CoverLetterFileDocument",
              _pdfData["CoverLetterFileDocument"],
              coverletterName + ".pdf"
            );
            formData.append("CoverLetterImage", coverletterName + ".jpg");
            formData.append(
              "CoverLetterImageDocument",
              _pdfData["CoverLetterImageDocument"],
              coverletterName + ".jpg"
            );
            formData.append("IsActive", JSON.stringify(true));
            saveCoverLetterCandidateMap(formData)
              .then((res) => {
                ////console.log(res.data);
                globalSpinner.hideSpinner();
                if (res.data.isSuccess) {
                  setIsOpen(false);
                  toast.success(res.data.message);
                  setTimeout(
                    () =>
                      history.push({
                        pathname: "/candidate/my-resume",
                      }),
                    3000
                  );
                } else {
                  toast.error(res.data.message);
                }
              })
              .catch((err) => {
                ////console.log(err);
                globalSpinner.hideSpinner();
                toast.error(err.toString());
              });
          })
          .catch((err) => {
            ////console.log(err);
            globalSpinner.hideSpinner();
            toast.error(err.toString());
          });
      });
    } else {
      globalSpinner.hideSpinner();
      toast.error("Unable to save,Please try again.");
    }
  };

  // const saveTemplateWithName = (coverletterName: any) => {
  //   let input = document.getElementById('pdfdiv');
  //   globalSpinner.showSpinner();
  //   if (input) {

  //     const divHeight = input.clientHeight;
  //     const divWidth = input.clientWidth;
  //     const ratio = divHeight / divWidth;
  //     let _pdfData: objectIndexing = {};
  //     _pdfData["CoverLetter"] = input.innerHTML;
  //     html2canvas(input,
  //       { height: divHeight, width: divWidth, logging: true, useCORS: true, allowTaint: true, scale: 2 }
  //     ).then((canvas) => {

  //       let imgData = canvas.toDataURL("image/jpeg");
  //       const pdf = new jsPDF(); // using defaults: orientation=portrait, unit=mm, size=A4
  //       var width = pdf.internal.pageSize.getWidth();
  //       var height = pdf.internal.pageSize.getHeight();
  //       height = ratio * width;
  //       let sub = 10;
  //       if (ratio > 1.5 && ratio < 2) {
  //         sub = 50  //tested 1.6
  //       }
  //       else if (ratio > 1 && ratio < 1.5) {
  //         sub = 20 //tested 1.3
  //       }
  //       pdf.addImage(imgData, 'JPEG', 0, 0, width - 3, height - sub);
  //       var b64toBlob = require('b64-to-blob')
  //       var info = imgData.replace("data:image/jpeg;base64,", "");
  //       var blob = b64toBlob(info, 'image/jpg');
  //       _pdfData["CoverLetterImageDocument"] = blob;
  //       //var binary = pdf.output();
  //       // var blob2 = b64toBlob(btoa(binary), 'application/pdf');
  //       // _pdfData["CoverLetterFileDocument"] = blob2;
  //       const opt1 = {
  //         margin: {
  //           top: 10
  //         },
  //         orientation: 'landscape' as const
  //       }
  //       asBlob(_pdfData["CoverLetter"], opt1).then((data: any) => {
  //         _pdfData["CoverLetterFileDocument"] = data

  //         const formData = new FormData();
  //         formData.append("RowId", JSON.stringify(0));
  //         formData.append("CoverLetterCandidateMapId", JSON.stringify(0));
  //         formData.append("CoverLetterTemplateId", JSON.stringify(coverLetterTemplateID));
  //         formData.append("CandidateId", JSON.stringify(props.candidateId));
  //         formData.append("CoverLetter", _pdfData["CoverLetter"]);
  //         formData.append("CoverLetterName", coverletterName);
  //         // formData.append("CoverLetterFile", coverletterName + ".pdf");
  //         formData.append("CoverLetterFile", coverletterName + ".docx");
  //         //formData.append("CoverLetterFileDocument", _pdfData["CoverLetterFileDocument"], coverletterName + ".pdf");
  //         formData.append("CoverLetterFileDocument", _pdfData["CoverLetterFileDocument"], coverletterName + ".docx");
  //         formData.append("CoverLetterImage", coverletterName + ".jpg");
  //         formData.append("CoverLetterImageDocument", _pdfData["CoverLetterImageDocument"], coverletterName + ".jpg");
  //         formData.append("IsActive", JSON.stringify(true));
  //         saveCoverLetterCandidateMap(formData).then((res) => {
  //           ////console.log(res.data);
  //           globalSpinner.hideSpinner();
  //           if (res.data.isSuccess) {
  //             setIsOpen(false);
  //             toast.success(res.data.message);
  //             setTimeout(() =>
  //               history.push({
  //                 pathname: '/candidate/my-resume',
  //               }), 3000);
  //           }
  //           else {
  //             toast.error(res.data.message);
  //           }
  //         }).catch((err) => {
  //           ////console.log(err);
  //           globalSpinner.hideSpinner();
  //           toast.error(err.toString());
  //         });
  //       });

  //     })
  //   }
  //   else {
  //     globalSpinner.hideSpinner();
  //     toast.error("Unable to save,Please try again.");
  //   }
  // };

  // const saveTemplateWithName = (coverletterName: any) => {
  //   let input = document.getElementById('pdfdiv');
  //   globalSpinner.showSpinner();
  //   if (input) {

  //     const divHeight = input.clientHeight;
  //     const divWidth = input.clientWidth;
  //     const ratio = divHeight / divWidth;
  //     let _pdfData: objectIndexing = {};
  //     _pdfData["CoverLetter"] = input.innerHTML;
  //     html2canvas(input,
  //       { height: divHeight, width: divWidth, logging: true, useCORS: true, allowTaint: true, scale: 2 }
  //     ).then((canvas) => {

  //       let imgData = canvas.toDataURL("image/jpeg");
  //       const pdf = new jsPDF(); // using defaults: orientation=portrait, unit=mm, size=A4
  //       var width = pdf.internal.pageSize.getWidth();
  //       var height = pdf.internal.pageSize.getHeight();
  //       height = ratio * width;
  //       let sub = 10;
  //       if (ratio > 1.5 && ratio < 2) {
  //         sub = 50  //tested 1.6
  //       }
  //       else if (ratio > 1 && ratio < 1.5) {
  //         sub = 20 //tested 1.3
  //       }
  //       pdf.addImage(imgData, 'JPEG', 0, 0, width - 3, height - sub);
  //       var b64toBlob = require('b64-to-blob')
  //       var info = imgData.replace("data:image/jpeg;base64,", "");
  //       var blob = b64toBlob(info, 'image/jpg');
  //       _pdfData["CoverLetterImageDocument"] = blob;
  //       var binary = pdf.output();
  //       var blob2 = b64toBlob(btoa(binary), 'application/pdf');
  //       _pdfData["CoverLetterFileDocument"] = blob2;
  //       const formData = new FormData();
  //       formData.append("RowId", JSON.stringify(0));
  //       formData.append("CoverLetterCandidateMapId", JSON.stringify(0));
  //       formData.append("CoverLetterTemplateId", JSON.stringify(coverLetterTemplateID));
  //       formData.append("CandidateId", JSON.stringify(props.candidateId));
  //       formData.append("CoverLetter", _pdfData["CoverLetter"]);
  //       formData.append("CoverLetterName", coverletterName);
  //       formData.append("CoverLetterFile", coverletterName + ".pdf");
  //       formData.append("CoverLetterFileDocument", _pdfData["CoverLetterFileDocument"], coverletterName + ".pdf");
  //       formData.append("CoverLetterImage", coverletterName + ".jpg");
  //       formData.append("CoverLetterImageDocument", _pdfData["CoverLetterImageDocument"], coverletterName + ".jpg");
  //       formData.append("IsActive", JSON.stringify(true));
  //       saveCoverLetterCandidateMap(formData).then((res) => {
  //         //console.log(res.data);
  //         globalSpinner.hideSpinner();
  //         if (res.data.isSuccess) {
  //           toast.success(res.data.message);
  //           history.push({
  //             pathname: '/candidate/my-resume',
  //           });
  //         }
  //         else {
  //           toast.error(res.data.message);
  //         }
  //       }).catch((err) => {
  //         //console.log(err);
  //         globalSpinner.hideSpinner();
  //         toast.error(err.toString());
  //       });

  //     })
  //   }
  //   else {
  //     globalSpinner.hideSpinner();
  //     toast.error("Unable to save,Please try again.");
  //   }
  // };

  useEffect(() => {
    setCoverLetterTemplateID(Number(props.coverLetterTemplateID));
    setCoverLetterTemplateHtml(props.coverLetterTemplateHtml);
    setcoverLetterDescription(props.coverLetterDescription);
    setCoverLetterInfo(props.coverLetterInfo);
    setCoverLetterTitle(props.coverLetterTitle);
  }, [props.coverLetterTemplateID, props.coverLetterInfo]);
  return (
    <>
      <ToastContainer />
      <div className="content-wrapper">
        <div className="container-fluid">
          <button
            className="btn cutomise_but"
            disabled={coverLetterTemplateID == 0 ? true : false}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {" "}
            Save Cover Letter
          </button>
          <button
            className="btn cutomise_but"
            onClick={() => {
              handleReselectTemplate();
            }}
          >
            {" "}
            Reselect Template
          </button>
          <div className="clearfix" />
          {coverLetterTemplateID > 0 && coverLetterInfo && (
            <PreviewHtmlTemplate
              coverLetterInfo={props.coverLetterInfo}
              coverLetterTemplateID={coverLetterTemplateID}
              coverLetterTemplateHtml={coverLetterTemplateHtml}
              coverLetterDescription={coverLetterDescription}
              coverLetterTitle={coverLetterTitle}
            />
          )}
        </div>
        <div className="clearfix" />
      </div>
      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <SaveCandidateCoverLetter
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          saveTemplateWithName={saveTemplateWithName}
        />
      </Modal>
    </>
  );
};
export default CoverLetterPreviewComponent;
