import Axios from "axios";
import { apiClient } from "./../utils/httpClient";

export function registerMe(data: any) {
  return apiClient("/api/account/register-me", data);
}

export function createCandidate(data: any) {
  return apiClient("/api/Candidate/coea", data);
}

export function createVendor(data: any) {
  return apiClient("/api/Vendor/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function createSuperAdmin(data: any) {
  return apiClient("/api/SuperAdmin/coea", data);
}

export function createAdmin(data: any) {
  return apiClient("/api/AdminProfile/update-admin-profile", data);
}

export function resetPassword(data: any) {
  return apiClient("/api/Account/reset-password", data);
}

export function forgotPasswordLink(data: any) {
  return apiClient("/api/Account/forgot-password-link", data);
}

export function sendPhoneOtp(data: any, query: string) {
  return apiClient(`/api/Account/send-phone-otp${query}`, data);
}

export function sendEmailOpt(data: any, query: string) {
  return apiClient(`/api/Account/send-email-otp${query}`, data);
}

export function verifyCode(data: any, query: string) {
  return apiClient(`/api/Account/verify-code${query}`, data);
}

export function verifyCodeNewMobile(data: any, query: string) {
  return apiClient(`/api/Account/update-phone-verify-code${query}`, data);
}

export function emailVerifyCode(data: any, query: string) {
  return apiClient(`/api/Account/email-verify-code${query}`, data);
}

export function changePassword(data: any) {
  return apiClient("/api/Account/change-password", data);
}

export function socialLoginHandler(data: any) {
  //console.log(1111, data);
  return Axios({
    method: "post",
    url: "http://localhost:8000/social-login-handler",
    data: data || {},
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function verifyUserEmailOrMobile(data: any, query: string) {
  // return apiClient(`/api/Candidate/verify-candidate${query}`, data);
  return apiClient(`/api/Account/verify-user${query}`, data);
}

export function getTokenByUserEmailOrMobile(data: any, query: string) {
  return apiClient(`/api/Account/verify-candidate${query}`, data);
}

export function resetPasswordByOtp(data: any, query: string) {
  return apiClient(`/api/Account/reset-password-otp${query}`, data);
}

export function saveContactUs(data: any) {
  return apiClient("/api/ContactUs/coea", data);
}

export function registerSocialMe(data: any) {
  return apiClient("/api/account/social-register-me", data);
}

export function socialLogin(provider: any, data: any, query: string) {
  var path = "";
  if (provider.includes("facebook")) {
    path = `https://graph.facebook.com/${query}`;
  }
  return Axios({
    method: "post",
    url: path,
    data: data || {},
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function updateAccessToken(data: any, query: string) {
  return apiClient(`/api/Account/update-accesstoken${query}`, data);
}

export function verifyForgetOtpAndGenerateToken(data: any, query: string) {
  return apiClient(`/api/Account/verify-user-forgot-otp${query}`, data);
}
