import React, { useEffect } from "react";
import _ from "lodash";


interface ReactSelectOption {
  value: string,
  label: string
}

function Level(props: any) {

  const { ResumeTemplateSelectBoxData, setLevel } = props;

  let designations: ReactSelectOption[] = [];

  if (!_.isEmpty(ResumeTemplateSelectBoxData.designations)) {

    designations = ResumeTemplateSelectBoxData.designations.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }



  return (
    <React.Fragment>
      <div className="heading_sec1">
        <h1>Level</h1>
      </div>
      <div className="section_box4">
        {designations.filter(f => f.value != "0").map((e: any, i: any) =>
          <div className="col-xs-4">
            <input id={e.value} type="radio" name="radioLevel" defaultValue={e.value} onClick={() => { setLevel(e.value); }} /><label htmlFor={e.value}><span><span /></span> {e.label} </label>
          </div>

        )}
      </div>
    </React.Fragment>
  );

};

export default Level