import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  StoryRequestModel,
  Story,
  StoryViewModel,
  StorySaveRequestModel,
  StorySaveRespondModel,
  StoryEditRequestModel,
  StoryResultModel,
  StoryGetDataWithId,
  StoryDeleteRequestModel,
  StoryDeleteResultModel
} from "../../models/admin/Story";
import {
  IStoryManagementState,
  StoryDispatcherContext,
  StoryStateContext,
} from "../../context/admin/StoryContext";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IStoryAction =
  | {
      type: "Story_LIST";
      Story: StoryViewModel;
    }
  | {
      type: "GET_Story";
      StoryResultModel: StoryResultModel;
    }
  | {
      type: "EDIT_Story";
      StorySaveRespond: StorySaveRespondModel;
    }
  | {
      type: "DELETE_Story";
      StoryDeleteResultModel: StoryDeleteResultModel;
    };

export const StoryReducer: Reducer<
  IStoryManagementState,
  IStoryAction
> = (draft, action): IStoryManagementState => {
  switch (action.type) {
    case "Story_LIST":
      draft.Story = action.Story;
      return draft;
    case "GET_Story":
      draft.StoryResultModel = action.StoryResultModel;
      return draft;
    case "EDIT_Story":
      draft.StorySaveRespond = action.StorySaveRespond;
      return draft;
    case "DELETE_Story":
      draft.StoryDeleteResultModel = action.StoryDeleteResultModel;
      return draft;
  }
};

export const useStoryDispatcher = (): React.Dispatch<IStoryAction> => {
  const StoryDispatcher = React.useContext(StoryDispatcherContext);
  if (!StoryDispatcher) {
    throw new Error(
      "You have to provide the Story dispatcher using theStoryDispatcherContext.Provider in a parent component."
    );
  }
  return StoryDispatcher;
};

export const useStoryContext = (): IStoryManagementState => {
  const StoryContext = React.useContext(StoryStateContext);
  if (!StoryContext)
    throw new Error(
      "You have to provide the Story context using the StoryStateContext.Provider in a parent component."
    );
  return StoryContext;
};

export const getStoryList = async (
  dispatcher: React.Dispatch<IStoryAction>,
  query: StoryRequestModel,
  //token: string
) => {
  try {
    // if (token?.startsWith(`"`)) {
    //   token = token.substring(1);
    //   token = token.slice(0, -1);
    // }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      //Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetStoryList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "Story_LIST", Story: res.data });
    });
  } catch (e) {}
};

export const deleteStory = async (
  dispatcher: React.Dispatch<IStoryAction>,
  query: StoryDeleteRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteStory;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "DELETE_Story", StoryDeleteResultModel: res.data });
    });
  } catch (e) {}
};

export const editStory = async (
  dispatcher: React.Dispatch<IStoryAction>,
  query: StoryEditRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditStory;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_Story",
        StorySaveRespond: res.data,
      });

      res.status == 200
        ? message.success("Career tip is updated.")
        : message.error("There are some error happend on updating career tips.");
    });
  } catch (e) {}
};

export const getStory = async (
  dispatcher: React.Dispatch<IStoryAction>,
  query: StoryGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetStory;
    if (query.rowId != 0) {
      axios
        .post(url, JSON.stringify(query), { headers: header })
        .then((res) => {
          dispatcher({
            type: "GET_Story",
            StoryResultModel: res.data,
          });
        });
    } else {
      dispatcher({
        type: "GET_Story",
        StoryResultModel: {} as StoryResultModel,
      });
    }
  } catch (e) {}
};