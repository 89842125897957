import React, { useState, useEffect } from "react";
import {
  getLoggedUserId,
  getMyProfileSelectBoxList,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import CandidateProfileComponent from "./CandidateProfileComponent";
import {
  getCandidateExperienceSelectBoxData,
  //getCandidateId,
  getCandidateRelativesSelectBoxData,
} from "./../../../apis/candidate";
import AuthService from "./../../../services/AuthService";
import {
  useCandidateContext,
  useCandidateExperienceContext,
  useCandidateRelativesContext,
  useCandidateSkillsContext,
  useTrainingContext,
  useBankDetailsContext,
  useEducationQualificationContext,
  useCandidateAchievementsContext,
  useCandidateLanguageMapsContext,
  useCandidateReferencesContext,
  useSocialAccountContext,
  usePassportInformationContext,
  useCandidateProfileImageContext,
  useCandidateOtherCertificateContext,
  useSeamanBookCdcContext,
  useDigiDocumentDetailsContext,
  useDigiDocumentTypeContext,
} from "./../../../context/candidate/CandidateMyProfile";
import {
  getProfileProgress,
  //, getSocialAccounts
} from "./../../../apis/candidate";
import {
  getDigiLockerList,
  useDigiLockerContext,
  useDigiLockerDispatcher,
} from "./../../../action/candidate/DigiLockerAction";
import { digiLockerDocumentRequestModel } from "./../../../models/candidate/DigiLocker";

interface ICandidateProfileContainerProps {}

// interface ICandidateProfileContainerState { }

// const initialState = {};

const CandidateProfileContainer: React.FC<ICandidateProfileContainerProps> = (
  props
) => {
  // Api For SelectBox Data
  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const myProfileDispatcher = useMyProfileDispatcher();
  const myProfileContext = useMyProfileContext();
  const {
    myProfile,
    //, basicInfo
    loggedUserId,
  } = myProfileContext;

  const candidateId = loggedUserId;

  ////console.log("userId", userId, "candidateId", candidateId);

  const [
    candidateExperienceSelectBoxData,
    setCandidateExperienceSelectBoxData,
  ] = useState({});

  const [
    candidateRelativeSelectBoxData,
    setCandidateRelativeSelectBoxData,
  ] = useState({});

  const candidateExperienceContext = useCandidateExperienceContext();
  const candidateContext = useCandidateContext();
  const candidateRelativesContext = useCandidateRelativesContext();
  const candidateSkillsContext = useCandidateSkillsContext();
  const trainingContext = useTrainingContext();
  const bankDetailsContext = useBankDetailsContext();
  const educationQualificationContext = useEducationQualificationContext();
  const candidateAchievementsContext = useCandidateAchievementsContext();
  const candidateLanguageMapsContext = useCandidateLanguageMapsContext();
  const candidateReferencesContext = useCandidateReferencesContext();
  const socialAccountContext = useSocialAccountContext();
  const passportInformationContext = usePassportInformationContext();
  const candidateProfileImageContext = useCandidateProfileImageContext();
  const candidateOtherCertificateContext = useCandidateOtherCertificateContext();
  const seamanBookCdcContext = useSeamanBookCdcContext();
  const digiDocumentDetailsContext = useDigiDocumentDetailsContext();
  // const digiDocumentTypeContext = useDigiDocumentTypeContext();
  const digiLockerMarkContext = useDigiLockerContext();
  const digiLockerMarkDispatcher = useDigiLockerDispatcher();

  const {
    getCandidateExperiences,
    candidateExperienceState,
  } = candidateExperienceContext;
  const { getCandidates, candidateState } = candidateContext;
  const {
    getCandidateRelatives,
    candidateRelativesState,
  } = candidateRelativesContext;
  const { getCandidateSkills, candidateSkillsState } = candidateSkillsContext;
  const { getTrainings, trainingState } = trainingContext;
  const { getBankDetails, bankDetailsState } = bankDetailsContext;
  const {
    getEducationQualifications,
    educationQualificationState,
  } = educationQualificationContext;
  const {
    getCandidateAchievements,
    candidateAchievementsState,
  } = candidateAchievementsContext;
  const {
    getCandidateLanguageMaps,
    candidateLanguageMaps,
  } = candidateLanguageMapsContext;
  const {
    getCandidateReferences,
    candidateReferences,
  } = candidateReferencesContext;

  const { socialAccounts, getSocialAccounts } = socialAccountContext;
  const {
    passportInformation,
    getPassportInformation,
  } = passportInformationContext;
  const {
    candidateProfileImage,
    getCandidateProfileImage,
  } = candidateProfileImageContext;
  const {
    candidateOtherCertificate,
    getCandidateOtherCertificates,
  } = candidateOtherCertificateContext;
  const { seamanBookCdc, getSeamanBookCdc } = seamanBookCdcContext;
  const {
    digiDocumentDetails,
    getDigiDocumentDetails,
  } = digiDocumentDetailsContext;
  // const { digiDocumentType, getDigiDocumentType } = digiDocumentTypeContext;
  const { digiLockerType, digiLockers } = digiLockerMarkContext;

  // //console.log(digiLockerType, digiLockers);

  const [progressBar, setProgressBar] = useState({});

  // const [socialAccounts, setSocialAccounts] = useState([]);
  const [progressCount, setProgressCount] = useState(0);

  useEffect(() => {
    if (candidateId) {
      getCandidateExperiences({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      });

      getCandidates({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      });

      getCandidateRelatives({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getCandidateSkills({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getTrainings({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getBankDetails({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getEducationQualifications({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getCandidateAchievements({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getCandidateLanguageMaps({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getCandidateReferences({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getSocialAccounts({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      if (candidateProfileImage.data.length <= 0) {
        getCandidateProfileImage({
          CandidateId: Number(candidateId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "string",
          SortOrder: "string",
          ShowInactive: false,
        });
      }

      getCandidateOtherCertificates({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getDigiDocumentDetails({
        CandidateId: Number(candidateId),
        PageIndex: 1,
        PageSize: 10,
        ShowInactive: false,
      });

      getProfileProgress({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          // ///console.log(res.data);
          setProgressBar(res.data);
          setProgressCount(1);
        })
        .catch((err) => {
          ////console.log(err)
        });
    }
  }, [candidateId]);

  React.useEffect(() => {
    (async () => {
      await getMyProfileSelectBoxList(myProfileDispatcher);
      if (loggedUserId != 0) {
        await getDigiLockerList(
          digiLockerMarkDispatcher,
          {
            candidateId: loggedUserId,
            digiDocumentTypeId: 0,
            pageIndex: 1,
            pageSize: 1000,
            showInactive: false,
          } as digiLockerDocumentRequestModel,
          AuthService.accessToken || ""
        );
      }
      getCandidateExperienceSelectBoxData()
        .then((res) => {
          //console.log(res.data);
          setCandidateExperienceSelectBoxData(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });

      getPassportInformation({
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
      });

      getSeamanBookCdc({
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
      });

      getCandidateRelativesSelectBoxData()
        .then((res) => {
          // //console.log(res.data);
          setCandidateRelativeSelectBoxData(res.data);
        })
        .catch((err) => {
          ////console.log(err);
        });
    })();
  }, [loggedUserId]);

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getLoggedUserId(
          myProfileDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  useEffect(() => {
    if (candidateId > 0 && progressCount > 0) {
      // //console.log("test", progressCount, candidateId, candidateState.data);
      getProfileProgress({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          setProgressBar(res.data);
        })
        .catch((err) => {});
    }
  }, [
    progressCount,
    candidateId,
    candidateState,
    candidateSkillsState,
    trainingState,
    bankDetailsState,
    educationQualificationState,
    candidateAchievementsState,
    candidateReferences,
    candidateRelativesState,
    socialAccounts,
    passportInformation,
    candidateProfileImage,
    candidateOtherCertificate,
    seamanBookCdc,
    digiDocumentDetails,
  ]);
  return (
    <>
      <CandidateProfileComponent
        myProfile={myProfile}
        candidateExperienceSelectBoxData={candidateExperienceSelectBoxData}
        candidateRelativeSelectBoxData={candidateRelativeSelectBoxData}
        candidateExperiences={candidateExperienceState.data}
        getCandidateExperiences={getCandidateExperiences}
        userId={userId}
        candidateId={candidateId}
        getCandidates={getCandidates}
        candidateState={candidateState}
        getCandidateRelatives={getCandidateRelatives}
        candidateRelativesState={candidateRelativesState}
        candidateLanguageMaps={candidateLanguageMaps.data}
        getCandidateLanguageMaps={getCandidateLanguageMaps}
        getCandidateSkills={getCandidateSkills}
        candidateSkillsState={candidateSkillsState}
        getTrainings={getTrainings}
        trainingState={trainingState}
        getBankDetails={getBankDetails}
        bankDetailsState={bankDetailsState}
        educationQualificationState={educationQualificationState}
        getEducationQualifications={getEducationQualifications}
        getCandidateAchievements={getCandidateAchievements}
        candidateAchievementsState={candidateAchievementsState}
        candidateReferences={candidateReferences}
        getCandidateReferences={getCandidateReferences}
        progressBar={progressBar}
        socialAccounts={socialAccounts}
        getSocialAccounts={getSocialAccounts}
        passportInformation={passportInformation}
        getPassportInformation={getPassportInformation}
        getCandidateProfileImage={getCandidateProfileImage}
        candidateProfileImage={candidateProfileImage}
        candidateOtherCertificate={candidateOtherCertificate}
        getCandidateOtherCertificates={getCandidateOtherCertificates}
        seamanBookCdc={seamanBookCdc}
        getSeamanBookCdc={getSeamanBookCdc}
        digiDocumentDetails={digiDocumentDetails}
        getDigiDocumentDetails={getDigiDocumentDetails}
        digiLockerType={digiLockerType}
        digiLockers={digiLockers}
      />
    </>
  );
};
export default CandidateProfileContainer;
