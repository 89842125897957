import { Reducer } from "react";
import {
  IJobAppliedManagementState,
  JobAppliedDispatcherContext,
  JobAppliedStateContext,
} from "../context/JobAppliedContext";
import React from "react";
import {
  jobAppliedCandidateResulViewtModel,
  jobAppliedRequestModel,
  jobAppliedSaveRequestModel,
  jobAppliedSaveResultModel,
  jobAppliedViewModel,
} from "../models/candidate/JobApplied";
import axios from "axios";
import AuthService from "../services/AuthService";
import { AppUrls } from "../environments/environment";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IJobAppliedAction =
  | {
      type: "SET_Job Applied_LIST";
      jobApplied: jobAppliedViewModel;
    }
  | {
      type: "SET_Job Applied_SAVE";
      jobAppliedSaveResultData: jobAppliedSaveResultModel;
    }
  | {
      type: "GET_JOB_APPLIED_CANDIDATE_SAVE";
      jobAppliedCandidateResult: jobAppliedCandidateResulViewtModel;
    };

export const jobAppliedReducer: Reducer<
  IJobAppliedManagementState,
  IJobAppliedAction
> = (draft, action): IJobAppliedManagementState => {
  switch (action.type) {
    case "SET_Job Applied_LIST":
      draft.jobApplied = action.jobApplied;
      return draft;
    case "SET_Job Applied_SAVE":
      draft.jobAppliedSaveResultData = action.jobAppliedSaveResultData;
      return draft;
    case "GET_JOB_APPLIED_CANDIDATE_SAVE":
      draft.jobAppliedCandidateResult = action.jobAppliedCandidateResult;
      return draft;
  }
};

export const useJobAppliedDispatcher = (): React.Dispatch<IJobAppliedAction> => {
  const jobAppliedDispatcher = React.useContext(JobAppliedDispatcherContext);
  if (!jobAppliedDispatcher) {
    throw new Error(
      "You have to provide the JobApplied dispatcher using theJobAppliedDispatcherContext.Provider in a parent component."
    );
  }
  return jobAppliedDispatcher;
};

export const useJobAppliedContext = (): IJobAppliedManagementState => {
  const jobAppliedContext = React.useContext(JobAppliedStateContext);
  if (!jobAppliedContext)
    throw new Error(
      "You have to provide the jobApplied context using the JobAppliedStateContext.Provider in a parent component."
    );
  return jobAppliedContext;
};

export const getJobAppliedList = async (
  dispatcher: React.Dispatch<IJobAppliedAction>,
  query: jobAppliedRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllJobApplied;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "SET_Job Applied_LIST", jobApplied: res.data });
    });
  } catch (e) {}
};

export const getJobAppliedCandidateList = async (
  dispatcher: React.Dispatch<IJobAppliedAction>,
  query: jobAppliedSaveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllCandidateJobAppliedList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_JOB_APPLIED_CANDIDATE_SAVE",
        jobAppliedCandidateResult: res.data,
      });
    });
  } catch (e) {}
};

export const saveJobApplied = async (
  dispatcher: React.Dispatch<IJobAppliedAction>,
  query: any,
  token: string
) => {
  try {
    //console.log(777777, authorizationToken);
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveJobApplied;
    message.loading("please wait..");
    axios.post(url, query, { headers: header }).then((res) => {
      //console.log(8989, res);
      dispatcher({
        type: "SET_Job Applied_SAVE",
        jobAppliedSaveResultData: res.data,
      });

      res.status == 200
        ? res.data.isSuccess
          ? message.success("Your application has been submitted.")
          : message.error(res.data.message)
        : message.error(" some error happend Applied a job ");
    });
  } catch (e) {}
};
