import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  BulkSMSAllListModel,
  BulkSMSDetailListModel,
  BulkSMSRespondModel,
  BulkSMSDeleteResultModel,

  BulkEmailAllListModel,
  BulkEmailDetailListModel,
  BulkEmailRespondModel,
  BulkEmailDeleteResultModel,
  ContactUsAllListModel,
  ContactUsDeleteResultModel,
  MessagesUserAllListModel,
  SmsTemplateListModel,
  EmailTemplateListModel
} from "../../models/admin/AdminCommunication";
import {
  adminCommunicationReducer,
  IAdminCommunicationAction,
} from "../../action/admin/AdminCommunicationAction";


export const AdminCommunicationDispatcherContext = React.createContext<React.Dispatch<IAdminCommunicationAction> | null>(
  null
);
export const AdminCommunicationStateContext = React.createContext<IAdminCommunicationManagementState | null>(
  null
);

export interface IAdminCommunicationManagementProps extends RouteComponentProps<any> { }


export interface IAdminCommunicationManagementState {
  bulkSMSAllList: BulkSMSAllListModel;
  bulkSMSDetailList: BulkSMSDetailListModel;
  bulkSMSRespond: BulkSMSRespondModel;
  bulkSMSDeleteResult: BulkSMSDeleteResultModel;
  bulkEmailAllList: BulkEmailAllListModel;
  bulkEmailDetailList: BulkEmailDetailListModel;
  bulkEmailRespond: BulkEmailRespondModel;
  bulkEmailDeleteResult: BulkEmailDeleteResultModel
  contactUsAllList: ContactUsAllListModel,
  contactUsDeleteResult: ContactUsDeleteResultModel,
  messagesUserAllList: MessagesUserAllListModel,
  smsTemplateList: SmsTemplateListModel,
  emailTemplateList: EmailTemplateListModel,
  saveEmailRespond: BulkEmailRespondModel
}


export const initialAdminCommunicationManagementState = {
  bulkSMSAllList: {} as BulkSMSAllListModel,
  bulkSMSDetailList: {} as BulkSMSDetailListModel,
  bulkSMSRespond: {} as BulkSMSRespondModel,
  bulkSMSDeleteResult: {} as BulkSMSDeleteResultModel,
  bulkEmailAllList: {} as BulkEmailAllListModel,
  bulkEmailDetailList: {} as BulkEmailDetailListModel,
  bulkEmailRespond: {} as BulkEmailRespondModel,
  bulkEmailDeleteResult: {} as BulkEmailDeleteResultModel,
  contactUsAllList: {} as ContactUsAllListModel,
  contactUsDeleteResult: {} as ContactUsDeleteResultModel,
  messagesUserAllList: {} as MessagesUserAllListModel,
  smsTemplateList: {} as SmsTemplateListModel,
  emailTemplateList: {} as EmailTemplateListModel,
  saveEmailRespond: {} as BulkEmailRespondModel
} as IAdminCommunicationManagementState;

export const AdminCommunicationContextProvider: React.FC = ({ children }) => {
  const [adminCommunicationState, dispatcher] = useImmerReducer(
    adminCommunicationReducer,
    initialAdminCommunicationManagementState
  );

  return (
    <AdminCommunicationDispatcherContext.Provider value={dispatcher}>
      <AdminCommunicationStateContext.Provider value={adminCommunicationState}>
        {children}
      </AdminCommunicationStateContext.Provider>
    </AdminCommunicationDispatcherContext.Provider>
  );
};