import {
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Popover,
  Radio,
  Tooltip,
} from "antd";

import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  getLoggedUserId,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import {
  getVendorLoggedUserId,
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../../action/MyProfileVendorAction";
import {
  setVendorJobAppliedSave,
  useVendorJobAppliedContext,
  useVendorJobAppliedDispatcher,
} from "../../../action/vendor/VendorJobAppliedAction";
import {
  getVendorJobBookMarkList,
  setVendorJobBookmark,
  useVendorJobBookMarkContext,
  useVendorJobBookMarkDispatcher,
} from "../../../action/vendor/VendorJobBookMarkAction";
import {
  DeleteVendorJobStatusList,
  getVendorJobStatusList,
  useVendorJobStatusContext,
  useVendorJobStatusDispatcher,
} from "../../../action/vendor/VendorJobStatusAction";
import {
  VendorCandidateList,
  VendorGetAllJobRequestModel,
  VendorJobAppliedRequestModel,
} from "../../../models/vendor/VendorJobApplied";
import { VendorJobBookMarkRequestModel } from "../../../models/vendor/VendorJobBookMark";
import { JobStatusGetAllRequestModel } from "../../../models/vendor/VendorJobStatus";
import AuthService from "../../../services/AuthService";
import { useGlobalSpinnerContext } from "../../../context/GlobalSpinner";
import CopyToClipboard from "react-copy-to-clipboard";
import InfiniteScroll from "react-infinite-scroll-component";
import { getDocumentStatus } from "../../../apis/vendor";

interface ReactSelectOption {
  value: string;
  label: string;
}
interface IVendorSavedJobComponentProps {}
interface IVendorSavedJobComponentState {
  jobCategorys: string;
  jobTypeData: string;
  location: string;
  // tempData: jobAlertTitleDropdownResult[];
  countNext: number;
  carouselValue: number;
  countNextStatus: number;
  carouselValueStatus: number;
  firstName: string;
  middleName: string;
  lastName: string;
  passportNo: string;
  dob: number;
  activeVisa: string;
  visaIssueCountry: string;
  expiryDate: number;
  currentJobId: number;
  filterFlag: boolean;
}
const defaultValues = {
  jobTypeData: "",
  jobCategorys: "",
  tempData: [],
  location: " ",
  countNext: 0,
  carouselValue: 0,
  countNextStatus: 0,
  carouselValueStatus: 0,
  firstName: "",
  middleName: "",
  lastName: "",
  passportNo: "",
  dob: 0,
  activeVisa: "",
  visaIssueCountry: "",
  expiryDate: 0,
  currentJobId: 0,
  filterFlag: true,
};
const VendorSavedJobComponent: React.FC<IVendorSavedJobComponentProps> = (
  props
) => {
  // const authorizationToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8';
  // let loggedUserId=1;

  const [
    vendorSavedJobComponentState,
    setVendorSavedJobComponentState,
  ] = React.useState<IVendorSavedJobComponentState>(defaultValues);

  const {
    carouselValue,
    countNext,
    currentJobId,
    carouselValueStatus,
    countNextStatus,
  } = vendorSavedJobComponentState;

  const vendorJobBookMarkDispatcher = useVendorJobBookMarkDispatcher();
  const vendorJobBookMarkContext = useVendorJobBookMarkContext();
  const {
    bookmarkSaveResult,
    vendorGetAllJobBookMark,
  } = vendorJobBookMarkContext;

  const jobAppliedVendorDispatcher = useVendorJobAppliedDispatcher();
  const jobAppliedVendorContext = useVendorJobAppliedContext();
  const {
    vendorJobAppliedSaveRespond,
    vendorGetAllJobList,
  } = jobAppliedVendorContext;

  const myVendorProfileDispatcher = useVendorMyProfileDispatcher();
  const myVendorProfileContext = useVendorMyProfileContext();
  const {
    basicInfo,
    // loggedUserId,
    myProfile,
    myProfileProgressBar,
    profileImage,
  } = myVendorProfileContext;

  const myProfileVContext = useMyProfileContext();
  const { loggedUserId } = myProfileVContext;

  //console.log(4435343, loggedUserId);
  let history = useHistory();

  const authorizationToken = AuthService.accessToken;

  const globalSpinner = useGlobalSpinnerContext();

  const secondButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [jobApplayOpen, setJobApplayOpen] = useState<boolean>(false);
  const [filterFlag, setFilterFlag] = useState<boolean>(true);
  const [radioValue2, setRadioValue2] = useState<number>(1000);
  const [radioIndexList, setRadioIndexList] = useState<any[]>([]);
  const [radioValue, setRadioValue] = useState<number>(1);
  const [currentJobIdValue, setCurrentJobIdValue] = useState<number>(0);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [jobStatusOPen, setJobStatusOPen] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<number>(0);
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isRemovedAll, setIsRemovedCheckedAll] = useState(false);
  const [isRenderCheckedList, setIsRenderCheckedList] = useState(true);
  const [indexCount, setIndexCount] = useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [documentStatus, setDocumentStatus] = useState("");

  const vendorJobStatusDispatcher = useVendorJobStatusDispatcher();
  const vendorJobStatusContext = useVendorJobStatusContext();
  const {
    vendorGetAllJobStatus,
    vendorJobStatusDeleteResult,
  } = vendorJobStatusContext;

  const [vendorJobStatus, setVendorJobStatus] = useState(
    vendorGetAllJobStatus != undefined &&
      vendorGetAllJobStatus.data != undefined
      ? vendorGetAllJobStatus.data
      : []
  );

  let user = AuthService.currentUser;
  // React.useEffect(() => {
  //   if (authorizationToken != null && user?.id != null)
  //     (async () => {
  //       await getVendorLoggedUserId(
  //         myVendorProfileDispatcher,
  //         parseInt(user.id),
  //         authorizationToken
  //       );
  //     })();
  // }, [authorizationToken]);

  React.useEffect(() => {
    // if (authorizationToken != null) {
    setJobApplayOpen(false);
    setJobStatusOPen(false);
    // }
  }, [vendorJobAppliedSaveRespond]);

  React.useEffect(() => {
    if (
      authorizationToken != null &&
      jobStatusOPen == true &&
      loggedUserId > 0
    ) {
      globalSpinner.showSpinner();
      setVendorJobStatus([]);
      (async () => {
        await getVendorJobStatusList(
          vendorJobStatusDispatcher,
          {
            JobId: currentJobId,
            jobStatusId: statusFilter,
            Page: 1,
            PageSize: 100,
            SearchTerm: "",
            VendorId: loggedUserId,
            ShowInactive: false,
            SortOrder: "",
          } as JobStatusGetAllRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
        setFilterShow(false);
      })();
    }
  }, [loggedUserId, statusFilter, jobStatusOPen, vendorJobStatusDeleteResult]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId > 0) {
      (async () => {
        await getVendorJobBookMarkList(
          vendorJobBookMarkDispatcher,
          {
            vendorId: loggedUserId,
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as VendorGetAllJobRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [
    bookmarkSaveResult,
    vendorJobStatusDeleteResult,
    vendorJobAppliedSaveRespond,
  ]);

  const handleSavedJobClick = (id: any) => {
    // setVendorDashboardComponentState({
    //   ...vendorDashboardComponentState,
    //   currentJobId: id,
    // });
    if (authorizationToken != null && loggedUserId > 0) {
      (async () => {
        await setVendorJobBookmark(
          vendorJobBookMarkDispatcher,
          {
            IsActive: true,
            jobBookmarkedId: 0,
            rowId: 0,
            vendorID: loggedUserId,
            jobId: id,
          } as VendorJobBookMarkRequestModel,
          authorizationToken
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImQ5ZjU0MjlmLTY3NTgtNGM0Ny04ODVhLTIwZjVkN2M4ODEyZiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTcyODEsImV4cCI6MTY0NDEzMzI4MSwiaWF0IjoxNjEyNTk3MjgxLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.sKevXWje7UM90S8rAZicVHpesgOz4IepSaY2JQkvcNM"
        );
      })();
    }
  };

  const handleJobAppliedClick = (id: any) => {
    if (secondButtonRef.current != null) {
      secondButtonRef.current.click();
    }
    setJobApplayOpen(!jobApplayOpen);
    setCurrentJobIdValue(id);
  };

  const onFinish = (values: any) => {
    //console.log("Received values of form:", values);

    let vendorCandidateListArray = [
      {
        rowId: 0,
        dob: values.dob != undefined ? values.dob._d : null,
        expDate: values.expiryDate != undefined ? values.expiryDate._d : null,
        firstName: values.firstName,
        isActive: true,
        isActiveVisa:
          values.visaIssueCountry === "" ||
          values.expiryDate == null ||
          values.visaIssueCountry == undefined ||
          values.expiryDate == undefined
            ? false
            : true,
        lastName: values.lastName,
        middleName: values.middleName,
        passPortNumber: values.passportNo,
        visaIssueCountry: values.visaIssueCountry,
      },
    ] as VendorCandidateList[];

    if (values.users != undefined && values.users.length > 0) {
      values.users.forEach((user: any) => {
        vendorCandidateListArray.push({
          rowId: 0,
          dob: user.dob != undefined ? user.dob._d : null,
          visaIssueCountry: user.visaIssueCountry,
          passPortNumber: user.passportNo,
          middleName: user.middleName,
          lastName: user.lastName,
          isActiveVisa:
            user.visaIssueCountry === "" ||
            user.expiryDate == null ||
            user.visaIssueCountry == undefined ||
            user.expiryDate == undefined
              ? false
              : true,
          isActive: true,
          firstName: user.firstName,
          expDate: user.expiryDate != undefined ? user.expiryDate._d : null,
        });
      });
    }
    if (authorizationToken != null) {
      (async () => {
        await setVendorJobAppliedSave(
          jobAppliedVendorDispatcher,
          {
            jobId: currentJobId,
            rowId: 0,
            vendorCandidateList: vendorCandidateListArray,
            vendorId: loggedUserId,
            IsActive: true,
          } as VendorJobAppliedRequestModel,
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
          authorizationToken
        );
      })();
    }
  };

  React.useEffect(() => {
    // if (authorizationToken != null) {
    setJobApplayOpen(false);
    // }
  }, [vendorJobAppliedSaveRespond]);

  React.useEffect(() => {
    if (
      vendorGetAllJobStatus != undefined &&
      vendorGetAllJobStatus.data != undefined
    ) {
      setIndexCount(vendorGetAllJobStatus.data.length);
      setVendorJobStatus(
        vendorGetAllJobStatus != undefined &&
          vendorGetAllJobStatus.data != undefined
          ? vendorGetAllJobStatus.data
          : []
      );
      globalSpinner.hideSpinner();
    }
  }, [vendorGetAllJobStatus]);

  function cancel(e: any) {}

  const handleRemoveIndex = (indexValue: any) => {
    const item = radioIndexList.filter((value, index) => {
      return value != indexValue;
    });
    setRadioIndexList(item);
  };

  const handleOnRadioButtonChange = (e: any) => {
    if (e.target.value.charAt(0) == "f") {
      const item = radioIndexList.filter((value, index) => {
        return value != parseInt(e.target.value.substring(1));
      });
      setRadioIndexList(item);
    } else {
      setRadioIndexList((radioIndexList: any) => [
        ...radioIndexList,
        parseInt(e.target.value.substring(1)),
      ]);
    }
  };

  const handleShareButtonClick = (id: any, value: any) => {
    setCurrentJobIdValue(id);
    setFilterFlag(value);
    setIsShareOpen(!isShareOpen);
  };

  const handleJobStatusClick = (jobId: any) => {
    setIsCheckedAll(false);
    setIsRemovedCheckedAll(false);
    setCheckedListArray([]);
    setIsChecked(false);
    setVendorJobStatus([]);
    setJobStatusOPen(!jobStatusOPen);
    setVendorSavedJobComponentState((vendorSavedJobComponentState) => {
      return {
        ...vendorSavedJobComponentState,
        currentJobId: jobId,
      };
    });
  };
  const onFinishStatus = (values: any) => {
    //console.log("Received values of form:", values);
    //console.log("Received vendorJobStatus", vendorJobStatus);
    let vendorCandidateListArray = [] as VendorCandidateList[];

    //     if(vendorJobStatus.length>0){
    // vendorJobStatus.forEach((data:any) => {
    //         vendorCandidateListArray.push({rowId:data.rowId,dob:data.dob,visaIssueCountry:data.visaIssueCountry,
    //           passPortNumber:data.passportNo,middleName:data.middleName,lastName:data.firstName,
    //           isActiveVisa:data.firstName,isActive:data.isActive,firstName:data.firstName,expDate:data.expDate
    //         })
    //     }
    // )}

    if (values.users != undefined && values.users.length > 0) {
      //console.log("hi");
      values.users.forEach((user: any) => {
        vendorCandidateListArray.push({
          rowId: 0,
          dob: user.dob != undefined ? user.dob._d : null,
          visaIssueCountry: user.visaIssueCountry,
          passPortNumber: user.passportNo,
          middleName: user.middleName,
          lastName: user.lastName,
          isActiveVisa:
            user.visaIssueCountry === "" ||
            user.expiryDate == null ||
            user.visaIssueCountry == undefined ||
            user.expiryDate == undefined
              ? false
              : true,
          isActive: true,
          firstName: user.firstName,
          expDate: user.expiryDate != undefined ? user.expiryDate._d : null,
        });
      });
    }
    if (authorizationToken != null) {
      (async () => {
        await setVendorJobAppliedSave(
          jobAppliedVendorDispatcher,
          {
            jobId: currentJobId,
            rowId: 0,
            vendorCandidateList: vendorCandidateListArray,
            vendorId: loggedUserId,
            IsActive: true,
          } as VendorJobAppliedRequestModel,
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
          authorizationToken
        );
      })();
    }
  };

  React.useEffect(() => {
    if (isChecked) {
      if (checkedListArray.length == vendorGetAllJobStatus.data.length) {
        setIsCheckedAll(true);
        //console.log("checkedListArray lenth", checkedListArray.length);
        //console.log("vendorJobStatus.length", vendorJobStatus.length);
      } else {
        setIsCheckedAll(false);
      }
      setIsChecked(false);
    }
  }, [isChecked]);

  const menuRef = useRef<any>();
  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setFilterShow(false);
          // setIsProfileOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  let IdArray = [] as any[];

  const handleAllChecked = (e: any) => {
    //console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArray = [];
      // digiLockerList
      vendorGetAllJobStatus.data
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArray.push(d.candidateId);
        });
      setCheckedListArray(IdArray);
      //console.log(23, checkedListArray);
      setIsCheckedAll(e.target.checked);
      //console.log("checkedListArray", checkedListArray);
      setIsRemovedCheckedAll(false);
    } else {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(e.target.checked);
      //console.log("checkedListArray", checkedListArray);
      setIsRemovedCheckedAll(true);
    }
  };

  React.useEffect(() => {
    if (
      vendorGetAllJobStatus != undefined &&
      vendorGetAllJobStatus.data != undefined &&
      isRenderCheckedList
    ) {
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(false);
      setIsRenderCheckedList(false);
      setVendorJobStatus(
        vendorGetAllJobStatus != undefined &&
          vendorGetAllJobStatus.data != undefined
          ? vendorGetAllJobStatus.data
          : []
      );
    }
  }, [isRenderCheckedList, vendorGetAllJobStatus]);

  const handleChecked = (e: any, id: any) => {
    // setIsChecked(true);
    if (e.target.checked) {
      IdArray.push(...checkedListArray, id);
      setCheckedListArray(IdArray);
      //console.log("checkedListArray", checkedListArray);
    } else {
      IdArray = checkedListArray.filter((data) => {
        return data !== id;
      });
      setCheckedListArray(IdArray);
      //console.log("checkedListArray", checkedListArray);
    }
    setIsRemovedCheckedAll(false);
    setIsChecked(true);
  };

  const handleJobStatusDelete = () => {
    if (authorizationToken != null) {
      (async () => {
        await DeleteVendorJobStatusList(
          vendorJobStatusDispatcher,
          {
            RowId: 0,
            JobId: currentJobId,
            VendorId: loggedUserId,
            VendorCandidateList: checkedListArray,
          },
          authorizationToken
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImQ5ZjU0MjlmLTY3NTgtNGM0Ny04ODVhLTIwZjVkN2M4ODEyZiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTcyODEsImV4cCI6MTY0NDEzMzI4MSwiaWF0IjoxNjEyNTk3MjgxLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.sKevXWje7UM90S8rAZicVHpesgOz4IepSaY2JQkvcNM"
        );
      })();
    }
  };

  const handleAllRemoved = (e: any) => {
    if (e.target.checked) {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(true);
      //console.log("checkedListArray", checkedListArray);
    }
  };

  function onChangeDate(date: any, dateString: any) {
    //console.log(date, dateString);
  }
  function disabledDate(current: any) {
    // Can not select days before today and today

    return current && current < moment().endOf("day");
  }

  React.useEffect(() => {
    // if (authorizationToken != null) {
    setVendorJobStatus([]);
    // }
  }, [statusFilter]);

  const content = (
    <>
      <p>You can only delete candidates in Applied and rejected tabs</p>
    </>
  );

  const fetchMoreData = async () => {};

  React.useEffect(() => {
    if (loggedUserId) {
      getDocumentStatus({
        VendorId: Number(loggedUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log("uploaded-document-status success", res.data);
          setDocumentStatus(res.data);
        })
        .catch((err) => {
          console.log("uploaded-document-status error", err);
        });
    }
  }, [loggedUserId]);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Saved jobs</h1>
          <div className="clearfix" />
          <div className="row ">
            <div className="col-sm-12">
              <div className="section_box3">
                <div className="row">
                  <InfiniteScroll
                    className="_custom-infinite-scroll-class"
                    height={700}
                    dataLength={
                      vendorGetAllJobBookMark.data != undefined
                        ? vendorGetAllJobBookMark.data.length
                        : 0
                    }
                    // dataLength={2000}
                    // dataLength={totalCount}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<h4></h4>}
                    // initialScrollY={indexState * 80}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>No more data to load</b>
                      </p>
                    }
                  >
                    {vendorGetAllJobBookMark.data != undefined
                      ? vendorGetAllJobBookMark.data.map((data, index) => {
                          return (
                            <div className="col-sm-3">
                              <div
                                className="matched_jobs"
                                style={{
                                  backgroundColor: data.jobActive
                                    ? "#fff"
                                    : "gainsboro",
                                }}
                              >
                                <div className="matched_jobs_cat_t">
                                  {data.categoryName}
                                </div>
                                <div className="jobs_start1">
                                  {data.daysAgo} days ago
                                </div>
                                <div className="matched_star1">
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                    onClick={() =>
                                      handleSavedJobClick(data.jobId)
                                    }
                                  />
                                </div>
                                <div className="clearfix" />
                                <Popover
                                  content={
                                    data.jobActive
                                      ? "Please click for more details"
                                      : "This job is not activated"
                                  }
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      data.jobActive
                                        ? history.push(
                                            // `/job_search_vendor/saved/${data.jobId}`
                                            `/jobSearch/saved/${data.jobId}/searchtitle/location/0`
                                          )
                                        : history.push("/vendor/saved-jobs");
                                    }}
                                  >
                                    <div className="matched_heading">
                                      {data.title}
                                    </div>
                                    <div className="matched_jobs_cat">
                                      <i
                                        className="fa fa-map-marker"
                                        aria-hidden="true"
                                      />{" "}
                                      {data.location}
                                    </div>
                                    <div className="matched_jobs_cat text-right">
                                      <i
                                        className="fa fa-clock-o"
                                        aria-hidden="true"
                                      />{" "}
                                      {data.jobType}
                                    </div>
                                    <div className="clearfix" />
                                    <div className="matched_jobs_cat">
                                      <i
                                        className="fa fa-list-ul"
                                        aria-hidden="true"
                                      />{" "}
                                      {data.numberOfVacancies} Vacancies
                                    </div>
                                  </div>
                                </Popover>
                                <div className="clearfix" />
                                <div className="matched_jobs_share">
                                  <a
                                    onClick={() =>
                                      data.jobActive
                                        ? handleShareButtonClick(
                                            data.jobId,
                                            true
                                          )
                                        : null
                                    }
                                    data-target="#share"
                                    data-toggle="modal"
                                  >
                                    <i
                                      className="fa fa-share-alt"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </div>
                                <div className="jobs_apply">
                                  {/* <a onClick={()=>handleJobAppliedClick(data.jobId)}>Apply Now</a> */}

                                  {documentStatus !== undefined ? (
                                    documentStatus.includes("Rejected") ? (
                                      data.jobActive ? (
                                        data.isApplied ? (
                                          <Popover
                                            content={
                                              "Your Document is Rejected"
                                            }
                                            trigger="hover"
                                          >
                                            <a
                                              // onClick={() =>
                                              //   handleJobStatusClick(data.jobId)
                                              // }
                                              data-target="#status_pop"
                                              data-toggle="modal"
                                            >
                                              View Status
                                            </a>
                                          </Popover>
                                        ) : (
                                          <Popover
                                            content={
                                              "Your Document is Rejected"
                                            }
                                            trigger="hover"
                                          >
                                            <a
                                              // onClick={() =>
                                              //   handleJobAppliedClick(data.jobId)
                                              // }
                                              data-target="#apply_now_pop"
                                              data-toggle="modal"
                                            >
                                              Apply Now
                                            </a>
                                          </Popover>
                                        )
                                      ) : data.isApplied ? (
                                        <Popover
                                          content={"Your Document is Rejected"}
                                          trigger="hover"
                                        >
                                          <a
                                            data-target="#status_pop"
                                            data-toggle="modal"
                                          >
                                            View Status
                                          </a>
                                        </Popover>
                                      ) : (
                                        <Popover
                                          content={"Your Document is Rejected"}
                                          trigger="hover"
                                        >
                                          <a
                                            data-target="#apply_now_pop"
                                            data-toggle="modal"
                                          >
                                            Apply Now
                                          </a>
                                        </Popover>
                                      )
                                    ) : data.jobActive ? (
                                      data.isApplied ? (
                                        <a
                                          onClick={() =>
                                            handleJobStatusClick(data.jobId)
                                          }
                                          data-target="#status_pop"
                                          data-toggle="modal"
                                        >
                                          View Status
                                        </a>
                                      ) : (
                                        <a
                                          onClick={() =>
                                            handleJobAppliedClick(data.jobId)
                                          }
                                          data-target="#apply_now_pop"
                                          data-toggle="modal"
                                        >
                                          Apply Now
                                        </a>
                                      )
                                    ) : data.isApplied ? (
                                      <Popover
                                        content={"The job is not activated"}
                                        trigger="hover"
                                      >
                                        <a
                                          data-target="#status_pop"
                                          data-toggle="modal"
                                        >
                                          View Status
                                        </a>
                                      </Popover>
                                    ) : (
                                      <Popover
                                        content={"The job is not activated"}
                                        trigger="hover"
                                      >
                                        <a
                                          data-target="#apply_now_pop"
                                          data-toggle="modal"
                                        >
                                          Apply Now
                                        </a>
                                      </Popover>
                                    )
                                  ) : data.jobActive ? (
                                    data.isApplied ? (
                                      <a
                                        onClick={() =>
                                          handleJobStatusClick(data.jobId)
                                        }
                                        data-target="#status_pop"
                                        data-toggle="modal"
                                      >
                                        View Status
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() =>
                                          handleJobAppliedClick(data.jobId)
                                        }
                                        data-target="#apply_now_pop"
                                        data-toggle="modal"
                                      >
                                        Apply Now
                                      </a>
                                    )
                                  ) : data.isApplied ? (
                                    <Popover
                                      content={"The job is not activated"}
                                      trigger="hover"
                                    >
                                      <a
                                        data-target="#status_pop"
                                        data-toggle="modal"
                                      >
                                        View Status
                                      </a>
                                    </Popover>
                                  ) : (
                                    <Popover
                                      content={"The job is not activated"}
                                      trigger="hover"
                                    >
                                      <a
                                        data-target="#apply_now_pop"
                                        data-toggle="modal"
                                      >
                                        Apply Now
                                      </a>
                                    </Popover>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </InfiniteScroll>
                </div>
              </div>
            </div>

            <Modal
              show={jobApplayOpen}
              onHide={() => {
                setJobApplayOpen(!jobApplayOpen);
                setRadioIndexList([]);
              }}
            >
              <Form
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                autoComplete="off"
              >
                {/* <form onSubmit={handleSubmit3(handleJobSubmit)} noValidate > */}
                <Modal.Header closeButton>
                  <Modal.Title>Apply Now</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="candidate_no">C001</div>
                  <div
                    className="close_icon _cursor-pointer"
                    style={{ display: "none" }}
                  >
                    <img
                      src={require("./../../../images/close_icon.png")}
                      width={16}
                      height={16}
                      style={{ marginTop: 26 }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="email">Candidate First Name</label>
                    <Form.Item
                      name="firstName"
                      rules={[
                        { required: true, message: "First  Name Missing " },
                      ]}
                    >
                      <Input placeholder="" style={{ height: 39 }} />
                    </Form.Item>
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="email">Middle Name</label>
                    <Form.Item
                      name="middleName"
                      rules={[
                        { required: false, message: "Middle Name Missing " },
                      ]}
                    >
                      <Input placeholder="" style={{ height: 39 }} />
                    </Form.Item>
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="email">Last Name</label>
                    <Form.Item
                      name="lastName"
                      rules={[
                        { required: true, message: "Last Name Missing " },
                      ]}
                    >
                      <Input placeholder="" style={{ height: 39 }} />
                    </Form.Item>
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="email">Passport No</label>
                    <Form.Item
                      name="passportNo"
                      rules={[
                        { required: true, message: "Passport No Missing " },
                      ]}
                    >
                      <Input placeholder="" style={{ height: 39 }} />
                    </Form.Item>
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="email">DOB</label>
                    <Form.Item
                      name="dob"
                      rules={[{ required: true, message: "DOB Missing " }]}
                    >
                      {/* <Input placeholder=""  />               */}
                      <DatePicker
                        placeholder="YYYY-MM-DD"
                        style={{ height: 39, width: 196 }}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="email">Active Visa?</label>
                      <div className="row">
                        <Form.Item
                          name="activeVisa"
                          rules={[{ required: false, message: "DOB Missing " }]}
                        >
                          <Radio.Group
                            defaultValue={radioValue}
                            onChange={
                              radioValue == 1
                                ? () => {
                                    setRadioValue(2);
                                  }
                                : () => {
                                    setRadioValue(1);
                                  }
                            }
                          >
                            <div className="col-xs-6">
                              <Radio value={1}>Yes</Radio>
                            </div>
                            <div className="col-xs-6">
                              <Radio value={2}>No</Radio>
                            </div>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="clearfix" />

                  <div
                    className="col-sm-4"
                    style={{ display: radioValue != 1 ? "none" : "" }}
                  >
                    <label htmlFor="email">Visa Issuing Country</label>
                    <Form.Item
                      name="visaIssueCountry"
                      rules={[
                        {
                          required: radioValue != 1 ? false : true,
                          message: "Visa Issuing Country Missing ",
                        },
                      ]}
                    >
                      <Input placeholder="" style={{ height: 39 }} />
                    </Form.Item>
                  </div>

                  <div
                    className="col-sm-4"
                    style={{ display: radioValue != 1 ? "none" : "" }}
                  >
                    <label htmlFor="email">Expiry Date</label>
                    <Form.Item
                      name="expiryDate"
                      rules={[
                        {
                          required: radioValue != 1 ? false : true,
                          message: "expiryDate Missing ",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="YYYY-MM-DD"
                        disabledDate={disabledDate}
                        style={{ height: 39, width: 196 }}
                      />
                    </Form.Item>
                  </div>

                  <Form.List name="users">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <div style={{ paddingTop: 120 }}>
                            <div className="candidate_no">C00{index + 2}</div>
                            <div className="close_icon _cursor-pointer">
                              <img
                                onClick={() => {
                                  remove(field.name);
                                  handleRemoveIndex(index);
                                }}
                                src={require("./../../../images/close_icon.png")}
                                width={16}
                                height={16}
                                style={{ marginTop: 26 }}
                              />
                            </div>
                            <div className="col-sm-4">
                              <label htmlFor="email">
                                Candidate First Name
                              </label>
                              <Form.Item
                                {...field}
                                name={[field.name, "firstName"]}
                                fieldKey={[field.fieldKey, "firstName"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input placeholder="" style={{ height: 39 }} />
                              </Form.Item>
                            </div>

                            <div className="col-sm-4">
                              <label htmlFor="email">Middle Name</label>
                              <Form.Item
                                {...field}
                                name={[field.name, "middleName"]}
                                fieldKey={[field.fieldKey, "middleName"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "Middle Name Missing ",
                                  },
                                ]}
                              >
                                <Input placeholder="" style={{ height: 39 }} />
                              </Form.Item>
                            </div>

                            <div className="col-sm-4">
                              <label htmlFor="email">Last Name</label>
                              <Form.Item
                                {...field}
                                name={[field.name, "lastName"]}
                                fieldKey={[field.fieldKey, "lastName"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Last Name Missing ",
                                  },
                                ]}
                              >
                                <Input placeholder="" style={{ height: 39 }} />
                              </Form.Item>
                            </div>

                            <div className="col-sm-4">
                              <label htmlFor="email">Passport No</label>
                              <Form.Item
                                {...field}
                                name={[field.name, "passportNo"]}
                                fieldKey={[field.fieldKey, "passportNo"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Passport No Missing ",
                                  },
                                ]}
                              >
                                <Input placeholder="" style={{ height: 39 }} />
                              </Form.Item>
                            </div>

                            <div className="col-sm-4">
                              <label htmlFor="email">DOB</label>
                              <Form.Item
                                {...field}
                                name={[field.name, "dob"]}
                                fieldKey={[field.fieldKey, "dob"]}
                                rules={[
                                  { required: true, message: "DOB Missing " },
                                ]}
                              >
                                {/* <Input placeholder=""  />               */}
                                <DatePicker
                                  placeholder="YYYY-MM-DD"
                                  style={{ height: 39, width: 196 }}
                                />
                              </Form.Item>
                            </div>

                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Active Visa?</label>
                                <div className="row">
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "activeVisa"]}
                                    fieldKey={[field.fieldKey, "activeVisa"]}
                                    rules={[
                                      {
                                        required: false,
                                        message: "activeVisa Missing ",
                                      },
                                    ]}
                                  >
                                    <Radio.Group
                                      // onChange={radioValue2!=index?()=>{setRadioValue2(index)}:()=>{setRadioValue2(1000)}}
                                      onChange={handleOnRadioButtonChange}
                                      defaultValue={`f${index}`}
                                    >
                                      <div className="col-xs-6">
                                        <Radio
                                          defaultChecked
                                          value={`f${index}`}
                                        >
                                          Yes
                                        </Radio>
                                      </div>
                                      <div className="col-xs-6">
                                        <Radio value={`s${index}`}>No</Radio>
                                      </div>
                                    </Radio.Group>
                                  </Form.Item>
                                </div>
                              </div>
                            </div>

                            <div className="clearfix" />

                            <div
                              className="col-sm-4"
                              style={{
                                display: radioIndexList.includes(index)
                                  ? "none"
                                  : "",
                              }}
                            >
                              <label htmlFor="email">
                                Visa Issuing Country
                              </label>
                              <Form.Item
                                {...field}
                                name={[field.name, "visaIssueCountry"]}
                                fieldKey={[field.fieldKey, "visaIssueCountry"]}
                                rules={[
                                  {
                                    required: radioIndexList.includes(index)
                                      ? false
                                      : true,
                                    message: "Visa Issuing Country Missing ",
                                  },
                                ]}
                              >
                                <Input placeholder="" style={{ height: 39 }} />
                              </Form.Item>
                            </div>

                            <div
                              className="col-sm-4"
                              style={{
                                display: radioIndexList.includes(index)
                                  ? "none"
                                  : "",
                              }}
                            >
                              <label htmlFor="email">Expiry Date</label>
                              <Form.Item
                                {...field}
                                name={[field.name, "expiryDate"]}
                                fieldKey={[field.fieldKey, "expiryDate"]}
                                rules={[
                                  {
                                    required: radioIndexList.includes(index)
                                      ? false
                                      : true,
                                    message: "expiryDate Missing ",
                                  },
                                ]}
                              >
                                <DatePicker
                                  placeholder="YYYY-MM-DD"
                                  style={{ height: 39, width: 196 }}
                                  disabledDate={disabledDate}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        ))}
                        <div className="col-sm-4">
                          <Form.Item>
                            <div className="form-group m_t_30">
                              <label htmlFor="email"></label>
                              <button
                                ref={secondButtonRef}
                                className="btn btn-success save-event waves-effect waves-light"
                                type="button"
                                onClick={() => add()}
                              >
                                Add Candidates
                              </button>
                            </div>
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </Form.List>
                </Modal.Body>
                <div className="modal-footer  m-t-30">
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      setJobApplayOpen(false);
                    }}
                    data-dismiss="modal"
                    className="btn btn-default waves-effect"
                    type="submit"
                  >
                    Cancel
                  </button>
                </div>
                <div className="clearfix" />
                {/* </form> */}
              </Form>
            </Modal>
          </div>
          <div className="clearfix" />
        </div>

        {/* Modal job Status */}

        <Modal
          show={jobStatusOPen}
          onHide={() => {
            setJobStatusOPen(!jobStatusOPen);
          }}
          // style={{width:680}}
        >
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinishStatus}
            autoComplete="off"
          >
            {/* <form onSubmit={handleSubmit3(handleJobSubmit)} noValidate > */}
            <Modal.Header closeButton>
              <Modal.Title>My Candidate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* filteration */}

              <div className="action_btn1">
                <button
                  type="button"
                  className="actions_bt"
                  onClick={() => {
                    setFilterShow(!filterShow);
                  }}
                >
                  <i
                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                    aria-hidden="true"
                  />
                </button>
                <div className="col-sm-12">
                  <div
                    className="dash_action"
                    tabIndex={-1}
                    style={{ display: filterShow ? "block" : "none" }}
                  >
                    <div className="action_sec">
                      <ul ref={menuRef}>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(0);
                            }}
                            style={{ color: statusFilter !== 0 ? "" : "blue" }}
                          >
                            All
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(1);
                            }}
                            style={{ color: statusFilter !== 1 ? "" : "blue" }}
                          >
                            Applied
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(2);
                            }}
                            style={{ color: statusFilter !== 2 ? "" : "blue" }}
                          >
                            {" "}
                            Selected{" "}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(3);
                            }}
                            style={{ color: statusFilter !== 3 ? "" : "blue" }}
                          >
                            Rejected{" "}
                          </a>
                        </li>

                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(4);
                            }}
                            style={{ color: statusFilter !== 4 ? "" : "blue" }}
                          >
                            On Hold
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(5);
                            }}
                            style={{ color: statusFilter !== 5 ? "" : "blue" }}
                          >
                            Management
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(6);
                            }}
                            style={{ color: statusFilter !== 6 ? "" : "blue" }}
                          >
                            Technical
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(7);
                            }}
                            style={{ color: statusFilter !== 7 ? "" : "blue" }}
                          >
                            Interview: HR
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(8);
                            }}
                            style={{ color: statusFilter !== 8 ? "" : "blue" }}
                          >
                            Shortlist
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* filteration end*/}

              <div>
                <div className="candidate_top">
                  <div className="col-sm-4">
                    <input
                      style={{ cursor: "pointer" }}
                      id="checkbox1"
                      type="checkbox"
                      name="checkbox"
                      defaultValue={1}
                      checked={isCheckedAll}
                      // defaultChecked={isCheckedAll ? true : false}
                      onChange={(e: any) => handleAllChecked(e)}
                    />
                    <label htmlFor="checkbox1">
                      <span /> Select All
                    </label>
                  </div>
                  <div className="col-sm-4">
                    <input
                      style={{ cursor: "pointer" }}
                      id="checkbox1"
                      type="checkbox"
                      name="checkbox"
                      defaultValue={1}
                      checked={isRemovedAll}
                      onChange={(e: any) => handleAllRemoved(e)}
                    />
                    <label htmlFor="checkbox1">
                      <span /> De-select All
                    </label>
                  </div>
                  <div className="col-sm-4" style={{ paddingTop: 4 }}>
                    {statusFilter === 1 || statusFilter === 3 ? (
                      <Popconfirm
                        title={
                          <div>
                            <p>Are you sure delete this Candidate </p>
                          </div>
                        }
                        onConfirm={handleJobStatusDelete}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          //  onClick={handleJobStatusDelete}
                        >
                          <i className="fa fa-trash-o" aria-hidden="true" />{" "}
                          Delete
                        </div>
                      </Popconfirm>
                    ) : (
                      <Tooltip title={content}>
                        <div style={{ cursor: "pointer", color: "gray" }}>
                          <i className="fa fa-trash-o" aria-hidden="true" />{" "}
                          Delete
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="clearfix" />

                {vendorJobStatus != undefined
                  ? vendorJobStatus.map((data, index) => {
                      return (
                        <>
                          <div style={{ paddingTop: 22 }}>
                            <div className="candidate_no">
                              <div>
                                {" "}
                                <input
                                  id="checkbox1"
                                  type="checkbox"
                                  name="checkbox"
                                  onChange={(e: any) =>
                                    handleChecked(e, data.candidateId)
                                  }
                                  checked={checkedListArray.includes(
                                    data.candidateId
                                  )}
                                />
                                <label htmlFor="checkbox1">
                                  <span />
                                  C-00{index + 1}
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">
                                  Candidate first Name
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.firstName}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Middle Name</label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.middleName}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Last Name</label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.lastName}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Passport No</label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.passPort}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">DOB</label>
                                <DatePicker
                                  placeholder="YYYY-MM-DD"
                                  style={{ height: 39, width: 196 }}
                                  disabled
                                  onChange={onChangeDate}
                                  defaultValue={moment(data.dob, "DD/MM/YYYY")}
                                  format={"DD/MM/YYYY"}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Active Visa?</label>
                                <div className="row">
                                  <div className="col-xs-6">
                                    {/* <input disabled id="radio1" type="radio" name="radio" checked={data.isActivevisa} /><label htmlFor="radio1"><span><span /></span> Yes</label> */}
                                    <Radio
                                      checked={data.isActivevisa}
                                      value={1}
                                    >
                                      Yes
                                    </Radio>
                                  </div>
                                  <div className="col-xs-6">
                                    <Radio
                                      checked={!data.isActivevisa}
                                      value={2}
                                    >
                                      No
                                    </Radio>
                                    {/* <input disabled id="radio1" type="radio" name="radio" defaultValue={1} checked={data.isActivevisa?false:true}/><label htmlFor="radio1"><span><span /></span> No</label> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                            <div
                              className="col-sm-4"
                              style={{
                                display: data.isActivevisa ? "" : "none",
                              }}
                            >
                              <div className="form-group">
                                <label htmlFor="email">
                                  Visa Issuing Country{" "}
                                </label>
                                <input
                                  disabled
                                  defaultValue={data.visaIssueCountry}
                                  type="text"
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div
                              className="col-sm-4"
                              style={{
                                display: data.isActivevisa ? "" : "none",
                              }}
                            >
                              <div className="form-group">
                                <label htmlFor="email">Expiry date </label>
                                <DatePicker
                                  placeholder="YYYY-MM-DD"
                                  style={{ height: 39, width: 196 }}
                                  disabled
                                  onChange={onChangeDate}
                                  defaultValue={moment(
                                    data.expirary_Date,
                                    "DD/MM/YYYY"
                                  )}
                                  format={"DD/MM/YYYY"}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            style={{ paddingTop: data.isActivevisa ? 72 : 1 }}
                          ></div>
                        </>
                      );
                    })
                  : null}

                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div style={{ paddingTop: 110 }}>
                          <div className="candidate_no">
                            C-00{index + indexCount + 1}
                          </div>
                          <div className="close_icon _cursor-pointer">
                            <img
                              onClick={() => {
                                remove(field.name);
                                handleRemoveIndex(index);
                              }}
                              src={require("./../../../images/close_icon.png")}
                              width={16}
                              height={16}
                              style={{ marginTop: 26 }}
                            />
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="email">Candidate First Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "firstName"]}
                              fieldKey={[field.fieldKey, "firstName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing first name",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Middle Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "middleName"]}
                              fieldKey={[field.fieldKey, "middleName"]}
                              rules={[
                                {
                                  required: false,
                                  message: "Middle Name Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Last Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "lastName"]}
                              fieldKey={[field.fieldKey, "lastName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Last Name Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Passport No</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "passportNo"]}
                              fieldKey={[field.fieldKey, "passportNo"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Passport No Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">DOB</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "dob"]}
                              fieldKey={[field.fieldKey, "dob"]}
                              rules={[
                                { required: true, message: "DOB Missing " },
                              ]}
                            >
                              {/* <Input placeholder=""  />               */}
                              <DatePicker
                                placeholder="YYYY-MM-DD"
                                style={{ height: 39, width: 196 }}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="email">Active Visa?</label>
                              <div className="row">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "activeVisa"]}
                                  fieldKey={[field.fieldKey, "activeVisa"]}
                                  rules={[
                                    {
                                      required: false,
                                      message: "activeVisa Missing ",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    // onChange={radioValue2!=index?()=>{setRadioValue2(index)}:()=>{setRadioValue2(1000)}}
                                    onChange={handleOnRadioButtonChange}
                                    defaultValue={`f${index}`}
                                  >
                                    <div className="col-xs-6">
                                      <Radio defaultChecked value={`f${index}`}>
                                        Yes
                                      </Radio>
                                    </div>
                                    <div className="col-xs-6">
                                      <Radio value={`s${index}`}>No</Radio>
                                    </div>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="clearfix" />

                          <div
                            className="col-sm-4"
                            style={{
                              display: radioIndexList.includes(index)
                                ? "none"
                                : "",
                            }}
                          >
                            <label htmlFor="email">Visa Issuing Country</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "visaIssueCountry"]}
                              fieldKey={[field.fieldKey, "visaIssueCountry"]}
                              rules={[
                                {
                                  required: radioIndexList.includes(index)
                                    ? false
                                    : true,
                                  message: "Visa Issuing Country Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div
                            className="col-sm-4"
                            style={{
                              display: radioIndexList.includes(index)
                                ? "none"
                                : "",
                            }}
                          >
                            <label htmlFor="email">Expiry Date</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "expiryDate"]}
                              fieldKey={[field.fieldKey, "expiryDate"]}
                              rules={[
                                {
                                  required: radioIndexList.includes(index)
                                    ? false
                                    : true,
                                  message: "expiryDate Missing ",
                                },
                              ]}
                            >
                              <DatePicker
                                placeholder="YYYY-MM-DD"
                                disabledDate={disabledDate}
                                style={{ height: 39, width: 196 }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                      <div className="col-sm-4">
                        <Form.Item>
                          <div className="form-group m_t_30">
                            <label htmlFor="email"></label>
                            <button
                              ref={secondButtonRef}
                              className="btn btn-success save-event waves-effect waves-light"
                              type="button"
                              onClick={() => add()}
                            >
                              Add Candidates
                            </button>
                          </div>
                        </Form.Item>
                      </div>
                    </>
                  )}
                </Form.List>

                <div className="clearfix" />

                {/* <div className="col-sm-12">
          <div className="form-group ">
            <label htmlFor="email"></label>
            <button className="btn btn-success save-event waves-effect waves-light" type="button">Add Candidates</button>
          </div>        
        </div> */}
              </div>
            </Modal.Body>
            <div className="modal-footer  m-t-30">
              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setJobStatusOPen(false);
                }}
                data-dismiss="modal"
                className="btn btn-default waves-effect"
                type="button"
              >
                Cancel
              </button>
            </div>
            <div className="clearfix" />
            {/* </form> */}
          </Form>
        </Modal>

        {/* end modal job Status  */}

        {/* scoial media share modal statrt */}
        <Modal
          show={isShareOpen}
          onHide={() => {
            setIsShareOpen(!isShareOpen);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-12 m_t_30 text-center">
              <div className="social1">
                <WhatsappShareButton
                  url={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                  )}
                  className={""}
                  title={"CareerApp Job Post"}
                >
                  {/* <a href="#" className="social_face"> */}
                  <Tooltip title="whatsApp">
                    <i
                      className="fa fa-whatsapp"
                      style={{
                        fontSize: 51,
                        color: "green",
                        paddingRight: 2,
                      }}
                    ></i>
                  </Tooltip>
                  {/* </a> */}
                </WhatsappShareButton>{" "}
                <TelegramShareButton
                  url={`career app job Share`}
                  title={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                  )}
                >
                  <Tooltip title="Telegram">
                    <i
                      className="fa fa-telegram"
                      style={{
                        fontSize: 48,
                        color: "rgb(55, 174, 226)",
                      }}
                    ></i>
                  </Tooltip>
                </TelegramShareButton>
                <EmailShareButton
                  url={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                  )}
                  subject={"Jobs In Carrer app"}
                  body={"This is a Jobs from   Carrer app"}
                  className={""}
                >
                  {/* <EmailIcon></EmailIcon> */}
                  {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                  <Tooltip title="Gmail">
                    <img
                      src={require("../../../images/gmail-icon-svg-28.jpg")}
                      style={{ height: 66, marginTop: -24 }}
                    />
                  </Tooltip>
                </EmailShareButton>{" "}
                <CopyToClipboard
                  //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                  text={`https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`}
                >
                  <Tooltip title="copy to clipboard">
                    {" "}
                    {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                    <img
                      src={require("../../../images/copy-clipboard-icon-28.jpg")}
                      style={{
                        height: 66,
                        marginTop: -24,
                        marginLeft: -15,
                      }}
                      onClick={() => {
                        setIsShareOpen(!isShareOpen);
                      }}
                    />
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>
            <div className="modal-footer  m-t-30"></div>
          </Modal.Body>
        </Modal>
        {/* social media modal end */}
      </div>
    </>
  );
};

export default VendorSavedJobComponent;
