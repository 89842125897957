import React from "react";
import _ from "lodash";
// import countryTelData from 'country-telephone-data';
import countryTelData from "./countryCodes";

function PhoneNumberWithCode(props: any) {
  const wrapperRef = React.useRef<HTMLUListElement>(null);

  const wrapperRef2 = React.useRef<HTMLButtonElement>(null);

  // const [cCode, setCCode] = React.useState("91");

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
        //console.log("You clicked outside of me!");
        if (
          wrapperRef2.current &&
          !wrapperRef2.current?.contains(event.target)
        ) {
          //console.log("You clicked outside button!");
          setIsOpen(false);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const xx = props.value || "+91";

  const val: any = countryTelData.getByDialCode(xx);

  const lis: any[] = [];

  const array: any[] = countryTelData.getAll();

  for (let i = 0; i < array.length; i++) {
    const e = array[i];

    const s = e.name;

    lis.push(
      <React.Fragment key={i}>
        <li key={i} style={{ width: "300px" }}>
          <a
            role="button"
            className="country"
            onClick={() => {
              props.onChange("+" + e.dialCode);
              setIsOpen(false);
            }}
          >
            {/* <i style={{ marginRight: '10px' }} id={e.iso2} className={`flag ${e.iso2}`} /> */}
            {s}
            <i
              id={e.dialCode}
              className="callingCode"
              style={{ marginLeft: "10px" }}
            >
              +{e.dialCode}
            </i>
          </a>
        </li>
        <li />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="input-phone">
        <div className="input-group">
          <div
            className={isOpen ? "input-group-btn open" : "input-group-btn"}
            style={{ cursor: props["disabled"] ? "no-drop" : "" }}
          >
            <button
              disabled={props["disabled"] ? true : false}
              ref={wrapperRef2}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              type="button"
              id="btn-country"
              className="btn btn-default  f16 dropdown-toggle"
              style={{ height: props.codeHeight ? props.codeHeight : "40px" }}
            >
              {/* <span className={`flag ${val ? val.iso2 : ""} btn-flag`} style={{ marginTop: "3px" }} /> */}
              <span className="btn-cc">
                &nbsp;&nbsp; +{val ? val.dialCode : ""} &nbsp;&nbsp;
              </span>
              <span className="caret" />
            </button>
            <ul
              ref={wrapperRef}
              className="dropdown-menu f16"
              style={{
                overflowX: "hidden",
                marginTop: props["downwards"] ? 0 : -445,
              }}
            >
              {lis}
            </ul>
          </div>
          {props.phoneNo}
        </div>
      </div>
      {props["noPenIcon"] ? (
        ""
      ) : (
        <div className="profile_edit2">
          <a>
            <i className="fa fa-pencil" aria-hidden="true" />
          </a>
        </div>
      )}
    </React.Fragment>
  );
}

export default PhoneNumberWithCode;
