import { ErrorMessage } from "@hookform/error-message";
import { Dropdown, Menu, message, Popconfirm } from "antd";
import axios from "axios";
import { Console } from "console";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { Controller, useForm } from "react-hook-form";
import {
  EditAdminClient,
  GetAllAdminClient,
  RemoveAdminClient,
  SaveAdminClient,
  useMyUserContext,
  useMyUserDispatcher,
} from "../../../action/admin/MyUserAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";

import { registerMe } from "../../../apis/misc";
import { GlobalSpinnerContext } from "../../../context/GlobalSpinner";
import { AppUrls, environment } from "../../../environments/environment";
import {
  AdminClientEditRequestModel,
  GetAllAdminClientRequestModel,
  GetAllAdminRemoveRequestModel,
  SaveAdminClientRequestModel,
} from "../../../models/admin/MyUser";
import { InvitationRequestModel } from "../../../models/superadmin/SuperAdminUsers";
import AuthService from "../../../services/AuthService";
import PhoneNumberWithCode from "../../components/PhoneNumberWithCode";

const defaultValues = {
  countryCode: "",
  mobileNumber: "",
  firstName: "",
};

const MyClient = () => {
  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;

  const myAdminProfileDispatcher = useAdminMyProfileDispatcher();
  const myAdminProfileContext = useAdminMyProfileContext();
  const {
    basicInfo,
    loggedAdminId,
    // myProfile,
    myProfileProgressBar,
    profileImage,
  } = myAdminProfileContext;

  const myUserDispatcher = useMyUserDispatcher();
  const myUserContext = useMyUserContext();
  const {
    AdminClientEditRespondModel,
    GetAllAdminClientRespondModel,
    SaveAdminClientRespondModel,
    GetAllAdminRemoveRespondModel,
  } = myUserContext;

  const [isListShow, setIsListShow] = useState<boolean>(false);
  const [isListShowWithIndex, setIsListShowWithIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentClient, setCurrentClient] = useState<number>(0);
  const [generatedPassword, setGeneratedPassword] = useState<string>("");
  const [permissionArrayList, setPermissionArrayList] = useState<number[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const globalSpinner = useContext(GlobalSpinnerContext);
  const [mobileData, setMobileData] = useState<string>("");
  const [emailData, setEmailData] = useState<string>("");
  const [countryCodeData, setCountryCodeData] = useState<string>("");
  const [passwordData, setPasswordData] = useState<string>("");
  const [isEmail, setIsEmail] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [currentClientIdAfterReg, setCurrentClientAfterReg] = useState<number>(
    0
  );
  const [prevPassword, setPrevPassword] = useState<string>("");
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null)
      (async () => {
        await getAdminLoggedUserId(
          myAdminProfileDispatcher,
          parseInt(user?.id),
          authorizationToken
        );
      })();
  }, [authorizationToken]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      (async () => {
        await GetAllAdminClient(
          myUserDispatcher,
          {
            AdminId: loggedAdminId,
            Page: 1,
            PageSize: 30,
            SearchTerm: "",
            ShowInactive: false,
            SortOrder: "",
          } as GetAllAdminClientRequestModel,
          authorizationToken
        );
      })();
      setIsOpen(false);
    }
  }, [
    SaveAdminClientRespondModel,
    GetAllAdminRemoveRespondModel,
    loggedAdminId,
  ]);

  React.useEffect(() => {
    if (AdminClientEditRespondModel != null) {
      setValue2("firstName", AdminClientEditRespondModel.clientName);
      setValue2("email", AdminClientEditRespondModel.email);
      setValue2("countryCode", AdminClientEditRespondModel.countryCode);
      setValue2("mobileNumber", AdminClientEditRespondModel.mobileNumber);
      setValue2("password", AdminClientEditRespondModel.password);
      setPrevPassword(AdminClientEditRespondModel.password);
      setCurrentClient(AdminClientEditRespondModel.rowId);
      setCurrentClientAfterReg(AdminClientEditRespondModel.userId);
      setGeneratedPassword(AdminClientEditRespondModel.password);
    }
  }, [AdminClientEditRespondModel]);

  const handleEdit = (id: any) => {
    if (authorizationToken != null) {
      (async () => {
        await EditAdminClient(
          myUserDispatcher,
          {
            RowId: id,
          } as AdminClientEditRequestModel,
          authorizationToken
        );
      })();
      setIsOpen(true);
    }
  };

  const handleDelete = (id: any) => {
    if (authorizationToken != null) {
      (async () => {
        await RemoveAdminClient(
          myUserDispatcher,
          {
            RowId: id,
          } as GetAllAdminRemoveRequestModel,
          authorizationToken
        );
      })();
    }
  };

  React.useEffect(() => {
    const handler = (event: any) => {
      if (1) {
        if (1) {
          // //console.log("*");
          // setIsListShowWithIndex(
          //   isListShowWithIndex == 1000 ? 1000 : isListShowWithIndex
          // );
        } else {
          //console.log(isListShowWithIndex);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const handleGeneratePasword = () => {
    let A1 = "Client";
    let A2 = "@";
    const min = 1;
    const max = 100;
    const rand1 = Math.floor(Math.random() * 31) + 10;
    const rand2 = Math.floor(Math.random() * 31) + 50;
    // const pwd = (A1 +Math.round(rand1) + A2 + Math.round(rand2)).toString();
    setGeneratedPassword(A1 + Math.round(rand1) + A2 + Math.round(rand2));
    //console.log(56888, rand1);
  };

  // const handleUserClick = (data: any) => {
  //   //console.log(99, data);
  //   if (authorizationToken != null) {
  //     (async () => {
  //       await SaveAdminClient(
  //         myUserDispatcher,
  //         {
  //           AdminId: 4,
  //           ClientName: data.firstName,
  //           Email: data.email,
  //           MobileNumber: data.mobileNumber,
  //           CountryCode: data.countryCode === "" ? "+91" : data.countryCode,
  //           GeneratedPassword: data.password,
  //           Password: data.password,
  //           IsActive: true,
  //           RowId: currentClient,
  //         } as SaveAdminClientRequestModel,
  //         authorizationToken
  //       );
  //     })();
  //   }
  // };

  const saveClientDetails = (data: any, clientUserId: number) => {
    if (authorizationToken != null) {
      (async () => {
        await SaveAdminClient(
          myUserDispatcher,
          {
            AdminId: loggedAdminId,
            ClientName: data.firstName,
            Email: data.email,
            MobileNumber: data.mobileNumber,
            CountryCode: data.countryCode === "" ? "+91" : data.countryCode,
            GeneratedPassword: data.password,
            Password: data.password,
            IsActive: true,
            RowId: currentClient,
            UserId: clientUserId,
          } as SaveAdminClientRequestModel,
          authorizationToken
        );
      })();
      setCurrentClient(0);
      setCurrentClientAfterReg(0);
    }
  };

  const handleClientClick = (data: any) => {
    if (authorizationToken != null) {
      setIsOpen(true);
      setIsListShowWithIndex(1000);
      // setIsListShow("none");
      // if (currentAdminId == 0) {
      message.loading("please wait..");
      registerMe({
        RowId: currentClient,
        UserId: currentClientIdAfterReg,
        RoleId: 5,
        Email: data.email,
        UserName: data.firstName,
        CountryCode: data.countryCode === "" ? "+91" : data.countryCode,
        PhoneNumber: data.mobileNumber,
        Password: data.password,
        CurrentPassword: currentClient === 0 ? data.password : prevPassword,
        EmployerFlag: false,
        VendorFlag: false,
        SuperAdminFlag: false,
        EmployeeFlag: false,
        CandidateFlag: false,
        AdminFlag: false,
        ClientFlag: true,
        IsActive: true,
      }).then((res: any) => {
        //console.log(456111, res);
        res.data.isSuccess
          ? message.loading("Creating Client..")
          : message.error(res.data.message);
        setCurrentClientAfterReg(res.data.rowId);
        if (res.data.isSuccess) {
          saveClientDetails(data, res.data.rowId);
        }
      });
      // } else {
      //   saveAdminDetails(data, currentAdminIdAfterReg);
      // }
    }
  };

  const handleInvitationClick = (
    mobileNumber: string,
    email: string,
    countryCode: string,
    password: string
  ) => {
    setGeneratedPassword("");

    setMobileData(mobileNumber);
    setEmailData(email);
    setCountryCodeData(countryCode);
    setPasswordData(password);

    setIsModalVisible(true);
  };

  const handleEmailClick = () => {
    setIsEmail(true);
    setIsMobile(false);
    if (authorizationToken != null) {
      let token = "";
      try {
        if (authorizationToken?.startsWith(`"`)) {
          let token = authorizationToken.substring(1);
          token = authorizationToken.slice(0, -1);
        }

        let header = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        };

        let data = {
          Email: emailData,
          PhoneNumber: countryCodeData + mobileData,
          Password: passwordData,
        } as InvitationRequestModel;

        // const url1 = AppUrls.SendInvitationPhone;
        const url2 = AppUrls.SendInvitationEmail;
        // axios
        //   .post(url1, JSON.stringify(data), { headers: header })
        //   .then((res) => {
        //     //console.log(res);
        //   });

        axios
          .post(url2, JSON.stringify(data), { headers: header })
          .then((res) => {
            //console.log(res);
          });
      } catch (e) {}
      setIsListShowWithIndex(1000);
      setIsModalVisible(false);
      setIsEmail(false);
      setIsMobile(false);
    }
  };

  const handlePhoneClick = () => {
    setIsEmail(false);
    setIsMobile(true);
    if (authorizationToken != null) {
      let token = "";
      try {
        if (authorizationToken?.startsWith(`"`)) {
          let token = authorizationToken.substring(1);
          token = authorizationToken.slice(0, -1);
        }

        let header = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        };

        let data = {
          Email: emailData,
          PhoneNumber: countryCodeData + mobileData,
          Password: passwordData,
        } as InvitationRequestModel;

        const url1 = AppUrls.SendInvitationPhone;
        // const url2 = AppUrls.SendInvitationEmail;
        axios
          .post(url1, JSON.stringify(data), { headers: header })
          .then((res) => {
            //console.log(res);
          });

        // axios
        //   .post(url2, JSON.stringify(data), { headers: header })
        //   .then((res) => {
        //     //console.log(res);
        //   });
      } catch (e) {}
      setIsListShowWithIndex(1000);
      setIsModalVisible(false);
      setIsEmail(false);
      setIsMobile(true);
    }
  };

  function cancel(e: any) {
    setGeneratedPassword("");
  }
  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <a
            onClick={() => {
              setIsOpen(!isOpen);
              setCurrentClient(0);
              setGeneratedPassword("");
            }}
            data-target="#new_admin"
            data-toggle="modal"
            className="cutomise_but"
          >
            New Client Profile
          </a>

          <Modal
            show={isModalVisible}
            onHide={() => {
              setIsModalVisible(!isModalVisible);
            }}
            bsSize="xsmall"
          >
            <Modal.Header closeButton>
              <Modal.Title>please select</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-sm-2"></div>
              <div className="col-sm-4">
                <button
                  onClick={handleEmailClick}
                  type="button"
                  className={
                    isEmail
                      ? "btn btn-success create_but"
                      : "btn btn-success sig_but"
                  }
                >
                  Email
                </button>
              </div>
              <div className="col-sm-4">
                <button
                  style={{ marginLeft: 52 }}
                  onClick={handlePhoneClick}
                  type="button"
                  className={
                    isMobile
                      ? "btn btn-success create_but"
                      : "btn btn-success sig_but"
                  }
                >
                  Mobile
                </button>
              </div>

              <div className="col-sm-2"></div>
            </Modal.Body>
          </Modal>

          <Modal
            show={isOpen}
            onHide={() => {
              setIsOpen(!isOpen);
            }}
          >
            <form onSubmit={handleSubmit2(handleClientClick)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>
                  {currentClient == 0
                    ? "  New Client Profile"
                    : "Update  Client Profile"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="email " className="required">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Name"
                      name="firstName"
                      ref={register2({
                        required: "Name is required",
                      })}
                    />
                    <ErrorMessage
                      errors={errors2}
                      name="firstName"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder=""
                      name="email"
                      ref={register2({
                        required: "Email is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Please enter a valid e-mail address.",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors2}
                      name="email"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Mobile Number
                    </label>

                    <Controller
                      control={control2}
                      name="countryCode"
                      render={({ onChange, onBlur, value, name }) => (
                        <PhoneNumberWithCode
                          codeHeight={"41px"}
                          noPenIcon={true}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                          phoneNo={
                            <input
                              type="text"
                              name="mobileNumber"
                              id="phoneNumber"
                              className="form-control phoneNumber"
                              placeholder="Mobile number"
                              ref={register2({
                                required: "Mobile number is required",
                                maxLength: {
                                  value:
                                    watch2("countryCode").length > 3 ? 9 : 10,
                                  message: `It must be ${
                                    watch2("countryCode").length > 3 ? 9 : 10
                                  } digits only.`,
                                },
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message:
                                    "Mobile number should be numbers only",
                                },
                                minLength: {
                                  value:
                                    watch2("countryCode").length > 3 ? 9 : 10,
                                  message: `It must be ${
                                    watch2("countryCode").length > 3 ? 9 : 10
                                  } digits only.`,
                                },
                              })}
                            />
                          }
                        />
                      )}
                    />

                    <ErrorMessage
                      errors={errors2}
                      name="mobileNumber"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label htmlFor="email" className="col-sm-12 required">
                      Password
                    </label>
                    <button
                      onClick={handleGeneratePasword}
                      className="btn btn-success save-event waves-effect waves-light"
                      type="button"
                    >
                      Generate Password
                    </button>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group ">
                    <label htmlFor="email" className="col-sm-12"></label>

                    <input
                      type="text"
                      // className="form-control "
                      style={{
                        paddingTop: 25,
                        color: "green",
                        border: "none",
                        fontSize: "large",
                      }}
                      // placeholder="location"
                      name="password"
                      value={generatedPassword}
                      ref={register2({
                        required: "password is required",
                      })}
                    />
                    {generatedPassword !== "" ? null : (
                      <ErrorMessage
                        errors={errors2}
                        name="password"
                        render={({ message }) => (
                          <div
                            style={{ fontSize: "large", marginTop: -28 }}
                            className="login_validation"
                          >
                            {message}
                          </div>
                        )}
                      />
                    )}
                    <div className="col-sm-4"></div>
                  </div>
                </div>

                {/* <div className="col-sm-1"></div> */}
                <div className="clearfix" />
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="clearfix" />
            </form>
          </Modal>

          {/* END MODAL */}
          <div className="clearfix" />
          <div className="row ">
            <div className="col-sm-12">
              <div className="row">
                <div
                  className="admin_users"
                  style={{ overflow: "hidden" }}
                  tabIndex={0}
                >
                  <div className="users_admin1_m">
                    {/* user start */}
                    <Scrollbars
                      style={{ height: 750 }}
                      autoHide
                      renderThumbVertical={({ style, ...props }) => (
                        <div
                          {...props}
                          style={{
                            ...style,
                            position: "relative",
                            display: "block",
                            width: "5px",
                            cursor: "pointer",
                            borderRadius: "inherit",
                            backgroundColor: "rgb(73, 69, 69)",
                            height: "115px",
                          }}
                        />
                      )}
                    >
                      {GetAllAdminClientRespondModel.data != undefined
                        ? GetAllAdminClientRespondModel.data.map((data, i) => {
                            return (
                              <div className="col-sm-3">
                                <div className="users_admin1">
                                  <div className="action_btn1">
                                    <Dropdown
                                      overlay={
                                        <div className="action_sec">
                                          <Menu
                                            style={{
                                              backgroundColor: "#e9eaf1",
                                              width: 100,
                                            }}
                                          >
                                            <Menu.Item key="0">
                                              <li
                                                onClick={() => {
                                                  handleInvitationClick(
                                                    data.mobileNumber,
                                                    data.email,
                                                    data.countryCode,
                                                    data.password
                                                  );
                                                  setIsListShowWithIndex(
                                                    data.adminId
                                                  );
                                                }}
                                              >
                                                <a>Invite </a>
                                              </li>
                                            </Menu.Item>
                                            <Menu.Divider />
                                            <Menu.Item key="1">
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleEdit(data.clientId)
                                                  }
                                                >
                                                  Edit{" "}
                                                </a>
                                              </li>
                                            </Menu.Item>
                                            <Menu.Divider />
                                            <Menu.Item key="3">
                                              <Popconfirm
                                                title={
                                                  <div>
                                                    <p>
                                                      Are you sure delete this
                                                      Client?{" "}
                                                    </p>
                                                  </div>
                                                }
                                                onConfirm={(id: any) =>
                                                  handleDelete(data.clientId)
                                                }
                                                onCancel={cancel}
                                                okText="Yes"
                                                cancelText="No"
                                              >
                                                <li>
                                                  <a
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    Delete
                                                  </a>
                                                </li>
                                              </Popconfirm>
                                            </Menu.Item>
                                          </Menu>
                                        </div>
                                      }
                                      trigger={["click"]}
                                    >
                                      <button
                                        type="button"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <i
                                          className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </Dropdown>
                                    <div className="user_action" tabIndex={-1}>
                                      <div className="action_sec"></div>
                                    </div>
                                  </div>
                                  <div className="clearfix" />
                                  <div className="admin_user">
                                    {data.imageURL == null ? (
                                      <img
                                        className="img-responsive"
                                        src={require("./../../../images/profileDefault1.jpg")}
                                      ></img>
                                    ) : (
                                      <img
                                        className="img-responsive"
                                        src={require("./../../../images/profileDefault1.jpg")}
                                        // src={
                                        //   environment.baseUrl +
                                        //   `/Upload/VendorProfileImage/${data.imageURL}`
                                        // }
                                      ></img>
                                    )}
                                  </div>
                                  <div className="admins_names"></div>
                                  <div className="admins_contact1">
                                    {" "}
                                    <i
                                      className="fa fa-phone"
                                      aria-hidden="true"
                                    />{" "}
                                    {data.countryCode}
                                    {data.mobileNumber}
                                  </div>
                                  <div
                                    className="admins_contact1"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <i
                                      className="fa fa-envelope-o"
                                      aria-hidden="true"
                                    />{" "}
                                    {data.email}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </>
  );
};

export default MyClient;
