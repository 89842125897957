import React, { useReducer, createContext, useContext } from "react";
import * as API from "./../../apis/candidate";

const initialState1 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateExperienceContext = createContext({
  candidateExperienceState: initialState1,
  getCandidateExperiences: (data?: any) => { },
});

const reducer1 = (candidateExperienceState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState1, loading: true };
    case "FETCHED":
      return { ...initialState1, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState1, loading: false, error: action.payload };
    default:
      return candidateExperienceState;
  }
};

export const CandidateExperienceContextProvider = (props: any) => {
  const [candidateExperienceState, dispatch] = useReducer(
    reducer1,
    initialState1
  );

  const getCandidateExperiences = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateExperiences(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateExperienceContext.Provider
      value={{
        candidateExperienceState,
        getCandidateExperiences,
      }}
    >
      {props.children}
    </CandidateExperienceContext.Provider>
  );
};

export const useCandidateExperienceContext = () => {
  const candidateExperienceContext = useContext(CandidateExperienceContext);
  return candidateExperienceContext;
};

const initialState2 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateContext = createContext({
  candidateState: initialState2,
  getCandidates: (data?: any) => { },
});

const reducer2 = (candidateState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState2, loading: true };
    case "FETCHED":
      return { ...initialState2, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState2, loading: false, error: action.payload };
    default:
      return candidateState;
  }
};

export const CandidateContextProvider = (props: any) => {
  const [candidateState, dispatch] = useReducer(reducer2, initialState2);

  const getCandidates = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidates(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateContext.Provider
      value={{
        candidateState,
        getCandidates,
      }}
    >
      {props.children}
    </CandidateContext.Provider>
  );
};

export const useCandidateContext = () => {
  const candidateContext = useContext(CandidateContext);
  return candidateContext;
};


const initialState3 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateSkillsContext = createContext({
  candidateSkillsState: initialState3,
  getCandidateSkills: (data?: any) => { },
});

const reducer3 = (candidateSkillsState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState3, loading: true };
    case "FETCHED":
      return { ...initialState3, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState3, loading: false, error: action.payload };
    default:
      return candidateSkillsState;
  }
};

export const CandidateSkillsContextProvider = (props: any) => {
  const [candidateSkillsState, dispatch] = useReducer(reducer3, initialState3);

  const getCandidateSkills = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateSkills(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateSkillsContext.Provider
      value={{
        candidateSkillsState,
        getCandidateSkills,
      }}
    >
      {props.children}
    </CandidateSkillsContext.Provider>
  );
};

export const useCandidateSkillsContext = () => {
  const candidateSkillsContext = useContext(CandidateSkillsContext);
  return candidateSkillsContext;
};

const initialState4 = {
  data: [],
  loading: false,
  error: null,
};

export const TrainingContext = createContext({
  trainingState: initialState4,
  getTrainings: (data?: any) => { },
});

const reducer4 = (trainingState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState4, loading: true };
    case "FETCHED":
      return { ...initialState4, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState4, loading: false, error: action.payload };
    default:
      return trainingState;
  }
};

export const TrainingContextProvider = (props: any) => {
  const [trainingState, dispatch] = useReducer(reducer4, initialState4);

  const getTrainings = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getTrainings(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <TrainingContext.Provider
      value={{
        trainingState,
        getTrainings,
      }}
    >
      {props.children}
    </TrainingContext.Provider>
  );
};

export const useTrainingContext = () => {
  const trainingContext = useContext(TrainingContext);
  return trainingContext;
};



const initialState5 = {
  data: [],
  loading: false,
  error: null,
};

export const EducationQualificationContext = createContext({
  educationQualificationState: initialState5,
  getEducationQualifications: (data?: any) => { },
});

const reducer5 = (educationQualificationState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState5, loading: true };
    case "FETCHED":
      return { ...initialState5, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState5, loading: false, error: action.payload };
    default:
      return educationQualificationState;
  }
};

export const EducationQualificationContextProvider = (props: any) => {
  const [educationQualificationState, dispatch] = useReducer(
    reducer5,
    initialState5
  );

  const getEducationQualifications = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getEducationQualifications(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <EducationQualificationContext.Provider
      value={{
        educationQualificationState,
        getEducationQualifications,
      }}
    >
      {props.children}
    </EducationQualificationContext.Provider>
  );
};

export const useEducationQualificationContext = () => {
  const educationQualificationContext = useContext(
    EducationQualificationContext
  );
  return educationQualificationContext;
};

// const initialState6 = {
//   data: [],
//   loading: false,
//   error: null,
// };

// export const CandidateAchievementsContext = createContext({
//   candidateAchievementsState: initialState6,
//   getCandidateAchievements: (data?: any) => { },
// });

// const reducer6 = (candidateAchievementsState: any, action: any) => {
//   switch (action.type) {
//     case "FETCHING":
//       return { ...initialState6, loading: true };
//     case "FETCHED":
//       return { ...initialState6, loading: false, data: action.payload };
//     case "FETCH_ERROR":
//       return { ...initialState6, loading: false, error: action.payload };
//     default:
//       return candidateAchievementsState;
//   }
// };

// export const CandidateAchievementsContextProvider = (props: any) => {
//   const [candidateAchievementsState, dispatch] = useReducer(
//     reducer6,
//     initialState6
//   );

//   const getCandidateAchievements = (data?: any) => {
//     dispatch({ type: "FETCHING" });
//     API.getCandidateAchievements(data)
//       .then((res: any) => {
//         dispatch({ type: "FETCHED", payload: res.data.data });
//       })
//       .catch((err: any) => {
//         dispatch({ type: "FETCH_ERROR", payload: err });
//       });
//   };

//   return (
//     <CandidateAchievementsContext.Provider
//       value={{
//         candidateAchievementsState,
//         getCandidateAchievements,
//       }}
//     >
//       {props.children}
//     </CandidateAchievementsContext.Provider>
//   );
// };

// export const useCandidateAchievementsContext = () => {
//   const candidateAchievementsContext = useContext(CandidateAchievementsContext);
//   return candidateAchievementsContext;
// };

const initialState7 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateLanguageMapsContext = createContext({
  candidateLanguageMaps: initialState7,
  getCandidateLanguageMaps: (data?: any) => { },
});

const reducer7 = (candidateLanguageMaps: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState7, loading: true };
    case "FETCHED":
      return { ...initialState7, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState7, loading: false, error: action.payload };
    default:
      return candidateLanguageMaps;
  }
};

export const CandidateLanguageMapsContextProvider = (props: any) => {
  const [candidateLanguageMaps, dispatch] = useReducer(reducer7, initialState7);

  const getCandidateLanguageMaps = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateLanguageMaps(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateLanguageMapsContext.Provider
      value={{
        candidateLanguageMaps,
        getCandidateLanguageMaps,
      }}
    >
      {props.children}
    </CandidateLanguageMapsContext.Provider>
  );
};

export const useCandidateLanguageMapsContext = () => {
  const candidateLanguageMapsContext = useContext(CandidateLanguageMapsContext);
  return candidateLanguageMapsContext;
};

// const initialState8 = {
//   data: [],
//   loading: false,
//   error: null,
// };

// export const CandidateReferencesContext = createContext({
//   candidateReferences: initialState8,
//   getCandidateReferences: (data?: any) => { },
// });

// const reducer8 = (candidateReferences: any, action: any) => {
//   switch (action.type) {
//     case "FETCHING":
//       return { ...initialState8, loading: true };
//     case "FETCHED":
//       return { ...initialState8, loading: false, data: action.payload };
//     case "FETCH_ERROR":
//       return { ...initialState8, loading: false, error: action.payload };
//     default:
//       return candidateReferences;
//   }
// };

// export const CandidateReferencesContextProvider = (props: any) => {
//   const [candidateReferences, dispatch] = useReducer(reducer8, initialState8);

//   const getCandidateReferences = (data?: any) => {
//     dispatch({ type: "FETCHING" });
//     API.getCandidateReferences(data)
//       .then((res: any) => {
//         dispatch({ type: "FETCHED", payload: res.data.data });
//       })
//       .catch((err: any) => {
//         dispatch({ type: "FETCH_ERROR", payload: err });
//       });
//   };

//   return (
//     <CandidateReferencesContext.Provider
//       value={{
//         candidateReferences,
//         getCandidateReferences,
//       }}
//     >
//       {props.children}
//     </CandidateReferencesContext.Provider>
//   );
// };

// export const useCandidateReferencesContext = () => {
//   const candidateReferencesContext = useContext(CandidateReferencesContext);
//   return candidateReferencesContext;
// };


const initialState9 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateProfileImageContext = createContext({
  candidateProfileImage: initialState9,
  getCandidateProfileImage: (data?: any) => { },
});

const reducer9 = (candidateProfileImage: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState9, loading: true };
    case "FETCHED":
      return { ...initialState9, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState9, loading: false, error: action.payload };
    default:
      return candidateProfileImage;
  }
};

export const CandidateProfileImageContextProvider = (props: any) => {
  const [candidateProfileImage, dispatch] = useReducer(reducer9, initialState9);

  const getCandidateProfileImage = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateProfileImage(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateProfileImageContext.Provider
      value={{
        candidateProfileImage,
        getCandidateProfileImage,
      }}
    >
      {props.children}
    </CandidateProfileImageContext.Provider>
  );
};

export const useCandidateProfileImageContext = () => {
  const candidateProfileImageContext = useContext(CandidateProfileImageContext);
  return candidateProfileImageContext;
};


// const initialState10 = {
//   data: [],
//   loading: false,
//   error: null,
// };

// export const CandidateOtherCertificateContext = createContext({
//   candidateOtherCertificate: initialState10,
//   getCandidateOtherCertificates: (data?: any) => { },
// });

// const reducer10 = (candidateOtherCertificate: any, action: any) => {
//   switch (action.type) {
//     case "FETCHING":
//       return { ...initialState10, loading: true };
//     case "FETCHED":
//       return { ...initialState10, loading: false, data: action.payload };
//     case "FETCH_ERROR":
//       return { ...initialState10, loading: false, error: action.payload };
//     default:
//       return candidateOtherCertificate;
//   }
// };

// export const CandidateOtherCertificateContextProvider = (props: any) => {
//   const [candidateOtherCertificate, dispatch] = useReducer(reducer10, initialState10);

//   const getCandidateOtherCertificates = (data?: any) => {
//     dispatch({ type: "FETCHING" });
//     API.getCandidateOtherCertificates(data)
//       .then((res: any) => {
//         dispatch({ type: "FETCHED", payload: res.data.data });
//       })
//       .catch((err: any) => {
//         dispatch({ type: "FETCH_ERROR", payload: err });
//       });
//   };

//   return (
//     <CandidateOtherCertificateContext.Provider
//       value={{
//         candidateOtherCertificate,
//         getCandidateOtherCertificates,
//       }}
//     >
//       {props.children}
//     </CandidateOtherCertificateContext.Provider>
//   );
// };

// export const useCandidateOtherCertificateContext = () => {
//   const candidateOtherCertificateContext = useContext(CandidateOtherCertificateContext);
//   return candidateOtherCertificateContext;
// };
