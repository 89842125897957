import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  getVendorApprovalList,
  setVendorStatus,
  editVendor,
  useVendorApprovalContext,
  useVendorApprovalDispatcher,
  getVendorStatusList,
} from "../../../action/admin/VendorApprovalAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import {
  VendorApprovalRequestModel,
  VendorEditRequestModel,
  VendorApproval,
  VendorApprovalViewModel,
  VendorApprovalSaveRequestModel,
  VendorApprovalSaveRespondModel,
  VendorStatusListRequestModel,
} from "../../../models/admin/VendorApproval";

import AuthService from "../../../services/AuthService";
import VendorDocumentForm from "./VendorDocumentForm";
import { Divider, Popover } from "antd";
import Popconfirm from "antd/lib/popconfirm";
import { environment } from "../../../environments/environment";
import {
  useAdminMyJobDispatcher,
  useAdminMyJobContext,
} from "../../../action/admin/AdminMyJobAction";
import { Input } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { ErrorMessage } from "@hookform/error-message";
const { Search } = Input;

interface IVendorApprovalComponentProps {}

interface IVendorApprovalComponentState {}

const defaultValues = {};

const VendorApprovalComponent: React.FC<IVendorApprovalComponentProps> = (
  props
) => {
  const [
    VendorApprovalComponentState,
    setVendorApprovalComponentState,
  ] = React.useState<IVendorApprovalComponentState>(defaultValues);

  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;

  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const { myProfile, basicInfo, loggedAdminId } = myProfileAdminContext;
  const [active, setActive] = useState(2);
  const adminId = loggedAdminId;

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  //console.log("userId", userId, "adminId", adminId);

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  const vendorApprovalDispatcher = useVendorApprovalDispatcher();
  const vendorApprovalContext = useVendorApprovalContext();
  const {
    vendorApproval,
    vendorApprovalSaveRespond,
    VendorStatusListResult,
  } = vendorApprovalContext;
  //console.log("----------vendorApproval-----------", vendorApproval);

  let history = useHistory();

  const [
    isVendorAppliedOpenOpen,
    setIsVendorAppliedOpenOpen,
  ] = useState<boolean>(false);
  const [vendorId, setVendorId] = useState<number>(0);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isRejectedComments, setIsRejectedComment] = useState<boolean>(false);
  const [statusId, setStatusId] = useState<number>(1);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IVendorApprovalComponentState>({
    defaultValues,
  });

  const fetchMoreData = async () => {};

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      //console.log("career tips call getCareerTipsApprovalList", adminId);
      (async () => {
        await getVendorStatusList(
          vendorApprovalDispatcher,
          {
            Page: 1,
            PageSize: 10,
            VendorStatusId: active,
            ShowInactive: false,
            SortOrder: "",
            SearchTitle: searchTerm,
            SearchTerm: "",
          } as VendorStatusListRequestModel,
          authorizationToken
        );
      })();
      setIsRejectedComment(false);
    }
  }, [loggedAdminId, searchTerm, active, vendorApprovalSaveRespond]);

  const handleApprovalStatus = (vendorStatusId: number, vendorId: number) => {
    if (authorizationToken != null)
      setVendorStatus(
        vendorApprovalDispatcher,
        {
          vendorId: vendorId,
          adminId: adminId,
          vendorStatusId: vendorStatusId,
          isActive: true,
          rejectedComment: "",
        } as VendorApprovalSaveRequestModel,
        authorizationToken
      );
  };

  const handleJobApplayClick = (id: any) => {
    setVendorApprovalComponentState({
      ...VendorApprovalComponentState,
      vendorId: id,
    });
    handleVendorAppliedOpen();
  };

  const handleVendorAppliedOpen = () => {
    setIsVendorAppliedOpenOpen(!isVendorAppliedOpenOpen);
  };

  const onSearch = (value: any) => {
    setSearchTerm(value);
  };

  const handleRejectCall = (rowId: any, statusId: any) => {
    setStatusId(statusId);
    setVendorId(rowId);
    setIsRejectedComment(true);
  };

  const handleSubmitReject = (data: any) => {
    if (authorizationToken != null)
      setVendorStatus(
        vendorApprovalDispatcher,
        {
          vendorId: vendorId,
          adminId: adminId,
          vendorStatusId: statusId,
          isActive: true,
          rejectedComment: data.comments,
        } as VendorApprovalSaveRequestModel,
        authorizationToken
      );
  };

  return (
    <React.Fragment>
      <section id="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <h1 className="heading">Venders Registration</h1>
            <div className="action_btn1">
              <button
                type="button"
                className="actions_menu"
                onClick={() => {
                  setIsListOpen(!isListOpen);
                }}
              >
                <i className="fa fa-align-left" aria-hidden="true" />
              </button>
              <div
                className="user_menu"
                tabIndex={-1}
                style={{ display: isListOpen ? "block" : "none" }}
              >
                <div className="action_sec">
                  <ul>
                    {AdminPermissionResult != undefined &&
                    AdminPermissionResult.isVendorsRegistrationPermission ? (
                      <li>
                        <Link to="/admin/vendorapproval">
                          Venders Registration
                        </Link>{" "}
                      </li>
                    ) : null}
                    {AdminPermissionResult != undefined &&
                    AdminPermissionResult.isClientsjobpostPermission ? (
                      <li>
                        <Link to="/admin/clientjobapproval">
                          Client Job Posts
                        </Link>{" "}
                      </li>
                    ) : null}
                    {AdminPermissionResult != undefined &&
                    AdminPermissionResult.isCareerTipsPermission ? (
                      <li>
                        <Link to="/admin/careertipsapproval">Career Tips</Link>{" "}
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>

            <div className="clearfix" />
            <div className="our_team">
              <Search
                placeholder="input search text"
                onSearch={onSearch}
                enterButton
                style={{
                  width: 200,
                  padding: 4,
                  float: "right",
                  paddingTop: 10,
                }}
              />
              {/*  */}
              <div className="row">
                <div className="col-sm-12 col-lg-12 ">
                  <div className="ProgCheck">
                    <ol className="breadcrumb">
                      <li
                        style={{
                          cursor: "pointer",
                        }}
                        className={active == 2 ? "active10" : ""}
                        onClick={() => {
                          setActive(2);
                        }}
                      >
                        {/* <span className="sr-only">Current step:</span> */}
                        New Applicant{" "}
                      </li>
                      <li
                        style={{
                          cursor: "pointer",
                        }}
                        className={active == 3 ? "active10" : ""}
                        onClick={() => {
                          setActive(3);
                        }}
                      >
                        {" "}
                        Approved{" "}
                      </li>
                      <li
                        style={{
                          cursor: "pointer",
                        }}
                        className={active == 4 ? "active10" : ""}
                        onClick={() => {
                          setActive(4);
                        }}
                      >
                        {" "}
                        Rejected{" "}
                      </li>
                    </ol>
                  </div>

                  {active == 2 && (
                    <>
                      {" "}
                      <div className="row">
                        <div
                          className="my_job_descrip"
                          style={{ overflow: "hidden", outline: "none" }}
                          tabIndex={0}
                        >
                          <InfiniteScroll
                            className="_custom-infinite-scroll-class"
                            height={600}
                            // dataLength={100}
                            dataLength={100}
                            next={fetchMoreData}
                            hasMore={false}
                            loader={<h4>Loading...</h4>}
                            endMessage={""}
                          >
                            {VendorStatusListResult.data != undefined
                              ? VendorStatusListResult.data
                                  .filter((data) => data.vendorStatusId == 2)
                                  .map((vendor, i) => {
                                    return (
                                      <div
                                        className="col-sm-4 col-lg-4"
                                        key={`${i}`}
                                      >
                                        <div className="admin_vender_sec">
                                          <div className="admin_vender_reg">
                                            {vendor.imageUrl != null ? (
                                              <img
                                                width="325px!important"
                                                height="184px!important"
                                                src={
                                                  environment.baseUrl +
                                                  `/Upload/VendorProfileImage/${vendor.imageUrl}`
                                                }
                                              />
                                            ) : (
                                              <img
                                                width="325px!important"
                                                height="184px!important"
                                                src={require("./../../../images/profileDefault1.jpg")}
                                              />
                                            )}
                                          </div>
                                          <h3>{vendor.vendorName}</h3>
                                          <div className="admins_contact1">
                                            {" "}
                                            <i
                                              className="fa fa-phone"
                                              aria-hidden="true"
                                            ></i>
                                            {vendor.countryCode}-
                                            {vendor.phoneNumber}
                                          </div>
                                          <div className="admins_contact1">
                                            <i
                                              className="fa fa-envelope-o"
                                              aria-hidden="true"
                                            ></i>
                                            {vendor.email}
                                          </div>
                                          <div style={{ textAlign: "center" }}>
                                            Status Change Date:{" "}
                                            {vendor.vendorStatusChangeDate}
                                          </div>

                                          <div className="admin_vender_bt">
                                            <a
                                              onClick={() => {
                                                setVendorId(vendor.rowId);
                                                handleVendorAppliedOpen();
                                              }}
                                              data-target="#post_career"
                                              data-toggle="modal"
                                            >
                                              View Document
                                            </a>
                                          </div>
                                          <div className="admin_vender_bt">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to approve this
                                                    vendor?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleApprovalStatus(
                                                  3,
                                                  vendor.rowId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Approve
                                              </a>
                                            </Popconfirm>

                                            {/* <a  onClick={() => {
                                          handleApprovalStatus(1,career.rowId);
                                        }}
                                  > Approve 
                                  </a> */}
                                          </div>
                                          <div className="admin_vender_bt">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to reject this
                                                    vendor?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleRejectCall(
                                                  vendor.rowId,
                                                  4
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Reject
                                              </a>
                                            </Popconfirm>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                              : null}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </>
                  )}
                  {active == 3 && (
                    <>
                      {" "}
                      <div className="row">
                        <div
                          className="my_job_descrip"
                          style={{ overflow: "hidden", outline: "none" }}
                          tabIndex={0}
                        >
                          <InfiniteScroll
                            className="_custom-infinite-scroll-class"
                            height={600}
                            // dataLength={100}
                            dataLength={100}
                            next={fetchMoreData}
                            hasMore={false}
                            loader={<h4>Loading...</h4>}
                            endMessage={""}
                          >
                            {VendorStatusListResult.data != undefined
                              ? VendorStatusListResult.data
                                  .filter((data) => data.vendorStatusId == 3)
                                  .map((vendor, i) => {
                                    return (
                                      <div
                                        className="col-sm-4 col-lg-4"
                                        key={`${i}`}
                                      >
                                        <div className="admin_vender_sec">
                                          <div className="admin_vender_reg">
                                            {vendor.imageUrl != null ? (
                                              <img
                                                width="325px!important"
                                                height="184px!important"
                                                src={
                                                  environment.baseUrl +
                                                  `/Upload/VendorProfileImage/${vendor.imageUrl}`
                                                }
                                              />
                                            ) : (
                                              <img
                                                width="325px!important"
                                                height="184px!important"
                                                src={require("./../../../images/profileDefault1.jpg")}
                                              />
                                            )}
                                          </div>
                                          <h3>{vendor.vendorName}</h3>
                                          <div className="admins_contact1">
                                            {" "}
                                            <i
                                              className="fa fa-phone"
                                              aria-hidden="true"
                                            ></i>
                                            {vendor.countryCode}-
                                            {vendor.phoneNumber}
                                          </div>
                                          <div className="admins_contact1">
                                            <i
                                              className="fa fa-envelope-o"
                                              aria-hidden="true"
                                            ></i>
                                            {vendor.email}
                                          </div>
                                          <div style={{ textAlign: "center" }}>
                                            Status Change Date:{" "}
                                            {vendor.vendorStatusChangeDate}
                                          </div>

                                          <div className="admin_vender_bt">
                                            <a
                                              onClick={() => {
                                                setVendorId(vendor.rowId);
                                                handleVendorAppliedOpen();
                                              }}
                                              data-target="#post_career"
                                              data-toggle="modal"
                                            >
                                              View Document
                                            </a>
                                          </div>
                                          <div className="admin_vender_bt">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to Move to new
                                                    applicant this vendor?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleApprovalStatus(
                                                  2,
                                                  vendor.rowId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Move to new applicant
                                              </a>
                                            </Popconfirm>

                                            {/* <a  onClick={() => {
                                          handleApprovalStatus(1,career.rowId);
                                        }}
                                  > Approve 
                                  </a> */}
                                          </div>
                                          <div className="admin_vender_bt">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to reject this
                                                    vendor?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleRejectCall(
                                                  vendor.rowId,
                                                  4
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Reject
                                              </a>
                                            </Popconfirm>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                              : null}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </>
                  )}
                  {active == 4 && (
                    <>
                      {" "}
                      <div className="row">
                        <div
                          className="my_job_descrip"
                          style={{ overflow: "hidden", outline: "none" }}
                          tabIndex={0}
                        >
                          <InfiniteScroll
                            className="_custom-infinite-scroll-class"
                            height={600}
                            // dataLength={100}
                            dataLength={100}
                            next={fetchMoreData}
                            hasMore={false}
                            loader={<h4>Loading...</h4>}
                            endMessage={""}
                          >
                            {VendorStatusListResult.data != undefined
                              ? VendorStatusListResult.data
                                  .filter((data) => data.vendorStatusId == 4)
                                  .map((vendor, i) => {
                                    return (
                                      <div
                                        className="col-sm-4 col-lg-4"
                                        key={`${i}`}
                                      >
                                        <div className="admin_vender_sec">
                                          <div className="admin_vender_reg">
                                            {vendor.imageUrl != null ? (
                                              <img
                                                width="325px!important"
                                                height="184px!important"
                                                src={
                                                  environment.baseUrl +
                                                  `/Upload/VendorProfileImage/${vendor.imageUrl}`
                                                }
                                              />
                                            ) : (
                                              <img
                                                width="325px!important"
                                                height="184px!important"
                                                src={require("./../../../images/profileDefault1.jpg")}
                                              />
                                            )}
                                          </div>
                                          <h3>{vendor.vendorName}</h3>
                                          <div className="admins_contact1">
                                            {" "}
                                            <i
                                              className="fa fa-phone"
                                              aria-hidden="true"
                                            ></i>
                                            {vendor.countryCode}-
                                            {vendor.phoneNumber}
                                          </div>
                                          <div className="admins_contact1">
                                            <i
                                              className="fa fa-envelope-o"
                                              aria-hidden="true"
                                            ></i>
                                            {vendor.email}
                                          </div>
                                          <div style={{ textAlign: "center" }}>
                                            Status Change Date:{" "}
                                            {vendor.vendorStatusChangeDate}
                                          </div>

                                          <Divider>Rejected Comment</Divider>
                                          <p>{vendor.rejectedComment}</p>
                                          <div className="admin_vender_bt">
                                            <a
                                              onClick={() => {
                                                setVendorId(vendor.rowId);
                                                handleVendorAppliedOpen();
                                              }}
                                              data-target="#post_career"
                                              data-toggle="modal"
                                            >
                                              View Document
                                            </a>
                                          </div>
                                          <div className="admin_vender_bt">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to approve this
                                                    vendor?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleApprovalStatus(
                                                  3,
                                                  vendor.rowId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Approve
                                              </a>
                                            </Popconfirm>

                                            {/* <a  onClick={() => {
                                          handleApprovalStatus(1,career.rowId);
                                        }}
                                  > Approve 
                                  </a> */}
                                          </div>
                                          <div className="admin_vender_bt">
                                            <Popconfirm
                                              title={
                                                <div>
                                                  <p>
                                                    Are you sure to Move to new
                                                    applicant this vendor?
                                                  </p>
                                                </div>
                                              }
                                              onConfirm={() =>
                                                handleApprovalStatus(
                                                  2,
                                                  vendor.rowId
                                                )
                                              }
                                              //onCancel={cancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <a
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Move to new applicant
                                              </a>
                                            </Popconfirm>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                              : null}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/*  */}
            </div>
            <Modal
              show={isRejectedComments}
              onHide={() => {
                setIsRejectedComment(false);
              }}
            >
              <form onSubmit={handleSubmit3(handleSubmitReject)} noValidate>
                <Modal.Header closeButton>
                  <Modal.Title> Edit a career tip</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <label className={"required"} htmlFor="email">
                      Comments for Reject
                    </label>
                    <input
                      type="text"
                      name="comments"
                      className="form-control"
                      placeholder="Enter Your Name"
                      ref={register3({
                        required: "Comments is required",
                      })}
                    />
                    <ErrorMessage
                      errors={errors3}
                      name="comments"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </Modal.Body>
                <div className="modal-footer  m-t-30">
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    data-dismiss="modal"
                    className="btn btn-default waves-effect"
                    type="button"
                    onClick={() => {
                      setIsRejectedComment(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </Modal>

            <div className="clearfix" />
            <VendorDocumentForm
              isVendorOpen={isVendorAppliedOpenOpen}
              handleVendorOpen={handleVendorAppliedOpen}
              currentVendorId={vendorId}
              loggedAdminId={loggedAdminId}
              authorizationToken={authorizationToken}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default VendorApprovalComponent;
