import React, { useState, useEffect } from "react";
import CandidateResumes from "../../resume_template/resume/CandidateResumes";
import CandidateCoverLetters from "../../resume_template/resume/CandidateCoverLetters";
import { useMyProfileContext } from "../../../../action/MyProfileAction";
import {
  getAllResumeCandidateMap,
  getAllCoverLetterCandidateMap,
} from "../../../../apis/resumebuilder";

interface IMyCoverLetterComponentProps {}

interface IMyCoverLetterComponentState {}

// const initialState = {};

const MyCoverLetterComponent: React.FC<IMyCoverLetterComponentProps> = (
  props
) => {
  // const [myCoverLetterComponentState, setMyCoverLetterComponentState] = React.useState<IMyCoverLetterComponentState>(
  //   initialState
  // );

  const myProfileContext = useMyProfileContext();
  const { loggedUserId } = myProfileContext;
  const candidateId = loggedUserId;

  const [candidateResumes, setCandidateResumes] = useState({});

  const [candidateCoverLetters, setCandidateCoverLetters] = useState({});

  useEffect(() => {
    if (candidateId) {
      getAllResumeCandidateMap({
        CandidateId: candidateId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          ////console.log(res.data);
          setCandidateResumes(res.data);
        })
        .catch((err) => {
          ////console.log(err);
        });

      getAllCoverLetterCandidateMap({
        CandidateId: candidateId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          ////console.log(res.data);
          setCandidateCoverLetters(res.data);
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [candidateId]);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="clearfix" />
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <CandidateCoverLetters
                candidateCoverLetters={candidateCoverLetters}
                candidateId={candidateId}
                setCandidateCoverLetters={setCandidateCoverLetters}
              />
              <div className="m-t-25" />

              <CandidateResumes
                candidateResumes={candidateResumes}
                candidateId={candidateId}
                setCandidateResumes={setCandidateResumes}
              />
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </>
  );
};
export default MyCoverLetterComponent;
