import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { environment } from "../../../environments/environment";
import { AdminCandidateSearchRespond } from "../../../models/admin/AdminCandidateSearch";
interface IAdminCandidateSearchDetailsProps {
  adminSearchCandidateetails: AdminCandidateSearchRespond[] | null;
  handleDisplayModeChangeWithProfile: (
    diaplaymode: number,
    profileUrl: string
  ) => void;
}

const AdminCandidateSearchDetails: React.FC<IAdminCandidateSearchDetailsProps> = (
  props
) => {
  const {
    adminSearchCandidateetails,
    handleDisplayModeChangeWithProfile,
  } = props;
  const [isOpenVideo2, setIsOpenVideo2] = useState<boolean>(false);
  const [videoName, setVideoName] = useState<string>("");
  if (adminSearchCandidateetails === null) {
    return null;
  }
  if (adminSearchCandidateetails[0].candidateInfo === null) {
    return null;
  }
  const OnHandleDisplayModeChangeWithProfile = (
    diaplaymode: number,
    profileUrl: string
  ) => {
    handleDisplayModeChangeWithProfile(diaplaymode, profileUrl);
  };

  const handlePlayClick = (name: string) => {
    setIsOpenVideo2(true);
    setVideoName(name);
  };

  return (
    <>
      <>
        <div className="resp-tabs-container jobsearch-tabs-content">
          <div className="about" style={{ cursor: "pointer" }}>
            <div className="row">
              <div className="col-sm-4">
                <div className="photo-inner">
                  <div
                    className="caroufredsel_wrapper"
                    style={{
                      display: "block",
                      textAlign: "start",
                      float: "none",
                      position: "relative",
                      inset: "auto",
                      zIndex: "auto",
                      width: "160px",
                      height: "183px",
                      margin: "0px",
                      overflow: "hidden",
                    }}
                  >
                    {adminSearchCandidateetails[0].candidateInfo
                      .profileImage !== null ? (
                      <img
                        src={
                          environment.baseUrl +
                          `/Upload/ProfileImage/${adminSearchCandidateetails[0].candidateInfo.profileImage}`
                        }
                        // src="https://beincard.com//uploads/14/02/1//avatar/PqeYxZpgwLbihQF_n.jpg"
                        height={203}
                        width={150}
                      />
                    ) : (
                      <img
                        height={203}
                        width={150}
                        src={require("./../../../images/profileDefault1.jpg")}
                      ></img>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="col-sm-8"
                onClick={() =>
                  OnHandleDisplayModeChangeWithProfile(
                    4,
                    adminSearchCandidateetails[0].candidateInfo.profileImage !==
                      null
                      ? adminSearchCandidateetails[0].candidateInfo.profileImage
                      : "./../../../images/profileDefault1.jpg"
                  )
                }
              >
                <h1>
                  {adminSearchCandidateetails[0].candidateInfo.candidateName}
                </h1>
                {/* <h3>Consultant &amp; Public Speaker</h3> */}
                <p>
                  {adminSearchCandidateetails[0].candidateInfo.profileSummary}
                </p>
              </div>
            </div>
          </div>
          <div
            className="personal-info"
            style={{
              float: "left",
              marginTop: 30,
              marginLeft: 10,
              padding: "20px 0 0 20px",
            }}
          >
            <div>
              <label style={{ fontWeight: "bolder" }}>Name :</label>
              <span>
                {adminSearchCandidateetails[0].candidateInfo.candidateName}
              </span>
            </div>
            <div>
              <label style={{ fontWeight: "bolder" }}>DOB :</label>
              <span>{adminSearchCandidateetails[0].candidateInfo.dob}</span>
            </div>
            <div>
              <label style={{ fontWeight: "bolder" }}>Address :</label>
              <span>
                {adminSearchCandidateetails[0].candidateInfo.location}
              </span>
            </div>
            <div>
              <label style={{ fontWeight: "bolder" }}>Email :</label>
              <span className="word-wrap">
                {adminSearchCandidateetails[0].candidateInfo.email}
              </span>
            </div>
            <div>
              <label style={{ fontWeight: "bolder" }}>Phone :</label>
              <span>
                {adminSearchCandidateetails[0].candidateInfo.countryCode}
                {adminSearchCandidateetails[0].candidateInfo.phoneNumber}
              </span>
            </div>
            <div>
              <label style={{ fontWeight: "bolder" }}>Resume :</label>
              <span className="word-wrap">
                {adminSearchCandidateetails[0].candidateInfo
                  .candidateRecentResume != null ? (
                  <a
                    href={
                      environment.baseUrl +
                      adminSearchCandidateetails[0].candidateInfo
                        .candidateRecentResume
                    }
                  >
                    Sample Resume
                  </a>
                ) : (
                  <a>No Resume</a>
                )}
              </span>
            </div>
            <div>
              <label style={{ fontWeight: "bolder" }}>Intro video :</label>
              <span className="word-wrap">
                {adminSearchCandidateetails[0].candidateInfo.videoName !=
                null ? (
                  <a
                    onClick={() =>
                      handlePlayClick(
                        adminSearchCandidateetails[0].candidateInfo.videoName
                      )
                    }
                  >
                    Intro Video
                  </a>
                ) : (
                  <a style={{ color: "gray" }}>No Video</a>
                )}
              </span>
            </div>

            <div>
              <label style={{ fontWeight: "bolder" }}>Skill :</label>
              <span>
                {adminSearchCandidateetails[0].candidateInfo.skills !==
                  undefined &&
                adminSearchCandidateetails[0].candidateInfo.skills.length > 0
                  ? adminSearchCandidateetails[0].candidateInfo.skills.map(
                      (data, index) => {
                        return <>{data}</>;
                      }
                    )
                  : null}
              </span>
            </div>
            <div>
              <label style={{ fontWeight: "bolder" }}>Experience :</label>
              <span>
                {adminSearchCandidateetails[0].candidateInfo.expdata !==
                  undefined &&
                adminSearchCandidateetails[0].candidateInfo.expdata.length > 0
                  ? adminSearchCandidateetails[0].candidateInfo.expdata.map(
                      (data, index) => {
                        return (
                          <>
                            {data.jobRole} , {data.orgnization},{" "}
                            {data.experience === "1"
                              ? data.experience + " year"
                              : data.experience + " years"}
                            <br />
                          </>
                        );
                      }
                    )
                  : null}
              </span>
            </div>
          </div>
        </div>
        <Modal
          show={isOpenVideo2}
          onHide={() => {
            setIsOpenVideo2(!isOpenVideo2);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactPlayer
              url={environment.baseUrl + `/Upload/CandidateVideos/${videoName}`}
              width="100%"
              controls={true}
            />
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default AdminCandidateSearchDetails;
