import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { GlobalSpinnerContext } from "./../../context/GlobalSpinner";
import {
  sendEmailOpt,
  verifyCode,
  emailVerifyCode,
  sendPhoneOtp,
} from "./../../apis/misc";
import { reactHookFormServerErrors } from "./../../utils/utility";
import OtpExpiryCouter from "./../GlobalOtpExpiryCouter";
import { toast, ToastContainer } from "react-toastify";
import { notification } from "antd";

interface IOtpVerificationState {
  otp: string;
}

function OtpVerification(props: any) {
  const query = new URLSearchParams(props.location?.search);

  const defaultValues = {
    otp: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    setError,
  } = useForm<IOtpVerificationState>({
    defaultValues,
  });

  const globalSpinner = useContext(GlobalSpinnerContext);

  const onSubmit = (data: any) => {
    //console.log(data);

    globalSpinner.showSpinner();

    emailVerifyCode(
      {},
      `?code=${data["otp"]}&email=${encodeURIComponent(
        query.get("email") || ""
      )}`
    )
      // .then((res: any) => {
      //   //console.log(res.data);

      //   globalSpinner.hideSpinner();
      // });
      // if (res.data.includes("There was an error confirming the code")) {
      //   reactHookFormServerErrors(
      //     {
      //       otp: ["Invalid verification code."],
      //     },
      //     setError
      //   );
      // } else {
      // sendPhoneOtp(
      //   {},
      //   //`?phoneNumber=${encodeURIComponent((query.get("countryCode") || "") + (query.get("mobileNumber") || ""))}`
      //   `?countryCode=${encodeURIComponent(
      //     query.get("countryCode") || ""
      //   )}&phoneNumber=${encodeURIComponent(query.get("mobileNumber") || "")}`
      // )
      // .then((res: any) => {
      //   ////console.log(res.data);
      // })
      //     .catch((err: any) => {
      //       //console.log(err);

      //       globalSpinner.hideSpinner();
      //       let msg = "Invalid code. Try again.";
      //       if (typeof err.response.data === "string") {
      //         msg = err.response.data.toString();
      //       }
      //       reactHookFormServerErrors(
      //         {
      //           //otp: [err.toString()],
      //           //otp: [err.response.data.toString()]
      //           otp: [msg],
      //         },
      //         setError
      //       );
      //     });
      // };

      .then((res: any) => {
        //console.log(res.data);

        globalSpinner.hideSpinner();

        if (res.data.includes("There was an error confirming the code")) {
          reactHookFormServerErrors(
            {
              otp: ["Invalid OTP."],
            },
            setError
          );
        } else {
          sendPhoneOtp(
            {},
            //`?phoneNumber=${encodeURIComponent((query.get("countryCode") || "") + (query.get("mobileNumber") || ""))}`
            `?countryCode=${encodeURIComponent(
              query.get("countryCode") || ""
            )}&phoneNumber=${encodeURIComponent(
              query.get("mobileNumber") || ""
            )}`
          )
            .then((res: any) => {
              ////console.log(res.data);
              if (res.data.includes("There was an error confirming the code")) {
                reactHookFormServerErrors(
                  {
                    otp: ["Invalid OTP."],
                  },
                  setError
                );
              } else {
                props.history.push({
                  pathname: "/otp-verification-mobile",
                  search: `?countryCode=${encodeURIComponent(
                    query.get("countryCode") || ""
                  )}&mobileNumber=${encodeURIComponent(
                    query.get("mobileNumber") || ""
                  )}&email=${encodeURIComponent(query.get("email") || "")}`,
                  state: {
                    countryCode: query.get("countryCode") || "",
                    mobileNumber: query.get("mobileNumber") || "",
                    email: query.get("email") || "",
                    user: props.location.state.user,
                    name: props.location.state.name,
                    userRoleId: props.location.state.userRoleId,
                    password: props.location.state.password,
                  },
                });
              }
            })
            .catch((err: any) => {
              //console.log(err);

              globalSpinner.hideSpinner();
              let msg = "Invalid code. Try again.";
              if (typeof err.response.data === "string") {
                msg = err.response.data.toString();
              }
              reactHookFormServerErrors(
                {
                  //otp: [err.toString()],
                  //otp: [err.response.data.toString()]
                  otp: [msg],
                },
                setError
              );
            });
        }
      })
      .catch((err: any) => {
        //console.log(33, err);
        globalSpinner.hideSpinner();
        toast.error("Invalid OTP");
        reactHookFormServerErrors(
          {
            //otp: [err.toString()],
            otp: [err.response.data.toString()],
          },
          setError
        );
      });

    // .then((res: any) => {
    //   //console.log(res.data);

    //   globalSpinner.hideSpinner();

    //   if (res.data.includes("There was an error confirming the code")) {
    //     reactHookFormServerErrors(
    //       {
    //         otp: ["Invalid verification code."],
    //       },
    //       setError
    //     );
    //   } else {
    //     props.history.push({
    //       pathname: "/otp-verification-mobile",
    //       search: `?countryCode=${encodeURIComponent(
    //         query.get("countryCode") || ""
    //       )}&mobileNumber=${encodeURIComponent(
    //         query.get("mobileNumber") || ""
    //       )}&email=${encodeURIComponent(query.get("email") || "")}`,
    //       state: {
    //         countryCode: query.get("countryCode") || "",
    //         mobileNumber: query.get("mobileNumber") || "",
    //         email: query.get("email") || "",
    //       },
    //     });

    //   }
    // })
    // .catch((err: any) => {
    //   //console.log(err);

    //   globalSpinner.hideSpinner();

    //   reactHookFormServerErrors(
    //     {
    //       otp: [err.toString()],
    //     },
    //     setError
    //   );
    // });
  };

  const [showTimer, setShowTimer] = React.useState("1");

  const resendOtp = (data: any) => {
    //console.log(data);

    globalSpinner.showSpinner();

    sendEmailOpt({}, `?email=${encodeURIComponent(query.get("email") || "")}`)
      .then((res: any) => {
        globalSpinner.hideSpinner();

        if (res.data.includes("There was an error")) {
          //console.log(33, res.data);
          toast.error(res.data);
          // notification["error"]({
          //   message: "Error",
          //   description: res.data,
          //   style: { color: "red" },
          // });
          // reactHookFormServerErrors(
          //   {
          //     otp: ["Invalid verification code."],
          //   },
          //   setError
          // );
        } else {
          if (Number(showTimer)) {
            const c = Number(showTimer) + 1;
            toast.success(" OTP has been successfully sent on your e-mail.");
            // notification["success"]({
            //   message: "Success",
            //   description: "OTP has been successfully sent on your e-mail.",
            //   style: { color: "green" },
            // });
            //console.log(c, "================================");
            setShowTimer(c + "");
          } else {
            setShowTimer("1");
          }
        }
      })
      .catch((err: any) => {
        //console.log(err);

        globalSpinner.hideSpinner();
        //console.log(err);
        toast.error("Something went wrong");
        // notification["error"]({
        //   message: "Error",
        //   description: "Something went wrong",
        //   style: { color: "red", zIndex: 999999 },
        // });
        // reactHookFormServerErrors(
        //   {
        //     otp: [err.toString()],
        //   },
        //   setError
        // );
      });
  };

  return (
    <div className=" container">
      <ToastContainer />
      <div className="row">
        <div className="col-sm-6 desk">
          <div className="login_img">
            <img
              src={require("./../../images/otp_img.jpg")}
              className="img-responsive"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-5 col-lg-offset-1">
          <form
            className="needs-validation"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className="otp_sec registration_sec">
              <div className="sub_heading">
                <h2>Email Verification</h2>
                <div className="otp_text">
                  We have sent an email with verification code to your email
                  address.{" "}
                </div>
                <div className="otp_text1">
                  Please enter the verification code to confirm.{" "}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-n"
                    placeholder="Enter Code"
                    name="otp"
                    ref={register({
                      required: "Field is required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="otp"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />

                  <OtpExpiryCouter setTimer={setShowTimer} timer={showTimer} />
                </div>
                <div className="already_login1">
                  Didn't get the code?{"  "}{" "}
                  <a onClick={resendOtp}> Send again.</a>
                </div>
                <div className="m_t_20">
                  <button type="submit" className="btn btn-success reg_but">
                    Verify
                  </button>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(OtpVerification);
