import React from "react";
import {
  getExperienceList,
  useExperienceDispatcher,
} from "../../../action/general/ExperienceAction";
import {
  getVendorMyProfileSelectBoxList,
  useVendorMyProfileDispatcher,
} from "../../../action/MyProfileVendorAction";
import { experienceRequestModel } from "../../../models/general/Experience";
import MyJobComponent from "./MyJobComponent";

const MyJobContainer = () => {
  const experienceDispatcher = useExperienceDispatcher();

  const myProfileVendorDispatcher = useVendorMyProfileDispatcher();
  React.useEffect(() => {
    if (1) {
      (async () => {
        await getVendorMyProfileSelectBoxList(myProfileVendorDispatcher);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getExperienceList(experienceDispatcher, {
          page: 1,
          pageSize: 30,
          searchTerm: "",
          sortOrder: "",
        } as experienceRequestModel);
      })();
    }
  }, []);

  return (
    <div>
      <MyJobComponent />
    </div>
  );
};

export default MyJobContainer;
