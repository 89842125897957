import { Reducer } from "react";
import React from "react";
// import axios from "axios";
// import { AppUrls } from "../../environments/environment";
import {
  BulkSMSDetailPostModel,
  BulkSMSListRequestModel,
  BulkSMSDetailRequestModel,
  BulkSMSAllListModel,
  BulkSMSDetailListModel,
  BulkSMSRespondModel,
  BulkSMSDeleteRequestModel,
  BulkSMSDeleteResultModel,
  BulkEmailAllListModel,
  BulkEmailDetailListModel,
  BulkEmailListRequestModel,
  BulkEmailDetailRequestModel,
  BulkEmailDetailPostModel,
  BulkEmailRespondModel,
  BulkEmailDeleteRequestModel,
  BulkEmailDeleteResultModel,
  ContactUsAllListModel,
  ContactUsDeleteResultModel,
  ContactUsDeleteRequestModel,
  ContactUsRequestModel,
  MessagesRequestModel,
  MessagesUserAllListModel,
  SmsTemplateListModel,
  EmailTemplateListModel
} from "../../models/admin/AdminCommunication";
import {
  AdminCommunicationDispatcherContext,
  AdminCommunicationStateContext,
  IAdminCommunicationManagementState,
} from "../../context/admin/AdminCommunicationContext";
import * as API from "./../../apis/admin";

//import AuthService from "../../services/AuthService";
// let token = AuthService.accessToken;
// let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";
import { message } from "antd";
export type IAdminCommunicationAction =
  | {
    type: "GET_Contact_Us_LIST";
    contactUsAllList: ContactUsAllListModel;
  }
  | {
    type: "REMOVE_Contact_Us";
    contactUsDeleteResult: ContactUsDeleteResultModel;
  }
  | {
    type: "GET_Bulk_SMS_LIST";
    bulkSMSAllList: BulkSMSAllListModel;
  }
  | {
    type: "GET_Bulk_SMS_Details_LIST";
    bulkSMSDetailList: BulkSMSDetailListModel;
  }
  | {
    type: "SAVE_Bulk_SMS";
    bulkSMSRespond: BulkSMSRespondModel;
  }
  | {
    type: "REMOVE_Bulk_SMS";
    bulkSMSDeleteResult: BulkSMSDeleteResultModel;
  }
  | {
    type: "GET_Bulk_Email_LIST";
    bulkEmailAllList: BulkEmailAllListModel;
  }
  | {
    type: "GET_Bulk_Email_Details_LIST";
    bulkEmailDetailList: BulkEmailDetailListModel;
  }
  | {
    type: "SAVE_Bulk_Email";
    bulkEmailRespond: BulkEmailRespondModel;
  }
  | {
    type: "REMOVE_Bulk_Email";
    bulkEmailDeleteResult: BulkEmailDeleteResultModel;
  }
  | {
    type: "GET_Messages_User_LIST";
    messagesUserAllList: MessagesUserAllListModel;
  }
  | {
    type: "GET_SMS_Template_LIST";
    smsTemplateList: SmsTemplateListModel;
  }
  | {
    type: "GET_Email_Template_LIST";
    emailTemplateList: EmailTemplateListModel;
  }
  | {
    type: "SAVE_Email";
    saveEmailRespond: BulkEmailRespondModel;
  }

export const adminCommunicationReducer: Reducer<
  IAdminCommunicationManagementState,
  IAdminCommunicationAction
> = (draft, action): IAdminCommunicationManagementState => {
  switch (action.type) {
    case "GET_Contact_Us_LIST":
      draft.contactUsAllList = action.contactUsAllList;
      return draft;
    case "REMOVE_Contact_Us":
      draft.contactUsDeleteResult = action.contactUsDeleteResult;
      return draft;
    case "GET_Bulk_SMS_LIST":
      draft.bulkSMSAllList = action.bulkSMSAllList;
      return draft;
    case "GET_Bulk_SMS_Details_LIST":
      draft.bulkSMSDetailList = action.bulkSMSDetailList;
      return draft;
    case "SAVE_Bulk_SMS":
      draft.bulkSMSRespond = action.bulkSMSRespond;
      return draft;
    case "REMOVE_Bulk_SMS":
      draft.bulkSMSDeleteResult = action.bulkSMSDeleteResult;
      return draft;
    case "GET_Bulk_Email_LIST":
      draft.bulkEmailAllList = action.bulkEmailAllList;
      return draft;
    case "GET_Bulk_Email_Details_LIST":
      draft.bulkEmailDetailList = action.bulkEmailDetailList;
      return draft;
    case "SAVE_Bulk_Email":
      draft.bulkEmailRespond = action.bulkEmailRespond;
      return draft;
    case "REMOVE_Bulk_Email":
      draft.bulkEmailDeleteResult = action.bulkEmailDeleteResult;
      return draft;
    case "GET_Messages_User_LIST":
      draft.messagesUserAllList = action.messagesUserAllList;
      return draft;
    case "GET_SMS_Template_LIST":
      draft.smsTemplateList = action.smsTemplateList;
      return draft;
    case "GET_Email_Template_LIST":
      draft.emailTemplateList = action.emailTemplateList;
      return draft;
    case "SAVE_Email":
      draft.saveEmailRespond = action.saveEmailRespond;
      return draft;
  }
};


export const useAdminCommunicationDispatcher = (): React.Dispatch<IAdminCommunicationAction> => {
  const adminCommunicationDispatcher = React.useContext(AdminCommunicationDispatcherContext);
  if (!adminCommunicationDispatcher) {
    throw new Error(
      "You have to provide the AdminCommunication dispatcher using the AdminCommunicationDispatcherContext.Provider in a parent component."
    );
  }
  return adminCommunicationDispatcher;
};

export const useAdminCommunicationContext = (): IAdminCommunicationManagementState => {
  const adminCommunicationContext = React.useContext(AdminCommunicationStateContext);
  if (!adminCommunicationContext)
    throw new Error(
      "You have to provide the adminCommunication context using the AdminCommunicationStateContext.Provider in a parent component."
    );
  return adminCommunicationContext;
};


export const getBulkSMSAllList = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: BulkSMSListRequestModel
) => {
  try {
    //message.loading("please wait..");
    API.getBulkSMSList(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "GET_Bulk_SMS_LIST", bulkSMSAllList: res.data });
      // res.data.isSuccess
      //   ? message.success("SMS conversation fetched successfully")
      //   : message.error(res.data.message);
    });
  } catch (e) { }
};

export const getBulkSMSDetailList = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: BulkSMSDetailRequestModel
) => {
  try {
    // message.loading("please wait..");
    API.getBulkSMSDetailList(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "GET_Bulk_SMS_Details_LIST", bulkSMSDetailList: res.data });
      // res.data.isSuccess
      //   ? message.success("SMS conversation detail fetched successfully")
      //   : message.error(res.data.message);
    });
  } catch (e) { }
};

export const saveBulkSMSConversation = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: any
) => {
  try {
    // message.loading("please wait..");
    API.saveBulkSMSList(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "SAVE_Bulk_SMS", bulkSMSRespond: res.data });
      // res.data.isSuccess
      //   ? message.success("SMS conversation saved successfully")
      //   : message.error(res.data.message);
    });
  } catch (e) { }
};

export const saveEmail = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: any
) => {
  try {
    // message.loading("please wait..");
    API.saveEmail(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "SAVE_Email", saveEmailRespond: res.data });
      // res.data.isSuccess
      //   ? message.success("SMS conversation saved successfully")
      //   : message.error(res.data.message);
    });
  } catch (e) { }
};

export const removeBulkSMSConversation = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: BulkSMSDeleteRequestModel
) => {
  try {
    //message.loading("please wait..");
    API.deleteBulkSMSListByIds(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "REMOVE_Bulk_SMS", bulkSMSDeleteResult: res.data });
      // res.data.isSuccess
      //   ? message.success("SMS conversation deleted successfully")
      //   : message.error(res.data.message);
    });
  } catch (e) { }
};

//Email api
export const getBulkEmailAllList = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: BulkEmailListRequestModel
) => {
  try {
    // message.loading("please wait..");
    API.getBulkEmailList(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "GET_Bulk_Email_LIST", bulkEmailAllList: res.data });
      // res.data.isSuccess
      //   ? message.success("Email conversation fetched successfully")
      //   : message.error(res.data.message);
    });
  } catch (e) { }
};

export const getBulkEmailDetailList = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: BulkEmailDetailRequestModel
) => {
  try {
    // message.loading("please wait..");
    API.getBulkEmailDetailList(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "GET_Bulk_Email_Details_LIST", bulkEmailDetailList: res.data });
      // res.data.isSuccess
      //   ? message.success("Email conversation detail fetched successfully")
      //   : message.error(res.data.message);
    });
  } catch (e) { }
};

export const saveBulkEmailConversation = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  //query: BulkEmailDetailPostModel
  query: any
) => {
  try {
    //message.loading("please wait..");
    API.saveBulkEmailList(query).then((res) => {
      dispatcher({ type: "SAVE_Bulk_Email", bulkEmailRespond: res.data });
      // res.data.isSuccess
      //   ? message.success("Email conversation saved successfully")
      //   : message.error(res.data.message);
    });
  } catch (e) { }
};

export const removeBulkEmailConversation = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: BulkEmailDeleteRequestModel
) => {
  try {
    //message.loading("please wait..");
    API.deleteBulkEmailListByIds(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "REMOVE_Bulk_Email", bulkEmailDeleteResult: res.data });
      // res.data.isSuccess
      //   ? message.success("Email conversation deleted successfully")
      //   : message.error(res.data.message);
    });
  } catch (e) { }
};

//contact us api


export const getContactUsList = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: ContactUsRequestModel
) => {
  try {
    API.getContactUsList(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "GET_Contact_Us_LIST", contactUsAllList: res.data });
    });
  } catch (e) { }
};

export const removeContactUs = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: ContactUsDeleteRequestModel
) => {
  try {
    API.deleteContactUsByIds(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "REMOVE_Contact_Us", contactUsDeleteResult: res.data });
    });
  } catch (e) { }
};

export const getMessageUserList = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: MessagesRequestModel
) => {
  try {
    API.getCommunicationUserList(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "GET_Messages_User_LIST", messagesUserAllList: res.data });
    });
  } catch (e) { }
};


//Bulk SMS Template
export const getSMSTemplateList = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: any
) => {
  try {
    API.getBulkSMSTemplates(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "GET_SMS_Template_LIST", smsTemplateList: res.data });
    });
  } catch (e) { }
};

//Bulk SMS Template
export const getEmailTemplateList = async (
  dispatcher: React.Dispatch<IAdminCommunicationAction>,
  query: any
) => {
  try {
    API.getBulkEmailTemplates(JSON.stringify(query)).then((res) => {
      dispatcher({ type: "GET_Email_Template_LIST", emailTemplateList: res.data });
    });
  } catch (e) { }
};