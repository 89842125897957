import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PikadayDatepicker from "./components/PikadayDatepicker";
import SelectOption from "./components/SelectOption";
import SelectMulti from "./components/SelectMulti";
import _ from "lodash";
import {
  saveCandidate,
  saveCandidateLanguageMaps,
} from "./../../../apis/candidate";
import { toast, ToastContainer } from "react-toastify";
import { GlobalSpinnerContext } from "./../../../context/GlobalSpinner";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import { pascal2Camel, camel2Pascal } from "./../../../utils/utility";
import { Modal } from "react-bootstrap";
import IsComponentDisabled from "./../../components/IsComponentDisabled";

type objectIndexing = {
  [key: string]: any;
};

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IPersonelInfoState {
  rowId: string;
  name: string;
  dob: number;
  nationality: string;
  position: string;
  gender: string;
  maritalStatus: string;
  languages: string[];
  summary: string;
}

function PersonelInfo(props: any) {
  const {
    myProfile,
    userId,
    candidateId,
    candidateState,
    candidateLanguageMaps,
    getCandidateLanguageMaps,
    getCandidates,
  } = props;

  //console.log(candidateLanguageMaps);

  let genders: ReactSelectOption[] = [];

  let maritalStatuss: ReactSelectOption[] = [];

  let nationalities: ReactSelectOption[] = [];

  let languageOpts: ReactSelectOption[] = [];

  let positions: ReactSelectOption[] = [];

  if (!_.isEmpty(myProfile)) {
    languageOpts = myProfile.languages.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });

    genders = myProfile.genders.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });

    maritalStatuss = myProfile.martialStatus.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });

    nationalities = myProfile.countries.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });

    positions = myProfile.jobRoles.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  let fifteenYearsAgo = new Date();
  let fifteenYears = fifteenYearsAgo.setFullYear(
    fifteenYearsAgo.getFullYear() - 15
  );

  let defaultValues = {
    rowId: "0",
    name: "",
    dob: 0,
    nationality: "",
    position: "",
    gender: "",
    maritalStatus: "",
    languages: [],
    summary: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<IPersonelInfoState>({
    defaultValues,
  });

  useEffect(() => {
    ////console.log(555555555555555, candidateState);
    if (candidateState.data.length > 0) {
      setValue("rowId", candidateState.data[0]["rowId"]);
      setValue("name", candidateState.data[0]["firstName"]);
      setValue(
        "dob",
        moment(candidateState.data[0]["dob"], "DD/MM/YYYY").toDate().getTime()
      );
      setValue("nationality", candidateState.data[0]["countryId"] + "");
      setValue(
        "position",
        candidateState.data[0]["positionApplyingFor"]
          ? candidateState.data[0]["positionApplyingFor"]
          : ""
      );
      setValue("gender", candidateState.data[0]["genderId"] + "");
      setValue("maritalStatus", candidateState.data[0]["martialStatusId"] + "");
      setValue(
        "languages",
        candidateLanguageMaps.map((e: any) => `${e["languageId"]}`)
      );
      setValue(
        "summary",
        candidateState.data[0]["profileSummary"] == null
          ? ""
          : candidateState.data[0]["profileSummary"] + ""
      );
    }
  }, [reset, candidateState.data.length, candidateLanguageMaps.length]);

  ////console.log(getValues());

  const globalSpinner = useContext(GlobalSpinnerContext);

  const onSubmit = (data: any) => {
    ////console.log(data);

    if (!disabled) {
      let _data1: objectIndexing = {};
      let _data2: objectIndexing = {};

      if (candidateState.data.length > 0) {
        ////console.log(candidateState.data[0]);
        _data1 = candidateState.data[0];

        _data1["rowId"] = data["rowId"];
        _data1["candidateId"] = Number(candidateId);
        _data1["dob"] = moment(new Date(data["dob"])).format("DD/MM/YYYY");
        _data1["genderId"] = data["gender"];
        _data1["positionApplyingFor"] = data["position"];
        _data1["martialStatusId"] =
          data["maritalStatus"].includes("sel") |
          data["maritalStatus"].includes("null")
            ? null
            : data["maritalStatus"];
        // _data1["firstName"] = data["name"];
        _data1["firstName"] = _.startCase(_.toLower(data["name"]));
        _data1["CountryId"] = Number(data["nationality"]);
        _data1["ProfileSummary"] = data["summary"];

        ////console.log(_data1);

        ////console.log(data.languages);

        const _lans = data.languages.map((e: any) => Number(e)) || [];

        ////console.log(_lans);

        globalSpinner.showSpinner();

        saveCandidateLanguageMaps({
          rowId: 0,
          candidateLanguageMapId: 0,
          candidateId: Number(candidateId),
          languageId: _lans,
          IsActive: true,
        })
          .then((res) => {
            if (res.data.isSuccess) {
              saveCandidate(_data1)
                .then((res: any) => {
                  //console.log(res.data);
                  globalSpinner.hideSpinner();
                  if (res.data.isSuccess) {
                    getCandidates({
                      CandidateId: Number(candidateId),
                      Page: 1,
                      PageSize: 10,
                      SearchTerm: "string",
                      SortOrder: "string",
                      ShowInactive: false,
                    });
                    getCandidateLanguageMaps({
                      CandidateId: Number(candidateId),
                      Page: 1,
                      PageSize: 10,
                      SearchTerm: "string",
                      SortOrder: "string",
                      ShowInactive: false,
                    });
                    toast.success("Personal Info details are saved.");
                  } else {
                    //console.log(res.data);
                    globalSpinner.hideSpinner();
                    toast.error(res.data.message);
                  }
                })
                .catch((err: any) => {
                  //console.log(err);
                  globalSpinner.hideSpinner();
                  toast.error(err.toString());
                });
            } else {
              //console.log(res.data);
              globalSpinner.hideSpinner();
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            //console.log(err);
            globalSpinner.hideSpinner();
            toast.error(err.toString());
          });
      }
    }
  };

  const [disabled, setDisabled] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <ToastContainer />
      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-0">
        <span className="resp-arrow" />
        Personal Info
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-0"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Personal Info</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div>
          <form
            className="personal_details"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <input
              type="number"
              name="rowId"
              style={{ display: "none" }}
              disabled={disabled}
              ref={register}
            />

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Name</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <input
                        type="text"
                        name="name"
                        className={"form-control"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          required: "Name is required.",
                          pattern: {
                            value: /^[a-zA-Z ]*$/,
                            message:
                              "Name must have words only with spaces between them.",
                          },
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Gender</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <span className="select-wrapper-sec">
                        <Controller
                          rules={{
                            required: "Gender is required",
                            validate: function (value) {
                              // //console.log(value);
                              return Number(value)
                                ? undefined
                                : "Gender is required";
                            },
                          }}
                          control={control}
                          name="gender"
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={genders}
                              disabled={disabled}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="gender"
                      render={({ message }) => (
                        <div className="profile_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> DOB (dd/mm/yyyy)</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <Controller
                        rules={{
                          validate: (value) => {
                            // return value < Date.now()
                            //   ? undefined
                            //   : "DOB cannot be a future date.";

                            if (value > Date.now()) {
                              return "DOB cannot be a future date.";
                            }
                            //  else if (value > fifteenYears) {
                            //   return "Your age should be gratter than 15";
                            // }
                            else {
                              return undefined;
                            }
                          },
                        }}
                        control={control}
                        name="dob"
                        render={({ onChange, onBlur, value, name }) => {
                          ////console.log(value);
                          return (
                            <PikadayDatepicker
                              disabled={disabled}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          );
                        }}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="dob"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label> Marital Status</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <span className="select-wrapper-sec">
                        <Controller
                          // rules={{
                          //   required: "Marital Status is required",
                          //   validate: function (value) {
                          //     //console.log(value);
                          //     return Number(value)
                          //       ? undefined
                          //       : "Marital Status is required";
                          //   },
                          // }}
                          control={control}
                          name="maritalStatus"
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={maritalStatuss}
                              disabled={disabled}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="maritalStatus"
                      render={({ message }) => (
                        <div className="profile_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Country</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <span className="select-wrapper-sec">
                        <Controller
                          rules={{
                            required: "Country is required",
                            validate: function (value) {
                              //console.log(value);
                              return Number(value)
                                ? undefined
                                : "Country is required";
                            },
                          }}
                          control={control}
                          name="nationality"
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={nationalities}
                              disabled={disabled}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="nationality"
                      render={({ message }) => (
                        <div className="profile_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Languages Known</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <Controller
                        control={control}
                        name="languages"
                        rules={{
                          validate: (value) => {
                            return value.length === 0
                              ? "Languages Known is required"
                              : undefined;
                          },
                        }}
                        render={({ onChange, onBlur, value, name }) => {
                          // //console.log(value);
                          return (
                            <SelectMulti
                              name={name}
                              values={languageOpts}
                              disabled={disabled}
                              value={value ? value : []}
                              onChange={onChange}
                              nameForLablel={""}
                            />
                          );
                        }}
                      />,
                      disabled
                    )}

                    <ErrorMessage
                      errors={errors}
                      name="languages"
                      render={({ message }) => (
                        <div className="profile_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}> Job Title</label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <input
                        type="text"
                        name="position"
                        className={"form-control"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          required: "Job Title is required",
                        })}
                      />,
                      disabled
                    )}
                    {/* <span className="select-wrapper-sec">
                      <Controller
                        rules={{
                          validate: (value) => {
                            return value ? undefined : "Position applying for cannot be blank.";
                          }
                        }}
                        control={control}
                        name="position"
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption values={positions} disabled={disabled} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                        )}
                      />
                    </span> */}
                    <ErrorMessage
                      errors={errors}
                      name="position"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <div className="col-sm-5">
                    <label className={"required"}>
                      {" "}
                      Profile Summary/Objective
                    </label>
                  </div>
                  <div className="col-sm-7">
                    {IsComponentDisabled(
                      <textarea
                        name="summary"
                        className={"form-control form-contro11"}
                        placeholder="Type here"
                        disabled={disabled}
                        ref={register({
                          required:
                            "Profile Summary/Objective cannot be blank.",
                          validate: (value) => {
                            //exclude  start and end white-space
                            value = value.replace(/(^\s*)|(\s*$)/gi, "");
                            //convert 2 or more spaces to 1
                            value = value.replace(/[ ]{2,}/gi, " ");
                            // exclude newline with a start spacing
                            value = value.replace(/\n /, "\n");

                            if (value.split(" ").length < 15) {
                              return "Minimum 15 words are required in responsibilities.";
                            } else if (value.split(" ").length > 40) {
                              return "Maximum words: 40";
                            } else {
                              return undefined;
                            }
                          },
                        })}
                      />,
                      disabled
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="summary"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="clearfix" />
            <button
              type="submit"
              className="btn submit_btn"
              disabled={disabled}
            >
              Save
            </button>
          </form>
        </div>
      </div>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Update your account password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="email">New Password</label>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Type here"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Re-enter new password</label>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Type here"
                />
              </div>
              <div className="profile_cons1">
                Note: Password should be 8 digits long with at least one
                Cappital letter, one small letter and a special character.
              </div>
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
              Verify
            </button>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="clearfix" />
        </form>
      </Modal>
    </React.Fragment>
  );
}

export default PersonelInfo;
