import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  CategorySaveRespondModel,
  TitleSaveRespondModel,
} from "../../models/general/Category";
import {
  categoryReducer,
  ICategoryAction,
} from "../../action/general/CategoryAction";

export const CategoryDispatcherContext = React.createContext<React.Dispatch<ICategoryAction> | null>(
  null
);
export const CategoryStateContext = React.createContext<ICategoryManagementState | null>(
  null
);

export interface ICategoryManagementProps extends RouteComponentProps<any> {}

export interface ICategoryManagementState {
  CategorySaveRespondModel: CategorySaveRespondModel;
  TitleSaveRespondModel: TitleSaveRespondModel;
}

export const initialCategoryManagementState = {
  CategorySaveRespondModel: {} as CategorySaveRespondModel,
  TitleSaveRespondModel: {} as TitleSaveRespondModel,
} as ICategoryManagementState;

export const CategoryContextProvider: React.FC = ({ children }) => {
  const [categoryState, dispatcher] = useImmerReducer(
    categoryReducer,
    initialCategoryManagementState
  );

  return (
    <CategoryDispatcherContext.Provider value={dispatcher}>
      <CategoryStateContext.Provider value={categoryState}>
        {children}
      </CategoryStateContext.Provider>
    </CategoryDispatcherContext.Provider>
  );
};
