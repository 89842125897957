import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  CareerTipsApprovalRequestModel,
  CareerTipsApproval,
  CareerTipsApprovalViewModel,
  CareerTipsApprovalWithOutTokenViewModel,
  CareerTipsApprovalSaveRequestModel,
  CareerTipsApprovalSaveRespondModel,
  CareerTipsEditRequestModel,
  CareerTipsResultModel,
  CareerTipsGetDataWithId,
  CareerTipsSelectBoxDataViewModel,
  CareerTipsCategoryViewModel,
  CareerTipsByCategoryRequestModel,
} from "../../models/admin/CareerTipsApproval";
import {
  ICareerTipsApprovalManagementState,
  CareerTipsApprovalDispatcherContext,
  CareerTipsApprovalStateContext,
} from "../../context/admin/CareerTipsApprovalContext";
import { message } from "antd";
import {
  getApproveOrRejectRequestModel,
  getApproveOrRejectResultModel,
  GoogleSlideUrlList,
} from "../../models/admin/VendorApproval";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type ICareerTipsApprovalAction =
  | {
      type: "CAREERTIPSAPPROVAL_LIST";
      careerTipsApproval: CareerTipsApprovalViewModel;
    }
  | {
      type: "CAREERTIPSBYCATEGORY_LIST";
      careerTipsByCategory: CareerTipsApprovalWithOutTokenViewModel;
    }
  | {
      type: "CAREERTIPSCATEGORY_LIST";
      careerTipsCategory: CareerTipsCategoryViewModel;
    }
  | {
      type: "CAREERTIPSAPPROVAL_WITHOUTTOKEN_LIST";
      careerTipsApprovalWithOutToken: CareerTipsApprovalWithOutTokenViewModel;
    }
  | {
      type: "CAREERTIPS_SELECTBOX_LIST";
      CareerTipsSelectBoxDataViewModel: CareerTipsSelectBoxDataViewModel;
    }
  | {
      type: "GET_CAREERTIPS";
      CareerTipsResultModel: CareerTipsResultModel;
    }
  | {
      type: "EDIT_CAREERTIPS";
      careerTipsApprovalSaveRespond: CareerTipsApprovalSaveRespondModel;
    }
  | {
      type: "SET_CAREERTIPS_STATUS";
      careerTipsApprovalSaveRespond: CareerTipsApprovalSaveRespondModel;
    }
  | {
      type: "GET_ALL_APPROVED_REJECTED_LIST";
      approveOrRejectResult: getApproveOrRejectResultModel;
    }
  | {
      type: "GET_ALL_GOOGLESLIDE_URL";
      googleSlideUrlList: GoogleSlideUrlList;
    }
  | {
      type: "SAVE_UPDATE_GOOGLE_SLIDE_URL";
      googleSlideUrlSave: CareerTipsApprovalSaveRespondModel;
    };

export const careerTipsApprovalReducer: Reducer<
  ICareerTipsApprovalManagementState,
  ICareerTipsApprovalAction
> = (draft, action): ICareerTipsApprovalManagementState => {
  switch (action.type) {
    case "CAREERTIPSAPPROVAL_LIST":
      draft.careerTipsApproval = action.careerTipsApproval;
      return draft;
    case "CAREERTIPSBYCATEGORY_LIST":
      draft.careerTipsByCategory = action.careerTipsByCategory;
      return draft;
    case "CAREERTIPSCATEGORY_LIST":
      draft.careerTipsCategory = action.careerTipsCategory;
      return draft;
    case "CAREERTIPSAPPROVAL_WITHOUTTOKEN_LIST":
      draft.careerTipsApprovalWithOutToken =
        action.careerTipsApprovalWithOutToken;
      return draft;
    case "GET_CAREERTIPS":
      draft.CareerTipsResultModel = action.CareerTipsResultModel;
      return draft;
    case "EDIT_CAREERTIPS":
      draft.careerTipsApprovalSaveRespond =
        action.careerTipsApprovalSaveRespond;
      return draft;
    case "SET_CAREERTIPS_STATUS":
      draft.careerTipsApprovalSaveRespond =
        action.careerTipsApprovalSaveRespond;
      return draft;
    case "CAREERTIPS_SELECTBOX_LIST":
      draft.CareerTipsSelectBoxDataViewModel =
        action.CareerTipsSelectBoxDataViewModel;
      return draft;
    case "GET_ALL_APPROVED_REJECTED_LIST":
      draft.approveOrRejectResult = action.approveOrRejectResult;
      return draft;
    case "GET_ALL_GOOGLESLIDE_URL":
      draft.googleSlideUrlList = action.googleSlideUrlList;
      return draft;
    case "SAVE_UPDATE_GOOGLE_SLIDE_URL":
      draft.googleSlideUrlSave = action.googleSlideUrlSave;
      return draft;
  }
};

export const useCareerTipsApprovalDispatcher = (): React.Dispatch<ICareerTipsApprovalAction> => {
  const careerTipsApprovalDispatcher = React.useContext(
    CareerTipsApprovalDispatcherContext
  );
  if (!careerTipsApprovalDispatcher) {
    throw new Error(
      "You have to provide the CareerTipsApproval dispatcher using theCareerTipsApprovalDispatcherContext.Provider in a parent component."
    );
  }
  return careerTipsApprovalDispatcher;
};

export const useCareerTipsApprovalContext = (): ICareerTipsApprovalManagementState => {
  const careerTipsApprovalContext = React.useContext(
    CareerTipsApprovalStateContext
  );
  if (!careerTipsApprovalContext)
    throw new Error(
      "You have to provide the careerTipsApproval context using the CareerTipsApprovalStateContext.Provider in a parent component."
    );
  return careerTipsApprovalContext;
};

export const getCareerTipsApprovalList = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>,
  query: CareerTipsApprovalRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetCareerTipsList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "CAREERTIPSAPPROVAL_LIST",
        careerTipsApproval: res.data,
      });
    });
  } catch (e) {}
};

export const getCareerTipsCategoryListWithOutToken = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>,
  query: CareerTipsApprovalRequestModel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const url = AppUrls.GetCareerTipsCategoryListWithOutToken;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "CAREERTIPSCATEGORY_LIST",
        careerTipsCategory: res.data,
      });
    });
  } catch (e) {}
};

export const getCareerTipsApprovalListWithOutToken = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>,
  query: CareerTipsApprovalRequestModel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const url = AppUrls.GetCareerTipsListWithOutToken;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "CAREERTIPSAPPROVAL_WITHOUTTOKEN_LIST",
        careerTipsApprovalWithOutToken: res.data,
      });
    });
  } catch (e) {}
};

export const getCareerTipsByCategoryWithOutToken = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>,
  query: CareerTipsByCategoryRequestModel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const url = AppUrls.GetCareerTipsListByCategoryWithOutToken;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "CAREERTIPSBYCATEGORY_LIST",
        careerTipsByCategory: res.data,
      });
    });
  } catch (e) {}
};

export const setCareerTipsStatus = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>,
  query: CareerTipsApprovalSaveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditCareerTipsStatus;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_CAREERTIPS_STATUS",
        careerTipsApprovalSaveRespond: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Career tips status updated.")
          : message.error(res.data.message)
        : message.error(" some error happend on updating career tips status.");
    });
  } catch (e) {}
};

export const editCareerTips = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>,
  query: CareerTipsEditRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditCareerTips;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_CAREERTIPS",
        careerTipsApprovalSaveRespond: res.data,
      });

      res.status == 200
        ? message.success("Career tip is updated.")
        : message.error(
            "There are some error happend on updating career tips."
          );
    });
  } catch (e) {}
};

export const getCareerTips = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>,
  query: CareerTipsGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetCareerTips;
    if (query.rowId != 0) {
      axios
        .post(url, JSON.stringify(query), { headers: header })
        .then((res) => {
          dispatcher({
            type: "GET_CAREERTIPS",
            CareerTipsResultModel: res.data,
          });
        });
    } else {
      dispatcher({
        type: "GET_CAREERTIPS",
        CareerTipsResultModel: {} as CareerTipsResultModel,
      });
    }
  } catch (e) {}
};

export const getCareerTipsSelectBoxList = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>
  //token: string
) => {
  try {
    //  if (token?.startsWith(`"`)) {
    //   token = token.substring(1);
    //   token = token.slice(0, -1);
    // }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      //Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetCareerTipsSelectBoxData;

    axios.post(url, JSON.stringify({}), { headers: header }).then((res) => {
      dispatcher({
        type: "CAREERTIPS_SELECTBOX_LIST",
        CareerTipsSelectBoxDataViewModel: res.data,
      });
    });
  } catch (e) {}
};

export const getCareerTipsApprovalOrRejectList = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>,
  query: getApproveOrRejectRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllApproveOrReject;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_APPROVED_REJECTED_LIST",
        approveOrRejectResult: res.data,
      });
    });
  } catch (e) {}
};

export const getAllGoogleSlideUrl = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllGoogleSlideUrl;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ALL_GOOGLESLIDE_URL",
        googleSlideUrlList: res.data,
      });
    });
  } catch (e) {}
};

export const getAllGoogleSlideUrlSave = async (
  dispatcher: React.Dispatch<ICareerTipsApprovalAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveGoogleSlideUrl;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SAVE_UPDATE_GOOGLE_SLIDE_URL",
        googleSlideUrlSave: res.data,
      });
    });
  } catch (e) {}
};
