import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { BookMarkCandidateViewResponseModel, jobBookMarkSaveRespondModel, jobBookMarkViewModel } from "../../models/candidate/BookMarkedJob";
import { IJobBookMarkAction, jobBookMarkReducer } from "../../action/candidate/JobBookMarkAction";



export const JobBookMarkDispatcherContext = React.createContext<React.Dispatch<IJobBookMarkAction> | null>(null);
export const JobBookMarkStateContext = React.createContext<IJobBookMarkManagementState | null>(null);

export interface IJobBookMarkManagementProps extends RouteComponentProps<any> { }

export interface IJobBookMarkManagementState {
    visible:boolean;
    value:number;
    jobBookMark:BookMarkCandidateViewResponseModel
    jobBookMarkSaveRespond:jobBookMarkSaveRespondModel
}

export const initialJobBookMarkManagementState = {
    visible:false,
    value:0,
    jobBookMark:{} as BookMarkCandidateViewResponseModel,
    jobBookMarkSaveRespond:{ } as jobBookMarkSaveRespondModel,
} as IJobBookMarkManagementState;

export const JobBookMarkContextProvider: React.FC = ({ children }) => {
    const [jobBookMarkState, dispatcher] = useImmerReducer(jobBookMarkReducer, initialJobBookMarkManagementState);

    return (
        <JobBookMarkDispatcherContext.Provider value={dispatcher}>
            <JobBookMarkStateContext.Provider value={jobBookMarkState}>
                {children}
            </JobBookMarkStateContext.Provider>
        </JobBookMarkDispatcherContext.Provider>
    )
}