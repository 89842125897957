import React from "react";
import JobSearchVendorComponent from "./JobSearchVendorComponent";

const JobSearchVendorContainer = () => {
  return (
    <>
      <JobSearchVendorComponent />
    </>
  );
};

export default JobSearchVendorContainer;
