import React, { useReducer, createContext, useContext } from "react";
import * as API from "./../../apis/candidate";

const initialState1 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateExperienceContext = createContext({
  candidateExperienceState: initialState1,
  getCandidateExperiences: (data?: any) => {},
});

const reducer1 = (candidateExperienceState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState1, loading: true };
    case "FETCHED":
      return { ...initialState1, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState1, loading: false, error: action.payload };
    default:
      return candidateExperienceState;
  }
};

export const CandidateExperienceContextProvider = (props: any) => {
  const [candidateExperienceState, dispatch] = useReducer(
    reducer1,
    initialState1
  );

  const getCandidateExperiences = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateExperiences(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateExperienceContext.Provider
      value={{
        candidateExperienceState,
        getCandidateExperiences,
      }}
    >
      {props.children}
    </CandidateExperienceContext.Provider>
  );
};

export const useCandidateExperienceContext = () => {
  const candidateExperienceContext = useContext(CandidateExperienceContext);
  return candidateExperienceContext;
};

const initialState2 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateContext = createContext({
  candidateState: initialState2,
  getCandidates: (data?: any) => {},
});

const reducer2 = (candidateState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState2, loading: true };
    case "FETCHED":
      return { ...initialState2, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState2, loading: false, error: action.payload };
    default:
      return candidateState;
  }
};

export const CandidateContextProvider = (props: any) => {
  const [candidateState, dispatch] = useReducer(reducer2, initialState2);

  const getCandidates = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidates(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateContext.Provider
      value={{
        candidateState,
        getCandidates,
      }}
    >
      {props.children}
    </CandidateContext.Provider>
  );
};

export const useCandidateContext = () => {
  const candidateContext = useContext(CandidateContext);
  return candidateContext;
};

const initialState3 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateRelativesContext = createContext({
  candidateRelativesState: initialState3,
  getCandidateRelatives: (data?: any) => {},
});

const reducer3 = (candidateRelativesState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState3, loading: true };
    case "FETCHED":
      return { ...initialState3, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState3, loading: false, error: action.payload };
    default:
      return candidateRelativesState;
  }
};

export const CandidateRelativesContextProvider = (props: any) => {
  const [candidateRelativesState, dispatch] = useReducer(
    reducer3,
    initialState3
  );

  const getCandidateRelatives = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateRelatives(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateRelativesContext.Provider
      value={{
        candidateRelativesState,
        getCandidateRelatives,
      }}
    >
      {props.children}
    </CandidateRelativesContext.Provider>
  );
};

export const useCandidateRelativesContext = () => {
  const candidateRelativesContext = useContext(CandidateRelativesContext);
  return candidateRelativesContext;
};

const initialState4 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateSkillsContext = createContext({
  candidateSkillsState: initialState4,
  getCandidateSkills: (data?: any) => {},
});

const reducer4 = (candidateSkillsState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState4, loading: true };
    case "FETCHED":
      return { ...initialState4, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState4, loading: false, error: action.payload };
    default:
      return candidateSkillsState;
  }
};

export const CandidateSkillsContextProvider = (props: any) => {
  const [candidateSkillsState, dispatch] = useReducer(reducer4, initialState4);

  const getCandidateSkills = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateSkills(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateSkillsContext.Provider
      value={{
        candidateSkillsState,
        getCandidateSkills,
      }}
    >
      {props.children}
    </CandidateSkillsContext.Provider>
  );
};

export const useCandidateSkillsContext = () => {
  const candidateSkillsContext = useContext(CandidateSkillsContext);
  return candidateSkillsContext;
};

const initialState5 = {
  data: [],
  loading: false,
  error: null,
};

export const TrainingContext = createContext({
  trainingState: initialState5,
  getTrainings: (data?: any) => {},
});

const reducer5 = (trainingState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState5, loading: true };
    case "FETCHED":
      return { ...initialState5, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState5, loading: false, error: action.payload };
    default:
      return trainingState;
  }
};

export const TrainingContextProvider = (props: any) => {
  const [trainingState, dispatch] = useReducer(reducer5, initialState5);

  const getTrainings = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getTrainings(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <TrainingContext.Provider
      value={{
        trainingState,
        getTrainings,
      }}
    >
      {props.children}
    </TrainingContext.Provider>
  );
};

export const useTrainingContext = () => {
  const trainingContext = useContext(TrainingContext);
  return trainingContext;
};

const initialState6 = {
  data: [],
  loading: false,
  error: null,
};

export const BankDetailsContext = createContext({
  bankDetailsState: initialState6,
  getBankDetails: (data?: any) => {},
});

const reducer6 = (bankDetailsState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState6, loading: true };
    case "FETCHED":
      return { ...initialState6, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState6, loading: false, error: action.payload };
    default:
      return bankDetailsState;
  }
};

export const BankDetailsContextProvider = (props: any) => {
  const [bankDetailsState, dispatch] = useReducer(reducer6, initialState6);

  const getBankDetails = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getBankDetails(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <BankDetailsContext.Provider
      value={{
        bankDetailsState,
        getBankDetails,
      }}
    >
      {props.children}
    </BankDetailsContext.Provider>
  );
};

export const useBankDetailsContext = () => {
  const bankDetailsContext = useContext(BankDetailsContext);
  return bankDetailsContext;
};

const initialState7 = {
  data: [],
  loading: false,
  error: null,
};

export const EducationQualificationContext = createContext({
  educationQualificationState: initialState7,
  getEducationQualifications: (data?: any) => {},
});

const reducer7 = (educationQualificationState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState7, loading: true };
    case "FETCHED":
      return { ...initialState7, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState7, loading: false, error: action.payload };
    default:
      return educationQualificationState;
  }
};

export const EducationQualificationContextProvider = (props: any) => {
  const [educationQualificationState, dispatch] = useReducer(
    reducer7,
    initialState7
  );

  const getEducationQualifications = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getEducationQualifications(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <EducationQualificationContext.Provider
      value={{
        educationQualificationState,
        getEducationQualifications,
      }}
    >
      {props.children}
    </EducationQualificationContext.Provider>
  );
};

export const useEducationQualificationContext = () => {
  const educationQualificationContext = useContext(
    EducationQualificationContext
  );
  return educationQualificationContext;
};

const initialState8 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateAchievementsContext = createContext({
  candidateAchievementsState: initialState8,
  getCandidateAchievements: (data?: any) => {},
});

const reducer8 = (candidateAchievementsState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState8, loading: true };
    case "FETCHED":
      return { ...initialState8, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState8, loading: false, error: action.payload };
    default:
      return candidateAchievementsState;
  }
};

export const CandidateAchievementsContextProvider = (props: any) => {
  const [candidateAchievementsState, dispatch] = useReducer(
    reducer8,
    initialState8
  );

  const getCandidateAchievements = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateAchievements(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateAchievementsContext.Provider
      value={{
        candidateAchievementsState,
        getCandidateAchievements,
      }}
    >
      {props.children}
    </CandidateAchievementsContext.Provider>
  );
};

export const useCandidateAchievementsContext = () => {
  const candidateAchievementsContext = useContext(CandidateAchievementsContext);
  return candidateAchievementsContext;
};

const initialState9 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateLanguageMapsContext = createContext({
  candidateLanguageMaps: initialState9,
  getCandidateLanguageMaps: (data?: any) => {},
});

const reducer9 = (candidateLanguageMaps: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState9, loading: true };
    case "FETCHED":
      return { ...initialState9, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState9, loading: false, error: action.payload };
    default:
      return candidateLanguageMaps;
  }
};

export const CandidateLanguageMapsContextProvider = (props: any) => {
  const [candidateLanguageMaps, dispatch] = useReducer(reducer9, initialState9);

  const getCandidateLanguageMaps = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateLanguageMaps(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateLanguageMapsContext.Provider
      value={{
        candidateLanguageMaps,
        getCandidateLanguageMaps,
      }}
    >
      {props.children}
    </CandidateLanguageMapsContext.Provider>
  );
};

export const useCandidateLanguageMapsContext = () => {
  const candidateLanguageMapsContext = useContext(CandidateLanguageMapsContext);
  return candidateLanguageMapsContext;
};

const initialState10 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateReferencesContext = createContext({
  candidateReferences: initialState10,
  getCandidateReferences: (data?: any) => {},
});

const reducer10 = (candidateReferences: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState10, loading: true };
    case "FETCHED":
      return { ...initialState10, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState10, loading: false, error: action.payload };
    default:
      return candidateReferences;
  }
};

export const CandidateReferencesContextProvider = (props: any) => {
  const [candidateReferences, dispatch] = useReducer(reducer10, initialState10);

  const getCandidateReferences = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateReferences(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateReferencesContext.Provider
      value={{
        candidateReferences,
        getCandidateReferences,
      }}
    >
      {props.children}
    </CandidateReferencesContext.Provider>
  );
};

export const useCandidateReferencesContext = () => {
  const candidateReferencesContext = useContext(CandidateReferencesContext);
  return candidateReferencesContext;
};

const initialState11 = {
  data: [],
  loading: false,
  error: null,
};

export const SocialAccountContext = createContext({
  socialAccounts: initialState11,
  getSocialAccounts: (data?: any) => {},
});

const reducer11 = (socialAccounts: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState11, loading: true };
    case "FETCHED":
      return { ...initialState11, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState11, loading: false, error: action.payload };
    default:
      return socialAccounts;
  }
};

export const SocialAccountContextProvider = (props: any) => {
  const [socialAccounts, dispatch] = useReducer(reducer11, initialState11);

  const getSocialAccounts = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getSocialAccounts(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <SocialAccountContext.Provider
      value={{
        socialAccounts,
        getSocialAccounts,
      }}
    >
      {props.children}
    </SocialAccountContext.Provider>
  );
};

export const useSocialAccountContext = () => {
  const socialAccountContext = useContext(SocialAccountContext);
  return socialAccountContext;
};

const initialState12 = {
  data: [],
  loading: false,
  error: null,
};

export const PassportInformationContext = createContext({
  passportInformation: initialState12,
  getPassportInformation: (data?: any) => {},
});

const reducer12 = (passportInformation: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState12, loading: true };
    case "FETCHED":
      return { ...initialState12, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState12, loading: false, error: action.payload };
    default:
      return passportInformation;
  }
};

export const PassportInformationContextProvider = (props: any) => {
  const [passportInformation, dispatch] = useReducer(reducer12, initialState12);

  const getPassportInformation = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getPassportInformation(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <PassportInformationContext.Provider
      value={{
        passportInformation,
        getPassportInformation,
      }}
    >
      {props.children}
    </PassportInformationContext.Provider>
  );
};

export const usePassportInformationContext = () => {
  const passportInformationContext = useContext(PassportInformationContext);
  return passportInformationContext;
};

const initialState13 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateProfileImageContext = createContext({
  candidateProfileImage: initialState13,
  getCandidateProfileImage: (data?: any) => {},
});

const reducer13 = (candidateProfileImage: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState13, loading: true };
    case "FETCHED":
      return { ...initialState13, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState13, loading: false, error: action.payload };
    default:
      return candidateProfileImage;
  }
};

export const CandidateProfileImageContextProvider = (props: any) => {
  const [candidateProfileImage, dispatch] = useReducer(
    reducer13,
    initialState13
  );

  const getCandidateProfileImage = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateProfileImage(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateProfileImageContext.Provider
      value={{
        candidateProfileImage,
        getCandidateProfileImage,
      }}
    >
      {props.children}
    </CandidateProfileImageContext.Provider>
  );
};

export const useCandidateProfileImageContext = () => {
  const candidateProfileImageContext = useContext(CandidateProfileImageContext);
  return candidateProfileImageContext;
};

const initialState14 = {
  data: [],
  loading: false,
  error: null,
};

export const CandidateOtherCertificateContext = createContext({
  candidateOtherCertificate: initialState14,
  getCandidateOtherCertificates: (data?: any) => {},
});

const reducer14 = (candidateOtherCertificate: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState14, loading: true };
    case "FETCHED":
      return { ...initialState14, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState14, loading: false, error: action.payload };
    default:
      return candidateOtherCertificate;
  }
};

export const CandidateOtherCertificateContextProvider = (props: any) => {
  const [candidateOtherCertificate, dispatch] = useReducer(
    reducer14,
    initialState14
  );

  const getCandidateOtherCertificates = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getCandidateOtherCertificates(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <CandidateOtherCertificateContext.Provider
      value={{
        candidateOtherCertificate,
        getCandidateOtherCertificates,
      }}
    >
      {props.children}
    </CandidateOtherCertificateContext.Provider>
  );
};

export const useCandidateOtherCertificateContext = () => {
  const candidateOtherCertificateContext = useContext(
    CandidateOtherCertificateContext
  );
  return candidateOtherCertificateContext;
};

const initialState15 = {
  data: [],
  loading: false,
  error: null,
};

export const SeamanBookCdcContext = createContext({
  seamanBookCdc: initialState15,
  getSeamanBookCdc: (data?: any) => {},
});

const reducer15 = (seamanBookCdc: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState15, loading: true };
    case "FETCHED":
      return { ...initialState15, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState15, loading: false, error: action.payload };
    default:
      return seamanBookCdc;
  }
};

export const SeamanBookCdcContextProvider = (props: any) => {
  const [seamanBookCdc, dispatch] = useReducer(reducer15, initialState15);

  const getSeamanBookCdc = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getSeamanBookCdc(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <SeamanBookCdcContext.Provider
      value={{
        seamanBookCdc,
        getSeamanBookCdc,
      }}
    >
      {props.children}
    </SeamanBookCdcContext.Provider>
  );
};

export const useSeamanBookCdcContext = () => {
  const seamanBookCdcContext = useContext(SeamanBookCdcContext);
  return seamanBookCdcContext;
};

const initialState16 = {
  data: [],
  loading: false,
  error: null,
};

export const DigiDocumentDetailsContext = createContext({
  digiDocumentDetails: initialState16,
  getDigiDocumentDetails: (data?: any) => {},
});

const reducer16 = (digiDocumentDetails: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState16, loading: true };
    case "FETCHED":
      return { ...initialState16, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState16, loading: false, error: action.payload };
    default:
      return digiDocumentDetails;
  }
};

export const DigiDocumentDetailsContextProvider = (props: any) => {
  const [digiDocumentDetails, dispatch] = useReducer(reducer16, initialState16);

  const getDigiDocumentDetails = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getDigiDocumentDetails(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <DigiDocumentDetailsContext.Provider
      value={{
        digiDocumentDetails,
        getDigiDocumentDetails,
      }}
    >
      {props.children}
    </DigiDocumentDetailsContext.Provider>
  );
};

export const useDigiDocumentDetailsContext = () => {
  const digiDocumentDetailsContext = useContext(DigiDocumentDetailsContext);
  return digiDocumentDetailsContext;
};

const initialState17 = {
  data: [],
  loading: false,
  error: null,
};

export const DigiDocumentTypeContext = createContext({
  digiDocumentType: initialState17,
  getDigiDocumentType: (data?: any) => {},
});

const reducer17 = (digiDocumentType: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState17, loading: true };
    case "FETCHED":
      return { ...initialState17, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState17, loading: false, error: action.payload };
    default:
      return digiDocumentType;
  }
};

export const DigiDocumentTypeContextProvider = (props: any) => {
  const [digiDocumentType, dispatch] = useReducer(reducer17, initialState17);

  const getDigiDocumentType = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getDigiDocumentType(data)
      .then((res: any) => {
        console.log(
          "====================================++++++++++",
          res.data.data
        );
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <DigiDocumentTypeContext.Provider
      value={{
        digiDocumentType,
        getDigiDocumentType,
      }}
    >
      {props.children}
    </DigiDocumentTypeContext.Provider>
  );
};

export const useDigiDocumentTypeContext = () => {
  const digiDocumentTypeContext = useContext(DigiDocumentTypeContext);
  return digiDocumentTypeContext;
};

const initialState18 = {
  data: [],
  loading: false,
  error: null,
};

export const NotificationListContext = createContext({
  notificationList: initialState18,
  getNotificationList: (data?: any) => {},
});

const reducer18 = (notificationList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState18, loading: true };
    case "FETCHED":
      return { ...initialState18, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState18, loading: false, error: action.payload };
    default:
      return notificationList;
  }
};

export const NotificationListContextProvider = (props: any) => {
  const [notificationList, dispatch] = useReducer(reducer18, initialState18);

  const getNotificationList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getNotificationList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <NotificationListContext.Provider
      value={{
        notificationList,
        getNotificationList,
      }}
    >
      {props.children}
    </NotificationListContext.Provider>
  );
};

export const useNotificationListContext = () => {
  const notificationListContext = useContext(NotificationListContext);
  return notificationListContext;
};
