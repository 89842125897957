import React, { useContext } from "react";
import Timer from "react-compound-timer";

function OddC({ setTimer }: { setTimer: any }) {
  console.log("oddR");
  return (
    <Timer
      initialTime={60000 * 5}
      direction="backward"
      onStop={() => {
        console.log("stop");
      }}
      onResume={() => console.log("onResume hook")}
      checkpoints={[
        {
          time: 0,
          callback: () => {
            console.log("Checkpoint A");
            setTimer("OTP is expired. Click Send again to get it back.");
          },
        },
      ]}
    >
      {({ reset }: { reset: any }) => {
        return (
          <React.Fragment>
            <div className="login_validation">
              {" "}
              OTP Expires in <Timer.Minutes /> Minutes, <Timer.Seconds />{" "}
              Seconds
            </div>
          </React.Fragment>
        );
      }}
    </Timer>
  );
}

function EvenC({ setTimer }: { setTimer: any }) {
  console.log("evenR");
  return (
    <Timer
      initialTime={60000 * 5}
      direction="backward"
      onStop={() => {
        console.log("stop");
      }}
      onResume={() => console.log("onResume hook")}
      checkpoints={[
        {
          time: 0,
          callback: () => {
            console.log("Checkpoint A");
            setTimer("OTP is expired. Click Send again to get it back.");
          },
        },
      ]}
    >
      {({ reset }: { reset: any }) => {
        return (
          <React.Fragment>
            <div className="login_validation">
              {" "}
              OTP Expires in <Timer.Minutes /> Minutes, <Timer.Seconds />{" "}
              Seconds
            </div>
          </React.Fragment>
        );
      }}
    </Timer>
  );
}

function OtpExpiryCouter({
  setTimer,
  timer,
}: {
  setTimer: any;
  timer: string;
}) {
  let r = <span />;

  if (Number(timer)) {
    if (Number(timer) % 2 == 0) {
      console.log("even");
      r = <EvenC setTimer={setTimer} />;
    } else if (Math.abs(Number(timer) % 2) == 1) {
      console.log("odd");
      r = <OddC setTimer={setTimer} />;
    } else {
      console.log("11");
      r = <div className="login_validation"> {timer}</div>;
    }
  } else {
    console.log("22");
    r = <div className="login_validation"> {timer}</div>;
  }

  return r;
}

export default OtpExpiryCouter;
