import React from "react";
import {
  MessageListContextProvider,
  UserListContextProvider,
} from "../../../action/client/ClientProfileAction";
import ClientMessageComponent from "./ClientMessageComponent";

interface IClientMessageContainerProps {}

interface IClientMessageContainerState {}

const initialState = {};

const ClientMessageContainer: React.FC<IClientMessageContainerProps> = (
  props
) => {
  const [
    ClientMessageContainerState,
    setClientMessageContainerState,
  ] = React.useState<IClientMessageContainerState>(initialState);

  return (
    <UserListContextProvider>
      <MessageListContextProvider>
        <ClientMessageComponent />
      </MessageListContextProvider>
    </UserListContextProvider>
  );
};
export default ClientMessageContainer;
