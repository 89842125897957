import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  getClientJobApprovalList,
  setClientJobStatus,
  editClientJob,
  useClientJobApprovalContext,
  useClientJobApprovalDispatcher,
  GetAllPendingApprovalJobList,
  ClientJobApproveOrRejectFromAdmin,
} from "../../../action/admin/ClientJobApprovalAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import {
  ClientJobApprovalRequestModel,
  ClientJobEditRequestModel,
  ClientJobApproval,
  ClientJobApprovalViewModel,
  ClientJobApprovalSaveRequestModel,
  ClientJobApprovalSaveRespondModel,
  CliendPendingJobRequestModel,
  JobApproveOrRejectRequestModel,
} from "../../../models/admin/ClientJobApproval";

import AuthService from "../../../services/AuthService";
import ClientJobForm from "./ClientJobForm";
import { Checkbox, DatePicker, Pagination, Popover, Radio, Space } from "antd";
import Popconfirm from "antd/lib/popconfirm";
import {
  useAdminMyJobDispatcher,
  useAdminMyJobContext,
  GetAdminPermission,
} from "../../../action/admin/AdminMyJobAction";
import { AdminPermissionRequestModel } from "../../../models/admin/AdminMyJob";
import moment from "moment";
import { useGlobalSpinnerContext } from "../../../context/GlobalSpinner";

interface IClientJobApprovalComponentProps {}

interface IClientJobApprovalComponentState {}

const defaultValues = {};

const ClientJobApprovalComponent: React.FC<IClientJobApprovalComponentProps> = (
  props
) => {
  const [
    ClientJobApprovalComponentState,
    setClientJobApprovalComponentState,
  ] = React.useState<IClientJobApprovalComponentState>(defaultValues);

  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;

  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const { myProfile, basicInfo, loggedAdminId } = myProfileAdminContext;

  const adminId = loggedAdminId;
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [isModelOpen2, setIsModelOpen2] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  //console.log("userId", userId, "adminId - client job approval ", adminId);
  const globalSpinner = useGlobalSpinnerContext();

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await GetAdminPermission(
          adminMyJobDispatcher,
          {
            AdminId: loggedAdminId,
          } as AdminPermissionRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedAdminId]);

  const clientJobApprovalDispatcher = useClientJobApprovalDispatcher();
  const clientJobApprovalContext = useClientJobApprovalContext();
  const {
    clientJobApproval,
    clientJobApprovalSaveRespond,
    ClientPendingJobApprovalResult,
    JobApproveOrRejectFromAdminResult,
  } = clientJobApprovalContext;
  console.log(
    "----------clientJobApproval-----------",
    ClientPendingJobApprovalResult
  );

  let history = useHistory();

  const [
    isClientJobAppliedOpen,
    setIsClientJobAppliednOpen,
  ] = useState<boolean>(false);
  const [clientJobId, setClientJobId] = useState<number>(0);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [approvingDate, setApprovingDate] = useState<string>("");

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IClientJobApprovalComponentState>({
    defaultValues,
  });

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      globalSpinner.hideSpinner();
      setIsModelOpen2(false);
      setApprovingDate("");
      setClientJobId(0);
      setIsModelOpen(false);
    }
  }, [JobApproveOrRejectFromAdminResult]);

  const handleJobModelClose = () => {
    setClientJobId(0);
  };

  // React.useEffect(() => {
  //   if (authorizationToken != null && loggedAdminId != 0) {
  //     //console.log('career tips call getClientJobApprovalList',adminId);
  //     (async () => {
  //       await getClientJobApprovalList(
  //         clientJobApprovalDispatcher,
  //         {
  //           userId: 0,
  //           page: currentPage,
  //           pageSize: currentPageSize,
  //           searchTerm: "",
  //           showInactive: false,
  //           sortOrder: "",
  //         } as ClientJobApprovalRequestModel,
  //         authorizationToken
  //       );
  //     })();
  //   }
  // }, [loggedAdminId, currentPage, currentPageSize]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      //console.log('career tips call getClientJobApprovalList',adminId);
      (async () => {
        await GetAllPendingApprovalJobList(
          clientJobApprovalDispatcher,
          {
            Page: 1,
            PageSize: 60,
            SearchTerm: "",
            // showInactive: false,
            SortOrder: "",
          } as CliendPendingJobRequestModel,
          authorizationToken
        );
      })();
    }
  }, [
    loggedAdminId,
    JobApproveOrRejectFromAdminResult,
    currentPage,
    currentPageSize,
  ]);

  const handleApprovalStatus = (
    jobId: number,
    clientId: number,
    clientJobStatusId: number
  ) => {
    if (authorizationToken != null)
      setClientJobStatus(
        clientJobApprovalDispatcher,
        {
          approvalClientJobId: 0,
          jobId: jobId,
          clientId: clientId,
          adminId: adminId,
          clientJobStatusId: clientJobStatusId,
          isActive: true,
        } as ClientJobApprovalSaveRequestModel,
        authorizationToken
      );
  };

  const handleJobApplayClick = (id: any) => {
    setClientJobApprovalComponentState({
      ...ClientJobApprovalComponentState,
      clientJobId: id,
    });
    handleClientJobAppliedOpen();
  };

  const handleClientJobAppliedOpen = () => {
    setIsClientJobAppliednOpen(!isClientJobAppliedOpen);
  };

  const handleModelSubmitApprove = (data: any) => {
    console.log(data);
    if (authorizationToken != null) {
      ClientJobApproveOrRejectFromAdmin(
        clientJobApprovalDispatcher,
        {
          AdminId: loggedAdminId,
          UserId: 0,
          DelayDate: approvingDate,
          IsApproved: true,
          IsRejected: false,
          Ispublished: false,
          JobId: clientJobId,
          ProcessingComment: "",
        } as JobApproveOrRejectRequestModel,
        authorizationToken
      );
      globalSpinner.showSpinner();
    }
  };
  const handleModelSubmitReject = (data: any) => {
    console.log(data);
    if (authorizationToken != null) {
      ClientJobApproveOrRejectFromAdmin(
        clientJobApprovalDispatcher,
        {
          AdminId: loggedAdminId,
          UserId: 0,
          DelayDate: approvingDate,
          IsApproved: false,
          IsRejected: true,
          Ispublished: false,
          JobId: clientJobId,
          ProcessingComment: data.reason,
        } as JobApproveOrRejectRequestModel,
        authorizationToken
      );
      globalSpinner.showSpinner();
    }
  };
  const onChangeRadio = (e: any) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
    setValue("reason", e.target.value);
  };

  const onChangeDate = (date: any, dateString: any) => {
    console.log(date, dateString);
    setApprovingDate(dateString);
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  const handlePageSizeChange = (page: any, pageSize: any) => {
    console.log("page", page, "pageSize", pageSize);
    setCurrentPage(page);
    setCurrentPageSize(pageSize);
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Job Posts</h1>
          <div className="action_btn1">
            <button
              type="button"
              className="actions_menu"
              onClick={() => {
                setIsListOpen(!isListOpen);
              }}
            >
              <i className="fa fa-align-left" aria-hidden="true" />
            </button>
            <div
              className="user_menu"
              tabIndex={-1}
              style={{ display: isListOpen ? "block" : "none" }}
            >
              <div className="action_sec">
                <ul>
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isVendorsRegistrationPermission ? (
                    <li>
                      <Link to="/admin/vendorapproval">
                        Venders Registration
                      </Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isClientsjobpostPermission ? (
                    <li>
                      <Link to="/admin/clientjobapproval">
                        Client Job Posts
                      </Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isCareerTipsPermission ? (
                    <li>
                      <Link to="/admin/careertipsapproval">Career Tips</Link>{" "}
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="section_box3">
            <div className="row">
              <div
                className="my_job_descrip"
                style={{ overflow: "hidden", outline: "none" }}
                tabIndex={0}
              >
                <Scrollbars
                  style={{ height: 750 }}
                  autoHide
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        position: "relative",
                        display: "block",
                        width: "5px",
                        cursor: "pointer",
                        borderRadius: "inherit",
                        backgroundColor: "rgb(73, 69, 69)",
                        height: "115px",
                      }}
                    />
                  )}
                >
                  {ClientPendingJobApprovalResult.data != undefined
                    ? ClientPendingJobApprovalResult.data.map(
                        (clientJob, i) => {
                          return (
                            <div className="col-sm-3" key={`${i}`}>
                              <div className="matched_jobs">
                                <div className="matched_jobs_cat_t">
                                  {clientJob.categoryName}
                                </div>
                                {/* <div className="matched_star1"><i className="fa fa-star-o" aria-hidden="true"></i></div>  */}
                                <div className="clearfix"></div>
                                <div className="matched_heading">
                                  {clientJob.title}
                                </div>
                                <div className="published">Not Published</div>
                                <div className="clearfix"></div>
                                <div className="matched_jobs_cat">
                                  <i
                                    className="fa fa-map-marker"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {clientJob.location}
                                </div>
                                <div className="matched_jobs_cat text-right">
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {clientJob.jobType}
                                </div>
                                <div className="clearfix"></div>
                                <div className="matched_jobs_cat">
                                  <i
                                    className="fa fa-list-ul"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {clientJob.numberOfVacancies} Vacancies
                                </div>
                                <div className="clearfix"></div>
                                <div className="check_status">
                                  {/* <a
                                    onClick={() => {
                                      setClientJobId(clientJob.rowId);

                                      handleClientJobAppliedOpen();
                                    }}
                                    data-target="#create_jobdescription"
                                    data-toggle="modal"
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                    Edit
                                  </a> */}
                                  <Popconfirm
                                    title={
                                      <div>
                                        <p>Are you sure to Reject this job?</p>
                                      </div>
                                    }
                                    onConfirm={
                                      () => {
                                        setIsModelOpen(true);
                                        setClientJobId(clientJob.jobId);
                                      }
                                      // handleApprovalStatus(clientJob.rowId, 0, 1)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <a
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "red",
                                      }}
                                    >
                                      Reject
                                    </a>
                                  </Popconfirm>
                                </div>
                                <div className="check_status">
                                  <Popconfirm
                                    title={
                                      <div>
                                        <p>Are you sure to approve this job?</p>
                                      </div>
                                    }
                                    onConfirm={() =>
                                      // handleApprovalStatus(clientJob.rowId, 0, 1)
                                      {
                                        setIsModelOpen2(true);
                                        setClientJobId(clientJob.jobId);
                                      }
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <a
                                      style={{
                                        cursor: "pointer",
                                        float: "left",
                                      }}
                                    >
                                      Approve
                                    </a>
                                  </Popconfirm>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    : null}
                </Scrollbars>
              </div>
              <div className="col-sm-12 col-lg-12" style={{ marginTop: 10 }}>
                <Pagination
                  total={
                    ClientPendingJobApprovalResult.data != undefined
                      ? ClientPendingJobApprovalResult.total
                      : 0
                  }
                  showSizeChanger
                  showQuickJumper
                  showTotal={(total) => `Total ${total} items`}
                  onChange={handlePageSizeChange}
                  current={currentPage}
                />
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <ClientJobForm
            isClientJobOpen={isClientJobAppliedOpen}
            handleClientJobOpen={handleClientJobAppliedOpen}
            currentClientJobId={clientJobId}
            loggedAdminId={loggedAdminId}
            // authorizationToken={authorizationToken}
            handleJobModelClose={handleJobModelClose}
          />
          {/* model------ */}
          <Modal
            show={isModelOpen}
            onHide={() => {
              setIsModelOpen(false);
            }}
          >
            <form onSubmit={handleSubmit(handleModelSubmitReject)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>Reject Reason</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <Radio.Group onChange={onChangeRadio} value={radioValue}>
                    <Space direction="vertical">
                      <Radio
                        value={
                          "Thanks for showing interest in JIT. Unfortunately, we don't have candidates who satisfy your requirements. We will contact you as soon as we receive such resumes"
                        }
                      >
                        Thanks for showing interest in JIT. Unfortunately, we
                        don't have candidates who satisfy your requirements.{" "}
                        <br />{" "}
                        <span style={{ paddingLeft: 25 }}>
                          We will contact you as soon as we receive such resumes
                        </span>
                      </Radio>
                      <Radio
                        value={
                          "We are sorry. We cannot accept your application since you don't satisfy our criteria."
                        }
                      >
                        We are sorry. We cannot accept your application since{" "}
                        you don't satisfy our criteria.
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      {/* <label htmlFor="email">Reason</label> */}
                      <textarea
                        className="form-control"
                        rows={4}
                        style={{ height: 88 }}
                        placeholder="Type Reason"
                        name="reason"
                        ref={register({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4"></div>

                  <div className="col-sm-1">
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="submit"
                      // onClick={() => {
                      //   setPublish(false);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-sm-2"></div>
                  <div className="col-sm-1">
                    <button
                      className="btn "
                      type="button"
                      onClick={() => {
                        setIsModelOpen(false);
                      }}
                    >
                      cancel
                    </button>
                  </div>

                  <div className="col-sm-4"></div>
                </div>
              </Modal.Body>
            </form>
          </Modal>

          {/* model------ */}
          <Modal
            show={isModelOpen2}
            onHide={() => {
              setIsModelOpen2(false);
            }}
          >
            <form onSubmit={handleSubmit(handleModelSubmitApprove)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>Select a date</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-4"></div>
                  <div className="col-sm-4">
                    <DatePicker
                      disabledDate={disabledDate}
                      size="large"
                      onChange={onChangeDate}
                      style={{ width: 240 }}
                    />
                  </div>
                  <div className="col-sm-4"></div>
                </div>
                <div className="row" style={{ marginTop: 12 }}>
                  <div className="col-sm-4"></div>

                  <div className="col-sm-1">
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="submit"
                      // onClick={() => {
                      //   setPublish(false);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-sm-2"></div>
                  <div className="col-sm-1">
                    <button
                      className="btn "
                      type="button"
                      onClick={() => {
                        setIsModelOpen2(false);
                      }}
                    >
                      cancel
                    </button>
                  </div>

                  <div className="col-sm-4"></div>
                </div>
              </Modal.Body>
            </form>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ClientJobApprovalComponent;
