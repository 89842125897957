import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../../candidate/my_profile/components/SelectOption";
// import { getBulkEMAILTemplates } from "../../../apis/admin";
import { saveAdminActions } from "../../../apis/admin";
import {
  useAdminCommunicationDispatcher,
  useAdminCommunicationContext,
  getEmailTemplateList,
  saveEmail,
  saveBulkEmailConversation,
} from "../../../action/admin/AdminCommunicationAction";
import {
  BulkEmailListRequestModel,
  BulkEmailDetailRequestModel,
  BulkEmailDetailPostModel,
  MailTransactionsData,
  BulkEmailDetailListModel,
  BulkEmailAllListModel,
  BulkEmailRespondModel,
} from "../../../models/admin/AdminCommunication";
import { saveEmails } from "./../../../apis/admin";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "./../../../context/GlobalSpinner";
import _ from "lodash";
import moment from "moment";
import { Modal } from "react-bootstrap";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import { environment } from "../../../environments/environment";
import { Select } from "antd";
import AuthService from "../../../services/AuthService";
import {
  GetAdminMyJob,
  useAdminMyJobContext,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import { RowIdRequestModel } from "../../../models/admin/AdminMyJob";
import { SendEmailOrSmsRequestModel } from "../../../models/admin/AdminCandidateSearch";
import {
  sendEmailOrSmsAdminCandidateSearch,
  useAdminCandidateSearchrContext,
  useAdminCandidateSearchrDispatcher,
} from "../../../action/admin/AdminCandidateSearchAction";

const { Option } = Select;
interface ReactSelectOption {
  value: string;
  label: string;
}

type objectIndexing = {
  [key: string]: any;
};

// fuction for Select template
function SelectTemplate(props: any) {
  const [selectedJobId, setSelectedJobId] = useState(0);

  const {
    setJobIdState,
    adminMyJobDispatcher,
    authorizationToken,
    getAdminJobSelectBoxData,
    adminEditRespond,
    setActive,
  } = props;

  function onChange(value: any) {
    //console.log(`selected ${value}`);
    setJobIdState(parseInt(value));
    if (authorizationToken != null) {
      (async () => {
        await GetAdminMyJob(
          adminMyJobDispatcher,
          {
            RowId: parseInt(value),
          } as RowIdRequestModel,
          authorizationToken
        );
      })();
    }
  }

  function onBlur() {
    //console.log("blur");
  }

  function onFocus() {
    //console.log("focus");
  }

  function onSearch(val: any) {
    //console.log("search:", val);
  }

  //console.log(8811, getNames);

  return (
    <React.Fragment>
      <Select
        showSearch
        style={{ width: 300 }}
        placeholder="Select a job"
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        // filterOption={(input, option) => option!==undefined? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0:false}
      >
        {getAdminJobSelectBoxData != undefined &&
        getAdminJobSelectBoxData.jobTitles != undefined
          ? getAdminJobSelectBoxData.jobTitles.length > 0
            ? getAdminJobSelectBoxData.jobTitles.map(
                (data: any, index: any) => {
                  return <Option value={data.value}>{data.caption}</Option>;
                }
              )
            : null
          : null}
      </Select>
      {adminEditRespond.data !== null &&
      adminEditRespond.data != undefined &&
      adminEditRespond.total > 0 ? (
        <>
          <div className="pull-right">
            <button
              style={{ marginTop: 0 }}
              className="btn next_btn"
              onClick={() => setActive(2)}
            >
              Next{" "}
            </button>
          </div>

          <div
            className="row"
            style={{ padding: 50, backgroundColor: "aliceblue", margin: 15 }}
          >
            <div
              className="resp-tab-content resp-tab-content-active"
              aria-labelledby="tab_item-0"
              style={{ display: "block" }}
            >
              <div
                className="jobs_searh"
                style={{ overflow: "hidden", outline: "none" }}
                tabIndex={0}
              >
                <div className="jobs_right_details">
                  {/* <img
                  src="images/job_search_details_img.png"
                  className="img-responsive"
                /> */}
                </div>
                <div className="jobs_right_details_con">
                  <div className="jobs_right_sec">
                    <h1>{adminEditRespond.data[0].title}</h1>
                    <h2>
                      <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                      {adminEditRespond.data[0].location}
                    </h2>
                  </div>
                  <div className="jobs_right_sec1">
                    <div className="clearfix" />
                    <div className="jobs_right_posted">
                      Posted {adminEditRespond.data[0].daysAgo} days ago
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="jobs_right_details_sec">
                    <div className="col-sm-3 jobs_right_details_br">
                      <div className="jobs_right_details_sec_con">
                        Job Category
                      </div>
                      <div className="jobs_right_details_sec_con1">
                        {adminEditRespond.data[0].categoryName}
                      </div>
                    </div>
                    <div className="col-sm-3 jobs_right_details_br">
                      <div className="jobs_right_details_sec_con">
                        Job Title
                      </div>
                      <div className="jobs_right_details_sec_con1">
                        {adminEditRespond.data[0].title}
                      </div>
                    </div>
                    <div className="col-sm-3 jobs_right_details_br">
                      <div className="jobs_right_details_sec_con">Type</div>
                      <div className="jobs_right_details_sec_con1">
                        {adminEditRespond.data[0].jobType}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="jobs_right_details_sec_con">
                        Experience
                      </div>
                      <div className="jobs_right_details_sec_con1">
                        {adminEditRespond.data[0].experience} years
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <h3>Overview</h3>
                  <p>{adminEditRespond.data[0].jobOverview}. </p>
                  <h3>Job description</h3>
                  <p></p>
                  <div>
                    {/* <img
                      src="images/job_search_arrow.png"
                      className="jobs_right_aroow"
                    />{" "} */}
                    {adminEditRespond.data[0].description}
                  </div>

                  <p />
                  <h3>
                    Vacancies : {adminEditRespond.data[0].numberOfVacancies}
                  </h3>
                  <h3>Minimum Qualification</h3>
                  <p>{adminEditRespond.data[0].minQualification} </p>
                  <h3>Skills Required</h3>
                  <p>{adminEditRespond.data[0].skillRequired} </p>
                  <h3>Salary Range</h3>
                  <p>
                    {adminEditRespond.data[0].maxAnnualSalary}-
                    {adminEditRespond.data[0].maxAnnualSalary}.{" "}
                  </p>

                  <h3>Other</h3>
                  <p>{adminEditRespond.data[0].profileDescription}. </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </React.Fragment>
  );
}

// fuction for edit Template
function EditTemplate(props: any) {
  const {
    setActive,
    emailEditSubject,
    setEmailEditSubject,
    emailEditBody,
    setEmailEditBody,
    toNames,
    obj,
    setObj,
    adminEditRespond,
  } = props;
  const [isOpenFile, setIsOpenFile] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div className="admins_se">
        <div className="row">
          <div className="col-sm-12">
            {/* <a onClick={() => setIsOpenFile(true)} className="attach_ic">
                <img src={require("../../../images/attach_icon.png")} />
              </a> */}
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="To"
                value={toNames}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                onChange={(e) => setEmailEditSubject(e.target.value)}
                placeholder="Message Body"
                value={emailEditSubject}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <textarea
                className="form-control form-contro12"
                onChange={(e) => setEmailEditBody(e.target.value)}
                placeholder="Type here"
                value={emailEditBody}
              />
            </div>
          </div>
        </div>
        <div className=" pull-left">
          <button className="btn next_btn" onClick={() => setActive(1)}>
            Back{" "}
          </button>
        </div>
        <div className="pull-right">
          <button className="btn next_btn" onClick={() => setActive(3)}>
            Next{" "}
          </button>
        </div>
      </div>
      <SelectFile
        isOpenFile={isOpenFile}
        setIsOpenFile={setIsOpenFile}
        obj={obj}
        setObj={setObj}
      />
    </React.Fragment>
  );
}

function SelectFile(props: any) {
  const { isOpenFile, setIsOpenFile, obj, setObj } = props;

  const handleDrop = (files: any) => {
    //console.log(files[0]);
    if (files.length > 0) {
      setObj({
        ...obj,
        ["file"]: files[0],
      });
    }
  };

  //cancel selection
  const handleCancel = () => {
    setObj(null);
    setIsOpenFile(false);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <Modal
            show={isOpenFile}
            onHide={() => {
              setIsOpenFile(!isOpenFile);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Attachment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-6">
                    <FileUploads
                      accept=".pdf,.jpg,.jpeg,.xlsx,.csv,.xls"
                      onChange={(file: any) => {
                        handleDrop([file]);
                      }}
                      name="Upload File"
                      disabled={false}
                    />
                  </div>
                  <div className="col-sm-6">
                    {obj.file && (
                      <div
                        className="update_con"
                        style={{ width: 268, height: 105, paddingTop: 20 }}
                      >
                        {obj.file.name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <div className="modal-footer  m-t-30">
              <button
                onClick={() => setIsOpenFile(false)}
                className="btn btn-danger"
                type="submit"
                disabled={obj.file ? false : true}
              >
                Select
              </button>
              <button
                onClick={() => handleCancel()}
                className="btn btn-default"
                type="button"
              >
                cancel
              </button>
            </div>
            <div className="clearfix" />
          </Modal>
        </div>
      </div>
    </>
  );
}

// preview Timplate

function PreviewTemplate(props: any) {
  const {
    setActive,
    emailEditSubject,
    emailEditBody,
    toNames,
    adminId,
    jobIdState,
    templateId,
    userId,
    emailTemplateHtml,
    checkedListArray,
    handleSendSms,
    authorizationToken,
    adminCandidateSearchrDispatcher,
  } = props;

  // const tryRequire = () => {
  //   try {
  //     switch (emailTemplateHtml) {
  //       case "email1.html":
  //         return require("./templates/email1.html");
  //       case "email2.html":
  //         return require("./templates/email2.html");
  //     }
  //   } catch (err) {

  //     return require("./templates/email1.html");
  //   }
  // };

  // let template = { __html: "" };
  // var __html = tryRequire();

  const globalSpinner = useContext(GlobalSpinnerContext);

  const to = "abhilash@borncode.in,abhilashravindrandvl@gmail.com";

  type objectIndexing = {
    [key: string]: any;
  };

  const sendMail = () => {
    if (authorizationToken != null) {
      (async () => {
        await sendEmailOrSmsAdminCandidateSearch(
          adminCandidateSearchrDispatcher,
          {
            AdminId: adminId,
            Emailflag: true,
            SMSflag: false,
            JobId: jobIdState,
            IsActive: true,
            candidateIds: checkedListArray,
            BodyContent: emailEditBody,
            Subject: emailEditSubject,
          } as SendEmailOrSmsRequestModel,
          authorizationToken
        );
      })();
    }
  };

  // var comptempl = _.template(__html);
  if (templateId != undefined && templateId > 0) {
    // let result = comptempl({
    //   subject: emailEditSubject,
    //   body: emailEditBody,
    //   moment: moment,
    // });
    // template = { __html: result };
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="admins_se">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="To"
                value={toNames}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Type here"
                value={emailEditSubject}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <textarea
                className="form-control form-contro12"
                placeholder="Type here"
                value={emailEditBody}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div id="pdfdiv" dangerouslySetInnerHTML={template} /> */}
        </div>
        <div className=" pull-left">
          <button className="btn next_btn" onClick={() => setActive(2)}>
            Back{" "}
          </button>
        </div>
        <div className="pull-right">
          <button className="btn next_btn" onClick={() => sendMail()}>
            Send{" "}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

const AdminCandidateBulkEmail = (props: any) => {
  const {
    handleDisplayModeChange,
    checkedListArray,
    userId,
    JobId,
    JobApplicationViewJobResultModel,
    adminId,
    basicInfo,
    getNames,
    getAdminJobSelectBoxData,
  } = props;
  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { adminEditRespond } = adminMyJobContext;

  const [active, setActive] = useState(1);
  const globalSpinner = useContext(GlobalSpinnerContext);
  const [obj, setObj] = useState<any>({ file: null });
  const [emailEditSubject, setEmailEditSubject] = useState("");
  const [emailEditBody, setEmailEditBody] = useState("");
  const [toNames, setToNames] = useState("");
  const [jobIdState, setJobIdState] = useState(0);

  const authorizationToken = AuthService.accessToken;
  const adminCandidateSearchrDispatcher = useAdminCandidateSearchrDispatcher();
  const adminCandidateSearchrContext = useAdminCandidateSearchrContext();
  const { sendEmailOrSmsResultData } = adminCandidateSearchrContext;
  const goToPreviousPath = () => {
    handleDisplayModeChange(0);
  };

  React.useEffect(() => {
    let ToNames = "";
    if (getNames != undefined && getNames.length > 0) {
      ToNames = "";
      getNames.filter((data: any) => {
        //console.log(data);
        ToNames += data + ",";
      });
    }
    setToNames(ToNames);
  }, [getNames]);

  // React.useEffect(() => {
  //   if (sendEmailOrSmsResultData.isSuccess) {
  //     handleDisplayModeChange(0);
  //   }
  // }, [sendEmailOrSmsResultData]);

  React.useEffect(() => {
    if (
      adminEditRespond.data !== null &&
      adminEditRespond.data != undefined &&
      adminEditRespond.total > 0
    ) {
      setEmailEditSubject(
        "Immediate opening for the position " + adminEditRespond.data[0].title
      );
      setEmailEditBody(
        "Dear Candidate," +
          "\n\n" +
          "We are having opening for " +
          adminEditRespond.data[0].title +
          " , " +
          adminEditRespond.data[0].categoryName +
          " in " +
          "[Company Name]." +
          "\n\n" +
          "Location : " +
          adminEditRespond.data[0].location +
          "\n\n" +
          "Job Description : " +
          adminEditRespond.data[0].description +
          "\n\n" +
          "Requirements : " +
          adminEditRespond.data[0].skillRequired
      );
    }
  }, [adminEditRespond]);

  return (
    <>
      {/*=================Content Section Start================*/}
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Published</h1>
          <a
            //  href="admin_myjobs_candidates.html"
            onClick={goToPreviousPath}
            className="btn cutomise_but"
          >
            <i className="fa fa-angle-left" aria-hidden="true" /> &nbsp; Back{" "}
          </a>
          <div className="clearfix" />
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="section_box4">
                <h1>EMAIL</h1>
                <div className="clearfix" />
                <div className="row">
                  <div className="col-sm-12 col-lg-12 ">
                    <div className="ProgCheck">
                      <ol className="breadcrumb">
                        <li className={active == 1 ? "active" : ""}>
                          {/* <span className="sr-only">Current step:</span> */}
                          Select Template{" "}
                        </li>
                        <li className={active == 2 ? "active" : ""}>
                          {" "}
                          Edit Template{" "}
                        </li>
                        <li className={active == 3 ? "active" : ""}>
                          {" "}
                          Preview Template{" "}
                        </li>
                      </ol>
                    </div>

                    {active === 1 && (
                      // emailTemplateList != undefined &&

                      <SelectTemplate
                        setActive={setActive}
                        setJobIdState={setJobIdState}
                        authorizationToken={authorizationToken}
                        adminMyJobDispatcher={adminMyJobDispatcher}
                        adminEditRespond={adminEditRespond}
                        // emailContent={emailContent}
                        // setSmsContent={setSmsContent}
                        // emailContacts={emailContacts}
                        // emailTemplateList={emailTemplateList}
                        getAdminJobSelectBoxData={getAdminJobSelectBoxData}
                      />
                    )}
                    {active == 2 && "display 2" && (
                      <EditTemplate
                        setActive={setActive}
                        emailEditSubject={emailEditSubject}
                        setEmailEditSubject={setEmailEditSubject}
                        emailEditBody={emailEditBody}
                        setEmailEditBody={setEmailEditBody}
                        toNames={toNames}
                        adminEditRespond={adminEditRespond}
                        obj={obj}
                        setObj={setObj}
                      />
                    )}
                    {active == 3 && "display 3" && (
                      <PreviewTemplate
                        setActive={setActive}
                        emailEditSubject={emailEditSubject}
                        emailEditBody={emailEditBody}
                        // emailContacts={emailContacts}
                        toNames={toNames}
                        authorizationToken={authorizationToken}
                        adminCandidateSearchrDispatcher={
                          adminCandidateSearchrDispatcher
                        }
                        //   templateId={templateId}
                        //   userId={userId}
                        //   emailTemplateHtml={templateHtml}
                        //   handleSendSms={handleSendSms}
                        checkedListArray={checkedListArray}
                        adminId={adminId}
                        jobIdState={jobIdState}
                        obj={obj}
                        setObj={setObj}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      {/*=================Content Section End================*/}
    </>
  );
};

export default AdminCandidateBulkEmail;
