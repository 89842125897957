import React, { useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import { saveContactUs } from "../../apis/misc";
import { toast, ToastContainer } from "react-toastify";
import { GlobalSpinnerContext } from "./../../context/GlobalSpinner";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  getStoryList,
  useStoryContext,
  useStoryDispatcher,
} from "../../action/admin/StoryAction";
import { StoryRequestModel } from "../../models/admin/Story";
import { environment } from "../../environments/environment";
import {
  getMyTeamMemberList,
  useMyTeamMemberContext,
  useMyTeamMemberDispatcher,
} from "../../action/admin/MyTeamMemberAction";
import { MyTeamMemberRequestModel } from "../../models/admin/MyTeamMember";

interface IAboutUsComponentProps {}

interface IAboutUsComponentState {
  name: string;

  email: string;

  subject: string;

  message: string;
}

const initialState = {};

const defaultValues = {
  name: "",

  email: "",

  subject: "",

  message: "",
};

const AboutUsComponent: React.FC<IAboutUsComponentProps> = (props: any) => {
  const [
    AboutUsComponentState,
    setAboutUsComponentState,
  ] = React.useState<IAboutUsComponentState>(defaultValues);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const {
    register,

    handleSubmit,

    watch,

    errors,

    setValue,

    getValues,

    setError,

    control,
  } = useForm<IAboutUsComponentState>({
    defaultValues,
  });

  const [isOpenJobOpportunities, setIsOpenJobOpportunities] = useState<boolean>(
    false
  );

  const [
    isOpenCareerDevelopment,
    setIsOpenCareerDevelopment,
  ] = useState<boolean>(false);

  const [isOpenCareerTips, setIsOpenCareerTips] = useState<boolean>(false);

  const [
    isOpenPortfolioBuilding,
    setIsOpenPortfolioBuilding,
  ] = useState<boolean>(false);
  const cl = props.location.pathname;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const authorizationToken = AuthService.accessToken;
  const history = useHistory();

  const StoryDispatcher = useStoryDispatcher();
  const StoryContext = useStoryContext();
  const { Story, StorySaveRespond } = StoryContext;
  //console.log("----------Story-----------", Story);

  React.useEffect(() => {
    //if (authorizationToken != null && loggedAdminId != 0) {
    //console.log("career tips call getStoryList");
    (async () => {
      await getStoryList(
        StoryDispatcher,
        {
          adminId: 0,
          page: 1,
          pageSize: 60,
          searchTerm: "",
          showInactive: false,
          sortOrder: "",
        } as StoryRequestModel
        //authorizationToken
      );
    })();
    //}
  });

  const MyTeamMemberDispatcher = useMyTeamMemberDispatcher();
  const MyTeamMemberContext = useMyTeamMemberContext();
  const { MyTeamMember, MyTeamMemberSaveRespond } = MyTeamMemberContext;
  //console.log("----------MyTeamMember-----------", MyTeamMember);

  React.useEffect(() => {
    //if (authorizationToken != null && loggedAdminId != 0) {
    //console.log("career tips call getMyTeamMemberList");
    (async () => {
      await getMyTeamMemberList(
        MyTeamMemberDispatcher,
        {
          adminId: 0,
          page: 1,
          pageSize: 4,
          searchTerm: "",
          showInactive: false,
          sortOrder: "",
        } as MyTeamMemberRequestModel
        //authorizationToken
      );
    })();
    //}
  });

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");

  const [subject, setSubject] = useState("");

  const [message, setMessage] = useState("");

  const onSubmit = (data: any) => {
    //console.log(data);

    globalSpinner.showSpinner();

    saveContactUs({
      RowId: 0,

      ContactUsId: 0,

      Name: data["name"],

      Email: data["email"],

      Subject: data["subject"],

      Message: data["message"],

      IsActive: true,
    })
      .then((res: any) => {
        toast.success("Message has been sent to Jitmarine");

        //console.log("Message has been sent to Jitmarine");

        globalSpinner.hideSpinner();
      })

      .catch((err: any) => {
        toast.error("Error occurred while sending.");

        //console.log(err);

        globalSpinner.hideSpinner();
      });

    setName("");

    setEmail("");

    setSubject("");

    setMessage("");
  };

  return (
    <>
      <ToastContainer />
      <div className="header header1">
        <header>
          <div className=" container">
            <div className="row">
              <div className="col-sm-2">
                <div className=" logo">
                  <a
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    <img
                      src={require("../../images/logo.png")}
                      // src={require("../../images/logo.png"
                      className="img-responsive"
                      alt=""
                    />{" "}
                  </a>
                </div>
              </div>
              <div className="col-sm-10">
                <div className="bs-example">
                  <nav
                    role="navigation"
                    className="navbar navbar-default navbar-static"
                    id="navbar-example"
                  >
                    <div className="navbar-header">
                      <button
                        data-target=".bs-example-js-navbar-collapse"
                        data-toggle="collapse"
                        type="button"
                        className="navbar-toggle"
                      >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                      </button>
                      <div className="collapse navbar-collapse bs-example-js-navbar-collapse">
                        <ul className="nav navbar-nav">
                          <li>
                            <Link to="/" className={cl === "/" ? "active" : ""}>
                              {" "}
                              Home
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/career_developer"
                              className={
                                cl === "/career_developer" ? "active" : ""
                              }
                            >
                              Career Developer
                            </Link>
                          </li>
                          {/* <li>
                            {" "}
                            <Link
                              to="/coming_soon"
                              className={cl === "/coming_soon" ? "active" : ""}
                            >
                              Client{" "}
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/vendor"
                              className={cl === "/vendor" ? "active" : ""}
                            >
                              Vendor
                            </Link>{" "}
                          </li> */}
                          <li>
                            <Link
                              to={`/help`}
                              className={cl === "/help" ? "active" : ""}
                            >
                              Help
                            </Link>
                          </li>
                          <li className="activefix">
                            <Link
                              to="/about_us"
                              className={cl === "/about_us" ? "active" : ""}
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact_us"
                              className={cl === "/contact_us" ? "active" : ""}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div className="sub_banner">
        <div className="sub_banner_con">
          <div className=" container">
            <div className="row">
              <div className="col-sm-12">
                <div className="sub_banner_heading">About Us</div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner_border">
          <img
            src={require("../../images/about_banner.jpg")}
            // src="images/about_banner.jpg"
            className=" img-responsive"
          />
        </div>
        <div className="clearfix" />
      </div>
      <div className="about_sub">
        <div className="container">
          <div className="row">
            <div className="partner-table">
              <div className="col-sm-6 partner">
                <h1>Who we are</h1>
                <p>
                  JUST IN TIME! That is what JIT stands for, and what our work
                  emulates. Founded in 2000, and based in the UAE, we are now
                  the leading enterprise in career placement and training. With
                  20 years of qualifying experience backing us, we have
                  successfully provided more than 20,000 HR solutions to date,
                  and our numbers are only climbing! JIT’s platform is always a
                  step ahead of the competition, and a one-stop solution to
                  queries and professionals seeking a new chapter to their
                  careers. We at JIT believe in building each other up and
                  growing together!
                  <br />
                  <br />
                  Join, and connect with us! We will be the wind to your sails,
                  as you set off on an ocean of opportunities!
                </p>
              </div>
              <div className="col-sm-6 partner">
                <img
                  src={require("../../images/about_img1.jpg")}
                  //  src="images/about_img1.jpg"
                  className="img-responsive center-block"
                />
              </div>
            </div>
            <div className="partner-table">
              <div className="col-sm-6 partner">
                <img
                  src={require("../../images/about_img2.jpg")}
                  // src="images/about_img2.jpg"
                  className="img-responsive center-block"
                />
              </div>
              <div className="col-sm-6 partner">
                <h1>Objective </h1>
                <p>
                  As a team of professionals committed to providing you with
                  only first-rate career opportunities. We also provide you with
                  numerous resources, from career development, to multiple
                  training services. You also have tools to help get your
                  professional career organized in a presentable manner. Which
                  means that you can write your resumes and cover letters, and
                  have them perfectly crafted, with no strings attached! At no
                  cost to you! We are always working to better ourselves, and
                  add even more value to make sure your experience is one of
                  first-class quality! And we achieve that, by bringing
                  happiness and success to the lives of the millions of people
                  who would join us!
                </p>
              </div>
            </div>
            <div className="partner-table">
              <div className="col-sm-6 partner">
                <h1>Our Values</h1>
                <p>
                  The key element, and specialty of JITians, is time. We strive
                  to accomplish all our milestones within any time given. We
                  always challenge ourselves, and aim higher than we did before.
                  Constantly adapting to our ever changing world.
                </p>
              </div>
              <div className="col-sm-6 partner">
                <img
                  src={require("../../images/about_img3.jpg")}
                  // src="images/about_img3.jpg"
                  className="img-responsive center-block"
                />
              </div>
            </div>

            <div className="partner-table">
              <div className="col-sm-8 partner">
                <h2>Our Values Include:</h2>
                <p>
                  - Adapt as the market evolves. - Train our resources as the
                  market stipulates.
                  <br />
                  - Learn new skills daily. <br />
                  - Become more knowledgeable, tailor our mindset to varying
                  needs, and stay one step ahead.
                  <br />- Executing every task with swift precision, every
                  {"       "} step of the way.
                  <br />- Positive, dedicated, and inclusive attitude at the
                  workplace. <br />- Each individual works and behaves as a
                  leader.
                </p>
              </div>
              <div className="col-sm-4 partner"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="about_services">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2>Our Services</h2>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="categories_sec">
                <a href="#" data-target="#didgilocker" data-toggle="modal">
                  <div>
                    <img
                      src={require("../../images/resume_builder.png")}
                      // src="images/resume_builder.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Job opportunities and Placement</h1>{" "}
                </a>
                <p>
                  {" "}
                  Dive in and explore the many opportunities that are available
                  at your fingertips! And choose only
                  <span id="dots" style={{ display: "inline" }}>
                    ...
                  </span>
                  <span
                    id="more"
                    style={{
                      display: isOpenJobOpportunities ? "inline" : "none",
                    }}
                  >
                    {" "}
                    the ones you want. You can create alerts for job
                    notifications and also see the results in your Applied Jobs
                    tab, so you are up to date on the recruitment process
                  </span>
                </p>
                <button
                  onClick={() => {
                    setIsOpenJobOpportunities(!isOpenJobOpportunities);
                  }}
                  id="myBtn"
                  className="btn more_button"
                >
                  {isOpenJobOpportunities ? "Read less" : "Read more"}
                </button>{" "}
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="categories_sec">
                <a href="#" data-target="#didgilocker" data-toggle="modal">
                  <div>
                    <img
                      src={require("../../images/digi_locker.png")}
                      // src="images/resume_builder.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Career Development</h1>{" "}
                </a>
                <p>
                  {" "}
                  Get training on skills that are relevant to the jobs you are
                  looking for with our Career Developer platform. You will be
                  provided with
                  <span id="dots" style={{ display: "inline" }}>
                    ...
                  </span>
                  <span
                    id="more"
                    style={{
                      display: isOpenCareerDevelopment ? "inline" : "none",
                    }}
                  >
                    {" "}
                    a series of videos lined up perfectly to help you understand
                    concepts as detailed as possible.
                  </span>
                </p>
                <button
                  onClick={() => {
                    setIsOpenCareerDevelopment(!isOpenCareerDevelopment);
                  }}
                  id="myBtn"
                  className="btn more_button"
                >
                  {isOpenCareerDevelopment ? "Read less" : "Read more"}
                </button>{" "}
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="categories_sec">
                <a href="#" data-target="#didgilocker" data-toggle="modal">
                  <div>
                    <img
                      src={require("../../images/career_developer.png")}
                      // src="images/resume_builder.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Career Tips</h1>{" "}
                </a>
                <p>
                  {" "}
                  Experts in the recruitment field will give you tips and tricks
                  on how to get yourself moving with your career and personal
                  life. Just
                  <span id="dots" style={{ display: "inline" }}>
                    ...
                  </span>
                  <span
                    id="more"
                    style={{
                      display: isOpenCareerTips ? "inline" : "none",
                    }}
                  >
                    {" "}
                    click on our Career Tips page and choose from the category
                    you would like to research. You can also share any advice
                    that you might have, which could benefit millions of others.
                    Plus we credit you for any input you share!
                  </span>
                </p>
                <button
                  onClick={() => {
                    setIsOpenCareerTips(!isOpenCareerTips);
                  }}
                  id="myBtn"
                  className="btn more_button"
                >
                  {isOpenCareerTips ? "Read less" : "Read more"}
                </button>{" "}
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="categories_sec">
                <a href="#" data-target="#didgilocker" data-toggle="modal">
                  <div>
                    <img
                      src={require("../../images/apply_jobs.png")}
                      // src="images/resume_builder.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Portfolio Building</h1>{" "}
                </a>
                <p>
                  {" "}
                  Build your resumes and cover letters in no time at all. Attach
                  any document you feel is necessary for your application, or
                  any that
                  <span id="dots" style={{ display: "inline" }}>
                    ...
                  </span>
                  <span
                    id="more"
                    style={{
                      display: isOpenPortfolioBuilding ? "inline" : "none",
                    }}
                  >
                    {" "}
                    you think would help aid in your hunt. Make them visible to
                    prospective recruiters, and make your skills known.
                    Everything is located all in one place for you, so that you
                    can save time, and improve your placement process!
                  </span>
                </p>
                <button
                  onClick={() => {
                    setIsOpenPortfolioBuilding(!isOpenPortfolioBuilding);
                  }}
                  id="myBtn"
                  className="btn more_button"
                >
                  {isOpenPortfolioBuilding ? "Read less" : "Read more"}
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*       
      <div className="about_services">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2>Our Services</h2>
            </div>
            <div className="col-sm-6 col-lg-3" style={{ height: 417 }}>
              <a href="#" data-target="#didgilocker" data-toggle="modal">
                <div className="categories_sec">
                  <div>
                    <img
                      src={require("../../images/resume_builder.png")}
                      //  src="images/resume_builder.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Resume Builder</h1>
                  <p style={{ textAlign: "justify" }}>
                    Dive in and explore the many opportunities that are
                    available at your fingertips! And choose only the ones you
                    want. You can create alerts for job notifications and also
                    see the results in your Applied Jobs tab, so you are up to
                    date on the recruitment process
                  </p>
                </div>
              </a>
            </div>
            <div className="col-sm-6 col-lg-3">
              <a href="#" data-target="#didgilocker" data-toggle="modal">
                <div className="categories_sec">
                  <div>
                    <img
                      src={require("../../images/digi_locker.png")}
                      // src="images/digi_locker.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Career Development</h1>
                  <p>
                    Get training on skills that are relevant to the jobs you are
                    looking for with our Career Developer platform. You will be
                    provided with a series of videos lined up perfectly to help
                    you understand concepts as detailed as possible.
                  </p>
                </div>
              </a>
            </div>
            <div className="col-sm-6 col-lg-3">
              <a href="#" data-target="#didgilocker" data-toggle="modal">
                <div className="categories_sec">
                  <div>
                    <img
                      src={require("../../images/career_developer.png")}
                      // src="images/career_developer.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Career Tips</h1>
                  <p>
                    Experts in the recruitment field will give you tips and
                    tricks on how to get yourself moving with your career and
                    personal life. Just click on our Career Tips page and choose
                    from the category you would like to research. You can also
                    share any advice that you might have, which could benefit
                    millions of others. Plus we credit you for any input you
                    share!
                  </p>
                </div>
              </a>
            </div>
            <div className="col-sm-6 col-lg-3">
              <a href="jobs_applied.html">
                <div className="categories_sec">
                  <div>
                    <img
                      src={require("../../images/apply_jobs.png")}
                      // src="images/apply_jobs.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Portfolio Building</h1>
                  <p>
                    {" "}
                    JIT Career is an ocean of opportunities for you to sail
                    through it and make a markBuild your resumes and cover
                    letters in no time at all. Attach any document you feel is
                    necessary for your application, or any that you think would
                    help aid in your hunt. Make them visible to prospective
                    recruiters, and make your skills known. Everything is
                    located all in one place for you, so that you can save time,
                    and improve your placement process!
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade none-border" id="didgilocker">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-hidden="true"
                data-dismiss="modal"
                className="close"
                type="button"
              >
                ×
              </button>
              <h4 className="modal-title">
                Login as candidate to use this feature.
              </h4>
            </div>
            <div className="col-sm-12 m_t_30 text-center">
              <button className="btn btn-success " type="button">
                Login Now
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
            <div className="modal-footer  m-t-30"></div>
          </div>
        </div>
      </div> */}

      <div className="our_team">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1>Our Team</h1>
            </div>
            {MyTeamMember.data != undefined
              ? MyTeamMember.data.map((team, i) => {
                  return (
                    <div className="col-sm-6 col-lg-3" key={`${i}`}>
                      <div className="our_team_sec">
                        <div>
                          <img
                            style={{ width: 280 }}
                            src={
                              environment.baseUrl +
                              `/Upload/MyTeamMember/${team.imageUrl}`
                            }
                            className="img-responsive center-block"
                          />
                        </div>
                        <h2>{team.name}</h2>
                        <p>{team.designationName}</p>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <div className="stories">
        <div className="container">
          <div className="row">
            <div className="partner-table">
              {Story.data != undefined ? (
                <div>
                  <div className="col-sm-6 partner">
                    <img
                      src={
                        environment.baseUrl +
                        `/Upload/Story/${Story.data[0].imageUrl}`
                      }
                      className="img-responsive center-block"
                    />
                  </div>
                  <div className="col-sm-6 partner">
                    <h1>Stories of JIT</h1>
                    <p>{Story.data[0].description}</p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="contact_form_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="contact_form_head">
                <img
                  src={require("../../images/contact_br.jpg")}
                  // src="images/contact_br.jpg"
                  width={59}
                  height={4}
                />{" "}
                &nbsp; Contact Us
              </div>
              <div className="contact_form_head1">Lets Get in touch</div>
            </div>
            <div className="col-sm-5">
              <form
                onSubmit={handleSubmit(onSubmit)}
                id="contact-form"
                noValidate
              >
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    ref={register({
                      required: "Name is required",
                    })}
                    className="form-control form-control-n"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="email"
                    ref={register({
                      required: "Email is required",

                      maxLength: {
                        value: 150,

                        message: "Should be a valid email",
                      },

                      pattern: {
                        value: /\S+@\S+\.\S+/,

                        message: "Should be a valid email",
                      },
                    })}
                    className="form-control form-control-n"
                    placeholder="Email ID"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="subject"
                    ref={register({
                      required: "Subject is required",
                    })}
                    className="form-control form-control-n"
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="subject"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="form-group">
                  <textarea
                    name="message"
                    className={"form-control form-contro11"}
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    ref={register({
                      required: "Message cannot be blank.",

                      validate: (value) => {
                        //exclude  start and end white-space

                        value = value.replace(/(^\s*)|(\s*$)/gi, "");

                        //convert 2 or more spaces to 1

                        value = value.replace(/[ ]{2,}/gi, " ");

                        // exclude newline with a start spacing

                        value = value.replace(/\n /, "\n");

                        if (value.split(" ").length > 250) {
                          return "Maximum words: 250";
                        }
                      },
                    })}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="message"
                    render={({ message }) => (
                      <div className="login_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="form-group">
                  <button type="submit" className="btn submit_btn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="col-sm-7">
              <img
                src={require("../../images/contact_img.jpg")}
                //  src="images/contact_img.jpg"
                className="img-responsive center-block"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(AboutUsComponent);
