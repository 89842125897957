import { ErrorMessage } from "@hookform/error-message";
import { Checkbox, Popconfirm, Popover, Tooltip, Upload } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { Controller, useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { useParams, Link } from "react-router-dom";
import {
  EmailShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  deleteDigiLocker,
  documentMove,
  getDigiLocker,
  getDigiLockerExpiringDocumentList,
  getDigiLockerNewList,
  postFile,
  saveDigiLockerList,
  shareDigiLocker,
  useDigiLockerContext,
  useDigiLockerDispatcher,
} from "../../../action/candidate/DigiLockerAction";
import {
  useMyProfileContext,
  useMyProfileDispatcher,
  getProfileProgressBarResult,
} from "../../../action/MyProfileAction";
import { useCandidateContext } from "../../../context/candidate/CandidateMyProfile";
import { environment } from "../../../environments/environment";
import {
  digiLockerDetails,
  digiLockerDetailsResultModel,
  digiLockerDocumentMoveRequestModel,
  digiLockerDocumentRequestModel,
  digiLockerDocumentSaveRequestModel,
  digiLockerExpiringDocumentRequestModel,
  digiLockerGetDataWithId,
} from "../../../models/candidate/DigiLocker";
import AuthService from "../../../services/AuthService";
import DragAndDrop from "../my_profile/components/DragAndDrop";
import FileUploads from "../my_profile/components/FileUploads";
import PikadayDatepicker from "../my_profile/components/PikadayDatepicker";
import SelectOption from "../my_profile/components/SelectOption";
import { profileImageRequestModel } from "../../../models/candidate/MyProfileSelectBoxData";
import { String } from "lodash";
import imageCompression from "browser-image-compression";

// const { Dragger } = Upload;
// const CheckboxGroup = Checkbox.Group;

interface ReactSelectOption {
  value: string;
  label: string;
}

interface ICandidateDigilockerComponentProps {
  // digiLockerDetails:digiLockerDetails
}

interface ICandidateDigilockerComponentState {
  countNext: number;
  carouselValue: number;
  documentType: string;
  IsActive: boolean;
  listArray: string[];
  documentName: string;
  docNumber: number;
  currentJobId: number;
  filterFlag: boolean;
  documents: digiLockerDetailsResultModel;
  documentTypeValue: number;
  documentNumber: string;
  expiryDate: number;
}

const defaultValues = {
  countNext: 0,
  carouselValue: 0,
  documentType: "3",
  IsActive: true,
  listArray: [],
  documentName: "",
  docNumber: 0,
  documentTypeValue: 0,
  documentNumber: "",
  expiryDate: 0,
  currentJobId: 0,
  filterFlag: true,
  documents: {} as digiLockerDetailsResultModel,
};

const CandidateDigilockerComponent: React.FC<ICandidateDigilockerComponentProps> = (
  props
) => {
  const [
    CandidateDigilockerComponentState,
    setCandidateDigilockerComponentState,
  ] = React.useState<ICandidateDigilockerComponentState>(defaultValues);
  // const {digiLockerDetails}=props;
  const {
    carouselValue,
    countNext,
    IsActive,
    listArray,
    documentName,
    docNumber,
    documentNumber,
    documentTypeValue,
    currentJobId,
    filterFlag,
    documents,
  } = CandidateDigilockerComponentState;
  const [
    currentPageOfDigiLockerList,
    setCurrentPageOfDigiLockerList,
  ] = useState(1);

  const user = AuthService.currentUser;
  const [currentPageOfExpDocList, setCurrentPageOfExpDocList] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modelTitle, setModelTitle] = useState<string>("");
  const authorizationToken = AuthService.accessToken;
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [imgError, setImgError] = useState<string>("");
  const [fullPath, setFullPath] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isVisibleTwo, setIsVisibleTwo] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ICandidateDigilockerComponentState>({
    defaultValues,
  });
  const [obj, setObj] = useState<any>({ file: null });
  const [isValidationHide, setIsValidationHide] = useState<boolean>(true);
  // const documentType = [
  //   {
  //     label: "general",
  //     value: 3,
  //   },
  //   {
  //     label: "Personal",
  //     value: 2,
  //   },
  // ];

  // var today = new Date(),
  //  Date currentDate =
  //     today.getDate() +
  //     "/" +
  //     (today.getMonth() + 1) +
  //     "/" +
  //     today.getFullYear();
  const myProfileDispatcher = useMyProfileDispatcher();
  const handleCarouselNextClick = () => {
    let num = digiLockerExpiringDocument.data.length; //lenth of expering doc coming from api
    let value = num / 4;
    if (countNext < value && value >= 1) {
      setCandidateDigilockerComponentState(
        (CandidateDigilockerComponentState) => {
          return {
            ...CandidateDigilockerComponentState,
            countNext: countNext + 1,
            carouselValue: carouselValue + -450,
          };
        }
      );
      if (countNext % 9 === 0) {
        setCurrentPageOfExpDocList(currentPageOfExpDocList + 1);
      }
    } else {
      setCandidateDigilockerComponentState(
        (CandidateDigilockerComponentState) => {
          return {
            ...CandidateDigilockerComponentState,
            countNext: 0,
            carouselValue: 0,
          };
        }
      );
    }
  };

  const handleCarouselPreviousClick = () => {
    let num = digiLockerExpiringDocument.data.length; //lenth of expering doc coming from api
    // let value = num / 4;
    if (countNext > 0) {
      setCandidateDigilockerComponentState(
        (CandidateDigilockerComponentState) => {
          return {
            ...CandidateDigilockerComponentState,
            countNext: countNext - 1,
            carouselValue: carouselValue + 450,
          };
        }
      );
      if (countNext % 9 === 0) {
        setCurrentPageOfExpDocList(
          currentPageOfExpDocList != 1 ? currentPageOfExpDocList - 1 : 1
        );
      }
    } else {
      setCandidateDigilockerComponentState(
        (CandidateDigilockerComponentState) => {
          return {
            ...CandidateDigilockerComponentState,
            countNext: 0,
            carouselValue: 0,
          };
        }
      );
    }
  };

  const digiLockerMarkDispatcher = useDigiLockerDispatcher();
  const digiLockerMarkContext = useDigiLockerContext();
  const {
    digiLockerType,
    digiLockerExpiringDocument,
    digiLockersNewList,
    digiLockerSaveRespond,
    digiLockerSaveRespondOne,
    digiLockerDetails,
    digiLockerDeletRespond,
    documentMoveResultModel,
    documentSharedResult,
  } = digiLockerMarkContext;

  const candidateContext = useCandidateContext();
  const { getCandidates, candidateState } = candidateContext;

  const myProfileContext = useMyProfileContext();
  const { loggedUserId, basicInfo, myProfileProgressBar } = myProfileContext;
  const [digiLockerValue, setDigiLockerValue] = useState<digiLockerDetails>(
    digiLockerDetails.data != undefined
      ? digiLockerDetails.data
      : ({} as digiLockerDetails)
  );
  const [rowIdData, setRowIdData] = useState(0);
  const [filename, setFileName] = useState("");
  const [messageData, setMessageData] = useState("");
  const [isRender, setIsRender] = useState(true);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [isChecked, setIsChecked] = useState(false);
  const [isRenderCheckedList, setIsRenderCheckedList] = useState(true);
  const [isSubmited, setIsSubmited] = useState(true);
  const [isCalled, setIsCalled] = useState(false);
  const [name, setName] = useState("");

  let { id } = useParams<any>();

  let _docTypes: ReactSelectOption[] =
    digiLockerType.data != undefined
      ? digiLockerType.data.map((e: any) => {
          return { value: e["rowId"], label: e["title"] };
        })
      : [];

  const [documentTypeActiveId, setDocumentTypeActiveId] = useState(
    digiLockerType != undefined && digiLockerType.data != undefined
      ? digiLockerType.data[0].rowId
      : 100000
  );

  const [digiLockerList, setDigiLockerList] = useState(
    digiLockersNewList != undefined && digiLockersNewList.data != undefined
      ? digiLockersNewList.data
      : []
  );

  const candidateId = loggedUserId;
  React.useEffect(() => {
    if (candidateId != null && candidateId != 0)
      (async () => {
        await getCandidates({
          CandidateId: Number(candidateId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "",
          SortOrder: "",
          ShowInactive: false,
        });
      })();
  }, [candidateId]);

  React.useEffect(() => {
    if (candidateState.data != undefined && candidateState.data.length > 0) {
      setName(candidateState.data[0]["firstName"]);
    }
  }, [candidateState]);

  // React.useEffect(() => {
  //   if (id === "2") {
  //     setDocumentTypeActiveId(
  //       digiLockerType.data != undefined ? digiLockerType.data[1].rowId : 2
  //     );
  //     //console.log(
  //       44,
  //       digiLockerType.data != undefined ? digiLockerType.data[1].rowId : 5
  //     );
  //     //console.log(45, documentTypeActiveId);
  //   }
  // }, [digiLockerType]);

  //console.log("digiLockersList", digiLockersNewList);

  React.useEffect(() => {
    if (
      digiLockersNewList != undefined &&
      digiLockersNewList.data != undefined &&
      isRenderCheckedList
    ) {
      //console.log(88888865555445544);

      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRenderCheckedList(false);
      setDigiLockerList(
        digiLockersNewList != undefined && digiLockersNewList.data != undefined
          ? digiLockersNewList.data
          : []
      );
    }
  }, [isRenderCheckedList, digiLockersNewList]);

  React.useEffect(() => {
    if (
      digiLockerType != undefined &&
      digiLockerType.data != undefined &&
      isRender
    ) {
      setIsRender(false);
      if (id === "2") {
        setDocumentTypeActiveId(
          digiLockerType.data != undefined ? digiLockerType.data[1].rowId : 2
        );
      } else {
        setDocumentTypeActiveId(
          digiLockerType != undefined && digiLockerType.data != undefined
            ? digiLockerType.data[0].rowId
            : 100000
        );
      }
    }
  }, [digiLockerType]);

  React.useEffect(() => {
    if (
      digiLockerType != undefined &&
      digiLockerType.data != undefined &&
      loggedUserId != 0 &&
      authorizationToken != null
    ) {
      // setIsRenderCheckedList(true);
      // setIsChecked(false);
      // setIsCheckedAll(false);
      (async () => {
        await getDigiLockerExpiringDocumentList(
          digiLockerMarkDispatcher,
          {
            candidateId: loggedUserId,
            digiDocumentTypeId: documentTypeActiveId,
            pageIndex: 1,
            pageSize: 10,
            showInactive: false,
          } as digiLockerExpiringDocumentRequestModel,
          authorizationToken
        );
      })();
    }
  }, [documentTypeActiveId, loggedUserId]);

  const handleDocumentChange = (value: any) => {
    // setIsCheckedAll(false);
    // setDocumentTypeActiveId(value);

    if (checkedListArray.length > 0 && authorizationToken != null) {
      documentMove(
        digiLockerMarkDispatcher,
        {
          RowId: checkedListArray,
          DigiDocumentTypeId: value,
        } as digiLockerDocumentMoveRequestModel,
        authorizationToken
      );
      setCheckedListArray([]);
      setIsCheckedAll(false);
    }
  };

  let IdArray = [] as any[];

  React.useEffect(() => {
    if (
      digiLockerType != undefined &&
      digiLockerType.data != undefined &&
      loggedUserId != 0 &&
      authorizationToken != null
    ) {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      // digiLockerList
      // digiLockersNewList.data
      // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
      // .map((d) => {
      //   IdArray.push(d.rowId);
      // });
      // setCheckedListArray(IdArray);
      // setIsCalled(true);

      (async () => {
        await getDigiLockerNewList(
          digiLockerMarkDispatcher,
          {
            candidateId: loggedUserId,
            digiDocumentTypeId: documentTypeActiveId,
            pageIndex: currentPageOfDigiLockerList,
            pageSize: 4,
            showInactive: false,
          } as digiLockerDocumentRequestModel,
          authorizationToken
        );
      })();
    }
  }, [documentTypeActiveId, loggedUserId, currentPageOfDigiLockerList]);
  //console.log("obj", obj);

  React.useEffect(() => {
    if (
      digiLockerSaveRespond != undefined &&
      digiLockerSaveRespond.isSuccess &&
      authorizationToken != null &&
      obj.file != null
    ) {
      const formData = new FormData();
      const data = {
        DigiDocumentUploadId: 0,
        DigiDocumentDetailId: digiLockerSaveRespond.entityId,
        DigiDocument: 2,
        IsActive: true,
      };

      formData.append(
        "DigiDocumentUploadId",
        JSON.stringify(data.DigiDocumentUploadId)
      );
      formData.append(
        "DigiDocumentDetailId",
        JSON.stringify(data.DigiDocumentDetailId)
      );
      formData.append("DigiDocument", JSON.stringify(data.DigiDocument));
      formData.append("IsActive", JSON.stringify(data.IsActive));
      // formData.append(JSON.stringify(data));
      formData.append("Document", obj.file);

      //console.log(112, formData);

      for (var key of (formData as any).entries()) {
        //console.log(key[0] + ", " + key[1]);
      }

      (async () => {
        await postFile(
          digiLockerMarkDispatcher,

          formData,
          authorizationToken
        );
      })();
    }
  }, [digiLockerSaveRespond]);

  const handlePageChange = (value: any) => {
    setCurrentPageOfDigiLockerList(value.selected + 1);
  };

  //------------- Save file---------------------------------------------------------

  const onSubmit = (data: any) => {
    if (filename == "" && isValidationHide) {
      setImgError("Please upload the document");
      setIsVisible(true);
      setIsVisibleTwo(true);
      //console.log(666, data);
    } else {
      //console.log(999, data);
      let date = new Date();
      setIsSubmited(false);
      if (authorizationToken != null)
        saveDigiLockerList(
          digiLockerMarkDispatcher,
          {
            candidateId: loggedUserId,
            name: data.documentName,
            rowId: rowIdData,
            description: "",
            documentNumber: data.documentNumber,
            // documentNumber:parseInt(data.documentNumber),
            digiDocumentTypeId: data.digiDocumentTypeId,
            digiDocumentDetailId: 0,
            //  expiryDate: new Date(data["expiryDate"]),
            expiryDate:
              data.expiryDate == 0
                ? ""
                : moment(new Date(data["expiryDate"])).format("DD/MM/YYYY"),
            expiryFlag: data.docRadio === "yes" ? true : false,
            isActive: true,
          } as digiLockerDocumentSaveRequestModel,
          authorizationToken
        );
      setIsOpen(!isOpen);
      // setFileName(
      //   data.document[0].name != undefined ? data.document[0].name : ""
      // );
    }
  };

  React.useEffect(() => {
    if (
      // (digiLockerSaveRespond != undefined &&
      //   digiLockerSaveRespond.isSuccess &&
      //   authorizationToken != null) ||
      // (digiLockerDeletRespond != undefined &&
      //   digiLockerDeletRespond.isSuccess &&
      //   authorizationToken != null) ||
      // (documentMoveResultModel != undefined &&
      //   documentMoveResultModel.isSuccess &&
      authorizationToken !== null
    ) {
      (async () => {
        await getDigiLockerExpiringDocumentList(
          digiLockerMarkDispatcher,
          {
            candidateId: loggedUserId,
            digiDocumentTypeId: documentTypeActiveId,
            pageIndex: 1,
            pageSize: 10,
            showInactive: false,
          } as digiLockerExpiringDocumentRequestModel,
          authorizationToken
        );

        await getDigiLockerNewList(
          digiLockerMarkDispatcher,
          {
            candidateId: loggedUserId,
            digiDocumentTypeId: documentTypeActiveId,
            pageIndex: currentPageOfDigiLockerList,
            pageSize: 4,
            showInactive: false,
          } as digiLockerDocumentRequestModel,
          authorizationToken
        );
      })();
      setRowIdData(0);
      //console.log(666666666, "helooo");
    }
  }, [
    digiLockerSaveRespondOne,
    digiLockerDeletRespond,
    documentMoveResultModel,
  ]);

  const handleEdit = (id: number) => {
    setIsVisible(false);
    setModelTitle("Edit Document");
    setIsValidationHide(false);
    setRowIdData(id);
    if (authorizationToken != null)
      getDigiLocker(
        digiLockerMarkDispatcher,
        {
          rowId: id,
        } as digiLockerGetDataWithId,
        authorizationToken
      );
    setIsOpen(!isOpen);
  };

  const handleUpdate = (id: number) => {
    setIsValidationHide(false);
    setIsVisible(false);
    setModelTitle("Update Document");
    setRowIdData(id);
    if (authorizationToken != null)
      getDigiLocker(
        digiLockerMarkDispatcher,
        {
          rowId: id,
        } as digiLockerGetDataWithId,
        authorizationToken
      );
    setIsOpen(!isOpen);
  };

  const handleDeleted = (id: number) => {
    if (authorizationToken != null)
      deleteDigiLocker(
        digiLockerMarkDispatcher,
        {
          rowId: id,
        } as digiLockerGetDataWithId,
        authorizationToken
      );
  };

  React.useEffect(() => {
    if (digiLockerDetails.data != undefined) {
      setValue("documentName", digiLockerDetails.data.name);
      setValue("digiDocumentTypeId", digiLockerDetails.data.digiDocumentTypeId);
      // setValue("docNumber", digiLockerDetails.data.documentNumber);
      setValue(
        "documentNumber",
        digiLockerDetails.data.documentNumber.toString()
      );
      setValue(
        "expiryDate",
        digiLockerDetails.data.expiryDate != null
          ? moment(digiLockerDetails.data.expiryDate, "DD/MM/YYYY")
              .toDate()
              .getTime()
          : 0
      );
      setFileName(digiLockerDetails.data.digiDocument);
      // setObj({file.path:})

      // expiryDate
      // setCandidateDigilockerComponentState(
      //   (CandidateDigilockerComponentState) => {
      //     return {
      //       ...CandidateDigilockerComponentState,
      //       documentName: digiLockerDetails.data.name,
      //       documentTypeValue: digiLockerDetails.data.digiDocumentTypeId,
      //       // documentExpDate: digiLockerDetails.data.expiryDate,
      //     };
      //   }
      // );
    }
  }, [digiLockerDetails]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getProfileProgressBarResult(
          myProfileDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as profileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  //console.log(124, documentTypeValue);
  //console.log("digiLockerExpiringDocument", digiLockerExpiringDocument);
  function cancel(e: any) {}

  const handleDownloadFile = () => {
    return null;
  };

  const handleAllChecked = (e: any) => {
    //console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArray = [];
      // digiLockerList
      digiLockersNewList.data
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArray.push(d.rowId);
        });
      setCheckedListArray(IdArray);
      //console.log(23, checkedListArray);
      setIsCheckedAll(e.target.checked);
      //console.log("checkedListArray", checkedListArray);
    } else {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(e.target.checked);
      //console.log("checkedListArray", checkedListArray);
    }
  };

  const handleChecked = (e: any, id: any) => {
    // setIsChecked(true);
    if (e.target.checked) {
      IdArray.push(...checkedListArray, id);
      setCheckedListArray(IdArray);
      //console.log("checkedListArray", checkedListArray);
    } else {
      IdArray = checkedListArray.filter((data) => {
        return data !== id;
      });
      setCheckedListArray(IdArray);
      //console.log("checkedListArray", checkedListArray);
    }

    setIsChecked(true);
  };

  //console.log(25, checkedListArray);
  React.useEffect(() => {
    if (isChecked) {
      if (checkedListArray.length == digiLockersNewList.data.length) {
        setIsCheckedAll(true);
        //console.log("checkedListArray lenth", checkedListArray.length);
        //console.log("digiLockerList.length", digiLockerList.length);
      } else {
        setIsCheckedAll(false);
      }
      setIsChecked(false);
    }
  }, [isChecked]);

  //console.log(26, isCheckedAll);

  const handleAddDocument = () => {
    setIsValidationHide(true);
    setModelTitle("Add Document");
    setObj({ file: null });
    setFileName("");
    setRowIdData(0);
    if (authorizationToken != null)
      getDigiLocker(
        digiLockerMarkDispatcher,
        {
          rowId: 0,
        } as digiLockerGetDataWithId,
        authorizationToken
      );
    setIsOpen(!isOpen);
  };

  const propsValue = {
    // name: "file",
    // multiple: true,
    // beforeUpload: (file: any) => {
    //   //console.log(99, file);
    //   if (file.type !== "image/png" || file.type !== "application/pdf") {
    //     setMessageData("Only Acceptable file formats: PDF and JPEG");
    //   }
    //   if (file.size > 5000000) {
    //     setMessageData("Maximum file size: 5 MB");
    //   }
    //   return file.type === "image/png";
    // },
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    // onChange(info: any) {
    //   //console.log(33, info);
    //   const { status } = info.file;
    //   if (status !== "uploading") {
    //     //console.log(44, info.file, info.fileList);
    //   }
    //   if (status === "done") {
    //     message.success(`${info.file.name} file uploaded successfully.`);
    //   } else if (status === "error") {
    //     // message.error(`${info.file.name} file upload failed.`);
    //   }
    // },
  };

  /// ant Check box

  const handleShareButtonClick = (id: any, value: any, FullPath: any) => {
    if (authorizationToken != null) {
      shareDigiLocker(digiLockerMarkDispatcher, id, authorizationToken);
    }
    setCandidateDigilockerComponentState({
      ...CandidateDigilockerComponentState,
      currentJobId: id,
      filterFlag: value,
    });
    // setCurrentDocType(currentDocType==="image/jpeg"?'jpg':'pdf')
    setFullPath(FullPath);
    setIsShareOpen(!isShareOpen);
  };

  const handleDrop = async (files: any) => {
    //console.log("uploaded file---", files);

    // if (["image/jpeg"].includes(files[0].type) && files[0].size < 1000000 * 5) {
    //   const options = {
    //     maxSizeMB: 1,
    //     maxWidthOrHeight: 1920,
    //     useWebWorker: true,
    //   };

    //   //console.log(files[0]);
    //   if (files.length > 0) {
    //     setObj({
    //       ...obj,
    //       ["file"]: await imageCompression(files[0], options),
    //     });
    //     setFileName(files[0].name);
    //     setIsVisible(false);
    //     setIsVisibleTwo(true);
    //   }
    // } else if (
    //   ["application/pdf"].includes(files[0].type) &&
    //   files[0].size < 1000000 * 5
    // ) {
    //   //console.log(files[0]);
    //   if (files.length > 0) {
    //     setObj({
    //       ...obj,
    //       ["file"]: files[0],
    //     });
    //     setFileName(files[0].name);
    //     setIsVisible(false);
    //     setIsVisibleTwo(true);
    //   }
    // }

    if (
      ["image/jpeg", "application/pdf"].includes(files[0].type) &&
      files[0].size < 1000000 * 5
    ) {
      //console.log(files[0]);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
        setFileName(files[0].name);
        setIsVisible(false);
        setIsVisibleTwo(true);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">
            {/* {basicInfo.fullName != undefined
              ? basicInfo.fullName.split(" ")[0]
              : ""} */}
            {name.split(" ")[0]}
            's DigiLocker
          </h1>
          <div className="clearfix" />
          <div className="row ">
            <div className="col-sm-12 col-lg-9">
              <div
                id="horizontalTab"
                style={{ display: "block", width: "100%", margin: "0px" }}
              >
                <div className="row ">
                  <ul className="resp-tabs-list resp-tabs">
                    {digiLockerType.data != undefined
                      ? digiLockerType.data.map((typeData, i) => {
                          return (
                            <li
                              key={i}
                              onClick={() => {
                                setDocumentTypeActiveId(typeData.rowId);
                              }}
                              className={
                                typeData.rowId == documentTypeActiveId
                                  ? "resp-tab-item resp-tab-active"
                                  : "resp-tab-item"
                              }
                              aria-controls={`tab_item-${i}`}
                              role="tab"
                            >
                              {typeData.title}{" "}
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
                <div className="resp-tabs-container">
                  <h2
                    className="resp-accordion resp-tab-active"
                    role="tab"
                    aria-controls="tab_item-0"
                  >
                    <span className="resp-arrow" />
                    Personal Documents{" "}
                  </h2>
                  <div
                    className="resp-tab-content resp-tab-content-active"
                    aria-labelledby="tab_item-0"
                    style={{ display: "block" }}
                  >
                    <div
                      className="heading_sec"
                      style={{ backgroundColor: "lightblue" }}
                    >
                      <h1>
                        <i className="fa fa-clock-o" aria-hidden="true" />{" "}
                        Expiring Documents
                      </h1>
                    </div>
                    <div className="prodict_list">
                      <div className="row">
                        <div
                          id="owl-demo1"
                          className="owl-carousel owl-theme"
                          style={{ opacity: 1, display: "block" }}
                        >
                          <div className="owl-wrapper-outer">
                            <div
                              className="owl-wrapper"
                              style={{
                                width: "2410px",
                                left: "0px",
                                display: "block",
                                transition: "all 1000ms ease 0s",
                                transform: `translate3d(${carouselValue}px, 0px, 0px)`,
                              }}
                            >
                              {digiLockerExpiringDocument.data != undefined
                                ? digiLockerExpiringDocument.data.map(
                                    (expData, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="owl-item"
                                          style={{ width: "241px" }}
                                        >
                                          <div className="item">
                                            <div className="matched_jobs_sec">
                                              <div className="digis_expairs">
                                                {expData.isExpired
                                                  ? "Expired on"
                                                  : "Expires on"}{" "}
                                                {expData.expiryDate}
                                              </div>
                                              <div className="matched_jobs">
                                                <img
                                                  // src={require("../../../images/digi_document.jpg")}
                                                  src={
                                                    expData.digDcoumentFullPath !==
                                                    ""
                                                      ? environment.baseUrl +
                                                        `/${expData.digDcoumentFullPath}`
                                                      : require("../../../images/pdf-icon.png")
                                                  }
                                                  alt=""
                                                  className="image"
                                                />
                                                {expData.isExpired ? (
                                                  <img
                                                    style={{
                                                      width: "27%",
                                                      height: 20,
                                                      position: "absolute",
                                                      top: 1,
                                                      marginLeft: 147,
                                                    }}
                                                    src={require("../../../images/exp.jpeg")}
                                                    // src="images/digi_document.jpg"
                                                    alt=""
                                                    className="image"
                                                  />
                                                ) : null}

                                                <div className="overlay">
                                                  <div className="text">
                                                    <div className="digis_update">
                                                      <a
                                                        className={
                                                          "_cursor-pointer"
                                                        }
                                                        onClick={(id: any) =>
                                                          handleUpdate(
                                                            expData.rowId
                                                          )
                                                        }
                                                        data-target="#add_document"
                                                        data-toggle="modal"
                                                      >
                                                        Update
                                                      </a>
                                                    </div>
                                                    <div className="digis_delete">
                                                      <Popconfirm
                                                        title={
                                                          <div>
                                                            <p>
                                                              Are you sure you
                                                              want to delete
                                                              this document?{" "}
                                                            </p>
                                                            Name:{" "}
                                                            {`${expData.name}`}
                                                            <br />
                                                            Last Modified:{" "}
                                                            {`${expData.updatedDate}`}
                                                          </div>
                                                        }
                                                        onConfirm={(e: any) =>
                                                          handleDeleted(
                                                            expData.rowId
                                                          )
                                                        }
                                                        onCancel={cancel}
                                                        okText="Yes"
                                                        cancelText="No"
                                                      >
                                                        <a
                                                        // onClick={(id: any) =>
                                                        //   handleDeleted(
                                                        //     expData.rowId
                                                        //   )
                                                        // }
                                                        >
                                                          Delete
                                                        </a>
                                                      </Popconfirm>
                                                    </div>{" "}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="digis_documents">
                                                <i
                                                  className="fa fa-file-text-o"
                                                  aria-hidden="true"
                                                />{" "}
                                                {expData.name != undefined
                                                  ? expData.name
                                                  : ""}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          </div>

                          <div className="owl-controls clickable">
                            <div className="owl-pagination">
                              <div className="owl-page">
                                <span className="" />
                              </div>
                              <div className="owl-page active">
                                <span className="" />
                              </div>
                            </div>
                            <div className="owl-buttons">
                              <div
                                className="owl-prev"
                                onClick={handleCarouselPreviousClick}
                              >
                                <img
                                  src={require("../../../images/products_ar1.png")}
                                />{" "}
                              </div>
                              <div
                                className="owl-next"
                                onClick={handleCarouselNextClick}
                              >
                                <img
                                  src={require("../../../images/products_ar2.png")}
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="heading_sec1">
                      <h1>
                        {myProfileProgressBar.profileProgress < 40 ? (
                          <Popover
                            content={
                              //"Please complete your profile to minimum 60% to access this feature."
                              <>
                                <p>
                                  Please complete your profile to minimum 40% to
                                  access this feature.
                                </p>
                                <Link to="/candidate/my-profile">
                                  Complete Now
                                </Link>
                              </>
                            }
                          >
                            <button
                              onClick={() => {
                                handleAddDocument();
                              }}
                              data-target="#add_document"
                              data-toggle="modal"
                              className={"_cursor-pointer AddDocumentButton"}
                              disabled
                              style={{
                                border: "none",
                                backgroundColor: "white",
                              }}
                            >
                              <i
                                className="fa fa-file-text-o"
                                aria-hidden="true"
                              />{" "}
                              Add Documents
                            </button>
                          </Popover>
                        ) : (
                          <button
                            onClick={() => {
                              handleAddDocument();
                            }}
                            data-target="#add_document"
                            data-toggle="modal"
                            className={"_cursor-pointer AddDocumentButton"}
                            style={{ border: "none", backgroundColor: "white" }}
                          >
                            <i
                              className="fa fa-file-text-o"
                              aria-hidden="true"
                            />{" "}
                            Add Documents
                          </button>
                        )}
                      </h1>
                      <div className="move_document">
                        {checkedListArray.length > 0 &&
                        authorizationToken != null
                          ? digiLockerType.data != undefined
                            ? digiLockerType.data
                                .filter(
                                  (data) => data.rowId !== documentTypeActiveId
                                )
                                .map((data, index) => {
                                  return (
                                    <a
                                      key={index}
                                      onClick={(rowId: any) =>
                                        handleDocumentChange(data.rowId)
                                      }
                                    >
                                      <img
                                        src={require("../../../images/move_document.png")}
                                      />{" "}
                                      Move to {data.title}
                                    </a>
                                  );
                                })
                            : null
                          : digiLockerType.data != undefined
                          ? digiLockerType.data
                              .filter(
                                (data) => data.rowId !== documentTypeActiveId
                              )
                              .map((data, index) => {
                                return (
                                  <Popover
                                    content={
                                      "Please select the document to be moved and then try this."
                                    }
                                  >
                                    <a
                                      key={index}
                                      // onClick={(rowId: any) =>
                                      //   handleDocumentChange(data.rowId)
                                      // }
                                    >
                                      <img
                                        src={require("../../../images/move_document.png")}
                                      />{" "}
                                      Move to {data.title}
                                    </a>
                                  </Popover>
                                );
                              })
                          : null}
                      </div>
                    </div>
                    <div className="section_box4">
                      <div className="table-responsive">
                        <table className="table table-hover table-bordered dataTable no-footer dtr-inline">
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  name="checkbox1"
                                  checked={isCheckedAll}
                                  // defaultChecked={isCheckedAll ? true : false}
                                  onChange={(e: any) => handleAllChecked(e)}
                                />
                                <label htmlFor="checkbox2">
                                  <span />
                                </label>{" "}
                              </th>

                              <th>Document Name</th>
                              <th>Expiry Date</th>
                              <th>Last Modified</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {digiLockersNewList != undefined &&
                            digiLockersNewList.data != undefined
                              ? digiLockersNewList.data.map((document, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        <input
                                          type="checkbox"
                                          onChange={(e: any) =>
                                            handleChecked(e, document.rowId)
                                          }
                                          checked={checkedListArray.includes(
                                            document.rowId
                                          )}
                                        />
                                        <label htmlFor="checkbox2">
                                          <span />
                                        </label>
                                      </td>

                                      {/* <td>
                                        <CheckboxGroup
                                          options={checkedListArray}
                                          value={checkedList}
                                          onChange={onChange}
                                        />
                                      </td> */}

                                      <td>
                                        {document.name != undefined
                                          ? document.name
                                          : ""}{" "}
                                      </td>
                                      <td>{document.expiryDate}</td>
                                      <td>{document.updatedDate}</td>
                                      <td className="table_con_edit">
                                        {myProfileProgressBar.profileProgress <
                                        40 ? (
                                          <Popover
                                            content={
                                              //"Please complete your profile to minimum 40% to access this feature."
                                              <>
                                                <p>
                                                  Please complete your profile
                                                  to minimum 40% to access this
                                                  feature.
                                                </p>
                                                <Link to="/candidate/my-profile">
                                                  Complete Now
                                                </Link>
                                              </>
                                            }
                                          >
                                            <a>
                                              <i
                                                className="fa fa-download"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </Popover>
                                        ) : (
                                          <a
                                            onClick={handleDownloadFile}
                                            href={
                                              environment.baseUrl +
                                              `/api/DigiDocumentUpload/download-doc/${document.rowId}`
                                            }
                                            // href={`https://careerapp.azurewebsites.net/file_view/jpg/careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${document.rowId}`}
                                          >
                                            <i
                                              className="fa fa-download"
                                              aria-hidden="true"
                                            />
                                          </a>
                                        )}

                                        {myProfileProgressBar.profileProgress <
                                        40 ? (
                                          <Popover
                                            content={
                                              //  "Please complete your profile to minimum 40% to access this feature."
                                              <>
                                                <p>
                                                  Please complete your profile
                                                  to minimum 40% to access this
                                                  feature.
                                                </p>
                                                <Link to="/candidate/my-profile">
                                                  Complete Now
                                                </Link>
                                              </>
                                            }
                                          >
                                            <a>
                                              {" "}
                                              <i
                                                className="fa fa-share-alt"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </Popover>
                                        ) : (
                                          <a
                                            onClick={() =>
                                              handleShareButtonClick(
                                                document.rowId,
                                                true,
                                                document.digDcoumentFullPath
                                              )
                                            }
                                            data-target="#share"
                                            data-toggle="modal"
                                          >
                                            <i
                                              className="fa fa-share-alt"
                                              aria-hidden="true"
                                            />
                                          </a>
                                        )}

                                        {myProfileProgressBar.profileProgress <
                                        40 ? (
                                          <Popover
                                            content={
                                              //   "Please complete your profile to minimum 40% to access this feature."
                                              <>
                                                <p>
                                                  Please complete your profile
                                                  to minimum 40% to access this
                                                  feature.
                                                </p>
                                                <Link to="/candidate/my-profile">
                                                  Complete Now
                                                </Link>
                                              </>
                                            }
                                          >
                                            <a>
                                              <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </Popover>
                                        ) : (
                                          <a
                                            onClick={(id: any) =>
                                              handleEdit(document.rowId)
                                            }
                                            data-target="#add_document"
                                            data-toggle="modal"
                                          >
                                            <i
                                              className="fa fa-pencil"
                                              aria-hidden="true"
                                            />
                                          </a>
                                        )}
                                        {myProfileProgressBar.profileProgress <
                                        40 ? (
                                          <Popover
                                            content={
                                              //  "Please complete your profile to minimum 40% to access this feature."
                                              <>
                                                <p>
                                                  Please complete your profile
                                                  to minimum 40% to access this
                                                  feature.
                                                </p>
                                                <Link to="/candidate/my-profile">
                                                  Complete Now
                                                </Link>
                                              </>
                                            }
                                          >
                                            <a>
                                              <i
                                                className="fa fa-trash-o"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </Popover>
                                        ) : (
                                          <Popconfirm
                                            title={
                                              <div>
                                                <p>
                                                  Are you sure delete this
                                                  document{" "}
                                                </p>
                                                Name: {`${document.name}`}
                                                <br />
                                                Last Modified:{" "}
                                                {`${document.updatedDate}`}
                                              </div>
                                            }
                                            onConfirm={(e: any) =>
                                              handleDeleted(document.rowId)
                                            }
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <a>
                                              <i
                                                className="fa fa-trash-o"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </Popconfirm>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <ReactPaginate
                      previousLabel={<i className="fa fa-angle-left" />}
                      nextLabel={<i className="fa fa-angle-right" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={
                        digiLockersNewList != undefined
                          ? digiLockersNewList.total / 4
                          : 1
                      }
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination m-b-5"}
                      activeClassName={"active"}
                    />
                    {/* </ul>          */}
                  </div>

                  {/* mobile version */}

                  <h2
                    className="resp-accordion"
                    role="tab"
                    aria-controls="tab_item-1"
                  >
                    <span className="resp-arrow" />
                    General Documents
                  </h2>
                  <div
                    className="resp-tab-content"
                    aria-labelledby="tab_item-1"
                  >
                    <div className="heading_sec">
                      <h1>
                        <i className="fa fa-clock-o" aria-hidden="true" />{" "}
                        Expiring General Documents
                      </h1>
                    </div>

                    <div className="prodict_list">
                      <div className="row">
                        <div
                          id="owl-demo2"
                          className="owl-carousel owl-theme"
                          style={{ opacity: 0, display: "block" }}
                        >
                          <div className="owl-wrapper-outer">
                            <div
                              className="owl-wrapper"
                              style={{
                                width: "250px",
                                left: "0px",
                                display: "block",
                              }}
                            >
                              {digiLockerExpiringDocument.data != undefined
                                ? digiLockerExpiringDocument.data.map(
                                    (expData, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="owl-item"
                                          style={{ width: "25px" }}
                                        >
                                          <div className="item">
                                            <div className="matched_jobs_sec">
                                              <div className="digis_expairs">
                                                Expires on - 16- 09-2020
                                              </div>
                                              <div className="matched_jobs">
                                                <img
                                                  src={require("../../../images/digi_document.jpg")}
                                                  // src="images/digi_document.jpg"
                                                  alt=""
                                                  className="image"
                                                />
                                                <div className="overlay">
                                                  <div className="text">
                                                    <div className="digis_update">
                                                      <a
                                                        href="#"
                                                        data-target="#add_document"
                                                        data-toggle="modal"
                                                      >
                                                        Update
                                                      </a>
                                                    </div>
                                                    <div className="digis_delete">
                                                      <a href="#">Delete</a>
                                                    </div>{" "}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="digis_documents">
                                                <i
                                                  className="fa fa-file-text-o"
                                                  aria-hidden="true"
                                                />{" "}
                                                Visa
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          </div>
                          <div className="owl-controls clickable">
                            <div className="owl-pagination">
                              <div className="owl-page active">
                                <span className="" />
                              </div>
                              <div className="owl-page">
                                <span className="" />
                              </div>
                            </div>
                            <div className="owl-buttons">
                              <div className="owl-prev"></div>
                              <div className="owl-next"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="heading_sec1">
                      <h1>
                        <a
                          href="#"
                          data-target="#add_document"
                          data-toggle="modal"
                        >
                          <i className="fa fa-file-text-o" aria-hidden="true" />{" "}
                          Add Documents
                        </a>
                      </h1>
                      <div className="move_document">
                        <a href="#">
                          <img
                            src={require("../../../images/digi_document.jpg")}
                            //  src="images/move_document.png"
                          />{" "}
                          Move to General Documents
                        </a>
                      </div>
                    </div>
                    <div className="section_box4">
                      <div className="table-responsive">
                        <table className="table table-hover table-bordered dataTable no-footer dtr-inline">
                          <thead>
                            <tr>
                              <th />
                              <th>Document Name</th>
                              <th>Expiry Date</th>
                              <th>Last Modified</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {digiLockersNewList.data != undefined
                              ? digiLockersNewList.data.map((document, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        <input
                                          id="checkbox2"
                                          type="checkbox"
                                          name="checkbox"
                                          defaultValue={1}
                                        />
                                        <label htmlFor="checkbox2">
                                          <span />
                                        </label>
                                      </td>
                                      <td>Visaaa </td>
                                      <td>17-09-2020</td>
                                      <td>7-07-2020</td>
                                      <td className="table_con_edit">
                                        <a href="#">
                                          <i
                                            className="fa fa-download"
                                            aria-hidden="true"
                                          />
                                        </a>
                                        <a
                                          href="#"
                                          data-target="#share"
                                          data-toggle="modal"
                                        >
                                          <i
                                            className="fa fa-share-alt"
                                            aria-hidden="true"
                                          />
                                        </a>
                                        <a
                                          href="#"
                                          data-target="#add_document"
                                          data-toggle="modal"
                                        >
                                          <i
                                            className="fa fa-pencil"
                                            aria-hidden="true"
                                          />
                                        </a>
                                        <a href="#">
                                          <i
                                            className="fa fa-trash-o"
                                            aria-hidden="true"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <ReactPaginate
                      previousLabel={<i className="fa fa-angle-left" />}
                      nextLabel={<i className="fa fa-angle-right" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={
                        digiLockersNewList != undefined
                          ? digiLockersNewList.total / 10
                          : 1
                      }
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination m-b-5"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3">
              <div className="heading_sec1">
                <h1>
                  <i className="fa fa-lock" aria-hidden="true" /> About
                  DigiLocker
                </h1>
              </div>
              <div className="section_box4">
                <div className="sec_con">
                  JIT's Digi Locker is a cost free feature giving candidates
                  secured access. Anytime. Anywhere. On the go.
                  <br />
                  {/* <br /> */}
                  There are two folders as General and Personal for convenience
                  to candidates. They cannot make Extra folders.
                  <br />
                  Candidates can ADD, UPDATE, EDIT, REMOVE, SHARE and SET EXPIRY
                  reminders. Candidates can ACCESS these documents as well as
                  ADD documents while attaching them in PROFILE or RESUME.
                  <br />
                  Candidates can ADD, UPDATE, EDIT, REMOVE, SHARE and SET EXPIRY
                  reminders. Candidates can ACCESS these documents as well as
                  ADD documents while attaching them in PROFILE or RESUME.
                  <br />
                  Candidates can ADD, UPDATE, EDIT, REMOVE, SHARE and SET EXPIRY
                  reminders. Candidates can ACCESS these documents as well as
                  ADD documents while attaching them in PROFILE or RESUME.
                  <br />
                  Candidates can ADD, UPDATE, EDIT, REMOVE, SHARE and SET EXPIRY
                  reminders. Candidates can ACCESS these documents as well as
                  ADD documents while attaching them in PROFILE or RESUME.
                </div>
              </div>
              <div className="heading_sec1">
                <h1>
                  <i className="fa fa-cog" aria-hidden="true" /> How it works?
                </h1>
              </div>
              <div className="section_box4">
                <div>
                  {" "}
                  <img
                    src={require("../../../images/video_img.jpg")}
                    // src="images/video_img.jpg"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>

      {/* model */}

      {/* model for digiLocker Uplaod */}

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">
              <i className="fa fa-file-text-o" aria-hidden="true" />{" "}
              <span>{modelTitle}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-5">
              <div className="form-group">
                <label htmlFor="email" className="required">
                  Name of the Document
                </label>
                <input
                  defaultValue={
                    !digiLockerDetails || digiLockerDetails.data != undefined
                      ? digiLockerDetails.data.name
                      : ""
                  }
                  type="text"
                  name="documentName"
                  className="form-control"
                  placeholder="Type here"
                  ref={register({
                    required: "Name is required",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="documentName"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="required">
                  Document Type
                </label>
                <span className="select-wrapper-sec">
                  <Controller
                    control={control}
                    rules={{
                      required: "Document Type is required",
                    }}
                    name="digiDocumentTypeId"
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={_docTypes}
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>
                <ErrorMessage
                  errors={errors}
                  name="digiDocumentTypeId"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="">
                  Document Number
                </label>
                <input
                  defaultValue={documentNumber}
                  type="text"
                  name="documentNumber"
                  className="form-control"
                  placeholder="Type here"
                  ref={register({
                    required: false,
                    // pattern: {
                    //   value: /^[0-9]*$/,
                    //   message: "Should be a number"
                    // }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="documentNumber"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Expiry Date</label>
                {/* <input type="text" className="form-control " /> */}
                <Controller
                  // rules={{
                  //   validate: (value) => {
                  //     return value < Date.now() ? undefined : "DOB cannot be a future date.";
                  //   }
                  // }}
                  control={control}
                  name="expiryDate"
                  render={({ onChange, onBlur, value, name }) => {
                    //console.log(value);
                    return (
                      <PikadayDatepicker
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    );
                  }}
                />
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-xs-6">
                    <label htmlFor="email">Reminder on expiry?</label>
                  </div>
                  <div className="col-xs-3">
                    <input
                      id="radio1"
                      type="radio"
                      name="docRadio"
                      value="yes"
                      defaultValue={1}
                      defaultChecked
                      ref={register}
                    />
                    <label htmlFor="radio1">
                      <span>
                        <span />
                      </span>
                      Yes
                    </label>
                  </div>
                  <div className="col-xs-3">
                    <input
                      id="radio2"
                      type="radio"
                      name="docRadio"
                      value="no"
                      defaultValue={2}
                      ref={register}
                    />
                    <label htmlFor="radio2">
                      <span>
                        <span />
                      </span>
                      NO
                    </label>
                  </div>
                </div>
              </div>
              <div className="update_now">
                <button type="submit">Save</button>
              </div>
            </div>
            <div className="col-sm-1" />
            <div className="col-sm-5">
              <div>
                <img
                  src={require("../../../images/upload_img1.jpg")}
                  className="center-block img-responsive"
                />
              </div>

              {/* <div className="box1">
                    <FileUploads />
                    <label htmlFor="file-1"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={17} viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg> <span>Upload Document</span></label>
                  </div> */}

              <FileUploads
                accept=".pdf,.jpg,.jpeg"
                onChange={(file: any) => {
                  handleDrop([file]);
                }}
                name="Upload Document"
                disabled={false}
              />
              {isVisibleTwo ? null : <div className="update_con">OR</div>}

              <DragAndDrop handleDrop={handleDrop}>
                {/* {obj.file ? (
                  <div
                    className="update_con"
                    style={{ width: 268, height: 105,marginLeft:-35 }}
                  >
                    {obj.file.name}{" "}
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="update_con">
                      Drag and drop your file here{" "}
                    </div>
                    <div className="update_con">
                      Acceptable file formats:PDF,JPG and JPEG
                    </div>
                    <div className="update_con">Maximum file size: 5 MB. </div>
                  </React.Fragment>
                )} */}

                {filename !== "" ? (
                  <div
                    className="update_con"
                    style={{ width: 268, height: 105, marginLeft: -35 }}
                  >
                    {filename}{" "}
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="update_con">
                      Drag and drop your file here{" "}
                    </div>
                    <div className="update_con">
                      Acceptable file formats:PDF,JPG and JPEG
                    </div>
                    <div className="update_con">Maximum file size: 5 MB. </div>
                  </React.Fragment>
                )}
              </DragAndDrop>
              <h6
                style={{
                  color: "red",
                  marginLeft: 8,
                  marginTop: 20,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              >
                {isVisible ? imgError : null}
              </h6>
            </div>
          </Modal.Body>
        </form>

        <div className="clearfix" />
      </Modal>

      <Modal
        show={isShareOpen}
        onHide={() => {
          setIsShareOpen(!isShareOpen);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share this document on</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 m_t_30 text-center">
            <div className="social1">
              <WhatsappShareButton
                url={encodeURI(
                  environment.baseUrl + `/${documentSharedResult}`
                )}
                className={""}
                title={"CareerApp Job Post"}
              >
                {/* <a href="#" className="social_face"> */}
                <Tooltip title="whatsApp">
                  <i
                    className="fa fa-whatsapp"
                    style={{ fontSize: 51, color: "green", paddingRight: 2 }}
                  ></i>
                </Tooltip>
                {/* </a> */}
              </WhatsappShareButton>{" "}
              <TelegramShareButton
                url={`career app job Share`}
                title={encodeURI(
                  environment.baseUrl + `/${documentSharedResult}`
                )}
              >
                <Tooltip title="Telegram">
                  <i
                    className="fa fa-telegram"
                    style={{ fontSize: 48, color: "rgb(55, 174, 226)" }}
                  ></i>
                </Tooltip>
              </TelegramShareButton>
              <EmailShareButton
                url={encodeURI(
                  environment.baseUrl + `/${documentSharedResult}`
                )}
                subject={"Jobs In Carrer app"}
                body={"This is a Jobs from   Carrer app"}
                className={""}
              >
                {/* <EmailIcon></EmailIcon> */}
                {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                <Tooltip title="Gmail">
                  <img
                    src={require("../../../images/gmail-icon-svg-28.jpg")}
                    style={{ height: 66, marginTop: -24 }}
                  />
                </Tooltip>
              </EmailShareButton>{" "}
              <CopyToClipboard
                //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                text={environment.baseUrl + `/${documentSharedResult}`}
              >
                <Tooltip title="copy to clipboard">
                  {" "}
                  {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                  <img
                    src={require("../../../images/copy-clipboard-icon-28.jpg")}
                    style={{ height: 66, marginTop: -24, marginLeft: -15 }}
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  />
                </Tooltip>
              </CopyToClipboard>
            </div>
          </div>
          <div className="modal-footer  m-t-30"></div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default CandidateDigilockerComponent;
