import React, { useReducer, createContext, useContext } from "react";
import * as API from "./../../apis/vendor";

const initialState2 = {
  data: [],
  loading: false,
  error: null
};

export const VendorContext = createContext({
  vendorState: initialState2,
  getVendors: (data?: any) => {}
});

const reducer2 = (vendorState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState2, loading: true };
    case "FETCHED":
      return { ...initialState2, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState2, loading: false, error: action.payload };
    default:
      return vendorState;
  }
};

export const VendorContextProvider = (props: any) => {
  const [vendorState, dispatch] = useReducer(reducer2, initialState2);
  const getVendors = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getVendors(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <VendorContext.Provider
      value={{
        vendorState,
        getVendors
      }}
    >
      {props.children}
    </VendorContext.Provider>
  );
};

export const useVendorContext = () => {
  const vendorContext = useContext(VendorContext);
  return vendorContext;
};

const initialState11 = {
  data: [],
  loading: false,
  error: null
};

export const SocialAccountContext = createContext({
  socialAccounts: initialState11,
  getSocialAccounts: (data?: any) => {}
});

const reducer11 = (socialAccounts: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState11, loading: true };
    case "FETCHED":
      return { ...initialState11, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState11, loading: false, error: action.payload };
    default:
      return socialAccounts;
  }
};

export const SocialAccountContextProvider = (props: any) => {
  const [socialAccounts, dispatch] = useReducer(reducer11, initialState11);

  const getSocialAccounts = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getSocialAccounts(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <SocialAccountContext.Provider
      value={{
        socialAccounts,
        getSocialAccounts
      }}
    >
      {props.children}
    </SocialAccountContext.Provider>
  );
};

export const useSocialAccountContext = () => {
  const socialAccountContext = useContext(SocialAccountContext);
  return socialAccountContext;
};

const initialState13 = {
  data: [],
  loading: false,
  error: null
};

export const VendorProfileImageContext = createContext({
  vendorProfileImage: initialState13,
  getVendorProfileImage: (data?: any) => {}
});

const reducer13 = (vendorProfileImage: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState13, loading: true };
    case "FETCHED":
      return { ...initialState13, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState13, loading: false, error: action.payload };
    default:
      return vendorProfileImage;
  }
};

export const VendorProfileImageContextProvider = (props: any) => {
  const [vendorProfileImage, dispatch] = useReducer(reducer13, initialState13);

  const getVendorProfileImage = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getVendorProfileImage(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <VendorProfileImageContext.Provider
      value={{
        vendorProfileImage,
        getVendorProfileImage
      }}
    >
      {props.children}
    </VendorProfileImageContext.Provider>
  );
};

export const useVendorProfileImageContext = () => {
  const vendorProfileImageContext = useContext(VendorProfileImageContext);
  return vendorProfileImageContext;
};

const initialState14 = {
  data: [],
  loading: false,
  error: null
};

export const VendorListContext = createContext({
  vendorList: initialState14,
  getVendorList: (data?: any) => {}
});

const reducer14 = (vendorList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState14, loading: true };
    case "FETCHED":
      return { ...initialState14, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState14, loading: false, error: action.payload };
    default:
      return vendorList;
  }
};

export const VendorListContextProvider = (props: any) => {
  const [vendorList, dispatch] = useReducer(reducer14, initialState14);

  const getVendorList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getVendorList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <VendorListContext.Provider
      value={{
        vendorList,
        getVendorList
      }}
    >
      {props.children}
    </VendorListContext.Provider>
  );
};

export const useVendorListContext = () => {
  const vendorListContext = useContext(VendorListContext);
  return vendorListContext;
};

const initialState15 = {
  data: [],
  loading: false,
  error: null
};

export const UserListContext = createContext({
  userList: initialState15,
  getUserList: (data?: any) => {}
});

const reducer15 = (userList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState15, loading: true };
    case "FETCHED":
      return { ...initialState15, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState15, loading: false, error: action.payload };
    default:
      return userList;
  }
};

export const UserListContextProvider = (props: any) => {
  const [userList, dispatch] = useReducer(reducer15, initialState15);

  const getUserList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getUserList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <UserListContext.Provider
      value={{
        userList,
        getUserList
      }}
    >
      {props.children}
    </UserListContext.Provider>
  );
};

export const useUserListContext = () => {
  const userListContext = useContext(UserListContext);
  return userListContext;
};

const initialState16 = {
  data: [],
  loading: false,
  error: null
};

export const MessageListContext = createContext({
  messageList: initialState16,
  getMessageList: (data?: any) => {}
});

const reducer16 = (messageList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState16, loading: true };
    case "FETCHED":
      return { ...initialState16, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState16, loading: false, error: action.payload };
    default:
      return messageList;
  }
};

export const MessageListContextProvider = (props: any) => {
  const [messageList, dispatch] = useReducer(reducer16, initialState16);

  const getMessageList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getMessageList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <MessageListContext.Provider
      value={{
        messageList,
        getMessageList
      }}
    >
      {props.children}
    </MessageListContext.Provider>
  );
};

export const useMessageListContext = () => {
  const messageListContext = useContext(MessageListContext);
  return messageListContext;
};

const initialState17 = {
  data: [],
  loading: false,
  error: null
};

export const NotificationListContext = createContext({
  notificationList: initialState17,
  getNotificationList: (data?: any) => {}
});

const reducer17 = (notificationList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState17, loading: true };
    case "FETCHED":
      return { ...initialState17, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState17, loading: false, error: action.payload };
    default:
      return notificationList;
  }
};

export const NotificationListContextProvider = (props: any) => {
  const [notificationList, dispatch] = useReducer(reducer17, initialState17);

  const getNotificationList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getNotificationList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <NotificationListContext.Provider
      value={{
        notificationList,
        getNotificationList
      }}
    >
      {props.children}
    </NotificationListContext.Provider>
  );
};

export const useNotificationListContext = () => {
  const notificationListContext = useContext(NotificationListContext);
  return notificationListContext;
};