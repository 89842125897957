import { ErrorMessage } from "@hookform/error-message";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Timer from "react-compound-timer/build";
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import {
  AddOrEditClientPersonalDetails,
  GettClientPersonalDetails,
  useClientProfileContext,
  useClientProfileDispatcher,
} from "../../../action/client/ClientProfileAction";
import {
  emailVerifyCode,
  sendEmailOpt,
  sendPhoneOtp,
  verifyCodeNewMobile,
} from "../../../apis/misc";
import { useGlobalSpinnerContext } from "../../../context/GlobalSpinner";
import {
  ClientPersonalInfoRequestByIdModel,
  ClientPersonalInfoRequestModel,
} from "../../../models/client/ClientProfile";
import AuthService from "../../../services/AuthService";
import { reactHookFormServerErrors } from "../../../utils/utility";
import IsComponentDisabled from "../../components/IsComponentDisabled";
import PhoneNumberWithCode from "../../components/PhoneNumberWithCode";

interface IPersonelInfoState {
  rowId: string;
  name: string;
  location: string;
  phoneNumber: string;
  countryCode: string;
  email: string;
  clientRole: string;
  designation: string;
  organisation: string;
  aboutMe: string;
}

// -----------------------

function EditContact2(props: any) {
  const [contactNumber, setContactNumber] = useState("");

  const [countryCode, setCountryCode] = useState("+91");

  const [showTimer, setShowTimer] = useState(false);

  const [timerMsg, setTimerMsg] = useState("");

  const [screen, setScreen] = useState(1);

  const globalSpinner = useGlobalSpinnerContext();

  const [disabled, setDisabled] = useState(false);

  const [eDisabled, setEDisabled] = useState(true);

  const { register, handleSubmit, watch, errors, trigger, control } = useForm<{
    contactNumber: string;
    countryCode: string;
  }>({
    defaultValues: { contactNumber: "", countryCode: "" },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    errors: errors2,
    setError: setError2,
    trigger: trigger2,
    clearErrors: clearErrors2,
  } = useForm<{ code: string }>({
    defaultValues: { code: "" },
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    watch: watch3,
    control: control3,
    errors: errors3,
    trigger: trigger3,
    clearErrors: clearErrors3,
  } = useForm<{ newContactNumber: string; newCountryCode: string }>({
    defaultValues: { newContactNumber: "", newCountryCode: "+91" },
  });

  const onSubmit = (data: any) => {
    console.log(data);

    setCountryCode(data["countryCode"] == "" ? "+91" : data["countryCode"]);
    setContactNumber(data["contactNumber"]);
    globalSpinner.showSpinner();
    // sendPhoneOtp({}, `?countryCode=${encodeURIComponent(data["countryCode"])}&phoneNumber=${encodeURIComponent(data["contactNumber"])}`).then((res: any) => {
    sendPhoneOtp(
      {},
      `?countryCode=${encodeURIComponent(
        data["countryCode"] == "" ? "+91" : data["countryCode"]
      )}&phoneNumber=${encodeURIComponent(data["contactNumber"])}`
    )
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (res.data.includes("error") || res.data.includes("Invalid")) {
          toast.error(res.data);
        } else {
          // toast.success("OTP has been send.");
          toast.success(
            "OTP has been successfully sent on your mobile number."
          );
          setScreen(2);
          setShowTimer(true);
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const sendVerification = async () => {
    console.log(11, countryCode);
    console.log(12, contactNumber);

    globalSpinner.showSpinner();
    sendPhoneOtp(
      {},
      `?countryCode=${encodeURIComponent(
        countryCode
      )}&phoneNumber=${encodeURIComponent(contactNumber)}`
    )
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (res.data.includes("error") || res.data.includes("Invalid")) {
          toast.error(res.data);
        } else {
          //toast.success("OTP has been resend.");
          toast.success(
            "OTP has been successfully sent on your mobile number."
          );
          setShowTimer(true);
          setTimerMsg("");
          setDisabled(false);
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const onSubmit2 = (data: any) => {
    // console.log(33, data);
    // console.log(41, countryCode);
    // console.log(42, contactNumber);
    // console.log(43, props.oldCountryCode);
    // console.log(44, props.oldCountryCode);
    globalSpinner.showSpinner();
    verifyCodeNewMobile(
      {},
      `?newCountryCode=${encodeURIComponent(
        countryCode
      )}&newPhoneNumber=${encodeURIComponent(
        contactNumber
      )}&oldCountryCode=${encodeURIComponent(
        props.oldCountryCode
      )}&oldPhoneNumber=${encodeURIComponent(props.oldPhoneNumber)}&code=${
        data["code"]
      }`
    )
      .then((res: any) => {
        globalSpinner.hideSpinner();
        console.log(res);
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          toast.success("OTP verified.");
          setTimerMsg("");
          //setScreen(3);
          let data = {
            newCountryCode: countryCode,
            newContactNumber: contactNumber,
          };
          props.updateNumber(data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        //toast.error("Something went wrong");
        //toast.error("Error: Invalid OTP.Please enter correct OTP");
        reactHookFormServerErrors(
          {
            code: ["Error: Invalid OTP.Please enter correct OTP"],
          },
          setError2
        );
      });
  };

  const onSubmit3 = (data: any) => {
    console.log(data);
    props.updateNumber(data);
  };

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit Contact number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-12">
          {screen == 1 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Contact Number</label>
                {/* <input type="text" className="form-control" placeholder="Type here" name="contactNumber" ref={register({
                  required: "Number is required"
                })} /> */}
                <Controller
                  control={control}
                  name="countryCode"
                  render={({ onChange, onBlur, value, name }) => (
                    <PhoneNumberWithCode
                      // codeHeight={"50px"}
                      downwards={true}
                      noPenIcon={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      disabled={false}
                      phoneNo={
                        <input
                          type="text"
                          name="contactNumber"
                          disabled={false}
                          id="phoneNumber"
                          className="form-control phoneNumber"
                          placeholder="Type here"
                          ref={register({
                            required: "Mobile number is required",
                            maxLength: {
                              value: watch("countryCode").length > 3 ? 9 : 10,
                              message: `It must be ${
                                watch("countryCode").length > 3 ? 9 : 10
                              } digits only.`,
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Mobile number should be numbers only",
                            },
                            minLength: {
                              value: watch("countryCode").length > 3 ? 9 : 10,
                              message: `It must be ${
                                watch("countryCode").length > 3 ? 9 : 10
                              } digits only.`,
                            },
                          })}
                        />
                      }
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="contactNumber"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 2 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Enter Code to confirm </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="code"
                  ref={register2({
                    required: "Code is required",
                  })}
                />

                <ErrorMessage
                  errors={errors2}
                  name="code"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />

                {showTimer ? (
                  <Timer
                    initialTime={60000 * 5}
                    direction="backward"
                    onStop={() => {
                      console.log("stop");
                    }}
                    onResume={() => console.log("onResume hook")}
                    checkpoints={[
                      {
                        time: 0,
                        callback: () => {
                          console.log("Checkpoint A");
                          setShowTimer(false);
                          setTimerMsg("OTP Expired.");
                          setDisabled(true);
                        },
                      },
                    ]}
                  >
                    {({ reset }: { reset: any }) => (
                      <React.Fragment>
                        <div className="login_validation">
                          {" "}
                          OTP Expires in <Timer.Minutes /> Minutes,{" "}
                          <Timer.Seconds /> Seconds
                        </div>
                      </React.Fragment>
                    )}
                  </Timer>
                ) : (
                  ""
                )}
                {timerMsg ? (
                  <div className="login_validation">{timerMsg}</div>
                ) : (
                  ""
                )}
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 3 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Contact Number</label>
                {/* <input type="text" className="form-control" placeholder="Type here" name="newContactNumber" ref={register3({
                  required: "New Number is required"
                })} /> */}
                <Controller
                  control={control3}
                  name="newCountryCode"
                  render={({ onChange, onBlur, value, name }) => (
                    <PhoneNumberWithCode
                      // codeHeight={"50px"}
                      noPenIcon={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      disabled={false}
                      phoneNo={
                        <input
                          type="text"
                          name="newContactNumber"
                          disabled={false}
                          id="phoneNumber"
                          className="form-control phoneNumber"
                          placeholder="Type here"
                          ref={register({
                            required: "Mobile number is required",
                            maxLength: {
                              //value: 15,
                              value: 10,
                              message: "Should be a mobile number",
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Mobile number should be numbers only",
                            },
                          })}
                        />
                      }
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors3}
                  name="newContactNumber"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}
        </div>

        {screen == 2 ? (
          <div className="already_login1">
            Didn't get the code?
            <a
              className="_cursor-pointer"
              onClick={() => {
                sendVerification();
              }}
            >
              &nbsp;Send again.
            </a>{" "}
            {/*  { if (!showTimer)}*/}
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
      <div className="modal-footer  m-t-30">
        {screen == 1 ? (
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Send Verification Code
          </button>
        ) : (
          ""
        )}
        {screen == 2 ? (
          <button
            onClick={handleSubmit2(onSubmit2)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
            disabled={disabled}
          >
            Verify
          </button>
        ) : (
          ""
        )}
        {screen == 3 ? (
          <button
            onClick={handleSubmit3(onSubmit3)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Save
          </button>
        ) : (
          ""
        )}
        <button
          onClick={() => {
            props.setIsOpen(!props.isOpen);
          }}
          data-dismiss="modal"
          className="btn btn-default waves-effect"
          type="button"
        >
          Cancel
        </button>
      </div>
      <div className="clearfix" />
    </React.Fragment>
  );
}

function EditEmail2(props: any) {
  const [email, setEmail] = useState("");

  const [showTimer, setShowTimer] = useState(false);

  const [timerMsg, setTimerMsg] = useState("");

  const [screen, setScreen] = useState(1);

  const globalSpinner = useGlobalSpinnerContext();
  const [disabled, setDisabled] = useState(false);
  const [disabledEmail, setDisabledEmail] = useState<boolean>(false);

  const emailRef = useRef<HTMLInputElement | null>(null);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    trigger,
    clearErrors,
  } = useForm<{ email: string }>({
    defaultValues: { email: "" },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    errors: errors2,
    trigger: trigger2,
    setError: setError2,
    clearErrors: clearErrors2,
  } = useForm<{ code: string }>({
    defaultValues: { code: "" },
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    watch: watch3,
    errors: errors3,
    trigger: trigger3,
    clearErrors: clearErrors3,
  } = useForm<{ newEmail: string }>({
    defaultValues: { newEmail: "" },
  });

  const onSubmit = (data: any) => {
    console.log(data);

    setEmail(data["email"]);

    globalSpinner.showSpinner();
    sendEmailOpt({}, `?email=${data["email"]}`)
      .then((res: any) => {
        console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          //toast.success("OTP has been send.");
          toast.success("OTP has been successfully sent on your e-mail.");
          setScreen(2);
          setShowTimer(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const sendVerification = async () => {
    console.log(email);

    globalSpinner.showSpinner();
    sendEmailOpt({}, `?email=${email}`)
      .then((res: any) => {
        console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          //toast.success("OTP has been resend.");
          toast.success("OTP has been successfully sent on your e-mail.");
          setShowTimer(true);
          setTimerMsg("");
          setDisabled(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
        globalSpinner.hideSpinner();
        toast.error("Something went wrong");
      });
  };

  const onSubmit2 = (data: any) => {
    console.log(data);

    globalSpinner.showSpinner();

    emailVerifyCode({}, `?email=${email}&code=${data["code"]}`)
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (res.data.includes("error")) {
          toast.error(res.data);
        } else {
          toast.success("OTP verified.");
          setTimerMsg("");
          setScreen(3);
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        //toast.error("Something went wrong");
        reactHookFormServerErrors(
          {
            code: ["Error: Invalid OTP.Please enter correct OTP"],
          },
          setError2
        );
      });
  };

  const onSubmit3 = (data: any) => {
    console.log(data);
    props.updateEmail(data);
  };

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-12">
          {screen == 1 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="email"
                  ref={register({
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please enter a valid e-mail address."
                    }
                  })}
                /> */}
                {IsComponentDisabled(
                  <input
                    type="text"
                    className={"form-control input-name"}
                    placeholder="Type here"
                    name="email"
                    ref={(e: HTMLInputElement) => {
                      register(e, {
                        required: "Email is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Should be a valid email",
                        },
                      });
                      emailRef.current = e;
                    }}
                    disabled={disabledEmail}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 2 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Enter Code to confirm </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="code"
                  ref={register2({
                    required: "Code is required",
                  })}
                />
                <ErrorMessage
                  errors={errors2}
                  name="code"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
                {showTimer ? (
                  <Timer
                    initialTime={60000 * 5}
                    direction="backward"
                    onStop={() => {
                      console.log("stop");
                    }}
                    onResume={() => console.log("onResume hook")}
                    checkpoints={[
                      {
                        time: 0,
                        callback: () => {
                          console.log("Checkpoint A");
                          setShowTimer(false);
                          setTimerMsg("OTP Expired.");
                          setDisabled(true);
                        },
                      },
                    ]}
                  >
                    {({ reset }: { reset: any }) => (
                      <React.Fragment>
                        <div className="login_validation">
                          {" "}
                          OTP Expires in <Timer.Minutes /> Minutes,{" "}
                          <Timer.Seconds /> Seconds
                        </div>
                      </React.Fragment>
                    )}
                  </Timer>
                ) : (
                  ""
                )}
                {timerMsg ? (
                  <div className="login_validation">{timerMsg}</div>
                ) : (
                  ""
                )}
              </div>
            </form>
          ) : (
            ""
          )}

          {screen == 3 ? (
            <form noValidate>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type here"
                  name="newEmail"
                  ref={register3({
                    required: "New email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors3}
                  name="newEmail"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </form>
          ) : (
            ""
          )}
        </div>

        {screen == 2 ? (
          <div className="already_login1">
            Didn't get the code?
            <a
              className="_cursor-pointer"
              onClick={() => {
                if (!showTimer) {
                  sendVerification();
                }
              }}
            >
              &nbsp;Send again.
            </a>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
      <div className="modal-footer  m-t-30">
        {screen == 1 ? (
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Send Verification Code
          </button>
        ) : (
          ""
        )}
        {screen == 2 ? (
          <button
            onClick={handleSubmit2(onSubmit2)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
            disabled={disabled}
          >
            Verify
          </button>
        ) : (
          ""
        )}
        {screen == 3 ? (
          <button
            onClick={handleSubmit3(onSubmit3)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Save
          </button>
        ) : (
          ""
        )}
        <button
          onClick={() => {
            props.setIsOpen2(!props.isOpen2);
          }}
          data-dismiss="modal"
          className="btn btn-default waves-effect"
          type="button"
        >
          Cancel
        </button>
      </div>
      <div className="clearfix" />
    </React.Fragment>
  );
}

// --------------------------------------------------------
// --------------------------------------------------------------------

const PersonalInfo = () => {
  let defaultValues = {
    rowId: "",
    name: "",
    location: "",
    phoneNumber: "",
    countryCode: "",
    email: "",
    clientRole: "",
    designation: "",
    organisation: "",
    aboutMe: "",
  };
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<IPersonelInfoState>({
    defaultValues,
  });
  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;
  const [disabled, setDisabled] = useState<boolean>(true);
  const globalSpinner = useGlobalSpinnerContext();
  const [disabledContact, setDisabledContact] = useState<boolean>(true);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);
  const [emailData, setEmailData] = useState("");
  const [phoneData, setPhoneData] = useState("");
  const [phoneCountryData, setPhoneCountryData] = useState("");
  const [isOpen3, setIsOpen3] = useState<boolean>(false);

  const clientProfileDispatcher = useClientProfileDispatcher();
  const clientProfileContext = useClientProfileContext();
  const {
    getClientPersonalDetails,
    addOrEditClientProfileResult,
  } = clientProfileContext;

  const updateNumber = (data: any) => {
    console.log("updateNumber", data);
    const _data1 = Object.assign({}, getClientPersonalDetails, {
      mobileNumber: data["contactNumber"],
      countryCode: data["countryCode"],
    });

    if (authorizationToken != null && user?.id != undefined) {
      globalSpinner.showSpinner();
      (async () => {
        await AddOrEditClientPersonalDetails(
          clientProfileDispatcher,
          _data1 as ClientPersonalInfoRequestModel,
          authorizationToken
        );
        globalSpinner.hideSpinner();
      })();
    }
    setIsOpen2(false);
    setIsOpen3(false);
    // const _data1 = Object.assign({}, vendorState.data[0], {
    //   countryCode: data["newCountryCode"],
    //   phoneNumber: data["newContactNumber"],
    //   email: emailData,
    // });
    // globalSpinner.showSpinner();
    // saveVendor(_data1)
    //   .then((res: any) => {
    //     //console.log(res.data);
    //     if (res.data.isSuccess) {
    //       getVendors({
    //         vendorId: Number(vendorId),
    //         Page: 1,
    //         PageSize: 10,
    //         SearchTerm: "string",
    //         SortOrder: "string",
    //         ShowInactive: false,
    //       });
    //       toast.success("Contact number updated.");
    //       setIsOpen(false);
    //       globalSpinner.hideSpinner();
    //     } else {
    //       globalSpinner.hideSpinner();
    //       toast.error(res.data.message);
    //     }
    //   })
    //   .catch((err: any) => {
    //     console.log(err);
    //     globalSpinner.hideSpinner();
    //     toast.error("Something went wrong.");
    //   });
  };

  const updateEmail = (data: any) => {
    console.log("updateEmail", data);

    const _data1 = Object.assign({}, getClientPersonalDetails, {
      email: data["newEmail"],
    });

    if (authorizationToken != null && user?.id != undefined) {
      globalSpinner.showSpinner();
      (async () => {
        await AddOrEditClientPersonalDetails(
          clientProfileDispatcher,
          _data1 as ClientPersonalInfoRequestModel,
          authorizationToken
        );
        globalSpinner.hideSpinner();
      })();
    }
    setIsOpen2(false);
    setIsOpen3(false);
    //
    // saveVendor(_data1)
    //   .then((res: any) => {
    //     console.log(res.data);
    //     if (res.data.isSuccess) {
    //       getVendors({
    //         vendorId: Number(vendorId),
    //         Page: 1,
    //         PageSize: 10,
    //         SearchTerm: "string",
    //         SortOrder: "string",
    //         ShowInactive: false,
    //       });
    //       toast.success("Email updated.");
    //       setIsOpen2(false);
    //     } else {
    //       globalSpinner.hideSpinner();
    //       toast.error(res.data.message);
    //     }
    //   })
    //   .catch((err: any) => {
    //     console.log(err);
    //     globalSpinner.hideSpinner();
    //     toast.error("Something went wrong.");
    //   });
  };

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != undefined) {
      (async () => {
        await GettClientPersonalDetails(
          clientProfileDispatcher,
          {
            rowId: 0,
            userId: parseInt(user?.id),
          } as ClientPersonalInfoRequestByIdModel,
          authorizationToken
        );
      })();
    }
  }, [user?.id, addOrEditClientProfileResult]);

  React.useEffect(() => {
    if (
      authorizationToken != null &&
      user?.id != undefined &&
      getClientPersonalDetails.rowId != undefined
    ) {
      setValue("aboutMe", getClientPersonalDetails.aboutMe);
      setValue("name", getClientPersonalDetails.clientName);
      setValue("countryCode", getClientPersonalDetails.countryCode);
      setValue("designation", getClientPersonalDetails.designation);
      setValue("email", getClientPersonalDetails.email);
      setValue("clientRole", getClientPersonalDetails.jobRole);
      setValue("location", getClientPersonalDetails.location);
      setValue("phoneNumber", getClientPersonalDetails.mobileNumber);
      setValue("organisation", getClientPersonalDetails.organisation);
    }
  }, [getClientPersonalDetails]);

  const onSubmit = (data: any) => {
    console.log(555, data);
    if (authorizationToken != null && user?.id != undefined) {
      (async () => {
        await AddOrEditClientPersonalDetails(
          clientProfileDispatcher,
          {
            aboutMe: data.aboutMe,
            adminId:
              getClientPersonalDetails.adminId != undefined
                ? getClientPersonalDetails.adminId
                : 0,
            clientName: data.name,
            countryCode: data.countryCode === "" ? "+91" : data.countryCode,
            designation: data.designation,
            email: data.email,
            generatedPassword:
              getClientPersonalDetails.generatedPassword != undefined
                ? getClientPersonalDetails.generatedPassword
                : "",
            isActive: true,
            jobRole: data.clientRole,
            location: data.location,
            mobileNumber: data.phoneNumber,
            userId: parseInt(user?.id),
            organisation: data.organisation,
            password:
              getClientPersonalDetails.password != undefined
                ? getClientPersonalDetails.password
                : "",
            rowId:
              getClientPersonalDetails.rowId != undefined
                ? getClientPersonalDetails.rowId
                : 0,
          } as ClientPersonalInfoRequestModel,
          authorizationToken
        );
      })();
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="col-sm-12">
        <div className="pfofile_tab_sec">
          <div id="horizontalTab">
            <div className="resp-tabs-container resp-tab-contents">
              {/*---------Start-------------------*/}
              <div>
                <div className="profile_top">
                  <h1>Personal Info</h1>
                  <div className="profile_edit">
                    <a
                      onClick={() => {
                        setDisabled(!disabled);
                      }}
                    >
                      <i className="fa fa-pencil" aria-hidden="true" />
                    </a>
                  </div>
                </div>
                <div>
                  <form
                    className="personal_details"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                  >
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="col-sm-5">
                            <label> Name</label>
                          </div>
                          <div className="col-sm-7">
                            <input
                              type="email"
                              name="name"
                              className="form-control"
                              placeholder=""
                              disabled={disabled}
                              ref={register({
                                required: "Name is required.",
                                pattern: {
                                  value: /^[a-zA-Z ]*$/,
                                  message:
                                    "Name must have words only with spaces between them.",
                                },
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="name"
                              render={({ message }) => (
                                <div className="login_validation">
                                  {message}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="col-sm-5">
                            <label> Role </label>
                          </div>
                          <div className="col-sm-7">
                            <input
                              disabled={disabled}
                              name="clientRole"
                              type="email"
                              className="form-control"
                              placeholder=""
                              ref={register({
                                required: "Role is required.",
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="clientRole"
                              render={({ message }) => (
                                <div className="login_validation">
                                  {message}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="col-sm-5">
                            <label> Location</label>
                          </div>
                          <div className="col-sm-7">
                            <input
                              name="location"
                              ref={register({
                                //required: "Location is required.",
                              })}
                              type="email"
                              className="form-control"
                              placeholder=""
                              disabled={disabled}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="col-sm-5">
                            <label> Designation</label>
                          </div>
                          <div className="col-sm-7">
                            <input
                              name="designation"
                              ref={register({
                                required: "Designation is required.",
                              })}
                              type="email"
                              className="form-control"
                              placeholder=""
                              disabled={disabled}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="designation"
                              render={({ message }) => (
                                <div className="login_validation">
                                  {message}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="col-sm-5">
                            <label> Phone</label>
                          </div>
                          <div className="col-sm-7">
                            <Controller
                              control={control}
                              name="countryCode"
                              render={({ onChange, onBlur, value, name }) => (
                                <PhoneNumberWithCode
                                  // codeHeight={"50px"}
                                  noPenIcon={true}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  name={name}
                                  disabled={disabled}
                                  phoneNo={
                                    <input
                                      type="text"
                                      name="phoneNumber"
                                      disabled={disabled}
                                      id="phoneNumber"
                                      className="form-control phoneNumber"
                                      placeholder="Type here"
                                      ref={register({
                                        required: "Mobile number is required",
                                        maxLength: {
                                          value:
                                            watch("countryCode").length > 3
                                              ? 9
                                              : 10,
                                          message: `It must be ${
                                            watch("countryCode").length > 3
                                              ? 9
                                              : 10
                                          } digits only.`,
                                        },
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Mobile number should be numbers only",
                                        },
                                        minLength: {
                                          value:
                                            watch("countryCode").length > 3
                                              ? 9
                                              : 10,
                                          message: `It must be ${
                                            watch("countryCode").length > 3
                                              ? 9
                                              : 10
                                          } digits only.`,
                                        },
                                      })}
                                    />
                                  }
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="phoneNumber"
                              render={({ message }) => (
                                <div className="login_validation">
                                  {message}
                                </div>
                              )}
                            />
                          </div>
                          <div className="profile_edit1">
                            {disabled ? null : (
                              <a
                                onClick={() => {
                                  setIsOpen3(!isOpen3);
                                }}
                                data-target="#edit_contactnumber"
                                data-toggle="modal"
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="col-sm-5">
                            <label> Organisation</label>
                          </div>
                          <div className="col-sm-7">
                            <input
                              type="email"
                              className="form-control"
                              disabled={disabled}
                              name="organisation"
                              ref={register({
                                required: "Organisation is required.",
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="organisation"
                              render={({ message }) => (
                                <div className="login_validation">
                                  {message}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="col-sm-5">
                            <label> Email</label>
                          </div>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className={"form-control input-name"}
                              placeholder=""
                              name="email"
                              ref={(e: HTMLInputElement) => {
                                register(e, {
                                  required: "Email is required",
                                  pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Should be a valid email",
                                  },
                                });
                                emailRef.current = e;
                              }}
                              disabled={disabled}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="email"
                              render={({ message }) => (
                                <div className="login_validation">
                                  {message}
                                </div>
                              )}
                            />
                          </div>
                          <div className="profile_edit1">
                            {disabled ? null : (
                              <a
                                onClick={() => {
                                  setIsOpen2(!isOpen2);
                                }}
                                data-target="#edit_email"
                                data-toggle="modal"
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="col-sm-5">
                            <label> About Me</label>
                          </div>
                          <div className="col-sm-7">
                            <textarea
                              rows={4}
                              ref={register({
                                required: "About Me is required.",
                              })}
                              name="aboutMe"
                              className="form-control form-contro11"
                              placeholder=""
                              defaultValue={""}
                              disabled={disabled}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="aboutMe"
                              render={({ message }) => (
                                <div className="login_validation">
                                  {message}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <button
                      disabled={disabled}
                      type="submit"
                      className="btn submit_btn"
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
              {/*---------End-------------------*/}
            </div>
          </div>
        </div>
        <Modal
          show={isOpen3}
          onHide={() => {
            setIsOpen3(!isOpen3);
          }}
        >
          <EditContact2
            setIsOpen={setIsOpen3}
            isOpen={isOpen3}
            updateNumber={updateNumber}
            oldPhoneNumber={phoneData}
            oldCountryCode={phoneCountryData}
          />
        </Modal>

        <Modal
          show={isOpen2}
          onHide={() => {
            setIsOpen2(!isOpen2);
          }}
        >
          <EditEmail2
            setIsOpen2={setIsOpen2}
            isOpen2={isOpen2}
            updateEmail={updateEmail}
          />
        </Modal>
      </div>
    </>
  );
};

export default PersonalInfo;
