import React from "react";
import {
  getMyProfileSelectBoxList,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import {
  getAdminLoggedUserId,
  getAdminMyProfileBasicInfo,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import AuthService from "../../../services/AuthService";
import AdminCandidateSearchComponent from "./AdminCandidateSearchComponent";

const AdminCandidateSearchContainer = (props: any) => {
  const myProfileDispatcher = useMyProfileDispatcher();
  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileContext = useMyProfileContext();
  const { myProfile } = myProfileContext;
  const authorizationToken = AuthService.accessToken;
  const userId1 = Number(AuthService.currentUser?.id);
  let user = AuthService.currentUser;

  //console.log(911, myProfile);
  React.useEffect(() => {
    if (1) {
      (async () => {
        await getMyProfileSelectBoxList(myProfileDispatcher);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null) {
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          parseInt(user.id),
          authorizationToken
        );
      })();
    }
  }, [authorizationToken]);

  return (
    <>
      <AdminCandidateSearchComponent
        myProfile={myProfile}
        basicInfo={props.basicInfo}
      />
    </>
  );
};

export default AdminCandidateSearchContainer;
