import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
// import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
//test - 12345678
import "./css/bootstrap.min.css";
import "./css/jit.css";
import "./css/bootstrap-dropdownhover.min.css";
import "./css/easy-responsive-tabs.css";
import "./css/menu_side.css";
import "./css/jit_custom.css";
import "./css/jit_custom_admin.css";
import "./css/owl.carousel.css";
import "./css/owl.theme.css";
import "./font/css/font-awesome.css";
import "./font/css/google.font.montserrat.css";
import "./font/css/google.font.roboto.css";
import "react-toastify/dist/ReactToastify.css";

import "./css/react_app_custom.css";
import "./css/intlInputPhone.css";
import "antd/dist/antd.css";

import "./css/jit_admin.css";
import HelmetMetaData from "./component/HelmetMetaData";

// require("./css/bootstrap.min.css");
// require("./css/jit.css");
// require("./css/bootstrap-dropdownhover.min.css");
// require("./css/bootstrap-multiselect.css");
// require("./css/bootstrap-select.min.css");
// require("./css/easy-responsive-tabs.css");
// require("./css/menu_side.css");
// require("./css/jit_custom.css");
// require("./css/list.css");
// require("./css/owl.carousel.css");
// require("./css/owl.theme.css");
// require("./css/pikaday.css");
// require("./css/prettify.min.css");
// require("./css/stylesheet-pure-css.css");
// require("./css/uploading.css");
// require("./font/css/font-awesome.css");

ReactDOM.render(
  <HashRouter>
    <HelmetMetaData></HelmetMetaData>
    <App />
  </HashRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
