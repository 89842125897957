import React, { useEffect, useRef, useState } from "react";

import Pdf from "react-to-pdf";
import { getDocumentStatus, getProfileProgress } from "../../../apis/vendor";
import {
  useSocialAccountContext,
  useVendorContext,
  useVendorProfileImageContext,
} from "../../../context/vendor/VendorMyProfile";
import { GetAdminCandidateResultModel } from "../../../models/admin/AdminJobApplication";
import JobApplicationProfileComponent from "./JobApplicationProfileComponent";

interface IJobApplicationProfile {
  handleDisplayModeChange: (data: any) => void;
  initialUserId: number;
  userId: number;
  AdminCandidateResult: GetAdminCandidateResultModel;
}

const JobApplicationProfileContainer: React.FC<IJobApplicationProfile> = (
  props
) => {
  const VendorContext = useVendorContext();
  const socialAccountContext = useSocialAccountContext();
  const VendorProfileImageContext = useVendorProfileImageContext();

  const { getVendors, vendorState } = VendorContext;
  const { socialAccounts, getSocialAccounts } = socialAccountContext;
  const {
    vendorProfileImage,
    getVendorProfileImage,
  } = VendorProfileImageContext;

  const [progressBar, setProgressBar] = useState({
    profileProgress: 0,
    resumeProgress: 0,
  });
  const [documentStatus, setDocumentStatus] = useState({});

  const {
    initialUserId,
    userId,
    handleDisplayModeChange,
    AdminCandidateResult,
  } = props;

  useEffect(() => {
    if (initialUserId && userId == 2) {
      // console.log(initialUserId,'@@@@@/////////// ',vendorState);
      getVendors({
        VendorId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      });

      getProfileProgress({
        VendorId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log(
            // "@@@@@@@@@@@@@@@vendorstate selected candidate@@@@@@@@@ ",
            res.data
          );
          setProgressBar(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      getDocumentStatus({
        VendorId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log(
            // "@@@@@@@@@@@@@@@uploaded-document-status@@@@@@@@@ ",
            res.data
          );
          setDocumentStatus(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      getSocialAccounts({
        VendorId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      if (vendorProfileImage.data.length <= 0) {
        getVendorProfileImage({
          VendorId: Number(initialUserId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "string",
          SortOrder: "string",
          ShowInactive: false,
        });
      }
    }
  }, [initialUserId]);

  return (
    <>
      <JobApplicationProfileComponent
        handleDisplayModeChange={handleDisplayModeChange}
        initialUserId={initialUserId}
        userId={userId}
        vendorState={vendorState}
        vendorSocialAccounts={socialAccounts}
        vendorProfileImage={vendorProfileImage}
        vendorDocumentStatus={documentStatus}
        progressBarVendor={progressBar}
        AdminCandidateResult={AdminCandidateResult}
      />
    </>
  );
};

export default JobApplicationProfileContainer;
