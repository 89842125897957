import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { JobStatusDeleteResult, JobStatusGetAllRespondModel } from "../../models/vendor/VendorJobStatus";
import { IVendorJobStatusAction, vendorJobStatusReducer } from "../../action/vendor/VendorJobStatusAction";



export const VendorJobStatusDispatcherContext = React.createContext<React.Dispatch<IVendorJobStatusAction> | null>(
  null
);
export const VendorJobStatusStateContext = React.createContext<IVendorJobStatusManagementState | null>(
  null
);

export interface IVendorJobStatusManagementProps extends RouteComponentProps<any> {}

export interface IVendorJobStatusManagementState {
  // visible: boolean;
  // value: number;
  // vendorGetAllJobRequestModel: VendorGetAllJobRequestModel;
  //  vendorJobStatusSaveRespond: VendorJobStatusRespondModal;
  //  vendorGetAllJobList:VendorGetAllJobRespondModel,
    vendorGetAllJobStatus:JobStatusGetAllRespondModel,
    // bookmarkSaveResult:VendorJobStatusRespondModel
    vendorJobStatusDeleteResult :JobStatusDeleteResult


}

export const initialVendorJobStatusManagementState = {
  
  // vendorGetAllJobRequestModel: {} as VendorGetAllJobRequestModel,
  //  vendorJobStatusSaveRespond: {} as VendorJobStatusRespondModal,
  //  vendorGetAllSuggestedJob:{} as GetAllSuggestedJobRespondModel,
  //  vendorGetAllJobList:{} as VendorGetAllJobRespondModel,
  vendorGetAllJobStatus:{} as JobStatusGetAllRespondModel,
  // bookmarkSaveResult:{} as VendorJobStatusRespondModel
  vendorJobStatusDeleteResult:{} as JobStatusDeleteResult
} as IVendorJobStatusManagementState;

export const VendorJobStatusContextProvider: React.FC = ({ children }) => {
  const [vendorJobStatusState, dispatcher] = useImmerReducer(
    vendorJobStatusReducer,
    initialVendorJobStatusManagementState
  );

  return (
    <VendorJobStatusDispatcherContext.Provider value={dispatcher}>
      <VendorJobStatusStateContext.Provider value={vendorJobStatusState}>
        {children}
      </VendorJobStatusStateContext.Provider>
    </VendorJobStatusDispatcherContext.Provider>
  );
};
