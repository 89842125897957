import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  SocialAccountUrlRequestModel,
  SocialAccountUrl,
  SocialAccountUrlViewModel,
  SocialAccountUrlSaveRequestModel,
  SocialAccountUrlSaveRespondModel,
  SocialAccountUrlEditRequestModel,
  SocialAccountUrlResultModel,
  SocialAccountUrlGetDataWithId,
  SocialAccountUrlSelectBoxDataViewModel,
  SocialAccountUrlDeleteRequestModel,
  SocialAccountUrlDeleteResultModel
} from "../../models/admin/SocialAccountUrl";
import {
  ISocialAccountUrlManagementState,
  SocialAccountUrlDispatcherContext,
  SocialAccountUrlStateContext,
} from "../../context/admin/SocialAccountUrlContext";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type ISocialAccountUrlAction =
  | {
      type: "SocialAccountUrl_LIST";
      SocialAccountUrl: SocialAccountUrlViewModel;
    }
  | {
      type: "SocialAccountUrl_SELECTBOX_LIST";
      SocialAccountUrlSelectBoxDataViewModel: SocialAccountUrlSelectBoxDataViewModel;
    }
  | {
      type: "GET_SocialAccountUrl";
      SocialAccountUrlResultModel: SocialAccountUrlResultModel;
    }
  | {
      type: "EDIT_SocialAccountUrl";
      SocialAccountUrlSaveRespond: SocialAccountUrlSaveRespondModel;
    }
  | {
      type: "DELETE_SocialAccountUrl";
      SocialAccountUrlDeleteResultModel: SocialAccountUrlDeleteResultModel;
    };

export const SocialAccountUrlReducer: Reducer<
  ISocialAccountUrlManagementState,
  ISocialAccountUrlAction
> = (draft, action): ISocialAccountUrlManagementState => {
  switch (action.type) {
    case "SocialAccountUrl_LIST":
      draft.SocialAccountUrl = action.SocialAccountUrl;
      return draft;
    case "GET_SocialAccountUrl":
      draft.SocialAccountUrlResultModel = action.SocialAccountUrlResultModel;
      return draft;
    case "EDIT_SocialAccountUrl":
      draft.SocialAccountUrlSaveRespond = action.SocialAccountUrlSaveRespond;
      return draft;
    case "DELETE_SocialAccountUrl":
      draft.SocialAccountUrlDeleteResultModel = action.SocialAccountUrlDeleteResultModel;
      return draft;
    case "SocialAccountUrl_SELECTBOX_LIST":
      draft.SocialAccountUrlSelectBoxDataViewModel = action.SocialAccountUrlSelectBoxDataViewModel;
      return draft;
  }
};

export const useSocialAccountUrlDispatcher = (): React.Dispatch<ISocialAccountUrlAction> => {
  const SocialAccountUrlDispatcher = React.useContext(SocialAccountUrlDispatcherContext);
  if (!SocialAccountUrlDispatcher) {
    throw new Error(
      "You have to provide the SocialAccountUrl dispatcher using theSocialAccountUrlDispatcherContext.Provider in a parent component."
    );
  }
  return SocialAccountUrlDispatcher;
};

export const useSocialAccountUrlContext = (): ISocialAccountUrlManagementState => {
  const SocialAccountUrlContext = React.useContext(SocialAccountUrlStateContext);
  if (!SocialAccountUrlContext)
    throw new Error(
      "You have to provide the SocialAccountUrl context using the SocialAccountUrlStateContext.Provider in a parent component."
    );
  return SocialAccountUrlContext;
};

export const getSocialAccountUrlList = async (
  dispatcher: React.Dispatch<ISocialAccountUrlAction>,
  query: SocialAccountUrlRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetSocialAccountUrlList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "SocialAccountUrl_LIST", SocialAccountUrl: res.data });
    });
  } catch (e) {}
};

export const deleteSocialAccountUrl = async (
  dispatcher: React.Dispatch<ISocialAccountUrlAction>,
  query: SocialAccountUrlDeleteRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteSocialAccountUrl;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "DELETE_SocialAccountUrl", SocialAccountUrlDeleteResultModel: res.data });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Social account removed successfully.")
          : message.error(res.data.message)
        : message.error(" Some error happend on removing social account.");
    });


  } catch (e) {}
};

export const editSocialAccountUrl = async (
  dispatcher: React.Dispatch<ISocialAccountUrlAction>,
  query: SocialAccountUrlEditRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditSocialAccountUrl;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_SocialAccountUrl",
        SocialAccountUrlSaveRespond: res.data,
      });

      res.status == 200
        ? message.success("Social account is updated.")
        : message.error("There are some error happend on updating social account.");
    });
  } catch (e) {}
};

export const getSocialAccountUrl = async (
  dispatcher: React.Dispatch<ISocialAccountUrlAction>,
  query: SocialAccountUrlGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetSocialAccountUrl;
    if (query.rowId != 0) {
      axios
        .post(url, JSON.stringify(query), { headers: header })
        .then((res) => {
          dispatcher({
            type: "GET_SocialAccountUrl",
            SocialAccountUrlResultModel: res.data,
          });
        });
    } else {
      dispatcher({
        type: "GET_SocialAccountUrl",
        SocialAccountUrlResultModel: {} as SocialAccountUrlResultModel,
      });
    }
  } catch (e) {}
};

export const getSocialAccountUrlSelectBoxList = async (
  dispatcher: React.Dispatch<ISocialAccountUrlAction>,
  token: string
) => {
  try {
     if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetSocialAccountUrlSelectBoxData;

    axios.post(url, JSON.stringify({}), { headers: header }).then((res) => {
      dispatcher({ type: "SocialAccountUrl_SELECTBOX_LIST", SocialAccountUrlSelectBoxDataViewModel: res.data });
    });
  } catch (e) {}
};