import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";

import {
  DashboardSaveRespond,
  GetAllDashboardRespond,
  GetAllDashboardRespondModel,
} from "../../models/candidate/Dashboard";
import {
  dashboardReducer,
  IDashboardAction,
} from "../../action/candidate/DashboardsAction";

export const DashboardDispatcherContext = React.createContext<React.Dispatch<IDashboardAction> | null>(
  null
);
export const DashboardStateContext = React.createContext<IDashboardManagementState | null>(
  null
);

export interface IDashboardManagementProps extends RouteComponentProps<any> {}

export interface IDashboardManagementState {
  // getAllDashboardRespond: GetAllDashboardRespond;
  dashboardRespond: GetAllDashboardRespond;
  dashboardSaveRespond: DashboardSaveRespond;
}

export const initialDashboardManagementState = {
  // getAllDashboardRespond: {} as GetAllDashboardRespond,
  dashboardRespond: {} as GetAllDashboardRespond,
  dashboardSaveRespond: {} as DashboardSaveRespond,
} as IDashboardManagementState;

export const DashboardContextProvider: React.FC = ({ children }) => {
  const [dashboardState, dispatcher] = useImmerReducer(
    dashboardReducer,
    initialDashboardManagementState
  );

  return (
    <DashboardDispatcherContext.Provider value={dispatcher}>
      <DashboardStateContext.Provider value={dashboardState}>
        {children}
      </DashboardStateContext.Provider>
    </DashboardDispatcherContext.Provider>
  );
};
