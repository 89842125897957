import { ErrorMessage } from "@hookform/error-message";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import DragAndDrop from "./my_profile/components/DragAndDrop";
import FileUploads from "./my_profile/components/FileUploads";
import PikadayDatepicker from "./my_profile/components/PikadayDatepicker";
import SelectOption from "./my_profile/components/SelectOption";
import { Popconfirm, Popover, Tooltip } from "antd";

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IAttachDocumentState {
  docName: string;
  docType: string;
  docNumber: string;
  docExpiry: number;
  docRadio: string;
}

function AttachDocument({
  isOpen2,
  setIsOpen2,
  candidateId,
  onSave,
  digiLockerType,
  digiLockers,
  docNameProp,
  docTypeProp,
  docNumberProp,
  docExpiryProp,
  docRadioProp,
}: {
  onSave: (a: any) => void;
  isOpen2: any;
  setIsOpen2: any;
  candidateId: any;
  digiLockerType: any;
  digiLockers?: any;
  docNameProp: any;
  docTypeProp: any;
  docNumberProp: any;
  docExpiryProp: any;
  docRadioProp: any;
}) {
  //console.log(digiLockerType);
  //console.log(digiLockers);

  // const defaultValues = {
  //   docName: "",
  //   docType: "",
  //   docNumber: "",
  //   docExpiry: 0,
  //   docRadio: "no",
  // };

  const defaultValues = {
    docName: docNameProp,
    docType: docTypeProp,
    docNumber: docNumberProp,
    docExpiry: docExpiryProp,
    docRadio: docRadioProp,
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<IAttachDocumentState>({
    defaultValues,
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    errors: errors2,
    setValue: setValue2,
    getValues: getValues2,
    control: control2,
    reset: reset2,
  } = useForm<{ docRadioButton: string }>({
    defaultValues: {
      docRadioButton: "",
    },
  });

  const watchAllFields = watch2();
  //console.log(3434, watchAllFields.docRadioButton);
  // useEffect(() => {
  //   if (digiLockerType.data.length > 0) {
  //     setValue("rowId", digiLockerType.data[0]["rowId"]);
  //   }
  // }, [reset, digiLockerType.data.length]);

  let _docTypes: ReactSelectOption[] = digiLockerType.data.map((e: any) => {
    return { value: e["rowId"], label: e["title"] };
  });

  const [isNewDocument, setIsNewDocument] = useState<boolean>(true);

  const [isFirstTab, setIsFirstTab] = useState<boolean>(true);

  const [obj, setObj] = useState<any>({ file: null });

  const handleDrop = (files: any) => {
    if (
      ["image/jpeg", "application/pdf"].includes(files[0].type) &&
      files[0].size < 1000000 * 5
    ) {
      //console.log(files[0]);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
    }
  };

  //console.log(obj);

  const onSubmit = (data: any) => {
    //console.log(data);
    //console.log(obj);
    data["docFile"] = obj.file;
    // const formData = new FormData();
    // formData.append("CandidateId", candidateId);
    // formData.append("Document", obj.file);

    // //console.log(formData);

    // for (var key of (formData as any).entries()) {
    //   //console.log(key[0] + ", " + key[1]);
    // }

    onSave(data);
  };

  const onSubmit2 = (data: any) => {
    //console.log(isFirstTab, data, isNewDocument);

    data["fromLocker"] = true;
    data["digiDocumentDetailId"] = Number(data["docRadioButton"]);

    onSave(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Modal.Header closeButton>
        <Modal.Title>Attach Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-sm-12">
          <div id="horizontalTab4">
            <ul className="resp-tabs-list">
              <li
                onClick={() => {
                  setIsNewDocument(!isNewDocument);
                }}
                className={
                  isNewDocument
                    ? "resp-tab-item resp-tab-active"
                    : "resp-tab-item"
                }
              >
                Upload New Document
              </li>
              <li
                onClick={() => {
                  setIsNewDocument(!isNewDocument);
                }}
                className={
                  !isNewDocument
                    ? "resp-tab-item resp-tab-active"
                    : "resp-tab-item"
                }
              >
                Choose From Digiloker
              </li>
            </ul>
            <div className="clearfix" />
            <div className="resp-tabs-container resp-tab-contents m_t_20">
              <div
                style={{ display: !isNewDocument ? "none" : "block" }}
                className="pad_t_15"
              >
                <div className="col-sm-5">
                  <div className="form-group">
                    <label htmlFor="email" className={"required"}>
                      Name of the Document
                    </label>
                    <input
                      type="text"
                      name="docName"
                      className="form-control"
                      placeholder="Type here"
                      ref={register({
                        required: "Name is required",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="docName"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className={"required"}>
                      Document Type
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        rules={{
                          required: "Doc type is required",
                        }}
                        control={control}
                        name="docType"
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={_docTypes}
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors}
                      name="docType"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Document Number</label>
                    <input
                      type="text"
                      name="docNumber"
                      className="form-control"
                      placeholder="Type here"
                      ref={register({
                        required: false,
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="docNumber"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Expiry Date</label>
                    {/* <input type="text" className="form-control " /> */}
                    <Controller
                      // rules={{
                      //   validate: (value) => {
                      //     return value < Date.now() ? undefined : "DOB cannot be a future date.";
                      //   }
                      // }}
                      control={control}
                      name="docExpiry"
                      render={({ onChange, onBlur, value, name }) => {
                        //console.log(value);
                        return (
                          <PikadayDatepicker
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        );
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="docExpiry"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-xs-6">
                        <label htmlFor="email">Reminder on expiry?</label>
                      </div>
                      <div className="col-xs-3">
                        <input
                          id="radio1"
                          type="radio"
                          name="docRadio"
                          value="yes"
                          ref={register}
                        />
                        <label htmlFor="radio1">
                          <span>
                            <span />
                          </span>
                          Yes
                        </label>
                      </div>
                      <div className="col-xs-3">
                        <input
                          id="radio2"
                          type="radio"
                          name="docRadio"
                          value="no"
                          ref={register}
                        />
                        <label htmlFor="radio2">
                          <span>
                            <span />
                          </span>
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-1" />
                <div className="col-sm-5">
                  <div>
                    <img
                      src={require("./../../images/upload_img1.jpg")}
                      className="center-block img-responsive"
                    />
                  </div>

                  {/* <div className="box1">
                    <FileUploads />
                    <label htmlFor="file-1"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={17} viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg> <span>Upload Document</span></label>
                  </div> */}

                  <FileUploads
                    accept=".pdf,.jpg,.jpeg"
                    onChange={(file: any) => {
                      handleDrop([file]);
                    }}
                    name="Upload Document"
                    disabled={false}
                  />

                  {obj.file ? "" : <div className="update_con">OR</div>}

                  <DragAndDrop handleDrop={handleDrop}>
                    {obj.file ? (
                      <div
                        className="update_con"
                        style={{ width: 268, height: 105 }}
                      >
                        {obj.file.name}
                        {"  "}
                        <Popconfirm
                          title={
                            <div>
                              <p>Are you sure to remove this attachment </p>
                              {/* Name: {`${e.resumeName}`} */}
                              <br />
                              {/* Last Modified: {`${e.updatedDate}`} */}
                            </div>
                          }
                          onConfirm={() =>
                            //handleDeleted(document.rowId)
                            //removeResume(e, i)
                            setObj({ file: null })
                          }
                          //onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <a>
                            {" "}
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </a>
                        </Popconfirm>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="update_con">
                          Drag and drop your file here{" "}
                        </div>
                        <div className="update_con">
                          Acceptable file formats:PDF and JPEG
                        </div>
                        <div className="update_con">
                          Maximum file size: 5 MB.{" "}
                        </div>
                      </React.Fragment>
                    )}
                  </DragAndDrop>
                </div>
              </div>
              <div
                style={{ display: isNewDocument ? "none" : "block" }}
                className="pad_t_15"
              >
                <div id="tabs2" className="c-tabs">
                  <div className="c-tabs-nav">
                    <a
                      onClick={() => {
                        setIsFirstTab(!isFirstTab);
                      }}
                      className={
                        isFirstTab
                          ? "c-tabs-nav__link is-active _cursor-pointer"
                          : "c-tabs-nav__link _cursor-pointer"
                      }
                    >
                      Pesonal Document
                    </a>
                    <a
                      onClick={() => {
                        setIsFirstTab(!isFirstTab);
                      }}
                      className={
                        !isFirstTab
                          ? "c-tabs-nav__link is-active _cursor-pointer"
                          : "c-tabs-nav__link _cursor-pointer"
                      }
                    >
                      General Document
                    </a>
                  </div>
                  <div className="c-tab is-active">
                    <div className="c-tab__content">
                      {isFirstTab ? (
                        <>
                          {digiLockerType.data != undefined &&
                            digiLockers.data != undefined &&
                            digiLockers.data
                              .filter(
                                (data: any) =>
                                  data.digiDocumentTypeId ===
                                  digiLockerType.data[0].rowId
                              )
                              .map((data: any, i: any) => {
                                return (
                                  <div>
                                    <input
                                      id="docRadioButton"
                                      type="radio"
                                      name="docRadioButton"
                                      value={data.rowId + ""}
                                      ref={register2}
                                    />
                                    <label htmlFor="docRadioButton">
                                      <span>
                                        <span />
                                      </span>
                                      {data.name}
                                    </label>
                                  </div>
                                );
                              })}
                        </>
                      ) : (
                        <>
                          {digiLockerType.data != undefined &&
                            digiLockers.data != undefined &&
                            digiLockers.data
                              .filter(
                                (data: any) =>
                                  data.digiDocumentTypeId ===
                                  digiLockerType.data[1].rowId
                              )
                              .map((data: any, i: any) => {
                                return (
                                  <div>
                                    <input
                                      id="docRadioButton"
                                      type="radio"
                                      name="docRadioButton"
                                      value={data.rowId + ""}
                                      ref={register2}
                                    />
                                    <label htmlFor="docRadioButton">
                                      <span>
                                        <span />
                                      </span>
                                      {data.name}
                                    </label>
                                  </div>
                                );
                              })}
                        </>
                      )}
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="docRadioButton"
                      render={({ message }) => (
                        <div className="login_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer  m-t-30">
        {isNewDocument || watchAllFields.docRadioButton === "" ? (
          <button
            disabled={obj.file ? false : true}
            className="btn btn-success save-event waves-effect waves-light"
            type="submit"
          >
            Save
          </button>
        ) : (
          <button
            onClick={handleSubmit2(onSubmit2)}
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
          >
            Save
          </button>
        )}
        <button
          onClick={() => {
            setIsOpen2(!isOpen2);
          }}
          className="btn btn-default waves-effect"
          type="button"
        >
          Cancel
        </button>
      </div>
      <div className="clearfix" />
    </form>
  );
}

export default AttachDocument;
