import { indexOf } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
// import { useAdminMyProfileContext } from "../../../action/MyProfileAdminAction";
import {
  getBankDetails,
  getCandidateAchievements,
  getCandidateExperiences,
  getCandidateLanguageMaps,
  getCandidateOtherCertificates,
  getCandidateReferences,
  getCandidateRelatives,
  getCandidateSkills,
  getDigiDocumentDetails,
  getEducationQualifications,
  getOneDigiDocumentDetails,
  getOnePassportInformation,
  getOneSeamanBookCdc,
  getPassportInformation,
  getProfileProgress,
  getSeamanBookCdc,
  getSocialAccounts,
  getTrainings,
} from "../../../apis/candidate";
import {
  useBankDetailsContext,
  useCandidateContext,
  useCandidateExperienceContext,
  useCandidateLanguageMapsContext,
  useCandidateProfileImageContext,
  useCandidateReferencesContext,
  useCandidateRelativesContext,
  useCandidateSkillsContext,
  useEducationQualificationContext,
  usePassportInformationContext,
  useSocialAccountContext,
  useTrainingContext,
} from "../../../context/candidate/CandidateMyProfile";
import JobApplicationProfileToPdf from "./JobApplicationProfileToPdf";
import _ from "lodash";
import { environment } from "../../../environments/environment";
import {
  getAdminPartResumeReport,
  getAdminResumeReport,
  SetAllResumeReport,
  useAdminJobApplicationDispatcher,
} from "../../../action/admin/AdminJobApplicationAction";
import {
  AdmincandidatePersonalInfo,
  GetAdminCandidateResultModel,
  ResumeReportGeneratorRequestModel,
} from "../../../models/admin/AdminJobApplication";
import AuthService from "../../../services/AuthService";
import axios from "axios";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Checkbox } from "antd";
type objectIndexing = {
  [key: string]: any;
};

interface IJobApplicationProfileComponentProps {
  handleDisplayModeChange: (data: any) => void;
  initialUserId: number;
  userId: number;
  AdminCandidateResult: GetAdminCandidateResultModel;
  vendorState: {
    data: never[];
    loading: boolean;
    error: null;
  };
  vendorSocialAccounts: {
    data: never[];
    loading: boolean;
    error: null;
  };
  vendorProfileImage: {
    data: never[];
    loading: boolean;
    error: null;
  };
  vendorDocumentStatus: {};
  progressBarVendor: {
    profileProgress: number;
    resumeProgress: number;
  };
}

const JobApplicationProfileComponent: React.FC<IJobApplicationProfileComponentProps> = (
  props
) => {
  const {
    handleDisplayModeChange,
    initialUserId,
    userId,
    vendorDocumentStatus,
    vendorProfileImage,
    vendorSocialAccounts,
    vendorState,
    progressBarVendor,
    AdminCandidateResult,
  } = props;
  let authorizationToken = AuthService.accessToken;
  const refOne = useRef<any>();
  const candidateContext = useCandidateContext();
  const candidateProfileImageContext = useCandidateProfileImageContext();
  const socialAccountContext = useSocialAccountContext();
  const candidateExperienceContext = useCandidateExperienceContext();
  const candidateLanguageMapsContext = useCandidateLanguageMapsContext();
  const candidateRelativesContext = useCandidateRelativesContext();
  const candidateReferencesContext = useCandidateReferencesContext();
  const passportInformationContext = usePassportInformationContext();
  const educationQualificationContext = useEducationQualificationContext();
  const trainingContext = useTrainingContext();
  const candidateSkillsContext = useCandidateSkillsContext();
  const bankDetailsContext = useBankDetailsContext();
  const {
    passportInformation,
    getPassportInformation,
  } = passportInformationContext;
  const { socialAccounts, getSocialAccounts } = socialAccountContext;
  const {
    getCandidateExperiences,
    candidateExperienceState,
  } = candidateExperienceContext;
  const {
    candidateProfileImage,
    getCandidateProfileImage,
  } = candidateProfileImageContext;
  const {
    getCandidateLanguageMaps,
    candidateLanguageMaps,
  } = candidateLanguageMapsContext;

  const {
    getCandidateRelatives,
    candidateRelativesState,
  } = candidateRelativesContext;

  const {
    getCandidateReferences,
    candidateReferences,
  } = candidateReferencesContext;

  const {
    getEducationQualifications,
    educationQualificationState,
  } = educationQualificationContext;

  const { getTrainings, trainingState } = trainingContext;

  const { getCandidateSkills, candidateSkillsState } = candidateSkillsContext;
  const { getBankDetails, bankDetailsState } = bankDetailsContext;

  const [progressBar, setProgressBar] = useState({
    profileProgress: 0,
    resumeProgress: 0,
  });
  const { getCandidates, candidateState } = candidateContext;
  const adminJobApplicationDispatcher = useAdminJobApplicationDispatcher();
  // const myProfileAdminContext = useAdminMyProfileContext();
  // const {
  //   //myProfile,
  //   // basicInfo,
  //   loggedAdminId,
  // } = myProfileAdminContext;

  const onHandleDisplayModeChange = (value: any) => {
    handleDisplayModeChange(value);
  };

  // //console.log("@@@@@///////////11 ", vendorState);
  // //console.log("@@@@@///////////12 ", vendorSocialAccounts);
  // //console.log("@@@@@///////////13 ", vendorProfileImage);
  // //console.log("@@@@@///////////14 ", progressBarVendor);
  // //console.log("@@@@@///////////15 ", vendorDocumentStatus);

  //console.log("****///////////11 ", candidateState);
  //console.log("****///////////12 ", candidateSkillsState);
  // //console.log("****///////////13 ", candidateProfileImage);
  // //console.log("****///////////14 ", progressBar);
  //console.log("****///////////14 ", candidateLanguageMaps);
  // //console.log("****///////////15 ", passportInformation);

  const [checkedListArrayForDisplay, setCheckedListArrayForDisplay] = useState(
    [] as any[]
  );
  let IdArrayForDisplay = [] as any[];

  const [
    checkedListArrayForDisplayQualification,
    setCheckedListArrayForDisplayQualification,
  ] = useState([] as any[]);
  const [isCheckedAllQualification, setIsCheckedAllQualification] = useState(
    false
  );
  let IdArrayForDisplayQualification = [] as any[];
  const [isCheckedQualification, setIsCheckedQualification] = useState(false);

  // Experience

  const [
    checkedListArrayForDisplayExperience,
    setCheckedListArrayForDisplayExperience,
  ] = useState([] as any[]);
  const [isCheckedAllExperience, setIsCheckedAllExperience] = useState(false);
  let IdArrayForDisplayExperience = [] as any[];
  const [isCheckedExperience, setIsCheckedExperience] = useState(false);

  // training

  const [
    checkedListArrayForDisplayTraining,
    setCheckedListArrayForDisplayTraining,
  ] = useState([] as any[]);
  const [isCheckedAllTraining, setIsCheckedAllTraining] = useState(false);
  let IdArrayForDisplayTraining = [] as any[];
  const [isCheckedTraining, setIsCheckedTraining] = useState(false);

  // skill

  const [
    checkedListArrayForDisplaySkill,
    setCheckedListArrayForDisplaySkill,
  ] = useState([] as any[]);
  const [isCheckedAllSkill, setIsCheckedAllSkill] = useState(false);
  let IdArrayForDisplaySkill = [] as any[];
  const [isCheckedSkill, setIsCheckedSkill] = useState(false);

  // Document

  const [
    checkedListArrayForDisplayDocument,
    setCheckedListArrayForDisplayDocument,
  ] = useState([] as any[]);
  const [isCheckedAllDocument, setIsCheckedAllDocument] = useState(false);
  let IdArrayForDisplayDocument = [] as any[];
  const [isCheckedDocument, setIsCheckedDocument] = useState(false);

  const [isDisplay, setIsDisplay] = useState<number>(0);
  const [showPdf, setShowPdf] = useState<number>(0);

  const [
    onePassportInformation,
    setOnePassportInformation,
  ] = useState<objectIndexing>({});

  const [
    onePassportDocumentInformation,
    setOnePassportDocumentInformation,
  ] = useState<objectIndexing>({});

  const [oneSeamanBookCdc, setOneSeamanBookCdc] = useState<objectIndexing>({});

  const [
    oneSeamanBookCdcDocument,
    setOneSeamanBookCdcDocument,
  ] = useState<objectIndexing>({});

  const [
    oneResidenceDocument,
    setOneResidenceDocument,
  ] = useState<objectIndexing>({});

  const [oneAadharDocument, setOneAadharDocument] = useState<objectIndexing>(
    {}
  );
  const [oneBankDocument, setOneBankDocument] = useState<objectIndexing>({});

  const [onePanDocument, setOnePanDocument] = useState<objectIndexing>({});

  const [checkedAllValue, setCheckedAllValue] = useState<boolean>(false);

  useEffect(() => {
    if (initialUserId && userId == 1) {
      // //console.log(initialUserId,'@@@@@/////////// ',vendorState);
      getCandidateExperiences({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      });

      getCandidates({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      });

      getCandidateRelatives({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getCandidateSkills({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getTrainings({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getBankDetails({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getEducationQualifications({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getCandidateAchievements({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getCandidateLanguageMaps({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getCandidateReferences({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getProfileProgress({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          //console.log(res.data);
          setProgressBar(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });

      getSocialAccounts({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      if (candidateProfileImage.data.length <= 0) {
        getCandidateProfileImage({
          CandidateId: Number(initialUserId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "string",
          SortOrder: "string",
          ShowInactive: false,
        });
      }

      getCandidateOtherCertificates({
        CandidateId: Number(initialUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      getDigiDocumentDetails({
        CandidateId: Number(initialUserId),
        PageIndex: 1,
        PageSize: 10,
        ShowInactive: false,
      });
    }
  }, [initialUserId]);

  React.useEffect(() => {
    // if (initialUserId != 0) {
    if (initialUserId) {
      getPassportInformation({
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
      });

      getSeamanBookCdc({
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
      });
    }
  }, [initialUserId]);

  useEffect(() => {
    if (candidateState.data.length > 0) {
      if (candidateState.data[0]["passportInformationId"]) {
        getOnePassportInformation({
          rowId: Number(candidateState.data[0]["passportInformationId"]),
        })
          .then((res) => {
            //console.log(res.data);
            setOnePassportInformation(res.data);
            if (res.data.digiDocumentDetailId != 0) {
              getOneDigiDocumentDetails({
                rowId: Number(res.data.digiDocumentDetailId),
              }).then((res) => {
                ////console.log(res);
                if (res.data.isSuccess == true && res.data.data != null) {
                  setOnePassportDocumentInformation(res.data);
                }
              });
            }
            //console.log(res.data.digiDocumentDetailId);
          })
          .catch((err) => {
            //console.log(err);
          });
      }
      if (candidateState.data[0]["seamanBookCdcId"]) {
        getOneSeamanBookCdc({
          rowId: Number(candidateState.data[0]["seamanBookCdcId"]),
        })
          .then((res) => {
            //console.log(res.data);
            setOneSeamanBookCdc(res.data);
            if (res.data.digiDocumentDetailId != 0) {
              getOneDigiDocumentDetails({
                rowId: Number(res.data.digiDocumentDetailId),
              }).then((res) => {
                ////console.log(res);
                if (res.data.isSuccess == true && res.data.data != null) {
                  setOneSeamanBookCdcDocument(res.data);
                }
              });
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
      if (
        candidateState.data[0]["residenceId"] &&
        candidateState.data[0]["residenceDigiDocumentDetailId"]
      ) {
        getOneDigiDocumentDetails({
          rowId: Number(
            candidateState.data[0]["residenceDigiDocumentDetailId"]
          ),
        }).then((res) => {
          //console.log(res);
          if (res.data.isSuccess == true && res.data.data != null) {
            setOneResidenceDocument(res.data);
          }
        });
      }
      if (
        candidateState.data[0]["panNo"] &&
        candidateState.data[0]["panDigiDocumentDetailId"]
      ) {
        getOneDigiDocumentDetails({
          rowId: Number(candidateState.data[0]["panDigiDocumentDetailId"]),
        }).then((res) => {
          //console.log(res);
          if (res.data.isSuccess == true && res.data.data != null) {
            setOnePanDocument(res.data);
          }
        });
      }
      if (
        candidateState.data[0]["aadharNo"] &&
        candidateState.data[0]["aadharDigiDocumentDetailId"]
      ) {
        getOneDigiDocumentDetails({
          rowId: Number(candidateState.data[0]["aadharDigiDocumentDetailId"]),
        }).then((res) => {
          //console.log(res);
          if (res.data.isSuccess == true && res.data.data != null) {
            setOneAadharDocument(res.data);
          }
        });
      }

      if (bankDetailsState.data.length > 0) {
        //console.log(bankDetailsState.data);
        getOneDigiDocumentDetails({
          rowId: Number(bankDetailsState.data[0]["digiDocumentDetailId"]),
        }).then((res) => {
          //console.log(res);
          if (res.data.isSuccess == true && res.data.data != null) {
            setOneBankDocument(res.data);
          }
        });
      }
    }
  }, [candidateState.data.length]);

  const handleChecked = (e: any, id: any) => {
    // setIsChecked(true);
    if (e.target.checked) {
      IdArrayForDisplay.push(...checkedListArrayForDisplay, id);
      setCheckedListArrayForDisplay(IdArrayForDisplay);
      //console.log("checkedListArray", checkedListArrayForDisplay);
    } else {
      IdArrayForDisplay = checkedListArrayForDisplay.filter((data) => {
        return data !== id;
      });
      setCheckedListArrayForDisplay(IdArrayForDisplay);
      //console.log("checkedListArray", checkedListArrayForDisplay);
    }
  };

  // personalInfo checked--------------
  const handleAllPersonalinfoChecked = (e: any, dataElement: any[]) => {
    //console.log(e.target.checked);
    if (e.target.checked) {
      IdArrayForDisplay = checkedListArrayForDisplay.filter(
        (item) => !dataElement.includes(item)
      );
      setCheckedListArrayForDisplay(IdArrayForDisplay);
      IdArrayForDisplay = checkedListArrayForDisplay.concat(dataElement);

      setCheckedListArrayForDisplay(IdArrayForDisplay);
      //console.log("checkedListArray", checkedListArrayForDisplay);
    } else {
      setCheckedAllValue(false);
      IdArrayForDisplay = checkedListArrayForDisplay.filter(
        (item) => !dataElement.includes(item)
      );

      setCheckedListArrayForDisplay(IdArrayForDisplay);
      //console.log("checkedListArray", checkedListArrayForDisplay);
    }
  };

  // communication checked---------

  const handleAllCommunicationChecked = (e: any, dataElement: any[]) => {
    //console.log(e.target.checked);
    if (e.target.checked) {
      IdArrayForDisplay = checkedListArrayForDisplay.filter(
        (item) => !dataElement.includes(item)
      );
      setCheckedListArrayForDisplay(IdArrayForDisplay);
      IdArrayForDisplay = checkedListArrayForDisplay.concat(dataElement);

      setCheckedListArrayForDisplay(IdArrayForDisplay);
      //console.log("checkedListArray", checkedListArrayForDisplay);
    } else {
      IdArrayForDisplay = checkedListArrayForDisplay.filter(
        (item) => !dataElement.includes(item)
      );

      setCheckedListArrayForDisplay(IdArrayForDisplay);
      //console.log("checkedListArray", checkedListArrayForDisplay);
    }
  };

  // Handle checked qualification---------------------------------------------------------
  const handleCheckedQualification = (e: any, id: any) => {
    //console.log(45, id);
    //    setIsCheckedQualification(true);
    if (e.target.checked) {
      IdArrayForDisplayQualification.push(
        ...checkedListArrayForDisplayQualification,
        id
      );
      setCheckedListArrayForDisplayQualification(
        IdArrayForDisplayQualification
      );
      //console.log("checkedListArray", checkedListArrayForDisplayQualification);
    } else {
      IdArrayForDisplayQualification = checkedListArrayForDisplayQualification.filter(
        (data) => {
          return data !== id;
        }
      );
      setCheckedListArrayForDisplayQualification(
        IdArrayForDisplayQualification
      );
      //console.log("checkedListArray", checkedListArrayForDisplayQualification);
    }
    setIsCheckedQualification(true);
  };

  const handleAllCheckedQalification = (e: any) => {
    //console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArrayForDisplayQualification = [];
      // digiLockerList
      educationQualificationState.data
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArrayForDisplayQualification.push(d["rowId"] + 200);
        });
      setCheckedListArrayForDisplayQualification(
        IdArrayForDisplayQualification
      );
      //console.log(23, IdArrayForDisplayQualification);
      setIsCheckedAllQualification(e.target.checked);
      //console.log("checkedListArray", checkedListArrayForDisplayQualification);
    } else {
      setCheckedAllValue(false);
      IdArrayForDisplayQualification = [];
      setCheckedListArrayForDisplayQualification(
        IdArrayForDisplayQualification
      );
      setIsCheckedAllQualification(e.target.checked);
      //console.log("checkedListArray", checkedListArrayForDisplayQualification);
    }
  };

  React.useEffect(() => {
    if (isCheckedQualification) {
      if (
        checkedListArrayForDisplayQualification.length ==
        educationQualificationState.data.length
      ) {
        setIsCheckedAllQualification(true);
      } else {
        setIsCheckedAllQualification(false);
      }
      setIsCheckedQualification(false);
    }
  }, [isCheckedQualification]);

  // Qualification end---------------

  // Experience Start-----------------------------------------------------------------------------
  const handleCheckedExperience = (e: any, id: any) => {
    //console.log(45, id);
    //    setIsCheckedExperience(true);
    if (e.target.checked) {
      IdArrayForDisplayExperience.push(
        ...checkedListArrayForDisplayExperience,
        id
      );
      setCheckedListArrayForDisplayExperience(IdArrayForDisplayExperience);
      //console.log("checkedListArray", checkedListArrayForDisplayExperience);
    } else {
      IdArrayForDisplayExperience = checkedListArrayForDisplayExperience.filter(
        (data) => {
          return data !== id;
        }
      );
      setCheckedListArrayForDisplayExperience(IdArrayForDisplayExperience);
      //console.log("checkedListArray", checkedListArrayForDisplayExperience);
    }
    setIsCheckedExperience(true);
  };

  const handleAllCheckedExperience = (e: any) => {
    //console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArrayForDisplayExperience = [];
      // digiLockerList
      candidateExperienceState.data
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArrayForDisplayExperience.push(d["rowId"] + 300);
        });
      setCheckedListArrayForDisplayExperience(IdArrayForDisplayExperience);
      //console.log(23, IdArrayForDisplayExperience);
      setIsCheckedAllExperience(e.target.checked);
      //console.log("checkedListArray", checkedListArrayForDisplayExperience);
    } else {
      setCheckedAllValue(false);
      IdArrayForDisplayExperience = [];
      setCheckedListArrayForDisplayExperience(IdArrayForDisplayExperience);
      setIsCheckedAllExperience(e.target.checked);
      //console.log("checkedListArray", checkedListArrayForDisplayExperience);
    }
  };

  React.useEffect(() => {
    if (isCheckedExperience) {
      if (
        checkedListArrayForDisplayExperience.length ==
        candidateExperienceState.data.length
      ) {
        setIsCheckedAllExperience(true);
      } else {
        setIsCheckedAllExperience(false);
      }
      setIsCheckedExperience(false);
    }
  }, [isCheckedExperience]);

  // Experience end---------------------------------------------------

  // training start----------------------------------------------------------
  const handleCheckedTraining = (e: any, id: any) => {
    //console.log(45, id);
    //    setIsCheckedTraining(true);
    if (e.target.checked) {
      IdArrayForDisplayTraining.push(...checkedListArrayForDisplayTraining, id);
      setCheckedListArrayForDisplayTraining(IdArrayForDisplayTraining);
      //console.log("checkedListArray", checkedListArrayForDisplayTraining);
    } else {
      IdArrayForDisplayTraining = checkedListArrayForDisplayTraining.filter(
        (data) => {
          return data !== id;
        }
      );
      setCheckedListArrayForDisplayTraining(IdArrayForDisplayTraining);
      //console.log("checkedListArray", checkedListArrayForDisplayTraining);
    }
    setIsCheckedTraining(true);
  };

  const handleAllCheckedTraining = (e: any) => {
    //console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArrayForDisplayTraining = [];
      // digiLockerList
      trainingState.data
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArrayForDisplayTraining.push(d["rowId"] + 400);
        });
      setCheckedListArrayForDisplayTraining(IdArrayForDisplayTraining);
      //console.log(23, IdArrayForDisplayTraining);
      setIsCheckedAllTraining(e.target.checked);
      //console.log("checkedListArray", checkedListArrayForDisplayTraining);
    } else {
      setCheckedAllValue(false);
      IdArrayForDisplayTraining = [];
      setCheckedListArrayForDisplayTraining(IdArrayForDisplayTraining);
      setIsCheckedAllTraining(e.target.checked);
      //console.log("checkedListArray", checkedListArrayForDisplayTraining);
    }
  };

  React.useEffect(() => {
    if (isCheckedTraining) {
      if (
        checkedListArrayForDisplayTraining.length == trainingState.data.length
      ) {
        setIsCheckedAllTraining(true);
      } else {
        setIsCheckedAllTraining(false);
      }
      setIsCheckedTraining(false);
    }
  }, [isCheckedTraining]);

  // training end---------------------------------------------------------------

  // Skill started--------------------------------------------------------------
  const handleCheckedSkill = (e: any, id: any) => {
    //console.log(45, id);
    //    setIsCheckedSkill(true);
    if (e.target.checked) {
      IdArrayForDisplaySkill.push(...checkedListArrayForDisplaySkill, id);
      setCheckedListArrayForDisplaySkill(IdArrayForDisplaySkill);
      //console.log("checkedListArray", checkedListArrayForDisplaySkill);
    } else {
      IdArrayForDisplaySkill = checkedListArrayForDisplaySkill.filter(
        (data) => {
          return data !== id;
        }
      );
      setCheckedListArrayForDisplaySkill(IdArrayForDisplaySkill);
      //console.log("checkedListArray", checkedListArrayForDisplaySkill);
    }
    setIsCheckedSkill(true);
  };

  const handleAllCheckedSkill = (e: any) => {
    //console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArrayForDisplaySkill = [];
      // digiLockerList
      candidateSkillsState.data
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArrayForDisplaySkill.push(d["rowId"] + 500);
        });
      setCheckedListArrayForDisplaySkill(IdArrayForDisplaySkill);
      //console.log(23, IdArrayForDisplaySkill);
      setIsCheckedAllSkill(e.target.checked);
      //console.log("checkedListArray", checkedListArrayForDisplaySkill);
    } else {
      setCheckedAllValue(false);
      IdArrayForDisplaySkill = [];
      setCheckedListArrayForDisplaySkill(IdArrayForDisplaySkill);
      setIsCheckedAllSkill(e.target.checked);
      //console.log("checkedListArray", checkedListArrayForDisplaySkill);
    }
  };

  React.useEffect(() => {
    if (isCheckedSkill) {
      if (
        checkedListArrayForDisplaySkill.length ==
        candidateSkillsState.data.length
      ) {
        setIsCheckedAllSkill(true);
      } else {
        setIsCheckedAllSkill(false);
      }
      setIsCheckedSkill(false);
    }
  }, [isCheckedSkill]);

  // skill end ----------------------------------------------------------------

  // Document started--------------------------------------------------------------

  const handleAllDocumentChecked = (e: any, dataElement: any[]) => {
    //console.log(e.target.checked);
    if (e.target.checked) {
      IdArrayForDisplay = checkedListArrayForDisplay.filter(
        (item) => !dataElement.includes(item)
      );
      setCheckedListArrayForDisplay(IdArrayForDisplay);
      IdArrayForDisplay = checkedListArrayForDisplay.concat(dataElement);

      setCheckedListArrayForDisplay(IdArrayForDisplay);
      //console.log("checkedListArray", checkedListArrayForDisplay);
    } else {
      IdArrayForDisplay = checkedListArrayForDisplay.filter(
        (item) => !dataElement.includes(item)
      );

      setCheckedListArrayForDisplay(IdArrayForDisplay);
      //console.log("checkedListArray", checkedListArrayForDisplay);
    }
  };

  // Document end ----------------------------------------------------------------

  const handleShowPDF = (value: number) => {
    // setShowPdf(value);

    if (1) {
      const personal = {
        CandidateName: checkedListArrayForDisplay.includes(7)
          ? candidateState.data != undefined && candidateState.data.length > 0
            ? candidateState.data[0]["firstName"]
            : ""
          : null,
        Gender: checkedListArrayForDisplay.includes(8)
          ? candidateState.data != undefined && candidateState.data.length > 0
            ? candidateState.data[0]["genderTitle"]
            : ""
          : null,
        DOB: checkedListArrayForDisplay.includes(9)
          ? candidateState.data != undefined && candidateState.data.length > 0
            ? candidateState.data[0]["dob"]
            : ""
          : null,
        MaritalStatus: checkedListArrayForDisplay.includes(10)
          ? candidateState.data != undefined && candidateState.data.length > 0
            ? candidateState.data[0]["martialStatusTitle"]
            : ""
          : null,

        Country: checkedListArrayForDisplay.includes(11)
          ? candidateState.data != undefined && candidateState.data.length > 0
            ? candidateState.data[0]["countryName"]
            : ""
          : null,
        Summary: checkedListArrayForDisplay.includes(14)
          ? candidateState.data != undefined && candidateState.data.length > 0
            ? candidateState.data[0]["profileSummary"] == null
              ? ""
              : candidateState.data[0]["profileSummary"] + ""
            : ""
          : null,
        //  -------------------
        CurrentAddress1: checkedListArrayForDisplay.includes(20)
          ? candidateState.data.length > 0
            ? candidateState.data[0]["currentAddress1"]
            : ""
          : null,
        PermanantAddress1: checkedListArrayForDisplay.includes(19)
          ? candidateState.data.length > 0
            ? candidateState.data[0]["permanantAddress1"]
            : ""
          : null,
        Email: checkedListArrayForDisplay.includes(15)
          ? candidateState.data.length > 0
            ? candidateState.data[0]["email"]
            : ""
          : null,
        Communication: "",
        CurrentAddress2: "",
        CountryCode: "",
        EmergencyContactCountryCode: "",
        EmergencyContactEmail: "",
        EmergencyContactName: "",
        EmergencyContactPhoneNumber: "",
        ImageUrl: "",
        JobTitle: checkedListArrayForDisplay.includes(13)
          ? candidateState.data != undefined && candidateState.data.length > 0
            ? candidateState.data[0]["positionApplyingFor"]
              ? candidateState.data[0]["positionApplyingFor"]
              : ""
            : ""
          : null,
        PermanantAddress2: "",
        PersonalInfo:
          checkedListArrayForDisplay.includes(9) ||
          checkedListArrayForDisplay.includes(10) ||
          checkedListArrayForDisplay.includes(801)
            ? ""
            : null,
        PhoneNumber: checkedListArrayForDisplay.includes(26)
          ? candidateState.data.length > 0
            ? candidateState.data[0]["phoneNumber"]
            : ""
          : null,
        ReferenceContactCountryCode: "",
        ReferenceContactEmail: "",
        ReferenceContactName: "",
        ReferenceContactPhoneNumber: "",
        TelephoneNumber: "",
        PassportNumber: checkedListArrayForDisplay.includes(801)
          ? candidateState.data.length > 0
            ? candidateState.data[0]["passportNo"]
            : ""
          : null,
      } as AdmincandidatePersonalInfo;

      let language =
        candidateLanguageMaps.data != undefined &&
        candidateLanguageMaps.data.length > 0
          ? candidateLanguageMaps.data.map((e: any) => {
              return parseInt(`${e["languageId"]}`);
            })
          : [0];

      let experience =
        candidateExperienceState.data != undefined &&
        candidateExperienceState.data.length > 0
          ? candidateExperienceState.data.map((e: any) => {
              if (
                checkedListArrayForDisplayExperience.includes(
                  parseInt(`${e["rowId"]}`) + 300
                )
              ) {
                return parseInt(`${e["rowId"]}`);
              }
            })
          : [0];

      let qualification =
        educationQualificationState.data != undefined &&
        educationQualificationState.data.length > 0
          ? educationQualificationState.data.map((e: any) => {
              if (
                checkedListArrayForDisplayQualification.includes(
                  parseInt(`${e["rowId"]}`) + 200
                )
              ) {
                return parseInt(`${e["rowId"]}`);
              }
            })
          : [0];

      let skill =
        candidateSkillsState.data != undefined &&
        candidateSkillsState.data.length > 0
          ? candidateSkillsState.data.map((e: any) => {
              if (
                checkedListArrayForDisplaySkill.includes(
                  parseInt(`${e["rowId"]}`) + 500
                )
              ) {
                return parseInt(`${e["rowId"]}`);
              }
            })
          : [0];

      let training =
        trainingState.data != undefined && trainingState.data.length > 0
          ? trainingState.data.map((e: any) => {
              if (
                checkedListArrayForDisplayTraining.includes(
                  parseInt(`${e["rowId"]}`) + 400
                )
              ) {
                return parseInt(`${e["rowId"]}`);
              }
            })
          : [0];

      // (async () => {
      //   await SetAllResumeReport(
      //     adminJobApplicationDispatcher,
      //     {
      //       AdmincandidatePersonalInfo: personal,
      //       CandidateId: Number(initialUserId),
      //       LanguagesId: checkedListArrayForDisplay.includes(12)
      //         ? language
      //         : [0],
      //       ExperiencsId: experience.length > 0 ? experience : [0],
      //       QualificationsId: qualification.length > 0 ? qualification : [0],
      //       SkillsId: skill.length > 0 ? skill : [0],
      //       SocialAccountsId: [0],
      //       TrainingsId: training.length > 0 ? training : [0],
      //       VendorId: 0,
      //     } as ResumeReportGeneratorRequestModel,
      //     ""
      //   );
      // })();
      //console.log(500, skill);
      if (authorizationToken?.startsWith(`"`)) {
        authorizationToken = authorizationToken.substring(1);
        authorizationToken = authorizationToken.slice(0, -1);
      }
      // getAdminPartResumeReport(
      //   {},

      //   JSON.stringify({
      //     AdmincandidatePersonalInfo: personal,
      //     CandidateId: userId == 1 ? Number(initialUserId) : 0,
      //     Id: 1,
      //     Token: `${authorizationToken}`,
      //     ReportType: 1,
      //     LanguagesId: checkedListArrayForDisplay.includes(12) ? language : [0],
      //     ExperiencsId:
      //       experience.length > 0 && experience[0] !== undefined
      //         ? experience.filter((d) => {
      //             return d != null;
      //           })
      //         : [0],

      //     QualificationsId:
      //       qualification.length > 0 && qualification[0] !== undefined
      //         ? qualification.filter((d) => {
      //             return d != null;
      //           })
      //         : [0],
      //     SkillsId:
      //       skill.length > 0 && skill[0] !== undefined
      //         ? skill.filter((d) => {
      //             return d != null;
      //           })
      //         : [0],
      //     SocialAccountsId: [0],
      //     TrainingsId:
      //       training.length > 0 && training[0] !== undefined
      //         ? training.filter((d) => {
      //             return d != null;
      //           })
      //         : [0],
      //     VendorId: userId == 1 ? 0 : Number(initialUserId),
      //   })
      // )
      getAdminResumeReport(
        JSON.stringify({
          AdmincandidatePersonalInfo: personal,
          CandidateId: userId == 1 ? Number(initialUserId) : 0,
          Id: 1,
          Token: `${authorizationToken}`,
          ReportType: 5,
          LanguagesId: checkedListArrayForDisplay.includes(12) ? language : [0],
          ExperiencsId:
            experience.length > 0 && experience[0] !== undefined
              ? experience.filter((d) => {
                  return d != null;
                })
              : [0],

          QualificationsId:
            qualification.length > 0 && qualification[0] !== undefined
              ? qualification.filter((d) => {
                  return d != null;
                })
              : [0],
          SkillsId:
            skill.length > 0 && skill[0] !== undefined
              ? skill.filter((d) => {
                  return d != null;
                })
              : [0],
          SocialAccountsId: [0],
          TrainingsId:
            training.length > 0 && training[0] !== undefined
              ? training.filter((d) => {
                  return d != null;
                })
              : [0],
          VendorId: userId == 1 ? 0 : Number(initialUserId),
        })
      )
        // axios
        //   .post(
        //     "http://localhost:44329/Report/GenerateAdminCandidateResume",
        //     JSON.stringify({
        //       model: {
        //         AdmincandidatePersonalInfo: personal,
        //         CandidateId: userId == 1 ? Number(initialUserId) : 0,
        //         Id: 1,
        //         Token: `${authorizationToken}`,
        //         ReportType: 5,
        //         LanguagesId: checkedListArrayForDisplay.includes(12)
        //           ? language
        //           : [0],
        //         ExperiencsId:
        //           experience.length > 0 && experience[0] !== undefined
        //             ? experience.filter((d) => {
        //                 return d != null;
        //               })
        //             : [0],

        //         QualificationsId:
        //           qualification.length > 0 && qualification[0] !== undefined
        //             ? qualification.filter((d) => {
        //                 return d != null;
        //               })
        //             : [0],
        //         SkillsId:
        //           skill.length > 0 && skill[0] !== undefined
        //             ? skill.filter((d) => {
        //                 return d != null;
        //               })
        //             : [0],
        //         SocialAccountsId: [0],
        //         TrainingsId:
        //           training.length > 0 && training[0] !== undefined
        //             ? training.filter((d) => {
        //                 return d != null;
        //               })
        //             : [0],
        //         VendorId: userId == 1 ? 0 : Number(initialUserId),
        //       },
        //     })
        //   )
        .then((res: any) => {
          //console.log(666, res);
          const blob = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          var FileSaver = require("file-saver");
          FileSaver.saveAs(blob, "1.docx");
          //const fileUrl = window.URL.createObjectURL(
          //  new Blob([res.data], { type: "application/pdf" })
          //setFile(new Blob([res.data], { type: 'application/pdf' }))
          // setFile(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }))
          //setIsPreview(true);
        })
        .catch((err) => {
          ////console.log(err);
          // globalSpinner.hideSpinner();
          // toast.error(err.toString());
        });
    }
  };

  function onChangeDataValues(e: any) {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      setCheckedAllValue(e.target.checked);
      setCheckedListArrayForDisplay([
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,

        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        1007,
        1008,
        1009,
        1010,
        801,
      ]);

      IdArrayForDisplayQualification = [];

      educationQualificationState.data.map((d) => {
        IdArrayForDisplayQualification.push(d["rowId"] + 200);
      });
      setCheckedListArrayForDisplayQualification(
        IdArrayForDisplayQualification
      );
      setIsCheckedAllQualification(e.target.checked);
      IdArrayForDisplayExperience = [];

      candidateExperienceState.data.map((d) => {
        IdArrayForDisplayExperience.push(d["rowId"] + 300);
      });
      setCheckedListArrayForDisplayExperience(IdArrayForDisplayExperience);

      setIsCheckedAllExperience(e.target.checked);

      // ---------
      IdArrayForDisplayTraining = [];

      trainingState.data.map((d) => {
        IdArrayForDisplayTraining.push(d["rowId"] + 400);
      });
      setCheckedListArrayForDisplayTraining(IdArrayForDisplayTraining);

      setIsCheckedAllTraining(e.target.checked);

      // -----------

      IdArrayForDisplaySkill = [];

      candidateSkillsState.data.map((d) => {
        IdArrayForDisplaySkill.push(d["rowId"] + 500);
      });
      setCheckedListArrayForDisplaySkill(IdArrayForDisplaySkill);

      setIsCheckedAllSkill(e.target.checked);
    } else {
      setCheckedAllValue(e.target.checked);
      setCheckedListArrayForDisplay([]);

      setCheckedListArrayForDisplayExperience([]);

      setIsCheckedAllExperience(e.target.checked);

      setCheckedListArrayForDisplayQualification([]);
      setIsCheckedAllQualification(e.target.checked);

      setCheckedListArrayForDisplayTraining([]);

      setIsCheckedAllTraining(e.target.checked);

      setCheckedListArrayForDisplaySkill([]);
      setIsCheckedAllSkill(e.target.checked);
    }
  }

  return (
    <>
      {userId == 1 ? (
        showPdf == 0 ? (
          <div className="content-wrapper">
            <div className="container-fluid">
              <h1 className="heading">Published</h1>
              <a
                onClick={() => onHandleDisplayModeChange(0)}
                className="btn cutomise_but"
              >
                <i className="fa fa-angle-left" aria-hidden="true" /> &nbsp;
                Back{" "}
              </a>
              {/* <Pdf targetRef={refOne} filename="div-blue.pdf">
            {({ toPdf }: any) => (
              // <button onClick={toPdf}>Generate pdf</button>
              <button onClick={toPdf} className="btn cutomise_but">
                {" "}
                Export To PDF{" "}
              </button>
            )}
          </Pdf> */}
              {/* <ReactToPrint
            trigger={() => (
              <button className="btn cutomise_but">Export To PDF</button>
            )}
            content={() => componentRef.current}
          /> */}
              <a onClick={() => handleShowPDF(1)} className="btn cutomise_but">
                {" "}
                Export Document{" "}
              </a>

              {/* <div className="action_btn1 ">
                <button type="button" className="actions_menu">
                  <i className="fa fa-align-left" aria-hidden="true" />
                </button>
                <div className="user_menu" tabIndex={-1}>
                  <div className="action_sec">
                    <ul>
                      <li>
                        <a href="#">Drafted</a>{" "}
                      </li>
                      <li>
                        <a href="#">Published </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
               */}
              <div className="clearfix" />

              <div className="row ">
                <div className="col-sm-12">
                  <div className=" section_box_m">
                    <div className="col-sm-3">
                      <div className="profile_sec_photo">
                        {/* <img
                      src="images/admin_users.jpg"
                      alt=""
                      className="image1"
                    /> */}
                        {candidateProfileImage.data.length > 0 ? (
                          <img
                            className="img-responsive"
                            src={
                              environment.baseUrl +
                              `/Upload/ProfileImage/${AdminCandidateResult.candidateImage}`
                            }
                            alt=""
                          />
                        ) : (
                          <img
                            className="img-responsive"
                            src={require("./../../../images/profileDefault1.jpg")}
                          ></img>
                        )}
                        <div className="overlay">
                          <div className="upload_photo">
                            <a
                              href="#"
                              data-target="#upload_ban"
                              data-toggle="modal"
                            >
                              <i
                                className="fa fa-picture-o"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix" />
                      {/* <div className="profile_cons1">Profile Complet</div>
                      <div className="profile_cons1">
                        {progressBar.profileProgress}%
                      </div> */}
                    </div>
                    <div className="col-sm-6">
                      <div className="social_net"> Social Networks </div>
                      {socialAccounts.data.length > 0 &&
                      socialAccounts.data[0]["facebooks"] !== "" ? (
                        <div>
                          <input
                            id="checkbox1"
                            type="checkbox"
                            name="checkbox"
                            defaultValue={1}
                            onChange={(e: any) => handleChecked(e, 1)}
                            checked={checkedListArrayForDisplay.includes(1)}
                          />
                          <label htmlFor="checkbox1">
                            <span />{" "}
                            {socialAccounts.data.length > 0
                              ? socialAccounts.data[0]["facebooks"]
                              : null}
                          </label>
                        </div>
                      ) : null}

                      {socialAccounts.data.length > 0 &&
                      socialAccounts.data[0]["google"] !== "" ? (
                        <div>
                          <input
                            id="checkbox1"
                            type="checkbox"
                            name="checkbox"
                            defaultValue={2}
                            onChange={(e: any) => handleChecked(e, 2)}
                            checked={checkedListArrayForDisplay.includes(2)}
                          />
                          <label htmlFor="checkbox1">
                            <span />{" "}
                            {socialAccounts.data.length > 0
                              ? socialAccounts.data[0]["google"]
                              : null}
                          </label>
                        </div>
                      ) : null}

                      {socialAccounts.data.length > 0 &&
                      socialAccounts.data[0]["linkedIn"] !== "" ? (
                        <div>
                          <input
                            id="checkbox1"
                            type="checkbox"
                            name="checkbox"
                            defaultValue={3}
                            onChange={(e: any) => handleChecked(e, 3)}
                            checked={checkedListArrayForDisplay.includes(3)}
                          />
                          <label htmlFor="checkbox1">
                            <span />{" "}
                            {socialAccounts.data.length > 0
                              ? socialAccounts.data[0]["linkedIn"]
                              : null}
                          </label>
                        </div>
                      ) : null}

                      {socialAccounts.data.length > 0 &&
                      socialAccounts.data[0]["twitter"] !== "" ? (
                        <div>
                          <input
                            id="checkbox1"
                            type="checkbox"
                            name="checkbox"
                            defaultValue={4}
                            onChange={(e: any) => handleChecked(e, 4)}
                            checked={checkedListArrayForDisplay.includes(4)}
                          />
                          <label htmlFor="checkbox1">
                            <span />{" "}
                            {socialAccounts.data.length > 0
                              ? socialAccounts.data[0]["twitter"]
                              : null}
                          </label>
                        </div>
                      ) : null}

                      {socialAccounts.data.length > 0 &&
                      socialAccounts.data[0]["instagram"] !== "" ? (
                        <div>
                          <input
                            id="checkbox1"
                            type="checkbox"
                            name="checkbox"
                            defaultValue={5}
                            onChange={(e: any) => handleChecked(e, 5)}
                            checked={checkedListArrayForDisplay.includes(5)}
                          />
                          <label htmlFor="checkbox1">
                            <span />{" "}
                            {socialAccounts.data.length > 0
                              ? socialAccounts.data[0]["instagram"]
                              : null}
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <Checkbox
                  checked={checkedAllValue}
                  onChange={onChangeDataValues}
                >
                  Select All
                </Checkbox>
                <div className="col-sm-12">
                  {/* persnal info start */}
                  <div className=" section_box_m">
                    <div className="ad_profile_h">
                      <div className="pull-left">
                        <input
                          id="checkbox2"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={6}
                          onChange={(e: any) =>
                            handleAllPersonalinfoChecked(e, [
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                              13,
                              14,
                            ])
                          }
                          checked={
                            checkedListArrayForDisplay.includes(7) &&
                            checkedListArrayForDisplay.includes(8) &&
                            checkedListArrayForDisplay.includes(9) &&
                            checkedListArrayForDisplay.includes(10) &&
                            checkedListArrayForDisplay.includes(11) &&
                            checkedListArrayForDisplay.includes(12) &&
                            checkedListArrayForDisplay.includes(13) &&
                            checkedListArrayForDisplay.includes(14)
                          }
                        />
                        <label htmlFor="checkbox2">
                          <span />
                        </label>
                      </div>

                      <h1>Pesonal Info</h1>
                    </div>

                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={7}
                          onChange={(e: any) => handleChecked(e, 7)}
                          checked={checkedListArrayForDisplay.includes(7)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {candidateState.data != undefined &&
                          candidateState.data.length > 0
                            ? candidateState.data[0]["firstName"]
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={8}
                          onChange={(e: any) => handleChecked(e, 8)}
                          checked={checkedListArrayForDisplay.includes(8)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {candidateState.data != undefined &&
                          candidateState.data.length > 0
                            ? candidateState.data[0]["genderTitle"]
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={9}
                          onChange={(e: any) => handleChecked(e, 9)}
                          checked={checkedListArrayForDisplay.includes(9)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {candidateState.data != undefined &&
                          candidateState.data.length > 0
                            ? candidateState.data[0]["dob"]
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={10}
                          onChange={(e: any) => handleChecked(e, 10)}
                          checked={checkedListArrayForDisplay.includes(10)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {candidateState.data != undefined &&
                          candidateState.data.length > 0
                            ? candidateState.data[0]["martialStatusTitle"]
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={11}
                          onChange={(e: any) => handleChecked(e, 11)}
                          checked={checkedListArrayForDisplay.includes(11)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {candidateState.data != undefined &&
                          candidateState.data.length > 0
                            ? candidateState.data[0]["countryName"]
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={12}
                          onChange={(e: any) => handleChecked(e, 12)}
                          checked={checkedListArrayForDisplay.includes(12)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {candidateLanguageMaps.data != undefined &&
                          candidateLanguageMaps.data.length > 0
                            ? candidateLanguageMaps.data.map(
                                (e: any) => `${e["languageName"]},`
                              )
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={13}
                          onChange={(e: any) => handleChecked(e, 13)}
                          checked={checkedListArrayForDisplay.includes(13)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {candidateState.data != undefined &&
                          candidateState.data.length > 0
                            ? candidateState.data[0]["positionApplyingFor"]
                              ? candidateState.data[0]["positionApplyingFor"]
                              : ""
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <input
                        id="checkbox14"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={14}
                        onChange={(e: any) => handleChecked(e, 14)}
                        checked={checkedListArrayForDisplay.includes(14)}
                        style={{ padding: 12 }}
                      />

                      <label htmlFor="checkbox14">
                        <span />
                        <h5>
                          {candidateState.data != undefined &&
                          candidateState.data.length > 0
                            ? candidateState.data[0]["profileSummary"] == null
                              ? ""
                              : candidateState.data[0]["profileSummary"] + ""
                            : ""}
                        </h5>
                      </label>
                    </div>
                  </div>

                  {/* persnal info end */}

                  {/* communication Start */}
                  <div className=" section_box_m">
                    <div className="ad_profile_h">
                      <div className="pull-left">
                        <input
                          id="checkbox2"
                          type="checkbox"
                          name="checkbox"
                          onChange={(e: any) =>
                            handleAllPersonalinfoChecked(e, [
                              15,
                              16,
                              // 17,
                              // 18,
                              19,
                              20,
                              21,
                              22,
                              23,
                              24,
                              25,
                              26,
                            ])
                          }
                          checked={
                            checkedListArrayForDisplay.includes(15) &&
                            checkedListArrayForDisplay.includes(16) &&
                            // checkedListArrayForDisplay.includes(17) &&
                            // checkedListArrayForDisplay.includes(18) &&
                            checkedListArrayForDisplay.includes(19) &&
                            checkedListArrayForDisplay.includes(20) &&
                            checkedListArrayForDisplay.includes(21) &&
                            checkedListArrayForDisplay.includes(22) &&
                            checkedListArrayForDisplay.includes(23) &&
                            checkedListArrayForDisplay.includes(24) &&
                            checkedListArrayForDisplay.includes(25) &&
                            checkedListArrayForDisplay.includes(26)
                          }
                        />
                        <label htmlFor="checkbox2">
                          <span />
                        </label>
                      </div>

                      {/* communication start */}
                      <h1>Communication</h1>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={26}
                          onChange={(e: any) => handleChecked(e, 26)}
                          checked={checkedListArrayForDisplay.includes(26)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {candidateState.data.length > 0
                            ? candidateState.data[0]["phoneNumber"]
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={15}
                          onChange={(e: any) => handleChecked(e, 15)}
                          checked={checkedListArrayForDisplay.includes(15)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {candidateState.data.length > 0
                            ? candidateState.data[0]["email"]
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={16}
                          onChange={(e: any) => handleChecked(e, 16)}
                          checked={checkedListArrayForDisplay.includes(16)}
                        />
                        <label htmlFor="checkbox1">
                          <span />
                          {candidateState.data.length > 0
                            ? candidateState.data[0]["telephoneNumber"]
                            : ""}
                        </label>
                      </div>
                    </div>

                    {/* <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={7}
                          onChange={(e: any) => handleChecked(e, 17)}
                          checked={checkedListArrayForDisplay.includes(17)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {candidateState.data.length > 0
                            ? candidateState.data[0]["email"]
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={18}
                          onChange={(e: any) => handleChecked(e, 18)}
                          checked={checkedListArrayForDisplay.includes(18)}
                        />
                        <label htmlFor="checkbox1">
                          <span />
                          {candidateState.data.length > 0
                            ? candidateState.data[0]["telephoneNumber"]
                            : ""}
                        </label>
                      </div>
                    </div> */}

                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={19}
                          onChange={(e: any) => handleChecked(e, 19)}
                          checked={checkedListArrayForDisplay.includes(19)}
                        />
                        <label htmlFor="checkbox1">
                          <span />
                          {candidateState.data.length > 0
                            ? candidateState.data[0]["permanantAddress1"]
                            : ""}
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={20}
                          onChange={(e: any) => handleChecked(e, 20)}
                          checked={checkedListArrayForDisplay.includes(20)}
                        />
                        <label htmlFor="checkbox1">
                          <span />
                          {candidateState.data.length > 0
                            ? candidateState.data[0]["currentAddress1"]
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label htmlFor="checkbox1">
                        <span />
                        Emergency Contact Details
                      </label>
                    </div>
                    {/* <div className="col-sm-6">
                    <div>
                      <input
                        id="checkbox1"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={21}
                        onChange={(e: any) => handleChecked(e, 21)}
                        checked={checkedListArrayForDisplay.includes(21)}
                      />
                      <label htmlFor="checkbox1">
                        <span />

                      </label>
                    </div>
                  </div> */}
                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={21}
                          onChange={(e: any) => handleChecked(e, 21)}
                          checked={checkedListArrayForDisplay.includes(21)}
                        />
                        <label htmlFor="checkbox1">
                          <span />
                          {candidateRelativesState.data.length > 0
                            ? candidateRelativesState.data[0][
                                "relativeFirstName"
                              ]
                            : ""}
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={22}
                          onChange={(e: any) => handleChecked(e, 22)}
                          checked={checkedListArrayForDisplay.includes(22)}
                        />
                        <label htmlFor="checkbox1">
                          <span />
                          {candidateRelativesState.data.length > 0
                            ? candidateRelativesState.data[0]["countryCode"]
                            : "+91"}
                          {candidateRelativesState.data.length > 0
                            ? candidateRelativesState.data[0]["phoneNumber"]
                            : ""}
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12">Reference Details</div>

                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={23}
                          onChange={(e: any) => handleChecked(e, 23)}
                          checked={checkedListArrayForDisplay.includes(23)}
                        />
                        <label htmlFor="checkbox1">
                          <span />
                          {candidateReferences.data.length > 0
                            ? candidateReferences.data[0]["referenceFirstName"]
                            : ""}
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={24}
                          onChange={(e: any) => handleChecked(e, 24)}
                          checked={checkedListArrayForDisplay.includes(24)}
                        />
                        <label htmlFor="checkbox1">
                          <span />
                          {candidateReferences.data.length > 0
                            ? candidateReferences.data[0]["countryCode"]
                            : "+91"}
                          {candidateReferences.data.length > 0
                            ? candidateReferences.data[0]["phoneNumber"]
                            : ""}
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={25}
                          onChange={(e: any) => handleChecked(e, 25)}
                          checked={checkedListArrayForDisplay.includes(25)}
                        />
                        <label htmlFor="checkbox1">
                          <span />
                          {candidateReferences.data.length > 0
                            ? candidateReferences.data[0]["email"]
                            : ""}
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* communication end */}

                  {/* Document Start */}
                  <div className=" section_box_m">
                    <div className="ad_profile_h">
                      <div className="pull-left">
                        <input
                          id="checkbox2"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={800}
                          onChange={(e: any) =>
                            handleAllPersonalinfoChecked(e, [801])
                          }
                          checked={checkedListArrayForDisplay.includes(801)}
                        />
                        <label htmlFor="checkbox2">
                          <span />
                        </label>
                      </div>
                      <h1>Document</h1>
                    </div>
                    {_.isEmpty(onePassportInformation) ? (
                      ""
                    ) : (
                      <>
                        <h1>Passport</h1>
                        <div className="col-sm-6">
                          <div>
                            <input
                              id="checkbox1"
                              type="checkbox"
                              name="checkbox"
                              defaultValue={801}
                              onChange={(e: any) => handleChecked(e, 801)}
                              checked={checkedListArrayForDisplay.includes(801)}
                            />
                            <label htmlFor="checkbox1">
                              <span /> {onePassportInformation["passportNo"]}
                            </label>{" "}
                            <label htmlFor="checkbox1">
                              <span /> {onePassportInformation["ecrStatus"]}
                            </label>
                            ,{" "}
                            <label htmlFor="checkbox1">
                              <span /> {onePassportInformation["placeIssued"]}
                            </label>
                            ,{" "}
                            <label htmlFor="checkbox1">
                              <span /> {onePassportInformation["endDate"]}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/* Document end */}

                  {/* Qualification Start */}
                  <div className=" section_box_m">
                    <div className="ad_profile_h">
                      <div className="pull-left">
                        <input
                          id="checkbox2"
                          type="checkbox"
                          name="checkbox"
                          checked={isCheckedAllQualification}
                          // defaultChecked={isCheckedAll ? true : false}
                          onChange={(e: any) => handleAllCheckedQalification(e)}
                        />
                        <label htmlFor="checkbox2">
                          <span />
                        </label>
                      </div>
                      <h1>Qualification</h1>
                    </div>

                    {educationQualificationState.data.map(
                      (data: any, i: any) => {
                        return (
                          <>
                            <div className="col-sm-6">
                              <div>
                                <input
                                  id="checkbox1"
                                  type="checkbox"
                                  name="checkbox"
                                  onChange={(e: any) =>
                                    handleCheckedQualification(
                                      e,
                                      data["rowId"] + 200
                                    )
                                  }
                                  checked={checkedListArrayForDisplayQualification.includes(
                                    data["rowId"] + 200
                                  )}
                                />
                                <label htmlFor="checkbox1">
                                  <span /> {data.course + ""}
                                </label>
                                ,{" "}
                                <label htmlFor="checkbox1">
                                  <span /> {data.university}
                                </label>
                                ,{" "}
                                <label htmlFor="checkbox1">
                                  <span /> {data.qualificationYear}
                                </label>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>

                  {/* Qualification end */}

                  {/* Experience Start */}
                  <div className=" section_box_m">
                    <div className="ad_profile_h">
                      <div className="pull-left">
                        <input
                          id="checkbox2"
                          type="checkbox"
                          name="checkbox"
                          checked={isCheckedAllExperience}
                          // defaultChecked={isCheckedAll ? true : false}
                          onChange={(e: any) => handleAllCheckedExperience(e)}
                        />
                        <label htmlFor="checkbox2">
                          <span />
                        </label>
                      </div>
                      <h1>Experience</h1>
                    </div>

                    {candidateExperienceState.data.map((data: any, i: any) => {
                      return (
                        <>
                          <div className="col-sm-6">
                            <div>
                              <input
                                id="checkbox1"
                                type="checkbox"
                                name="checkbox"
                                onChange={(e: any) =>
                                  handleCheckedExperience(
                                    e,
                                    data["rowId"] + 300
                                  )
                                }
                                checked={checkedListArrayForDisplayExperience.includes(
                                  data["rowId"] + 300
                                )}
                              />
                              <label htmlFor="checkbox1">
                                <span /> {data.employerName + ""}
                              </label>
                              ,{" "}
                              <label htmlFor="checkbox1">
                                <span /> {data.jobRole}
                              </label>
                              ,{" "}
                              <label htmlFor="checkbox1">
                                <span /> {data.responsibilities}
                              </label>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {/* Experience end */}

                  {/* Training Start */}
                  <div className=" section_box_m">
                    <div className="ad_profile_h">
                      <div className="pull-left">
                        <input
                          id="checkbox2"
                          type="checkbox"
                          name="checkbox"
                          checked={isCheckedAllTraining}
                          onChange={(e: any) => handleAllCheckedTraining(e)}
                        />
                        <label htmlFor="checkbox2">
                          <span />
                        </label>
                      </div>
                      <h1>Training</h1>
                    </div>

                    {trainingState.data.map((data: any, i: any) => {
                      return (
                        <>
                          <div className="col-sm-6">
                            <div>
                              <input
                                id="checkbox1"
                                type="checkbox"
                                name="checkbox"
                                onChange={(e: any) =>
                                  handleCheckedTraining(e, data["rowId"] + 400)
                                }
                                checked={checkedListArrayForDisplayTraining.includes(
                                  data["rowId"] + 400
                                )}
                              />
                              <label htmlFor="checkbox1">
                                <span /> {data.institute + ""}
                              </label>
                              ,{" "}
                              <label htmlFor="checkbox1">
                                <span /> {data.trainingCertificate}
                              </label>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {/*Training end  */}

                  {/* Skill Start */}
                  <div className=" section_box_m">
                    <div className="ad_profile_h">
                      <div className="pull-left">
                        <input
                          id="checkbox2"
                          type="checkbox"
                          name="checkbox"
                          checked={isCheckedAllSkill}
                          onChange={(e: any) => handleAllCheckedSkill(e)}
                        />
                        <label htmlFor="checkbox2">
                          <span />
                        </label>
                      </div>
                      <h1>Skill</h1>
                    </div>

                    {candidateSkillsState.data.map((data: any, i: any) => {
                      return (
                        <>
                          <div className="col-sm-6">
                            <div>
                              <input
                                id="checkbox1"
                                type="checkbox"
                                name="checkbox"
                                onChange={(e: any) =>
                                  handleCheckedSkill(e, data["rowId"] + 500)
                                }
                                checked={checkedListArrayForDisplaySkill.includes(
                                  data["rowId"] + 500
                                )}
                              />
                              <label htmlFor="checkbox1">
                                <span /> {data.skillName + " "}
                              </label>
                              ,{" "}
                              <label htmlFor="checkbox1">
                                <span /> {data.proficiencyTitle}
                              </label>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {/*Skill end  */}
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>
        ) : (
          <JobApplicationProfileToPdf
            candidateState={candidateState}
            socialAccounts={socialAccounts}
            candidateProfileImage={candidateProfileImage}
            progressBar={progressBar}
            handleShowPDF={handleShowPDF}
            checkedListArrayForDisplay={checkedListArrayForDisplay}
            candidateLanguageMaps={candidateLanguageMaps}
            candidateReferences={candidateReferences}
            candidateRelativesState={candidateRelativesState}
            educationQualificationState={educationQualificationState}
            checkedListArrayForDisplayQualification={
              checkedListArrayForDisplayQualification
            }
            candidateExperienceState={candidateExperienceState}
            checkedListArrayForDisplayExperience={
              checkedListArrayForDisplayExperience
            }
            trainingState={trainingState}
            checkedListArrayForDisplayTraining={
              checkedListArrayForDisplayTraining
            }
            candidateSkillsState={candidateSkillsState}
            checkedListArrayForDisplaySkill={checkedListArrayForDisplaySkill}
            onePassportInformation={onePassportInformation}
            vendorState={vendorState}
            vendorSocialAccounts={vendorSocialAccounts}
            userId={userId}
            vendorProfileImage={vendorProfileImage}
            progressBarVendor={progressBarVendor}
          />
        )
      ) : showPdf == 0 ? (
        <div className="content-wrapper">
          <div className="container-fluid">
            <h1 className="heading">Published</h1>
            <a
              onClick={() => onHandleDisplayModeChange(0)}
              className="btn cutomise_but"
            >
              <i className="fa fa-angle-left" aria-hidden="true" /> &nbsp; Back{" "}
            </a>

            <a onClick={() => handleShowPDF(1)} className="btn cutomise_but">
              {" "}
              Export Document{" "}
            </a>

            {/* <div className="action_btn1 ">
              <button type="button" className="actions_menu">
                <i className="fa fa-align-left" aria-hidden="true" />
              </button>
              <div className="user_menu" tabIndex={-1}>
                <div className="action_sec">
                  <ul>
                    <li>
                      <a href="#">Drafted</a>{" "}
                    </li>
                    <li>
                      <a href="#">Published </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="clearfix" />

            <div className="row ">
              <div className="col-sm-12">
                <div className=" section_box_m">
                  <div className="col-sm-3">
                    <div className="profile_sec_photo">
                      {vendorProfileImage.data.length > 0 ? (
                        <img
                          className="img-responsive"
                          src={
                            environment.baseUrl +
                            `/Upload/VendorProfileImage/${vendorProfileImage.data[0]["imageUrl"]}`
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          className="img-responsive"
                          src={require("./../../../images/profileDefault1.jpg")}
                        ></img>
                      )}
                      <div className="overlay">
                        <div className="upload_photo">
                          <a
                            href="#"
                            data-target="#upload_ban"
                            data-toggle="modal"
                          >
                            <i className="fa fa-picture-o" aria-hidden="true" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="profile_cons1">Profile Complet</div>
                    <div className="profile_cons1">
                      {progressBarVendor.profileProgress}%
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="social_net"> Social Networks </div>
                    {socialAccounts.data.length > 0 &&
                    socialAccounts.data[0]["facebooks"] !== "" ? (
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={1001}
                          onChange={(e: any) => handleChecked(e, 1001)}
                          checked={checkedListArrayForDisplay.includes(1002)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {vendorSocialAccounts.data.length > 0
                            ? vendorSocialAccounts.data[0]["facebooks"]
                            : null}
                        </label>
                      </div>
                    ) : null}

                    {vendorSocialAccounts.data.length > 0 &&
                    vendorSocialAccounts.data[0]["google"] !== "" ? (
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={1002}
                          onChange={(e: any) => handleChecked(e, 1002)}
                          checked={checkedListArrayForDisplay.includes(1002)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {vendorSocialAccounts.data.length > 0
                            ? vendorSocialAccounts.data[0]["google"]
                            : null}
                        </label>
                      </div>
                    ) : null}

                    {vendorSocialAccounts.data.length > 0 &&
                    vendorSocialAccounts.data[0]["linkedIn"] !== "" ? (
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={1003}
                          onChange={(e: any) => handleChecked(e, 1003)}
                          checked={checkedListArrayForDisplay.includes(1003)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {vendorSocialAccounts.data.length > 0
                            ? vendorSocialAccounts.data[0]["linkedIn"]
                            : null}
                        </label>
                      </div>
                    ) : null}

                    {vendorSocialAccounts.data.length > 0 &&
                    vendorSocialAccounts.data[0]["twitter"] !== "" ? (
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={1004}
                          onChange={(e: any) => handleChecked(e, 1004)}
                          checked={checkedListArrayForDisplay.includes(1004)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {vendorSocialAccounts.data.length > 0
                            ? vendorSocialAccounts.data[0]["twitter"]
                            : null}
                        </label>
                      </div>
                    ) : null}

                    {vendorSocialAccounts.data.length > 0 &&
                    vendorSocialAccounts.data[0]["instagram"] !== "" ? (
                      <div>
                        <input
                          id="checkbox1"
                          type="checkbox"
                          name="checkbox"
                          defaultValue={1005}
                          onChange={(e: any) => handleChecked(e, 1005)}
                          checked={checkedListArrayForDisplay.includes(1005)}
                        />
                        <label htmlFor="checkbox1">
                          <span />{" "}
                          {vendorSocialAccounts.data.length > 0
                            ? vendorSocialAccounts.data[0]["instagram"]
                            : null}
                        </label>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                {/* persnal info start */}
                <div className=" section_box_m">
                  <div className="ad_profile_h">
                    <div className="pull-left">
                      <input
                        id="checkbox2"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={6}
                        onChange={(e: any) =>
                          handleAllPersonalinfoChecked(e, [
                            1007,
                            1008,
                            1009,
                            1010,
                          ])
                        }
                        checked={
                          checkedListArrayForDisplay.includes(1007) &&
                          checkedListArrayForDisplay.includes(1008) &&
                          checkedListArrayForDisplay.includes(1009) &&
                          checkedListArrayForDisplay.includes(1010)
                        }
                      />
                      <label htmlFor="checkbox2">
                        <span />
                      </label>
                    </div>

                    <h1>Pesonal Info</h1>
                  </div>

                  <div className="col-sm-6">
                    <div>
                      <input
                        id="checkbox1"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={7}
                        onChange={(e: any) => handleChecked(e, 1007)}
                        checked={checkedListArrayForDisplay.includes(1007)}
                      />
                      <label htmlFor="checkbox1">
                        <span />{" "}
                        {vendorState.data != undefined &&
                        vendorState.data.length > 0
                          ? vendorState.data[0]["vendorName"]
                          : ""}
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div>
                      <input
                        id="checkbox1"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={8}
                        onChange={(e: any) => handleChecked(e, 1008)}
                        checked={checkedListArrayForDisplay.includes(1008)}
                      />
                      <label htmlFor="checkbox1">
                        <span />{" "}
                        {vendorState.data != undefined &&
                        vendorState.data.length > 0
                          ? vendorState.data[0]["email"]
                          : ""}
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div>
                      <input
                        id="checkbox1"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={9}
                        onChange={(e: any) => handleChecked(e, 1009)}
                        checked={checkedListArrayForDisplay.includes(1009)}
                      />
                      <label htmlFor="checkbox1">
                        <span />{" "}
                        {vendorState.data != undefined &&
                        vendorState.data.length > 0
                          ? vendorState.data[0]["countryCode"]
                          : ""}{" "}
                        {vendorState.data != undefined &&
                        vendorState.data.length > 0
                          ? vendorState.data[0]["phoneNumber"]
                          : ""}
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div>
                      <input
                        id="checkbox1"
                        type="checkbox"
                        name="checkbox"
                        defaultValue={11}
                        onChange={(e: any) => handleChecked(e, 1010)}
                        checked={checkedListArrayForDisplay.includes(1010)}
                      />
                      <label htmlFor="checkbox1">
                        <span />{" "}
                        {vendorState.data != undefined &&
                        vendorState.data.length > 0
                          ? vendorState.data[0]["location"]
                          : ""}
                      </label>
                    </div>
                  </div>
                </div>

                {/* persnal info end */}
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      ) : (
        <JobApplicationProfileToPdf
          candidateState={candidateState}
          socialAccounts={socialAccounts}
          candidateProfileImage={candidateProfileImage}
          progressBar={progressBar}
          handleShowPDF={handleShowPDF}
          checkedListArrayForDisplay={checkedListArrayForDisplay}
          candidateLanguageMaps={candidateLanguageMaps}
          candidateReferences={candidateReferences}
          candidateRelativesState={candidateRelativesState}
          educationQualificationState={educationQualificationState}
          checkedListArrayForDisplayQualification={
            checkedListArrayForDisplayQualification
          }
          candidateExperienceState={candidateExperienceState}
          checkedListArrayForDisplayExperience={
            checkedListArrayForDisplayExperience
          }
          trainingState={trainingState}
          checkedListArrayForDisplayTraining={
            checkedListArrayForDisplayTraining
          }
          candidateSkillsState={candidateSkillsState}
          checkedListArrayForDisplaySkill={checkedListArrayForDisplaySkill}
          onePassportInformation={onePassportInformation}
          vendorState={vendorState}
          vendorSocialAccounts={vendorSocialAccounts}
          userId={userId}
          vendorProfileImage={vendorProfileImage}
          progressBarVendor={progressBarVendor}
        />
      )}
    </>
  );
};

export default JobApplicationProfileComponent;
