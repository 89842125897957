import React from "react";
import { NotificationListContextProvider } from "../../../context/superadmin/SuperAdminMyProfile";
import SuperAdminDashboardComponent from "./SuperAdminDashboardComponent";

const SuperAdminDashboardContainer = () => {
  return (
    <>
      <NotificationListContextProvider>
        <SuperAdminDashboardComponent />
      </NotificationListContextProvider>
    </>
  );
};

export default SuperAdminDashboardContainer;
