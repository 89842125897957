import { Reducer } from "react";

import React from "react";

import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  VendorSuggestedJobTitleRequestModel,
  VendorSuggestedJobTitleViewModel,
  VendorSuggestedJobViewModel,
  VendorSuggestedRequestModel,
} from "../../models/vendor/VendorSuggestedJob";
import {
  IVendorSuggestedJobManagementState,
  VendorSuggestedJobDispatcherContext,
  VendorSuggestedJobStateContext,
} from "../../context/vendor/VendorSuggestedJobContext";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IVendorSuggestedJobAction =
  | {
      type: "SET_Job_SUGGESTED_LIST";
      vendorSuggestedJobs: VendorSuggestedJobViewModel;
    }
  | {
      type: "SET_Job_SUGGESTED_TITLE_LIST";
      vendorSuggestedJobTitle: VendorSuggestedJobTitleViewModel;
    };

export const vendorSuggestedJobReducer: Reducer<
  IVendorSuggestedJobManagementState,
  IVendorSuggestedJobAction
> = (draft, action): IVendorSuggestedJobManagementState => {
  switch (action.type) {
    case "SET_Job_SUGGESTED_LIST":
      draft.vendorSuggestedJobs = action.vendorSuggestedJobs;
      return draft;

    case "SET_Job_SUGGESTED_TITLE_LIST":
      draft.vendorSuggestedJobTitle = action.vendorSuggestedJobTitle;
      return draft;
  }
};

export const useVendorSuggestedJobDispatcher = (): React.Dispatch<IVendorSuggestedJobAction> => {
  const vendorVendorSuggestedJobDispatcher = React.useContext(
    VendorSuggestedJobDispatcherContext
  );
  if (!vendorVendorSuggestedJobDispatcher) {
    throw new Error(
      "You have to provide the VendorSuggestedJob dispatcher using theVendorSuggestedJobDispatcherContext.Provider in a parent component."
    );
  }
  return vendorVendorSuggestedJobDispatcher;
};

export const useVendorSuggestedJobContext = (): IVendorSuggestedJobManagementState => {
  const vendorVendorSuggestedJobContext = React.useContext(
    VendorSuggestedJobStateContext
  );
  if (!vendorVendorSuggestedJobContext)
    throw new Error(
      "You have to provide the vendorVendorSuggestedJob context using the VendorSuggestedJobStateContext.Provider in a parent component."
    );
  return vendorVendorSuggestedJobContext;
};

export const getVendorSuggestedJobList = async (
  dispatcher: React.Dispatch<IVendorSuggestedJobAction>,
  query: VendorSuggestedRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllVendorSuggestedJob;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      //console.log(7777,res)
      dispatcher({
        type: "SET_Job_SUGGESTED_LIST",
        vendorSuggestedJobs: res.data,
      });
    });
  } catch (e) {}
};

export const getVendorSuggestedJobTitleList = async (
  dispatcher: React.Dispatch<IVendorSuggestedJobAction>,
  query: VendorSuggestedJobTitleRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllVendorSuggestedJobTitle;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      //console.log(7777,res)
      dispatcher({
        type: "SET_Job_SUGGESTED_TITLE_LIST",
        vendorSuggestedJobTitle: res.data,
      });
    });
  } catch (e) {}
};
