import React, { useEffect } from "react";
import Select, { components } from "react-select";

function SelectMulti(props: any) {
  const x = props.values.filter((e: any) => props.value.includes(e.value));

  //console.log(11, x);

  return (
    <span
      style={{ cursor: props.disabled ? "" : "default" }}
      className={props.disabled ? "_cursor-no-drop" : ""}
    >
      <Select
        className={
          props.nameForLablel === ""
            ? "_react-select-language"
            : "_react-select-language10"
        }
        classNamePrefix="select"
        value={x}
        isDisabled={props.disabled}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        isMulti={true}
        name={props.name}
        options={props.values}
        onChange={(e: any) => {
          props.onChange(
            e
              ? e.map((e: any) => {
                  if (e.value != "0") {
                    return e.value;
                  }
                })
              : []
          );
        }}
        placeholder={
          props.nameForLablel === "" ? "Select" : props.nameForLablel
        }
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </span>
  );
}

export default SelectMulti;
