import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { IVendorJobBookMarkAction, vendorJobBookMarkReducer } from "../../action/vendor/VendorJobBookMarkAction";
import {  GetAllJobBookMarkRespondModel, VendorJobBookMarkRespondModel } from "../../models/vendor/VendorJobBookMark";




export const VendorJobBookMarkDispatcherContext = React.createContext<React.Dispatch<IVendorJobBookMarkAction> | null>(
  null
);
export const VendorJobBookMarkStateContext = React.createContext<IVendorJobBookMarkManagementState | null>(
  null
);

export interface IVendorJobBookMarkManagementProps extends RouteComponentProps<any> {}

export interface IVendorJobBookMarkManagementState {
  // visible: boolean;
  // value: number;
  // vendorGetAllJobRequestModel: VendorGetAllJobRequestModel;
  //  vendorJobBookMarkSaveRespond: VendorJobBookMarkRespondModal;
  //  vendorGetAllJobList:VendorGetAllJobRespondModel,
    vendorGetAllJobBookMark:GetAllJobBookMarkRespondModel,
    bookmarkSaveResult:VendorJobBookMarkRespondModel

}

export const initialVendorJobBookMarkManagementState = {
  
  // vendorGetAllJobRequestModel: {} as VendorGetAllJobRequestModel,
  //  vendorJobBookMarkSaveRespond: {} as VendorJobBookMarkRespondModal,
  //  vendorGetAllSuggestedJob:{} as GetAllSuggestedJobRespondModel,
  //  vendorGetAllJobList:{} as VendorGetAllJobRespondModel,
  vendorGetAllJobBookMark:{} as GetAllJobBookMarkRespondModel,
  bookmarkSaveResult:{} as VendorJobBookMarkRespondModel
} as IVendorJobBookMarkManagementState;

export const VendorJobBookMarkContextProvider: React.FC = ({ children }) => {
  const [vendorJobBookMarkState, dispatcher] = useImmerReducer(
    vendorJobBookMarkReducer,
    initialVendorJobBookMarkManagementState
  );

  return (
    <VendorJobBookMarkDispatcherContext.Provider value={dispatcher}>
      <VendorJobBookMarkStateContext.Provider value={vendorJobBookMarkState}>
        {children}
      </VendorJobBookMarkStateContext.Provider>
    </VendorJobBookMarkDispatcherContext.Provider>
  );
};
