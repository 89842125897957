import { getVendorJobStatusList } from "./../action/vendor/VendorJobStatusAction";
export const environment = {
  production: false,
  baseUrl: "https://careerapp.azurewebsites.net",
  // baseUrl: "https://localhost:44361",
  // baseUrl: "https://careerapp.azurewebsites.net",
  // baseUrl: "https://careerapp.azurewebsites.net",
  // tokenUrl: "https://careerapp.azurewebsites.net/api",
  //tokenUrl: "https://localhost:44361/api",
  tokenUrl: "https://careerapp.azurewebsites.net/api",
  loginUrl: "/login",
};

export const AppUrls = {
  // registerMe: environment.baseUrl + "/api/account/register-me",
  GetLoggedCandidateId: environment.baseUrl + "/api/Candidate/gc/",
  GetAllJobCategory: environment.baseUrl + "/api/JobCategory/gaaa",
  GetAllJobApplied: environment.baseUrl + "/api/JobApplied/gaaa",
  GetMyProfileSelectBoxData:
    environment.baseUrl + "/api/Candidate/get-selectbox-data",
  GetVendorMyProfileSelectBoxData:
    environment.baseUrl + "/api/Vendor/get-selectbox-data",
  GetAllMatchedJob: environment.baseUrl + "/api/JobPost/sbca",
  GetAllSearchResultWithToken: environment.baseUrl + "/api/JobPost/sa",
  GetAllJobType: environment.baseUrl + "/api/JobType/gaaa",
  GetAllNewlyPostedJob: environment.baseUrl + "/api/jobsearch/gaaa",
  GetAllSearchResultWithOutToken: environment.baseUrl + "/api/jobsearch/sa",
  GetAllJobAlert: environment.baseUrl + "/api/JobPost/gaja",
  GetAllJobAlertWithCandidateId:
    environment.baseUrl + "/api/CandidateJobAlert/gaaa",
  GetAllExperience: environment.baseUrl + "/api/ExpereinceType/gaaa",
  GetAllDatePosted: environment.baseUrl + "/api/DatePosted/gaaa",
  GetMyProfileBasicDetails:
    environment.baseUrl + "/api/Account/get-basic-details",
  GetAllBookMarkedJobs: environment.baseUrl + "/api/jobBookmarked/grsj",
  GetAllCandidateBookMarkedJobs:
    environment.baseUrl + "/api/jobBookmarked/gaaa",
  GetDigiLockerDocumentList:
    environment.baseUrl + "/api/DigiDocumentDetails/gaa",
  GetDigiLockerDocumentTypeList:
    environment.baseUrl + "/api/DigiDocumentType/gaaa",
  GetDigiLockerExpiringDocumentList:
    environment.baseUrl + "/api/DigiDocumentDetails/geda",
  GetDigiLocker: environment.baseUrl + "/api/DigiDocumentDetails/ga",
  GetProfileImage: environment.baseUrl + "/api/CandidateProfileImage/gaaa",
  GetVendorProfileImage: environment.baseUrl + "/api/VendorProfileImage/gaaa",
  GetSuggestedJob:
    environment.baseUrl + "/api/CandidateJobAlert/GetAllSuggestedjobAsync",
  GetSuggestedJobTitle:
    environment.baseUrl + "/api/CandidateJobAlert/GetAllJobTitlesAsync",
  GetAllCandidateJobAppliedList:
    environment.baseUrl + "/api/JobAppliedDetails/graj",
  GetAllCount: environment.baseUrl + "/api/JobApplied/gaca",
  GetProfileProgressBar: environment.baseUrl + "/api/JobApplied/gpa",
  GetTitleWithCategory: environment.baseUrl + "/api/JobPostAlert/get-title",
  GetDashboard: environment.baseUrl + "/api/DashboardList/GetLayoutIdAsync",
  SaveDashBoardLayout:
    environment.baseUrl + "/api/DashboardList/SaveLayoutAsync",
  // ShareDigiLocker:environment.baseUrl+"/api/DigiDocumentDownload/ga",
  ShareDigiLocker: environment.baseUrl + "/api/DigiDocumentUpload/share-doc",
  SaveJobAlert: environment.baseUrl + "/api/CandidateJobAlert/coea",
  SaveJobAlertCategoryMap:
    environment.baseUrl + "/api/JobAlertCategoryMap/coea",
  SaveJobAlertExperienceMap:
    environment.baseUrl + "/api/jobAlertExperienceMap/coea",
  SaveJobAlertIndustryMap:
    environment.baseUrl + "/api/jobAlertIndustryMap/coea",
  SaveJobAlertRoleMapMap: environment.baseUrl + "/api/jobAlertRoleMap/coea",
  SaveJobAlertTypeMap: environment.baseUrl + "/api/jobAlertTypeMap/coea",
  SaveJobApplied: environment.baseUrl + "/api/jobAppliedDetails/coea",
  SaveBookMarkList: environment.baseUrl + "/api/jobBookmarked/coea",
  SaveDigiLockerList: environment.baseUrl + "/api/DigiDocumentDetails/coea",
  SaveCandidateDashBoard: environment.baseUrl + "/api/DigiDocumentDetails/coea",
  SetAsignTo:
    environment.baseUrl +
    "/api/AdminDraftedandPublishedJobs/AssigntoAdminAsync",

  DeleteDigiLocker: environment.baseUrl + "/api/DigiDocumentDetails/da",
  DeleteBookMarked: environment.baseUrl + "/api/Jobbookmarked/nba",
  DeleteJobAlert: environment.baseUrl + "/api/CandidateJobAlert/da",

  ToMoveDocument: environment.baseUrl + "/api/DigiDocumentDetails/doc-move",
  NotifyJobs:
    environment.baseUrl + "/api/CandidateNotifiedJobs/NotifiedJobListAsync",
  NotInterestedInNotifyJob:
    environment.baseUrl + "​/api/CandidateNotifiedJobs/NotInterestedAsync",

  // vendor
  GetLoggedVendorId: environment.baseUrl + "/api/Vendor/gv/",
  SaveVendorJobAlert: environment.baseUrl + "/api/VendorJobAlert/coea",
  SaveVendorJobApplied:
    environment.baseUrl + "/api/VendorCandidates/AddCandAndApplyForJobAsync",
  SaveAndUnSaveVendorBookMark:
    environment.baseUrl + "/api/VendorJobsBookmarked/BookMarked-UnBookMarked",

  GetAllVendorJobAlert: environment.baseUrl + "/api/VendorJobAlert/gaaa",
  GetAllVendorMatchedJob: environment.baseUrl + "/api/JobPost/sbva",
  GetAllVendorAppliedJob:
    environment.baseUrl + "/api/VendorJobApplied/GetAllAppliedAsync",
  GetAllJobBookMark:
    environment.baseUrl + "/api/VendorJobsBookmarked/AllBookmarkedSync",
  // GetAllJobStatus: environment.baseUrl + "/api/VendorJobApplied/GetJobStatusAsync",
  GetAllJobStatus: environment.baseUrl + "/api/VendorJobApplied/GetCadStatus",
  GetAllVendorCount:
    environment.baseUrl + "/api/VendorCandidates/getAllCountAsync",
  GetAllVendorSuggestedJob:
    environment.baseUrl + "/api/VendorJobApplied/GetAllSuggestedjobAsync",
  GetAllVendorSuggestedJobTitle:
    environment.baseUrl + "/api/VendorJobApplied/GetAllJobTitlesAsync",
  GetListOfVendorSearchResult:
    environment.baseUrl + "/api/VendorJobSearch/SearchJob",

  DeleteVendorJobAlert: environment.baseUrl + "/api/VendorJobAlert/da",
  DeleteVendorJobStatus:
    environment.baseUrl + "/api/VendorJobApplied/RmoveCandFromAppJobAsync",

  GenericSearch:
    environment.baseUrl + "/api/GenericJobListing/GetAllActiveJobList",

  // Vendor apis
  GetMyProfileVendorSelectBoxData:
    environment.baseUrl + "/api/Vendor/get-selectbox-data",
  GetProfileVendorImage: environment.baseUrl + "/api/VendorProfileImage/gaaa",
  GetProfileVendorProgressBar:
    environment.baseUrl + "/api/JobApplied_vendor/gpa",

  // SuperAdmin apis
  GetSuperAdminMyProfileSelectBoxData:
    environment.baseUrl + "/api/SuperAdmin/get-selectbox-data",
  GetSuperAdminProfileImage:
    environment.baseUrl + "/api/SuperAdminProfileImage/gaaa",
  GetMyProfileSuperAdminSelectBoxData:
    environment.baseUrl + "/api/SuperAdmin/get-selectbox-data",
  GetProfileSuperAdminImage:
    environment.baseUrl + "/api/SuperAdminProfileImage/gaaa",
  GetProfileSuperAdminProgressBar:
    environment.baseUrl + "/api/JobApplied_superadmin/gpa",

  // super Admin
  GetAllSuperAdminUser:
    environment.baseUrl + "/api/AdminRegistration/GetAllAdminsAsync",
  GetSuperAdminUser:
    environment.baseUrl + "/api/AdminRegistration/GetAdminProfileByIdAsync",
  GetAllPermision:
    environment.baseUrl + "/api/AdminRegistration/GetAllPermissionsAsync",
  GetAllAdminReport:
    environment.baseUrl + "/api/SuperAdminReport/GetAllAdminsAsync",

  GetTotalCountReport:
    environment.baseUrl + "/api/SuperAdminReport/getTotalCountAsync",

  SaveSuperAdminUser:
    environment.baseUrl + "/api/AdminRegistration/AddorEditProfileAsync",

  DeleteSuperAdminUser:
    environment.baseUrl + "/api/adminRegistration/RemoveProfileAsync",

  SendInvitationPhone:
    environment.baseUrl + "/api/Invitation/sendSmsNotification",
  SendInvitationEmail:
    environment.baseUrl + "/api/Invitation/send-email-notification",

  // Admin
  AdminSaveJob:
    environment.baseUrl + "/api/AdminDraftedandPublishedJobs/AddOrEditJobAsync",
  SavePublished:
    environment.baseUrl + "/api/AdminDraftedandPublishedJobs/PublishJobAsync",
  GetAllPermissionInAdmin:
    environment.baseUrl + "/api/AdminRegistration/GetAllAdminByPermIdAsync",
  GetAllJobSInAdmin:
    environment.baseUrl +
    "/api/AdminDraftedandPublishedJobs/GetAllActiveJobDetails",
  GetJobDetails:
    environment.baseUrl + "/api/AdminDraftedandPublishedJobs/GetJobDetailsById",
  GetViewJobApplication:
    environment.baseUrl +
    "/api/AdminDraftedandPublishedJobs/ViewApplicationAsync",
  GetAdminPermission:
    environment.baseUrl +
    "/api/AdminDraftedandPublishedJobs/GetAdminPermissionStatusAsync",
  GetAdminCandidateDetails:
    environment.baseUrl +
    "/api/AdminDraftedandPublishedJobs/getCanAppliedDocDetailsAsync",
  GetAdminVendorCandidateDetails:
    environment.baseUrl +
    "/api/AdminDraftedandPublishedJobs/GetVendorCandidatesByIdAsync",

  MoveAdminJobStatus:
    environment.baseUrl +
    "/api/AdminDraftedandPublishedJobs/MoveJobStatusAsync",

  DeleteAdminJob:
    environment.baseUrl + "/api/AdminDraftedandPublishedJobs/RemoveJobAsync",
  GetOrGetAllJobSInAdmin:
    environment.baseUrl + "/api/AdminDrafted/GetJobDetails",
  InterviewScheduleSmsAndBulkEmail:
    environment.baseUrl +
    "/api/AdminDraftedandPublishedJobs/SendSMSInterviewScheduleAsync",
  SearchAdminCandidate:
    environment.baseUrl + "/api/AdminSearchCandidates/GetCandidatesAsync",
  SearchAdminCandidateDetails:
    environment.baseUrl + "​​/api/AdminSearchCandidates/ab",
  SaveAdminCandidateSearch:
    environment.baseUrl + "​​​/api/AdminSearchCandidates/SaveSearchAsync",
  GetAdminCandidateSearch:
    environment.baseUrl + "/api/AdminSearchCandidates/GetSavedSearchListAsync",
  GetAdminCandidateSelectBoxJobs:
    environment.baseUrl + "​/api/AdminSearchCandidates/get-selectbox-data",
  SetAdminCandidateSearch:
    environment.baseUrl + "/api/AdminSearchCandidates/getSaveSearchById",
  RemoveAdminCandidateSavedSearch:
    environment.baseUrl + "/api/AdminSearchCandidates/RemoveSearchAsync",
  SendEmailOrSmSInAdminSearch:
    environment.baseUrl + "/api/AdminSearchCandidates/SendEmailSMSAsync",

  SaveClientDetails: environment.baseUrl + "/api/Client/AddorEditProfileAsync",
  EditClientDetails: environment.baseUrl + "/api/Client/GetClientByIdAsync",
  GetAllAdminClientDetails:
    environment.baseUrl + "/api/Client/GetAllClientsAsync",
  RemoveAdminClientDetails:
    environment.baseUrl + "/api/Client/RemoveClientAsync",
  SaveCategory: environment.baseUrl + "/api/JobCategory/coea",
  SaveTitle: environment.baseUrl + "/api/JobTitle/AddorEditJobTitleAsync",
  GetAllVendorReport:
    environment.baseUrl + "/api/VendorReport/GetAllVendorAsync",
  SetAllResumeReport: environment.baseUrl + "/api/Report/getAdminCandidate",

  GetVendorTotalCountReport:
    environment.baseUrl + "/api/VendorReport/getTotalCountAsync",
  // Admin apis
  GetAdminMyProfileSelectBoxData:
    environment.baseUrl + "/api/Admin/get-selectbox-data",
  GetAdminProfileImage: environment.baseUrl + "/api/AdminProfileImage/gaaa",
  GetMyProfileAdminSelectBoxData:
    environment.baseUrl + "/api/Admin/get-selectbox-data",
  GetProfileAdminImage: environment.baseUrl + "/api/AdminProfileImage/gaaa",

  GetCareerTipsList: environment.baseUrl + "/api/CareerTips/gaaa",
  GetCareerTipsListWithOutToken: environment.baseUrl + "/api/CareerTips/gpda",
  GetCareerTipsListByCategoryWithOutToken:
    environment.baseUrl + "/api/CareerTips/gbca",
  GetCareerTipsCategoryListWithOutToken:
    environment.baseUrl + "/api/CareerTipsCategory/gawt",
  EditCareerTips: environment.baseUrl + "/api/CareerTips/coea",
  EditCareerTipsStatus: environment.baseUrl + "/api/ApprovalCareerTips/coea",
  GetCareerTips: environment.baseUrl + "/api/CareerTips/ga",
  GetCareerTipsSelectBoxData:
    environment.baseUrl + "/api/CareerTips/get-selectbox-data",
  GetAllApproveOrReject:
    environment.baseUrl + "/api/CareerTips/getApprovedorRejectedAsync",

  GetVendorList: environment.baseUrl + "/api/Vendor/gvpa",
  EditVendor: environment.baseUrl + "/api/Vendor/coea",
  EditVendorStatus: environment.baseUrl + "/api/Vendor/update-vendor-status",
  GetVendor: environment.baseUrl + "/api/Vendor/ga",
  GetVendorSelectBoxData:
    environment.baseUrl + "/api/Vendor/get-selectbox-data",

  GetClientJobList: environment.baseUrl + "/api/JobPost/gcja",
  EditClientJob: environment.baseUrl + "/api/JobPost/coea",
  EditClientJobStatus: environment.baseUrl + "/api/ApprovalClientJob/coea",
  GetClientJob: environment.baseUrl + "/api/JobPost/ga",
  GetClientJobSelectBoxData:
    environment.baseUrl + "/api/ApprovalClientJob/get-selectbox-data",

  GetMyTeamMemberList: environment.baseUrl + "/api/MyTeamMember/gaaa",
  EditMyTeamMember: environment.baseUrl + "/api/MyTeamMember/coea",
  GetMyTeamMember: environment.baseUrl + "/api/MyTeamMember/ga",
  DeleteMyTeamMember: environment.baseUrl + "/api/MyTeamMember/da",
  GetMyTeamMemberSelectBoxData:
    environment.baseUrl + "/api/MyTeamMember/get-selectbox-data",

  GetStoryList: environment.baseUrl + "/api/Story/gaaa",
  EditStory: environment.baseUrl + "/api/Story/coea",
  GetStory: environment.baseUrl + "/api/Story/ga",
  DeleteStory: environment.baseUrl + "/api/Story/da",

  GetSocialAccountUrlList: environment.baseUrl + "/api/SocialAccountUrl/gaaa",
  EditSocialAccountUrl: environment.baseUrl + "/api/SocialAccountUrl/coea",
  GetSocialAccountUrl: environment.baseUrl + "/api/SocialAccountUrl/ga",
  DeleteSocialAccountUrl: environment.baseUrl + "/api/SocialAccountUrl/da",
  GetSocialAccountUrlSelectBoxData:
    environment.baseUrl + "/api/SocialAccountUrl/get-selectbox-data",

  GetAchievementsList: environment.baseUrl + "/api/Achievements/gaaa",
  GetAchievementsListWithOutToken:
    environment.baseUrl + "/api/Achievements/gaas",
  EditAchievements: environment.baseUrl + "/api/Achievements/coea",
  GetAchievements: environment.baseUrl + "/api/Achievements/ga",
  DeleteAchievements: environment.baseUrl + "/api/Achievements/da",
  GetAchievementsSelectBoxData:
    environment.baseUrl + "/api/Achievements/get-selectbox-data",
  GetClientsListWithOutToken:
    environment.baseUrl + "/api/Client/GetAllClientsLogoAsync",

  GetDocumentResumeInAdminSide:
    environment.baseUrl + "/api/Report/getadmincandidate",

  GetVendorJobStatusList: environment.baseUrl + "/api/Vendor/gvpa",

  GetAllFakeCandidate:
    environment.baseUrl + "/api/ClientFakeCandidatesCount/GetAllActiveDetails",
  DeleteAllFakeCandidate:
    environment.baseUrl + "/api/ClientFakeCandidatesCount/RemoveJobTitleAsync",
  AddFakeCandidate:
    environment.baseUrl + "/api/ClientFakeCandidatesCount/AddorEditCountAsync",
  ClientJobPendingApprove:
    environment.baseUrl + "/api/ApprovalClientJob/GetAllActiveJobDetails",
  ListAllAdminClientJobs:
    environment.baseUrl +
    "/api/AdminDraftedandPublishedJobs/GetAllClientJobsAsync",
  ApproveOrRejectClientJobsFromAdmin:
    environment.baseUrl + "/api/ApprovalClientJob/UpdateJobtatus",
  CandidateDetailsByInfo:
    environment.baseUrl + "/api/ClientJobsCandidateList/GetCandidateDetails",
  AddCandidateInToForm:
    environment.baseUrl +
    "/api/ClientJobsCandidateList/AddSingleCandidateAsync",
  GetAllAdminSideClientCandidatelist:
    environment.baseUrl + "/api/ClientJobsCandidateList/GetCandidateStatus",

  GetAllBanner: environment.baseUrl + "/api/Banners/GetAllBannersAsync",
  AddBanner: environment.baseUrl + "/api/Banners/AddBannerAsync",
  DeleteBanner: environment.baseUrl + "/api/Banners/da",
  BannerStatusChange: environment.baseUrl + "/api/Banners/ActiveInactive",
  EditBanner: environment.baseUrl + "/api/Banners/ga",
  GetAllGoogleSlideUrl: environment.baseUrl + "/api/CareerTips/getURLlist",
  SaveGoogleSlideUrl: environment.baseUrl + "/api/CareerTips/updateGoogeURL",
  // client
  AddOrEditPersonalDetailsClient:
    environment.baseUrl + "/api/Client/AddorEditProfileAsync",
  GetProfilePersonalDetailsClient:
    environment.baseUrl + "/api/Client/GetClientByIdAsync",
  GetProfileImageClient: environment.baseUrl + "/api/ClientProfileImage/gaaa",
  DeleteClientProfile: environment.baseUrl + "/api/ClientProfileImage/da",
  SaveOrDeleteClientJob:
    environment.baseUrl + "/api/ClientJobs/AddOrEditorDeleteJobAsync",
  GetAllClientJob:
    environment.baseUrl + "/api/ClientJobs/GetAllActiveJobDetails",
  GetClientJobById: environment.baseUrl + "/api/ClientJobs/GetJobDetailsById",
  PublishClientJob: environment.baseUrl + "/api/ClientJobs/PublishJobAsync",
  CheckJobStatus: environment.baseUrl + "/api/ClientJobs/CheckJobStatusAsync",
  ClientNotification: environment.baseUrl + "/api/Client/getNotificatinsAsync",
  ClientDashboardCount: environment.baseUrl + "/api/Client/getAllCountAsync",
  ClientFakeCandidateSearch:
    environment.baseUrl + "/api/Client/getFakeCandidatesCountAsync",
  ClientFakeCandidateEdit:
    environment.baseUrl + "/api/ClientFakeCandidatesCount/GetActiveJobById",
  ClientViewApplication:
    environment.baseUrl + "/api/ClientJobs/ViewApplicationAsync",
  ClientViewApplicationDetails:
    environment.baseUrl + "/api/ClientJobs/getCanAppliedDocDetailsAsync",
  ClientCandidateMove:
    environment.baseUrl + "/api/ClientJobs/MoveJobStatusAsync",

  GetClientTotalCountReport:
    environment.baseUrl + "/api/ClientReport/getTotalCountAsync",
  GetAllClientReport:
    environment.baseUrl + "/api/ClientReport/GetAllClientsAsync",
};

export const socialLoginIds = {
  facebook: {
    appId: "376466233478368",
  },
};
