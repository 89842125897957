import { Popover, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import Scrollbars from "react-custom-scrollbars";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  getJobBookMarkList,
  saveBookMarkList,
  useJobBookMarkContext,
  useJobBookMarkDispatcher,
} from "../../../action/candidate/JobBookMarkAction";
import {
  useJobAppliedContext,
  useJobAppliedDispatcher,
} from "../../../action/JobAppliedAction";
import { useMyProfileContext } from "../../../action/MyProfileAction";
import {
  getAllCoverLetterCandidateMap,
  getAllResumeCandidateMap,
} from "../../../apis/resumebuilder";
import {
  jobBookMarkRequestModel,
  jobBookMarkSaveRequestModel,
} from "../../../models/candidate/BookMarkedJob";
import { CoverletterDataResult } from "../../../models/candidate/CoverLetter";
import { ResumeAllList } from "../../../models/candidate/Resume";
import AuthService from "../../../services/AuthService";
import JobAppliedForm from "../jobs_Applied/JobAppliedForm";

interface ICandidateSavedJobComponentProps {}

interface ICandidateSavedJobComponentState {
  currentJobId: number;
  filterFlag: boolean;
}

const defaultValues = {
  currentJobId: 0,
  filterFlag: true,
};

const CandidateSavedJobComponent: React.FC<ICandidateSavedJobComponentProps> = (
  props
) => {
  const [
    CandidateSavedJobComponentState,
    setCandidateSavedJobComponentState,
  ] = React.useState<ICandidateSavedJobComponentState>(defaultValues);
  const authorizationToken = AuthService.accessToken;

  const { filterFlag, currentJobId } = CandidateSavedJobComponentState;

  const jobBookMarkDispatcher = useJobBookMarkDispatcher();
  const jobBookMarkContext = useJobBookMarkContext();
  const { jobBookMark, jobBookMarkSaveRespond } = jobBookMarkContext;

  const jobAppliedContext = useJobAppliedContext();
  const { jobAppliedSaveResultData } = jobAppliedContext;

  console.group(6754, jobBookMark);
  let history = useHistory();

  const myProfileContext = useMyProfileContext();
  const {
    basicInfo,
    loggedUserId,
    myProfile,
    myProfileProgressBar,
  } = myProfileContext;

  const [isJobAppliedOpen, setIsJobAppliedOpen] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [candidateCoverLetters, setCandidateCoverLetters] = useState(
    {} as CoverletterDataResult
  );
  const candidateId = loggedUserId;

  const [candidateResumes, setCandidateResumes] = useState({} as ResumeAllList);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ICandidateSavedJobComponentState>({
    defaultValues,
  });

  const jobAppliedDispatcher = useJobAppliedDispatcher();

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getJobBookMarkList(
          jobBookMarkDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 600,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as jobBookMarkRequestModel,
          authorizationToken
        );
      })();
    }
  }, [jobBookMarkSaveRespond, jobAppliedSaveResultData]);

  // const handleDeleteBookMarked = (jobId: number) => {
  //   if (authorizationToken != null)
  //     deleteJobBookMarkList(
  //       jobBookMarkDispatcher,
  //       {
  //         jobId: jobId,
  //         candidateId: loggedUserId,
  //       } as jobBookMarkDataWithUserId,
  //       authorizationToken
  //     );
  // };

  const handleJobBookMarked = (jobId: number) => {
    if (authorizationToken != null) {
      saveBookMarkList(
        jobBookMarkDispatcher,
        {
          candidateId: loggedUserId,
          jobBookmarkedId: 0,
          jobId: jobId,
          isActive: true,
          rowId: 0,
        } as jobBookMarkSaveRequestModel,
        authorizationToken
      );
    }
  };

  useEffect(() => {
    if (candidateId) {
      getAllResumeCandidateMap({
        CandidateId: candidateId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          ////console.log(res.data);
          setCandidateResumes(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, [candidateId]);

  useEffect(() => {
    if (candidateId) {
      getAllCoverLetterCandidateMap({
        CandidateId: candidateId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          ////console.log(res.data);
          setCandidateCoverLetters(res.data);
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [candidateId]);

  const handleJobApplied = () => {
    // //console.log(89, jobId);
    // saveJobApplied(jobAppliedDispatcher, {
    //   isActive: true,
    //   jobId: jobId,
    //   candidateId: loggedUserId,
    //   jobAppliedId: 0,
    //   rowId: 0,
    // } as jobAppliedSaveRequestModel);
  };

  const handleJobSelect = (id: any) => {
    history.push(`/jobSearch/saved/${id}/searchtitle/location/0`);
  };

  const handleShareButtonClick = (id: any, value: any) => {
    setCandidateSavedJobComponentState({
      ...CandidateSavedJobComponentState,
      currentJobId: id,
      filterFlag: value,
    });
    setIsShareOpen(!isShareOpen);
  };

  const handleJobApplayClick = (id: any) => {
    setCandidateSavedJobComponentState({
      ...CandidateSavedJobComponentState,
      currentJobId: id,
    });
    handleJobOpen();
  };

  const handleJobOpen = () => {
    setIsJobAppliedOpen(!isJobAppliedOpen);
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Saved jobs</h1>
          <div className="clearfix" />
          <div className="row ">
            <div className="col-sm-12">
              <div className="section_box3">
                <div className="row">
                  <Scrollbars
                    style={{ height: 750 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {jobBookMark.data != undefined
                      ? jobBookMark.data.map((Job, i) => {
                          return (
                            <div
                              // onClick={() => handleJobSelect(Job.rowId)}
                              className="col-sm-3"
                              key={`${i}`}
                              style={{ width: 300 }}
                            >
                              <div className="matched_jobs">
                                <div className="matched_jobs_cat_t textOverFlow">
                                  {Job.categoryName}
                                </div>
                                <div className="jobs_start1 textOverFlow">
                                  {Job.postedDate}
                                </div>
                                <div className="matched_star">
                                  {Job.isBookmarked ? (
                                    <i
                                      onClick={() =>
                                        handleJobBookMarked(Job.jobId)
                                      }
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <i
                                      // onClick={() =>
                                      //   handleSaveJobBookMarked(Job.jobId)
                                      // }
                                      className="fa fa-star-o"
                                      aria-hidden="true"
                                    />
                                  )}
                                </div>
                                <div className="clearfix" />
                                <Popover
                                  content={"Please click for more details"}
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleJobSelect(Job.jobId)}
                                  >
                                    <div className="matched_heading">
                                      {Job.title}
                                    </div>
                                    <div className="matched_jobs_cat textOverFlow">
                                      <i
                                        className="fa fa-map-marker"
                                        aria-hidden="true"
                                      />
                                      {""}
                                      {Job.location}
                                    </div>
                                    <div className="matched_jobs_cat text-right">
                                      <i
                                        style={{ marginRight: 5 }}
                                        className="fa fa-clock-o"
                                        aria-hidden="true"
                                      />
                                      {"  "}
                                      {Job.jobType}
                                    </div>
                                    <div className="clearfix" />
                                    <p className="textOverFlow">
                                      {Job.description}
                                    </p>
                                  </div>
                                </Popover>
                                <div className="matched_jobs_share">
                                  <a
                                    onClick={() =>
                                      handleShareButtonClick(Job.jobId, true)
                                    }
                                  >
                                    <i
                                      className="fa fa-share-alt"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </div>

                                <div className="jobs_apply">
                                  {
                                    Job.isApplied ? (
                                      <a>{Job.jobAppliedStatus}</a>
                                    ) : myProfileProgressBar.profileProgress <
                                      40 ? (
                                      <Popover
                                        content={
                                          //    "Please complete your profile to minimum 60% to access this feature."
                                          <>
                                            <p>
                                              Please complete your profile to
                                              minimum 40% to access this
                                              feature.
                                            </p>
                                            <Link to="/candidate/my-profile">
                                              Complete Now
                                            </Link>
                                          </>
                                        }
                                      >
                                        {Job.jobActive ? (
                                          <a>Apply Now</a>
                                        ) : (
                                          // "Apply Now"
                                          <Popover content={"In Active job"}>
                                            <a>Apply Now</a>
                                          </Popover>
                                        )}
                                        {/* <a 
                                      // onClick={(id: any) =>
                                      //   handleJobApplayClick(Job.jobId)
                                      // }
                                      >
                                         Apply Now 
                                       </a> */}
                                        //{" "}
                                      </Popover>
                                    ) : Job.jobActive ? (
                                      <a
                                        onClick={(id: any) =>
                                          handleJobApplayClick(Job.jobId)
                                        }
                                      >
                                        Apply Now
                                      </a>
                                    ) : (
                                      <Popover content={"In Active job"}>
                                        <a>Apply Now</a>
                                      </Popover>
                                    )
                                    // <a
                                    //   onClick={(id: any) =>
                                    //     handleJobApplayClick(Job.jobId)
                                    //   }
                                    // >

                                    //   Apply Now
                                    // </a>
                                  }
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />

          <JobAppliedForm
            isJobAppliedOpen={isJobAppliedOpen}
            handleJobOpen={handleJobOpen}
            currentJobId={currentJobId}
            loggedUserId={loggedUserId}
            authorizationToken={authorizationToken}
            candidateResumes={candidateResumes}
            candidateCoverLetters={candidateCoverLetters}
          />
          {/* modal for share */}

          <Modal
            show={isShareOpen}
            onHide={() => {
              setIsShareOpen(!isShareOpen);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Share this job post on</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-sm-12 m_t_30 text-center">
                <div className="social1">
                  <WhatsappShareButton
                    url={encodeURI(
                      `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                    )}
                    className={""}
                    title={"CareerApp Job Post"}
                  >
                    {/* <a href="#" className="social_face"> */}
                    <Tooltip title="whatsApp">
                      <i
                        className="fa fa-whatsapp"
                        style={{
                          fontSize: 51,
                          color: "green",
                          paddingRight: 2,
                        }}
                      ></i>
                    </Tooltip>
                    {/* </a> */}
                  </WhatsappShareButton>{" "}
                  <TelegramShareButton
                    url={`career app job Share`}
                    title={encodeURI(
                      `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                    )}
                  >
                    <Tooltip title="Telegram">
                      <i
                        className="fa fa-telegram"
                        style={{
                          fontSize: 48,
                          color: "rgb(55, 174, 226)",
                        }}
                      ></i>
                    </Tooltip>
                  </TelegramShareButton>
                  <EmailShareButton
                    url={encodeURI(
                      `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                    )}
                    subject={"Jobs In Carrer app"}
                    body={"This is a Jobs from   Carrer app"}
                    className={""}
                  >
                    {/* <EmailIcon></EmailIcon> */}
                    {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                    <Tooltip title="Gmail">
                      <img
                        src={require("../../../images/gmail-icon-svg-28.jpg")}
                        style={{ height: 66, marginTop: -24 }}
                      />
                    </Tooltip>
                  </EmailShareButton>{" "}
                  <CopyToClipboard
                    //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                    text={`https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`}
                  >
                    <Tooltip title="copy to clipboard">
                      {" "}
                      {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                      <img
                        src={require("../../../images/copy-clipboard-icon-28.jpg")}
                        style={{
                          height: 66,
                          marginTop: -24,
                          marginLeft: -15,
                        }}
                        onClick={() => {
                          setIsShareOpen(!isShareOpen);
                        }}
                      />
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="modal-footer  m-t-30"></div>
            </Modal.Body>
          </Modal>
          {/* end Model for share */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default CandidateSavedJobComponent;
