import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import {
  getStoryList,
  useStoryContext,
  useStoryDispatcher,
} from "../../../../action/admin/StoryAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../../action/MyProfileAdminAction";
import {
  StoryRequestModel,
  StoryEditRequestModel,
  Story,
  StoryViewModel,
  StorySaveRequestModel,
  StorySaveRespondModel,
} from "../../../../models/admin/Story";

import AuthService from "../../../../services/AuthService";
import StoryForm from "./Story";
import { Popover } from "antd";
import Popconfirm from "antd/lib/popconfirm";
import { environment } from "../../../../environments/environment";
import {
  useAdminMyJobDispatcher,
  useAdminMyJobContext,
} from "../../../../action/admin/AdminMyJobAction";

interface IStoryComponentProps {}

interface IStoryComponentState {}

const defaultValues = {};

const StoryComponent: React.FC<IStoryComponentProps> = (props) => {
  const [
    StoryComponentState,
    setStoryComponentState,
  ] = React.useState<IStoryComponentState>(defaultValues);

  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;

  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const { myProfile, basicInfo, loggedAdminId } = myProfileAdminContext;

  const adminId = loggedAdminId;

  //console.log("userId", userId, "adminId", adminId);

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  const StoryDispatcher = useStoryDispatcher();
  const StoryContext = useStoryContext();
  const { Story, StorySaveRespond } = StoryContext;
  //console.log("----------Story-----------", Story);

  let history = useHistory();

  const [isStoryOpen, setisStoryOpen] = useState<boolean>(false);
  const [StoryId, setStoryId] = useState<number>(0);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IStoryComponentState>({
    defaultValues,
  });

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      //console.log("career tips call getStoryList", adminId);
      (async () => {
        await getStoryList(
          StoryDispatcher,
          {
            adminId: 0,
            page: 1,
            pageSize: 60,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as StoryRequestModel
          //authorizationToken
        );
      })();
    }
  });

  const handleApprovalStatus = (StoryStatusId: number, StoryId: number) => {
    // if (authorizationToken != null)
    // setStoryStatus(
    //     StoryDispatcher,
    //     {
    //           myTeamMemberId: 0,
    //           myTeamMemberId: myTeamMemberId,
    //           adminId: adminId,
    //           StoryStatusId: StoryStatusId,
    //           isActive: true
    //     } as StorySaveRequestModel,
    //     authorizationToken
    //   );
  };

  const handleJobApplayClick = (id: any) => {
    setStoryComponentState({
      ...StoryComponentState,
      myTeamMemberId: id,
    });
    handleCareerAppliedOpen();
  };

  const handleCareerAppliedOpen = () => {
    setisStoryOpen(!isStoryOpen);
  };

  const menuRef = useRef<any>();

  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setIsListOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Stories Of JIT</h1>
          <a
            onClick={() => {
              setStoryId(0);
              handleCareerAppliedOpen();
            }}
            data-target="#create_jobdescription"
            data-toggle="modal"
            className="btn cutomise_but"
          >
            <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp; Add New
          </a>
          <div className="action_btn1">
            <button
              type="button"
              className="actions_menu"
              onClick={() => {
                setIsListOpen(!isListOpen);
              }}
            >
              <i className="fa fa-align-left" aria-hidden="true" />
            </button>
            <div
              className="user_menu"
              tabIndex={-1}
              style={{ display: isListOpen ? "block" : "none" }}
            >
              <div className="action_sec">
                <ul ref={menuRef}>
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isMyTeamPermission ? (
                    <li>
                      <Link to="/admin/myteammember">My Team</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isStoriesofJIT ? (
                    <li>
                      <Link to="/admin/story">Stories of JIT</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isCompanyContactsandSocialPermission ? (
                    <li>
                      <Link to="/admin/socialaccounturl">
                        Company Contacts and Social Media
                      </Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isAchievementPermission ? (
                    <li>
                      <Link to="/admin/achievements">Achievements</Link>{" "}
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  (AdminPermissionResult.isAddJobCategoryPermission ||
                    AdminPermissionResult.isAddJobTitlePermission) ? (
                    <li>
                      <Link to="/admin/sitesettings/jobCategory">
                        Category / Job Title
                      </Link>{" "}
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="stories">
            <div className="row">
              <div className="partner-table">
                {Story.data != undefined ? (
                  <div>
                    <div className="col-sm-6 partner">
                      <img
                        src={
                          environment.baseUrl +
                          `/Upload/Story/${Story.data[0].imageUrl}`
                        }
                        className="img-responsive center-block"
                      />
                    </div>
                    <div className="col-sm-6 partner">
                      <h1>Stories of JIT</h1>
                      <p>{Story.data[0].description}</p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <StoryForm
            isStoryOpen={isStoryOpen}
            handleStoryOpen={handleCareerAppliedOpen}
            currentStoryId={StoryId}
            loggedAdminId={loggedAdminId}
            authorizationToken={authorizationToken}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default StoryComponent;
