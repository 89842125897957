import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { IVendorJobAppliedAction, vendorJobAppliedReducer } from "../../action/vendor/VendorJobAppliedAction";
import { GetAllSuggestedJobRespondModel, VendorGetAllJobRequestModel, VendorGetAllJobRespondModel, VendorJobAppliedRespondModal } from "../../models/vendor/VendorJobApplied";



export const VendorJobAppliedDispatcherContext = React.createContext<React.Dispatch<IVendorJobAppliedAction> | null>(
  null
);
export const VendorJobAppliedStateContext = React.createContext<IVendorJobAppliedManagementState | null>(
  null
);

export interface IVendorJobAppliedManagementProps extends RouteComponentProps<any> {}

export interface IVendorJobAppliedManagementState {
  // visible: boolean;
  // value: number;
  vendorGetAllJobRequestModel: VendorGetAllJobRequestModel;
   vendorJobAppliedSaveRespond: VendorJobAppliedRespondModal;
   vendorGetAllJobList:VendorGetAllJobRespondModel,
   vendorGetAllSuggestedJob:GetAllSuggestedJobRespondModel
  // vendorJobAppliedCategoryMapSaveRespond: vendorJobAppliedSaveRespondModel;
  // vendorJobAppliedExperienceMapSaveRespond: vendorJobAppliedSaveRespondModel;
  // vendorJobAppliedIndustryMapSaveRespond: vendorJobAppliedSaveRespondModel;
  // VendorJobAppliedRoleMapSaveRespond: vendorJobAppliedSaveRespondModel;
  // VendorJobAppliedTypeMapSaveRespond: vendorJobAppliedSaveRespondModel;
  // vendorJobAppliedGetAll: vendorJobAppliedGellAllRespondModel;
  // vendorJobAppliedTitleDropdownResult: vendorJobAppliedTitleDropdownResult[];
}

export const initialVendorJobAppliedManagementState = {
  // visible: false,
  // value: 0,
  // vendorJobApplied: {} as vendorJobAppliedViewModel,
  // vendorJobAppliedGetAll: {} as vendorJobAppliedGellAllRespondModel,
  // vendorJobAppliedTitleDropdownResult: [] as vendorJobAppliedTitleDropdownResult[],
  vendorGetAllJobRequestModel: {} as VendorGetAllJobRequestModel,
   vendorJobAppliedSaveRespond: {} as VendorJobAppliedRespondModal,
   vendorGetAllSuggestedJob:{} as GetAllSuggestedJobRespondModel,
   vendorGetAllJobList:{} as VendorGetAllJobRespondModel,
} as IVendorJobAppliedManagementState;

export const VendorJobAppliedContextProvider: React.FC = ({ children }) => {
  const [vendorJobAppliedState, dispatcher] = useImmerReducer(
    vendorJobAppliedReducer,
    initialVendorJobAppliedManagementState
  );

  return (
    <VendorJobAppliedDispatcherContext.Provider value={dispatcher}>
      <VendorJobAppliedStateContext.Provider value={vendorJobAppliedState}>
        {children}
      </VendorJobAppliedStateContext.Provider>
    </VendorJobAppliedDispatcherContext.Provider>
  );
};
