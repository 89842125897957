import _ from "lodash";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { environment } from "../../../environments/environment";

type objectIndexing = {
  [key: string]: any;
};
interface IJobApplicationProfileToPdf {
  candidateState: {
    data: never[];
    loading: boolean;
    error: null;
  };
  socialAccounts: {
    data: never[];
    loading: boolean;
    error: null;
  };
  candidateProfileImage: {
    data: never[];
    loading: boolean;
    error: null;
  };
  progressBar: {
    profileProgress: number;
    resumeProgress: number;
  };
  handleShowPDF: (value: number) => void;
  checkedListArrayForDisplay: any[];

  candidateLanguageMaps: {
    data: never[];
    loading: boolean;
    error: null;
  };

  candidateReferences: {
    data: never[];
    loading: boolean;
    error: null;
  };
  candidateRelativesState: {
    data: never[];
    loading: boolean;
    error: null;
  };
  educationQualificationState: {
    data: never[];
    loading: boolean;
    error: null;
  };
  checkedListArrayForDisplayQualification: any[];
  candidateExperienceState: {
    data: never[];
    loading: boolean;
    error: null;
  };
  checkedListArrayForDisplayExperience: any[];
  trainingState: { data: never[]; loading: boolean; error: null };
  checkedListArrayForDisplayTraining: any[];
  candidateSkillsState: { data: never[]; loading: boolean; error: null };
  checkedListArrayForDisplaySkill: any[];

  onePassportInformation: objectIndexing;
  vendorState: { data: never[]; loading: boolean; error: null };

  vendorSocialAccounts: { data: never[]; loading: boolean; error: null };
  userId: number;
  vendorProfileImage: { data: never[]; loading: boolean; error: null };
  progressBarVendor: { profileProgress: number; resumeProgress: number };
}

const JobApplicationProfileToPdf: React.FC<IJobApplicationProfileToPdf> = (
  props
) => {
  const {
    candidateProfileImage,
    candidateState,
    progressBar,
    socialAccounts,
    handleShowPDF,
    checkedListArrayForDisplay,
    candidateLanguageMaps,
    candidateReferences,
    candidateRelativesState,
    educationQualificationState,
    checkedListArrayForDisplayQualification,
    candidateExperienceState,
    checkedListArrayForDisplayExperience,
    checkedListArrayForDisplayTraining,
    trainingState,
    candidateSkillsState,
    checkedListArrayForDisplaySkill,
    onePassportInformation,
    vendorSocialAccounts,
    vendorState,
    userId,
    vendorProfileImage,
    progressBarVendor,
  } = props;

  const componentRef = useRef<HTMLInputElement | null>(null);

  const onHandleShowPDF = () => {
    handleShowPDF(0);
  };
  // React.useEffect(() => {
  //   // if (initialUserId != 0) {

  // }, []);

  // const handlePdfClick=()=>{
  //   <ReactToPrint
  //     trigger={() => (
  //       <button className="btn cutomise_but">Export To PDF</button>
  //     )}
  //     content={() => componentRef.current}
  //   />
  // }

  return (
    <>
      {userId == 1 ? (
        <div className="content-wrapper">
          <div className="container-fluid">
            <h1 className="heading">Published</h1>
            <a onClick={onHandleShowPDF} className="btn cutomise_but">
              <i className="fa fa-angle-left" aria-hidden="true" /> &nbsp; Back{" "}
            </a>
            {/* <Pdf targetRef={refOne} filename="div-blue.pdf">
            {({ toPdf }: any) => (
              // <button onClick={toPdf}>Generate pdf</button>
              <button onClick={toPdf} className="btn cutomise_but">
                {" "}
                Export To PDF{" "}
              </button>
            )}
          </Pdf> */}
            <ReactToPrint
              trigger={() => (
                <button className="btn cutomise_but">Export To PDF</button>
              )}
              content={() => componentRef.current}
              bodyClass={"col-sm-6 social_net"}
            />
            {/* <a href="admin_myjobs_candidates.html" className="btn cutomise_but">
            {" "}
            Export To PDF{" "}
          </a> */}

            {/* <div className="action_btn1 ">
              <button type="button" className="actions_menu">
                <i className="fa fa-align-left" aria-hidden="true" />
              </button>
              <div className="user_menu" tabIndex={-1}>
                <div className="action_sec">
                  <ul>
                    <li>
                      <a href="#">Drafted</a>{" "}
                    </li>
                    <li>
                      <a href="#">Published </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            */}
            <div className="clearfix" />
            <div className="row " ref={componentRef}>
              <div className="col-sm-12">
                <div className=" section_box_m">
                  <table style={{ width: 875 }}>
                    <tbody>
                      <tr>
                        <td>
                          <div className="col-sm-3">
                            <div className="profile_sec_photo">
                              {/* <img
                      src="images/admin_users.jpg"
                      alt=""
                      className="image1"
                    /> */}
                              {candidateProfileImage.data.length > 0 ? (
                                <img
                                  className="img-responsive"
                                  src={
                                    environment.baseUrl +
                                    `/Upload/ProfileImage/${candidateProfileImage.data[0]["imageUrl"]}`
                                  }
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="img-responsive"
                                  src={require("./../../../images/profileDefault1.jpg")}
                                ></img>
                              )}
                              <div className="overlay">
                                <div className="upload_photo">
                                  <a
                                    href="#"
                                    data-target="#upload_ban"
                                    data-toggle="modal"
                                  >
                                    <i
                                      className="fa fa-picture-o"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                            {/* <div className="profile_cons1">Profile Complet</div>
                            <div className="profile_cons1">
                              {progressBar.profileProgress}%
                            </div> */}
                          </div>
                        </td>
                        <td>
                          <div className="col-sm-6">
                            {checkedListArrayForDisplay.includes(1) ||
                            checkedListArrayForDisplay.includes(2) ||
                            checkedListArrayForDisplay.includes(3) ||
                            checkedListArrayForDisplay.includes(4) ||
                            checkedListArrayForDisplay.includes(5) ? (
                              <div className="social_net">
                                {" "}
                                Social Networks{" "}
                              </div>
                            ) : null}

                            {socialAccounts.data.length > 0 &&
                            socialAccounts.data[0]["facebooks"] !== "" ? (
                              <div>
                                {checkedListArrayForDisplay.includes(1) ? (
                                  <label htmlFor="checkbox1">
                                    <span />{" "}
                                    {socialAccounts.data.length > 0
                                      ? socialAccounts.data[0]["facebooks"]
                                      : null}
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                            {socialAccounts.data.length > 0 &&
                            socialAccounts.data[0]["google"] !== "" ? (
                              <div>
                                {checkedListArrayForDisplay.includes(2) ? (
                                  <label htmlFor="checkbox1">
                                    <span />{" "}
                                    {socialAccounts.data.length > 0
                                      ? socialAccounts.data[0]["google"]
                                      : null}
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                            {socialAccounts.data.length > 0 &&
                            socialAccounts.data[0]["linkedIn"] !== "" ? (
                              <div>
                                {checkedListArrayForDisplay.includes(3) ? (
                                  <label htmlFor="checkbox1">
                                    <span />{" "}
                                    {socialAccounts.data.length > 0
                                      ? socialAccounts.data[0]["linkedIn"]
                                      : null}
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                            {socialAccounts.data.length > 0 &&
                            socialAccounts.data[0]["twitter"] !== "" ? (
                              <div>
                                {checkedListArrayForDisplay.includes(4) ? (
                                  <label htmlFor="checkbox1">
                                    <span />{" "}
                                    {socialAccounts.data.length > 0
                                      ? socialAccounts.data[0]["twitter"]
                                      : null}
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                            {socialAccounts.data.length > 0 &&
                            socialAccounts.data[0]["instagram"] !== "" ? (
                              <div>
                                {checkedListArrayForDisplay.includes(5) ? (
                                  <label htmlFor="checkbox1">
                                    <span />{" "}
                                    {socialAccounts.data.length > 0
                                      ? socialAccounts.data[0]["instagram"]
                                      : null}
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* personal info */}
                  {checkedListArrayForDisplay.includes(7) ||
                  checkedListArrayForDisplay.includes(8) ||
                  checkedListArrayForDisplay.includes(9) ||
                  checkedListArrayForDisplay.includes(10) ||
                  checkedListArrayForDisplay.includes(11) ||
                  checkedListArrayForDisplay.includes(12) ||
                  checkedListArrayForDisplay.includes(13) ||
                  checkedListArrayForDisplay.includes(14) ? (
                    <div className="social_net">
                      {" "}
                      <h4 style={{ marginTop: 50 }}>PERSONAL INFO</h4>
                    </div>
                  ) : null}

                  <table style={{}}>
                    <tbody>
                      {checkedListArrayForDisplay.includes(7) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data != undefined &&
                              candidateState.data.length > 0
                                ? candidateState.data[0]["firstName"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(8) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data != undefined &&
                              candidateState.data.length > 0
                                ? candidateState.data[0]["genderId"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(9) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data != undefined &&
                              candidateState.data.length > 0
                                ? candidateState.data[0]["dob"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(10) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data != undefined &&
                              candidateState.data.length > 0
                                ? candidateState.data[0]["martialStatusId"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(11) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data != undefined &&
                              candidateState.data.length > 0
                                ? candidateState.data[0]["countryId"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(12) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateLanguageMaps.data != undefined &&
                              candidateLanguageMaps.data.length > 0
                                ? candidateLanguageMaps.data.map(
                                    (e: any) => `${e["languageId"]}`
                                  )
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(13) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data != undefined &&
                              candidateState.data.length > 0
                                ? candidateState.data[0]["positionApplyingFor"]
                                  ? candidateState.data[0][
                                      "positionApplyingFor"
                                    ]
                                  : ""
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(14) ? (
                        <div className="col-sm-6">
                          <label htmlFor="checkbox14">
                            <span />
                            <h5>
                              {candidateState.data != undefined &&
                              candidateState.data.length > 0
                                ? candidateState.data[0]["profileSummary"] ==
                                  null
                                  ? ""
                                  : candidateState.data[0]["profileSummary"] +
                                    ""
                                : ""}
                            </h5>
                          </label>
                        </div>
                      ) : null}
                    </tbody>
                  </table>

                  {/* Communication */}

                  {checkedListArrayForDisplay.includes(26) ||
                  checkedListArrayForDisplay.includes(15) ||
                  checkedListArrayForDisplay.includes(16) ||
                  checkedListArrayForDisplay.includes(17) ||
                  checkedListArrayForDisplay.includes(18) ||
                  checkedListArrayForDisplay.includes(19) ||
                  checkedListArrayForDisplay.includes(20) ? (
                    <div className="social_net">
                      {" "}
                      <h4 style={{ marginTop: 50 }}>COMMUNICATION</h4>
                    </div>
                  ) : null}

                  <table style={{}}>
                    <tbody>
                      {checkedListArrayForDisplay.includes(26) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data.length > 0
                                ? candidateState.data[0]["phoneNumber"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {checkedListArrayForDisplay.includes(15) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data.length > 0
                                ? candidateState.data[0]["email"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {checkedListArrayForDisplay.includes(16) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data.length > 0
                                ? candidateState.data[0]["email"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {checkedListArrayForDisplay.includes(17) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data.length > 0
                                ? candidateState.data[0]["email"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {checkedListArrayForDisplay.includes(18) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {candidateState.data.length > 0
                                ? candidateState.data[0]["email"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(19) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />
                              {candidateState.data.length > 0
                                ? candidateState.data[0]["permanantAddress1"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(20) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />
                              {candidateState.data.length > 0
                                ? candidateState.data[0]["currentAddress1"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {checkedListArrayForDisplay.includes(21) ||
                      checkedListArrayForDisplay.includes(22) ||
                      checkedListArrayForDisplay.includes(23) ||
                      checkedListArrayForDisplay.includes(24) ||
                      checkedListArrayForDisplay.includes(25) ? (
                        <div className="social_net">
                          {" "}
                          <h4>Emergency Contact Details</h4>
                        </div>
                      ) : null}
                      {/* <h4>Emergency Contact Details</h4> */}
                      {checkedListArrayForDisplay.includes(21) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />
                              {candidateRelativesState.data.length > 0
                                ? candidateRelativesState.data[0][
                                    "relativeFirstName"
                                  ]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(22) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />
                              {candidateRelativesState.data.length > 0
                                ? candidateRelativesState.data[0]["countryCode"]
                                : "+91"}
                              {candidateRelativesState.data.length > 0
                                ? candidateRelativesState.data[0]["phoneNumber"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      <h4>Reference Details</h4>
                      {checkedListArrayForDisplay.includes(23) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />
                              {candidateReferences.data.length > 0
                                ? candidateReferences.data[0][
                                    "referenceFirstName"
                                  ]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(24) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />
                              {candidateReferences.data.length > 0
                                ? candidateReferences.data[0]["countryCode"]
                                : "+91"}
                              {candidateReferences.data.length > 0
                                ? candidateReferences.data[0]["phoneNumber"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(25) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />
                              {candidateReferences.data.length > 0
                                ? candidateReferences.data[0]["email"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                    </tbody>
                  </table>

                  {checkedListArrayForDisplayQualification.length > 0 ? (
                    <div className="social_net">
                      {" "}
                      <h4 style={{ marginTop: 50 }}>QUALIFICATION</h4>
                    </div>
                  ) : null}

                  <table style={{}}>
                    <tbody>
                      {educationQualificationState.data.map(
                        (data: any, i: any) => {
                          return (
                            <>
                              {checkedListArrayForDisplayQualification.includes(
                                data["rowId"] + 200
                              ) ? (
                                <div className="col-sm-6">
                                  <div>
                                    <label htmlFor="checkbox1">
                                      <span /> {data.course + ""}
                                    </label>
                                    ,{" "}
                                    <label htmlFor="checkbox1">
                                      <span /> {data.university}
                                    </label>
                                    ,{" "}
                                    <label htmlFor="checkbox1">
                                      <span /> {data.qualificationYear}
                                    </label>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>

                  {checkedListArrayForDisplayExperience.length > 0 ? (
                    <div className="social_net">
                      {" "}
                      <h4 style={{ marginTop: 50 }}>Experience</h4>
                    </div>
                  ) : null}

                  <table style={{}}>
                    <tbody>
                      {candidateExperienceState.data.map(
                        (data: any, i: any) => {
                          return (
                            <>
                              {checkedListArrayForDisplayExperience.includes(
                                data["rowId"] + 300
                              ) ? (
                                <div className="col-sm-6">
                                  <div>
                                    <label htmlFor="checkbox1">
                                      <span /> {data.employerName + ""}
                                    </label>
                                    ,{" "}
                                    <label htmlFor="checkbox1">
                                      <span /> {data.jobRole}
                                    </label>
                                    ,{" "}
                                    <label htmlFor="checkbox1">
                                      <span /> {data.responsibilities}
                                    </label>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  {checkedListArrayForDisplayTraining.length > 0 ? (
                    <div className="social_net">
                      {" "}
                      <h4 style={{ marginTop: 50 }}>Training</h4>
                    </div>
                  ) : null}
                  {/* training start */}

                  <table style={{}}>
                    <tbody>
                      {trainingState.data.map((data: any, i: any) => {
                        return (
                          <>
                            {checkedListArrayForDisplayTraining.includes(
                              data["rowId"] + 400
                            ) ? (
                              <div className="col-sm-6">
                                <div>
                                  <label htmlFor="checkbox1">
                                    <span /> {data.institute + ""}
                                  </label>
                                  ,{" "}
                                  <label htmlFor="checkbox1">
                                    <span /> {data.trainingCertificate}
                                  </label>
                                </div>
                              </div>
                            ) : null}
                          </>
                        );
                      })}
                    </tbody>
                  </table>

                  {/* skill start */}

                  {checkedListArrayForDisplayTraining.length > 0 ? (
                    <div className="social_net">
                      {" "}
                      <h4 style={{ marginTop: 50 }}>Skill</h4>
                    </div>
                  ) : null}

                  {/* <h4 style={{ marginTop: 50 }}>Skill</h4> */}
                  <table style={{}}>
                    <tbody>
                      {candidateSkillsState.data.map((data: any, i: any) => {
                        return (
                          <>
                            {checkedListArrayForDisplaySkill.includes(
                              data["rowId"] + 500
                            ) ? (
                              <div className="col-sm-6">
                                <div>
                                  <label htmlFor="checkbox1">
                                    <span /> {data.skillName + " "}
                                  </label>
                                  ,{" "}
                                  <label htmlFor="checkbox1">
                                    <span /> {data.proficiencyTitle}
                                  </label>
                                </div>
                              </div>
                            ) : null}
                          </>
                        );
                      })}
                    </tbody>
                  </table>

                  {/* document start */}
                  {checkedListArrayForDisplay.includes(801) ? (
                    <div className="social_net">
                      {" "}
                      <h4 style={{ marginTop: 50 }}>Document</h4>
                    </div>
                  ) : null}

                  <table style={{}}>
                    <tbody></tbody>
                  </table>
                  {_.isEmpty(onePassportInformation) ? (
                    ""
                  ) : (
                    <>
                      {checkedListArrayForDisplay.includes(801) ? (
                        <>
                          <h1>Passport</h1>
                          <div className="col-sm-6">
                            <div>
                              <label htmlFor="checkbox1">
                                <span /> {onePassportInformation["passportNo"]}
                              </label>{" "}
                              <label htmlFor="checkbox1">
                                <span /> {onePassportInformation["ecrStatus"]}
                              </label>
                              ,{" "}
                              <label htmlFor="checkbox1">
                                <span /> {onePassportInformation["placeIssued"]}
                              </label>
                              ,{" "}
                              <label htmlFor="checkbox1">
                                <span /> {onePassportInformation["endDate"]}
                              </label>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      ) : (
        <div className="content-wrapper">
          <div className="container-fluid">
            <h1 className="heading">Published</h1>
            <a onClick={onHandleShowPDF} className="btn cutomise_but">
              <i className="fa fa-angle-left" aria-hidden="true" /> &nbsp; Back{" "}
            </a>
            {/* <Pdf targetRef={refOne} filename="div-blue.pdf">
       {({ toPdf }: any) => (
         // <button onClick={toPdf}>Generate pdf</button>
         <button onClick={toPdf} className="btn cutomise_but">
           {" "}
           Export To PDF{" "}
         </button>
       )}
     </Pdf> */}
            <ReactToPrint
              trigger={() => (
                <button className="btn cutomise_but">Export To PDF</button>
              )}
              content={() => componentRef.current}
              bodyClass={"col-sm-6 social_net"}
            />
            {/* <a href="admin_myjobs_candidates.html" className="btn cutomise_but">
       {" "}
       Export To PDF{" "}
     </a> */}

            <div className="action_btn1 ">
              <button type="button" className="actions_menu">
                <i className="fa fa-align-left" aria-hidden="true" />
              </button>
              {/* <div className="user_menu" tabIndex={-1}>
                <div className="action_sec">
                  <ul>
                    <li>
                      <a href="#">Drafted</a>{" "}
                    </li>
                    <li>
                      <a href="#">Published </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="clearfix" />
            <div className="row " ref={componentRef}>
              <div className="col-sm-12">
                <div className=" section_box_m">
                  <table style={{ width: 875 }}>
                    <tbody>
                      <tr>
                        <td>
                          <div className="col-sm-3">
                            <div className="profile_sec_photo">
                              {/* <img
                 src="images/admin_users.jpg"
                 alt=""
                 className="image1"
               /> */}
                              {vendorProfileImage.data.length > 0 ? (
                                <img
                                  className="img-responsive"
                                  src={
                                    environment.baseUrl +
                                    `/Upload/VendorProfileImage/${vendorProfileImage.data[0]["imageUrl"]}`
                                  }
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="img-responsive"
                                  src={require("./../../../images/profileDefault1.jpg")}
                                ></img>
                              )}
                              <div className="overlay">
                                <div className="upload_photo">
                                  <a
                                    href="#"
                                    data-target="#upload_ban"
                                    data-toggle="modal"
                                  >
                                    <i
                                      className="fa fa-picture-o"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                            <div className="profile_cons1">Profile Complet</div>
                            {/* <div className="profile_cons1">
                              {progressBarVendor.profileProgress}%
                            </div> */}
                          </div>
                        </td>
                        <td>
                          <div className="col-sm-6">
                            {checkedListArrayForDisplay.includes(1001) ||
                            checkedListArrayForDisplay.includes(1002) ||
                            checkedListArrayForDisplay.includes(1003) ||
                            checkedListArrayForDisplay.includes(1004) ||
                            checkedListArrayForDisplay.includes(1005) ? (
                              <div className="social_net">
                                {" "}
                                Social Networks{" "}
                              </div>
                            ) : null}

                            {vendorSocialAccounts.data.length > 0 &&
                            vendorSocialAccounts.data[0]["facebooks"] !== "" ? (
                              <div>
                                {checkedListArrayForDisplay.includes(1001) ? (
                                  <label htmlFor="checkbox1">
                                    <span />{" "}
                                    {vendorSocialAccounts.data.length > 0
                                      ? vendorSocialAccounts.data[0][
                                          "facebooks"
                                        ]
                                      : null}
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                            {vendorSocialAccounts.data.length > 0 &&
                            vendorSocialAccounts.data[0]["google"] !== "" ? (
                              <div>
                                {checkedListArrayForDisplay.includes(1002) ? (
                                  <label htmlFor="checkbox1">
                                    <span />{" "}
                                    {vendorSocialAccounts.data.length > 0
                                      ? vendorSocialAccounts.data[0]["google"]
                                      : null}
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                            {vendorSocialAccounts.data.length > 0 &&
                            vendorSocialAccounts.data[0]["linkedIn"] !== "" ? (
                              <div>
                                {checkedListArrayForDisplay.includes(1003) ? (
                                  <label htmlFor="checkbox1">
                                    <span />{" "}
                                    {vendorSocialAccounts.data.length > 0
                                      ? vendorSocialAccounts.data[0]["linkedIn"]
                                      : null}
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                            {vendorSocialAccounts.data.length > 0 &&
                            vendorSocialAccounts.data[0]["twitter"] !== "" ? (
                              <div>
                                {checkedListArrayForDisplay.includes(1004) ? (
                                  <label htmlFor="checkbox1">
                                    <span />{" "}
                                    {vendorSocialAccounts.data.length > 0
                                      ? vendorSocialAccounts.data[0]["twitter"]
                                      : null}
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                            {vendorSocialAccounts.data.length > 0 &&
                            vendorSocialAccounts.data[0]["instagram"] !== "" ? (
                              <div>
                                {checkedListArrayForDisplay.includes(1005) ? (
                                  <label htmlFor="checkbox1">
                                    <span />{" "}
                                    {vendorSocialAccounts.data.length > 0
                                      ? vendorSocialAccounts.data[0][
                                          "instagram"
                                        ]
                                      : null}
                                  </label>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* personal info */}
                  {checkedListArrayForDisplay.includes(1007) ||
                  checkedListArrayForDisplay.includes(1008) ||
                  checkedListArrayForDisplay.includes(1009) ||
                  checkedListArrayForDisplay.includes(1010) ? (
                    <div className="social_net">
                      {" "}
                      <h4 style={{ marginTop: 50 }}>PERSONAL INFO</h4>
                    </div>
                  ) : null}
                  <h4 style={{ marginTop: 50 }}>PERSONAL INFO</h4>
                  <table style={{}}>
                    <tbody>
                      {checkedListArrayForDisplay.includes(1007) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {vendorState.data != undefined &&
                              vendorState.data.length > 0
                                ? vendorState.data[0]["vendorName"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(1008) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {vendorState.data != undefined &&
                              vendorState.data.length > 0
                                ? vendorState.data[0]["email"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(1009) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {vendorState.data != undefined &&
                              vendorState.data.length > 0
                                ? vendorState.data[0]["countryCode"]
                                : ""}{" "}
                              {vendorState.data != undefined &&
                              vendorState.data.length > 0
                                ? vendorState.data[0]["phoneNumber"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                      {checkedListArrayForDisplay.includes(1010) ? (
                        <div className="col-sm-6">
                          <div>
                            <label htmlFor="checkbox1">
                              <span />{" "}
                              {vendorState.data != undefined &&
                              vendorState.data.length > 0
                                ? vendorState.data[0]["location"]
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : null}
                    </tbody>
                  </table>

                  {/* Communication */}
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      )}
    </>
  );
};

export default JobApplicationProfileToPdf;
