import React, { useState, useEffect } from "react";

import AdminProfileComponent from "./AdminProfileComponent";
import { getAdminId } from "./../../../apis/admin";
import AuthService from "./../../../services/AuthService";
import {
  useAdminContext,
  useAdminProfileImageContext,
} from "./../../../context/admin/AdminMyProfile";
import {
  getAdminLoggedUserId,
  getAdminMyProfileSelectBoxList,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import {
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";

interface IAdminProfileContainerProps {}

interface IAdminProfileContainerState {}

const initialState = {};

const AdminProfileContainer: React.FC<IAdminProfileContainerProps> = (
  props
) => {
  // Api For SelectBox Data
  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const myProfileDispatcher = useMyProfileDispatcher();
  const myProfileContext = useMyProfileContext();

  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const { myProfile, basicInfo, loggedAdminId } = myProfileAdminContext;

  const adminId = loggedAdminId;

  //console.log("userId", userId, "adminId", adminId);

  const [
    AdminExperienceSelectBoxData,
    setAdminExperienceSelectBoxData,
  ] = useState({});

  const [AdminRelativeSelectBoxData, setAdminRelativeSelectBoxData] = useState(
    {}
  );

  const AdminContext = useAdminContext();
  const AdminProfileImageContext = useAdminProfileImageContext();

  const { adminProfileImage, getAdminProfileImage } = AdminProfileImageContext;

  const [progressBar, setProgressBar] = useState({});
  const [documentStatus, setDocumentStatus] = useState({});
  const { getAdmins, adminState } = AdminContext;

  // const [socialAccounts, setSocialAccounts] = useState([]);

  //console.log(888888888888888, loggedAdminId, userId);

  useEffect(() => {
    if (adminId) {
      //console.log(adminId, "@@@@@/////////// ", adminState);
      getAdmins({
        AdminId: Number(adminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      });

      if (adminProfileImage.data.length <= 0) {
        getAdminProfileImage({
          AdminId: Number(adminId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "string",
          SortOrder: "string",
          ShowInactive: false,
        });
      }
    }
  }, [adminId]);

  React.useEffect(() => {
    (async () => {
      await getAdminMyProfileSelectBoxList(myProfileAdminDispatcher);
    })();
  }, [loggedAdminId]);

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  return (
    <>
      <AdminProfileComponent
        myProfile={myProfile}
        userId={userId}
        adminId={adminId}
        adminState={adminState}
        getAdmins={getAdmins}
        progressBar={progressBar}
        getAdminProfileImage={getAdminProfileImage}
        adminProfileImage={adminProfileImage}
      />
    </>
  );
};
export default AdminProfileContainer;
