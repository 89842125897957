import { Reducer } from "react";

import React from "react";

import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  BookMarkCandidateViewResponseModel,
  jobBookMarkDataWithId,
  jobBookMarkDataWithUserId,
  jobBookMarkRequestModel,
  jobBookMarkSaveRequestModel,
  jobBookMarkSaveRespondModel,
  jobBookMarkViewModel,
} from "../../models/candidate/BookMarkedJob";
import {
  IJobBookMarkManagementState,
  JobBookMarkDispatcherContext,
  JobBookMarkStateContext,
} from "../../context/candidate/JobBookMarkContext";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IJobBookMarkAction =
  | {
      type: "SET_Job_BookMarked_LIST";
      jobBookMark: BookMarkCandidateViewResponseModel;
    }
  | {
      type: "SAVE_Job_BookMarked_LIST";
      jobBookMarkSaveRespond: jobBookMarkSaveRespondModel;
    }
  | {
      type: "Delete_BookMarked_LIST";
      jobBookMarkSaveRespond: jobBookMarkSaveRespondModel;
    };

export const jobBookMarkReducer: Reducer<
  IJobBookMarkManagementState,
  IJobBookMarkAction
> = (draft, action): IJobBookMarkManagementState => {
  switch (action.type) {
    case "SET_Job_BookMarked_LIST":
      draft.jobBookMark = action.jobBookMark;
      return draft;
    case "SAVE_Job_BookMarked_LIST":
      draft.jobBookMarkSaveRespond = action.jobBookMarkSaveRespond;
      return draft;
    case "Delete_BookMarked_LIST":
      draft.jobBookMarkSaveRespond = action.jobBookMarkSaveRespond;
      return draft;
  }
};

export const useJobBookMarkDispatcher = (): React.Dispatch<IJobBookMarkAction> => {
  const jobBookMarkDispatcher = React.useContext(JobBookMarkDispatcherContext);
  if (!jobBookMarkDispatcher) {
    throw new Error(
      "You have to provide the JobBookMark dispatcher using theJobBookMarkDispatcherContext.Provider in a parent component."
    );
  }
  return jobBookMarkDispatcher;
};

export const useJobBookMarkContext = (): IJobBookMarkManagementState => {
  const jobBookMarkContext = React.useContext(JobBookMarkStateContext);
  if (!jobBookMarkContext)
    throw new Error(
      "You have to provide the jobBookMark context using the JobBookMarkStateContext.Provider in a parent component."
    );
  return jobBookMarkContext;
};

export const getJobBookMarkList = async (
  dispatcher: React.Dispatch<IJobBookMarkAction>,
  query: jobBookMarkRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllBookMarkedJobs;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "SET_Job_BookMarked_LIST", jobBookMark: res.data });
    });
  } catch (e) {}
};

export const deleteJobBookMarkList = async (
  dispatcher: React.Dispatch<IJobBookMarkAction>,
  query: jobBookMarkDataWithUserId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteBookMarked;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "Delete_BookMarked_LIST",
        jobBookMarkSaveRespond: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Job is removed from Saved Jobs.")
          : message.error(res.data.message)
        : message.error(
            " some error happend removed a job from the saved jobs list"
          );
    });
  } catch (e) {}
};

export const saveBookMarkList = async (
  dispatcher: React.Dispatch<IJobBookMarkAction>,
  query: jobBookMarkSaveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveBookMarkList;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SAVE_Job_BookMarked_LIST",
        jobBookMarkSaveRespond: res.data,
      });

      res.status == 200
        ? message.success("Done Successfuly")
        : message.error("There are some error happend on save a job");
    });
  } catch (e) {}
};
