import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../../candidate/my_profile/components/SelectOption";
// import { getBulkEMAILTemplates } from "../../../apis/admin";
import { saveAdminActions } from "../../../apis/admin";
import {
  useAdminCommunicationDispatcher,
  useAdminCommunicationContext,
  getEmailTemplateList,
  saveEmail,
  saveBulkEmailConversation,
} from "../../../action/admin/AdminCommunicationAction";
import {
  BulkEmailListRequestModel,
  BulkEmailDetailRequestModel,
  BulkEmailDetailPostModel,
  MailTransactionsData,
  BulkEmailDetailListModel,
  BulkEmailAllListModel,
  BulkEmailRespondModel,
} from "../../../models/admin/AdminCommunication";
import { saveEmails } from "./../../../apis/admin";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "./../../../context/GlobalSpinner";
import _ from "lodash";
import moment from "moment";
import { Modal } from "react-bootstrap";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import { environment } from "../../../environments/environment";
import { staticEmailTemplate } from "./BulkEmailTemplate";

interface ReactSelectOption {
  value: string;
  label: string;
}

type objectIndexing = {
  [key: string]: any;
};

function SelectTemplate(props: any) {
  const { setActive, emailContent, setEmailContent, emailTemplateList } = props;
  const [emailTemplateImage, setEmailTemplateImage] = useState(null);

  const handleChange = (e: any) => {
    //console.log(`selected ${e.target.value}`);
    //emailTemplateList.data.filter()
    let seletedTempate = { templateId: 0, subject: "", body: "", htmlFile: "" };
    emailTemplateList.data.filter((data: any) => {
      if (data.rowId == e.target.value) {
        seletedTempate = {
          templateId: data.rowId,
          subject: data.emailContent,
          body: data.emailBodyContent,
          htmlFile: data.emailTemplateFile,
        };
        setEmailTemplateImage(data.emailTemplateImage);
      }
    });
    setEmailContent(seletedTempate);
    //sethandleMode(parseInt(value));
  };

  //console.log("--------emailTemplateList--------", emailTemplateList);
  return (
    <React.Fragment>
      <div className="admins_se">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label> Subject</label>
              {emailTemplateList.data != undefined &&
                emailTemplateList.data.map((item: any, index: any) => {
                  //console.log(item);
                })}
              {emailTemplateList.data != undefined && (
                <select
                  name="timepass"
                  className="custom-select-sec"
                  onChange={handleChange}
                  defaultValue="0"
                  style={{ width: 300 }}
                >
                  <option value="0">Select</option>
                  {emailTemplateList.data.map((item: any, index: any) => {
                    return (
                      <option key={item["rowId"]} value={item["rowId"]}>
                        {item["title"]}
                      </option>
                    );
                  })}
                </select>
              )}
              {/* <div className="button dropdown">
                              <select id="colorselector" >
                                <option value="1">Candidate</option>
                                <option value="2">Vendor</option>
                              </select>
                            </div> */}
            </div>
          </div>
          <div className="clearfix" />
          <div className="col-sm-12">
            {emailTemplateImage != null ? (
              <img
                src={require(`../../../images/${emailTemplateImage}`)}
                //  src="images/banner_sh.png"
                style={{ width: 500, height: 285 }}
                className=" img-responsive"
              />
            ) : // <img
            //   width="325"
            //   height="184"
            //   src={
            //     environment.baseUrl +
            //     `/Upload/EmailTemplatesImage/${emailTemplateImage}`
            //   }
            //   className="img-responsive"
            // />
            null}
          </div>
        </div>
        <div className="pull-right">
          <button className="btn next_btn" onClick={() => setActive(2)}>
            Next{" "}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

function EditTemplate(props: any) {
  const {
    setActive,
    emailEditSubject,
    setEmailEditSubject,
    emailEditBody,
    setEmailEditBody,
    toNames,
    obj,
    setObj,
  } = props;
  const [isOpenFile, setIsOpenFile] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div className="admins_se">
        <div className="row">
          <div className="col-sm-12">
            <a onClick={() => setIsOpenFile(true)} className="attach_ic">
              <img src={require("../../../images/attach_icon.png")} />
            </a>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="To"
                value={toNames}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                onChange={(e) => setEmailEditSubject(e.target.value)}
                placeholder="Message Body"
                value={emailEditSubject}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <textarea
                className="form-control form-contro12"
                onChange={(e) => setEmailEditBody(e.target.value)}
                placeholder="Type here"
                value={emailEditBody}
              />
            </div>
          </div>
        </div>
        <div className=" pull-left">
          <button className="btn next_btn" onClick={() => setActive(1)}>
            Back{" "}
          </button>
        </div>
        <div className="pull-right">
          <button className="btn next_btn" onClick={() => setActive(3)}>
            Next{" "}
          </button>
        </div>
      </div>
      <SelectFile
        isOpenFile={isOpenFile}
        setIsOpenFile={setIsOpenFile}
        obj={obj}
        setObj={setObj}
      />
    </React.Fragment>
  );
}

function SelectFile(props: any) {
  const { isOpenFile, setIsOpenFile, obj, setObj } = props;

  const handleDrop = (files: any) => {
    //console.log(files[0]);
    if (files.length > 0) {
      setObj({
        ...obj,
        ["file"]: files[0],
      });
    }
  };

  //cancel selection
  const handleCancel = () => {
    setObj(null);
    setIsOpenFile(false);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <Modal
            show={isOpenFile}
            onHide={() => {
              setIsOpenFile(!isOpenFile);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Attachment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-6">
                    <FileUploads
                      accept=".pdf,.jpg,.jpeg,.xlsx,.csv,.xls"
                      onChange={(file: any) => {
                        handleDrop([file]);
                      }}
                      name="Upload File"
                      disabled={false}
                    />
                  </div>
                  <div className="col-sm-6">
                    {obj.file && (
                      <div
                        className="update_con"
                        style={{ width: 268, height: 105, paddingTop: 20 }}
                      >
                        {obj.file.name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <div className="modal-footer  m-t-30">
              <button
                onClick={() => setIsOpenFile(false)}
                className="btn btn-danger"
                type="submit"
                disabled={obj.file ? false : true}
              >
                Select
              </button>
              <button
                onClick={() => handleCancel()}
                className="btn btn-default"
                type="button"
              >
                cancel
              </button>
            </div>
            <div className="clearfix" />
          </Modal>
        </div>
      </div>
    </>
  );
}

function PreviewTemplate(props: any) {
  const {
    setActive,
    emailEditSubject,
    emailEditBody,
    toNames,
    templateId,
    userId,
    emailTemplateHtml,
    handleSendEmail,
  } = props;

  const tryRequire = () => {
    try {
      switch (emailTemplateHtml) {
        case "email1.html":
          return require("./templates/email1.html");
        case "email2.html":
          return require("./templates/email2.html");
      }
    } catch (err) {
      ////console.log(err)
      return require("./templates/email1.html");
    }
  };

  let template = { __html: "" };
  var __html = tryRequire();

  const globalSpinner = useContext(GlobalSpinnerContext);

  const to = "abhilash@borncode.in,abhilashravindrandvl@gmail.com";

  type objectIndexing = {
    [key: string]: any;
  };

  const sendMail = () => {
    let input = document.getElementById("pdfdiv");
    if (input) {
      handleSendEmail(input.innerHTML);
    }
  };

  var comptempl = _.template(__html);
  if (templateId != undefined && templateId > 0) {
    let result = comptempl({
      subject: emailEditSubject,
      body: emailEditBody,
      moment: moment,
    });
    template = { __html: result };
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="admins_se">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="To"
                value={toNames}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Type here"
                value={emailEditSubject}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <textarea
                className="form-control form-contro12"
                placeholder="Type here"
                value={emailEditBody}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div id="pdfdiv" dangerouslySetInnerHTML={template} />
        </div>
        <div className=" pull-left">
          <button className="btn next_btn" onClick={() => setActive(2)}>
            Back{" "}
          </button>
        </div>
        <div className="pull-right">
          <button className="btn next_btn" onClick={() => sendMail()}>
            Send{" "}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

const BulkEmail = (props: any) => {
  const adminCommunicationDispatcher = useAdminCommunicationDispatcher();
  const adminCommunicationContext = useAdminCommunicationContext();
  const { emailTemplateList, bulkEmailRespond } = adminCommunicationContext;

  const {
    handleDisplayModeChange,
    checkedListArray,
    userId,
    JobId,
    jobTitle,
    JobApplicationViewJobResultModel,
    adminId,
    basicInfo,
  } = props;
  const [active, setActive] = useState(1);
  const [emailContent, setEmailContent] = useState({
    templateId: 0,
    subject: "",
    body: "",
    htmlFile: "",
  });
  const [emailEditSubject, setEmailEditSubject] = useState("");
  const [emailEditBody, setEmailEditBody] = useState("");
  let emailSendData: ReactSelectOption[] = [];
  const [emailContacts, setEmailContacts] = useState([] as any[]);
  // //console.log(checkedListArray)
  // //console.log(userId)
  // //console.log(JobId)
  // //console.log(JobApplicationViewJobResultModel.data)
  const [toNames, setToNames] = useState("");
  const [templateId, setTemplateId] = useState(0);
  const [templateHtml, setTemplateHtml] = useState("");
  const globalSpinner = useContext(GlobalSpinnerContext);
  const [obj, setObj] = useState<any>({ file: null });
  const [counter, setCounter] = useState(0);
  React.useEffect(() => {
    if (
      JobApplicationViewJobResultModel.data != undefined &&
      JobApplicationViewJobResultModel.data.length > 0
    ) {
      //setInitialUserId(JobApplicationViewJobResultModel.data[0].userId);
      let allFounded = JobApplicationViewJobResultModel.data.filter(
        (e: any) => {
          if (checkedListArray.includes(e.userId)) {
            //console.log(e.userId);
            return e;
          }
        }
      );
      setEmailContacts(allFounded);
    }
  }, [JobApplicationViewJobResultModel.data]);

  React.useEffect(() => {
    let ToNames = "";
    if (emailContacts != undefined && emailContacts.length > 0) {
      ToNames = "";
      emailContacts.filter((data: any) => {
        //console.log(data);
        ToNames += data.userName + ",";
      });
    }
    setToNames(ToNames);
  }, [emailContacts.length]);

  React.useEffect(() => {
    (async () => {
      await getEmailTemplateList(adminCommunicationDispatcher, {
        UserId: userId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      });
    })();
  }, []);

  React.useEffect(() => {
    if (emailContent.templateId > 0) {
      setTemplateId(emailContent.templateId);
      setEmailEditSubject(
        emailContent.subject.replace("<<Job Title>>", jobTitle)
      );
      setEmailEditBody(emailContent.body.replace("<<Job Title>>", jobTitle));
      setTemplateHtml(emailContent.htmlFile);
    } else {
      setEmailEditSubject("");
      setEmailEditBody("");
      setTemplateHtml("");
    }
  }, [emailContent.templateId, emailContent.subject, emailContent.body]);

  const handleSendEmail = (html: string) => {
    //console.log("h");
    // //console.log(html);
    setCounter(1);
    let trans = [] as MailTransactionsData[];
    // //console.log(emailContacts);
    // //console.log(basicInfo);
    const formData = new FormData();
    if (emailContacts.length > 0) {
      emailContacts.forEach((item: any, index: any) => {
        //console.log(index);
        const listItem: MailTransactionsData = {
          MailFrom: basicInfo.email,
          MailTo: item["userEmail"],
          MailStatus: "NA",
        };
        formData.append(
          "MailTransactions[" + index + "][MailFrom]",
          String(basicInfo.email)
        );
        formData.append(
          "MailTransactions[" + index + "][MailTo]",
          String(item["userEmail"])
        );
        formData.append(
          "MailTransactions[" + index + "][MailStatus]",
          String("NA")
        );
        trans.push(listItem);
      });
    }
    // //console.log(trans.length);
    if (trans.length > 0) {
      //globalSpinner.showSpinner();

      formData.append("MailTypeId", String(0));
      formData.append("MailType", "Bulk");
      formData.append("MailSubject", emailEditSubject);
      formData.append("MailBody", emailEditBody);
      formData.append("MailHtmlContent", html);
      formData.append("FileAttached1", String(null));
      if (obj.file != null) {
        formData.append("FileAttached1", obj.file);
      }
      formData.append("FileAttached2", String(null));
      formData.append("FileAttached3", String(null));
      formData.append("FileAttached4", String(null));
      formData.append("FileAttached5", String(null));
      formData.append("UserId", String(adminId));
      formData.append("IsActive", JSON.stringify(true));
      (async () => {
        await saveBulkEmailConversation(adminCommunicationDispatcher, formData);
      })();
    }
  };

  React.useEffect(() => {
    if (bulkEmailRespond != undefined) {
      //console.log(bulkEmailRespond);
      //globalSpinner.hideSpinner();
      if (bulkEmailRespond.isSuccess && counter != 0) {
        globalSpinner.hideSpinner();
        toast.success("Email sent successfully.");
        // call processed api for reports
        let _data: objectIndexing = {};

        _data["AdminId"] = Number(adminId);
        _data["JobId"] = JobId;
        _data["PublishedJobsFlag"] = false;
        _data["ApprovalJobsFlag"] = false;
        _data["ViewedJobsFlag"] = false;
        _data["ProcessedJobsFlag"] = true;
        _data["FlagId"] = Number(4);
        _data["IsActive"] = true;
        saveAdminActions(_data).then((res: any) => {
          //console.log(res);
          if (res.data.isSuccess) {
            // handleDisplayModeChange(0);
            setCounter(2);
          } else {
          }
        });
      } else if (counter != 0) {
        globalSpinner.hideSpinner();
        toast.error("Error:Email not sent.");
        setCounter(0);
      }
    }
  }, [bulkEmailRespond]);

  React.useEffect(() => {
    if (counter == 2) {
      setCounter(0);
      goToPreviousPath();
    }
  }, [counter]);

  const goToPreviousPath = () => {
    handleDisplayModeChange(0);
  };

  return (
    <>
      {/*=================Content Section Start================*/}
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Published</h1>
          <a
            //  href="admin_myjobs_candidates.html"
            onClick={goToPreviousPath}
            className="btn cutomise_but"
          >
            <i className="fa fa-angle-left" aria-hidden="true" /> &nbsp; Back{" "}
          </a>
          <div className="clearfix" />
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="section_box4">
                <h1>EMAIL</h1>
                <div className="clearfix" />
                <div className="row">
                  <div className="col-sm-12 col-lg-12 ">
                    <div className="ProgCheck">
                      <ol className="breadcrumb">
                        <li className={active == 1 ? "active" : ""}>
                          {/* <span className="sr-only">Current step:</span> */}
                          Select Template{" "}
                        </li>
                        <li className={active == 2 ? "active" : ""}>
                          {" "}
                          Edit Template{" "}
                        </li>
                        <li className={active == 3 ? "active" : ""}>
                          {" "}
                          Preview Template{" "}
                        </li>
                      </ol>
                    </div>

                    {active == 1 && staticEmailTemplate != undefined && (
                      <SelectTemplate
                        setActive={setActive}
                        emailContent={emailContent}
                        setEmailContent={setEmailContent}
                        emailContacts={emailContacts}
                        emailTemplateList={staticEmailTemplate}
                      />
                    )}
                    {active == 2 && (
                      <EditTemplate
                        setActive={setActive}
                        emailEditSubject={emailEditSubject}
                        setEmailEditSubject={setEmailEditSubject}
                        emailEditBody={emailEditBody}
                        setEmailEditBody={setEmailEditBody}
                        toNames={toNames}
                        obj={obj}
                        setObj={setObj}
                      />
                    )}
                    {active == 3 && (
                      <PreviewTemplate
                        setActive={setActive}
                        emailEditSubject={emailEditSubject}
                        emailEditBody={emailEditBody}
                        emailContacts={emailContacts}
                        toNames={toNames}
                        templateId={templateId}
                        userId={userId}
                        emailTemplateHtml={templateHtml}
                        handleSendEmail={handleSendEmail}
                        obj={obj}
                        setObj={setObj}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      {/*=================Content Section End================*/}
    </>
  );
};

export default BulkEmail;
