import { ErrorMessage } from "@hookform/error-message";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectOption from "../../candidate/my_profile/components/SelectOptionForSearch";
import { LoadingOutlined } from "@ant-design/icons";
import { Popover, Spin } from "antd";
import { getByDisplayValue } from "@testing-library/react";
import _ from "lodash";
import PhoneNumberWithCode from "../../components/PhoneNumberWithCode";
import {
  GetAdminSavedSearch,
  GetAdminSelectBoxJobs,
  RemoveAdminCandidateSaveSearch,
  SaveAdminCandidateSearch,
  SearchAdminCandidate,
  SearchAdminCandidateDetails,
  SetAdminCandidateSaveSeach,
  useAdminCandidateSearchrContext,
  useAdminCandidateSearchrDispatcher,
} from "../../../action/admin/AdminCandidateSearchAction";
import {
  AdminCandidateDetailsRespondModel,
  AdminCandidateSearchDetailsRequestModel,
  AdminCandidateSearchRequestModel,
  AdminCandidateSearchRespond,
  AdminSaveSearchRequestModel,
  GetAdminSelectBoxJobDataRequest,
  GetSaveSearchAdminListRequestModel,
  SetAdminCandidateSaveRequestModel,
} from "../../../models/admin/AdminCandidateSearch";
import AuthService from "../../../services/AuthService";
import { useHistory, useParams } from "react-router-dom";
import SelectMulti from "../../candidate/my_profile/components/SelectMulti";
import { environment } from "../../../environments/environment";
import InfiniteScroll from "react-infinite-scroll-component";
import AdminCandidateBulkEmail from "./AdminCandidateBulckEmail";
import { Modal } from "react-bootstrap";
import { useAdminMyProfileContext } from "../../../action/MyProfileAdminAction";
import AdminCandidateBulkSms from "./AdminCandidateBulkSms";
import { useGlobalSpinnerContext } from "../../../context/GlobalSpinner";
import CandidateChat from "../jobApplication/CandidateChat";
import AdminCandidateSearchDetails from "./AdminCandidateSearchDetails";
import { useAdminContext } from "../../../context/admin/AdminMyProfile";
import JobApplicationProfileContainer from "../jobApplication/JobApplicationProfileContainer";
import { GetAdminCandidateResultModel } from "../../../models/admin/AdminJobApplication";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "green" }} spin />
);

interface ReactSelectOption {
  value: string;
  label: string;
}
interface IAdminCandidateSearchComponentState {
  experienceFilterId: number;
  datePostedFilterId: number;
  jobTypesFilterIds: number[];
  jobCategorys: string;
  jobTypeData: string;
  location: string;
  contactNumber: string;
  countryCode: string;
}
const defaultValues = {
  experienceFilterId: 0,
  datePostedFilterId: 0,
  jobTypesFilterIds: [],
  location: "",
  jobTypeData: "",
  jobCategorys: "",
  contactNumber: "",
  countryCode: "",
};
const AdminCandidateSearchComponent = (props: any) => {
  let nationalities: ReactSelectOption[] = [];
  let expValues: ReactSelectOption[] = [
    { value: "0-1", label: "0-1" },
    { value: "1-2", label: "1-2" },
    { value: "2-5", label: "2-5" },
    { value: "5-10", label: "5-10" },
    { value: "10-50", label: ">10" },
  ];

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IAdminCandidateSearchComponentState>({
    defaultValues,
  });

  let { paramId } = useParams<any>();
  let history = useHistory();

  const { myProfile, basicInfo } = props;
  if (!_.isEmpty(myProfile)) {
    nationalities = myProfile.countries.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  const authorizationToken = AuthService.accessToken;
  const adminCandidateSearchrDispatcher = useAdminCandidateSearchrDispatcher();
  const adminCandidateSearchrContext = useAdminCandidateSearchrContext();
  const {
    adminCandidateSearchResult,
    adminCandidateSearchResultDetails,
    getAdminSaveSearchResultmodel,
    setAdminCandidateResultModel,
    getAdminJobSelectBoxData,
    removeAdminSavedCandidateSearchResultModel,
    adminSaveSearchResultmodel,
    sendEmailOrSmsResultData,
  } = adminCandidateSearchrContext;

  const adminContext = useAdminContext();
  const { adminState } = adminContext;

  const [isSearchLoaderSearch, setIsSearchLoaderSearch] = React.useState(false);
  const [
    isSearchLoaderSavedSearch,
    setIsSearchLoaderSavedSearch,
  ] = React.useState(false);
  const myProfileAdminContext = useAdminMyProfileContext();
  const { loggedAdminId } = myProfileAdminContext;

  const globalSpinner = useGlobalSpinnerContext();
  const [isTrue, setIsTrue] = React.useState(false);
  const [isAdvanceSearch, setIsAdvanceSearch] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isRemovedAll, setIsRemovedCheckedAll] = useState(false);
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [isChecked, setIsChecked] = useState(false);
  const [displayMode, setDisplayMode] = useState<number>(0);
  const [isSaveSearch, setIsSaveSearch] = useState<boolean>(false);
  const [saveSearch, setSaveSearch] = useState<string>("");
  const [showChat, setShowChat] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");

  const [candidateJobTitleState, setCandidateJobTitleState] = React.useState(
    ""
  );
  const [candidateNameState, setCandidateNameState] = React.useState("");
  const [candidateExpirenceState, setCandidateExpirenceState] = React.useState(
    [] as any
  );
  const [candidateLocationState, setCandidateLocationState] = React.useState(0);
  const [
    candidatePhonenumberState,
    setCandidatePhonenumberState,
  ] = React.useState("");
  const [
    candidateCountryCodeState,
    setCandidateCountryCodeState,
  ] = React.useState("");

  const [
    adminCandidateSearchResultState,
    setAdminCandidateSearchResultState,
  ] = useState<AdminCandidateSearchRespond[]>([]);

  // const [
  //   adminCandidateSearchDetailsResultState,
  //   setAdminCandidateSearchDetailsResultState,
  // ] = useState<AdminCandidateDetailsRespondModel>(
  //   {} as AdminCandidateDetailsRespondModel
  // );

  let user = AuthService.currentUser;
  const [hasMore, setHasMore] = React.useState(true);
  const [isLoader, setIsLoader] = React.useState(false);
  React.useEffect(() => {
    if (sendEmailOrSmsResultData.isSuccess) {
      setDisplayMode(0);
      setCheckedListArray([]);
    }
  }, [sendEmailOrSmsResultData]);

  //console.log(399, isAdvanceSearch);

  React.useEffect(() => {
    // setIsLoader(false);
    // console.log(55555555, isLoader);
    setAdminCandidateSearchResultState([]);
  }, []);

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0 && user?.id != null) {
      (async () => {
        await GetAdminSelectBoxJobs(
          adminCandidateSearchrDispatcher,
          {
            AdminId: loggedAdminId,
            UserId: parseInt(user.id),
          } as GetAdminSelectBoxJobDataRequest,
          authorizationToken
        );
      })();
    }
  }, [loggedAdminId]);

  React.useEffect(() => {
    console.log(55555, isTrue);

    setIsAdvanceSearch(true);
    setValue("jobTitle", "");
    setValue("name", "");
    setValue("experience", []);
    setValue("nationality", 0);
    setValue("contactNumber", "");
    setValue(
      "countryCode",
      setAdminCandidateResultModel.countryCode === null ? "" : ""
    );
    setIsTrue(false);
  }, []);

  React.useEffect(() => {
    if (
      adminCandidateSearchResult &&
      adminCandidateSearchResult.data != undefined &&
      isLoader
    ) {
      if (currentPage === 1) {
        if (
          adminCandidateSearchResult &&
          adminCandidateSearchResult.data != undefined
        ) {
          setResultMessage(
            adminCandidateSearchResult.data.length > 0 ? "" : "No Result Found"
          );
        }
        // console.log(55555666, isLoader);
        setAdminCandidateSearchResultState(
          adminCandidateSearchResult &&
            adminCandidateSearchResult.data != undefined
            ? adminCandidateSearchResult.data
            : []
        );
      } else {
        setHasMore(adminCandidateSearchResult.hasNext);
        setAdminCandidateSearchResultState([
          ...adminCandidateSearchResult.data,
          ...adminCandidateSearchResultState,
        ]);
      }
      setIsCheckedAll(false);
      if (adminCandidateSearchResult.data.length > 0) {
        history.push(
          `/admin/candidate_search/${adminCandidateSearchResult.data[0].candidateId}`
        );
      }
    }
  }, [adminCandidateSearchResult.data, isLoader]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      setIsTrue(true);
      (async () => {
        await GetAdminSavedSearch(
          adminCandidateSearchrDispatcher,
          {
            AdminId: loggedAdminId,
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
            ShowInactive: false,
          } as GetSaveSearchAdminListRequestModel,
          authorizationToken
        );
      })();
      setIsSaveSearch(false);
      globalSpinner.hideSpinner();
    }
  }, [
    loggedAdminId,
    removeAdminSavedCandidateSearchResultModel,
    adminSaveSearchResultmodel,
  ]);

  React.useEffect(() => {
    if (authorizationToken != null && isTrue) {
      console.log(55555, 6666);
      setValue("jobTitle", setAdminCandidateResultModel.whatInput);
      setValue("name", setAdminCandidateResultModel.candidateName);
      setValue("experience", setAdminCandidateResultModel.experienceLevel);
      setValue("nationality", setAdminCandidateResultModel.countryId);
      setValue("contactNumber", setAdminCandidateResultModel.phoneNumber);
      setValue(
        "countryCode",
        setAdminCandidateResultModel.countryCode === null
          ? ""
          : setAdminCandidateResultModel.countryCode
      );
      setIsAdvanceSearch(
        setAdminCandidateResultModel.advancedSearchedFlag != undefined
          ? setAdminCandidateResultModel.advancedSearchedFlag
          : true
      );

      // search
      setIsSearchLoaderSearch(true);
      setIsLoader(true);
      setAdminCandidateSearchResultState([]);
      setCurrentPage(1);
      (async () => {
        await SearchAdminCandidate(
          adminCandidateSearchrDispatcher,
          {
            AdvancedSearchedFlag:
              setAdminCandidateResultModel.advancedSearchedFlag != undefined
                ? !setAdminCandidateResultModel.advancedSearchedFlag
                : true,
            WhatInput: setAdminCandidateResultModel.whatInput,
            CandidateName: setAdminCandidateResultModel.candidateName,
            ExperienceLevel:
              setAdminCandidateResultModel.experienceLevel != undefined
                ? setAdminCandidateResultModel.experienceLevel
                : [],
            Location: "",
            LocationId:
              setAdminCandidateResultModel.countryId != undefined
                ? setAdminCandidateResultModel.countryId
                : 0,
            PhoneNumber: setAdminCandidateResultModel.phoneNumber,
            countryCode:
              setAdminCandidateResultModel.countryCode === null
                ? "+91"
                : setAdminCandidateResultModel.countryCode,
            Page: 1,
            PageSize: 10,
            SortOrder: "",
            SearchTerm: "",
          } as AdminCandidateSearchRequestModel,
          authorizationToken
        );
      })();
      setIsCheckedAll(false);
      setIsSearchLoaderSearch(false);
    }
  }, [setAdminCandidateResultModel]);

  const onSubmit = (data: any) => {
    console.log(400, data);
    if (authorizationToken != null) {
      setCandidateJobTitleState(data.jobTitle);
      setCandidateNameState(data.name);
      setCandidateExpirenceState(
        data.experience != undefined ? data.experience : []
      );
      setCandidateLocationState(
        data.nationality != undefined ? parseInt(data.nationality) : 0
      );
      setCandidatePhonenumberState(data.contactNumber);
      setCandidateCountryCodeState(
        data.countryCode !== "" && data.countryCode != undefined
          ? data.countryCode
          : "+91"
      );
      setIsSearchLoaderSearch(true);
      setIsLoader(true);
      setAdminCandidateSearchResultState([]);
      setCurrentPage(1);
      (async () => {
        await SearchAdminCandidate(
          adminCandidateSearchrDispatcher,
          {
            AdvancedSearchedFlag: !isAdvanceSearch,
            WhatInput: data.jobTitle,
            CandidateName: data.name,
            ExperienceLevel:
              data.experience != undefined ? data.experience : [],
            Location: "",
            LocationId:
              data.nationality != undefined ? parseInt(data.nationality) : 0,
            PhoneNumber: data.contactNumber,
            countryCode:
              data.countryCode !== "" && data.countryCode != undefined
                ? data.countryCode
                : "+91",
            Page: 1,
            PageSize: 10,
            SortOrder: "",
            SearchTerm: "",
          } as AdminCandidateSearchRequestModel,
          authorizationToken
        );
      })();
      setIsCheckedAll(false);
      setIsSearchLoaderSearch(false);
    }
  };

  const fetchMoreData = async () => {
    ////console.log(2424);
    setCurrentPage(currentPage + 1);
    if (authorizationToken != null) {
      (async () => {
        await SearchAdminCandidate(
          adminCandidateSearchrDispatcher,
          {
            AdvancedSearchedFlag: !isAdvanceSearch,
            WhatInput: candidateJobTitleState,
            CandidateName: candidateNameState,
            ExperienceLevel: candidateExpirenceState,
            Location: "",
            LocationId: candidateLocationState,
            PhoneNumber: candidatePhonenumberState,
            countryCode: candidateCountryCodeState,
            Page: currentPage + 1,
            PageSize: 10,
            SortOrder: "",
            SearchTerm: "",
          } as AdminCandidateSearchRequestModel,
          authorizationToken
        );
      })();
    }
  };

  const handleAllChecked = (e: any) => {
    ////console.log(12, e.target.checked);
    if (e.target.checked) {
      let IdArray = [] as any;
      // digiLockerList
      adminCandidateSearchResultState
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArray.push(d.candidateId);
        });
      setCheckedListArray(IdArray);
      ////console.log(23, checkedListArray);
      setIsCheckedAll(e.target.checked);
      ////console.log("checkedListArray", checkedListArray);
    } else {
      let IdArray = [] as any;
      setCheckedListArray(IdArray);
      setIsCheckedAll(e.target.checked);
      ////console.log("checkedListArray", checkedListArray);
    }
  };

  const handleChecked = (e: any, id: any) => {
    // setIsChecked(true);
    let IdArray = [] as any;
    if (e.target.checked) {
      IdArray.push(...checkedListArray, id);
      setCheckedListArray(IdArray);
      ////console.log("checkedListArray", checkedListArray);
    } else {
      IdArray = checkedListArray.filter((data) => {
        return data !== id;
      });
      setCheckedListArray(IdArray);
      ////console.log("checkedListArray", checkedListArray);
    }
    setIsRemovedCheckedAll(false);
    setIsChecked(true);
  };

  React.useEffect(() => {
    if (isChecked) {
      if (checkedListArray.length == adminCandidateSearchResultState.length) {
        setIsCheckedAll(true);
        ////console.log("checkedListArray lenth", checkedListArray.length);
        // ////console.log("vendorJobStatus.length", vendorJobStatus.length);
      } else {
        setIsCheckedAll(false);
      }
      setIsChecked(false);
    }
  }, [isChecked]);

  const content = (
    <>
      <p>Please Select Candidate</p>
    </>
  );

  const contentOne = (
    <>
      <p>Please Select one Candidate</p>
    </>
  );

  const handleSaveButtonClick = () => {
    console.log(11, getValues("experience"), getValues("countryCode"));
    if (authorizationToken != null && loggedAdminId != 0) {
      (async () => {
        await SaveAdminCandidateSearch(
          adminCandidateSearchrDispatcher,
          {
            AdvancedSearchFlag: !isAdvanceSearch,
            JobTitle: getValues("jobTitle"),
            CandidateName: getValues("name"),
            ExperienceLevel:
              getValues("experience") !== undefined
                ? getValues("experience")
                : [],
            Location: "",
            CountryId:
              getValues("nationality") !== undefined
                ? getValues("nationality")
                : 0,
            PhoneNumber: getValues("contactNumber"),
            CountryCode:
              getValues("countryCode") === null
                ? "+91"
                : getValues("countryCode"),
            AdminId: loggedAdminId,
            IsActive: true,
            SearchTitle: saveSearch,
            Orgnization: "",
            RowId: 0,
          } as AdminSaveSearchRequestModel,
          authorizationToken
        );
      })();
    }
  };

  const handleDisplayModeChange = (value: any) => {
    setDisplayMode(value);
  };

  const handleSaveSearchTitleClick = (id: number) => {
    //console.log("clicked");
    if (authorizationToken != null) {
      (async () => {
        await SetAdminCandidateSaveSeach(
          adminCandidateSearchrDispatcher,
          {
            RowId: id,
          } as SetAdminCandidateSaveRequestModel,
          authorizationToken
        );
      })();
    }
  };

  const handleRemoveSavedJobSearch = (id: number) => {
    if (authorizationToken != null) {
      (async () => {
        await RemoveAdminCandidateSaveSearch(
          adminCandidateSearchrDispatcher,
          {
            RowId: id,
          } as SetAdminCandidateSaveRequestModel,
          authorizationToken
        );
      })();
      globalSpinner.showSpinner();
    }
  };

  const handleRestCheckedArrays = () => {
    setCheckedListArray([]);
  };

  const handleAdvanceSearchClick = () => {
    setIsAdvanceSearch(!isAdvanceSearch);
    setValue("name", "");
    setValue("experience", []);

    setValue("contactNumber", "");
    setValue(
      "countryCode",
      setAdminCandidateResultModel.countryCode === null ? "" : ""
    );
  };

  const handleDisplayModeChangeWithProfile = (
    diaplaymode: number,
    profileUrl: string
  ) => {
    setProfileImageUrl(profileUrl);
    setDisplayMode(diaplaymode);
  };

  const handleDisplayMode = () => {
    if (displayMode == 0) {
      return (
        <div className="content-wrapper">
          <div className="job_search_page">
            <div className=" container-fluid">
              <div>
                {/* job search */}
                <div className="col-sm-12" style={{ marginTop: -50 }}>
                  <div
                    style={{ height: isAdvanceSearch ? 130 : 200 }}
                    className="job_search_area"
                  >
                    <div className="row">
                      <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div
                          className="col-sm-9 col-lg-9"
                          style={{ width: "72%" }}
                        >
                          {" "}
                          <div className="job_search_forms10">
                            <div className="cr_serach_br">
                              <input
                                name="jobTitle"
                                ref={register({ required: false })}
                                type="text"
                                className="form-control"
                                placeholder="Job Title,Organization"
                                // value={candidateJobTitleState}
                                // onChange={(e: any) => {
                                //   setCandidateJobTitleState(e.target.value);
                                // }}
                              />

                              <div className="search_icons">
                                <img
                                  src={require("./../../../images/search_icon.png")}
                                  // src="images/search_icon.png"
                                />
                              </div>
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name="jobTitle"
                              render={({ message }) => (
                                <div className="register_validation">
                                  {message}
                                </div>
                              )}
                            />
                          </div>
                          <div className="job_search_forms10">
                            <div className="cr_serach_br">
                              <span
                                style={{ border: "none" }}
                                className="select-wrapper-sec"
                              >
                                <Controller
                                  rules={{
                                    required: false,
                                  }}
                                  control={control}
                                  name="nationality"
                                  render={({
                                    onChange,
                                    onBlur,
                                    value,
                                    name,
                                  }) => (
                                    <SelectOption
                                      values={nationalities}
                                      disabled={false}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      name={name}
                                      nameForLablel="Location"
                                    />
                                  )}
                                />
                              </span>

                              <ErrorMessage
                                errors={errors}
                                name="nationality"
                                render={({ message }) => (
                                  <div className="profile_validation">
                                    {message}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="search_button">
                          <div className="search">
                            {isSearchLoaderSearch ? (
                              <button
                                className="CustomButtonCss"
                                style={{ marginLeft: -225 }}
                                type="button"
                              >
                                <Spin indicator={antIcon} />
                              </button>
                            ) : (
                              <button
                                className="CustomButtonCss"
                                style={{ marginLeft: -225 }}
                                type="submit"
                              >
                                Search
                              </button>
                            )}
                          </div>
                        </div>

                        <div className="search_button">
                          <div className="search">
                            <button
                              className="CustomButtonCss"
                              style={{
                                marginLeft: 75,
                                width: 155,
                                backgroundColor:
                                  adminCandidateSearchResultState.length > 0
                                    ? "green"
                                    : "lightgray",
                              }}
                              type="button"
                              onClick={() => {
                                setIsSaveSearch(true);
                              }}
                              disabled={
                                adminCandidateSearchResultState.length > 0
                                  ? false
                                  : true
                              }
                            >
                              Save Search
                            </button>
                          </div>
                        </div>

                        <div className="col-sm-9 col-lg-9">
                          <button
                            style={{ margin: 25 }}
                            className="CustomButtonCss"
                            type="button"
                            onClick={() => {
                              handleAdvanceSearchClick();
                            }}
                          >
                            Advanced Search
                          </button>
                        </div>

                        <div
                          className="col-sm-9 col-lg-9"
                          style={{ display: isAdvanceSearch ? "none" : "" }}
                        >
                          <div className="job_search_forms9">
                            <div className="cr_serach_br">
                              <span className="select-search">
                                <Controller
                                  control={control}
                                  name="experience"
                                  render={({
                                    onChange,
                                    onBlur,
                                    value,
                                    name,
                                  }) => (
                                    <SelectMulti
                                      values={expValues}
                                      disabled={false}
                                      onChange={onChange}
                                      // onBlur={onBlur}
                                      value={value ? value : []}
                                      name={name}
                                      nameForLablel="Experience"
                                    />
                                  )}
                                />
                              </span>
                              <div className="search_icons">
                                {/* <img
                          src={require("./../../../images/type_icon.png")}
                          
                        /> */}
                              </div>
                            </div>
                          </div>
                          <div className="job_search_forms9">
                            <div className="cr_serach_br">
                              <input
                                name="name"
                                ref={register({ required: false })}
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                // value={candidateNameState}
                                onChange={(e: any) => {
                                  setCandidateNameState(e.target.value);
                                }}
                              />

                              <div className="search_icons">
                                <img
                                  src={require("./../../../images/search_icon.png")}
                                  // src="images/search_icon.png"
                                />
                              </div>
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name="name"
                              render={({ message }) => (
                                <div className="register_validation">
                                  {message}
                                </div>
                              )}
                            />
                          </div>
                          <div className="job_search_forms9">
                            <div className="cr_serach_br">
                              <Controller
                                control={control}
                                name="countryCode"
                                render={({ onChange, onBlur, value, name }) => (
                                  <PhoneNumberWithCode
                                    // codeHeight={"50px"}
                                    noPenIcon={true}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    name={name}
                                    disabled={false}
                                    downwards={true}
                                    phoneNo={
                                      <input
                                        type="text"
                                        name="contactNumber"
                                        disabled={false}
                                        id="phoneNumber"
                                        className="form-control phoneNumber"
                                        placeholder="Type here"
                                        // value={candidatePhonenumberState}
                                        ref={register({
                                          required:
                                            watch("countryCode") === ""
                                              ? false
                                              : "Mobile number is required",
                                          maxLength: {
                                            value:
                                              watch("countryCode").length > 3
                                                ? 9
                                                : 10,
                                            message: `It must be ${
                                              watch("countryCode").length > 3
                                                ? 9
                                                : 10
                                            } digits only.`,
                                          },
                                          pattern: {
                                            value: /^[0-9]*$/,
                                            message:
                                              "Mobile number should be numbers only",
                                          },
                                          minLength: {
                                            value:
                                              watch("countryCode").length > 3
                                                ? 9
                                                : 10,
                                            message: `It must be ${
                                              watch("countryCode").length > 3
                                                ? 9
                                                : 10
                                            } digits only.`,
                                          },
                                        })}
                                      />
                                    }
                                  />
                                )}
                              />

                              {/* <div className="search_icons">
                        <img
                          src={require("./../../../images/search_icon.png")}
                          // src="images/search_icon.png"
                        />
                      </div> */}
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name="contactNumber"
                              render={({ message }) => (
                                <div className="register_validation">
                                  {message}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* endjob search */}
                {/*Save Search modal start */}
                <Modal
                  show={isSaveSearch}
                  onHide={() => {
                    setIsSaveSearch(!isSaveSearch);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Save Search</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="job_search_forms9">
                      <div className="cr_serach_br" style={{ width: 635 }}>
                        <input
                          name="name"
                          // ref={register({ required: false })}
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          onChange={(e: any) => setSaveSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <div className="modal-footer  m-t-30">
                    {isSearchLoaderSearch ? (
                      <button
                        className="btn btn-success"
                        style={{ marginLeft: -225 }}
                        type="button"
                      >
                        <Spin indicator={antIcon} />
                      </button>
                    ) : (
                      <button
                        className="btn btn-success"
                        style={{
                          marginLeft: -225,
                          backgroundColor:
                            saveSearch === "" ? "lightgray" : "green",
                        }}
                        type="button"
                        onClick={handleSaveButtonClick}
                      >
                        Save
                      </button>
                    )}

                    <button
                      onClick={() => setIsSaveSearch(false)}
                      className="btn btn-default"
                      type="button"
                    >
                      cancel
                    </button>
                  </div>

                  <div className="clearfix" />
                </Modal>
                {/* Save Search Modal End */}
                <div style={{ height: 767 }} className="col-sm-12">
                  <div
                    className="job_search_left"
                    style={{ backgroundColor: "white", marginTop: 17 }}
                  >
                    <h1
                      style={{
                        paddingLeft: 36,
                        fontFamily: "revert",
                        backgroundColor: "antiquewhite",
                        color: "darkgray",
                        fontSize: "revert",
                      }}
                    >
                      Saved Search
                    </h1>
                    <div>
                      {getAdminSaveSearchResultmodel &&
                      getAdminSaveSearchResultmodel.data !== undefined
                        ? getAdminSaveSearchResultmodel.data.map(
                            (data, index) => {
                              return (
                                <h1
                                  style={{
                                    color: "deepskyblue",
                                    paddingLeft: 27,
                                    fontSize: "larger",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleSaveSearchTitleClick(data.rowId)
                                  }
                                >
                                  {data.searchTitle.toUpperCase()}
                                  <i
                                    style={{ color: "orange", paddingLeft: 4 }}
                                    onClick={() => {
                                      handleRemoveSavedJobSearch(data.rowId);
                                    }}
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </h1>
                              );
                            }
                          )
                        : null}
                    </div>
                  </div>

                  <div className="job_search_right">
                    {/* select and DeSelect Start */}
                    {adminCandidateSearchResultState.length > 0 ? (
                      <>
                        {" "}
                        <div className="m_t_20">
                          <div className="section_box4">
                            <div className="row">
                              <div
                                style={{ paddingTop: 12 }}
                                className="col-sm-3 col-lg-2"
                              >
                                <input
                                  id="checkbox1"
                                  type="checkbox"
                                  name="checkbox"
                                  defaultValue={1}
                                  checked={isCheckedAll}
                                  // defaultChecked={isCheckedAll ? true : false}
                                  onChange={(e: any) => handleAllChecked(e)}
                                />
                                <label htmlFor="checkbox1">
                                  <span /> Select All
                                </label>
                              </div>
                              <div className="col-sm-9 col-lg-9">
                                <div className="admins_send">
                                  <ul>
                                    <li>
                                      {checkedListArray.length > 0 ? (
                                        <a
                                          onClick={() => {
                                            setDisplayMode(1);
                                          }}
                                        >
                                          Send SMS
                                        </a>
                                      ) : (
                                        <Popover
                                          content={content}
                                          trigger="hover"
                                        >
                                          <a>Send SMS</a>
                                        </Popover>
                                      )}
                                    </li>
                                    <li>
                                      {checkedListArray.length > 0 ? (
                                        <a
                                          onClick={() => {
                                            setDisplayMode(2);
                                          }}
                                        >
                                          Send Email
                                        </a>
                                      ) : (
                                        <Popover
                                          content={content}
                                          trigger="hover"
                                        >
                                          <a>Send Email</a>
                                        </Popover>
                                      )}
                                    </li>

                                    <li>
                                      {checkedListArray.length > 0 &&
                                      checkedListArray.length < 2 ? (
                                        <a
                                          onClick={() => {
                                            setShowChat(true);
                                          }}
                                        >
                                          Chat
                                        </a>
                                      ) : (
                                        <Popover
                                          content={contentOne}
                                          trigger="hover"
                                        >
                                          <a>Chat</a>
                                        </Popover>
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {/* ---------- */}

                    <div
                      id="verticalTab"
                      className="jobsearch-tabs  resp-vtabs"
                      style={{ display: "block", width: "100%", margin: "0px" }}
                    >
                      <ul className="resp-tabs-list ">
                        <div
                          id="jobs_searh1"
                          style={{ overflow: "hidden", outline: "none" }}
                          tabIndex={0}
                        >
                          {adminCandidateSearchResultState.length > 0 ? (
                            <>
                              {/* candidate list */}
                              <InfiniteScroll
                                className="_custom-infinite-scroll-class"
                                height={720}
                                dataLength={
                                  adminCandidateSearchResultState.length + 1
                                }
                                // dataLength={2000}
                                // dataLength={totalCount}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={<h4>Loading...</h4>}
                                // initialScrollY={indexState * 80}
                                endMessage={
                                  <p style={{ textAlign: "center" }}>
                                    <b>No more data to load</b>
                                  </p>
                                }
                              >
                                {adminCandidateSearchResultState.map(
                                  (data, index) => {
                                    return (
                                      <>
                                        <li
                                          className={`resp-tab-item ${
                                            data.candidateId ===
                                            parseInt(paramId)
                                              ? "resp-tab-active"
                                              : ""
                                          }`}
                                          aria-controls="tab_item-0"
                                          role="tab"
                                        >
                                          <div className="candidates_check">
                                            <input
                                              id="checkbox2"
                                              type="checkbox"
                                              name="checkbox"
                                              defaultValue={data.candidateId}
                                              onChange={(e: any) =>
                                                handleChecked(
                                                  e,
                                                  data.candidateId
                                                )
                                              }
                                              checked={checkedListArray.includes(
                                                data.candidateId
                                              )}
                                            />
                                            <label htmlFor="checkbox2">
                                              <span />
                                            </label>
                                          </div>

                                          <div className="candidates_icon">
                                            {data.profileImage !== null &&
                                            data.profileImage !== "" ? (
                                              <img
                                                src={
                                                  environment.baseUrl +
                                                  `/Upload/ProfileImage/${data.profileImage}`
                                                }
                                              />
                                            ) : (
                                              <img
                                                src={require("./../../../images/profileDefault1.jpg")}
                                              ></img>
                                            )}
                                          </div>
                                          <div
                                            onClick={() => {
                                              history.push(
                                                `/admin/candidate_search/${data.candidateId}`
                                              );
                                            }}
                                          >
                                            <div className="candidates_con10">
                                              <div className="candidates_con_head">
                                                {data.candidateName}
                                              </div>
                                              <div className="candidates_con_con">
                                                {data.jobTitle}
                                              </div>
                                              <div className="candidates_con_con">
                                                {data.relExperience === "1"
                                                  ? data.relExperience + " year"
                                                  : data.relExperience +
                                                    " years"}
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        ;
                                      </>
                                    );
                                  }
                                )}
                              </InfiniteScroll>

                              {/* candidate list end */}
                            </>
                          ) : (
                            <h3>{resultMessage}</h3>
                          )}
                        </div>
                      </ul>
                      {/* <div className="resp-tabs-container jobsearch-tabs-content"> */}
                      {/*----------------Start-------------*/}
                      <AdminCandidateSearchDetails
                        handleDisplayModeChangeWithProfile={
                          handleDisplayModeChangeWithProfile
                        }
                        adminSearchCandidateetails={
                          adminCandidateSearchResultState.length > 0
                            ? adminCandidateSearchResultState.filter(
                                (data, index) => {
                                  return data.candidateId === parseInt(paramId);
                                }
                              )
                            : null
                        }
                      />

                      {/*----------------End-------------*/}
                      {/* </div> */}
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (displayMode == 1) {
      return (
        <AdminCandidateBulkSms
          handleDisplayModeChange={handleDisplayModeChange}
          checkedListArray={checkedListArray}
          getAdminJobSelectBoxData={getAdminJobSelectBoxData}
          getNames={adminCandidateSearchResultState
            .filter((data, index) => {
              return checkedListArray.includes(data.candidateId);
            })
            .map((data, index) => {
              return data.candidateName;
            })}
          // userId={userId}
          // JobId={parseInt(id)}
          // JobApplicationViewJobResultModel={JobApplicationViewJobResultModel}
          adminId={loggedAdminId}
          // basicInfo={basicInfo}
        />
      );
    } else if (displayMode == 2) {
      return (
        <AdminCandidateBulkEmail
          handleDisplayModeChange={handleDisplayModeChange}
          checkedListArray={checkedListArray}
          getAdminJobSelectBoxData={getAdminJobSelectBoxData}
          getNames={adminCandidateSearchResultState
            .filter((data, index) => {
              return checkedListArray.includes(data.candidateId);
            })
            .map((data, index) => {
              return data.candidateName;
            })}
          adminId={loggedAdminId}
        />
      );
    } else if (displayMode == 4) {
      return (
        <JobApplicationProfileContainer
          handleDisplayModeChange={handleDisplayModeChange}
          initialUserId={parseInt(paramId)}
          userId={1}
          AdminCandidateResult={
            { candidateImage: profileImageUrl } as GetAdminCandidateResultModel
          }
        />
      );
    }
  };

  return (
    <>
      {handleDisplayMode()}

      <Modal
        show={showChat}
        onHide={() => {
          setShowChat(!showChat);
        }}
        // style={{ width: 600 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CandidateChat
            handleDisplayModeChange={handleDisplayModeChange}
            //checkedListArray={userId == 1 ? checkedListArray : checkedListArrayTwo}
            checkedListArray={checkedListArray}
            userId={1}
            handleRestCheckedArrays={handleRestCheckedArrays}
            loggedAdminId={loggedAdminId}
            jobTitle={
              ""
              // adminEditRespond.data != undefined
              //   ? adminEditRespond.data[0].title != undefined
              //     ? adminEditRespond.data[0].title
              //     : null
              //   : null
            }
            JobId={0}
            JobApplicationViewJobResultModel={
              adminCandidateSearchResultState.length > 0 &&
              adminCandidateSearchResultState !== undefined
                ? adminCandidateSearchResult
                : null
            }
            basicInfo={basicInfo}
            adminState={adminState}
            searchOperation={true}
          />
        </Modal.Body>
        <div className="modal-footer  m-t-30">
          <button
            onClick={() => setShowChat(false)}
            className="btn btn-default"
            type="button"
          >
            cancel
          </button>
        </div>
        <div className="clearfix" />
      </Modal>
    </>
  );
};

export default AdminCandidateSearchComponent;
