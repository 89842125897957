import React, { useState } from "react";
import { Link, useRouteMatch, withRouter } from "react-router-dom";
import { useClientProfileContext } from "../../../action/client/ClientProfileAction";
import { environment } from "../../../environments/environment";
import AuthService from "../../../services/AuthService";

const ClientSidebarComponent = (props: any) => {
  const [menuHide, setMenuHide] = useState(false);
  const [name, setName] = useState("");
  const [currentWidth, setCurrentCurrentWidth] = useState<number>(
    window.innerWidth
  );
  const clientProfileContext = useClientProfileContext();
  const { clientProfileImage, getClientPersonalDetails } = clientProfileContext;
  const { path, url } = useRouteMatch();
  const cl = props.location.pathname;

  const navigation = (url: any) => {
    props.history.push(url);
    if (currentWidth < 650) {
      setMenuHide(!menuHide);
    }
  };
  const onLogout = () => {
    AuthService.logout();
    props.history.push("/");
  };

  return (
    <>
      <div className="icon-mobile-nav">
        <a
          onClick={() => {
            setMenuHide(!menuHide);
          }}
        >
          <i className="fa fa-2x fa-reorder" />
        </a>
      </div>

      <div className="menu_main">
        <div
          className={`left-menu-admin client_menu  ${
            menuHide ? "hide-menu" : ""
          }`}
        >
          <div className="dash_logo">
            <img
              src={require("../../../images/logo_dash.png")}
              // src="images/logo_dash.png"
              className="img-responsive center-block"
            />
          </div>
          <div className="dash_profile">
            {clientProfileImage.data != undefined ? (
              clientProfileImage.data.length > 0 ? (
                <img
                  className="img-responsive"
                  src={
                    environment.baseUrl +
                    `/Upload/ClientProfileImage/${clientProfileImage.data[0].imageUrl}`
                  }
                  alt=""
                />
              ) : (
                <img
                  className="img-responsive"
                  src={require("./../../../images/profileDefault1.jpg")}
                ></img>
              )
            ) : (
              <img
                className="img-responsive"
                src={require("./../../../images/profileDefault1.jpg")}
              ></img>
            )}
          </div>
          <div className="dash_profile_name">
            {getClientPersonalDetails.clientName != undefined
              ? getClientPersonalDetails.clientName
              : ""}
          </div>
          <aside
            className="bg-black dk nav_side-xs aside hidden-print"
            id="nav"
          >
            <section className="vbox">
              <section className="w-f-md scrollable">
                <div>
                  <div
                    data-height="auto"
                    data-disable-fade-out="true"
                    data-distance={0}
                    data-size="10px"
                    data-railopacity="0.2"
                  >
                    {/* nav */}
                    <div
                      id="divexample1"
                      style={{ overflow: "hidden" }}
                      tabIndex={0}
                    >
                      <nav className="nav_side-primary nav_pad">
                        <ul className="nav_side" data-ride="collapse">
                          {/* <li>
                            <a href="#">
                              <i className="fa fa-home" aria-hidden="true" />
                              <div className="menus">Home</div>{" "}
                            </a>
                          </li> */}
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}`);
                              }}
                              className={
                                cl === "/client"
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-th-large"
                                aria-hidden="true"
                              />
                              <div className="menus">Dashboard </div>{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/my_jobpost`);
                              }}
                              className={
                                cl.includes("admin/my_jobpost")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              <div className="menus">My Job Posts</div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/message`);
                              }}
                              className={
                                cl.includes("client/message")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-commenting-o"
                                aria-hidden="true"
                              />
                              <div className="menus">Messages</div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/my_profile`);
                              }}
                              className={
                                cl.includes("client/my_profile")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-user-o" aria-hidden="true" />
                              <div className="menus">My Profile</div>
                            </a>
                          </li>
                        </ul>
                        <ul className="nav_side nav_side1" data-ride="collapse">
                          <li>
                            <Link to="/help">
                              {" "}
                              <i
                                className="fa fa-question-circle"
                                aria-hidden="true"
                              />
                              <div className="menus">Help</div>
                            </Link>
                          </li>
                          <li>
                            <a onClick={onLogout}>
                              {" "}
                              <i
                                className="fa fa-power-off"
                                aria-hidden="true"
                              />
                              <div className="menus">Log Out </div>{" "}
                            </a>
                          </li>
                        </ul>
                      </nav>
                      {/* / nav */}
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </aside>
        </div>
      </div>
    </>
  );
};

export default withRouter(ClientSidebarComponent);
