import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import axios from "axios";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { useForm } from "react-hook-form";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { isNull } from "util";
import {
  GetAdminMyJob,
  useAdminMyJobContext,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import {
  AddcandidateForListForm,
  CandidateInfoByIdForForm,
  ClientJobApproveOrRejectFromAdmin,
  GetAllAdminSideClientJobs,
  GetAllAppliedCandidateInAdminSide,
  useClientJobApprovalContext,
  useClientJobApprovalDispatcher,
} from "../../../action/admin/ClientJobApprovalAction";
import {
  getClientjob,
  useClientJobContext,
  useClientJobDispatcher,
} from "../../../action/client/ClientJobAction";
import { useAdminMyProfileContext } from "../../../action/MyProfileAdminAction";
import { useGlobalSpinnerContext } from "../../../context/GlobalSpinner";
import { AppUrls, environment } from "../../../environments/environment";
import {
  AdminJobEditRespondModel,
  RowIdRequestModel,
} from "../../../models/admin/AdminMyJob";
import {
  AdminSideClientJobListRequestModel,
  AllAppliedCandidateListInAdminsideRequestModel,
  CandidateInfoByIdRequestModel,
  CandidateInfoByIdResultModel,
  JobApproveOrRejectRequestModel,
} from "../../../models/admin/ClientJobApproval";
import AuthService from "../../../services/AuthService";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import AdminEditJob from "./components/AdminEditJob";
const { Option } = Select;

interface IAdminClientSideJobsProps extends RouteComponentProps {}

interface IAdminClientSideJobsState {}

const defaultValues = {};

const AdminClientSideJobs: React.FC<IAdminClientSideJobsProps> = (props) => {
  const [
    adminClientSideJobstState,
    setAdminClientSideJobsState,
  ] = React.useState<IAdminClientSideJobsState>(defaultValues);

  const [active, setActive] = useState(2);
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<number>(0);
  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;

  const menuRef = useRef<any>();
  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setFilterShow(false);
          // setIsProfileOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IAdminClientSideJobsState>({
    defaultValues,
  });

  const globalSpinner = useGlobalSpinnerContext();
  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const { adminEditRespond } = adminMyJobContext;
  const clientJobDispatcher = useClientJobDispatcher();
  const clientJobContext = useClientJobContext();
  const { ClientJob } = clientJobContext;
  const myProfileAdminContext = useAdminMyProfileContext();
  const { myProfile, basicInfo, loggedAdminId } = myProfileAdminContext;

  const secondButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const clientJobApprovalDispatcher = useClientJobApprovalDispatcher();
  const clientJobApprovalContext = useClientJobApprovalContext();
  const {
    AdminClientSideJobs,
    JobApproveOrRejectFromAdminResult,
    CandidateInfoById,
    AddCandidateListForFormResult,
    AdminGetAllApliedCandidateListForClientJob,
  } = clientJobApprovalContext;
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<string>("");
  const [jobApplayOpen, setJobApplayOpen] = useState<boolean>(false);
  const [currentJobIdValue, setCurrentJobIdValue] = useState<number>(0);
  const [currentJobId, setCurrentJobId] = useState<number>(0);
  const [adminEditRespondState, setAdminEditRespondState] = useState({});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [radioGroupValue, setRadioGroupValue] = React.useState(6);
  const [filename, setFileName] = useState("");
  const [obj, setObj] = useState<any>({ file: null });
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [checkedData, setCheckedData] = useState<String[]>([]);
  const [isDisplay, setIsDisplay] = useState<boolean>(true);
  const [isDisplaySpin, setIsDisplaySpin] = useState<boolean>(false);
  const [candidateaddResult, setCandidateaddResult] = useState("");
  const [
    candidateInfoList,
    setcandidateInfoList,
  ] = useState<CandidateInfoByIdResultModel>(
    {} as CandidateInfoByIdResultModel
  );
  const [temp, setTemp] = useState({
    candidateId: 1,
    firstName: "string",
    middleName: "string",
    lastName: "string",
  });

  React.useEffect(() => {
    if (active == 3) {
      console.log(667, ClientJob);
      setAdminEditRespondState(ClientJob);
    } else {
      console.log(668, adminEditRespond);
      setAdminEditRespondState(adminEditRespond);
    }
  }, [adminEditRespond, ClientJob.data]);

  React.useEffect(() => {
    if (searchData.length == 0) {
      let token;
      if (authorizationToken?.startsWith(`"`)) {
        token = authorizationToken.substring(1);
        token = authorizationToken.slice(0, -1);
      }

      let header = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const url =
        environment.baseUrl + "/api/ClientJobsCandidateList/GetCandidateIdList";

      axios.post(url, JSON.stringify({}), { headers: header }).then((d) => {
        setSearchData(d.data);
      });
    }
  }, [loggedAdminId]);

  React.useEffect(() => {
    if (CandidateInfoById.candidateId != undefined) {
      setcandidateInfoList(CandidateInfoById);
    }
  }, [CandidateInfoById]);

  React.useEffect(() => {
    if (AddCandidateListForFormResult.isSuccess) {
      setCandidateaddResult(AddCandidateListForFormResult.message);
      console.log(3333);
      setIsDisplaySpin(false);
      setIsDisplay(true);
    } else {
      console.log(4444);
      setCandidateaddResult(AddCandidateListForFormResult.message);
      setIsDisplaySpin(false);
    }
  }, [AddCandidateListForFormResult]);

  console.log("CandidateInfoById", CandidateInfoById);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != undefined) {
      (async () => {
        await GetAllAdminSideClientJobs(
          clientJobApprovalDispatcher,
          {
            AdminId: 0,
            UserId: parseInt(user?.id),
            JobProcessingStatusId: active,
            JobStatus: 1,
            Page: 1,
            PageSize: 1000,
            SortOrder: "",
            SearchTerm: "",
          } as AdminSideClientJobListRequestModel,
          authorizationToken
        );
      })();
      globalSpinner.hideSpinner();
      setCurrentJobId(0);
      setIsModelOpen(false);
    }
  }, [user?.id, active, JobApproveOrRejectFromAdminResult]);

  const handleEdit = (id: any) => {
    setCurrentJobId(id);
    if (authorizationToken != null) {
      (async () => {
        await GetAdminMyJob(
          adminMyJobDispatcher,
          {
            RowId: id,
          } as RowIdRequestModel,
          authorizationToken
        );
      })();
    }
    setIsOpen(true);
  };

  const handleEditReject = (id: any) => {
    setCurrentJobId(id);
    if (authorizationToken != null) {
      (async () => {
        await getClientjob(
          clientJobDispatcher,
          {
            RowId: id,
          } as any,
          authorizationToken
        );
      })();
    }
    setIsOpen(true);
  };

  const handleDelete = (id: any) => {};

  const handleModelSubmit = (data: any) => {
    console.log(data);
  };

  const onChangeRadio = (e: any) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
    setValue("reason", e.target.value);
  };

  const handleJobAddCandidate = (id: any) => {
    if (secondButtonRef.current != null) {
      secondButtonRef.current.click();
    }

    if (authorizationToken != null && user != null) {
      (async () => {
        await GetAllAppliedCandidateInAdminSide(
          clientJobApprovalDispatcher,
          {
            AdminId: loggedAdminId,
            JobId: id,
            ApplicationStatusId: statusFilter,
            UserId: parseInt(user?.id),
            ShowInactive: false,
            Page: 1,
            PageSize: 1000,
            SearchTerm: "",
            SortOrder: "",
          } as AllAppliedCandidateListInAdminsideRequestModel,
          authorizationToken
        );
      })();
    }

    setJobApplayOpen(!jobApplayOpen);
    setCurrentJobIdValue(id);
  };

  React.useEffect(() => {
    if (authorizationToken != null && user != null) {
      (async () => {
        await GetAllAppliedCandidateInAdminSide(
          clientJobApprovalDispatcher,
          {
            AdminId: loggedAdminId,
            JobId: currentJobIdValue,
            ApplicationStatusId: statusFilter,
            UserId: parseInt(user?.id),
            ShowInactive: false,
            Page: 1,
            PageSize: 1000,
            SearchTerm: "",
            SortOrder: "",
          } as AllAppliedCandidateListInAdminsideRequestModel,
          authorizationToken
        );
      })();
      setCandidateaddResult("");
    }
  }, [statusFilter]);

  const onFinish = (values: any) => {
    console.log("Received values of form:", values);

    let formData = new FormData();

    let ClientCandidateDocListArray = [] as any[];
    let ClientCandidateDocListArray1 = [] as any[];

    if (values.users != undefined && values.users.length > 0) {
      //console.log("hi");
      // ClientCandidateDocListArray.push(
      //   formData.append("JobId", JSON.stringify(currentJobIdValue)),
      //   formData.append("UserId", JSON.stringify(user?.id)),
      //   formData.append("IsActive", JSON.stringify(true))
      // );
      formData.append("JobId", JSON.stringify(currentJobIdValue));
      formData.append("UserId", JSON.stringify(user?.id));
      formData.append("IsActive", JSON.stringify(true));
      // values.users.forEach((user: any, i: any) => {

      values.users.map((user: any, i: any) => {
        if (user.documentFlag == 1) {
          {
            formData.append("RowId ", JSON.stringify(0));
            formData.append("CandidateId ", JSON.stringify(user.candidateId));
            formData.append("FirstName", JSON.stringify(user.firstName));
            formData.append("MiddleName ", JSON.stringify(user.middleName));
            formData.append("LastName", JSON.stringify(user.lastName));
            formData.append("ResumeFileName", String(user.document.name));
            // ClientCandidateDocListArray[i].push();
            formData.append("ResumeFile", user.document);
            formData.append(
              "ResumeFileFlag",
              user.documentFlag == 1
                ? JSON.stringify(true)
                : JSON.stringify(false)
            );
            formData.append("CertificateFileName", String());
            formData.append("CertificateFile", JSON.stringify(null));
            formData.append("CertificateFileFlag ", JSON.stringify(false));
            formData.append("PassportFileName", String(null));
            formData.append("PassportFile", JSON.stringify(null));
            formData.append("PassportFileFlag ", JSON.stringify(false));
            formData.append("ResidentStatusFileName", String(null));
            formData.append("ResidentStatusFile", JSON.stringify(null));
            formData.append("ResidentStatusFile", JSON.stringify(false));
          }
        }
        if (user.documentFlag == 2) {
          {
            formData.append("RowId ", JSON.stringify(0));
            formData.append("RowId ", JSON.stringify(0));
            formData.append("CandidateId ", JSON.stringify(user.candidateId));
            formData.append("FirstName", JSON.stringify(user.firstName));
            formData.append("MiddleName ", JSON.stringify(user.middleName));
            formData.append("LastName", JSON.stringify(user.lastName));
            formData.append("CertificateFileName", String(user.document.name));
            formData.append("CertificateFile", user.document);
            formData.append("CertificateFileFlag ", JSON.stringify(true));
            formData.append("ResumeFileName", String(null));
            formData.append("ResumeFile", JSON.stringify(null));
            formData.append("ResumeFileFlag", JSON.stringify(false));
            formData.append("PassportFileName", String(null));
            formData.append("PassportFile", JSON.stringify(null));
            formData.append("PassportFileFlag ", JSON.stringify(false));
            formData.append("ResidentStatusFileName", String(null));
            formData.append("ResidentStatusFile", JSON.stringify(null));
            formData.append("ResidentStatusFile", JSON.stringify(false));
          }
        }
        if (user.documentFlag == 3) {
          {
            formData.append("RowId ", JSON.stringify(0));
            formData.append("RowId ", JSON.stringify(0));
            formData.append("CandidateId ", JSON.stringify(user.candidateId));
            formData.append("FirstName", JSON.stringify(user.firstName));
            formData.append("MiddleName ", JSON.stringify(user.middleName));
            formData.append("LastName", JSON.stringify(user.lastName));
            formData.append("PassportFileName", String(user.document.name));
            formData.append("PassportFile", user.document);
            formData.append("PassportFileFlag ", JSON.stringify(true));
            formData.append("ResumeFileName", String(null));
            formData.append("ResumeFile", JSON.stringify(null));
            formData.append("ResumeFileFlag", JSON.stringify(false));
            formData.append("CertificateFileName", String());
            formData.append("CertificateFile", JSON.stringify(null));
            formData.append("CertificateFileFlag ", JSON.stringify(false));
            formData.append("ResidentStatusFileName", String(null));
            formData.append("ResidentStatusFile", JSON.stringify(null));
            formData.append("ResidentStatusFile", JSON.stringify(false));
          }
        }
        if (user.documentFlag == 4) {
          {
            formData.append("RowId ", JSON.stringify(0));
            formData.append("RowId ", JSON.stringify(0));
            formData.append("CandidateId ", JSON.stringify(user.candidateId));
            formData.append("FirstName", JSON.stringify(user.firstName));
            formData.append("MiddleName ", JSON.stringify(user.middleName));
            formData.append("LastName", JSON.stringify(user.lastName));
            formData.append(
              "ResidentStatusFileName",
              String(user.document.name)
            );
            formData.append("ResidentStatusFile", user.document);
            formData.append("ResidentStatusFile", JSON.stringify(true));
            formData.append("ResumeFileName", String(null));
            formData.append("ResumeFile", JSON.stringify(null));
            formData.append("ResumeFileFlag", JSON.stringify(false));
            formData.append("CertificateFileName", String());
            formData.append("CertificateFile", JSON.stringify(null));
            formData.append("CertificateFileFlag ", JSON.stringify(false));
            formData.append("PassportFileName", String(null));
            formData.append("PassportFile", JSON.stringify(null));
            formData.append("PassportFileFlag ", JSON.stringify(false));
          }
        }
        if (authorizationToken != null && user != undefined) {
          let token;
          if (authorizationToken?.startsWith(`"`)) {
            token = authorizationToken.substring(1);
            token = token.slice(0, -1);
          }
          let header = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };

          const url = AppUrls.AddCandidateInToForm;

          axios.post(url, formData, { headers: header }).then((res) => {
            {
              formData.delete("RowId ");
              formData.delete("CandidateId ");
              formData.delete("FirstName");
              formData.delete("MiddleName ");
              formData.delete("LastName");
              formData.delete("ResumeFileName");
              // ClientCandidateDocListArray[i].push();
              formData.delete("ResumeFile");
              formData.delete("ResumeFileFlag");
              formData.delete("CertificateFileName");
              formData.delete("CertificateFile");
              formData.delete("CertificateFileFlag ");
              formData.delete("PassportFileName");
              formData.delete("PassportFile");
              formData.delete("PassportFileFlag ");
              formData.delete("ResidentStatusFileName");
              formData.delete("ResidentStatusFile");
              formData.delete("ResidentStatusFile");
            }
          });
          //   // async
          //   (() => {
          //     // await
          // AddcandidateForListForm(
          //   clientJobApprovalDispatcher,

          //   formData,
          //   authorizationToken
          // );
          //   })();
        }
      });

      // ClientCandidateDocListArray1.push(formData);
      //   CandidateList: ClientCandidateDocListArray,
      // });
      // {
      // }
      // formData.append("JobId", JSON.stringify(currentJobIdValue));
      // formData.append("UserId", JSON.stringify(user?.id));
      // formData.append("IsActive", JSON.stringify(true));
      // formData.append(
      //   "CandidateList",
      //   JSON.stringify(ClientCandidateDocListArray)
      // );
    }

    // if (authorizationToken != null && user != undefined) {
    //   (async () => {
    //     await AddcandidateForListForm(
    //       clientJobApprovalDispatcher,

    //       formData,
    //       authorizationToken
    //     );
    //   })();
    // }
  };

  const onFinishOne = (values: any) => {
    console.log("Received values of form:", values);

    let formData = new FormData();

    let ClientCandidateDocListArray = [] as any[];
    let ClientCandidateDocListArray1 = [] as any[];

    if (values.users != undefined && values.users.length > 0) {
      //console.log("hi");
      // ClientCandidateDocListArray.push(
      //   formData.append("JobId", JSON.stringify(currentJobIdValue)),
      //   formData.append("UserId", JSON.stringify(user?.id)),
      //   formData.append("IsActive", JSON.stringify(true))
      // );
      formData.append("JobId", JSON.stringify(currentJobIdValue));
      formData.append(
        "UserId",
        JSON.stringify(user?.id != undefined ? parseInt(user.id) : "")
      );
      formData.append("IsActive", JSON.stringify(true));
      // values.users.forEach((user: any, i: any) => {

      values.users.map((user: any, i: any) => {
        if (values.users.length == i + 1) {
          formData.append("RowId", JSON.stringify(0));
          formData.append(
            "CandidateId",
            JSON.stringify(parseInt(user.candidateId))
          );
          formData.append("FirstName", String(user.firstName));
          formData.append("MiddleName", String(user.middleName));
          formData.append("LastName", String(user.lastName));
          formData.append(
            "ResumeFileName",
            user.resume == undefined
              ? JSON.stringify(null)
              : String(user.resume.name)
          );
          // ClientCandidateDocListArray[i].push();
          formData.append(
            "ResumeFile",
            user.resume == undefined ? JSON.stringify(null) : user.resume
          );
          formData.append(
            "ResumeFileFlag",
            user.documentFlag.includes("1")
              ? JSON.stringify(true)
              : JSON.stringify(false)
          );
          formData.append(
            "CertificateFileName",
            user.certifications == undefined
              ? JSON.stringify(null)
              : String(user.certifications.name)
          );
          formData.append(
            "CertificateFile",
            user.certifications == undefined
              ? JSON.stringify(null)
              : user.certifications
          );
          formData.append(
            "CertificateFileFlag",
            user.documentFlag.includes("2")
              ? JSON.stringify(true)
              : JSON.stringify(false)
          );

          formData.append(
            "PassportFileName",
            user.passport == undefined
              ? JSON.stringify(null)
              : String(user.passport.name)
          );
          formData.append(
            "PassportFile",
            user.passport == undefined ? JSON.stringify(null) : user.passport
          );
          formData.append(
            "PassportFileFlag",
            user.documentFlag.includes("3")
              ? JSON.stringify(true)
              : JSON.stringify(false)
          );

          formData.append(
            "ResidentStatusFileName",
            user.residence == undefined
              ? JSON.stringify(null)
              : String(user.residence.name)
          );
          formData.append(
            "ResidentStatusFile",
            user.residence == undefined ? JSON.stringify(null) : user.residence
          );
          formData.append(
            "ResidentStatusFileFlag",
            user.documentFlag.includes("4")
              ? JSON.stringify(true)
              : JSON.stringify(false)
          );
        }
      });

      if (authorizationToken != null && user != undefined) {
        setIsDisplaySpin(true);
        (async () => {
          await AddcandidateForListForm(
            clientJobApprovalDispatcher,

            formData,
            authorizationToken
          );
        })();
      }
    }
  };

  const handleCloseModel = () => {
    setIsOpen(false);
    setAdminEditRespondState({});
  };

  const handleViewClick = (
    id: any,
    candidateDefaultId: any,
    vendorDefaultId: any
  ) => {
    props.history.push(
      `/admin/job_Application/${candidateDefaultId}/${vendorDefaultId}/${id}`
    );
  };

  const handleModelSubmitReject = (data: any) => {
    console.log(data);
    if (authorizationToken != null && user?.id != undefined) {
      ClientJobApproveOrRejectFromAdmin(
        clientJobApprovalDispatcher,
        {
          AdminId: 0,
          UserId: parseInt(user?.id),
          DelayDate: "",
          IsApproved: false,
          IsRejected: true,
          Ispublished: false,
          JobId: currentJobId,
          ProcessingComment: data.reason,
        } as JobApproveOrRejectRequestModel,
        authorizationToken
      );
      globalSpinner.showSpinner();
    }
  };

  const handlePublih = (id: any) => {
    if (authorizationToken != null && user?.id != undefined) {
      ClientJobApproveOrRejectFromAdmin(
        clientJobApprovalDispatcher,
        {
          AdminId: 0,
          UserId: parseInt(user?.id),
          DelayDate: "",
          IsApproved: false,
          IsRejected: false,
          Ispublished: true,
          JobId: id,
          ProcessingComment: "",
        } as JobApproveOrRejectRequestModel,
        authorizationToken
      );
      globalSpinner.showSpinner();
    }
  };

  const onChangeRadioGroup = (e: any) => {
    console.log("radio checked", e.target.value);
    setRadioGroupValue(e.target.value);
  };

  const handleDrop = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "application/pdf"].includes(files[0].type) &&
        files[0].size < 1000000 * 5
      ) {
        //console.log(files[0]);
        if (files.length > 0) {
          setObj({
            ...obj,
            ["file"]: files[0],
          });
          setFileName(files[0].name);
        }
      }
    }
  };

  function onChange(value: any) {
    console.log(`selected ${value}`);
    const myArr = value.split("-");
    console.log(`real value ${myArr[1]}`);

    if (authorizationToken != null && user?.id != undefined) {
      CandidateInfoByIdForForm(
        clientJobApprovalDispatcher,
        {
          candidateId: parseInt(myArr[1]),
        } as CandidateInfoByIdRequestModel,
        authorizationToken
      );
    }
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
    console.log("searchData.lengt:", searchData.length);
    // if (searchData.length == 0) {
    //   let token;
    //   if (authorizationToken?.startsWith(`"`)) {
    //     token = authorizationToken.substring(1);
    //     token = authorizationToken.slice(0, -1);
    //   }

    //   let header = {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: `Bearer ${token}`,
    //   };

    //   const url =
    //     environment.baseUrl + "/api/ClientJobsCandidateList/GetCandidateIdList";

    //   axios.post(url, JSON.stringify({}), { headers: header }).then((d) => {
    //     setSearchData(d.data);
    //   });
    // } else {
    //   // setSearchData([]);
    // }
  }

  function onSearch(val: any) {
    console.log("search:", val);
  }

  const options = searchData.map((d) => (
    <Option value={`CN-${d}`}>CN-{d}</Option>
  ));

  function onChangeCheckBox(checkedValues: any) {
    console.log("checked = ", checkedValues);
    setCheckedData(checkedValues);
  }
  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="dash_sec">
                <div className="row">
                  <div className="col-sm-12 col-lg-12 ">
                    <div className="ProgCheck">
                      <ol className="breadcrumb">
                        <li
                          style={{
                            cursor: "pointer",
                          }}
                          className={active == 2 ? "active10" : ""}
                          onClick={() => {
                            setActive(2);
                          }}
                        >
                          {/* <span className="sr-only">Current step:</span> */}
                          Approved{" "}
                        </li>
                        <li
                          style={{
                            cursor: "pointer",
                          }}
                          className={active == 4 ? "active10" : ""}
                          onClick={() => {
                            setActive(4);
                          }}
                        >
                          {" "}
                          Published{" "}
                        </li>
                        <li
                          style={{
                            cursor: "pointer",
                          }}
                          className={active == 3 ? "active10" : ""}
                          onClick={() => {
                            setActive(3);
                          }}
                        >
                          {" "}
                          Rejected{" "}
                        </li>
                      </ol>
                    </div>

                    {active == 2 && (
                      <>
                        {" "}
                        <div className="section_box3">
                          <div className="row">
                            <div
                              className="my_job_descrip"
                              style={{ overflow: "hidden", outline: "none" }}
                              tabIndex={0}
                            >
                              <Scrollbars
                                style={{ height: 750 }}
                                autoHide
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      position: "relative",
                                      display: "block",
                                      width: "5px",
                                      cursor: "pointer",
                                      borderRadius: "inherit",
                                      backgroundColor: "rgb(73, 69, 69)",
                                      height: "115px",
                                    }}
                                  />
                                )}
                              >
                                {AdminClientSideJobs.data != undefined
                                  ? AdminClientSideJobs.data.map(
                                      (clientJob, i) => {
                                        return (
                                          <div
                                            className="col-sm-3"
                                            key={`${i}`}
                                          >
                                            <div className="matched_jobs">
                                              <div className="matched_jobs_cat_t">
                                                {clientJob.categoryName}
                                              </div>
                                              {/* <div className="matched_star1"><i className="fa fa-star-o" aria-hidden="true"></i></div>  */}
                                              <div className="clearfix"></div>
                                              <div className="matched_heading">
                                                {clientJob.title}
                                              </div>
                                              <div className="published">
                                                Not Published
                                              </div>
                                              <div className="clearfix"></div>
                                              <div className="matched_jobs_cat">
                                                <i
                                                  className="fa fa-map-marker"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                {clientJob.location}
                                              </div>
                                              <div className="matched_jobs_cat text-right">
                                                <i
                                                  className="fa fa-clock-o"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                {clientJob.jobType}
                                              </div>
                                              <div className="clearfix"></div>
                                              <div className="matched_jobs_cat">
                                                <i
                                                  className="fa fa-list-ul"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                {clientJob.numberOfVacancies}{" "}
                                                Vacancies
                                              </div>
                                              {/* <div className="matched_jobs_cat"> */}
                                              <a
                                                style={{ marginLeft: 60 }}
                                                onClick={() => {
                                                  handleEdit(clientJob.rowId);
                                                }}
                                                data-target="#create_jobdescription"
                                                data-toggle="modal"
                                              >
                                                <i
                                                  className="fa fa-pencil-square-o"
                                                  aria-hidden="true"
                                                ></i>
                                                Edit
                                              </a>
                                              {/* </div> */}
                                              <div className="clearfix"></div>
                                              <div
                                                className="row"
                                                style={{
                                                  paddingBottom: 9,
                                                  paddingRight: 25,
                                                  paddingLeft: 5,
                                                }}
                                              >
                                                <div className="check_status">
                                                  <Popconfirm
                                                    title={
                                                      <div>
                                                        <p>
                                                          Are you sure to
                                                          Publish this job?
                                                        </p>
                                                      </div>
                                                    }
                                                    onConfirm={
                                                      () => {
                                                        handlePublih(
                                                          clientJob.jobId
                                                        );
                                                        setCurrentJobId(
                                                          clientJob.jobId
                                                        );
                                                        // setIsModelOpen(true);
                                                      }
                                                      // handleApprovalStatus(clientJob.rowId, 0, 1)
                                                    }
                                                    okText="Yes"
                                                    cancelText="No"
                                                  >
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                        // backgroundColor: "red",
                                                      }}
                                                    >
                                                      Publish
                                                    </a>
                                                  </Popconfirm>
                                                </div>
                                                <div className="check_status">
                                                  <Popconfirm
                                                    title={
                                                      <div>
                                                        <p>
                                                          Are you sure to Reject
                                                          this job?
                                                        </p>
                                                      </div>
                                                    }
                                                    onConfirm={
                                                      () => {
                                                        setIsModelOpen(true);
                                                        setCurrentJobId(
                                                          clientJob.clientJobId
                                                        );
                                                      }
                                                      // handleApprovalStatus(clientJob.rowId, 0, 1)
                                                    }
                                                    okText="Yes"
                                                    cancelText="No"
                                                  >
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                        backgroundColor: "red",
                                                        marginRight: 86,
                                                      }}
                                                    >
                                                      Reject
                                                    </a>
                                                  </Popconfirm>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="check_status">
                                                  {/* <Popconfirm
                                  title={
                                    <div>
                                      <p>Are you sure to approve this job?</p>
                                    </div>
                                  }
                                  onConfirm={() =>
                                   
                                    {
                                      setIsModelOpen2(true);
                                    }
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                > */}
                                                  <a
                                                    style={{
                                                      cursor: "pointer",
                                                      // float: "left",
                                                    }}
                                                    onClick={() =>
                                                      handleJobAddCandidate(
                                                        clientJob.jobId
                                                      )
                                                    }
                                                  >
                                                    Add Candidate
                                                  </a>
                                                  {/* </Popconfirm> */}
                                                </div>
                                                {/* {clientJob.numberOfApplicants !==
                                                  undefined &&
                                                clientJob.numberOfApplicants !==
                                                  null &&
                                                clientJob.numberOfApplicants >
                                                  0 ? (
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                      float: "left",
                                                    }}
                                                    className="check_status"
                                                  >
                                                    <a
                                                      onClick={() => {
                                                        handleViewClick(
                                                          clientJob.jobId,
                                                          clientJob.candidateDefaultId,
                                                          clientJob.vendorDefaultId
                                                        );
                                                      }}
                                                    >
                                                      View Application
                                                    </a>
                                                  </div>
                                                ) : (
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                      float: "left",
                                                    }}
                                                    className="check_status"
                                                  >
                                                    <a
                                                      style={{
                                                        backgroundColor:
                                                          "darkgray",
                                                        marginRight: 27,
                                                      }}
                                                    >
                                                      No Applicant
                                                    </a>
                                                  </div>
                                                )} */}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : null}
                              </Scrollbars>
                            </div>
                            <div
                              className="col-sm-12 col-lg-12"
                              style={{ marginTop: 10 }}
                            >
                              {/* <Pagination
                  total={
                    clientJobApproval.data != undefined
                      ? clientJobApproval.total
                      : 0
                  }
                  showSizeChanger
                  showQuickJumper
                  showTotal={(total) => `Total ${total} items`}
                  onChange={handlePageSizeChange}
                  current={currentPage}
                /> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {active == 4 && (
                      <>
                        {" "}
                        <div className="section_box3">
                          <div className="row">
                            <div
                              className="my_job_descrip"
                              style={{ overflow: "hidden", outline: "none" }}
                              tabIndex={0}
                            >
                              <Scrollbars
                                style={{ height: 750 }}
                                autoHide
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      position: "relative",
                                      display: "block",
                                      width: "5px",
                                      cursor: "pointer",
                                      borderRadius: "inherit",
                                      backgroundColor: "rgb(73, 69, 69)",
                                      height: "115px",
                                    }}
                                  />
                                )}
                              >
                                {AdminClientSideJobs.data != undefined
                                  ? AdminClientSideJobs.data.map(
                                      (clientJob, i) => {
                                        return (
                                          <div
                                            className="col-sm-3"
                                            key={`${i}`}
                                          >
                                            <div className="matched_jobs">
                                              <div className="matched_jobs_cat_t">
                                                {clientJob.categoryName}
                                              </div>
                                              {/* <div className="matched_star1"><i className="fa fa-star-o" aria-hidden="true"></i></div>  */}
                                              <div className="clearfix"></div>
                                              <div className="matched_heading">
                                                {clientJob.title}
                                              </div>
                                              <div className="published">
                                                Not Published
                                              </div>
                                              <div className="clearfix"></div>
                                              <div className="matched_jobs_cat">
                                                <i
                                                  className="fa fa-map-marker"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                {clientJob.location}
                                              </div>
                                              <div className="matched_jobs_cat text-right">
                                                <i
                                                  className="fa fa-clock-o"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                {clientJob.jobType}
                                              </div>
                                              <div className="clearfix"></div>
                                              <div className="matched_jobs_cat">
                                                <i
                                                  className="fa fa-list-ul"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                {clientJob.numberOfVacancies}{" "}
                                                Vacancies
                                              </div>
                                              {/* <div className="matched_jobs_cat"> */}
                                              <a
                                                style={{ marginLeft: 60 }}
                                                onClick={() => {
                                                  handleEdit(clientJob.rowId);
                                                }}
                                                data-target="#create_jobdescription"
                                                data-toggle="modal"
                                              >
                                                <i
                                                  className="fa fa-pencil-square-o"
                                                  aria-hidden="true"
                                                ></i>
                                                Edit
                                              </a>
                                              {/* </div> */}
                                              <div className="clearfix"></div>
                                              <div
                                                className="row"
                                                style={{
                                                  paddingBottom: 9,
                                                  paddingRight: 133,
                                                  paddingLeft: 5,
                                                }}
                                              >
                                                <div className="check_status">
                                                  <Popconfirm
                                                    title={
                                                      <div>
                                                        <p>
                                                          Are you sure to Reject
                                                          this job?
                                                        </p>
                                                      </div>
                                                    }
                                                    onConfirm={
                                                      () => {
                                                        setIsModelOpen(true);
                                                        setCurrentJobId(
                                                          clientJob.clientJobId
                                                        );
                                                      }
                                                      // handleApprovalStatus(clientJob.rowId, 0, 1)
                                                    }
                                                    okText="Yes"
                                                    cancelText="No"
                                                  >
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                        backgroundColor: "red",
                                                        marginRight: 46,
                                                      }}
                                                    >
                                                      Reject
                                                    </a>
                                                  </Popconfirm>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="check_status">
                                                  <a
                                                    style={{
                                                      cursor: "pointer",
                                                      float: "left",
                                                    }}
                                                    onClick={() =>
                                                      handleJobAddCandidate(
                                                        clientJob.jobId
                                                      )
                                                    }
                                                  >
                                                    Add Candidate
                                                  </a>
                                                  {/* </Popconfirm> */}
                                                </div>

                                                {clientJob.numberOfApplicants !==
                                                  undefined &&
                                                clientJob.numberOfApplicants !==
                                                  null &&
                                                clientJob.numberOfApplicants >
                                                  0 ? (
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                      float: "left",
                                                    }}
                                                    className="check_status"
                                                  >
                                                    <a
                                                      onClick={() => {
                                                        handleViewClick(
                                                          clientJob.jobId,
                                                          clientJob.candidateDefaultId,
                                                          clientJob.vendorDefaultId
                                                        );
                                                      }}
                                                    >
                                                      View Application
                                                    </a>
                                                  </div>
                                                ) : (
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                      float: "left",
                                                    }}
                                                    className="check_status"
                                                  >
                                                    <a
                                                      style={{
                                                        backgroundColor:
                                                          "darkgray",
                                                        marginRight: 27,
                                                      }}
                                                    >
                                                      No Applicant
                                                    </a>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : null}
                              </Scrollbars>
                            </div>
                            <div
                              className="col-sm-12 col-lg-12"
                              style={{ marginTop: 10 }}
                            >
                              {/* <Pagination
                  total={
                    clientJobApproval.data != undefined
                      ? clientJobApproval.total
                      : 0
                  }
                  showSizeChanger
                  showQuickJumper
                  showTotal={(total) => `Total ${total} items`}
                  onChange={handlePageSizeChange}
                  current={currentPage}
                /> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {active == 3 && (
                      <>
                        {AdminClientSideJobs.data != undefined
                          ? AdminClientSideJobs.data.map((data, index) => {
                              return (
                                <div className="col-sm-4">
                                  <div className="matched_jobs">
                                    <div className="matched_jobs_cat_t">
                                      {" "}
                                      {data.categoryName}
                                    </div>
                                    <div className="matched_star1">
                                      {/* <i
                              className="fa fa-star-o"
                              aria-hidden="true"
                            /> */}
                                    </div>
                                    <div className="clearfix" />
                                    <div className="matched_heading">
                                      {data.title}
                                    </div>
                                    <div className="published">
                                      {/* <p className="textOverFlow">
                              {data.description}
                            </p> */}
                                    </div>
                                    <div className="clearfix" />
                                    <div className="matched_jobs_cat">
                                      <i
                                        className="fa fa-map-marker"
                                        aria-hidden="true"
                                      />{" "}
                                      {data.location}
                                    </div>
                                    <div className="matched_jobs_cat text-right">
                                      {/* <i
                              className="fa fa-clock-o"
                              aria-hidden="true"
                            />{" "} */}
                                      {data.jobType}
                                    </div>
                                    <div className="clearfix" />
                                    <div className="matched_jobs_cat">
                                      <i
                                        className="fa fa-list-ul"
                                        aria-hidden="true"
                                      />{" "}
                                      {data.numberOfVacancies} Vecancies
                                    </div>
                                    <div className="clearfix" />
                                    <div className="matched_jobs_share">
                                      <a
                                        href="#"
                                        data-target="#share"
                                        data-toggle="modal"
                                      >
                                        <i
                                          className="fa fa-share-alt"
                                          aria-hidden="true"
                                        />
                                      </a>
                                      <a
                                        style={{ marginLeft: 12 }}
                                        onClick={() =>
                                          handleEditReject(data.jobId)
                                        }
                                        data-target="#apply_now_pop"
                                        data-toggle="modal"
                                      >
                                        Edit
                                      </a>
                                    </div>
                                    {/*
                                    <div className="check_status">
                                      {data.ispublishedJobs ? (
                                        data.numberOfApplicants < 1 ? (
                                          <a
                                            onClick={() =>
                                              handleCheckStatus(data.jobId)
                                            }
                                            data-target="#apply_now_pop"
                                            data-toggle="modal"
                                          >
                                            Check Status
                                          </a>
                                        ) : (
                                          <a
                                            onClick={() =>
                                              handleCheckStatus(data.jobId)
                                            }
                                            data-target="#apply_now_pop"
                                            data-toggle="modal"
                                          >
                                            View Applicant
                                          </a>
                                        )
                                      ) : (
                                        <a
                                          onClick={() =>
                                            handlePublished(data.jobId)
                                          }
                                          data-target="#apply_now_pop"
                                          data-toggle="modal"
                                        >
                                          Publish
                                        </a>
                                      )}
                                    </div>
                                  */}
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* model------ */}
          <Modal
            show={isModelOpen}
            onHide={() => {
              setIsModelOpen(false);
            }}
          >
            <form onSubmit={handleSubmit(handleModelSubmitReject)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>Reject Reason</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <Radio.Group onChange={onChangeRadio} value={radioValue}>
                    <Space direction="vertical">
                      <Radio
                        value={
                          "Thanks for showing interest in JIT. Unfortunately, we don't have candidates who satisfy your requirements. We will contact you as soon as we receive such resumes"
                        }
                      >
                        Thanks for showing interest in JIT. Unfortunately, we
                        don't have candidates who satisfy your requirements.{" "}
                        <br />{" "}
                        <span style={{ paddingLeft: 25 }}>
                          We will contact you as soon as we receive such resumes
                        </span>
                      </Radio>
                      <Radio
                        value={
                          "We are sorry. We cannot accept your application since you don't satisfy our criteria."
                        }
                      >
                        We are sorry. We cannot accept your application since{" "}
                        you don't satisfy our criteria.
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      {/* <label htmlFor="email">Reason</label> */}
                      <textarea
                        className="form-control"
                        rows={4}
                        style={{ height: 88 }}
                        placeholder="Type Reason"
                        name="reason"
                        ref={register({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4"></div>

                  <div className="col-sm-1">
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="submit"
                      // onClick={() => {
                      //   setPublish(false);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-sm-2"></div>
                  <div className="col-sm-1">
                    <button
                      className="btn "
                      type="button"
                      onClick={() => {
                        setIsModelOpen(false);
                      }}
                    >
                      cancel
                    </button>
                  </div>

                  <div className="col-sm-4"></div>
                </div>
              </Modal.Body>
            </form>
          </Modal>

          {/* model------ */}

          {/* candidate add model */}
          <Modal
            show={jobApplayOpen}
            onHide={() => {
              setJobApplayOpen(!jobApplayOpen);
            }}
          >
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinishOne}
              autoComplete="off"
            >
              <Modal.Header closeButton>
                <Modal.Title>Apply Now</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="clearfix" />

                {/*  */}
                {/* filteration */}

                <div className="action_btn1">
                  <button
                    type="button"
                    className="actions_bt"
                    onClick={() => {
                      setFilterShow(!filterShow);
                    }}
                  >
                    <i
                      className="fa fa-ellipsis-h mdi-toggle-switch-off"
                      aria-hidden="true"
                    />
                  </button>
                  <div className="col-sm-12">
                    <div
                      className="dash_action"
                      tabIndex={-1}
                      style={{ display: filterShow ? "block" : "none" }}
                    >
                      <div className="action_sec">
                        <ul ref={menuRef}>
                          <li>
                            <a
                              onClick={() => {
                                setStatusFilter(0);
                              }}
                              style={{
                                color: statusFilter !== 0 ? "" : "blue",
                              }}
                            >
                              All
                            </a>{" "}
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                setStatusFilter(1);
                              }}
                              style={{
                                color: statusFilter !== 1 ? "" : "blue",
                              }}
                            >
                              Applied
                            </a>{" "}
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                setStatusFilter(2);
                              }}
                              style={{
                                color: statusFilter !== 2 ? "" : "blue",
                              }}
                            >
                              {" "}
                              Selected{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                setStatusFilter(3);
                              }}
                              style={{
                                color: statusFilter !== 3 ? "" : "blue",
                              }}
                            >
                              Rejected{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* filteration end*/}

                {/*  */}
                <div style={{ marginTop: 25 }}>
                  {AdminGetAllApliedCandidateListForClientJob.data != undefined
                    ? AdminGetAllApliedCandidateListForClientJob.data.map(
                        (data, index) => {
                          return (
                            <>
                              <div className="candidate_no">
                                C00{index + 1}
                                {data.applicationStatusId == 1 ? (
                                  <>
                                    <span
                                      style={{
                                        marginLeft: 6,
                                        color: "blueviolet",
                                        fontSize: 18,
                                      }}
                                    >
                                      {data.statusTitle}
                                    </span>
                                  </>
                                ) : null}
                                {data.applicationStatusId == 2 ? (
                                  <>
                                    <span
                                      style={{
                                        marginLeft: 6,
                                        color: "green",
                                        fontSize: 18,
                                      }}
                                    >
                                      {data.statusTitle}
                                    </span>
                                  </>
                                ) : null}
                                {data.applicationStatusId == 3 ? (
                                  <>
                                    <span
                                      style={{
                                        marginLeft: 6,
                                        color: "red",
                                        fontSize: 18,
                                      }}
                                    >
                                      {data.statusTitle}
                                    </span>
                                    <p style={{ color: "red" }}>
                                      {data.rejectedComment}
                                    </p>
                                  </>
                                ) : null}
                              </div>
                              <div className="close_icon _cursor-pointer"></div>
                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label htmlFor="email">Candidate Id</label>
                                  <input
                                    type="text"
                                    disabled
                                    defaultValue={data.candidateId}
                                    className="form-control "
                                    placeholder=""
                                  />
                                </div>
                              </div>

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label htmlFor="email">
                                    Candidate first Name
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    defaultValue={data.firstName}
                                    className="form-control "
                                    placeholder=""
                                  />
                                </div>
                              </div>

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label htmlFor="email">
                                    Candidate Middle Name
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    defaultValue={data.middleName}
                                    className="form-control "
                                    placeholder=""
                                  />
                                </div>
                              </div>

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label htmlFor="email">
                                    Candidate Last Name
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    defaultValue={data.lastName}
                                    className="form-control "
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </>
                          );
                        }
                      )
                    : null}
                </div>
                {/*  */}

                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div style={{ paddingTop: 20 }}>
                          <div className="candidate_no">C00{index + 1}</div>
                          <div className="close_icon _cursor-pointer">
                            <img
                              onClick={() => {
                                remove(field.name);
                                // handleRemoveIndex(index);
                                setCandidateaddResult(
                                  fields.length == index + 1
                                    ? ""
                                    : AddCandidateListForFormResult.message
                                );
                                setIsDisplay(
                                  fields.length == index + 1 ? true : false
                                );
                              }}
                              src={require("./../../../images/close_icon.png")}
                              width={16}
                              height={16}
                              style={{ marginTop: 26 }}
                            />
                          </div>
                          <div className="col-sm-3">
                            <label htmlFor="email">Candidate Id</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "candidateId"]}
                              fieldKey={[field.fieldKey, "candidateId"]}
                              initialValue={"0"}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing Candidate Id",
                                },
                              ]}
                            >
                              <Input placeholder="" style={{ height: 39 }} />
                            </Form.Item>
                          </div>

                          <div className="col-sm-3">
                            <label htmlFor="email">Candidate First Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "firstName"]}
                              fieldKey={[field.fieldKey, "firstName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing first name",
                                },
                              ]}
                            >
                              <Input placeholder="" style={{ height: 39 }} />
                            </Form.Item>
                          </div>

                          <div className="col-sm-3">
                            <label htmlFor="email">Middle Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "middleName"]}
                              fieldKey={[field.fieldKey, "middleName"]}
                              rules={[
                                {
                                  required: false,
                                  message: "Middle Name Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" style={{ height: 39 }} />
                            </Form.Item>
                          </div>

                          <div className="col-sm-3">
                            <label htmlFor="email">Last Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "lastName"]}
                              fieldKey={[field.fieldKey, "lastName"]}
                              rules={[
                                {
                                  required: false,
                                  message: "Last Name Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" style={{ height: 39 }} />
                            </Form.Item>
                          </div>

                          <div className="col-sm-10">
                            <label htmlFor="email">
                              Document attachment option
                            </label>
                            <Form.Item
                              {...field}
                              name={[field.name, "documentFlag"]}
                              fieldKey={[field.fieldKey, "document"]}
                              rules={[
                                {
                                  required: false,
                                  message: "documentFlag No Missing ",
                                },
                              ]}
                            >
                              {/* <Radio.Group
                                onChange={onChangeRadioGroup}
                                value={radioGroupValue}
                                size="large"
                              >
                                <Radio style={{ paddingLeft: 3 }} value={1}>
                                  Resume
                                </Radio>
                                <Radio value={2}>Certifications</Radio>
                                <Radio value={3}>Passport</Radio>
                                <Radio value={4}>Residence Status</Radio>
                              </Radio.Group> */}
                              <Checkbox.Group
                                style={{ width: "100%" }}
                                onChange={onChangeCheckBox}
                              >
                                <Row>
                                  <Col span={6}>
                                    <Checkbox value="1">Resume</Checkbox>
                                  </Col>
                                  <Col span={6}>
                                    <Checkbox value="2">
                                      Certifications
                                    </Checkbox>
                                  </Col>
                                  <Col span={6}>
                                    <Checkbox value="3">Passport</Checkbox>
                                  </Col>
                                  <Col span={6}>
                                    <Checkbox value="4">Residence</Checkbox>
                                  </Col>
                                </Row>
                              </Checkbox.Group>
                            </Form.Item>
                          </div>

                          <div
                            className="col-sm-12"
                            style={
                              {
                                // display: checkedData.includes("1") ? "" : "none",
                              }
                            }
                          >
                            <label htmlFor="email">Resume</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "resume"]}
                              fieldKey={[field.fieldKey, "resume"]}
                              rules={[
                                {
                                  required: false,
                                  message: "resume No Missing ",
                                },
                              ]}
                            >
                              <FileUploads
                                accept=".pdf,.jpg,.jpeg,.png"
                                onChange={(file: any) => {
                                  handleDrop([file]);
                                }}
                                name="Upload icon"
                                disabled={false}
                              />
                            </Form.Item>
                          </div>
                          <div
                            className="col-sm-12"
                            style={
                              {
                                // display: checkedData.includes("2") ? "" : "none",
                              }
                            }
                          >
                            <label htmlFor="email">Certifications</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "certifications"]}
                              fieldKey={[field.fieldKey, "certifications"]}
                              rules={[
                                {
                                  required: false,
                                  message: "certifications No Missing ",
                                },
                              ]}
                            >
                              <FileUploads
                                accept=".pdf,.jpg,.jpeg,.png"
                                onChange={(file: any) => {
                                  handleDrop([file]);
                                }}
                                name="Upload icon"
                                disabled={false}
                              />
                            </Form.Item>
                          </div>
                          <div
                            className="col-sm-12"
                            style={
                              {
                                // display: checkedData.includes("3") ? "" : "none",
                              }
                            }
                          >
                            <label htmlFor="email">Passport</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "passport"]}
                              fieldKey={[field.fieldKey, "passport"]}
                              rules={[
                                {
                                  required: false,
                                  message: "Passport No Missing ",
                                },
                              ]}
                            >
                              <FileUploads
                                accept=".pdf,.jpg,.jpeg,.png"
                                onChange={(file: any) => {
                                  handleDrop([file]);
                                }}
                                name="Upload icon"
                                disabled={false}
                              />
                            </Form.Item>
                          </div>
                          <div
                            className="col-sm-12"
                            style={
                              {
                                // display: checkedData.includes("4") ? "" : "none",
                              }
                            }
                          >
                            <label htmlFor="email">Residence</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "residence"]}
                              fieldKey={[field.fieldKey, "residence"]}
                              rules={[
                                {
                                  required: false,
                                  message: "residence No Missing ",
                                },
                              ]}
                            >
                              <FileUploads
                                accept=".pdf,.jpg,.jpeg,.png"
                                onChange={(file: any) => {
                                  handleDrop([file]);
                                }}
                                name="Upload icon"
                                disabled={false}
                              />
                            </Form.Item>
                          </div>
                          <div className="clearfix" />
                          {/* <button
                            style={{ marginLeft: 234 }}
                            className="btn btn-success "
                            type="submit"
                          >
                            Save
                          </button> */}
                        </div>
                      ))}
                      <Spin
                        size="large"
                        style={{
                          marginLeft: 245,
                          display: isDisplaySpin ? "" : "none",
                        }}
                      />
                      <p
                        style={{
                          color: AddCandidateListForFormResult.isSuccess
                            ? "green"
                            : "red",
                        }}
                      >
                        {candidateaddResult}
                      </p>
                      <div
                        style={{
                          marginTop: 0,
                          display: isDisplay ? "" : "none",
                        }}
                        className="col-sm-4"
                      >
                        <label htmlFor="email">Search Candidate</label>
                        <Form.Item>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select a Candidate Id"
                            optionFilterProp="children"
                            onChange={onChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            // filterOption={(input, option) =>
                            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            // }
                          >
                            {options}
                          </Select>
                        </Form.Item>
                      </div>

                      <div
                        className="col-sm-4"
                        style={{ display: isDisplay ? "" : "none" }}
                      >
                        <Form.Item>
                          <div className="form-group m_t_30">
                            <label htmlFor="email"></label>
                            <button
                              ref={secondButtonRef}
                              className="btn btn-success save-event waves-effect waves-light"
                              type="button"
                              onClick={() => {
                                add(candidateInfoList);
                                setcandidateInfoList(
                                  {} as CandidateInfoByIdResultModel
                                );
                                setIsDisplay(false);
                                setCandidateaddResult("");
                              }}
                            >
                              Add Candidates
                            </button>
                          </div>
                        </Form.Item>
                      </div>
                    </>
                  )}
                </Form.List>
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setJobApplayOpen(false);
                    setIsDisplay(true);
                    setCandidateaddResult("");
                  }}
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                >
                  Cancel
                </button>
              </div>
              <div className="clearfix" />
              {/* </form> */}
            </Form>
          </Modal>
          <AdminEditJob
            isOpenData={isOpen}
            adminEditRespondState={adminEditRespondState}
            handleCloseModel={handleCloseModel}
          />

          {/* model end */}
        </div>
      </div>
    </>
  );
};

export default withRouter(AdminClientSideJobs);
