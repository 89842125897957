import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import IdleTimer from "react-idle-timer";
import { withRouter } from "react-router-dom";
import AuthService from "../services/AuthService";

const IdleTimeChecker = (props: any) => {
  const idelTimerRef = useRef(null);
  const sessionTimeOutRef = useRef(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnIdle = () => {
    //console.log(444444, "User Is Idle");
    setIsOpen(!isOpen);
    sessionTimeOutRef.current = window.setTimeout(handleLogOut, 10000);
  };

  const handleLogOut = () => {
    setIsOpen(!isOpen);

    AuthService.logout();
    props.history.push("/login");
    clearTimeout(sessionTimeOutRef.current);
  };

  const handleActive = () => {
    setIsOpen(!isOpen);
    clearTimeout(sessionTimeOutRef.current);
  };

  return (
    <div>
      <IdleTimer
        ref={idelTimerRef}
        timeout={1800000}
        onIdle={handleOnIdle}
      ></IdleTimer>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>your session is going to expire</Modal.Body>
        <div className="modal-footer  m-t-30">
          <button
            className="btn btn-success save-event waves-effect waves-light"
            type="button"
            onClick={handleLogOut}
          >
            LogOut
          </button>
          <button
            onClick={handleActive}
            data-dismiss="modal"
            className="btn btn-default waves-effect"
            type="button"
          >
            Keep Me Login
          </button>
        </div>
        <div className="clearfix" />
      </Modal>
    </div>
  );
};

export default withRouter(IdleTimeChecker);
