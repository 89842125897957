import React, { useState } from "react";

function SelectOption({
  values,
  disabled,
  onChange,
  onBlur,
  value,
  name,
  nameForLablel,
}: {
  values: any;
  disabled: any;
  onChange: any;
  onBlur: any;
  value: any;
  name: any;
  nameForLablel: any;
}) {
  const opts = values.map((e: any, i: any) => (
    <option key={i} value={e.value}>
      {e.label}
    </option>
  ));

  let o = false;

  const actualLabel = values.find((e: any) => {
    if (e.value == "0") {
      o = true;
    }
    return e.value == value;
  });

  return (
    <React.Fragment>
      <select
        className={
          disabled ? "custom-select-sec _cursor-no-drop" : "custom-select-sec"
        }
        disabled={disabled}
        value={value}
        name={name}
        onChange={(e: any) => {
          if (e.target.value) {
            onChange(e.target.value);
          }
        }}
      >
        {o ? "" : <option key={1000000} value="0"></option>}
        {opts}
      </select>
      <span className="holder">
        {actualLabel ? actualLabel["label"] : nameForLablel}
      </span>
    </React.Fragment>
  );
}

export default SelectOption;
