import { ErrorMessage } from "@hookform/error-message";
import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import {
  AdminJobSave,
  DeleteAdminMyJob,
  GetAdminMyJob,
  GetAdminPermission,
  GetAllAdminMyJobList,
  SavePublish,
  getAdminPermissionList,
  useAdminMyJobContext,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import {
  getTitleWithCategoryId,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import {
  getExperienceList,
  useExperienceContext,
  useExperienceDispatcher,
} from "../../../action/general/ExperienceAction";
import {
  getVendorMyProfileSelectBoxList,
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../../action/MyProfileVendorAction";
import {
  AdminJobListRequestModel,
  AdminPermissionRequestModel,
  JobIdRequestModel,
  PublishedJobRequestModel,
  PublishedJobRespond,
  RowIdRequestModel,
  permisionRequestModel,
} from "../../../models/admin/AdminMyJob";
import { jobAlertTitleDropdownResultModel } from "../../../models/candidate/JobAlert";
import { categoryWithId } from "../../../models/candidate/JobCategory";
import AuthService from "../../../services/AuthService";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import SelectOption from "../../candidate/my_profile/components/SelectOption";
import AdminDraftedJobComponent from "../myJobs/AdminDraftedJobComponent";
import AdminPublishedJobComponent from "../myJobs/AdminPublishedJobComponent";
import { updateJob } from "./../../../apis/admin";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "./../../../context/GlobalSpinner";
import { toast, ToastContainer } from "react-toastify";
import {
  getClientjob,
  useClientJobContext,
  useClientJobDispatcher,
} from "../../../action/client/ClientJobAction";

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IClientJobFormProps {
  isClientJobOpen: boolean;
  handleClientJobOpen: () => void;
  currentClientJobId: number;
  loggedAdminId: number;
  handleJobModelClose: () => void;
}

interface IClientJobFormState {
  tempData: jobAlertTitleDropdownResultModel[];
}

const defaultValues = {
  jobTypeData: "",
  countryCode: "",
  mobileNumber: "",
  firstName: "",
  categoryId: 0,
  tempData: [],
  jobTitle: "",
  jobStatus: "",
  assignTo: "",
};

const ClientJobForm: React.FC<IClientJobFormProps> = (props) => {
  const {
    isClientJobOpen,
    handleClientJobOpen,
    currentClientJobId,
    loggedAdminId,
    handleJobModelClose,
  } = props;

  const authorizationToken = AuthService.accessToken;
  let jobCategoryData: ReactSelectOption[] = [];
  let experienceData: ReactSelectOption[] = [];
  let jobTypeDatas: ReactSelectOption[] = [];

  const [
    adminMyJobComponentState,
    setAdminMyJobComponentState,
  ] = React.useState<IClientJobFormState>(defaultValues);

  const { tempData } = adminMyJobComponentState;

  const jobStatusSelect = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" },
  ];

  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filename, setFileName] = useState("");

  const [obj, setObj] = useState<any>({ file: null });
  const [filename1, setFileName1] = useState("");
  const [obj1, setObj1] = useState<any>({ file: null });
  const [mirrorData, setMirrorData] = useState<number>(0);
  const [jobStatusData, setjobStatusData] = useState<number>(1);
  const [currentJobId, setCurrentJobId] = useState<number>(0);
  const [isPublish, setPublish] = useState<boolean>(false);

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const {
    adminSaveJobRespond,
    jobListInAdmin,
    permissionResult,
    adminEditRespond,
    adminDeleteRespond,
    AdminPermissionResult,
  } = adminMyJobContext;

  const myProfileVendorDispatcher = useVendorMyProfileDispatcher();

  const jobAlertDispatcher = useJobAlertDispatcher();
  const myVendorProfileDispatcher = useVendorMyProfileDispatcher();
  const myVendorProfileContext = useVendorMyProfileContext();
  const {
    basicInfo,
    loggedUserId,
    myProfile,
    myProfileProgressBar,
    profileImage,
  } = myVendorProfileContext;

  const clientJobDispatcher = useClientJobDispatcher();
  const clientJobContext = useClientJobContext();
  const {
    AllClientJobs,
    ClientJob,
    publishJobResult,
    ClientJobSaveRespondModel,
    clientJobStatusResul,
  } = clientJobContext;

  if (myProfile.jobCategories != undefined) {
    jobCategoryData = myProfile.jobCategories.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  if (myProfile.experiences != undefined) {
    experienceData = myProfile.experiences.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  const jobAlertContext = useJobAlertContext();
  const {
    jobAlert,
    jobAlertGetAll,
    jobAlertSaveRespond,
    jobAlertTitleDropdownResult,
  } = jobAlertContext;

  const globalSpinner = useGlobalSpinnerContext();

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const watchAllFields = watch2();
  //console.log(34, adminEditRespond.data);

  //console.log("props.isClientJobOpen------", isClientJobOpen);
  //console.log("props.currentClientJobId------", currentClientJobId);
  //console.log("adminEditRespond------", adminEditRespond);
  //console.log("adminEditRespond------", tempData);

  React.useEffect(() => {
    if (isClientJobOpen != undefined && currentClientJobId != undefined) {
      //console.log("------adminEditRespond------", adminEditRespond);
      setMirrorData(0);
      setCurrentJobId(currentClientJobId);
      // if (authorizationToken != null) {
      //   (async () => {
      //     await GetAdminMyJob(
      //       adminMyJobDispatcher,
      //       {
      //         RowId: currentClientJobId,
      //       } as RowIdRequestModel,
      //       authorizationToken
      //     );
      //   })();
      // }
      if (authorizationToken != null && currentClientJobId != 0) {
        (async () => {
          await getClientjob(
            clientJobDispatcher,
            {
              RowId: currentClientJobId,
            } as any,
            authorizationToken
          );
        })();
      }
      // setIsOpen(true);
    }
  }, [currentClientJobId]);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getVendorMyProfileSelectBoxList(myProfileVendorDispatcher);
      })();
    }
  }, []);

  React.useEffect(() => {
    setAdminMyJobComponentState((adminMyJobComponentState) => {
      return {
        ...adminMyJobComponentState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);

  React.useEffect(() => {
    if (adminSaveJobRespond.isSuccess || adminDeleteRespond.isSuccess) {
      // setIsOpen(false);
      handleClientJobOpen();
    }
  }, [adminSaveJobRespond, adminDeleteRespond]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getAdminPermissionList(
          adminMyJobDispatcher,
          {
            PermissionId: 1,
          } as permisionRequestModel,
          authorizationToken
        );
      })();
    }
  }, [authorizationToken]);

  useEffect(() => {
    if (ClientJob.data != undefined && currentJobId > 0) {
      //console.log("inside setvalue adminEditRespond.data");
      //console.log(adminEditRespond.data[0]);
      //console.log("inside setvalue adminEditRespond.data category :");
      //console.log(adminEditRespond.data[0].categoryId);
      //console.log("inside setvalue categories 1:");
      //console.log(myProfile.jobCategories);
      //console.log("inside setvalue categories 2:");
      //console.log(jobCategoryData);
      // setValue("AdminId", );
      // setValue("Rowid", );
      setValue2("categoryId", Number(ClientJob.data[0].categoryId));
      setValue2("jobTitle", ClientJob.data[0].title);
      setValue2("jobDescription", ClientJob.data[0].description);
      setValue2("experienceId", Number(ClientJob.data[0].experienceId));
      setValue2("vacancies", ClientJob.data[0].numberOfVacancies);
      setValue2("jobTypeData", ClientJob.data[0].jobTypeId.toString());
      setValue2("location", ClientJob.data[0].location);
      setValue2("minAnnualSalary", ClientJob.data[0].minAnnualSalary);
      setValue2("maxAnnualSalary", ClientJob.data[0].maxAnnualSalary);
      // setValue2("MinAnnualSalary", ClientJob.data[0].);
      //  setValue2("WillingnessToTravelFlag",ClientJob.data[0]. );
      // setValue2("AutoScreeningFilterFlag", ClientJob.data[0].);
      // setValue2("AutoSkillAssessmentFlag", ClientJob.data[0].);
      setValue2("jobOverview", ClientJob.data[0].jobOverview);
      setValue2("minimumQualification", ClientJob.data[0].minQualification);
      setValue2("skillsRequired", ClientJob.data[0].skillRequired);
      setValue2("documentsRequired", ClientJob.data[0].documentsRequired);
      // setValue2("AutoSkillAssessmentFlag",ClientJob.data[0]. );
      setValue2("others", ClientJob.data[0].others);
      setValue2("assignTo", ClientJob.data[0].assignedToAdminId.toString());
      setValue2("jobStatus", ClientJob.data[0].jobStatus ? "1" : "2");
      // setValue2("JobIcon", adminEditRespond.data[0].);
      // setValue2("JobIcon",adminEditRespond.data[0]. );
      // setValue2("AdminId", adminEditRespond.data[0].);
      // setValue2("IsAprroved", adminEditRespond.data[0].);
      // setValue2("IsAprroved", adminEditRespond.data[0].);
      // setValue2("Ispublished",adminEditRespond.data[0]. );
      // setValue2("JobIconDocument", adminEditRespond.data[0].);
      // setValue2("JobBackgroundDocument",adminEditRespond.data[0]. );
      // setPermissionArrayList(superAdminUser.data.permissionTypes);
      //setPublish(adminEditRespond.data[0].ispublishedJobs);
      //setCurrentJobId(mirrorData == 1 ? 0 : adminEditRespond.data[0].jobId);
      //}});
    }
  }, [ClientJob.data, currentJobId]);

  React.useEffect(() => {
    //console.log(1213, watchAllFields.categoryId);
    //console.log(1212, authorizationToken);

    if (authorizationToken != null && watchAllFields.categoryId !== 0)
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: watchAllFields.categoryId,
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.categoryId]);

  const handleAdminClick = (data: any) => {
    //console.log(3329, currentJobId);
    //console.log("job save ", data);
    const formData = new FormData();

    formData.append("AdminId", JSON.stringify(loggedAdminId));
    formData.append("RowId", JSON.stringify(currentJobId));
    formData.append("JobId", JSON.stringify(currentJobId));
    formData.append("CategoryId", JSON.stringify(parseInt(data.categoryId)));
    formData.append("Title", String(data.jobTitle));
    formData.append("Description", String(data.jobDescription));
    formData.append(
      "ExperienceId",
      JSON.stringify(parseInt(data.experienceId))
    );
    formData.append(
      "NumberOfVacancies",
      JSON.stringify(parseInt(data.vacancies))
    );
    formData.append("JobTypeId", JSON.stringify(parseInt(data.jobTypeData)));
    formData.append("IsPreferred", JSON.stringify(false));
    formData.append("IsRequired", JSON.stringify(false));
    formData.append("LocationId", String(data.location));
    formData.append(
      "MinAnnualSalary",
      JSON.stringify(parseInt(data.minAnnualSalary))
    );
    formData.append(
      "MaxAnnualSalary",
      JSON.stringify(parseInt(data.maxAnnualSalary))
    );
    formData.append("WillingnessToTravelFlag", JSON.stringify(false));
    formData.append("AutoScreeningFilterFlag", JSON.stringify(false));
    formData.append("AutoSkillAssessmentFlag", JSON.stringify(false));
    formData.append("JobOverview", String(data.jobOverview));
    formData.append("MinQualification", String(data.minimumQualification));
    formData.append("SkillRequired", String(data.skillsRequired));
    formData.append("DocumentsRequired", String(data.documentsRequired));
    formData.append("Others", String(data.others));
    formData.append(
      "AssignedToAdminId",
      JSON.stringify(parseInt(data.assignTo))
    );
    formData.append(
      "JobStatus",
      JSON.stringify(data.jobStatus === "1" ? true : false)
    );
    if (ClientJob.data != null) {
      formData.append("JobIcon", String(ClientJob.data[0].jobIcon));
    }
    if (ClientJob.data != null) {
      formData.append("JobBackground", String(ClientJob.data[0].jobBackground));
    }
    // formData.append("JobIcon", JSON.stringify(filename));
    // formData.append("JobBackground", JSON.stringify(filename1));
    formData.append("IsAprrovedJobs", JSON.stringify(false));
    formData.append("IsProcessedJobs", JSON.stringify(false));
    formData.append("IspublishedJobs", JSON.stringify(isPublish));
    formData.append("JobIconDocument", obj.file);
    formData.append("JobBackgroundDocument", obj1.file);
    // if (authorizationToken != null) {
    //   (async () => {
    //     await AdminJobSave(
    //       adminMyJobDispatcher,

    //       formData,
    //       authorizationToken
    //     );
    //   })();
    // }

    for (var key of (formData as any).entries()) {
      //console.log(key[0] + ", " + key[1]);
    }

    globalSpinner.showSpinner();
    //console.log("----Form Data----", formData);
    updateJob(formData)
      .then((res: any) => {
        //console.log(res.data);
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          toast.success("Job details updated successfully.");
        } else {
          //console.log(res.data);
          globalSpinner.hideSpinner();
          toast.error(res.data.message);
        }
      })
      .catch((err: any) => {
        //console.log(err);
        globalSpinner.hideSpinner();
        toast.error(err.toString());
      });

    handleClientJobOpen();
    handleJobModelClose();
  };

  const handleDrop = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "application/pdf"].includes(files[0].type) &&
        files[0].size < 1000000 * 5
      ) {
        //console.log(files[0]);
        if (files.length > 0) {
          setObj({
            ...obj,
            ["file"]: files[0],
          });
          setFileName(files[0].name);
        }
      }
    }
  };

  const handleDropOne = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "application/pdf"].includes(files[0].type) &&
        files[0].size < 1000000 * 5
      ) {
        //console.log(files[0]);
        if (files.length > 0) {
          setObj1({
            ...obj1,
            ["file"]: files[0],
          });
          setFileName1(files[0].name);
        }
      }
    }
  };

  const handleEdit = (id: any) => {
    setMirrorData(0);
    setCurrentJobId(id);
    if (authorizationToken != null) {
      (async () => {
        await GetAdminMyJob(
          adminMyJobDispatcher,
          {
            RowId: id,
          } as RowIdRequestModel,
          authorizationToken
        );
      })();
    }
    //setIsOpen(true);
  };

  const handleMirrorJob = (id: any) => {
    setMirrorData(1);

    if (authorizationToken != null) {
      (async () => {
        await GetAdminMyJob(
          adminMyJobDispatcher,
          {
            RowId: id,
          } as RowIdRequestModel,
          authorizationToken
        );
      })();
    }
    //setIsOpen(true);
  };

  const handleDelete = () => {
    if (authorizationToken != null) {
      (async () => {
        await DeleteAdminMyJob(
          adminMyJobDispatcher,
          {
            JobId: currentJobId,
          } as JobIdRequestModel,
          authorizationToken
        );
      })();
    }
  };

  const handlePublish = (id: any) => {
    if (authorizationToken != null) {
      (async () => {
        await SavePublish(
          adminMyJobDispatcher,
          {
            AdminId: loggedAdminId,
            Isactive: true,
            Jobid: id,
            PublishedJobsFLag: true,
          } as PublishedJobRequestModel,
          authorizationToken
        );
      })();
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="content-wrapper">
        <div className="container-fluid">
          {/* BEGIN MODAL */}
          <Modal
            show={isClientJobOpen}
            onHide={() => {
              handleClientJobOpen();
              handleJobModelClose();
            }}
          >
            <form onSubmit={handleSubmit2(handleAdminClick)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>
                  {/* {currentAdminId == 0
                        ? " New Admin Profile"
                        : "Update Admin Profile"} */}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Category
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        rules={{
                          required: "Category is required",
                          validate: function (value) {
                            //console.log(value);
                            return Number(value)
                              ? undefined
                              : "Category is required";
                          },
                        }}
                        control={control2}
                        name="categoryId"
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={jobCategoryData}
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="categoryId"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Type
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        className="custom-select-sec"
                        control={control2}
                        name="jobTypeData"
                        rules={{
                          required: "Job Type is required",
                        }}
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              myProfile.jobTypes != undefined
                                ? myProfile.jobTypes.map((e: any) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : jobTypeDatas
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="jobTitle"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Title
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="jobTitle"
                        rules={{
                          required: "Job Title is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              tempData != undefined
                                ? tempData.map((e) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : tempData
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                  </div>
                  <ErrorMessage
                    errors={errors2}
                    name="jobTitle"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      name="location"
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Experience</label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="experienceId"
                        rules={{
                          required: false,
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={experienceData}
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="experience"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>

                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Experience</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      name="experience"
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div> */}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Vacancies</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      name="vacancies"
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Overview</label>
                    <textarea
                      name="jobOverview"
                      className="form-control"
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Description</label>
                    <textarea
                      name="jobDescription"
                      className="form-control"
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Minimum Qualification</label>
                    <textarea
                      name="minimumQualification"
                      className="form-control"
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Skills Required</label>
                    <textarea
                      name="skillsRequired"
                      className="form-control"
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">MinAnnualSalary</label>
                    <input
                      type="text"
                      className="form-control"
                      name="minAnnualSalary"
                      placeholder="Type here"
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">MaxAnnualSalary</label>
                    <input
                      type="text"
                      className="form-control"
                      name="maxAnnualSalary"
                      placeholder="Type here"
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>

                <div className="clearfix" />
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Documents Required</label>
                    <textarea
                      name="documentsRequired"
                      className="form-control"
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Others</label>
                    <textarea
                      name="others"
                      className="form-control"
                      defaultValue={""}
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Assign TO</label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="assignTo"
                        rules={{
                          required: "Assign To is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              permissionResult != undefined
                                ? permissionResult.data.map((e: any) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : jobCategoryData
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="assignTo"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Status</label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="jobStatus"
                        rules={{
                          required: "Job Title is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={jobStatusSelect}
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="jobStatus"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Icon</label>
                    {/* <div className="box1">
                      <input
                        type="file"
                        name="file-1[]"
                        id="file-1"
                        className="inputfile inputfile-1"
                        data-multiple-caption="{count} files selected"
                        multiple
                      />
                      <label htmlFor="file-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={17}
                          viewBox="0 0 20 17"
                        >
                          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                        </svg>
                       
                        <span></span>
                      </label>
                    </div>
                  */}
                    <FileUploads
                      accept=".pdf,.jpg,.jpeg"
                      onChange={(file: any) => {
                        handleDrop([file]);
                      }}
                      name="Upload icon"
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Job Background</label>
                    <FileUploads
                      accept=".pdf,.jpg,.jpeg"
                      onChange={(file: any) => {
                        handleDropOne([file]);
                      }}
                      name="Upload Background"
                      disabled={false}
                    />
                    {/* <div className="box1">
                      <input
                        type="file"
                        name="file-1[]"
                        id="file-1"
                        className="inputfile inputfile-1"
                        data-multiple-caption="{count} files selected"
                        multiple
                      />
                      <label htmlFor="file-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={17}
                          viewBox="0 0 20 17"
                        >
                          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                        </svg>
                        <span>Upload Backgrond</span>
                      </label>
                    </div>
                  */}
                  </div>
                </div>

                <div className="clearfix" />
              </Modal.Body>
              <div className="modal-footer m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                  onClick={() => {
                    handleClientJobOpen();
                    handleJobModelClose();
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="clearfix" />
            </form>
          </Modal>

          {/* END MODAL */}
          <div className="clearfix" />
        </div>
      </div>
    </>
  );
};

export default ClientJobForm;
