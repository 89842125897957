import { Dispatch, Reducer } from "react";

import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";

import { AppUrls } from "../../environments/environment";
import {
  AdminJbSaveRespondModel,
  AdminJobEditRespondModel,
  AdminJobListRequestModel,
  AdminJobListRespondModel,
  AdminPermissionRequestModel,
  AdminPermissionRespondModel,
  AssignToRequestModel,
  AssignToRespondModel,
  DeleteJobRespondModel,
  JobIdRequestModel,
  permisionRequestModel,
  PermissionResultModel,
  PublishedJobRequestModel,
  PublishedJobRespond,
  RowIdRequestModel,
} from "../../models/admin/AdminMyJob";
import {
  AdminMyJobDispatcherContext,
  AdminMyJobStateContext,
  IAdminMyJobManagementState,
} from "../../context/admin/AdminMyJobContext";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IAdminMyJobAction =
  | {
      type: "LEST_ALL_ADMIN_PERMISSION";
      permissionResult: PermissionResultModel;
    }
  | {
      type: "LEST_ALL_ADMIN_JOBS";
      jobListInAdmin: AdminJobListRespondModel;
    }
  | {
      type: "LEST_ALL_ADMIN_JOBS_PUB";
      jobListInAdminPub: AdminJobListRespondModel;
    }
  | {
      type: "SAVE_ADMIN_JOB";
      adminSaveJobRespond: AdminJbSaveRespondModel;
    }
  | {
      type: "EDIT_JOB";
      adminEditRespond: AdminJobEditRespondModel;
    }
  | {
      type: "DELETE_JOB";
      adminDeleteRespond: DeleteJobRespondModel;
    }
  | {
      type: "SAVE_PUBLISH";
      publishedJobRespond: PublishedJobRespond;
    }
  | {
      type: "GET_ADMIN_PERMISSION";
      AdminPermissionResult: AdminPermissionRespondModel;
    }
  | {
      type: "SET_ASSIGN_TO";
      AssignToRespondModelData: AssignToRespondModel;
    };

export const adminMyJobReducer: Reducer<
  IAdminMyJobManagementState,
  IAdminMyJobAction
> = (draft, action): IAdminMyJobManagementState => {
  switch (action.type) {
    case "LEST_ALL_ADMIN_PERMISSION":
      draft.permissionResult = action.permissionResult;
      return draft;
    case "LEST_ALL_ADMIN_JOBS":
      draft.jobListInAdmin = action.jobListInAdmin;
      return draft;
    case "LEST_ALL_ADMIN_JOBS_PUB":
      draft.jobListInAdminPub = action.jobListInAdminPub;
      return draft;
    case "SAVE_ADMIN_JOB":
      draft.adminSaveJobRespond = action.adminSaveJobRespond;
      return draft;
    case "EDIT_JOB":
      draft.adminEditRespond = action.adminEditRespond;
      return draft;
    case "DELETE_JOB":
      draft.adminDeleteRespond = action.adminDeleteRespond;
      return draft;
    case "SAVE_PUBLISH":
      draft.publishedJobRespond = action.publishedJobRespond;
      return draft;
    case "GET_ADMIN_PERMISSION":
      draft.AdminPermissionResult = action.AdminPermissionResult;
      return draft;
    case "SET_ASSIGN_TO":
      draft.AssignToRespondModelData = action.AssignToRespondModelData;
      return draft;
  }
};

export const useAdminMyJobDispatcher = (): React.Dispatch<IAdminMyJobAction> => {
  const adminMyJobDispatcher = React.useContext(AdminMyJobDispatcherContext);
  if (!adminMyJobDispatcher) {
    throw new Error(
      "You have to provide the AdminMyJob dispatcher using theAdminMyJobDispatcherContext.Provider in a parent component."
    );
  }
  return adminMyJobDispatcher;
};

export const useAdminMyJobContext = (): IAdminMyJobManagementState => {
  const adminMyJobContext = React.useContext(AdminMyJobStateContext);
  if (!adminMyJobContext)
    throw new Error(
      "You have to provide the adminMyJob context using the AdminMyJobStateContext.Provider in a parent component."
    );
  return adminMyJobContext;
};

export const getAdminPermissionList = async (
  dispatcher: React.Dispatch<IAdminMyJobAction>,
  query: permisionRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllPermissionInAdmin;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "LEST_ALL_ADMIN_PERMISSION",
        permissionResult: res.data,
      });
    });
  } catch (e) {}
};

// Gaaa,  Active inactive

export const GetAllAdminMyJobList = async (
  dispatcher: React.Dispatch<IAdminMyJobAction>,
  query: AdminJobListRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllJobSInAdmin;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "LEST_ALL_ADMIN_JOBS",
        jobListInAdmin: res.data,
      });
    });
  } catch (e) {}
};

// only for showing publish job number
export const GetAllAdminMyJobListPub = async (
  dispatcher: React.Dispatch<IAdminMyJobAction>,
  query: AdminJobListRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllJobSInAdmin;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "LEST_ALL_ADMIN_JOBS_PUB",
        jobListInAdminPub: res.data,
      });
    });
  } catch (e) {}
};

export const AdminJobSave = async (
  dispatcher: React.Dispatch<IAdminMyJobAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.AdminSaveJob;
    message.loading("please wait file is uploading...");
    axios.post(url, query, { headers: header }).then((res) => {
      dispatcher({
        type: "SAVE_ADMIN_JOB",
        adminSaveJobRespond: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Successfully Uploaded")
          : message.error(res.data.message)
        : message.error("error occured");
    });
  } catch (e) {}
};

export const GetAdminMyJob = async (
  dispatcher: React.Dispatch<IAdminMyJobAction>,
  query: RowIdRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetJobDetails;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_JOB",
        adminEditRespond: res.data,
      });
    });
  } catch (e) {}
};

export const DeleteAdminMyJob = async (
  dispatcher: React.Dispatch<IAdminMyJobAction>,
  query: JobIdRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteAdminJob;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "DELETE_JOB",
        adminDeleteRespond: res.data,
      });
    });
  } catch (e) {}
};

export const SavePublish = async (
  dispatcher: React.Dispatch<IAdminMyJobAction>,
  query: PublishedJobRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SavePublished;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SAVE_PUBLISH",
        publishedJobRespond: res.data,
      });
    });
  } catch (e) {}
};

export const GetAdminPermission = async (
  dispatcher: React.Dispatch<IAdminMyJobAction>,
  query: AdminPermissionRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAdminPermission;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ADMIN_PERMISSION",
        AdminPermissionResult: res.data,
      });
    });
  } catch (e) {}
};

export const SetAllAssignTo = async (
  dispatcher: React.Dispatch<IAdminMyJobAction>,
  query: AssignToRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SetAsignTo;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SET_ASSIGN_TO",
        AssignToRespondModelData: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Assigned Successfully")
          : message.error(res.data.message)
        : message.error("error occured");
    });
  } catch (e) {}
};
