import React from "react";
import ClientJobApprovalComponent from "./ClientJobApprovalComponent";
import AuthService from "./../../../services/AuthService";

interface IClientJobApprovalContainerProps { }

interface IClientJobApprovalContainerState { }

const initialState = {};

const ClientJobApprovalContainer: React.FC<IClientJobApprovalContainerProps> = (props) => {
  const [ClientJobApprovalContainerState, setClientJobApprovalContainerState] = React.useState<IClientJobApprovalContainerState>(
    initialState
  );
  return (
    <>
      <ClientJobApprovalComponent/>
    </>
  );
};
export default ClientJobApprovalContainer;
