import React from "react";
import SocialAccountUrlComponent from "./SocialAccountUrlComponent";
import AuthService from "./../../../../services/AuthService";

interface ISocialAccountUrlContainerProps { }

interface ISocialAccountUrlContainerState { }

const initialState = {};

const SocialAccountUrlContainer: React.FC<ISocialAccountUrlContainerProps> = (props) => {
  const [SocialAccountUrlContainerState, setSocialAccountUrlContainerState] = React.useState<ISocialAccountUrlContainerState>(
    initialState
  );
  return (
    <>
      <SocialAccountUrlComponent/>
    </>
  );
};
export default SocialAccountUrlContainer;
