import { DatePicker, Popover, Radio, Space } from "antd";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import {
  ClientViewApplicationDetailsInfo,
  ClientViewApplicationInfo,
  MoveClientCandidate,
  useClientJobContext,
  useClientJobDispatcher,
} from "../../../action/client/ClientJobAction";
import { environment } from "../../../environments/environment";
import {
  ClientCandidateMoveRequestModel,
  ClientViewApplicationDetailsRequestModel,
  ClientViewApplicationDetailsResultModel,
  ClientViewApplicationRequestModel,
} from "../../../models/client/ClientJob";
import AuthService from "../../../services/AuthService";
import ClientViewApplicationDetails from "./ClientViewApplicationDetails";
const { RangePicker } = DatePicker;
interface IClientViewApplicationComponentState {}

const defaultValues = {};

const ClientViewApplicationComponent = () => {
  let user = AuthService.currentUser;
  const authorizationToken = AuthService.accessToken;
  let { jobId, jobTypeId } = useParams<{
    jobId: any;
    jobTypeId: any;
  }>();

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IClientViewApplicationComponentState>({
    defaultValues,
  });
  const clientJobDispatcher = useClientJobDispatcher();
  const clientJobContext = useClientJobContext();
  const {
    ClientViewApplication,
    ClientViewApplicationDetailsData,
    ClientCandidateMoveData,
  } = clientJobContext;

  const [JobStatusName, setJobStatusName] = useState<string>("Move To");
  const [JobStatusFilterName, setJobStatusFilterName] = useState<string>(
    "In Process"
  );
  const [JobStatusId, setJobStatusId] = useState<number>(0);
  const [candidateId, setCandidateId] = useState<number>(
    ClientViewApplication.data != undefined
      ? ClientViewApplication.data.length > 0
        ? ClientViewApplication.data[0].userId
        : 0
      : 0
  );
  const [JobStatusFilterId, setJobStatusFilterId] = useState<number>(1);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isRemovedAll, setIsRemovedCheckedAll] = useState(false);
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [isChecked, setIsChecked] = useState(false);
  const [isAcept, setIsAcept] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [radioValue, setRadioValue] = useState<string>("");
  const [isRenderCheckedList, setIsRenderCheckedList] = useState(true);
  const [contractDateState, setContractDateState] = useState([]);
  const [
    clientViewDetaisState,
    setClientViewDetaisState,
  ] = useState<ClientViewApplicationDetailsResultModel>(
    {} as ClientViewApplicationDetailsResultModel
  );
  let IdArray = [] as any[];

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != undefined) {
      (async () => {
        await ClientViewApplicationInfo(
          clientJobDispatcher,
          {
            ClientJobId: parseInt(jobId),
            IsActive: true,
            JobStatusId: JobStatusFilterId,
            UserId: 98,
            // parseInt(user.id),
            UserTypeId: 1,
            ViewedJobsFlag: true,
            Page: 1,
            PageSize: 100,
            SearchTerm: "",
            SortOrder: "",
          } as ClientViewApplicationRequestModel,
          authorizationToken
        );
      })();
      setClientViewDetaisState({} as ClientViewApplicationDetailsResultModel);
      setIsAcept(false);
      setIsReject(false);
      setJobStatusId(0);
      setJobStatusName("Move To");
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(false);
      setCheckedListArray([] as any[]);
      setIsChecked(false);
    }
  }, [JobStatusFilterId, ClientCandidateMoveData]);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != undefined) {
      setCandidateId(
        ClientViewApplication.data != undefined
          ? ClientViewApplication.data.length > 0
            ? ClientViewApplication.data[0].userId
            : 0
          : 0
      );
    }
  }, [ClientViewApplication.data]);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != undefined) {
      setClientViewDetaisState(ClientViewApplicationDetailsData);
    }
  }, [ClientViewApplicationDetailsData]);

  React.useEffect(() => {
    if (
      authorizationToken != null &&
      user?.id != undefined &&
      candidateId != 0
    ) {
      (async () => {
        await ClientViewApplicationDetailsInfo(
          clientJobDispatcher,
          {
            CandidateId: candidateId,
            JobId: parseInt(jobId),
          } as ClientViewApplicationDetailsRequestModel,
          authorizationToken
        );
      })();
    }
  }, [candidateId]);

  React.useEffect(() => {
    if (
      authorizationToken != null &&
      user?.id != undefined &&
      JobStatusId != 0
    ) {
      if (parseInt(jobTypeId) !== 1) {
        if (JobStatusId == 3) {
          // open rejected modal
          setIsReject(true);
        } else {
          // open dateselect modal
          setIsAcept(true);
        }
      } else {
        // perment job
        if (JobStatusId == 3) {
          // open rejected modal
          setIsReject(true);
        } else {
          // call api
          (async () => {
            await MoveClientCandidate(
              clientJobDispatcher,
              {
                CandidateId: checkedListArray,
                UserTypeId: 1,
                ClientId: 0,
                UserId: parseInt(user.id),
                ClientJobId: parseInt(jobId),
                JobMovingStatusId: JobStatusId,
                ContractEndDate: new Date(),
                RejectedComment: "",
              } as ClientCandidateMoveRequestModel,
              authorizationToken
            );
          })();
        }
      }
    }
  }, [JobStatusId]);

  React.useEffect(() => {
    if (isRenderCheckedList) {
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(false);
      setIsRenderCheckedList(false);
    }
  }, [isRenderCheckedList]);

  const handleAllChecked = (e: any) => {
    //console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArray = [];

      ClientViewApplication.data.map((d) => {
        IdArray.push(d.userId);
      });
      setCheckedListArray(IdArray);

      setIsCheckedAll(e.target.checked);

      setIsRemovedCheckedAll(false);
    } else {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(e.target.checked);

      setIsRemovedCheckedAll(true);
    }
  };

  const handleAllRemoved = (e: any) => {
    if (e.target.checked) {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(true);
      //console.log("checkedListArray", checkedListArray);
    }
  };

  const handleChecked = (e: any, id: any) => {
    // setIsChecked(true);
    if (e.target.checked) {
      IdArray.push(...checkedListArray, id);
      setCheckedListArray(IdArray);
      //console.log("checkedListArray", checkedListArray);
    } else {
      IdArray = checkedListArray.filter((data) => {
        return data !== id;
      });
      setCheckedListArray(IdArray);
      //console.log("checkedListArray", checkedListArray);
    }
    setIsRemovedCheckedAll(false);
    setIsChecked(true);
  };

  //console.log(25, checkedListArray);
  React.useEffect(() => {
    if (isChecked) {
      if (checkedListArray.length == ClientViewApplication.data.length) {
        setIsCheckedAll(true);
        //console.log("checkedListArray lenth", checkedListArray.length);
        // //console.log("vendorJobStatus.length", vendorJobStatus.length);
      } else {
        setIsCheckedAll(false);
      }
      setIsChecked(false);
    }
  }, [isChecked]);

  const handleStatusMove = (e: any) => {
    if (e.target.value === "1") {
      setJobStatusName("In Process");
    } else if (e.target.value === "2") {
      setJobStatusName("Selected");
    } else if (e.target.value === "3") {
      setJobStatusName("Rejected");
    } else if (e.target.value === "4") {
      setJobStatusName("On Hold");
    } else if (e.target.value === "5") {
      setJobStatusName("Interview: Management");
    } else if (e.target.value === "6") {
      setJobStatusName("Interview: Technical");
    } else if (e.target.value === "7") {
      setJobStatusName("Interview: HR");
    }
    setJobStatusId(parseInt(e.target.value));
  };

  const handleOnJobStatusFilterChange = (e: any) => {
    if (e.target.value === "1") {
      setJobStatusFilterName("In Process");
    } else if (e.target.value === "2") {
      setJobStatusFilterName("Selected");
    } else if (e.target.value === "3") {
      setJobStatusFilterName("Rejected");
    } else if (e.target.value === "4") {
      setJobStatusFilterName("On Hold");
    } else if (e.target.value === "5") {
      setJobStatusFilterName("Interview: Management");
    } else if (e.target.value === "6") {
      setJobStatusFilterName("Interview: Technical");
    } else if (e.target.value === "7") {
      setJobStatusFilterName("Interview: HR");
    }
    setJobStatusFilterId(parseInt(e.target.value));
  };

  const content = (
    <>
      <p>Please Select Candidate</p>
    </>
  );

  const handleModelSubmitReject = (data: any) => {
    if (
      authorizationToken != null &&
      user?.id != undefined &&
      JobStatusId != 0
    ) {
      (async () => {
        await MoveClientCandidate(
          clientJobDispatcher,
          {
            CandidateId: checkedListArray,
            UserTypeId: 1,
            ClientId: 0,
            UserId: parseInt(user.id),
            ClientJobId: parseInt(jobId),
            JobMovingStatusId: JobStatusId,
            ContractEndDate: new Date(),
            RejectedComment: data.reason,
          } as ClientCandidateMoveRequestModel,
          authorizationToken
        );
      })();
    }
    console.log("1");
  };
  const handleModelSubmitAccept = (data: any) => {
    if (
      authorizationToken != null &&
      user?.id != undefined &&
      JobStatusId != 0
    ) {
      (async () => {
        await MoveClientCandidate(
          clientJobDispatcher,
          {
            CandidateId: checkedListArray,
            UserTypeId: 1,
            ClientId: 0,
            UserId: parseInt(user.id),
            ClientJobId: parseInt(jobId),
            JobMovingStatusId: JobStatusId,
            ContractEndDate: new Date(contractDateState[1]),
            RejectedComment: data.reason,
          } as ClientCandidateMoveRequestModel,
          authorizationToken
        );
      })();
    }
    console.log("2");
  };

  const onChangeRadio = (e: any) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
    setValue("reason", e.target.value);
  };

  function onChange(date: any, dateString: any) {
    console.log(date, dateString);
    setContractDateState(dateString);
  }

  return (
    <>
      {" "}
      {/* <div className="content-wrapper"> */}
      <div className="container-fluid">
        {/* <h1 className="heading">Published</h1> */}

        <div className="clearfix" />
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="section_box4">
              <h1 className="pull-left">Job Application</h1>
              <div className="pull-right">
                <span className="select-wrapper-sec">
                  <select
                    name="timepass"
                    className="custom-select-sec"
                    onChange={handleOnJobStatusFilterChange}
                  >
                    <option value="1">
                      In Process -{" "}
                      {ClientViewApplication.countInfo != undefined &&
                      ClientViewApplication.countInfo != undefined &&
                      ClientViewApplication.countInfo.appliedCount != undefined
                        ? ClientViewApplication.countInfo.inProcessCount
                        : 0}
                    </option>
                    <option value="2">
                      Selected -
                      {ClientViewApplication.data != undefined &&
                      ClientViewApplication.countInfo != undefined &&
                      ClientViewApplication.countInfo.selectedCount != undefined
                        ? ClientViewApplication.countInfo.selectedCount
                        : 0}
                    </option>
                    <option value="3">
                      Rejected -
                      {ClientViewApplication.data != undefined &&
                      ClientViewApplication.countInfo != undefined &&
                      ClientViewApplication.countInfo.rejectedCount != undefined
                        ? ClientViewApplication.countInfo.rejectedCount
                        : 0}
                    </option>

                    {/* <option value="8">
                      Shortlist -
                      {ClientViewApplication.data !=
                              undefined &&
                            ClientViewApplication.countInfo !=
                              undefined &&
                            ClientViewApplication.countInfo
                              .shortListedCount != undefined
                              ? ClientViewApplication.countInfo
                                  .shortListedCount
                              : 0}
                    </option> */}
                    {/* <option value="9">
                      Shortlist -
                      {ClientViewApplication.data !=
                              undefined &&
                            ClientViewApplication.countInfo !=
                              undefined &&
                            ClientViewApplication.countInfo
                              .inprocessCount != undefined
                              ? ClientViewApplication.countInfo
                                  .inprocessCount
                              : 0}
                    </option> */}
                  </select>
                  <span className="holder">{JobStatusFilterName}</span>
                </span>
              </div>
              <div className="clearfix" />
              <div className="row">
                <div className="output">
                  <div className="col-sm-12 col-lg-12">
                    <div
                      id="red"
                      className="colors"
                      style={{ display: "block" }}
                    >
                      <div className="m_t_20">
                        <div className="section_box4">
                          <div className="row">
                            <div className="col-sm-3 col-lg-2">
                              <input
                                id="checkbox1"
                                type="checkbox"
                                name="checkbox"
                                defaultValue={1}
                                checked={isCheckedAll}
                                // defaultChecked={isCheckedAll ? true : false}
                                onChange={(e: any) => handleAllChecked(e)}
                              />
                              <label htmlFor="checkbox1">
                                <span /> Select All
                              </label>
                            </div>
                            <div className="col-sm-3 col-lg-2">
                              <input
                                id="checkbox1"
                                type="checkbox"
                                name="checkbox"
                                defaultValue={1}
                                checked={isRemovedAll}
                                onChange={(e: any) => handleAllRemoved(e)}
                              />
                              <label htmlFor="checkbox1">
                                <span /> Deselect All
                              </label>
                            </div>
                            <div className="col-sm-3 col-lg-2">
                              {checkedListArray.length > 0 ? (
                                <>
                                  <span className="select-wrapper-sec">
                                    <select
                                      name="timepass"
                                      className="custom-select-sec"
                                      onChange={handleStatusMove}
                                      defaultValue={JobStatusId.toString()}
                                    >
                                      <option value="1">In Process</option>
                                      <option value="2">Selected</option>
                                      <option value="3">Rejected</option>
                                    </select>

                                    <span className="holder">
                                      {JobStatusName}
                                    </span>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <Popover content={content} trigger="hover">
                                    <span className="select-wrapper-sec">
                                      <select
                                        name="timepass"
                                        className="custom-select-sec"
                                        onChange={handleStatusMove}
                                        defaultValue={JobStatusId.toString()}
                                        disabled={true}
                                      >
                                        <option value="1">In Process</option>
                                        <option value="2">Selected</option>
                                        <option value="3">Rejected</option>
                                      </select>

                                      <span className="holder">
                                        {JobStatusName}
                                      </span>
                                    </span>
                                  </Popover>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="verticalTab"
                        className="jobsearch-tabs  resp-vtabs"
                        style={{
                          display: "block",
                          width: "100%",
                          margin: "0px",
                        }}
                      >
                        <ul className="resp-tabs-list ">
                          <div
                            className="dash_message_vendor"
                            style={{ overflow: "hidden", outline: "none" }}
                            tabIndex={0}
                          >
                            <InfiniteScroll
                              className="_custom-infinite-scroll-class"
                              height={500}
                              dataLength={
                                ClientViewApplication.data != undefined
                                  ? ClientViewApplication.data.length
                                  : 0
                              }
                              // dataLength={2000}
                              // dataLength={totalCount}
                              next={() => {}}
                              hasMore={
                                ClientViewApplication.data != undefined
                                  ? ClientViewApplication.hasNext
                                  : false
                              }
                              loader={
                                null
                                // jobAlertResultDataSate.length > 0 ? (
                                //   <h4>Loading...</h4>
                                // ) : null
                              }
                              // initialScrollY={indexState * 80}
                              endMessage={null}
                            >
                              {/*  */}
                              {ClientViewApplication.data != undefined
                                ? ClientViewApplication.data.map(
                                    (data, index) => {
                                      return (
                                        <li
                                          className={`resp-tab-item ${
                                            data.userId == candidateId
                                              ? "resp-tab-active"
                                              : null
                                          }`}
                                          aria-controls="tab_item-0"
                                          role="tab"
                                        >
                                          <div className="candidates_check">
                                            <input
                                              id="checkbox2"
                                              type="checkbox"
                                              name="checkbox"
                                              defaultValue={1}
                                              onChange={(e: any) =>
                                                handleChecked(e, data.userId)
                                              }
                                              checked={checkedListArray.includes(
                                                data.userId
                                              )}
                                            />
                                            <label htmlFor="checkbox2">
                                              <span />
                                            </label>
                                          </div>
                                          <div
                                            onClick={() => {
                                              setCandidateId(data.userId);
                                            }}
                                          >
                                            <div className="candidates_icon">
                                              {data.userImage != null ? (
                                                <img
                                                  src={
                                                    environment.baseUrl +
                                                    `/Upload/ProfileImage/${data.userImage}`
                                                  }
                                                />
                                              ) : (
                                                <img
                                                  className="img-responsive"
                                                  src={require("./../../../images/profileDefault1.jpg")}
                                                ></img>
                                              )}
                                            </div>
                                            <div className="candidates_con">
                                              <div className="candidates_con_head">
                                                {data.userName}
                                              </div>
                                              <div className="candidates_con_con">
                                                {data.location}
                                              </div>
                                              {/* <div className="candidates_con_con">
      info@jitgmail.com
    </div> */}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    }
                                  )
                                : null}
                            </InfiniteScroll>
                            {/*  */}
                          </div>
                        </ul>
                        <div className="resp-tabs-container jobsearch-tabs-content admins_se">
                          {/*----------------Start-------------*/}
                          <ClientViewApplicationDetails
                            clientViewDetails={clientViewDetaisState}
                          />
                          {/*----------------End-------------*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* model------ */}
        <Modal
          show={isReject}
          onHide={() => {
            setIsReject(false);
          }}
        >
          <form onSubmit={handleSubmit(handleModelSubmitReject)} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>Reject Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <Radio.Group onChange={onChangeRadio} value={radioValue}>
                  <Space direction="vertical">
                    <Radio
                      value={
                        "Thanks for showing interest in JIT. Unfortunately, we don't have candidates who satisfy your requirements. We will contact you as soon as we receive such resumes"
                      }
                    >
                      Thanks for showing interest in JIT. Unfortunately, we
                      don't have candidates who satisfy your requirements.{" "}
                      <br />{" "}
                      <span style={{ paddingLeft: 25 }}>
                        We will contact you as soon as we receive such resumes
                      </span>
                    </Radio>
                    <Radio
                      value={
                        "We are sorry. We cannot accept your application since you don't satisfy our criteria."
                      }
                    >
                      We are sorry. We cannot accept your application since you
                      don't satisfy our criteria.
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    {/* <label htmlFor="email">Reason</label> */}
                    <textarea
                      className="form-control"
                      rows={4}
                      style={{ height: 88 }}
                      placeholder="Type Reason"
                      name="reason"
                      ref={register({
                        required: false,
                      })}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4"></div>

                <div className="col-sm-1">
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                    // onClick={() => {
                    //   setPublish(false);
                    // }}
                  >
                    Save
                  </button>
                </div>
                <div className="col-sm-2"></div>
                <div className="col-sm-1">
                  <button
                    className="btn "
                    type="button"
                    onClick={() => {
                      setIsReject(false);
                    }}
                  >
                    cancel
                  </button>
                </div>

                <div className="col-sm-4"></div>
              </div>
            </Modal.Body>
          </form>
        </Modal>

        {/* model------ */}

        <Modal
          show={isAcept}
          onHide={() => {
            setIsAcept(false);
          }}
        >
          <form onSubmit={handleSubmit(handleModelSubmitAccept)} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>Contract Period</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row" style={{ paddingBottom: 12 }}>
                <RangePicker style={{ marginLeft: 192 }} onChange={onChange} />
              </div>

              <div className="row">
                <div className="col-sm-4"></div>

                <div className="col-sm-1">
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                    // onClick={() => {
                    //   setPublish(false);
                    // }}
                  >
                    Save
                  </button>
                </div>
                <div className="col-sm-2"></div>
                <div className="col-sm-1">
                  <button
                    className="btn "
                    type="button"
                    onClick={() => {
                      setIsAcept(false);
                    }}
                  >
                    cancel
                  </button>
                </div>

                <div className="col-sm-4"></div>
              </div>
            </Modal.Body>
          </form>
        </Modal>

        <div className="clearfix" />
      </div>
      {/* </div> */}
    </>
  );
};

export default ClientViewApplicationComponent;
