import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import SelectOption from "./components/SelectOption";
import FileUploads from "./components/FileUploads";
import PikadayDatepicker from "./components/PikadayDatepicker";
import DragAndDrop from "./components/DragAndDrop";
import { Modal } from "react-bootstrap";
import {
  getTrainingSelectBoxData,
  saveTraining,
  deleteTrainings,
  saveCandidateOtherCertificate,
  saveDigiDocumentDetails,
  saveDigiDocumentUpload,
  getOneDigiDocumentDetails,
} from "./../../../apis/candidate";
import { GlobalSpinnerContext } from "./../../../context/GlobalSpinner";
import { toast, ToastContainer } from "react-toastify";
import { confirmWrapper, confirm } from "./../../GlobalConfirm";
import { ErrorMessage } from "@hookform/error-message";
import AttachDocument from "./../AttachDocument3";
import IsComponentDisabled from "./../../components/IsComponentDisabled";
import { Popconfirm, Tooltip } from "antd";
import _ from "lodash";

type objectIndexing = {
  [key: string]: any;
};

interface ReactSelectOption {
  value: string;
  label: string;
}

interface ITrainingState {
  trainingCertificate: string;
  institue: string;
}

function Training({
  index,
  item,
  courses,
  disabled,
  removeTraining,
  updateTraining,
  candidateId,
  digiLockerType,
  getTrainings,
  digiLockers,
}: {
  index: any;
  item: any;
  courses: any[];
  disabled: boolean;
  removeTraining: any;
  updateTraining: any;
  candidateId: any;
  digiLockerType: any;
  getTrainings: any;
  digiLockers: any;
}) {
  const defaultValues = {
    trainingCertificate: item["trainingCertificate"],
    institue: item["institute"],
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ITrainingState>({
    defaultValues,
  });

  const [
    oneTrainingDocument,
    setOneTrainingDocument,
  ] = useState<objectIndexing>({});

  useEffect(() => {
    if (
      item["digiDocumentDetailId"] != null &&
      item["digiDocumentDetailId"] > 0
    ) {
      getOneDigiDocumentDetails({
        rowId: Number(item["digiDocumentDetailId"]),
      }).then((res) => {
        //console.log(res);
        if (res.data.isSuccess == true && res.data.data != null) {
          setOneTrainingDocument(res.data);
        }
      });
    }
  }, [item.digiDocumentDetailId]);

  const onSubmit = (data: any) => {
    if (!disabled) {
      //console.log(data);

      item["Institute"] = data["institue"];
      item["TrainingCertificate"] = data["trainingCertificate"];

      updateTraining(item, index);
    }
  };

  const globalSpinner = useContext(GlobalSpinnerContext);

  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const onSave = (data: any) => {
    //console.log(data);

    globalSpinner.showSpinner();

    if ("fromLocker" in data) {
      const e = Object.assign({}, item, {
        digiDocumentDetailId: data.digiDocumentDetailId,
      });

      saveTraining(e)
        .then((res) => {
          //console.log(res);
          globalSpinner.hideSpinner();
          if (res.data.isSuccess) {
            getTrainings({
              CandidateId: Number(candidateId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "string",
              SortOrder: "string",
              ShowInactive: false,
            });
            toast.success("Training detail has been updated.");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          //console.log(err);
          globalSpinner.hideSpinner();
          toast.error(err.toString());
        });
    } else {
      saveDigiDocumentDetails({
        RowId: 0,
        DigiDocumentDetailId: 0,
        Name: data["docName"],
        DocumentNumber: data["docNumber"],
        Description: "string",
        CandidateId: Number(candidateId),
        DigiDocumentTypeId: Number(data["docType"]),
        ExpiryDate: new Date(data["docExpiry"]).toISOString(),
        ExpiryFlag: data["docRadio"] == "yes",
        IsActive: true,
      })
        .then((res: any) => {
          //console.log(res.data);
          if (res.data.isSuccess) {
            const formData = new FormData();
            // formData.append("RowId", 0);
            // formData.append("DigiDocumentUploadId", 0);
            formData.append("DigiDocumentDetailId", res.data.entityId);
            formData.append("DigiDocument", "string");
            formData.append("Document", data["docFile"]);
            formData.append("IsActive", "true");

            saveDigiDocumentUpload(formData)
              .then((res1: any) => {
                if (res1.data.isSuccess) {
                  const e = Object.assign({}, item, {
                    digiDocumentDetailId: res.data.entityId,
                  });

                  saveTraining(e)
                    .then((res) => {
                      //console.log(res);
                      globalSpinner.hideSpinner();
                      if (res.data.isSuccess) {
                        getTrainings({
                          CandidateId: Number(candidateId),
                          Page: 1,
                          PageSize: 10,
                          SearchTerm: "string",
                          SortOrder: "string",
                          ShowInactive: false,
                        });
                        toast.success("Training detail has been updated.");
                      } else {
                        toast.error(res.data.message);
                      }
                    })
                    .catch((err) => {
                      //console.log(err);
                      globalSpinner.hideSpinner();
                      toast.error(err.toString());
                    });
                } else {
                  globalSpinner.hideSpinner();
                  toast.error(res.data.message);
                }
              })
              .catch((err: any) => {
                globalSpinner.hideSpinner();
                toast.error("Something went wrong.");
              });
          } else {
            globalSpinner.hideSpinner();
            toast.error(res.data.message);
          }
        })
        .catch((err: any) => {
          globalSpinner.hideSpinner();
          toast.error("Something went wrong.");
        });
    }
  };

  return (
    <React.Fragment>
      <div className=" profile_sec_bg">
        <div
          className="close_icon1 _cursor-pointer"
          onClick={() => {
            removeTraining(item, index);
          }}
        >
          <img
            src={require("./../../../images/close_icon.png")}
            width={16}
            height={16}
          />
        </div>
        <div className="row">
          <form
            className="personal_details"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Training Certificate
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    name="trainingCertificate"
                    className={"form-control"}
                    placeholder="Type here"
                    ref={register({
                      required: "Training certificate is required",
                    })}
                    disabled={disabled}
                  />,
                  disabled
                )}
                {/* <span className="select-wrapper-sec">
                <Controller
                  control={control}
                  name="trainingCertificate"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption values={courses} disabled={disabled} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                />
              </span> */}
                <ErrorMessage
                  errors={errors}
                  name="trainingCertificate"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email" className={"required"}>
                  Institute
                </label>
                {IsComponentDisabled(
                  <input
                    type="text"
                    disabled={disabled}
                    className={"form-control validate input-pwd"}
                    placeholder="Type here"
                    name="institue"
                    ref={register({
                      required: "Institute is required",
                    })}
                  />,
                  disabled
                )}
                <ErrorMessage
                  errors={errors}
                  name="institue"
                  render={({ message }) => (
                    <div className="login_validation">{message}</div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-4">
              {/* <a onClick={() => { setIsOpen2(!isOpen2) }} className="btn submit_btn">Attach Document</a> */}
              {/* <button className="btn submit_btn" type="button" disabled={disabled} onClick={() => { setIsOpen2(!isOpen2) }}>Attach Document</button> */}
              {_.isEmpty(oneTrainingDocument) != true &&
                item["digiDocumentDetailId"] > 0 && (
                  <Popconfirm
                    title={
                      <div>
                        <p>
                          You already have a document attached to this record.
                        </p>
                        <p> Name: {`${oneTrainingDocument.data.name} `}</p>
                        {/* <br /> */}
                        <p>
                          {" "}
                          Last Modified:{" "}
                          {`${oneTrainingDocument.data.updatedDate}`}
                        </p>
                        <p> Do you want to update the above document?</p>
                      </div>
                    }
                    onConfirm={() => setIsOpen2(!isOpen2)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      type="button"
                      className="btn submit_btn"
                      disabled={disabled}
                    >
                      Attach Document
                    </button>
                    <a className="btn submit_btn" style={{ display: "none" }}>
                      Attach Document
                    </a>
                  </Popconfirm>
                )}
              {(_.isEmpty(oneTrainingDocument) == true ||
                item["digiDocumentDetailId"] == null ||
                item["digiDocumentDetailId"] < 1) && (
                <button
                  onClick={() => {
                    setIsOpen2(!isOpen2);
                  }}
                  type="button"
                  className="btn submit_btn"
                  disabled={disabled}
                >
                  Attach Document
                </button>
              )}

              <button
                className="btn submit_btn"
                type="submit"
                disabled={disabled}
              >
                Save
              </button>
            </div>
            <div className="col-sm-8">
              {_.isEmpty(oneTrainingDocument) != true &&
                item["digiDocumentDetailId"] > 0 && (
                  <label className="m_t_20">Document attached </label>
                )}
            </div>
          </form>
        </div>
      </div>
      <Modal
        show={isOpen2}
        onHide={() => {
          setIsOpen2(!isOpen2);
        }}
        dialogClassName="_attach-training"
      >
        <AttachDocument
          digiLockerType={digiLockerType}
          setIsOpen2={setIsOpen2}
          isOpen2={isOpen2}
          candidateId={candidateId}
          onSave={onSave}
          digiLockers={digiLockers}
          docNameProp={watch("trainingCertificate")}
          docTypeProp={2}
          docNumberProp={""}
          docExpiryProp={0}
          docRadioProp={"no"}
        />
      </Modal>
    </React.Fragment>
  );
}

interface ITrainingsState {
  trainingCertificate: string;
  institue: string;
}

function Trainings(props: any) {
  const {
    getTrainings,
    trainingState,
    userId,
    candidateId,
    getCandidateOtherCertificates,
    candidateOtherCertificate,
    getDigiDocumentDetails,
    digiDocumentDetails,
    digiLockerType,
    digiLockers,
  } = props;

  //console.log(trainingState);
  //console.log(candidateOtherCertificate);
  //console.log(digiDocumentDetails);
  //console.log(digiLockerType);

  const defaultValues = {
    trainingCertificate: "",
    institue: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ITrainingsState>({
    defaultValues,
  });

  const [disabled, setDisabled] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [courses, setCourses] = useState([]);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const onSubmit = (data: any) => {
    //console.log(data);
    globalSpinner.showSpinner();
    saveTraining({
      RowId: 0,
      TrainingId: 0,
      CandidateId: Number(candidateId),
      TrainingCertificate: data["trainingCertificate"],
      Institute: data["institue"],
      ValidUpTo: new Date().toISOString(),
      IsActive: true,
    })
      .then((res) => {
        if (res.data.isSuccess) {
          getTrainings({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success("Training details are saved.");
        } else {
          toast.error(res.data.message);
        }
        globalSpinner.hideSpinner();
        setIsOpen(!isOpen);
      })
      .catch((err) => {
        //console.log(err);
        globalSpinner.hideSpinner();
        toast.error(err.toString());
      });
  };

  const updateTraining = async (e: any, i: any) => {
    //console.log(e, i);
    globalSpinner.showSpinner();
    saveTraining(e)
      .then((res) => {
        //console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          getTrainings({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success("Training detail has been updated.");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        //console.log(err);
        globalSpinner.hideSpinner();
        toast.error(err.toString());
      });
  };

  const removeTraining = async (e: any, i: any) => {
    if (
      await confirm({
        confirmation: "Are you sure you want to delete this?",
        options: {
          yes: "Yes",
          no: "No",
          header: "Delete",
        },
      })
    ) {
      //console.log("yes");
      //console.log(e, i);
      globalSpinner.showSpinner();
      deleteTrainings({ RowId: e.rowId })
        .then((res) => {
          //console.log(res);
          globalSpinner.hideSpinner();
          if (res.data.isSuccess) {
            getTrainings({
              CandidateId: Number(candidateId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "string",
              SortOrder: "string",
              ShowInactive: false,
            });
            toast.success("Training detail has been removed.");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          //console.log(err);
          globalSpinner.hideSpinner();
          toast.error(err.toString());
        });
    } else {
      //console.log("no");
      //console.log(e, i);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-5">
        <span className="resp-arrow" />
        Trainings
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-5"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Trainings</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>

        <div>
          <a
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="btn add_btn"
            style={{ marginBottom: "10px" }}
          >
            Add Training
          </a>
        </div>

        {trainingState.data.map((e: any, i: any) => (
          <Training
            index={i}
            getTrainings={getTrainings}
            key={i}
            item={e}
            disabled={disabled}
            courses={courses}
            removeTraining={removeTraining}
            updateTraining={updateTraining}
            candidateId={candidateId}
            digiLockerType={digiLockerType}
            digiLockers={digiLockers}
          />
        ))}
        {/* <div className="col-sm-12">
          <a onClick={() => { setIsOpen(!isOpen) }} className="btn submit_btn">Add Training</a>
        </div> */}
      </div>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Add Training</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Training Certificate
              </label>
              <input
                type="text"
                name="trainingCertificate"
                className="form-control"
                placeholder="Type here"
                ref={register({
                  required: "Training certificate is required",
                })}
              />
              {/* <span className="select-wrapper-sec">
                <Controller
                  control={control}
                  name="trainingCertificate"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption values={courses} disabled={false} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                />
              </span> */}
              <ErrorMessage
                errors={errors}
                name="trainingCertificate"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Institute
              </label>
              <input
                type="text"
                name="institue"
                className="form-control"
                placeholder="Type here"
                ref={register({
                  required: "Institute is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="institue"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="clearfix" />
        </form>
      </Modal>
    </React.Fragment>
  );
}

export default Trainings;
