import React, { useState, useEffect } from "react";
import ResumePreviewComponent from "./ResumePreviewComponent";
import { useMyProfileContext } from "../../../../action/MyProfileAction";

import { getResumeCandidateMap } from "../../../../apis/resumebuilder";

interface IResumePreviewContainerProps {
  selectTemplateInfo: any;
}

//interface IResumePreviewContainerState { }

//const initialState = {};

const ResumePreviewContainer: React.FC<IResumePreviewContainerProps> = (
  props
) => {
  // const [resumePreviewContainerState, setResumePreviewContainerState] = React.useState<IResumePreviewContainerState>(
  //   initialState
  // );

  const myProfileContext = useMyProfileContext();
  const { loggedUserId } = myProfileContext;
  const candidateId = loggedUserId;
  const [ResumeTemplateData, setResumeTemplateData] = useState({});
  const [resumeTemplateID, setResumeTemplateID] = useState(0);
  const [resumeTemplateHtml, setResumeTemplateHtml] = useState(
    "template1.html"
  );

  useEffect(() => {
    if (candidateId && props.selectTemplateInfo.location.state != undefined) {
      //setresumeTemplateID(props.selectTemplateInfo.location.state.templateInfo.resumeTemplateId)
      ////console.log(props.selectTemplateInfo.location.state);
      setResumeTemplateID(
        props.selectTemplateInfo.location.state.templateInfo.rowId
      );
      setResumeTemplateHtml(
        props.selectTemplateInfo.location.state.templateInfo.resumeContent
      );
      // //console.log(resumeTemplateID);
      // //console.log(resumeTemplateHtml);
      getResumeCandidateMap({
        CandidateId: candidateId,
        Page: 0,
        PageSize: 0,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: true,
      })
        .then((res) => {
          ////console.log(res.data);
          setResumeTemplateData(res.data);
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [candidateId]);

  return (
    <>
      <ResumePreviewComponent
        resumeInfo={ResumeTemplateData}
        candidateId={candidateId}
        resumeTemplateID={resumeTemplateID}
        resumeTemplateHtml={resumeTemplateHtml}
      />
    </>
  );
};
export default ResumePreviewContainer;
