import React from "react";
import { Switch, Route, useRouteMatch, withRouter } from "react-router-dom";
import CandidateLayoutSidebar from "./CandidateLayoutSidebar";
import CandidateDashboardContainer from "../dashboard/CandidateDashoardContainer";
import CandidateDigilockerContainer from "../digilocker/CandidateDigilockerContainer";
import CandidateJobAppliedContainer from "../jobs_Applied/CandidateJobAppliedContainer";
import CandidateMessageContainer from "../message/CandidateMessageContainer";
import CandidateMyResumeContainer from "../my_Resumes/CandidateMyResumeContainer";
import CandidateSavedJobContainer from "../saved_Jobs/CandidateSavedJobContainer";
import CandidateProfileContainer from "./../my_profile/CandidateProfileContainer";
import CoverLetterLayoutContainer from "../cover_letter/layout/CoverLetterLayoutContainer";
import ResumeLayoutContainer from "../resume_template/layout/ResumeLayoutContainer";
import { JobCategoryContextProvider } from "../../../context/JobCategoryContext";
import { JobAppliedContextProvider } from "../../../context/JobAppliedContext";
import { MyProfileContextProvider } from "../../../context/MyProfileContext";
import { MatchedJobContextProvider } from "../../../context/MatchedJobContext";
import {
  CandidateExperienceContextProvider,
  CandidateRelativesContextProvider,
  CandidateSkillsContextProvider,
  TrainingContextProvider,
  BankDetailsContextProvider,
  EducationQualificationContextProvider,
  CandidateAchievementsContextProvider,
  CandidateLanguageMapsContextProvider,
  CandidateReferencesContextProvider,
  SocialAccountContextProvider,
  PassportInformationContextProvider,
  CandidateProfileImageContextProvider,
  CandidateOtherCertificateContextProvider,
  SeamanBookCdcContextProvider,
  DigiDocumentDetailsContextProvider,
  DigiDocumentTypeContextProvider,
} from "./../../../context/candidate/CandidateMyProfile";
import CandidateDashboardComponentTwo from "../dashboard/CandidateDashboardComponentTwo";
import { DigiLockerContextProvider } from "../../../context/candidate/DigiLockerContext";
import NotifyJobContainer from "../notifyJobs/NotifyJobContainer";

interface ICandidateLayoutComponentProps {}

interface ICandidateLayoutComponentState {}

const initialState = {};

const CandidateLayoutComponent: React.FC<ICandidateLayoutComponentProps> = (
  props
) => {
  const [
    CandidateLayoutComponentState,
    setCandidateLayoutComponentState,
  ] = React.useState<ICandidateLayoutComponentState>(initialState);

  const { path, url } = useRouteMatch();

  return (
    <div id="wrapper">
      {/* <CandidateProfileImageContextProvider> */}
      {/*======Left Menu Start=======*/}
      {/* <div className="icon-mobile-nav">
        <a >
          <i className="fa fa-2x fa-reorder" />
        </a>
      </div> */}
      {/* <CandidateProfileImageContextProvider> */}
      <CandidateLayoutSidebar />
      {/* </CandidateProfileImageContextProvider> */}

      {/*======Left Menu End=======*/}
      {/*=================Content Section Start================*/}
      <Switch>
        <Route exact path={`${path}`}>
          <MatchedJobContextProvider>
            <JobCategoryContextProvider>
              <CandidateDashboardContainer />
            </JobCategoryContextProvider>
          </MatchedJobContextProvider>
        </Route>
        <Route exact path={`${path}/digilocker/:id`}>
          {/* <DigiLockerContextProvider> */}
          <CandidateDigilockerContainer />
          {/* </DigiLockerContextProvider> */}
        </Route>

        <Route exact path={`${path}/jobs-applied`}>
          <CandidateJobAppliedContainer />
        </Route>
        <Route exact path={`${path}/messages`}>
          <CandidateMessageContainer />
        </Route>
        <Route exact path={`${path}/saved-jobs`}>
          <CandidateSavedJobContainer />
        </Route>
        <Route exact path={`${path}/notify-jobs/:id`}>
          <NotifyJobContainer />
        </Route>
        <Route exact path={`${path}/my-resume`}>
          <CandidateMyResumeContainer />
        </Route>
        <Route exact path={`${path}/my-profile`}>
          <MyProfileContextProvider>
            <CandidateExperienceContextProvider>
              <CandidateRelativesContextProvider>
                <CandidateSkillsContextProvider>
                  <TrainingContextProvider>
                    <BankDetailsContextProvider>
                      <EducationQualificationContextProvider>
                        <CandidateAchievementsContextProvider>
                          <CandidateLanguageMapsContextProvider>
                            <CandidateReferencesContextProvider>
                              <SocialAccountContextProvider>
                                <PassportInformationContextProvider>
                                  {/* <CandidateProfileImageContextProvider> */}
                                  <CandidateOtherCertificateContextProvider>
                                    <SeamanBookCdcContextProvider>
                                      <DigiDocumentDetailsContextProvider>
                                        <DigiDocumentTypeContextProvider>
                                          {/* <DigiLockerContextProvider> */}
                                          <CandidateProfileContainer />
                                          {/* </DigiLockerContextProvider> */}
                                        </DigiDocumentTypeContextProvider>
                                      </DigiDocumentDetailsContextProvider>
                                    </SeamanBookCdcContextProvider>
                                  </CandidateOtherCertificateContextProvider>
                                  {/* </CandidateProfileImageContextProvider> */}
                                </PassportInformationContextProvider>
                              </SocialAccountContextProvider>
                            </CandidateReferencesContextProvider>
                          </CandidateLanguageMapsContextProvider>
                        </CandidateAchievementsContextProvider>
                      </EducationQualificationContextProvider>
                    </BankDetailsContextProvider>
                  </TrainingContextProvider>
                </CandidateSkillsContextProvider>
              </CandidateRelativesContextProvider>
            </CandidateExperienceContextProvider>
          </MyProfileContextProvider>
        </Route>

        <Route path={`${path}/my-resume/cover-letter`}>
          <CoverLetterLayoutContainer />
        </Route>
        <Route path={`${path}/my-resume/resume`}>
          <ResumeLayoutContainer />
        </Route>
      </Switch>

      {/* </CandidateProfileImageContextProvider> */}
    </div>
  );
};
export default withRouter(CandidateLayoutComponent);
