import { apiClient } from "./../utils/httpClient";

export function getVendorId(data?: any) {
  return apiClient(`/api/Vendor/gv/${data}`, {}, "get");
}

export function getVendors(data?: any) {
  return apiClient("/api/Vendor/gaaa", data);
}

export function getVendor(data?: any) {
  return apiClient("/api/Vendor/ga", data);
}

export function saveVendor(data?: any) {
  //console.log('-----------------------formdata------------------', data);
  return apiClient("/api/Vendor/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getSelectBoxData(data?: any) {
  return apiClient("/api/Vendor/get-selectbox-data", data);
}

export function getProfileProgress(data?: any) {
  return apiClient("/api/Vendor/gscp", data);
}

export function getDocumentStatus(data?: any) {
  return apiClient("/api/Vendor/uploaded-document-status", data);
}

export function getSocialAccounts(data?: any) {
  return apiClient("/api/VendorSocialAccounts/gaaa", data);
}

export function deleteSocialAccounts(data?: any) {
  return apiClient("/api/VendorSocialAccounts/da", data);
}

export function updateSocialAccounts(data?: any) {
  return apiClient("/api/VendorSocialAccounts/coea", data);
}

export function saveSocialAccounts(data?: any) {
  return apiClient("/api/VendorSocialAccounts/coea", data);
}

export function getVendorProfileImage(data?: any) {
  return apiClient("/api/VendorProfileImage/gaaa", data);
}

export function saveVendorProfileImage(data?: any) {
  return apiClient("/api/VendorProfileImage/coea", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getVendorList(data?: any) {
  return apiClient("/api/Vendor/gaoa", data);
}

export function deleteVendorDocument(data?: any) {
  return apiClient("/api/Vendor/delete-doc", data);
}

export function getUserList(data?: any) {
  return apiClient("/api/Vendor/gul", data);
}

export function getMessageList(data?: any) {
  return apiClient("/api/ChatDetails/gaaa", data);
}

export function getNotificationList(data?: any) {
  return apiClient("/api/vendor/getNotificatinsAsync", data);
}

export function sendChatFiles(data?: any) {
  return apiClient("/api/FileUpload/files", data, "post", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateReadStatus(data?: any) {
  return apiClient("/api/ChatDetails/chat-read", data);
}

export function getMessageDashboardList(data?: any) {
  return apiClient("/api/Vendor/gml", data);
}

export function deleteVendorProfileImage(data?: any) {
  return apiClient("/api/VendorProfileImage/da", data);
}
