import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  editCareerTips,
  getCareerTips,
  getCareerTipsSelectBoxList,
  useCareerTipsApprovalContext,
  useCareerTipsApprovalDispatcher,
} from "../../action/admin/CareerTipsApprovalAction";
import { CareerTipsEditRequestModel } from "../../models/admin/CareerTipsApproval";
import FileUploads from "../candidate/my_profile/components/FileUploads";
import SelectOption from "../candidate/my_profile/components/SelectOption";
import { CareerTipsGetDataWithId } from "../../models/admin/CareerTipsApproval";
import { saveCareerTips } from "../../apis/admin";
import _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../context/GlobalSpinner";
import DragAndDrop from "../candidate/my_profile/components/DragAndDrop";

interface ICareerPostProps {
  isCareerTipsOpen: boolean;
  handleCareerTipsOpen: () => void;
  currentCareerTipsId: number;
  loggedUserId: number;
  authorizationToken: string | null;
}
interface ReactSelectOption {
  value: string;
  label: string;
}
const defaultValues = {
  selectResume: "",
  coverLetter: "",
  careerTipsCategoryId: 0,
};

const CareerPost: React.FC<ICareerPostProps> = (props) => {
  const {
    handleCareerTipsOpen,
    isCareerTipsOpen,
    currentCareerTipsId,
    loggedUserId,
    authorizationToken,
  } = props;

  const [obj, setObj] = useState<any>({ file: null });
  const [obj2, setObj2] = useState<any>({ file: null });
  const [disable, setDisable] = useState<number>(0);
  const [disable2, setDisable2] = useState<number>(0);
  const [disable3, setDisable3] = useState<string>("");
  const [disable4, setDisable4] = useState<string>("");

  const careerTipsApprovalDispatcher = useCareerTipsApprovalDispatcher();
  const careerTipsApprovalContext = useCareerTipsApprovalContext();
  const {
    CareerTipsResultModel,
    CareerTipsSelectBoxDataViewModel,
  } = careerTipsApprovalContext;
  const globalSpinner = useContext(GlobalSpinnerContext);

  const [sizeValidate, setSizeValidate] = useState<string>("");

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  let careerTipsCategories: ReactSelectOption[] = [];
  if (!_.isEmpty(CareerTipsSelectBoxDataViewModel)) {
    careerTipsCategories = CareerTipsSelectBoxDataViewModel.careerTipsCategories.map(
      (e: any) => {
        return { value: e["value"], label: e["caption"] };
      }
    );
  }

  const handleDrop = (files: any) => {
    if (
      ["image/jpeg", "image/jpg", "image/png"].includes(files[0].type) === false
    ) {
      setSizeValidate("Acceptable file formats: JPEG,JPG,PNG");
      return false;
    }

    if (
      ["image/jpeg", "image/jpg", "image/png"].includes(files[0].type) &&
      files[0].size < 1000000 * 2
    ) {
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
      setSizeValidate("");
    } else {
      setSizeValidate("Image size can't be grater than 2MB");
    }
  };

  React.useEffect(() => {
    if (loggedUserId != 0 && authorizationToken != null) {
      (async () => {
        await getCareerTips(
          careerTipsApprovalDispatcher,
          {
            rowId: currentCareerTipsId,
          } as CareerTipsGetDataWithId,
          authorizationToken
        );
      })();
    }
  }, [currentCareerTipsId]);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getCareerTipsSelectBoxList(
          careerTipsApprovalDispatcher
          //authorizationToken
        );
      })();
    }
  }, []);

  React.useEffect(() => {
    //console.log("----CareerTipsResultModel----", CareerTipsResultModel);
    if (CareerTipsResultModel.data != null) {
      setValue3("rowId", CareerTipsResultModel.data["rowId"]);
      setValue3("careerTipsId", CareerTipsResultModel.data["rowId"]);
      setValue3("name", CareerTipsResultModel.data["name"]);
      setValue3("email", CareerTipsResultModel.data["email"]);
      setValue3("contact", CareerTipsResultModel.data["contact"]);
      setValue3(
        "careerTipsCategoryId",
        Number(CareerTipsResultModel.data["careerTipsCategoryId"])
      );
      setValue3(
        "careerTipsDocument",
        CareerTipsResultModel.data["careerTipsDocument"]
      );
      setValue3("description", CareerTipsResultModel.data["description"]);
      setValue3("isActive", CareerTipsResultModel.data["isActive"]);
    }
  }, [CareerTipsResultModel.data, currentCareerTipsId]);

  //console.log("CareerTipsResultModel", CareerTipsResultModel);
  //console.log("loggedUserId", loggedUserId);

  const handleCareerTipsApproval = (data: any) => {
    //console.log(33, data);
    if (1) {
      globalSpinner.showSpinner();
      const formData = new FormData();
      formData.append("RowId", String(currentCareerTipsId));
      formData.append("Name", String(data["name"]));
      formData.append("Email", String(data["email"]));
      formData.append("Contact", String(data["contact"]));
      formData.append(
        "CareerTipsCategoryId",
        String(data["careerTipsCategoryId"])
      );
      //formData.append("CareerTipsDocument", String(null));
      formData.append("Document", obj.file);
      if (CareerTipsResultModel.data != null) {
        formData.append(
          "CareerTipsDocument",
          String(CareerTipsResultModel.data["careerTipsDocument"])
        );
      }
      formData.append("Description", String(data["description"]));
      formData.append("IsActive", JSON.stringify(true));
      saveCareerTips(formData).then((res: any) => {
        //console.log(res);
        if (res.data.isSuccess) {
          globalSpinner.hideSpinner();
          toast.success("Career tips saved successfully.");
        } else {
          globalSpinner.hideSpinner();
          toast.error("Error:Career tips not saved or updated.");
        }
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setDisable(0);
    setDisable2(0);
    setDisable3("");
    handleCareerTipsOpen();
  };

  return (
    <>
      <ToastContainer />
      <div className="content-wrapper">
        <div className="container-fluid">
          <Modal show={isCareerTipsOpen} onHide={handleClose}>
            <form onSubmit={handleSubmit3(handleCareerTipsApproval)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title>Post a Career Tip</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label className={"required"} htmlFor="email">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter Your Name"
                    ref={register3({
                      required: "Name is required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors3}
                    name="name"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
                <div className="form-group">
                  <label className={"required"} htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    ref={register3({
                      required: "Email is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Should be a valid email",
                      },
                    })}
                    className="form-control"
                    placeholder="Enter Your Email"
                  />
                  <ErrorMessage
                    errors={errors3}
                    name="email"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
                <div className="form-group">
                  <label className={"required"} htmlFor="email">
                    Contact
                  </label>
                  <input
                    type="text"
                    name="contact"
                    ref={register3({
                      required: "Contact is required",
                    })}
                    className="form-control"
                    placeholder="Enter Your Contact"
                  />
                  <ErrorMessage
                    errors={errors3}
                    name="contact"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
                <div className="form-group">
                  <label className={"required"} htmlFor="email">
                    Category
                  </label>
                  <span className="select-wrapper-sec">
                    <Controller
                      rules={{
                        required: "Category is required",
                        validate: function (value) {
                          //console.log(value);
                          return Number(value)
                            ? undefined
                            : "Category is required";
                        },
                      }}
                      control={control3}
                      name="careerTipsCategoryId"
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          disabled={false}
                          values={careerTipsCategories}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                  </span>
                  <ErrorMessage
                    errors={errors3}
                    name="careerTipsCategoryId"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
                <div className="clearfix"></div>
                <div className="form-group">
                  <label className={"required"} htmlFor="email">
                    Description
                  </label>
                  <textarea
                    name="description"
                    className={"form-control form-contro11"}
                    placeholder="Type here"
                    ref={register3({
                      required: "Career tips description cannot be blank.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors3}
                    name="description"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
                <div className="form-group">
                  <FileUploads
                    accept=".jpg,.jpeg,.png"
                    onChange={(file: any) => {
                      handleDrop([file]);
                    }}
                    name="Upload Career Tips"
                    disabled={disable3 !== "" ? true : false}
                  />
                  <DragAndDrop handleDrop={handleDrop}>
                    {obj.file ? (
                      <div
                        className="update_con"
                        style={{ width: 268, height: 105 }}
                      >
                        {obj.file.name}
                      </div>
                    ) : (
                      <React.Fragment>
                        {/* <div className="update_con">
                  Drag and drop your file here{" "}
                </div> */}
                        <div className="update_con">
                          Acceptable file formats: JPEG,JPG,PNG
                        </div>
                        <div className="update_con">
                          Maximum file size: 2 MB.{" "}
                        </div>
                      </React.Fragment>
                    )}
                  </DragAndDrop>
                  <h6 style={{ color: "red", marginLeft: 225 }}>
                    {sizeValidate}
                  </h6>
                </div>
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default CareerPost;
