import { Dispatch, Reducer } from "react";

import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";

import { AppUrls } from "../../environments/environment";
import {
  CreateAdminRequestModel,
  CreateAdminRespondModel,
  GetAllPermissionRequestModel,
  GetAllPermissionResultModel,
  SuperAdminRequestModelById,
  SuperAdminRespondModel,
  SuperAdminRespondModelById,
  SuperAdminUserDeleteRequestModel,
  SuperAdminUserDeleteResultModel,
  SuperAdminUserRequestModel,
  SuperAdminUserRespondModel,
} from "../../models/superadmin/SuperAdminUsers";
import {
  ISuperAdminUserManagementState,
  SuperAdminUserDispatcherContext,
  SuperAdminUserStateContext,
} from "../../context/superadmin/SuperAdminUserContext";
import { message } from "antd";
let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type ISuperAdminUserAction =
  | {
      type: "LEST_ALL_SUPER_ADMIN_USER";
      superAdminUsersList: SuperAdminUserRespondModel;
    }
  | {
      type: "SAVE_SUPER_ADMIN_USER";
      saveAdminRespondModel: CreateAdminRespondModel;
    }
  | {
      type: "GET_SUPER_ADMIN_USER_BY_ID";
      superAdminUser: SuperAdminRespondModel;
    }
  | {
      type: "GET_ALL_PERMISSION";
      getAllPermission: GetAllPermissionResultModel;
    }
  | {
      type: "DELETE_ALL_SUPER_ADMIN_USER";
      deleteSuperAdminUserResponse: SuperAdminUserDeleteResultModel;
    };

export const superAdminUserReducer: Reducer<
  ISuperAdminUserManagementState,
  ISuperAdminUserAction
> = (draft, action): ISuperAdminUserManagementState => {
  switch (action.type) {
    case "LEST_ALL_SUPER_ADMIN_USER":
      draft.superAdminUsersList = action.superAdminUsersList;
      return draft;

    case "SAVE_SUPER_ADMIN_USER":
      draft.saveAdminRespondModel = action.saveAdminRespondModel;
      return draft;

    case "GET_SUPER_ADMIN_USER_BY_ID":
      draft.superAdminUser = action.superAdminUser;
      return draft;

    case "GET_ALL_PERMISSION":
      draft.getAllPermission = action.getAllPermission;
      return draft;
    case "DELETE_ALL_SUPER_ADMIN_USER":
      draft.deleteSuperAdminUserResponse = action.deleteSuperAdminUserResponse;
      return draft;
  }
};

export const useSuperAdminUserDispatcher = (): React.Dispatch<ISuperAdminUserAction> => {
  const superAdminUserDispatcher = React.useContext(
    SuperAdminUserDispatcherContext
  );
  if (!superAdminUserDispatcher) {
    throw new Error(
      "You have to provide the SuperAdminUser dispatcher using theSuperAdminUserDispatcherContext.Provider in a parent component."
    );
  }
  return superAdminUserDispatcher;
};

export const useSuperAdminUserContext = (): ISuperAdminUserManagementState => {
  const superAdminUserContext = React.useContext(SuperAdminUserStateContext);
  if (!superAdminUserContext)
    throw new Error(
      "You have to provide the superAdminUser context using the SuperAdminUserStateContext.Provider in a parent component."
    );
  return superAdminUserContext;
};

export const getSuperAdminUserList = async (
  dispatcher: React.Dispatch<ISuperAdminUserAction>,
  query: SuperAdminUserRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllSuperAdminUser;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "LEST_ALL_SUPER_ADMIN_USER",
        superAdminUsersList: res.data,
      });
    });
  } catch (e) {}
};

export const getSuperAdminUserById = async (
  dispatcher: React.Dispatch<ISuperAdminUserAction>,
  query: SuperAdminRequestModelById,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetSuperAdminUser;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_SUPER_ADMIN_USER_BY_ID",
        superAdminUser: res.data,
      });
    });
  } catch (e) {}
};

export const saveSuperAdminUser = async (
  dispatcher: React.Dispatch<ISuperAdminUserAction>,
  query: CreateAdminRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveSuperAdminUser;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "SAVE_SUPER_ADMIN_USER",
        saveAdminRespondModel: res.data,
      });
      res.data.isSuccess
        ? message.success("Save sucessfully")
        : message.error(res.data.message);
    });
  } catch (e) {}
};

export const getAllAdminUserPermission = async (
  dispatcher: React.Dispatch<ISuperAdminUserAction>,
  query: GetAllPermissionRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllPermision;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "GET_ALL_PERMISSION", getAllPermission: res.data });

      //console.log(869990, res.data);
    });
  } catch (e) {}
};

export const deleteSuperAdminUser = async (
  dispatcher: React.Dispatch<ISuperAdminUserAction>,
  query: SuperAdminUserDeleteRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteSuperAdminUser;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "DELETE_ALL_SUPER_ADMIN_USER",
        deleteSuperAdminUserResponse: res.data,
      });
    });
  } catch (e) {}
};
