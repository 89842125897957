import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { MyTeamMemberViewModel, 
         MyTeamMemberSaveRespondModel, 
         MyTeamMemberResultModel,
         MyTeamMemberSelectBoxDataViewModel,
        MyTeamMemberDeleteResultModel} from "../../models/admin/MyTeamMember";
import { IMyTeamMemberAction, MyTeamMemberReducer } from "../../action/admin/MyTeamMemberAction";



export const MyTeamMemberDispatcherContext = React.createContext<React.Dispatch<IMyTeamMemberAction> | null>(null);
export const MyTeamMemberStateContext = React.createContext<IMyTeamMemberManagementState | null>(null);

export interface IMyTeamMemberManagementProps extends RouteComponentProps<any> { }

export interface IMyTeamMemberManagementState {
    visible:boolean;
    value:number;
    MyTeamMember:MyTeamMemberViewModel,
    MyTeamMemberResultModel:MyTeamMemberResultModel,
    MyTeamMemberSaveRespond:MyTeamMemberSaveRespondModel,
    MyTeamMemberSelectBoxDataViewModel:MyTeamMemberSelectBoxDataViewModel,
    MyTeamMemberDeleteResultModel:MyTeamMemberDeleteResultModel;
}

export const initialMyTeamMemberManagementState = {
    visible:false,
    value:0,
    MyTeamMember:{} as MyTeamMemberViewModel,
    MyTeamMemberResultModel:{} as MyTeamMemberResultModel,
    MyTeamMemberSaveRespond:{} as MyTeamMemberSaveRespondModel,
    MyTeamMemberSelectBoxDataViewModel:{} as MyTeamMemberSelectBoxDataViewModel,
    MyTeamMemberDeleteResultModel:{} as MyTeamMemberDeleteResultModel
} as IMyTeamMemberManagementState;

export const MyTeamMemberContextProvider: React.FC = ({ children }) => {
    const [MyTeamMemberState, dispatcher] = useImmerReducer(MyTeamMemberReducer, initialMyTeamMemberManagementState);

    return (
        <MyTeamMemberDispatcherContext.Provider value={dispatcher}>
            <MyTeamMemberStateContext.Provider value={MyTeamMemberState}>
                {children}
            </MyTeamMemberStateContext.Provider>
        </MyTeamMemberDispatcherContext.Provider>
    )
}