import { Reducer } from "react";

import React from "react";

import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls, environment } from "../../environments/environment";

import {
  IDigiLockerManagementState,
  DigiLockerDispatcherContext,
  DigiLockerStateContext,
} from "../../context/candidate/DigiLockerContext";
import {
  digidocDownloadRequest,
  digidocDownloadResult,
  digiLockerDetails,
  digiLockerDetailsResultModel,
  digiLockerDetailsViewModel,
  digiLockerDocumentMoveRequestModel,
  digiLockerDocumentMoveResultmodel,
  digiLockerDocumentRequestModel,
  digiLockerDocumentSaveRequestModel,
  digiLockerDocumentSaveRespondModel,
  digiLockerExpiringDocumentRequestModel,
  digiLockerExpiringDocumentViewModel,
  digiLockerGetDataWithId,
  digiLockerTypeRequestModel,
  digiLockerTypeViewModel,
} from "../../models/candidate/DigiLocker";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IDigiLockerAction =
  | {
      type: "GET_DIGILOCKER_DOCUMENT_LIST";
      digiLockers: digiLockerDetailsViewModel;
    }
  | {
      type: "SAVE_DIGILOCKER_DOCUMENT_LIST";
      digiLockerSaveRespond: digiLockerDocumentSaveRespondModel;
    }
  | {
      type: "GET_DIGILOCKER_TYPE_LIST";
      digiLockerType: digiLockerTypeViewModel;
    }
  | {
      type: "GET_DIGILOCKER_EXPIRING_DOCUMENT_LIST";
      digiLockerExpiringDocument: digiLockerExpiringDocumentViewModel;
    }
  | {
      type: "GET_DIGILOCKER";
      digiLockerDetails: digiLockerDetailsResultModel;
    }
  | {
      type: "DELETE_DIGILOCKER_DOCUMENT";
      digiLockerDeletRespond: digiLockerDocumentSaveRespondModel;
    }
  | {
      type: "DOCUMENT_MOVE";
      documentMoveResultModel: digiLockerDocumentMoveResultmodel;
    }
  | {
      type: "DOCUMENT_SHARED";
      documentSharedResult: string;
    }
  | {
      type: "SAVE_DIGILOCKER_DOCUMENT_LIST_FILE";
      digiLockerSaveRespondOne: digiLockerDocumentSaveRespondModel;
    }
  | {
      type: "GET_DIGILOCKER_DOCUMENT_LIST_NEW";
      digiLockersNewList: digiLockerDetailsViewModel;
    }
  | {
      type: "CLOSE_DIGILOCK_MODAL";
      digiDocumentExpModal: boolean;
    };

export const digiLockerReducer: Reducer<
  IDigiLockerManagementState,
  IDigiLockerAction
> = (draft, action): IDigiLockerManagementState => {
  switch (action.type) {
    case "GET_DIGILOCKER_DOCUMENT_LIST":
      draft.digiLockers = action.digiLockers;
      return draft;
    case "SAVE_DIGILOCKER_DOCUMENT_LIST":
      draft.digiLockerSaveRespond = action.digiLockerSaveRespond;
      return draft;
    case "SAVE_DIGILOCKER_DOCUMENT_LIST_FILE":
      draft.digiLockerSaveRespondOne = action.digiLockerSaveRespondOne;
      return draft;
    case "GET_DIGILOCKER_TYPE_LIST":
      draft.digiLockerType = action.digiLockerType;
      return draft;
    case "GET_DIGILOCKER_EXPIRING_DOCUMENT_LIST":
      draft.digiLockerExpiringDocument = action.digiLockerExpiringDocument;
      return draft;
    case "GET_DIGILOCKER":
      draft.digiLockerDetails = action.digiLockerDetails;
      return draft;
    case "DELETE_DIGILOCKER_DOCUMENT":
      draft.digiLockerDeletRespond = action.digiLockerDeletRespond;
      return draft;
    case "DOCUMENT_MOVE":
      draft.documentMoveResultModel = action.documentMoveResultModel;
      return draft;
    case "DOCUMENT_SHARED":
      draft.documentSharedResult = action.documentSharedResult;
      return draft;
    case "GET_DIGILOCKER_DOCUMENT_LIST_NEW":
      draft.digiLockersNewList = action.digiLockersNewList;
      return draft;
    case "CLOSE_DIGILOCK_MODAL":
      draft.digiDocumentExpModal = action.digiDocumentExpModal;
      return draft;
  }
};

export const useDigiLockerDispatcher = (): React.Dispatch<IDigiLockerAction> => {
  const digiLockerDispatcher = React.useContext(DigiLockerDispatcherContext);
  if (!digiLockerDispatcher) {
    throw new Error(
      "You have to provide the DigiLocker dispatcher using theDigiLockerDispatcherContext.Provider in a parent component."
    );
  }
  return digiLockerDispatcher;
};

export const useDigiLockerContext = (): IDigiLockerManagementState => {
  const digiLockerContext = React.useContext(DigiLockerStateContext);
  if (!digiLockerContext)
    throw new Error(
      "You have to provide the digiLocker context using the DigiLockerStateContext.Provider in a parent component."
    );
  return digiLockerContext;
};

export const getDigiLockerList = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: digiLockerDocumentRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetDigiLockerDocumentList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      //console.log(66, res);
      dispatcher({
        type: "GET_DIGILOCKER_DOCUMENT_LIST",
        digiLockers: res.data,
      });
    });
  } catch (e) {}
};

export const getDigiLockerNewList = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: digiLockerDocumentRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetDigiLockerDocumentList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      //console.log(66, res);
      dispatcher({
        type: "GET_DIGILOCKER_DOCUMENT_LIST_NEW",
        digiLockersNewList: res.data,
      });
    });
  } catch (e) {}
};

export const saveDigiLockerList = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: digiLockerDocumentSaveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.SaveDigiLockerList;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      //console.log(34, res);
      dispatcher({
        type: "SAVE_DIGILOCKER_DOCUMENT_LIST",
        digiLockerSaveRespond: res.data,
      });
      res.data.isSuccess
        ? message.success("Document is added")
        : message.error(res.data.message);
    });
  } catch (e) {}
};

export const getDigiLockerDocumentTypeList = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: digiLockerTypeRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetDigiLockerDocumentTypeList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_DIGILOCKER_TYPE_LIST",
        digiLockerType: res.data,
      });
    });
  } catch (e) {}
};

export const getDigiLockerExpiringDocumentList = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: digiLockerExpiringDocumentRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetDigiLockerExpiringDocumentList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_DIGILOCKER_EXPIRING_DOCUMENT_LIST",
        digiLockerExpiringDocument: res.data,
      });
    });
  } catch (e) {}
};

export const getDigiLocker = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: digiLockerGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetDigiLocker;
    if (query.rowId != 0) {
      axios
        .post(url, JSON.stringify(query), { headers: header })
        .then((res) => {
          dispatcher({
            type: "GET_DIGILOCKER",
            digiLockerDetails: res.data,
          });
        });
    } else {
      dispatcher({
        type: "GET_DIGILOCKER",
        digiLockerDetails: {} as digiLockerDetailsResultModel,
      });
    }
  } catch (e) {}
};

export const deleteDigiLocker = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: digiLockerGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteDigiLocker;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "DELETE_DIGILOCKER_DOCUMENT",
        digiLockerDeletRespond: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Document is deleted")
          : message.error(res.data.message)
        : message.error("error occured");
    });
  } catch (e) {}
};

export const postFile = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: any,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = environment.baseUrl + "/api/DigiDocumentUpload/coea";
    message.loading("please wait file is uploading...");
    axios.post(url, query, { headers: header }).then((res) => {
      dispatcher({
        type: "SAVE_DIGILOCKER_DOCUMENT_LIST_FILE",
        digiLockerSaveRespondOne: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Successfully Uploaded")
          : message.error(res.data.message)
        : message.error("error occured");
    });
  } catch (e) {}
};

export const documentMove = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: digiLockerDocumentMoveRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ToMoveDocument;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "DOCUMENT_MOVE",
        documentMoveResultModel: res.data,
      });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("successfully moved")
          : message.error(res.data.message)
        : message.error("error occured");
    });
  } catch (e) {}
};

export const shareDigiLocker = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: digidocDownloadRequest,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.ShareDigiLocker + `/${query}`;

    axios.get(url).then((res) => {
      //console.log(8888, res);
      dispatcher({
        type: "DOCUMENT_SHARED",
        documentSharedResult: res.data,
      });
    });
  } catch (e) {}
};

export const closeDigiLockerModal = async (
  dispatcher: React.Dispatch<IDigiLockerAction>,
  query: boolean
) => {
  dispatcher({
    type: "CLOSE_DIGILOCK_MODAL",
    digiDocumentExpModal: query,
  });
};
