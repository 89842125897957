import React, { useReducer, createContext, useContext } from "react";
import * as API from "../../apis/admin";

const initialState2 = {
  data: [],
  loading: false,
  error: null,
};

export const AdminContext = createContext({
  adminState: initialState2,
  getAdmins: (data?: any) => {},
});

const reducer2 = (adminState: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState2, loading: true };
    case "FETCHED":
      return { ...initialState2, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState2, loading: false, error: action.payload };
    default:
      return adminState;
  }
};

export const AdminContextProvider = (props: any) => {
  const [adminState, dispatch] = useReducer(reducer2, initialState2);
  const getAdmins = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getAdmins(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <AdminContext.Provider
      value={{
        adminState,
        getAdmins,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => {
  const adminContext = useContext(AdminContext);
  return adminContext;
};

const initialState13 = {
  data: [],
  loading: false,
  error: null,
};

export const AdminProfileImageContext = createContext({
  adminProfileImage: initialState13,
  getAdminProfileImage: (data?: any) => {},
});

const reducer13 = (adminProfileImage: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState13, loading: true };
    case "FETCHED":
      return { ...initialState13, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState13, loading: false, error: action.payload };
    default:
      return adminProfileImage;
  }
};

export const AdminProfileImageContextProvider = (props: any) => {
  const [adminProfileImage, dispatch] = useReducer(
    reducer13,
    initialState13
  );

  const getAdminProfileImage = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getAdminProfileImage(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <AdminProfileImageContext.Provider
      value={{
        adminProfileImage,
        getAdminProfileImage,
      }}
    >
      {props.children}
    </AdminProfileImageContext.Provider>
  );
};

export const useAdminProfileImageContext = () => {
  const adminProfileImageContext = useContext(
    AdminProfileImageContext
  );
  return adminProfileImageContext;
};

const initialState14 = {
  data: [],
  loading: false,
  error: null,
};

export const AdminListContext = createContext({
  adminList: initialState14,
  getAdminList: (data?: any) => {},
});

const reducer14 = (adminList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState14, loading: true };
    case "FETCHED":
      return { ...initialState14, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState14, loading: false, error: action.payload };
    default:
      return adminList;
  }
};

export const AdminListContextProvider = (props: any) => {
  const [adminList, dispatch] = useReducer(reducer14, initialState14);

  const getAdminList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getAdminList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <AdminListContext.Provider
      value={{
        adminList,
        getAdminList,
      }}
    >
      {props.children}
    </AdminListContext.Provider>
  );
};

export const useAdminListContext = () => {
  const adminListContext = useContext(AdminListContext);
  return adminListContext;
};

const initialState15 = {
  data: [],
  loading: false,
  error: null,
};

export const UserListContext = createContext({
  userList: initialState15,
  getUserList: (data?: any) => {},
});

const reducer15 = (userList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState15, loading: true };
    case "FETCHED":
      return { ...initialState15, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState15, loading: false, error: action.payload };
    default:
      return userList;
  }
};

export const UserListContextProvider = (props: any) => {
  const [userList, dispatch] = useReducer(reducer15, initialState15);

  const getUserList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getUserList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <UserListContext.Provider
      value={{
        userList,
        getUserList,
      }}
    >
      {props.children}
    </UserListContext.Provider>
  );
};

export const useUserListContext = () => {
  const userListContext = useContext(UserListContext);
  return userListContext;
};

const initialState16 = {
  data: [],
  loading: false,
  error: null,
};

export const MessageListContext = createContext({
  messageList: initialState16,
  getMessageList: (data?: any) => {},
});

const reducer16 = (messageList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState16, loading: true };
    case "FETCHED":
      return { ...initialState16, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState16, loading: false, error: action.payload };
    default:
      return messageList;
  }
};

export const MessageListContextProvider = (props: any) => {
  const [messageList, dispatch] = useReducer(reducer16, initialState16);

  const getMessageList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getMessageList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <MessageListContext.Provider
      value={{
        messageList,
        getMessageList,
      }}
    >
      {props.children}
    </MessageListContext.Provider>
  );
};

export const useMessageListContext = () => {
  const messageListContext = useContext(MessageListContext);
  return messageListContext;
};

const initialState17 = {
  data: [],
  loading: false,
  error: null,
};

export const NotificationListContext = createContext({
  notificationList: initialState17,
  getNotificationList: (data?: any) => {},
});

const reducer17 = (notificationList: any, action: any) => {
  switch (action.type) {
    case "FETCHING":
      return { ...initialState17, loading: true };
    case "FETCHED":
      return { ...initialState17, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initialState17, loading: false, error: action.payload };
    default:
      return notificationList;
  }
};

export const NotificationListContextProvider = (props: any) => {
  const [notificationList, dispatch] = useReducer(reducer17, initialState17);

  const getNotificationList = (data?: any) => {
    dispatch({ type: "FETCHING" });
    API.getNotificationList(data)
      .then((res: any) => {
        dispatch({ type: "FETCHED", payload: res.data });
      })
      .catch((err: any) => {
        dispatch({ type: "FETCH_ERROR", payload: err });
      });
  };

  return (
    <NotificationListContext.Provider
      value={{
        notificationList,
        getNotificationList,
      }}
    >
      {props.children}
    </NotificationListContext.Provider>
  );
};

export const useNotificationListContext = () => {
  const notificationListContext = useContext(NotificationListContext);
  return notificationListContext;
};
