import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Link, useParams, withRouter } from "react-router-dom";
import {
  AdminJobAppliedStatusMovie,
  GetAdminCandidateDetails,
  GetAdminVendorCandidateDetails,
  getJobApplicationViewJobList,
  useAdminJobApplicationContext,
  useAdminJobApplicationDispatcher,
} from "../../../action/admin/AdminJobApplicationAction";
import { useAdminMyJobContext } from "../../../action/admin/AdminMyJobAction";
import {
  AdminMoveJobStatusRequestModel,
  GetAdminCandidateRequestModel,
  GetAdminVendorCandidateRequestModel,
  JobApplicationViewJobRequestModel,
} from "../../../models/admin/AdminJobApplication";
import AuthService from "../../../services/AuthService";
import InterviewShedule from "./InterviewShedule";
import BulkSMS from "./BulkSMS";
import BulkEmail from "./BulkEmail";
import CandidateChat from "./CandidateChat";
import JobApplicationProfileContainer from "./JobApplicationProfileContainer";
import { useAdminMyProfileContext } from "../../../action/MyProfileAdminAction";
import { saveAdminActions } from "../../../apis/admin";
import { environment } from "../../../environments/environment";
import { Popover } from "antd";
import { useGlobalSpinnerContext } from "../../../context/GlobalSpinner";
import { useAdminContext } from "../../../context/admin/AdminMyProfile";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

interface ReactSelectOption {
  value: string;
  label: string;
}

type objectIndexing = {
  [key: string]: any;
};

const defaultValues = {};

const JobApplicationComponent = (props: any) => {
  let experienceData: ReactSelectOption[] = [];
  const authorizationToken = AuthService.accessToken;
  const userId1 = Number(AuthService.currentUser?.id);

  // const jobStatusSelect = [
  //   { value: 1, label: "Active" },
  //   { value: 2, label: "InActive" },
  // ];
  const adminMyJobContext = useAdminMyJobContext();
  const { adminEditRespond } = adminMyJobContext;

  const adminJobApplicationDispatcher = useAdminJobApplicationDispatcher();
  const adminJobApplicationContext = useAdminJobApplicationContext();
  const {
    JobApplicationViewJobResultModel,
    AdminVendorCandidateResult,
    AdminCandidateResult,
    AdminMoveJobStatusResult,
  } = adminJobApplicationContext;

  const myProfileAdminContext = useAdminMyProfileContext();
  const {
    //myProfile,
    basicInfo,
    loggedAdminId,
  } = myProfileAdminContext;

  const adminContext = useAdminContext();
  const { adminState } = adminContext;

  let { id, candidateDefaultId, vendorDefaultId } = useParams<{
    id: any;
    candidateDefaultId: any;
    vendorDefaultId: any;
  }>();
  const globalSpinner = useGlobalSpinnerContext();
  // const [userId, setUserId] = useState<number>(userId1);
  const [userId, setUserId] = useState<number>(
    parseInt(candidateDefaultId) <= parseInt(vendorDefaultId) ? 1 : 2
  );
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isRemovedAll, setIsRemovedCheckedAll] = useState(false);
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [isChecked, setIsChecked] = useState(false);
  const [isRenderCheckedList, setIsRenderCheckedList] = useState(true);
  const [initialUserId, setInitialUserId] = useState<number>(0);
  const [JobStatusId, setJobStatusId] = useState<number>(1);
  const [JobStatusFilterId, setJobStatusFilterId] = useState<number>(
    parseInt(candidateDefaultId) <= parseInt(vendorDefaultId)
      ? parseInt(candidateDefaultId)
      : parseInt(vendorDefaultId)
  );
  const [JobStatusName, setJobStatusName] = useState<string>("Move To");
  const [JobStatusFilterName, setJobStatusFilterName] = useState<string>(
    "Applied"
  );

  const [isCheckedAllTwo, setIsCheckedAllTwo] = useState(false);
  const [isRemovedAllTwo, setIsRemovedCheckedAllTwo] = useState(false);
  const [checkedListArrayTwo, setCheckedListArrayTwo] = useState([] as any[]);
  const [isCheckedTwo, setIsCheckedTwo] = useState(false);
  const [isRenderCheckedListTwo, setIsRenderCheckedListTwo] = useState(true);
  const [displayMode, setDisplayMode] = useState<number>(0);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [exicuteCount, setExicuteCount] = useState<number>(0);
  const [showChat, setShowChat] = useState(false);
  const [isOpenVideo2, setIsOpenVideo2] = useState<boolean>(false);
  const [videoName, setVideoName] = useState<string>("");
  // const [defaultFilter, setDefaultFilter] = useState({
  //   candidateDefaultId: 0,
  //   vendorDefaultId: 0,
  // });
  let IdArray = [] as any[];
  let IdArrayTwo = [] as any[];

  //console.log("$$##", displayMode);

  console.log(
    "****///////////15 ",
    candidateDefaultId,
    vendorDefaultId
    // defaultFilter.candidateDefaultId
    // JobApplicationViewJobResultModel.data != undefined &&
    //   JobApplicationViewJobResultModel.data[0] != undefined &&
    //   JobApplicationViewJobResultModel.data[0].appliedCount != undefined
    //   ? JobApplicationViewJobResultModel.data[0].appliedCount
    //   : ""
  );

  React.useEffect(() => {
    console.log(9999999, userId);

    if (userId === 1) {
      console.log(980980980);
      setJobStatusFilterId(parseInt(candidateDefaultId));
      if (candidateDefaultId === "1") {
        setJobStatusFilterName("Applied");
      } else if (candidateDefaultId === "2") {
        setJobStatusFilterName("Selected");
      } else if (candidateDefaultId === "3") {
        console.log(970970970);
        setJobStatusFilterName("Rejected");
      } else if (candidateDefaultId === "4") {
        setJobStatusFilterName("On Hold");
      } else if (candidateDefaultId === "5") {
        setJobStatusFilterName("Interview: Management");
      } else if (candidateDefaultId === "6") {
        setJobStatusFilterName("Interview: Technical");
      } else if (candidateDefaultId === "7") {
        setJobStatusFilterName("Interview: HR");
      }
    } else {
      setJobStatusFilterId(parseInt(vendorDefaultId));
      console.log(960960960);
      if (vendorDefaultId === "1") {
        setJobStatusFilterName("Applied");
      } else if (vendorDefaultId === "2") {
        setJobStatusFilterName("Selected");
      } else if (vendorDefaultId === "3") {
        setJobStatusFilterName("Rejected");
      } else if (vendorDefaultId === "4") {
        console.log(950950950);
        setJobStatusFilterName("On Hold");
      } else if (vendorDefaultId === "5") {
        setJobStatusFilterName("Interview: Management");
      } else if (vendorDefaultId === "6") {
        setJobStatusFilterName("Interview: Technical");
      } else if (vendorDefaultId === "7") {
        setJobStatusFilterName("Interview: HR");
      }
    }
  }, [userId]);

  React.useEffect(() => {
    if (loggedAdminId != 0) {
      ////console.log(bulkSMSRespond);
      //  globalSpinner.hideSpinner();
      if (JobApplicationViewJobResultModel) {
        // call processed api for reports
        let _data: objectIndexing = {};

        _data["AdminId"] = Number(loggedAdminId);
        _data["JobId"] = id;
        _data["PublishedJobsFlag"] = false;
        _data["ApprovalJobsFlag"] = false;
        _data["ViewedJobsFlag"] = true;
        _data["ProcessedJobsFlag"] = false;
        _data["FlagId"] = Number(3);
        _data["IsActive"] = true;
        saveAdminActions(_data).then((res: any) => {
          //console.log(res);
          if (res.data.isSuccess) {
            //  globalSpinner.hideSpinner();
            //  toast.success("Email sent successfully.");
            //  setActive(1);
          } else {
            // globalSpinner.hideSpinner();
            // toast.error("Error:Email not sent.");
          }
        });
      }
    }
  }, [JobApplicationViewJobResultModel]);

  React.useEffect(() => {
    globalSpinner.showSpinner();
    if (
      authorizationToken != null &&
      loggedAdminId != 0 &&
      JobStatusFilterId != 0
    ) {
      (async () => {
        await getJobApplicationViewJobList(
          adminJobApplicationDispatcher,
          {
            AdminId: loggedAdminId,
            // JobStatusId: userId == 1 ? JobStatusFilterId : 1,
            JobStatusId: JobStatusFilterId,

            UserType: "",
            UserTypeId: userId,
            ViewedJobsFlag: false,
            jobId: parseInt(id),
            IsActive: true,
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            SortOrder: "",
          } as JobApplicationViewJobRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedAdminId, JobStatusFilterId, AdminMoveJobStatusResult]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      if (
        JobApplicationViewJobResultModel.data != undefined &&
        JobApplicationViewJobResultModel.data.length > 0
      ) {
        setInitialUserId(JobApplicationViewJobResultModel.data[0].userId);
        setExicuteCount(exicuteCount + 1);
      } else {
        setInitialUserId(100000);
        setExicuteCount(exicuteCount + 1);
      }
    }
  }, [JobApplicationViewJobResultModel.data]);

  React.useEffect(() => {
    if (
      authorizationToken != null &&
      initialUserId != 0 &&
      userId == 2
      // &&
      // JobApplicationViewJobResultModel.data.length > 0
    ) {
      (async () => {
        await GetAdminVendorCandidateDetails(
          adminJobApplicationDispatcher,
          {
            JobId: parseInt(id),
            JobStatusId: JobStatusFilterId,
            VendorId: initialUserId,
          } as GetAdminVendorCandidateRequestModel,
          authorizationToken
        );
      })();
      globalSpinner.hideSpinner();
      setCheckedListArray([]);
      setIsCheckedAll(false);
      setCheckedListArrayTwo([]);
      setIsCheckedAllTwo(false);
      setIsRemovedCheckedAll(false);
    }
  }, [
    initialUserId,
    exicuteCount,
    JobStatusFilterId,
    AdminMoveJobStatusResult,
    // JobApplicationViewJobResultModel.data,
  ]);

  React.useEffect(() => {
    if (
      authorizationToken != null &&
      userId == 1 &&
      initialUserId != 0 &&
      JobApplicationViewJobResultModel.data != undefined
    ) {
      (async () => {
        await GetAdminCandidateDetails(
          adminJobApplicationDispatcher,
          {
            CandidateId: initialUserId,
            JobId: parseInt(id),
          } as GetAdminCandidateRequestModel,
          authorizationToken
        );
      })();
      globalSpinner.hideSpinner();
      // setExicuteCount(0);
      setCheckedListArray([]);
      setIsCheckedAll(false);
      setCheckedListArrayTwo([]);
      setIsCheckedAllTwo(false);
      setIsRemovedCheckedAll(false);
    }
  }, [initialUserId, exicuteCount]);

  React.useEffect(() => {
    //console.log(125, authorizationToken);
    //console.log(126, initialUserId);
    if (authorizationToken != null && initialUserId != 0) {
      (async () => {
        await AdminJobAppliedStatusMovie(
          adminJobApplicationDispatcher,
          {
            AdminId: loggedAdminId,
            CandidateId: userId == 1 ? checkedListArray : checkedListArrayTwo,
            JobId: parseInt(id),
            JobMovingStatusId: JobStatusId,
            UserTypeId: userId,
            VendorId: userId == 1 ? 0 : initialUserId,
          } as AdminMoveJobStatusRequestModel,
          authorizationToken
        );
      })();
      setJobStatusName("Move To");
      setJobStatusId(0);
      setCheckedListArray([]);
      setIsCheckedAll(false);
      setCheckedListArrayTwo([]);
      setIsCheckedAllTwo(false);
      setIsRemovedCheckedAll(false);
    }
  }, [JobStatusId]);

  const handleOnChangeUser = (e: any) => {
    setJobStatusFilterId(0);
    setUserId(parseInt(e.target.value));
  };

  const handleUserIdChange = (id: any) => {
    setInitialUserId(id);
  };

  const handleRestCheckedArrays = () => {
    setCheckedListArray([]);
  };
  const handleOnJobStatusFilterChange = (e: any) => {
    if (e.target.value === "1") {
      setJobStatusFilterName("Applied");
    } else if (e.target.value === "2") {
      setJobStatusFilterName("Selected");
    } else if (e.target.value === "3") {
      setJobStatusFilterName("Rejected");
    } else if (e.target.value === "4") {
      setJobStatusFilterName("On Hold");
    } else if (e.target.value === "5") {
      setJobStatusFilterName("Interview: Management");
    } else if (e.target.value === "6") {
      setJobStatusFilterName("Interview: Technical");
    } else if (e.target.value === "7") {
      setJobStatusFilterName("Interview: HR");
    }
    setJobStatusFilterId(parseInt(e.target.value));
  };

  const handleStatusMove = (e: any) => {
    if (e.target.value === "1") {
      setJobStatusName("Applied");
    } else if (e.target.value === "2") {
      setJobStatusName("Selected");
    } else if (e.target.value === "3") {
      setJobStatusName("Rejected");
    } else if (e.target.value === "4") {
      setJobStatusName("On Hold");
    } else if (e.target.value === "5") {
      setJobStatusName("Interview: Management");
    } else if (e.target.value === "6") {
      setJobStatusName("Interview: Technical");
    } else if (e.target.value === "7") {
      setJobStatusName("Interview: HR");
    }
    setJobStatusId(parseInt(e.target.value));
  };

  // select and deselect

  React.useEffect(() => {
    if (isRenderCheckedList) {
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(false);
      setIsRenderCheckedList(false);
    }
  }, [isRenderCheckedList]);

  const handleAllChecked = (e: any) => {
    //console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArray = [];
      // digiLockerList
      JobApplicationViewJobResultModel.data
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArray.push(d.userId);
        });
      setCheckedListArray(IdArray);
      //console.log(23, checkedListArray);
      setIsCheckedAll(e.target.checked);
      //console.log("checkedListArray", checkedListArray);
      setIsRemovedCheckedAll(false);
    } else {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(e.target.checked);
      //console.log("checkedListArray", checkedListArray);
      setIsRemovedCheckedAll(true);
    }
  };

  const handleAllRemoved = (e: any) => {
    if (e.target.checked) {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(true);
      //console.log("checkedListArray", checkedListArray);
    }
  };

  const handleChecked = (e: any, id: any) => {
    // setIsChecked(true);
    if (e.target.checked) {
      IdArray.push(...checkedListArray, id);
      setCheckedListArray(IdArray);
      //console.log("checkedListArray", checkedListArray);
    } else {
      IdArray = checkedListArray.filter((data) => {
        return data !== id;
      });
      setCheckedListArray(IdArray);
      //console.log("checkedListArray", checkedListArray);
    }
    setIsRemovedCheckedAll(false);
    setIsChecked(true);
  };

  //console.log(25, checkedListArray);
  React.useEffect(() => {
    if (isChecked) {
      if (
        checkedListArray.length == JobApplicationViewJobResultModel.data.length
      ) {
        setIsCheckedAll(true);
        //console.log("checkedListArray lenth", checkedListArray.length);
        // //console.log("vendorJobStatus.length", vendorJobStatus.length);
      } else {
        setIsCheckedAll(false);
      }
      setIsChecked(false);
    }
  }, [isChecked]);

  // Select and deselect 2

  React.useEffect(() => {
    if (isRenderCheckedListTwo) {
      setCheckedListArrayTwo(IdArrayTwo);
      setIsCheckedAllTwo(false);
      setIsRemovedCheckedAllTwo(false);
      setIsRenderCheckedListTwo(false);
    }
  }, [isRenderCheckedListTwo]);

  const handleAllCheckedTwo = (e: any) => {
    //console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArrayTwo = [];
      // digiLockerList
      AdminVendorCandidateResult.data.candidateInfoModels
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArrayTwo.push(d.candidateId);
        });
      setCheckedListArrayTwo(IdArrayTwo);
      //console.log(23, checkedListArrayTwo);
      setIsCheckedAllTwo(e.target.checked);
      //console.log("checkedListArray", checkedListArrayTwo);
      setIsRemovedCheckedAllTwo(false);
    } else {
      IdArrayTwo = [];
      setCheckedListArrayTwo(IdArrayTwo);
      setIsCheckedAllTwo(e.target.checked);
      //console.log("checkedListArray", checkedListArrayTwo);
      setIsRemovedCheckedAllTwo(true);
    }
  };

  const handleAllRemovedTwo = (e: any) => {
    if (e.target.checked) {
      IdArrayTwo = [];
      setCheckedListArrayTwo(IdArrayTwo);
      setIsCheckedAllTwo(false);
      setIsRemovedCheckedAllTwo(true);
      //console.log("checkedListArray", checkedListArrayTwo);
    }
  };

  const handleCheckedTwo = (e: any, id: any) => {
    //console.log(11, id);
    //console.log(e.target.checked);
    // setIsChecked(true);
    if (e.target.checked) {
      IdArrayTwo.push(...checkedListArrayTwo, id);
      setCheckedListArrayTwo(IdArrayTwo);
      //console.log("checkedListArrayTwo", checkedListArrayTwo);
    } else {
      IdArrayTwo = checkedListArrayTwo.filter((data) => {
        return data !== id;
      });
      setCheckedListArrayTwo(IdArrayTwo);
      //console.log("checkedListArray", checkedListArrayTwo);
    }
    setIsRemovedCheckedAllTwo(false);
    setIsCheckedTwo(true);
  };

  //console.log(35, checkedListArrayTwo);
  React.useEffect(() => {
    if (isCheckedTwo) {
      if (
        checkedListArrayTwo.length ==
        AdminVendorCandidateResult.data.candidateInfoModels.length
      ) {
        setIsCheckedAllTwo(true);
        //console.log("checkedListArray lenth", checkedListArrayTwo.length);
        // //console.log("vendorJobStatus.length", vendorJobStatus.length);
      } else {
        setIsCheckedAllTwo(false);
      }
      setIsCheckedTwo(false);
    }
  }, [isCheckedTwo]);

  const handleResumeClick = (data: any) => {
    //console.log(55, data);
    window.open(data);
  };

  const handleCoverLetterClick = (data: any) => {
    window.open(data);
  };

  const handleDisplayModeChange = (value: any) => {
    setDisplayMode(value);
  };

  const content = (
    <>
      <p>Please Select Candidate/Vendor</p>
    </>
  );

  const handlePlayClick = (name: string) => {
    setIsOpenVideo2(true);
    setVideoName(name);
  };

  const handleDisplayMode = () => {
    if (displayMode == 0) {
      return (
        <>
          <div className="content-wrapper">
            <div className="container-fluid">
              <h1 className="heading">
                {adminEditRespond.data != undefined &&
                adminEditRespond.data.length > 0
                  ? adminEditRespond.data[0].categoryName != undefined
                    ? adminEditRespond.data[0].categoryName
                    : null
                  : null}
                {"->"}
                {adminEditRespond.data != undefined &&
                adminEditRespond.data.length > 0
                  ? adminEditRespond.data[0].jobType != undefined
                    ? adminEditRespond.data[0].jobType
                    : null
                  : null}
                {"->"}
                {adminEditRespond.data != undefined &&
                adminEditRespond.data.length > 0
                  ? adminEditRespond.data[0].title != undefined
                    ? adminEditRespond.data[0].title
                    : null
                  : null}
              </h1>
              <div className="action_btn1 ">
                {/* <button type="button" className="actions_menu">
                  <i className="fa fa-align-left" aria-hidden="true" />
                </button> */}

                <div className="row ">
                  <div className="action_btn1 ">
                    <button
                      type="button"
                      className="actions_menu"
                      onClick={() => {
                        setIsListOpen(!isListOpen);
                      }}
                    >
                      <i className="fa fa-align-left" aria-hidden="true" />
                    </button>
                    <div
                      className="user_menu"
                      tabIndex={-1}
                      style={{ display: isListOpen ? "block" : "none" }}
                    >
                      <div className="action_sec">
                        <ul>
                          <li>
                            <Link to="/admin/myJob/drafted">Drafted</Link>{" "}
                          </li>
                          <li>
                            <Link to="/admin/myJob/published">Published </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix" style={{ marginBottom: 22 }} />
              <div className="row">
                <div className="col-sm-12 col-lg-12">
                  <div className="section_box4">
                    <h1 className="pull-left">Job Application</h1>
                    <div className="pull-right">
                      <span className="select-wrapper-sec">
                        <select
                          name="timepass"
                          className="custom-select-sec"
                          onChange={handleOnJobStatusFilterChange}
                        >
                          <option value="1">
                            Applied -{" "}
                            {JobApplicationViewJobResultModel.countInfo !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo
                              .appliedCount != undefined
                              ? JobApplicationViewJobResultModel.countInfo
                                  .appliedCount
                              : 0}
                          </option>
                          <option value="2">
                            Selected -
                            {JobApplicationViewJobResultModel.data !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo
                              .selectedCount != undefined
                              ? JobApplicationViewJobResultModel.countInfo
                                  .selectedCount
                              : 0}
                          </option>
                          <option value="3">
                            Rejected -
                            {JobApplicationViewJobResultModel.data !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo
                              .rejectedCount != undefined
                              ? JobApplicationViewJobResultModel.countInfo
                                  .rejectedCount
                              : 0}
                          </option>
                          <option value="4">
                            On Hold -
                            {JobApplicationViewJobResultModel.data !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo
                              .onHoldCount != undefined
                              ? JobApplicationViewJobResultModel.countInfo
                                  .onHoldCount
                              : 0}
                          </option>
                          <option value="5">
                            Interview: Management -
                            {JobApplicationViewJobResultModel.data !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo
                              .managementCount != undefined
                              ? JobApplicationViewJobResultModel.countInfo
                                  .managementCount
                              : 0}
                          </option>
                          <option value="6">
                            Interview: Technical -
                            {JobApplicationViewJobResultModel.data !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo
                              .technicalCount != undefined
                              ? JobApplicationViewJobResultModel.countInfo
                                  .technicalCount
                              : 0}
                          </option>
                          <option value="7">
                            Interview: HR -
                            {JobApplicationViewJobResultModel.data !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo
                              .hRCount != undefined
                              ? JobApplicationViewJobResultModel.countInfo
                                  .hRCount
                              : 0}
                          </option>
                          <option value="8">
                            Shortlist -
                            {JobApplicationViewJobResultModel.data !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo !=
                              undefined &&
                            JobApplicationViewJobResultModel.countInfo
                              .shortListedCount != undefined
                              ? JobApplicationViewJobResultModel.countInfo
                                  .shortListedCount
                              : 0}
                          </option>
                        </select>
                        <span className="holder">{JobStatusFilterName}</span>
                      </span>
                    </div>
                    <div className="clearfix" />
                    <div className="row">
                      <div className="col-sm-3 col-lg-3">
                        <div className="button dropdown">
                          <select
                            id="colorselector"
                            onChange={handleOnChangeUser}
                            value={userId}
                          >
                            <option value="1">Candidate</option>
                            <option value="2">Vendor</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-9 col-lg-9">
                        <div className="admins_send">
                          <ul>
                            <li>
                              {checkedListArray.length > 0 ||
                              checkedListArrayTwo.length > 0 ? (
                                <a
                                  onClick={() => {
                                    setDisplayMode(1);
                                  }}
                                >
                                  Send SMS
                                </a>
                              ) : (
                                <Popover content={content} trigger="hover">
                                  <a>Send SMS</a>
                                </Popover>
                              )}
                            </li>
                            <li>
                              {checkedListArray.length > 0 ||
                              checkedListArrayTwo.length > 0 ? (
                                <a
                                  onClick={() => {
                                    setDisplayMode(2);
                                  }}
                                >
                                  Send Email
                                </a>
                              ) : (
                                <Popover content={content} trigger="hover">
                                  <a>Send Email</a>
                                </Popover>
                              )}
                            </li>
                            <li>
                              {checkedListArray.length > 0 ||
                              checkedListArrayTwo.length > 0 ? (
                                <a
                                  onClick={() => {
                                    setDisplayMode(3);
                                  }}
                                >
                                  Interview schedule
                                </a>
                              ) : (
                                <Popover content={content} trigger="hover">
                                  <a>Interview schedule</a>
                                </Popover>
                              )}
                            </li>
                            <li>
                              {checkedListArray.length == 1 ||
                              checkedListArrayTwo.length == 1 ? (
                                <a
                                  onClick={() => {
                                    setShowChat(true);
                                    //setDisplayMode(5);
                                  }}
                                >
                                  Chat
                                </a>
                              ) : (
                                <Popover content={content} trigger="hover">
                                  <a>Chat</a>
                                </Popover>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="output">
                        <div className="col-sm-12 col-lg-12">
                          <div
                            id="red"
                            className="colors"
                            style={{ display: userId == 1 ? "block" : "none" }}
                          >
                            <div className="m_t_20">
                              <div className="section_box4">
                                <div className="row">
                                  <div className="col-sm-3 col-lg-2">
                                    <input
                                      id="checkbox1"
                                      type="checkbox"
                                      name="checkbox"
                                      defaultValue={1}
                                      checked={isCheckedAll}
                                      // defaultChecked={isCheckedAll ? true : false}
                                      onChange={(e: any) => handleAllChecked(e)}
                                    />
                                    <label htmlFor="checkbox1">
                                      <span /> Select All
                                    </label>
                                  </div>
                                  <div className="col-sm-3 col-lg-2">
                                    <input
                                      id="checkbox1"
                                      type="checkbox"
                                      name="checkbox"
                                      defaultValue={1}
                                      checked={isRemovedAll}
                                      onChange={(e: any) => handleAllRemoved(e)}
                                    />
                                    <label htmlFor="checkbox1">
                                      <span /> Deselect All
                                    </label>
                                  </div>
                                  <div className="col-sm-3 col-lg-2">
                                    {checkedListArray.length > 0 ||
                                    checkedListArrayTwo.length > 0 ? (
                                      <>
                                        <span className="select-wrapper-sec">
                                          <select
                                            name="timepass"
                                            className="custom-select-sec"
                                            onChange={handleStatusMove}
                                            defaultValue={JobStatusId.toString()}
                                          >
                                            <option value="1">Applied</option>
                                            <option value="2">Selected</option>
                                            <option value="3">Rejected</option>
                                            <option value="4">On Hold</option>
                                            <option value="5">
                                              Interview: Management
                                            </option>
                                            <option value="6">
                                              Interview: Technical
                                            </option>
                                            <option value="7">
                                              Interview: HR
                                            </option>
                                          </select>

                                          <span className="holder">
                                            {JobStatusName}
                                          </span>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <Popover
                                          content={content}
                                          trigger="hover"
                                        >
                                          <span className="select-wrapper-sec">
                                            <select
                                              name="timepass"
                                              className="custom-select-sec"
                                              onChange={handleStatusMove}
                                              defaultValue={JobStatusId.toString()}
                                              disabled={true}
                                            >
                                              <option value="1">Applied</option>
                                              <option value="2">
                                                Selected
                                              </option>
                                              <option value="3">
                                                Rejected
                                              </option>
                                              <option value="4">On Hold</option>
                                              <option value="5">
                                                Interview: Management
                                              </option>
                                              <option value="6">
                                                Interview: Technical
                                              </option>
                                              <option value="7">
                                                Interview: HR
                                              </option>
                                            </select>

                                            <span className="holder">
                                              {JobStatusName}
                                            </span>
                                          </span>
                                        </Popover>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              id="verticalTab"
                              className="jobsearch-tabs  resp-vtabs"
                              style={{
                                display: "block",
                                width: "100%",
                                margin: "0px",
                              }}
                            >
                              <ul className="resp-tabs-list ">
                                <div
                                  className="dash_message_vendor"
                                  style={{
                                    overflow: "hidden",
                                    outline: "none",
                                  }}
                                  tabIndex={0}
                                >
                                  {/* vertical tab start */}
                                  {JobApplicationViewJobResultModel.data !=
                                  undefined
                                    ? JobApplicationViewJobResultModel.data.map(
                                        (data, index) => {
                                          return (
                                            <li
                                              style={{
                                                backgroundColor: data.adminViewedStatus
                                                  ? ""
                                                  : "lightcyan",
                                              }}
                                              className={`resp-tab-item ${
                                                initialUserId == data.userId
                                                  ? "resp-tab-active"
                                                  : ""
                                              }`}
                                              aria-controls="tab_item-0"
                                              role="tab"
                                            >
                                              <div className="candidates_check">
                                                <input
                                                  id="checkbox2"
                                                  type="checkbox"
                                                  name="checkbox"
                                                  defaultValue={data.userId}
                                                  onChange={(e: any) =>
                                                    handleChecked(
                                                      e,
                                                      data.userId
                                                    )
                                                  }
                                                  checked={checkedListArray.includes(
                                                    data.userId
                                                  )}
                                                />
                                                <label htmlFor="checkbox2">
                                                  <span />
                                                </label>
                                              </div>
                                              <div
                                                onClick={() =>
                                                  handleUserIdChange(
                                                    data.userId
                                                  )
                                                }
                                              >
                                                <div className="candidates_icon">
                                                  <img
                                                    // src={
                                                    //   environment.baseUrl +
                                                    //   `/Upload/ProfileImage/${AdminCandidateResult.candidateImage}`
                                                    // }
                                                    src={
                                                      environment.baseUrl +
                                                      `/Upload/ProfileImage/${data.userImage}`
                                                    }
                                                  />
                                                </div>
                                                <div className="candidates_con">
                                                  <div className="candidates_con_head">
                                                    {data.userName}
                                                  </div>
                                                  <div className="candidates_con_con">
                                                    {data.userPhone}
                                                  </div>
                                                  <div className="candidates_con_con">
                                                    {data.userEmail}
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )
                                    : null}

                                  {/* vertical tab end */}
                                </div>
                              </ul>
                              <div className="resp-tabs-container jobsearch-tabs-content admins_se">
                                {/*----------------Start-------------*/}

                                <h2
                                  className="resp-accordion resp-tab-active"
                                  role="tab"
                                  aria-controls="tab_item-0"
                                >
                                  <span className="resp-arrow" />
                                  <div className="candidates_check">
                                    <input
                                      id="checkbox2"
                                      type="checkbox"
                                      name="checkbox"
                                      defaultValue={1}
                                    />
                                    <label htmlFor="checkbox2">
                                      <span />
                                    </label>
                                  </div>
                                  <div className="candidates_icon">
                                    <img src="images/admin_users.jpg" />
                                  </div>
                                  <div className="candidates_con">
                                    <div className="candidates_con_head">
                                      Emilin Jhon1
                                    </div>
                                    <div className="candidates_con_con">
                                      086 834 2525
                                    </div>
                                    <div className="candidates_con_con">
                                      info@jitgmail.com
                                    </div>
                                  </div>
                                </h2>

                                <div
                                  className="resp-tab-content resp-tab-content-active"
                                  aria-labelledby="tab_item-0"
                                  style={{ display: "block" }}
                                >
                                  {AdminCandidateResult.candidateId !=
                                    undefined &&
                                  AdminCandidateResult.candidateEmail !=
                                    null ? (
                                    <>
                                      <div className="candidates_icon1">
                                        {AdminCandidateResult.candidateImage !==
                                        null ? (
                                          <img
                                            src={
                                              environment.baseUrl +
                                              `/Upload/ProfileImage/${AdminCandidateResult.candidateImage}`
                                            }
                                          />
                                        ) : (
                                          <img
                                            className="img-responsive"
                                            src={require("./../../../images/profileDefault1.jpg")}
                                          ></img>
                                        )}
                                      </div>
                                      <div
                                        className="candidates_con1"
                                        onClick={() => {
                                          setDisplayMode(4);
                                        }}
                                      >
                                        <div className="candidates_con_head1">
                                          <a>
                                            {" "}
                                            {AdminCandidateResult.candidateName}
                                          </a>
                                        </div>
                                        <div className="candidates_con_con1">
                                          <i
                                            className="fa fa-phone"
                                            aria-hidden="true"
                                          />{" "}
                                          {
                                            AdminCandidateResult.candidatePhoneNo
                                          }
                                        </div>
                                        <div className="candidates_con_con1">
                                          <i
                                            className="fa fa-envelope-o"
                                            aria-hidden="true"
                                          />{" "}
                                          {AdminCandidateResult.candidateEmail}
                                        </div>
                                      </div>
                                      <div className="clearfix" />
                                      <div className="row m-t-25">
                                        <div className="col-sm-6">
                                          {AdminCandidateResult.resumeUrl !==
                                          null ? (
                                            <div className="form-group">
                                              <label> Resume</label>
                                              <div
                                                style={{
                                                  width: 200,
                                                  cursor: "pointer",
                                                }}
                                                className="fileUpload fileUpload_admin"
                                                onClick={(data: any) =>
                                                  handleResumeClick(
                                                    environment.baseUrl +
                                                      AdminCandidateResult.resumeUrl
                                                  )
                                                }
                                              >
                                                <span>Resume</span>
                                                <input
                                                  style={{ cursor: "pointer" }}
                                                  id="uploadBtn"
                                                  type="button"
                                                  className="upload "
                                                />
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-sm-6">
                                          {AdminCandidateResult.coverLetterUrl !==
                                          null ? (
                                            <div className="form-group">
                                              <label> Cover Letter</label>
                                              <div
                                                style={{
                                                  width: 200,
                                                  cursor: "pointer",
                                                }}
                                                className="fileUpload fileUpload_admin"
                                                onClick={(data: any) =>
                                                  handleResumeClick(
                                                    environment.baseUrl +
                                                      AdminCandidateResult.coverLetterUrl
                                                  )
                                                }
                                              >
                                                <span>Cover Letter</span>
                                                <input
                                                  style={{ cursor: "pointer" }}
                                                  id="uploadBtn"
                                                  type="button"
                                                  className="upload"
                                                />
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>

                                        {/* video */}
                                        <div className="col-sm-6">
                                          {AdminCandidateResult.videoName !==
                                          null ? (
                                            <div className="form-group">
                                              <label> Self Intro</label>
                                              <div
                                                className="career_video"
                                                style={{
                                                  marginLeft: 85,
                                                  marginTop: 6,
                                                  width: "62%",
                                                }}
                                              >
                                                <ReactPlayer
                                                  url={
                                                    environment.baseUrl +
                                                    `/Upload/CandidateVideos/${AdminCandidateResult.videoName}`
                                                  }
                                                  width="100%"
                                                  height="300"
                                                />

                                                <div className="overlay">
                                                  <div className="text">
                                                    <div className="career_play">
                                                      <a
                                                        onClick={
                                                          () =>
                                                            handlePlayClick(
                                                              AdminCandidateResult.videoName
                                                            )
                                                          //   {
                                                          //   setIsOpenVideo2(true),
                                                          //     setVideoName(
                                                          //       AdminCandidateResult.videoName
                                                          //     );
                                                          // }
                                                        }
                                                        data-target="#add_document"
                                                        data-toggle="modal"
                                                      >
                                                        Play
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                        {/*  */}
                                        <div className="col-sm-6">
                                          {AdminCandidateResult.docNameUrl.map(
                                            (data, index) => {
                                              return data.documentUrl !==
                                                null ? (
                                                <div className="form-group">
                                                  <label>
                                                    {data.documentName}
                                                  </label>
                                                  <div
                                                    style={{
                                                      width: 200,
                                                      cursor: "pointer",
                                                    }}
                                                    className="fileUpload fileUpload_admin"
                                                    onClick={() =>
                                                      handleResumeClick(
                                                        environment.baseUrl +
                                                          data.documentUrl
                                                      )
                                                    }
                                                  >
                                                    <span>
                                                      {data.documentName}
                                                    </span>
                                                    <input
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      id="uploadBtn"
                                                      type="button"
                                                      className="upload"
                                                    />
                                                  </div>
                                                </div>
                                              ) : null;
                                            }
                                          )}
                                        </div>
                                      </div>
                                      {/* <div className="row m-t-25">
                                        <div className="col-sm-12">
                                          <label>Resume</label>
                                        </div>
                                        <div className="col-sm-12">
                                          <button
                                            onClick={() =>
                                              handleResumeClick(
                                               
                                                environment.baseUrl +
                                                  "/Upload/ProfileImage/3a16127cf28d4ade8f2147ccce049fc6.png"
                                              )
                                            }
                                          >
                                            Resume
                                          </button>
                                        </div>
                                        <div className="col-sm-12">
                                          <label>Coverletter</label>
                                        </div>
                                        <div className="col-sm-12">
                                          <button
                                            onClick={() =>
                                              handleResumeClick(
                                                AdminCandidateResult.coverLetterUrl
                                              )
                                            }
                                          >
                                            Coverletter
                                          </button>
                                       
                                        </div>
                                        <div className="col-sm-12">
                                          <label>Document</label>
                                        </div>
                                        <div className="col-sm-12">
                                          <button
                                            onClick={() =>
                                              handleResumeClick(
                                                AdminCandidateResult.documentUrl
                                              )
                                            }
                                          >
                                            {AdminCandidateResult.documentName}
                                          </button>
                                         
                                        </div>
                                      </div>
                                     */}
                                    </>
                                  ) : null}

                                  {/*<a href="#" class="btn submit_btn">Save</a> */}
                                </div>
                                {/*----------------End-------------*/}
                              </div>
                            </div>
                          </div>
                          <div
                            id="yellow"
                            className="colors"
                            style={{ display: userId == 2 ? "block" : "none" }}
                          >
                            <div className="m_t_20">
                              <div className="section_box4">
                                <div className="row">
                                  <div className="col-sm-3 col-lg-2">
                                    <input
                                      id="checkbox1"
                                      type="checkbox"
                                      name="checkbox"
                                      defaultValue={1}
                                      checked={isCheckedAll}
                                      // defaultChecked={isCheckedAll ? true : false}
                                      onChange={(e: any) => handleAllChecked(e)}
                                    />
                                    <label htmlFor="checkbox1">
                                      <span /> Select All
                                    </label>
                                  </div>
                                  <div className="col-sm-3 col-lg-2">
                                    <input
                                      id="checkbox1"
                                      type="checkbox"
                                      name="checkbox"
                                      defaultValue={1}
                                      checked={isRemovedAll}
                                      onChange={(e: any) => handleAllRemoved(e)}
                                    />
                                    <label htmlFor="checkbox1">
                                      <span /> Deselect All
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              id="verticalTab1"
                              className="jobsearch-tabs  resp-vtabs"
                              style={{
                                display: "block",
                                width: "100%",
                                margin: "0px",
                              }}
                            >
                              <ul className="resp-tabs-list ">
                                <div
                                  className="dash_message_vendor"
                                  style={{
                                    overflow: "hidden",
                                    outline: "none",
                                  }}
                                  tabIndex={0}
                                >
                                  {/* Vendor candidate Start */}
                                  {JobApplicationViewJobResultModel.data !=
                                  undefined
                                    ? JobApplicationViewJobResultModel.data.map(
                                        (data, index) => {
                                          return (
                                            <li
                                              className={`resp-tab-item ${
                                                initialUserId == data.userId
                                                  ? "resp-tab-active"
                                                  : ""
                                              }`}
                                              aria-controls="tab_item-0"
                                              role="tab"
                                            >
                                              <div className="candidates_check">
                                                <input
                                                  id="checkbox2"
                                                  type="checkbox"
                                                  name="checkbox"
                                                  defaultValue={1}
                                                  onChange={(e: any) =>
                                                    handleChecked(
                                                      e,
                                                      data.userId
                                                    )
                                                  }
                                                  checked={checkedListArray.includes(
                                                    data.userId
                                                  )}
                                                />
                                                <label htmlFor="checkbox2">
                                                  <span />
                                                </label>
                                              </div>
                                              <div
                                                onClick={() =>
                                                  handleUserIdChange(
                                                    data.userId
                                                  )
                                                }
                                              >
                                                <div className="candidates_icon">
                                                  {data.userImage !== null ? (
                                                    <img
                                                      src={
                                                        environment.baseUrl +
                                                        `/Upload/VendorProfileImage/${data.userImage}`
                                                      }
                                                    />
                                                  ) : (
                                                    <img
                                                      className="img-responsive"
                                                      src={require("./../../../images/profileDefault1.jpg")}
                                                    ></img>
                                                  )}
                                                </div>
                                                <div className="candidates_con">
                                                  <div className="candidates_con_head">
                                                    {data.userName}
                                                  </div>
                                                  <div className="candidates_con_con">
                                                    {data.userPhone}
                                                  </div>
                                                  <div className="candidates_con_con">
                                                    {data.userEmail}
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )
                                    : null}

                                  {/* vendor candidate end */}
                                </div>
                              </ul>
                              <div className="resp-tabs-container jobsearch-tabs-content admins_se">
                                {/*----------------Start-------------*/}
                                <h2
                                  className="resp-accordion resp-tab-active"
                                  role="tab"
                                  aria-controls="tab_item-0"
                                >
                                  <span className="resp-arrow" />
                                  <div className="candidates_check">
                                    <input
                                      id="checkbox2"
                                      type="checkbox"
                                      name="checkbox"
                                      defaultValue={1}
                                    />
                                    <label htmlFor="checkbox2">
                                      <span />
                                    </label>
                                  </div>
                                  <div className="candidates_icon">
                                    <img src="images/admin_users.jpg" />
                                  </div>
                                  <div className="candidates_con">
                                    <div className="candidates_con_head">
                                      Emilin Jhon7
                                    </div>
                                    <div className="candidates_con_con">
                                      086 834 2525
                                    </div>
                                    <div className="candidates_con_con">
                                      info@jitgmail.com
                                    </div>
                                  </div>
                                </h2>

                                <div
                                  className="resp-tab-content resp-tab-content-active"
                                  aria-labelledby="tab_item-0"
                                  style={{ display: "block" }}
                                >
                                  <div className="section_box4">
                                    <div className="row">
                                      <div className="col-sm-4 col-lg-4">
                                        <input
                                          id="checkbox1"
                                          type="checkbox"
                                          name="checkbox"
                                          defaultValue={1}
                                          checked={isCheckedAllTwo}
                                          // defaultChecked={isCheckedAll ? true : false}
                                          onChange={(e: any) =>
                                            handleAllCheckedTwo(e)
                                          }
                                        />
                                        <label htmlFor="checkbox1">
                                          <span /> Select All
                                        </label>
                                      </div>
                                      <div className="col-sm-4 col-lg-4">
                                        <input
                                          id="checkbox1"
                                          type="checkbox"
                                          name="checkbox"
                                          defaultValue={1}
                                          checked={isRemovedAllTwo}
                                          onChange={(e: any) =>
                                            handleAllRemovedTwo(e)
                                          }
                                        />
                                        <label htmlFor="checkbox1">
                                          <span /> Deselect All
                                        </label>
                                      </div>
                                      <div className="col-sm-4 col-lg-4">
                                        <span className="select-wrapper-sec">
                                          {/* <Controller
                        control={control2}
                        name="jobStatus"
                        rules={{
                          required: "Job Title is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={jobStatusSelect}
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      /> */}
                                          <select
                                            name="timepass"
                                            className="custom-select-sec"
                                            onChange={handleStatusMove}
                                            value={JobStatusId.toString()}
                                          >
                                            <option
                                              id="Applied"
                                              label="Applied"
                                              value="1"
                                            >
                                              Applied
                                            </option>
                                            <option
                                              id="Selected"
                                              label="Selected"
                                              value="2"
                                            >
                                              Selected
                                            </option>
                                            <option label="Rejected" value="3">
                                              Rejected
                                            </option>
                                            <option label="On Hold" value="4">
                                              On Hold
                                            </option>
                                            <option
                                              label="Interview: Management"
                                              value="5"
                                            >
                                              Interview: Management
                                            </option>
                                            <option
                                              label="Interview: Technical"
                                              value="6"
                                            >
                                              Interview: Technical
                                            </option>
                                            <option
                                              label="Interview: HR"
                                              value="7"
                                            >
                                              Interview: HR
                                            </option>
                                          </select>

                                          <span className="holder">
                                            {JobStatusName}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Vendor Cnadidate details Start */}
                                  {AdminVendorCandidateResult.data !=
                                  undefined ? (
                                    AdminVendorCandidateResult.data
                                      .candidateInfoModels.length > 0 ? (
                                      <>
                                        <div className="candidates_icon1">
                                          <img
                                            src={
                                              environment.baseUrl +
                                              `/Upload/VendorProfileImage/${AdminVendorCandidateResult.data.vendorImageURL}`
                                            }
                                          />
                                        </div>
                                        <div
                                          className="candidates_con1"
                                          onClick={() => {
                                            setDisplayMode(4);
                                          }}
                                        >
                                          <div className="candidates_con_head1">
                                            {
                                              AdminVendorCandidateResult.data
                                                .vendorName
                                            }
                                          </div>
                                          <div className="candidates_con_con1">
                                            <i
                                              className="fa fa-phone"
                                              aria-hidden="true"
                                            />{" "}
                                            {
                                              AdminVendorCandidateResult.data
                                                .vendorPhone
                                            }
                                          </div>
                                          <div className="candidates_con_con1">
                                            <i
                                              className="fa fa-envelope-o"
                                              aria-hidden="true"
                                            />{" "}
                                            {
                                              AdminVendorCandidateResult.data
                                                .vendorEmail
                                            }
                                          </div>
                                        </div>
                                        <div className="clearfix" />
                                        <div className=" m-t-25" />
                                        {AdminVendorCandidateResult.data.candidateInfoModels.map(
                                          (data, index) => {
                                            return (
                                              <div className="section_box4">
                                                <div className="row">
                                                  <div className="col-sm-12 col-lg-12">
                                                    <input
                                                      id="checkbox1"
                                                      type="checkbox"
                                                      name="checkbox"
                                                      defaultValue={1}
                                                      onChange={(e: any) =>
                                                        handleCheckedTwo(
                                                          e,
                                                          data.candidateId
                                                        )
                                                      }
                                                      checked={checkedListArrayTwo.includes(
                                                        data.candidateId
                                                      )}
                                                    />
                                                    <label htmlFor="checkbox1">
                                                      <span /> {index + 1}
                                                    </label>
                                                  </div>
                                                  <div className="col-sm-6">
                                                    <div className="form-group">
                                                      <label htmlFor="email">
                                                        Candidate first Name
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder=""
                                                        value={data.firstName}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-sm-6">
                                                    <div className="form-group">
                                                      <label htmlFor="email">
                                                        Middle Name
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder=""
                                                        value={data.middleName}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-sm-6">
                                                    <div className="form-group">
                                                      <label htmlFor="email">
                                                        Last Name
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder=""
                                                        value={data.lastName}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-sm-6">
                                                    <div className="form-group">
                                                      <label htmlFor="email">
                                                        Passport No
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder=""
                                                        value={data.passport}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-sm-6">
                                                    <div className="form-group">
                                                      <label htmlFor="email">
                                                        DOB
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder=""
                                                        value={data.dob}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-sm-6">
                                                    <div className="form-group">
                                                      <label htmlFor="email">
                                                        Active Visa?
                                                      </label>
                                                      <div className="row">
                                                        <div className="col-xs-6">
                                                          <input
                                                            id={`radio${
                                                              index + 1
                                                            }`}
                                                            type="radio"
                                                            name={`radio${
                                                              index + 1
                                                            }`}
                                                            defaultValue={1}
                                                            checked={
                                                              data.isActiveVisa
                                                                ? true
                                                                : false
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={`radio${
                                                              index + 1
                                                            }`}
                                                          >
                                                            <span>
                                                              <span />
                                                            </span>{" "}
                                                            Yes
                                                          </label>
                                                        </div>
                                                        <div className="col-xs-6">
                                                          <input
                                                            id={`radio${
                                                              index + 1
                                                            }`}
                                                            type="radio"
                                                            name={`radio${
                                                              index + 1
                                                            }`}
                                                            defaultValue={1}
                                                            checked={
                                                              data.isActiveVisa
                                                                ? false
                                                                : true
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={`radio${
                                                              index + 1
                                                            }`}
                                                          >
                                                            <span>
                                                              <span />
                                                            </span>{" "}
                                                            No
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="clearfix" />
                                                  <div
                                                    className="col-sm-6"
                                                    style={{
                                                      display: data.isActiveVisa
                                                        ? ""
                                                        : "none",
                                                    }}
                                                  >
                                                    <div className="form-group">
                                                      <label htmlFor="email">
                                                        Visa Issuing Country{" "}
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder=""
                                                        value={
                                                          data.visaIssueCountry
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="col-sm-6"
                                                    style={{
                                                      display: data.isActiveVisa
                                                        ? ""
                                                        : "none",
                                                    }}
                                                  >
                                                    <div className="form-group">
                                                      <label htmlFor="email">
                                                        Expiry date{" "}
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control "
                                                        placeholder=""
                                                        value={data.expDate}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : null
                                  ) : null}

                                  {/* Vendor Cnadidate details end */}
                                </div>
                                {/*----------------End-------------*/}
                                {/*----------------Start-------------*/}
                                <h2
                                  className="resp-accordion"
                                  role="tab"
                                  aria-controls="tab_item-1"
                                >
                                  <span className="resp-arrow" />
                                  <div className="candidates_check">
                                    <input
                                      id="checkbox3"
                                      type="checkbox"
                                      name="checkbox"
                                      defaultValue={1}
                                    />
                                    <label htmlFor="checkbox3">
                                      <span />
                                    </label>
                                  </div>
                                  <div className="candidates_icon">
                                    <img src="images/admin_users1.jpg" />
                                  </div>
                                  <div className="candidates_con">
                                    <div className="candidates_con_head">
                                      Emilin Jhon9
                                    </div>
                                    <div className="candidates_con_con">
                                      086 834 2525
                                    </div>
                                    <div className="candidates_con_con">
                                      info@jitgmail.com
                                    </div>
                                  </div>
                                </h2>
                                <div
                                  className="resp-tab-content"
                                  aria-labelledby="tab_item-1"
                                >
                                  <p>bbbb</p>
                                </div>
                                {/*----------------End-------------*/}
                                {/*----------------Start-------------*/}
                                <h2
                                  className="resp-accordion"
                                  role="tab"
                                  aria-controls="tab_item-2"
                                >
                                  <span className="resp-arrow" />
                                  <div className="candidates_check">
                                    <input
                                      id="checkbox4"
                                      type="checkbox"
                                      name="checkbox"
                                      defaultValue={1}
                                    />
                                    <label htmlFor="checkbox4">
                                      <span />
                                    </label>
                                  </div>
                                  <div className="candidates_icon">
                                    <img src="images/admin_users2.jpg" />
                                  </div>
                                  <div className="candidates_con">
                                    <div className="candidates_con_head">
                                      Emilin Jhon10
                                    </div>
                                    <div className="candidates_con_con">
                                      086 834 2525
                                    </div>
                                    <div className="candidates_con_con">
                                      info@jitgmail.com
                                    </div>
                                  </div>
                                </h2>
                                <div
                                  className="resp-tab-content"
                                  aria-labelledby="tab_item-2"
                                >
                                  <p>cccc</p>
                                </div>
                                {/*----------------End-------------*/}
                                {/*----------------Start-------------*/}
                                <h2
                                  className="resp-accordion"
                                  role="tab"
                                  aria-controls="tab_item-3"
                                >
                                  <span className="resp-arrow" />
                                  <div className="candidates_check">
                                    <input
                                      id="checkbox5"
                                      type="checkbox"
                                      name="checkbox"
                                      defaultValue={1}
                                    />
                                    <label htmlFor="checkbox5">
                                      <span />
                                    </label>
                                  </div>
                                  <div className="candidates_icon">
                                    <img src="images/admin_users3.jpg" />
                                  </div>
                                  <div className="candidates_con">
                                    <div className="candidates_con_head">
                                      Emilin Jhon11
                                    </div>
                                    <div className="candidates_con_con">
                                      086 834 2525
                                    </div>
                                    <div className="candidates_con_con">
                                      info@jitgmail.com
                                    </div>
                                  </div>
                                </h2>
                                <div
                                  className="resp-tab-content"
                                  aria-labelledby="tab_item-3"
                                >
                                  <p>dddd</p>
                                </div>
                                {/*----------------End-------------*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </>
      );
    } else if (displayMode == 1) {
      return (
        <BulkSMS
          handleDisplayModeChange={handleDisplayModeChange}
          checkedListArray={checkedListArray}
          userId={userId}
          JobId={parseInt(id)}
          JobApplicationViewJobResultModel={JobApplicationViewJobResultModel}
          adminId={loggedAdminId}
          basicInfo={basicInfo}
        />
      );
    } else if (displayMode == 2) {
      return (
        <BulkEmail
          handleDisplayModeChange={handleDisplayModeChange}
          checkedListArray={checkedListArray}
          userId={userId}
          JobId={parseInt(id)}
          JobApplicationViewJobResultModel={JobApplicationViewJobResultModel}
          adminId={loggedAdminId}
          basicInfo={basicInfo}
          jobTitle={
            adminEditRespond.data != undefined
              ? adminEditRespond.data[0].title != undefined
                ? adminEditRespond.data[0].title
                : null
              : null
          }
        />
      );
    } else if (displayMode == 3) {
      return (
        <InterviewShedule
          handleDisplayModeChange={handleDisplayModeChange}
          checkedListArray={checkedListArray}
          userId={userId}
          handleRestCheckedArrays={handleRestCheckedArrays}
          loggedAdminId={loggedAdminId}
          jobTitle={
            adminEditRespond.data != undefined
              ? adminEditRespond.data[0].title != undefined
                ? adminEditRespond.data[0].title
                : null
              : null
          }
        />
      );
    } else if (displayMode == 4) {
      return (
        <JobApplicationProfileContainer
          handleDisplayModeChange={handleDisplayModeChange}
          initialUserId={initialUserId}
          userId={userId}
          AdminCandidateResult={AdminCandidateResult}
        />
      );
    } //else if (displayMode == 5) {
    //   return (
    //     <CandidateChat
    //       handleDisplayModeChange={handleDisplayModeChange}
    //       // checkedListArray={checkedListArray}
    //       checkedListArray={userId == 1 ? checkedListArray : checkedListArrayTwo}
    //       userId={userId}
    //       handleRestCheckedArrays={handleRestCheckedArrays}
    //       loggedAdminId={loggedAdminId}
    //       jobTitle={
    //         adminEditRespond.data != undefined
    //           ? adminEditRespond.data[0].title != undefined
    //             ? adminEditRespond.data[0].title
    //             : null
    //           : null
    //       }
    //       JobId={parseInt(id)}
    //       JobApplicationViewJobResultModel={JobApplicationViewJobResultModel}
    //       basicInfo={basicInfo}
    //       //receiver={receiver}
    //       adminState={adminState}
    //     />
    //   );
    // }
  };

  return (
    <>
      {handleDisplayMode()}

      <Modal
        show={showChat}
        onHide={() => {
          setShowChat(!showChat);
        }}
        // style={{ width: 600 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CandidateChat
            handleDisplayModeChange={handleDisplayModeChange}
            //checkedListArray={userId == 1 ? checkedListArray : checkedListArrayTwo}
            checkedListArray={checkedListArray}
            userId={userId}
            handleRestCheckedArrays={handleRestCheckedArrays}
            loggedAdminId={loggedAdminId}
            jobTitle={
              adminEditRespond.data != undefined &&
              adminEditRespond.data.length > 0
                ? adminEditRespond.data[0].title != undefined
                  ? adminEditRespond.data[0].title
                  : null
                : null
            }
            JobId={parseInt(id)}
            JobApplicationViewJobResultModel={JobApplicationViewJobResultModel}
            basicInfo={basicInfo}
            adminState={adminState}
            searchOperation={false}
          />
        </Modal.Body>
        <div className="modal-footer  m-t-30">
          <button
            onClick={() => setShowChat(false)}
            className="btn btn-default"
            type="button"
          >
            cancel
          </button>
        </div>
        <div className="clearfix" />
      </Modal>

      <Modal
        show={isOpenVideo2}
        onHide={() => {
          setIsOpenVideo2(!isOpenVideo2);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer
            url={environment.baseUrl + `/Upload/CandidateVideos/${videoName}`}
            width="100%"
            controls={true}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withRouter(JobApplicationComponent);
