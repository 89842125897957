import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { VendorSuggestedJobTitleViewModel, VendorSuggestedJobViewModel } from "../../models/vendor/VendorSuggestedJob";
import { IVendorSuggestedJobAction, vendorSuggestedJobReducer } from "../../action/vendor/VendorSuggestedJobAction";





export const VendorSuggestedJobDispatcherContext = React.createContext<React.Dispatch<IVendorSuggestedJobAction> | null>(null);
export const VendorSuggestedJobStateContext = React.createContext<IVendorSuggestedJobManagementState | null>(null);

export interface IVendorSuggestedJobManagementProps extends RouteComponentProps<any> { }

export interface IVendorSuggestedJobManagementState {
    visible:boolean;
    value:number;
    vendorSuggestedJobs: VendorSuggestedJobViewModel,
    vendorSuggestedJobTitle:VendorSuggestedJobTitleViewModel
}

export const initialVendorSuggestedJobManagementState = {
    visible:false,
    value:0,
    vendorSuggestedJobs:{} as VendorSuggestedJobViewModel,
    vendorSuggestedJobTitle:{} as VendorSuggestedJobTitleViewModel
   
} as IVendorSuggestedJobManagementState;

export const VendorSuggestedJobContextProvider: React.FC = ({ children }) => {
    const [vendorSuggestedJobState, dispatcher] = useImmerReducer(vendorSuggestedJobReducer, initialVendorSuggestedJobManagementState);

    return (
        <VendorSuggestedJobDispatcherContext.Provider value={dispatcher}>
            <VendorSuggestedJobStateContext.Provider value={vendorSuggestedJobState}>
                {children}
            </VendorSuggestedJobStateContext.Provider>
        </VendorSuggestedJobDispatcherContext.Provider>
    )
}