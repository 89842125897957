import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  CareerTipsApprovalViewModel,
  CareerTipsApprovalWithOutTokenViewModel,
  CareerTipsApprovalSaveRespondModel,
  CareerTipsResultModel,
  CareerTipsSelectBoxDataViewModel,
  CareerTipsCategoryViewModel,
} from "../../models/admin/CareerTipsApproval";
import {
  ICareerTipsApprovalAction,
  careerTipsApprovalReducer,
} from "../../action/admin/CareerTipsApprovalAction";
import {
  getApproveOrRejectResultModel,
  GoogleSlideUrlList,
} from "../../models/admin/VendorApproval";

export const CareerTipsApprovalDispatcherContext = React.createContext<React.Dispatch<ICareerTipsApprovalAction> | null>(
  null
);
export const CareerTipsApprovalStateContext = React.createContext<ICareerTipsApprovalManagementState | null>(
  null
);

export interface ICareerTipsApprovalManagementProps
  extends RouteComponentProps<any> {}

export interface ICareerTipsApprovalManagementState {
  visible: boolean;
  value: number;
  careerTipsApproval: CareerTipsApprovalViewModel;
  careerTipsApprovalWithOutToken: CareerTipsApprovalWithOutTokenViewModel;
  CareerTipsResultModel: CareerTipsResultModel;
  careerTipsApprovalSaveRespond: CareerTipsApprovalSaveRespondModel;
  CareerTipsSelectBoxDataViewModel: CareerTipsSelectBoxDataViewModel;
  careerTipsCategory: CareerTipsCategoryViewModel;
  careerTipsByCategory: CareerTipsApprovalWithOutTokenViewModel;
  approveOrRejectResult: getApproveOrRejectResultModel;
  googleSlideUrlList: GoogleSlideUrlList;
  googleSlideUrlSave: CareerTipsApprovalSaveRespondModel;
}

export const initialCareerTipsApprovalManagementState = {
  visible: false,
  value: 0,
  careerTipsApproval: {} as CareerTipsApprovalViewModel,
  careerTipsApprovalWithOutToken: {} as CareerTipsApprovalWithOutTokenViewModel,
  CareerTipsResultModel: {} as CareerTipsResultModel,
  careerTipsApprovalSaveRespond: {} as CareerTipsApprovalSaveRespondModel,
  CareerTipsSelectBoxDataViewModel: {} as CareerTipsSelectBoxDataViewModel,
  careerTipsCategory: {} as CareerTipsCategoryViewModel,
  careerTipsByCategory: {} as CareerTipsApprovalWithOutTokenViewModel,
  approveOrRejectResult: {} as getApproveOrRejectResultModel,
  googleSlideUrlList: {} as GoogleSlideUrlList,
  googleSlideUrlSave: {} as CareerTipsApprovalSaveRespondModel,
} as ICareerTipsApprovalManagementState;

export const CareerTipsApprovalContextProvider: React.FC = ({ children }) => {
  const [careerTipsApprovalState, dispatcher] = useImmerReducer(
    careerTipsApprovalReducer,
    initialCareerTipsApprovalManagementState
  );

  return (
    <CareerTipsApprovalDispatcherContext.Provider value={dispatcher}>
      <CareerTipsApprovalStateContext.Provider value={careerTipsApprovalState}>
        {children}
      </CareerTipsApprovalStateContext.Provider>
    </CareerTipsApprovalDispatcherContext.Provider>
  );
};
