import React from 'react'
import _ from "lodash";

interface ReactSelectOption {
  value: string,
  label: string
}
function Expertise(props: any) {

  const { ResumeTemplateSelectBoxData, setFieldOfExpertise } = props;

  let expertises: ReactSelectOption[] = [];

  if (!_.isEmpty(ResumeTemplateSelectBoxData.fieldOfExeprtises)) {

    expertises = ResumeTemplateSelectBoxData.fieldOfExeprtises.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  return (
    <React.Fragment>
      <div className="heading_sec1">
        <h1>Field of expertise</h1>
      </div>
      <div className="section_box4">
        {expertises.filter(f => f.value != "0").map((e: any, i: any) =>
          <div className="col-xs-4">
            <input id={e.value} type="radio" name="radioExpertise" defaultValue={e.value} onClick={() => { setFieldOfExpertise(e.value); }} /><label htmlFor={e.value}><span><span /></span> {e.label}</label>
          </div>

        )}

      </div>

    </React.Fragment>
  );

};
export default Expertise