import { Reducer } from "react";
import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  MyTeamMemberRequestModel,
  MyTeamMember,
  MyTeamMemberViewModel,
  MyTeamMemberSaveRequestModel,
  MyTeamMemberSaveRespondModel,
  MyTeamMemberEditRequestModel,
  MyTeamMemberResultModel,
  MyTeamMemberGetDataWithId,
  MyTeamMemberSelectBoxDataViewModel,
  MyTeamMemberDeleteRequestModel,
  MyTeamMemberDeleteResultModel
} from "../../models/admin/MyTeamMember";
import {
  IMyTeamMemberManagementState,
  MyTeamMemberDispatcherContext,
  MyTeamMemberStateContext,
} from "../../context/admin/MyTeamMemberContext";
import { message } from "antd";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IMyTeamMemberAction =
  | {
      type: "MyTeamMember_LIST";
      MyTeamMember: MyTeamMemberViewModel;
    }
  | {
      type: "MyTeamMember_SELECTBOX_LIST";
      MyTeamMemberSelectBoxDataViewModel: MyTeamMemberSelectBoxDataViewModel;
    }
  | {
      type: "GET_MyTeamMember";
      MyTeamMemberResultModel: MyTeamMemberResultModel;
    }
  | {
      type: "EDIT_MyTeamMember";
      MyTeamMemberSaveRespond: MyTeamMemberSaveRespondModel;
    }
  | {
      type: "DELETE_MyTeamMember";
      MyTeamMemberDeleteResultModel: MyTeamMemberDeleteResultModel;
    };

export const MyTeamMemberReducer: Reducer<
  IMyTeamMemberManagementState,
  IMyTeamMemberAction
> = (draft, action): IMyTeamMemberManagementState => {
  switch (action.type) {
    case "MyTeamMember_LIST":
      draft.MyTeamMember = action.MyTeamMember;
      return draft;
    case "GET_MyTeamMember":
      draft.MyTeamMemberResultModel = action.MyTeamMemberResultModel;
      return draft;
    case "EDIT_MyTeamMember":
      draft.MyTeamMemberSaveRespond = action.MyTeamMemberSaveRespond;
      return draft;
    case "DELETE_MyTeamMember":
      draft.MyTeamMemberDeleteResultModel = action.MyTeamMemberDeleteResultModel;
      return draft;
    case "MyTeamMember_SELECTBOX_LIST":
      draft.MyTeamMemberSelectBoxDataViewModel = action.MyTeamMemberSelectBoxDataViewModel;
      return draft;
  }
};

export const useMyTeamMemberDispatcher = (): React.Dispatch<IMyTeamMemberAction> => {
  const MyTeamMemberDispatcher = React.useContext(MyTeamMemberDispatcherContext);
  if (!MyTeamMemberDispatcher) {
    throw new Error(
      "You have to provide the MyTeamMember dispatcher using theMyTeamMemberDispatcherContext.Provider in a parent component."
    );
  }
  return MyTeamMemberDispatcher;
};

export const useMyTeamMemberContext = (): IMyTeamMemberManagementState => {
  const MyTeamMemberContext = React.useContext(MyTeamMemberStateContext);
  if (!MyTeamMemberContext)
    throw new Error(
      "You have to provide the MyTeamMember context using the MyTeamMemberStateContext.Provider in a parent component."
    );
  return MyTeamMemberContext;
};

export const getMyTeamMemberList = async (
  dispatcher: React.Dispatch<IMyTeamMemberAction>,
  query: MyTeamMemberRequestModel,
  //token: string
) => {
  try {
    // if (token?.startsWith(`"`)) {
    //   token = token.substring(1);
    //   token = token.slice(0, -1);
    // }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      //Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetMyTeamMemberList;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "MyTeamMember_LIST", MyTeamMember: res.data });
    });
  } catch (e) {}
};

export const deleteMyTeamMember = async (
  dispatcher: React.Dispatch<IMyTeamMemberAction>,
  query: MyTeamMemberDeleteRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.DeleteMyTeamMember;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "DELETE_MyTeamMember", MyTeamMemberDeleteResultModel: res.data });
      res.status == 200
        ? res.data.isSuccess
          ? message.success("Team member removed successfully.")
          : message.error(res.data.message)
        : message.error(" Some error happend on removing team member.");
    });


  } catch (e) {}
};

export const editMyTeamMember = async (
  dispatcher: React.Dispatch<IMyTeamMemberAction>,
  query: MyTeamMemberEditRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.EditMyTeamMember;
    message.loading("please wait..");
    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "EDIT_MyTeamMember",
        MyTeamMemberSaveRespond: res.data,
      });

      res.status == 200
        ? message.success("Team member is updated.")
        : message.error("There are some error happend on updating team member.");
    });
  } catch (e) {}
};

export const getMyTeamMember = async (
  dispatcher: React.Dispatch<IMyTeamMemberAction>,
  query: MyTeamMemberGetDataWithId,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetMyTeamMember;
    if (query.rowId != 0) {
      axios
        .post(url, JSON.stringify(query), { headers: header })
        .then((res) => {
          dispatcher({
            type: "GET_MyTeamMember",
            MyTeamMemberResultModel: res.data,
          });
        });
    } else {
      dispatcher({
        type: "GET_MyTeamMember",
        MyTeamMemberResultModel: {} as MyTeamMemberResultModel,
      });
    }
  } catch (e) {}
};

export const getMyTeamMemberSelectBoxList = async (
  dispatcher: React.Dispatch<IMyTeamMemberAction>,
  token: string
) => {
  try {
     if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetMyTeamMemberSelectBoxData;

    axios.post(url, JSON.stringify({}), { headers: header }).then((res) => {
      dispatcher({ type: "MyTeamMember_SELECTBOX_LIST", MyTeamMemberSelectBoxDataViewModel: res.data });
    });
  } catch (e) {}
};