import { ErrorMessage } from "@hookform/error-message";
import React, { useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import {
  getNewlyPostedJobList,
  useNewlyPostedJobContext,
  useNewlyPostedJobDispatcher,
} from "../../../action/general/NewlyPostedJobAction";
import { GetAllBannerRequestModel } from "../../../models/admin/Banner";
import {
  getSearchListWithOutToken,
  getSearchListWithToken,
  useSearchContext,
  useSearchDispatcher,
} from "../../../action/general/SearchAction";
import { useMyProfileContext } from "../../../action/MyProfileAction";
import { newlyPostedJobRequestModel } from "../../../models/general/NewlyPostedJob";
import { searchDataWithOutTokenRequestModel } from "../../../models/general/Search";
import AuthService from "../../../services/AuthService";
import SelectOption from "../../candidate/my_profile/components/SelectOptionForSearch";
import {
  getAchievementsListWithOutToken,
  getClientsListWithOutToken,
  useAchievementsContext,
  useAchievementsDispatcher,
} from "../../../action/admin/AchievementsAction";
import {
  AchievementsRequestModel,
  ClientsRequestModel,
} from "../../../models/admin/Achievements";
import { Divider, notification } from "antd";
import {
  GetAllBanners,
  GetAllBannerswithoutoken,
  useBannerContext,
  useBannerDispatcher,
} from "../../../action/admin/BannerAction";
import { environment } from "../../../environments/environment";
import { render } from "@testing-library/react";
import { settings } from "cluster";

interface ReactSelectOption {
  value: string;
  label: string;
}
interface IIndexContentComponentProps {}

interface IIndexContentComponentState {
  jobTitle: string;
  location: string;
  type: string;
  jobTypeData: string;
}

const defaultValues = {
  jobTitle: "",
  location: "",
  type: "",
  jobTypeData: "",
};

const IndexContentComponent: React.FC<IIndexContentComponentProps> = (
  props
) => {
  const [
    IndexContentComponentState,
    setIndexContentComponentState,
  ] = React.useState<IIndexContentComponentState>(defaultValues);

  let jobCategoryData: ReactSelectOption[] = [];
  let jobTypeDatas: ReactSelectOption[] = [];

  const authorizationToken = AuthService.accessToken;
  const user = AuthService.currentUser;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isRead, setIsRead] = useState({});
  const [isShowVendor, setIsShowVendor] = useState<boolean>(false);
  const [isShowClient, setIsShowClient] = useState<boolean>(false);

  const newlyPostedJobDispatcher = useNewlyPostedJobDispatcher();
  const newlyPostedJobContext = useNewlyPostedJobContext();
  const { newlyPostedJob } = newlyPostedJobContext;

  const searchDispatcher = useSearchDispatcher();

  const myProfileContext = useMyProfileContext();
  const { myProfile, loggedUserId } = myProfileContext;

  const achievementsDispatcher = useAchievementsDispatcher();
  const achievementsContext = useAchievementsContext();
  const { AchievementsWithOutToken, ClientsWithOutToken } = achievementsContext;
  const bannerDispatcher = useBannerDispatcher();
  const bannerContext = useBannerContext();
  const {
    bannersList,
    deleteBannerRespond,
    addBannerRespond,
    bannerStatusRespond,
    editBaneerRespond,
  } = bannerContext;

  console.log(
    "----------AchievementsWithOutToken-----------",
    AchievementsWithOutToken
  );

  let role = AuthService.currentUser?.roles[0];

  console.log(500, AuthService.currentUser?.email);

  React.useEffect(() => {
    (async () => {
      await GetAllBannerswithoutoken(bannerDispatcher, {
        Page: 1,
        BannerStatus: true,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      } as GetAllBannerRequestModel);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      await getNewlyPostedJobList(newlyPostedJobDispatcher, {
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      } as newlyPostedJobRequestModel);
    })();
  }, []);

  React.useEffect(() => {
    let array1 = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
    ];
    let array2 = [
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "210",
      "211",
      "212",
      "213",
      "214",
      "215",
    ];
    array1.forEach((element) => {
      notification.close(element);
    });
    array2.forEach((element) => {
      notification.close(element);
    });
  }, []);

  let history = useHistory();

  React.useEffect(() => {
    (async () => {
      await getAchievementsListWithOutToken(achievementsDispatcher, {
        userId: 0,
        page: 1,
        pageSize: 60,
        searchTerm: "",
        showInactive: false,
        sortOrder: "",
      } as AchievementsRequestModel);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      await getClientsListWithOutToken(achievementsDispatcher, {
        adminId: 0,
        page: 1,
        pageSize: 60,
        searchTerm: "",
        showInactive: false,
        sortOrder: "",
      } as ClientsRequestModel);
    })();
  }, []);

  // React.useEffect(() => {
  //   if(1){
  //   (async () => {
  //       await getSearchListWithOutToken(searchDispatcher,{expereince:[],location:[''],
  //       title:[''],type:[''],lastDays:[],pageIndex:1,pageSize:10,showInactive:true
  //     } as searchDataWithOutTokenRequestModel)

  //   })();
  // }
  // }, [])

  // if (!newlyPostedJob || !newlyPostedJob.Data || newlyPostedJob.Data.length <= 0) {
  //   return (
  //     <>
  //       <h1>Loding...</h1>
  //       </>
  //   );
  // }

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<IIndexContentComponentState>({
    defaultValues,
  });

  const onSubmit = (data: any) => {
    if (authorizationToken != null && loggedUserId != 0) {
      console.log(39393, data);

      history.push(
        `/jobSearch/searchtitle/0/${
          data.jobTitle === "" ? "searchtitle" : data.jobTitle
        }/${data.location === "" ? "location" : data.location}/${
          data.jobTypeData === "" ? "0" : data.jobTypeData
        }`
      );
    } else {
      history.push(
        `/jobSearch/newlyPosted/0/${
          data.jobTitle === "" ? "searchtitle" : data.jobTitle
        }/${data.location === "" ? "location" : data.location}/${
          data.jobTypeData === "" ? "0" : data.jobTypeData
        }`
      );
    }
  };

  const handleRedirect = () => {
    history.push(`/jobSearch/searchtitle/0/searchtitle/location/0`);

    // if (user?.roles[0] === "Vendor") {
    //   history.push("/job_search_vendor/search/0");
    // } else {
    //   history.push("/job_search/0");
    // }
  };

  // React.useEffect(() => {
  //   if(AchievementsWithOutToken.data != undefined)
  //    {
  //      AchievementsWithOutToken.data.map((achievement, i) => {
  //          items[achievement.rowId]  = false;
  //          console.log(achievement.rowId,'handleRead:',items[achievement.rowId]);
  //     });
  //   }
  // }, [AchievementsWithOutToken]);

  // const handleRead = (rowId : number) => {
  //   console.log('handleRead 0:');
  //    if(AchievementsWithOutToken.data != undefined)
  //    {
  //      AchievementsWithOutToken.data.map((achievement, i) => {
  //           if(achievement.rowId === rowId)
  //           {
  //              items[achievement.rowId]  = !items[achievement.rowId];
  //              console.log(achievement.rowId,'handleRead 1:', items[achievement.rowId] );
  //           }
  //     });
  //   }
  // };

  const [selItems, setSelItems] = useState(0);
  const handleRead = (rowId: number, desc: string) => {
    console.log("handleRead 0:");
    if (selItems === rowId) {
      setSelItems(0);
    } else {
      setSelItems(rowId);
    }
  };
  // console.log(bannersList.data);

  return (
    <>
      <div className="clearfix" />

      <div className="banners">
        {bannersList != undefined &&
        bannersList.data != undefined &&
        bannersList.data.length > 0 ? (
          <Carousel>
            {bannersList != undefined &&
            bannersList.data != undefined &&
            bannersList.data.length > 0
              ? bannersList.data.map((data, index) => {
                  return (
                    <Carousel.Item>
                      <div className="home_banner">
                        <div
                          className="banner_con"
                          style={{
                            backgroundImage: `url(${
                              environment.baseUrl +
                              "/Upload/Banners" +
                              `/${data.bannerFileName}`
                            })`,
                            width: "100%",
                            height: "100%",
                            padding: "300px 0px 280px 0px",
                          }}
                        >
                          <div className=" container">
                            <div className="row">
                              <Carousel.Caption>
                                <div className="col-sm-12">
                                  <div className="banner_heading">
                                    <span>
                                      {" "}
                                      {data.title != null ? data.title : ""}
                                    </span>
                                  </div>
                                  <div className="banner_content">
                                    {data.description != null
                                      ? data.description
                                      : ""}
                                  </div>
                                </div>
                              </Carousel.Caption>
                            </div>
                          </div>
                        </div>

                        <div className="banner_border">
                          <img
                            // src={
                            //   data.bannerFileName != null ?
                            //     environment.baseUrl + '/Upload/Banners' +
                            //     `/${data.bannerFileName}`
                            //     : require("../../../images/banner_sh.png")
                            // }
                            // src={require("../../../images/banner_sh.png}")}
                            //src="images/banner_sh.png"
                            className=" img-responsive"
                          />
                        </div>
                        <div className="clearfix" />
                      </div>
                    </Carousel.Item>
                  );
                })
              : null}
          </Carousel>
        ) : null}
      </div>

      <div className="clearfix" />

      <div className="categories">
        <div className=" container">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <a
                onClick={
                  authorizationToken != null
                    ? () => {
                        history.push("/candidate/my-resume");
                      }
                    : () => {
                        setIsOpen(!isOpen);
                      }
                }
                data-target="#didgilocker"
                data-toggle="modal"
              >
                <div className="categories_sec">
                  <div>
                    <img
                      src={require("../../../images/resume_builder.png")}
                      // src="images/resume_builder.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Resume Builder</h1>
                  <p>
                    Build your resume and design a cover letter in quick time
                    with our RESUME BUILDER
                  </p>
                </div>
              </a>
            </div>
            <div className="col-sm-6 col-lg-3">
              <a
                onClick={
                  authorizationToken != null
                    ? () => {
                        history.push("/candidate/digilocker/3");
                      }
                    : () => {
                        setIsOpen(!isOpen);
                      }
                }
                data-toggle="modal"
              >
                <div className="categories_sec">
                  <div>
                    <img
                      src={require("../../../images/digi_locker.png")}
                      // src="images/digi_locker.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Digi Locker</h1>
                  <p>
                    On the go, anytime, anywhere access to your documents. Keep
                    your personal and professional documents secured and manage
                    their access level.
                  </p>
                </div>
              </a>
            </div>
            <div className="col-sm-6 col-lg-3">
              <a
                onClick={
                  authorizationToken != null
                    ? () => {
                        history.push("/career_developer");
                      }
                    : () => {
                        setIsOpen(!isOpen);
                      }
                }
                data-target="#didgilocker"
                data-toggle="modal"
              >
                <div className="categories_sec">
                  <div>
                    <img
                      src={require("../../../images/career_developer.png")}
                      // src="images/career_developer.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Career Developer</h1>
                  <p>
                    Our Career Developer platform helps you strengthen your
                    professional pursuit and provides training into vast fields
                    of technology and management
                  </p>
                </div>
              </a>
            </div>
            <div className="col-sm-6 col-lg-3">
              <a onClick={handleRedirect}>
                <div className="categories_sec">
                  <div>
                    <img
                      src={require("../../../images/apply_jobs.png")}
                      // src="images/apply_jobs.png"
                      className="center-block"
                    />
                  </div>
                  <h1>Apply Jobs</h1>
                  <p>
                    {" "}
                    JIT Career is an ocean of opportunities for you to sail
                    through it and make a mark
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* BEGIN MODAL */}
        <div className="modal fade none-border" id="didgilocker">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  aria-hidden="true"
                  data-dismiss="modal"
                  className="close"
                  type="button"
                >
                  ×
                </button>
                <h4 className="modal-title">
                  Login as candidate to use this feature.
                </h4>
              </div>
              <div className="col-sm-12 m_t_30 text-center">
                <button className="btn btn-success " type="button">
                  Login Now
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div className="modal-footer  m-t-30"></div>
            </div>
          </div>
        </div>
        {/* END MODAL */}
        <div className=" container">
          <div className="carees_search">
            <div className="row">
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="search_forms">
                  <div className="cr_serach_br">
                    <input
                      name="jobTitle"
                      ref={register({ required: false })}
                      type="text"
                      className="form-control"
                      placeholder="Job Title"
                    />
                    <div className="search_icons">
                      <img
                        src={require("../../../images/search_icon.png")}
                        //  src="images/search_icon.png"
                      />
                    </div>
                  </div>
                </div>
                {/* <ErrorMessage errors={errors} name="jobType" render={({ message }) => <div className="register_validation">{message}</div>} /> */}
                <div className="search_forms">
                  <div className="cr_serach_br">
                    <span className="select-search">
                      <input
                        name="location"
                        ref={register({ required: false })}
                        type="text"
                        className="form-control"
                        placeholder="location"
                      />
                    </span>

                    <div className="search_icons">
                      <img
                        src={require("../../../images/locattion_icon.png")}
                        // src="images/locattion_icon.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="search_forms">
                  <div className="cr_serach_br">
                    <span className="select-search">
                      <Controller
                        control={control}
                        name="jobTypeData"
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              myProfile.jobTypes != undefined
                                ? myProfile.jobTypes.map((e) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : jobTypeDatas
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                            nameForLablel="Job Type"
                          />
                        )}
                      />
                      {/* <span className="holder">Type</span> */}
                    </span>
                    <div className="search_icons">
                      <img
                        src={require("../../../images/type_icon.png")}
                        // src="images/type_icon.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="search_button">
                  <div className="search">
                    <button className="CustomButtonCss" type="submit">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="how_its_work">
        <div className=" container">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <h1>How It Works</h1>
            </div>
            <div className="col-sm-3">
              <div className="how_its_work_br">
                <img
                  src={require("../../../images/how_its_work_br.png")}
                  // src="images/how_its_work_br.png"
                />
              </div>
              <div>
                <img
                  src={require("../../../images/how_its_work1.png")}
                  // src="images/how_its_work1.png"
                  className="center-block"
                />
              </div>
              <h2>
                Register with <br />
                JIT Career
              </h2>
            </div>
            <div className="col-sm-3">
              <div className="how_its_work_br1">
                <img
                  //  src="images/how_its_work_br1.png"
                  src={require("../../../images/how_its_work_br1.png")}
                />
              </div>
              <div>
                <img
                  src={require("../../../images/how_its_work2.png")}
                  // src="images/how_its_work2.png"
                  className="center-block"
                />
              </div>
              <h2>Get free training with our career development platform.</h2>
            </div>
            <div className="col-sm-3">
              <div className="how_its_work_br">
                <img
                  src={require("../../../images/how_its_work_br.png")}
                  // src="images/how_its_work_br.png"
                />
              </div>
              <div>
                <img
                  src={require("../../../images/how_its_work3.png")}
                  // src="images/how_its_work3.png"
                  className="center-block"
                />
              </div>
              <h2>
                Build a resume and
                <br />
                apply jobs
              </h2>
            </div>
            <div className="col-sm-3">
              <div>
                <img
                  src={require("../../../images/how_its_work4.png")}
                  // src="images/how_its_work4.png"
                  className="center-block"
                />
              </div>
              <h2>Get placed with the best employer for you</h2>
            </div>
          </div>
        </div>
      </div>
      {/*       
      <div className="video_sec">
        <div className=" container">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <h1>JIT Career Developer: the learning platform</h1>
              <p>
                Team of professionals in the training and placement <br />
                industry sharing their insights.
              </p>
            </div>
            <div className="col-sm-8 col-sm-offset-2">
              <div className="video_sec_br">
                <div className="video_sec_br1">
                  <a href="#">
                    <img
                      src={require("../../../images/video_img.jpg")}
                      // src="images/video_img.jpg"
                      className=" img-responsive center-block"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      */}
      <div className="career_tips">
        <Link to="/career_tips">
          <div className=" container">
            <div className="row">
              <div className="col-sm-12 col-lg-12">
                <h1>Career Tips</h1>
                <p>
                  Love the job you have? Good—
                  <br />
                  keep looking at other jobs anyway.
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
      {authorizationToken != null && role === "Candidate" ? null : (
        <>
          <div className="vender_h">
            <div className=" container">
              <div className="row">
                <div className="col-sm-12 col-lg-10  col-lg-offset-1">
                  <h1>Vendor</h1>
                  <div className="vender_sec">
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="vender_icon">
                          <img
                            src={require("../../../images/vendor_partner.png")}
                            // src="images/vendor_partner.png"
                            className="center-block"
                          />
                        </div>
                        <h2>Partner With JIT</h2>
                        <p>Grow your business with JIT</p>
                        <div className="view_more">
                          <a
                            onClick={() => {
                              setIsShowVendor(!isShowVendor);
                            }}
                          >
                            View More
                          </a>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="vender_br">
                          <img
                            src={require("../../../images/vendor_sec.png")}
                            // src="images/vendor_sec.png"
                            className="img-responsive center-block"
                          />
                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="vender_icon">
                          <img
                            src={require("../../../images/vendor_dashboard.png")}
                            // src="images/vendor_dashboard.png"
                            className="center-block"
                          />
                        </div>
                        <h2>Dashboard</h2>
                        <p>One stop solution for you</p>
                        <div className="view_more">
                          {authorizationToken !== null ? (
                            <Link to={`${role}`}>View More</Link>
                          ) : (
                            <Link to="/login">View More</Link>
                          )}
                          {/* <a href="#">View More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="vender_h"
            style={{ marginTop: -150, display: isShowVendor ? "" : "none" }}
          >
            <div className=" container">
              <div className="row">
                <div className="col-sm-12 col-lg-10  col-lg-offset-1">
                  <Divider style={{}} />
                  <h4 style={{ fontSize: "large" }}>
                    <b>Individuals</b>
                  </h4>{" "}
                  <h5 style={{ fontSize: "medium" }}>
                    • Individuals who possess HR skills can associate with the
                    JIT team to receive opportunities to source and supply
                    employees worldwide.
                  </h5>
                  <br />{" "}
                  <h5 style={{ fontSize: "medium" }}>
                    • Individuals may receive great reach and opportunities to
                    become professionals.
                  </h5>
                  <br />{" "}
                  <h5 style={{ fontSize: "medium" }}>
                    {" "}
                    • Individuals have high chances of receiving opportunities
                    from corporates.
                  </h5>{" "}
                  <br />
                  <h4 style={{ fontSize: "large" }}>
                    <b>Organisation</b>{" "}
                  </h4>{" "}
                  <h5 style={{ fontSize: "medium" }}>
                    {" "}
                    • They can improve business possibilities- to various
                    countries and various clients.
                  </h5>
                  <Divider />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="newly_posted">
        <div className=" container">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <h1>Newly Posted jobs</h1>
              <p>Leading Employers already using job and talent.</p>
              {!newlyPostedJob ||
              !newlyPostedJob.data ||
              newlyPostedJob.data.length <= 0 ? (
                <h1>loading..</h1>
              ) : (
                newlyPostedJob.data.slice(0, 6).map((job, index) => {
                  return (
                    <div className="newly_posted_sec" key={index}>
                      {user?.roles[0] === "Vendor" ? (
                        <a
                          onClick={() =>
                            history.push(
                              `/jobSearch/newlyPosted/${job.jobId}/searchtitle/location/0`
                            )
                          }
                        >
                          <div className="col-sm-1 col-xs-3">
                            <img
                              src={require("../../../images/jobs_icon.png")}
                              // src="images/jobs_icon.png"
                              className="center-block img-responsive"
                            />
                          </div>
                          <div className="col-sm-8 col-xs-10">
                            <h2>{job.title}</h2>
                            <div className="newly_company">{job.industry}</div>
                            <div className="newly_place">
                              <img
                                src={require("../../../images/job_-place.png")}
                                // src="images/job_-place.png"
                              />{" "}
                              {job.location}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className={`${
                                job.jobType != "Permanent"
                                  ? "parttime"
                                  : "fulltime"
                              }`}
                            >
                              {job.jobType}
                            </div>
                            <div className="clearfix" />
                            <div className="posted_time">{job.postedDate}</div>
                          </div>
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            history.push(
                              `/jobSearch/newlyPosted/${job.jobId}/searchtitle/location/0`
                            )
                          }
                        >
                          <div className="col-sm-1 col-xs-3">
                            <img
                              src={require("../../../images/jobs_icon.png")}
                              // src="images/jobs_icon.png"
                              className="center-block img-responsive"
                            />
                          </div>
                          <div className="col-sm-8 col-xs-10">
                            <h2>{job.title}</h2>
                            <div className="newly_company">{job.industry}</div>
                            <div className="newly_place">
                              <img
                                src={require("../../../images/job_-place.png")}
                                // src="images/job_-place.png"
                              />{" "}
                              {job.location}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className={`${
                                job.jobType != "Permanent"
                                  ? "parttime"
                                  : "fulltime"
                              }`}
                            >
                              {job.jobType}
                            </div>
                            <div className="clearfix" />
                            <div className="posted_time">{job.postedDate}</div>
                          </div>
                        </a>
                      )}
                    </div>
                  );
                })
              )}

              <div className="view_more1">
                <a onClick={handleRedirect}>View More Jobs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {authorizationToken != null && role === "Candidate" ? null : (
        <div className="clients_h">
          <div className=" container">
            <div className="row">
              <div className="col-sm-12 ">
                <h1>Clients</h1>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="clients_sec">
                  <div className="row">
                    <div className="col-sm-2">
                      <img
                        src={require("../../../images/client_partner.png")}
                        className="center-block"
                      />
                    </div>
                    <div className="col-sm-6">
                      <h2>Partner With JIT</h2>
                      <p>Let JIT find right resources for your company</p>
                    </div>
                    <div className="col-sm-4">
                      <div className="view_more2">
                        <a
                          onClick={() => {
                            setIsShowClient(!isShowClient);
                          }}
                        >
                          View More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="clients_sec">
                  <div className="row">
                    <div className="col-sm-2">
                      <img
                        src={require("../../../images/client_dash.png")}
                        className="center-block"
                      />
                    </div>
                    <div className="col-sm-6">
                      <h2>Dashboard</h2>
                      <p>One stop solution for you</p>
                    </div>
                    <div className="col-sm-4">
                      <div className="view_more2">
                        {authorizationToken !== null ? (
                          <Link to={`${role}`}>View More</Link>
                        ) : (
                          <Link to="/login">View More</Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <>
        <div
          className="vender_h"
          style={{ marginTop: -150, display: isShowClient ? "" : "none" }}
        >
          <div className=" container">
            <div className="row">
              <div className="col-sm-12 col-lg-10  col-lg-offset-1">
                <Divider style={{}} />
                <h5 style={{ fontSize: "medium" }}>
                  • If the client teams up with companies that possess high
                  career supportive knowledge, the chances of receiving
                  professionals as their employees are high.
                </h5>
                <br />{" "}
                <h5 style={{ fontSize: "medium" }}>
                  • Availability of manually managed or filtered database.
                </h5>
                <br />{" "}
                <h5 style={{ fontSize: "medium" }}>
                  • The experience of HR experts from various countries will be
                  received in the sourcing process.
                </h5>{" "}
                <br />{" "}
                <h5 style={{ fontSize: "medium" }}>
                  • Possibilities of hiring potential candidates from the
                  existing database.
                </h5>{" "}
                <br />{" "}
                <h5 style={{ fontSize: "medium" }}>
                  • It is reachable to individual freelance HR consultancy.
                </h5>{" "}
                <Divider />
              </div>
            </div>
          </div>
        </div>
      </>
      <div className="achievements">
        <div className=" container">
          <div className="row">
            <div className="col-sm-12 ">
              <h1>JIT Achievements</h1>
            </div>
            {AchievementsWithOutToken.data != undefined
              ? AchievementsWithOutToken.data.map((achievement, i) => {
                  return (
                    <div className="col-sm-4">
                      <div className="achievements_sec">
                        <div className="achieve_img">
                          <img
                            width="367px!important"
                            height="208px!important"
                            //src={`https://careerapp.azurewebsites.net/Upload/AchievementDocument/${achievement.achievementDocument}`}
                            src={
                              achievement.achievementDocument !== "null" &&
                              achievement.achievementDocument !== null &&
                              achievement.achievementDocument !== undefined
                                ? `https://careerapp.azurewebsites.net/Upload/AchievementDocument/${achievement.achievementDocument}`
                                : require("./../../../images/noimage.png")
                            }
                          />
                        </div>
                        <h2>{achievement.achievementTitle}</h2>
                        <p>
                          {achievement.rowId !== selItems
                            ? achievement.description.length > 45
                              ? achievement.description.substring(0, 45) + "..."
                              : achievement.description
                            : achievement.description}
                        </p>
                        <div className="achieve_date">
                          {" "}
                          <img
                            src={require("../../../images/achive_date.png")}
                          />{" "}
                          {achievement.publishedDate}{" "}
                        </div>
                        <div className="achieve_more">
                          <a
                            onClick={() =>
                              handleRead(
                                achievement.rowId,
                                achievement.description
                              )
                            }
                          >
                            <img
                              src={require("../../../images/achive_more.png")}
                            />{" "}
                            {achievement.rowId === selItems && (
                              <>
                                {" "}
                                {achievement.rowId === selItems
                                  ? "Read Less"
                                  : "Read More"}{" "}
                              </>
                            )}
                            {achievement.rowId !== selItems && <> Read More </>}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <div className="associations">
        <div className="container ">
          <div className="row">
            <div className="col-sm12">
              <h1>Our Associations</h1>
            </div>
          </div>
          <div className="our_client_logos">
            <div className="col-sm-12 ">
              <div
                id="owl-example"
                className="owl-carousel owl-theme"
                style={{ opacity: 1, display: "block" }}
              >
                <div className="owl-wrapper-outer">
                  <div
                    className="owl-wrapper"
                    style={{ width: "3600px", left: "0px", display: "block" }}
                  >
                    {ClientsWithOutToken.data != undefined
                      ? ClientsWithOutToken.data.map((client, i) => {
                          return (
                            <div
                              className="owl-item"
                              style={{ width: "300px" }}
                            >
                              <div className="item">
                                <img
                                  //src={`https://careerapp.azurewebsites.net/Upload/ClientLogo/${client.imageURL}`}
                                  src={
                                    client.imageURL !== "null" &&
                                    client.imageURL !== null &&
                                    client.imageURL !== undefined
                                      ? `https://careerapp.azurewebsites.net/Upload/ClientLogo/${client.imageURL}`
                                      : require("./../../../images/noimage.png")
                                  }
                                  alt="Clients"
                                  className="center-block"
                                />
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="owl-controls clickable">
                  <div className="owl-pagination">
                    <div className="owl-page active">
                      <span className="" />
                    </div>
                    {/* <div className="owl-page">
                      <span className="" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
      <div className="newsletter_sec">
        <div className="container ">
          <div className="row">
            <div className="col-sm-3 col-sm-offset-1">
              <h1>Newsletter SignUp</h1>
              <p>Subscribe to get latest newsletter. </p>
            </div>
            <div className="col-sm-7  col-sm-offset-1">
              <div className="newsletter">
                <div className="newsletter_form">
                  <input
                    name=""
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                  />
                </div>
                <div className="newsletter_btn">
                  <a href="#">Subscribe</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />

        <Modal
          show={isOpen}
          onHide={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Login as candidate to use this feature.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-12 m_t_30 text-center">
              <button
                className="btn btn-success "
                type="button"
                onClick={() => {
                  history.push("/login");
                }}
              >
                Login Now
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default IndexContentComponent;
