import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { saveTraining, deleteTrainings } from "../../../../apis/candidate";
import { GlobalSpinnerContext } from "../../../../context/GlobalSpinner";
import { toast, ToastContainer } from "react-toastify";
import { confirmWrapper, confirm } from "../../../GlobalConfirm";
import { ErrorMessage } from "@hookform/error-message";
import IsComponentDisabled from "../../../components/IsComponentDisabled";

interface ReactSelectOption {
  value: string;
  label: string;
}

interface ITrainingState {
  trainingCertificate: string;
  institue: string;
}

function Training({
  index,
  item,
  courses,
  disabled,
  removeTraining,
  updateTraining,
}: {
  index: any;
  item: any;
  courses: any[];
  disabled: boolean;
  removeTraining: any;
  updateTraining: any;
}) {
  const defaultValues = {
    trainingCertificate: item["trainingCertificate"],
    institue: item["institute"],
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ITrainingState>({
    defaultValues,
  });

  const onSubmit = (data: any) => {
    if (!disabled) {
      //console.log(data);

      item["Institute"] = data["institue"];
      item["TrainingCertificate"] = data["trainingCertificate"];

      updateTraining(item, index);
    }
  };

  return (
    <div className=" profile_sec_bg">
      <div
        className="close_icon1 _cursor-pointer"
        onClick={() => {
          removeTraining(item, index);
        }}
      >
        <img
          src={require("../../../../images/close_icon.png")}
          width={16}
          height={16}
        />
      </div>
      <div className="row">
        <form
          className="personal_details"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Training Certificate
              </label>
              {IsComponentDisabled(
                <input
                  type="text"
                  name="trainingCertificate"
                  className={"form-control"}
                  placeholder="Type here"
                  ref={register({
                    required: "Training certificate is required",
                  })}
                />,
                disabled
              )}

              <ErrorMessage
                errors={errors}
                name="trainingCertificate"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Institute
              </label>
              {IsComponentDisabled(
                <input
                  type="text"
                  disabled={disabled}
                  className={"form-control validate input-pwd"}
                  placeholder="Type here"
                  name="institue"
                  ref={register({
                    required: "Institute is required",
                  })}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="institue"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <button
              className="btn submit_btn"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

interface ITrainingsState {
  trainingCertificate: string;
  institue: string;
}

function Trainings(props: any) {
  const { getTrainings, trainingState, candidateId } = props;

  //console.log(trainingState)

  const defaultValues = {
    trainingCertificate: "",
    institue: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ITrainingsState>({
    defaultValues,
  });

  const [disabled, setDisabled] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [courses, setCourses] = useState([]);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const onSubmit = (data: any) => {
    //console.log(data);
    globalSpinner.showSpinner();
    saveTraining({
      RowId: 0,
      TrainingId: 0,
      CandidateId: Number(candidateId),
      TrainingCertificate: data["trainingCertificate"],
      Institute: data["institue"],
      ValidUpTo: new Date().toISOString(),
      IsActive: true,
    })
      .then((res) => {
        if (res.data.isSuccess) {
          getTrainings({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success("Training details are saved.");
        } else {
          toast.error(res.data.message);
        }
        globalSpinner.hideSpinner();
        setIsOpen(!isOpen);
      })
      .catch((err) => {
        //console.log(err);
        globalSpinner.hideSpinner();
        toast.error(err.toString());
      });
  };

  const updateTraining = async (e: any, i: any) => {
    //console.log(e, i);
    globalSpinner.showSpinner();
    saveTraining(e)
      .then((res) => {
        //console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          getTrainings({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success("Training detail has been updated.");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        //console.log(err);
        globalSpinner.hideSpinner();
        toast.error(err.toString());
      });
  };

  const removeTraining = async (e: any, i: any) => {
    if (
      await confirm({
        confirmation: "Are you sure you want to delete this?",
        options: {
          yes: "Yes",
          no: "No",
          header: "Delete",
        },
      })
    ) {
      //console.log('yes');
      //console.log(e, i);
      globalSpinner.showSpinner();
      deleteTrainings({ RowId: e.rowId })
        .then((res) => {
          //console.log(res);
          globalSpinner.hideSpinner();
          if (res.data.isSuccess) {
            getTrainings({
              CandidateId: Number(candidateId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "string",
              SortOrder: "string",
              ShowInactive: false,
            });
            toast.success("Training detail has been removed.");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          //console.log(err);
          globalSpinner.hideSpinner();
          toast.error(err.toString());
        });
    } else {
      //console.log('no');
      //console.log(e, i);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-5">
        <span className="resp-arrow" />
        Trainings
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-5"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Trainings</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>

        <div style={{ paddingBottom: "50px" }}>
          <a
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="btn add_btn"
          >
            Add Training
          </a>
        </div>

        {trainingState.data.map((e: any, i: any) => (
          <Training
            index={i}
            key={i}
            item={e}
            disabled={disabled}
            courses={courses}
            removeTraining={removeTraining}
            updateTraining={updateTraining}
          />
        ))}
      </div>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Add Training</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Training Certificate
              </label>
              <input
                type="text"
                name="trainingCertificate"
                className="form-control"
                placeholder="Type here"
                ref={register({
                  required: "Training certificate is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="trainingCertificate"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Institute
              </label>
              <input
                type="text"
                name="institue"
                className="form-control"
                placeholder="Type here"
                ref={register({
                  required: "Institute is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="institue"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="clearfix" />
        </form>
      </Modal>
    </React.Fragment>
  );
}

export default Trainings;
