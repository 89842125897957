import { Badge, Checkbox, Popover, Tooltip } from "antd";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  GetAllAdminMyJobList,
  GetAllAdminMyJobListPub,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import {
  AdminJobList,
  AdminJobListRequestModel,
  AdminJobListRespondModel,
  AdminPermissionRespondModel,
} from "../../../models/admin/AdminMyJob";
import AuthService from "../../../services/AuthService";

interface IAdminPublishedComponentProps extends RouteComponentProps {
  handleMirrorJob: (id: number) => void;
  jobListInAdmin: AdminJobListRespondModel;
  AdminPermissionResult: AdminPermissionRespondModel;
  handleEdit: (id: number) => void;
  jobStatusData: number;
  loggedAdminId: number;
  onChangeInPub: (list: number, publishedjobState: AdminJobList[]) => void;
  checkedList: number[];
  onCheckAllChangeInPub: (e: any, publishedjobState: AdminJobList[]) => void;
  checkAll: boolean;
}

const AdminPublishedJobComponent: React.FC<IAdminPublishedComponentProps> = (
  props
) => {
  const {
    handleMirrorJob,
    jobListInAdmin,
    AdminPermissionResult,
    handleEdit,
    jobStatusData,
    loggedAdminId,
    onChangeInPub,
    checkedList,
    checkAll,
    onCheckAllChangeInPub,
  } = props;

  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [currentjobId, setCurrentJobId] = useState<number>(0);

  const adminMyJobDispatcher = useAdminMyJobDispatcher();

  const authorizationToken = AuthService.accessToken;

  const [hasMore, setHasMore] = React.useState(true);
  const [totalCount, setTotalCount] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSize = 16;
  const [totalPage, setTotalPage] = useState(1);
  const [publishedjobState, setPublishjobState] = useState<AdminJobList[]>([]);

  // React.useEffect(() => {
  //   //console.log(44444444444, currentPage);
  //   if (jobListInAdmin.data !== undefined && currentPage === 1) {
  //     //console.log(100000, "userList");
  //     //console.log(jobListInAdmin);
  //     setPublishjobState(jobListInAdmin.data);
  //     //setCurrentPage(1);
  //     setTotalPage(Math.ceil(jobListInAdmin.total / currentPageSize));
  //     setTotalCount(jobListInAdmin.total);
  //     //console.log(publishedjobState);
  //   }

  //   if (jobListInAdmin.data !== undefined && currentPage > 1) {
  //     //console.log("Publish");
  //     setPublishjobState([...jobListInAdmin.data, ...publishedjobState]);
  //   }
  // }, [jobListInAdmin.data]);

  const onHCheckAllChangeInPub = (e: any) => {
    onCheckAllChangeInPub(e, publishedjobState);
  };

  React.useEffect(() => {
    if (jobListInAdmin.data !== undefined) {
      if (currentPage === 1) {
        setPublishjobState(
          jobListInAdmin && jobListInAdmin.data != undefined
            ? jobListInAdmin.data
            : []
        );
        setHasMore(jobListInAdmin.hasNext);
      } else {
        setHasMore(jobListInAdmin.hasNext);
        setPublishjobState([...jobListInAdmin.data, ...publishedjobState]);
      }

      // setLoader(false);
    }
  }, [jobListInAdmin]);

  const fetchMoreData = async () => {
    setCurrentPage(currentPage + 1);
    if (authorizationToken != null) {
      // if (cl === "/admin/myJob/drafted") {
      (async () => {
        await GetAllAdminMyJobListPub(
          adminMyJobDispatcher,
          {
            AdminId: loggedAdminId,
            Draftedjobsflag: false,
            JobStatus: jobStatusData,
            Page: currentPage + 1,
            PageSize: currentPageSize,
            Publishedjobsflag: true,
            SearchTerm: "",
            SortOrder: "",
          } as AdminJobListRequestModel,
          authorizationToken
        );
      })();
      // }
    }
  };

  const onHandleMirror = (id: number) => {
    handleMirrorJob(id);
  };

  const onHandleEdit = (id: number) => {
    handleEdit(id);
  };

  const handleViewClick = (
    id: any,
    candidateDefaultId: any,
    vendorDefaultId: any
  ) => {
    props.history.push(
      `/admin/job_Application/${candidateDefaultId}/${vendorDefaultId}/${id}`
    );
  };

  const handleShareButtonClick = (id: any, value: any) => {
    setCurrentJobId(id);
    setIsShareOpen(!isShareOpen);
  };

  const content = (
    <>
      <p>You Don't Have Permission</p>
    </>
  );

  const content1 = (
    <>
      <p>You are not assign admin</p>
    </>
  );
  function onChange(checkedValues: any) {
    onChangeInPub(checkedValues, publishedjobState);
    //console.log("checked = ", checkedValues);
  }

  return (
    <>
      <div className="row">
        <Checkbox
          style={{ marginLeft: 20 }}
          onChange={onHCheckAllChangeInPub}
          checked={checkAll}
        >
          Select all
        </Checkbox>
        <div className="col-sm-12 col-lg-12">
          <div className="section_box3">
            <div className="row">
              <div
                className="my_job_descrip"
                style={{ overflow: "hidden", outline: "none" }}
                tabIndex={0}
              >
                {/* job Staet */}
                <InfiniteScroll
                  className="_custom-infinite-scroll-class"
                  height={700}
                  // dataLength={100}
                  dataLength={
                    publishedjobState !== undefined
                      ? publishedjobState.length
                      : 50
                  }
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    jobListInAdmin.data !== undefined &&
                    jobListInAdmin.data.length > 0 ? (
                      <h4>Loading...</h4>
                    ) : null
                  }
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      {jobListInAdmin.data !== undefined &&
                      jobListInAdmin.data.length > 16 ? (
                        <b>Yay! You have seen it all</b>
                      ) : null}
                    </p>
                  }
                >
                  <Checkbox.Group
                    onChange={onChange}
                    defaultValue={checkedList}
                    value={checkedList}
                  >
                    {publishedjobState != undefined
                      ? publishedjobState.map((data, index) => {
                          return (
                            <div className="col-sm-3">
                              <div className="matched_jobs">
                                {/* <Badge style={{ float: "right" }} count={10} /> */}
                                <div style={{ float: "left", marginTop: -8 }}>
                                  {" "}
                                  <Checkbox value={data.jobId}></Checkbox>
                                </div>
                                <div
                                  className="matched_jobs_cat_t"
                                  style={{ marginTop: 15, marginLeft: -20 }}
                                >
                                  {" "}
                                  {data.categoryName}
                                </div>
                                <div style={{ float: "right", marginTop: -8 }}>
                                  {" "}
                                  <Tooltip
                                    title={
                                      data.applicationviewcount +
                                      " " +
                                      "new applicant"
                                    }
                                  >
                                    <Badge
                                      style={{ float: "right" }}
                                      count={data.applicationviewcount}
                                    />
                                  </Tooltip>
                                </div>
                                <div className="matched_star1">
                                  {AdminPermissionResult != undefined &&
                                  AdminPermissionResult.isCreateorEditJobPermission ? (
                                    <a
                                      onClick={() => {
                                        onHandleMirror(data.rowId);
                                      }}
                                      data-target="#create_jobdescription"
                                      data-toggle="modal"
                                    >
                                      Mirror a Job
                                    </a>
                                  ) : (
                                    <Popover content={content} trigger="hover">
                                      <a
                                        data-target="#create_jobdescription"
                                        data-toggle="modal"
                                      >
                                        Mirror a Job
                                      </a>
                                    </Popover>
                                  )}
                                </div>{" "}
                                <div className="clearfix" />
                                <div
                                  onClick={() => {
                                    onHandleEdit(data.rowId);
                                  }}
                                >
                                  <div className="matched_heading">
                                    {data.title}
                                  </div>
                                  <div className="clearfix" />
                                  <div className="matched_jobs_cat">
                                    <i
                                      className="fa fa-map-marker"
                                      aria-hidden="true"
                                    />{" "}
                                    {data.location}
                                  </div>
                                  <div className="matched_jobs_cat text-right">
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    />{" "}
                                    {data.jobType}
                                  </div>
                                  <div className="clearfix" />
                                  <div className="matched_jobs_cat">
                                    <i
                                      className="fa fa-list-ul"
                                      aria-hidden="true"
                                    />{" "}
                                    {data.numberOfVacancies} Vacancies
                                  </div>
                                  {/* ------- */}
                                  {data.assignedToAdminId === loggedAdminId ? (
                                    <>
                                      <div className="matched_jobs_cat">
                                        Created By:
                                        {data.assignedBy}
                                      </div>

                                      <div className="matched_jobs_cat">
                                        <i
                                          className="fa fa-list-ul"
                                          aria-hidden="true"
                                        />{" "}
                                        Assigned Date:
                                        {data.jobAssignDate}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="matched_jobs_cat">
                                        Assign To:
                                        {data.assignTo}
                                      </div>

                                      <div className="matched_jobs_cat">
                                        <i
                                          className="fa fa-list-ul"
                                          aria-hidden="true"
                                        />{" "}
                                        Assigned Date:
                                        {data.jobAssignDate}
                                      </div>
                                    </>
                                  )}
                                  {/* ------- */}
                                </div>
                                Job Id: {data.jobId}
                                <div className="clearfix" />
                                <div className="matched_jobs_share">
                                  {jobStatusData === 0 ? (
                                    <a></a>
                                  ) : (
                                    <a
                                      onClick={() =>
                                        handleShareButtonClick(data.jobId, true)
                                      }
                                      data-target="#share"
                                      data-toggle="modal"
                                    >
                                      <i
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  )}
                                </div>
                                {data.numberOfApplicants !== undefined &&
                                data.numberOfApplicants !== null &&
                                data.numberOfApplicants > 0 ? (
                                  <div className="check_status">
                                    {AdminPermissionResult != undefined &&
                                    AdminPermissionResult.isViewJobPermission ? (
                                      jobStatusData == 0 ? (
                                        <a>
                                          <Popover
                                            content={
                                              "please active job for view application"
                                            }
                                            trigger="hover"
                                          >
                                            View Application
                                          </Popover>
                                        </a>
                                      ) : data.assignedToAdminId ===
                                        loggedAdminId ? (
                                        <a
                                          onClick={() => {
                                            handleViewClick(
                                              data.jobId,
                                              data.candidateDefaultId,
                                              data.vendorDefaultId
                                            );
                                          }}
                                        >
                                          View Application
                                        </a>
                                      ) : (
                                        <Popover
                                          content={content1}
                                          trigger="hover"
                                        >
                                          <a
                                            style={{ backgroundColor: "gray" }}
                                          >
                                            View Application
                                          </a>
                                        </Popover>
                                      )
                                    ) : (
                                      <Popover
                                        content={content}
                                        trigger="hover"
                                      >
                                        <a>View Application</a>
                                      </Popover>
                                    )}
                                  </div>
                                ) : (
                                  <div className="check_status">
                                    <a style={{ backgroundColor: "darkgray" }}>
                                      No Applicant
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </Checkbox.Group>
                </InfiniteScroll>
                {/* job end */}
              </div>
            </div>
          </div>
        </div>
        {/* BEGIN MODAL */}
        <Modal
          show={isShareOpen}
          onHide={() => {
            setIsShareOpen(!isShareOpen);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share this document on</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-12 m_t_30 text-center">
              <div className="social1">
                <WhatsappShareButton
                  url={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentjobId}/searchtitle/location/0`
                  )}
                  className={""}
                  title={"CareerApp Job Post"}
                >
                  {/* <a href="#" className="social_face"> */}
                  <Tooltip title="whatsApp">
                    <i
                      className="fa fa-whatsapp"
                      style={{
                        fontSize: 51,
                        color: "green",
                        paddingRight: 2,
                      }}
                    ></i>
                  </Tooltip>
                  {/* </a> */}
                </WhatsappShareButton>{" "}
                <TelegramShareButton
                  url={`career app job Share`}
                  title={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentjobId}/searchtitle/location/0`
                  )}
                >
                  <Tooltip title="Telegram">
                    <i
                      className="fa fa-telegram"
                      style={{
                        fontSize: 48,
                        color: "rgb(55, 174, 226)",
                      }}
                    ></i>
                  </Tooltip>
                </TelegramShareButton>
                <EmailShareButton
                  url={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentjobId}/searchtitle/location/0`
                  )}
                  subject={"Jobs In Carrer app"}
                  body={"This is a Jobs from   Carrer app"}
                  className={""}
                >
                  {/* <EmailIcon></EmailIcon> */}
                  {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                  <Tooltip title="Gmail">
                    <img
                      src={require("../../../images/gmail-icon-svg-28.jpg")}
                      style={{ height: 66, marginTop: -24 }}
                    />
                  </Tooltip>
                </EmailShareButton>{" "}
                <CopyToClipboard
                  //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                  text={`https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentjobId}/searchtitle/location/0`}
                >
                  <Tooltip title="copy to clipboard">
                    {" "}
                    {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                    <img
                      src={require("../../../images/copy-clipboard-icon-28.jpg")}
                      style={{
                        height: 66,
                        marginTop: -24,
                        marginLeft: -15,
                      }}
                      onClick={() => {
                        setIsShareOpen(!isShareOpen);
                      }}
                    />
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>
            <div className="modal-footer  m-t-30"></div>
          </Modal.Body>
        </Modal>

        {/* END MODAL */}
      </div>
    </>
  );
};

export default withRouter(AdminPublishedJobComponent);
