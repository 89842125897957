import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useAdminMyProfileContext } from "../../../action/MyProfileAdminAction";
import {
  removeCareerDeveloperVideo,
  saveCareerDeveloperVideo,
  getCareerDeveloperVideoAllList,
  getCareerDeveloperCategoryAllList,
  saveCareerDeveloperCategory,
  useCareerDeveloperContext,
  useCareerDeveloperDispatcher,
} from "../../../action/admin/CareerDeveloperAction";
import { CareerDeveloperCategoryPostModel } from "../../../models/admin/CareerDeveloper";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../../context/GlobalSpinner";
import _ from "lodash";
import SelectOption from "../../candidate/my_profile/components/SelectOption";
import { ErrorMessage } from "@hookform/error-message";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import { Popconfirm, Popover, Radio, Tooltip } from "antd";
import ReactPlayer from "react-player";
import { environment } from "../../../environments/environment";
import { getCareerDeveloperVideosBySearch } from "../../../apis/index";
import { useAdminMyJobContext } from "../../../action/admin/AdminMyJobAction";

type objectIndexing = {
  [key: string]: any;
};
interface ReactSelectOption {
  value: string;
  label: string;
}

function CareerDeveloperVideo(props: any) {
  const {
    careerDeveloperVideoAllList,
    // countNext,
    // setCountNext,
    // carouselValue,
    // setCarouselValue,
    removeVideo,
    setCurrentVideo,
    AdminPermissionResult,
    content,
    fetchMoreCareerDeveloperData,
    currentVideoPage,
    setHasMoreVideo,
  } = props;

  const [value, setValue] = React.useState(1);

  React.useEffect(() => {
    if (
      careerDeveloperVideoAllList != undefined &&
      !_.isEmpty(careerDeveloperVideoAllList)
    ) {
      //console.log(careerDeveloperVideoAllList);
      setHasMoreVideo(careerDeveloperVideoAllList.hasNext);
    }
  }, [careerDeveloperVideoAllList]);
  const handleCarouselNextClick = () => {
    // let num =
    //   careerDeveloperVideoAllList.data != undefined
    //     ? careerDeveloperVideoAllList.data.length
    //     : 0;
    // //let num = 5;
    // let value = num / 4;
    // if (countNext < value && value >= 1) {
    //   setCountNext(countNext + 1);
    //   setCarouselValue(carouselValue - 225); //206
    // } else {
    //   setCountNext(0);
    //   setCarouselValue(0);
    // }
    fetchMoreCareerDeveloperData(currentVideoPage + 1, true);
  };

  const handleCarouselPreviousClick = () => {
    //let num = candidateResumes.data != undefined ? candidateResumes.data.length : 0;
    //let value = num / 4;
    // if (countNext > 0) {
    //   setCountNext(countNext + 1);
    //   setCarouselValue(carouselValue + 225); //206
    // } else {
    //   setCountNext(0);
    //   setCarouselValue(0);
    // }
    fetchMoreCareerDeveloperData(currentVideoPage - 1, false);
  };

  const playVideo = async (e: any, i: any) => {
    //console.log(e, i);
    setCurrentVideo(
      e.videoName === "null"
        ? e.youTubeUrl
        : environment.baseUrl + `/Upload/CareerDeveloperVideos/${e.videoName}`
    );
  };

  return (
    <React.Fragment>
      <div className="section_box2">
        <div className="col-sm-12">
          <h1>Videos</h1>
          <div className="row">
            <div className="prodict_list">
              <div
                id="owl-demo1"
                className="owl-carousel owl-theme"
                style={{ opacity: 1, display: "block" }}
              >
                <div className="owl-wrapper-outer">
                  <div
                    className="owl-wrapper"
                    style={{
                      width: "2772px",
                      //"2568px",
                      left: "0px",
                      display: "block",
                      transition: "all 0ms ease 0s",
                      transform: "translate3d(0px, 0px, 0px)",
                      //transform: `translate3d(${carouselValue}px, 0px, 0px)`,
                    }}
                  >
                    {careerDeveloperVideoAllList.data != undefined &&
                      careerDeveloperVideoAllList.data
                        .filter((f: any) => f.isActive == true)
                        .map((e: any, i: any) => (
                          <div className="owl-item" style={{ width: "231px" }}>
                            <div className="item">
                              <div className="matched_jobs_sec">
                                {/* ============================================== */}

                                <div className="career_video">
                                  {/* <img src={require("../../../images/videos_1.jpg")} alt="" className="image" /> */}
                                  <ReactPlayer
                                    url={
                                      e.videoName === "null"
                                        ? e.youTubeUrl
                                        : environment.baseUrl +
                                          `/Upload/CareerDeveloperVideos/${e.videoName}`
                                    }
                                    width="100%"
                                    height="300"
                                  />
                                  <div className="overlay">
                                    <div className="text">
                                      <div className="career_play">
                                        <a
                                          onClick={() => {
                                            playVideo(e, i);
                                          }}
                                          data-target="#add_document"
                                          data-toggle="modal"
                                        >
                                          Play
                                        </a>
                                      </div>
                                      {/* <div className="career_update"><a href="#" data-target="#add_document" data-toggle="modal">Update</a></div> */}
                                      {AdminPermissionResult != undefined &&
                                      AdminPermissionResult.isDeleteVideoUnderCategoryPermission ? (
                                        <Popconfirm
                                          title={
                                            <div>
                                              <p>
                                                Are you sure delete this video{" "}
                                              </p>
                                              {/* Name: {`${e.resumeName}`}
                                            <br />
                                        Last Modified: {`${e.updatedDate}`} */}
                                            </div>
                                          }
                                          onConfirm={() =>
                                            //handleDeleted(document.rowId)
                                            removeVideo(e, i)
                                          }
                                          //onCancel={cancel}
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <div className="digis_delete">
                                            <a>Delete</a>
                                          </div>
                                        </Popconfirm>
                                      ) : (
                                        <Popover
                                          content={content}
                                          trigger="hover"
                                        >
                                          <div className="digis_delete">
                                            <a>Delete</a>
                                          </div>
                                        </Popover>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="videos_head">
                                  {e.displayVideoName}
                                </div>
                                {/* <div className="videos_time">20 Min</div> */}
                                <div className="clearfix" />
                                <div className="videos_by">by JIT</div>
                                <div className="videos_by">{e.title}</div>

                                {/* ============================================== */}
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
                <div
                  className="owl-controls clickable"
                  style={{ display: "none" }}
                >
                  <div className="owl-pagination">
                    <div className="owl-page active">
                      <span className="" />
                    </div>
                  </div>
                  <div className="owl-buttons">
                    <div
                      className="owl-prev"
                      onClick={handleCarouselPreviousClick}
                    >
                      <img src={require("../../../images/products_ar1.png")} />
                    </div>
                    <div className="owl-next" onClick={handleCarouselNextClick}>
                      <img src={require("../../../images/products_ar2.png")} />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div id="owl-demo1" className="owl-carousel">
                <div className="item">
                  <div className="matched_jobs_sec">
                    <div className="career_video">
                      <img src="images/videos_1.jpg" alt="" className="image" />
                      <div className="overlay">
                        <div className="text">
                          <div className="career_play"><a href="#" data-target="#add_document" data-toggle="modal">Play</a></div>
                          <div className="career_update"><a href="#" data-target="#add_document" data-toggle="modal">Update</a></div>
                          <div className="digis_delete"><a href="#">Delete</a></div>
                           </div>
                      </div>
                    </div>
                    <div className="videos_head">Video 1</div>
                    <div className="videos_time">20 Min</div>
                    <div className="clearfix" />
                    <div className="videos_by">by JIT</div>
                    <div className="videos_by">Category</div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const CareerDeveloperComponent = () => {
  //const globalSpinner = useContext(GlobalSpinnerContext);
  const careerDeveloperDispatcher = useCareerDeveloperDispatcher();
  const careerDeveloperContext = useCareerDeveloperContext();
  const {
    careerDeveloperVideoAllList,
    careerDeveloperVideoRespond,
    careerDeveloperVideoDeleteResult,
    careerDeveloperCategoryList,
    careerDeveloperCategoryRespond,
  } = careerDeveloperContext;
  const globalSpinner = useContext(GlobalSpinnerContext);
  const myProfileAdminContext = useAdminMyProfileContext();
  const {
    //myProfile,
    // basicInfo,
    loggedAdminId,
  } = myProfileAdminContext;
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;
  const adminId = loggedAdminId;
  //console.log(adminId);
  let careerDeveloperCategories: ReactSelectOption[] = [];
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [obj, setObj] = useState<any>({ file: null });
  const [isOpen1, setIsOpen1] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);
  // const [countNext, setCountNext] = useState(0);
  const [currentVideo, setCurrentVideo] = useState("");
  const [radioValue, setRadioValue] = React.useState(1);
  // const [carouselValue, setCarouselValue] = useState(0);
  // const [dashDisplay, setDashDisplay] = useState("block");
  const currentVideoPageSize = 5;
  const [hasMoreVideo, setHasMoreVideo] = React.useState(false);
  const [currentVideoPage, setCurrentVideoPage] = useState(1);
  const { register, handleSubmit, watch, errors, trigger, control } = useForm<{
    categoryId: string;
  }>({
    defaultValues: { categoryId: "0" },
  });

  if (!_.isEmpty(careerDeveloperCategoryList)) {
    // //console.log(careerDeveloperCategoryList);
    careerDeveloperCategories = careerDeveloperCategoryList.careerDeveloperCategories.map(
      (e: any) => {
        return { value: e["value"], label: e["caption"] };
      }
    );
  }
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    errors: errors2,
    setError: setError2,
    trigger: trigger2,
    clearErrors: clearErrors2,
  } = useForm<{ newCategory: string }>({
    defaultValues: { newCategory: "" },
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    watch: watch3,
    control: control3,
    errors: errors3,
    trigger: trigger3,
    clearErrors: clearErrors3,
  } = useForm<{ videoName: string; categoryId: string }>({
    defaultValues: { videoName: "", categoryId: "0" },
  });

  React.useEffect(() => {
    (async () => {
      await getCareerDeveloperCategoryAllList(careerDeveloperDispatcher, {});
    })();
  }, []);

  const onChangeValue = (e: any) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
  };

  // function handleChange(value: any) {
  //   // //console.log(`selected ${value}`);
  //   setSelectedCategory(parseInt(value));
  // }
  React.useEffect(() => {
    if (
      !_.isEmpty(careerDeveloperCategories) &&
      careerDeveloperCategories.length > 1
    ) {
      getCareerDeveloperData("", 1, 1);
    }
  }, [loggedAdminId, careerDeveloperCategories.length]);

  const getCareerDeveloperData = (
    searchString: any,
    getPage: any,
    getPageSize: any
  ) => {
    globalSpinner.showSpinner();
    getCareerDeveloperVideosBySearch({
      Page: getPage,
      PageSize: getPageSize,
      SearchTerm: searchString,
      SortOrder: "",
    })
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (res.data.total > 0) {
          setCurrentVideo(
            res.data.data[0].videoName === "null"
              ? res.data.data[0].youTubeUrl
              : environment.baseUrl +
                  `/Upload/CareerDeveloperVideos/${res.data.data[0].videoName}`
          );
        }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
      });
  };

  const onSubmit = (data: any) => {
    //console.log(data);
    if (data.categoryId > 0) {
      setSelectedCategory(data.categoryId);
      setCurrentVideoPage(1);
      // (async () => {
      //   await getCareerDeveloperVideoAllList(careerDeveloperDispatcher, {
      //     Page: 1,
      //     PageSize: 100,
      //     SearchTerm: "",
      //     SortOrder: "",
      //     CategoryId: data.categoryId,
      //   });
      // })();
      getCategoryWiseCareerDeveloperData(
        data.categoryId,
        1,
        currentVideoPageSize
      );
    }
  };

  const onSubmit2 = (data: any) => {
    // //console.log(data);
    (async () => {
      globalSpinner.showSpinner();
      await saveCareerDeveloperCategory(careerDeveloperDispatcher, {
        RowId: 0,
        Title: data.newCategory,
        Description: data.newCategory,
        IsActive: true,
      } as CareerDeveloperCategoryPostModel);
      globalSpinner.hideSpinner();
    })();
  };

  const onSubmit3 = (data: any) => {
    // //console.log(data);
    // globalSpinner.showSpinner();
    const formData = new FormData();
    formData.append("RowId", JSON.stringify(0));
    formData.append("VideoName", radioValue == 2 ? obj.file.name : null);
    formData.append("DisplayVideoName", data.videoName);
    formData.append("CategoryId", data.categoryId);
    formData.append("Document", radioValue == 2 ? obj.file : null);
    formData.append("YouTubeUrl", radioValue == 1 ? data.youtubeUrl : null);
    formData.append("UserId", JSON.stringify(adminId));
    formData.append("IsActive", JSON.stringify(true));

    (async () => {
      globalSpinner.showSpinner();
      await saveCareerDeveloperVideo(careerDeveloperDispatcher, formData);
      globalSpinner.hideSpinner();
    })();
  };

  React.useEffect(() => {
    if (
      careerDeveloperCategoryRespond != undefined &&
      !_.isEmpty(careerDeveloperCategoryRespond)
    ) {
      //console.log(careerDeveloperCategoryRespond);
      if (careerDeveloperCategoryRespond.isSuccess) {
        (async () => {
          await getCareerDeveloperCategoryAllList(
            careerDeveloperDispatcher,
            {}
          );
        })();
        toast.success("Category saved successfully.");
        setIsOpen1(false);
      } else {
        // globalSpinner.hideSpinner();
        let msg = careerDeveloperCategoryRespond.message.includes(
          "Career Developer Category is already exist!"
        )
          ? "Error:Category already exists."
          : "Error:Category not saved.";
        toast.error(msg);
      }

      globalSpinner.hideSpinner();
    }
  }, [careerDeveloperCategoryRespond]);

  React.useEffect(() => {
    if (
      careerDeveloperVideoRespond != undefined &&
      !_.isEmpty(careerDeveloperVideoRespond)
    ) {
      // //console.log(careerDeveloperCategoryRespond);
      if (careerDeveloperVideoRespond.isSuccess) {
        toast.success("Career developer details saved successfully");
        setIsOpen2(false);
        getCategoryWiseCareerDeveloperData(
          selectedCategory,
          currentVideoPage,
          currentVideoPageSize
        );
      } else {
        // globalSpinner.hideSpinner();
        toast.error("Error:Career developer details not saved.");
      }

      globalSpinner.hideSpinner();
    }
  }, [careerDeveloperVideoRespond]);

  const handleDrop = (files: any) => {
    //console.log(files[0].size);
    if (["video/mp4"].includes(files[0].type) && files[0].size < 1000000 * 10) {
      //console.log(files[0]);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
    } else {
      toast.error("Error:File not uploaded successfully.");
    }
  };

  const removeVideo = async (e: any, i: any) => {
    /////console.log(e);

    (async () => {
      globalSpinner.showSpinner();
      await removeCareerDeveloperVideo(careerDeveloperDispatcher, {
        RowId: e.rowId,
      });
      globalSpinner.hideSpinner();
    })();
  };

  React.useEffect(() => {
    if (
      careerDeveloperVideoDeleteResult != undefined &&
      !_.isEmpty(careerDeveloperVideoDeleteResult)
    ) {
      // //console.log(careerDeveloperVideoDeleteResult);
      if (careerDeveloperVideoDeleteResult.isSuccess) {
        (async () => {
          await getCareerDeveloperVideoAllList(careerDeveloperDispatcher, {
            Page: currentVideoPage,
            PageSize: currentVideoPageSize,
            SearchTerm: "",
            SortOrder: "",
            CategoryId: selectedCategory,
          });
        })();
        toast.success("Career developer details are removed.");
      } else {
        // globalSpinner.hideSpinner();
        toast.error("Error:Video not removed.");
      }

      globalSpinner.hideSpinner();
    }
  }, [careerDeveloperVideoDeleteResult]);

  const content = (
    <>
      <p>You Don't Have Permission</p>
    </>
  );

  const fetchMoreCareerDeveloperData = async (
    getPage: any,
    fetchNext: boolean
  ) => {
    if (hasMoreVideo && fetchNext && selectedCategory > 0) {
      setCurrentVideoPage(getPage);
      getCategoryWiseCareerDeveloperData(
        selectedCategory,
        getPage,
        currentVideoPageSize
      );
    }
    if (fetchNext != true && currentVideoPage > 1 && selectedCategory > 0) {
      setCurrentVideoPage(getPage);
      getCategoryWiseCareerDeveloperData(
        selectedCategory,
        getPage,
        currentVideoPageSize
      );
    }
  };

  const getCategoryWiseCareerDeveloperData = (
    categoryId: any,
    getPage: any,
    getPageSize: any
  ) => {
    (async () => {
      globalSpinner.showSpinner();
      await getCareerDeveloperVideoAllList(careerDeveloperDispatcher, {
        Page: getPage,
        PageSize: getPageSize,
        SearchTerm: "",
        SortOrder: "",
        CategoryId: categoryId,
      });
      globalSpinner.hideSpinner();
    })();
    // getCareerDeveloperVideosByCategory({
    //   CategoryId: [categoryId],
    //   Page: getPage,
    //   PageSize: getPageSize,
    //   SearchTerm: "",
    //   SortOrder: "",
    // })
    //   .then((res: any) => {
    //     globalSpinner.hideSpinner();
    //     if (getPage == 1) {
    //       setTotalVideoPage(Math.ceil(res.data.total / currentPageSize));
    //     }
    //     setCareerVideos(res.data.data);
    //     setHasMoreVideo(res.data.hasNext)
    //   })
    //   .catch((err: any) => {
    //     globalSpinner.hideSpinner();
    //   });
  };

  return (
    <>
      <ToastContainer />
      {/*=================Content Section Start================*/}
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Career Developer</h1>
          {AdminPermissionResult != undefined &&
          AdminPermissionResult.isAddNewCategoriesPermission ? (
            <a
              onClick={() => {
                setIsOpen1(true);
              }}
              data-target="#new_category"
              data-toggle="modal"
              className="btn cutomise_but"
            >
              <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; New
              Category
            </a>
          ) : (
            <Popover content={content} trigger="hover">
              <a
                data-target="#new_category"
                data-toggle="modal"
                className="btn cutomise_but"
              >
                <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; New
                Category
              </a>
            </Popover>
          )}
          {AdminPermissionResult != undefined &&
          AdminPermissionResult.isAddNewVideoUnderCategoryPermission ? (
            <a
              onClick={() => {
                setIsOpen2(true);
              }}
              data-target="#new_video"
              data-toggle="modal"
              className="btn cutomise_but"
            >
              <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; New Video
            </a>
          ) : (
            <Popover content={content} trigger="hover">
              <a
                data-target="#new_video"
                data-toggle="modal"
                className="btn cutomise_but"
              >
                <i className="fa fa-pencil" aria-hidden="true" /> &nbsp; New
                Video
              </a>
            </Popover>
          )}
          <div className="clearfix" />
          <div>
            <div className="row">
              <div className="col-sm-8">
                <div className="section_box2">
                  {!_.isEmpty(currentVideo) && (
                    <ReactPlayer
                      url={
                        currentVideo
                        // environment.baseUrl +
                        // `/Upload/CareerDeveloperVideos/${currentVideo}`
                      }
                      width="100%"
                      controls={true}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-4"></div>
            </div>
            <div className="clearfix" />
            <div className="section_box4">
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="col-sm-2">
                  <label>Select Category</label>
                </div>
                <div className="col-sm-5">
                  <span className="select-wrapper-sec">
                    <Controller
                      rules={{
                        required: "Category is required",
                        validate: function (value) {
                          //console.log(value);
                          return Number(value)
                            ? undefined
                            : "Category is required";
                        },
                      }}
                      control={control}
                      name="categoryId"
                      render={({ onChange, onBlur, value, name }) => (
                        <SelectOption
                          values={careerDeveloperCategories}
                          disabled={false}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          name={name}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="categoryId"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                    {/* <select name="timepass" className="custom-select-sec" onChange={handleChange}>
                      {careerDeveloperCategories.map((item: any, index: any) => {
                        return <option key={item["value"]} value={item["value"]}>{item["label"]}</option>
                      })}
                    </select> */}
                  </span>
                </div>
                <div className="col-sm-3">
                  {/* <label>&nbsp;</label> */}
                  <div className="search1">
                    {/* <a>Get All Videos</a> */}
                    <button className="btn btn-success" type="submit">
                      {" "}
                      Get All Videos{" "}
                    </button>
                  </div>
                  {/* <button className="btn btn-default" onClick={handleVideos} type="button" >Get All Videos </button> */}
                </div>
              </form>
            </div>
            <CareerDeveloperVideo
              careerDeveloperVideoAllList={careerDeveloperVideoAllList}
              // countNext={countNext}
              // setCountNext={setCountNext}
              // carouselValue={carouselValue}
              // setCarouselValue={setCarouselValue}
              removeVideo={removeVideo}
              setCurrentVideo={setCurrentVideo}
              AdminPermissionResult={AdminPermissionResult}
              content={content}
              fetchMoreCareerDeveloperData={fetchMoreCareerDeveloperData}
              currentVideoPage={currentVideoPage}
              setHasMoreVideo={setHasMoreVideo}
            />
          </div>
          <div className="clearfix" />
        </div>
      </div>

      {/*=================Content Section End================*/}

      <Modal
        show={isOpen1}
        onHide={() => {
          setIsOpen1(!isOpen1);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2(onSubmit2)} noValidate>
            <div className="form-group">
              <label htmlFor="email">Category</label>
              <input
                type="text"
                className="form-control "
                placeholder="Type here"
                name="newCategory"
                ref={register2({
                  required: "category is required",
                  // pattern: {
                  //   value: /\S+@\S+\.\S+/,
                  //   message: "Please enter a valid email",
                  // },
                })}
              />

              <ErrorMessage
                errors={errors2}
                name="newCategory"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
            <div className="modal-footer  m-t-30">
              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
              >
                {" "}
                Add{" "}
              </button>
              <button
                onClick={() => {
                  setIsOpen1(false);
                }}
                data-dismiss="modal"
                className="btn btn-default waves-effect _cursor-pointer"
                type="button"
              >
                {" "}
                Cancel
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={isOpen2}
        onHide={() => {
          setIsOpen2(!isOpen2);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit3(onSubmit3)} noValidate>
            <div className="form-group">
              <label htmlFor="email">Video Name</label>
              <input
                type="text"
                className="form-control "
                placeholder="Type here"
                name="videoName"
                ref={register3({
                  required: "Video name is required",
                  // pattern: {
                  //   value: /\S+@\S+\.\S+/,
                  //   message: "Please enter a valid email",
                  // },
                })}
              />
              <ErrorMessage
                errors={errors3}
                name="videoName"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Category</label>
              <span className="select-wrapper-sec">
                <Controller
                  rules={{
                    required: "Category is required",
                    validate: function (value) {
                      //console.log(value);
                      return Number(value) ? undefined : "Category is required";
                    },
                  }}
                  control={control3}
                  name="categoryId"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption
                      values={careerDeveloperCategories}
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors3}
                  name="categoryId"
                  render={({ message }) => (
                    <div className="register_validation">{message}</div>
                  )}
                />
              </span>
            </div>
            <div className="form-group">
              <Radio.Group onChange={onChangeValue} value={radioValue}>
                <Radio value={1}>YoutubeUrl</Radio>
                <Radio value={2}>VideoFile</Radio>
              </Radio.Group>
            </div>
            <div
              style={{ display: radioValue == 1 ? "" : "none" }}
              className="form-group"
            >
              <label htmlFor="email">Youtube URL</label>
              <input
                type="text"
                className="form-control "
                placeholder="Type here"
                name="youtubeUrl"
                ref={register3({
                  required: false,
                })}
              />
              <ErrorMessage
                errors={errors3}
                name="youtubeUrl"
                render={({ message }) => (
                  <div className="login_validation">{message}</div>
                )}
              />
            </div>
            <div
              style={{ display: radioValue == 2 ? "" : "none" }}
              className="form-group"
            >
              <label htmlFor="email">Upload</label>
              <div className="box1">
                <FileUploads
                  accept=".mp4"
                  onChange={(file: any) => {
                    handleDrop([file]);
                  }}
                  name="Choose a file"
                  disabled={false}
                  // ref={register3}
                />
                {/* <input type="file" name="file-1[]" id="file-1" className="inputfile inputfile-1" data-multiple-caption="{count} files selected" multiple /> */}
                {/* <label htmlFor="file-1"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={17} viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg> <span>Upload Video</span></label> */}
              </div>
            </div>
            <div
              style={{ display: radioValue == 2 ? "" : "none" }}
              className="update_con"
            >
              Acceptable file formats: MP4
            </div>
            <div
              style={{ display: radioValue == 2 ? "" : "none" }}
              className="update_con"
            >
              Maximum file size: 10 MB.
            </div>

            <div className="modal-footer  m-t-30">
              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
              >
                {" "}
                Add{" "}
              </button>
              <button
                onClick={() => {
                  setIsOpen2(false);
                }}
                data-dismiss="modal"
                className="btn btn-default waves-effect _cursor-pointer"
                type="button"
              >
                {" "}
                Cancel
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CareerDeveloperComponent;
