import { Reducer } from "react";

import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";

import { message } from "antd";
import {
  GlobelCounterDispatcherContext,
  GlobelCounterStateContext,
  IGlobelCounterManagementState,
} from "../../context/general/GlobelCounterContext";
let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IGlobelCounterAction = {
  type: "SET_COUNTER-VALUE";
  isAccessible: boolean;
  startTime: number;
};

export const globelCounterReducer: Reducer<
  IGlobelCounterManagementState,
  IGlobelCounterAction
> = (draft, action): IGlobelCounterManagementState => {
  switch (action.type) {
    case "SET_COUNTER-VALUE":
      draft.isAccessible = action.isAccessible;
      draft.startTime = action.startTime;
      return draft;
  }
};

export const useGlobelCounterDispatcher = (): React.Dispatch<IGlobelCounterAction> => {
  const globelCounterDispatcher = React.useContext(
    GlobelCounterDispatcherContext
  );
  if (!globelCounterDispatcher) {
    throw new Error(
      "You have to provide the GlobelCounter dispatcher using theGlobelCounterDispatcherContext.Provider in a parent component."
    );
  }
  return globelCounterDispatcher;
};

export const useGlobelCounterContext = (): IGlobelCounterManagementState => {
  const globelCounterContext = React.useContext(GlobelCounterStateContext);
  if (!globelCounterContext)
    throw new Error(
      "You have to provide the globelCounter context using the GlobelCounterStateContext.Provider in a parent component."
    );
  return globelCounterContext;
};

export const SetAccessible = async (
  dispatcher: React.Dispatch<IGlobelCounterAction>,
  query1: boolean,
  query2: number
) => {
  dispatcher({
    type: "SET_COUNTER-VALUE",
    isAccessible: query1,
    startTime: query2,
  });
};
