import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  AdminJbSaveRespondModel,
  AdminJobEditRespondModel,
  AdminJobListRespondModel,
  AdminPermissionRespondModel,
  AssignToRespondModel,
  DeleteJobRespondModel,
  PermissionResultModel,
  PublishedJobRespond,
} from "../../models/admin/AdminMyJob";
import {
  adminMyJobReducer,
  IAdminMyJobAction,
} from "../../action/admin/AdminMyJobAction";

export const AdminMyJobDispatcherContext = React.createContext<React.Dispatch<IAdminMyJobAction> | null>(
  null
);
export const AdminMyJobStateContext = React.createContext<IAdminMyJobManagementState | null>(
  null
);

export interface IAdminMyJobManagementProps extends RouteComponentProps<any> {}

export interface IAdminMyJobManagementState {
  permissionResult: PermissionResultModel;
  jobListInAdmin: AdminJobListRespondModel;
  jobListInAdminPub: AdminJobListRespondModel;
  adminSaveJobRespond: AdminJbSaveRespondModel;
  adminEditRespond: AdminJobEditRespondModel;
  adminDeleteRespond: DeleteJobRespondModel;
  publishedJobRespond: PublishedJobRespond;
  AdminPermissionResult: AdminPermissionRespondModel;
  AssignToRespondModelData: AssignToRespondModel;
}

export const initialAdminMyJobManagementState = {
  permissionResult: {} as PermissionResultModel,
  jobListInAdmin: {} as AdminJobListRespondModel,
  jobListInAdminPub: {} as AdminJobListRespondModel,
  adminSaveJobRespond: {} as AdminJbSaveRespondModel,
  adminEditRespond: {} as AdminJobEditRespondModel,
  adminDeleteRespond: {} as DeleteJobRespondModel,
  publishedJobRespond: {} as PublishedJobRespond,
  AdminPermissionResult: {} as AdminPermissionRespondModel,
  AssignToRespondModelData: {} as AssignToRespondModel,
} as IAdminMyJobManagementState;

export const AdminMyJobContextProvider: React.FC = ({ children }) => {
  const [adminMyJobState, dispatcher] = useImmerReducer(
    adminMyJobReducer,
    initialAdminMyJobManagementState
  );

  return (
    <AdminMyJobDispatcherContext.Provider value={dispatcher}>
      <AdminMyJobStateContext.Provider value={adminMyJobState}>
        {children}
      </AdminMyJobStateContext.Provider>
    </AdminMyJobDispatcherContext.Provider>
  );
};
