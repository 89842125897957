import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  editVendor,
  getVendor,
  getVendorSelectBoxList,
  useVendorApprovalContext,
  useVendorApprovalDispatcher,
} from "../../../action/admin/VendorApprovalAction";
import { VendorEditRequestModel } from "../../../models/admin/VendorApproval";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import SelectOption from "../../candidate/my_profile/components/SelectOption";
import { VendorGetDataWithId } from "../../../models/admin/VendorApproval";
// import {
//   saveVendor,
// } from "../../../apis/admin";
import _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../../context/GlobalSpinner";
import { environment } from "../../../environments/environment";

interface IVendorApprovalFormProps {
  isVendorOpen: boolean;
  handleVendorOpen: () => void;
  currentVendorId: number;
  loggedAdminId: number;
  authorizationToken: string | null;
}
interface ReactSelectOption {
  value: string;
  label: string;
}
const defaultValues = {
  selectResume: "",
  coverLetter: "",
  vendorCategoryId: 0,
};

const VendorApprovalForm: React.FC<IVendorApprovalFormProps> = (props) => {
  const {
    handleVendorOpen,
    isVendorOpen,
    currentVendorId,
    loggedAdminId,
    authorizationToken,
  } = props;

  const [obj, setObj] = useState<any>({ file: null });
  const [obj2, setObj2] = useState<any>({ file: null });
  const [disable, setDisable] = useState<number>(0);
  const [disable2, setDisable2] = useState<number>(0);
  const [disable3, setDisable3] = useState<string>("");
  const [disable4, setDisable4] = useState<string>("");
  const [vendorDocument, setDocument] = useState<string>("");

  const vendorApprovalDispatcher = useVendorApprovalDispatcher();
  const vendorApprovalContext = useVendorApprovalContext();
  const {
    VendorResultModel,
    VendorSelectBoxDataViewModel,
  } = vendorApprovalContext;
  const globalSpinner = useContext(GlobalSpinnerContext);

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue,
    reset,
  } = useForm({
    defaultValues,
  });

  let vendorStatuses: ReactSelectOption[] = [];
  if (!_.isEmpty(VendorSelectBoxDataViewModel)) {
    vendorStatuses = VendorSelectBoxDataViewModel.vendorStatuses.map(
      (e: any) => {
        return { value: e["value"], label: e["caption"] };
      }
    );
  }

  const handleDrop = (files: any) => {
    if (
      ["image/jpeg", "application/pdf"].includes(files[0].type) &&
      files[0].size < 1000000 * 5
    ) {
      setDisable(1);
      //console.log(files[0]);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
    }
  };

  React.useEffect(() => {
    if (loggedAdminId != 0 && authorizationToken != null) {
      (async () => {
        await getVendor(
          vendorApprovalDispatcher,
          {
            rowId: currentVendorId,
          } as VendorGetDataWithId,
          authorizationToken
        );
      })();
    }
  }, [currentVendorId]);

  React.useEffect(() => {
    if (1 && authorizationToken != null) {
      (async () => {
        await getVendorSelectBoxList(
          vendorApprovalDispatcher,
          authorizationToken
        );
      })();
    }
  }, []);

  React.useEffect(() => {
    //console.log("----VendorResultModel----", VendorResultModel);
    if (VendorResultModel.data != null) {
      setDocument(VendorResultModel.data["vendorDocument"]);
    }
  });

  //console.log("VendorResultModel", VendorResultModel);
  //console.log("loggedAdminId", loggedAdminId);

  const handleVendorApproval = (data: any) => {
    //console.log(33, data);
    if (authorizationToken != null) {
      //   globalSpinner.showSpinner();
      //   const formData = new FormData();
      //     formData.append("RowId", String(currentVendorId));
      //     formData.append("Name", String(data["name"]));
      //     formData.append("Email", String(data["email"]));
      //     formData.append("Contact", String(data["contact"]));
      //     formData.append("VendorCategoryId", String(data["vendorCategoryId"]));
      //     formData.append("VendorDocument", String(null));
      //     formData.append("Document", obj.file);
      //     formData.append("Description", String(data["description"]));
      //     formData.append("IsActive", JSON.stringify(true));
      //   saveVendor(formData).then((res: any) => {
      //   //console.log(res);
      //   if (res.data.isSuccess) {
      //     globalSpinner.hideSpinner();
      //    toast.success("Career tips updated successfully.");
      //   } else {
      //     globalSpinner.hideSpinner();
      //     toast.error("Error:Career tips not updated.");
      //   }
      // });
      handleClose();
    }
  };

  const handleClose = () => {
    setDisable(0);
    setDisable2(0);
    setDisable3("");
    handleVendorOpen();
  };

  return (
    <>
      <ToastContainer />
      <Modal show={isVendorOpen} onHide={handleClose}>
        <form onSubmit={handleSubmit3(handleVendorApproval)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title> View Document</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 600, maxWidth: 750 }}>
            <div className="form-group">
              {vendorDocument !== undefined &&
              vendorDocument !== null &&
              vendorDocument !== "null" ? (
                vendorDocument.split(".")[1] !== "pdf" &&
                vendorDocument.split(".")[1] !== "docx" ? (
                  <img
                    src={
                      environment.baseUrl +
                      `/Upload/VendorDocument/${vendorDocument}`
                    }
                    className="img-responsive"
                  />
                ) : (
                  <div>
                    <img
                      width="auto!important"
                      className="img-responsive"
                      src={require("../../../images/digi_document.jpg")}
                    />
                    <a
                      href={
                        environment.baseUrl +
                        `/Upload/VendorDocument/${vendorDocument}`
                      }
                      target="_blank"
                    >
                      Please click here to view the document.
                    </a>
                  </div>
                )
              ) : (
                <div>No document uploaded!</div>
              )}
              {/* <img
                width="325"
                height="184"
                src={
                  environment.baseUrl +
                  `/Upload/VendorDocument/${vendorDocument}`
                }
                className="img-responsive"
              /> */}
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            {/* <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
             Save
            </button> */}
            <button
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
              onClick={handleClose}
            >
              Ok
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default VendorApprovalForm;
