import React from "react";
import UserAdminComponent from "./UserAdminComponent";

const UsersAdminContainer = () => {
  return (
    <>
      <UserAdminComponent />
    </>
  );
};

export default UsersAdminContainer;
