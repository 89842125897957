import { ErrorMessage } from "@hookform/error-message";
import { Spin, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  saveBookMarkList,
  useJobBookMarkContext,
  useJobBookMarkDispatcher,
} from "../../../action/candidate/JobBookMarkAction";
import {
  getDatePostedList,
  useDatePostedContext,
  useDatePostedDispatcher,
} from "../../../action/general/DatePostedAction";
import {
  getExperienceList,
  useExperienceContext,
  useExperienceDispatcher,
} from "../../../action/general/ExperienceAction";
import {
  getJobTypeList,
  useJobTypeContext,
  useJobTypeDispatcher,
} from "../../../action/general/JobTypeAction";
import {
  generalSearch,
  useSearchContext,
  useSearchDispatcher,
} from "../../../action/general/SearchAction";
import {
  getLoggedUserId,
  getLoggedVendorId,
  getMyProfileSelectBoxList,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import {
  useCandidateContext,
  useCandidateProfileImageContext,
} from "../../../context/candidate/CandidateMyProfile";
import { environment } from "../../../environments/environment";
import { jobBookMarkSaveRequestModel } from "../../../models/candidate/BookMarkedJob";
import { jobViewModel } from "../../../models/candidate/MatchedJob";
import { datePostedRequestModel } from "../../../models/general/DatePosted";
import { experienceRequestModel } from "../../../models/general/Experience";
import { jobTypeRequestmodel } from "../../../models/general/JobType";
import { SearchRequest, searchResult } from "../../../models/general/Search";
import AuthService from "../../../services/AuthService";
import SelectOption from "../../candidate/my_profile/components/SelectOptionForSearch";
import SearchJobDetails from "./SearchJobDetails";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import {
  EmailShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { useJobAppliedContext } from "../../../action/JobAppliedAction";
import {
  getTitleWithCategoryId,
  setJobAlertSave,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import {
  jobAlertSaveRequestModel,
  jobAlertTitleDropdownResultModel,
} from "../../../models/candidate/JobAlert";
import { categoryWithId } from "../../../models/candidate/JobCategory";
import {
  useVendorContext,
  useVendorProfileImageContext,
} from "../../../context/vendor/VendorMyProfile";
import { useVendorMyProfileDispatcher } from "../../../action/MyProfileVendorAction";
import { VendorProfileImageRequestModel } from "../../../models/vendor/MyProfileSelectBoxData";
import { useVendorJobAppliedContext } from "../../../action/vendor/VendorJobAppliedAction";
import {
  setVendorJobBookmark,
  useVendorJobBookMarkDispatcher,
} from "../../../action/vendor/VendorJobBookMarkAction";
import { VendorJobBookMarkRequestModel } from "../../../models/vendor/VendorJobBookMark";
import { getDocumentStatus } from "../../../apis/vendor";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "green" }} spin />
);

let role = AuthService.currentUser?.roles[0];
let userId = AuthService.currentUser?.id;
interface ReactSelectOption {
  value: string;
  label: string;
}
interface ISearchGenericComponentState {
  experienceFilterId: number;
  datePostedFilterId: number;
  jobTypesFilterIds: number[];
  jobCategorys: string;
  jobTypeData: string;
  location: string;
}
const defaultValues = {
  experienceFilterId: 0,
  datePostedFilterId: 0,
  jobTypesFilterIds: [],
  location: "",
  jobTypeData: "",
  jobCategorys: "",
};

const SearchGenericComponent = (props: any) => {
  const [
    searchGenericComponenttState,
    setSearchGenericComponentState,
  ] = React.useState<ISearchGenericComponentState>(defaultValues);

  let {
    paramTitle,
    paramId,
    paramJobTitle,
    paramLocation,
    paramJobType,
  } = useParams<any>();

  let history = useHistory();
  const authorizationToken = AuthService.accessToken;

  const menuRef = useRef<any>();

  const {
    datePostedFilterId,
    experienceFilterId,
    jobTypesFilterIds,
    location,
  } = searchGenericComponenttState;

  const candidateProfileImageContext = useCandidateProfileImageContext();
  const {
    candidateProfileImage,
    // getCandidateProfileImage,
  } = candidateProfileImageContext;

  const vendorJobBookMarkDispatcher = useVendorJobBookMarkDispatcher();
  const VendorProfileImageContext = useVendorProfileImageContext();
  const {
    vendorProfileImage,
    getVendorProfileImage,
  } = VendorProfileImageContext;

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ISearchGenericComponentState>({
    defaultValues,
  });

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const watchAllFields = watch2();

  const VendorContext = useVendorContext();
  const { getVendors, vendorState } = VendorContext;

  const jobAlertDispatcher = useJobAlertDispatcher();
  const candidateContext = useCandidateContext();

  const jobTypeDispatcher = useJobTypeDispatcher();
  const jobTypeContext = useJobTypeContext();
  const { jobType } = jobTypeContext;

  const experienceDispatcher = useExperienceDispatcher();
  const experienceContext = useExperienceContext();
  const { experience } = experienceContext;

  const datePostedDispatcher = useDatePostedDispatcher();
  const datePostedContext = useDatePostedContext();
  const { datePosted } = datePostedContext;

  const searchDispatcher = useSearchDispatcher();
  const searchContext = useSearchContext();
  const { searchResultData } = searchContext;

  const jobBookMarkDispatcher = useJobBookMarkDispatcher();
  const jobBookMarkContext = useJobBookMarkContext();
  const { jobBookMarkSaveRespond } = jobBookMarkContext;

  const jobAppliedContext = useJobAppliedContext();
  const { jobAppliedSaveResultData } = jobAppliedContext;

  const myProfileDispatcher = useMyProfileDispatcher();
  const myProfileContext = useMyProfileContext();
  const { loggedUserId, myProfile, myProfileProgressBar } = myProfileContext;

  const jobAppliedVendorContext = useVendorJobAppliedContext();
  const {
    vendorJobAppliedSaveRespond,
    vendorGetAllJobList,
  } = jobAppliedVendorContext;

  const myVendorProfileDispatcher = useVendorMyProfileDispatcher();

  const jobAlertContext = useJobAlertContext();
  const {
    jobAlert,
    jobAlertGetAll,
    jobAlertSaveRespond,
    jobAlertTitleDropdownResult,
    JobAlertRoleMapSaveRespond,
  } = jobAlertContext;

  let jobCategoryData: ReactSelectOption[] = [];
  const jobTypeDatas = [
    { value: "1", label: "Permanent" },
    { value: "2", label: "Temporary" },
    { value: "3", label: "Freelancer" },
    { value: "4", label: "Any" },
  ];

  const { getCandidates, candidateState } = candidateContext;
  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);
  const [name, setName] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [isJobTypeOpen, setIsJobTypeOpen] = useState<boolean>(true);
  const [isDatePostedOpen, setIsDatePostedOpen] = useState<boolean>(true);
  const [isExperienceOpen, setExperienceOpen] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize] = useState(10);
  const [jobListIndex, setJobListIndex] = useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  const [jobMessage, setJobMessage] = React.useState("");
  const [jobTitleSate, setJobTitleSate] = React.useState(paramJobTitle);
  const [locationSate, setLocationState] = React.useState(paramLocation);
  const [expSate, setExpState] = React.useState(paramJobType);
  const [searchClickCount, setSearchClickCount] = useState(0);
  const [indexState, setIndexState] = useState(0);
  const [currentSharedJobId, setCurrentSharedJobId] = useState(0);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isSearchLoader, setIsSearchLoader] = React.useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentAlertId, setcurrentAlertId] = useState<number>(0);
  const [documentStatus, setDocumentStatus] = useState("");
  const [tempData, setTempData] = useState<jobAlertTitleDropdownResultModel[]>(
    []
  );
  const [searchResultDataSate, setsearchResultDataState] = useState<
    jobViewModel[]
  >([]);
  const [searchExperienceId, setSearchExperienceId] = useState<number>(0);
  const [searchJobTypeId, setSearchJobTypeId] = useState<number>(0);
  let user = AuthService.currentUser;
  React.useEffect(() => {
    if (role === "Candidate") {
      if (authorizationToken != null && user?.id != null)
        (async () => {
          await getLoggedUserId(
            myProfileDispatcher,
            parseInt(user.id),
            authorizationToken
          );
        })();
    } else if (role === "Vendor") {
      if (authorizationToken != null && user?.id != null)
        (async () => {
          await getLoggedVendorId(
            myProfileDispatcher,
            parseInt(user.id),
            authorizationToken
          );
        })();
    }
  }, [authorizationToken]);

  const candidateId = loggedUserId;

  //console.log(5454, loggedUserId);
  console.log(5453, paramJobTitle);

  React.useEffect(() => {
    if (1) {
      if (paramTitle === "matched") {
        setJobListIndex(1);
      } else if (paramTitle === "applied") {
        setJobListIndex(2);
      } else if (paramTitle === "saved") {
        setJobListIndex(3);
      } else if (paramTitle === "suggested") {
        setJobListIndex(4);
      } else if (paramTitle === "notify") {
        setJobListIndex(7);
      } else if (paramJobTitle !== "searchtitle") {
        authorizationToken != null ? setJobListIndex(5) : setJobListIndex(6);
      } else {
        setJobListIndex(6);
      }
      // setSearchGenericComponentState({
      //   ...searchGenericComponenttState,
      //   jobTypesFilterIds:
      //     paramJobType === "0" ? [1, 2, 3] : [parseInt(paramJobType)],
      // });
    }
  }, [paramTitle]);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getMyProfileSelectBoxList(myProfileDispatcher);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (loggedUserId && role === "Vendor") {
      getDocumentStatus({
        VendorId: Number(loggedUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log("uploaded-document-status success", res.data);
          setDocumentStatus(res.data);
        })
        .catch((err) => {
          console.log("uploaded-document-status error", err);
        });
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  React.useEffect(() => {
    setTempData(jobAlertTitleDropdownResult);
  }, [jobAlertTitleDropdownResult]);

  React.useEffect(() => {
    if (candidateId != null)
      (async () => {
        getCandidates({
          CandidateId: Number(candidateId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "",
          SortOrder: "",
          ShowInactive: false,
        });
      })();
  }, [candidateId]);

  React.useEffect(() => {
    if (loggedUserId != null)
      (async () => {
        getVendors({
          VendorId: Number(loggedUserId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "",
          SortOrder: "",
          ShowInactive: false,
        });
      })();
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      getVendorProfileImage({
        VendorId: Number(loggedUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (vendorState.data != undefined && vendorState.data.length > 0) {
      setVendorName(vendorState.data[0]["firstName"]);
    }
  }, [vendorState]);

  React.useEffect(() => {
    if (candidateState.data != undefined && candidateState.data.length > 0) {
      setName(candidateState.data[0]["firstName"]);
    }
  }, [candidateState]);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getJobTypeList(jobTypeDispatcher, {
          page: 1,
          pageSize: 10,
          searchTerm: "",
          sortOrder: "",
        } as jobTypeRequestmodel);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getExperienceList(experienceDispatcher, {
          page: 1,
          pageSize: 10,
          searchTerm: "",
          sortOrder: "",
        } as experienceRequestModel);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getDatePostedList(datePostedDispatcher, {
          page: 1,
          pageSize: 10,
          searchTerm: "",
          sortOrder: "",
        } as datePostedRequestModel);
      })();
    }
  }, []);

  const fetchMoreData = async () => {
    //console.log(2424);
    setCurrentPage(currentPage + 1);
    // if (authorizationToken != null && candidateId !== 0) {
    (async () => {
      await generalSearch(
        searchDispatcher,
        {
          Title: jobTitleSate === "searchtitle" ? "" : jobTitleSate,
          Location: locationSate === "location" ? "" : locationSate,

          CandidateId:
            authorizationToken != null && candidateId !== 0
              ? role === "Candidate"
                ? candidateId
                : 0
              : 0,
          VendorId:
            authorizationToken != null && loggedUserId !== 0
              ? role === "Vendor"
                ? loggedUserId
                : 0
              : 0,
          JobListIndex: jobListIndex,
          ShowInactive: false,
          JobTypeFilterId:
            paramJobType === "0"
              ? [1, 2, 3]
              : jobTypesFilterIds.length > 0
              ? jobTypesFilterIds
              : [parseInt(paramJobType)],

          ExperienceFilterId:
            experienceFilterId != undefined && experienceFilterId != null
              ? experienceFilterId
              : 0,
          DatePostedFilterId:
            datePostedFilterId === null || datePostedFilterId === undefined
              ? 0
              : datePostedFilterId,
          SearchExperienceId: searchExperienceId,
          SearchJobTypeId: searchJobTypeId,
          Page: currentPage + 1,
          PageSize: currentPageSize,
          SearchTerm: "",
          SortOrder: "",
          JobId: parseInt(paramId),
        } as SearchRequest,
        // authorizationToken
        ""
      );
    })();
    // }
  };

  React.useEffect(() => {
    if (searchResultData && searchResultData.data != undefined) {
      if (currentPage === 1) {
        if (searchResultData.data.length > 0) {
          setJobMessage("");
        } else {
          setJobMessage("No Job Found");
        }
        setsearchResultDataState(
          searchResultData && searchResultData.data != undefined
            ? searchResultData.data
            : []
        );
      } else {
        setHasMore(searchResultData.hasNext);
        setsearchResultDataState([
          ...searchResultData.data,
          ...searchResultDataSate,
        ]);
      }

      if (paramId === "0" && searchResultData.data.length > 0) {
        history.push(
          `/jobSearch/${paramTitle}/${searchResultData.data[0].jobId}/searchtitle/${paramLocation}/${paramJobType}`
        );
      }
      setLoader(false);
      setIsSearchLoader(false);
    }
  }, [searchResultData]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      if (jobListIndex !== 0 && loggedUserId !== 0) {
        setsearchResultDataState([]);
        handleJobSearchCall(loggedUserId);
      }
    } else {
      if (jobListIndex !== 0) {
        setsearchResultDataState([]);
        handleJobSearchCall(0);
      }
    }
  }, [
    candidateId,
    jobTypesFilterIds,
    experienceFilterId,
    datePostedFilterId,
    jobListIndex,
    // searchClickCount,
    jobBookMarkSaveRespond,
    jobAppliedSaveResultData,
    vendorJobAppliedSaveRespond,
  ]);

  const handleJobSearchCall = (loggedUserIdData: any) => {
    // if (authorizationToken != null && candidateId !== 0) {
    //console.log("role", role);
    setsearchResultDataState([]);
    (async () => {
      await generalSearch(
        searchDispatcher,
        {
          Title: jobTitleSate === "searchtitle" ? "" : jobTitleSate,
          Location: locationSate === "location" ? "" : locationSate,
          UserId: userId !== undefined ? parseInt(userId) : 0,
          CandidateId: role === "Candidate" ? loggedUserIdData : 0,
          VendorId: role === "Vendor" ? loggedUserIdData : 0,
          JobListIndex:
            // paramJobTitle === "searchtitle"
            //   ? authorizationToken !== undefined
            //     ? jobListIndex
            //     : 6
            //   : 5,
            jobListIndex,
          ShowInactive: false,
          JobTypeFilterId:
            paramJobType === "0"
              ? [1, 2, 3]
              : jobTypesFilterIds.length > 0
              ? jobTypesFilterIds
              : [parseInt(paramJobType)],
          // jobTypesFilterIds === [0] ? [1, 2, 3] : jobTypesFilterIds,
          ExperienceFilterId:
            experienceFilterId != undefined && experienceFilterId != null
              ? // experienceFilterId === null ||
                // experienceFilterId === undefined ||
                // experienceFilterId === NaN
                experienceFilterId
              : 0,
          DatePostedFilterId:
            datePostedFilterId === null || datePostedFilterId === undefined
              ? 0
              : datePostedFilterId,
          SearchExperienceId: 0,
          SearchJobTypeId: 0,
          Page: 1,
          PageSize: currentPageSize * currentPage,
          SearchTerm: "",
          SortOrder: "",
          JobId: parseInt(paramId),
        } as SearchRequest,
        ""
        // authorizationToken
      );
    })();
    setLoader(true);
    // }
  };

  //console.log(77888, candidateState);

  const onLogout = () => {
    AuthService.logout();
    props.history.push("/");
  };

  const handleJobTypeChange = (event: any) => {
    if (event.target.checked) {
      setSearchGenericComponentState({
        ...searchGenericComponenttState,
        jobTypesFilterIds: jobTypesFilterIds.concat(
          parseInt(event.target.value)
        ),
      });

      history.push(
        `/jobSearch/${paramTitle}/0/searchtitle/location/${parseInt(
          event.target.value
        )}`
      );
    } else {
      setSearchGenericComponentState({
        ...searchGenericComponenttState,
        jobTypesFilterIds: jobTypesFilterIds.filter(
          (data) => data != parseInt(event.target.value)
        ),
      });

      history.push(
        `/jobSearch/${paramTitle}/0/searchtitle/location/${
          jobTypesFilterIds.length > 1 ? parseInt(event.target.value) : 0
        }`
      );
    }
  };

  const handleExperienceChange = (value: any) => {
    if (value !== undefined) {
      setSearchGenericComponentState({
        ...searchGenericComponenttState,
        experienceFilterId: parseInt(value),
      });
    } else {
      setSearchGenericComponentState({
        ...searchGenericComponenttState,
        experienceFilterId: 0,
      });
    }

    history.push(`/jobSearch/${paramTitle}/0/searchtitle/location/0`);
  };

  const handleDatePosteChange = (value: any) => {
    setSearchGenericComponentState({
      ...searchGenericComponenttState,
      datePostedFilterId: parseInt(value),
    });

    history.push(`/jobSearch/${paramTitle}/0/searchtitle/location/0`);
  };

  const onSubmit = (data: any) => {
    setsearchResultDataState([]);
    setIsSearchLoader(true);
    history.push(
      `/jobSearch/${paramTitle}/0/${
        data.jobTitle === "" ? "searchtitle" : data.jobTitle
      }/${data.location === "" ? "location" : data.location}/${
        data.jobType === undefined ? "0" : data.jobType
      }`
    );
    setJobTitleSate(data.jobTitle);
    setLocationState(data.location);
    setExpState(data.experience);

    (async () => {
      await generalSearch(
        searchDispatcher,
        {
          Title: data.jobTitle,
          Location: data.location,
          UserId: userId !== undefined ? parseInt(userId) : 0,
          CandidateId: role === "Candidate" ? loggedUserId : 0,
          VendorId: role === "Candidate" ? 0 : loggedUserId,
          JobListIndex: jobListIndex,
          ShowInactive: false,
          JobTypeFilterId: [0],

          ExperienceFilterId: 0,
          DatePostedFilterId: 0,
          SearchExperienceId:
            data.experience != null && data.experience != undefined
              ? parseInt(data.experience)
              : 0,
          SearchJobTypeId:
            data.jobType != null && data.jobType != undefined
              ? parseInt(data.jobType)
              : 0,
          Page: 1,
          PageSize: currentPageSize * currentPage,
          SearchTerm: "",
          SortOrder: "",
          JobId: 0,
        } as SearchRequest,
        ""
        // authorizationToken
      );
    })();
    setSearchExperienceId(
      data.experience != null && data.experience != undefined
        ? parseInt(data.experience)
        : 0
    );
    setSearchJobTypeId(
      data.jobType != null && data.jobType != undefined
        ? parseInt(data.jobType)
        : 0
    );
    //console.log(data);
    setSearchClickCount(searchClickCount + 1);
    // });
  };

  const handleJobBookMarked = (jobId: number) => {
    if (authorizationToken != null) {
      if (role === "Candidate") {
        saveBookMarkList(
          jobBookMarkDispatcher,
          {
            candidateId: loggedUserId,
            jobBookmarkedId: 0,
            jobId: jobId,
            isActive: true,
            rowId: 0,
          } as jobBookMarkSaveRequestModel,
          authorizationToken
        );
      } else {
        (async () => {
          await setVendorJobBookmark(
            vendorJobBookMarkDispatcher,
            {
              IsActive: true,
              jobBookmarkedId: 0,
              rowId: 0,
              vendorID: loggedUserId,
              jobId: jobId,
            } as VendorJobBookMarkRequestModel,
            authorizationToken
            // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImQ5ZjU0MjlmLTY3NTgtNGM0Ny04ODVhLTIwZjVkN2M4ODEyZiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTcyODEsImV4cCI6MTY0NDEzMzI4MSwiaWF0IjoxNjEyNTk3MjgxLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.sKevXWje7UM90S8rAZicVHpesgOz4IepSaY2JQkvcNM"
          );
        })();
      }
    }
  };

  const handleShareButtonClick = (currentShareId: any) => {
    setCurrentSharedJobId(currentShareId);
    setIsShareOpen(true);
  };

  const handleJobAlertCreateClick = () => {
    setIsOpen(!isOpen);
    setcurrentAlertId(0);
  };

  const handleJobAlert = (data: any) => {
    // setCandidateDashboardComponentState({
    //   ...CandidateDashboardComponentState,
    //   jobCategorys: data.jobCategorys,
    //   jobTypeData: data.jobTypeData,
    // });

    if (authorizationToken != null) {
      (async () => {
        await setJobAlertSave(
          jobAlertDispatcher,
          {
            RowId: currentAlertId,
            JobAlertId: 1,
            JobCategoryId: parseInt(data.jobCategorys),
            Location: data.location,
            jobTitle: data.jobTitle,
            JobTypeId: parseInt(data.jobTypeData),
            JobTitleId: 0,
            Active: data.radio === "1" ? true : false,
            CandidateId: loggedUserId,
            IsActive: true,
          } as jobAlertSaveRequestModel,
          authorizationToken
        );
      })();
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      {" "}
      {/* Header Start */}
      <div className="job_search_header">
        <div className="container-fluid">
          <div className="col-sm-6">
            {authorizationToken ? (
              role === "Candidate" ? (
                <a
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <i className="fa fa-2x fa-arrow-circle-left"></i>
                </a>
              ) : (
                <a
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <i className="fa fa-2x fa-arrow-circle-left"></i>
                </a>
              )
            ) : (
              <a
                onClick={() => {
                  history.push("/");
                }}
              >
                <i className="fa fa-2x fa-arrow-circle-left"></i>
              </a>
            )}
            {/* <a
              onClick={() => {
                history.push("/");
              }}
              className="CustomButtonCss"
            > */}
            {/* <img
              src={require("../../../images/logo_login.png")}
              src=src={require("../../../images/logo_login.png"
              /> */}
            {/* <button
                
                type="button"
                onClick={() => history.push("/")}
              > */}
            {/*  Back To Home */}
            {/* </button> */}
            {/* </a> */}
          </div>
          <div className="col-sm-6">
            {authorizationToken ? (
              <>
                <div
                  className="action_btn1"
                  onClick={() => {
                    setIsProfileOpen(!isProfileOpen);
                  }}
                >
                  <button type="button" className="jobs_action">
                    {/* {basicInfo.fullName != undefined ? basicInfo.fullName : ""}{" "} */}
                    {role === "Candidate" ? name : vendorName}

                    <i className="fa fa-angle-down" aria-hidden="true" />
                  </button>
                  <div className="login_jobs">
                    {/* <img
                  src={
                    profileImage != null && profileImage.total > 0
                      ? `https://careerapp.azurewebsites.net/Upload/ProfileImage/${profileImage.data[0].imageUrl}`
                      : require("../../images/profileDefault1.jpg")
                  }
                /> */}
                    {role === "Candidate" ? (
                      candidateProfileImage.data.length > 0 ? (
                        <img
                          className="img-responsive"
                          src={
                            environment.baseUrl +
                            `/Upload/ProfileImage/${candidateProfileImage.data[0]["imageUrl"]}`
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          className="img-responsive"
                          src={require("../../../images/profileDefault1.jpg")}
                        ></img>
                      )
                    ) : vendorProfileImage.data.length > 0 ? (
                      <img
                        className="img-responsive"
                        src={
                          environment.baseUrl +
                          `/Upload/VendorProfileImage/${vendorProfileImage.data[0]["imageUrl"]}`
                        }
                        alt=""
                      />
                    ) : (
                      <img
                        className="img-responsive"
                        src={require("../../../images/profileDefault1.jpg")}
                      ></img>
                    )}
                  </div>

                  <div
                    className="jobs_action_dp"
                    tabIndex={-1}
                    style={{ display: `${isProfileOpen ? "block" : "none"}` }}
                  >
                    <div className="action_sec">
                      {role === "Candidate" ? (
                        <ul ref={menuRef}>
                          <li>
                            <Link to="/candidate" className="active">
                              {" "}
                              <i
                                className="fa fa-th-large"
                                aria-hidden="true"
                              />
                              Dashboard{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/candidate/jobs-applied"
                              className="active"
                            >
                              {" "}
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Job Applied{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/candidate/digilocker/3"
                              className="active"
                            >
                              {" "}
                              <i className="fa fa-lock" aria-hidden="true" />
                              {name.split(" ")[0]}
                              's DigiLocker{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/candidate/my-resume" className="active">
                              {" "}
                              <i className="fa fa-files-o" aria-hidden="true" />
                              My Resumes{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/candidate/messages" className="active">
                              {" "}
                              <i
                                className="fa fa-commenting-o"
                                aria-hidden="true"
                              />
                              Messages{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/candidate/saved-jobs" className="active">
                              {" "}
                              <i
                                className="fa fa-suitcase"
                                aria-hidden="true"
                              />
                              Saved Jobs{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/candidate/notify-jobs/0"
                              className="active"
                            >
                              {" "}
                              <i
                                className="fa fa-suitcase"
                                aria-hidden="true"
                              />
                              Notify Jobs{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/candidate/my-profile" className="active">
                              {" "}
                              <i className="fa fa-user-o" aria-hidden="true" />
                              My Profile{" "}
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/candidate">DashBoard</Link>
                          </li> */}
                          <li>
                            <a onClick={onLogout} className="_cursor-pointer">
                              <i
                                className="fa fa-power-off"
                                aria-hidden="true"
                              ></i>
                              Logout
                            </a>{" "}
                          </li>
                        </ul>
                      ) : (
                        <ul ref={menuRef}>
                          <li>
                            <Link to="/vendor">
                              {" "}
                              <i
                                className="fa fa-th-large"
                                aria-hidden="true"
                              />
                              Dashboard{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/vendor/jobs-applied">
                              {" "}
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Job Applied{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/vendor/messages">
                              {" "}
                              <i
                                className="fa fa-commenting-o"
                                aria-hidden="true"
                              />
                              Messages{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/vendor/saved-jobs">
                              {" "}
                              <i
                                className="fa fa-suitcase"
                                aria-hidden="true"
                              />
                              Saved Jobs{" "}
                            </Link>
                          </li>

                          <li>
                            <Link to="/vendor/my-profile">
                              {" "}
                              <i className="fa fa-user-o" aria-hidden="true" />
                              My Profile{" "}
                            </Link>
                          </li>
                          {/* <li>
                    <Link to="/candidate">DashBoard</Link>
                  </li> */}
                          <li>
                            <a onClick={onLogout} className="_cursor-pointer">
                              <i
                                className="fa fa-power-off"
                                aria-hidden="true"
                              ></i>
                              Logout
                            </a>{" "}
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="top_links">
                  <>
                    <Link to="/login">Login</Link>
                    <Link to="/registration">Register</Link>
                  </>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Header Ends */}
      <div className="job_search_page">
        <div className=" container-fluid">
          <div>
            {/* job search */}
            <div className="col-sm-12">
              <div className="job_search_area">
                <div className="row">
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        <input
                          name="jobTitle"
                          ref={register({ required: false })}
                          type="text"
                          className="form-control"
                          placeholder="Job Title"
                        />

                        <div className="search_icons">
                          <img
                            src={require("./../../../images/search_icon.png")}
                            // src="images/search_icon.png"
                          />
                        </div>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="jobTitle"
                        render={({ message }) => (
                          <div className="register_validation">{message}</div>
                        )}
                      />
                    </div>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        <input
                          name="location"
                          ref={register({ required: false })}
                          type="text"
                          className="form-control"
                          placeholder="Location"
                        />
                        <div className="search_icons">
                          <img
                            src={require("./../../..//images/locattion_icon.png")}
                            // src="images/locattion_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        <span className="select-search">
                          <Controller
                            control={control}
                            name="jobType"
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  jobType.data != undefined
                                    ? jobType.data.map((e) => {
                                        return {
                                          value: e.rowId,
                                          label: e.title,
                                        };
                                      })
                                    : []
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                nameForLablel="Job Type"
                              />
                            )}
                          />
                        </span>
                        <div className="search_icons">
                          <img
                            src={require("./../../../images/type_icon.png")}
                            //  src="images/type_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="job_search_forms">
                      <div className="cr_serach_br">
                        <span className="select-search">
                          <Controller
                            control={control}
                            name="experience"
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  experience.data != undefined
                                    ? experience.data.map((e) => {
                                        return {
                                          value: e.expereinceTypeId,
                                          label:
                                            e.title !== "0"
                                              ? e.title + " " + "years"
                                              : e.title + " " + "year",
                                        };
                                      })
                                    : []
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                nameForLablel="Experience"
                              />
                            )}
                          />
                        </span>
                        <div className="search_icons">
                          <img
                            src={require("./../../../images/type_icon.png")}
                            //  src="images/type_icon.png"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="search_button">
                      <div className="search">
                        {isSearchLoader ? (
                          <button className="CustomButtonCss" type="button">
                            <Spin indicator={antIcon} />
                          </button>
                        ) : (
                          <button className="CustomButtonCss" type="submit">
                            Search
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* endjob search */}

            <div className="col-sm-12">
              <div className="job_search_left">
                {/* --- */}
                <div className="create_job_alert">
                  <div className="create_job_alert_head">Create Job Alert</div>
                  <div className="create_job_alert_con{">
                    Create job alerts and get notifications for jobs.
                  </div>
                  <div className="create_job_alert_btn">
                    {authorizationToken !== null ? (
                      <a
                        onClick={
                          //   ()=>{
                          //    setIsOpen(!isOpen);
                          // }
                          handleJobAlertCreateClick
                        }
                        data-target="#job_alert"
                        data-toggle="modal"
                      >
                        Job Alert
                      </a>
                    ) : (
                      <Tooltip title="Please login with your registered email and password to apply for this job post">
                        <a data-target="#job_alert" data-toggle="modal">
                          Job Alert
                        </a>
                      </Tooltip>
                    )}
                  </div>
                </div>
                {/* --- */}
                <div className="accordian_main">
                  <div className="accordian_tab">
                    <h1
                      className={`${isJobTypeOpen ? "active" : ""}`}
                      onClick={() => {
                        setIsJobTypeOpen(!isJobTypeOpen);
                      }}
                    >
                      Job Type <div className=" arrow" />
                    </h1>

                    <div
                      className="acc_con"
                      style={{ display: `${isJobTypeOpen ? "block" : "none"}` }}
                    >
                      {!jobType || !jobType.data || jobType.data.length <= 0 ? (
                        <h1>loading..</h1>
                      ) : (
                        jobType.data.map((jobTypeData, index) => {
                          return (
                            <div key={index}>
                              <input
                                onChange={(e: any) => handleJobTypeChange(e)}
                                value={`${jobTypeData.rowId.toString()}`}
                                id="checkbox"
                                type="checkbox"
                                name="checkbox"
                              />
                              <label htmlFor="checkbox2">
                                <span />
                                {jobTypeData.title}{" "}
                              </label>
                              <div className="jobs_notification"></div>
                            </div>
                          );
                        })
                      )}
                    </div>

                    <h1
                      className={`${isExperienceOpen ? "active" : ""}`}
                      onClick={() => {
                        setExperienceOpen(!isExperienceOpen);
                      }}
                    >
                      Experience <div className=" arrow" />
                    </h1>
                    <div
                      className="acc_con"
                      style={{
                        display: `${isExperienceOpen ? "block" : "none"}`,
                      }}
                    >
                      {!experience ||
                      !experience.data ||
                      experience.data.length <= 0 ? (
                        <h1>loading..</h1>
                      ) : (
                        experience.data.map((exp, index) => {
                          return (
                            <div key={index}>
                              <input
                                onClick={() =>
                                  handleExperienceChange(exp.expereinceTypeId)
                                }
                                id="radio1"
                                type="radio"
                                name="radio1"
                                defaultValue={0}
                                // defaultChecked
                              />
                              <label htmlFor="radio1">
                                <span>
                                  <span />
                                </span>{" "}
                                {exp.title}{" "}
                                {exp.title !== "0" ? "years" : "year"}
                              </label>
                              <div className="jobs_notification"></div>
                            </div>
                          );
                        })
                      )}
                    </div>
                    <h1
                      className={`${isDatePostedOpen ? "active" : ""}`}
                      onClick={() => {
                        setIsDatePostedOpen(!isDatePostedOpen);
                      }}
                    >
                      Date Posted <div className=" arrow" />
                    </h1>
                    <div
                      className="acc_con"
                      style={{
                        display: `${isDatePostedOpen ? "block" : "none"}`,
                      }}
                    >
                      {!datePosted ||
                      !datePosted.data ||
                      datePosted.data.length <= 0 ? (
                        <h1>loading..</h1>
                      ) : (
                        datePosted.data.map((date, index) => {
                          return (
                            <div key={index}>
                              <input
                                onClick={() =>
                                  handleDatePosteChange(date.datePostedId)
                                }
                                id="radio2"
                                type="radio"
                                name="radio2"
                                defaultValue={0}
                                // defaultChecked
                              />
                              <label htmlFor="radio2">
                                <span>
                                  <span />
                                </span>{" "}
                                Last {date.day !== 0 ? date.day : date.month}{" "}
                                {date.day !== 0 ? "days" : "months"}{" "}
                              </label>
                              <div className="jobs_notification"></div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="job_search_right">
                <div
                  id="verticalTab"
                  className="jobsearch-tabs  resp-vtabs"
                  style={{ display: "block", width: "100%", margin: "0px" }}
                >
                  <ul className="resp-tabs-list ">
                    <div
                      id="jobs_searh1"
                      style={{ overflow: "hidden", outline: "none" }}
                      tabIndex={0}
                    >
                      {searchResultDataSate != undefined ? (
                        searchResultDataSate.length > 0 ? (
                          <InfiniteScroll
                            className="_custom-infinite-scroll-class"
                            height={900}
                            dataLength={searchResultDataSate.length + 1}
                            // dataLength={2000}
                            // dataLength={totalCount}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            loader={<h4>Loading...</h4>}
                            // initialScrollY={indexState * 80}
                            endMessage={
                              <p style={{ textAlign: "center" }}>
                                <b>No more data to load</b>
                              </p>
                            }
                          >
                            {searchResultDataSate !== undefined
                              ? searchResultDataSate.map((data, index) => {
                                  return (
                                    <>
                                      <li
                                        className={`resp-tab-item ${
                                          data.jobId === parseInt(paramId)
                                            ? "resp-tab-active"
                                            : ""
                                        } `}
                                        aria-controls="tab_item-0"
                                        role="tab"
                                      >
                                        <div
                                          onClick={() => {
                                            history.push(
                                              `/jobSearch/${paramTitle}/${data.jobId}/${paramJobTitle}/${paramLocation}/${paramJobType}`
                                            );
                                          }}
                                        >
                                          <div className="job_search_icon">
                                            {data.jobIcon !== null &&
                                            data.jobIcon !== "" &&
                                            data.jobIcon !== '""' &&
                                            data.jobIcon !== "null" ? (
                                              <img
                                                style={{
                                                  width: 60,
                                                  marginTop: 7,
                                                }}
                                                src={
                                                  environment.baseUrl +
                                                  `/Upload/JobIconImage/${data.jobIcon}`
                                                }
                                              />
                                            ) : (
                                              <img
                                                src={require("../../../images/designer_icon.png")}
                                                width={52}
                                                height={52}
                                              />
                                            )}
                                          </div>
                                          <div className="job_search_con">
                                            <div className="job_search_con_head">
                                              {data.title}
                                            </div>
                                            <div className="job_search_con_con">
                                              <i
                                                className="fa fa-map-marker"
                                                aria-hidden="true"
                                              />{" "}
                                              {data.location}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="job_search_social">
                                          <div>
                                            <i
                                              onClick={() =>
                                                handleShareButtonClick(
                                                  data.jobId
                                                )
                                              }
                                              className="fa fa-share-alt"
                                              aria-hidden="true"
                                            />
                                          </div>
                                          <div>
                                            {authorizationToken !== null ? (
                                              data.isBookmarked ? (
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    handleJobBookMarked(
                                                      data.jobId
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <i
                                                  className="fa fa-star-o"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    handleJobBookMarked(
                                                      data.jobId
                                                    )
                                                  }
                                                />
                                              )
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="clearfix" />
                                        <div
                                          onClick={() => {
                                            history.push(
                                              `/jobSearch/${paramTitle}/${data.jobId}/${paramJobTitle}/${paramLocation}/${paramJobType}`
                                            );
                                          }}
                                        >
                                          <div className="job_fulltime">
                                            {data.jobType}
                                          </div>
                                          <div className="job_search_exp">
                                            {data.experience} Years
                                          </div>
                                          <div className="job_search_post">
                                            {data.isApplied
                                              ? `${data.daysAgo} days ago`
                                              : data.postedDate}
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })
                              : null}
                          </InfiniteScroll>
                        ) : loader ? (
                          <Spin size="large" style={{ padding: 20 }} />
                        ) : (
                          <h4>{jobMessage}</h4>
                        )
                      ) : null}
                    </div>
                  </ul>

                  <SearchJobDetails
                    searchResultDataSateDetails={
                      searchResultDataSate !== undefined &&
                      searchResultDataSate !== null
                        ? searchResultDataSate.filter((data, index) => {
                            return data.jobId === parseInt(paramId);
                          })
                        : null
                    }
                    handleJobBookMarked={handleJobBookMarked}
                    handleShareButtonClick={handleShareButtonClick}
                    roleData={role !== undefined ? role : ""}
                    documentStatus={documentStatus}
                  />
                </div>
                <Modal
                  show={isShareOpen}
                  onHide={() => {
                    setIsShareOpen(!isShareOpen);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Share this document on</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="col-sm-12 m_t_30 text-center">
                      <div className="social1">
                        <WhatsappShareButton
                          url={encodeURI(
                            `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentSharedJobId}/searchtitle/location/0`
                          )}
                          className={""}
                          title={"CareerApp Job Post"}
                        >
                          {/* <a href="#" className="social_face"> */}
                          <Tooltip title="whatsApp">
                            <i
                              className="fa fa-whatsapp"
                              style={{
                                fontSize: 51,
                                color: "green",
                                paddingRight: 2,
                              }}
                            ></i>
                          </Tooltip>
                          {/* </a> */}
                        </WhatsappShareButton>{" "}
                        <TelegramShareButton
                          url={`career app job Share`}
                          title={encodeURI(
                            `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentSharedJobId}/searchtitle/location/0`
                          )}
                        >
                          <Tooltip title="Telegram">
                            <i
                              className="fa fa-telegram"
                              style={{
                                fontSize: 48,
                                color: "rgb(55, 174, 226)",
                              }}
                            ></i>
                          </Tooltip>
                        </TelegramShareButton>
                        <EmailShareButton
                          url={encodeURI(
                            `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentSharedJobId}/searchtitle/location/0`
                          )}
                          subject={"Jobs In Carrer app"}
                          body={"This is a Jobs from   Carrer app"}
                          className={""}
                        >
                          {/* <EmailIcon></EmailIcon> */}
                          {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                          <Tooltip title="Gmail">
                            <img
                              src={require("../../../images/gmail-icon-svg-28.jpg")}
                              style={{ height: 66, marginTop: -24 }}
                            />
                          </Tooltip>
                        </EmailShareButton>{" "}
                        <CopyToClipboard
                          //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                          text={`https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentSharedJobId}/searchtitle/location/0`}
                        >
                          <Tooltip title="copy to clipboard">
                            {" "}
                            {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                            <img
                              src={require("../../../images/copy-clipboard-icon-28.jpg")}
                              style={{
                                height: 66,
                                marginTop: -24,
                                marginLeft: -15,
                              }}
                              onClick={() => {
                                setIsShareOpen(!isShareOpen);
                              }}
                            />
                          </Tooltip>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="modal-footer  m-t-30"></div>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={isOpen}
                  onHide={() => {
                    setIsOpen(!isOpen);
                    setcurrentAlertId(0);
                  }}
                >
                  <form onSubmit={handleSubmit2(handleJobAlert)} noValidate>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {currentAlertId == 0
                          ? "New Job Alert"
                          : "Update Job Alert"}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="form-group">
                        <label htmlFor="email" className="required">
                          Job Category
                        </label>
                        <span className="select-wrapper-sec">
                          <Controller
                            control={control2}
                            name="jobCategorys"
                            rules={{
                              required: "Job Category is required",
                            }}
                            // value=''
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  myProfile.jobCategories != undefined
                                    ? myProfile.jobCategories.map((e: any) => {
                                        return {
                                          value: e["value"],
                                          label: e["caption"],
                                        };
                                      })
                                    : jobCategoryData
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                nameForLablel="Select"
                              />
                            )}
                          />
                        </span>
                        <ErrorMessage
                          errors={errors2}
                          name="jobCategorys"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="email" className="required">
                          Job Type
                        </label>
                        <span className="select-wrapper-sec">
                          <Controller
                            className="custom-select-sec"
                            control={control2}
                            name="jobTypeData"
                            rules={{
                              required: "Job Type is required",
                            }}
                            // rules={{
                            //   validate: (value) => {
                            //     return value === "" ? "Job Type is required" : "";
                            //   },
                            // }}
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  // myProfile.jobTypes != undefined
                                  //   ? myProfile.jobTypes.map((e: any) => {
                                  //       return {
                                  //         value: e["value"],
                                  //         label: e["caption"],
                                  //       };
                                  //     })
                                  //   :
                                  jobTypeDatas
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                nameForLablel="Select"
                              />
                            )}
                          />
                        </span>
                        <ErrorMessage
                          errors={errors2}
                          name="jobTypeData"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="email" className="required">
                          Job Title
                        </label>
                        <span className="select-wrapper-sec">
                          <Controller
                            control={control2}
                            name="jobTitle"
                            rules={{
                              required: "Job Title is required",
                            }}
                            // value=''
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  tempData != undefined
                                    ? tempData.map((e) => {
                                        return {
                                          value: e["value"],
                                          label: e["caption"],
                                        };
                                      })
                                    : tempData
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                nameForLablel="Select"
                              />
                            )}
                          />
                        </span>
                      </div>
                      <ErrorMessage
                        errors={errors2}
                        name="jobTitle"
                        render={({ message }) => (
                          <div className="register_validation">{message}</div>
                        )}
                      />
                      <div className="form-group">
                        <label htmlFor="email">Location</label>
                        <input
                          defaultValue={location}
                          type="text"
                          className="form-control "
                          name="location"
                          placeholder=" "
                          ref={register2({
                            required: false,
                          })}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          id="radio1"
                          type="radio"
                          ref={register2({
                            required: false,
                          })}
                          name="radio"
                          defaultValue={1}
                          defaultChecked
                        />
                        <label htmlFor="radio1" style={{ marginRight: 20 }}>
                          <span>
                            <span />
                          </span>{" "}
                          Active
                        </label>
                        <input
                          id="radio2"
                          type="radio"
                          ref={register2({
                            required: false,
                          })}
                          name="radio"
                          defaultValue={2}
                          // defaultChecked
                        />
                        <label
                          htmlFor="radio2"
                          // style={{ marginLeft: 1 }}
                        >
                          <span>
                            <span />
                          </span>{" "}
                          Inactive
                        </label>
                      </div>
                    </Modal.Body>
                    <div className="modal-footer  m-t-30">
                      <button
                        className="btn btn-success save-event waves-effect waves-light"
                        type="submit"
                      >
                        Create Alert
                      </button>
                      <button
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setcurrentAlertId(0);
                        }}
                        data-dismiss="modal"
                        className="btn btn-default waves-effect"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="clearfix" />
                  </form>
                </Modal>

                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SearchGenericComponent);
