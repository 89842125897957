import { camelCase, pascalCase } from "change-case";

export function reactHookFormServerErrors<T>(
  errors: { [P in keyof T]?: string[] },
  setError: (
    fieldName: keyof T,
    error: { type: string; message: string }
  ) => void
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: "server",
      message: errors[key as keyof T]!.join(
        ". "
      ),
    });
  });
}

type objectIndexing = {
  [key: string]: any
}

export function pascal2Camel(obj = {}) {
  const res: objectIndexing = {};
  for (let [key, value] of Object.entries(obj)) {
    res[camelCase(key)] = value;
  }
  return res;
}

export function camel2Pascal(obj = {}) {
  const res: objectIndexing = {};
  for (let [key, value] of Object.entries(obj)) {
    res[pascalCase(key)] = value;
  }
  return res;
}