import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { IExperienceAction, experienceReducer } from "../../action/general/ExperienceAction";
import { experienceViewModel } from "../../models/general/Experience";


export const ExperienceDispatcherContext = React.createContext<React.Dispatch<IExperienceAction> | null>(null);
export const ExperienceStateContext = React.createContext<IExperienceManagementState | null>(null);

export interface IExperienceManagementProps extends RouteComponentProps<any> { }

export interface IExperienceManagementState {
    visible:boolean;
    value:number;
    experience:experienceViewModel
}

export const initialExperienceManagementState = {
    visible:false,
    value:0,
    experience:{} as experienceViewModel,
} as IExperienceManagementState;

export const ExperienceContextProvider: React.FC = ({ children }) => {
    const [experienceState, dispatcher] = useImmerReducer(experienceReducer, initialExperienceManagementState);

    return (
        <ExperienceDispatcherContext.Provider value={dispatcher}>
            <ExperienceStateContext.Provider value={experienceState}>
                {children}
            </ExperienceStateContext.Provider>
        </ExperienceDispatcherContext.Provider>
    )
}