import { ErrorMessage } from "@hookform/error-message";
import {
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Popover,
  Radio,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { Controller, useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useHistory } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  getTitleWithCategoryId,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import {
  getAllVendorCount,
  useJobTypeContext,
  useJobTypeDispatcher,
} from "../../../action/general/JobTypeAction";
import {
  getJobCategoryList,
  useJobCategoryContext,
  useJobCategoryDispatcher,
} from "../../../action/JobCategoryAction";
import {
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import {
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../../action/MyProfileVendorAction";
import {
  deleteJobAlertVendorList,
  getAllJobAlertVendorList,
  setJobAlertVendorSave,
  useJobAlertVendorContext,
  useJobAlertVendorDispatcher,
} from "../../../action/vendor/JobAlertAction";
import {
  getVendorJobAppliedList,
  setVendorJobAppliedSave,
  useVendorJobAppliedContext,
  useVendorJobAppliedDispatcher,
} from "../../../action/vendor/VendorJobAppliedAction";
import {
  setVendorJobBookmark,
  useVendorJobBookMarkContext,
  useVendorJobBookMarkDispatcher,
} from "../../../action/vendor/VendorJobBookMarkAction";
import {
  DeleteVendorJobStatusList,
  getVendorJobStatusList,
  useVendorJobStatusContext,
  useVendorJobStatusDispatcher,
} from "../../../action/vendor/VendorJobStatusAction";
import {
  getMatchedJobVendorList,
  useMatchedJobVendorContext,
  useMatchedJobVendorDispatcher,
} from "../../../action/vendor/VendorMatchedJobAction";
import {
  getDocumentStatus,
  getMessageDashboardList,
} from "../../../apis/vendor";
import { useGlobalSpinnerContext } from "../../../context/GlobalSpinner";
import { useNotificationListContext } from "../../../context/vendor/VendorMyProfile";
import {
  jobAlertGetAllModel,
  jobAlertTitleDropdownResultModel,
} from "../../../models/candidate/JobAlert";
import { categoryWithId } from "../../../models/candidate/JobCategory";
import { VendorCountAllRequestModel } from "../../../models/general/JobType";
import {
  jobAlertVendorGellAllRequestModel,
  jobAlertVendorSaveRequestModel,
} from "../../../models/vendor/JobAlert";
import {
  VendorCandidateList,
  VendorGetAllJobRequestModel,
  VendorJobAppliedRequestModel,
} from "../../../models/vendor/VendorJobApplied";
import { VendorJobBookMarkRequestModel } from "../../../models/vendor/VendorJobBookMark";
import { JobStatusGetAllRequestModel } from "../../../models/vendor/VendorJobStatus";
import { matchedJobVendorRequestModel } from "../../../models/vendor/VendorMatchedJob";
import AuthService from "../../../services/AuthService";
import SelectOption from "../../candidate/my_profile/components/SelectOption";
import { jobAlertVendorGetAll } from "../../../models/vendor/JobAlert";
import CopyToClipboard from "react-copy-to-clipboard";

interface ReactSelectOption {
  value: string;
  label: string;
}
interface IVendorDashboardComponentProps {}
interface IVendorDashboardComponentState {
  jobCategorys: string;
  jobTypeData: string;
  location: string;
  tempData: jobAlertTitleDropdownResultModel[];
  countNext: number;
  carouselValue: number;
  countNextStatus: number;
  carouselValueStatus: number;
  firstName: string;
  middleName: string;
  lastName: string;
  passportNo: string;
  dob: number;
  activeVisa: string;
  visaIssueCountry: string;
  expiryDate: number;
  currentJobId: number;
  filterFlag: boolean;
}
const defaultValues = {
  jobTypeData: "",
  jobCategorys: "",
  tempData: [],
  location: " ",
  countNext: 0,
  carouselValue: 0,
  countNextStatus: 0,
  carouselValueStatus: 0,
  firstName: "",
  middleName: "",
  lastName: "",
  passportNo: "",
  dob: 0,
  activeVisa: "",
  visaIssueCountry: "",
  expiryDate: 0,
  currentJobId: 0,
  filterFlag: true,
};
// const authorizationToken = AuthService.accessToken;
// const authorizationToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8';
// let loggedUserId=1;

const VendorDashboardComponent: React.FC<IVendorDashboardComponentProps> = (
  props
) => {
  const [
    vendorDashboardComponentState,
    setVendorDashboardComponentState,
  ] = React.useState<IVendorDashboardComponentState>(defaultValues);

  let jobCategoryData: ReactSelectOption[] = [];
  const jobTypeDatas = [
    { value: "1", label: "Permanent" },
    { value: "2", label: "Temporary" },
    { value: "3", label: "Freelancer" },
    { value: "4", label: "Any" },
  ];
  let jobTitleData: ReactSelectOption[] = [];
  const globalSpinner = useGlobalSpinnerContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [jobApplayOpen, setJobApplayOpen] = useState<boolean>(false);
  const [jobStatusOPen, setJobStatusOPen] = useState<boolean>(false);
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const [currentAlertId, setcurrentAlertId] = useState<number>(0);
  const [radioValue, setRadioValue] = useState<number>(1);
  const [radioValue2, setRadioValue2] = useState<number>(1000);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isRemovedAll, setIsRemovedCheckedAll] = useState(false);
  const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const [isChecked, setIsChecked] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isRenderCheckedList, setIsRenderCheckedList] = useState(true);
  const [statusFilter, setStatusFilter] = useState<number>(0);
  const [isHide, setIsHide] = useState<boolean>(false);
  const [list, setList] = useState();
  const [messageList, setMessageList] = useState();
  const [messageListFlag, setMessageListFlag] = useState(0);
  const [listFlag, setListFlag] = useState(0);
  const [indexCount, setIndexCount] = useState(0);
  let scrollDiv = useRef<any>(null);
  let IdArray = [] as any[];

  // let jobPostArray=[]
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  const watchAllFields = watch2();
  const watchAllFields3 = watch3();

  const {
    carouselValue,
    countNext,
    filterFlag,
    currentJobId,
    carouselValueStatus,
    countNextStatus,
  } = vendorDashboardComponentState;
  const [currentPageOfExpDocList, setCurrentPageOfExpDocList] = useState(1);
  const {
    jobCategorys,
    jobTypeData,
    tempData,
    location,
  } = vendorDashboardComponentState;

  const myVendorProfileDispatcher = useVendorMyProfileDispatcher();
  const myVendorProfileContext = useVendorMyProfileContext();
  const {
    basicInfo,
    myProfile,
    myProfileProgressBar,
    profileImage,
  } = myVendorProfileContext;

  const myProfileVContext = useMyProfileContext();
  const { loggedUserId } = myProfileVContext;

  const jobAlertDispatcher = useJobAlertDispatcher();
  const jobAlertContext = useJobAlertContext();
  const {
    jobAlert,
    jobAlertGetAll,
    jobAlertSaveRespond,
    jobAlertTitleDropdownResult,
  } = jobAlertContext;

  const jobAlertVendorDispatcher = useJobAlertVendorDispatcher();
  const jobAlertVendorContext = useJobAlertVendorContext();
  const {
    jobAlertVendorSaveRespond,
    jobAlertVendorGetAll,
    JobAlertVendorRoleMapSaveRespond,
  } = jobAlertVendorContext;

  const matchedJobVendorDispatcher = useMatchedJobVendorDispatcher();
  const matchedJobVendorContext = useMatchedJobVendorContext();
  const { matchedJobVendor } = matchedJobVendorContext;

  const jobAppliedVendorDispatcher = useVendorJobAppliedDispatcher();
  const jobAppliedVendorContext = useVendorJobAppliedContext();
  const {
    vendorJobAppliedSaveRespond,
    vendorGetAllJobList,
  } = jobAppliedVendorContext;

  const vendorJobBookMarkDispatcher = useVendorJobBookMarkDispatcher();
  const vendorJobBookMarkContext = useVendorJobBookMarkContext();
  const { bookmarkSaveResult } = vendorJobBookMarkContext;

  const vendorJobStatusDispatcher = useVendorJobStatusDispatcher();
  const vendorJobStatusContext = useVendorJobStatusContext();
  const {
    vendorGetAllJobStatus,
    vendorJobStatusDeleteResult,
  } = vendorJobStatusContext;

  const [vendorJobStatus, setVendorJobStatus] = useState(
    vendorGetAllJobStatus != undefined &&
      vendorGetAllJobStatus.data != undefined
      ? vendorGetAllJobStatus.data
      : []
  );

  const jobTypeContext = useJobTypeContext();
  const { vendorGetAllCount } = jobTypeContext;

  const jobCategoryDispatcher = useJobCategoryDispatcher();
  const jobCategoryContext = useJobCategoryContext();
  const { jobCategory } = jobCategoryContext;

  const notificationListContext = useNotificationListContext();
  const { notificationList, getNotificationList } = notificationListContext;

  const authorizationToken = AuthService.accessToken;

  const myProfileDispatcher = useMyProfileDispatcher();

  const jobTypeDispatcher = useJobTypeDispatcher();

  const secondButtonRef = React.useRef<HTMLButtonElement | null>(null);

  const [jobAlertResultDataSate, setobAlertResultDataState] = useState<
    jobAlertVendorGetAll[]
  >([]);
  const [currentPageJobAlert, setCurrentPageJobAlert] = useState<number>(1);
  const [hasMore, setHasMore] = React.useState(true);
  let history = useHistory();

  const [documentStatus, setDocumentStatus] = useState("");

  console.log(3030, loggedUserId);

  console.log(3031, matchedJobVendor);
  console.log(3032, bookmarkSaveResult);

  React.useEffect(() => {
    if (loggedUserId) {
      getDocumentStatus({
        VendorId: Number(loggedUserId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log("uploaded-document-status success", res.data);
          setDocumentStatus(res.data);
        })
        .catch((err) => {
          console.log("uploaded-document-status error", err);
        });
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (
      loggedUserId > 0 &&
      authorizationToken != null &&
      authorizationToken != undefined
    ) {
      getMessageDashboardList({
        UserId: Number(AuthService.currentUser?.id),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      })
        .then((res) => {
          console.log("Dashboard message ", res);
          setMessageList(
            res.data.data.map((item: any, index: any) => {
              if (item["unReadCount"] > 0) {
                setMessageListFlag(1);
                return (
                  <div className="message_sec1">
                    {/* <Link to="vendor/messages"> */}
                    <Link
                      to={{
                        pathname: "vendor/messages",
                        state: item,
                      }}
                    >
                      <span className="message_icon">
                        <img
                          src={
                            item["imageUrl"] !== "null" &&
                            item["imageUrl"] !== null &&
                            item["imageUrl"] !== undefined
                              ? `https://careerapp.azurewebsites.net/Upload/AdminProfileImage/${item["imageUrl"]}`
                              : require("./../../../images/profileDefault1.jpg")
                          }
                          className="img-responsive"
                        />
                      </span>
                      <div className="message_con_vend">
                        <div className="message_head">{item["userName"]}</div>
                        <div className="message_pra">{item["message"]}</div>
                      </div>
                      <div className="message_noti">
                        <div className="message_noti_con">
                          {item["daysAgo"]} day ago
                        </div>
                        <div className="message_noti_count">
                          {item["unReadCount"]}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })
          );
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (
      loggedUserId > 0 &&
      authorizationToken != null &&
      authorizationToken != undefined
    ) {
      console.log(loggedUserId, " notificationList0 ", authorizationToken);
      getNotificationList({
        VendorId: loggedUserId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      });
      console.log(
        loggedUserId,
        " notificationList0 ",
        notificationList,
        " ",
        authorizationToken
      );
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (
      notificationList != null &&
      notificationList != undefined &&
      authorizationToken != null &&
      authorizationToken != undefined &&
      loggedUserId > 0
    ) {
      if (notificationList.data != undefined) {
        console.log(
          loggedUserId,
          " notificationList1 ",
          notificationList,
          " ",
          authorizationToken
        );

        setList(
          notificationList.data.map((item: String) => {
            setListFlag(1);
            if (item.includes("Profile") || item.includes("Password")) {
              return (
                <div className="notification_sec">
                  <Link to="vendor/my-profile">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.includes("Matched")) {
              return (
                <div className="notification_sec">
                  <a
                    onClick={() => handleScrollClick()}
                    // onClick={scrollDiv.current.scrollIntoView({
                    //   behavior: "smooth",
                    // })}
                  >
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </a>
                </div>
              );
            } else if (item.includes("applied")) {
              return (
                <div className="notification_sec">
                  <Link to="vendor/jobs-applied">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else {
              return (
                <div className="notification_sec">
                  <Link to="/vendor">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            }
          })
        );
      }
    }
  }, [loggedUserId, notificationList]);

  const [radioIndexList, setRadioIndexList] = useState<any[]>([]);

  const handleOnRadioButtonChange = (e: any) => {
    if (e.target.value.charAt(0) == "f") {
      const item = radioIndexList.filter((value, index) => {
        return value != parseInt(e.target.value.substring(1));
      });
      setRadioIndexList(item);
    } else {
      setRadioIndexList((radioIndexList: any) => [
        ...radioIndexList,
        parseInt(e.target.value.substring(1)),
      ]);
    }
  };

  const handleRemoveIndex = (indexValue: any) => {
    const item = radioIndexList.filter((value, index) => {
      return value != indexValue;
    });
    setRadioIndexList(item);
  };

  const onFinish = (values: any) => {
    console.log("Received values of form:", values);

    let vendorCandidateListArray = [
      {
        rowId: 0,
        dob: values.dob != undefined ? values.dob._d : null,
        expDate: values.expiryDate != undefined ? values.expiryDate._d : null,
        firstName: values.firstName,
        isActive: true,
        isActiveVisa:
          values.visaIssueCountry === "" ||
          values.expiryDate == null ||
          values.visaIssueCountry == undefined ||
          values.expiryDate == undefined
            ? false
            : true,
        lastName: values.lastName,
        middleName: values.middleName,
        passPortNumber: values.passportNo,
        visaIssueCountry: values.visaIssueCountry,
      },
    ] as VendorCandidateList[];

    if (values.users != undefined && values.users.length > 0) {
      values.users.forEach((user: any) => {
        vendorCandidateListArray.push({
          rowId: 0,
          dob: user.dob != undefined ? user.dob._d : null,
          visaIssueCountry: user.visaIssueCountry,
          passPortNumber: user.passportNo,
          middleName: user.middleName,
          lastName: user.lastName,
          isActiveVisa:
            user.visaIssueCountry === "" ||
            user.expiryDate == null ||
            user.visaIssueCountry == undefined ||
            user.expiryDate == undefined
              ? false
              : true,
          isActive: true,
          firstName: user.firstName,
          expDate: user.expiryDate != undefined ? user.expiryDate._d : null,
        });
      });
    }
    if (authorizationToken != null && loggedUserId > 0) {
      (async () => {
        await setVendorJobAppliedSave(
          jobAppliedVendorDispatcher,
          {
            jobId: currentJobId,
            rowId: 0,
            vendorCandidateList: vendorCandidateListArray,
            vendorId: loggedUserId,
            IsActive: true,
          } as VendorJobAppliedRequestModel,
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
          authorizationToken
        );
      })();
    }
  };

  const onFinishStatus = (values: any) => {
    console.log("Received values of form:", values);
    console.log("Received vendorJobStatus", vendorJobStatus);
    let vendorCandidateListArray = [] as VendorCandidateList[];

    //     if(vendorJobStatus.length>0){
    // vendorJobStatus.forEach((data:any) => {
    //         vendorCandidateListArray.push({rowId:data.rowId,dob:data.dob,visaIssueCountry:data.visaIssueCountry,
    //           passPortNumber:data.passportNo,middleName:data.middleName,lastName:data.firstName,
    //           isActiveVisa:data.firstName,isActive:data.isActive,firstName:data.firstName,expDate:data.expDate
    //         })
    //     }
    // )}

    if (values.users != undefined && values.users.length > 0) {
      console.log("hi");
      values.users.forEach((user: any) => {
        vendorCandidateListArray.push({
          rowId: 0,
          dob: user.dob != undefined ? user.dob._d : null,
          visaIssueCountry: user.visaIssueCountry,
          passPortNumber: user.passportNo,
          middleName: user.middleName,
          lastName: user.lastName,
          isActiveVisa:
            user.visaIssueCountry === "" ||
            user.expiryDate == null ||
            user.visaIssueCountry == undefined ||
            user.expiryDate == undefined
              ? false
              : true,
          isActive: true,
          firstName: user.firstName,
          expDate: user.expiryDate != undefined ? user.expiryDate._d : null,
        });
      });
    }
    if (authorizationToken != null && loggedUserId > 0) {
      (async () => {
        await setVendorJobAppliedSave(
          jobAppliedVendorDispatcher,
          {
            jobId: currentJobId,
            rowId: 0,
            vendorCandidateList: vendorCandidateListArray,
            vendorId: loggedUserId,
            IsActive: true,
          } as VendorJobAppliedRequestModel,
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
          authorizationToken
        );
      })();
    }
  };

  React.useEffect(() => {
    // if (authorizationToken != null) {
    setVendorJobStatus([]);
    // }
  }, [statusFilter]);

  React.useEffect(() => {
    // if (authorizationToken != null) {
    setJobApplayOpen(false);
    setJobStatusOPen(false);
    // }
  }, [vendorJobAppliedSaveRespond]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId > 0) {
      (async () => {
        await getMatchedJobVendorList(
          matchedJobVendorDispatcher,
          {
            VendorId: loggedUserId,
            PageIndex: 1,
            PageSize: 100,
            SearchTerm: "",
            SortOrder: "",
            ShowInactive: false,
          } as matchedJobVendorRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [
    loggedUserId,
    bookmarkSaveResult,
    jobAlertVendorSaveRespond,
    JobAlertVendorRoleMapSaveRespond,
    vendorJobStatusDeleteResult,
    vendorJobAppliedSaveRespond,
  ]);

  React.useEffect(() => {
    if (
      authorizationToken != null &&
      jobStatusOPen == true &&
      loggedUserId > 0
    ) {
      globalSpinner.showSpinner();
      (async () => {
        await getVendorJobStatusList(
          vendorJobStatusDispatcher,
          {
            JobId: currentJobId,
            jobStatusId: statusFilter,
            Page: 1,
            PageSize: 100,
            SearchTerm: "",
            VendorId: loggedUserId,
            ShowInactive: false,
            SortOrder: "",
          } as JobStatusGetAllRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
        setFilterShow(false);
      })();
    }
  }, [loggedUserId, statusFilter, jobStatusOPen, vendorJobStatusDeleteResult]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId !== 0) {
      (async () => {
        await getAllVendorCount(
          jobTypeDispatcher,
          {
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
            vendorId: loggedUserId,
          } as VendorCountAllRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [
    vendorJobStatusDeleteResult,
    vendorJobAppliedSaveRespond,
    jobAlertVendorSaveRespond,
    JobAlertVendorRoleMapSaveRespond,
    matchedJobVendor,
    loggedUserId,
  ]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId > 0) {
      (async () => {
        await getVendorJobAppliedList(
          jobAppliedVendorDispatcher,
          {
            vendorId: loggedUserId,
            page: 1,
            pageSize: 100,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as VendorGetAllJobRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [
    loggedUserId,
    bookmarkSaveResult,
    vendorJobStatusDeleteResult,
    vendorJobAppliedSaveRespond,
  ]);

  React.useEffect(() => {
    if (authorizationToken != null) {
    }
  }, [loggedUserId, bookmarkSaveResult]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId > 0) {
      //   setCandidateDashboardComponentState({
      //     ...CandidateDashboardComponentState,
      //   jobAlertListData:[]
      //  });
      setobAlertResultDataState([]);
      (async () => {
        getAllJobAlertVendorList(
          jobAlertVendorDispatcher,
          {
            vendorId: loggedUserId,
            page: 1,
            pageSize: currentPageJobAlert * 3,
            searchTerm: "",
            sortOrder: "",
            showInactive: false,
          } as jobAlertVendorGellAllRequestModel,
          authorizationToken
        );
      })();
      // setISLoder(false);
    }
  }, [
    loggedUserId,
    jobAlertVendorSaveRespond,
    JobAlertVendorRoleMapSaveRespond,
  ]);

  const fetchMoreData = async () => {
    console.log(2424);

    console.log(2425, currentPageJobAlert);
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        getAllJobAlertVendorList(
          jobAlertVendorDispatcher,
          {
            vendorId: loggedUserId,
            page: currentPageJobAlert + 1,
            pageSize: 3,
            searchTerm: "",
            sortOrder: "",
            showInactive: false,
          } as jobAlertVendorGellAllRequestModel,
          authorizationToken
        );
      })();
      // setISLoder(false);
      setCurrentPageJobAlert(currentPageJobAlert + 1);
    }
  };

  React.useEffect(() => {
    setVendorDashboardComponentState((vendorDashboardComponentState) => {
      return {
        ...vendorDashboardComponentState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);

  React.useEffect(() => {
    console.log(1213, watchAllFields.jobCategorys);
    console.log(1212, authorizationToken);

    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  const handleJobAlert = (data: any) => {
    setVendorDashboardComponentState({
      ...vendorDashboardComponentState,
      jobCategorys: data.jobCategorys,
      jobTypeData: data.jobTypeData,
    });
    console.log(7878, data.radio);
    if (authorizationToken != null) {
      (async () => {
        await setJobAlertVendorSave(
          jobAlertVendorDispatcher,
          {
            rowId: currentAlertId,
            jobAlertId: 1,
            VendorId: loggedUserId,
            JobCategoryId: parseInt(data.jobCategorys),
            location: data.location,
            jobTitle: data.jobTitle,
            JobTypeId: parseInt(data.jobTypeData),
            // Active: true,
            isActive: true,
            Active: data.radio === "1" ? true : false,
          } as jobAlertVendorSaveRequestModel,
          authorizationToken
        );
      })();
      setIsOpen(!isOpen);
    }
  };

  console.log(575757, myProfile);

  const handleJobAlertClick = (rowId: any) => {
    // setValue2("location",jobAlertVendorGetAll!=undefined? jobAlertVendorGetAll.data.filter(data=>data.rowId==rowId)[0].location:'');
    setIsOpen(true);
    setcurrentAlertId(rowId);
    // setValue2("location",'avccccc')

    // setValue2("jobTypeData",'1')
    //  setValue2("jobCategorys",'1')
  };

  React.useEffect(() => {
    if (currentAlertId != 0) {
      console.log(
        717172,
        jobAlertVendorGetAll.data.filter(
          (data) => data.rowId == currentAlertId
        )[0].jobTypeId
      );
      console.log(
        717173,
        jobAlertVendorGetAll.data.filter((data) => data.rowId == currentAlertId)
      );
      setValue2(
        "jobTypeData",
        `${
          jobAlertVendorGetAll.data.filter(
            (data) => data.rowId == currentAlertId
          )[0].jobTypeId
        }`
      );
      setValue2(
        "jobCategorys",
        `${
          jobAlertVendorGetAll.data.filter(
            (data) => data.rowId == currentAlertId
          )[0].jobCategoryId
        }`
      );
      setValue2(
        "location",
        jobAlertVendorGetAll.data.filter(
          (data) => data.rowId == currentAlertId
        )[0].location
      );
      setValue2(
        "radio",
        jobAlertVendorGetAll.data.filter(
          (data) => data.rowId == currentAlertId
        )[0].active
          ? "1"
          : "2"
      );
    }
  }, [currentAlertId]);

  React.useEffect(() => {
    if (currentAlertId && tempData != undefined) {
      // setValue2("jobTitle",jobAlertVendorGetAll.data.filter(data=>data.rowId==currentAlertId)[0].jobTitle)
      setValue2(
        "jobTitle",
        jobAlertVendorGetAll.data.filter(
          (data) => data.rowId == currentAlertId
        )[0].jobTitle
      );
      console.log(
        717175,
        jobAlertVendorGetAll.data.filter(
          (data) => data.rowId == currentAlertId
        )[0].jobTitle
      );
      console.log(717176, tempData);
    }
  }, [watchAllFields.jobCategorys, tempData]);

  const handleJobAlertCreateClick = () => {
    setIsOpen(!isOpen);
    setcurrentAlertId(0);
  };

  const handleJobStatusClick = (jobId: any) => {
    setIsCheckedAll(false);
    setIsRemovedCheckedAll(false);
    setCheckedListArray([]);
    setIsChecked(false);
    setVendorJobStatus([]);
    setJobStatusOPen(!jobStatusOPen);
    setVendorDashboardComponentState((vendorDashboardComponentState) => {
      return {
        ...vendorDashboardComponentState,
        currentJobId: jobId,
      };
    });
  };

  const handleJobAppliedClick = (jobId: any) => {
    if (secondButtonRef.current != null) {
      secondButtonRef.current.click();
    }
    setJobApplayOpen(!jobApplayOpen);
    setVendorDashboardComponentState((vendorDashboardComponentState) => {
      return {
        ...vendorDashboardComponentState,
        currentJobId: jobId,
      };
    });
  };

  const handleJobSubmit = (data: any) => {
    console.log("7171", data);
  };

  const handleJobAlertDeleteClick = (rowId: any) => {
    if (authorizationToken != null) {
      (async () => {
        await deleteJobAlertVendorList(
          jobAlertVendorDispatcher,
          {
            rowId: rowId,
          } as jobAlertVendorSaveRequestModel,
          authorizationToken
        );
      })();
    }
  };

  const handleCarouselPreviousClick = () => {
    let num =
      matchedJobVendor.data != undefined ? matchedJobVendor.data.length : 4;
    let value = num / 4;
    if (countNext > 0) {
      setVendorDashboardComponentState((vendorDashboardComponentState) => {
        return {
          ...vendorDashboardComponentState,
          countNext: countNext - 1,
          carouselValue: carouselValue + 235,
        };
      });
      if (countNext % 9 === 0) {
        setCurrentPageOfExpDocList(
          currentPageOfExpDocList != 1 ? currentPageOfExpDocList - 1 : 1
        );
      }
    } else {
      setVendorDashboardComponentState((vendorDashboardComponentState) => {
        return {
          ...vendorDashboardComponentState,
          countNext: 0,
          carouselValue: 0,
        };
      });
    }
  };

  const handleCarouselNextClick = () => {
    let num =
      matchedJobVendor.data != undefined ? matchedJobVendor.data.length : 4;
    let value = num - 2;

    if (countNext < value && value >= 1) {
      setVendorDashboardComponentState((vendorDashboardComponentState) => {
        return {
          ...vendorDashboardComponentState,
          countNext: countNext + 1,
          carouselValue: carouselValue + -300,
        };
      });
      if (countNext % 9 === 0) {
        setCurrentPageOfExpDocList(currentPageOfExpDocList + 1);
      }
    } else {
      setVendorDashboardComponentState((vendorDashboardComponentState) => {
        return {
          ...vendorDashboardComponentState,
          countNext: 0,
          carouselValue: 0,
        };
      });
    }
  };

  const handleCarouselPreviousClickStatus = () => {
    let num =
      vendorGetAllJobList.data != undefined
        ? vendorGetAllJobList.data.length
        : 4;
    let value = num / 4;
    if (countNextStatus > 0) {
      setVendorDashboardComponentState((vendorDashboardComponentState) => {
        return {
          ...vendorDashboardComponentState,
          countNextStatus: countNextStatus - 1,
          carouselValueStatus: carouselValueStatus + 225,
        };
      });
      if (countNext % 9 === 0) {
        setCurrentPageOfExpDocList(
          currentPageOfExpDocList != 1 ? currentPageOfExpDocList - 1 : 1
        );
      }
    } else {
      setVendorDashboardComponentState((vendorDashboardComponentState) => {
        return {
          ...vendorDashboardComponentState,
          countNextStatus: 0,
          carouselValueStatus: 0,
        };
      });
    }
  };

  const handleCarouselNextClickStatus = () => {
    let num =
      vendorGetAllJobList.data != undefined
        ? vendorGetAllJobList.data.length
        : 4;
    let value = num - 2;

    if (countNextStatus < value && value >= 1) {
      setVendorDashboardComponentState((vendorDashboardComponentState) => {
        return {
          ...vendorDashboardComponentState,
          countNextStatus: countNextStatus + 1,
          carouselValueStatus: carouselValueStatus + -225,
        };
      });
      if (countNext % 9 === 0) {
        setCurrentPageOfExpDocList(currentPageOfExpDocList + 1);
      }
    } else {
      setVendorDashboardComponentState((vendorDashboardComponentState) => {
        return {
          ...vendorDashboardComponentState,
          countNextStatus: 0,
          carouselValueStatus: 0,
        };
      });
    }
  };

  const handleShareButtonClick = (id: any, value: any) => {
    setVendorDashboardComponentState({
      ...vendorDashboardComponentState,
      currentJobId: id,
      filterFlag: value,
    });
    setIsShareOpen(!isShareOpen);
  };

  function cancel(e: any) {}

  const handleSavedJobClick = (id: any) => {
    // setVendorDashboardComponentState({
    //   ...vendorDashboardComponentState,
    //   currentJobId: id,
    // });
    if (authorizationToken != null) {
      (async () => {
        await setVendorJobBookmark(
          vendorJobBookMarkDispatcher,
          {
            IsActive: true,
            jobBookmarkedId: 0,
            rowId: 0,
            vendorID: loggedUserId,
            jobId: id,
          } as VendorJobBookMarkRequestModel,
          authorizationToken
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImQ5ZjU0MjlmLTY3NTgtNGM0Ny04ODVhLTIwZjVkN2M4ODEyZiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTcyODEsImV4cCI6MTY0NDEzMzI4MSwiaWF0IjoxNjEyNTk3MjgxLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.sKevXWje7UM90S8rAZicVHpesgOz4IepSaY2JQkvcNM"
        );
      })();
    }
  };

  // select and deselect

  React.useEffect(() => {
    if (
      vendorGetAllJobStatus != undefined &&
      vendorGetAllJobStatus.data != undefined &&
      isRenderCheckedList
    ) {
      setIndexCount(vendorGetAllJobStatus.data.length);
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(false);
      setIsRenderCheckedList(false);
      setVendorJobStatus(
        vendorGetAllJobStatus != undefined &&
          vendorGetAllJobStatus.data != undefined
          ? vendorGetAllJobStatus.data
          : []
      );
      globalSpinner.hideSpinner();
    }
  }, [isRenderCheckedList, vendorGetAllJobStatus]);

  React.useEffect(() => {
    if (
      vendorGetAllJobStatus != undefined &&
      vendorGetAllJobStatus.data != undefined
    ) {
      setIndexCount(vendorGetAllJobStatus.data.length);
      setVendorJobStatus(
        vendorGetAllJobStatus != undefined &&
          vendorGetAllJobStatus.data != undefined
          ? vendorGetAllJobStatus.data
          : []
      );
      globalSpinner.hideSpinner();
    }
  }, [vendorGetAllJobStatus, vendorGetAllJobStatus.data]);

  const handleAllChecked = (e: any) => {
    console.log(12, e.target.checked);
    if (e.target.checked) {
      IdArray = [];
      // digiLockerList
      vendorGetAllJobStatus.data
        // .filter((data) => data.digiDocumentTypeId == documentTypeActiveId)
        .map((d) => {
          IdArray.push(d.candidateId);
        });
      setCheckedListArray(IdArray);
      console.log(23, checkedListArray);
      setIsCheckedAll(e.target.checked);
      console.log("checkedListArray", checkedListArray);
      setIsRemovedCheckedAll(false);
    } else {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(e.target.checked);
      console.log("checkedListArray", checkedListArray);
      setIsRemovedCheckedAll(true);
    }
  };

  const handleAllRemoved = (e: any) => {
    if (e.target.checked) {
      IdArray = [];
      setCheckedListArray(IdArray);
      setIsCheckedAll(false);
      setIsRemovedCheckedAll(true);
      console.log("checkedListArray", checkedListArray);
    }
  };

  const handleChecked = (e: any, id: any) => {
    // setIsChecked(true);
    if (e.target.checked) {
      IdArray.push(...checkedListArray, id);
      setCheckedListArray(IdArray);
      console.log("checkedListArray", checkedListArray);
    } else {
      IdArray = checkedListArray.filter((data) => {
        return data !== id;
      });
      setCheckedListArray(IdArray);
      console.log("checkedListArray", checkedListArray);
    }
    setIsRemovedCheckedAll(false);
    setIsChecked(true);
  };

  console.log(25, checkedListArray);
  React.useEffect(() => {
    if (isChecked) {
      if (checkedListArray.length == vendorGetAllJobStatus.data.length) {
        setIsCheckedAll(true);
        console.log("checkedListArray lenth", checkedListArray.length);
        console.log("vendorJobStatus.length", vendorJobStatus.length);
      } else {
        setIsCheckedAll(false);
      }
      setIsChecked(false);
    }
  }, [isChecked]);

  function onChangeDate(date: any, dateString: any) {
    console.log(date, dateString);
  }

  const menuRef = useRef<any>();
  React.useEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != undefined) {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setFilterShow(false);
          // setIsProfileOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  React.useEffect(() => {
    if (jobAlertVendorGetAll && jobAlertVendorGetAll.data != undefined) {
      if (currentPageJobAlert === 1) {
        setobAlertResultDataState([]);
        setHasMore(jobAlertVendorGetAll.hasNext);
        setobAlertResultDataState(
          jobAlertVendorGetAll && jobAlertVendorGetAll.data != undefined
            ? jobAlertVendorGetAll.data
            : []
        );
        setHasMore(jobAlertVendorGetAll.hasNext);
      } else {
        setHasMore(jobAlertVendorGetAll.hasNext);
        setobAlertResultDataState([
          ...jobAlertVendorGetAll.data,
          ...jobAlertResultDataSate,
        ]);
      }
    }
  }, [jobAlertVendorGetAll]);

  const handleJobStatusDelete = () => {
    if (authorizationToken != null) {
      (async () => {
        await DeleteVendorJobStatusList(
          vendorJobStatusDispatcher,
          {
            RowId: 0,
            JobId: currentJobId,
            VendorId: loggedUserId,
            VendorCandidateList: checkedListArray,
          },
          authorizationToken
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImQ5ZjU0MjlmLTY3NTgtNGM0Ny04ODVhLTIwZjVkN2M4ODEyZiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTcyODEsImV4cCI6MTY0NDEzMzI4MSwiaWF0IjoxNjEyNTk3MjgxLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.sKevXWje7UM90S8rAZicVHpesgOz4IepSaY2JQkvcNM"
        );
      })();
    }
  };

  React.useEffect(() => {
    if (authorizationToken != null)
      (async () => {
        await getJobCategoryList(jobCategoryDispatcher, authorizationToken);
      })();
  }, [authorizationToken]);

  function disabledDate(current: any) {
    // Can not select days before today and today

    return current && current < moment().endOf("day");
  }

  const content = (
    <>
      <p>You can only delete candidates in Applied and rejected tabs</p>
    </>
  );
  const handleScrollClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row m-t-25">
            <div className="col-sm-12">
              <div className="dash_sec">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img src={require("../../../images/my_candidates.png")} />{" "}
                      {vendorGetAllCount.noofCandidates} My Candidates
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img src={require("../../../images/shortlisted.png")} />{" "}
                      {/* {vendorGetAllCount.successsratio}  */}
                      {parseFloat(
                        vendorGetAllCount.successsratio != undefined
                          ? vendorGetAllCount.successsratio.toString()
                          : ""
                      ).toFixed(2)}{" "}
                      Success %
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img
                        src={require("../../../images/expiring_documents.png")}
                      />{" "}
                      {vendorGetAllCount.noJobApplied} Job Posts
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img src={require("../../../images/jobs_applied.png")} />{" "}
                      {/* {vendorGetAllCount.noofJobPosts}{" "}Matched Jobs */}
                      {matchedJobVendor.total != undefined
                        ? matchedJobVendor.total
                        : 0}{" "}
                      Matched Jobs
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img src={require("../../../images/new_messages.png")} />{" "}
                      {vendorGetAllCount.noofNewMessages} Unread Messages
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="dash_notification">
                      <img src={require("../../../images/new_alerts.png")} />{" "}
                      {vendorGetAllCount.noofNewAlerts} New Alerts
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-8" ref={scrollDiv}>
              <div className="section_box1">
                <h1>Matched Job</h1>
                <div className="row">
                  <div className="prodict_list">
                    <div
                      id="owl-demo1"
                      className="owl-carousel owl-theme"
                      style={{ opacity: 1, display: "block" }}
                    >
                      <div className="owl-wrapper-outer">
                        <div
                          className="owl-wrapper"
                          style={{
                            width: "3140px",
                            left: "0px",
                            display: "block",
                            transition: "all 1000ms ease 0s",
                            transform: `translate3d(${carouselValue}px, 0px, 0px)`,
                          }}
                        >
                          {matchedJobVendor.data != undefined
                            ? matchedJobVendor.data.map((data, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="owl-item"
                                    style={{ width: "314px" }}
                                  >
                                    <div className="item">
                                      <div className="matched_jobs_sec">
                                        <div className="matched_jobs">
                                          <div className="matched_jobs_cat_t">
                                            {data.categoryName}
                                          </div>
                                          <div className="jobs_start1">
                                            {data.daysAgo} days ago
                                          </div>
                                          <div className="matched_star1">
                                            {data.isBookmarked ? (
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  handleSavedJobClick(
                                                    data.jobId
                                                  )
                                                }
                                              />
                                            ) : (
                                              <i
                                                className="fa fa-star-o"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  handleSavedJobClick(
                                                    data.jobId
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                          <div className="clearfix" />
                                          <Popover
                                            content={
                                              "Please click for more details"
                                            }
                                          >
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                history.push(
                                                  `/jobSearch/matched/${data.rowId}/searchtitle/location/0`
                                                );
                                              }}
                                            >
                                              <div className="matched_heading">
                                                {data.title}
                                              </div>
                                              <div className="matched_jobs_cat">
                                                <i
                                                  className="fa fa-map-marker"
                                                  aria-hidden="true"
                                                />{" "}
                                                {data.location}
                                              </div>
                                              <div className="matched_jobs_cat text-right">
                                                <i
                                                  className="fa fa-clock-o"
                                                  aria-hidden="true"
                                                />{" "}
                                                {data.jobType}
                                              </div>
                                              {/* </div> */}
                                              <div className="clearfix" />
                                              <div className="matched_jobs_cat">
                                                <i
                                                  className="fa fa-list-ul"
                                                  aria-hidden="true"
                                                />{" "}
                                                {data.numberOfVacancies}{" "}
                                                Vacancies
                                              </div>
                                              <div className="clearfix" />
                                            </div>
                                          </Popover>
                                          <div className="matched_jobs_share">
                                            <a
                                              onClick={() =>
                                                handleShareButtonClick(
                                                  data.rowId,
                                                  true
                                                )
                                              }
                                              data-target="#share"
                                              data-toggle="modal"
                                            >
                                              <i
                                                className="fa fa-share-alt"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </div>
                                          <div className="jobs_apply">
                                            {documentStatus !== undefined ? (
                                              documentStatus.includes(
                                                "Rejected"
                                              ) ? (
                                                data.isApplied ? (
                                                  <Popover
                                                    content={
                                                      "Your document is rejected"
                                                    }
                                                  >
                                                    <a
                                                      // onClick={() =>
                                                      //   handleJobStatusClick(
                                                      //     data.jobId
                                                      //   )
                                                      // }
                                                      data-target="#status_pop"
                                                      data-toggle="modal"
                                                    >
                                                      View Status
                                                    </a>
                                                  </Popover>
                                                ) : (
                                                  <Popover
                                                    content={
                                                      "Your document is rejected"
                                                    }
                                                  >
                                                    <a
                                                      // onClick={() =>
                                                      //   handleJobAppliedClick(
                                                      //     data.jobId
                                                      //   )
                                                      // }
                                                      data-target="#apply_now_pop"
                                                      data-toggle="modal"
                                                    >
                                                      Apply Now
                                                    </a>
                                                  </Popover>
                                                )
                                              ) : data.isApplied ? (
                                                <a
                                                  onClick={() =>
                                                    handleJobStatusClick(
                                                      data.jobId
                                                    )
                                                  }
                                                  data-target="#status_pop"
                                                  data-toggle="modal"
                                                >
                                                  View Status
                                                </a>
                                              ) : (
                                                <a
                                                  onClick={() =>
                                                    handleJobAppliedClick(
                                                      data.jobId
                                                    )
                                                  }
                                                  data-target="#apply_now_pop"
                                                  data-toggle="modal"
                                                >
                                                  Apply Now
                                                </a>
                                              )
                                            ) : (
                                              <a
                                                onClick={() =>
                                                  handleJobAppliedClick(
                                                    data.jobId
                                                  )
                                                }
                                                data-target="#apply_now_pop"
                                                data-toggle="modal"
                                              >
                                                Apply Now
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                      <div className="owl-controls clickable">
                        <div className="owl-pagination">
                          <div className="owl-page active">
                            <span className="" />
                          </div>
                          <div className="owl-page">
                            <span className="" />
                          </div>
                        </div>
                        <div className="owl-buttons">
                          <div
                            className="owl-prev"
                            onClick={handleCarouselPreviousClick}
                          >
                            <img
                              src={require("../../../images/products_ar1.png")}
                            />{" "}
                          </div>
                          <div
                            className="owl-next"
                            onClick={handleCarouselNextClick}
                          >
                            <img
                              src={require("../../../images/products_ar2.png")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section_box1">
                <h1>My Job Posts</h1>
                {/* scrollDiv.current != null */}
                {/* scrollDiv.current.scrollIntoView({ */}
                {/* behavior: "smooth", */}
                {/* }) */}
                {/* : "" */}
                <div className="row">
                  <div className="prodict_list">
                    <div
                      id="owl-demo1"
                      className="owl-carousel owl-theme"
                      style={{ opacity: 1, display: "block" }}
                    >
                      <div className="owl-wrapper-outer">
                        <div
                          className="owl-wrapper"
                          style={{
                            width: "3140px",
                            left: "0px",
                            display: "block",
                            transition: "all 1000ms ease 0s",
                            transform: `translate3d(${carouselValueStatus}px, 0px, 0px)`,
                          }}
                        >
                          {vendorGetAllJobList != undefined &&
                          vendorGetAllJobList.data != undefined
                            ? vendorGetAllJobList.data.map((data, index) => {
                                return (
                                  <div
                                    className="owl-item"
                                    style={{ width: "275px" }}
                                  >
                                    <div className="item">
                                      <div className="matched_jobs_sec">
                                        <div className="matched_jobs">
                                          <div className="matched_jobs_cat_t">
                                            {data.categoryName}
                                          </div>
                                          <div className="matched_star1">
                                            {data.isBookmarked ? (
                                              <i
                                                onClick={() =>
                                                  handleSavedJobClick(
                                                    data.jobId
                                                  )
                                                }
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <i
                                                onClick={() =>
                                                  handleSavedJobClick(
                                                    data.jobId
                                                  )
                                                }
                                                className="fa fa-star-o"
                                                aria-hidden="true"
                                              />
                                            )}
                                          </div>
                                          <div className="clearfix" />
                                          <Popover
                                            content={
                                              "Please click for more details"
                                            }
                                          >
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                history.push(
                                                  `/jobSearch/applied/${data.jobId}/searchtitle/location/0`
                                                );
                                              }}
                                            >
                                              <div className="matched_heading">
                                                {data.title}
                                              </div>
                                              <div className="matched_jobs_cat">
                                                <i
                                                  className="fa fa-map-marker"
                                                  aria-hidden="true"
                                                />{" "}
                                                {data.location}
                                              </div>
                                              <div className="matched_jobs_cat text-right">
                                                <i
                                                  className="fa fa-clock-o"
                                                  aria-hidden="true"
                                                />{" "}
                                                {data.jobType}
                                              </div>
                                              <div className="clearfix" />
                                              <div className="matched_jobs_cat">
                                                <i
                                                  className="fa fa-list-ul"
                                                  aria-hidden="true"
                                                />{" "}
                                                {data.numberOfVacancies}{" "}
                                                Vacancies
                                              </div>
                                              <div className="matched_jobs_cat text-right">
                                                <i
                                                  className="fa fa-calendar"
                                                  aria-hidden="true"
                                                />{" "}
                                                Applied On <br />
                                                {data.postedDate}
                                              </div>
                                            </div>
                                          </Popover>
                                          <div className="clearfix" />

                                          <div className="matched_jobs_share">
                                            <a
                                              data-target="#share"
                                              data-toggle="modal"
                                              onClick={() =>
                                                handleShareButtonClick(
                                                  data.jobId,
                                                  true
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-share-alt"
                                                aria-hidden="true"
                                              />
                                            </a>
                                          </div>
                                          <div className="matched_jobs_status">
                                            {documentStatus !== undefined ? (
                                              documentStatus.includes(
                                                "Rejected"
                                              ) ? (
                                                <Popover
                                                  content={
                                                    "Your document is rejected"
                                                  }
                                                >
                                                  <a
                                                    // onClick={() =>
                                                    //   handleJobStatusClick(data.jobId)
                                                    // }
                                                    data-target="#status_pop"
                                                    data-toggle="modal"
                                                  >
                                                    View Status
                                                  </a>
                                                </Popover>
                                              ) : (
                                                <a
                                                  onClick={() =>
                                                    handleJobStatusClick(
                                                      data.jobId
                                                    )
                                                  }
                                                  data-target="#status_pop"
                                                  data-toggle="modal"
                                                >
                                                  View Status
                                                </a>
                                              )
                                            ) : (
                                              <a
                                                onClick={() =>
                                                  handleJobStatusClick(
                                                    data.jobId
                                                  )
                                                }
                                                data-target="#status_pop"
                                                data-toggle="modal"
                                              >
                                                View Status
                                              </a>
                                            )}
                                            {/* <a
                                              onClick={() =>
                                                handleJobStatusClick(data.jobId)
                                              }
                                              data-target="#status_pop"
                                              data-toggle="modal"
                                            >
                                              View Status
                                            </a> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                      <div className="owl-controls clickable">
                        <div className="owl-pagination">
                          <div className="owl-page active">
                            <span className="" />
                          </div>
                          <div className="owl-page">
                            <span className="" />
                          </div>
                        </div>
                        <div className="owl-buttons">
                          <div
                            className="owl-prev"
                            onClick={handleCarouselPreviousClickStatus}
                          >
                            <img
                              src={require("../../../images/products_ar1.png")}
                            />{" "}
                          </div>
                          <div
                            className="owl-next"
                            onClick={handleCarouselNextClickStatus}
                          >
                            <img
                              src={require("../../../images/products_ar2.png")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* BEGIN MODAL */}
            <div
              className="modal fade none-border"
              id="share"
              style={{ display: "none" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      aria-hidden="true"
                      data-dismiss="modal"
                      className="close"
                      type="button"
                    >
                      ×
                    </button>
                    <h4 className="modal-title">Share </h4>
                  </div>
                  <div className="col-sm-12 m_t_30 text-center">
                    <div className="social1">
                      <a href="#" className="social_face">
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                      <a href="#" className="social_twit">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                      <a href="#" className="social_insta">
                        <i className="fa fa-instagram" aria-hidden="true" />
                      </a>
                      <a href="#" className="social_youtube">
                        <i className="fa fa-youtube-play" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                  <div className="modal-footer  m-t-30"></div>
                </div>
              </div>
            </div>
            {/* END MODAL */}
            <div className="col-sm-4">
              <div className="section_box2">
                <h1>Messages</h1>
                <div
                  className="dash_message_vendor"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 480 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {messageListFlag === 1 ? (
                      messageList
                    ) : (
                      <div>You have no messages!</div>
                    )}
                  </Scrollbars>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="section_box2">
                <h1>Job Alert</h1>
                <div className="jobs_alert_btn">
                  <a
                    onClick={
                      //   ()=>{
                      //    setIsOpen(!isOpen);
                      // }
                      handleJobAlertCreateClick
                    }
                    data-target="#job_alert"
                    data-toggle="modal"
                  >
                    Create Alert
                  </a>
                </div>
                <div className="clearfix" />
                <div
                  className="jobs_alert_scroll"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={0}
                >
                  <InfiniteScroll
                    className="_custom-infinite-scroll-class"
                    height={200}
                    dataLength={jobAlertResultDataSate.length}
                    // dataLength={2000}
                    // dataLength={totalCount}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={
                      jobAlertResultDataSate.length > 0 ? (
                        <h4>Loading...</h4>
                      ) : null
                    }
                    // initialScrollY={indexState * 80}
                    endMessage={
                      jobAlertResultDataSate.length > 0 ? (
                        <p style={{ textAlign: "center" }}>
                          <b>No more data to load</b>
                        </p>
                      ) : null
                    }
                  >
                    {jobAlertResultDataSate != undefined
                      ? jobAlertResultDataSate.map((jobAlert, i) => {
                          return (
                            <>
                              <div className="jobalert_sec">
                                <span className="jobalert_sec_icon">
                                  <Popconfirm
                                    title={
                                      <div>
                                        <p>
                                          Are you sure delete this Job Alert{" "}
                                        </p>
                                      </div>
                                    }
                                    onConfirm={(e: any) =>
                                      handleJobAlertDeleteClick(jobAlert.rowId)
                                    }
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <img
                                      // onClick={()=>handleJobAlertDeleteClick(jobAlert.rowId)}
                                      src={require("../../../images/job_close.png")}
                                      className="img-responsive"
                                    />
                                  </Popconfirm>
                                </span>
                                <div
                                  className="jobalert_con _cursor-pointer"
                                  onClick={() =>
                                    handleJobAlertClick(jobAlert.rowId)
                                  }
                                >
                                  <div
                                    className={`jobalert_headings jobalert_clr${
                                      (i % 3) + 1
                                    }`}
                                  >
                                    {jobAlert.jobCategory
                                      .charAt(0)
                                      .toUpperCase() +
                                      jobAlert.jobCategory.slice(1)}
                                  </div>
                                  <div className="jobalert_head">
                                    {jobAlert.jobTitle} - {jobAlert.jobType}
                                  </div>
                                  <div
                                    // className={`${jobAlert.active}jobalert_active`}
                                    className={
                                      jobAlert.active
                                        ? "jobalert_active"
                                        : "jobalert_inactive"
                                    }
                                  >
                                    {jobAlert.active ? "Active" : "Inactive"}
                                  </div>
                                </div>
                                <div className="jobalert_con1">
                                  <div className="jobalert_time">1 day ago</div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : null}
                  </InfiniteScroll>

                  {/* <div className="jobalert_sec">
                    <span className="jobalert_sec_icon">
                      <img
                        src={require("../../../images/job_close.png")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="jobalert_con">
                      <div className="jobalert_headings jobalert_clr2">IT</div>
                      <div className="jobalert_head">Software Engineer </div>
                      <div className="jobalert_inactive">Inactive</div>
                    </div>
                    <div className="jobalert_con1">
                      <div className="jobalert_time">2 day ago</div>
                    </div>
                  </div>
                  <div className="jobalert_sec">
                    <span className="jobalert_sec_icon">
                      <img
                        src={require("../../../images/job_close.png")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="jobalert_con">
                      <div className="jobalert_headings jobalert_clr3">
                        Fieldwork
                      </div>
                      <div className="jobalert_head">Welder</div>
                      <div className="jobalert_inactive">Inactive</div>
                    </div>
                    <div className="jobalert_con1">
                      <div className="jobalert_time">3 day ago</div>
                    </div>
                  </div>
                  <div className="jobalert_sec">
                    <span className="jobalert_sec_icon">
                      <img
                        src={require("../../../images/job_close.png")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="jobalert_con">
                      <div className="jobalert_headings jobalert_clr2">IT</div>
                      <div className="jobalert_head">Software Engineer </div>
                      <div className="jobalert_inactive">Inactive</div>
                    </div>
                    <div className="jobalert_con1">
                      <div className="jobalert_time">2 day ago</div>
                    </div>
                  </div>
               */}
                </div>
              </div>
            </div>
            {/* BEGIN MODAL */}
            <div className="modal fade none-border" id="job_alert">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      aria-hidden="true"
                      data-dismiss="modal"
                      className="close"
                      type="button"
                    >
                      ×
                    </button>
                    <h4 className="modal-title">New Job Alert </h4>
                  </div>
                  <div className="col-sm-12 m_t_30">
                    <div className="form-group">
                      <label htmlFor="email">Job Category</label>
                      <span className="select-wrapper-sec">
                        <select name="timepass" className="custom-select-sec">
                          <option value="">Select</option>
                          <option value="">Dummy</option>
                          <option value="">Dummy</option>
                        </select>
                        <span className="holder">Select</span>
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Job Type</label>
                      <span className="select-wrapper-sec">
                        <select name="timepass" className="custom-select-sec">
                          <option value="">Select</option>
                          <option value="">Dummy</option>
                          <option value="">Dummy</option>
                        </select>
                        <span className="holder">Select</span>
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Job Title</label>
                      <span className="select-wrapper-sec">
                        <select name="timepass" className="custom-select-sec">
                          <option value="">Select</option>
                          <option value="">Dummy</option>
                          <option value="">Dummy</option>
                        </select>
                        <span className="holder">Select</span>
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Location</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Add Number"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        id="radio1"
                        type="radio"
                        name="radio"
                        defaultValue={1}
                        defaultChecked
                      />
                      <label htmlFor="radio1">
                        <span>
                          <span />
                        </span>{" "}
                        Active
                      </label>
                      <input
                        id="radio2"
                        type="radio"
                        name="radio"
                        defaultValue={2}
                      />
                      <label htmlFor="radio2">
                        <span>
                          <span />
                        </span>{" "}
                        Inactive
                      </label>
                    </div>
                  </div>
                  <div className="modal-footer  m-t-30">
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="button"
                    >
                      Create Alert
                    </button>
                    <button
                      data-dismiss="modal"
                      className="btn btn-default waves-effect"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              show={isOpen}
              onHide={() => {
                setIsOpen(!isOpen);
                setcurrentAlertId(0);
              }}
            >
              <form onSubmit={handleSubmit2(handleJobAlert)} noValidate>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {currentAlertId == 0 ? "New Job Alert" : "Update Job Alert"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Category
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="jobCategorys"
                        rules={{
                          required: "Job Category is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              myProfile.jobCategories != undefined
                                ? myProfile.jobCategories.map((e: any) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : jobCategoryData
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="jobCategorys"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Type
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        className="custom-select-sec"
                        control={control2}
                        name="jobTypeData"
                        rules={{
                          required: "Job Type is required",
                        }}
                        // rules={{
                        //   validate: (value) => {
                        //     return value === "" ? "Job Type is required" : "";
                        //   },
                        // }}
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              // myProfile.jobTypes != undefined
                              //   ? myProfile.jobTypes.map((e: any) => {
                              //       return {
                              //         value: e["value"],
                              //         label: e["caption"],
                              //       };
                              //     })
                              //   :
                              jobTypeDatas
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors2}
                      name="jobTitle"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Job Title
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        control={control2}
                        name="jobTitle"
                        rules={{
                          required: "Job Title is required",
                        }}
                        // value=''
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            values={
                              tempData != undefined
                                ? tempData.map((e) => {
                                    return {
                                      value: e["value"],
                                      label: e["caption"],
                                    };
                                  })
                                : tempData
                            }
                            disabled={false}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                  </div>
                  <ErrorMessage
                    errors={errors2}
                    name="jobTitle"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                  <div className="form-group">
                    <label htmlFor="email">Location</label>
                    <input
                      defaultValue={location}
                      type="text"
                      className="form-control "
                      name="location"
                      placeholder=" "
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      id="radio1"
                      type="radio"
                      ref={register2({
                        required: false,
                      })}
                      name="radio"
                      defaultValue={1}
                      defaultChecked
                    />
                    <label htmlFor="radio1" style={{ marginRight: 20 }}>
                      <span>
                        <span />
                      </span>{" "}
                      Active
                    </label>
                    <input
                      id="radio2"
                      type="radio"
                      ref={register2({
                        required: false,
                      })}
                      name="radio"
                      defaultValue={2}
                      // defaultChecked
                    />
                    <label
                      htmlFor="radio2"
                      // style={{ marginLeft: 1 }}
                    >
                      <span>
                        <span />
                      </span>{" "}
                      Inactive
                    </label>
                  </div>
                </Modal.Body>
                <div className="modal-footer  m-t-30">
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                  >
                    Create Alert
                  </button>
                  <button
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setcurrentAlertId(0);
                    }}
                    data-dismiss="modal"
                    className="btn btn-default waves-effect"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
                <div className="clearfix" />
              </form>
            </Modal>

            {/* END MODAL */}
            <div className="col-sm-6">
              <div className="section_box2">
                <h1>Notifications</h1>
                <div
                  className="dash_noti_scroll"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 230 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {listFlag === 1 ? (
                      list
                    ) : (
                      <div>You have no notifications!</div>
                    )}
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>

        {/* modal Matched job */}
        {/*         
        <Modal
              show={jobApplayOpen}
              onHide={() => {
                setJobApplayOpen(!jobApplayOpen);
              }}
              
            >
              <form onSubmit={handleSubmit3(handleJobSubmit)} noValidate >
                <Modal.Header closeButton>
                  <Modal.Title>Apply Now</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <div>
        <div className="candidate_no">1</div>
        <div className="close_icon _cursor-pointer">
          <img
            
            src={require("./../../../images/close_icon.png")}
            width={16}
            height={16}
            style={{marginTop:26}}
          />
        </div>
        <div className="col-sm-4">
          <div className="form-group"> 
            <label htmlFor="email">Candidate First  Name</label>
            <input type="text" name='firstName' className="form-control " placeholder='' 
            ref={register3({
              required: 'Candidate First  Name is required',
            })}
            /> 
              <ErrorMessage
                    errors={errors3}
                    name="firstName"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />  
          </div>  
            
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="email">Middle Name</label>
            <input type="text" className="form-control " placeholder='' name='middleName'/> 
          </div>        
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="email">Last Name</label>
            <input type="text" className="form-control " placeholder='' name='lastName'
             ref={register3({
              required: 'Candidate Last  Name is required',
            })}/> 
            <ErrorMessage
                    errors={errors3}
                    name="lastName"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />   
          
          </div> 
                  
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="email">Passport No</label>
            <input type="text" className="form-control " placeholder='' name='passportNo' /> 
          </div>        
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="email">DOB</label>
            <input type="text" className="form-control " placeholder=''   ref={register3({
              required: false,
            })}/> 
          </div>        
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label htmlFor="email">Active Visa?</label>
            <div className="row">
              <div className="col-xs-6">
                <input id="radio1" type="radio" name="activeVisa" defaultValue={1} defaultChecked  ref={register3({
              required: false,
            })}/><label htmlFor="radio1"><span><span /></span> Yes</label>
              </div>
              <div className="col-xs-6">
                <input id="radio1" type="radio" name="activeVisa" defaultValue={2} ref={register3({
              required: false,
            })}/><label htmlFor="radio1"><span><span /></span> No</label>
              </div>
            </div>
          </div>        
        </div>
        <div className="clearfix" />
        <div className="col-sm-4" style={{display:watchAllFields3.activeVisa!=="2"?'':'none'}}>
          <div className="form-group">
            <label htmlFor="email">Visa Issuing Country </label>
            <input type="text" className="form-control " placeholder='' name='visaIssueCountry' ref={register3({
              required: false,
            })}/> 
          </div>        
        </div>
        <div className="col-sm-4" style={{display:watchAllFields3.activeVisa!=="2"?'':'none'}}>
          <div className="form-group">
            <label htmlFor="email">Expiry Date </label>
          
              <Controller
                 
                  control={control3}
                  name="expiryDate"
                  render={({ onChange, onBlur, value, name }) => {
                    console.log(value);
                    return (
                      <PikadayDatepicker
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    );
                  }}
                />
          </div>        
        </div>
       
        {jobPostArray}
        <div className="col-sm-4">
          <div className="form-group m_t_30">
            <label htmlFor="email"></label>
            <button className="btn btn-success save-event waves-effect waves-light" type="button"
            onClick={handleAddJobPostForm}
            >Add Candidates</button>
          </div>        
        </div>
     
      </div>
                
                </Modal.Body>
                <div className="modal-footer  m-t-30">
                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      setJobApplayOpen  (!jobApplayOpen);
                    }}
                    data-dismiss="modal"
                    className="btn btn-default waves-effect"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
                <div className="clearfix" />
              </form>
            </Modal> */}

        {/* model Using Ant-------------------------------------------- */}

        <Modal
          show={jobApplayOpen}
          onHide={() => {
            setJobApplayOpen(!jobApplayOpen);
            setRadioIndexList([]);
          }}
        >
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
          >
            {/* <form onSubmit={handleSubmit3(handleJobSubmit)} noValidate > */}
            <Modal.Header closeButton>
              <Modal.Title>Apply Now</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="candidate_no">C-001</div>
              <div
                className="close_icon _cursor-pointer"
                style={{ display: "none" }}
              >
                <img
                  src={require("./../../../images/close_icon.png")}
                  width={16}
                  height={16}
                  style={{ marginTop: 26 }}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="email">Candidate First Name</label>
                <Form.Item
                  name="firstName"
                  rules={[{ required: true, message: "First  Name Missing " }]}
                >
                  <Input placeholder="" style={{ height: 39 }} />
                </Form.Item>
              </div>

              <div className="col-sm-4">
                <label htmlFor="email">Middle Name</label>
                <Form.Item
                  name="middleName"
                  rules={[{ required: false, message: "Middle Name Missing " }]}
                >
                  <Input placeholder="" style={{ height: 39 }} />
                </Form.Item>
              </div>

              <div className="col-sm-4">
                <label htmlFor="email">Last Name</label>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: "Last Name Missing " }]}
                >
                  <Input placeholder="" style={{ height: 39 }} />
                </Form.Item>
              </div>

              <div className="col-sm-4">
                <label htmlFor="email">Passport No</label>
                <Form.Item
                  name="passportNo"
                  rules={[{ required: true, message: "Passport No Missing " }]}
                >
                  <Input placeholder="" style={{ height: 39 }} />
                </Form.Item>
              </div>

              <div className="col-sm-4">
                <label htmlFor="email">DOB</label>
                <Form.Item
                  name="dob"
                  rules={[{ required: true, message: "DOB Missing " }]}
                >
                  {/* <Input placeholder=""  />               */}
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    style={{ height: 39, width: 196 }}
                  />
                </Form.Item>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="email">Active Visa?</label>
                  <div className="row">
                    <Form.Item
                      name="activeVisa"
                      rules={[{ required: false, message: "DOB Missing " }]}
                    >
                      <Radio.Group
                        defaultValue={radioValue}
                        onChange={
                          radioValue == 1
                            ? () => {
                                setRadioValue(2);
                              }
                            : () => {
                                setRadioValue(1);
                              }
                        }
                      >
                        <div className="col-xs-6">
                          <Radio value={1}>Yes</Radio>
                        </div>
                        <div className="col-xs-6">
                          <Radio value={2}>No</Radio>
                        </div>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="clearfix" />

              <div
                className="col-sm-4"
                style={{ display: radioValue != 1 ? "none" : "" }}
              >
                <label htmlFor="email">Visa Issuing Country</label>
                <Form.Item
                  name="visaIssueCountry"
                  rules={[
                    {
                      required: radioValue != 1 ? false : true,
                      message: "Visa Issuing Country Missing ",
                    },
                  ]}
                >
                  <Input placeholder="" style={{ height: 39 }} />
                </Form.Item>
              </div>

              <div
                className="col-sm-4"
                style={{ display: radioValue != 1 ? "none" : "" }}
              >
                <label htmlFor="email">Expiry Date</label>
                <Form.Item
                  name="expiryDate"
                  rules={[
                    {
                      required: radioValue != 1 ? false : true,
                      message: "expiryDate Missing ",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    style={{ height: 39, width: 196 }}
                    placeholder="YYYY-MM-DD"
                  />
                </Form.Item>
              </div>

              <Form.List name="users">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div style={{ paddingTop: 120 }}>
                        <div className="candidate_no">
                          {" "}
                          C-00{index + indexCount + 1}
                        </div>
                        <div className="close_icon _cursor-pointer">
                          <img
                            onClick={() => {
                              remove(field.name);
                              handleRemoveIndex(index);
                            }}
                            src={require("./../../../images/close_icon.png")}
                            width={16}
                            height={16}
                            style={{ marginTop: 26 }}
                          />
                        </div>
                        <div className="col-sm-4">
                          <label htmlFor="email">Candidate First Name</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "firstName"]}
                            fieldKey={[field.fieldKey, "firstName"]}
                            rules={[
                              { required: true, message: "Missing first name" },
                            ]}
                          >
                            <Input placeholder="" style={{ height: 39 }} />
                          </Form.Item>
                        </div>

                        <div className="col-sm-4">
                          <label htmlFor="email">Middle Name</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "middleName"]}
                            fieldKey={[field.fieldKey, "middleName"]}
                            rules={[
                              {
                                required: false,
                                message: "Middle Name Missing ",
                              },
                            ]}
                          >
                            <Input placeholder="" style={{ height: 39 }} />
                          </Form.Item>
                        </div>

                        <div className="col-sm-4">
                          <label htmlFor="email">Last Name</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "lastName"]}
                            fieldKey={[field.fieldKey, "lastName"]}
                            rules={[
                              { required: true, message: "Last Name Missing " },
                            ]}
                          >
                            <Input placeholder="" style={{ height: 39 }} />
                          </Form.Item>
                        </div>

                        <div className="col-sm-4">
                          <label htmlFor="email">Passport No</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "passportNo"]}
                            fieldKey={[field.fieldKey, "passportNo"]}
                            rules={[
                              {
                                required: true,
                                message: "Passport No Missing ",
                              },
                            ]}
                          >
                            <Input placeholder="" style={{ height: 39 }} />
                          </Form.Item>
                        </div>

                        <div className="col-sm-4">
                          <label htmlFor="email">DOB</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "dob"]}
                            fieldKey={[field.fieldKey, "dob"]}
                            rules={[
                              { required: true, message: "DOB Missing " },
                            ]}
                          >
                            {/* <Input placeholder=""  />               */}
                            <DatePicker
                              placeholder="YYYY-MM-DD"
                              style={{ height: 39, width: 196 }}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-group">
                            <label htmlFor="email">Active Visa?</label>
                            <div className="row">
                              <Form.Item
                                {...field}
                                name={[field.name, "activeVisa"]}
                                fieldKey={[field.fieldKey, "activeVisa"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "activeVisa Missing ",
                                  },
                                ]}
                              >
                                <Radio.Group
                                  // onChange={radioValue2!=index?()=>{setRadioValue2(index)}:()=>{setRadioValue2(1000)}}
                                  onChange={handleOnRadioButtonChange}
                                  defaultValue={`f${index}`}
                                >
                                  <div className="col-xs-6">
                                    <Radio defaultChecked value={`f${index}`}>
                                      Yes
                                    </Radio>
                                  </div>
                                  <div className="col-xs-6">
                                    <Radio value={`s${index}`}>No</Radio>
                                  </div>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <div className="clearfix" />

                        <div
                          className="col-sm-4"
                          style={{
                            display: radioIndexList.includes(index)
                              ? "none"
                              : "",
                          }}
                        >
                          <label htmlFor="email">Visa Issuing Country</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "visaIssueCountry"]}
                            fieldKey={[field.fieldKey, "visaIssueCountry"]}
                            rules={[
                              {
                                required: radioIndexList.includes(index)
                                  ? false
                                  : true,
                                message: "Visa Issuing Country Missing ",
                              },
                            ]}
                          >
                            <Input placeholder="" style={{ height: 39 }} />
                          </Form.Item>
                        </div>

                        <div
                          className="col-sm-4"
                          style={{
                            display: radioIndexList.includes(index)
                              ? "none"
                              : "",
                          }}
                        >
                          <label htmlFor="email">Expiry Date</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "expiryDate"]}
                            fieldKey={[field.fieldKey, "expiryDate"]}
                            rules={[
                              {
                                required: radioIndexList.includes(index)
                                  ? false
                                  : true,
                                message: "expiryDate Missing ",
                              },
                            ]}
                          >
                            <DatePicker
                              style={{ height: 39, width: 196 }}
                              disabledDate={disabledDate}
                              placeholder="YYYY-MM-DD"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    ))}
                    <div className="col-sm-4">
                      <Form.Item>
                        <div className="form-group m_t_30">
                          <label htmlFor="email"></label>
                          <button
                            ref={secondButtonRef}
                            className="btn btn-success save-event waves-effect waves-light"
                            type="button"
                            onClick={() => add()}
                          >
                            Add Candidates
                          </button>
                        </div>
                      </Form.Item>
                    </div>
                  </>
                )}
              </Form.List>
            </Modal.Body>
            <div className="modal-footer  m-t-30">
              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setJobApplayOpen(false);
                }}
                data-dismiss="modal"
                className="btn btn-default waves-effect"
                type="submit"
              >
                Cancel
              </button>
            </div>
            <div className="clearfix" />
            {/* </form> */}
          </Form>
        </Modal>

        {/* -------------------------------------------------------- */}

        {/* end modal Matched job */}

        {/* Modal job Status */}

        <Modal
          show={jobStatusOPen}
          onHide={() => {
            setJobStatusOPen(!jobStatusOPen);
          }}
          // style={{width:680}}
        >
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinishStatus}
            autoComplete="off"
          >
            {/* <form onSubmit={handleSubmit3(handleJobSubmit)} noValidate > */}
            <Modal.Header closeButton>
              <Modal.Title>My Candidate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* filteration */}

              <div className="action_btn1">
                <button
                  type="button"
                  className="actions_bt"
                  onClick={() => {
                    setFilterShow(!filterShow);
                  }}
                >
                  <i
                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                    aria-hidden="true"
                  />
                </button>
                <div className="col-sm-12">
                  <div
                    className="dash_action"
                    tabIndex={-1}
                    style={{ display: filterShow ? "block" : "none" }}
                  >
                    <div className="action_sec">
                      <ul ref={menuRef}>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(0);
                            }}
                            style={{ color: statusFilter !== 0 ? "" : "blue" }}
                          >
                            All
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(1);
                            }}
                            style={{ color: statusFilter !== 1 ? "" : "blue" }}
                          >
                            Applied
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(2);
                            }}
                            style={{ color: statusFilter !== 2 ? "" : "blue" }}
                          >
                            {" "}
                            Selected{" "}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(3);
                            }}
                            style={{ color: statusFilter !== 3 ? "" : "blue" }}
                          >
                            Rejected{" "}
                          </a>
                        </li>

                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(4);
                            }}
                            style={{ color: statusFilter !== 4 ? "" : "blue" }}
                          >
                            On Hold
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(5);
                            }}
                            style={{ color: statusFilter !== 5 ? "" : "blue" }}
                          >
                            Management
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(6);
                            }}
                            style={{ color: statusFilter !== 6 ? "" : "blue" }}
                          >
                            Technical
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(7);
                            }}
                            style={{ color: statusFilter !== 7 ? "" : "blue" }}
                          >
                            Interview: HR
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setStatusFilter(8);
                            }}
                            style={{ color: statusFilter !== 8 ? "" : "blue" }}
                          >
                            Shortlist
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* filteration end*/}

              <div>
                <div className="candidate_top">
                  <div className="col-sm-4">
                    <input
                      style={{ cursor: "pointer" }}
                      id="checkbox1"
                      type="checkbox"
                      name="checkbox"
                      defaultValue={1}
                      checked={isCheckedAll}
                      // defaultChecked={isCheckedAll ? true : false}
                      onChange={(e: any) => handleAllChecked(e)}
                    />
                    <label htmlFor="checkbox1">
                      <span /> Select All
                    </label>
                  </div>
                  <div className="col-sm-4">
                    <input
                      style={{ cursor: "pointer" }}
                      id="checkbox1"
                      type="checkbox"
                      name="checkbox"
                      defaultValue={1}
                      checked={isRemovedAll}
                      onChange={(e: any) => handleAllRemoved(e)}
                    />
                    <label htmlFor="checkbox1">
                      <span /> De-select All
                    </label>
                  </div>
                  <div className="col-sm-4" style={{ paddingTop: 4 }}>
                    {statusFilter === 1 || statusFilter === 3 ? (
                      <Popconfirm
                        title={
                          <div>
                            <p>Are you sure delete this Candidate </p>
                          </div>
                        }
                        onConfirm={handleJobStatusDelete}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          //  onClick={handleJobStatusDelete}
                        >
                          <i className="fa fa-trash-o" aria-hidden="true" />{" "}
                          Delete
                        </div>
                      </Popconfirm>
                    ) : (
                      <Tooltip title={content}>
                        <div style={{ cursor: "pointer", color: "gray" }}>
                          <i className="fa fa-trash-o" aria-hidden="true" />{" "}
                          Delete
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="clearfix" />

                {vendorJobStatus != undefined
                  ? vendorJobStatus.map((data, index) => {
                      return (
                        <>
                          <div style={{ paddingTop: 22 }}>
                            <div className="candidate_no">
                              <div>
                                {" "}
                                <input
                                  id="checkbox1"
                                  type="checkbox"
                                  name="checkbox"
                                  onChange={(e: any) =>
                                    handleChecked(e, data.candidateId)
                                  }
                                  checked={checkedListArray.includes(
                                    data.candidateId
                                  )}
                                />
                                <label htmlFor="checkbox1">
                                  <span />
                                  C-00{index + 1}
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">
                                  Candidate first Name
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.firstName}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Middle Name</label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.middleName}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Last Name</label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.lastName}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Passport No</label>
                                <input
                                  type="text"
                                  disabled
                                  defaultValue={data.passPort}
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">DOB</label>
                                <DatePicker
                                  placeholder="YYYY-MM-DD"
                                  style={{ height: 39, width: 196 }}
                                  disabled
                                  onChange={onChangeDate}
                                  defaultValue={moment(data.dob, "DD/MM/YYYY")}
                                  format={"DD/MM/YYYY"}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="email">Active Visa?</label>
                                <div className="row">
                                  <div className="col-xs-6">
                                    {/* <input disabled id="radio1" type="radio" name="radio" checked={data.isActivevisa} /><label htmlFor="radio1"><span><span /></span> Yes</label> */}
                                    <Radio
                                      checked={data.isActivevisa}
                                      value={1}
                                    >
                                      Yes
                                    </Radio>
                                  </div>
                                  <div className="col-xs-6">
                                    <Radio
                                      checked={!data.isActivevisa}
                                      value={2}
                                    >
                                      No
                                    </Radio>
                                    {/* <input disabled id="radio1" type="radio" name="radio" defaultValue={1} checked={data.isActivevisa?false:true}/><label htmlFor="radio1"><span><span /></span> No</label> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                            <div
                              className="col-sm-4"
                              style={{
                                display: data.isActivevisa ? "" : "none",
                              }}
                            >
                              <div className="form-group">
                                <label htmlFor="email">
                                  Visa Issuing Country{" "}
                                </label>
                                <input
                                  disabled
                                  defaultValue={data.visaIssueCountry}
                                  type="text"
                                  className="form-control "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div
                              className="col-sm-4"
                              style={{
                                display: data.isActivevisa ? "" : "none",
                              }}
                            >
                              <div className="form-group">
                                <label htmlFor="email">Expiry date </label>
                                <DatePicker
                                  placeholder="YYYY-MM-DD"
                                  style={{ height: 39, width: 196 }}
                                  disabled
                                  onChange={onChangeDate}
                                  defaultValue={moment(
                                    data.expirary_Date,
                                    "DD/MM/YYYY"
                                  )}
                                  format={"DD/MM/YYYY"}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            style={{ paddingTop: data.isActivevisa ? 72 : 1 }}
                          ></div>
                        </>
                      );
                    })
                  : null}

                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div style={{ paddingTop: 110 }}>
                          <div className="candidate_no">
                            {" "}
                            C-00{index + indexCount + 1}
                          </div>
                          <div className="close_icon _cursor-pointer">
                            <img
                              onClick={() => {
                                remove(field.name);
                                handleRemoveIndex(index);
                              }}
                              src={require("./../../../images/close_icon.png")}
                              width={16}
                              height={16}
                              style={{ marginTop: 26 }}
                            />
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="email">Candidate First Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "firstName"]}
                              fieldKey={[field.fieldKey, "firstName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing first name",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Middle Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "middleName"]}
                              fieldKey={[field.fieldKey, "middleName"]}
                              rules={[
                                {
                                  required: false,
                                  message: "Middle Name Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Last Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "lastName"]}
                              fieldKey={[field.fieldKey, "lastName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Last Name Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Passport No</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "passportNo"]}
                              fieldKey={[field.fieldKey, "passportNo"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Passport No Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">DOB</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "dob"]}
                              fieldKey={[field.fieldKey, "dob"]}
                              rules={[
                                { required: true, message: "DOB Missing " },
                              ]}
                            >
                              {/* <Input placeholder=""  />               */}
                              <DatePicker
                                placeholder="YYYY-MM-DD"
                                style={{ height: 39, width: 196 }}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="email">Active Visa?</label>
                              <div className="row">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "activeVisa"]}
                                  fieldKey={[field.fieldKey, "activeVisa"]}
                                  rules={[
                                    {
                                      required: false,
                                      message: "activeVisa Missing ",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    // onChange={radioValue2!=index?()=>{setRadioValue2(index)}:()=>{setRadioValue2(1000)}}
                                    onChange={handleOnRadioButtonChange}
                                    defaultValue={`f${index}`}
                                  >
                                    <div className="col-xs-6">
                                      <Radio defaultChecked value={`f${index}`}>
                                        Yes
                                      </Radio>
                                    </div>
                                    <div className="col-xs-6">
                                      <Radio value={`s${index}`}>No</Radio>
                                    </div>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="clearfix" />

                          <div
                            className="col-sm-4"
                            style={{
                              display: radioIndexList.includes(index)
                                ? "none"
                                : "",
                            }}
                          >
                            <label htmlFor="email">Visa Issuing Country</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "visaIssueCountry"]}
                              fieldKey={[field.fieldKey, "visaIssueCountry"]}
                              rules={[
                                {
                                  required: radioIndexList.includes(index)
                                    ? false
                                    : true,
                                  message: "Visa Issuing Country Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </div>

                          <div
                            className="col-sm-4"
                            style={{
                              display: radioIndexList.includes(index)
                                ? "none"
                                : "",
                            }}
                          >
                            <label htmlFor="email">Expiry Date</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "expiryDate"]}
                              fieldKey={[field.fieldKey, "expiryDate"]}
                              rules={[
                                {
                                  required: radioIndexList.includes(index)
                                    ? false
                                    : true,
                                  message: "expiryDate Missing ",
                                },
                              ]}
                            >
                              <DatePicker
                                disabledDate={disabledDate}
                                style={{ height: 39, width: 196 }}
                                placeholder="YYYY-MM-DD"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                      <div className="col-sm-4">
                        <Form.Item>
                          <div className="form-group m_t_30">
                            <label htmlFor="email"></label>
                            <button
                              ref={secondButtonRef}
                              className="btn btn-success save-event waves-effect waves-light"
                              type="button"
                              onClick={() => add()}
                            >
                              Add Candidates
                            </button>
                          </div>
                        </Form.Item>
                      </div>
                    </>
                  )}
                </Form.List>

                <div className="clearfix" />

                {/* <div className="col-sm-12">
          <div className="form-group ">
            <label htmlFor="email"></label>
            <button className="btn btn-success save-event waves-effect waves-light" type="button">Add Candidates</button>
          </div>        
        </div> */}
              </div>
            </Modal.Body>
            <div className="modal-footer  m-t-30">
              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setJobStatusOPen(false);
                }}
                data-dismiss="modal"
                className="btn btn-default waves-effect"
                type="button"
              >
                Cancel
              </button>
            </div>
            <div className="clearfix" />
            {/* </form> */}
          </Form>
        </Modal>

        {/* end modal job Status  */}

        {/* scoial media share modal statrt */}
        <Modal
          show={isShareOpen}
          onHide={() => {
            setIsShareOpen(!isShareOpen);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-12 m_t_30 text-center">
              <div className="social1">
                <WhatsappShareButton
                  url={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                  )}
                  className={""}
                  title={"CareerApp Job Post"}
                >
                  {/* <a href="#" className="social_face"> */}
                  <Tooltip title="whatsApp">
                    <i
                      className="fa fa-whatsapp"
                      style={{
                        fontSize: 51,
                        color: "green",
                        paddingRight: 2,
                      }}
                    ></i>
                  </Tooltip>
                  {/* </a> */}
                </WhatsappShareButton>{" "}
                <TelegramShareButton
                  url={`career app job Share`}
                  title={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                  )}
                >
                  <Tooltip title="Telegram">
                    <i
                      className="fa fa-telegram"
                      style={{
                        fontSize: 48,
                        color: "rgb(55, 174, 226)",
                      }}
                    ></i>
                  </Tooltip>
                </TelegramShareButton>
                <EmailShareButton
                  url={encodeURI(
                    `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                  )}
                  subject={"Jobs In Carrer app"}
                  body={"This is a Jobs from   Carrer app"}
                  className={""}
                >
                  {/* <EmailIcon></EmailIcon> */}
                  {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                  <Tooltip title="Gmail">
                    <img
                      src={require("../../../images/gmail-icon-svg-28.jpg")}
                      style={{ height: 66, marginTop: -24 }}
                    />
                  </Tooltip>
                </EmailShareButton>{" "}
                <CopyToClipboard
                  //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                  text={`https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`}
                >
                  <Tooltip title="copy to clipboard">
                    {" "}
                    {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                    <img
                      src={require("../../../images/copy-clipboard-icon-28.jpg")}
                      style={{
                        height: 66,
                        marginTop: -24,
                        marginLeft: -15,
                      }}
                      onClick={() => {
                        setIsShareOpen(!isShareOpen);
                      }}
                    />
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>
            <div className="modal-footer  m-t-30"></div>
          </Modal.Body>

          {/* <Modal.Body>
            <div className="col-sm-12 m_t_30 text-center">
              <div className="social1">
                <FacebookShareButton
                  url={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                  quote={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                  hashtag={"efg"}
                  title={"hij"}
                 
                  className={""}
                >
                 
                  <a href="#" className="social_face">
                    <i className="fa fa-facebook" aria-hidden="true" />
                  </a>
                </FacebookShareButton>

                <TwitterShareButton
                  url={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                  via={`${
                    filterFlag
                      ? matchedJobVendor != undefined &&
                        matchedJobVendor.data != undefined
                        ? matchedJobVendor.data.filter(
                            (data) => data.rowId === currentJobId
                          )[0] != undefined
                          ? matchedJobVendor.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0].description
                          : ""
                        : ""
                      : matchedJobVendor.data != undefined &&
                        matchedJobVendor.data != undefined
                      ? matchedJobVendor.data.filter(
                          (data) => data.rowId === currentJobId
                        )[0] != undefined
                        ? matchedJobVendor.data.filter(
                            (data) => data.rowId === currentJobId
                          )[0].description
                        : ""
                      : ""
                  }`}
                  hashtags={[
                    `${
                      filterFlag
                        ? matchedJobVendor != undefined &&
                          matchedJobVendor.data != undefined
                          ? matchedJobVendor.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0] != undefined
                            ? matchedJobVendor.data.filter(
                                (data) => data.rowId === currentJobId
                              )[0].description
                            : ""
                          : ""
                        : matchedJobVendor.data != undefined &&
                          matchedJobVendor.data != undefined
                        ? matchedJobVendor.data.filter(
                            (data) => data.rowId === currentJobId
                          )[0] != undefined
                          ? matchedJobVendor.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0].description
                          : ""
                        : ""
                    }`,
                  ]}
                  className={""}
                >
             
                  <a href="#" className="social_twit">
                    <i className="fa fa-twitter" aria-hidden="true" />
                  </a>
                </TwitterShareButton>

                <LinkedinShareButton
                  url={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                  title={`${
                    filterFlag
                      ? matchedJobVendor != undefined &&
                        matchedJobVendor.data != undefined
                        ? matchedJobVendor.data.filter(
                            (data) => data.rowId === currentJobId
                          )[0] != undefined
                          ? matchedJobVendor.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0].title
                          : ""
                        : ""
                      : matchedJobVendor.data != undefined &&
                        matchedJobVendor.data != undefined
                      ? matchedJobVendor.data.filter(
                          (data) => data.rowId === currentJobId
                        )[0] != undefined
                        ? matchedJobVendor.data.filter(
                            (data) => data.rowId === currentJobId
                          )[0].title
                        : ""
                      : ""
                  }`}
                  summary={`${
                    filterFlag
                      ? matchedJobVendor != undefined &&
                        matchedJobVendor.data != undefined
                        ? matchedJobVendor.data.filter(
                            (data) => data.rowId === currentJobId
                          )[0] != undefined
                          ? matchedJobVendor.data.filter(
                              (data) => data.rowId === currentJobId
                            )[0].description
                          : ""
                        : ""
                      : matchedJobVendor.data != undefined &&
                        matchedJobVendor.data != undefined
                      ? matchedJobVendor.data.filter(
                          (data) => data.rowId === currentJobId
                        )[0] != undefined
                        ? matchedJobVendor.data.filter(
                            (data) => data.rowId === currentJobId
                          )[0].description
                        : ""
                      : ""
                  }`}
                  source={`http://careerappui.clubactive.in/#/job_search/${currentJobId}`}
                >
                  <a href="#" className="social_twit">
                    <i className="fa fa-linkedin" aria-hidden="true" />
                  </a>
                </LinkedinShareButton>
              </div>
            </div>
            <div className="modal-footer  m-t-30">
              
            </div>
          </Modal.Body> */}
        </Modal>
      </div>
    </>
  );
};

export default VendorDashboardComponent;
