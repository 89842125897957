import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  jobAppliedSaveRequestModel,
  jobAppliedViewModel,
} from "../../../models/candidate/JobApplied";
import {
  getJobAppliedCandidateList,
  getJobAppliedList,
  saveJobApplied,
  useJobAppliedContext,
  useJobAppliedDispatcher,
} from "../../../action/JobAppliedAction";
import { jobAppliedRequestModel } from "../../../models/candidate/JobApplied";
import {
  getSuggestedJobList,
  getSuggestedJobTitleList,
  useSuggestedJobContext,
  useSuggestedJobDispatcher,
} from "../../../action/candidate/SuggestedAction";
import { suggestedJobRequestModel } from "../../../models/candidate/SuggestedJob";
import { Controller, useForm } from "react-hook-form";

import { useMyProfileContext } from "../../../action/MyProfileAction";
import AuthService from "../../../services/AuthService";
import { searchDataWithOutTokenRequestModel } from "../../../models/general/Search";
import {
  getSearchListWithToken,
  useSearchDispatcher,
} from "../../../action/general/SearchAction";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  deleteJobBookMarkList,
  saveBookMarkList,
  useJobBookMarkContext,
  useJobBookMarkDispatcher,
} from "../../../action/candidate/JobBookMarkAction";
import {
  jobBookMarkDataWithId,
  jobBookMarkDataWithUserId,
  jobBookMarkSaveRequestModel,
} from "../../../models/candidate/BookMarkedJob";
import SelectOption from "../my_profile/components/SelectOptionForSearch";
import { Popover, Tooltip } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";

interface ReactSelectOption {
  value: string;
  label: string;
}
interface ICandidateJobAppliedComponentProps {}

interface ICandidateJobAppliedComponentState {
  location: string;
  type: string[];
  jobTypeData: string;
  currentJobId: number;
  filterFlag: boolean;
}

const defaultValues = {
  location: "",
  type: [],
  jobTypeData: "",
  currentJobId: 0,
  filterFlag: true,
};

const CandidateJobAppliedComponent: React.FC<ICandidateJobAppliedComponentProps> = (
  props
) => {
  const [
    CandidateJobAppliedComponentState,
    setCandidateJobAppliedComponentState,
  ] = React.useState<ICandidateJobAppliedComponentState>(defaultValues);

  const {
    currentJobId,
    filterFlag,
    jobTypeData,
  } = CandidateJobAppliedComponentState;

  let jobCategoryData: ReactSelectOption[] = [];
  let jobTypeDatas: ReactSelectOption[] = [];

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ICandidateJobAppliedComponentState>({
    defaultValues,
  });

  let history = useHistory();

  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [jobTitle, setJobTitle] = useState<string>("");

  const jobBookMarkDispatcher = useJobBookMarkDispatcher();
  const jobBookMarkContext = useJobBookMarkContext();
  const { jobBookMark, jobBookMarkSaveRespond } = jobBookMarkContext;

  const jobAppliedDispatcher = useJobAppliedDispatcher();
  const jobAppliedContext = useJobAppliedContext();
  const { jobApplied, jobAppliedCandidateResult } = jobAppliedContext;

  const suggestedJobDispatcher = useSuggestedJobDispatcher();
  const suggestedJobContext = useSuggestedJobContext();
  const { suggestedJobs, suggestedJobTitleResultModel } = suggestedJobContext;

  const myProfileContext = useMyProfileContext();
  const { myProfile, loggedUserId } = myProfileContext;

  const searchDispatcher = useSearchDispatcher();

  const authorizationToken = AuthService.accessToken;

  //console.log("suggestedJobs", jobAppliedCandidateResult);

  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getJobAppliedCandidateList(
          jobAppliedDispatcher,
          {
            candidateId: loggedUserId,
            jobAppliedId: 0,
            isActive: true,
            jobId: 0,
            rowId: 0,
          } as jobAppliedSaveRequestModel,
          authorizationToken
        );
      })();
    }
  }, [jobBookMarkSaveRespond]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getSuggestedJobList(
          suggestedJobDispatcher,
          {
            CandidateId: loggedUserId,
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            ShowInactive: false,
            SortOrder: "",
          } as suggestedJobRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getSuggestedJobTitleList(
          suggestedJobDispatcher,
          {
            CandidateId: loggedUserId,
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            ShowInactive: false,
            SortOrder: "",
          } as suggestedJobRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  const onSubmit = (data: any) => {
    if (authorizationToken != null && loggedUserId != 0) {
      //console.log(39393, data);

      history.push(
        `/jobSearch/searchtitle/0/${
          data.jobTitle === "" ? "searchtitle" : data.jobTitle
        }/${data.location === "" ? "location" : data.location}/${
          data.jobTypeData === "" ? "0" : data.jobTypeData
        }`
      );
    }
  };

  React.useEffect(() => {
    if (jobTitle != "" && authorizationToken != null) {
      (async () => {
        await getSearchListWithToken(
          searchDispatcher,
          {
            expereince: [],
            location: [],
            title: [jobTitle],
            type: [],
            lastDays: [],
            candidateId: loggedUserId,
            pageIndex: 1,
            pageSize: 60,
            showInactive: false,
          } as searchDataWithOutTokenRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
        setJobTitle("");
      })();
      history.push("/job_search/0");
    }
  }, [jobTitle]);

  const handleJobSelect = (id: any) => {
    history.push(`/jobSearch/applied/${id}/searchtitle/location/0`);
  };

  const handleShareButtonClick = (id: any, value: any) => {
    setCandidateJobAppliedComponentState({
      ...CandidateJobAppliedComponentState,
      currentJobId: id,
      filterFlag: value,
    });
    setIsShareOpen(!isShareOpen);
  };

  const handleSaveJobBookMarked = (jobId: number) => {
    if (authorizationToken != null) {
      saveBookMarkList(
        jobBookMarkDispatcher,
        {
          candidateId: loggedUserId,
          jobBookmarkedId: 0,
          jobId: jobId,
          isActive: true,
          rowId: 0,
        } as jobBookMarkSaveRequestModel,
        authorizationToken
      );
    }
  };

  const handleDeleteBookMarked = (jobId: number) => {
    if (authorizationToken != null)
      deleteJobBookMarkList(
        jobBookMarkDispatcher,
        {
          jobId: jobId,
          candidateId: loggedUserId,
        } as jobBookMarkDataWithUserId,
        authorizationToken
      );
  };

  const handleSuggestedClick = (id: any) => {
    history.push(`/jobSearch/suggested/${id}/searchtitle/location/0`);
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Jobs Applied</h1>
          <div className="clearfix" />
          <div className="row ">
            <div className="col-sm-12">
              <div className="carees_search1">
                <div className="row">
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="search_forms">
                      <div className="cr_serach_br">
                        <input
                          name="jobTitle"
                          ref={register({ required: false })}
                          type="text"
                          className="form-control"
                          placeholder="Job Title"
                        />
                        <div className="search_icons">
                          <img
                            src={require("../../../images/search_icon.png")}
                            //  src="images/search_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <ErrorMessage errors={errors} name="jobType" render={({ message }) => <div className="register_validation">{message}</div>} /> */}
                    <div className="search_forms">
                      <div className="cr_serach_br">
                        <span className="select-search">
                          <input
                            name="location"
                            ref={register({ required: false })}
                            type="text"
                            className="form-control"
                            placeholder="Location"
                          />
                        </span>

                        <div className="search_icons">
                          <img
                            src={require("../../../images/locattion_icon.png")}
                            // src="images/locattion_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="search_forms">
                      <div className="cr_serach_br">
                        <span className="select-search">
                          <Controller
                            control={control}
                            name="jobTypeData"
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  myProfile.jobTypes != undefined
                                    ? myProfile.jobTypes.map((e) => {
                                        return {
                                          value: e["value"],
                                          label: e["caption"],
                                        };
                                      })
                                    : jobTypeDatas
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                                nameForLablel="Job Type"
                              />
                            )}
                          />
                          {/* <span className="holder">Type</span> */}
                        </span>
                        <div className="search_icons">
                          <img
                            src={require("../../../images/type_icon.png")}
                            // src="images/type_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="search_button">
                      <div className="search">
                        <button className="CustomButtonCss" type="submit">
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-sm-8">
              <div className="section_box3">
                <div className="row">
                  <div
                    className="CandidateJobApplied_scroll"
                    style={{ overflow: "hidden", outline: "none" }}
                    tabIndex={0}
                  >
                    <Scrollbars
                      style={{ height: 690 }}
                      autoHide
                      renderThumbVertical={({ style, ...props }) => (
                        <div
                          {...props}
                          style={{
                            ...style,
                            position: "relative",
                            display: "block",
                            width: "5px",
                            cursor: "pointer",
                            borderRadius: "inherit",
                            backgroundColor: "rgb(73, 69, 69)",
                            height: "115px",
                          }}
                        />
                      )}
                    >
                      {jobAppliedCandidateResult.data
                        ? // jobApplied != undefined
                          jobAppliedCandidateResult.data
                            //  jobApplied.data
                            .map((Job, index) => {
                              return (
                                <div
                                  // onClick={() => handleJobSelect(Job.rowId)}
                                  className="col-sm-4"
                                  key={`${index}`}
                                >
                                  <div className="matched_jobs">
                                    <div className="matched_jobs_cat_t textOverFlow">
                                      {Job.categoryName}
                                    </div>
                                    <div className="jobs_start1 textOverFlow">
                                      {Job.postedDate}
                                    </div>
                                    <div className="matched_star">
                                      {Job.isBookmarked ? (
                                        <i
                                          onClick={() =>
                                            handleDeleteBookMarked(Job.jobId)
                                          }
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <i
                                          onClick={() =>
                                            handleSaveJobBookMarked(Job.jobId)
                                          }
                                          className="fa fa-star-o"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </div>
                                    <div className="clearfix" />
                                    <Popover
                                      content={"Please click for more details"}
                                    >
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleJobSelect(Job.jobId)
                                        }
                                      >
                                        <div className="matched_heading textOverFlow">
                                          {Job.title}
                                        </div>
                                        <div className="matched_jobs_cat textOverFlow">
                                          <i
                                            className="fa fa-map-marker"
                                            aria-hidden="true"
                                          />
                                          {""}
                                          {Job.location}
                                        </div>
                                        <div className="matched_jobs_cat text-right">
                                          <i
                                            style={{ marginRight: 5 }}
                                            className="fa fa-clock-o"
                                            aria-hidden="true"
                                          />
                                          {"  "}
                                          {Job.jobType}
                                        </div>
                                        <div className="clearfix" />
                                        <p className="textOverFlow">
                                          {Job.description}
                                        </p>
                                      </div>
                                      <div className="matched_jobs_share">
                                        <a
                                          onClick={() =>
                                            handleShareButtonClick(
                                              Job.rowId,
                                              true
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-share-alt"
                                            aria-hidden="true"
                                          />
                                        </a>
                                      </div>
                                    </Popover>
                                    <div className="matched_jobs_status">
                                      <a>{Job.jobAppliedStatus}</a>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                        : null}
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="section_box3">
                <h1>Suggested Jobs</h1>
                <div
                  className="suggested_job_scroll"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 300 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {suggestedJobs.data != undefined
                      ? suggestedJobs.data.map((data, i) => {
                          return (
                            <div className="suggested_sec" key={i}>
                              <Popover
                                content={"Please click for more details"}
                              >
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleSuggestedClick(data.jobId)
                                  }
                                >
                                  <div className="suggested_sec_cat">
                                    <i
                                      className="fa fa-briefcase"
                                      aria-hidden="true"
                                    />{" "}
                                    {data.title}
                                  </div>
                                  <div className="suggested_sec_cat">
                                    <i
                                      className="fa fa-calendar"
                                      aria-hidden="true"
                                    />{" "}
                                    {data.postedDate}
                                  </div>
                                  <div className="suggested_sec_cat">
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    />
                                    {"  "}
                                    {data.jobType}
                                  </div>
                                  <div className="suggested_sec_cat">
                                    <i
                                      className="fa fa-user"
                                      aria-hidden="true"
                                    />{" "}
                                    Ex {data.experience} years
                                  </div>
                                  <div className="suggested_sec_cat">
                                    <i
                                      className="fa fa-map-marker"
                                      aria-hidden="true"
                                    />{" "}
                                    {data.location}
                                  </div>
                                </div>
                              </Popover>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setIsShareOpen(!isShareOpen);
                                }}
                                className="suggested_sec_cat"
                              >
                                <i
                                  className="fa fa-share-alt"
                                  aria-hidden="true"
                                />{" "}
                                Share
                              </div>
                            </div>
                          );
                        })
                      : null}
                    {/* <div className="suggested_sec">
                    <div className="suggested_sec_cat"><i className="fa fa-briefcase" aria-hidden="true" />  Ui/Ux designer</div>
                    <div className="suggested_sec_cat"><i className="fa fa-calendar" aria-hidden="true" /> Posted 1d ago</div>
                    <div className="suggested_sec_cat"><i className="fa fa-clock-o" aria-hidden="true" />  Part Time</div>
                    <div className="suggested_sec_cat"><i className="fa fa-user" aria-hidden="true" /> Ex 2-4 years</div>
                    <div className="suggested_sec_cat"><i className="fa fa-map-marker" aria-hidden="true" />  San Fransisco</div>
                    <div className="suggested_sec_cat"><i className="fa fa-share-alt" aria-hidden="true" /> Share</div>
                  </div>
                  <div className="suggested_sec">
                    <div className="suggested_sec_cat"><i className="fa fa-briefcase" aria-hidden="true" />  Ui/Ux designer</div>
                    <div className="suggested_sec_cat"><i className="fa fa-calendar" aria-hidden="true" /> Posted 1d ago</div>
                    <div className="suggested_sec_cat"><i className="fa fa-clock-o" aria-hidden="true" />  Part Time</div>
                    <div className="suggested_sec_cat"><i className="fa fa-user" aria-hidden="true" /> Ex 2-4 years</div>
                    <div className="suggested_sec_cat"><i className="fa fa-map-marker" aria-hidden="true" />  San Fransisco</div>
                    <div className="suggested_sec_cat"><i className="fa fa-share-alt" aria-hidden="true" /> Share</div>
                  </div>
                  <div className="suggested_sec">
                    <div className="suggested_sec_cat"><i className="fa fa-briefcase" aria-hidden="true" />  Ui/Ux designer</div>
                    <div className="suggested_sec_cat"><i className="fa fa-calendar" aria-hidden="true" /> Posted 1d ago</div>
                    <div className="suggested_sec_cat"><i className="fa fa-clock-o" aria-hidden="true" />  Part Time</div>
                    <div className="suggested_sec_cat"><i className="fa fa-user" aria-hidden="true" /> Ex 2-4 years</div>
                    <div className="suggested_sec_cat"><i className="fa fa-map-marker" aria-hidden="true" />  San Fransisco</div>
                    <div className="suggested_sec_cat"><i className="fa fa-share-alt" aria-hidden="true" /> Share</div>
                  </div>
                  <div className="suggested_sec">
                    <div className="suggested_sec_cat"><i className="fa fa-briefcase" aria-hidden="true" />  Ui/Ux designer</div>
                    <div className="suggested_sec_cat"><i className="fa fa-calendar" aria-hidden="true" /> Posted 1d ago</div>
                    <div className="suggested_sec_cat"><i className="fa fa-clock-o" aria-hidden="true" />  Part Time</div>
                    <div className="suggested_sec_cat"><i className="fa fa-user" aria-hidden="true" /> Ex 2-4 years</div>
                    <div className="suggested_sec_cat"><i className="fa fa-map-marker" aria-hidden="true" />  San Fransisco</div>
                    <div className="suggested_sec_cat"><i className="fa fa-share-alt" aria-hidden="true" /> Share</div>
                  </div> */}
                  </Scrollbars>
                </div>
              </div>
              <div className="section_box3">
                <h1>Suggested Job Titles</h1>
                <div
                  className="suggested_title_scroll"
                  style={{ overflow: "hidden", outline: "none" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 270 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    <div className="suggested_titles">
                      <ul>
                        {suggestedJobTitleResultModel.data != undefined
                          ? suggestedJobTitleResultModel.data.map((data, i) => {
                              return (
                                <Popover
                                  content={"Please click for more details"}
                                >
                                  <li key={i}>
                                    <a
                                      onClick={() => {
                                        history.push(
                                          `/jobSearch/suggested/0/${data.title}/location/0`
                                        );
                                      }}
                                    >
                                      {data.title}
                                    </a>
                                  </li>
                                </Popover>
                              );
                            })
                          : null}
                        {/* <li><a href="#">Software Engineer</a></li>
                      <li><a href="#">Developer</a></li>
                      <li><a href="#">Test Lead</a></li>
                      <li><a href="#">Dev Lead</a></li>
                      <li><a href="#">UI Developer</a></li>
                      <li><a href="#">UI Developer</a></li> */}
                      </ul>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>

      <Modal
        show={isShareOpen}
        onHide={() => {
          setIsShareOpen(!isShareOpen);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share this job post on</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 m_t_30 text-center">
            <div className="social1">
              <WhatsappShareButton
                url={encodeURI(
                  `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                )}
                className={""}
                title={"CareerApp Job Post"}
              >
                {/* <a href="#" className="social_face"> */}
                <Tooltip title="whatsApp">
                  <i
                    className="fa fa-whatsapp"
                    style={{
                      fontSize: 51,
                      color: "green",
                      paddingRight: 2,
                    }}
                  ></i>
                </Tooltip>
                {/* </a> */}
              </WhatsappShareButton>{" "}
              <TelegramShareButton
                url={`career app job Share`}
                title={encodeURI(
                  `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                )}
              >
                <Tooltip title="Telegram">
                  <i
                    className="fa fa-telegram"
                    style={{
                      fontSize: 48,
                      color: "rgb(55, 174, 226)",
                    }}
                  ></i>
                </Tooltip>
              </TelegramShareButton>
              <EmailShareButton
                url={encodeURI(
                  `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`
                )}
                subject={"Jobs In Carrer app"}
                body={"This is a Jobs from   Carrer app"}
                className={""}
              >
                {/* <EmailIcon></EmailIcon> */}
                {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                <Tooltip title="Gmail">
                  <img
                    src={require("../../../images/gmail-icon-svg-28.jpg")}
                    style={{ height: 66, marginTop: -24 }}
                  />
                </Tooltip>
              </EmailShareButton>{" "}
              <CopyToClipboard
                //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                text={`https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentJobId}/searchtitle/location/0`}
              >
                <Tooltip title="copy to clipboard">
                  {" "}
                  {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                  <img
                    src={require("../../../images/copy-clipboard-icon-28.jpg")}
                    style={{
                      height: 66,
                      marginTop: -24,
                      marginLeft: -15,
                    }}
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  />
                </Tooltip>
              </CopyToClipboard>
            </div>
          </div>
          <div className="modal-footer  m-t-30"></div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default CandidateJobAppliedComponent;
