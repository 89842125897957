import { Dispatch, Reducer } from "react";

import React from "react";
import axios from "axios";
import AuthService from "../../services/AuthService";

import { AppUrls } from "../../environments/environment";

import {
  AdminJobApplicationDispatcherContext,
  AdminJobApplicationStateContext,
  IAdminJobApplicationManagementState,
} from "../../context/admin/AdminJobApplicationContext";
import { message } from "antd";
import {
  permisionRequestModel,
  //PermissionResultModel,
} from "../../models/admin/AdminMyJob";
import {
  AdminMoveJobStatusRequestModel,
  AdminMoveJobStatusRespondModel,
  GetAdminCandidateRequestModel,
  GetAdminCandidateResultModel,
  GetAdminVendorCandidateRequestModel,
  GetAdminVendorCandidateResultModel,
  InterviewScheduleSmsAndBulkEmailRequestModel,
  InterviwSheduleBulkSmsAndEmailRespondModel,
  JobApplicationViewJobRequestModel,
  JobApplicationViewJobResultModel,
  ResumeReportGeneratorRequestModel,
} from "../../models/admin/AdminJobApplication";
import DocViewer from "react-docs-viewer";
import { Document, Packer, Paragraph } from "docx";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

type objectIndexing = {
  [key: string]: any;
};

export type IAdminJobApplicationAction =
  | {
      type: "LEST_ALL_VIEW_JOBS";
      JobApplicationViewJobResultModel: JobApplicationViewJobResultModel;
    }
  | {
      type: "ADMIN_JOB_STATUS_MOVE";
      AdminMoveJobStatusResult: AdminMoveJobStatusRespondModel;
    }
  | {
      type: "GET_ADMIN_CANDIDATE_RESULT";
      AdminCandidateResult: GetAdminCandidateResultModel;
    }
  | {
      type: "GET_ADMIN_VENDOR_CANDIDATE_RESULT";
      AdminVendorCandidateResult: GetAdminVendorCandidateResultModel;
    }
  | {
      type: "INTERVIEW_SHEDULE_BULK_SMS_AND_EMAIL";
      interviewSheduleBulkSmsOrEmailRespond: InterviwSheduleBulkSmsAndEmailRespondModel;
    }
  | {
      type: "GENERAL_REPORT";
      GeneralReportResult: AdminMoveJobStatusRespondModel;
    };

export const adminJobApplicationReducer: Reducer<
  IAdminJobApplicationManagementState,
  IAdminJobApplicationAction
> = (draft, action): IAdminJobApplicationManagementState => {
  switch (action.type) {
    case "LEST_ALL_VIEW_JOBS":
      draft.JobApplicationViewJobResultModel =
        action.JobApplicationViewJobResultModel;
      return draft;

    case "ADMIN_JOB_STATUS_MOVE":
      draft.AdminMoveJobStatusResult = action.AdminMoveJobStatusResult;
      return draft;

    case "GET_ADMIN_CANDIDATE_RESULT":
      draft.AdminCandidateResult = action.AdminCandidateResult;
      return draft;

    case "GET_ADMIN_VENDOR_CANDIDATE_RESULT":
      draft.AdminVendorCandidateResult = action.AdminVendorCandidateResult;
      return draft;

    case "INTERVIEW_SHEDULE_BULK_SMS_AND_EMAIL":
      draft.interviewSheduleBulkSmsOrEmailRespond =
        action.interviewSheduleBulkSmsOrEmailRespond;
      return draft;
    case "GENERAL_REPORT":
      draft.GeneralReportResult = action.GeneralReportResult;
      return draft;
  }
};

export const useAdminJobApplicationDispatcher = (): React.Dispatch<IAdminJobApplicationAction> => {
  const adminJobApplicationDispatcher = React.useContext(
    AdminJobApplicationDispatcherContext
  );
  if (!adminJobApplicationDispatcher) {
    throw new Error(
      "You have to provide the AdminJobApplication dispatcher using theAdminJobApplicationDispatcherContext.Provider in a parent component."
    );
  }
  return adminJobApplicationDispatcher;
};

export const useAdminJobApplicationContext = (): IAdminJobApplicationManagementState => {
  const adminJobApplicationContext = React.useContext(
    AdminJobApplicationStateContext
  );
  if (!adminJobApplicationContext)
    throw new Error(
      "You have to provide the adminJobApplication context using the AdminJobApplicationStateContext.Provider in a parent component."
    );
  return adminJobApplicationContext;
};

export const getJobApplicationViewJobList = async (
  dispatcher: React.Dispatch<IAdminJobApplicationAction>,
  query: JobApplicationViewJobRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetViewJobApplication;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "LEST_ALL_VIEW_JOBS",
        JobApplicationViewJobResultModel: res.data,
      });
    });
  } catch (e) {}
};

// moving Status
export const AdminJobAppliedStatusMovie = async (
  dispatcher: React.Dispatch<IAdminJobApplicationAction>,
  query: AdminMoveJobStatusRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.MoveAdminJobStatus;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "ADMIN_JOB_STATUS_MOVE",
        AdminMoveJobStatusResult: res.data,
      });
    });
  } catch (e) {}
};

export const GetAdminCandidateDetails = async (
  dispatcher: React.Dispatch<IAdminJobApplicationAction>,
  query: GetAdminCandidateRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAdminCandidateDetails;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ADMIN_CANDIDATE_RESULT",
        AdminCandidateResult: res.data,
      });
    });
  } catch (e) {}
};

export const GetAdminVendorCandidateDetails = async (
  dispatcher: React.Dispatch<IAdminJobApplicationAction>,
  query: GetAdminVendorCandidateRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAdminVendorCandidateDetails;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "GET_ADMIN_VENDOR_CANDIDATE_RESULT",
        AdminVendorCandidateResult: res.data,
      });
    });
  } catch (e) {}
};

// Interview Shedule And Bulk Sms,Email

export const InterviewSheduleBulkSmsAndEmailSend = async (
  dispatcher: React.Dispatch<IAdminJobApplicationAction>,
  query: InterviewScheduleSmsAndBulkEmailRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.InterviewScheduleSmsAndBulkEmail;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({
        type: "INTERVIEW_SHEDULE_BULK_SMS_AND_EMAIL",
        interviewSheduleBulkSmsOrEmailRespond: res.data,
      });
      res.data.isSuccess
        ? message.success("Successfully Sent")
        : message.error(res.data.message);
    });
  } catch (e) {}
};

// General Resume Report

export const SetAllResumeReport = async (
  dispatcher: React.Dispatch<IAdminJobApplicationAction>,
  query: ResumeReportGeneratorRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      responseType: "blob",
    };

    const url = AppUrls.SetAllResumeReport;
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        const blob = new Blob([res.data], {
          type:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        var FileSaver = require("file-saver");
        FileSaver.saveAs(blob, "1.docx");
        //const fileUrl = window.URL.createObjectURL(
        //  new Blob([res.data], { type: "application/pdf" })
        //setFile(new Blob([res.data], { type: 'application/pdf' }))
        // setFile(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }))
        //setIsPreview(true);
      });
    // axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
    //   dispatcher({
    //     type: "GENERAL_REPORT",
    //     GeneralReportResult: res.data,
    //   });
    //   const blob = new Blob([res.data], {
    //     type:
    //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //   });
    //   var FileSaver = require("file-saver");
    //   FileSaver.saveAs(blob, "1.docx");

    //const fileUrl = window.URL.createObjectURL(
    //  new Blob([res.data], { type: "application/pdf" })
    //setFile(new Blob([res.data], { type: 'application/pdf' }))
    // setFile(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }))
    //setIsPreview(true);
    // const link = document.createElement("a");
    // link.href = fileUrl;
    // link.setAttribute("download", `resume.pdf`);
    // document.body.appendChild(link);
    // link.click();

    // const packer = new Packer();

    // const mimeType =
    //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    // packer.toBlob(res.data).then((blob: any) => {
    //   const docblob = blob.slice(0, blob.size, mimeType);
    //   saveAs(docblob, "first.docx");
    // });

    // var FileSaver = require("file-saver");
    // FileSaver.saveAs(res.data, "resume.docx");

    // const a = document.createElement("a");
    // document.body.appendChild(a);

    // a.setAttribute("style", "display: none");

    // var fileUrl = URL.createObjectURL(
    //   new File([res.data], "resume1.docx", {
    //     type:
    //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //     // "application/msword",
    //   })
    // );
    // const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
    // a.href = fileUrl;

    // a.download = "resume1.docx";
    // a.click();

    // window.URL.revokeObjectURL(fileUrl);
    // a.remove();

    // var b64toBlob = require("b64-to-blob");
    // const reader = new FileReader();
    // reader.readAsDataURL(b64toBlob(res.data));

    // var FileSaver = require("file-saver");
    // FileSaver.saveAs(res.data, "resume.docx");
    // FileSaver.saveAs(
    //   new Blob([res.data], {
    //     type:
    //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //   }),
    //   "resume.docx"
    // );
    // res.data.isSuccess
    //   ? message.success("Successfully Send")
    //   : message.error(res.data.message);
    // });
  } catch (e) {}
};

export function getAdminResumeReport(data?: any) {
  let accessToken = AuthService.accessToken;

  if (accessToken?.startsWith(`"`)) {
    accessToken = accessToken.substring(1);
    accessToken = accessToken.slice(0, -1);
  }

  let headers: objectIndexing = {
    // Authorization: "bearer " + accessToken,

    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  // if (opts && opts.headers) {
  //   headers = Object.assign({}, headers, opts.headers);
  // }

  const formData = new FormData();
  formData.append("person", data);

  return axios({
    method: "post",
    responseType: "blob",
    url: "https://jitreport.clubactive.in/Report/GenerateAdminCandidateResume",
    // url: AppUrls.GetDocumentResumeInAdminSide,
    // data: data || {},
    data: formData || {},
    // headers,
  });
}

export function getAdminPartResumeReport(data: any, query: any) {
  let accessToken = AuthService.accessToken;
  if (accessToken?.startsWith(`"`)) {
    accessToken = accessToken.substring(1);
    accessToken = accessToken.slice(0, -1);
  }

  // query = query + `&token=${accessToken}`;
  return axios({
    method: "get",
    responseType: "blob",
    // url: `http://localhost:44329/Report/GenerateAdminCandidateResume`,
    params: {
      model: query,
    },
    url: `https://jitreport.clubactive.in/Report/GenerateCandidateResume${query}`,
    data: data || {},
  });
}
