import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { IMyProfileAction, myProfileReducer } from "../action/MyProfileAction";
import {
  BasicInfoViewModel,
  MyProfileSelectBoxDataViewModel,
  profileBarProgress,
  profileImage,
  profileImageViewModel,
} from "../models/candidate/MyProfileSelectBoxData";

export const MyProfileDispatcherContext = React.createContext<React.Dispatch<
  IMyProfileAction
> | null>(null);
export const MyProfileStateContext = React.createContext<IMyProfileManagementState | null>(
  null
);

export interface IMyProfileManagementProps extends RouteComponentProps<any> {}

export interface IMyProfileManagementState {
  visible: boolean;
  value: number;
  myProfile: MyProfileSelectBoxDataViewModel;
  basicInfo: BasicInfoViewModel;
  loggedUserId: number;
  profileImage: profileImageViewModel;
  myProfileProgressBar: profileBarProgress;
}

export const initialMyProfileManagementState = {
  visible: false,
  value: 0,
  myProfile: {} as MyProfileSelectBoxDataViewModel,
  basicInfo: {} as BasicInfoViewModel,
  loggedUserId: 0,
  profileImage: {} as profileImageViewModel,
  myProfileProgressBar: {} as profileBarProgress,
} as IMyProfileManagementState;

export const MyProfileContextProvider: React.FC = ({ children }) => {
  const [myProfileState, dispatcher] = useImmerReducer(
    myProfileReducer,
    initialMyProfileManagementState
  );

  return (
    <MyProfileDispatcherContext.Provider value={dispatcher}>
      <MyProfileStateContext.Provider value={myProfileState}>
        {children}
      </MyProfileStateContext.Provider>
    </MyProfileDispatcherContext.Provider>
  );
};
