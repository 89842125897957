import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { newlyPostedJobViewModel } from "../../models/general/NewlyPostedJob";
import { INewlyPostedJobAction, newlyPostedJobReducer } from "../../action/general/NewlyPostedJobAction";


export const NewlyPostedJobDispatcherContext = React.createContext<React.Dispatch<INewlyPostedJobAction> | null>(null);
export const NewlyPostedJobStateContext = React.createContext<INewlyPostedJobManagementState | null>(null);

export interface INewlyPostedJobManagementProps extends RouteComponentProps<any> { }

export interface INewlyPostedJobManagementState {
    visible:boolean;
    value:number;
    newlyPostedJob:newlyPostedJobViewModel
}

export const initialNewlyPostedJobManagementState = {
    visible:false,
    value:0,
    newlyPostedJob:{} as newlyPostedJobViewModel,
} as INewlyPostedJobManagementState;

export const NewlyPostedJobContextProvider: React.FC = ({ children }) => {
    const [newlyPostedJobState, dispatcher] = useImmerReducer(newlyPostedJobReducer, initialNewlyPostedJobManagementState);

    return (
        <NewlyPostedJobDispatcherContext.Provider value={dispatcher}>
            <NewlyPostedJobStateContext.Provider value={newlyPostedJobState}>
                {children}
            </NewlyPostedJobStateContext.Provider>
        </NewlyPostedJobDispatcherContext.Provider>
    )
}