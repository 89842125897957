import React from "react";
import VendorApprovalComponent from "./VendorApprovalComponent";
import AuthService from "../../../services/AuthService";

interface IVendorApprovalContainerProps { }

interface IVendorApprovalContainerState { }

const initialState = {};

const VendorApprovalContainer: React.FC<IVendorApprovalContainerProps> = (props) => {
  const [VendorApprovalContainerState, setVendorApprovalContainerState] = React.useState<IVendorApprovalContainerState>(
    initialState
  );
  return (
    <>
      <VendorApprovalComponent/>
    </>
  );
};
export default VendorApprovalContainer;
