import React from "react";
import IndexFooterComponent from "./IndexFooterComponent";
import { SocialAccountUrlContextProvider } from "../../../context/admin/SocialAccountUrlContext";

interface IIndexFooterContainerProps { }

interface IIndexFooterContainerState { }

const initialState = {};

const IndexFooterContainer: React.FC<IIndexFooterContainerProps> = (props) => {
  const [
    IndexFooterContainerState,
    setIndexFooterContainerState,
  ] = React.useState<IIndexFooterContainerState>(initialState);

  return (
    <>
      <SocialAccountUrlContextProvider>
        <IndexFooterComponent />
      </SocialAccountUrlContextProvider>
    </>
  );
};
export default IndexFooterContainer;
