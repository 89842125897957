import { ErrorMessage } from "@hookform/error-message";
import { Select, TimePicker } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  InterviewSheduleBulkSmsAndEmailSend,
  useAdminJobApplicationContext,
  useAdminJobApplicationDispatcher,
} from "../../../action/admin/AdminJobApplicationAction";
import { saveAdminActions } from "../../../apis/admin";
import { InterviewScheduleSmsAndBulkEmailRequestModel } from "../../../models/admin/AdminJobApplication";
import AuthService from "../../../services/AuthService";
import PikadayDatepicker from "../../candidate/my_profile/components/PikadayDatepicker";

const defaultValues = {
  date: 0,
};

type objectIndexing = {
  [key: string]: any;
};

interface IInterviewSheduleProps {
  handleDisplayModeChange: (data: any) => void;
  checkedListArray: any[];
  userId: number;
  handleRestCheckedArrays: () => void;
  loggedAdminId: number;
  jobTitle: string | null;
}

const InterviewShedule: React.FC<IInterviewSheduleProps> = (props) => {
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const adminJobApplicationDispatcher = useAdminJobApplicationDispatcher();
  const adminJobApplicationContext = useAdminJobApplicationContext();
  const { interviewSheduleBulkSmsOrEmailRespond } = adminJobApplicationContext;

  const authorizationToken = AuthService.accessToken;
  let { id } = useParams<{ id: any }>();
  const {
    handleDisplayModeChange,
    checkedListArray,
    userId,
    handleRestCheckedArrays,
    loggedAdminId,
    jobTitle,
  } = props;

  const onHandleDisplayModeChange = (value: any) => {
    handleDisplayModeChange(value);
  };

  const { Option } = Select;

  const [handleMode, sethandleMode] = useState<number>(0);
  const [chooseType, setChooseType] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<string>("");
  const [timevalidation, setTimevalidation] = useState<string>("");

  let history = useHistory();
  function handleChange(value: any) {
    //console.log(`selected ${value}`);
    sethandleMode(parseInt(value));
  }

  const onHandleRestCheckedArrays = () => {
    handleRestCheckedArrays();
  };

  const handleFormSubmit = (data: any) => {
    //console.log(4445, data);
    //console.log(4446, handleMode);
    if (currentTime === "") {
      setTimevalidation("  time is required");
    } else if (authorizationToken != null && checkedListArray.length > 0) {
      (async () => {
        await InterviewSheduleBulkSmsAndEmailSend(
          adminJobApplicationDispatcher,
          {
            AdminId: 4,
            // InterViewTime: data.time,
            //  InterviewDate:new Date(data["data.date"]).toISOString(),
            JobTitle: jobTitle !== null ? jobTitle : "",
            InterViewTime: currentTime,
            IsActive: true,
            JobId: id,
            Location: data.location,
            OnlineUrl: data.onlineurl,
            ModeOfInterview: handleMode,
            ProcessedJobsFlag: true,
            comments: data.comment,
            Subject: data.title,
            UserIds: checkedListArray,
            SendEmail: chooseType == 0 ? false : true,
            SendSMS: chooseType == 0 ? true : false,
            userTypeId: userId,
            InterviewDate:
              data.date == 0
                ? ""
                : moment(new Date(data["date"])).format("DD/MM/YYYY"),
          } as InterviewScheduleSmsAndBulkEmailRequestModel,
          authorizationToken
        );
      })();
      onHandleRestCheckedArrays();
      setTimevalidation("");
      let _data: objectIndexing = {};

      _data["AdminId"] = Number(loggedAdminId);
      _data["JobId"] = id;
      _data["PublishedJobsFlag"] = false;
      _data["ApprovalJobsFlag"] = false;
      _data["ViewedJobsFlag"] = false;
      _data["ProcessedJobsFlag"] = true;
      _data["FlagId"] = Number(4);
      _data["IsActive"] = true;
      saveAdminActions(_data).then((res: any) => {
        //console.log(res);
        if (res.data.isSuccess) {
          handleDisplayModeChange(0);
          //  globalSpinner.hideSpinner();
          //  toast.success("Email sent successfully.");
          //  setActive(1);
        } else {
          // globalSpinner.hideSpinner();
          // toast.error("Error:Email not sent.");
        }
      });
    }
  };

  const onChangeTime = (time: any, timeString: any) => {
    //console.log(moment(time));
    setCurrentTime(timeString);
    setTimevalidation(" ");
  };

  // React.useEffect(() => {
  //   if (interviewSheduleBulkSmsOrEmailRespond.isSuccess) {
  //     onHandleDisplayModeChange(0);
  //   } else if (!interviewSheduleBulkSmsOrEmailRespond.isSuccess) {
  //     onHandleDisplayModeChange(0);
  //   }
  // }, [interviewSheduleBulkSmsOrEmailRespond]);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Interview Scheduler</h1>
          <a
            onClick={() => onHandleDisplayModeChange(0)}
            className="btn cutomise_but"
          >
            <i className="fa fa-angle-left" aria-hidden="true" /> &nbsp; Back{" "}
          </a>
          <div className="clearfix" />
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="section_box4">
                <h1>Schedule on Interview</h1>
                <div className="clearfix" />
                <div className="row">
                  <div className="col-sm-12 col-lg-12 ">
                    <div className="admins_se">
                      <div className=" section_box4">
                        <form
                          onSubmit={handleSubmit2(handleFormSubmit)}
                          noValidate
                        >
                          <div className=" form-group">
                            <label htmlFor="email" className="col-sm-2">
                              Title
                            </label>
                            <div className="col-sm-10">
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Subject"
                                name="title"
                                ref={register2({
                                  required: "Title is Required",
                                })}
                              />
                              <ErrorMessage
                                errors={errors2}
                                name="title"
                                render={({ message }) => (
                                  <div className="login_validation">
                                    {message}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className=" clearfix" />
                          <div className=" form-group">
                            <label htmlFor="email" className="col-sm-2">
                              Date
                            </label>
                            <div className="col-sm-10">
                              <Controller
                                rules={{
                                  validate: (value) => {
                                    if (value <= 0) {
                                      return "Required from date.";
                                    } else if (value < Date.now()) {
                                      return "From Date cannot be a past date.";
                                    } else {
                                      return undefined;
                                    }
                                  },
                                }}
                                control={control2}
                                name="date"
                                render={({ onChange, onBlur, value, name }) => (
                                  <PikadayDatepicker
                                    disabled={false}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    name={name}
                                  />
                                )}
                              />
                              <ErrorMessage
                                errors={errors2}
                                name="date"
                                render={({ message }) => (
                                  <div className="login_validation">
                                    {message}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className=" clearfix" />
                          <div className=" form-group">
                            <label htmlFor="email" className="col-sm-2">
                              Time
                            </label>
                            <div className="col-sm-10">
                              {/* <input
                                type="email"
                                className="form-control"
                                name="time"
                                ref={register2({
                                  required: "Title is Required",
                                })}
                              /> */}
                              <TimePicker
                                use12Hours
                                onChange={onChangeTime}
                                style={{ width: 950 }}
                              />
                              {/* <TimePickerComponent placeholder='select time' onChange></TimePickerComponent> */}
                              {currentTime === "" ? (
                                <h6 style={{ color: "red" }}>
                                  {timevalidation}
                                </h6>
                              ) : (
                                <h6></h6>
                              )}
                              {/* <ErrorMessage
                                errors={errors2}
                                name="time"
                                render={({ message }) => (
                                  <div className="login_validation">
                                    {message}
                                  </div>
                                )}
                              /> */}
                            </div>
                          </div>
                          <div className=" clearfix" />
                          <div className=" form-group">
                            <label htmlFor="email" className="col-sm-2">
                              Mode
                            </label>
                            <div className="col-sm-10">
                              <Select
                                defaultValue="0"
                                style={{ width: 950 }}
                                onChange={handleChange}
                              >
                                <Option value="0">Online</Option>
                                <Option value="1">Offline</Option>
                              </Select>
                            </div>
                          </div>
                          <div className=" clearfix" />
                          {handleMode == 0 ? (
                            <div className=" form-group">
                              <label htmlFor="email" className="col-sm-2">
                                Online URL
                              </label>
                              <div className="col-sm-10">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="onlineurl"
                                  ref={register2({
                                    required:
                                      handleMode == 0
                                        ? "online URL is required"
                                        : false,
                                  })}
                                />
                                <ErrorMessage
                                  errors={errors2}
                                  name="onlineurl"
                                  render={({ message }) => (
                                    <div className="login_validation">
                                      {message}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className=" form-group">
                              <label htmlFor="email" className="col-sm-2">
                                Location
                              </label>
                              <div className="col-sm-10">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="location"
                                  ref={register2({
                                    required:
                                      handleMode == 0
                                        ? false
                                        : "location is required",
                                  })}
                                />
                                <ErrorMessage
                                  errors={errors2}
                                  name="location"
                                  render={({ message }) => (
                                    <div className="login_validation">
                                      {message}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                          )}
                          <div className=" form-group">
                            <label htmlFor="email" className="col-sm-2">
                              Comments
                            </label>
                            <div className="col-sm-10">
                              <textarea
                                name="comment"
                                ref={register2({
                                  required: "Title is Required",
                                })}
                                className="form-control form-contro12"
                                // placeholder="The inline form above feels and will look much better with Bootstrap's spacing utilities. The following example adds a right margin"
                                defaultValue={""}
                              />
                              <ErrorMessage
                                errors={errors2}
                                name="comment"
                                render={({ message }) => (
                                  <div className="login_validation">
                                    {message}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className="pull-right " style={{ padding: 5 }}>
                            <button
                              onClick={() => setChooseType(1)}
                              type="submit"
                              className="btn next_btn"
                            >
                              Send Email
                            </button>
                          </div>{" "}
                          <div
                            className="pull-right m_r_10"
                            style={{ padding: 5 }}
                          >
                            <button
                              onClick={() => setChooseType(0)}
                              type="submit"
                              className="btn next_btn"
                            >
                              Send SMS
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </>
  );
};

export default InterviewShedule;
