import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { matchedJobVendorViewModel } from "../../models/vendor/VendorMatchedJob";
import { IMatchedJobVendorAction, matchedJobVendorReducer } from "../../action/vendor/VendorMatchedJobAction";



export const MatchedJobVendorDispatcherContext = React.createContext<React.Dispatch<IMatchedJobVendorAction> | null>(null);
export const MatchedJobVendorStateContext = React.createContext<IMatchedJobVendorManagementState | null>(null);

export interface IMatchedJobVendorManagementProps extends RouteComponentProps<any> { }

export interface IMatchedJobVendorManagementState {
    visible:boolean;
    value:number;
    matchedJobVendor:matchedJobVendorViewModel
}

export const initialMatchedJobVendorManagementState = {
    visible:false,
    value:0,
    matchedJobVendor:{} as matchedJobVendorViewModel,
} as IMatchedJobVendorManagementState;

export const MatchedJobVendorContextProvider: React.FC = ({ children }) => {
    const [matchedJobVendorState, dispatcher] = useImmerReducer(matchedJobVendorReducer, initialMatchedJobVendorManagementState);

    return (
        <MatchedJobVendorDispatcherContext.Provider value={dispatcher}>
            <MatchedJobVendorStateContext.Provider value={matchedJobVendorState}>
                {children}
            </MatchedJobVendorStateContext.Provider>
        </MatchedJobVendorDispatcherContext.Provider>
    )
}