import React from "react";
import {
  GetAdminPermission,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import {
  getAdminLoggedUserId,
  getAdminMyProfileBasicInfo,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import { AdminContextProvider } from "../../../context/admin/AdminMyProfile";
import { MyUserContextProvider } from "../../../context/admin/MyUserContext";
import {
  BankDetailsContextProvider,
  CandidateContextProvider,
  CandidateExperienceContextProvider,
  CandidateLanguageMapsContextProvider,
  CandidateReferencesContextProvider,
  CandidateRelativesContextProvider,
  CandidateSkillsContextProvider,
  EducationQualificationContextProvider,
  PassportInformationContextProvider,
  SocialAccountContextProvider,
  TrainingContextProvider,
} from "../../../context/candidate/CandidateMyProfile";
import { MyProfileAdminContextProvider } from "../../../context/MyProfileAdminContext";
import { VendorContextProvider } from "../../../context/vendor/VendorMyProfile";
import { AdminPermissionRequestModel } from "../../../models/admin/AdminMyJob";
import AuthService from "../../../services/AuthService";
import AdminLayoutComponent from "./AdminLayoutComponent";
import { useAdminProfileImageContext } from "../../../context/admin/AdminMyProfile";

interface IAdminLayoutContainerProps {}

interface IAdminLayoutContainerState {}

const initialState = {};

const AdminLayoutContainer: React.FC<IAdminLayoutContainerProps> = (props) => {
  const [
    AdminLayoutContainerState,
    setAdminLayoutContainerState,
  ] = React.useState<IAdminLayoutContainerState>(initialState);
  const authorizationToken = AuthService.accessToken;
  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const {
    //myProfile,
    basicInfo,
    loggedAdminId,
  } = myProfileAdminContext;
  let user = AuthService.currentUser;
  const myAdminProfileDispatcher = useAdminMyProfileDispatcher();

  const adminProfileImageContext = useAdminProfileImageContext();
  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const { adminProfileImage, getAdminProfileImage } = adminProfileImageContext;

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null) {
      (async () => {
        await getAdminLoggedUserId(
          myAdminProfileDispatcher,
          parseInt(user?.id),
          authorizationToken
        );
      })();
      (async () => {
        await getAdminMyProfileBasicInfo(
          myProfileAdminDispatcher,
          authorizationToken
        );
      })();
    }
  }, [authorizationToken]);

  React.useEffect(() => {
    //console.log("adminlayoutsidebar outside if", loggedAdminId);
    if (loggedAdminId) {
      //console.log("adminlayoutsidebar inside if", loggedAdminId);
      getAdminProfileImage({
        AdminId: Number(loggedAdminId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [loggedAdminId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await GetAdminPermission(
          adminMyJobDispatcher,
          {
            AdminId: loggedAdminId,
          } as AdminPermissionRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedAdminId]);

  //console.log(101022, basicInfo);

  return (
    <>
      <MyUserContextProvider>
        <MyProfileAdminContextProvider>
          <AdminContextProvider>
            <VendorContextProvider>
              <BankDetailsContextProvider>
                <CandidateSkillsContextProvider>
                  <TrainingContextProvider>
                    <CandidateExperienceContextProvider>
                      <EducationQualificationContextProvider>
                        <PassportInformationContextProvider>
                          <CandidateReferencesContextProvider>
                            <CandidateRelativesContextProvider>
                              <CandidateLanguageMapsContextProvider>
                                <SocialAccountContextProvider>
                                  <CandidateContextProvider>
                                    <AdminLayoutComponent
                                      basicInfo={basicInfo}
                                    />
                                  </CandidateContextProvider>
                                </SocialAccountContextProvider>
                              </CandidateLanguageMapsContextProvider>
                            </CandidateRelativesContextProvider>
                          </CandidateReferencesContextProvider>
                        </PassportInformationContextProvider>
                      </EducationQualificationContextProvider>
                    </CandidateExperienceContextProvider>
                  </TrainingContextProvider>
                </CandidateSkillsContextProvider>
              </BankDetailsContextProvider>
            </VendorContextProvider>
          </AdminContextProvider>
        </MyProfileAdminContextProvider>
      </MyUserContextProvider>
    </>
  );
};
export default AdminLayoutContainer;
