import React from "react";
import CoverLetterLayoutComponent from "./CoverLetterLayOutComponent";

interface ICoverLetterLayoutContainerProps { }

interface ICoverLetterLayoutContainerState { }

//const initialState = {};

const CoverLetterLayoutContainer: React.FC<ICoverLetterLayoutContainerProps> = (props) => {
  // const [coverLetterLayoutContainerState, setCoverLetterLayoutContainerState] = React.useState<ICoverLetterLayoutContainerState>(
  //   initialState
  // );

  return (
    <>
      <CoverLetterLayoutComponent />
    </>
  );
};
export default CoverLetterLayoutContainer;
