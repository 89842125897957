import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";

interface ReactSelectOption {
  value: string,
  label: string
}


function Experience(props: any) {

  const { ResumeTemplateSelectBoxData, setExperiences } = props;

  let experiences: ReactSelectOption[] = [];

  if (!_.isEmpty(ResumeTemplateSelectBoxData.experienceTypes)) {

    experiences = ResumeTemplateSelectBoxData.experienceTypes.map((e: any) => {
      return { value: e["value"], label: e["caption"] };
    });
  }

  return (
    <React.Fragment>
      <div className="heading_sec1">
        <h1>Experience In Years</h1>
      </div>
      <div className="section_box4">
        {experiences.filter(f => f.value != "0").map((e: any, i: any) =>
          <div className="col-xs-4">
            <input id={e.value} type="radio" name="radioExperience" defaultValue={e.value} onClick={() => { setExperiences(e.value); }} /><label htmlFor={e.value}><span><span /></span> {e.label}</label>
          </div>

        )}

      </div>
    </React.Fragment>
  );

};
export default Experience;