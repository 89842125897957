import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import {
  VendorApprovalViewModel,
  VendorApprovalSaveRespondModel,
  VendorResultModel,
  VendorSelectBoxDataViewModel,
  VendorStatusListResultModel,
} from "../../models/admin/VendorApproval";
import {
  IVendorApprovalAction,
  vendorApprovalReducer,
} from "../../action/admin/VendorApprovalAction";

export const VendorApprovalDispatcherContext = React.createContext<React.Dispatch<IVendorApprovalAction> | null>(
  null
);
export const VendorApprovalStateContext = React.createContext<IVendorApprovalManagementState | null>(
  null
);

export interface IVendorApprovalManagementProps
  extends RouteComponentProps<any> {}

export interface IVendorApprovalManagementState {
  visible: boolean;
  value: number;
  vendorApproval: VendorApprovalViewModel;
  VendorResultModel: VendorResultModel;
  vendorApprovalSaveRespond: VendorApprovalSaveRespondModel;
  VendorSelectBoxDataViewModel: VendorSelectBoxDataViewModel;
  VendorStatusListResult: VendorStatusListResultModel;
}

export const initialVendorApprovalManagementState = {
  visible: false,
  value: 0,
  vendorApproval: {} as VendorApprovalViewModel,
  VendorResultModel: {} as VendorResultModel,
  vendorApprovalSaveRespond: {} as VendorApprovalSaveRespondModel,
  VendorSelectBoxDataViewModel: {} as VendorSelectBoxDataViewModel,
  VendorStatusListResult: {} as VendorStatusListResultModel,
} as IVendorApprovalManagementState;

export const VendorApprovalContextProvider: React.FC = ({ children }) => {
  const [vendorApprovalState, dispatcher] = useImmerReducer(
    vendorApprovalReducer,
    initialVendorApprovalManagementState
  );

  return (
    <VendorApprovalDispatcherContext.Provider value={dispatcher}>
      <VendorApprovalStateContext.Provider value={vendorApprovalState}>
        {children}
      </VendorApprovalStateContext.Provider>
    </VendorApprovalDispatcherContext.Provider>
  );
};
