import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  getAllGoogleSlideUrl,
  getAllGoogleSlideUrlSave,
  getCareerTipsApprovalListWithOutToken,
  useCareerTipsApprovalContext,
  useCareerTipsApprovalDispatcher,
} from "../../../action/admin/CareerTipsApprovalAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import { CareerTipsApprovalRequestModel } from "../../../models/admin/CareerTipsApproval";
import AuthService from "../../../services/AuthService";
import SelectOption from "../../candidate/my_profile/components/SelectOption";

interface IGoogleSideUrlState {
  url: string;
  careerTipsId: string;
}

const GoogleSideUrl = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [url, setUrl] = useState<string>("gvgv");
  const defaultValues = {
    url: "",
    careerTipsId: "0",
  };
  const {
    register,
    errors,
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm<IGoogleSideUrlState>({ defaultValues });

  const careerTipsApprovalDispatcher = useCareerTipsApprovalDispatcher();
  const careerTipsApprovalContext = useCareerTipsApprovalContext();
  const {
    careerTipsApproval,
    careerTipsCategory,
    careerTipsApprovalWithOutToken,
    careerTipsApprovalSaveRespond,
    googleSlideUrlList,
    googleSlideUrlSave,
  } = careerTipsApprovalContext;
  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;

  const myAdminProfileDispatcher = useAdminMyProfileDispatcher();
  const myAdminProfileContext = useAdminMyProfileContext();
  const {
    basicInfo,
    loggedAdminId,
    // myProfile,
    myProfileProgressBar,
    profileImage,
  } = myAdminProfileContext;

  const onSubmit = (data: any) => {
    console.log(11, data);
    if (authorizationToken != null) {
      (async () => {
        await getAllGoogleSlideUrlSave(
          careerTipsApprovalDispatcher,
          {
            RowId: parseInt(data.careerTipsId),
            GoogleSlideURL: data.url,
          } as any,
          authorizationToken
        );
      })();
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      await getCareerTipsApprovalListWithOutToken(
        careerTipsApprovalDispatcher,
        {
          userId: 0,
          page: 1,
          pageSize: 60,
          searchTerm: "",
          showInactive: false,
          sortOrder: "",
        } as CareerTipsApprovalRequestModel
      );
    })();
  }, [googleSlideUrlSave]);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null)
      (async () => {
        await getAdminLoggedUserId(
          myAdminProfileDispatcher,
          parseInt(user?.id),
          authorizationToken
        );
      })();
  }, [authorizationToken]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      (async () => {
        await getAllGoogleSlideUrl(
          careerTipsApprovalDispatcher,
          {
            UserId: loggedAdminId,
            Page: 1,
            PageSize: 30,
            SearchTerm: "",
            SortOrder: "",
            ShowInactive: false,
          } as any,
          authorizationToken
        );
      })();
    }
  }, [loggedAdminId]);

  console.log(googleSlideUrlList);

  const handleCancel = () => {
    setIsOpen(false);
    setIsEdit(false);
  };

  const handleCreateClick = () => {
    setIsOpen(true);
  };

  const handleEditClick = (id: number, url: string) => {
    setId(id.toString());
    setUrl(url);

    setIsOpen(true);
    setIsEdit(true);
    // console.log(id, url);
  };

  React.useEffect(() => {
    if (isEdit) {
      setValue("careerTipsId", id);
      setValue("url", url);
    } else {
      setId("0");
      setUrl("");
    }
  }, [isEdit]);

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <div>
          <div className="row" style={{ padding: 12 }}>
            <div className="col-sm-10"></div>
            <div className="col-sm-2">
              <button
                type="button"
                style={{ float: "right" }}
                className="btn btn-info btn-rounded btn-fw"
                onClick={() => {
                  handleCreateClick();
                }}
              >
                {" "}
                Create{" "}
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title"> Google Slide url List</h4>
                  {/* <p className="card-description">
                {" "}
                Add class <code>.table-striped</code>
              </p> */}
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Index</th>
                          <th>Title</th>
                          <th>Url</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {googleSlideUrlList != undefined &&
                        googleSlideUrlList.data != undefined
                          ? googleSlideUrlList.data.map((data, index) => {
                              return (
                                <tr>
                                  <td className="py-1">{index + 1}</td>

                                  <td>{data.name}</td>
                                  <td>{data.googleSlideURL}</td>
                                  <td>
                                    {/* <button
                                      type="button"
                                      style={{ float: "right", marginRight: 3 }}
                                      className="btn btn-danger btn-rounded btn-fw"
                                    
                                    >
                                      {" "}
                                      Delete{" "}
                                    </button> */}

                                    <button
                                      type="button"
                                      style={{
                                        float: "right",
                                        marginRight: 15,
                                      }}
                                      className="btn btn-success btn-rounded btn-fw"
                                      onClick={() => {
                                        handleEditClick(
                                          data.rowId,
                                          data.googleSlideURL
                                        );
                                      }}
                                    >
                                      {" "}
                                      Edit{" "}
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          : null}

                        {/* <tr>
                      <td className="py-1">
                        <img
                          src="../../assets/images/faces-clipart/pic-2.html"
                          alt="image"
                        />
                      </td>
                      <td>Messsy Adam</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            style={{ width: "75%" }}
                            aria-valuenow={75}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </td>
                      <td>$245.30</td>
                      <td>July 1, 2015</td>
                    </tr>
                   */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              backdrop="static"
              animation={false}
              show={isOpen}
              onHide={() => {
                setIsOpen(!isOpen);
                setIsEdit(false);
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)} className="forms-sample">
                <Modal.Header closeButton>
                  <Modal.Title>Add Google Slide Url</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <label className={"required"} htmlFor="email">
                      CarrerTip
                    </label>
                    <span className="select-wrapper-sec">
                      <Controller
                        className="custom-select-sec"
                        control={control}
                        name="careerTipsId"
                        render={({ onChange, onBlur, value, name }) => (
                          <SelectOption
                            disabled={false}
                            values={
                              careerTipsApprovalWithOutToken.data != undefined
                                ? careerTipsApprovalWithOutToken.data.map(
                                    (e: any) => {
                                      return {
                                        value: e["rowId"],
                                        label: e["name"],
                                      };
                                    }
                                  )
                                : []
                            }
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name={name}
                          />
                        )}
                      />
                    </span>
                    <ErrorMessage
                      errors={errors}
                      name="careerTipsId"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="url">Google Slide Url</label>
                    <input
                      ref={register({
                        required: "Google slide url",
                      })}
                      type="text"
                      className="form-control p-input"
                      // id="url"
                      name="url"
                      aria-describedby="brandHelp"
                      placeholder="Enter name"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="url"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                </Modal.Body>
                <div className="modal-footer  m-t-30">
                  <div className="row">
                    <div className="col-lg-6">
                      <button
                        className="btn btn-success save-event waves-effect waves-light"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-lg-2">
                      <button
                        onClick={handleCancel}
                        data-dismiss="modal"
                        className="btn btn-danger waves-effect waves-light"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Modal>
            {/* ------------------------------ */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleSideUrl;
