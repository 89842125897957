import React from "react";
import CandidateMyResumeComponent from "./CandidateMyResumeComponent";




interface ICandidateMyResumeContainerProps { }

interface ICandidateMyResumeContainerState { }

//const initialState = {};

const CandidateMyResumeContainer: React.FC<ICandidateMyResumeContainerProps> = (props) => {
  // const [CandidateMyResumeContainerState, setCandidateMyResumeContainerState] = React.useState<ICandidateMyResumeContainerState>(
  //   initialState
  // );

  return (
    <>
      <CandidateMyResumeComponent />
    </>
  );
};
export default CandidateMyResumeContainer;
