import React from "react";
import {
  getAdminLoggedUserId,
  useAdminMyProfileDispatcher
} from "../../../../action/MyProfileAdminAction";
import AuthService from "../../../../services/AuthService";
import BulkSMSComponent from "./BulkSMSComponent";

const BulkSMSContainer = () => {
  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();

  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null)
      (async () => {
        await getAdminLoggedUserId(
          myProfileAdminDispatcher,
          parseInt(user.id),
          authorizationToken
        );
      })();
  }, [authorizationToken]);



  return (
    <>
      <BulkSMSComponent />
    </>
  );
};
export default BulkSMSContainer;
