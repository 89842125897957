import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  getAchievements,
  getAchievementsSelectBoxList,
  useAchievementsContext,
  useAchievementsDispatcher,
} from "../../../../action/admin/AchievementsAction";
import { AchievementsEditRequestModel } from "../../../../models/admin/Achievements";
import FileUploads from "../../../candidate/my_profile/components/FileUploads";
import SelectOption from "../../../candidate/my_profile/components/SelectOption";
import { AchievementsGetDataWithId } from "../../../../models/admin/Achievements";
import { saveAchievements } from "./../../../../apis/admin";
import _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "./../../../../context/GlobalSpinner";
import DragAndDrop from "../../../candidate/my_profile/components/DragAndDrop";

interface IAchievementsFormProps {
  isAchievementsOpen: boolean;
  handleAchievementsOpen: () => void;
  currentAchievementsId: number;
  loggedAdminId: number;
  authorizationToken: string | null;
}
interface ReactSelectOption {
  value: string;
  label: string;
}
const defaultValues = {
  selectResume: "",
  coverLetter: "",
  socialAccountTypeId: 0,
};

const AchievementsForm: React.FC<IAchievementsFormProps> = (props) => {
  const {
    handleAchievementsOpen,
    isAchievementsOpen,
    currentAchievementsId,
    loggedAdminId,
    authorizationToken,
  } = props;

  const [obj, setObj] = useState<any>({ file: null });
  const [obj2, setObj2] = useState<any>({ file: null });
  const [disable, setDisable] = useState<number>(0);
  const [disable2, setDisable2] = useState<number>(0);
  const [disable3, setDisable3] = useState<string>("");
  const [disable4, setDisable4] = useState<string>("");

  const AchievementsDispatcher = useAchievementsDispatcher();
  const AchievementsContext = useAchievementsContext();
  const {
    AchievementsResultModel,
    AchievementsSelectBoxDataViewModel,
  } = AchievementsContext;
  const globalSpinner = useContext(GlobalSpinnerContext);
  const [sizeValidate, setSizeValidate] = useState<string>("");

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  const handleDrop = (files: any) => {
    if (
      ["image/jpeg", "image/jpg", "image/png"].includes(files[0].type) === false
    ) {
      setSizeValidate("Acceptable file formats: JPEG,JPG,PNG");
      return false;
    }

    if (
      ["image/jpeg", "image/jpg", "image/png"].includes(files[0].type) &&
      files[0].size < 1000000 * 2
    ) {
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
      setSizeValidate("");
    } else {
      setSizeValidate("Image size can't be grater than 2MB");
    }
  };

  React.useEffect(() => {
    if (loggedAdminId != 0 && authorizationToken != null) {
      (async () => {
        await getAchievements(
          AchievementsDispatcher,
          {
            rowId: currentAchievementsId,
          } as AchievementsGetDataWithId,
          authorizationToken
        );
      })();
    }
  }, [currentAchievementsId]);

  React.useEffect(() => {
    if (1 && authorizationToken != null) {
      (async () => {
        await getAchievementsSelectBoxList(
          AchievementsDispatcher,
          authorizationToken
        );
      })();
    }
  }, []);

  React.useEffect(() => {
    //console.log("----AchievementsResultModel----", AchievementsResultModel);
    if (AchievementsResultModel.data != null) {
      setValue3("rowId", AchievementsResultModel.data["rowId"]);
      setValue3("achievementId", AchievementsResultModel.data["achievementId"]);
      setValue3(
        "achievementTitle",
        AchievementsResultModel.data["achievementTitle"]
      );
      setValue3(
        "achievementDocument",
        AchievementsResultModel.data["achievementDocument"]
      );
      setValue3("description", AchievementsResultModel.data["description"]);
      setValue3("isShow", AchievementsResultModel.data["isShow"]);
      setValue3("userId", loggedAdminId);
      setValue3("isActive", AchievementsResultModel.data["isActive"]);
    }
  }, [AchievementsResultModel.data, currentAchievementsId]);

  //console.log("AchievementsResultModel", AchievementsResultModel);
  //console.log("loggedAdminId", loggedAdminId);

  const handleAchievements = (data: any) => {
    //console.log(33, data);
    if (authorizationToken != null) {
      globalSpinner.showSpinner();
      const formData = new FormData();
      if (currentAchievementsId > 0) {
        formData.append("RowId", String(currentAchievementsId));
      } else {
        formData.append("RowId", String(0));
      }

      formData.append("AchievementTitle", String(data["achievementTitle"]));
      formData.append("Document", obj.file);
      if (AchievementsResultModel.data != null) {
        formData.append(
          "AchievementDocument",
          String(AchievementsResultModel.data["achievementDocument"])
        );
      }
      formData.append("Description", String(data["description"]));
      formData.append("IsShow", data["isShow"]);
      formData.append("UserId", String(loggedAdminId));
      formData.append("IsActive", JSON.stringify(true));

      saveAchievements(formData).then((res: any) => {
        //console.log(res);
        if (res.data.isSuccess) {
          globalSpinner.hideSpinner();
          toast.success("Achievement updated successfully.");
        } else {
          globalSpinner.hideSpinner();
          toast.error("Error:Achievement not saved or updated.");
        }
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setDisable(0);
    setDisable2(0);
    setDisable3("");
    handleAchievementsOpen();
  };

  return (
    <>
      <ToastContainer />
      <div className=" ">
        <div id="create_jobdescription" className="modal fade none-border">
          <Modal show={isAchievementsOpen} onHide={handleClose}>
            <form onSubmit={handleSubmit3(handleAchievements)} noValidate>
              <Modal.Header closeButton>
                <Modal.Title> Add or Edit a Achievement</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="email">Name</label>
                  <input
                    type="text"
                    name="achievementTitle"
                    className="form-control"
                    placeholder="Enter Your Achievement's Title"
                    ref={register3({
                      required: "Achievement's Title is required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors3}
                    name="achievementTitle"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
                <div className="clearfix"></div>
                <div className="form-group">
                  <label htmlFor="email">Description</label>
                  <textarea
                    name="description"
                    className={"form-control form-contro11"}
                    placeholder="Type here"
                    ref={register3({
                      required: "Career tips description cannot be blank.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors3}
                    name="description"
                    render={({ message }) => (
                      <div className="register_validation">{message}</div>
                    )}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email"></label>
                  <div style={{ paddingTop: "6px" }}>
                    <input
                      id="checkbox1"
                      type="checkbox"
                      name="isShow"
                      ref={register3}
                      className="_cursor-pointer"
                    />
                    <label htmlFor="checkbox1">
                      <span className="_add-experience-checkbox _cursor-pointer"></span>{" "}
                      &nbsp;
                    </label>
                    <span>Need to show in home page?</span>
                  </div>
                </div>
                <div className="form-group">
                  <FileUploads
                    accept=".jpg,.jpeg,.png"
                    onChange={(file: any) => {
                      handleDrop([file]);
                    }}
                    name="Upload Career Tips"
                    disabled={disable3 !== "" ? true : false}
                  />
                  <DragAndDrop handleDrop={handleDrop}>
                    {obj.file ? (
                      <div
                        className="update_con"
                        style={{ width: 268, height: 105 }}
                      >
                        {obj.file.name}
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="update_con">
                          Acceptable file formats: JPEG,JPG,PNG
                        </div>
                        <div className="update_con">
                          Maximum file size: 2 MB.{" "}
                        </div>
                      </React.Fragment>
                    )}
                  </DragAndDrop>
                  <h6 style={{ color: "red", marginLeft: 225 }}>
                    {sizeValidate}
                  </h6>
                </div>
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default AchievementsForm;
