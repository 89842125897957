import React, { useState, useContext } from "react";
import { environment } from "../../../../environments/environment";
import { getResumeTemplates } from "../../../../apis/resumebuilder";
import { GlobalSpinnerContext } from "../../../../context/GlobalSpinner";

// interface templateList {
//   RowId: number;
//   ResumeTemplateId: number;
//   Title: "string";
//   Description: "string";
//   ResumeContent: "string";
//   ResumeImage: "string";
//   IsActive: true;
// }

function Template(props: any) {
  const {
    // ResumeTemplates,
    setResumeSelectTemplateComponentState,
    resumeSelectTemplateComponentState,
    expertise,
    experience,
    level,
    // setResumeTemplates
  } = props;
  // const [carouselValue, setCarouselValue] = useState(0);
  // const [countNext, setCountNext] = useState(0);

  // const [hasMore, setHasMore] = React.useState(true);
  // const [totalCount, setTotalCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(false);
  const currentPageSize = 8;
  const [totalPage, setTotalPage] = useState(1);
  const [resumeTemplates, setResumeTemplates] = useState<any>([]);
  const globalSpinner = useContext(GlobalSpinnerContext);

  React.useEffect(() => {
    if (currentPage == 1 && previousPage == false) {
      getResumeTemplatesData(currentPage, currentPageSize);
    }
  }, [currentPage]);

  const fetchNextData = async () => {
    if (currentPage >= totalPage) {
      // setHasMore(false);
      return;
    } else {
      setCurrentPage(currentPage + 1);
      getResumeTemplatesData(currentPage + 1, currentPageSize);
    }
  };

  const fetchPreviousData = async () => {
    if (currentPage <= 1) {
      // setHasMore(false);
      return;
    } else {
      if (currentPage - 1 == 1) {
        setPreviousPage(true);
      }
      setCurrentPage(currentPage - 1);
      getResumeTemplatesData(currentPage - 1, currentPageSize);
    }
  };

  const getResumeTemplatesData = (getPage: any, getPageSize: any) => {
    globalSpinner.showSpinner();
    getResumeTemplates({
      CandidateId: 0,
      FieldOfExpertiseId: [Number(expertise)],
      ExperienceTypeId: [Number(experience)],
      DesignationId: [Number(level)],
      PageIndex: getPage,
      PageSize: getPageSize,
      ShowInactive: false,
    })
      .then((res: any) => {
        globalSpinner.hideSpinner();
        if (getPage == 1) {
          setTotalPage(Math.ceil(res.data.total / currentPageSize));
          // setTotalCount(res.data.total);
          // setCarouselValue(0);
        }
        setResumeTemplates(res.data.data);
        // else {
        //   // setResumeTemplates([...ResumeTemplates, ...res.data.data]);

        // }
      })
      .catch((err: any) => {
        globalSpinner.hideSpinner();
        // //console.log(err);
      });
  };
  const handleResumeTemplateClick = (item: any) => {
    setResumeSelectTemplateComponentState({
      rowId: item.rowId,
      resumeTemplateId: item.resumeTemplateId,
      title: item.title,
      resumeContent: item.resumeContent,
      resumeImage:
        environment.baseUrl + "/Upload/ResumeImage/" + item.resumeImage,
      description: item.description,
    });
  };

  const handleCarouselNextClick = () => {
    // let num =
    //   ResumeTemplates.data != undefined ? ResumeTemplates.data.length : 0;
    // let value = num / 4;
    // if (countNext < value && value >= 1) {
    //   setCountNext(countNext + 1);
    //   setCarouselValue(carouselValue - 225); //206
    // } else {
    //   setCountNext(0);
    //   setCarouselValue(0);
    // }
    //call api()
    fetchNextData();
  };

  const handleCarouselPreviousClick = () => {
    // if (countNext > 0) {
    //   setCountNext(countNext + 1);
    //   setCarouselValue(carouselValue + 225); //206
    // } else {
    //   setCountNext(0);
    //   setCarouselValue(0);
    // }
    fetchPreviousData();
  };

  return (
    <React.Fragment>
      {/*=================Content Section Start================*/}
      <div className="col-sm-4">
        <div>
          <img
            src={resumeSelectTemplateComponentState.resumeImage}
            className="img-responsive"
          />
        </div>
        <div>
          <img
            src={require("./../../../../images/shadow.jpg")}
            className="img-responsive"
          />
        </div>
      </div>
      <div className="col-sm-8">
        <div className="section_box4">
          <div className="select_templates">
            <div className="row">
              {/* {ResumeTemplates.data != undefined && ResumeTemplates.data.map((e: any, i: any) =>

                <div className="col-sm-3">
                  <a onClick={() => handleResumeTemplateClick(e)}
                  ><img src={`https://careerapp.azurewebsites.net/Upload/ResumeImage/${e.resumeImage}`} className=" img-responsive" /> </a></div>
                // {e.description}
              )} */}
              <div className="prodict_list">
                <div
                  id="owl-demo1"
                  className="owl-carousel owl-theme"
                  style={{ opacity: 1, display: "block" }}
                >
                  <div className="owl-wrapper-outer">
                    <div
                      className="owl-wrapper"
                      style={{
                        // width: "2568px",
                        left: "0px",
                        display: "block",
                        transition: "all 0ms ease 0s",
                        transform: `translate3d(0px, 0px, 0px)`,
                        // transform: `translate3d(${carouselValue}px, 0px, 0px)`,
                      }}
                    >
                      {resumeTemplates != undefined &&
                        resumeTemplates.map((e: any, i: any) => (
                          <div className="owl-item" style={{ width: "200px" }}>
                            <div className="item">
                              <div className="matched_jobs_sec">
                                <div
                                  className="matched_jobs"
                                  onClick={() => handleResumeTemplateClick(e)}
                                >
                                  <img
                                    src={`${
                                      environment.baseUrl +
                                      "/Upload/ResumeImage/" +
                                      e.resumeImage
                                    }`}
                                    alt=""
                                    className="image2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div
                    className="owl-controls clickable"
                    style={{ display: "none" }}
                  >
                    <div className="owl-pagination">
                      <div className="owl-page active">
                        <span className="" />
                      </div>
                    </div>
                    <div className="owl-buttons">
                      <div
                        className="owl-prev"
                        onClick={handleCarouselPreviousClick}
                      >
                        <img
                          src={require("../../../../images/products_ar1.png")}
                        />
                      </div>
                      <div
                        className="owl-next"
                        onClick={handleCarouselNextClick}
                      >
                        <img
                          src={require("../../../../images/products_ar2.png")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*=================Content Section End================*/}
    </React.Fragment>
  );
}

export default Template;
