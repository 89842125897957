import { ErrorMessage } from "@hookform/error-message";
import { message, Pagination, Popconfirm } from "antd";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  AddFakeCandidate,
  DeleteFakeCandidate,
  EditFakeCandidate,
  GetAllFakeCandidateList,
  useClientJobApprovalContext,
  useClientJobApprovalDispatcher,
} from "../../../action/admin/ClientJobApprovalAction";
import {
  getTitleWithCategoryId,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import { useVendorMyProfileContext } from "../../../action/MyProfileVendorAction";
import {
  ClientFakeCandidateAllRequestModel,
  ClientFakeCandidateRequestModel,
} from "../../../models/admin/ClientJobApproval";
import { jobAlertTitleDropdownResultModel } from "../../../models/candidate/JobAlert";
import { categoryWithId } from "../../../models/candidate/JobCategory";
import AuthService from "../../../services/AuthService";
import SelectOption from "../../candidate/my_profile/components/SelectOptionForSearch";

interface ReactSelectOption {
  value: string;
  label: string;
}

interface IResourceAvailability {
  tempData: jobAlertTitleDropdownResultModel[];
}

const defaultValues = {
  jobTypeData: "",
  countryCode: "",
  mobileNumber: "",
  firstName: "",
  jobCategorys: "",
  tempData: [],
  jobTitle: "",
  jobStatus: "",
  assignTo: "",
};

const ResourceAvailability = () => {
  let jobCategoryData: ReactSelectOption[] = [];
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const [
    resourceAvailabilityState,
    setResourceAvailabilityState,
  ] = React.useState<IResourceAvailability>(defaultValues);

  const { tempData } = resourceAvailabilityState;

  const myVendorProfileContext = useVendorMyProfileContext();
  const {
    basicInfo,
    // loggedUserId,
    myProfile,
    myProfileProgressBar,
    profileImage,
  } = myVendorProfileContext;

  const jobAlertContext = useJobAlertContext();
  const {
    jobAlert,
    jobAlertGetAll,
    jobAlertSaveRespond,
    jobAlertTitleDropdownResult,
  } = jobAlertContext;

  const clientJobApprovalDispatcher = useClientJobApprovalDispatcher();
  const clientJobApprovalContext = useClientJobApprovalContext();
  const {
    ClientAllFakeCandidateResul,
    ClientFakeCandidateAddResult,
    ClientFakeCandidateDeleteResult,
    ClientFakeCandidateEditResult,
  } = clientJobApprovalContext;

  console.log(888, ClientFakeCandidateEditResult);

  const watchAllFields = watch2();
  const authorizationToken = AuthService.accessToken;
  const jobAlertDispatcher = useJobAlertDispatcher();

  const [currentRowId, setCurrentcurrentRowId] = useState<number>(0);

  const myAdminProfileDispatcher = useAdminMyProfileDispatcher();
  const myAdminProfileContext = useAdminMyProfileContext();
  const {
    loggedAdminId,
    // myProfile,
  } = myAdminProfileContext;
  let user = AuthService.currentUser;

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null)
      (async () => {
        await getAdminLoggedUserId(
          myAdminProfileDispatcher,
          parseInt(user?.id),
          authorizationToken
        );
      })();
  }, [authorizationToken]);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null) {
      (async () => {
        await GetAllFakeCandidateList(
          clientJobApprovalDispatcher,
          {
            Page: 1,
            UserId: parseInt(user?.id),
            PageSize: 100,
            SearchTerm: "",
            ShowInactive: false,
            SortOrder: "",
          } as ClientFakeCandidateAllRequestModel,
          authorizationToken
        );
      })();
    }
  }, [
    authorizationToken,
    ClientFakeCandidateAddResult,
    ClientFakeCandidateDeleteResult,
  ]);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null) {
      setCurrentcurrentRowId(
        ClientFakeCandidateEditResult.rowId != undefined
          ? ClientFakeCandidateEditResult.rowId
          : 0
      );
      setValue2(
        "jobCategorys",
        ClientFakeCandidateEditResult.categoryId != undefined
          ? ClientFakeCandidateEditResult.categoryId.toString()
          : ""
      );
      setValue2(
        "jobTitle",
        ClientFakeCandidateEditResult.jobTitle != undefined
          ? ClientFakeCandidateEditResult.jobTitle
          : ""
      );
      setValue2(
        "experience",
        ClientFakeCandidateEditResult.experience
          ? ClientFakeCandidateEditResult.experience
          : null
      );
      setValue2(
        "NoOfCandidates",
        ClientFakeCandidateEditResult.noofCandidates != undefined
          ? ClientFakeCandidateEditResult.noofCandidates
          : null
      );
      setValue2(
        "location",
        ClientFakeCandidateEditResult != undefined
          ? ClientFakeCandidateEditResult.location
          : ""
      );
    }
  }, [ClientFakeCandidateEditResult]);

  React.useEffect(() => {
    setResourceAvailabilityState((resourceAvailabilityState) => {
      return {
        ...resourceAvailabilityState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);
  console.log(1213, tempData);
  React.useEffect(() => {
    //console.log(1213, watchAllFields.jobCategorys);
    //console.log(1212, authorizationToken);

    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null) {
      setCurrentcurrentRowId(0);
      setValue2("jobCategorys", "");
      setValue2("jobTitle", "");
      setValue2("experience", null);
      setValue2("NoOfCandidates", null);
      setValue2("location", "");
    }
  }, [ClientFakeCandidateAddResult]);

  const onSubmit = (data: any) => {
    console.log(33, data);
    if (authorizationToken != null) {
      (async () => {
        await AddFakeCandidate(
          clientJobApprovalDispatcher,
          {
            AdminId: loggedAdminId,
            CategoryId: parseInt(data.jobCategorys),
            Experience: parseInt(data.experience),
            IsActive: true,
            JobTitleId: 1,
            JobTitle: data.jobTitle,
            Location: data.location,
            NoofCandidates: parseInt(data.NoOfCandidates),
            JobTypeId: 0,
            RowId: currentRowId,
          } as ClientFakeCandidateRequestModel,
          authorizationToken
        );
      })();
    }
  };

  const handleEdit = (id: any) => {
    if (authorizationToken != null) {
      (async () => {
        await EditFakeCandidate(
          clientJobApprovalDispatcher,
          {
            RowId: id,
          } as any,
          authorizationToken
        );
      })();
    }
  };

  const handleDelete = (id: any) => {
    if (authorizationToken != null) {
      (async () => {
        await DeleteFakeCandidate(
          clientJobApprovalDispatcher,
          {
            RowId: [id],
          } as any,
          authorizationToken
        );
      })();
    }
  };

  function cancel(e: any) {
    console.log(e);
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="col-sm-12 col-lg-12" style={{ marginTop: 50 }}>
            <div className="section_box3">
              <form onSubmit={handleSubmit2(onSubmit)} noValidate>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email" className="required">
                        Job Category
                      </label>
                      <span className="select-wrapper-sec">
                        <Controller
                          control={control2}
                          name="jobCategorys"
                          rules={{
                            required: "Job Category is required",
                          }}
                          // value=''
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={
                                myProfile.jobCategories != undefined
                                  ? myProfile.jobCategories.map((e: any) => {
                                      return {
                                        value: e["value"],
                                        label: e["caption"],
                                      };
                                    })
                                  : jobCategoryData
                              }
                              disabled={false}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                              nameForLablel="Job Category"
                            />
                          )}
                        />
                      </span>
                      <ErrorMessage
                        errors={errors2}
                        name="jobCategorys"
                        render={({ message }) => (
                          <div className="register_validation">{message}</div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email" className="required">
                        Job Title
                      </label>
                      <span className="select-wrapper-sec">
                        <Controller
                          control={control2}
                          name="jobTitle"
                          rules={{
                            required: "Job Title is required",
                          }}
                          // value=''
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={
                                tempData != undefined
                                  ? tempData.map((e) => {
                                      return {
                                        value: e["value"],
                                        label: e["caption"],
                                      };
                                    })
                                  : tempData
                              }
                              disabled={false}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                              nameForLablel="Job Title"
                            />
                          )}
                        />
                      </span>

                      <ErrorMessage
                        errors={errors2}
                        name="jobTitle"
                        render={({ message }) => (
                          <div className="register_validation">{message}</div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Experience</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type here"
                        name="experience"
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">No.Of Candidates</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type here"
                        name="NoOfCandidates"
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-6">
                    <div className="form-group" style={{ marginLeft: 120 }}>
                      <label htmlFor="email">Job Location</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type here"
                        name="location"
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>

                  <div className="col-sm-2"></div>
                </div>
                <div className="row">
                  <div className="col-sm-4"></div>
                  <div className="col-sm-1"></div>
                  <div className="col-sm-2">
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="submit"
                      // onClick={() => {
                      //   setPublish(false);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-sm-1"></div>
                  <div className="col-sm-4"></div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-12 col-lg-12" style={{ marginTop: 10 }}>
            <div className="section_box3">
              <div className="section_box4">
                <div className="table-responsive">
                  <table className="table table-hover table-bordered dataTable no-footer dtr-inline">
                    <thead>
                      <tr>
                        <th />
                        <th>Job Title</th>
                        <th>Job Category</th>
                        <th>Job Experience</th>
                        <th>No.Of Candidates</th>
                        <th>Location</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {/*  */}
                      {ClientAllFakeCandidateResul.data != undefined
                        ? ClientAllFakeCandidateResul.data.map(
                            (data, index) => {
                              return (
                                <tr key={1}>
                                  <td>
                                    <input
                                      id="checkbox2"
                                      type="checkbox"
                                      name="checkbox"
                                      defaultValue={1}
                                    />
                                    <label htmlFor="checkbox2">
                                      <span />
                                    </label>
                                  </td>
                                  <td>{data.jobTitle} </td>
                                  <td>{data.categoryName}</td>
                                  <td>{data.experience}</td>
                                  <td>{data.noofCandidates}</td>
                                  <td>{data.location}</td>
                                  <td className="table_con_edit">
                                    <a
                                      onClick={() => handleEdit(data.rowId)}
                                      data-target="#add_document"
                                      data-toggle="modal"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <Popconfirm
                                      title="Are you sure to delete this Info?"
                                      onConfirm={() => handleDelete(data.rowId)}
                                      onCancel={cancel}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <a>
                                        <i
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                        />
                                      </a>
                                    </Popconfirm>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-12" style={{ marginTop: 10 }}>
            <Pagination
              total={
                ClientAllFakeCandidateResul.data != undefined
                  ? ClientAllFakeCandidateResul.total
                  : 0
              }
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceAvailability;
