import React from "react";
import CoverLetterSelectTemplateComponent from "./CoverLetterSelectTemplateComponent";

interface ICoverLetterSelectTemplateContainerProps { }

interface ICoverLetterSelectTemplateContainerState { }

// const initialState = {};

const CoverLetterSelectTemplateContainer: React.FC<ICoverLetterSelectTemplateContainerProps> = (props) => {
  // const [coverLetterSelectTemplateContainerState, setCoverLetterSelectTemplateContainerState] = React.useState<ICoverLetterSelectTemplateContainerState>(
  //   initialState
  // );

  return (
    <>
      <CoverLetterSelectTemplateComponent />
    </>
  );
};
export default CoverLetterSelectTemplateContainer;
