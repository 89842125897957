import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Link,
  withRouter,
  useRouteMatch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import {
  GetAllJobNotifyList,
  useJobNotifyContext,
  useJobNotifyDispatcher,
} from "../../../action/candidate/JobNotifyAction";
import { useMyProfileContext } from "../../../action/MyProfileAction";
import { getCandidates } from "../../../apis/candidate";
import {
  useCandidateContext,
  useCandidateProfileImageContext,
} from "../../../context/candidate/CandidateMyProfile";
import { environment } from "../../../environments/environment";
import { NotifyJobRequestModel } from "../../../models/candidate/JobNotify";
import AuthService from "./../../../services/AuthService";

function CandidateLayoutSidebar(props: any) {
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname != null ? location.pathname : "/candidate"
  );

  const [menuHide, setMenuHide] = useState(false);
  const [name, setName] = useState("");

  const back = () => {
    //console.log(location);
    props.history.replace(location.pathname);
    return <Redirect to="/" />;
  };

  const candidateProfileImageContext = useCandidateProfileImageContext();

  const {
    candidateProfileImage,
    getCandidateProfileImage,
  } = candidateProfileImageContext;

  const myProfileContext = useMyProfileContext();
  const { myProfile, loggedUserId, profileImage, basicInfo } = myProfileContext;
  const authorizationToken = AuthService.accessToken;
  const user = AuthService.currentUser;

  const candidateContext = useCandidateContext();
  const { getCandidates, candidateState } = candidateContext;

  const [currentWidth, setCurrentCurrentWidth] = useState<number>(
    window.innerWidth
  );

  const jobNotifyDispatcher = useJobNotifyDispatcher();
  const jobNotifyContext = useJobNotifyContext();
  const { notifyJobRespond } = jobNotifyContext;

  const candidateId = loggedUserId;

  React.useEffect(() => {
    if (candidateId) {
      getCandidateProfileImage({
        CandidateId: Number(candidateId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });
    }
  }, [candidateId]);

  React.useEffect(() => {
    if (candidateId)
      (async () => {
        await getCandidates({
          CandidateId: Number(candidateId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "",
          SortOrder: "",
          ShowInactive: false,
        });
      })();
  }, [candidateId]);

  React.useEffect(() => {
    if (candidateState.data != undefined && candidateState.data.length > 0) {
      setName(candidateState.data[0]["firstName"]);
    }
  }, [candidateState]);

  const onLogout = () => {
    AuthService.logout();
    props.history.push("/");
  };

  const navigation = (url: any) => {
    props.history.push(url);
    if (currentWidth < 650) {
      setMenuHide(!menuHide);
    }
  };

  const cl = props.location.pathname;

  //console.log(111111111111111, name.split(" ")[0]);
  //console.log(222222222222222, window.innerWidth);

  React.useEffect(() => {
    setCurrentCurrentWidth(window.innerWidth);
  }, [window.innerWidth]);

  React.useEffect(() => {
    if (authorizationToken !== null && loggedUserId !== 0) {
      (async () => {
        await GetAllJobNotifyList(
          jobNotifyDispatcher,
          {
            CandidateId: loggedUserId,
            DigiDocumentTypeId: 1,
            PageIndex: 1,
            PageSize: 100,
            ShowInactive: false,
          } as NotifyJobRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  return (
    <>
      <div className="icon-mobile-nav">
        <a
          onClick={() => {
            setMenuHide(!menuHide);
          }}
        >
          <i className="fa fa-2x fa-reorder" />
        </a>
      </div>
      <div className="menu_main">
        <div className={`left-menu  ${menuHide ? "hide-menu" : ""}`}>
          <div className="dash_logo">
            <img
              src={require("./../../../images/logo_dash.png")}
              className="img-responsive center-block"
            />
          </div>
          <div className="dash_profile">
            {/* <img
            src={
              profileImage != null && profileImage.total > 0
                ? `https://localhost:44361/Upload/ProfileImage/${profileImage.data[0].imageUrl}`
                : require("../../../images/profileDefault1.jpg")
            }
            className="img-responsive"
          /> */}

            {candidateProfileImage.data.length > 0 ? (
              <img
                className="img-responsive"
                src={
                  environment.baseUrl +
                  `/Upload/ProfileImage/${candidateProfileImage.data[0]["imageUrl"]}`
                }
                alt=""
              />
            ) : (
              <img
                className="img-responsive"
                src={require("./../../../images/profileDefault1.jpg")}
              ></img>
            )}
          </div>
          <div className="dash_profile_name">
            {candidateState.data != undefined && candidateState.data.length > 0
              ? candidateState.data[0]["firstName"]
              : ""}
            {/* {basicInfo.fullName != undefined ? basicInfo.fullName : ""} */}
          </div>
          <aside
            className="bg-black dk nav_side-xs aside hidden-print"
            id="nav"
            style={{ paddingTop: 30 }}
          >
            <section className="vbox">
              <section className="w-f-md scrollable">
                <div>
                  <div
                    data-height="auto"
                    data-disable-fade-out="true"
                    data-distance={0}
                    data-size="10px"
                    data-railopacity="0.2"
                  >
                    {/* nav */}
                    <div
                      id="divexample1"
                      style={{ overflow: "hidden" }}
                      tabIndex={0}
                    >
                      <nav className="nav_side-primary nav_pad">
                        <ul className="nav_side" data-ride="collapse">
                          <li>
                            <a
                              onClick={() => {
                                props.history.push("/");
                              }}
                              className={
                                cl == "/"
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-home" aria-hidden="true" />
                              <div className="menus">Home </div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}`);
                              }}
                              className={
                                cl == "/candidate"
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-th-large"
                                aria-hidden="true"
                              />
                              <div className="menus">Dashboard </div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/jobs-applied`);
                              }}
                              className={
                                cl.includes("/candidate/jobs-applied")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              <div className="menus">Jobs Applied</div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/digilocker/${3}`);
                              }}
                              className={
                                cl.includes("/candidate/digilocker")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-lock" aria-hidden="true" />
                              <div className="menus">
                                {/* {basicInfo.fullName != undefined
                                  ? basicInfo.fullName.split(" ")[0]
                                  : ""} */}
                                {name.split(" ")[0]}
                                's DigiLocker
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/my-resume`);
                              }}
                              className={
                                cl.includes("/candidate/my-resume")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-files-o" aria-hidden="true" />
                              <div className="menus">My Resumes</div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/messages`);
                              }}
                              className={
                                cl.includes("/candidate/messages")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-commenting-o"
                                aria-hidden="true"
                              />
                              <div className="menus">Messages</div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/saved-jobs`);
                              }}
                              className={
                                cl.includes("/candidate/saved-jobs")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-suitcase"
                                aria-hidden="true"
                              />
                              <div className="menus">Saved Jobs</div>
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigation(`${url}/notify-jobs/${0}`);
                              }}
                              className={
                                cl.includes("/candidate/notify-jobs")
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-suitcase"
                                aria-hidden="true"
                              />
                              <div className="menus">
                                Notify Jobs
                                <div
                                  style={{
                                    color: "red",
                                    marginTop: -18,
                                    marginLeft: 82,
                                  }}
                                >
                                  {notifyJobRespond.data !== undefined &&
                                  notifyJobRespond.data.length > 0
                                    ? "(" +
                                      notifyJobRespond.data[0].notViewdCount +
                                      ")"
                                    : ""}
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href={`#${url}/my-profile`}
                              className={
                                cl.includes(`${url}/my-profile`)
                                  ? "active _cursor-pointer"
                                  : "_cursor-pointer"
                              }
                            >
                              {" "}
                              <i className="fa fa-user-o" aria-hidden="true" />
                              <div className="menus">My Profile</div>
                            </a>
                          </li>

                          {/* <li> */}
                          {/* /<ul className="nav_side nav_side1" data-ride="collapse"> */}
                          <li style={{ paddingTop: "5%" }}>
                            <Link to="/help">
                              {" "}
                              <i
                                className="fa fa-question-circle"
                                aria-hidden="true"
                              />
                              <div className="menus">Help</div>
                            </Link>
                          </li>
                          <li>
                            <a onClick={onLogout} className="_cursor-pointer">
                              {" "}
                              <i
                                className="fa fa-power-off"
                                aria-hidden="true"
                              />
                              <div className="menus">Logout </div>
                            </a>
                          </li>
                          {/* </ul> */}
                          {/* </li> */}
                        </ul>
                        {/* <ul className="nav_side nav_side1" data-ride="collapse">
                          <li>
                            <Link to="/help">
                              {" "}
                              <i
                                className="fa fa-question-circle"
                                aria-hidden="true"
                              />
                              <div className="menus">Help</div>
                            </Link>
                          </li>
                          <li>
                            <a onClick={onLogout} className="_cursor-pointer">
                              {" "}
                              <i
                                className="fa fa-power-off"
                                aria-hidden="true"
                              />
                              <div className="menus">Logout </div>
                            </a>
                          </li>
                        </ul> */}
                      </nav>
                      {/* / nav */}
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </aside>
        </div>
      </div>
    </>
  );
}

export default withRouter(CandidateLayoutSidebar);
