import React, { useState } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import ResumeMyInformationContainer from "../my_Information/ResumeMyInformationContainer";
import ResumePreviewContainer from "../preview/ResumePreviewContainer";
import ResumeContainer from "../resume/ResumeContainer";
import ResumeSelectTemplateContainer from "../select_template/ResumeSelectTemplateContainer";

import {
  CandidateContextProvider,
  CandidateExperienceContextProvider,
  CandidateSkillsContextProvider,
  TrainingContextProvider,
  EducationQualificationContextProvider,
  // CandidateAchievementsContextProvider,
  CandidateLanguageMapsContextProvider,
  // CandidateReferencesContextProvider,
  CandidateProfileImageContextProvider,
  // CandidateOtherCertificateContextProvider,
} from "../../../../context/resume/CandidateMyInformation";
import AuthService from "../../../../services/AuthService";
import { updateAccessToken } from "../../../../apis/misc";

interface IResumeLayoutComponentProps {
  location: any;
}

interface IResumeLayoutComponentState {}

const initialState = {};

const ResumeLayoutComponent: React.FC<IResumeLayoutComponentProps> = (
  props
) => {
  const [
    resumeLayoutComponentState,
    setResumeLayoutComponentState,
  ] = React.useState<IResumeLayoutComponentState>(initialState);

  const { path, url } = useRouteMatch();
  let history = useHistory();
  const [active, setActive] = useState("My Information");

  const titles = [
    "My Information",
    "Select Template",
    "Preview My Resume",
    "Resume",
  ];

  React.useEffect(() => {
    const c1 = props.location.pathname;
    ////console.log(c1);
    if (c1.includes("/resume/my_information")) {
      setActive("My Information");
    } else if (c1.includes("/resume/select_template")) {
      setActive("Select Template");
    } else if (c1.includes("/resume/preview_my")) {
      setActive("Preview My Resume");
    } else if (c1.includes("/resume/Resume")) {
      setActive("Resume");
    }
  });

  let icons = new Map([
    [0, "fa fa-file-text-o"],
    [1, "fa fa-tachometer"],
    [2, "fa fa-file-text"],
    [3, "fa fa-files-o"],
  ]);

  const _titles = titles.map((e, i) => {
    var p = e.split(" ");
    return (
      <>
        <li
          key={i}
          onClick={() => {
            setActive(e);
          }}
          style={{ paddingTop: "10px" }}
        >
          <Link
            to={`${url}/${
              p[1] == undefined
                ? p[0]
                : p[0].toLowerCase() + "_" + p[1].toLowerCase()
            }`}
            className={e == active ? "active" : ""}
          >
            {" "}
            <i className={icons.get(i)} aria-hidden="true" />
            <div className="menus">{e} </div>{" "}
          </Link>
        </li>
      </>
    );
  });

  const back = () => {
    history.replace("/candidate/my-resume");
  };

  const onLogout = () => {
    updateAccessToken(
      {},
      `?id=${AuthService.currentUser?.id}&accessToken=${
        AuthService.accessToken
      }&login=${false}`
    )
      .then((res: any) => {
        //console.log("updated access token ", res.data);
      })
      .catch((err: any) => {
        //console.log("error in updating access token", err);
      });
    AuthService.logout();
    history.push("/");
  };

  return (
    <>
      <div id="wrapper">
        {/*======Left Menu Start=======*/}
        <div className="icon-mobile-nav">
          <a href="#">
            <i className="fa fa-2x fa-reorder" />
          </a>
        </div>
        <div className="menu_main">
          <div className="left-menu">
            <div className="dash_Left_arrow">
              <Link to="/candidate/my-resume">
                <i className="fa fa-2x fa-arrow-circle-left"></i>
              </Link>
            </div>
            <div className="dash_logo">
              <img
                src={require("./../../../../images/logo_dash.png")}
                //  src="images/logo_dash.png"
                className="img-responsive center-block"
              />
            </div>
            <a href="#" className="btn resume_build_btn">
              Resume Builder
            </a>
            <aside
              className="bg-black dk nav_side-xs aside hidden-print"
              id="nav"
            >
              <section className="vbox" style={{ paddingTop: "40px" }}>
                <section className="w-f-md scrollable">
                  <div>
                    <div
                      data-height="auto"
                      data-disable-fade-out="true"
                      data-distance={0}
                      data-size="10px"
                      data-railopacity="0.2"
                    >
                      {/* nav */}
                      <div
                        id="divexample1"
                        style={{ overflow: "hidden" }}
                        tabIndex={0}
                      >
                        <nav className="nav_side-primary nav_pad">
                          <ul className="nav_side" data-ride="collapse">
                            {_titles}
                            <li style={{ paddingTop: "10px" }}>
                              <Link
                                to="/candidate"
                                // onClick={back}
                              >
                                {" "}
                                <i
                                  className="fa fa-th-large"
                                  aria-hidden="true"
                                />
                                <div className="menus">Dashboard</div>
                              </Link>
                            </li>
                          </ul>
                          <ul
                            className="nav_side nav_side1"
                            data-ride="collapse"
                          >
                            <li>
                              <Link to="/help">
                                {" "}
                                <i
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                                />
                                <div className="menus">Help</div>
                              </Link>
                            </li>
                            <li>
                              <a onClick={onLogout} className="_cursor-pointer">
                                {" "}
                                <i
                                  className="fa fa-power-off"
                                  aria-hidden="true"
                                />
                                <div className="menus">Log Out </div>{" "}
                              </a>
                            </li>
                          </ul>
                        </nav>
                        {/* / nav */}
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </aside>
          </div>
        </div>
        {/*======Left Menu End=======*/}
        {/*=================Content Section Start================*/}
        <Switch>
          <Route exact path={`${path}`}>
            {/* <ResumeMyInformationContainer/>  */}
            <Redirect to={`${path}/my_information`} />
          </Route>
          <Route exact path={`${path}/preview_my`}>
            <ResumePreviewContainer selectTemplateInfo={props} />
          </Route>
          <Route exact path={`${path}/my_information`}>
            <CandidateContextProvider>
              <CandidateExperienceContextProvider>
                <CandidateSkillsContextProvider>
                  <TrainingContextProvider>
                    <EducationQualificationContextProvider>
                      <CandidateLanguageMapsContextProvider>
                        <CandidateProfileImageContextProvider>
                          <ResumeMyInformationContainer />
                        </CandidateProfileImageContextProvider>
                      </CandidateLanguageMapsContextProvider>
                    </EducationQualificationContextProvider>
                  </TrainingContextProvider>
                </CandidateSkillsContextProvider>
              </CandidateExperienceContextProvider>
            </CandidateContextProvider>
          </Route>
          <Route exact path={`${path}/select_template`}>
            <ResumeSelectTemplateContainer />
          </Route>
          <Route exact path={`${path}/resume`}>
            <ResumeContainer digiLockerVew={false} />
          </Route>
        </Switch>
        {/* <ResumeMyInformationContainer/>  */}
        {/* <ResumePreviewContainer/> */}
        {/* <ResumeSelectTemplateContainer/> */}
        {/* <ResumeContainer/> */}
        {/*=================Content Section End================*/}
      </div>
    </>
  );
};

export default withRouter(ResumeLayoutComponent);
