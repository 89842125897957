import React from "react";
import MyTeamMemberComponent from "./MyTeamMemberComponent";
import AuthService from "./../../../../services/AuthService";

interface IMyTeamMemberContainerProps { }

interface IMyTeamMemberContainerState { }

const initialState = {};

const MyTeamMemberContainer: React.FC<IMyTeamMemberContainerProps> = (props) => {
  const [MyTeamMemberContainerState, setMyTeamMemberContainerState] = React.useState<IMyTeamMemberContainerState>(
    initialState
  );
  return (
    <>
      <MyTeamMemberComponent/>
    </>
  );
};
export default MyTeamMemberContainer;
