import { ErrorMessage } from "@hookform/error-message";
import {
  Badge,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Popover,
  Radio,
  Spin,
  Tooltip,
} from "antd";
import { values } from "lodash";
import moment from "moment";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  useHistory,
  Link,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { updateDecorator } from "typescript";
import {
  getAdminLoggedUserId,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../action/MyProfileAdminAction";
import { jobAlertTitleDropdownResultModel } from "../../../models/candidate/JobAlert";
import { categoryWithId } from "../../../models/candidate/JobCategory";
import AuthService from "../../../services/AuthService";
import SelectOption from "../../candidate/my_profile/components/SelectOption";
import { useNotificationListContext } from "../../../context/admin/AdminMyProfile";
import { getMessageDashboardList } from "../../../apis/admin";
import { Scrollbars } from "react-custom-scrollbars";
import {
  useMyUserDispatcher,
  useMyUserContext,
} from "../../../action/admin/MyUserAction";
import { GetAllAdminClientRequestModel } from "../../../models/admin/MyUser";
import { GetAllAdminClient } from "../../../action/admin/MyUserAction";
import {
  AdminJobSave,
  DeleteAdminMyJob,
  GetAdminMyJob,
  getAdminPermissionList,
  GetAllAdminMyJobListPub,
  useAdminMyJobContext,
  useAdminMyJobDispatcher,
} from "../../../action/admin/AdminMyJobAction";
import {
  AdminJobListRequestModel,
  JobIdRequestModel,
  permisionRequestModel,
  RowIdRequestModel,
} from "../../../models/admin/AdminMyJob";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getTitleWithCategoryId,
  useJobAlertContext,
  useJobAlertDispatcher,
} from "../../../action/candidate/JobAlertAction";
import FileUploads from "../../candidate/my_profile/components/FileUploads";
import {
  getVendorMyProfileSelectBoxList,
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../../action/MyProfileVendorAction";
import {
  getExperienceList,
  useExperienceContext,
  useExperienceDispatcher,
} from "../../../action/general/ExperienceAction";
import { experienceRequestModel } from "../../../models/general/Experience";
import CopyToClipboard from "react-copy-to-clipboard";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "green" }} spin />
);
interface ReactSelectOption {
  value: string;
  label: string;
}
interface IAdminDashboardComponentProps extends RouteComponentProps {}
interface IAdminDashboardComponentState {
  jobCategorys: string;
  jobTypeData: string;
  location: string;
  tempData: jobAlertTitleDropdownResultModel[];
  countNext: number;
  carouselValue: number;
  countNextStatus: number;
  carouselValueStatus: number;
  firstName: string;
  middleName: string;
  lastName: string;
  passportNo: string;
  dob: number;
  activeVisa: string;
  visaIssueCountry: string;
  expiryDate: number;
  currentJobId: number;
  filterFlag: boolean;
}
const defaultValues = {
  jobTypeData: "",
  jobCategorys: "",
  tempData: [],
  location: " ",
  countNext: 0,
  carouselValue: 0,
  countNextStatus: 0,
  carouselValueStatus: 0,
  firstName: "",
  middleName: "",
  lastName: "",
  passportNo: "",
  dob: 0,
  activeVisa: "",
  visaIssueCountry: "",
  expiryDate: 0,
  currentJobId: 0,
  filterFlag: true,
};

const AdminDashboardComponent: React.FC<IAdminDashboardComponentProps> = (
  props
) => {
  const [
    adminDashboardComponentState,
    setAdminDashboardComponentState,
  ] = React.useState<IAdminDashboardComponentState>(defaultValues);

  const jobStatusSelect = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" },
  ];

  let jobCategoryData: ReactSelectOption[] = [];
  let jobTypeDatas: ReactSelectOption[] = [];

  const { tempData } = adminDashboardComponentState;
  const authorizationToken = AuthService.accessToken;

  const [list, setList] = useState();
  const [messageList, setMessageList] = useState();
  const [messageListFlag, setMessageListFlag] = useState(0);
  const [listFlag, setListFlag] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoader, setIsLoader] = useState(true);
  const [currentJobId, setCurrentJobId] = useState<number>(0);
  const [mirrorData, setMirrorData] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPublish, setPublish] = useState<boolean>(false);
  const [filename, setFileName] = useState("");
  const [obj, setObj] = useState<any>({ file: null });
  const [filename1, setFileName1] = useState("");
  const [obj1, setObj1] = useState<any>({ file: null });
  const [isLoder, setIsLoder] = useState<boolean>(false);
  const [isLoderPublish, setIsLoderPublish] = useState<boolean>(false);
  const [isLoderDelete, setIsLoderDelete] = useState<boolean>(false);
  const [currentSharedJobId, setCurrentSharedJobId] = useState(0);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    defaultValues,
  });

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    setValue: setValue3,
  } = useForm({
    defaultValues,
  });

  const watchAllFields = watch2();
  let experienceData: ReactSelectOption[] = [];

  const myAdminProfileDispatcher = useAdminMyProfileDispatcher();
  const myAdminProfileContext = useAdminMyProfileContext();
  const {
    basicInfo,
    loggedAdminId,
    // myProfile,
    myProfileProgressBar,
    profileImage,
  } = myAdminProfileContext;

  const myVendorProfileContext = useVendorMyProfileContext();
  const {
    // basicInfo,
    // loggedUserId,
    myProfile,
    // myProfileProgressBar,
    // profileImage,
  } = myVendorProfileContext;

  const jobAlertDispatcher = useJobAlertDispatcher();
  const myProfileVendorDispatcher = useVendorMyProfileDispatcher();
  const notificationListContext = useNotificationListContext();
  const { notificationList, getNotificationList } = notificationListContext;

  const myUserDispatcher = useMyUserDispatcher();
  const myUserContext = useMyUserContext();
  const { GetAllAdminClientRespondModel } = myUserContext;

  const adminMyJobDispatcher = useAdminMyJobDispatcher();
  const adminMyJobContext = useAdminMyJobContext();
  const {
    adminSaveJobRespond,
    jobListInAdmin,
    jobListInAdminPub,
    permissionResult,
    adminEditRespond,
    adminDeleteRespond,
    AdminPermissionResult,
    publishedJobRespond,
  } = adminMyJobContext;

  const experienceContext = useExperienceContext();
  const { experience } = experienceContext;

  const jobAlertContext = useJobAlertContext();
  const { jobAlertTitleDropdownResult } = jobAlertContext;

  const experienceDispatcher = useExperienceDispatcher();

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getVendorMyProfileSelectBoxList(myProfileVendorDispatcher);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (1) {
      (async () => {
        await getExperienceList(experienceDispatcher, {
          page: 1,
          pageSize: 30,
          searchTerm: "",
          sortOrder: "",
        } as experienceRequestModel);
      })();
    }
  }, []);

  console.log("@@", experience);

  if (experience.data != undefined) {
    experienceData = experience.data.map((e: any) => {
      return {
        value: e["rowId"],
        label:
          e["title"] !== "0"
            ? e["title"] + " " + "years"
            : e["title"] + " " + "year",
      };
    });
  }

  React.useEffect(() => {
    setAdminDashboardComponentState((adminDashboardComponentState) => {
      return {
        ...adminDashboardComponentState,
        tempData: jobAlertTitleDropdownResult,
      };
    });
  }, [jobAlertTitleDropdownResult]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId != 0) {
      (async () => {
        await GetAllAdminClient(
          myUserDispatcher,
          {
            AdminId: loggedAdminId,
            Page: 1,
            PageSize: 30,
            SearchTerm: "",
            ShowInactive: false,
            SortOrder: "",
          } as GetAllAdminClientRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedAdminId]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getAdminPermissionList(
          adminMyJobDispatcher,
          {
            PermissionId: 12,
          } as permisionRequestModel,
          authorizationToken
        );
      })();
    }
  }, [authorizationToken]);

  let history = useHistory();
  let user = AuthService.currentUser;

  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null)
      (async () => {
        await getAdminLoggedUserId(
          myAdminProfileDispatcher,
          parseInt(user?.id),
          authorizationToken
        );
      })();
  }, [authorizationToken]);

  React.useEffect(() => {
    if (
      loggedAdminId > 0 &&
      authorizationToken != null &&
      authorizationToken != undefined
    ) {
      getMessageDashboardList({
        UserId: Number(AuthService.currentUser?.id),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      })
        .then((res) => {
          console.log("Dashboard message ", res);

          setMessageList(
            res.data.data.map((item: any, index: any) => {
              if (item["unReadCount"] > 0) {
                setMessageListFlag(1);
                return (
                  <div className="message_sec1">
                    {/* <Link to="admin/communication/messages"> */}
                    <Link
                      to={{
                        pathname: "admin/communication/messages",
                        state: item,
                      }}
                    >
                      <span className="message_icon">
                        <img
                          src={
                            item["imageUrl"] !== "null" &&
                            item["imageUrl"] !== null &&
                            item["imageUrl"] !== undefined
                              ? `https://careerapp.azurewebsites.net/Upload/${item["imageUrl"]}`
                              : require("./../../../images/profileDefault1.jpg")
                          }
                          className="img-responsive"
                        />
                      </span>
                      <div className="message_con_vend">
                        <div className="message_head">{item["userName"]}</div>
                        <div className="message_pra">{item["message"]}</div>
                      </div>
                      <div className="message_noti">
                        <div className="message_noti_con">
                          {item["daysAgo"]} day ago
                        </div>
                        <div className="message_noti_count">
                          {item["unReadCount"]}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })
          );
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [loggedAdminId]);

  React.useEffect(() => {
    if (
      loggedAdminId > 0 &&
      authorizationToken != null &&
      authorizationToken != undefined
    ) {
      console.log(loggedAdminId, " notificationList0 ", authorizationToken);
      getNotificationList({
        AdminId: loggedAdminId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
      });
      console.log(
        loggedAdminId,
        " notificationList0 ",
        notificationList,
        " ",
        authorizationToken
      );
    }
  }, [loggedAdminId]);

  console.log("loggedAdminId---", loggedAdminId);

  React.useEffect(() => {
    if (
      notificationList != null &&
      notificationList != undefined &&
      authorizationToken != null &&
      authorizationToken != undefined &&
      loggedAdminId > 0
    ) {
      if (notificationList.data != undefined) {
        console.log(
          loggedAdminId,
          " notificationList1 ",
          notificationList,
          " ",
          authorizationToken
        );

        setList(
          notificationList.data.map((item: String) => {
            setListFlag(1);
            if (item.includes("Profile") || item.includes("Password")) {
              return (
                <div className="notification_sec">
                  <Link to="admin/my-profile">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.includes("Matched")) {
              return (
                <div className="notification_sec">
                  <Link to="/admin">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.includes("Matched")) {
              return (
                <div className="notification_sec">
                  <Link to="/admin">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.includes("assigned")) {
              return (
                <div className="notification_sec">
                  <Link to="admin/myJob/drafted">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            } else {
              return (
                <div className="notification_sec">
                  <Link to="/admin">
                    <span className="notification_icon">
                      <img
                        src={require("../../../images/profileDefault1.jpg")}
                        className="img-responsive"
                      />
                    </span>
                    <div className="notification_con">
                      <div className="notification_head">{item}</div>
                    </div>
                  </Link>
                </div>
              );
            }
          })
        );
      }
    }
  }, [loggedAdminId, notificationList]);

  React.useEffect(() => {
    if (authorizationToken != null && loggedAdminId !== 0) {
      (async () => {
        await GetAllAdminMyJobListPub(
          adminMyJobDispatcher,
          {
            AdminId: loggedAdminId,
            Draftedjobsflag: false,
            JobStatus: 1,
            Page: currentPage,
            PageSize: 3,
            Publishedjobsflag: true,
            SearchTerm: "",
            SortOrder: "",
          } as AdminJobListRequestModel,
          authorizationToken
        );
      })();
    }
    setIsLoader(true);
  }, [
    adminSaveJobRespond,
    adminDeleteRespond,
    publishedJobRespond,
    currentPage,
    loggedAdminId,
  ]);

  React.useEffect(() => {
    setIsLoader(false);
  }, [jobListInAdminPub]);

  const handleCarouselPreviousClickStatus = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleCarouselNextClickStatus = () => {
    console.log(
      jobListInAdminPub != undefined && jobListInAdminPub.data != undefined
        ? jobListInAdminPub.data.length % 3
        : 0
    );
    if (
      jobListInAdminPub != undefined && jobListInAdminPub.data != undefined
        ? jobListInAdminPub.total / 3 >= currentPage
        : 0
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  // ----------------------------------------

  useEffect(() => {
    if (adminEditRespond.data != undefined) {
      // setValue2("AdminId", );
      // setValue2("Rowid", );
      setValue2("jobCategorys", adminEditRespond.data[0].categoryId.toString());
      setValue2("jobTitle", adminEditRespond.data[0].title);
      setValue2("jobDescription", adminEditRespond.data[0].description);
      setValue2("experience", adminEditRespond.data[0].experienceId.toString());
      setValue2("vacancies", adminEditRespond.data[0].numberOfVacancies);
      setValue2("jobTypeData", adminEditRespond.data[0].jobTypeId.toString());
      setValue2("location", adminEditRespond.data[0].location);
      setValue2("minAnnualSalary", adminEditRespond.data[0].minAnnualSalary);
      setValue2("maxAnnualSalary", adminEditRespond.data[0].maxAnnualSalary);
      // setValue2("MinAnnualSalary", adminEditRespond.data[0].);
      //  setValue2("WillingnessToTravelFlag",adminEditRespond.data[0]. );
      // setValue2("AutoScreeningFilterFlag", adminEditRespond.data[0].);
      // setValue2("AutoSkillAssessmentFlag", adminEditRespond.data[0].);
      setValue2("jobOverview", adminEditRespond.data[0].jobOverview);
      setValue2(
        "minimumQualification",
        adminEditRespond.data[0].minQualification
      );
      setValue2("skillsRequired", adminEditRespond.data[0].skillRequired);
      setValue2(
        "documentsRequired",
        adminEditRespond.data[0].documentsRequired
      );
      // setValue2("AutoSkillAssessmentFlag",adminEditRespond.data[0]. );
      setValue2("others", adminEditRespond.data[0].others);
      setValue2(
        "assignTo",
        adminEditRespond.data[0].assignedToAdminId.toString()
      );
      setValue2("jobStatus", adminEditRespond.data[0].jobStatus ? "1" : "2");

      setPublish(adminEditRespond.data[0].ispublishedJobs);
      setCurrentJobId(mirrorData == 1 ? 0 : adminEditRespond.data[0].jobId);
      setFileName(adminEditRespond.data[0].jobIcon);
      setFileName1(adminEditRespond.data[0].jobBackground);
    }
  }, [adminEditRespond.data]);

  React.useEffect(() => {
    console.log(1213, watchAllFields.jobCategorys);
    console.log(1212, authorizationToken);

    if (authorizationToken != null && watchAllFields.jobCategorys !== "")
      (async () => {
        await getTitleWithCategoryId(
          jobAlertDispatcher,
          {
            categoryId: parseInt(watchAllFields.jobCategorys),
          } as categoryWithId,
          authorizationToken
        );
      })();
  }, [watchAllFields.jobCategorys]);

  const handleAdminClick = (data: any) => {
    console.log(3329, data);
    const formData = new FormData();

    formData.append("AdminId", JSON.stringify(loggedAdminId));
    formData.append("Rowid", JSON.stringify(currentJobId));
    formData.append("CategoryId", JSON.stringify(parseInt(data.jobCategorys)));
    formData.append("Title", String(data.jobTitle));
    formData.append("Description", String(data.jobDescription));
    formData.append(
      "ExperienceId",
      JSON.stringify(parseInt(data.experience == null ? 0 : data.experience))
    );
    formData.append(
      "NumberOfVacancies",
      JSON.stringify(parseInt(data.vacancies == "" ? 0 : data.vacancies))
    );
    formData.append("JobTypeId", JSON.stringify(parseInt(data.jobTypeData)));
    formData.append("LocationId", String(data.location));
    formData.append(
      "MinAnnualSalary",
      JSON.stringify(
        parseInt(data.minAnnualSalary == "" ? 0 : data.minAnnualSalary)
      )
    );
    formData.append(
      "MaxAnnualSalary",
      JSON.stringify(
        parseInt(data.maxAnnualSalary == "" ? 0 : data.maxAnnualSalary)
      )
    );
    formData.append("WillingnessToTravelFlag", JSON.stringify(false));
    formData.append("AutoScreeningFilterFlag", JSON.stringify(false));
    formData.append("AutoSkillAssessmentFlag", JSON.stringify(false));
    formData.append("JobOverview", String(data.jobOverview));
    formData.append("MinQualification", String(data.minimumQualification));
    formData.append("SkillRequired", String(data.skillsRequired));
    formData.append("DocumentsRequired", String(data.documentsRequired));
    formData.append("Others", String(data.others));
    formData.append(
      "AssignedToAdminId",
      JSON.stringify(parseInt(data.assignTo))
    );
    formData.append(
      "JobStatus",
      JSON.stringify(data.jobStatus === "1" ? true : false)
    );
    formData.append("JobIcon", String(filename));
    formData.append("JobBackground", String(filename1));
    formData.append("IsAprrovedJobs", JSON.stringify(false));
    formData.append("IsProcessedJobs", JSON.stringify(false));
    formData.append("IspublishedJobs", JSON.stringify(isPublish));
    formData.append("JobIconDocument", obj.file);
    formData.append("JobBackgroundDocument", obj1.file);
    setIsLoder(true);
    if (authorizationToken != null) {
      (async () => {
        await AdminJobSave(
          adminMyJobDispatcher,

          formData,
          authorizationToken
        );
      })();
    }
    setPublish(false);
    // );
  };

  const handleDelete = () => {
    setIsLoderDelete(true);
    if (authorizationToken != null) {
      (async () => {
        await DeleteAdminMyJob(
          adminMyJobDispatcher,
          {
            JobId: currentJobId,
          } as JobIdRequestModel,
          authorizationToken
        );
      })();
    }
  };

  const handleEdit = (id: any) => {
    setMirrorData(0);
    setCurrentJobId(id);
    if (authorizationToken != null) {
      (async () => {
        await GetAdminMyJob(
          adminMyJobDispatcher,
          {
            RowId: id,
          } as RowIdRequestModel,
          authorizationToken
        );
      })();
    }
    setIsOpen(true);
  };

  const handleMirrorJob = (id: any) => {
    setMirrorData(1);

    if (authorizationToken != null) {
      (async () => {
        await GetAdminMyJob(
          adminMyJobDispatcher,
          {
            RowId: id,
          } as RowIdRequestModel,
          authorizationToken
        );
      })();
    }
    setIsOpen(true);
  };

  const handleDrop = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "application/pdf"].includes(files[0].type) &&
        files[0].size < 1000000 * 5
      ) {
        console.log(files[0]);
        if (files.length > 0) {
          setObj({
            ...obj,
            ["file"]: files[0],
          });
          setFileName(files[0].name);
        }
      }
    }
  };

  const handleDropOne = (files: any) => {
    if (files[0].type != undefined) {
      if (
        ["image/jpeg", "application/pdf"].includes(files[0].type) &&
        files[0].size < 1000000 * 5
      ) {
        console.log(files[0]);
        if (files.length > 0) {
          setObj1({
            ...obj1,
            ["file"]: files[0],
          });
          setFileName1(files[0].name);
        }
      }
    }
  };

  React.useEffect(() => {
    if (adminSaveJobRespond.isSuccess || adminDeleteRespond.isSuccess) {
      setIsLoder(false);
      setIsOpen(false);
      setFileName("");
      setFileName1("");
      setIsLoderDelete(false);
      setIsLoderPublish(false);
    }
  }, [adminSaveJobRespond, adminDeleteRespond]);

  const content = (
    <>
      <p>You Don't Have Permission</p>
    </>
  );

  const content1 = (
    <>
      <p>You are not assign admin</p>
    </>
  );

  const handleJobIconDelete = () => {
    setFileName("");
    setObj({ file: null });
  };

  const handleJobBackGroundDelete = () => {
    setFileName1("");
    setObj1({ file: null });
  };

  const handleViewClick = (
    id: any,
    candidateDefaultId: any,
    vendorDefaultId: any
  ) => {
    props.history.push(
      `/admin/job_Application/${candidateDefaultId}/${vendorDefaultId}/${id}`
    );
  };

  const handleShareButtonClick = (currentShareId: any) => {
    setCurrentSharedJobId(currentShareId);
    setIsShareOpen(true);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row m-t-25">
            <div className="col-sm-8">
              <div className="section_admin">
                <h1>Jobs</h1>
                <div className="row">
                  <div className="prodict_list">
                    <div
                      id="owl-demo1"
                      className="owl-carousel owl-theme"
                      style={{ opacity: 1, display: "block" }}
                    >
                      <div className="owl-wrapper-outer">
                        <div
                          className="owl-wrapper"
                          style={{
                            width: "3140px",
                            left: "0px",
                            display: "block",
                            transition: "all 1000ms ease 0s",
                            // transform: `translate3d(${carouselValueStatus}px, 0px, 0px)`,
                          }}
                        >
                          {isLoader ? (
                            <Spin indicator={antIcon} />
                          ) : jobListInAdminPub != undefined &&
                            jobListInAdminPub.data != undefined ? (
                            jobListInAdminPub.data.map((data, index) => {
                              return (
                                <div className="col-sm-1">
                                  <div
                                    style={{ width: 250 }}
                                    className="matched_jobs"
                                  >
                                    <div className="matched_jobs_cat_t">
                                      {" "}
                                      {data.categoryName}
                                    </div>
                                    <div
                                      style={{ float: "right", marginTop: -8 }}
                                    >
                                      {" "}
                                      <Tooltip
                                        title={
                                          data.applicationviewcount +
                                          " " +
                                          "new applicant"
                                        }
                                      >
                                        <Badge
                                          style={{ float: "right" }}
                                          count={data.applicationviewcount}
                                        />
                                      </Tooltip>
                                    </div>
                                    <div className="matched_star1">
                                      {AdminPermissionResult != undefined &&
                                      AdminPermissionResult.isCreateorEditJobPermission ? (
                                        <a
                                          onClick={() => {
                                            handleMirrorJob(data.rowId);
                                          }}
                                          data-target="#create_jobdescription"
                                          data-toggle="modal"
                                        >
                                          Mirror a Job
                                        </a>
                                      ) : (
                                        <Popover
                                          content={content}
                                          trigger="hover"
                                        >
                                          <a
                                            data-target="#create_jobdescription"
                                            data-toggle="modal"
                                          >
                                            Mirror a Job
                                          </a>
                                        </Popover>
                                      )}
                                    </div>{" "}
                                    <div className="clearfix" />
                                    <div
                                      onClick={() => {
                                        handleEdit(data.rowId);
                                      }}
                                    >
                                      <div className="matched_heading">
                                        {data.title}
                                      </div>
                                      <div className="clearfix" />
                                      <div className="matched_jobs_cat">
                                        <i
                                          className="fa fa-map-marker"
                                          aria-hidden="true"
                                        />{" "}
                                        {data.location}
                                      </div>
                                      <div className="matched_jobs_cat text-right">
                                        <i
                                          className="fa fa-clock-o"
                                          aria-hidden="true"
                                        />{" "}
                                        {data.jobType}
                                      </div>
                                      <div className="clearfix" />
                                      <div className="matched_jobs_cat">
                                        <i
                                          className="fa fa-list-ul"
                                          aria-hidden="true"
                                        />{" "}
                                        {data.numberOfVacancies} Vacancies
                                      </div>

                                      {/* ------- */}
                                      {data.assignedToAdminId ===
                                      loggedAdminId ? (
                                        <>
                                          <div className="matched_jobs_cat">
                                            {/* <i
                                              className="fa fa-list-ul"
                                              aria-hidden="true"
                                            />{" "} */}
                                            Created By:
                                            {data.assignedBy}
                                          </div>
                                          Job Id: {data.jobId}
                                          <div className="matched_jobs_cat">
                                            <i
                                              className="fa fa-list-ul"
                                              aria-hidden="true"
                                            />{" "}
                                            Assigned Date:
                                            {data.jobAssignDate}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="matched_jobs_cat">
                                            {/* <i
                                              className="fa fa-list-ul"
                                              aria-hidden="true"
                                            />{" "} */}
                                            Assign To:
                                            {data.assignTo}
                                          </div>

                                          <div className="matched_jobs_cat">
                                            <i
                                              className="fa fa-list-ul"
                                              aria-hidden="true"
                                            />{" "}
                                            Assigned Date:
                                            {data.jobAssignDate}
                                          </div>
                                        </>
                                      )}
                                      {/* ------- */}
                                    </div>
                                    <div className="clearfix" />
                                    <div className="matched_jobs_share">
                                      <a
                                        onClick={() =>
                                          handleShareButtonClick(data.jobId)
                                        }
                                        data-target="#share"
                                        data-toggle="modal"
                                      >
                                        <i
                                          className="fa fa-share-alt"
                                          aria-hidden="true"
                                        />
                                      </a>
                                      )
                                    </div>
                                    {data.numberOfApplicants !== undefined &&
                                    data.numberOfApplicants !== null &&
                                    data.numberOfApplicants > 0 ? (
                                      <div className="check_status">
                                        {AdminPermissionResult != undefined &&
                                        AdminPermissionResult.isViewJobPermission ? (
                                          data.assignedToAdminId ===
                                          loggedAdminId ? (
                                            <a
                                              onClick={() => {
                                                handleViewClick(
                                                  data.jobId,
                                                  data.candidateDefaultId,
                                                  data.vendorDefaultId
                                                );
                                              }}
                                            >
                                              View Application
                                            </a>
                                          ) : (
                                            <Popover
                                              content={content1}
                                              trigger="hover"
                                            >
                                              <a
                                                style={{
                                                  backgroundColor: "gray",
                                                }}
                                              >
                                                View Application
                                              </a>
                                            </Popover>
                                          )
                                        ) : (
                                          <Popover
                                            content={content}
                                            trigger="hover"
                                          >
                                            <a>View Application</a>
                                          </Popover>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="check_status">
                                        <a
                                          style={{
                                            backgroundColor: "darkgray",
                                          }}
                                        >
                                          No Applicant
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          ) : null}
                        </div>
                      </div>
                      <div className="owl-controls clickable">
                        <div className="owl-pagination">
                          <div className="owl-page active">
                            <span className="" />
                          </div>
                          <div className="owl-page">
                            <span className="" />
                          </div>
                        </div>
                        <div className="owl-buttons">
                          <div
                            className="owl-prev"
                            onClick={handleCarouselPreviousClickStatus}
                          >
                            <img
                              src={require("../../../images/products_ar1.png")}
                            />{" "}
                          </div>
                          <div
                            className="owl-next"
                            onClick={handleCarouselNextClickStatus}
                          >
                            <img
                              src={require("../../../images/products_ar2.png")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section_admin">
                <h1>Our Clients</h1>
                <div className="row">
                  {/* <div className="col-sm-12">Coming Soon!</div>  */}

                  {GetAllAdminClientRespondModel.data != undefined
                    ? GetAllAdminClientRespondModel.data.map((data, i) => {
                        return (
                          <div className="col-sm-3">
                            <div className="users_admin1">
                              <div className="action_btn1">
                                <div className="user_action" tabIndex={-1}>
                                  <div className="action_sec"></div>
                                </div>
                              </div>
                              <div className="clearfix" />
                              <div className="admin_user">
                                {/* {data.imageURL == null ? ( */}
                                <img
                                  className="img-responsive"
                                  src={require("./../../../images/profileDefault1.jpg")}
                                ></img>
                                {/* ) : (
                                      <img
                                        className="img-responsive"
                                        src={data.imageURL}
                                      ></img>
                                    )} */}
                              </div>
                              <div className="admins_names">
                                {data.clientName}
                              </div>
                              <div className="admins_contact">
                                {" "}
                                <i
                                  className="fa fa-phone"
                                  aria-hidden="true"
                                />{" "}
                                {data.countryCode}
                                {data.mobileNumber}
                              </div>
                              <div
                                className="admins_contact"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <i
                                  className="fa fa-envelope-o"
                                  aria-hidden="true"
                                />{" "}
                                {data.email}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}

                  {/* <div className="prodict_list"> 
            		  	  <div id="owl-demo2" className="owl-carousel"> 
                          
                          <div className="item"> 
                                <div className="col-sm-12">
                                <div className="users_admin">
                                    <div className="action_btn1">  
                                            <button type="button" className="actions_user"><i className="fa fa-ellipsis-h mdi-toggle-switch-off" aria-hidden="true"></i></button>
                                       
                                        <div className="user_action" tabIndex={-1}>
                                                <div className="action_sec">
                                         <ul>
                                            <li><a href="#">Dummy</a> </li>
                                            <li><a href="#">Dummy </a>  </li>
                                            <li><a href="#">Dummy </a></li>
                                            <li><a href="#">Dummy</a></li> 
                                            </ul>  
                                            </div> 
                                         </div>
                                         
                                          </div>
                                          
                                          
                                         <div className="clearfix"></div>
                                         
                                         <div className="admin_p"><img src="images/admin_users.jpg"/></div>
                                         <div className="admins_names">Emilin Jhon</div>
                                         <div className="admins_contact"> <i className="fa fa-phone" aria-hidden="true"></i>
 086 834 2525</div>
                                         <div className="admins_contact"><i className="fa fa-envelope-o" aria-hidden="true"></i>
 emilinjhon@gmail.com</div>
                                         
                                
                                </div>          
                               </div> 
                          </div>
                          

                          <div className="item"> 
                                <div className="col-sm-12">
                                <div className="users_admin">
                                    <div className="action_btn1">  
                                            <button type="button" className="actions_user1"><i className="fa fa-ellipsis-h mdi-toggle-switch-off" aria-hidden="true"></i></button>
                                       
                                        <div className="user_action1" tabIndex={-1}>
                                                <div className="action_sec">
                                         <ul>
                                            <li><a href="#">Dummy</a> </li>
                                            <li><a href="#">Dummy </a>  </li>
                                            <li><a href="#">Dummy </a></li>
                                            <li><a href="#">Dummy</a></li> 
                                            </ul>  
                                            </div> 
                                         </div>
                                         
                                          </div>
                                          
                                          
                                         <div className="clearfix"></div>
                                         
                                         <div className="admin_p"><img src="images/admin_users1.jpg"/></div>
                                         <div className="admins_names">Kian</div>
                                         <div className="admins_contact"> <i className="fa fa-phone" aria-hidden="true"></i>
 086 834 2525</div>
                                         <div className="admins_contact"><i className="fa fa-envelope-o" aria-hidden="true"></i>
 kian@jitgmail.com</div>
                                         
                                </div>
                                          
                               </div> 
                          </div>
                         

                          <div className="item"> 
                                <div className="col-sm-12">
                                <div className="users_admin">
                                    <div className="action_btn1">  
                                            <button type="button" className="actions_user2"><i className="fa fa-ellipsis-h mdi-toggle-switch-off" aria-hidden="true"></i></button>
                                       
                                        <div className="user_action2" tabIndex={-1}>
                                                <div className="action_sec">
                                         <ul>
                                            <li><a href="#">Dummy</a> </li>
                                            <li><a href="#">Dummy </a>  </li>
                                            <li><a href="#">Dummy </a></li>
                                            <li><a href="#">Dummy</a></li> 
                                            </ul>  
                                            </div> 
                                         </div>
                                         
                                          </div>
                                          
                                          
                                         <div className="clearfix"></div>
                                         
                                         <div className="admin_p"><img src="images/admin_users2.jpg"/></div>
                                         <div className="admins_names">Amelia</div>
                                         <div className="admins_contact"> <i className="fa fa-phone" aria-hidden="true"></i>
 086 834 2525</div>
                                         <div className="admins_contact"><i className="fa fa-envelope-o" aria-hidden="true"></i>
 amelia@gmail.com</div>
                                         
                                </div>
                                          
                               </div> 
                          </div>
                         
                         
                          <div className="item"> 
                                <div className="col-sm-12">
                                <div className="users_admin">
                                    <div className="action_btn1">  
                                            <button type="button" className="actions_user"><i className="fa fa-ellipsis-h mdi-toggle-switch-off" aria-hidden="true"></i></button>
                                       
                                         
                                         
                                          </div>
                                          
                                          
                                         <div className="clearfix"></div>
                                         
                                         <div className="admin_p"><img src="images/admin_users7.jpg"/></div>
                                         <div className="admins_names">Evelyn</div>
                                         <div className="admins_contact"> <i className="fa fa-phone" aria-hidden="true"></i>
 086 834 2525</div>
                                         <div className="admins_contact"><i className="fa fa-envelope-o" aria-hidden="true"></i>
 evelyn@gmail.com</div>
                                         
                                
                               </div>           
                               </div> 
                          </div>
                         
                          <div className="item"> 
                               <div className="col-sm-12"><div className="users_admin">
                                    <div className="action_btn1">  
                                            <button type="button" className="actions_user"><i className="fa fa-ellipsis-h mdi-toggle-switch-off" aria-hidden="true"></i></button>
                                       
                                         
                                         
                                          </div>
                                          
                                          
                                         <div className="clearfix"></div>
                                         
                                         <div className="admin_p"><img src="images/admin_users7.jpg"/></div>
                                         <div className="admins_names">Evelyn</div>
                                         <div className="admins_contact"> <i className="fa fa-phone" aria-hidden="true"></i>
 086 834 2525</div>
                                         <div className="admins_contact"><i className="fa fa-envelope-o" aria-hidden="true"></i>
 evelyn@gmail.com</div>
                                         
                                
                                          
                               </div> 
                               </div>
                          </div>
                          </div> 
                </div>*/}
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="section_box2">
                <h1>Messages</h1>
                <div
                  className="admin_message"
                  style={{ overflow: "hidden" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 545 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {messageListFlag === 1 ? (
                      messageList
                    ) : (
                      <div>You have no messages!</div>
                    )}
                  </Scrollbars>
                </div>
              </div>
            </div>

            {/* <div className="col-sm-6">
                      <div className="section_box2">
                           <div className="col-sm-6"><h1>Database</h1> </div>
                          <div className="col-sm-6"><a href="#" className="btn export_btn">Import</a>
                              <a href="#" className="btn export_btn">Export</a></div>
                          
                          <div className="clearfix"></div>
                              <div className="col-sm-3"><img src="images/database_icon.png" /></div>
                              <div className="col-sm-6">
                                    <div className="admins_data_h">My Database Details</div>
                                    <div className="admins_data_c">Database Name :</div>
                                    <div className="admins_data_c">Server :</div>
                                    <div className="admins_data_c">Host :</div>
                                    <div className="admins_data_c">User Name :</div>
                                    <div className="admins_data_c">Connection State : Active</div> 
                              </div>
                              
                          
                   </div>        
                    
                 </div>  */}

            <div className="col-sm-6">
              <div className="section_box2">
                <h1>Notifications</h1>
                <div
                  className="dash_noti_scroll"
                  style={{ overflow: "hidden" }}
                  tabIndex={0}
                >
                  <Scrollbars
                    style={{ height: 230 }}
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          position: "relative",
                          display: "block",
                          width: "5px",
                          cursor: "pointer",
                          borderRadius: "inherit",
                          backgroundColor: "rgb(73, 69, 69)",
                          height: "115px",
                        }}
                      />
                    )}
                  >
                    {listFlag === 1 ? (
                      list
                    ) : (
                      <div>You have no notifications!</div>
                    )}
                  </Scrollbars>
                </div>
              </div>
            </div>
            <Modal
              show={isOpen}
              onHide={() => {
                setIsOpen(!isOpen);
              }}
              backdrop="static"
            >
              <form onSubmit={handleSubmit2(handleAdminClick)} noValidate>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {/* {currentAdminId == 0
                        ? " New Admin Profile"
                        : "Update Admin Profile"} */}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="email" className="required">
                          Job Category
                        </label>
                        <span className="select-wrapper-sec">
                          <Controller
                            control={control2}
                            name="jobCategorys"
                            rules={{
                              required: "Job Category is required",
                            }}
                            // value=''
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  myProfile.jobCategories != undefined
                                    ? myProfile.jobCategories.map((e: any) => {
                                        return {
                                          value: e["value"],
                                          label: e["caption"],
                                        };
                                      })
                                    : jobCategoryData
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                              />
                            )}
                          />
                        </span>
                        <ErrorMessage
                          errors={errors2}
                          name="jobCategorys"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="email" className="required">
                          Job Type
                        </label>
                        <span className="select-wrapper-sec">
                          <Controller
                            className="custom-select-sec"
                            control={control2}
                            name="jobTypeData"
                            rules={{
                              required: "Job Type is required",
                            }}
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  myProfile.jobTypes != undefined
                                    ? myProfile.jobTypes.map((e: any) => {
                                        return {
                                          value: e["value"],
                                          label: e["caption"],
                                        };
                                      })
                                    : jobTypeDatas
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                              />
                            )}
                          />
                        </span>
                        <ErrorMessage
                          errors={errors2}
                          name="jobTypeData"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="email" className="required">
                          Job Title
                        </label>
                        <span className="select-wrapper-sec">
                          <Controller
                            control={control2}
                            name="jobTitle"
                            rules={{
                              required: "Job Title is required",
                            }}
                            // value=''
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={
                                  tempData != undefined
                                    ? tempData.map((e) => {
                                        return {
                                          value: e["value"],
                                          label: e["caption"],
                                        };
                                      })
                                    : tempData
                                }
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                              />
                            )}
                          />
                        </span>

                        <ErrorMessage
                          errors={errors2}
                          name="jobTitle"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="email" className="required">
                          Experience
                        </label>
                        <span className="select-wrapper-sec">
                          <Controller
                            control={control2}
                            name="experience"
                            rules={{
                              required: "experience is required",
                            }}
                            // value=''
                            render={({ onChange, onBlur, value, name }) => (
                              <SelectOption
                                values={experienceData}
                                disabled={false}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name={name}
                              />
                            )}
                          />
                        </span>
                        <ErrorMessage
                          errors={errors2}
                          name="experience"
                          render={({ message }) => (
                            <div className="register_validation">{message}</div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Job Location</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type here"
                        name="location"
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>

                  {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">Experience</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here"
                      name="experience"
                      ref={register2({
                        required: false,
                      })}
                    />
                  </div>
                </div> */}
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Vacancies</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type here"
                        name="vacancies"
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                  {/* <div className="clearfix" /> */}
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Job Overview</label>
                      <textarea
                        name="jobOverview"
                        rows={4}
                        className="form-control"
                        defaultValue={""}
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Job Description</label>
                      <textarea
                        name="jobDescription"
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Minimum Qualification</label>
                      <textarea
                        name="minimumQualification"
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Skills Required</label>
                      <textarea
                        name="skillsRequired"
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Min Annual Salary</label>
                      <input
                        type="number"
                        className="form-control"
                        name="minAnnualSalary"
                        placeholder="Type here"
                        ref={register2({
                          required: false,
                          validate: (value) => {
                            if (value < 1 && value != 0) {
                              return "Min Annual Salary can not be a negative number";
                            } else if (
                              value >= Number(watch2("maxAnnualSalary")) &&
                              Number(watch2("minAnnualSalary")) !== 0
                            ) {
                              console.log(
                                value,
                                Number(watch2("maxAnnualSalary"))
                              );
                              return "Must be less than Max Salary.";
                            } else {
                              return undefined;
                            }
                            // return value < Date.now() ? undefined : "DOB cannot be a future date.";
                          },
                        })}
                      />
                    </div>
                    <ErrorMessage
                      errors={errors2}
                      name="minAnnualSalary"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Max Annual Salary</label>
                      <input
                        type="number"
                        className="form-control"
                        name="maxAnnualSalary"
                        placeholder="Type here"
                        ref={register2({
                          required: true,
                          validate: (value) => {
                            if (value < 1 && value != 0) {
                              return "Max Annual Salary can not be a negative number";
                            } else if (
                              value < Number(watch2("minAnnualSalary") && 0)
                            ) {
                              return "Must be grater than Min Salary.";
                            } else {
                              return undefined;
                            }
                          },
                        })}
                      />
                    </div>
                    <ErrorMessage
                      errors={errors2}
                      name="maxAnnualSalary"
                      render={({ message }) => (
                        <div className="register_validation">{message}</div>
                      )}
                    />
                  </div>

                  <div className="clearfix" />
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Documents Required</label>
                      <textarea
                        name="documentsRequired"
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Others</label>
                      <textarea
                        name="others"
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        ref={register2({
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email" className="required">
                        Assign TO
                      </label>
                      <span className="select-wrapper-sec">
                        <Controller
                          control={control2}
                          name="assignTo"
                          rules={{
                            required: "Assign To is required",
                          }}
                          // value=''
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={
                                permissionResult != undefined
                                  ? permissionResult.data.map((e: any) => {
                                      return {
                                        value: e["value"],
                                        label: e["caption"],
                                      };
                                    })
                                  : []
                              }
                              disabled={false}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>
                      <ErrorMessage
                        errors={errors2}
                        name="assignTo"
                        render={({ message }) => (
                          <div className="register_validation">{message}</div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email" className="required">
                        Job Status
                      </label>
                      <span className="select-wrapper-sec">
                        <Controller
                          control={control2}
                          name="jobStatus"
                          rules={{
                            required: "Job Status is required",
                          }}
                          // value=''
                          render={({ onChange, onBlur, value, name }) => (
                            <SelectOption
                              values={jobStatusSelect}
                              disabled={false}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name={name}
                            />
                          )}
                        />
                      </span>
                      <ErrorMessage
                        errors={errors2}
                        name="jobStatus"
                        render={({ message }) => (
                          <div className="register_validation">{message}</div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Job Icon</label>

                      <FileUploads
                        accept=".pdf,.jpg,.jpeg"
                        onChange={(file: any) => {
                          handleDrop([file]);
                        }}
                        name="Upload icon"
                        disabled={false}
                      />
                    </div>
                    {filename}{" "}
                    {filename === "" || filename === null ? null : (
                      <a>
                        <i
                          onClick={() => handleJobIconDelete()}
                          className="fa fa-trash-o"
                          aria-hidden="true"
                          style={{ paddingLeft: 2 }}
                        />
                      </a>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="email">Job Background</label>
                      <FileUploads
                        accept=".pdf,.jpg,.jpeg"
                        onChange={(file: any) => {
                          handleDropOne([file]);
                        }}
                        name="Upload Background"
                        disabled={false}
                      />
                    </div>
                    {filename1}{" "}
                    {filename1 === "" || filename1 == null ? null : (
                      <a>
                        <i
                          onClick={() => handleJobBackGroundDelete()}
                          className="fa fa-trash-o"
                          aria-hidden="true"
                          style={{ paddingLeft: 2 }}
                        />
                      </a>
                    )}
                  </div>

                  <div className="clearfix" />
                </Modal.Body>
                <div className="modal-footer m-t-30">
                  {currentJobId != 0 ? (
                    isLoderDelete ? (
                      <button
                        className="btn btn-danger save-event waves-effect waves-light"
                        type="button"
                      >
                        <Spin indicator={antIcon} />
                      </button>
                    ) : (
                      <button
                        className="btn btn-danger save-event waves-effect waves-light"
                        type="button"
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                    )
                  ) : null}

                  {isLoader ? (
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="submit"
                    >
                      <Spin indicator={antIcon} />
                    </button>
                  ) : (
                    <button
                      className="btn btn-success save-event waves-effect waves-light"
                      type="submit"
                    >
                      Save
                    </button>
                  )}

                  <button
                    className="btn btn-success save-event waves-effect waves-light"
                    type="submit"
                    onClick={() => {
                      setPublish(true);
                      setIsLoderPublish(true);
                    }}
                  >
                    Published Now
                  </button>

                  <button
                    data-dismiss="modal"
                    className="btn btn-default waves-effect"
                    type="button"
                    onClick={() => {
                      setIsOpen(false);
                      setFileName("");
                      setFileName1("");
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="clearfix" />
              </form>
            </Modal>

            <Modal
              show={isShareOpen}
              onHide={() => {
                setIsShareOpen(!isShareOpen);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Share this document on</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-sm-12 m_t_30 text-center">
                  <div className="social1">
                    <WhatsappShareButton
                      url={encodeURI(
                        `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentSharedJobId}/searchtitle/location/0`
                      )}
                      className={""}
                      title={"CareerApp Job Post"}
                    >
                      {/* <a href="#" className="social_face"> */}
                      <Tooltip title="whatsApp">
                        <i
                          className="fa fa-whatsapp"
                          style={{
                            fontSize: 51,
                            color: "green",
                            paddingRight: 2,
                          }}
                        ></i>
                      </Tooltip>
                      {/* </a> */}
                    </WhatsappShareButton>{" "}
                    <TelegramShareButton
                      url={`career app job Share`}
                      title={encodeURI(
                        `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentSharedJobId}/searchtitle/location/0`
                      )}
                    >
                      <Tooltip title="Telegram">
                        <i
                          className="fa fa-telegram"
                          style={{
                            fontSize: 48,
                            color: "rgb(55, 174, 226)",
                          }}
                        ></i>
                      </Tooltip>
                    </TelegramShareButton>
                    <EmailShareButton
                      url={encodeURI(
                        `https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentSharedJobId}/searchtitle/location/0`
                      )}
                      subject={"Jobs In Carrer app"}
                      body={"This is a Jobs from   Carrer app"}
                      className={""}
                    >
                      {/* <EmailIcon></EmailIcon> */}
                      {/* <i
                  className="fa fa-envelope"
                  style={{ fontSize: 48, color: "" }}
                ></i> */}
                      <Tooltip title="Gmail">
                        <img
                          src={require("../../../images/gmail-icon-svg-28.jpg")}
                          style={{ height: 66, marginTop: -24 }}
                        />
                      </Tooltip>
                    </EmailShareButton>{" "}
                    <CopyToClipboard
                      //  text={`http://careerappui.clubactive.in/#/file_view/${currentDocType}/http://careerapp.azurewebsites.net/api/DigiDocumentUpload/download-doc/${currentJobId}`}
                      text={`https://jitcareerappui.azurewebsites.net/#/jobSearch/searchtitle/${currentSharedJobId}/searchtitle/location/0`}
                    >
                      <Tooltip title="copy to clipboard">
                        {" "}
                        {/* <i
                    style={{ fontSize: 48 }}
                    className="fa fa-clipboard"
                    aria-hidden="true"
                    onClick={() => {
                      setIsShareOpen(!isShareOpen);
                    }}
                  ></i> */}
                        <img
                          src={require("../../../images/copy-clipboard-icon-28.jpg")}
                          style={{
                            height: 66,
                            marginTop: -24,
                            marginLeft: -15,
                          }}
                          onClick={() => {
                            setIsShareOpen(!isShareOpen);
                          }}
                        />
                      </Tooltip>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="modal-footer  m-t-30"></div>
              </Modal.Body>
            </Modal>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
};

export default withRouter(AdminDashboardComponent);
