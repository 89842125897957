import React, { useState } from "react";

import { BrowserRouter, Link, withRouter } from "react-router-dom";
import { Collapse } from "antd";
import { useHistory } from 'react-router-dom';
import { AcroFormTextField } from "jspdf";

const { Panel } = Collapse;

interface IHelpComponentProps { }

interface IHelpComponentState { }

const initialState = {};

const HelpComponent: React.FC<IHelpComponentProps> = (props: any) => {
  const [startUpState, setHelpComponentState] = React.useState<
    IHelpComponentState
  >(initialState);

  const history = useHistory();
  const cl = props.location.pathname;
  const [accordionValue, setAccordionValue] = useState(0);
  const [active, setActive] = useState("Candidate");
  const [isSecondTabActive, setIsSecondTabActive] = useState("General");

  const firstTabTitles = ["Candidate", "Client", "Vendor"];
  const secondTabTitles = [
    "General",
    "Digi Locker",
    "Resume Builder",
    "Career Developer",
    "Jobs",
  ];

  const firstTabImages = [
    "cadidate_icon.png",
    "client_icon.png",
    "vendor_icon.png",
  ];

  const secondTabImages = [
    "general_tab_icon.png",
    "digilocker_tab_icon.png",
    "resume_tab_icon.png",
    "career_tab_icon.png",
    "job_tab_icon.png",
  ];




  const _titlesOfFirstTab = firstTabTitles.map((e, i) => {
    return (
      <li
        key={i}
        onClick={() => {
          setActive(e);
        }}
        className={
          e == active
            ? `help-tabs-clr${i + 1} resp-tab-item resp-tab-active`
            : `help-tabs-clr${i + 1} resp-tab-item`
        }
        aria-controls={`tab_item-${i}`}
        role="tab"
      >
        <img src={require("../../images/" + firstTabImages[i])} />
        {e}
      </li>
    );
  });

  const _titleOfSecondTab = secondTabTitles.map((e, i) => {
    return (
      <a
        key={i}
        onClick={() => {
          setIsSecondTabActive(e);
        }}
        className={
          e == isSecondTabActive
            ? `c-tabs-nav__link tabs_cat is-active`
            : `c-tabs-nav__link tabs_cat `
        }
      >
        <img src={require("../../images/" + secondTabImages[i])} /> {e}
      </a>
    );
  });

  const handleChangeAccordion = (value: number) => {
    if (value == accordionValue) {
      setAccordionValue(0);
    } else {
      setAccordionValue(value);
    }
  };

  let tab = null;

  // general  query------------------------------------------------------
  if (active == "Candidate" && isSecondTabActive == "General") {
    tab = (
      <>
        <h1
          onClick={() => {
            handleChangeAccordion(1);
          }}
          className={accordionValue == 1 ? "active_help" : ""}
        >
          How do I register to be a candidate? <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 1 ? "block" : "none",
          }}
        >
          Steps to register as a candidate are as follows-

          <ol>
            <li>Click the <b>REGISTER</b> button on Home Page.
            </li>
            <li>Enter your <b>NAME, E-MAIL, MOBILE NUMBER, PASSWORD and CONFIRM the password. </b>
            </li>
          </ol>
          <b>Mandatory: </b>Registration is followed by Email and Mobile verification using OTP. Once your email and mobile number have been verified, your candidates profile will be complete!
          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(2);
          }}
          className={accordionValue == 2 ? "active_help" : ""}
        >
          How do I login as a candidate? <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 2 ? "block" : "none",
          }}
        >
          Steps to login as a candidate are as follows-
          <ol>
            <li>
              Click on the <b>LOGIN</b> link (located on the Home Page)
            </li>
            <li>
              Enter the verified email address and password
            </li>
            <li>
              Click on the Login button to access your account.
            </li>
          </ol>
          {" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(3);
          }}
          className={accordionValue == 3 ? "active_help" : ""}
        >
          How do I communicate with other users? <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 3 ? "block" : "none",
          }}
        >
          Currently you can only communicate with the admins.
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(4);
          }}
          className={accordionValue == 4 ? "active_help" : ""}
        >
          How do I update/edit my profile?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 4 ? "block" : "none",
          }}
        >
          Profile updates can be managed from your dashboard. In order to navigate to the Editing function, simply navigate via Login --> Dashboard --> Profile. Once you have arrived at the Profile's page, the option to Edit can be found on the right-hand side of your screen. Unless the Edit option is clicked, your information will remain as read-only, and cannot be altered otherwise.
          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(5);
          }}
          className={accordionValue == 5 ? "active_help" : ""}
        >
          What kind of information can I keep on my profile?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 5 ? "block" : "none",
          }}
        >
          Your profile comes equipped with several tabs and categories to keep
          your information safe and organized! Included in these Tabs are:
          personal info, communication capabilities, important documents, the
          user's qualifications, experience, training and skills.{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(6);
          }}
          className={accordionValue == 6 ? "active_help" : ""}
        >
          Where can I see my notifications?

          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 6 ? "block" : "none",
          }}
        >
          All your notifications can be accessed and viewed on your dashboard.
          .{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(7);
          }}
          className={accordionValue == 7 ? "active_help" : ""}
        >
          How do I change my password?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 7 ? "block" : "none",
          }}
        >
          You can reset password from PROFILE. Steps are as follow

          <ol>
            <li>
              Click <b>UPDATE PASSWORD</b> button.
            </li>
            <li>
              Enter new password.
            </li>
            <li>
              Conform the password.
            </li>
          </ol>
          {" "}
        </div>


        <h1
          onClick={() => {
            handleChangeAccordion(8);
          }}
          className={accordionValue == 8 ? "active_help" : ""}
        >
          How do I change my email address?

          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 8 ? "block" : "none",
          }}
        >
          To change your email address-

          <ol>
            <li>
              Navigate to <b>PROFILE</b> page on your dashboard.
            </li>
            <li>
              Click on the Communication tab.
            </li>
          </ol>


          Remember, changing or updating your email will require an OTP (One Time Pin) verification to be able to take effect.


          .{" "}
        </div>


        <h1
          onClick={() => {
            handleChangeAccordion(9);
          }}
          className={accordionValue == 9 ? "active_help" : ""}
        >
          How do I change my mobile number?

          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 9 ? "block" : "none",
          }}
        >
          To change your mobile number-
          <ol>
            <li>Navigate to your <b>PROFILE</b> page on your dashboard.</li>
            <li>Click on the Communication tab.</li>
            <li>Click on edit option.</li>
            <li>Change the phone number.</li>
          </ol>

          **Remember, changing or updating your mobile number will require an OTP (One Time Pin) verification to be able to take effect



          .{" "}
        </div>


        <h1
          onClick={() => {
            handleChangeAccordion(10);
          }}
          className={accordionValue == 10 ? "active_help" : ""}
        >
          What do I do if I have forgotten my password?


          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 10 ? "block" : "none",
          }}
        >
          There is a Forgot Password feature which can be found on the Login page.
          Resetting your password will require a verification, either email or mobile number.
          Once verified, you will be prompted to enter a new password and confirm new password.

          .{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(11);
          }}
          className={accordionValue == 11 ? "active_help" : ""}
        >
          Can I link my social media accounts with my profile?



          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 11 ? "block" : "none",
          }}
        >
          Yes, you can include your social networks with JIT Career.
          This helps you share job posts across social platforms and make them available to friends and people in your community.


          .{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(12);
          }}
          className={accordionValue == 12 ? "active_help" : ""}
        >
          Can recruiters see my social network profiles?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 12 ? "block" : "none",
          }}
        >
          Yes! Social Networking details are visible to recruiters! This allows them to
          learn more about you!

          .{" "}
        </div>


        <h1
          onClick={() => {
            handleChangeAccordion(13);
          }}
          className={accordionValue == 13 ? "active_help" : ""}
        >
          Can recruiters see my documents, cover letter and resume?

          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 13 ? "block" : "none",
          }}
        >
          No, they cannot. Not until you attach them to your application during the application process. It is necessary to attach these documents when applying for jobs.


          .{" "}
        </div>


        <h1
          onClick={() => {
            handleChangeAccordion(14);
          }}
          className={accordionValue == 14 ? "active_help" : ""}
        >
          Where do I find Career Tips?

          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 14 ? "block" : "none",
          }}
        >
          You can click on the Career Tips banner on the Home page. To find it, scroll down past the JIT Career Developer section on the Home page.

          .{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(15);
          }}
          className={accordionValue == 15 ? "active_help" : ""}
        >
          What categories can I expect to find under Career tips?

          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 15 ? "block" : "none",
          }}
        >
          There are more than 10 categories found in the Career Tips section. For more information, please click on Career Tips, found on the Home page.

          .{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(16);
          }}
          className={accordionValue == 16 ? "active_help" : ""}
        >
          Are the Career Tips available in the form of blogs, or videos?

          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 16 ? "block" : "none",
          }}
        >
          Career Tips are available in the form of Blogs.

          .{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(17);
          }}
          className={accordionValue == 17 ? "active_help" : ""}
        >
          If I have any issues or concerns, who do I contact? How can I get in touch with them?


          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 17 ? "block" : "none",
          }}
        >
          You can vist the Contact Us page and send us an email with the address you provided during registration. You can send us a message from the Let's Get In Touch section as well. Once found, you may insert your queries and/or issues there.


          .{" "}
        </div>
      </>
    );
  }
  // digi Locker Query-------------------------

  if (active == "Candidate" && isSecondTabActive == "Digi Locker") {
    tab = (
      <>
        <h1
          onClick={() => {
            handleChangeAccordion(101);
          }}
          className={accordionValue == 101 ? "active_help" : ""}
        >
          What is DigiLocker? <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 101 ? "block" : "none",
          }}
        >
          DigiLocker is a feature put in place by JIT to help candidates safely
          manage and control the visibility of their documents. On the go.
          Anytime. Anywhere.
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(102);
          }}
          className={accordionValue == 102 ? "active_help" : ""}
        >
          What can DigiLocker do?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 102 ? "block" : "none",
          }}
        >
          DigiLocker allows you to keep your documents, all in one secure place.
          You can set expiration reminders for your documents and also get
          updates when your documents are nearing their expiration. You can Add,
          Edit, Share, Delete, and Move documents between General and Personal
          folders.{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(103);
          }}
          className={accordionValue == 103 ? "active_help" : ""}
        >
          Is DigiLocker free, or pay to use?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 103 ? "block" : "none",
          }}
        >
          There is no cost to you! This feature is free to use!{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(104);
          }}
          className={accordionValue == 104 ? "active_help" : ""}
        >
          Is there a limit to how many documents I can upload?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 104 ? "block" : "none",
          }}
        >
          With JIT's DigiLocker, any number of documents can be stored safely and managed, without any limitations.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(105);
          }}
          className={accordionValue == 105 ? "active_help" : ""}
        >
          How do I use DigiLocker?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 105 ? "block" : "none",
          }}
        >
          To make use of the DigiLocker feature-
          <ol><li>Create a candidate account.</li>
            <li>Login with the JIT career platform.</li>
            <li>DigiLocker is one of the free service available with your profile.</li>
          </ol>

          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(106);
          }}
          className={accordionValue == 106 ? "active_help" : ""}
        >
          Is there a limit to how many documents I can upload?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 106 ? "block" : "none",
          }}
        >
          With JIT's DigiLocker, any number of documents can be stored safely and managed, without any limitations.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(107);
          }}
          className={accordionValue == 107 ? "active_help" : ""}
        >
          How I know when my documents are going ot expire?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 107 ? "block" : "none",
          }}
        >
          When uploading your documetns using DigiLocker, make sure you check off the option for "Get Expiry Reminders{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(108);
          }}
          className={accordionValue == 108 ? "active_help" : ""}
        >
          How do I add new documents?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 108 ? "block" : "none",
          }}
        >
          Follow the path as below to add new documents.
          <br /><b>Home --> Candidate Login -- > Dashboard --> DigiLocker --> Add Document.</b>{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(109);
          }}
          className={accordionValue == 109 ? "active_help" : ""}
        >
          How do I update my documents?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 109 ? "block" : "none",
          }}
        >
          In order to update any documents follow the below steps:
          <ol>
            <li>	Hover your mouse over the document that you want to update </li>
            <li>Click on the <b>UPLOAD</b> option. </li>
            <li>Enter the correct information</li>
            <li>Click <b>SAVE</b>.</li>
          </ol>

          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(110);
          }}
          className={accordionValue == 110 ? "active_help" : ""}
        >
          How do I remove my documents?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 110 ? "block" : "none",
          }}
        >
          In order to remove any documents follow the below steps:
          <ol>
            <li>Find your list of documents.</li>
            <li>Click on the <b>DELETE</b> icon, which will be in front of the document.</li>
            <li><b>CONFIRM</b> your selection to remove, and the document will be permanantly deleted.</li>
          </ol>

          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(111);
          }}
          className={accordionValue == 111 ? "active_help" : ""}
        >
          How do I share my documents?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 111 ? "block" : "none",
          }}
        >
          In order to share any documents follow the below steps:
          <ol>	<li>Find your list of documents, locate the document you wish to share.</li>
            <li>Click on the <b>SHARE </b>icon, which will be found in front of the document.</li></ol>

          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(112);
          }}
          className={accordionValue == 112 ? "active_help" : ""}
        >
          How do I download my documents?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 112 ? "block" : "none",
          }}
        >
          In order to download any documents follow the below steps:
          <ol>
            <li>	Find your list of documents, locate the file you wish to download.</li>
            <li>Click on the <b>DOWNLOAD</b> icon, which will be located in front of the document.</li>
          </ol>{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(113);
          }}
          className={accordionValue == 113 ? "active_help" : ""}
        >
          How do I move my documents?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 113 ? "block" : "none",
          }}
        >
          In order to download any documents follow the below steps:
          <ol><li>Find your list of documents, and click on the checkbox found next to the document you wish to move.
          </li>
            <li>Then select whether or not you want to move it to another folder. To select/de-select all your documents, click on the checkbox located in the header.
            </li></ol>{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(114);
          }}
          className={accordionValue == 114 ? "active_help" : ""}
        >
          How do I control who gets to see my documents?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 114 ? "block" : "none",
          }}
        >
          To make your documents visible to recruiters, must click on the Apply Now option found on the Job Posting. Once you have done that, select from the list of documents, which ones you want visible to the recruiter.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(115);
          }}
          className={accordionValue == 115 ? "active_help" : ""}
        >
          How do I link my documents to a resume or my profile?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 115 ? "block" : "none",
          }}
        >
          By clicking on the attach document option, and selecting them from the DigiLocker, candidates can attach their documents to their resumes and profile. These documents are visible to recruiters.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(116);
          }}
          className={accordionValue == 116 ? "active_help" : ""}
        >
          How do I know my documents are safe with DigiLocker?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 116 ? "block" : "none",
          }}
        >
          With DigiLocker, candidates’ documents are completely safe and secure, and are not shared outside of JIT's database. No matter the case. All sharing capabilities are within the candidates’ discretion.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(117);
          }}
          className={accordionValue == 117 ? "active_help" : ""}
        >
          Can I share my documents via my social media accounts?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 117 ? "block" : "none",
          }}
        >
          Candidates can share their documents across social media platforms, as long as the media platforms have been linked to your profile.{" "}
        </div>

      </>
    );
  }

  // Resume Builder Query

  if (active == "Candidate" && isSecondTabActive == "Resume Builder") {
    tab = (
      <>
        <h1
          onClick={() => {
            handleChangeAccordion(201);
          }}
          className={accordionValue == 201 ? "active_help" : ""}
        >
          What is Resume Builder?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 201 ? "block" : "none",
          }}
        >
          Using JIT's Resume Builder, you can create resumes and cover letters
          in no time. You can make as many copies as you wish! These can be
          copied, edited, shared, deleted, and you can even attach them to job
          applications! Resume Builder also offers you hundreds of stylized
          templates for you to choose from! This is all free, and gives you the
          tools needed to get that extra step ahead of the competition. All of
          this, in one place.
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(202);
          }}
          className={accordionValue == 202 ? "active_help" : ""}
        >
          What are the features of Resume Builder? What can Resume Builder do?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 202 ? "block" : "none",
          }}
        >
          <ol style={{ listStyleType: "disc" }}><li>Resume Builder lets you design your resume and prepare your cover letter, quickly, and easily. </li>
            <li>Not to mention tmany templates you can choose from. </li>
            <li>You can change the template and preview your resume before saving it, as well as being able download copies for yourself. There is no limitations as to how you can use it! </li>
          </ol>{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(203);
          }}
          className={accordionValue == 203 ? "active_help" : ""}
        >
          Is Resume Builder free, or pay to use?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 203 ? "block" : "none",
          }}
        >
          Totally free to use!.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(204);
          }}
          className={accordionValue == 204 ? "active_help" : ""}
        >
          Are there any limitations in using Resume Builder?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 204 ? "block" : "none",
          }}
        >
          None whatsoever!{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(205);
          }}
          className={accordionValue == 205 ? "active_help" : ""}
        >
          How do I use Resume Builder?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 205 ? "block" : "none",
          }}
        >
          <ol> <li>	Go to Home page, as well as your dashboard. </li>
            <li>	Click on the button, and you are on your way .</li></ol>
          <b>Note: </b>Remember, this feature is only available to candidates!.
          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(206);
          }}
          className={accordionValue == 206 ? "active_help" : ""}
        >
          Do I need to enter my information from scratch?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 206 ? "block" : "none",
          }}
        >
          Resume Builder automatically pulls information from your profile and does not require you to enter your information from scratch. If you have already saved your details on your profile, then all thats left to do is select a template and preview the resume before you save it! In terms of your cover letter though, you will need to select the right template and make changes accordingly. Then preview and save, as you did for your resume. If you are dissatisfied with the preview, you can re-select the template and try a different one..{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(207);
          }}
          className={accordionValue == 207 ? "active_help" : ""}
        >
          Does Resume Builder take information from my profile to create a resume?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 207 ? "block" : "none",
          }}
        >
          Yes, it does.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(208);
          }}
          className={accordionValue == 208 ? "active_help" : ""}
        >
          Are all the templates free to use on Resume Builder?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 208 ? "block" : "none",
          }}
        >
          Yes.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(209);
          }}
          className={accordionValue == 209 ? "active_help" : ""}
        >
          Can I make multiple resumes on Resume Builder?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 209 ? "block" : "none",
          }}
        >
          Yes.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(210);
          }}
          className={accordionValue == 210 ? "active_help" : ""}
        >
          Is my resume visible to recruiters?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 210 ? "block" : "none",
          }}
        >
          To make your cover letter and resume visible to recruiters, attach them to your application when applying for your jobs.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(211);
          }}
          className={accordionValue == 211 ? "active_help" : ""}
        >
          How many of my resumes will be visible to recruiters?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 211 ? "block" : "none",
          }}
        >
          One resume and/or cover letter may be made visible to recruiters, for every application sent.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(212);
          }}
          className={accordionValue == 212 ? "active_help" : ""}
        >
          Can I control which resume is visible to recruiters?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 212 ? "block" : "none",
          }}
        >
          Yes, you will have the chance to pick and choose which cover letter and/or resume will be made visible to recruiters..{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(213);
          }}
          className={accordionValue == 213 ? "active_help" : ""}
        >
          Can I add my documents from DigiLocker to Resume Builder?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 213 ? "block" : "none",
          }}
        >
          Yes, the steps are as follows-
          <ol><li>	Click <b>ATTACH DOCUMENT.</b></li>
            <li>Then select <b>CHOOSE FROM DIGILOCKER.</b></li></ol>
          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(214);
          }}
          className={accordionValue == 214 ? "active_help" : ""}
        >
          Can I save documents to DigiLocker while uploading them to Resume Builder?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 214 ? "block" : "none",
          }}
        >
          Yes, the steps are as follows-
          <ol>
            <li>Click <b>ATTACH DOCUMENT.</b></li>
            <li>Then select <b>UPLOAD TO DIGILOCKER.</b></li></ol>
          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(215);
          }}
          className={accordionValue == 215 ? "active_help" : ""}
        >
          Can I re-select templates?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 215 ? "block" : "none",
          }}
        >
          Yes, this is possible when in preview.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(216);
          }}
          className={accordionValue == 216 ? "active_help" : ""}
        >
          Can I edit the information on my resumes?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 216 ? "block" : "none",
          }}
        >
          Yes, information can be changed by clicking edit at the right side in each tab.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(217);
          }}
          className={accordionValue == 217 ? "active_help" : ""}
        >
          Does JIT share my information to outside parties?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 217 ? "block" : "none",
          }}
        >
          JIT does not share any of your information to any outside party.{" "}
        </div>
      </>
    );
  }

  // Career Developer Query

  if (active == "Candidate" && isSecondTabActive == "Career Developer") {
    tab = (
      <>
        <h1
          onClick={() => {
            handleChangeAccordion(301);
          }}
          className={accordionValue == 301 ? "active_help" : ""}
        >
          What is Career Developer?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 301 ? "block" : "none",
          }}
        >
          It is an online platform for you to get training into numerous fields
          via video tutorials. You can browse through each category and get a
          better idea. No subscription is required to use this platform.
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(302);
          }}
          className={accordionValue == 302 ? "active_help" : ""}
        >
          What features are available in Career Developer?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 302 ? "block" : "none",
          }}
        >
          There are tens of categories and each category has videos from basics
          to expert levels. This helps you understand the what to expect, and
          how to get ready for it. .{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(303);
          }}
          className={accordionValue == 303 ? "active_help" : ""}
        >
          Are the videos done professionally?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 303 ? "block" : "none",
          }}
        >
          The videos are posted by people who are experts in their own fields. They are serialized to know the process in a better way.  Much care and precaution is taken in creating these tutorials, so as to provide you with the best possible experience.{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(304);
          }}
          className={accordionValue == 304 ? "active_help" : ""}
        >
          Can I save a video for later viewing?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 304 ? "block" : "none",
          }}
        >
          Yes, videos can be saved.{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(305);
          }}
          className={accordionValue == 305 ? "active_help" : ""}
        >
          Can I share these tutorials with my friends?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 305 ? "block" : "none",
          }}
        >
          Yes, you can post them on social platforms to your friends and community.{" "}
        </div>

      </>
    );
  }

  // Job query

  if (active == "Candidate" && isSecondTabActive == "Jobs") {
    tab = (
      <>
        <h1
          onClick={() => {
            handleChangeAccordion(401);
          }}
          className={accordionValue == 401 ? "active_help" : ""}
        >
          How do I search for jobs?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 401 ? "block" : "none",
          }}
        >
          The job search section can be found on the Home page and your
          dashboard. You can search for your desired job, sumbit and
          application, and get first pick of new opportunities.
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(402);
          }}
          className={accordionValue == 402 ? "active_help" : ""}
        >
          How do I create job alerts and get notifications?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 402 ? "block" : "none",
          }}
        >
          You can set up job alerts on the job search page, as well as your
          dashboard. Upon defining your criterias, you will recieve an alert in
          your notifications section of any matching opportunities.{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(403);
          }}
          className={accordionValue == 403 ? "active_help" : ""}
        >
          How do I find jobs that fit me best?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 403 ? "block" : "none",
          }}
        >
          Complete your profile, and we can do our part in finding the best fit for you!{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(404);
          }}
          className={accordionValue == 404 ? "active_help" : ""}
        >
          Where can I find the job description?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 404 ? "block" : "none",
          }}
        >
          Job descriptions can be found on the right-hand side of the job search page. Upon clicking on a job from the job list, the description will be loaded on the right.{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(405);
          }}
          className={accordionValue == 405 ? "active_help" : ""}
        >
          How do I save jobs for future reference?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 405 ? "block" : "none",
          }}
        >
          Click on the <b>STAR </b>icon, and you can see the jobs you have saved.
          {" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(406);
          }}
          className={accordionValue == 406 ? "active_help" : ""}
        >
          How do I share jobs with my connections?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 406 ? "block" : "none",
          }}
        >
          You can click on the <b>SHARE</b> icon, and select the social media platform you wish to share from. Make sure that your accounts are linked to your profile beforehand.{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(407);
          }}
          className={accordionValue == 407 ? "active_help" : ""}
        >
          How do I apply to jobs?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 407 ? "block" : "none",
          }}
        >
          Just click on <b>APPLY JOBS</b> and fill in the required fields, then hit <b>SUBMIT</b>.{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(408);
          }}
          className={accordionValue == 408 ? "active_help" : ""}
        >
          How do I see the jobs I have applied to already?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 408 ? "block" : "none",
          }}
        >
          You can navigate to the <b>JOBS APPLIED </b> tab, located on your dashboard. The status of the jobs you have applied to will be shown on each individual job listed.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(409);
          }}
          className={accordionValue == 409 ? "active_help" : ""}
        >
          Can I share the jobs I have applied to?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 409 ? "block" : "none",
          }}
        >
          Yes, applied jobs can be shared.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(410);
          }}
          className={accordionValue == 410 ? "active_help" : ""}
        >
          How do I remove jobs from my saved jobs list?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 410 ? "block" : "none",
          }}
        >
          Uncheck the <b>STAR</b> icon located next to the job you want to remove from the <b>APPLIED JOBS </b> list.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(411);
          }}
          className={accordionValue == 411 ? "active_help" : ""}
        >
          What are matched jobs?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 411 ? "block" : "none",
          }}
        >
          Matched jobs are opportunities tailored to your preferences, based on the alerts you have set up.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(412);
          }}
          className={accordionValue == 412 ? "active_help" : ""}
        >
          What are suggested jobs?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 412 ? "block" : "none",
          }}
        >
          Suggested jobs are opportunities that cater to your criterias, refined and sought for you, by us.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(413);
          }}
          className={accordionValue == 413 ? "active_help" : ""}
        >
          What are suggested job titles?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 413 ? "block" : "none",
          }}
        >
          These are other job titles from the category of jobs you are looking out for.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(414);
          }}
          className={accordionValue == 414 ? "active_help" : ""}
        >
          Can I set notifications for jobs in my prefered location?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 414 ? "block" : "none",
          }}
        >
          Yes, that’s possible.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(415);
          }}
          className={accordionValue == 415 ? "active_help" : ""}
        >
          Can I set job types in my job alerts?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 415 ? "block" : "none",
          }}
        >
          Yes.{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(416);
          }}
          className={accordionValue == 416 ? "active_help" : ""}
        >
          What are the various job types in JIT Career?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 416 ? "block" : "none",
          }}
        >
          Part time, full time, frelancer and more{" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(417);
          }}
          className={accordionValue == 417 ? "active_help" : ""}
        >
          What are the various job categories in JIT Career?
          <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 417 ? "block" : "none",
          }}
        >
          There are hundreds of categories into which JIT shares the openings.{" "}
        </div>


      </>
    );
  }
  // client  query------------------------------------------------------
  if (active == "Client") {
    tab = (
      <>
        <h1
          onClick={() => {
            handleChangeAccordion(1);
          }}
          className={accordionValue == 1 ? "active_help" : ""}
        >
          Who should register as a client?  <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 1 ? "block" : "none",
          }}
        >
          You should register as a client if you want to hire potential candidates from our manually filtered database.
          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(2);
          }}
          className={accordionValue == 2 ? "active_help" : ""}
        >
          How do I register to be a client? (pending) <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 2 ? "block" : "none",
          }}
        >
          (Will be given later)
          {" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(3);
          }}
          className={accordionValue == 3 ? "active_help" : ""}
        >
          Why should you register? <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 3 ? "block" : "none",
          }}
        >
          Advantages of registering as a client-
          <ol style={{ listStyleType: "disc" }} >

            <li>If the clients team up with companies which possess high career supportive knowledge the chances of  receiving professionals as their employees are high.</li>
            <li>Availability of manually managed or filtered database.</li>
            <li>The experience of HR experts from various countries will be received in sourcing .</li>
            <li>Possibilities of hiring potential candidates from existing database.</li>
            <li>It is reachable to individual freelance HR consultancy.</li>


          </ol>{" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(4);
          }}
          className={accordionValue == 4 ? "active_help" : ""}
        >
          Is my data secure?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 4 ? "block" : "none",
          }}
        >
          Yes, your data is safe with us.
          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(5);
          }}
          className={accordionValue == 5 ? "active_help" : ""}
        >
          Why is there an approval process?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 5 ? "block" : "none",
          }}
        >
          There is an approval process in order to reduce spamming.{" "}
        </div>

      </>
    );
  }
  // Vendor  query------------------------------------------------------
  if (active == "Vendor") {
    tab = (
      <>
        <h1
          onClick={() => {
            handleChangeAccordion(1);
          }}
          className={accordionValue == 1 ? "active_help" : ""}
        >
          Who should register as a Vendor?   <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 1 ? "block" : "none",
          }}
        >
          You can register as a vendor if you can provide us with potential people to get the job done. As per requirement you will be analysing skills and recruiting the potential candidates.
          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(2);
          }}
          className={accordionValue == 2 ? "active_help" : ""}
        >
          How do I register to be a Vendor?  <div className=" arrow_help" />
        </h1>
        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 2 ? "block" : "none",
          }}
        >
          Steps to register as a Vendor are as follows-
          <ol>
            <li>Click the <b>REGISTER</b> as a Vendor button on Home Page. </li>
            <li>Enter your <b>NAME, E-MAIL, MOBILE NUMBER, PASSWORD and CONFIRM </b>the password. </li></ol>
          <b>Mandatory:</b> Registration is followed by Email and Mobile verification using OTP. Once your email and mobile number have been verified, your candidates profile will be complete! Please provide your company details and license in order to get registered as a vendor.

          {" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(3);
          }}
          className={accordionValue == 3 ? "active_help" : ""}
        >
          Why should you register? <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 3 ? "block" : "none",
          }}
        >
          As a vendor you get access to different categories of job and job requirements.
          {" "}
        </div>

        <h1
          onClick={() => {
            handleChangeAccordion(4);
          }}
          className={accordionValue == 4 ? "active_help" : ""}
        >
          Is my data secure?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 4 ? "block" : "none",
          }}
        >
          Yes, your data is safe with us.
          {" "}
        </div>
        <h1
          onClick={() => {
            handleChangeAccordion(5);
          }}
          className={accordionValue == 5 ? "active_help" : ""}
        >
          Why is there an approval process?
          <div className=" arrow_help" />
        </h1>

        <div
          className="acc_con_help"
          style={{
            display: accordionValue == 5 ? "block" : "none",
          }}
        >
          There is an approval process in order to reduce spamming.{" "}
        </div>

      </>
    );
  }

  return (
    <>
      {/* Header Start */}
      <div className="header header1">
        <header>
          <div className=" container">
            <div className="row">
              <div className="col-sm-2">
                <div className=" logo">
                  <a
                    onClick={() => {
                      history.push("/");
                    }}>
                    <img
                      src={require("../../images/logo.png")}
                      // src={require("../../images/logo.png"
                      className="img-responsive"
                      alt=""
                    />{" "}
                  </a>
                </div>
              </div>
              <div className="col-sm-10">
                <div className="bs-example">
                  <nav
                    role="navigation"
                    className="navbar navbar-default navbar-static"
                    id="navbar-example"
                  >
                    <div className="navbar-header">
                      <button
                        data-target=".bs-example-js-navbar-collapse"
                        data-toggle="collapse"
                        type="button"
                        className="navbar-toggle"
                      >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                        <span className="icon-bar" />{" "}
                      </button>
                      <div className="collapse navbar-collapse bs-example-js-navbar-collapse">
                        <ul className="nav navbar-nav">
                          <li>
                            <Link to="/" className={cl === "/" ? "active" : ""}>
                              {" "}
                              Home
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link
                              to="/career_developer"
                              className={
                                cl === "/career_developer" ? "active" : ""
                              }
                            >
                              Career Developer
                            </Link>
                          </li>
                          {/* <li>
                            {" "}
                            <Link
                              to="/coming_soon"
                              className={cl === "/coming_soon" ? "active" : ""}
                            >
                              Client{" "}
                            </Link>
                          </li> */}
                          {/* <li>
                            {" "}
                            <Link
                              to="/vendor"
                              className={cl === "/vendor" ? "active" : ""}
                            >
                              Vendor
                            </Link>{" "}
                          </li> */}
                          <li className="activefix">
                            <Link
                              to={`/help`}
                              className={cl === "/help" ? "active" : ""}
                            >
                              Help
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/about_us"
                              className={cl === "/about_us" ? "active" : ""}
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact_us"
                              className={cl === "/contact_us" ? "active" : ""}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>

                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      {/* Header Ends */}

      {/* Content start */}
      <div className="sub_banner">
        <div className="desk">
          <img
            src={require("../../images/help_banner.jpg")}
            // src={require("../../images/help_banner.jpg"
            className=" img-responsive"
          />
        </div>
        <div className=" mob">
          <img
            src={require("../../images/help_banner_mob.jpg")}
            // src={require("../../images/help_banner_mob.jpg"
            className=" img-responsive"
          />
        </div>
        <div className="career_banner">
          <div className=" container">
            <div className="row">
              <div className="col-sm-6 col-lg-5">
                <div className="career_banner_heading">Help Center </div>
                <div className="career_banner_heading1">
                  We are happy to help you.&#128522;
                </div>
                {/* <div className="help_sec">
                  <div className="help_sec_form">
                    <input
                      name=""
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                  <div className="help_sec_btn">
                    <a href="/help">Find</a>
                  </div>
                  <div className=" clearfix" />
                </div>
              */}
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>

      {/* Content end */}

      {/* ------------------------------ */}
      <div className="help_bg">
        <div className="container">
          <div className="row">
            <div className="help-tabs_sec">
              <div
                id="horizontalTab"
                style={{ display: "block", width: "100%", margin: "0px" }}
              >
                <div className="row">
                  <ul className="resp-tabs-list help-tabs">
                    {_titlesOfFirstTab}
                  </ul>
                </div>
                <div className="resp-tabs-container resp-tab-contents">
                  {/*---------Start-------------------*/}
                  <div
                    className="resp-tab-content resp-tab-content-active"
                    aria-labelledby="tab_item-2"
                    style={{ display: "block" }}
                  >
                    <div id="tabs2" className="c-tabs">

                      {active == "Candidate" ? <div className="c-tabs-nav c-tabs-nav1">

                        {_titleOfSecondTab}
                      </div> : null}




                      <div className="clearfix" />




                      {/* question start */}

                      <div className="c-tab is-active">
                        <div className="c-tab__content c-tab__content1">
                          {/* <div className="accordian_main"> */}

                          <div className="accordian_help_tab">
                            {/*----------------- General Question------------------ */}

                            {tab}

                            {/* </div> */}
                          </div>
                        </div>
                      </div>

                      {/*---------------Innter Tab End-----------------*/}
                    </div>
                  </div>
                  {/*---------End-------------------*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(HelpComponent);
