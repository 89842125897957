import React from "react";
import AchievementsComponent from "./AchievementsComponent";
import AuthService from "./../../../../services/AuthService";

interface IAchievementsContainerProps { }

interface IAchievementsContainerState { }

const initialState = {};

const AchievementsContainer: React.FC<IAchievementsContainerProps> = (props) => {
  const [AchievementsContainerState, setAchievementsContainerState] = React.useState<IAchievementsContainerState>(
    initialState
  );
  return (
    <>
      <AchievementsComponent/>
    </>
  );
};
export default AchievementsContainer;
