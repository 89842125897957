import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { useImmerReducer } from "use-immer";
import { matchedJobViewModel } from "../models/candidate/MatchedJob";
import { IMatchedJobAction, matchedJobReducer } from "../action/MatchedJobAction";


export const MatchedJobDispatcherContext = React.createContext<React.Dispatch<IMatchedJobAction> | null>(null);
export const MatchedJobStateContext = React.createContext<IMatchedJobManagementState | null>(null);

export interface IMatchedJobManagementProps extends RouteComponentProps<any> { }

export interface IMatchedJobManagementState {
    visible:boolean;
    value:number;
    matchedJob:matchedJobViewModel
}

export const initialMatchedJobManagementState = {
    visible:false,
    value:0,
    matchedJob:{} as matchedJobViewModel,
} as IMatchedJobManagementState;

export const MatchedJobContextProvider: React.FC = ({ children }) => {
    const [matchedJobState, dispatcher] = useImmerReducer(matchedJobReducer, initialMatchedJobManagementState);

    return (
        <MatchedJobDispatcherContext.Provider value={dispatcher}>
            <MatchedJobStateContext.Provider value={matchedJobState}>
                {children}
            </MatchedJobStateContext.Provider>
        </MatchedJobDispatcherContext.Provider>
    )
}