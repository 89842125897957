import { DatePicker, Form, Input, Radio } from "antd";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useVendorMyProfileContext } from "../../action/MyProfileVendorAction";
import {
  setVendorJobAppliedSave,
  useVendorJobAppliedContext,
  useVendorJobAppliedDispatcher,
} from "../../action/vendor/VendorJobAppliedAction";
import { jobViewModel } from "../../models/candidate/MatchedJob";
import {
  VendorCandidateList,
  VendorJobAppliedRequestModel,
} from "../../models/vendor/VendorJobApplied";
import AuthService from "../../services/AuthService";

interface IJobSearchDetailedComponentProps {
  jobList: jobViewModel[];
  onHandleSavedJobClick: (id: any) => void;
  onHandleShareButtonClick: (id: any, value: boolean) => void;
}

const JobSearchDetailedComponent: React.FC<IJobSearchDetailedComponentProps> = (
  props
) => {
  //console.log(8787, props.jobList);

  // const authorizationToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8';
  // let loggedUserId=1;

  const authorizationToken = AuthService.accessToken;
  const { jobList, onHandleSavedJobClick, onHandleShareButtonClick } = props;

  const jobAppliedVendorDispatcher = useVendorJobAppliedDispatcher();
  const jobAppliedVendorContext = useVendorJobAppliedContext();
  const {
    vendorJobAppliedSaveRespond,
    vendorGetAllJobList,
  } = jobAppliedVendorContext;

  const vendorMyProfileContext = useVendorMyProfileContext();
  const {
    myProfile,
    loggedUserId,
    profileImage,
    basicInfo,
    myProfileProgressBar,
  } = vendorMyProfileContext;

  const secondButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [jobApplayOpen, setJobApplayOpen] = useState<boolean>(false);
  const [currentJobIdValue, setCurrentJobIdValue] = useState<number>(0);
  const [radioIndexList, setRadioIndexList] = useState<any[]>([]);
  const [radioValue, setRadioValue] = useState<number>(1);
  const [radioValue2, setRadioValue2] = useState<number>(1000);

  React.useEffect(() => {
    if (vendorJobAppliedSaveRespond.isSuccess) {
      Reload();
    }
  }, [vendorJobAppliedSaveRespond]);

  if (jobList.length < 1) {
    return null;
  }

  const handleJobShareClick = (id: any, value: any) => {
    onHandleShareButtonClick(id, value);
  };

  const handleSaveButtonClick = (id: any) => {
    onHandleSavedJobClick(id);
  };

  const handleJobAppliedClick = (id: any) => {
    if (secondButtonRef.current != null) {
      secondButtonRef.current.click();
    }
    setJobApplayOpen(!jobApplayOpen);
    setCurrentJobIdValue(id);
  };
  const onFinish = (values: any) => {
    //console.log("Received values of form:", values);

    let vendorCandidateListArray = [
      {
        rowId: 0,
        dob: values.dob != undefined ? values.dob._d : null,
        expDate: values.expiryDate != undefined ? values.expiryDate._d : null,
        firstName: values.firstName,
        isActive: true,
        isActiveVisa:
          values.visaIssueCountry === "" ||
          values.expiryDate == null ||
          values.visaIssueCountry == undefined ||
          values.expiryDate == undefined
            ? false
            : true,
        lastName: values.lastName,
        middleName: values.middleName,
        passPortNumber: values.passportNo,
        visaIssueCountry: values.visaIssueCountry,
      },
    ] as VendorCandidateList[];

    if (values.users != undefined && values.users.length > 0) {
      values.users.forEach((user: any) => {
        vendorCandidateListArray.push({
          rowId: 0,
          dob: user.dob != undefined ? user.dob._d : null,
          visaIssueCountry: user.visaIssueCountry,
          passPortNumber: user.passportNo,
          middleName: user.middleName,
          lastName: user.lastName,
          isActiveVisa:
            user.visaIssueCountry === "" ||
            user.expiryDate == null ||
            user.visaIssueCountry == undefined ||
            user.expiryDate == undefined
              ? false
              : true,
          isActive: true,
          firstName: user.firstName,
          expDate: user.expiryDate != undefined ? user.expiryDate._d : null,
        });
      });
    }
    if (authorizationToken != null) {
      (async () => {
        await setVendorJobAppliedSave(
          jobAppliedVendorDispatcher,
          {
            jobId: currentJobIdValue,
            rowId: 0,
            vendorCandidateList: vendorCandidateListArray,
            vendorId: loggedUserId,
            IsActive: true,
          } as VendorJobAppliedRequestModel,
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
          authorizationToken
        );
      })();
    }
  };

  const Reload = () => {
    window.location.reload();
  };

  const handleRemoveIndex = (indexValue: any) => {
    const item = radioIndexList.filter((value, index) => {
      return value != indexValue;
    });
    setRadioIndexList(item);
  };

  const handleOnRadioButtonChange = (e: any) => {
    if (e.target.value.charAt(0) == "f") {
      const item = radioIndexList.filter((value, index) => {
        return value != parseInt(e.target.value.substring(1));
      });
      setRadioIndexList(item);
    } else {
      setRadioIndexList((radioIndexList: any) => [
        ...radioIndexList,
        parseInt(e.target.value.substring(1)),
      ]);
    }
  };

  return (
    <>
      <h2
        className="resp-accordion resp-tab-active"
        role="tab"
        aria-controls="tab_item-0"
      >
        <span className="resp-arrow" />
        <div className="job_search_icon">
          <img
            src={require("../../images/designer_icon.png")}
            width={52}
            height={52}
          />
        </div>
        <div className="job_search_con">
          <div className="job_search_con_head"></div>
          <div className="job_search_con_con">
            <i className="fa fa-map-marker" aria-hidden="true" /> Abudhabi
          </div>
        </div>
        <div className="job_search_social">
          <div>
            <i className="fa fa-share-alt" aria-hidden="true" />
          </div>
          <div>
            {jobList[0].isBookmarked ? (
              <i className="fa fa-star" aria-hidden="true" />
            ) : (
              <i className="fa fa-star-o" aria-hidden="true" />
            )}
            <i className="fa fa-star-o" aria-hidden="true" />
          </div>
        </div>
        <div className="clearfix" />
        <div className="job_fulltime">{jobList[0].jobType}</div>
        <div className="job_search_exp">{jobList[0].experience}</div>
        <div className="job_search_post">{jobList[0].experience} </div>
      </h2>
      <div
        className="resp-tab-content resp-tab-content-active"
        aria-labelledby="tab_item-0"
        style={{ display: "block" }}
      >
        <div
          className="jobs_searh"
          style={{ overflow: "hidden", outline: "none" }}
          tabIndex={0}
        >
          <div className="jobs_right_details">
            <img
              src={require("../../images/job_search_details_img.png")}
              className="img-responsive"
            />
          </div>
          <div className="jobs_right_details_con">
            <div className="jobs_right_sec">
              <h1>{jobList[0].title}</h1>
              <h2>
                <i className="fa fa-map-marker" aria-hidden="true" /> USA
              </h2>
            </div>
            <div className="jobs_right_sec1">
              <div className="jobs_apply1">
                {jobList[0].isApplied ? (
                  <a>Job Applied</a>
                ) : (
                  <a onClick={() => handleJobAppliedClick(jobList[0].jobId)}>
                    Apply Now
                  </a>
                )}
                {/* <a >{jobList[0].isApplied?'Job Applied':'Apply Now'}</a> */}
              </div>
              <div className="job_search_social1">
                <a>
                  <i
                    className="fa fa-share-alt"
                    onClick={() => handleJobShareClick(jobList[0].jobId, true)}
                    aria-hidden="true"
                  />{" "}
                </a>
                <a onClick={() => handleSaveButtonClick(jobList[0].jobId)}>
                  {jobList[0].isBookmarked ? (
                    <i className="fa fa-star" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-star-o" aria-hidden="true" />
                  )}
                </a>
              </div>
              <div className="clearfix" />
              <div className="jobs_right_posted">
                Posted {jobList[0].postedDate}{" "}
              </div>
            </div>
            <div className="clearfix" />
            <div className="jobs_right_details_sec">
              <div className="col-sm-3 jobs_right_details_br">
                <div className="jobs_right_details_sec_con">Employer</div>
                <div className="jobs_right_details_sec_con1">JIT Marine</div>
              </div>
              <div className="col-sm-3 jobs_right_details_br">
                <div className="jobs_right_details_sec_con">Designation</div>
                <div className="jobs_right_details_sec_con1">
                  {jobList[0].categoryName}
                </div>
              </div>
              <div className="col-sm-3 jobs_right_details_br">
                <div className="jobs_right_details_sec_con">Type</div>
                <div className="jobs_right_details_sec_con1">
                  {jobList[0].jobType}
                </div>
              </div>
              <div className="col-sm-3">
                <div className="jobs_right_details_sec_con">Experience</div>
                <div className="jobs_right_details_sec_con1">
                  {jobList[0].experience}
                </div>
              </div>
            </div>
            <div className="clearfix" />
            <h3>Overview</h3>
            <p>
              we belive that design (and you) will be critical to the company
              success. You will work with our founders and our early customers
              to help define and build our core product funtionality, while
              maintaining the quality bar that customers have come tto expect
              from modern SaaS applications.{" "}
            </p>
            <h3>Job description</h3>
            <div>
              <img
                src={require("../../images/job_search_arrow.png")}
                className="jobs_right_aroow"
              />{" "}
              8+ Years working as aproduct designer
            </div>
            <div>
              <img
                src={require("../../images/job_search_arrow.png")}
                className="jobs_right_aroow"
              />{" "}
              A portfolio that highlights your approach to problem solving, as
              well as your skills UI.
            </div>
            <div>
              <img
                src={require("../../images/job_search_arrow.png")}
                className="jobs_right_aroow"
              />{" "}
              Experience conducting research and building out smooth flows for
              different types of users.
            </div>
            <div>
              <img
                src={require("../../images/job_search_arrow.png")}
                className="jobs_right_aroow"
              />{" "}
              Excellent communication skills with a well-defined design process.
            </div>
            <div>
              <img
                src={require("../../images/job_search_arrow.png")}
                className="jobs_right_aroow"
              />{" "}
              Familiarity with design tools like Sketch and Figma.
            </div>
            <div>
              <img
                src={require("../../images/job_search_arrow.png")}
                className="jobs_right_aroow"
              />{" "}
              Up-level our overall design and bring consistency to end-user
              facing properties.
            </div>
            <h3>Vacancies : 7</h3>
            <h3>Minimum Qualification</h3>
            <p>
              we belive that design (and you) will be critical to the company
              success. You will work with our founders and our early customers
              to help define and build our core product funtionality, while
              maintaining the quality bar that customers have come tto expect
              from modern SaaS applications.{" "}
            </p>
            <h3>Skills Required</h3>
            <p>
              we belive that design (and you) will be critical to the company
              success. You will work with our founders and our early customers
              to help define and build our core product funtionality, while
              maintaining the quality bar that customers have come tto expect
              from modern SaaS applications.{" "}
            </p>
            <h3>Salanry Range</h3>
            <p>
              we belive that design (and you) will be critical to the company
              success. You will work with our founders and our early customers
              to help define and build our core product funtionality, while
              maintaining the quality bar that customers have come tto expect
              from modern SaaS applications.{" "}
            </p>
            <h3>Documents Required</h3>
            <p>
              we belive that design (and you) will be critical to the company
              success. You will work with our founders and our early customers
              to help define and build our core product funtionality, while
              maintaining the quality bar that customers have come tto expect
              from modern SaaS applications.{" "}
            </p>
            <h3>Other</h3>
            <p>
              we belive that design (and you) will be critical to the company
              success. You will work with our founders and our early customers
              to help define and build our core product funtionality, while
              maintaining the quality bar that customers have come tto expect
              from modern SaaS applications.{" "}
            </p>
          </div>
        </div>

        <Modal
          show={jobApplayOpen}
          onHide={() => {
            setJobApplayOpen(!jobApplayOpen);
            setRadioIndexList([]);
          }}
        >
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
          >
            {/* <form onSubmit={handleSubmit3(handleJobSubmit)} noValidate > */}
            <Modal.Header closeButton>
              <Modal.Title>Apply Now</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="candidate_no">C001</div>
              <div
                className="close_icon _cursor-pointer"
                style={{ display: "none" }}
              >
                <img
                  src={require("./../../images/close_icon.png")}
                  width={16}
                  height={16}
                  style={{ marginTop: 26 }}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="email">Candidate First Name</label>
                <Form.Item
                  name="firstName"
                  rules={[{ required: true, message: "First  Name Missing " }]}
                >
                  <Input placeholder="" style={{ height: 39 }} />
                </Form.Item>
              </div>

              <div className="col-sm-4">
                <label htmlFor="email">Middle Name</label>
                <Form.Item
                  name="middleName"
                  rules={[{ required: true, message: "Middle Name Missing " }]}
                >
                  <Input placeholder="" style={{ height: 39 }} />
                </Form.Item>
              </div>

              <div className="col-sm-4">
                <label htmlFor="email">Last Name</label>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: "Last Name Missing " }]}
                >
                  <Input placeholder="" style={{ height: 39 }} />
                </Form.Item>
              </div>

              <div className="col-sm-4">
                <label htmlFor="email">Passport No</label>
                <Form.Item
                  name="passportNo"
                  rules={[{ required: true, message: "Passport No Missing " }]}
                >
                  <Input placeholder="" style={{ height: 39 }} />
                </Form.Item>
              </div>

              <div className="col-sm-4">
                <label htmlFor="email">DOB</label>
                <Form.Item
                  name="dob"
                  rules={[{ required: true, message: "DOB Missing " }]}
                >
                  {/* <Input placeholder=""  />               */}
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    style={{ height: 39, width: 196 }}
                  />
                </Form.Item>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="email">Active Visa?</label>
                  <div className="row">
                    <Form.Item
                      name="activeVisa"
                      rules={[{ required: false, message: "DOB Missing " }]}
                    >
                      <Radio.Group
                        defaultValue={radioValue}
                        onChange={
                          radioValue == 1
                            ? () => {
                                setRadioValue(2);
                              }
                            : () => {
                                setRadioValue(1);
                              }
                        }
                      >
                        <div className="col-xs-6">
                          <Radio value={1}>Yes</Radio>
                        </div>
                        <div className="col-xs-6">
                          <Radio value={2}>No</Radio>
                        </div>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="clearfix" />

              <div
                className="col-sm-4"
                style={{ display: radioValue != 1 ? "none" : "" }}
              >
                <label htmlFor="email">Visa Issuing Country</label>
                <Form.Item
                  name="visaIssueCountry"
                  rules={[
                    {
                      required: radioValue != 1 ? false : true,
                      message: "Visa Issuing Country Missing ",
                    },
                  ]}
                >
                  <Input placeholder="" style={{ height: 39 }} />
                </Form.Item>
              </div>

              <div
                className="col-sm-4"
                style={{ display: radioValue != 1 ? "none" : "" }}
              >
                <label htmlFor="email">Expiry Date</label>
                <Form.Item
                  name="expiryDate"
                  rules={[
                    {
                      required: radioValue != 1 ? false : true,
                      message: "expiryDate Missing ",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    style={{ height: 39, width: 196 }}
                  />
                </Form.Item>
              </div>

              <Form.List name="users">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div style={{ paddingTop: 120 }}>
                        <div className="candidate_no">C00{index + 2}</div>
                        <div className="close_icon _cursor-pointer">
                          <img
                            onClick={() => {
                              remove(field.name);
                              handleRemoveIndex(index);
                            }}
                            src={require("./../../images/close_icon.png")}
                            width={16}
                            height={16}
                            style={{ marginTop: 26 }}
                          />
                        </div>
                        <div className="col-sm-4">
                          <label htmlFor="email">Candidate First Name</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "firstName"]}
                            fieldKey={[field.fieldKey, "firstName"]}
                            rules={[
                              { required: true, message: "Missing first name" },
                            ]}
                          >
                            <Input placeholder="" style={{ height: 39 }} />
                          </Form.Item>
                        </div>

                        <div className="col-sm-4">
                          <label htmlFor="email">Middle Name</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "middleName"]}
                            fieldKey={[field.fieldKey, "middleName"]}
                            rules={[
                              {
                                required: true,
                                message: "Middle Name Missing ",
                              },
                            ]}
                          >
                            <Input placeholder="" style={{ height: 39 }} />
                          </Form.Item>
                        </div>

                        <div className="col-sm-4">
                          <label htmlFor="email">Last Name</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "lastName"]}
                            fieldKey={[field.fieldKey, "lastName"]}
                            rules={[
                              { required: true, message: "Last Name Missing " },
                            ]}
                          >
                            <Input placeholder="" style={{ height: 39 }} />
                          </Form.Item>
                        </div>

                        <div className="col-sm-4">
                          <label htmlFor="email">Passport No</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "passportNo"]}
                            fieldKey={[field.fieldKey, "passportNo"]}
                            rules={[
                              {
                                required: true,
                                message: "Passport No Missing ",
                              },
                            ]}
                          >
                            <Input placeholder="" style={{ height: 39 }} />
                          </Form.Item>
                        </div>

                        <div className="col-sm-4">
                          <label htmlFor="email">DOB</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "dob"]}
                            fieldKey={[field.fieldKey, "dob"]}
                            rules={[
                              { required: true, message: "DOB Missing " },
                            ]}
                          >
                            {/* <Input placeholder=""  />               */}
                            <DatePicker
                              placeholder="YYYY-MM-DD"
                              style={{ height: 39, width: 196 }}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-group">
                            <label htmlFor="email">Active Visa?</label>
                            <div className="row">
                              <Form.Item
                                {...field}
                                name={[field.name, "activeVisa"]}
                                fieldKey={[field.fieldKey, "activeVisa"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "activeVisa Missing ",
                                  },
                                ]}
                              >
                                <Radio.Group
                                  // onChange={radioValue2!=index?()=>{setRadioValue2(index)}:()=>{setRadioValue2(1000)}}
                                  onChange={handleOnRadioButtonChange}
                                  defaultValue={`f${index}`}
                                >
                                  <div className="col-xs-6">
                                    <Radio defaultChecked value={`f${index}`}>
                                      Yes
                                    </Radio>
                                  </div>
                                  <div className="col-xs-6">
                                    <Radio value={`s${index}`}>No</Radio>
                                  </div>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <div className="clearfix" />

                        <div
                          className="col-sm-4"
                          style={{
                            display: radioIndexList.includes(index)
                              ? "none"
                              : "",
                          }}
                        >
                          <label htmlFor="email">Visa Issuing Country</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "visaIssueCountry"]}
                            fieldKey={[field.fieldKey, "visaIssueCountry"]}
                            rules={[
                              {
                                required: radioIndexList.includes(index)
                                  ? false
                                  : true,
                                message: "Visa Issuing Country Missing ",
                              },
                            ]}
                          >
                            <Input placeholder="" style={{ height: 39 }} />
                          </Form.Item>
                        </div>

                        <div
                          className="col-sm-4"
                          style={{
                            display: radioIndexList.includes(index)
                              ? "none"
                              : "",
                          }}
                        >
                          <label htmlFor="email">Expiry Date</label>
                          <Form.Item
                            {...field}
                            name={[field.name, "expiryDate"]}
                            fieldKey={[field.fieldKey, "expiryDate"]}
                            rules={[
                              {
                                required: radioIndexList.includes(index)
                                  ? false
                                  : true,
                                message: "expiryDate Missing ",
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="YYYY-MM-DD"
                              style={{ height: 39, width: 196 }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    ))}
                    <div className="col-sm-4">
                      <Form.Item>
                        <div className="form-group m_t_30">
                          <label htmlFor="email"></label>
                          <button
                            ref={secondButtonRef}
                            className="btn btn-success save-event waves-effect waves-light"
                            type="button"
                            onClick={() => add()}
                          >
                            Add Candidates
                          </button>
                        </div>
                      </Form.Item>
                    </div>
                  </>
                )}
              </Form.List>
            </Modal.Body>
            <div className="modal-footer  m-t-30">
              <button
                className="btn btn-success save-event waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setJobApplayOpen(false);
                }}
                data-dismiss="modal"
                className="btn btn-default waves-effect"
                type="submit"
              >
                Cancel
              </button>
            </div>
            <div className="clearfix" />
            {/* </form> */}
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default JobSearchDetailedComponent;
