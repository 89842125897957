import React from "react";
import MyCoverLetterComponent from "./MyCoverLetterComponent";


interface IMyCoverLetterContainerProps { }

interface IMyCoverLetterContainerState { }

// const initialState = {};

const MyCoverLetterContainer: React.FC<IMyCoverLetterContainerProps> = (props) => {
  // const [myCoverLetterContainerState, setMyCoverLetterContainerState] = React.useState<IMyCoverLetterContainerState>(
  //   initialState
  // );

  return (
    <>
      <MyCoverLetterComponent />
    </>
  );
};
export default MyCoverLetterContainer;
