import { Reducer } from "react";
import {
  IJobTypeManagementState,
  JobTypeDispatcherContext,
  JobTypeStateContext,
} from "../../context/general/JobTypeContext";
import React from "react";

import axios from "axios";
import AuthService from "../../services/AuthService";
import { AppUrls } from "../../environments/environment";
import {
  countAllRequestModel,
  countAllResultModel,
  jobTypeRequestmodel,
  jobTypeViewModel,
  VendorCountAllRequestModel,
  VendorCountAllResultModel,
} from "../../models/general/JobType";

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, "") : "";

export type IJobTypeAction =
  | {
      type: "SET_Job Type_LIST";
      jobType: jobTypeViewModel;
    }
  | {
      type: "GET ALL COUNT";
      allCounts: countAllResultModel;
    }| {
      type: "GET ALL VENDOR COUNT";
      vendorGetAllCount: VendorCountAllResultModel;
    };

export const jobTypeReducer: Reducer<
  IJobTypeManagementState,
  IJobTypeAction
> = (draft, action): IJobTypeManagementState => {
  switch (action.type) {
    case "SET_Job Type_LIST":
      draft.jobType = action.jobType;
      return draft;
    case "GET ALL COUNT":
      draft.allCounts = action.allCounts;
      return draft;
      case "GET ALL VENDOR COUNT":
        draft.vendorGetAllCount = action.vendorGetAllCount;
        return draft;
  }
};

export const useJobTypeDispatcher = (): React.Dispatch<IJobTypeAction> => {
  const jobTypeDispatcher = React.useContext(JobTypeDispatcherContext);
  if (!jobTypeDispatcher) {
    throw new Error(
      "You have to provide the JobType dispatcher using theJobTypeDispatcherContext.Provider in a parent component."
    );
  }
  return jobTypeDispatcher;
};

export const useJobTypeContext = (): IJobTypeManagementState => {
  const jobTypeContext = React.useContext(JobTypeStateContext);
  if (!jobTypeContext)
    throw new Error(
      "You have to provide the jobType context using the JobTypeStateContext.Provider in a parent component."
    );
  return jobTypeContext;
};

export const getJobTypeList = async (
  dispatcher: React.Dispatch<IJobTypeAction>,
  query: jobTypeRequestmodel
) => {
  try {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authorizationToken}`,
    };

    const url = AppUrls.GetAllJobType;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "SET_Job Type_LIST", jobType: res.data });
    });
  } catch (e) {}
};

export const getAllCount = async (
  dispatcher: React.Dispatch<IJobTypeAction>,
  query: countAllRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const url = AppUrls.GetAllCount;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "GET ALL COUNT", allCounts: res.data });
    });
  } catch (e) {}
};


export const getAllVendorCount = async (
  dispatcher: React.Dispatch<IJobTypeAction>,
  query: VendorCountAllRequestModel,
  token: string
) => {
  try {
    if (token?.startsWith(`"`)) {
      token = token.substring(1);
      token = token.slice(0, -1);
    }

    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

     const url = AppUrls.GetAllVendorCount;

    axios.post(url, JSON.stringify(query), { headers: header }).then((res) => {
      dispatcher({ type: "GET ALL VENDOR COUNT", vendorGetAllCount: res.data });
    });
  } catch (e) {}
};