import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  SetAccessible,
  useGlobelCounterDispatcher,
} from "../../../action/general/GlobelCounterAction";
import { sendPhoneOtp, verifyCode } from "../../../apis/misc";
import { useGlobalSpinnerContext } from "../../../context/GlobalSpinner";

const DigiLockerOtpModal = (props: any) => {
  let history = useHistory();
  const { basicInfo, d1 } = props;
  const [isShow, setIsShow] = useState(true);
  const globelCounterDispatcher = useGlobelCounterDispatcher();
  const globalSpinner = useGlobalSpinnerContext();
  const [currentOpt, setCurrentOpt] = useState("");

  React.useEffect(() => {
    if (
      basicInfo !== undefined &&
      basicInfo.phoneNumber !== undefined &&
      basicInfo.phoneNumber !== ""
    ) {
      // if (isAccessible && diffTime > -60) {
      // } else {
      sendPhoneOtp(
        {},
        `?countryCode=${encodeURIComponent(
          basicInfo.countryCode === "" ? "+91" : basicInfo.countryCode
        )}&phoneNumber=${encodeURIComponent(basicInfo.phoneNumber)}`
      )
        .then((res: any) => {
          globalSpinner.hideSpinner();
          if (res.data.includes("error") || res.data.includes("Invalid")) {
            toast.error(res.data);
          } else {
            // toast.success("OTP has been send.");
            toast.success(
              "OTP has been successfully sent on your mobile number."
            );

            // setShowTimer(true);
          }
        })
        .catch((err: any) => {
          globalSpinner.hideSpinner();
          toast.error("Something went wrong");
        });
      // }
    }
  }, []);

  const handleSubmitButtonClick = () => {
    verifyCode(
      {},
      //`?code=${data["otp"]}&phoneNumber=${encodeURIComponent((query.get("countryCode") || "") + (query.get("mobileNumber") || ""))}`
      `?code=${currentOpt}&countryCode=${encodeURIComponent(
        basicInfo.countryCode || ""
      )}&phoneNumber=${encodeURIComponent(basicInfo.phoneNumber || "")}`
    ).then((res: any) => {
      if (res.data === "OTP is verified.") {
        (() => {
          SetAccessible(globelCounterDispatcher, true, d1.getTime());
        })();
      }
    });
  };

  return (
    <>
      <ToastContainer />

      <Modal
        show={isShow}
        onHide={() => {
          setIsShow(!isShow);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="job_search_forms9">
            <div className="cr_serach_br" style={{ width: 635 }}>
              <input
                name="otp"
                // ref={register({ required: false })}
                type="text"
                className="form-control"
                placeholder="please enter your OTP"
                onChange={(e: any) => setCurrentOpt(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer  m-t-30">
          <button
            className="btn btn-success"
            style={{
              marginLeft: -225,
              backgroundColor: "green",
            }}
            type="button"
            onClick={handleSubmitButtonClick}
          >
            Submit
          </button>

          <button
            onClick={() => history.push("/candidate")}
            className="btn btn-default"
            type="button"
          >
            cancel
          </button>
        </div>

        <div className="clearfix" />
      </Modal>
    </>
  );
};

export default DigiLockerOtpModal;
