import {
  Form,
  Popover,
  Tooltip,
  DatePicker,
  Input,
  Popconfirm,
  Radio,
} from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getProfileProgressBarResult,
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import {
  setVendorJobAppliedSave,
  useVendorJobAppliedContext,
  useVendorJobAppliedDispatcher,
} from "../../../action/vendor/VendorJobAppliedAction";
import {
  getAllCoverLetterCandidateMap,
  getAllResumeCandidateMap,
} from "../../../apis/resumebuilder";
import { environment } from "../../../environments/environment";
import { jobViewModel } from "../../../models/candidate/MatchedJob";
import { profileImageRequestModel } from "../../../models/candidate/MyProfileSelectBoxData";
import { ResumeAllList } from "../../../models/candidate/Resume";
import {
  VendorCandidateList,
  VendorJobAppliedRequestModel,
} from "../../../models/vendor/VendorJobApplied";
import AuthService from "../../../services/AuthService";
import JobAppliedForm from "../../candidate/jobs_Applied/JobAppliedForm";
import moment from "moment";
import { CoverletterDataResult } from "../../../models/candidate/CoverLetter";
import { Link } from "react-router-dom";

interface ISearchJobDetailsProps {
  searchResultDataSateDetails: jobViewModel[] | null;
  handleJobBookMarked: (jobId: any) => void;
  handleShareButtonClick: (jobId: any) => void;
  roleData: string;
  documentStatus: string;
}

const SearchJobDetails: React.FC<ISearchJobDetailsProps> = (props) => {
  const {
    searchResultDataSateDetails,
    handleJobBookMarked,
    handleShareButtonClick,
    roleData,
    documentStatus,
  } = props;
  //console.log(88, searchResultDataSateDetails);
  const authorizationToken = AuthService.accessToken;

  const [isJobAppliedOpen, setIsJobAppliedOpen] = useState<boolean>(false);
  const [currentJobId, setCurrentJobId] = useState<number>(0);
  const [jobApplayOpen, setJobApplayOpen] = useState<boolean>(false);
  const secondButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [jobStatusOPen, setJobStatusOPen] = useState<boolean>(false);
  const [radioIndexList, setRadioIndexList] = useState<any[]>([]);
  const [currentJobIdValue, setCurrentJobIdValue] = useState<number>(0);
  const [radioValue, setRadioValue] = useState<number>(1);
  const [candidateCoverLetters, setCandidateCoverLetters] = useState(
    {} as CoverletterDataResult
  );
  const myProfileDispatcher = useMyProfileDispatcher();
  const myProfileContext = useMyProfileContext();
  const { loggedUserId, myProfile, myProfileProgressBar } = myProfileContext;
  const jobAppliedVendorDispatcher = useVendorJobAppliedDispatcher();
  const jobAppliedVendorContext = useVendorJobAppliedContext();
  const {
    vendorJobAppliedSaveRespond,
    vendorGetAllJobList,
  } = jobAppliedVendorContext;
  const [candidateResumes, setCandidateResumes] = useState({} as ResumeAllList);
  const onHandleJobBookMarkrd = (jobId: any) => {
    handleJobBookMarked(jobId);
  };

  const onHandleSharedJobClick = (jobId: any) => {
    handleShareButtonClick(jobId);
  };
  let role = AuthService.currentUser?.roles[0];
  React.useEffect(() => {
    if (authorizationToken != null && loggedUserId != 0) {
      (async () => {
        await getProfileProgressBarResult(
          myProfileDispatcher,
          {
            candidateId: loggedUserId,
            page: 1,
            pageSize: 10,
            searchTerm: "",
            showInactive: false,
            sortOrder: "",
          } as profileImageRequestModel,
          authorizationToken
        );
      })();
    }
  }, [loggedUserId]);

  useEffect(() => {
    if (loggedUserId) {
      getAllResumeCandidateMap({
        CandidateId: loggedUserId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          ////console.log(res.data);
          setCandidateResumes(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, [loggedUserId]);

  useEffect(() => {
    if (loggedUserId) {
      getAllCoverLetterCandidateMap({
        CandidateId: loggedUserId,
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      })
        .then((res) => {
          ////console.log(res.data);
          setCandidateCoverLetters(res.data);
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [loggedUserId]);

  const handleJobOpen = () => {
    setIsJobAppliedOpen(!isJobAppliedOpen);
  };

  const handleJobApplayClick = (id: any) => {
    setCurrentJobId(id);
    handleJobOpen();
  };
  React.useEffect(() => {
    // if (authorizationToken != null) {
    setJobApplayOpen(false);
    setJobStatusOPen(false);
    // }
  }, [vendorJobAppliedSaveRespond]);

  const handleJobAppliedClick = (id: any) => {
    if (secondButtonRef.current != null) {
      secondButtonRef.current.click();
    }
    //console.log(1000, 10, 10);
    setJobApplayOpen(!jobApplayOpen);
    setCurrentJobIdValue(id);
    setCurrentJobId(id);
  };

  function disabledDate(current: any) {
    // Can not select days before today and today

    return current && current < moment().endOf("day");
  }

  const handleRemoveIndex = (indexValue: any) => {
    const item = radioIndexList.filter((value, index) => {
      return value != indexValue;
    });
    setRadioIndexList(item);
  };

  const handleOnRadioButtonChange = (e: any) => {
    if (e.target.value.charAt(0) == "f") {
      const item = radioIndexList.filter((value, index) => {
        return value != parseInt(e.target.value.substring(1));
      });
      setRadioIndexList(item);
    } else {
      setRadioIndexList((radioIndexList: any) => [
        ...radioIndexList,
        parseInt(e.target.value.substring(1)),
      ]);
    }
  };

  const onFinish = (values: any) => {
    //console.log("Received values of form:", values);

    let vendorCandidateListArray = [
      {
        rowId: 0,
        dob: values.dob != undefined ? values.dob._d : null,
        expDate: values.expiryDate != undefined ? values.expiryDate._d : null,
        firstName: values.firstName,
        isActive: true,
        isActiveVisa:
          values.visaIssueCountry === "" ||
          values.expiryDate == null ||
          values.visaIssueCountry == undefined ||
          values.expiryDate == undefined
            ? false
            : true,
        lastName: values.lastName,
        middleName: values.middleName,
        passPortNumber: values.passportNo,
        visaIssueCountry: values.visaIssueCountry,
      },
    ] as VendorCandidateList[];

    if (values.users != undefined && values.users.length > 0) {
      values.users.forEach((user: any) => {
        vendorCandidateListArray.push({
          rowId: 0,
          dob: user.dob != undefined ? user.dob._d : null,
          visaIssueCountry: user.visaIssueCountry,
          passPortNumber: user.passportNo,
          middleName: user.middleName,
          lastName: user.lastName,
          isActiveVisa:
            user.visaIssueCountry === "" ||
            user.expiryDate == null ||
            user.visaIssueCountry == undefined ||
            user.expiryDate == undefined
              ? false
              : true,
          isActive: true,
          firstName: user.firstName,
          expDate: user.expiryDate != undefined ? user.expiryDate._d : null,
        });
      });
    }
    if (authorizationToken != null) {
      (async () => {
        await setVendorJobAppliedSave(
          jobAppliedVendorDispatcher,
          {
            jobId: currentJobId,
            rowId: 0,
            vendorCandidateList: vendorCandidateListArray,
            vendorId: loggedUserId,
            IsActive: true,
          } as VendorJobAppliedRequestModel,
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
          authorizationToken
        );
      })();
    }
  };

  if (
    searchResultDataSateDetails !== null &&
    searchResultDataSateDetails !== undefined &&
    searchResultDataSateDetails.length === 0
  ) {
    return null;
  } else {
    return (
      <>
        <div className="resp-tabs-container jobsearch-tabs-content">
          {/*----------------Start-------------*/}
          {/* <h2
          className="resp-accordion resp-tab-active"
          role="tab"
          aria-controls="tab_item-0"
        >
          <span className="resp-arrow" />
          <div className="job_search_icon">
            <img 
              src={environment.baseUrl+`/Upload/SuperAdminProfileImage/${searchResultDataSateDetails!==null?searchResultDataSateDetails[0].jobBackground:null}`}
            
            />
          </div>
          <div className="job_search_con">
            <div className="job_search_con_head">Designer</div>
            <div className="job_search_con_con">
              <i className="fa fa-map-marker" aria-hidden="true" /> Abudhabi1
            </div>
          </div>
          <div className="job_search_social">
            <div>
              <i className="fa fa-share-alt" aria-hidden="true" />
            </div>
            <div>
              <i className="fa fa-star-o" aria-hidden="true" />
            </div>
          </div>
          <div className="clearfix" />
          <div className="job_fulltime">Full Time</div>
          <div className="job_search_exp">0-2 Years</div>
          <div className="job_search_post">New 1 d </div>
        </h2>
         */}
          <div
            className="resp-tab-content resp-tab-content-active"
            aria-labelledby="tab_item-0"
            style={{ display: "block" }}
          >
            <div
              className="jobs_searh"
              style={{ overflow: "hidden", outline: "none" }}
              tabIndex={0}
            >
              <InfiniteScroll
                className="_custom-infinite-scroll-class"
                height={900}
                dataLength={200}
                // dataLength={2000}
                // dataLength={totalCount}
                next={() => {}}
                hasMore={true}
                loader={<h4></h4>}
                // initialScrollY={indexState * 80}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>No more data to load</b>
                  </p>
                }
              >
                <div className="jobs_right_details">
                  {searchResultDataSateDetails !== null ? (
                    searchResultDataSateDetails[0].jobBackground !== null &&
                    searchResultDataSateDetails[0].jobBackground !== "" &&
                    searchResultDataSateDetails[0].jobBackground !== "null" ? (
                      <img
                        style={{ width: 882, height: 250 }}
                        src={
                          environment.baseUrl +
                          `/Upload/JobIconImage/${
                            searchResultDataSateDetails !== null
                              ? searchResultDataSateDetails[0].jobBackground
                              : require("./../../../images/job_search_details_img.png")
                          }`
                        }
                        className="img-responsive"
                      />
                    ) : (
                      <img
                        src={require("../../../images/job_search_details_img.png")}
                        className="img-responsive"
                      />
                    )
                  ) : (
                    <img
                      src={require("../../../images/job_search_details_img.png")}
                      className="img-responsive"
                    />
                  )}
                </div>
                <div className="jobs_right_details_con">
                  <div className="jobs_right_sec">
                    <h1>
                      {searchResultDataSateDetails !== null
                        ? searchResultDataSateDetails[0].title
                        : null}
                    </h1>
                    <h2>
                      <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                      {searchResultDataSateDetails !== null
                        ? searchResultDataSateDetails[0].location
                        : null}
                    </h2>
                  </div>
                  <div className="jobs_right_sec1">
                    <div className="jobs_apply1">
                      {authorizationToken != null ? (
                        searchResultDataSateDetails != null ? (
                          role === "Candidate" ? (
                            searchResultDataSateDetails[0].isApplied ? (
                              <a>
                                {" "}
                                {searchResultDataSateDetails[0]
                                  .jobAppliedStatus == null
                                  ? "Applied"
                                  : searchResultDataSateDetails[0]
                                      .jobAppliedStatus}
                              </a>
                            ) : myProfileProgressBar.profileProgress < 40 ? (
                              <Popover
                                content={
                                  //"Please complete your profile to minimum 40% to access this feature."
                                  <>
                                    <p>
                                      Please complete your profile to minimum
                                      40% to access this feature.
                                    </p>
                                    <Link to="/candidate/my-profile">
                                      Complete Now
                                    </Link>
                                  </>
                                }
                              ></Popover>
                            ) : (
                              <a
                                onClick={() =>
                                  handleJobApplayClick(
                                    searchResultDataSateDetails !== null
                                      ? searchResultDataSateDetails[0].jobId
                                      : null
                                  )
                                }
                              >
                                Apply Now
                              </a>
                            )
                          ) : // end of candidate
                          roleData == "Vendor" ? (
                            documentStatus !== undefined &&
                            documentStatus.includes("Rejected") ? (
                              <Popover
                                content={"Your Document is Rejected"}
                                trigger="hover"
                              >
                                <a>Apply Now</a>
                              </Popover>
                            ) : (
                              <a
                                onClick={(jobId: any) =>
                                  handleJobAppliedClick(
                                    searchResultDataSateDetails !== null
                                      ? searchResultDataSateDetails[0].jobId
                                      : null
                                  )
                                }
                              >
                                Apply Now
                              </a>
                            )
                          ) : null
                        ) : null
                      ) : (
                        <Tooltip title="Please login with your registered email and password to apply for this job post">
                          <a className="jobs_apply1Btn1">Apply Now</a>
                        </Tooltip>
                      )}
                    </div>
                    <div className="job_search_social1">
                      {searchResultDataSateDetails !== null ? (
                        <a
                          onClick={() =>
                            onHandleSharedJobClick(
                              searchResultDataSateDetails[0].jobId
                            )
                          }
                        >
                          <i className="fa fa-share-alt" aria-hidden="true" />{" "}
                        </a>
                      ) : (
                        <a onClick={() => onHandleSharedJobClick(0)}>
                          <i className="fa fa-share-alt" aria-hidden="true" />{" "}
                        </a>
                      )}
                      <a>
                        {authorizationToken !== null ? (
                          searchResultDataSateDetails !== null ? (
                            searchResultDataSateDetails[0].isBookmarked ? (
                              <i
                                className="fa fa-star"
                                aria-hidden="true"
                                onClick={() =>
                                  onHandleJobBookMarkrd(
                                    searchResultDataSateDetails[0].jobId
                                  )
                                }
                              />
                            ) : (
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                                onClick={() =>
                                  onHandleJobBookMarkrd(
                                    searchResultDataSateDetails[0].jobId
                                  )
                                }
                              />
                            )
                          ) : null
                        ) : null}
                      </a>
                    </div>
                    <div className="clearfix" />
                    <div className="jobs_right_posted">
                      {searchResultDataSateDetails !== null
                        ? searchResultDataSateDetails[0].isApplied
                          ? `${searchResultDataSateDetails[0].daysAgo} days ago`
                          : searchResultDataSateDetails[0].postedDate
                        : null}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="jobs_right_details_sec">
                    <div className="col-sm-3 jobs_right_details_br">
                      <div className="jobs_right_details_sec_con">Employer</div>
                      <div className="jobs_right_details_sec_con1">
                        JIT Marine
                      </div>
                    </div>
                    <div className="col-sm-3 jobs_right_details_br">
                      <div className="jobs_right_details_sec_con">
                        Category Name
                      </div>
                      <div className="jobs_right_details_sec_con1">
                        {searchResultDataSateDetails !== null
                          ? searchResultDataSateDetails[0].categoryName
                          : null}
                      </div>
                    </div>
                    <div className="col-sm-3 jobs_right_details_br">
                      <div className="jobs_right_details_sec_con">Type</div>
                      <div className="jobs_right_details_sec_con1">
                        {searchResultDataSateDetails !== null
                          ? searchResultDataSateDetails[0].jobType
                          : null}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="jobs_right_details_sec_con">
                        Experience
                      </div>
                      <div className="jobs_right_details_sec_con1">
                        {searchResultDataSateDetails !== null
                          ? searchResultDataSateDetails[0].experience
                          : null}{" "}
                        years
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <h3>Overview</h3>
                  <p>
                    {searchResultDataSateDetails !== null
                      ? searchResultDataSateDetails[0].jobOverview
                      : null}
                    .{" "}
                  </p>
                  <h3>Job description</h3>
                  <div>
                    {/* <img
                    src="images/job_search_arrow.png"
                    className="jobs_right_aroow"
                  />{" "} */}
                    {searchResultDataSateDetails !== null
                      ? searchResultDataSateDetails[0].description
                      : null}
                  </div>
                  {/* <div>
                  <img
                    src="images/job_search_arrow.png"
                    className="jobs_right_aroow"
                  />{" "}
                  A portfolio that highlights your approach to problem solving,
                  as well as your skills UI.
                </div>
                <div>
                  <img
                    src="images/job_search_arrow.png"
                    className="jobs_right_aroow"
                  />{" "}
                  Experience conducting research and building out smooth flows
                  for different types of users.
                </div>
                <div>
                  <img
                    src="images/job_search_arrow.png"
                    className="jobs_right_aroow"
                  />{" "}
                  Excellent communication skills with a well-defined design
                  process.
                </div>
                <div>
                  <img
                    src="images/job_search_arrow.png"
                    className="jobs_right_aroow"
                  />{" "}
                  Familiarity with design tools like Sketch and Figma.
                </div>
                <div>
                  <img
                    src="images/job_search_arrow.png"
                    className="jobs_right_aroow"
                  />{" "}
                  Up-level our overall design and bring consistency to end-user
                  facing properties.
                </div>
                */}
                  <h3>
                    Vacancies :{" "}
                    {searchResultDataSateDetails !== null
                      ? searchResultDataSateDetails[0].numberOfVacancies
                      : null}
                  </h3>
                  <h3>Minimum Qualification</h3>
                  <p>
                    {searchResultDataSateDetails !== null
                      ? searchResultDataSateDetails[0].minQualification
                      : null}
                    .{" "}
                  </p>
                  <h3>Skills Required</h3>
                  <p>
                    {searchResultDataSateDetails !== null
                      ? searchResultDataSateDetails[0].skillRequired
                      : null}
                    .{" "}
                  </p>
                  <h3>Salanry Range</h3>
                  <p>
                    {searchResultDataSateDetails !== null
                      ? searchResultDataSateDetails[0].minAnnualSalary
                      : null}{" "}
                    -
                    {searchResultDataSateDetails !== null
                      ? searchResultDataSateDetails[0].maxAnnualSalary
                      : null}
                  </p>
                  <h3>Documents Required</h3>
                  <p>
                    {searchResultDataSateDetails !== null
                      ? searchResultDataSateDetails[0].documentsRequired
                      : null}
                    .{" "}
                  </p>
                  <h3>Other</h3>
                  <p>
                    {searchResultDataSateDetails !== null
                      ? searchResultDataSateDetails[0].others
                      : null}
                    .{" "}
                  </p>
                </div>
              </InfiniteScroll>
            </div>
          </div>

          <Modal
            show={jobApplayOpen}
            onHide={() => {
              setJobApplayOpen(!jobApplayOpen);
              setRadioIndexList([]);
            }}
          >
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
            >
              {/* <form onSubmit={handleSubmit3(handleJobSubmit)} noValidate > */}
              <Modal.Header closeButton>
                <Modal.Title>Apply Now</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="candidate_no">C001</div>
                <div
                  className="close_icon _cursor-pointer"
                  style={{ display: "none" }}
                >
                  <img
                    src={require("./../../../images/close_icon.png")}
                    width={16}
                    height={16}
                    style={{ marginTop: 26 }}
                  />
                </div>
                <div className="col-sm-4">
                  <label htmlFor="email">Candidate First Name</label>
                  <Form.Item
                    name="firstName"
                    rules={[
                      { required: true, message: "First  Name Missing " },
                    ]}
                  >
                    <Input placeholder="" style={{ height: 39 }} />
                  </Form.Item>
                </div>

                <div className="col-sm-4">
                  <label htmlFor="email">Middle Name</label>
                  <Form.Item
                    name="middleName"
                    rules={[
                      { required: false, message: "Middle Name Missing " },
                    ]}
                  >
                    <Input placeholder="" style={{ height: 39 }} />
                  </Form.Item>
                </div>

                <div className="col-sm-4">
                  <label htmlFor="email">Last Name</label>
                  <Form.Item
                    name="lastName"
                    rules={[{ required: true, message: "Last Name Missing " }]}
                  >
                    <Input placeholder="" style={{ height: 39 }} />
                  </Form.Item>
                </div>

                <div className="col-sm-4">
                  <label htmlFor="email">Passport No</label>
                  <Form.Item
                    name="passportNo"
                    rules={[
                      { required: true, message: "Passport No Missing " },
                    ]}
                  >
                    <Input placeholder="" style={{ height: 39 }} />
                  </Form.Item>
                </div>

                <div className="col-sm-4">
                  <label htmlFor="email">DOB</label>
                  <Form.Item
                    name="dob"
                    rules={[{ required: true, message: "DOB Missing " }]}
                  >
                    {/* <Input placeholder=""  />               */}
                    <DatePicker
                      placeholder="YYYY-MM-DD"
                      style={{ height: 39, width: 196 }}
                    />
                  </Form.Item>
                </div>

                <div className="col-sm-4">
                  <div className="form-group">
                    <label htmlFor="email">Active Visa?</label>
                    <div className="row">
                      <Form.Item
                        name="activeVisa"
                        rules={[{ required: false, message: "DOB Missing " }]}
                      >
                        <Radio.Group
                          defaultValue={radioValue}
                          onChange={
                            radioValue == 1
                              ? () => {
                                  setRadioValue(2);
                                }
                              : () => {
                                  setRadioValue(1);
                                }
                          }
                        >
                          <div className="col-xs-6">
                            <Radio value={1}>Yes</Radio>
                          </div>
                          <div className="col-xs-6">
                            <Radio value={2}>No</Radio>
                          </div>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="clearfix" />

                <div
                  className="col-sm-4"
                  style={{ display: radioValue != 1 ? "none" : "" }}
                >
                  <label htmlFor="email">Visa Issuing Country</label>
                  <Form.Item
                    name="visaIssueCountry"
                    rules={[
                      {
                        required: radioValue != 1 ? false : true,
                        message: "Visa Issuing Country Missing ",
                      },
                    ]}
                  >
                    <Input placeholder="" style={{ height: 39 }} />
                  </Form.Item>
                </div>

                <div
                  className="col-sm-4"
                  style={{ display: radioValue != 1 ? "none" : "" }}
                >
                  <label htmlFor="email">Expiry Date</label>
                  <Form.Item
                    name="expiryDate"
                    rules={[
                      {
                        required: radioValue != 1 ? false : true,
                        message: "expiryDate Missing ",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="YYYY-MM-DD"
                      disabledDate={disabledDate}
                      style={{ height: 39, width: 196 }}
                    />
                  </Form.Item>
                </div>

                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div style={{ paddingTop: 120 }}>
                          <div className="candidate_no">C00{index + 2}</div>
                          <div className="close_icon _cursor-pointer">
                            <img
                              onClick={() => {
                                remove(field.name);
                                handleRemoveIndex(index);
                              }}
                              src={require("./../../../images/close_icon.png")}
                              width={16}
                              height={16}
                              style={{ marginTop: 26 }}
                            />
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="email">Candidate First Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "firstName"]}
                              fieldKey={[field.fieldKey, "firstName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing first name",
                                },
                              ]}
                            >
                              <Input placeholder="" style={{ height: 39 }} />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Middle Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "middleName"]}
                              fieldKey={[field.fieldKey, "middleName"]}
                              rules={[
                                {
                                  required: false,
                                  message: "Middle Name Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" style={{ height: 39 }} />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Last Name</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "lastName"]}
                              fieldKey={[field.fieldKey, "lastName"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Last Name Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" style={{ height: 39 }} />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">Passport No</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "passportNo"]}
                              fieldKey={[field.fieldKey, "passportNo"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Passport No Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" style={{ height: 39 }} />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="email">DOB</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "dob"]}
                              fieldKey={[field.fieldKey, "dob"]}
                              rules={[
                                { required: true, message: "DOB Missing " },
                              ]}
                            >
                              {/* <Input placeholder=""  />               */}
                              <DatePicker
                                placeholder="YYYY-MM-DD"
                                style={{ height: 39, width: 196 }}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="email">Active Visa?</label>
                              <div className="row">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "activeVisa"]}
                                  fieldKey={[field.fieldKey, "activeVisa"]}
                                  rules={[
                                    {
                                      required: false,
                                      message: "activeVisa Missing ",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    // onChange={radioValue2!=index?()=>{setRadioValue2(index)}:()=>{setRadioValue2(1000)}}
                                    onChange={handleOnRadioButtonChange}
                                    defaultValue={`f${index}`}
                                  >
                                    <div className="col-xs-6">
                                      <Radio defaultChecked value={`f${index}`}>
                                        Yes
                                      </Radio>
                                    </div>
                                    <div className="col-xs-6">
                                      <Radio value={`s${index}`}>No</Radio>
                                    </div>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="clearfix" />

                          <div
                            className="col-sm-4"
                            style={{
                              display: radioIndexList.includes(index)
                                ? "none"
                                : "",
                            }}
                          >
                            <label htmlFor="email">Visa Issuing Country</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "visaIssueCountry"]}
                              fieldKey={[field.fieldKey, "visaIssueCountry"]}
                              rules={[
                                {
                                  required: radioIndexList.includes(index)
                                    ? false
                                    : true,
                                  message: "Visa Issuing Country Missing ",
                                },
                              ]}
                            >
                              <Input placeholder="" style={{ height: 39 }} />
                            </Form.Item>
                          </div>

                          <div
                            className="col-sm-4"
                            style={{
                              display: radioIndexList.includes(index)
                                ? "none"
                                : "",
                            }}
                          >
                            <label htmlFor="email">Expiry Date</label>
                            <Form.Item
                              {...field}
                              name={[field.name, "expiryDate"]}
                              fieldKey={[field.fieldKey, "expiryDate"]}
                              rules={[
                                {
                                  required: radioIndexList.includes(index)
                                    ? false
                                    : true,
                                  message: "expiryDate Missing ",
                                },
                              ]}
                            >
                              <DatePicker
                                placeholder="YYYY-MM-DD"
                                style={{ height: 39, width: 196 }}
                                disabledDate={disabledDate}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                      <div className="col-sm-4">
                        <Form.Item>
                          <div className="form-group m_t_30">
                            <label htmlFor="email"></label>
                            <button
                              ref={secondButtonRef}
                              className="btn btn-success save-event waves-effect waves-light"
                              type="button"
                              onClick={() => add()}
                            >
                              Add Candidates
                            </button>
                          </div>
                        </Form.Item>
                      </div>
                    </>
                  )}
                </Form.List>
              </Modal.Body>
              <div className="modal-footer  m-t-30">
                <button
                  className="btn btn-success save-event waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setJobApplayOpen(false);
                  }}
                  data-dismiss="modal"
                  className="btn btn-default waves-effect"
                  type="submit"
                >
                  Cancel
                </button>
              </div>
              <div className="clearfix" />
              {/* </form> */}
            </Form>
          </Modal>

          <JobAppliedForm
            isJobAppliedOpen={isJobAppliedOpen}
            handleJobOpen={handleJobOpen}
            currentJobId={currentJobId}
            loggedUserId={loggedUserId}
            authorizationToken={authorizationToken}
            candidateResumes={candidateResumes}
            candidateCoverLetters={candidateCoverLetters}
          />

          {/*----------------End-------------*/}
          {/*----------------Start-------------*/}
        </div>
      </>
    );
  }
};

export default SearchJobDetails;
