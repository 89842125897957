import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import SelectOption from "./components/SelectOption";
import { Modal } from "react-bootstrap";
import {
  getCandidateSkillSelectBoxData,
  saveCandidateSkill,
  deleteCandidateSkill,
} from "./../../../apis/candidate";
import { GlobalSpinnerContext } from "./../../../context/GlobalSpinner";
import { toast, ToastContainer } from "react-toastify";
import { confirmWrapper, confirm } from "./../../GlobalConfirm";
import { ErrorMessage } from "@hookform/error-message";
import IsComponentDisabled from "./../../components/IsComponentDisabled";

interface ReactSelectOption {
  value: string;
  label: string;
}

interface ISkillsState {
  skillName: string;
  proficiency: string;
}

function Skill({
  index,
  item,
  proficiencies,
  disabled,
  removeSkill,
  updateSkill,
}: {
  index: any;
  item: any;
  proficiencies: any[];
  disabled: boolean;
  removeSkill: any;
  updateSkill: any;
}) {
  const defaultValues = {
    skillName: item["skillName"],
    proficiency: item["proficiencyId"] + "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ISkillsState>({
    defaultValues,
  });

  const onSubmit = (data: any) => {
    if (!disabled) {
      //console.log(data);

      item["skillName"] = data["skillName"];
      item["proficiencyId"] = Number(data["proficiency"]);

      updateSkill(item, index);
    }
  };

  return (
    <div className=" profile_sec_bg">
      <div
        className="close_icon1 _cursor-pointer"
        onClick={() => {
          removeSkill(item, index);
        }}
      >
        <img
          src={require("./../../../images/close_icon.png")}
          width={16}
          height={16}
        />
      </div>
      <div className="row">
        <form
          className="personal_details"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Skill Name
              </label>
              {IsComponentDisabled(
                <input
                  type="text"
                  disabled={disabled}
                  className={"form-control validate input-pwd"}
                  placeholder="Type here"
                  name="skillName"
                  ref={register({
                    required: "Skill Name is required",
                  })}
                />,
                disabled
              )}
              <ErrorMessage
                errors={errors}
                name="skillName"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Proficiency
              </label>
              {IsComponentDisabled(
                <span className="select-wrapper-sec">
                  <Controller
                    rules={{
                      required: "Proficiency is required",
                      validate: function (value) {
                        //console.log(value);
                        return Number(value)
                          ? undefined
                          : "Proficiency is required";
                      },
                    }}
                    control={control}
                    name="proficiency"
                    render={({ onChange, onBlur, value, name }) => (
                      <SelectOption
                        values={proficiencies}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                      />
                    )}
                  />
                </span>,
                disabled
              )}
            </div>
          </div>
          <div className="col-sm-12">
            {/* <a onClick={() => { setIsOpen(!isOpen) }} className="btn submit_btn">Add Skills</a> */}
            <button
              type="submit"
              disabled={disabled}
              className="btn submit_btn"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function Skills(props: any) {
  const {
    getCandidateSkills,
    candidateSkillsState,
    userId,
    candidateId,
  } = props;

  //console.log(candidateSkillsState);
  //console.log(userId);
  //console.log(candidateId);

  const defaultValues = {
    skillName: "",
    proficiency: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useForm<ISkillsState>({
    defaultValues,
  });

  const [disabled, setDisabled] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const globalSpinner = useContext(GlobalSpinnerContext);

  const [proficiencies, setProficiencies] = useState([]);

  const onSubmit = (data: any) => {
    //console.log(data);
    globalSpinner.showSpinner();
    saveCandidateSkill({
      RowId: 0,
      CandidateSkillId: 0,
      CandidateId: Number(candidateId),
      SkillName: data["skillName"],
      ProficiencyId: Number(data["proficiency"]),
      IsActive: true,
    })
      .then((res) => {
        getCandidateSkills({
          CandidateId: Number(candidateId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "string",
          SortOrder: "string",
          ShowInactive: false,
        });
        if (res.data.isSuccess) {
          toast.success("Skill details are saved.");
        } else {
          toast.error(res.data.message);
        }
        globalSpinner.hideSpinner();
        setIsOpen(!isOpen);
      })
      .catch((err) => {
        //console.log(err);
        globalSpinner.hideSpinner();
        toast.error(err.toString());
      });
  };

  useEffect(() => {
    getCandidateSkillSelectBoxData()
      .then((res: any) => {
        //console.log(res.data);
        const proficiencies = res.data.proficiencies.map((e: any) => {
          return { value: e["value"], label: e["caption"] };
        });
        setProficiencies(proficiencies);
      })
      .catch((err: any) => {
        //console.log(err);
      });
  }, []);

  const updateSkill = async (e: any, i: any) => {
    //console.log(e, i);
    globalSpinner.showSpinner();
    saveCandidateSkill(e)
      .then((res) => {
        //console.log(res);
        globalSpinner.hideSpinner();
        if (res.data.isSuccess) {
          getCandidateSkills({
            CandidateId: Number(candidateId),
            Page: 1,
            PageSize: 10,
            SearchTerm: "string",
            SortOrder: "string",
            ShowInactive: false,
          });
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        //console.log(err);
        globalSpinner.hideSpinner();
        toast.error(err.toString());
      });
  };

  const removeSkill = async (e: any, i: any) => {
    if (
      await confirm({
        confirmation: "Are you sure you want to delete this?",
        options: {
          yes: "Yes",
          no: "No",
          header: "Delete",
        },
      })
    ) {
      //console.log("yes");
      //console.log(e, i);
      globalSpinner.showSpinner();
      deleteCandidateSkill({ RowId: e.rowId })
        .then((res) => {
          //console.log(res);
          globalSpinner.hideSpinner();
          if (res.data.isSuccess) {
            getCandidateSkills({
              CandidateId: Number(candidateId),
              Page: 1,
              PageSize: 10,
              SearchTerm: "string",
              SortOrder: "string",
              ShowInactive: false,
            });
            toast.success("Skill deleted successfully");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          //console.log(err);
          globalSpinner.hideSpinner();
          toast.error(err.toString());
        });
    } else {
      //console.log("no");
      //console.log(e, i);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <h2 className="resp-accordion" role="tab" aria-controls="tab_item-6">
        <span className="resp-arrow" />
        Skills
      </h2>
      <div
        className="resp-tab-content"
        aria-labelledby="tab_item-6"
        style={{ display: "block" }}
      >
        <div className="profile_top">
          <h1>Skills</h1>
          <div className="profile_edit">
            <a
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <i className="fa fa-pencil _cursor-pointer" aria-hidden="true" />
            </a>
          </div>
        </div>

        <div>
          <a
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="btn add_btn"
            style={{ marginBottom: "10px" }}
          >
            Add Skills
          </a>
        </div>

        {candidateSkillsState.data.map((e: any, i: any) => (
          <Skill
            index={i}
            key={i}
            item={e}
            disabled={disabled}
            proficiencies={proficiencies}
            removeSkill={removeSkill}
            updateSkill={updateSkill}
          />
        ))}

        {/* <div className=" profile_sec_bg">
          <div className="close_icon1"><img src={require("./../../../images/close_icon.png")} width={16} height={16} /></div>
          <div className="row">

            {candidateSkillsState.data.map((e: any, i: any) => <Skill key={i} item={e} disabled={disabled} proficiencies={proficiencies} />)}

            <form className="personal_details">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">Skill Name</label>
                  <span className="select-wrapper-sec"><select name="timepass" className="custom-select-sec">
                    <option value="">Text</option>
                    <option value="">Text</option>
                    <option value="">Text</option>
                  </select><span className="holder">Text</span></span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">Proficiency</label>
                  <span className="select-wrapper-sec"><select name="timepass" className="custom-select-sec">
                    <option value="">Text</option>
                    <option value="">Text</option>
                    <option value="">Text</option>
                  </select><span className="holder">Text</span></span>
                </div>
              </div>
            </form>
          </div>
        </div> */}

        {/* <div className="col-sm-12">
          <a onClick={() => { setIsOpen(!isOpen) }} className="btn submit_btn">Add Skills</a>
        </div> */}
      </div>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!isOpen);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Add Skills</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Skill Name
              </label>
              <input
                type="text"
                className="form-control validate input-pwd"
                placeholder="Type here"
                name="skillName"
                ref={register({
                  required: "Skill Name is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="skillName"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
              {/* <span className="select-wrapper-sec">
                <Controller
                  control={control}
                  name="skillName"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption values={skillNames} disabled={false} onChange={onChange} onBlur={onBlur} value={value} name={name} />
                  )}
                />
              </span> */}
            </div>
            <div className="form-group">
              <label htmlFor="email" className={"required"}>
                Proficiency
              </label>
              <span className="select-wrapper-sec">
                <Controller
                  rules={{
                    required: "Proficiency is required",
                    validate: function (value) {
                      //console.log(value);
                      return Number(value)
                        ? undefined
                        : "Proficiency is required";
                    },
                  }}
                  control={control}
                  name="proficiency"
                  render={({ onChange, onBlur, value, name }) => (
                    <SelectOption
                      values={proficiencies}
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                    />
                  )}
                />
              </span>
              <ErrorMessage
                errors={errors}
                name="proficiency"
                render={({ message }) => (
                  <div className="profile_validation">{message}</div>
                )}
              />
            </div>
          </Modal.Body>
          <div className="modal-footer  m-t-30">
            <button
              className="btn btn-success save-event waves-effect waves-light"
              type="submit"
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              data-dismiss="modal"
              className="btn btn-default waves-effect"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="clearfix" />
        </form>
      </Modal>
    </React.Fragment>
  );
}

export default Skills;
