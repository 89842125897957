import React from "react";
import IndexContentContainer from "./indexcontent/IndexContentContainer";
import IndexFooterComponent from "./indexFooter/IndexFooterComponent";
import IndexFooterContainer from "./indexFooter/IndexFooterContainer";
import IndexHeaderContainer from "./indexHeader/IndexHeaderContainer";

interface IIndexComponentProps {}

interface IIndexComponentState {}

const initialState = {};

const IndexComponent: React.FC<IIndexComponentProps> = (props) => {
  const [indexComponentState, setIndexComponentState] = React.useState<
    IIndexComponentState
  >(initialState);

  return (
    <>
      <IndexHeaderContainer />
      <IndexContentContainer />
      <IndexFooterContainer />
    </>
  );
};
export default IndexComponent;
