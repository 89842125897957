import React, { useEffect, useState } from "react";
import {
  useMyProfileContext,
  useMyProfileDispatcher,
} from "../../../action/MyProfileAction";
import {
  getVendorLoggedUserId,
  getVendorMyProfileSelectBoxList,
  useVendorMyProfileContext,
  useVendorMyProfileDispatcher,
} from "../../../action/MyProfileVendorAction";
import { getDocumentStatus, getProfileProgress } from "./../../../apis/vendor";
import {
  useSocialAccountContext,
  useVendorContext,
  useVendorProfileImageContext,
} from "./../../../context/vendor/VendorMyProfile";
import AuthService from "./../../../services/AuthService";
import VendorProfileComponent from "./VendorProfileComponent";

interface IVendorProfileContainerProps {}

interface IVendorProfileContainerState {}

const initialState = {};

const VendorProfileContainer: React.FC<IVendorProfileContainerProps> = (
  props
) => {
  // Api For SelectBox Data
  const authorizationToken = AuthService.accessToken;
  const userId = AuthService.currentUser?.id;

  const myProfileDispatcher = useMyProfileDispatcher();
  const myProfileContext = useMyProfileContext();

  const myProfileVendorDispatcher = useVendorMyProfileDispatcher();
  const myProfileVendorContext = useVendorMyProfileContext();
  const { myProfile, basicInfo, loggedUserId } = myProfileVendorContext;

  const vendorId = loggedUserId;

  console.log("userId", userId, "vendorId", vendorId);

  const [
    VendorExperienceSelectBoxData,
    setVendorExperienceSelectBoxData,
  ] = useState({});

  const [
    VendorRelativeSelectBoxData,
    setVendorRelativeSelectBoxData,
  ] = useState({});

  const VendorContext = useVendorContext();
  const socialAccountContext = useSocialAccountContext();
  const VendorProfileImageContext = useVendorProfileImageContext();

  const { socialAccounts, getSocialAccounts } = socialAccountContext;
  const {
    vendorProfileImage,
    getVendorProfileImage,
  } = VendorProfileImageContext;

  const [progressBar, setProgressBar] = useState(0);
  const [documentStatus, setDocumentStatus] = useState({});
  const { getVendors, vendorState } = VendorContext;

  // const [socialAccounts, setSocialAccounts] = useState([]);

  console.log(888888888888888, loggedUserId, userId);

  useEffect(() => {
    if (vendorId) {
      console.log(vendorId, "@@@@@/////////// ", vendorState);
      getVendors({
        VendorId: Number(vendorId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "",
        SortOrder: "",
        ShowInactive: false,
      });

      getProfileProgress({
        VendorId: Number(vendorId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log(
            "@@@@@@@@@@@@@@@vendorstate selected candidate@@@@@@@@@ ",
            res.data
          );
          setProgressBar(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      getDocumentStatus({
        VendorId: Number(vendorId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      })
        .then((res) => {
          console.log(
            "@@@@@@@@@@@@@@@uploaded-document-status@@@@@@@@@ ",
            res.data
          );
          setDocumentStatus(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      getSocialAccounts({
        VendorId: Number(vendorId),
        Page: 1,
        PageSize: 10,
        SearchTerm: "string",
        SortOrder: "string",
        ShowInactive: false,
      });

      if (vendorProfileImage.data.length <= 0) {
        getVendorProfileImage({
          VendorId: Number(vendorId),
          Page: 1,
          PageSize: 10,
          SearchTerm: "string",
          SortOrder: "string",
          ShowInactive: false,
        });
      }
    }
  }, [vendorId]);

  React.useEffect(() => {
    (async () => {
      await getVendorMyProfileSelectBoxList(myProfileVendorDispatcher);
    })();
  }, [loggedUserId]);

  React.useEffect(() => {
    if (userId && authorizationToken !== null)
      (async () => {
        await getVendorLoggedUserId(
          myProfileVendorDispatcher,
          Number(userId),
          authorizationToken
        );
      })();
  }, [userId]);

  const docStatusRefresh = () => {
    getDocumentStatus({
      VendorId: Number(vendorId),
      Page: 1,
      PageSize: 10,
      SearchTerm: "string",
      SortOrder: "string",
      ShowInactive: false,
    })
      .then((res) => {
        console.log(
          "@@@@@@@@@@@@@@@uploaded-document-status@@@@@@@@@ ",
          res.data
        );
        setDocumentStatus(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <VendorProfileComponent
        myProfile={myProfile}
        userId={userId}
        vendorId={vendorId}
        vendorState={vendorState}
        getVendors={getVendors}
        progressBar={progressBar}
        documentStatus={documentStatus}
        socialAccounts={socialAccounts}
        getSocialAccounts={getSocialAccounts}
        getVendorProfileImage={getVendorProfileImage}
        vendorProfileImage={vendorProfileImage}
        docStatusRefresh={docStatusRefresh}
      />
    </>
  );
};
export default VendorProfileContainer;
