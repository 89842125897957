import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Link, useHistory, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import AuthService from "../../../../services/AuthService";

import {
  // getMessageUserList,
  useAdminCommunicationDispatcher,
  useAdminCommunicationContext,
} from "../../../../action/admin/AdminCommunicationAction";
import {
  MessagesRequestModel,
  MessagesUserAllListModel,
} from "../../../../models/admin/AdminCommunication";
import {
  GlobalSpinnerContext,
  useGlobalSpinnerContext,
} from "../../../../context/GlobalSpinner";
// import { useAdminMyProfileContext } from "../../../../action/MyProfileAdminAction";
import {
  getAdminLoggedUserId,
  getAdminMyProfileBasicInfo,
  useAdminMyProfileContext,
  useAdminMyProfileDispatcher,
} from "../../../../action/MyProfileAdminAction";
import {
  sendChatFiles,
  getMessageList,
  updateReadStatus,
} from "../../../../apis/vendor";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { Modal } from "react-bootstrap";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import FileUploads from "../../../../component/candidate/my_profile/components/FileUploads";
import { environment } from "../../../../environments/environment";
import { useAdminMyJobContext } from "../../../../action/admin/AdminMyJobAction";
import { getCommunicationUserList } from "../../../../apis/admin";

type objectIndexing = {
  [key: string]: any;
};

function ShowFileInfo(props: any) {
  const { obj } = props;
  //console.log(props);
  return (
    <React.Fragment>
      <div className="col-sm-6">
        {obj.file && (
          <div
            className="update_con"
            style={{
              width: 268,
              height: 105,
              paddingTop: 20,
            }}
          >
            {obj.file.name}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

function MessageUserList(props: any) {
  const {
    filter,
    userList,
    //setCheckedListArray,
    //checkedListArray,
    adminCommunicationDispatcher,
    // setSelectedMessage,
    // handleDeleteMessage,
    setUserShowList,
    userShowList,
    handleReceiveUser,
    globalSpinner,
    senderEmail,
    adminId,
    getMessageUserListNonContext,
  } = props;
  // const [isCheckedAll, setIsCheckedAll] = useState(false);
  //const [isUnCheckedAll, setIsUnCheckedAll] = useState(false);
  //const [isUserListOpen, setIsUserListOpen] = useState<boolean>(false);
  const [hasMore, setHasMore] = React.useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSize = 10;
  const [totalPage, setTotalPage] = useState(10);

  React.useEffect(() => {
    //console.log(currentPage);
    //console.log(currentPage, totalPage);
    //console.log(totalCount);
    if (userList.data != undefined && currentPage == 1) {
      //console.log("userList");
      //console.log(userList);
      //setCurrentPage(1);
      setHasMore(userList.hasNext);
      setTotalPage(Math.ceil(userList.total / currentPageSize));
      setTotalCount(userList.total);
      setUserShowList(userList.data);
      //console.log(userShowList);
    }
    if (userList.data != undefined && currentPage > 1) {
      //console.log("userList2");
      //console.log(userList);
      setHasMore(userList.hasNext);
      setUserShowList([...userList.data, ...userShowList]);
    }
  }, [userList]);

  const fetchMoreData = async () => {
    //console.log("hhh");
    //console.log(currentPage, totalPage);
    //console.log(totalCount);
    // //console.log(adminUserId);
    // if (currentPage >= totalPage) {
    //   setHasMore(false);
    //   // return;
    // } else {
    setCurrentPage(currentPage + 1);
    // (async () => {
    //   globalSpinner.showSpinner();
    //   await getMessageUserList(adminCommunicationDispatcher, {
    //     UserId: Number(adminId),
    //     Page: currentPage + 1,
    //     PageSize: currentPageSize,
    //     SearchTerm: filter, //"",
    //     SortOrder: "",
    //   } as MessagesRequestModel);
    //   globalSpinner.hideSpinner();
    // })();
    getMessageUserListNonContext(
      adminId,
      currentPage + 1,
      currentPageSize,
      filter
    );

    // }
  };
  // const handleAllChecked = (e: any) => {
  //   let IdArray = [] as any[];
  //   if (e.target.checked && userShowList != undefined) {
  //     IdArray = [];
  //     userShowList
  //       .map((d: any) => {
  //         //console.log(d)
  //         if (Number(d.transactionCount) > 0) {
  //           IdArray.push(d.messageTypeId);
  //         }
  //       });
  //     setIsCheckedAll(e.target.checked);
  //     setCheckedListArray(IdArray);
  //     setIsUnCheckedAll(false);
  //   } else {
  //     IdArray = [];
  //     setCheckedListArray(IdArray);
  //     setIsCheckedAll(false);
  //     setIsUnCheckedAll(false);
  //   }

  // };

  // const handleAllUnChecked = (e: any) => {
  //   let IdArray = [] as any[];
  //   if (e.target.checked) {
  //     IdArray = [];
  //     setCheckedListArray(IdArray);
  //     setIsCheckedAll(false);
  //   }
  //   setIsUnCheckedAll(e.target.checked);
  // };

  // const handleChecked = (e: any, item: any) => {
  //   //console.log("hhhhoooo")
  //   let IdArray = [] as any[];
  //   if (e.target.checked) {
  //     IdArray.push(...checkedListArray, item.messageTypeId);
  //   } else {
  //     IdArray = checkedListArray.filter((data: any) => {
  //       //console.log(IdArray)
  //       //console.log(data)
  //       //console.log(item)
  //       return data !== item.messageTypeId;
  //     });
  //   }
  //   setCheckedListArray(IdArray);
  // }

  // React.useEffect(() => {
  //   if (checkedListArray.length > 0 && checkedListArray.length < 2) {
  //     let sel = userShowList.filter((data: any) => data.messageTypeId == checkedListArray[0])[0]
  //     setSelectedMessage(sel);
  //   }
  //   else {
  //     setSelectedMessage({ messageTypeId: 0, messageBody: "", messageSubject: "" });
  //   }
  // }, [checkedListArray.length]);
  // React.useEffect(() => {
  //   if (currentPage >= totalPage) {
  //     setHasMore(false);
  //   }
  // }, [currentPage]);
  return (
    <React.Fragment>
      <div className=" m-t-25">
        {/* <div className="row">
          <div className="col-sm-4 col-lg-4 pad_r_0">
            <input id="checkboxsel" type="checkbox" name="checkboxsel"
              checked={isCheckedAll}
              onChange={(e: any) => handleAllChecked(e)}
            />
            <label htmlFor="checkboxsel"><span /> Select All</label></div>
          <div className="col-sm-5 col-lg-5 pad_r_0">
            <input id="checkboxusel" type="checkbox" name="checkboxusel"
              checked={isUnCheckedAll}
              onChange={(e: any) => handleAllUnChecked(e)} />
            <label htmlFor="checkboxusel"><span /> Deselect All</label></div>
          <div className="col-sm-3 col-lg-3">
            
          </div>
         
        </div> */}
        <div
          className="message_chat_scroll"
          style={{ overflow: "hidden" }}
          tabIndex={0}
        >
          <div className="connect_scroll">
            {userShowList != undefined && adminId > 0 && (
              <ul>
                <InfiniteScroll
                  className="_custom-infinite-scroll-class"
                  height={600}
                  dataLength={userShowList.length}
                  // dataLength={2000}
                  // dataLength={totalCount}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>No more data to load</b>
                    </p>
                  }
                >
                  {userShowList.map((item: any, index: any) => {
                    // const lowercasedFilter = filter.toLowerCase();
                    // //console.log(String(item["email"]).includes(senderEmail))
                    // //console.log(item["email"])
                    // //console.log(senderEmail)
                    if (!String(item["email"]).includes(senderEmail)) {
                      return (
                        <li>
                          {" "}
                          <a
                            onClick={() => {
                              handleReceiveUser(
                                item["userId"],
                                item["userName"],
                                item["isOnline"]
                              );
                              // showSelectedConversation(item["messageTypeId"], item["messageSubject"], item["messageBody"], true)
                              // //console.log(item);
                            }}
                            className="admin_m1"
                          >
                            <div className="candidates_check1">
                              {/* <input id={`checkbox${item["userId"]}`} type="checkbox" name={`checkbox${item["userId"]}`}
                            onChange={(e: any) =>
                              handleChecked(e, item)
                            }
                            checked={checkedListArray.includes(item["userId"])} />
                          <label htmlFor={`checkbox${item["rowId"]}`}><span /></label>
                          */}
                            </div>
                            <div className="connect_icon">
                              <div className="icon_clr1">
                                {String(item["userName"]).charAt(0)}
                              </div>
                            </div>
                            <div className="connect_con">
                              <div className="connect_con_name">
                                {" "}
                                {item["userName"]}
                                {/* {Number(item["transactionCount"]) > 1 && <div className="connect_con_des" onClick={() => { showUsers(item, index); }}>
                              +{Number(item["transactionCount"]) - 1}more
                          </div>
                            } */}
                                <span className="dot dot-busy"></span>
                              </div>
                              <div className="connect_con_des">
                                {item["email"]}
                              </div>
                            </div>
                            {/* <div className="chat_time_sec1"> */}
                            {/* <div className="chat_time">{moment(new Date(item["createdDate"])).format("DD-MM-YYYY")}</div>
                          <div className="chat_time">{moment(new Date(item["createdDate"])).format("HH:mm:ss")}</div> */}
                            {/* </div> */}
                            {item["unReadCount"] > 0 ? (
                              <div className="chat_time_sec1">
                                <div className="connect_con_noti">
                                  {item["unReadCount"]}
                                </div>
                              </div>
                            ) : null}
                          </a>
                        </li>
                      );
                    }
                  })}
                </InfiniteScroll>
              </ul>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function ChatMessageContent(props: any) {
  const {
    // totalCount,
    fetchMoreData,
    hasMore,
    messageShowList,
    receiveUserName,
    sendUserId,
    basicInfo,
    receiveUserId,
    downloadFile,
  } = props;
  ////console.log(props)
  return (
    <React.Fragment>
      <InfiniteScroll
        className="_custom-infinite-scroll-class"
        height={600}
        //dataLength={totalCount}
        dataLength={messageShowList.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        //To put endMessage and loader to the top.
        inverse={true} //
        //scrollableTarget="scrollableDiv"
      >
        {messageShowList.map((item: any, index: any) => {
          //start----------------------
          let chatPosition = "chat_right";
          let chatIcon = "chat_icon2";
          let subChatIcon = "icon_clr_rs2";
          let chatName = "chat_name1";
          let displayleft = item["messageTime"] + item["messageDate"];
          let displayRight = receiveUserName;

          let boxSide = "chat_box_r";
          if (item["fromUserId"] == sendUserId) {
            chatPosition = "chat_left";
            chatIcon = "chat_ico1";
            subChatIcon = "icon_clr_rs1";
            chatName = "chat_name";
            displayleft =
              basicInfo.fullName != undefined ? basicInfo.fullName : "";
            displayRight = item["messageTime"] + item["messageDate"];
            boxSide = "chat_box_l";
          }
          return (
            <div className={chatPosition}>
              <div className={chatIcon}>
                <div className={subChatIcon}>
                  {/* {basicInfo.fullName != undefined
                    ? basicInfo.fullName.charAt(0)
                    : ""} */}
                  {item["fromUserId"] == sendUserId && (
                    <>{displayleft.charAt(0)}</>
                  )}
                  {item["fromUserId"] == receiveUserId && (
                    <>{displayRight.charAt(0)}</>
                  )}
                </div>
              </div>
              <div className={chatName}>
                {item["fromUserId"] == sendUserId && (
                  <>
                    {displayleft}
                    <span>{displayRight}</span>
                  </>
                )}
                {item["fromUserId"] == receiveUserId && (
                  <>
                    <span>{displayleft}</span>
                    {displayRight}
                  </>
                )}
              </div>
              <div className="clearfix" />
              {item["fileType"] == "Text" && (
                <div className={boxSide} key={index}>
                  {item["message"]}
                </div>
              )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["contentHeader"].includes("image") ||
                  item["contentHeader"] == "image/jpeg") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={
                        environment.baseUrl +
                        `/Upload/ChatAttachment/${item["chatFile"]}`
                      }
                      // src={
                      //   "data:" +
                      //   item["contentHeader"] +
                      //   ";base64," +
                      //   item["attachment"]
                      // }
                      // src={require("../../images/logo.png"
                      className="img-responsive"
                      alt=""
                      width={200}
                      height={200}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl +
                            `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["chatFile"].includes(".doc") ||
                  item["contentHeader"] ==
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={require("../../../../images/word_document.png")}
                      //className="img-responsive"
                      alt={item["chatFile"]}
                      width={100}
                      height={100}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl +
                            `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["chatFile"].includes(".pdf") ||
                  item["contentHeader"] == "application/pdf") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={require("../../../../images/pdf-icon.png")}
                      //className="img-responsive"
                      alt={item["chatFile"]}
                      width={100}
                      height={100}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl +
                            `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}

              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                item["contentHeader"].includes("video") && (
                  <div className={boxSide} key={index}>
                    <ReactPlayer
                      url={
                        environment.baseUrl +
                        `/Upload/ChatAttachment/${item["chatFile"]}`
                      }
                      width="50%"
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["contentHeader"].includes("audio") ||
                  item["contentHeader"] == "audio/wav") && (
                  <div className={boxSide} key={index}>
                    <ReactAudioPlayer
                      src={
                        environment.baseUrl +
                        `/Upload/ChatAttachment/${item["chatFile"]}`
                      }
                      //autoPlay
                      controls
                    />
                  </div>
                )}
              {item["fileType"] != null &&
                item["fileType"] == "File" &&
                (item["chatFile"].includes(".xls") ||
                  item["contentHeader"] ==
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") && (
                  <div className={boxSide} key={index}>
                    <img
                      src={require("../../../../images/excel_document.png")}
                      //className="img-responsive"
                      alt={item["chatFile"]}
                      width={100}
                      height={100}
                      onClick={() =>
                        downloadFile(
                          environment.baseUrl +
                            `/Upload/ChatAttachment/${item["chatFile"]}`,
                          item["message"]
                        )
                      }
                    />
                  </div>
                )}
            </div>
          );

          //end----------------------------
        })}
      </InfiniteScroll>
    </React.Fragment>
  );
}

const MessagesComponent = (props: any) => {
  // console.log(props);
  const globalSpinner = useContext(GlobalSpinnerContext);
  const adminCommunicationDispatcher = useAdminCommunicationDispatcher();
  const adminCommunicationContext = useAdminCommunicationContext();
  //const { messagesUserAllList } = adminCommunicationContext;
  // const myVendorProfileContext = useVendorMyProfileContext();
  // const { basicInfo, loggedUserId } = myVendorProfileContext;
  const myProfileAdminDispatcher = useAdminMyProfileDispatcher();
  const myProfileAdminContext = useAdminMyProfileContext();
  const {
    //myProfile,
    basicInfo,
    loggedAdminId,
  } = myProfileAdminContext;
  // const adminId = loggedAdminId;
  const adminMyJobContext = useAdminMyJobContext();
  const { AdminPermissionResult } = adminMyJobContext;
  const [filter, setFilter] = useState<string>(
    props.location.state?.userName != undefined
      ? props.location.state.userName
      : ""
  );
  const [searchKey, setSearchKey] = useState<string>(
    props.location.state?.userName != undefined
      ? props.location.state.userName
      : ""
  );
  //const [sendMessage, setSendMessage] = useState<string>("");
  //const [checkedListArray, setCheckedListArray] = useState([] as any[]);
  const authorizationToken = AuthService.accessToken;
  const [selectedMessage, setSelectedMessage] = useState({
    userId: 0,
    userName: "",
    fullName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
  });
  const [userShowList, setUserShowList] = useState(
    Array<MessagesUserAllListModel>()
  );
  // const [recordState, setRecordState] = useState();
  const [hasMore, setHasMore] = React.useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCount, setTotalCount] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSize = 10;
  const [messageShowList, setMessageShowList] = useState(
    Array<{
      rowId: number;
      fromUserId: string;
      fromUserName: string;
      toUserId: string;
      toUserName: string;
      messageTime: string;
      messageDate: string;
      message: string;
      isActive: boolean;
      fileType: string;
      contentHeader: string;
      chatFile: string;
      attachment: string;
      chatDetailsId: number;
    }>()
  );
  const [DBMessageList, setDBMessageList] = useState(
    Array<{
      rowId: number;
      fromUserId: string;
      fromUserName: string;
      toUserId: string;
      toUserName: string;
      messageTime: string;
      messageDate: string;
      message: string;
      isActive: boolean;
      fileType: string;
      contentHeader: string;
      chatFile: string;
      attachment: string;
      chatDetailsId: number;
    }>()
  );
  const [newMessage, setNewMessage] = React.useState(false);
  const [messageListInfo, setMessageListInfo] = useState(
    Array<{
      rowId: number;
      fromUserId: string;
      fromUserName: string;
      toUserId: string;
      toUserName: string;
      messageTime: string;
      messageDate: string;
      message: string;
      isActive: boolean;
      fileType: string;
      contentHeader: string;
      chatFile: string;
      attachment: string;
      chatDetailsId: number;
    }>()
  );

  const [sendUserId, setSendUserId] = useState<string>();
  const [sendUserName, setSendUserName] = useState<string>();
  const [receiveUserId, setReceiveUserId] = useState<string>();
  const [receiveUserName, setReceiveUserName] = useState<string>();
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);
  const [hubConnection, setHubConnection] = useState<HubConnection>();

  const [showing, setShowing] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [isOpenFile, setIsOpenFile] = useState<boolean>(false);
  const [isOpenRecorder, setIsOpenRecorder] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [recordState, setRecordState] = useState();
  const [toggleBlockDisplay, setToggleBlockDisplay] = useState<string>("none");
  const user = AuthService.currentUser;
  const adminId = user?.id;
  let enterPress = true;

  const [messageUsers, setMessageUsers] = useState({});

  React.useEffect(() => {
    if (adminId != undefined && Number(adminId) > 0) {
      getMessageUserListNonContext(adminId, 1, 10, filter);
      // (async () => {
      //   globalSpinner.showSpinner();
      //   await getMessageUserList(adminCommunicationDispatcher, {
      //     UserId: Number(adminId),
      //     Page: 1,
      //     PageSize: 10,
      //     SearchTerm: filter, //"",
      //     SortOrder: "",
      //   } as MessagesRequestModel);
      //   globalSpinner.hideSpinner();
      // })();
    }
  }, [searchKey]);
  //}, [adminId, searchKey]);

  // React.useEffect(() => {
  //   if (messagesUserAllList != undefined) {
  //     // //console.log(messagesUserAllList);
  //     globalSpinner.hideSpinner();
  //   }
  // }, [messagesUserAllList]);

  // Set the Hub Connection on mount.
  useEffect(() => {
    if (adminId != undefined && Number(adminId) > 0) {
      // //console.log(adminId);
      // Set the initial SignalR Hub Connection.
      const createHubConnection = async () => {
        const signalR = require("@aspnet/signalr");

        // Build new Hub Connection, url is currently hard coded.
        const hubConnect = new HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Debug)
          .withUrl(environment.baseUrl + "/chathub", {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
          })
          .build();

        try {
          await hubConnect.start();
          // //console.log('Connection successful!!!');
          // //console.log('getLoggedUserId', loggedUserId);
          // //console.log('user', user);
          // //console.log('user id', user?.id);
          setSendUserId(user?.id);
          // setSendUserId(String(adminId));
          setSendUserName(basicInfo.fullName);
          // //console.log('Basic Info', basicInfo);
          // //console.log(basicInfo.fullName)
          // //console.log("authorizationToken", authorizationToken)

          // Bind event handlers to the hubConnection.
          hubConnect.on(
            "ReceiveMessage",
            (
              sendUserId1: string,
              sendUserName1: string,
              receiveUserId1: string,
              receiveUserName1: string,
              message1: any
            ) => {
              // setMessages(m => [...m, message]);
              //console.log("sendUserId :", `${sendUserId1}`);
              //console.log("sendUserName :", `${sendUserName1}`);
              //console.log("receiveUserId :", `${receiveUserId1}`);
              //console.log("receiveUserName :", `${receiveUserName1}`);
              //console.log("sendUserId :", `-------------`);
              //console.log("sendUserId :", `${sendUserId}`);
              //console.log("sendUserName :", `${sendUserName}`);
              //console.log("receiveUserId :", `${receiveUserId}`);
              //console.log("receiveUserName :", `${receiveUserName}`);
              let postTime = new Date().toLocaleString("en", {
                hour: "numeric",
                hour12: true,
                minute: "numeric",
              });
              let messageText = "";
              let fileType = "";
              let contentHeader = "";
              let chatFile = "";
              let attachment = "";
              if (typeof message1 == "object") {
                messageText = message1["fileName"];
                fileType = "File";
                contentHeader = message1["fileHeaders"];
                chatFile = message1["fileName"];
                attachment = message1["fileBinary"];
              }
              if (typeof message1 == "string") {
                messageText = message1;
                fileType = "Text";
              }
              const item = {
                rowId: 0,
                fromUserId: `${sendUserId1}`,
                fromUserName: `${sendUserName1}`,
                toUserId: `${receiveUserId1}`,
                toUserName: `${receiveUserName1}`,
                messageTime: postTime,
                messageDate: "",
                message: messageText,
                isActive: true,
                fileType: fileType,
                contentHeader: contentHeader,
                chatFile: chatFile,
                attachment: attachment,
                chatDetailsId: 0,
              };
              // if (sendUserId == sendUserId1 && receiveUserId == receiveUserId1) {
              setDBMessageList([item]);
              setNewMessage(true);
              // }
            }
          );
        } catch (err) {
          //alert(err);
          //console.log("Error while establishing connection: " + { err });
        }
        setHubConnection(hubConnect);
      };
      createHubConnection();
    }
  }, [adminId]);

  React.useEffect(() => {
    if (Number(sendUserId) > 0 && Number(receiveUserId) > 0) {
      getMessageList({
        FromUserId: Number(sendUserId),
        ToUserId: Number(receiveUserId),
        Page: 1,
        PageSize: currentPageSize,
        SearchTerm: "",
        SortOrder: "",
      })
        .then((res) => {
          //console.log(res.data);
          //console.log(res.data.data.length);
          //setMessageListInfo(res.data);
          setMessageShowList(res.data.data);
          if (res.data.total > 10) {
            setHasMore(true);
            setTotalPage(Math.ceil(res.data.total / currentPageSize));
            setTotalCount(res.data.total);
          }
        })
        .catch((err) => {
          // //console.log(err);
        });
      setCurrentPage(1);
    }
  }, [sendUserId, receiveUserId]);

  React.useEffect(() => {
    setMessageShowList([...messageShowList, ...messageListInfo]);
    /// //console.log(messageShowList);
  }, [messageListInfo]);

  React.useEffect(() => {
    // //console.log(DBMessageList);
    // //console.log(sendUserId);
    // //console.log(receiveUserId);
    if (newMessage && Number(sendUserId) > 0 && Number(receiveUserId) > 0) {
      setNewMessage(false);
      // setMessageShowList([...DBMessageList, ...messageShowList]);
      const shownDbList = DBMessageList.filter(
        (data: any) =>
          (data.fromUserId === String(sendUserId) &&
            data.toUserId === String(receiveUserId)) ||
          (data.fromUserId === String(receiveUserId) &&
            data.toUserId === String(sendUserId))
      );
      if (shownDbList != undefined && shownDbList.length > 0) {
        setMessageShowList([...shownDbList, ...messageShowList]);
      }
    }
  }, [newMessage]);
  React.useEffect(() => {
    if (authorizationToken != null && user?.id != null) {
      (async () => {
        await getAdminMyProfileBasicInfo(
          myProfileAdminDispatcher,
          authorizationToken
        );
      })();
    }
  }, [authorizationToken]);

  const handleReceiveUser = async (
    receiveUserIdSel: any,
    receiveUserNameSel: any,
    receiverUserIsOnline: any
  ) => {
    setReceiveUserId(receiveUserIdSel);
    setOnlineStatus(receiverUserIsOnline);
    setReceiveUserName(receiveUserNameSel);

    //setMessages([]);
    setShowing(true);
    // Update message read status

    let _data: objectIndexing = {};

    _data["SendUserId"] = Number(receiveUserIdSel);
    _data["ReceiveUserId"] = Number(user?.id);

    updateReadStatus(_data).then((res: any) => {
      //console.log(res);
      if (res.data.isSuccess) {
        //console.log("read-updated-success");
      } else {
        //console.log("read-updated-error");
      }
    });
  };

  const handleMessage = async () => {
    await sendChatMessage();
    setMessage("");
    enterPress = true;
  };

  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && enterPress) {
      enterPress = false;
      handleMessage();
    }
  };

  const emojiIconClick = () => {
    setShowEmoji(!showEmoji);
  };
  const addEmoji = (e: any) => {
    let emoji = e.native;
    setMessage(message + emoji);
  };

  /** Send message to server with client's nickname and message. */
  async function sendChatMessage(): Promise<void> {
    try {
      if (hubConnection && message !== "") {
        await hubConnection.invoke(
          "SendMessage",
          sendUserId,
          sendUserName,
          receiveUserId,
          receiveUserName,
          message
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  const sendFiles = () => {
    ////console.log(selectedFiles);
    globalSpinner.showSpinner();
    if (obj.file != undefined) {
      const formData = new FormData();
      // formData.append('FormFile', selectedFiles);
      // formData.append('FileName', selectedFiles.name);
      formData.append("FormFile", obj.file);
      formData.append("FileName", obj.file.name);
      formData.append("SendUserId", String(sendUserId));
      formData.append("SendUserName", String(sendUserName));
      formData.append("ReceiveUserId", String(receiveUserId));
      formData.append("ReceiveUserName", String(receiveUserName));
      sendChatFiles(formData)
        .then((res) => {
          ////console.log(res.data);
          //setSelectedFiles(undefined);
          setIsOpenFile(false);
          setObj({ file: null });
        })
        .catch((err) => {
          //console.log(err);
          setObj({ file: null });
          toast.error("Please try again later.");
          setIsOpenFile(false);
        });
    }
    globalSpinner.hideSpinner();
  };
  const sendAudioFiles = (data: any) => {
    globalSpinner.showSpinner();
    const formData = new FormData();
    formData.append("FormFile", data.blob, "blob.wav");
    formData.append("FileName", "blob.wav");
    formData.append("SendUserId", String(sendUserId));
    formData.append("SendUserName", String(sendUserName));
    formData.append("ReceiveUserId", String(receiveUserId));
    formData.append("ReceiveUserName", String(receiveUserName));
    sendChatFiles(formData)
      .then((res) => {
        ////console.log(res.data);
        setIsOpenRecorder(false);
      })
      .catch((err: any) => {
        //console.log(err);
        toast.error("Please try again later.");
        setIsOpenRecorder(false);
      });
    globalSpinner.hideSpinner();
  };

  const start = () => {
    setRecordState(RecordState.START);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };

  //audioData contains blob and blobUrl
  const onStop = (audioData: any) => {
    // //console.log('audioData', audioData)
    sendAudioFiles(audioData);
  };

  const downloadFile = (filePath: any, fileName: any) => {
    var FileSaver = require("file-saver");
    FileSaver.saveAs(filePath, fileName);
  };

  const fetchMoreData = () => {
    //console.log("hhh");
    //console.log(currentPage, totalPage);
    if (currentPage >= totalPage) {
      setHasMore(false);
      return;
    } else {
      // getMessageList({
      //   FromUserId: Number(sendUserId),
      //   ToUserId: Number(receiveUserId),
      //   Page: currentPage,
      //   PageSize: currentPageSize,
      //   SearchTerm: "",
      //   SortOrder: ""
      // });
      getMessageList({
        FromUserId: Number(sendUserId),
        ToUserId: Number(receiveUserId),
        Page: currentPage + 1,
        PageSize: currentPageSize,
        SearchTerm: "",
        SortOrder: "",
      })
        .then((res) => {
          // //console.log(res.data.data.length);
          // setMessageListInfo(res.data);
          // setMessageShowList(res.data.data);
          setMessageListInfo(res.data.data);
          //console.log(messageListInfo);
          //setMessageShowList([...messageShowList, ...messageListInfo]);
          setCurrentPage(currentPage + 1);
          // if (res.data.total > 10) {
          //   setHasMore(true);
          // }
        })
        .catch((err) => {
          // //console.log(err);
        });
    }
  };

  const [obj, setObj] = useState<any>({ file: null });

  const handleDrop = (files: any) => {
    if (
      // ["image/jpeg", "application/pdf"].includes(files[0].type) &&
      files[0].size <
      1048576 * 20
    ) {
      //console.log(files[0]);
      if (files.length > 0) {
        setObj({
          ...obj,
          ["file"]: files[0],
        });
      }
    } else {
      toast.error("File size limit is 20MB");
    }
  };
  React.useEffect(() => {
    if (currentPage >= totalPage) {
      setHasMore(false);
    }
  }, [currentPage]);

  const handleToggle = () => {
    if (toggleBlockDisplay.includes("none")) {
      setToggleBlockDisplay("block");
    } else {
      setToggleBlockDisplay("none");
    }
  };

  const getMessageUserListNonContext = (
    admin_Id: any,
    page: any,
    pageSize: any,
    filterSearch: any
  ) => {
    getCommunicationUserList({
      UserId: Number(admin_Id),
      Page: page,
      PageSize: pageSize,
      SearchTerm: filterSearch, //"",
      SortOrder: "",
    } as MessagesRequestModel).then((res: any) => {
      if (
        res.status == 200 &&
        res.data.data.length != undefined &&
        res.data.data.length > 0
      ) {
        setMessageUsers(res.data);
        if (res.data.data.length == 1) {
          //console.log(res.data.data[0]);
          handleReceiveUser(
            res.data.data[0]["userId"],
            res.data.data[0]["userName"],
            res.data.data[0]["isOnline"]
          );
        }
      }
    });
  };

  return (
    <>
      <ToastContainer />
      {/*=================Content Section Start================*/}
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Message</h1>
          <div className="action_btn1">
            <button
              type="button"
              className="actions_menu"
              onClick={handleToggle}
            >
              <i className="fa fa-align-left" aria-hidden="true" />
            </button>
            <div
              className="user_menu"
              style={{
                display: `${toggleBlockDisplay}`,
              }}
            >
              <div className="action_sec">
                <ul>
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isCreateorEditJobPermission ? (
                    <li>
                      <Link to="/admin/communication/bulkSMS"> Sent SMS</Link>
                    </li>
                  ) : null}
                  {AdminPermissionResult != undefined &&
                  AdminPermissionResult.isCreateorEditJobPermission ? (
                    <li>
                      {" "}
                      <Link to="/admin/communication/bulkEmail">
                        Bulk Sent Email
                      </Link>{" "}
                    </li>
                  ) : null}
                  <li>
                    <Link to="/admin/communication/messages">Messages</Link>{" "}
                  </li>
                  <li>
                    <Link to="/admin/communication/contactUs">
                      Contact us Queries
                    </Link>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row " style={{ paddingTop: 10 }}>
            <div className="col-sm-5 col-lg-4 p-r-0">
              <div className="panel panel-default panel_n">
                <div className="panel-body panel-body1">
                  <div className="connect_left">
                    <div>
                      {/* <input type="email" className="form-control" placeholder="Search" /> */}
                      <input
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setSearchKey((e.target as HTMLInputElement).value);
                          }
                        }}
                        className="form-control"
                        placeholder="Search"
                      />
                      <div className="search_icon">
                        <i className="fa fa-search" aria-hidden="true" />
                      </div>
                    </div>
                    {messageUsers != undefined &&
                      adminId != undefined &&
                      Number(adminId) > 0 && (
                        <MessageUserList
                          filter={searchKey}
                          //userList={messagesUserAllList}
                          userList={messageUsers}
                          //setCheckedListArray={setCheckedListArray}
                          //checkedListArray={checkedListArray}
                          adminCommunicationDispatcher={
                            adminCommunicationDispatcher
                          }
                          setSelectedMessage={setSelectedMessage}
                          //handleDeleteSMS={handleDeleteMessage}
                          setUserShowList={setUserShowList}
                          userShowList={userShowList}
                          handleReceiveUser={handleReceiveUser}
                          senderEmail={basicInfo.email}
                          globalSpinner={globalSpinner}
                          adminId={adminId}
                          getMessageUserListNonContext={
                            getMessageUserListNonContext
                          }
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-7 col-lg-8">
              <div className="panel panel-default panel_n">
                <div className="panel-body panel-body1">
                  <div className="connect_right">
                    <div className="connect_right_top">
                      {receiveUserName != undefined ? (
                        <div>
                          <div className="chat_icon1">
                            <div className="icon_clr_rs1">
                              {String(receiveUserName).charAt(0)}
                            </div>
                          </div>
                          <div className="connect_con_name_r">
                            {receiveUserName}
                          </div>
                          <div className="connect_con_ac">
                            {String(onlineStatus).includes("false")
                              ? "Offline"
                              : "Online"}
                            {/* Online */}
                          </div>
                        </div>
                      ) : (
                        <div className="connect_con_name_r"></div>
                      )}
                    </div>
                    <div className=" m-t-10">
                      <div
                        className="message_chat_des_scroll"
                        style={{ overflow: "hidden" }}
                        tabIndex={0}
                      >
                        <div className="connect_scroll_r">
                          <ChatMessageContent
                            totalCount={totalCount}
                            fetchMoreData={fetchMoreData}
                            messageShowList={messageShowList}
                            receiveUserName={receiveUserName}
                            sendUserId={sendUserId}
                            basicInfo={basicInfo}
                            receiveUserId={receiveUserId}
                            downloadFile={downloadFile}
                            hasMore={hasMore}
                          />
                        </div>
                      </div>
                    </div>
                    {showEmoji && (
                      <div className="connect_right_bottom">
                        <div className="connect_right_forms">
                          <span>
                            <Picker onSelect={addEmoji} />
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="connect_right_bottom">
                      <div className="connect_right_forms">
                        <input
                          type="text"
                          value={message}
                          className="form-control"
                          placeholder="Type message here"
                          onChange={(e) => setMessage(e.target.value)}
                          onKeyDown={onEnter}
                        />
                      </div>
                      <div className="smile_icon">
                        <img
                          onClick={() => emojiIconClick()}
                          src={require("../../../../images/smile.png")}
                        />
                      </div>
                      <div className="connect_right_icons">
                        <img
                          src={require("../../../../images/attach_icon.png")}
                          onClick={() => setIsOpenFile(true)}
                          style={{ paddingLeft: 10, paddingRight: 10 }}
                        />
                        <img
                          src={require("../../../../images/speaker_icon.png")}
                          onClick={() => setIsOpenRecorder(true)}
                        />
                      </div>
                    </div>

                    <Modal
                      show={isOpenFile}
                      onHide={() => {
                        setIsOpenFile(!isOpenFile);
                      }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Send File</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-6">
                              <FileUploads
                                accept=".pdf,.jpg,.jpeg,.xlsx,.csv,.xls"
                                onChange={(file: any) => {
                                  handleDrop([file]);
                                }}
                                name="Upload File"
                                disabled={false}
                              />
                            </div>
                            <ShowFileInfo obj={obj} />
                            {/* <div className="col-sm-6">
                              {obj.file && (
                                <div
                                  className="update_con"
                                  style={{
                                    width: 268,
                                    height: 105,
                                    paddingTop: 20,
                                  }}
                                >
                                  {obj.file.name}
                                </div>
                              )}
                            </div> */}
                          </div>
                        </div>
                      </Modal.Body>
                      <div className="modal-footer  m-t-30">
                        <button
                          onClick={() => sendFiles()}
                          className="btn btn-danger"
                          type="submit"
                          disabled={obj.file ? false : true}
                        >
                          Send
                        </button>
                        <button
                          onClick={() => setIsOpenFile(false)}
                          className="btn btn-default"
                          type="button"
                        >
                          cancel
                        </button>
                      </div>
                      <div className="clearfix" />
                    </Modal>
                    <Modal
                      show={isOpenRecorder}
                      onHide={() => {
                        setIsOpenRecorder(!isOpenRecorder);
                      }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Send Audio File</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <AudioReactRecorder
                            state={recordState}
                            onStop={onStop}
                          />
                          <button onClick={start} className="btn btn-primary">
                            Start
                          </button>
                          <button onClick={stop} className="btn btn-danger">
                            Send
                          </button>
                        </div>
                      </Modal.Body>
                      <div className="modal-footer  m-t-30">
                        <button
                          onClick={() => setIsOpenRecorder(false)}
                          className="btn btn-default"
                          type="button"
                        >
                          cancel
                        </button>
                      </div>
                      <div className="clearfix" />
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      {/*=================Content Section End================*/}
    </>
  );
};

export default withRouter(MessagesComponent);
