import { Select } from "antd";
import React, { useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  getAllVendorListInReport,
  getAllTotalReportCount,
  useVendorReportContext,
  useVendorReportDispatcher,
} from "../../../action/admin/VendorReportAction";
import { VendorReportRequestModel } from "../../../models/admin/VendorReport";
import AuthService from "../../../services/AuthService";
import Pdf from "react-to-pdf";
import ReactToExel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";

const VendorReportComponent = () => {
  const { Option } = Select;
  const authorizationToken = AuthService.accessToken;
  let user = AuthService.currentUser;
  const refOne = useRef<any>();

  const vendorReportDispatcher = useVendorReportDispatcher();
  const vendorReportContext = useVendorReportContext();
  const { vendorReportsList, totalReportList } = vendorReportContext;
  const [currentPageOfListReport, setCurrentPageOfListReport] = useState(1);
  const [filterValue, setFilterValue] = useState<number>(1);

  //console.log(545454, totalReportList);
  //console.log(545454, vendorReportsList);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getAllVendorListInReport(
          vendorReportDispatcher,
          {
            FilterId: filterValue,
            Page: currentPageOfListReport,
            PageSize: 10,
            SearchTerm: "",
            ShowInactive: false,
            SortOrder: "",
          } as VendorReportRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [filterValue, currentPageOfListReport]);

  React.useEffect(() => {
    if (authorizationToken != null) {
      (async () => {
        await getAllTotalReportCount(
          vendorReportDispatcher,
          {
            FilterId: filterValue,
            Page: 1,
            PageSize: 10,
            SearchTerm: "",
            ShowInactive: false,
            SortOrder: "",
          } as VendorReportRequestModel,
          authorizationToken
          // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNyIsIm5hbWUiOiJhYjEzOWZlOC01MTJkLTQyZGMtOTQ3MS0zODMzZTFlYjRlZjYiLCJ0b2tlbl91c2FnZSI6ImFjY2Vzc190b2tlbiIsImp0aSI6ImVmNTU5ZjYxLTBlZTktNDBhMS1iYTViLTQ5ZTE2MDk5Mzg0MiIsInNjb3BlIjpbIm9wZW5pZCIsImVtYWlsIiwicGhvbmUiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlcyJdLCJuYmYiOjE2MTI1OTg2NjQsImV4cCI6MTY0NDEzNDY2NCwiaWF0IjoxNjEyNTk4NjY0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDM2MS8ifQ.WyMMc_5KbhYoYnmAjyx65VQAn5-mAu4dEsHxVHPWDA8'
        );
      })();
    }
  }, [filterValue]);

  const handlePageChange = (value: any) => {
    setCurrentPageOfListReport(value.selected + 1);
  };

  const handleChangeFilter = (value: any) => {
    //console.log(`selected ${value}`);
    setFilterValue(parseInt(value));
  };
  const componentRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Reports</h1>
          <ReactToExel
            className="cutomise_but"
            table="table-to-xls"
            filename="excelFile"
            sheet="sheet 1"
            buttonText="Export To Exel"
          />

          <ReactToPrint
            trigger={() => (
              <button className="cutomise_but">Export To PDF</button>
            )}
            content={() => componentRef.current}
          />

          <div className="clearfix" />
          <div className="row ">
            <div className="col-sm-12 col-lg-12" ref={componentRef}>
              <div className="report_top">
                <div className="col-sm-9">
                  <div className="report_sec">
                    <div className="report_co"> Job Applications </div>
                    <div className="report_co1">
                      {totalReportList.totalAppliedJobsCount}
                    </div>
                  </div>

                  <div className="report_sec">
                    <div className="report_co">Applied </div>
                    <div className="report_co1">
                      {" "}
                      {totalReportList.totalAppliedCandidatesCount}
                    </div>
                  </div>

                  <div className="report_sec">
                    <div className="report_co">Selected</div>
                    <div className="report_co1">
                      {" "}
                      {totalReportList.totalSelectedCandidatesCount}
                    </div>
                  </div>

                  <div className="report_sec">
                    <div className="report_co">Rejected</div>
                    <div className="report_co1">
                      {" "}
                      {totalReportList.totalRejectedCandidatesCount}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  {/* <span className="select-wrapper-sec"> */}
                  <Select
                    defaultValue="1"
                    style={{ width: 240 }}
                    onChange={handleChangeFilter}
                  >
                    <Option value="1">Today</Option>
                    <Option value="2">Last 7 days</Option>
                    <Option value="3">Last 30 days</Option>
                    <Option value="4">Last 90 days</Option>
                    <Option value="5">Last 1 year</Option>
                    <Option value="6">{">"} 1 year</Option>
                  </Select>
                  {/* </span> */}
                </div>
              </div>
              <div className="section_box4">
                <div className="reports">
                  <div className="table-responsive">
                    <table
                      className="table table-hover table-bordered dataTable no-footer dtr-inline"
                      width="100%"
                      id="table-to-xls"
                    >
                      <thead>
                        <tr>
                          {/* <th>&nbsp;</th> */}
                          <th colSpan={2} className="bg">
                            Name{" "}
                          </th>
                          <th colSpan={2} className="bg">
                            Jobs
                          </th>
                          {/* <th>&nbsp;</th> */}
                          <th colSpan={2} className="bg">
                            Candidates{" "}
                          </th>
                        </tr>
                        <tr>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>Job Applications</th>
                          <th>Applied </th>
                          <th>Selected</th>
                          <th>Rejected</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vendorReportsList.data != undefined
                          ? vendorReportsList.data.map((data, index) => {
                              return (
                                <tr>
                                  <td>
                                    <img
                                      // src={data.imageurl}
                                      src={require("../../../images/profileDefault1.jpg")}
                                      className="report_img"
                                    />
                                  </td>
                                  <td className="text-left">
                                    {data.vendorName}
                                  </td>
                                  <td>{data.appliedJobsCount}</td>
                                  <td>{data.appliedCandidatesCount}</td>
                                  <td>{data.selectedCandidatesCount}</td>
                                  <td>{data.rejectedCandidatesCount}</td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <ReactPaginate
                previousLabel={<i className="fa fa-angle-left" />}
                nextLabel={<i className="fa fa-angle-right" />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={
                  vendorReportsList != undefined
                    ? vendorReportsList.total / 10
                    : 1
                }
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={"pagination m-b-5"}
                activeClassName={"active"}
              />
              {/* 
              <ul className="pagination m-b-5">
                <li>
                  <a href="#" aria-label="Previous">
                    {" "}
                    <i className="fa fa-angle-left" />{" "}
                  </a>
                </li>
                <li className="active">
                  <a href="#">1</a>
                </li>
                <li>
                  <a href="#">2</a>
                </li>
                <li>
                  <a href="#">3</a>
                </li>
                <li>
                  <a href="#">4</a>
                </li>
                <li>
                  <a href="#">5</a>
                </li>
                <li>
                  {" "}
                  <a href="#" aria-label="Next">
                    {" "}
                    <i className="fa fa-angle-right" />{" "}
                  </a>{" "}
                </li>
              </ul>
          
           */}
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </>
  );
};
export default VendorReportComponent;
