import React from "react";
import SearchGenericComponent from "./SearchGenericComponent";

const SearchGenericContainer = () => {
  return (
    <>
      <SearchGenericComponent />
    </>
  );
};

export default SearchGenericContainer;
