import { Reducer } from "react"
import { IJobCategoryManagementState, JobCategoryDispatcherContext, JobCategoryStateContext } from "../context/JobCategoryContext"
import React from "react";
import { jobCategoryViewModel } from "../models/candidate/JobCategory";
import axios from 'axios';
import AuthService from "../services/AuthService";
import { AppUrls } from "../environments/environment";

let token=AuthService.accessToken;
let   authorizationToken =token!=null?token.replace(/['"]+/g,''):""; 

export type IJobCategoryAction = {
    type:"SET_Job Category_LIST",
    jobCategory:jobCategoryViewModel 
}




export const jobCategoryReducer: Reducer<IJobCategoryManagementState, IJobCategoryAction> = (draft, action): IJobCategoryManagementState => {
    switch (action.type) {
     
            case "SET_Job Category_LIST":
                draft.jobCategory=action.jobCategory;
                return draft;
    }
}



export const useJobCategoryDispatcher = (): React.Dispatch<IJobCategoryAction> => {
    const jobCategoryDispatcher = React.useContext(JobCategoryDispatcherContext);
    if (!jobCategoryDispatcher) {
        throw new Error("You have to provide the JobCategory dispatcher using theJobCategoryDispatcherContext.Provider in a parent component.");
    }
    return jobCategoryDispatcher;
}


export const useJobCategoryContext = (): IJobCategoryManagementState => {
    const jobCategoryContext = React.useContext(JobCategoryStateContext);
    if (!jobCategoryContext) throw new Error("You have to provide the jobCategory context using the JobCategoryStateContext.Provider in a parent component.");
    return jobCategoryContext;
}





export const getJobCategoryList = async (dispatcher: React.Dispatch<IJobCategoryAction>,token:string ) => {
   
    try{
      if (token?.startsWith(`"`)) {
        token = token.substring(1);
        token = token.slice(0, -1);
      }


      let header = {
                      "Content-Type": "application/json",
                      "Accept": "application/json",
                     "Authorization":`Bearer ${token}`,
                  };
  
                   const url=AppUrls.GetAllJobCategory
  
                  axios.post(url, JSON.stringify( 
                      {}
                  
                   ),{ headers: header })
                  .then(res => {
                    dispatcher({ type:"SET_Job Category_LIST", jobCategory: res.data });
                  })

  }
  catch(e){

  }
}



