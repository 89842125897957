import React from "react";
import { Link, withRouter, useRouteMatch } from "react-router-dom";
import ResumeContainer from "../resume_template/resume/ResumeContainer";

interface ICandidateMyResumeComponentProps { }

// interface ICandidateMyResumeComponentState { }

// const initialState = {};

const CandidateMyResumeComponent: React.FC<ICandidateMyResumeComponentProps> = (
  props
) => {
  // const { path, url } = useRouteMatch();
  // const [
  //   CandidateMyResumeComponentState,
  //   setCandidateMyResumeComponentState,
  // ] = React.useState<ICandidateMyResumeComponentState>(initialState);

  return (
    <React.Fragment>
      {/* <div className="content-wrapper">
        <div className="container-fluid">
          <h1 className="heading">Resume</h1>
          <div className="clearfix" />
          <div className="row ">
            <div className="col-sm-12 col-lg-9">
              <div className="heading_sec2">
                <h1>Resume Builder </h1>
                <div>
                  <Link to={`${url}/resume`} className="btn resume_build_but">
                    Build your resume with us
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="prodict_list">
                  <div
                    id="owl-demo1"
                    className="owl-carousel owl-theme"
                    style={{ opacity: 1, display: "block" }}
                  >
                    <div className="owl-wrapper-outer">
                      <div
                        className="owl-wrapper"
                        style={{
                          width: "1928px",
                          left: "0px",
                          display: "block",
                          transition: "all 1000ms ease 0s",
                          transform: "translate3d(0px, 0px, 0px)",
                        }}
                      >
                        <div className="owl-item" style={{ width: "241px" }}>
                          <div className="item">
                            <div className="matched_jobs_sec">
                              <div className="digis_expairs">
                                Created on - 16- 09-2020
                              </div>
                              <div className="matched_jobs">
                                <img
                                  src={require("./../../../images/my_resume_sh.jpg")}
                                  // src="images/my_resume_sh.jpg"
                                  alt=""
                                  className="image"
                                />
                                <div className="overlay">
                                  <div className="myresume_edits ">
                                    <a href="#" className="myresume_clr1">
                                      <i
                                        className="fa fa-download"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr2">
                                      <i
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr3">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr4">
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="digis_documents">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                />{" "}
                                Resume 1
                              </div>
                              <div className="action_btn1">
                                <button type="button" className="actions_bt">
                                  <i
                                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                    aria-hidden="true"
                                  />
                                </button>
                                <div className="dash_action" tabIndex={-1}>
                                  <div className="action_sec">
                                    <ul>
                                      <li>
                                        <a href="#">Dummy</a>{" "}
                                      </li>
                                      <li>
                                        <a href="#"> ummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="owl-item" style={{ width: "241px" }}>
                          <div className="item">
                            <div className="matched_jobs_sec">
                              <div className="digis_expairs">
                                Created on - 16- 09-2020
                              </div>
                              <div className="matched_jobs">
                                <img
                                  src={require("./../../../images/my_resume_sh.jpg")}
                                  // src="images/my_resume_sh.jpg"
                                  alt=""
                                  className="image"
                                />
                                <div className="overlay">
                                  <div className="myresume_edits ">
                                    <a href="#" className="myresume_clr1">
                                      <i
                                        className="fa fa-download"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr2">
                                      <i
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr3">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr4">
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="digis_documents">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                />{" "}
                                Resume 2
                              </div>
                              <div className="action_btn1">
                                <button type="button" className="actions_bt1">
                                  <i
                                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                    aria-hidden="true"
                                  />
                                </button>
                                <div className="dash_action1" tabIndex={-1}>
                                  <div className="action_sec">
                                    <ul>
                                      <li>
                                        <a href="#">Dummy</a>{" "}
                                      </li>
                                      <li>
                                        <a href="#"> ummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="owl-item" style={{ width: "241px" }}>
                          <div className="item">
                            <div className="matched_jobs_sec">
                              <div className="digis_expairs">
                                Created on - 16- 09-2020
                              </div>
                              <div className="matched_jobs">
                                <img
                                  src={require("./../../../images/my_resume_sh.jpg")}
                                  //  src="images/my_resume_sh.jpg"
                                  alt=""
                                  className="image"
                                />
                                <div className="overlay">
                                  <div className="myresume_edits ">
                                    <a href="#" className="myresume_clr1">
                                      <i
                                        className="fa fa-download"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr2">
                                      <i
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr3">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr4">
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="digis_documents">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                />{" "}
                                Resume 3
                              </div>
                              <div className="action_btn1">
                                <button type="button" className="actions_bt2">
                                  <i
                                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                    aria-hidden="true"
                                  />
                                </button>
                                <div className="dash_action2" tabIndex={-1}>
                                  <div className="action_sec">
                                    <ul>
                                      <li>
                                        <a href="#">Dummy</a>{" "}
                                      </li>
                                      <li>
                                        <a href="#"> ummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="owl-item" style={{ width: "241px" }}>
                          <div className="item">
                            <div className="matched_jobs_sec">
                              <div className="digis_expairs">
                                Created on - 16- 09-2020
                              </div>
                              <div className="matched_jobs">
                                <img
                                  src={require("./../../../images/my_resume_sh.jpg")}
                                  //  src="images/my_resume_sh.jpg"
                                  alt=""
                                  className="image"
                                />
                                <div className="overlay">
                                  <div className="myresume_edits ">
                                    <a href="#" className="myresume_clr1">
                                      <i
                                        className="fa fa-download"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr2">
                                      <i
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr3">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr4">
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="digis_documents">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                />{" "}
                                Resume 4
                              </div>
                              <div className="action_btn1">
                                <button type="button" className="actions_bt3">
                                  <i
                                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                    aria-hidden="true"
                                  />
                                </button>
                                <div className="dash_action3" tabIndex={-1}>
                                  <div className="action_sec">
                                    <ul>
                                      <li>
                                        <a href="#">Dummy</a>{" "}
                                      </li>
                                      <li>
                                        <a href="#"> ummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="owl-controls clickable"
                      style={{ display: "none" }}
                    >
                      <div className="owl-pagination">
                        <div className="owl-page active">
                          <span className="" />
                        </div>
                      </div>
                      <div className="owl-buttons">
                        <div className="owl-prev">
                          <img
                            src={require("../../../images/products_ar1.png")}
                          />{" "}
                        </div>
                        <div className="owl-next">
                          {" "}
                          <img
                            src={require("../../../images/products_ar2.png")}
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-t-25" />
              <div className="heading_sec2">
                <h1>Cover Letter</h1>
                <div>
                  <Link
                    to={`${url}/cover-letter`}
                    className="btn resume_build_but"
                  >
                    {" "}
                    Build your cover letter with us
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="prodict_list">
                  <div
                    id="owl-demo2"
                    className="owl-carousel owl-theme"
                    style={{ opacity: 1, display: "block" }}
                  >
                    <div className="owl-wrapper-outer">
                      <div
                        className="owl-wrapper"
                        style={{
                          width: "1928px",
                          left: "0px",
                          display: "block",
                        }}
                      >
                        <div className="owl-item" style={{ width: "241px" }}>
                          <div className="item">
                            <div className="matched_jobs_sec">
                              <div className="digis_expairs">
                                Created on - 16- 09-2020
                              </div>
                              <div className="matched_jobs">
                                <img
                                  src={require("./../../../images/cover_letter.jpg")}
                                  // src="images/cover_letter.jpg"
                                  alt=""
                                  className="image"
                                />
                                <div className="overlay">
                                  <div className="myresume_edits ">
                                    <a href="#" className="myresume_clr1">
                                      <i
                                        className="fa fa-download"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr2">
                                      <i
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr3">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr4">
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="digis_documents">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                />{" "}
                                Cover Letter 1
                              </div>
                              <div className="action_btn1">
                                <button type="button" className="actions_bt4">
                                  <i
                                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                    aria-hidden="true"
                                  />
                                </button>
                                <div className="dash_action4" tabIndex={-1}>
                                  <div className="action_sec">
                                    <ul>
                                      <li>
                                        <a href="#">Dummy</a>{" "}
                                      </li>
                                      <li>
                                        <a href="#"> ummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="owl-item" style={{ width: "241px" }}>
                          <div className="item">
                            <div className="matched_jobs_sec">
                              <div className="digis_expairs">
                                Created on - 16- 09-2020
                              </div>
                              <div className="matched_jobs">
                                <img
                                  src={require("./../../../images/cover_letter.jpg")}
                                  // src="images/cover_letter.jpg"
                                  alt=""
                                  className="image"
                                />
                                <div className="overlay">
                                  <div className="myresume_edits ">
                                    <a href="#" className="myresume_clr1">
                                      <i
                                        className="fa fa-download"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr2">
                                      <i
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr3">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr4">
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="digis_documents">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                />{" "}
                                Cover Letter 1
                              </div>
                              <div className="action_btn1">
                                <button type="button" className="actions_bt4">
                                  <i
                                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                    aria-hidden="true"
                                  />
                                </button>
                                <div className="dash_action4" tabIndex={-1}>
                                  <div className="action_sec">
                                    <ul>
                                      <li>
                                        <a href="#">Dummy</a>{" "}
                                      </li>
                                      <li>
                                        <a href="#"> ummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="owl-item" style={{ width: "241px" }}>
                          <div className="item">
                            <div className="matched_jobs_sec">
                              <div className="digis_expairs">
                                Created on - 16- 09-2020
                              </div>
                              <div className="matched_jobs">
                                <img
                                  src={require("./../../../images/cover_letter.jpg")}
                                  // src="images/cover_letter.jpg"
                                  alt=""
                                  className="image"
                                />
                                <div className="overlay">
                                  <div className="myresume_edits ">
                                    <a href="#" className="myresume_clr1">
                                      <i
                                        className="fa fa-download"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr2">
                                      <i
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr3">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr4">
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="digis_documents">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                />{" "}
                                Cover Letter 2
                              </div>
                              <div className="action_btn1">
                                <button type="button" className="actions_bt5">
                                  <i
                                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                    aria-hidden="true"
                                  />
                                </button>
                                <div className="dash_action5" tabIndex={-1}>
                                  <div className="action_sec">
                                    <ul>
                                      <li>
                                        <a href="#">Dummy</a>{" "}
                                      </li>
                                      <li>
                                        <a href="#"> ummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="owl-item" style={{ width: "241px" }}>
                          <div className="item">
                            <div className="matched_jobs_sec">
                              <div className="digis_expairs">
                                Created on - 16- 09-2020
                              </div>
                              <div className="matched_jobs">
                                <img
                                  src={require("./../../../images/cover_letter.jpg")}
                                  // src="images/cover_letter.jpg"
                                  alt=""
                                  className="image"
                                />
                                <div className="overlay">
                                  <div className="myresume_edits ">
                                    <a href="#" className="myresume_clr1">
                                      <i
                                        className="fa fa-download"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr2">
                                      <i
                                        className="fa fa-share-alt"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr3">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      />
                                    </a>
                                    <a href="#" className="myresume_clr4">
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="digis_documents">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                />{" "}
                                Cover Letter 3
                              </div>
                              <div className="action_btn1">
                                <button type="button" className="actions_bt6">
                                  <i
                                    className="fa fa-ellipsis-h mdi-toggle-switch-off"
                                    aria-hidden="true"
                                  />
                                </button>
                                <div className="dash_action6" tabIndex={-1}>
                                  <div className="action_sec">
                                    <ul>
                                      <li>
                                        <a href="#">Dummy</a>{" "}
                                      </li>
                                      <li>
                                        <a href="#"> ummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                      <li>
                                        <a href="#">Dummy</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="owl-controls clickable"
                      style={{ display: "none" }}
                    >
                      <div className="owl-pagination">
                        <div className="owl-page active">
                          <span className="" />
                        </div>
                      </div>
                      <div className="owl-buttons">
                        <div className="owl-prev">
                          <img
                            src={require("../../../images/products_ar1.png")}
                          />{" "}
                        </div>
                        <div className="owl-next">
                          {" "}
                          <img
                            src={require("../../../images/products_ar2.png")}
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3 ">
              <div className="heading_sec1">
                <h1>
                  <i className="fa fa-lock" aria-hidden="true" /> About RESUME
                  BUILDER
                </h1>
              </div>
              <div className="section_box4">
                <div className="sec_con">
                  Candidates can CREATE their resumes and DESIGN cover letters
                  with this feature from JIT which comes cost free. <br />
                  There is no limit of how many copies they can make.
                  <br />
                  Candidates can DUPLICATE the existing resume or cover letter
                  to create another from it. With this feature, they can CREATE,
                  MODIFY, SHARE, DELETE and ATTACH resumes and cover letters
                  while applying for jobs.
                  <br />
                  Candidates can share these documents out of JIT platform as
                  well.
                  <br />
                  Candidates don't need to enter all the information.
                  Information is populated from their profile and candidates can
                  modify or add to it if they wish to do so. <br />
                  Candidates don't need to enter all the information.
                  Information is populated from their profile and candidates can
                  modify or add to it if they wish to do so. <br />
                  JIT's RESUME BUILDER is an impactful feature for the
                  candidates as they can quickly build a resume and a cover
                  letter even if they don't have a one with them. It hardly
                  takes 10 minutes to get ready provided that every detail is
                  already updated in the profile. Candidates can sail through
                  the oceans of opportunities with these much needed tools in
                  their hands.
                  <br />
                </div>
              </div>
              <div className="heading_sec1">
                <h1>
                  <i className="fa fa-cog" aria-hidden="true" /> How it Works
                </h1>
              </div>
              <div className="section_box4">
                <div>
                  {" "}
                  <img
                    src={require("./../../../images/video_img.jpg")}
                    // src="images/video_img.jpg"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div> */}
      <ResumeContainer digiLockerVew={true} />
    </React.Fragment>
  );
};
export default withRouter(CandidateMyResumeComponent);
